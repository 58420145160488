import { CheckCircleOutlined, CloseCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Empty, Row, Tooltip, Typography } from 'antd';
import { CloneIcon, EditIcon, RemoveIcon } from 'components/Icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatCurrency } from 'utils/utils';
import getInflation from '../../utils/inflation';
import SeeMore from 'components/SeeMore/index';
import './PrescriptionTable.scss';
import MedicareCostMenu from '../MedicareCostMenu';

const { Text } = Typography;

class PrescriptionTable extends Component {
  render() {
    const { data, handleEdit, handleClone, handleDelete, handleSeed, handleChangePosition, canEdit } = this.props;

    return (
      <Row className="msa-prescription-table-container">
        <Row type="flex" align="middle" className="table-header">
          <Col md={3} className="border-right center">
            Prescription
          </Col>
          <Col md={3}>
            <Row className="group">
              <Col md={12} className="border-right center">
                NDC
              </Col>
              <Col md={12} className="border-right center">
                Unit Form
              </Col>
            </Row>
          </Col>
          <Col md={2} className="border-right center">
            Prescribed Strength
          </Col>
          <Col md={3} className="border-right">
            <Row className="sub-group-header">
              <Col md={24}>Prescribed Frequency</Col>
            </Row>
            <Row type="flex" align="middle" className="sub-header border-top">
              <Col md={12} className="border-right center">
                Units per Refill
              </Col>
              <Col md={12} className="center">
                Refills per Year
              </Col>
            </Row>
          </Col>
          <Col md={10} className="border-right comment">
            <Row className="sub-group-header">
              <Col md={24}>Pricing Policy: Average Wholesale Price(AWP)</Col>
            </Row>
            <Row type="flex" align="middle" className="sub-header border-top">
              <Col md={4} className="border-right center">
                Price Per Unit
              </Col>
              <Col md={4} className="border-right center">
                Annual Cost
              </Col>
              <Col md={2} className="border-right center">
                Total Years
              </Col>
              <Col md={4} className="border-right center">
                Total Cost
              </Col>
              {this.props.report.has_inflation && (
                <Col md={3} className="border-right center">
                  Inflation Total
                </Col>
              )}
              <Col className="center comment">Comments</Col>
            </Row>
          </Col>
          {!this.props.isMcp && (
            <Col md={2} className="border-right center">
              Used in seed money calculation
            </Col>
          )}

          <Col md={2} className="action"></Col>
        </Row>
        <Row className="table-content">
          {data.length > 0 ? (
            <Row>
              {data
                .sort((a, b) => {
                  return a.position - b.position;
                })
                .map((item, index) => (
                  <Row key={index} className="record" id={'table-item-' + item.id + item.position}>
                    <Col md={3} className="text-left">
                      <Text>{item.prescriptions}</Text>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <Text>{item.ndc}</Text>
                        </Col>
                        <Col md={12}>
                          <Text>{item.unit_form && item.unit_form.name}</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <Text>{item.prescription_strength}</Text>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <Text className="text-center">{item.units_per_refill}</Text>
                        </Col>
                        <Col md={12}>
                          <Text className="text-center">{item.refills_per_year}</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={10} className="comment">
                      <Row className="AWP-container">
                        <Col md={4}>
                          <Text>{formatCurrency(item.per_unit_cost)}</Text>
                        </Col>
                        <Col md={4}>
                          <Text>{formatCurrency(item.annual_cost)}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.total_years}</Text>
                        </Col>
                        <Col md={4}>
                          <Text>{formatCurrency(item.total_cost)}</Text>
                        </Col>
                        {this.props.report.has_inflation && (
                          <Col md={3}>
                            <Text>
                              {formatCurrency(
                                getInflation(
                                  {
                                    interest_rate: this.props.report.interest_rate,
                                  },
                                  item.total_cost,
                                  item.total_years
                                )
                              )}
                            </Text>
                          </Col>
                        )}
                        <Col md={this.props.report.has_inflation ? 6 : 9} className="text-left">
                          <Text>
                            <SeeMore text={item.comments} />
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={1}></Col>
                    {!this.props.isMcp && canEdit && (
                      <Col md={1}>
                        <Text>
                          {!item.is_seed_calculated ? (
                            <CloseCircleOutlined
                              onClick={() => {
                                handleSeed(item.id, item.is_seed_calculated);
                              }}
                              style={{
                                color: 'gray',
                                fontSize: 17,
                                fontWeight: 'bold',
                              }}
                            />
                          ) : (
                            <CheckCircleOutlined
                              onClick={() => {
                                handleSeed(item.id, item.is_seed_calculated);
                              }}
                              style={{
                                color: 'green',
                                fontSize: 17,
                                fontWeight: 'bold',
                              }}
                            />
                          )}
                        </Text>
                      </Col>
                    )}

                    <Col>
                      {canEdit && (
                        <MedicareCostMenu
                          handleClone={handleClone}
                          handleDelete={handleDelete}
                          handleChangePosition={handleChangePosition}
                          handleEdit={handleEdit}
                          item={item}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
            </Row>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
          )}
        </Row>
      </Row>
    );
  }
}
const mapStateToProps = state => {
  const props = {
    settings: state.auth.settings,
    report: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, {})(PrescriptionTable);
