import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Button, notification } from 'antd';
import AuthSideBar from '../../../components/AuthSideBar';
import { useHistory, useParams } from 'react-router-dom';
import SpinBox from 'components/SpinBox';
import { CheckSuccessIcon } from '../../../components/Icons';
import * as API from 'utils/api';
import './LoginClient.scss';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import { createClientProfileAuthorize } from 'utils/api-authorize';

const { Title, Text } = Typography;

const LoginClient = () => {
  const [step, setStep] = useState(1);
  const [dataGlobal, setDataGlobal] = useState({});
  const [juridictionList, setJuridictionList] = useState([]);
  const [listContactType, setListContactType] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  let { tenant_id } = useParams();

  const getStates = async () => {
    try {
      await API.getStates().then(res => {
        setJuridictionList(res.data.states);
      });
      await API.getListContactType({tenant_id}).then(res => {
        setListContactType(res.data.contact_type);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  const handleChangeStep = ({ step }) => {
    setStep(step);
  };

  const handleSaveData = async ({ sectionStep, payload, nextStep }) => {
    setDataGlobal({
      ...dataGlobal,
      [sectionStep]: payload,
    });

    if (nextStep < 5) {
      setStep(nextStep);
      return;
    }

    const params = {
      ...dataGlobal,
      [sectionStep]: payload,
    };

    await API.signUp({
      params,
      tenant_id,
    })
      .then(async res => {
        const clientProfileAuthorize = {
          firstName: params.client.fields.companyName,
          lastName: params.client.fields.companyName,
          address: params.client.fields.address,
          city: params.client.fields.city,
          state: params.client.fields.state,
          zip: params.client.fields.zip,
          number: params.client.fields.phone,
          client_id: res.data.client.id,
          user_id: res.data.user.id,
          email: params.client.fields.email
        };

        await createClientProfileAuthorize({ body: clientProfileAuthorize });

        if (res) {
          setStep(nextStep);
        }
      })
      .catch(() => {
        notification['warning']({
          message: 'Please validate your company is not already registered, validate with your system admin.',
        });
        setStep(2);
      });
  };

  return (
    <SpinBox loading={loading}>
      <Row type="flex" className="login-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        {step === 5 ? (
          <div className="loginClient_content successPadding">
            <div className="succesCreateCount">
              <CheckSuccessIcon className="check-icon" />
              <Title level={1}>Registration Completed</Title>
              <Text>Our managers will review your application. If approved, further instructions will be sent to:</Text>
              <Text className="textColor_green">{dataGlobal?.user?.email || ''}</Text>
              <button className="addAdditionalContactButton" onClick={() => history.push('/login')}>
                Back to Login
              </button>
            </div>
          </div>
        ) : (
          <div className="loginClient_content">
            <div className="loginClient_content_title">
              <Title level={1} className="loginClient_title">
                Sign Up
              </Title>
              <Text>
                Already have an account?
                <Button type="link" href="/login">
                  Sign In
                </Button>
              </Text>
            </div>

            <div className="loginClient_steps">
              <div
                onClick={() => step > 1 && handleChangeStep({ step: 1 })}
                className={`loginClient_step ${step === 1 ? 'active' : ''} ${step > 1 ? 'stepComplete' : ''}`}>
                <div className="span">1</div> Account Info
              </div>
              <div
                onClick={() => step > 2 && handleChangeStep({ step: 2 })}
                className={`loginClient_step ${step === 2 ? 'active' : ''} ${step > 2 ? 'stepComplete' : ''}`}>
                <div className="span">2</div> Company Info
              </div>
              <div
                onClick={() => step > 3 && handleChangeStep({ step: 3 })}
                className={`loginClient_step ${step === 3 ? 'active' : ''} ${step > 3 ? 'stepComplete' : ''}`}>
                <div className="span">3</div> Contact Info
              </div>
              <div className={`loginClient_step ${step === 4 ? 'active' : ''}`}>
                <div className="span">4</div> Billing Info
              </div>
            </div>

            {step === 1 && <Step1 data={dataGlobal?.user || {}} onSubmit={handleSaveData} />}
            {step === 2 && <Step2 data={dataGlobal?.tenant || {}} onSubmit={handleSaveData} user={dataGlobal?.user} tenant_id={tenant_id} />}
            {step === 3 && (
              <Step3
                data={dataGlobal?.contacts || []}
                juridictionList={juridictionList}
                listContactType={listContactType}
                onSubmit={handleSaveData}
              />
            )}
            {step === 4 && <Step4 data={dataGlobal?.client || {}} onSubmit={handleSaveData} />}
          </div>
        )}
      </Row>
    </SpinBox>
  );
};

export default LoginClient;
