import React from 'react';

// Components
import InputBox from 'components/InputBox';

// Utils
import { getTypeCard } from 'components/Modal/ModalAddCardPayInvoices/typeCreditCard';
import { validInputNumber } from 'utils/utils';

// Style
import './inputCardNumber.scss';

const InputCardNumber = ({ cardNumber, onChange }) => {
  const IconCard = getTypeCard({ cardNumber }).Icon;

  return (
    <div className="inputCardNumber">
      <IconCard />
      <InputBox
        label="Card Number"
        value={cardNumber}
        onChange={value => onChange(validInputNumber({ value: value, max: 16 }))}
        placeholder="0000 0000 0000 0000"
      />
    </div>
  );
};

export default InputCardNumber;
