const htmlRegex = new RegExp('<[^>]*>|\&nbsp;', 'g');
const puntutationRegex = new RegExp("[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]", 'g');
const extraSpaceRegex = /\s+/g;
const puntSpaceregex = new RegExp(puntutationRegex.source + '|' + extraSpaceRegex.source, 'g')
const spanTag = (cls, token) => `<span class='${cls}'>${token}</span>`;


/**
 * item contains [keyName, nurseVersion, qaVersions, changes]
 * @param {string} nurseVersion 
 * @param {string} qaVersion 
 * @returns {string}
 */
export const compareText = (nurseVersion = '', qaVersion = '') => {
    if (!nurseVersion || !qaVersion) return spanTag(qaVersion ? 'new' : 'del', qaVersion);
    // Tokenization based on space only
    var qaTokens = `${qaVersion}`.replace(htmlRegex, ' ').replace(extraSpaceRegex, ' ').trim().split(' ');
    var nurseTokens = `${nurseVersion}`.replace(htmlRegex, ' ').replace(extraSpaceRegex, ' ').trim().split(' ');
    // remove empty strings
    qaTokens = qaTokens.filter((token) => token != "");
    nurseTokens = nurseTokens.filter((token) => token != "");

    const length = Math.max(nurseTokens.length, qaTokens.length);

    var myOutputHTML = ` ${qaVersion} `;
    var maxIndex = myOutputHTML.indexOf(qaTokens[0]);
    var ntokens = [...nurseTokens];
    var qtokens = [...qaTokens];
    for (let i = 0; i < length; i++) {
        var token = nurseTokens[i];
        var newTag, token;

        if (token && !qtokens.includes(token)) {
            let newTag = spanTag('del', token);
            myOutputHTML = myOutputHTML.slice(0, maxIndex) + " " + newTag + " " + myOutputHTML.slice(maxIndex);
            maxIndex += newTag.length + 1;
        }
        token = qaTokens[i];
        if (token) {
            do {
                maxIndex = myOutputHTML.slice(maxIndex).indexOf(token) + maxIndex;
                var word = myOutputHTML.slice(maxIndex - 1, maxIndex + token.length + 1);
            } while ((word.match(puntSpaceregex) || []).length < (maxIndex && maxIndex < 2)); // prevents matching a substring
            newTag = spanTag(ntokens.includes(token) ? token === nurseTokens[i] ? 'normal' : 'shifted' : 'new', token);
            if (ntokens.includes(token)) {
                let tokenIndex = ntokens.indexOf(token);
                ntokens = ntokens.slice(0, tokenIndex).concat(ntokens.slice(tokenIndex + 1)); // remove the checked token
                tokenIndex = qtokens.indexOf(token);
                qtokens = qtokens.slice(0, tokenIndex).concat(qtokens.slice(tokenIndex + 1)); // remove the checked token
            }
            myOutputHTML = myOutputHTML.slice(0, maxIndex) + myOutputHTML.slice(maxIndex).replace(token, newTag);
            maxIndex += newTag.length;
        }
    }
    return myOutputHTML.trim();
}