import React, { useState, useEffect } from 'react';

import { Select, Icon, Divider, Button, Typography } from 'antd';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Icons
import { TrashIcon } from 'components/Svg';

// Const
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { CHILD_FOLDER, PARENT_FOLDER } from 'constants/typeFolder';

const { Title, Text } = Typography;
const { Option } = Select;

const StepAddChildFolder = ({ goBackStep, onClick, dataGlobal }) => {
  // State
  const [listSubFolder, setListSubFolder] = useState([]);
  const [listClients, setListClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addAdditionalSubFolder, setAddAdditionalSubFolder] = useState(false);

  // Const
  const selectSubFolder = listSubFolder.length === 0 || addAdditionalSubFolder;

  const getAllClients = async () => {
    try {
      setLoading(true);

      if (dataGlobal.typeFolder === CHILD_FOLDER) {
        const resParent = await API.getAllParentFolder({ pageSize: 1000000 });
        setListClients(resParent.rows.map(item => ({ id: item.id, client_name: item.name })));
        return;
      }

      const res = await API.getClientWithoutFolder({ pageSize: 1000000 });
      setListClients(res.rows);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setListSubFolder(dataGlobal?.listSubFolder || []);
  }, [dataGlobal]);

  useEffect(() => {
    listClients.length === 0 ? getAllClients() : setLoading(false);
  }, []);

  // handle
  const selectedSubFolder = ({ subFolderId }) => {
    const getClientInfo = listClients.find(client => client.id === subFolderId);

    if (addAdditionalSubFolder) setAddAdditionalSubFolder(false);

    if (listSubFolder.some(subFolder => subFolder.id === getClientInfo.id)) return;

    setListSubFolder(prevState => [...prevState, getClientInfo]);
  };

  const handleRemoveSubFolder = ({ subFolderId }) => {
    const updateList = listSubFolder.filter(subFolder => subFolder.id !== subFolderId);
    setListSubFolder(updateList);
  };

  const handleSubmit = ({ action }) => {
    const data = {
      listSubFolder,
    };

    if (action === 'back') return goBackStep({ data });

    onClick({ data });
  };

  return (
    <>
      <div className="createParentFolder_addFolder">
        {selectSubFolder ? (
          <>
            <Title level={4}>
              {dataGlobal.typeFolder === CHILD_FOLDER ? 'Select Parent-folder' : 'Select Child-folder'}
            </Title>
            <p className='textCenter'>Optional</p>
            <Select
              getPopupContainer={triggerNode => triggerNode.parentNode}
              showSearch
              optionFilterProp="children"
              className="dropdown addClaimant_select"
              value=""
              onChange={id => selectedSubFolder({ subFolderId: id })}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option disabled value={''}>
                {dataGlobal.typeFolder === CHILD_FOLDER ? 'Type folder name' : 'Type sub-folder name'}
              </Option>
              {listClients.map(client => (
                <Option value={client.id} key={client.id}>
                  {client.client_name}
                </Option>
              ))}
            </Select>
          </>
        ) : (
          <div className="listCard_createParentFolder">
            {listSubFolder.map(subFolder => (
              <div key={subFolder.id} className="cardSubFolder_createParentFolder">
                <div className="cardSubFolder_createParentFolder_logo">
                  {subFolder?.client_logo && <img src={subFolder.client_logo} alt={subFolder.client_name} />}
                </div>
                <div className="cardSubFolder_createParentFolder_content">
                  <Title level={4}>{subFolder.client_name}</Title>
                  <Text>Child Folder</Text>
                </div>
                <div className="cardSubFolder_createParentFolder_btn">
                  <TrashIcon
                    color={PRIMARY_LIGHT_BLUE}
                    onClick={() => handleRemoveSubFolder({ subFolderId: subFolder.id })}
                  />
                </div>
              </div>
            ))}

            {dataGlobal.typeFolder === PARENT_FOLDER && (
              <button
                className="addAdditionalContactButton margin-button"
                onClick={() => setAddAdditionalSubFolder(true)}>
                Add Folder
              </button>
            )}
          </div>
        )}
      </div>

      <Divider />

      <div className="createParentFolder_btn createParentFolder_btn_between">
        <Button
          type="primary"
          size="large"
          className="transparent-btn button"
          onClick={() => handleSubmit({ action: 'back' })}>
          Previous Step
        </Button>
        <Button type="primary" size="large" className="green-btn button" onClick={handleSubmit}>
          {dataGlobal?.typeFolder === CHILD_FOLDER ? 'Create Folder' : 'Next Step'}
        </Button>
      </div>

      {loading && <ModalLoading />}
    </>
  );
};

export default StepAddChildFolder;
