import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

const SortableList = SortableContainer(({ items, type, deleteFile, canEdit }) => {
  return (
    <>
      {items.map((value, index) => (
        <SortableItem
          deleteFile={deleteFile}
          key={`item-${value.id}`}
          list={items}
          type={type}
          index={index}
          value={value}
          canEdit={canEdit}
        />
      ))}
    </>
  );
});

export default SortableList;
