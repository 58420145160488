import moment from 'moment';
import { INIT_SIGNATURE } from './const';
import {
  ELITE_PACKAGE,
  INDIVIDUAL_BASIC_ID,
  INDIVIDUAL_ELITE_ID,
  INDIVIDUAL_PROFESSIONAL_ID,
  MULTIPLE_BASIC_ID,
  MULTIPLE_ELITE_ID,
  MULTIPLE_PROFESSIONAL_ID,
  PROFESSIONAL_PACKAGE,
} from 'containers/Tenant/tenantCreate/components/StepPackageTier/components/PlanCard/planList';
import {
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_MCP_MSA_TYPE_ID,
  REPORT_SURGICAL_TYPE_ID,
} from 'constants/reports';
import * as API from 'utils/api';

export const getValueInsuranceCarrier = ({ value = '', reportContact, claimant }) => {
  if (!value && reportContact.filter(item => item.type === 'insurance_carrier').length > 0) {
    value = reportContact
      .filter(item => item.type === 'insurance_carrier')
      .map(item => item.contact.contact_name)
      .join(', ');
  }

  if (!value && claimant) {
    value = claimant.insurance_carrier;
  }

  return value;
};

export const getListSignature = ({ signature }) => {
  if (Array.isArray(signature) && signature.length > 0)
    return signature.map(item => ({
      ...item,
      referral_date: moment(item.referral_date, 'YYYY MM DD') || moment(),
    }));

  return [INIT_SIGNATURE];
};

export const disableFutureDay = current => {
  return current && current > moment().endOf('day');
};

export const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const validateOpenReportForPackage = async ({ report_type, tenant_id }) => {
  // old user of MSA
  const tenantInfo = await API.getTenantById({ id: tenant_id });
  const package_id = tenantInfo?.tenant?.package_id || null;

  if (package_id === null)
    return {
      open: true,
    };

  const pricingGlobal = await API.getPricingGlobal();
  const packages = pricingGlobal?.data?.packages || [];

  // move to professional
  if (
    [INDIVIDUAL_BASIC_ID, MULTIPLE_BASIC_ID].includes(package_id) &&
    [REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID, REPORT_EARLY_INTERVENTION_TYPE_ID].includes(report_type)
  )
    return {
      open: false,
      update: PROFESSIONAL_PACKAGE,
      price: packages.find(plan => plan.id === MULTIPLE_PROFESSIONAL_ID)?.price || 0,
      package_id: package_id === INDIVIDUAL_BASIC_ID ? INDIVIDUAL_PROFESSIONAL_ID : MULTIPLE_PROFESSIONAL_ID,
    };

  // move to -> elite
  if (
    [INDIVIDUAL_BASIC_ID, MULTIPLE_BASIC_ID, INDIVIDUAL_PROFESSIONAL_ID, MULTIPLE_PROFESSIONAL_ID].includes(
      package_id
    ) &&
    [REPORT_MCP_MSA_TYPE_ID, REPORT_SURGICAL_TYPE_ID].includes(report_type)
  )
    return {
      open: false,
      update: ELITE_PACKAGE,
      price: packages.find(plan => plan.id === MULTIPLE_ELITE_ID)?.price || 0,
      package_id: [INDIVIDUAL_BASIC_ID, INDIVIDUAL_PROFESSIONAL_ID].includes(package_id)
        ? INDIVIDUAL_ELITE_ID
        : MULTIPLE_ELITE_ID,
    };

  return {
    open: true,
  };
};

export const matchSourceToItem = (source, list) => {
  let found = null;
  if (!source) {
    return null;
  }
  list.forEach(el => {
    if (source.includes(el.name)) {
      found = el.id;
    }
  });
  return found;
};

export const matchIdToElement = (list, id) => {
  let found = {};
  list.forEach(el => {
    if (el.id === id) {
      found = el;
    }
  });
  return found;
};


export const handleValidExpiration = ({ value = '' }) => {
  if (!value) return true;

  if (value.length < 5) return false;

  const today = new Date();

  const expire = value.split('/');

  const date = new Date(`${expire?.[0] || ''}/01/${expire?.[1] || ''}`);

  if (date === 'Invalid Date') return true;

  if (date < today) {
    return false;
  }

  return true;
};