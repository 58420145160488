import { PRIMARY_LIGHT_GREEN, PRIMARY_RED, PRIMARY_YELLOW } from './colors';

/**
 * Calculate color for the event time icon
 * @param {string} eventTime - end time of the event
 * @returns appropriate color for the event
 */
export const calculateColor = eventTime => {
  const dateDiff = new Date(eventTime).getTime() - new Date().getTime();
  const diff = Math.ceil(dateDiff / (1000 * 3600 * 24));

  return diff > 0 ? PRIMARY_LIGHT_GREEN : diff === 0 ? PRIMARY_YELLOW : PRIMARY_RED;
};
