import React, { useState } from 'react';
import * as API from 'utils/api';
import { Row, Typography, Button, Modal, Select } from 'antd';
import { notifyApiError, notifySuccess } from '../../utils/notification';
import './ReportCompliteModal.scss';

const { Text } = Typography;
const { Option } = Select;

export default function ReportCompliteModal({ isOpen, title, onCancel, contacts, report_id, onSearch, loadingSearch }) {
  const [email, setEmail] = useState('');
  const handleChange = value => {
    setEmail(value);
  };
  const onConfirm = async () => {
    try {
      await API.sendEmailForReportReady(email, report_id);
      notifySuccess('Emails sent successfully');
      onCancel();
    } catch (e) {
      notifyApiError(e);
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      centered={true}
      closable={false}
      width={400}
      className="report-complete-modal"
      title={null}>
      <Row type="flex" align="middle" className="delete-body">
        <Row>
          <Text className="label">{title}</Text>
        </Row>
        <Row className="content-info">
          <Select
            style={{ width: '100%' }}
            mode="tags"
            onChange={handleChange}
            placeholder={title}
            showSearch
            onSearch={search => onSearch({ search })}
            loading={loadingSearch}
            optionFilterProp="children">
            {contacts &&
              contacts.length > 0 &&
              contacts
                .filter(contact => contact.email && contact.email.length > 0)
                .map((contact, key) => {
                  return (
                    <Option value={contact.email} key={`${key}-${contact.id}`}>
                      {contact.contact_name} ({contact.email})
                    </Option>
                  );
                })}
          </Select>
        </Row>
        <Row>
          <Button type="primary" className="green-btn button" onClick={onConfirm}>
            Send
          </Button>
        </Row>
        <Row className="cancel-button">
          <Text onClick={onCancel}>Cancel</Text>
        </Row>
      </Row>
    </Modal>
  );
}
