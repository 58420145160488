import React, { useEffect, useState } from 'react';

// Components
import { Button, Modal, Typography } from 'antd';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from '../ModalLoading';
import { CreditCardInfo } from 'components/CreditCardInfo';
import { CustomCheckBox } from 'components/CustomCheckBox';

// Hooks
import { useCreditCard } from 'hooks';

// Style
import './modalSelectCardPay.scss';
import { FormAddCreditCard } from 'components/Form/FormAddCreditCard';

const { Title, Text } = Typography;

const MODAL_STEPS_SELECTED_CARD_PAY = {
  LIST_CARD: 'listCard',
  ADD_CARD: 'addCard',
};

const ModalSelectCardPay = ({ onClose, onPay }) => {
  // State
  const [selectedCard, setSelectedCard] = useState(null);
  const [step, setStep] = useState(MODAL_STEPS_SELECTED_CARD_PAY.LIST_CARD);

  // Hooks
  const { creditCardList, loadingCreditCard, publicKey, getCreditCardList, getPublicKey } = useCreditCard();

  useEffect(() => {
    (async () => {
      try {
        await getCreditCardList();
      } catch (e) {
        notifyApiError(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (creditCardList.length === 0) return;

    const defaultCard = creditCardList.find(card => card?.defaultPaymentProfile);

    if (!defaultCard) return;

    setSelectedCard(defaultCard.customerPaymentProfileId);
  }, [creditCardList]);

  const handleAfterAddCard = () => {
    try {
      getCreditCardList();
      setStep(MODAL_STEPS_SELECTED_CARD_PAY.LIST_CARD);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const handleAddCreditCard = async () => {
    try {
      await getPublicKey();
      setStep(MODAL_STEPS_SELECTED_CARD_PAY.ADD_CARD);
    } catch (e) {
      notifyApiError(e);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      title={<Title level={4}>Pay Report</Title>}>
      {loadingCreditCard && <ModalLoading />}
      <div className="modalSelectCardPay">
        <Title level={3}>Select Card</Title>
        {step === MODAL_STEPS_SELECTED_CARD_PAY.LIST_CARD && (
          <>
            {creditCardList.map(card => (
              <div className="modalSelectCardPay_selectedCard" key={card.customerPaymentProfileId}>
                <CustomCheckBox
                  optionSignature={true}
                  onChange={() => setSelectedCard(card.customerPaymentProfileId)}
                  htmlId={`credictCard_${card.customerPaymentProfileId}`}
                  value={card.customerPaymentProfileId === selectedCard}
                />
                <CreditCardInfo cardInfo={card} />
              </div>
            ))}
            <Text className="modalSelectCardPay_addCard" onClick={handleAddCreditCard}>
              Add New Card
            </Text>

            <div className="modalSelectCardPay_btn">
              <Button
                type="primary"
                disabled={!selectedCard}
                size="large"
                className="m-2 green-btn button btn-normal"
                onClick={() => onPay({ selectedCard })}>
                Pay
              </Button>
            </div>
          </>
        )}

        {step === MODAL_STEPS_SELECTED_CARD_PAY.ADD_CARD && (
          <FormAddCreditCard
            textBtn="Add"
            goBack={() => setStep(MODAL_STEPS_SELECTED_CARD_PAY.LIST_CARD)}
            afterAdd={() => handleAfterAddCard()}
            publicKey={publicKey}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalSelectCardPay;
