import React from 'react';
import { Button } from 'antd';

// Style
import './reviewRolesNewTenant.scss';

const ReviewRolesNewTenant = ({ handleSubmit, close }) => {
  return (
    <div className="stepContainer stepContainerNewTenant">
      <div className="stepTitleContainer">
        <h1 className="stepTitle">Review Roles</h1>
      </div>

      <div className="reviewRolesNewTenant">
        <ul>
          <li>
            <p>
              <strong>Super Admin (this role is given to the registrant of the multiple users packages)</strong>{' '}
              Contains all functions and permissions of the platform, and is the account holder (billing contact).
            </p>
          </li>
          <li>
            <p>
              <strong>LCP/Nurse Writer/MD Writer (this is included in all packages)</strong> Our Writer workflow allows
              for individual professionals to house, manage, and produce products for clients. Available report types
              will be dependent on selected package.
            </p>
          </li>
          <li>
            <p>
              <strong>Internal Case Management (available for the upgraded and elite packages)</strong> Our Case
              management workflows allow for individuals to manage, communicate, and request new products.
            </p>
          </li>
          <li>
            <p>
              <strong>Sales Management/Field Case Management (only available in the Elite and Custom packages)</strong>{' '}
              Our Field Case Management workflow(s) allows for complete clarity of the assigned case management users.
            </p>
          </li>
          <li>
            <p>
              <strong>External Case Management (only available in the Elite and Custom packages)</strong> Our External
              Case Management workflow allows for permission to view and work specific assigned cases
            </p>
          </li>
        </ul>
      </div>

      <div className="add_new_tenant">
        <Button type="primary" size="default" className="transparent-btn button" onClick={close}>
          PREVIOUS STEP
        </Button>

        <Button type="primary" size="default" className="stepNextButton" onClick={handleSubmit}>
          Next Step
        </Button>
      </div>
    </div>
  );
};

export default ReviewRolesNewTenant;
