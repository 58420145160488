import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Typography, Divider, Input, DatePicker } from 'antd';
import * as Logic from '../ProposedMedicare/logic';
import SpinBox from 'components/SpinBox';
import InputCurrencyBox from 'components/InputCurrencyBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MSA_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

import './AnnuityInfo.scss';

const { Title, Text } = Typography;

class AnnuityInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      medicare: [],

      report_id: report_id,
      life_expectancy: 0,
      annuity_payment_over_remaining_expectancy_at_starting_date: 0,
      annuity_start_date: moment(),
      annuity_length: 0,
      annuity_amount: 0,
      loadingText: 'Loading',
      loading: false,
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };

  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getMedicareData({ reportId: report_id }).then(res => {
      this.setState({ medicare: res.medicare_cost_projections });
    });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        const {
          life_expectancy,
          annuity_payment_over_remaining_expectancy_at_starting_date,
          annuity_start_date,
          is_template,
        } = reportInfo;

        let annuity_length = life_expectancy - moment().diff(moment(annuity_start_date), 'years');
        let annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date;
        if (annuity_length !== 0)
          annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date / annuity_length;

        this.setState({
          report: reportInfo,
          life_expectancy: life_expectancy || 0,
          annuity_payment_over_remaining_expectancy_at_starting_date:
            annuity_payment_over_remaining_expectancy_at_starting_date || 0,
          annuity_start_date: annuity_start_date ? moment(annuity_start_date) : moment(),
          annuity_length: annuity_length || 0,
          annuity_amount: Number(annuity_amount).toFixed(2) || 0,
          loading: false,
          is_template,
        });
        this.props.updateReportInfo(reportInfo);
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const { life_expectancy, annuity_payment_over_remaining_expectancy_at_starting_date, annuity_start_date } =
        reportInfo;

      let annuity_length = life_expectancy - moment().diff(moment(annuity_start_date), 'years');
      let annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date;
      if (annuity_length !== 0)
        annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date / annuity_length;

      this.setState({
        life_expectancy: life_expectancy || 0,
        annuity_payment_over_remaining_expectancy_at_starting_date:
          annuity_payment_over_remaining_expectancy_at_starting_date || 0,
        annuity_start_date: annuity_start_date ? moment(annuity_start_date) : moment(),
        annuity_length: annuity_length || 0,
        annuity_amount: Number(annuity_amount).toFixed(2) || 0,
        loading: false,
      });
    }
  }

  handleUpdateReport = async (isNextStep = false) => {
    const { report_id, annuity_start_date, annuity_amount } = this.state;

    const params = {
      id: report_id,
      annuity_start_date: moment(annuity_start_date).format('MM/DD/YYYY'),
      annuity_amount: Number(annuity_amount).toFixed(2),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleDateChange = (date, dateString) => {
    const { life_expectancy, annuity_payment_over_remaining_expectancy_at_starting_date } = this.state;

    let annuity_length = life_expectancy - moment().diff(date, 'years');
    let annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date;
    if (annuity_length !== 0)
      annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date / annuity_length;
    this.setState({
      annuity_start_date: date,
      annuity_length,
      annuity_amount: annuity_amount.toFixed(2),
    });
  };

  render() {
    const { annuity_start_date, loadingTitle, loading, REPORT_PERMISSIONS } = this.state;

    return (
      <Row className="msa-annuity-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown beforeNext={async () => await this.handleUpdateReport(false)} loading={loading} />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Annuity Information</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Annuity Start Date</Text>
                </Col>
                <Row>
                  <DatePicker
                    disabled={!REPORT_PERMISSIONS.edit}
                    className="date"
                    format="MM/DD/YYYY"
                    value={annuity_start_date}
                    onChange={this.handleDateChange}
                  />
                </Row>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Annuity Length</Text>
                </Col>
                <Col className="input-col">
                  <Input
                    placeholder={'Required'}
                    className="input"
                    disabled={true}
                    value={this.state.report ? Logic.lengthOfAnnuity(this.state.report) : 0}
                    onChange={e => this.setState({ annuity_length: e.target.value })}
                  />
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Annuity Amount</Text>
                </Col>
                <Col className="input-col">
                  <InputCurrencyBox
                    placeholder={'Required'}
                    className="input"
                    disabled={true}
                    value={
                      this.state.report
                        ? Logic.seedMoney(this.state.medicare, this.state.report).annual_annuity.toFixed(2)
                        : 0
                    }
                    onChange={value => this.setState({ annuity_amount: value })}
                  />
                </Col>
              </Row>
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    roles: state.auth.roles,
    userInfo: state.auth.userInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(AnnuityInfo);
