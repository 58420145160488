import React from 'react';
import { TrashIcon, DownloadIcon } from 'components/Svg';
import { Typography } from 'antd';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

const { Text } = Typography;

const TableFileBoxDragAndDrop = ({ listFiles, removeFile, removeAllFiles, download }) => {
  return (
    <>
      <div className="tableFileBoxDragAndDrop">
        <div className="tableFileBoxDragAndDrop_header tableFileBoxDragAndDrop_item newUploadFile_grid2">
          <Text>Name</Text>
          <Text className="tableFileBoxDragAndDrop_btnEnd">Actions</Text>
        </div>
        <div className="tableFileBoxDragAndDrop_body">
          {listFiles.map(file => (
            <div key={file.id || file.name} className="tableFileBoxDragAndDrop_item newUploadFile_grid2">
              <Text>{file.name}</Text>
              <Text className="tableFileBoxDragAndDrop_btnEnd">
                <button className="newUploadFile_btnTrashRemove" onClick={() => removeFile({ fileName: file.name, file })}>
                  <TrashIcon color={PRIMARY_LIGHT_BLUE} />
                </button>

                {download && (
                  <div className="newUploadFile_btnTrashRemove" onClick={() => download({ file })}>
                    <DownloadIcon />
                  </div>
                )}
              </Text>
            </div>
          ))}
        </div>
      </div>
      
      {removeAllFiles && (
        <div className="newUploadFiles_downloadAll newUploadFile_mTop newUploadFile_pRight">
          <button className="newUploadFile_btnTrashRemove" onClick={removeAllFiles}>
            Remove All
            <TrashIcon color={PRIMARY_LIGHT_BLUE} />
          </button>
        </div>
      )}
    </>
  );
};

export default TableFileBoxDragAndDrop;
