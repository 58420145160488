import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Divider, Modal, Checkbox } from 'antd';
import { connect } from 'react-redux';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import { formatCurrency } from 'utils/utils';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import { updateReportInfo } from '../../../../redux/report';
import AddAssignForm from '../../../Calendar/src/src/containers/base/AddAssignForm';
import './NonMedicareTotals.scss';
import { notifyApiError, notifyInfo } from 'utils/notification';
import isAllowed, { isAttorney, ROLES_DIVISIONS } from 'layout/roles';
import { BtnGenerateReport } from 'containers/Reports/components/BtnGenerateReport';
import { returnToSenderReport } from 'containers/Reports/utils/returnToSender';
import { ModalReturnToSender } from 'containers/Reports/components/ModalReturnToSender';
import {
  ASSIGNMENT_TYPE_QA_INTERNAL,
  ASSIGNMENT_TYPE_LEGAL_NURSE,
  ASSIGNMENT_TYPE_MEDICAL_REVIEWER,
} from 'constants/assignment-types';
import { WaterMarkCheck } from 'components/WaterMarkCheck';
import { STATUS_IN_SUPERVISOR_REVIEW, STATUS_GENERATED } from 'constants/report_status';
import { CLAIMANT_INFO_NAV, MAIN_NAV } from 'layout/navigate_const';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { REPORT_MCP_MSA_COMBO_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { existsAssigment } from 'utils/assigment';

const { Title, Text } = Typography;

class NonMedicareTotals extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      medicareInfo: {},
      loading: false,
      is_template: false,
      showNewAssignForm: false,
      show_additional_comments: false,
      modalReturnToSender: false,
      assignmentReturn: '',
      assignWriter: false,
      editedparams: {},
      is_watermark: false,
      selectPdf: '',
      REPORT_PERMISSIONS,
    };

    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.openNewAssignForm = this.openNewAssignForm.bind(this);
    this.handleReturnToSender = this.handleReturnToSender.bind(this);
    this.handleUpdateWaterMark = this.handleUpdateWaterMark.bind(this);
    this.handlePreviewReport = this.handlePreviewReport.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;
    API.getReportById(report_id)
      .then(async reportInfo => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        this.setState({
          is_template: reportInfo.is_template,
          show_additional_comments: reportInfo.show_additional_comments,
          claimant: reportInfo.claimant,
          reportInfo,
          is_watermark: reportInfo.is_watermark,
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
    this.setState({ loading: true });
    API.getNonMedicareInfoData(report_id).then(res => {
      this.setState({
        medicareInfo: res.totals,
        loading: false,
      });
    });
  }

  async handleGenerateReport() {
    const { report_id } = this.state;

    this.setState({
      loading: true,
      loadingTitle: 'Generating...',
    });

    if (isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER)) {
      await API.updateReportNurse({ id: report_id, report_status_id: STATUS_GENERATED.id });
    }

    API.downloadReport(report_id)
      .then(res => {
        this.setState({ loading: false });
        Modal.success({
          content: 'Report is generated',
          onOk: () => {
            window.open(res.report_url);
          },
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        Modal.error({
          content: 'Error',
        });
      });
  }

  async onSubmit(assignment) {
    this.setState({
      loading: true,
      loadingText: 'pending...',
    });
    try {
      const hasAssigment = Boolean(assignment.id);

      if (!hasAssigment) {
        const res = await API.createAssignementUser(assignment);

        if (res?.assignment?.assignment_type_id === ASSIGNMENT_TYPE_LEGAL_NURSE) {
          await API.updateReportNurse({
            id: res.assignment.report_id,
            report_status_id: STATUS_IN_SUPERVISOR_REVIEW.id,
          });
        }

        notifyInfo('Assignment created');
      } else {
        let newEdit = { ...assignment };
        delete newEdit.id;
        delete newEdit.status;

        newEdit.start_date = moment(newEdit.start_date).format('YYYY-MM-DD');
        newEdit.end_date = moment(newEdit.end_date).format('YYYY-MM-DD');

        const isUserAdmin = isAllowed(this.props.roles, [
          ...ROLES_DIVISIONS.SUPERADMIN,
          ...ROLES_DIVISIONS.SALES_MANAGER,
        ]);
        const isUserQA = isAllowed(this.props.roles, ROLES_DIVISIONS.QA);

        const params = isUserQA && !isUserAdmin ? { ...newEdit, tenantOnly: true } : newEdit;

        await API.UpdateAssignement(assignment.id, params);
        notifyInfo('Assignment updated');
      }

      this.setState({ loading: false, showNewAssignForm: false, assignWriter: false });

      if (isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_ONLY)) {
        this.props.history.push(`${CLAIMANT_INFO_NAV.path}/${this.state.claimant.id}`);
      }
      return true;
    } catch (e) {
      notifyApiError(e);
      this.setState({ loading: false, showNewAssignForm: false, assignWriter: false });
      return false;
    }
  }

  async openNewAssignForm() {
    try {
      this.setState({ loading: true });
      const { finalAssigned, assignWriter } = await existsAssigment({
        reportInfo: this.state.reportInfo,
        roles: this.props.roles,
      });
      this.setState({ showNewAssignForm: true, editedparams: finalAssigned, assignWriter });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleReturnToSender() {
    try {
      this.setState({ loading: true });
      const { report_id, claimant } = this.state;
      const claimant_id = claimant.id;

      const res = await returnToSenderReport({ report_id, claimant_id });

      if (res?.assignment?.id) {
        this.setState({
          modalReturnToSender: true,
          assignmentReturn: `${res.assignment.user.given_name} ${res.assignment?.user?.family_name || ''}`,
        });
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleUpdateWaterMark(value) {
    try {
      const checked = value.target.checked;

      this.setState({
        loading: true,
        loadingTitle: 'Saving...  ',
        is_watermark: checked,
      });

      const { report_id } = this.state;
      const params = {
        id: report_id,
        is_watermark: checked,
      };

      await API.updateReport(params);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handlePreviewReport() {
    try {
      this.setState({ loading: true });
      const res = await API.getReviewReportCompare({ idReport: this.state.report_id });
      if (res?.report_url) this.setState({ selectPdf: res.report_url });
    } catch (err) {
      notifyApiError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      medicareInfo,
      loading,
      showNewAssignForm,
      report_id,
      show_additional_comments,
      modalReturnToSender,
      assignmentReturn,
      assignWriter,
      editedparams,
      is_watermark,
      selectPdf,
      REPORT_PERMISSIONS,
    } = this.state;

    return (
      <>
        <Row className="msa-non-medical-totals-container">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Setup {REPORT_MCP_MSA_COMBO_NAME} {this.state.is_template ? 'Template' : 'Report'}
              </Title>
            </Col>
            <Col className="step">
              <DropDown loading={loading} />
            </Col>
          </Row>
          <Row className="main">
            <SpinBox loading={loading}>
              <Row className="content">
                <Row type="flex" justify="space-between" align="middle" className="row">
                  <Col md={24}>
                    <Text className="title">Totals and Allocations (Non Medicare)</Text>
                  </Col>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Annual Cost Total</Text>
                  </Col>
                  <Text className="text">{formatCurrency(medicareInfo.annual_cost_total || 0)}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text className="label">Recommended Medical Allocation</Text>
                  </Col>
                  <Text className="text">{formatCurrency(medicareInfo.recommended_medical_allocation || 0)}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text className="label">Surgeries, Replacements and Procedures Allocation</Text>
                  </Col>
                  <Text className="text">
                    {formatCurrency(medicareInfo.surgeries_replacements_procedures_allocation || 0)}
                  </Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text className="label">Recommended Prescription Allocation</Text>
                  </Col>
                  <Text className="text">{formatCurrency(medicareInfo.prescription_allocation || 0)}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text className="label">Recommended Allocation</Text>
                  </Col>
                  <Text className="text">
                    {formatCurrency(
                      medicareInfo.prescription_allocation +
                        medicareInfo.recommended_medical_allocation +
                        medicareInfo.surgeries_replacements_procedures_allocation
                    )}
                  </Text>
                </Row>
                {REPORT_PERMISSIONS.edit && (
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col></Col>
                    <Col>
                      <Checkbox
                        checked={show_additional_comments}
                        onChange={() => {
                          this.setState({
                            show_additional_comments: !show_additional_comments,
                          });
                          API.updateReport({ id: report_id, show_additional_comments: !show_additional_comments });
                        }}>
                        Show comment sections
                        <br />
                        on the PDF
                      </Checkbox>
                    </Col>
                  </Row>
                )}

                <Divider className="divider" />

                {REPORT_PERMISSIONS.edit && (
                  <WaterMarkCheck customClass="mb-3" checked={is_watermark} onChange={this.handleUpdateWaterMark} />
                )}

                <PrevNextStep />

                {!isAttorney(this.props.roles) && (
                  <BtnGenerateReport
                    handleGenerateReport={REPORT_PERMISSIONS.edit && !this.state.is_template && this.handleGenerateReport}
                    openModal={REPORT_PERMISSIONS.assign && !this.state.is_template && this.openNewAssignForm}
                    roles={this.props.roles}
                    customClass="mt-3"
                    oneBtn={isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY)}
                    returnToSender={this.handleReturnToSender}
                    previewReport={this.handlePreviewReport}
                  />
                )}
              </Row>
            </SpinBox>
          </Row>
        </Row>

        {modalReturnToSender && (
          <ModalReturnToSender
            onClose={() => this.props.history.push('/app/Reviews')}
            returnName={assignmentReturn || ''}
          />
        )}

        {showNewAssignForm && (
          <Modal
            width={700}
            maskClosable={false}
            bodyStyle={{ padding: 0, minWidth: 450 }}
            visible={showNewAssignForm}
            onCancel={() => this.setState({ showNewAssignForm: false })}
            keyboard={false}
            footer={null}>
            <AddAssignForm
              onSubmit={this.onSubmit}
              edit={editedparams}
              disabledField={{
                client: true,
                claimant: true,
                report: true,
              }}
              onlyAssign={
                assignWriter && [
                  ASSIGNMENT_TYPE_QA_INTERNAL,
                  ASSIGNMENT_TYPE_LEGAL_NURSE,
                  ASSIGNMENT_TYPE_MEDICAL_REVIEWER,
                ]
              }
            />
          </Modal>
        )}

        {selectPdf && <ModalPdf path={selectPdf} close={() => this.setState({ selectPdf: '' })} />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, { updateReportInfo })(NonMedicareTotals);
