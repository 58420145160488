import * as React from 'react'

const EditSvgIcon = ({ color, width, height } ) => (
    <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 18 18">
    <defs>
        <path id="prefix__a" d="M0 0H18V18H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a"/>
        </mask>
        <path stroke={color} strokeWidth="1.2" d="M16.151 5.444L12.556 1.85 1.6 12.804V16.4h3.596L16.15 5.444zM2.058 12.467c1.3 1.272 2.47 2.448 3.51 3.528M9.896 4.669l3.488 3.497" mask="url(#prefix__b)"/>
    </g>
</svg>
)

export default EditSvgIcon