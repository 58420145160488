import React, { Component } from "react";
import { Row, Col, Button, Typography, Divider } from "antd";
import "./Terms.scss";

const { Title, Text } = Typography;

export default class Tos extends Component {
  render() {
    return (
      <Row style={{ backgroundColor: "#000" }}>
        <Row className="terms-container">
          <Row type="flex" justify="space-between">
            <Col
              className="logo"
              onClick={() => this.props.history.push("/")}
            />
            <Col>
              <Button type="link" size="large" className="button" href="/login">
                Sign In
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} md={20}>
              <Row type="flex" justify="center" className="content">
                <Row>
                  <Title level={1} className="title">
                    Terms and Conditions
                  </Title>
                </Row>
                <Row>
                  <Title level={3} className="sub-title">
                    Cras quis nulla
                  </Title>
                  <Text>
                    Nam dapibus nisl vitae elit fringilla rutrum. Aenean
                    sollicitudin, erat a elementum rutrum, neque sem pretium
                    metus, quis mollis nisl nunc et massa. Vestibulum sed metus
                    in lorem tristique ullamcorper id vitae erat. Nulla mollis
                    sapien sollicitudin lacinia lacinia. Vivamus facilisis dolor
                    et massa placerat, at vestibulum nisl egestas. Nullam
                    rhoncus lacus non odio luctus, eu condimentum mauris
                    ultrices. Praesent blandit, augue a posuere aliquam, arcu
                    tortor feugiat tu.
                  </Text>
                </Row>
                <Row>
                  <Title level={3} className="sub-title">
                    Cras quis nulla commodo, aliquam lectus
                  </Title>
                  <Text>
                    Cras quis nulla commodo, aliquam lectus sed, blandit augue.
                    Cras ullamcorper bibendum bibendum. Duis tincidunt urna non
                    pretium porta. Nam condimentum vitae ligula vel ornare.
                    Phasellus at semper turpis. Nunc eu tellus tortor. Etiam at
                    condimentum nisl, vitae sagittis orci. Donec id dignissim
                    nunc. Donec elit ante, eleifend a dolor et, venenatis
                    facilisis dolor. In feugiat orci odio, sed lacinia sem
                    elementum quis. Aliquam consectetur, eros et vulputate
                    euismod, nunc leo tempor lacus, ac rhoncus neque eros nec
                    lacus. Cras lobortis molestie faucibus.Donec facilisis
                    tortor ut augue lacinia, at viverra est semper. Sed sapien
                    metus, scelerisque nec pharetra id, tempor a tortor.
                    Pellentesque non dignissim neque. Ut porta viverra est, ut
                    dignissim elit elementum ut. Nunc vel rhoncus nibh, ut
                    tincidunt turpis. Integer ac enim pellentesque, adipiscing
                    metus id, pharetra odio. Donec bibendum nunc sit amet tortor
                    scelerisque luctus et sit amet mauris. Suspendisse felis
                    sem, condimentum ullamcorper est sit amet, molestie mollis
                    nulla. Etiam lorem orci, consequat ac magna quis, facilisis
                    vehicula neque.Nam porttitor blandit accumsan. Ut vel dictum
                    sem, a pretium dui. In malesuada enim in dolor euismod, id
                    commodo mi consectetur..
                  </Text>
                </Row>
                <Row>
                  <Title level={3} className="sub-title">
                    Nam porttitor blandit.
                  </Title>
                  <Text>
                    Nam porttitor blandit accumsan. Ut vel dictum sem, a pretium
                    dui. In malesuada enim in dolor euismod, id commodo mi
                    consectetur. Curabitur at vestibulum nisi. Nullam vehicula
                    nisi velit. Mauris turpis nisl, molestie ut ipsum et,
                    suscipit vehicula odio. Vestibulum interdum vestibulum felis
                    ac molestie. Praesent aliquet quam et libero dictum, vitae
                    dignissim leo eleifend. In in turpis turpis. Quisque justo
                    turpis, vestibulum non enim nec, tempor mollis mi. Sed vel
                    tristique quam.Mauris non tempor quam, et lacinia sapien.
                    Mauris accumsan eros eget libero posuere vulputate.
                  </Text>
                </Row>
                <Row>
                  <Title level={3} className="sub-title">
                    Cras quis nulla
                  </Title>
                  <Text>
                    Nam dapibus nisl vitae elit fringilla rutrum. Aenean
                    sollicitudin, erat a elementum rutrum, neque sem pretium
                    metus, quis mollis nisl nunc et massa. Vestibulum sed metus
                    in lorem tristique ullamcorper id vitae erat. Nulla mollis
                    sapien sollicitudin lacinia lacinia. Vivamus facilisis dolor
                    et massa placerat, at vestibulum nisl egestas. Nullam
                    rhoncus lacus non odio luctus, eu condimentum mauris
                    ultrices. Praesent blandit, augue a posuere aliquam, arcu
                    tortor feugiat tu.
                  </Text>
                </Row>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row type="flex" justify="space-between">
            <Col>
              <Text>Copyright @ 2024 Wrkfloz, Inc.</Text>
            </Col>
            <Col>
              <Text className="link">Terms and conditions</Text>
              <Text>Privacy Policy</Text>
            </Col>
          </Row>
        </Row>
      </Row>
    );
  }
}
