import React from 'react';

// Components
import { Typography } from 'antd';

// Style
import './header.scss';

const { Title } = Typography;

const Header = ({ left, right, children }) => {
  return (
    <div className="headerComponent">
      {left && left}
      <Title level={4} className="title-text">
        {children}
      </Title>
      {right && right}
    </div>
  );
};

export default Header;
