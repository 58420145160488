import * as React from 'react'

const DeleteSvgIcon = ({ color, width, height } ) => (
    <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 18 18">
        <defs>
            <path id="prefix__a" d="M0 0H18V18H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="prefix__b" fill="#fff">
                <use xlinkHref="#prefix__a"/>
            </mask>
            <path stroke={ color } strokeWidth="1.2" d="M3.6 4.6V15c0 .773.627 1.4 1.4 1.4h8c.773 0 1.4-.627 1.4-1.4V4.6H3.6z" mask="url(#prefix__b)"/>
            <path stroke={ color } strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M1.5 4.5h15M7.5 8.5v4M10.5 8.5v4" mask="url(#prefix__b)"/>
            <path stroke={ color } strokeWidth="1.2" d="M5.5 4v-.5c0-1.105.895-2 2-2h3c1.105 0 2 .895 2 2V4" mask="url(#prefix__b)"/>
        </g>
    </svg>

)

export default DeleteSvgIcon