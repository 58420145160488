import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Notification } from 'msa-components';
import { notifyApiError } from 'utils/notification';
import { Empty, Modal, Spin } from 'antd';
import { InView } from 'react-intersection-observer';

import { fetchNotificationsClaimant } from 'utils/api-notifications';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

// Style
import './TabNotification.scss';

const LIMIT_PAGE = 10;

const TabNotifications = ({ claimant }) => {
  const { roles } = useSelector(state => state.auth);

  const [listNotification, setListNotification] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [searchMore, setSearchMore] = useState(true);

  const getNotification = async ({ page }) => {
    try {
      setLoading(true);
      const data = await fetchNotificationsClaimant({
        idClaimant: claimant.id,
        offset: LIMIT_PAGE * page,
        limit: LIMIT_PAGE,
      });

      if (data?.rows && data.rows.length < 10) {
        setSearchMore(false);
      }

      if (data?.rows && data.rows.length > 0) {
        setListNotification(prevState => [...prevState, ...data.rows]);
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const getNotificationPagination = ({ inView }) => {
    if (!inView) return;

    const currentPage = page;
    setPage(prevState => prevState + 1);
    getNotification({ page: currentPage });
  };

  useEffect(() => {
    getNotificationPagination({ inView: true });
  }, []);

  return (
    <>
      <div className="tabNotification">
        {loading && (
          <Modal visible={loading} footer={null} className="modal-loader">
            <Spin />
          </Modal>
        )}

        {listNotification.length > 0 ? (
          <>
            {listNotification.map(notification => (
              <Notification
                key={notification.id}
                item={notification}
                isAdmin={isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER])}
                isClient={isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT)}
              />
            ))}
          </>
        ) : (
          <Empty />
        )}
      </div>

      {listNotification.length > 0 && searchMore && (
        <InView
          onChange={inView => {
            getNotificationPagination({ inView });
          }}
        />
      )}
    </>
  );
};

export default TabNotifications;
