import React from 'react';

const CardAssignSetting = ({ name, type, email, phone }) => (
  <div className="assignSalesRep">
    <div className="assignSalesRep_card">
      <h2>{name || ''}</h2>

      <div className="assignSalesRep_card_type">
        <p className="assignSalesRep_card_label">Contact Type</p>
        <p className="assignSalesRep_card_value">{type || ''}</p>
      </div>

      <div className="assignSalesRep_card_data">
        <div className="assignSalesRep_card_type">
          <p className="assignSalesRep_card_label">Email</p>
          <p className="assignSalesRep_card_value">{email || ''}</p>
        </div>
        <div className="assignSalesRep_card_type">
          <p className="assignSalesRep_card_label">Phone</p>
          <p className="assignSalesRep_card_value">{phone || ''}</p>
        </div>
      </div>
    </div>
  </div>
);

export default CardAssignSetting;
