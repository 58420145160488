import React from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';

import { TrashIcon } from 'components/Icons';

export default function DeleteModal({ isOpen, onCancel, DeleteResourceLink, deleteData }) {
  const { Text } = Typography;

  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      centered={true}
      closable={false}
      width={400}
      className="delete-modal"
      title={null}>
      <Row type="flex" align="middle" className="delete-body">
        <Row className="trash-icon">
          <TrashIcon />
        </Row>
        <Row>
          <Text className="label">Delete this link?</Text>
        </Row>
        <Row type="flex" align="middle" className="user-info">
          <Col className="user-name">
            <Text ellipsis style={{ color: 'black' }}>
              {deleteData.name}
            </Text>
            <Row>
              <Text ellipsis className="text-row">
                {deleteData.description}
              </Text>
            </Row>
          </Col>
        </Row>
        <Row>
          <Button type="primary" className="pink-btn button" onClick={() => DeleteResourceLink(deleteData.id)}>
            Delete
          </Button>
        </Row>
        <Row className="cancel-button"></Row>
      </Row>
    </Modal>
  );
}
