import React, { useState } from 'react';
import { Row, Col, Typography, Menu, Dropdown, Icon, Modal, Spin } from 'antd';
import SpinBox from 'components/SpinBox';
import moment from 'moment';
import { DetailsIcon } from 'components/Icons';
import { REPORT_MSA_LETTER_TYPE_ID, REPORT_MSA_REDUCTION_TYPE_ID, REPORT_MSA_TYPE_ID } from 'constants/reports';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';

const { Text } = Typography;

const TableRerportAttorney = ({ loading, reportInfo, handleGotoReportEdit }) => {
  const [previewReport, setPreviewReport] = useState('');
  const [loadingInternal, setLoadingInternal] = useState(false);

  const menu = ({ type_report }) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => handleGotoReportEdit({ type_report })} className="item-menu">
          <DetailsIcon className="icon" />
          View Report
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => handlePreview({ report_type_id: type_report })} className="item-menu">
          <DetailsIcon className="icon" />
          Preview Report
        </a>
      </Menu.Item>
    </Menu>
  );

  const handlePreview = async ({ report_type_id }) => {
    try {
      setLoadingInternal(true);
      const reportReview = await API.getReviewReportCompare({ idReport: reportInfo.id, report_type_id });

      if (!reportReview?.report_url) return;

      setPreviewReport(reportReview.report_url);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingInternal(false);
    }
  };

  return (
    <>
      <Modal visible={loadingInternal} footer={null} className="modal-loader">
        <Spin />
      </Modal>

      <Row className="reports-list-container">
        <Row type="flex" className="table-header table-header__reports">
          <Col md={10}>Type of report</Col>
          <Col md={8}>Last Update</Col>
          <Col md={6} style={{ textAlign: 'right' }}>
            Actions
          </Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading}>
            <Row>
              {reportInfo && (
                <>
                  <Row className="record">
                    <Col md={10}>
                      <Text>MSA Letter</Text>
                    </Col>
                    <Col md={8}>
                      <Text>{moment(reportInfo?.updatedAt || '').format('MM/DD/YYYY')}</Text>
                    </Col>
                    <Col md={6} className="actions">
                      <Dropdown
                        overlay={menu({ type_report: REPORT_MSA_LETTER_TYPE_ID })}
                        placement="bottomRight"
                        trigger={['click']}>
                        <Icon type="ellipsis" className="dots-icon" />
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row className="record">
                    <Col md={10}>
                      <Text>MSA Worksheet</Text>
                    </Col>
                    <Col md={8}>
                      <Text>{moment(reportInfo?.updatedAt || '').format('MM/DD/YYYY')}</Text>
                    </Col>

                    <Col md={6} className="actions">
                      <Dropdown
                        overlay={menu({ type_report: REPORT_MSA_REDUCTION_TYPE_ID })}
                        placement="bottomRight"
                        trigger={['click']}>
                        <Icon type="ellipsis" className="dots-icon" />
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row className="record">
                    <Col md={10}>
                      <Text>MSA Report</Text>
                    </Col>
                    <Col md={8}>
                      <Text>{moment(reportInfo?.updatedAt || '').format('MM/DD/YYYY')}</Text>
                    </Col>
                    <Col md={6} className="actions">
                      <Dropdown
                        overlay={menu({ type_report: REPORT_MSA_TYPE_ID })}
                        placement="bottomRight"
                        trigger={['click']}>
                        <Icon type="ellipsis" className="dots-icon" />
                      </Dropdown>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
          </SpinBox>
        </Row>
      </Row>

      {previewReport && <ModalPdf path={previewReport} close={() => setPreviewReport('')} />}
    </>
  );
};
export default TableRerportAttorney;
