import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as API from 'utils/api';
import moment from 'moment';
import { useSelector } from 'react-redux';
import isAllowed, { ROLES_DIVISIONS, SALES_MANAGER_ID } from 'layout/roles';

import { Row, Col, Typography, Input, Button, Avatar, Empty } from 'antd';
import Pagination from 'components/Pagination';
import DeleteModal from 'components/DeleteModal';
import ModalSharedRegister from 'components/Modal/ModalSharedRegister';
import SpinBox from 'components/SpinBox';
import { PARENT_FOLDER_NAME, CLIENT_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';
import { ModalAssignUser } from 'components/Modal/ModalAssignUser';

// Utils
import { notifyApiError, notifyInfo } from 'utils/notification';

// Icons
import { UserIcon, PencilIcon, TrashIcon } from 'components/Svg';

// Routes
import {
  ATTORNEY_CLAIMANT_NAV,
  CLIENTS_NAV,
  PARENT_FOLDER_CREATE_NAV,
  PARENT_FOLDER_EDIT_NAV,
} from 'layout/navigate_const';

const { Title, Text } = Typography;
const { Search } = Input;

const ParentFolderPage = () => {
  // Const
  const history = useHistory();
  const { userInfo, roles } = useSelector(state => state.auth);

  // Permissions
  const { PARENT_FOLDER_PERMISSIONS, CLIENT_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [PARENT_FOLDER_NAME, CLIENT_PERMISSIONS_NAME],
  });
  // end permissions

  // State
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalSharedRegister, setShowModalSharedRegister] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [assignSalesManager, setAssignSalesManager] = useState(null);
  const [listSalesManager, setListSalesManager] = useState([]);

  useEffect(() => {
    handlerSearch();
  }, []);

  // Handlers
  const handlerSearch = async ({ search = '', page = 0 } = {}) => {
    try {
      setLoading(true);
      setPage(page);

      const res = await API.getAllParentFolder({ searchQuery: search, page, model: 'child_client' });
      setClientsData(res.rows);
      setTotalCount(res.count);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModalDelete = ({ folder }) => {
    setShowModalDelete(true);
    setSelectedFolder(folder);
  };

  const closeModalDelete = () => {
    setShowModalDelete(false);
    setSelectedFolder({});
  };

  const handleOpenModalShareRegister = () => {
    setShowModalSharedRegister(true);
  };

  const closeModalShareRegister = () => {
    setShowModalSharedRegister(false);
  };


  const handleDeleteFolder = async () => {
    try {
      setLoading(true);
      closeModalDelete();

      const deleteId = selectedFolder.id;

      if (selectedFolder.isFolder) {
        await API.deleteParentFolder({ id: deleteId });
        notifyInfo('Parent Folder was deleted');
      } else {
        await API.deleteClient({ id: deleteId });
        notifyInfo('Child Folder was deleted');
      }

      await handlerSearch();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async ({ item }) => {
    if (item.isFolder) return history.push(PARENT_FOLDER_EDIT_NAV({ idFolder: item.id }).path);

    history.push('/app/clients/edit', { data: item });
  };

  const goToOpen = ({ item }) => {
    if (item.isFolder) return history.push(`${CLIENTS_NAV.path}/${item.id}`);

    const path = isAllowed(roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? ATTORNEY_CLAIMANT_NAV.path : '/app/claimants';
    history.push(path, { data: item });
  };

  const handleOpenModalAssignSalesManager = async ({ item }) => {
    try {
      setLoading(true);
      if (listSalesManager.length === 0) {
        const salesManager = await API.getUsersByRoleId({ roleId: SALES_MANAGER_ID });
        setListSalesManager(salesManager.users);
      }

      setAssignSalesManager({ id: item.id, isFolder: item.isFolder });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAssigUserToClient = async ({ userId }) => {
    try {
      setLoading(true);

      const body = {
        user_id: userId,
        client_folder_id: assignSalesManager.id,
        model: assignSalesManager.isFolder ? 'parent' : 'client',
      };

      await API.assigUserToClient({ body });

      setAssignSalesManager(null);

      await handlerSearch();
      notifyInfo('The user was successfully assigned');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const permissionByFolder = ({ isFolder, action }) => {
    if (isFolder) {
      if (action === 'edit' && PARENT_FOLDER_PERMISSIONS.edit) return true;

      if (action === 'delete' && PARENT_FOLDER_PERMISSIONS.delete) return true;

      return false;
    }

    if (action === 'edit' && CLIENT_PERMISSIONS.edit) return true;
    if (action === 'delete' && CLIENT_PERMISSIONS.delete) return true;

    return false;
  };

  return (
    <Row className="clients-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            My Clients
          </Title>
        </Col>
        <Col className="search">
          <Search
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            onSearch={() => handlerSearch({ search: searchQuery, page: 0 })}
            className="search-box"
          />
        </Col>
        <Col className="pagination">
          <Pagination
            onChange={newPage => handlerSearch({ search: searchQuery, page: newPage })}
            totalCount={totalCount}
            pageIndex={page}
            pageSize={10}
          />
        </Col>
        {PARENT_FOLDER_PERMISSIONS.create && (
          <Col className="add-button">
            <Button type="primary" className="green-btn" onClick={() => history.push(PARENT_FOLDER_CREATE_NAV.path)}>
              Add New Folder
            </Button>
            {PARENT_FOLDER_PERMISSIONS.share && (
            <Button type="primary" className="purple-btn ml-2" onClick={() => handleOpenModalShareRegister()}>
              Share Registration
            </Button>
            )}
          </Col>
        )}
        
      </Row>
      <Row type="flex" className="table-header">
        <Col md={6}>Client</Col>
        <Col md={5}>Date Added</Col>
        <Col md={2}>Sub-Folders</Col>
        <Col md={2}>Claimants</Col>
        <Col md={6}>Sales Manager</Col>
        <Col md={3} style={{ textAlign: 'right', paddingRight: '30px' }}>
          Actions
        </Col>
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading}>
          {clientsData.length > 0 ? (
            <Row data-test-id="table-clients">
              {clientsData.map(item => (
                <Row key={item.id} className="record">
                  {/* Client */}
                  <Col md={6}>
                    <Col
                      className="client-info client-indo-folder"
                      data-test-id={`clientTableItem_${item.isFolder ? 'folder' : 'client'}`}
                      onClick={() => goToOpen({ item })}>
                      <div className={item.isFolder ? 'client-indo-folder-image' : ''}>
                        <Avatar size={48} src={item.logo} />
                      </div>
                      <Text className="client-name">{item.name}</Text>
                    </Col>
                  </Col>
                  {/* Created At */}
                  <Col md={5}>
                    <Text>{moment(item.createdAt).format('MMMM DD, YYYY')}</Text>
                  </Col>
                  {/* Sub Folder */}
                  <Col md={2}>
                    <Text>{item.count_clients ?? '-'}</Text>
                  </Col>
                  {/* Claimants */}
                  <Col md={2}>
                    <Text>{item.count_claimant ?? '-'}</Text>
                  </Col>
                  {/* Sales Manager */}
                  <Col md={6} className="client-indo-folder-manager">
                    {item?.sales_manager.length > 0 && (
                      <>
                        <p className="client-indo-folder-manager-name">
                          {item.sales_manager[0].given_name || ''} {item.sales_manager[0].family_name || ''}
                        </p>
                        <p className="client-indo-folder-manager-label">{item.sales_manager[0].locale || ''}</p>
                        <p className="client-indo-folder-manager-label">{item.sales_manager[0].email || ''}</p>
                      </>
                    )}
                  </Col>
                  {/* Actions */}
                  <Col md={3} className="actions content_actions">
                    {PARENT_FOLDER_PERMISSIONS.assignSalesManager && (
                      <UserIcon
                        className="all_icons_action_xs"
                        onClick={() => handleOpenModalAssignSalesManager({ item })}
                      />
                    )}

                    {permissionByFolder({ isFolder: item.isFolder, action: 'edit' }) && (
                      <PencilIcon className="all_icons_action" onClick={() => handleEdit({ item })} />
                    )}
                    {permissionByFolder({ isFolder: item.isFolder, action: 'delete' }) && (
                      <TrashIcon className="all_icons_action" onClick={() => handleOpenModalDelete({ folder: item })} />
                    )}
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Empty
              description={loading ? false : 'No Data'}
              image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
              className="empty-icon"
            />
          )}
        </SpinBox>
      </Row>
      
      {showModalSharedRegister && (
        <ModalSharedRegister
          title={'Share Link'}
          isOpen={showModalSharedRegister}
          tenantId={userInfo?.attributes["custom:tenant_id"]}
          onConfirm={closeModalShareRegister}
          onCancel={closeModalShareRegister}
        />
      )}

      {showModalDelete && (
        <DeleteModal
          isOpen={showModalDelete}
          title="Delete this Folder?"
          avatar={selectedFolder.logo}
          content={selectedFolder.name}
          onConfirm={handleDeleteFolder}
          onCancel={closeModalDelete}
        />
      )}

      {assignSalesManager && (
        <ModalAssignUser
          title="Assign Sales Manager"
          onClose={() => setAssignSalesManager(null)}
          listUser={listSalesManager}
          onAssign={handleAssigUserToClient}
        />
      )}
    </Row>
  );
};

export default ParentFolderPage;
