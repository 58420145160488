import React from 'react';
import { Select, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select;

const SelectComponent = ({ placeholder, label, value, onChange, disabled }) => {
  const options = value.replaceAll(/["'[\] ]/g, '').split(',');

  return (
    <div className='listInputsLCP_select'>
      <Text>{label}</Text>

      <Select
        getPopupContainer={triggerNode => triggerNode.parentNode}
        showSearch
        disabled={disabled}
        placeholder={placeholder}
        optionFilterProp="children"
        style={{ width: '100%' }}
        onChange={id => onChange(id)}>
        {options.map(valueOption => (
          <Option value={valueOption} key={valueOption}>
            {valueOption}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectComponent;
