import { Alert, Button, Col, Modal, Row } from "antd";
import InputBox from "components/InputBox";
import SpinBox from "components/SpinBox";
import React from "react";

export default function UpdatePricing({
  isOpen,
  onCancel,
  loading,
  handleUpdate,
  nameError,
  viewPricingName,
  onNameChange,
}) {
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Update Pricing Group"}
      >
        <Row type="flex" align="middle" className="report-body">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info pricing-group-info"
          >
            <SpinBox loading={loading}>
              <Col md={24}>
                <Row>
                  <span>Name</span>
                </Row>
                <InputBox
                  name="name"
                  value={viewPricingName.name}
                  onChange={onNameChange}
                />
                <Row>
                  {nameError && (
                    <Alert
                      type="error"
                      message={nameError}
                      banner
                      style={{ marginTop: 15 }}
                    />
                  )}
                </Row>
              </Col>
            </SpinBox>
          </Row>

          <Button
            type="primary"
            size="large"
            className="green-btn button"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Row>
      </Modal>
    </div>
  );
}
