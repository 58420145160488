import React, { useState } from 'react';
import { Modal, Typography, Spin } from 'antd';
import '../../../Reports/components/UploadFileModal/UploadFileModal.scss';
import './modalReview.scss';
import TrashIcon from 'components/Svg/TrashIcon';
import { UploadPresigned, BulkAddFilesToReport } from 'utils/upload';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';

const UploadFileModal = ({ close, confirm, reportId, claimId, only, title }) => {
  const { Text } = Typography;

  const [listFiles, setListFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAddFiles = ({ file }) => {
    const nameOld = listFiles.map(oldFile => oldFile.name);

    if (nameOld.includes(file.name)) {
      return;
    }

    setListFiles([...listFiles, file]);
  };

  const beforeUpload = file => {
    if (!file) return false;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Modal.error({
        content: 'You can only upload PDF file!',
      });
    }

    return isPdf;
  };

  const removeFile = ({ fileName }) => {
    setListFiles(listFiles.filter(file => file.name !== fileName));
  };

  const removeAllFiles = () => {
    setListFiles([]);
  };

  const handleUploadFiles = async () => {
    setLoading(true);

    if (listFiles.length > 0) {
      const uploadArrayWithPromises = listFiles.map(file => UploadPresigned(file));

      const urls = await Promise.all(uploadArrayWithPromises);

      await BulkAddFilesToReport(urls, only[0], claimId, reportId);
    }

    setLoading(false);
    confirm && confirm();
  };

  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      className="report-modal"
      title={title}>
      <div className="modalUploadFile">
        {/* LOADER */}
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>
        {/* END LOADER */}

        <BoxDragAndDrop multiple beforeUpload={beforeUpload} handleAddFiles={handleAddFiles} />

        {listFiles.length > 0 && (
          <TableFileBoxDragAndDrop listFiles={listFiles} removeFile={removeFile} removeAllFiles={removeAllFiles} />
        )}

        <div className="modalReviewReport_btns">
          <button className="btn_transparent_secundary" onClick={close}>
            Cancel
          </button>
          <button className="btn_primary" onClick={handleUploadFiles}>
            Request Revision
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
