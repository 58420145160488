import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Input, Button, Modal, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { EditIcon, RemoveIcon } from 'components/Icons';
import { ModalManagerHelp } from './components/ModalManagerHelp';
import { ModalMaterialsHelp } from './components/ModalMaterialsHelp';
import * as API from 'utils/api';
import { orderMarketingSection } from 'containers/Marketing/utils/orderMarketingSection';
import { useSelector } from 'react-redux';
import { ShowTutorial } from './components/ShowTutorial';
import { getPermissionsBySection, HELP_TUTORIALS_PERMISSIONS_NAME } from 'permissions';

import 'containers/Marketing/marketing.scss';
import { notifyApiError } from 'utils/notification';

const { Title, Text } = Typography;
const { Search } = Input;

const HelpNew = () => {
  const { roles } = useSelector(state => state.auth);
  const [selectPdf, setSelectTutorial] = useState(null);
  const [listDocumentDefault, setListDocumentDefault] = useState([]);
  const [listDocument, setListDocument] = useState([]);
  const [modalManager, setModalManager] = useState(false);
  const [modalMaterial, setModalMaterial] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [listSection, setListSection] = useState([]);
  const [materialEdit, setMaterialEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  // Permissions
  const { HELP_TUTORIALS_PERMISSIONS } = getPermissionsBySection({
    roles,
    permissions: [HELP_TUTORIALS_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    getSections();
    getDataComponent();
  }, []);

  const getSections = async () => {
    // buscando las secciones desde la API
    const getSections = await API.getTutorialsSection();
    const getListSections = getSections.users;

    // ordenamos la lista
    const result = orderMarketingSection({ list: getListSections });

    setListSection(result);
  };

  const getDataComponent = async () => {
    try {
      const res = await API.getTutorials();
      setListDocumentDefault(res.tutorials);
      setListDocument(res.tutorials);
    }catch (error) {
      notifyApiError(error);
    }finally {
      setLoadingPage(false);
    }
  };

  const handleSearch = value => {
    if (value) {
      setListDocument(listDocumentDefault.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
    } else {
      setListDocument(listDocumentDefault);
    }
  };

  const handleToggleManager = () => setModalManager(!modalManager);
  const handleToggleMaterial = () => setModalMaterial(!modalMaterial);

  const handleCloseModalManager = () => {
    getSections();
    handleToggleManager();
  };

  const handleCreateMaterial = () => {
    getDataComponent();
    handleToggleMaterial();
  };

  const handleDeleteMaterial = async ({ material }) => {
    await API.deleteTutorials({ id: material.id });
    getDataComponent();
  };

  const handleEditMaterial = async ({ material }) => {
    setMaterialEdit(material);
    handleToggleMaterial();
  };

  const getAllowedSectionWithFiles = () => {
    const ordenList = listSection.map(section => {
      return { section, documents: listDocument.filter(file => file.section_id === section.id) };
    });

    if (!HELP_TUTORIALS_PERMISSIONS.viewAllDocument) {
      return ordenList
        .map(orden => {
          return {
            ...orden,
            documents: orden.documents.filter(
              file =>
                (HELP_TUTORIALS_PERMISSIONS.viewDocumentClient && file.is_client) ||
                (HELP_TUTORIALS_PERMISSIONS.viewDocumentInternal && file.is_internal)
            ),
          };
        })
        .filter(orden => orden.documents.length > 0 || orden.documents.video_url !== '');
    }

    return ordenList;
  };

  const handleDownloadDocument = async ({ document }) => {
    setLoading(true);
    await API.downloadDocumentMarketing({ url: document.url, nameDocument: document.document_name });
    setLoading(false);
  };

  const handleSelectTutorial = ({ document }) => {
    if (document.video_url)
      return setSelectTutorial({
        url: document.video_url,
        isVideo: true,
      });

    setSelectTutorial({ url: document.url, isVideo: false });
  };

  return (
    <Row className="clients-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Help and Tutorials
          </Title>
        </Col>
        <Col className="search">
          <Search placeholder="Search..." className="search-box" onSearch={handleSearch} />
        </Col>
        <Col className="pagination add-button">
          {HELP_TUTORIALS_PERMISSIONS.createHeader && (
            <Button type="primary" className="green-btn" onClick={handleToggleManager}>
              Header Manager
            </Button>
          )}
        </Col>
      </Row>

      <div className="marketing_content">
        {loadingPage ? (
          <Modal visible={loadingPage} footer={null} className="modal-loader">
            <Spin />
          </Modal>
        ) : (
          <>
            {loading && (
              <Modal visible={loading} footer={null} className="modal-loader">
                <Spin />
              </Modal>
            )}
            {HELP_TUTORIALS_PERMISSIONS.createMaterials && (
              <div className="marketing_add_material">
                <Button type="primary" className="purple-btn" onClick={handleToggleMaterial}>
                  Add Materials
                </Button>
              </div>
            )}

            {getAllowedSectionWithFiles().map(item => (
              <div key={item.section.id}>
                <Title level={4} className="textCenter">
                  {item.section.name}
                </Title>

                {item.documents.map(document => {
                  if (
                    HELP_TUTORIALS_PERMISSIONS.viewAllDocument ||
                    (HELP_TUTORIALS_PERMISSIONS.viewDocumentClient && document.is_client) ||
                    (HELP_TUTORIALS_PERMISSIONS.viewDocumentInternal && document.is_internal)
                  ) {
                    return (
                      <Row key={document.name} className="marketingPage">
                        <Text className="marketingPage_item">
                          <Text className="marketingPage_title" onClick={() => handleSelectTutorial({ document })}>
                            {document.name}
                          </Text>

                          <div className="marketing_actions">
                            {!document.read_only && (
                              <Text onClick={() => handleDownloadDocument({ document })}>
                                <DownloadOutlined
                                  height={'20px'}
                                  style={{ color: '#0082ff', fontSize: 18 }}
                                  width={'20px'}
                                  className="icon"
                                />
                              </Text>
                            )}

                            {HELP_TUTORIALS_PERMISSIONS.editMaterial && (
                              <EditIcon className="icon" onClick={() => handleEditMaterial({ material: document })} />
                            )}
                            {HELP_TUTORIALS_PERMISSIONS.deleteMaterial && (
                              <RemoveIcon
                                className="icon"
                                onClick={() => handleDeleteMaterial({ material: document })}
                              />
                            )}
                          </div>
                        </Text>
                      </Row>
                    );
                  }

                  return '';
                })}
              </div>
            ))}
          </>
        )}
      </div>

      {selectPdf?.url && <ShowTutorial data={selectPdf} close={() => setSelectTutorial(null)} />}
      {modalManager && <ModalManagerHelp close={handleCloseModalManager} />}
      {modalMaterial && (
        <ModalMaterialsHelp
          close={() => {
            setMaterialEdit(null);
            handleToggleMaterial();
          }}
          onCreate={handleCreateMaterial}
          dataEdit={materialEdit}
        />
      )}
    </Row>
  );
};

export default HelpNew;
