import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Tabs, Button, Badge } from 'antd';
import SpinBox from 'components/SpinBox';
import { BackIcon } from 'components/Icons';
import { goFirstStep } from 'utils/report-routing';
import { useHistory } from 'react-router-dom';
import { REPORT_SECTIONS_DEFAULT, REPORT_TYPES_BY_ID } from 'constants/reports';
import store from 'redux/store';
import * as API from 'utils/api';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { ATTORNEY_CLAIMANT_NAV, MAIN_NAV } from 'layout/navigate_const';
import { TAB_REPORTS_NUMBER, TAB_INVOICING_NUMBER } from 'constants/tabClaimantDetails';
import { TableRerportAttorney } from './components/TableRerportAttorney';
import { fetchTransactions } from 'utils/api-transactions';
import TransactionList from 'containers/Claimants/DetailedClaimant/Transactions/TransactionList';
import ChooseReportsModal from 'containers/Claimants/DetailedClaimant/Transactions/ChooseReportsModal';

import './AttorneyClaimants.scss';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { ModalUpgratePackageOpenReport } from 'components/Modal/ModalUpgratePackageOpenReport';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const ViewMsaReports = () => {
  const history = useHistory();
  let { idClaimant } = useParams();
  let search = history.location.search;
  let params = new URLSearchParams(search);
  const claimant_id = params.get('claimant_id') || idClaimant || '';

  const [loading, setLoading] = useState(true);
  const [reportInfo, setReportInfo] = useState({});
  const [dataHistory, setDataHistory] = useState(history.location.state?.data || {});
  const [selectedTab, setSelectTab] = useState(TAB_REPORTS_NUMBER);
  const [transactionsData, setTransactionData] = useState([]);
  const [transactionsTotalCount, setTransactionTotalCount] = useState(0);
  const [showChooseReportsModal, setShowChooseReportsModal] = useState(false);
  const [editedTransaction, setEditedTransaction] = useState(false);
  const [openModalUpgradedPlan, setOpenModalUpgradedPlan] = useState(false);

  const getTransactionsData = async ({ claimant }) => {
    const res = await fetchTransactions({ claimant_id: claimant.id });

    setTransactionData(res.rows);
    setTransactionTotalCount(res.count);
  };
  useEffect(() => {
    (async () => {
      try {
        let infoHistory = dataHistory;
        if (claimant_id) {
          const claimantData = await API.getClaimantsDataByClaimantId(claimant_id);
          infoHistory = claimantData.claimant;
          setDataHistory(infoHistory);
        }

        if (Object.keys(infoHistory).length === 0) return history.push(MAIN_NAV.path);

        const userId = store.getState().auth.roles.user_roles.id;
        const data = await API.getReportsDataByUserId({ claimantId: infoHistory.id, userId });

        await getTransactionsData({ claimant: dataHistory });

        setReportInfo(data.reports[0]);
        setLoading(false);
      } catch (e) {
        notifyApiError(e);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleGotoReportEdit = async ({ type_report } = {}) => {
    try {
      setLoading(true);
      const roles = store.getState().auth.roles;

      const validOpenRerpot = await validateOpenReportForPackage({
        report_type: type_report,
        tenant_id: roles.user_roles.tenant_id,
      });

      if (!validOpenRerpot.open)
        return setOpenModalUpgradedPlan({
          ...validOpenRerpot,
          open: true,
          tenant_id: roles.user_roles.tenant_id,
          customer: roles.user_roles.id,
        });

      const sendDataHistory = {
        claimantInfo: dataHistory,
        assignmentAttorney: true,
      };

      goFirstStep({
        sections: REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[type_report]],
        reportType: REPORT_TYPES_BY_ID[type_report],
        report_id: reportInfo.id,
        history,
        sendDataHistory,
      });
    } catch (e) {
      setLoading(false);
      notifyApiError(e);
    }
  };

  const handleChangeTab = value => {
    setSelectTab(value);
  };

  const handleOpenModalTransaction = () => {
    setShowChooseReportsModal(prevState => {
      return !prevState;
    });
  };

  const handleEditTransactions = transaction => {
    handleOpenModalTransaction();
    setEditedTransaction(transaction);
  };

  const insertTransactionRow = ({ transaction, invoice }) => {
    if (editedTransaction) {
      const index = transactionsData.findIndex(t => t.id === transaction.id);
      transactionsData.splice(index, 1, transaction);
      setTransactionData([...transactionsData]);
      const invoiceId = invoice?.DocNumber || invoice?.Id || null;
      notifyInfo('Transaction updated', `${invoiceId ? 'Invoice #' + invoiceId + ' has been generated.' : ''}`);
    } else {
      setTransactionData([transaction, ...transactionsData]);
      setTransactionTotalCount(prevState => {
        return prevState + 1;
      });
      notifyInfo(
        'Invoicing created',
        `${invoice?.DocNumber ? 'Invoice #' + invoice?.DocNumber + ' has been generated.' : ''}`
      );
    }
  };

  if (loading) return <SpinBox loading={loading}></SpinBox>;

  return (
    <Row className="detailed-claimant-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            {`${dataHistory.claimant_name || ''} ${dataHistory.claimant_last_name || ''}`}
          </Title>
          <Text>{dataHistory.client.client_name}</Text>
        </Col>
        <Col className="back-icon">
          <BackIcon
            className="icon"
            onClick={() => history.push(ATTORNEY_CLAIMANT_NAV.path, { data: dataHistory.client })}
          />
        </Col>
      </Row>

      <Row className="main">
        <Row className="second-content">
          <Row type="flex" justify="end" className="gap-16">
            {selectedTab === TAB_REPORTS_NUMBER && (
              <Button type="primary" size="default" className="green-btn button" style={{ opacity: 0 }} />
            )}
            {selectedTab === TAB_INVOICING_NUMBER && (
              <Button type="primary" size="default" className="green-btn button" onClick={handleOpenModalTransaction}>
                Add Invoicing
              </Button>
            )}
          </Row>
          <Tabs className="tab" activeKey={selectedTab} onChange={handleChangeTab}>
            <TabPane tab={<span>Report</span>} key={TAB_REPORTS_NUMBER}>
              <TableRerportAttorney
                loading={loading}
                reportInfo={reportInfo}
                handleGotoReportEdit={handleGotoReportEdit}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  Invoicing
                  <Badge
                    overflowCount={9999}
                    showZero
                    count={transactionsTotalCount}
                    className={`${selectedTab === TAB_INVOICING_NUMBER ? 'selected-' : ''}badge-icon`}
                  />
                </span>
              }
              key={TAB_INVOICING_NUMBER}>
              <TransactionList
                data={transactionsData}
                setData={value => setTransactionData(value)}
                claimantInfo={dataHistory}
                loading={loading}
                handleEditTransactions={handleEditTransactions}
                refresh={() => getTransactionsData({ claimant: dataHistory })}
              />
            </TabPane>
          </Tabs>
        </Row>
      </Row>

      {showChooseReportsModal && (
        <ChooseReportsModal
          isOpened={true}
          claimantInfo={dataHistory}
          onClose={handleOpenModalTransaction}
          addRow={insertTransactionRow}
          editedTransaction={editedTransaction}
        />
      )}

      {openModalUpgradedPlan?.open && (
        <ModalUpgratePackageOpenReport data={openModalUpgradedPlan} onClose={() => setOpenModalUpgradedPlan({})} />
      )}
    </Row>
  );
};

export default ViewMsaReports;
