import React, { useState } from 'react';
import { Modal, Select, Icon, Typography, Spin, Col, Row } from 'antd';
import './UploadFileModal.scss';
import { REPORT_FIELDS_UPLOAD } from 'constants/reportfieldsUploadFile';
import { UploadPresigned, BulkAddFilesToReport } from 'utils/upload';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles.js';
import * as API from 'utils/api';
import { useSelector } from 'react-redux';

const UploadFileModal = ({ close, returnFiles, reportName, reportId, claimId, only, title }) => {
  const { Option } = Select;
  const { Text } = Typography;

  const [listFiles, setListFiles] = useState([]);
  const [selectField, setSelectField] = useState(null);
  const [loading, setLoading] = useState(false);
  const { roles } = useSelector(state => state.auth);

  const handleAddFiles = ({ file }) => {
    const nameOld = listFiles.map(oldFile => oldFile.name);

    if (nameOld.includes(file.name)) {
      return;
    }

    setListFiles([...listFiles, file]);
  };

  const beforeUpload = file => {
    if (!file) return false;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Modal.error({
        content: 'You can only upload PDF file!',
      });
    }

    return isPdf;
  };

  const removeFile = ({ fileName }) => {
    setListFiles(listFiles.filter(file => file.name !== fileName));
  };

  const removeAllFiles = () => {
    setListFiles([]);
  };

  const handleSelectField = value => {
    setSelectField(value);
  };

  const handleUploadFiles = async () => {
    if (!selectField && !only[0]) {
      Modal.error({
        content: `Please choose a type of file`,
      });
      return;
    }

    if (listFiles.length === 0) {
      Modal.error({
        content: `Please upload a file`,
      });
      return;
    }

    setLoading(true);
    const isClient = isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT);
    const uploadArrayWithPromises = listFiles.map(file => UploadPresigned(file));

    const urls = await Promise.all(uploadArrayWithPromises);

    if (isClient) {
      await Promise.all(urls.map(item => API.ReportFileUpload(claimId, item)));
    } else {
      await BulkAddFilesToReport(urls, selectField || only[0], claimId, reportId);
    }

    setLoading(false);
    returnFiles && returnFiles();
  };

  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      className="report-modal"
      title={title}>
      <div className="modalUploadFile" id='modalUploadFile'>
        {/* LOADER */}
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>
        {/* END LOADER */}

        {!only && (
          <Col>
            <Row className="label">
              <Text>Choose a Type of File</Text>
            </Row>
            <Select
              showSearch
              placeholder="Please select"
              optionFilterProp="children"
              className="dropdown"
              value={selectField}
              id='selectTypeUploadFile'
              onChange={handleSelectField}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option disabled value="">
                Required
              </Option>
              {(only ? only : REPORT_FIELDS_UPLOAD[reportName]).map((field, index) => (
                <Option id={`selectTypeUploadFile_option_${index}`} key={field} value={field}>
                  {field}
                </Option>
              ))}
            </Select>
          </Col>
        )}

        <BoxDragAndDrop multiple beforeUpload={beforeUpload} handleAddFiles={handleAddFiles} />

        {listFiles.length > 0 && (
          <TableFileBoxDragAndDrop listFiles={listFiles} removeFile={removeFile} removeAllFiles={removeAllFiles} />
        )}

        <div className="modalUploadFile_btns">
          <button className="modalUploadFile_addBtn" onClick={handleUploadFiles}>
            Add
          </button>
          <button className="modalUploadFile_cancelBtn" onClick={close}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
