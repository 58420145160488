import React, {useState, useCallback, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import * as dayjs from "dayjs";

import AnswerItem from "./components/AnswerItem";
import "./feedbackitem.scss"
import {Button, Divider} from "antd";
import InputMultiline from "../InputMultiline";
import {reply} from "../../redux/help";
import {ArrowDownRightIcon} from "../Icons";


const FeedbackItem = props => {
  const dispatch = useDispatch();

  const {chat = {}, showLatest = false} = props
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const {entities, pending} = useSelector(({help}) => help);

  const messages = chat.messages || [];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const scrollToBegin = useCallback(() => {
    const messagesDiv = document.getElementById(`messages-${chat.id}`);
    if (messagesDiv) messagesDiv.scrollTop = messagesDiv.scrollHeight;
    // if (messagesDiv) messagesDiv.scrollTop = 0;
  }, [chat.id]);

  useEffect(() => {
    scrollToBegin();
  }, [scrollToBegin, chat.messages.length]);

  const onSubmit = useCallback(async () => {
    const res = await reply(chat.id, value)(dispatch);
    if (res) {
      setValue('');
      handleClose();
      scrollToBegin();
    }
  }, [chat.id, dispatch, scrollToBegin, value]);

  return (
    <div className="fi-container">
      <div className="fi-header">
        <div className="fi-date">{dayjs(chat.createdAt).format('MMM DD, YYYY')}</div>
        <div className="fi-time">{dayjs(chat.createdAt).format('h:mm a')}</div>
      </div>
      <div className="fi-title">{chat.name}</div>
      <div className="fi-description">{chat.description}</div>

      <div className="fi-answers-wrap">
        <ArrowDownRightIcon className="fi-arrow"/>
        <div className="fi-answers" id={`messages-${chat.id}`}>
          {
            messages.length === 0
              ? (<div className="fi-no-answers">Sorry. There is no feedback from MSA Administrators yet. Your question will be
                answered as soon as possible</div>)
              : (
                <>
                  {
                    showLatest ? (
                      <AnswerItem message={entities.messages[messages[0]]}/>
                    ) : (
                      messages.length > 0 && messages.map((messageId, index) => {
                        const message = entities.messages[messageId];
                        const user = entities.users[message.user_id];
                        return <AnswerItem key={`message-${messageId}`} message={message} index={index} user={user}/>
                      })
                    )
                  }
                </>
              )
          }
        </div>
      </div>
      {
        messages.length > 0 &&
        (
          <div className="fi-reply-form">
            {
              open
                ? (
                  <>
                    <Divider style={{margin: '20px 0'}}/>
                    <div className="reply-form">
                      <InputMultiline
                        label="Your reply"
                        placeholder="Required"
                        value={value}
                        onChange={(value) => setValue(value)}
                        size={3}
                        required
                      />
                      <div className="reply-form-actions">
                        <Button type="primary" loading={pending} disabled={!value} onClick={onSubmit}>Send</Button>
                        <Button type="link" onClick={handleClose}
                                style={{backgroundColor: 'transparent'}}>Cancel</Button></div>

                    </div>
                  </>)
                : (<Button type="primary" onClick={handleOpen} style={{margin: '20px 0 0 99px'}}>Reply</Button>)
            }
          </div>
        )
      }
    </div>
  )

}

export default FeedbackItem;
