import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Typography, Divider, Select, Icon, DatePicker } from 'antd';
import SpinBox from 'components/SpinBox';
import InputCurrencyBox from 'components/InputCurrencyBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MCP_MSA_COMBO_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

import './SettlementInfo.scss';

const { Title, Text } = Typography;
const { Option } = Select;

class SettlementInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      settlement_amount: '',
      settlement_type: 'lump sum',
      proposed_settlement_date: moment(),
      loadingTitle: 'Loading...',
      loading: false,
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };

  componentDidMount = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        this.setState({
          is_template: reportInfo.is_template,
          settlement_amount: reportInfo.settlement_amount || '',
          settlement_type: reportInfo.settlement_type || 'lump sum',
          proposed_settlement_date: reportInfo.proposed_settlement_date
            ? moment(reportInfo.proposed_settlement_date)
            : moment(),
          loading: false,
        });
        this.props.updateReportInfo(reportInfo);
      })
      .catch(err => forbiddenRedirect(err, this.props));
  };

  componentDidUpdate = prevProps => {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        settlement_amount: reportInfo.settlement_amount || '',
        settlement_type: reportInfo.settlement_type || 'lump sum',
        proposed_settlement_date: reportInfo.proposed_settlement_date
          ? moment(reportInfo.proposed_settlement_date)
          : moment(),
        loading: false,
      });
    }
  };

  handleUpdateReport = async (isNextStep = false) => {
    const { report_id, settlement_amount, settlement_type, proposed_settlement_date } = this.state;

    const params = {
      id: report_id,
      settlement_amount,
      settlement_type,
      proposed_settlement_date: moment(proposed_settlement_date).format('MM/DD/YYYY'),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { settlement_amount, settlement_type, proposed_settlement_date, loadingTitle, loading, REPORT_PERMISSIONS } =
      this.state;

    return (
      <Row className="msa-settlement-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MCP_MSA_COMBO_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown loading={loading} beforeNext={async () => await this.handleUpdateReport(false)} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Settlement Information</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Settlement Amount</Text>
                </Col>
                <Col className="input-col">
                  <InputCurrencyBox
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={settlement_amount}
                    onChange={value => this.setState({ settlement_amount: value })}
                  />
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Type of Settlement</Text>
                </Col>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    disabled={!REPORT_PERMISSIONS.edit}
                    className="dropdown"
                    value={settlement_type}
                    onChange={value => this.setState({ settlement_type: value })}
                    suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                    <Option value={'lump sum'} key={'1'}>
                      Lump sum
                    </Option>
                    <Option value={'structured'} key={'2'}>
                      Structured
                    </Option>
                  </Select>
                </Row>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Proposed Settlement Date</Text>
                </Col>
                <Row>
                  <DatePicker
                    className="date"
                    disabled={!REPORT_PERMISSIONS.edit}
                    format="MM/DD/YYYY"
                    value={proposed_settlement_date}
                    onChange={(date, dateString) => this.setState({ proposed_settlement_date: date })}
                  />
                </Row>
              </Row>
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    roles: state.auth.roles,
    userInfo: state.auth.userInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(SettlementInfo);
