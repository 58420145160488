import * as types from './actionTypes';
import * as API from '../../utils/api';
import { Auth } from 'aws-amplify';
import { listener } from '../../index';
import { notifyApiError, notifyError } from '../../utils/notification';
import { closeChatDrawer } from '../Drawer/DrawerActions';
import dayjs from 'dayjs';
import { EXTERNAL_AR_ID, EXTERNAL_QA_ID, MSA_MEDICAL_REVIEWER_ID } from 'layout/roles';

export const login = (username, password) => dispatch => {
  dispatch({
    type: types.LOGIN_REQUEST,
  });

  Auth.signIn(username, password)
    .then(async res => {
      let isNewPasswordRequest = false;
      let isAuthenticated = true;

      //force change password flow
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        isNewPasswordRequest = true;
        isAuthenticated = false;
        dispatch({
          type: types.LOGIN_SUCCESS,
          isNewPasswordRequest: isNewPasswordRequest,
          isAuthenticated: isAuthenticated,
          userInfo: res,
        });
        return;
      }

      let active = { is_active: false, connectionIds: [] };
      try {
        active = await API.isActiveUserV2(res.username);
      } catch (error) {
        notifyApiError(error);
        dispatch({
          type: types.LOGIN_FAILURE,
          errorMessage: "Can't get session data",
        });
        return 'contact a msa';
      }

      try {
        const activeTenant = await API.isActiveTenant();

        if (!activeTenant) throw '';
      } catch (error) {
        notifyApiError("Can't login");
        dispatch({
          type: types.LOGIN_FAILURE,
          errorMessage: "Can't login",
        });
        return;
      }

      // if is_active=true and no connection ids - it's stale session so allow sign in
      if (active.is_active && active.connectionIds?.length) {
        dispatch({
          type: types.LOGIN_FAILURE,
          errorMessage: 'This user already has an active session on another device',
        });
        return;
      }

      try {
        const roles = await API.GetUserRoles(res.username);

        let activeReviewer = true;

        if (
          roles?.user_roles?.roles?.[0]?.id &&
          [EXTERNAL_QA_ID, EXTERNAL_AR_ID, MSA_MEDICAL_REVIEWER_ID].includes(roles.user_roles.roles[0].id) &&
          !roles?.user_roles?.is_active
        ) {
          activeReviewer = false;
        }

        dispatch({ type: types.FETCH_ROLES, roles });
        dispatch({ type: types.IS_ACTIVE_REVIEWER, activeReviewer });
        localStorage.setItem('isActiveReviewer', activeReviewer);
      } catch (e) {
        notifyApiError(e);
        dispatch({
          type: types.LOGIN_FAILURE,
          errorMessage: "Can't get user roles",
        });
        return;
      }

      const signInTime = dayjs();
      localStorage.setItem('lastActiveAt', signInTime.toISOString());
      localStorage.setItem('isAuthenticated', isAuthenticated);
      dispatch({
        type: types.LOGIN_SUCCESS,
        isNewPasswordRequest: false,
        isAuthenticated: true,
        userInfo: res,
        signInTime,
      });
    })
    .catch(e => {
      console.error(e);
      dispatch({
        type: types.LOGIN_FAILURE,
        errorMessage: e.message || 'Authentication error',
      });
    });
};
export const fetchRoles = () => async dispatch => {
  try {
    const res = await Auth.currentUserInfo();
    if (!res) return;
    const roles = await API.GetUserRoles(res.username);
    dispatch({ type: types.FETCH_ROLES, roles: roles });
  } catch (e) {
    console.error(e);
    /* dispatch({
      type: types.LOGIN_FAILURE,
      errorMessage: 'Could not obtain roles. Please sign in again',
    });*/
    // dispatch({ type: types.FETCH_ROLES, roles: { user_roles: [] } });
    notifyError('Could not obtain roles. Please reload page.');
  }
};
export const logout = () => async dispatch => {
  try {
    const res = await Auth.currentUserInfo();
    if (res) {
      /*await API.updateActiveUser({
        user_id: res.username,
        is_active: false,
      });*/
      await API.setActiveUser({
        is_active: false,
      });
    }
    Auth.signOut();
    window.removeEventListener('beforeunload', listener);
    localStorage.removeItem('lastActiveAt');
    localStorage.removeItem('isAuthenticated');
    dispatch({
      type: types.LOGOUT_REQUEST,
    });
    dispatch(closeChatDrawer());
  } catch (e) {
    notifyApiError(e);
  }
};

export const sendEmail = email => dispatch => {
  dispatch({
    type: types.SEND_EMAIL_REQUEST,
  });
  Auth.forgotPassword(email)
    .then(() => {
      dispatch({
        type: types.SEND_EMAIL_SUCCESS,
      });
    })
    .catch(error => {
      let errorMessage = '';
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.SEND_EMAIL_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const resetPassword = (userName, verifyCode, newPassword) => dispatch => {
  dispatch({
    type: types.RESET_PASSWORD_REQUEST,
  });
  Auth.forgotPasswordSubmit(userName, verifyCode, newPassword)
    .then(() => {
      dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
      });
    })
    .catch(error => {
      let errorMessage = '';
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.RESET_PASSWORD_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const getUserInfo = () => dispatch => {
  dispatch({
    type: types.GET_USER_INFO_REQUEST,
  });
  Auth.currentUserInfo()
    .then(res => {
      dispatch({
        type: types.GET_USER_INFO_SUCCESS,
        userInfo: res,
      });
    })
    .catch(error => {
      let errorMessage = '';
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.GET_USER_INFO_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const setPassword = (userName, newPassword) => dispatch => {
  dispatch({
    type: types.SET_PASSWORD_REQUEST,
  });
  Auth.completeNewPassword(userName, newPassword)
    .then(() => {
      dispatch({
        type: types.SET_PASSWORD_SUCCESS,
      });
    })
    .catch(error => {
      let errorMessage = '';
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.SET_PASSWORD_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const loginBackground =
  ({ userName, newPassword, confirmCode }) =>
  dispatch => {
    dispatch({
      type: types.SET_PASSWORD_REQUEST,
    });
    return Auth.signIn(userName, confirmCode)
      .then(user => {
        return Auth.completeNewPassword(user, newPassword)
          .then(() => {
            dispatch({
              type: types.SET_PASSWORD_SUCCESS,
            });
            return true;
          })
          .catch(error => {
            let errorMessage = '';
            if (error) {
              errorMessage = error.message;
            }
            dispatch({
              type: types.SET_PASSWORD_FAILURE,
              errorMessage: errorMessage,
            });
            return false;
          });
      })
      .catch(error => {
        let errorMessage = '';
        if (error) {
          errorMessage = error.message;
        }
        dispatch({
          type: types.SET_PASSWORD_FAILURE,
          errorMessage: errorMessage,
        });
        return false;
      });
  };

export const changePassword = async ({ oldPassword, newPassword }) => {
  return await Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(() => ({
      error: false,
      message: 'The password was changed successfully',
    }))
    .catch(err => ({
      error: true,
      message: err.message,
    }));
};
