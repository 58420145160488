import {
  REVIEWS_GET_USER_ASSIGNMENTS,
  REVIEWS_LOADING_END,
  REVIEWS_LOADING_START,
  REVIEWS_GET_ASSIGNMENT,
  REVIEWS_UPDATE_HIGHLIGHTS,
  REVIEWS_PENDING_START,
  REVIEWS_PENDING_END,
  REVIEWS_UPDATE_ASSIGNMENT_ID
} from './actionTypes';

const initialState = {
  isLoading: false,
  isPending: false,
  assignments: [],
  count: 0,
  assignment: { id: null },
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case REVIEWS_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };

    case REVIEWS_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };

    case REVIEWS_PENDING_START:
      return {
        ...state,
        isPending: true,
      };

    case REVIEWS_PENDING_END:
      return {
        ...state,
        isPending: false,
      };

    case REVIEWS_GET_USER_ASSIGNMENTS: {
      return {
        ...state,
        assignments: action.payload?.assignments || [],
        count: action.payload?.count || 0,
      };
    }

    case REVIEWS_GET_ASSIGNMENT: {
      return {
        ...state,
        assignment: action.payload?.assignment,
      };
    }

    case REVIEWS_UPDATE_ASSIGNMENT_ID : {
      const { assignment } = state;
      assignment.id = action.payload || null;
      return {
        ...state,
        assignment,
      };
    }

    case REVIEWS_UPDATE_HIGHLIGHTS : {
      const { assignment } = state;
      assignment.highlights = [...action.payload] || [];
      return {
        ...state,
        assignment,
      };
    }

    default:
      return state;
  }
};

export default report;
