import React, { Fragment } from 'react';
import { Row, Col, Button, Typography } from 'antd';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

import { PRIMARY_TEXT_DARK } from '../../../lib/utils/colors';

import CustomButton from "../../../components/common/CustomButton";

const { Text } = Typography;

export default class CustomToolbar extends Toolbar {

  render() {
    return (
      <Fragment>
        {this.props.view === "month" && <Row align="middle" className="custom-toolbar">
          <Col span={24} style={{ display: 'flex', alignItems: 'center' }} className="calendar-header-wrapper">
            <Text
              level={4}
              style={{ marginRight: '5px' }}
              className="current-date"
            >
              {this.props.label}
            </Text>
            <CustomButton

              border={'none'}
              shape={'circle'}
            >
              <Icon
                path={mdiChevronLeft}
                size={1}
                color={PRIMARY_TEXT_DARK}
                onClick={() => this.navigate('PREV')}
              />
            </CustomButton>
            <CustomButton

              border={'none'}
              shape={'circle'}
            >
              <Icon
                path={mdiChevronRight}
                size={1}
                color={PRIMARY_TEXT_DARK}
                onClick={() => this.navigate('NEXT')}
              />
            </CustomButton>
            <Button size={'large'} style={{ color: '#000' }} onClick={() => this.navigate('TODAY')}>
              {'Today'}
            </Button>

          </Col>
        </Row>}

      </Fragment>
    );
  }
}
