import * as API from 'utils/api';

export const onActionNotifications = async ({ action, payload }) => {
  if (action === 'accept_user' || action === 'decline_user') {
    const body = {
      allowUser: action === 'accept_user',
      user_id: payload?.notification?.info?.user?.id,
      notification_id: payload.notification_id,
    };
    await API.updateUserAllowed({ body });
  }
};
