import React, { useEffect } from 'react';

// Components
import { PlanCard } from './components/PlanCard';
import { usePricingGlobal } from 'hooks';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Hooks

const StepPackageTier = ({ data, onSubmit, typePackage }) => {
  const { listPricingGlobal, loadingPricingGlobal, getPricingGlobal } = usePricingGlobal();

  useEffect(() => {
    (async () => {
      await getPricingGlobal();
    })();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = ({ selectedPlan }) => {
    onSubmit({ sectionStep: 'selected_plan', payload: selectedPlan, nextStep: 4 });
  };

  if (loadingPricingGlobal) return <ModalLoading />;

  return (
    <div className="stepContainer">
      <div className="stepTitleContainer">
        <p className="stepText">Step 3</p>
        <h1 className="stepTitle">Choose your plan</h1>
      </div>

      <PlanCard onSubmit={handleSubmit} selectedPlan={data} typePackage={typePackage} listPricingGlobal={listPricingGlobal} />
    </div>
  );
};

export default StepPackageTier;
