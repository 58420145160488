import React, { useState, useEffect } from 'react';
import { Button, Select, Icon } from 'antd';
import { EyeIcon, EyeInvisib } from 'components/Svg';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { ElipsisIcon } from 'components/Svg';
import { PRIMARY_BORDER_GREY } from 'containers/Calendar/src/src/lib/utils/colors';
import './templateLifeCarePlanFields.scss';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';
import * as API from 'utils/api';
import { FILED_SPECIAL_LIFE_TABLE } from '../../const/itemsTableDragable';

const { Option } = Select;

const TemplateLifeCarePlanFields = ({ field, onSave, listReportType, onlyField }) => {
  const [data, setData] = useState(field);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listFieldReportType, setListFieldReportType] = useState([]);

  const toggleEdit = () => setEdit(prevState => !prevState);

  useEffect(() => {
    (async () => {
      if (data?.report_type_ref) {
        await getFielReportType({ reportTypeId: data?.report_type_ref });
      }
    })();
  }, []);

  const handleChangeField = e => {
    e?.persist && e.persist();
    setData(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSaveField = () => {
    toggleEdit();
    onSave({ fieldEdit: data });
  };

  const handleChangeReportType = async value => {
    try {
      setLoading(true);

      await getFielReportType({ reportTypeId: value });

      handleChangeField({ target: { name: 'report_type_ref', value } });
      handleChangeField({ target: { name: 'report_ref', value: '' } });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const getFielReportType = async ({ reportTypeId }) => {
    try {
      const listField = await API.getFieldByReportTypeId({ reportTypeId });

      setListFieldReportType(listField.section_fields);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const isLifeExpectancySource = data.ref === FILED_SPECIAL_LIFE_TABLE;
  return (
    <>
      {loading && <ModalLoading />}

      <div
        className={`templateLifeCarePlanFields templateLifeCarePlanFields_showInfo ${edit ? 'active' : ''} ${
          onlyField ? 'templateLifeCarePlanFields_showInfo_only' : ''
        } ${
          isLifeExpectancySource ? 'templateLifeCarePlanFields_selectLife_content' : ''
        }`}>
        {isLifeExpectancySource ? (
          <div className="templateLifeCarePlanFields_selectLife">
            <p className="templateLifeCarePlanFields_showInput">Life Source</p>
            <p className="templateLifeCarePlanFields_showInput">Life Table</p>
          </div>
        ) : (
          <>
            <p className={`templateLifeCarePlanFields_showLabel ${data.is_hidden ? 'hiddenLabel' : ''}`}>
              {!onlyField && <ElipsisIcon color={PRIMARY_BORDER_GREY} />}
              {data?.label || ''}
            </p>
            <p className="templateLifeCarePlanFields_showInput" onClick={toggleEdit}>
              {data?.placeholder || ''}
            </p>
            <div className="templateLifeCarePlanFields_editInfo">
              <label>
                Label
                <input
                  type="text"
                  placeholder="Label"
                  name="label"
                  value={data?.label || ''}
                  onChange={handleChangeField}
                  disabled={data.is_hidden}
                />
                {data.is_hidden ? (
                  <EyeIcon
                    className="templateLifeCarePlanFields_editInfo_svg"
                    color={PRIMARY_LIGHT_BLUE}
                    onClick={() =>
                      handleChangeField({
                        target: {
                          name: 'is_hidden',
                          value: false,
                        },
                      })
                    }
                  />
                ) : (
                  <EyeInvisib
                    className="templateLifeCarePlanFields_editInfo_svg"
                    color={PRIMARY_LIGHT_BLUE}
                    onClick={() =>
                      handleChangeField({
                        target: {
                          name: 'is_hidden',
                          value: true,
                        },
                      })
                    }
                  />
                )}
              </label>
              <label>
                Placeholder
                <input
                  type="text"
                  placeholder="Placeholder"
                  name="placeholder"
                  value={data?.placeholder || ''}
                  onChange={handleChangeField}
                  disabled={data.is_hidden}
                />
              </label>
              <label>
                Report Type
                <Select
                  showSearch
                  optionFilterProp="children"
                  className="templateLifeCarePlanFields_editInfo_select"
                  value={Number(data?.report_type_ref) || ''}
                  onChange={handleChangeReportType}
                  suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                  <Option disabled value="">
                    Report Type
                  </Option>
                  {listReportType.map(report => (
                    <Option key={report.id} value={report.id}>
                      {report.name}
                    </Option>
                  ))}
                </Select>
              </label>
              <label>
                Report Field
                <Select
                  showSearch
                  optionFilterProp="children"
                  className="templateLifeCarePlanFields_editInfo_select"
                  value={data?.report_ref || ''}
                  onChange={value => handleChangeField({ target: { name: 'report_ref', value } })}
                  suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                  <Option disabled value="">
                    Report Field
                  </Option>
                  {listFieldReportType.map(item => (
                    <Option key={item.id} value={item.report_column_ref}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </label>

              <div className="templateLifeCarePlan_editBtn">
                <Button type="primary" size="large" className="green-btn button" onClick={handleSaveField}>
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TemplateLifeCarePlanFields;
