import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Row, Typography } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";
import { stateFromHTML } from "draft-js-import-html";
import V2 from "./RichTextEditor2";
const { Text } = Typography;
window.WEBSPELLCHECKER_CONFIG = {
  autoSearch: true,
  enableGrammar: true,
  serviceId: "jdvmqulLhWbINzr",
};

export default V2;
class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);
    const html = "<div></div>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    this.props.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(
        /font-family/g,
        "f"
      )
    );
    this.setState({
      editorState,
    });
  };
  componentDidMount = () => {
    if (this.props.value) {
      const html = this.props.value;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }
  };
  handlePastedText = (text, html) => {
    const { editorState } = this.state;
    const blockMap = stateFromHTML(html).blockMap;
    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap
    );
    this.setState({
      editorState: EditorState.push(editorState, newState, "insert-fragment"),
    });
    return true;
  };
  render() {
    const { editorState } = this.state;
    return (
      <Row>
        <Row className="label">
          <Text>{this.props.label}</Text>
        </Row>
        <Row>
          <Editor
            // spellCheck={true}
            // ariaAutoComplete={true}
            toolbarOnFocus={true}
            isSoftReturnDefault={true}
            toolbarClassName="demo-toolbar-absolute"
            // toolbarOnFocus
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",

                "list",
                "textAlign",

                "remove",
                "history",
              ],
              inline: {
                options: [
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "monospace",
                ], // this can be specified as well, toolbar wont have
                //strikethrough, 'monospace', 'superscript', 'subscript'
              },
            }}
            handlePastedText={this.handlePastedText}
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={this.onEditorStateChange}
          />
        </Row>
      </Row>
    );
  }
}
