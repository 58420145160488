import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import * as API from 'utils/api';
import { UploadPresigned } from 'utils/upload';
import { orderMarketingSection } from 'containers/Marketing/utils/orderMarketingSection';
import { ModalMaterialsComponent } from 'containers/Marketing/components/ModalMaterials';

import 'containers/Marketing/components/ModalMaterials/modalMaterials.scss';

const DATA_INIT = {
  name: '',
  section_id: 0,
  read_only: true,
  video_url: '',
  is_client: false,
  is_internal: false,
};

const ModalMaterialsHelp = ({ close, onCreate, dataEdit }) => {
  const [listFiles, setListFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listSection, setListSection] = useState([]);
  const [newData, setNewData] = useState(dataEdit || DATA_INIT);

  useEffect(() => {
    asyncEffect();
    // eslint-disable-next-line
  }, []);

  const asyncEffect = async () => {
    try {
      setLoading(true);
      const getData = await API.getTutorialsSection();

      if (!getData.users && getData.users.length === 0) return;

      const result = orderMarketingSection({
        list: getData.users,
      });

      if (dataEdit) {
        setListFiles([{ name: dataEdit.document_name }]);
      }

      setListSection(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAddFiles = ({ file }) => {
    if (listFiles.length >= 1) return;

    const nameOld = listFiles.map(oldFile => oldFile.name);

    if (nameOld.includes(file.name)) {
      return;
    }

    setListFiles([...listFiles, file]);
  };

  const beforeUpload = file => {
    if (!file) return false;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Modal.error({
        content: 'You can only upload PDF file!',
      });
    }

    return isPdf;
  };

  const removeFile = ({ fileName }) => {
    setListFiles(listFiles.filter(file => file.name !== fileName));
  };

  const removeAllFiles = () => {
    setListFiles([]);
  };

  const handleSave = async () => {
    try {
      if (listFiles.length === 0 && newData.video_url === '') {
        Modal.error({
          content: `Please upload a file or video`,
        });
        return;
      }

      if (!newData.name) {
        Modal.error({
          content: `Please field name is required`,
        });
        return;
      }

      if (newData.section_id === 0) {
        Modal.error({
          content: `Please field section is required`,
        });
        return;
      }

      if (newData.is_client !== true && newData.is_internal !== true) {
        Modal.error({
          content: `Please select Client, Interal or both`,
        });
        return;
      }

      const file = listFiles[0];

      setLoading(true);

      let body = {};
      if (file?.uid) {
        const uploadArrayWithPromises = UploadPresigned(file);
        const urls = await Promise.all([uploadArrayWithPromises]);

        body = {
          ...newData,
          url: urls[0].url,
          document_name: file.name,
        };
      } else {
        delete newData.url;

        body = {
          ...newData,
        };
      }

      let res = null;

      if (!dataEdit) {
        res = await API.createTutorial({ body });
      } else {
        res = await API.updateTutorial({ body });
      }

      if (res.success) {
        onCreate && onCreate();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeNewData = ({ field, value }) => {
    setNewData({
      ...newData,
      [field]: value,
    });
  };

  return (
    <ModalMaterialsComponent
      loading={loading}
      newData={newData}
      handleChangeNewData={handleChangeNewData}
      listSection={listSection}
      listFiles={listFiles}
      beforeUpload={beforeUpload}
      handleAddFiles={handleAddFiles}
      removeFile={removeFile}
      removeAllFiles={removeAllFiles}
      handleSave={handleSave}
      close={close}
      handleClientOrInternal
      title="Upload Tutorial Material"
      embeddedVideo
    />
  );
};

export default ModalMaterialsHelp;
