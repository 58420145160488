import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import * as API from 'utils/api';

import './modalUpgratePackageOpenReport.scss';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from '../ModalLoading';

const { Title, Text } = Typography;

const ModalUpgratePackageOpenReport = ({ onClose, data }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await API.updateSubscriptionService({
        tenant_id: data.tenant_id,
        package_id: data.package_id,
        customer: data.customer,
      });
      onClose();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      width={500}
      maskClosable={false}
      visible={true}
      onCancel={onClose}
      keyboard={false}
      className="modalUpgradedPackageOpenReport"
      footer={null}>
      {loading && <ModalLoading />}
      <div className="modalUpgradedPackageOpenReport_content">
        <Title level={4}>This report is not included in your package</Title>
        <Text>
          Would you like to move to the {data.update} tier to include this report type? <br />
          You will be billed at the new package rate of ${data.price} when your next billing cycle is due
        </Text>
        <div className="modalConfirm_btns">
          <Button type="primary" className="green-btn" onClick={handleConfirm}>
            Yes
          </Button>
          <Button type="primary" className="purple-btn-outline" onClick={onClose}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalUpgratePackageOpenReport;
