import React, { useState, useEffect } from "react";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import { Row, Col, Button, Typography, Divider } from "antd";
import AddTemplateFields from "./AddTemplateFields";
import * as API from "utils/api";
import { EditIcon, RemoveIcon, CloneIcon } from "components/Icons";
import UpdateTemplateModal from "./UpdateTemplateFields";
import "./TemplateFields.scss";
import SpinBox from "components/SpinBox";
import moment from "moment";
export default function TemplateFields({
  report_id,
  section_id,
  report_template_id,
  is_template,
}) {
  const { Title } = Typography;
  useEffect(() => {
    API.getTemplateFields(
      is_template ? report_id : report_template_id,
      section_id
    ).then((res) => {
      setTemplateFields(res.template_fields);
      if (report_template_id) {
        API.GetFieldValues(report_id).then((response) => {
          setLoading(false);
          setReportFields(response.report_extra_field_values);
        });
      } else {
        setLoading(false);
      }
    });
  }, [report_template_id]);
  const [reportFields, setReportFields] = useState([]);
  const [openTemplateFieldsModal, setOpenTemplateFieldsModal] = useState(false);
  const [templateFields, setTemplateFields] = useState([]);
  const [openUpdateTemplateModal, setOpenUpdateTemplateModal] = useState(false);
  const [template, setTemplate] = useState("");
  const [loading, setLoading] = useState(true);
  const closeModal = () => {
    setOpenTemplateFieldsModal(false);
  };
  const handleOpenUpdateTemplateModal = (template) => {
    setOpenUpdateTemplateModal(true);
    setTemplate(template);
  };
  const handleDeleteTemplateField = (id) => {
    setLoading(true);
    API.deleteTemplateField(id).then((res) => {
      const tempArr = [...templateFields];

      const filteredTempaltes = tempArr.filter(
        (template) => template.id !== id
      );
      setTemplateFields(filteredTempaltes);
      setLoading(false);
    });
  };
  const fetchAllTemplateFields = async () => {
    API.getTemplateFields(
      is_template ? report_id : report_template_id,
      section_id
    ).then((res) => {
      setTemplateFields(res.template_fields);
      setLoading(false);
      setOpenUpdateTemplateModal(false);
    });
  };
  const mergeData = (templateField, reportFields) => {
    let merged = templateField.map((templatefield) => {
      templatefield.data = {};

      reportFields
        // .sort((a, b) => {
        //   return b.id - a.id;
        // })
        .map((reportField) => {
          if (reportField.template_fields_id === templatefield.id) {
            templatefield.data = reportField;
          }
        });
      return templatefield;
    });
    console.table(merged, "merged");
    return merged;
  };
  if (loading) {
    return <></>;
  }
  if (report_template_id) {
    return (
      <>
        {mergeData(templateFields, reportFields).map((template_fields) => {
          return (
            <Col className="input-col" md={24} style={{ marginTop: 10 }}>
              <RichTextEditor
                modal={false}
                label={template_fields.display_name}
                className="input"
                value={
                  template_fields.data.value
                    ? template_fields.data.value
                    : template_fields.default_value
                }
                onChange={(value) => {
                  if (loading) {
                    return;
                  }
                  let fields = [...reportFields];
                  let indexFound = null;

                  let found = fields.map((field, index) => {
                    if (
                      field.template_fields_id ===
                      template_fields.data.template_fields_id
                    ) {
                      indexFound = index;
                    }
                  });

                  if (indexFound !== null) {
                    //patch
                    fields[indexFound].value = value;
                    API.EditFields(template_fields.data.id, {
                      value,
                    }).then((res) => {});
                    setReportFields([...fields]);
                  } else {
                    API.AddExtraFieldValue(
                      template_fields.id,
                      report_id,
                      value
                    ).then((res) => {
                      fields.push({
                        id: res.report_extra_field_value.id,
                        value: value,
                        template_fields_id: template_fields.id,
                        report_id,
                      });
                      setReportFields([...fields]);
                    });
                    //create
                  }
                }}
              />
            </Col>
          );
        })}
      </>
    );
  }
  return (
    <>
      <Row className="extra-fields-title" type="flex" justify="space-between">
        <Title level={4}>Extra Fields</Title>
        <Button
          onClick={() => setOpenTemplateFieldsModal(true)}
          className="ant-btn-primary green-btn"
        >
          Add{" "}
        </Button>
      </Row>

      <Row>
        <Col md={10}>Display Name</Col>
        <Col md={10}>Default Value</Col>
        <Col md={4}>Actions</Col>
      </Row>
      {/* {templateFields &&
        templateFields.map((data, index) => (
          <Row type="flex" key={index}>
            <Col md={24}>
              <RichTextEditor
                label={data.display_name}
                placeholder="Please enter"
                value={data.default_value}
              />
            </Col>
          </Row>
        ))} */}
      <SpinBox loading={loading}>
        {templateFields &&
          templateFields.map((template, index) => (
            <Row key={index} className="template-fields-wrapper">
              <Col md={10}>{template.display_name}</Col>
              <Col md={10}>{template.default_value}</Col>
              <Col md={4} className="template-fields-actions">
                <EditIcon
                  style={{ margin: "0px 10px" }}
                  onClick={() => handleOpenUpdateTemplateModal(template)}
                />
                <RemoveIcon
                  onClick={() => handleDeleteTemplateField(template.id)}
                />
              </Col>
              <Divider style={{ margin: "10px 0px" }} />
            </Row>
          ))}
      </SpinBox>

      <AddTemplateFields
        report_template_id={report_id}
        section_id={section_id}
        isOpen={openTemplateFieldsModal}
        onCancel={() => setOpenTemplateFieldsModal(false)}
        report_id={report_id}
        setTemplateFields={setTemplateFields}
        templateFields={templateFields}
        closeModal={closeModal}
      />
      <UpdateTemplateModal
        report_template_id={report_template_id}
        isOpen={openUpdateTemplateModal}
        onCancel={() => setOpenUpdateTemplateModal(false)}
        template={template}
        fetchAllTemplateFields={fetchAllTemplateFields}
      />
    </>
  );
}
