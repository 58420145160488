import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as API from 'utils/api';
import { useParams, useHistory } from 'react-router-dom';

// Components
import { Row, Col, Typography, Tooltip, Button, Divider } from 'antd';
import DropDown from 'components/Dropdown';
import SpinBox from 'components/SpinBox';
import PrevNextStep from 'components/PrevNextStep';
import MedicareCostModal from 'components/MedicareCostModal';
import { LCPMedicareCostTable } from 'components/LCPMedicareCostTable';
import DeleteModal from 'components/DeleteModal';

// Const
import { REPORT_LIFE_CARE_PLAN_NAME } from 'constants/reports';
import { LCP_MEDICARE, ACTION_TYPE_ADD_MEDICARE } from 'components/MedicareCostModal/data';
import { CLAIMANT_DETAIL_NAV, MAIN_NAV } from 'layout/navigate_const';

// Utils
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { getPosition, get2NewPositions } from 'utils/shared';
import { notifyApiError, notifyInfo } from 'utils/notification';

const { Title, Text } = Typography;

const MedicalCostLCP = () => {
  const { id } = useParams();
  const { roles } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const [loadingPage, setLoadingPage] = useState(false);
  const [modalMedicareCost, setModalMedicareCost] = useState(false);
  const [cptList, setCptList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [unitFormList, setUnitFormList] = useState([]);
  const [reportInfo, setReportInfo] = useState({});
  const [structureTable, setStructureTable] = useState([]);
  const [actionType, setActionType] = useState(ACTION_TYPE_ADD_MEDICARE);
  const [medicareDataList, setMedicareDataList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);

  // Permissions
  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    asyncEffect();
  }, []);

  const asyncEffect = async () => {
    await Promise.all([
      getSpecialityList(),
      getCPTData(),
      getUnitForms(),
      getReport(),
      getStructureTable(),
      getMedicareDataList(),
    ]);
  };

  const getSpecialityList = async () => {
    const res = await API.getSpeciality();
    setSpecialityList(res.specialities);
  };

  const getCPTData = async () => {
    const res = await API.getCPTData();
    setCptList(res.cpt_codes);
  };

  const getUnitForms = async () => {
    const res = await API.getUnitForms();
    setUnitFormList(res.unit_forms);
  };

  const getReport = async () => {
    let report = {};

    if (REPORT_PERMISSIONS.cloneReport) {
      report = await API.getReportById(id);

      if (report === false) {
        report = await API.getReportById(id, true);
        await API.createViewReport(report);
      }
    } else {
      report = await API.getReportById(id, true);
    }
    setReportInfo(report);
  };

  const getStructureTable = async () => {
    const reportStructure = await API.getReportLifeCarePlan({ idReport: id });
    if (reportStructure.tables.struture.count > 0) setStructureTable(reportStructure.tables.struture.rows);
  };

  const getMedicareDataList = async () => {
    const res = await API.getMedicareData({ reportId: id });
    setMedicareDataList(res.medicare_cost_projections);
  };

  const searchCodes = async value => {
    const getCptList = await API.getCPTData(value);
    setCptList(getCptList.cpt_codes);
  };

  const handleAddMedicareCost = ({ actionType = ACTION_TYPE_ADD_MEDICARE, selectedItem = null } = {}) => {
    setSelectedItem(selectedItem);

    setActionType(actionType);
    setModalMedicareCost(prevState => !prevState);
  };

  const handleMedicareCost = async params => {
    setLoadingPage(true);

    if (actionType === ACTION_TYPE_ADD_MEDICARE) {
      try {
        handleAddMedicareCost();
        setLoadingPage(true);
        const body = {
          ...params,
          position: getPosition(medicareDataList, params.type),
          report_id: id,
        };

        await API.createMedicareItem(body);
        await getMedicareDataList();
        notifyInfo('Medicare Cost Created successfully');
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoadingPage(false);
      }
    } else {
      try {
        handleAddMedicareCost();
        setLoadingPage(true);
        const param = {
          ...params,
          id: selectedItem.id,
        };
        await API.updateMedicareItem(param);
        await getMedicareDataList();
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoadingPage(false);
      }
    }
  };

  const handleOpenDeleteModal = selectedItem => {
    if (selectedItem) setSelectedItem(selectedItem);

    setModalDelete(prevState => !prevState);
  };

  const handleDelete = async () => {
    try {
      handleOpenDeleteModal();
      setLoadingPage(true);

      await API.deleteMedicareItem(selectedItem.id);
      await getMedicareDataList();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleClone = async item => {
    try {
      setLoadingPage(true);

      let position = getPosition(medicareDataList, item.type);
      let newItem = { ...item, position };
      delete newItem.id;
      delete newItem.updatedAt;
      delete newItem.createdAt;
      delete newItem.deletedAt;
      delete newItem.cpt_code;

      await API.createMedicareItem(newItem);
      await getMedicareDataList();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleChangePosition = async (id, move) => {
    let result = get2NewPositions(medicareDataList, id, move);

    if (result.success) {
      const Promise1 = API.updateMedicareItem(result.update1);
      const Promise2 = API.updateMedicareItem(result.update2);

      await Promise.all([Promise1, Promise2]);

      setMedicareDataList(result.newArr);
      const item2 = document.getElementById('table-item-' + result.update1.id + result.update1.position);
      const item1 = document.getElementById('table-item-' + result.update2.id + result.update2.position);

      item2.classList.remove('animate-' + (move === 'up' ? 'down' : 'up'));
      item1.classList.remove('animate-' + move);
    }
  };

  return (
    <Row className="msa-medicare-cost-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            {REPORT_LIFE_CARE_PLAN_NAME} Report
          </Title>
        </Col>
        <Col className="step">
          <DropDown
            loading={loadingPage}
            goBack={() => history.push(REPORT_PERMISSIONS.goBackClaimant ? CLAIMANT_DETAIL_NAV.path : MAIN_NAV.path)}
          />
        </Col>
      </Row>

      <Row className="main">
        <SpinBox loading={loadingPage}>
          <Row className="content">
            <Row type="flex" justify="end" className="row">
              {REPORT_PERMISSIONS.edit && (
                <Tooltip title="Add Medical Cost item">
                  <Button type="primary" size="default" className="green-btn button" onClick={handleAddMedicareCost}>
                    Add Medical Cost
                  </Button>
                </Tooltip>
              )}
            </Row>

            {structureTable.map((structure, index) => (
              <Row className="section" key={structure.id}>
                <Row className="sub-title">
                  <Text>
                    {index + 1}. {structure.name}
                  </Text>
                </Row>
                <LCPMedicareCostTable
                  searchCodes={searchCodes}
                  data={medicareDataList.filter(item => {
                    return item.type === structure.name;
                  })}
                  report={reportInfo}
                  structure={structure}
                  handleEdit={(action, item) => handleAddMedicareCost({ actionType: action, selectedItem: item })}
                  handleDelete={handleOpenDeleteModal}
                  handleClone={handleClone}
                  handleChangePosition={handleChangePosition}
                  canEdit={REPORT_PERMISSIONS.edit}
                />
              </Row>
            ))}

            <Divider className="divider" />
            <PrevNextStep />
          </Row>
        </SpinBox>
      </Row>

      {modalMedicareCost && (
        <MedicareCostModal
          searchCodes={searchCodes}
          isOpen={modalMedicareCost}
          actionType={actionType}
          modalType={LCP_MEDICARE}
          specialityList={specialityList}
          cptList={cptList}
          unitFormList={unitFormList}
          selectedItem={selectedItem}
          reportData={reportInfo}
          typeList={structureTable.map(item => item.name)}
          onConfirm={handleMedicareCost}
          onCancel={handleAddMedicareCost}
        />
      )}
      {modalDelete && (
        <DeleteModal
          isOpen={modalDelete}
          title="Delete Medicare Item"
          content={selectedItem && selectedItem.name}
          onConfirm={handleDelete}
          onCancel={handleOpenDeleteModal}
        />
      )}
    </Row>
  );
};

export default MedicalCostLCP;
