import React from 'react';
import { Checkbox } from 'antd';

import './waterMarkCheck.scss';

const WaterMarkCheck = ({ checked, onChange, customClass }) => {
  return (
    <div className={`check_watermark ${customClass ? customClass : ''}`}>
      Add Watermark
      <Checkbox checked={checked} onChange={onChange} />
    </div>
  );
};

export default WaterMarkCheck;
