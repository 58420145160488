import React from 'react';
import { useSelector } from 'react-redux';

// Components
import { CardAssignSetting } from './Components';

const AssignSalesRep = () => {
  const { roles } = useSelector(state => state.auth);

  const salesRep = roles?.user_roles?.roles[0]?.user_roles?.sales_rep || {};

  return (
    <>
      {Object.values(salesRep).some(item => item !== null) && (
        <CardAssignSetting
          name={`${salesRep?.given_name || ''} ${salesRep?.family_name || ''}`}
          type="Sales Manager"
          email={salesRep?.email}
          phone={salesRep?.locale}
        />
      )}
    </>
  );
};

export default AssignSalesRep;
