import React from 'react';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import { ModalVideo } from 'components/Modal/ModalVideo';

const ShowTutorial = ({ data, close }) => {
  if (data?.isVideo) return <ModalVideo video={data.url} close={close} />;

  return <ModalPdf path={data.url} close={close} expand />;
};

export default ShowTutorial;
