import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Button, Select, Icon, DatePicker, Modal } from 'antd';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import isAllowed, { isClient, ROLES_DIVISIONS } from '../../../layout/roles';
import InputBox from 'components/InputBox';
import InputCurrencyBox from 'components/InputCurrencyBox';
import { BackIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import './EditClaimant.scss';
import { sortArrayObjectForKey, formatMoneyToNumber } from 'utils/utils';
// import InlineContact from 'components/InlineContact';
// import dynamicData from 'dynamicData';
// import Address from 'components/Address';
// import ClaimantInfo from 'components/ClaimantInfoOnWizard/ClaimantInfo';

const { Title } = Typography;
const { Option } = Select;

class EditClaimant extends Component {
  constructor(props) {
    super(props);

    let claimantInfo = {};
    if (this.props.location && this.props.location.state) {
      claimantInfo = this.props.location.state.data;
    }

    let getAddress = claimantInfo.address.split(',');
    if (getAddress.length > 3) {
      getAddress.length = getAddress.length - 3;
    }
    const correctAddress = getAddress;

    this.state = {
      isClient: isClient(this.props.roles),
      id: claimantInfo.id,
      client_id: claimantInfo.client_id,
      claimant_name: claimantInfo.claimant_name,
      claimant_middle_name: claimantInfo.claimant_middle_name,
      claimant_last_name: claimantInfo.claimant_last_name,
      claimant_title: claimantInfo.claimant_title,
      claimant_email: claimantInfo.claimant_email,
      claimant_phone: claimantInfo.claimant_phone,
      dob: moment(claimantInfo.dob),
      SSN: claimantInfo.SSN,
      HICN: claimantInfo.HICN || '',
      Gender: claimantInfo.Gender,
      rated_age: claimantInfo.rated_age,
      race_id: claimantInfo.race_id || undefined,
      address: correctAddress,
      additional_addres: claimantInfo.additional_addres,
      loadingTitle: 'Loading...',
      loading: false,
      isNurseManager: isAllowed(props.roles, ROLES_DIVISIONS.SUPERMANGER),
      juridiction_list: [],
      zip_code: claimantInfo.zip_code || '',
      state: claimantInfo.state || '',
      city: claimantInfo.city || '',
      insurance_carrier: claimantInfo.insurance_carrier || '',
      // insuranceList: [],
      claim_number: claimantInfo.claim_number || '',
      policy_limits: claimantInfo.policy_limits || '',
      present_value_case: claimantInfo.present_value_case || '',
    };
  }

  getAddress = address => {
    this.setState({ address });
  };
  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleEditClaimant = () => {
    const {
      id,
      client_id,
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      SSN,
      HICN,
      Gender,
      rated_age,
      race_id,
      address,
      additional_addres,
      zip_code,
      state,
      city,
      insurance_carrier,
      claim_number,
      policy_limits,
      present_value_case,
    } = this.state;

    const param = {
      id,
      client_id,
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      SSN,
      HICN,
      Gender,
      rated_age,
      race_id: race_id ? race_id : null,
      address: `${address},${city},${state},${zip_code}`,
      additional_addres,
      zip_code,
      state,
      city,
      insurance_carrier: insurance_carrier,
      claim_number,
      policy_limits: formatMoneyToNumber({ money: policy_limits }),
      present_value_case,
    };

    this.setState({ loadingTitle: 'Saving...', loading: true });
    let validationSuccess = this.validateRequired();
    if (validationSuccess) {
      API.updateClaimant(param)
        .then(res => {
          this.setState({ loading: false });
          Modal.success({
            content: 'Success',
            onOk: () => this.props.history.goBack(),
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          const errorMessage = error.response.data.reason ? error.response.data.reason[0].message : 'Error';
          Modal.error({
            content: errorMessage,
          });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  validateRequired = () => {
    const { claimant_name, claimant_last_name, claimant_title, dob, SSN, claimant_phone } = this.state;
    if (!claimant_title) {
      Modal.error({
        content: 'Title must not be empty',
      });
      return false;
    } else if (!claimant_name) {
      Modal.error({
        content: 'First name must not be empty',
      });
      return false;
    } else if (!claimant_last_name) {
      Modal.error({
        content: 'Last name must not be empty',
      });
      return false;
    } else if (dob === moment()) {
      Modal.error({
        content: 'Please enter correct date of birth',
      });
      return false;
    } else if (!SSN || SSN.includes('_')) {
      Modal.error({
        content: 'Please enter SSN correctly',
      });
      return false;
    } else if (!claimant_phone || claimant_phone.includes('_')) {
      Modal.error({
        content: 'Please enter Phone Number correctly',
      });
      return false;
    }
    return true;
  };

  componentDidMount = () => {
    API.getJuridictionStateList().then(res => {
      this.setState({ juridiction_list: sortArrayObjectForKey({ array: res.juridictionStates, key: 'name' }) });
    });

    // this.getContactLists();
  };

  // getContactLists = () => {
  //   const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
  //   const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

  //   const tenantOnly = isUserNurseManager && !isUserAdmin;

  //   //Getting all contacts data
  //   API.getContactsPublic('', 0, 1000, tenantOnly).then(res => {
  //     const dynamic = dynamicData();

  //     this.setState({
  //       contactsList: res.contacts,
  //       insuranceList: res.contacts.filter(
  //         item => (item.contact_type ? item.contact_type.name : '') === dynamic.insurance_carrier
  //       ),
  //     });
  //   });
  // };

  render() {
    const {
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      SSN,
      HICN,
      Gender,
      rated_age,
      address,
      additional_addres,
      loadingTitle,
      loading,
      isNurseManager,
      juridiction_list,
      zip_code,
      state,
      city,
      insurance_carrier,
      // insuranceList,
      claim_number,
      policy_limits,
      present_value_case,
    } = this.state;

    const isNotClient = !isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT);

    const isFormValid =
      claimant_name &&
      claimant_last_name &&
      claimant_title &&
      claimant_email &&
      claimant_phone &&
      dob &&
      SSN &&
      Gender &&
      address &&
      city &&
      state &&
      zip_code;

    return (
      <Row className="edit-claimant-container newClaimantStyle">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Edit Claimant
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Title level={4} className="title-text">
                Claimant Information
              </Title>

              <div className="addClaimantContainer">
                <div className="addClaimant_col2LeftSmall">
                  <InputBox
                    label="Title"
                    value={claimant_title}
                    onChange={value => this.setState({ claimant_title: value })}
                    classes="addClaimantInput"
                    placeholder="ie. Mr."
                  />
                  <InputBox
                    label="First Name"
                    value={claimant_name}
                    onChange={value => this.setState({ claimant_name: value })}
                    classes="addClaimantInput"
                  />
                </div>

                <div className="addClaimant_col2LeftSmall">
                  <InputBox
                    label="Middle Name"
                    placeholder="Optional"
                    value={claimant_middle_name}
                    onChange={value => this.setState({ claimant_middle_name: value })}
                    classes="addClaimantInput"
                  />
                  <InputBox
                    label="Last Name"
                    value={claimant_last_name}
                    onChange={value => this.setState({ claimant_last_name: value })}
                    classes="addClaimantInput"
                  />
                </div>

                <div className="addClaimant_label">
                  <p>Date of Birth</p>
                  <DatePicker
                    className="addClaimantDatePicker"
                    format="MM/DD/YYYY"
                    allowClear={false}
                    value={dob}
                    placeholder="DD / MM / YYYY"
                    onChange={(date, dateString) => this.handleSetValue('dob', date)}
                  />
                </div>

                <div className={isNotClient ? 'addClaimant_col2LeftSmall' : ''}>
                  {isNotClient && (
                    <div className="addClaimant_label">
                      <p>Rated Age</p>
                      <InputMask
                        className="addClaimantInput"
                        placeholder="Optional"
                        value={rated_age || ''}
                        onChange={e => this.setState({ rated_age: e.target.value }, this.createFinalAddress)}
                      />
                    </div>
                  )}
                  <div className="addClaimant_label">
                    <p>Gender</p>
                    <Select
                      showSearch
                      placeholder="Gender"
                      optionFilterProp="children"
                      className="dropdown addClaimant_select"
                      value={Gender || ''}
                      onChange={value => this.handleSetValue('Gender', value)}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      <Option disabled value={''}>
                        Required
                      </Option>
                      <Option value={'Male'}>Male</Option>
                      <Option value={'Female'}>Female</Option>
                    </Select>
                  </div>
                </div>

                <div className="addClaimant_label">
                  <p>SSN</p>
                  <InputMask
                    mask="999-99-9999"
                    placeholder="Required"
                    className="addClaimantInput"
                    value={SSN}
                    onChange={e => this.setState({ SSN: e.target.value })}
                  />
                </div>

                <InputBox
                  label="MBI"
                  placeholder="Optional"
                  classes="addClaimantInput"
                  value={HICN}
                  onChange={value => this.setState({ HICN: value })}
                />

                <div className="addClaimant_label">
                  <p>Phone</p>
                  <InputMask
                    mask="999-999-9999"
                    className="addClaimantInput"
                    placeholder="Required"
                    value={claimant_phone}
                    onChange={e => this.setState({ claimant_phone: e.target.value })}
                  />
                </div>

                <InputBox
                  label="Email"
                  value={claimant_email}
                  onChange={value => this.setState({ claimant_email: value })}
                  classes="addClaimantInput"
                />

                <div className="addClaimant_colAll">
                  <InputBox
                    label="Address Line 1"
                    value={address}
                    onChange={value => this.setState({ address: value }, this.createFinalAddress)}
                    classes="addClaimantInput"
                  />
                </div>

                <div className="addClaimant_colAll">
                  <InputBox
                    label="Additional Address"
                    placeholder="Optional"
                    value={additional_addres}
                    onChange={value => this.setState({ additional_addres: value }, this.createFinalAddress)}
                    classes="addClaimantInput"
                  />
                </div>

                <InputBox
                  label="City"
                  value={city}
                  classes="addClaimantInput"
                  onChange={value => this.setState({ city: value }, this.createFinalAddress)}
                />
                <div className="addClaimant_col2">
                  <div className="addClaimant_label">
                    <p>State</p>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown addClaimant_select"
                      value={state}
                      onChange={value => {
                        this.setState({ state: value }, this.createFinalAddress);
                      }}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      <Option disabled value={''}>
                        Required
                      </Option>
                      {juridiction_list.map((el, i) => {
                        return (
                          <Option key={i} value={el.name}>
                            {el.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="addClaimant_label">
                    <p>Zip Code</p>
                    <InputMask
                      mask="99999"
                      className="addClaimantInput"
                      placeholder="Required"
                      value={zip_code}
                      onChange={e => this.setState({ zip_code: e.target.value }, this.createFinalAddress)}
                    />
                  </div>
                </div>
              </div>

              <Title level={4} className="title-text addClaimantMt_4">
                Claim Information
              </Title>

              <div className="addClaimantContainer">
                <InputBox
                  label="Claim #"
                  value={claim_number}
                  onChange={value => this.setState({ claim_number: value })}
                  classes="addClaimantInput"
                  placeholder="Required"
                />

                <InputBox
                  label="Attorney Estimated Value of Case"
                  placeholder="Optional"
                  value={present_value_case}
                  onChange={value => this.setState({ present_value_case: value }, this.createFinalAddress)}
                  classes="addClaimantInput"
                  type="number"
                />

                <div className="addClaimant_label">
                  <InputBox
                    label="Insurance carrier"
                    value={insurance_carrier}
                    onChange={value => this.setState({ insurance_carrier: value })}
                    classes="addClaimantInput"
                  />
                </div>

                <InputCurrencyBox
                  label="Policy Limits"
                  placeholder="Please enter"
                  value={policy_limits}
                  onChange={value => this.setState({ policy_limits: value })}
                  classes="currency-claimant-input"
                />
              </div>

              <div className="addClaimant_colAll addClaimant_button">
                <Button
                  type="primary"
                  size="large"
                  disabled={!isFormValid}
                  className="green-btn button"
                  onClick={this.handleEditClaimant}>
                  Save Changes
                </Button>
              </div>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {})(EditClaimant);
