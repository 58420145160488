import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Spin, Button, Typography, Tooltip } from 'antd';
import { COLUMNS_TABLE_LIFE_CARE_PLAN } from './columns';
import * as API from 'utils/api';
import { CloseIcon } from 'components/Svg';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import {
  TEMPLATE_CREATE_COLOR_NAV,
  TEMPLATE_CREATE_STRUCTURE_NAV,
  TEMPLATE_EDIT_COLOR_NAV,
  TEMPLATE_EDIT_STRUCTURE_NAV,
  TEMPLATE_NAV,
} from 'layout/navigate_const';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { REPORT_LIFE_CARE_PLAN_NAME } from 'constants/reports';
import { ModalCreateTable } from '../components/ModalCreateTable';
import { LIST_NO_EDIT_TABLE } from '../const/itemsTableDragable';

import './templateLifeCarePlanTable.scss';
import { TemplateLifeCarePlanSectionTableContent } from '../components/TemplateLifeCarePlanSectionTableContent';
import { ACTION_TYPE_ADD_MEDICARE, LCP_MEDICARE } from 'components/MedicareCostModal/data';
import MedicareCostModal from 'components/MedicareCostModal';
import { getPosition } from 'utils/shared';
import { LCPMedicareCostTableContent } from 'components/LCPMedicareCostTable';
import DeleteModal from 'components/DeleteModal';

const { Title } = Typography;

const TemplateLifeCarePlanTable = () => {
  const { idReport } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [modalCreate, setModalCreate] = useState(false);
  const [newTable, setNewTable] = useState({});
  const [listTable, setListTable] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [actionType, setActionType] = useState(ACTION_TYPE_ADD_MEDICARE);
  const [modalMedicareCost, setModalMedicareCost] = useState(false);
  const [cptList, setCptList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [unitFormList, setUnitFormList] = useState([]);
  const [reportInfo, setReportInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);

  const isEdit = location.pathname.includes('edit');

  useEffect(() => {
    (async () => {
      try {
        setLoadingPage(true);
        await Promise.all([
          getTable({ loading: false }),
          getSpecialityList(),
          getUnitForms(),
          getReport(),
          getDataTable(),
          clearNewTable(),
        ]);
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoadingPage(false);
      }
    })();
  }, []);

  const getDataTable = async () => {
    try {
      const getDataTable = await API.getMedicareDataOri(idReport);
      setDataTable(getDataTable.medicare_cost_projections);
    } catch (e) {
      notifyApiError(e);
    }
  };
  const getTable = async ({ loading = true } = {}) => {
    try {
      if (loading) setLoadingPage(true);
      const res = await API.findTemplateLifeCarePlanTable({ idReport });

      if (!res.success) return;

      const orderTable = res.note.rows.sort(function (a, b) {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });

      setListTable(orderTable);
    } catch (err) {
      notifyApiError(err);
    } finally {
      if (loading) setLoadingPage(false);
    }
  };

  const clearNewTable = () => {
    const columns = {};
    COLUMNS_TABLE_LIFE_CARE_PLAN.forEach(item => {
      columns[item.field] = true;
    });

    setNewTable({
      name: '',
      report_id: idReport,
      ...columns,
    });
  };

  const toggleModalCreate = () => setModalCreate(prevState => !prevState);

  const handleDeleteTable = async ({ idTable }) => {
    try {
      setLoadingPage(true);
      const table = listTable.find(item => item.id === idTable);

      await API.deleteTemplateLifeCarePlanTable({ idTable, reportId: idReport, tableType: table.name });
      await getTable({ loading: false });
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleEditTable = ({ table }) => {
    toggleModalCreate();
    setNewTable(table);
  };

  const handleUpdateList = async () => {
    await getTable();
  };

  const handleAddMedicareCost = ({ actionType = ACTION_TYPE_ADD_MEDICARE, selectedItem } = {}) => {
    if (selectedItem) setSelectedItem(selectedItem);

    setActionType(actionType);
    setModalMedicareCost(prevState => !prevState);
  };

  const searchCodes = async value => {
    const getCptList = await API.getCPTData(value);
    setCptList(getCptList.cpt_codes);
  };

  const getSpecialityList = async () => {
    const res = await API.getSpeciality();
    setSpecialityList(res.specialities);
  };

  const getUnitForms = async () => {
    const res = await API.getUnitForms();
    setUnitFormList(res.unit_forms);
  };

  const getReport = async () => {
    const report = await API.getReportById(idReport, true);
    setReportInfo(report);
  };

  const handleMedicareCost = async params => {
    setLoadingPage(true);

    if (actionType === ACTION_TYPE_ADD_MEDICARE) {
      try {
        handleAddMedicareCost();
        setLoadingPage(true);
        const body = {
          ...params,
          position: getPosition(listTable, params.type),
          report_id: idReport,
        };

        await API.createMedicareItem(body);
        await getTable();
        notifyInfo('Medicare Cost Created successfully');
        await getDataTable();
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoadingPage(false);
      }
    } else {
      try {
        handleAddMedicareCost();
        setLoadingPage(true);
        const param = {
          ...params,
          id: selectedItem.id,
        };
        await API.updateMedicareItem(param);
        await getDataTable();
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoadingPage(false);
      }
    }
  };

  const handleOpenDeleteModal = selectedItem => {
    if (selectedItem) setSelectedItem(selectedItem);

    setModalDelete(prevState => !prevState);
  };

  const handleDelete = async () => {
    try {
      handleOpenDeleteModal();
      setLoadingPage(true);

      await API.deleteMedicareItem(selectedItem.id);
      await getDataTable();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleClone = async item => {
    try {
      setLoadingPage(true);

      let position = getPosition(listTable, item.type);
      let newItem = { ...item, position };
      delete newItem.id;
      delete newItem.updatedAt;
      delete newItem.createdAt;
      delete newItem.deletedAt;
      delete newItem.cpt_code;

      await API.createMedicareItem(newItem);
      await getDataTable();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <>
      <Spin spinning={loadingPage}>
        <Row className="clients-container">
          <Row type="flex" align="middle" className="header templateLifeCarePlan_header">
            <Col className="title">
              <Title level={4} className="title-text">
                {REPORT_LIFE_CARE_PLAN_NAME}
              </Title>
            </Col>
            <Col className="closeTemplate" onClick={() => history.push(TEMPLATE_NAV.path)}>
              <CloseIcon color={PRIMARY_LIGHT_BLUE} /> Close
            </Col>
            <Col className="pagination add-button"></Col>
          </Row>

          <div className="table-content templateLifeCarePlanTable">
            <div className="templateLifeCarePlanTable_header">
              <Title level={4} className="title-text">
                Review Tables
              </Title>

              {listTable.length > 0 && (
                <Row type="flex" justify="end" className="row">
                  <Tooltip title="Add Medical Cost item">
                    <Button type="primary" size="default" className="green-btn button" onClick={handleAddMedicareCost}>
                      Add Medical Cost
                    </Button>
                  </Tooltip>
                </Row>
              )}
            </div>

            {listTable.length > 0 ? (
              <div className="templateLifeCarePlanTable_list">
                {listTable.map(table => (
                  <React.Fragment key={table.id}>
                    <TemplateLifeCarePlanSectionTableContent
                      data={table}
                      onDelete={handleDeleteTable}
                      onEdit={!LIST_NO_EDIT_TABLE.includes(table.name) && handleEditTable}>
                      <LCPMedicareCostTableContent
                        data={dataTable.filter(item => item.type === table.name)}
                        report={reportInfo}
                        canEdit
                        handleEdit={(action, item) => handleAddMedicareCost({ actionType: action, selectedItem: item })}
                        handleDelete={handleOpenDeleteModal}
                        handleClone={handleClone}
                        structure={table}
                      />
                    </TemplateLifeCarePlanSectionTableContent>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className="templateLifeCarePlanTable_empty">
                <Title level={4}>currently there is no table.</Title>
              </div>
            )}

            <div className="templateLifeCarePlanTable_endBtn">
              <Button
                type="primary"
                size="default"
                className="transparent-btn button"
                onClick={() =>
                  history.push(
                    isEdit
                      ? `${TEMPLATE_EDIT_STRUCTURE_NAV.path}/${idReport}`
                      : `${TEMPLATE_CREATE_STRUCTURE_NAV.path}/${idReport}`
                  )
                }>
                PREVIOUS STEP
              </Button>

              <Button
                type="primary"
                size="default"
                className="green-btn button"
                onClick={() =>
                  history.push(
                    isEdit
                      ? `${TEMPLATE_EDIT_COLOR_NAV.path}/${idReport}`
                      : `${TEMPLATE_CREATE_COLOR_NAV.path}/${idReport}`
                  )
                }>
                Next Step
              </Button>
            </div>
          </div>
        </Row>
      </Spin>

      {modalCreate && (
        <ModalCreateTable
          onClose={() => {
            toggleModalCreate();
            clearNewTable();
          }}
          editTable={newTable}
          onUpdaleList={handleUpdateList}
        />
      )}

      {modalMedicareCost && (
        <MedicareCostModal
          searchCodes={searchCodes}
          isOpen={modalMedicareCost}
          actionType={actionType}
          modalType={LCP_MEDICARE}
          specialityList={specialityList}
          cptList={cptList}
          unitFormList={unitFormList}
          selectedItem={selectedItem}
          reportData={reportInfo}
          typeList={listTable.map(item => item.name)}
          onConfirm={handleMedicareCost}
          onCancel={handleAddMedicareCost}
        />
      )}

      {modalDelete && (
        <DeleteModal
          isOpen={modalDelete}
          title="Delete Medicare Item"
          content={selectedItem && selectedItem.name}
          onConfirm={handleDelete}
          onCancel={handleOpenDeleteModal}
        />
      )}
    </>
  );
};

export default TemplateLifeCarePlanTable;
