import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RemoveIcon, EditIcon } from 'components/Icons';
import { Spin, Switch } from 'antd';
import * as API from 'utils/api';
import { Row, Col, Button, Typography, Divider, Select, Modal } from 'antd';
import InputBox from 'components/InputBox';
import InputMask from 'react-input-mask';
import { validateEmail, validatePhone, validateName } from 'utils/utils';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { DeleteUserModal } from 'components/Modal/DeleteUserModal';
import {
  EXTERNAL_LITIGATION_ATTORNEY_ID,
  ROL_ID_BY_NAME,
  DISPLAY_ROLE_EXTERNAL_LITIGATION_ATTORNEY,
} from 'layout/roles';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { useSelector } from 'react-redux';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';
import { getPermissionsBySection, USERS_PERMISSIONS_NAME } from 'permissions';
import '../EditClient.scss';
import { UploadFileCVUser } from 'utils/upload';

const { Text } = Typography;
const { Option } = Select;

const CREATE_EDIT_INIT = {
  open: false,
  data: {},
};

const UsersCreate = ({ data, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [listRoles, setListRoles] = useState([]);
  const [given_name, setGivenName] = useState(data?.given_name || '');
  const [family_name, setFamilyName] = useState(data?.family_name || '');
  const [locale, setLocale] = useState(data?.locale || '');
  const [email, setEmail] = useState(data?.email || '');
  const [role_id, setRole_id] = useState('');
  const [listFiles, setListFiles] = useState([]);
  const [oldFile, setOldFile] = useState(null);
  const [visibility, setVisibility] = useState(true);

  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { USERS_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [USERS_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    (async () => {
      try {
        const roles = await API.getNurseRoles();

        if (data?.id) {
          const user = await API.GetUserRoles(data.id);
          setVisibility(!!user.user_roles.roles[0].user_roles.visibility);
        }

        const availableRole = roles.roles.filter(rol => rol.name.toLowerCase().includes('client'));

        if (USERS_PERMISSIONS.createLitigation)
          availableRole.push(roles.roles.find(rol => rol.id === EXTERNAL_LITIGATION_ATTORNEY_ID));

        if (data?.role_name) setRole_id(availableRole.find(rol => rol.display_name === data.role_name).id);

        if (data && data.role_name === DISPLAY_ROLE_EXTERNAL_LITIGATION_ATTORNEY) {
          const fileCv = await API.getFileByUser({ path: 'CV', userId: data.id });
          setListFiles(fileCv ? [fileCv] : []);
        }

        setListRoles(availableRole);
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    onSubmit({
      data: {
        given_name,
        family_name,
        locale,
        email,
        role_id,
        cv: listFiles[0],
        visibility,
      },
      deleteFile: oldFile,
      idEdit: data?.id || null,
    });
  };

  const beforeUpload = file => {
    if (!file) return false;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Modal.error({
        content: 'You can only upload PDF file!',
      });
    }

    return isPdf;
  };

  const handleAddFiles = ({ file }) => {
    if (listFiles.length > 0 && listFiles[0]?.id && !oldFile) setOldFile(listFiles[0]);

    setListFiles([file]);
  };

  const removeAllFiles = () => {
    if (listFiles.length > 0 && listFiles[0]?.id && !oldFile) setOldFile(listFiles[0]);
    setListFiles([]);
  };

  const isFormValid =
    given_name &&
    family_name &&
    role_id &&
    validateEmail(email) &&
    validatePhone(locale) &&
    validateName(given_name) &&
    validateName(family_name);

  const disabledField = isAllowed(roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

  return (
    <Spin spinning={loading}>
      <form onSubmit={handleSubmit} className="form-create-user">
        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col md={11} className="row">
            <InputBox label="First Name" value={given_name} onChange={value => setGivenName(value)} />
          </Col>
          <Col md={11} className="row">
            <InputBox label="Last Name" value={family_name} onChange={value => setFamilyName(value)} />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col md={11} className="row">
            <InputBox label="Email" value={email} disabled={disabledField} onChange={value => setEmail(value)} />
          </Col>
          <Col md={11} className="row">
            <Row className="label">
              <Text>Phone</Text>
            </Row>
            <Row>
              <InputMask
                mask="999-999-9999"
                placeholder="Optional"
                className="input-ssn"
                value={locale}
                onChange={e => setLocale(e.target.value)}
              />
            </Row>
          </Col>
        </Row>
        <Col span={24}>
          <Row className="row" style={{ marginBottom: '10px' }}>
            <Text className="label">Roles</Text>
          </Row>
          <Row>
            <Select
              disabled={disabledField}
              value={role_id}
              placeholder="Required"
              onChange={value => setRole_id(value)}
              style={{ width: '100%' }}>
              {listRoles.map(rol => (
                <Option key={rol.id} value={rol.id}>
                  {rol.display_name}
                </Option>
              ))}
            </Select>
          </Row>
        </Col>

        {role_id === EXTERNAL_LITIGATION_ATTORNEY_ID && (
          <>
            <Col span={24} className="userRoles_section">
              <Text className="label">Visible at MSA Attorney's List</Text>
              <br />
              <Switch
                className="litigation_switch"
                defaultChecked={visibility}
                checked={visibility}
                onChange={() => setVisibility(prevState => !prevState)}
              />
            </Col>
            <Col span={24}>
              <Text className="label">CV</Text>
              <BoxDragAndDrop multiple beforeUpload={beforeUpload} handleAddFiles={handleAddFiles} />

              {listFiles.length > 0 && (
                <TableFileBoxDragAndDrop
                  listFiles={listFiles}
                  removeFile={removeAllFiles}
                  removeAllFiles={removeAllFiles}
                />
              )}
            </Col>
          </>
        )}

        <Divider className="divider-space" />

        <Row type="flex" justify="space-between" className="confirm-button">
          <Col md={11} className="row" justify="center" align="end">
            <Button
              type="primary"
              size="large"
              className="green-btn button"
              disabled={!isFormValid}
              onClick={handleSubmit}>
              {data?.id ? 'Update User' : 'Create User'}
            </Button>
          </Col>
          <Col md={11} justify="center" className="row">
            <Button type="primary" size="large" className="transparent-btn" onClick={onClose}>
              cancel
            </Button>
          </Col>
        </Row>
      </form>
    </Spin>
  );
};

const UsersTab = () => {
  const history = useHistory();
  const clientInfo = history.location.state?.data || {};
  const [loading, setLoading] = useState(true);
  const [listUsers, setListUsers] = useState([]);
  const [createOrEdit, setCreateOrEdit] = useState(CREATE_EDIT_INIT);
  const [selectUserDelete, setSelectUserDelete] = useState({
    open: false,
    user: null,
  });

  const getUsers = async () => {
    try {
      const res = await API.getUsersByClient({ idClient: clientInfo.id });
      setListUsers(res.rows);
      setLoading(false);
    } catch (e) {
      notifyApiError(e.message);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async data => {
    try {
      setLoading(true);
      await API.deleteUserSynchronised(data.id);
      await API.deleteUser(data.email);

      const updateListUser = listUsers.filter(user => user.id !== data.id);
      setListUsers(updateListUser);

      notifyInfo('User was deleted');
      setSelectUserDelete({});
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleAddUser = async ({ data }) => {
    try {
      setLoading(true);

      const { given_name, family_name, locale, email, role_id, cv, visibility } = data;

      const param = {
        given_name,
        family_name,
        locale,
        email,
        role_id,
        restrictions: 'restrictions',
        tenant_id: clientInfo.tenant_id,
      };

      const res = await API.createUser(param);

      param.user_id = res.data.User.Username;

      await API.synchroniseUser({ ...param, id: res.data.User.Username });

      const body = {
        user_id: param.user_id,
        client_id: clientInfo.id,
        role_id,
      };

      if (role_id === EXTERNAL_LITIGATION_ATTORNEY_ID) body.visibility = visibility;

      await API.AppendRoles([body]);

      // si eres litigation cargamos tu CV en caso de ser necesario
      if (cv && role_id === EXTERNAL_LITIGATION_ATTORNEY_ID) {
        await UploadFileCVUser({ file: cv, user_id: param.user_id });
      }

      await getUsers();

      notifyInfo('User was created');
      setCreateOrEdit(CREATE_EDIT_INIT);
    } catch (error) {
      const errorCondition = error.response.data.reason;
      const errorMessage = errorCondition ? errorCondition[0].message : 'Error';

      notifyApiError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = async ({ data, deleteFile }) => {
    try {
      const params = {
        email: data.email,
        given_name: data.given_name,
        family_name: data.family_name,
        locale: data.locale,
        'custom:restrictions': ',',
      };
      setLoading(true);
      // Si el rol al editar es diferente al que ya tenia lo actualizamos
      if (ROL_ID_BY_NAME[createOrEdit.data.role_name] !== data.role_id) {
        const old_role_id = ROL_ID_BY_NAME[createOrEdit.data.role_name];
        const new_role_id = data.role_id;
        const id_user = createOrEdit.data.id;
        const client_id = clientInfo.id;

        await API.DeleteRoles(id_user, old_role_id);
        await API.AddRole(id_user, new_role_id, { client_id });
      }

      await API.updateUser(params.email, params);
      await API.updateUserSynchronised({
        ...data,
        tenant_id: clientInfo.tenant_id,
      });

      if (deleteFile?.id) {
        await API.deleteFile(deleteFile.id);
      }

      if (data?.cv && !data.cv.id) await UploadFileCVUser({ file: data.cv, user_id: data.id });

      if (data.role_id === EXTERNAL_LITIGATION_ATTORNEY_ID)
        API.EditRole(data.id, EXTERNAL_LITIGATION_ATTORNEY_ID, {
          visibility: data.visibility,
        });

      await getUsers();
      setCreateOrEdit(CREATE_EDIT_INIT);
    } catch (e) {
      notifyApiError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = ({ data, idEdit, deleteFile }) => {
    if (idEdit) return handleEditUser({ data: { ...data, id: idEdit }, deleteFile });

    handleAddUser({ data });
  };

  const thereIsUsers = listUsers.length > 0;

  return (
    <Spin spinning={loading}>
      <div className="msa-stepContainer">
        {!createOrEdit.open && (
          <div className="stepFormContainerStep2">
            {thereIsUsers &&
              listUsers.map(item => (
                <div className="stepContainerContact" key={item.id}>
                  <div className="stepContactItem">
                    <h2>{`${item.given_name} ${item.family_name}`}</h2>
                  </div>
                  <div className="stepContactItemButtons">
                    <button>
                      <EditIcon
                        onClick={() =>
                          setCreateOrEdit({
                            open: true,
                            data: item,
                          })
                        }
                      />
                    </button>
                    <button onClick={() => setSelectUserDelete({ open: true, user: item })}>
                      <RemoveIcon />
                    </button>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Roles</p>
                    <p className="stepContactItemValue">{item.role_name || ''}</p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Company info</p>
                    <p className="stepContactItemValue">{item.company_info || ''}</p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Email</p>
                    <p className="stepContactItemValue">{item.email || ''}</p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Phone</p>
                    <p className="stepContactItemValue">{item.locale || ''}</p>
                  </div>
                </div>
              ))}

            <button
              className="addAdditionalContactButton margin-button"
              onClick={() =>
                setCreateOrEdit({
                  open: true,
                  date: null,
                })
              }>
              Add New User
            </button>
          </div>
        )}

        {createOrEdit.open && (
          <UsersCreate
            data={createOrEdit.data}
            onClose={() => setCreateOrEdit(CREATE_EDIT_INIT)}
            onSubmit={handleSubmit}
          />
        )}

        <DeleteUserModal
          open={selectUserDelete.open}
          onClose={() => setSelectUserDelete({})}
          onDelete={handleDelete}
          data={selectUserDelete.user || {}}
        />
      </div>
    </Spin>
  );
};

export default UsersTab;
