import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from '../containers/Auth/Login';
import SendRequest from '../containers/Auth/SendRequest';
import CheckEmail from '../containers/Auth/CheckEmail';
import SetPassword from '../containers/Auth/SetPassword';
import ConfirmSet from '../containers/Auth/ConfirmSet';
import ResetPassword from '../containers/Auth/ResetPassword';
import ConfirmReset from '../containers/Auth/ConfirmReset';
import Terms from '../containers/Auth/Terms';
import Signup from '../containers/Auth/SignUp/Signup';
import RightBar from 'containers/RightSidebar/RightBar';
import WebsocketWrapper from '../layout/WebsocketWrapper';
import ChatBar from '../containers/RightSidebar/ChatBar';
import LoginClient from '../containers/Auth/LoginClient';
import { TenantCreate } from 'containers/Tenant/tenantCreate';

import { MAIN_NAV, TENANT_CREATE } from 'layout/navigate_const';

export default class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <RightBar />
        <ChatBar />
        <Route path="/" exact render={() => <Redirect to={MAIN_NAV.path} />} />
        <Route path="/app" exact render={() => <Redirect to={MAIN_NAV.path} />} />
        <ProtectedRoute path="/app" dispatch={this.props.dispatch} component={WebsocketWrapper} />
        <Route path="/login" component={Login} />
        <Route path="/Signup" component={Signup} />
        <Route path="/register/:tenant_id" component={LoginClient} />
        <Route path="/sendRequest" component={SendRequest} />
        <Route path="/checkEmail" component={CheckEmail} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/confirmReset" component={ConfirmReset} />
        <Route path="/setPassword" component={SetPassword} />
        <Route path="/confirmSet" component={ConfirmSet} />
        <Route path="/terms" component={Terms} />
        <Route path={TENANT_CREATE.path} component={TenantCreate} />
      </BrowserRouter>
    );
  }
}
