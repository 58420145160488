import { Col, Row, Select, Typography } from 'antd';
import { BackIcon } from 'components/Icons';
import InputBox from 'components/InputBox';
import SpinBox from 'components/SpinBox';
import React from 'react';

const { Title, Text } = Typography;

export default function UpdateReport(props) {
  let loading = false;
  return (
    <Row className="edit-claimant-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Edit Report Cabinet
          </Title>
        </Col>
        <Col className="back" onClick={() => props.history.goBack()}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <Row className="main">
        <SpinBox loading={loading}>
          <Row className="content">
            <Row type="flex" justify="space-between" align="middle" className="main-info">
              <Col md={24} className="row">
                <InputBox label="Report Name" />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="middle" className="main-info">
              <Col md={24} className="row">
                <InputBox label="Report Name" />
              </Col>
            </Row>
          </Row>
        </SpinBox>
      </Row>
    </Row>
  );
}
