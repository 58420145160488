import React, { Component } from 'react';
import { Row, Col, Typography, Button, Divider, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import SpinBox from 'components/SpinBox';
import DeleteModal from 'components/DeleteModal';
import { CloneIcon } from 'components/Icons';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import MedicareCostModal from 'components/MedicareCostModal';
import MedicareCostTable from 'components/MedicareCostTable';
import PrescriptionTable from 'components/PrescriptionTable';
import { getPosition, get2NewPositions } from 'utils/shared';
import { notifyApiError } from '../../../../utils/notification';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import { updateReportInfo } from '../../../../redux/report';
import RecalculateMedicareCost from 'components/RecalculateMedicareCost';
import moment from 'moment';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import './NonMedicareCost.scss';
import { REPORT_MSA_NAME, REPORT_SURGICAL_TYPE_ID } from 'constants/reports';
import {
  MSA_NON_MEDICARE,
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  FUTURE_MEDICAL_NEEDED,
  PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE,
  DONT_CHANGE_COST_ON_THIS_TYPE_2,
  PRESCRIPTION_MEDICATIONS_COVERED,
  DONT_CHANGE_COST_ON_THIS_TYPE,
  ACTION_TYPE_ADD_MEDICARE,
} from 'components/MedicareCostModal/data';

const { Title, Text } = Typography;
const { confirm } = Modal;

class NonMedicareCost extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      reportData: {},
      contactsList: [],
      medicareDataList: [],
      cptList: [],
      unitFormList: [],
      specialityList: [],
      actionType: ACTION_TYPE_ADD_MEDICARE,
      selectedItem: null,
      showMedicareModal: false,
      loading: false,
      showRecalculateModal: false,
      REPORT_PERMISSIONS,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getSpeciality().then(res => {
      this.setState({
        specialityList: res.specialities,
      });
    });
    API.getCPTData().then(res => {
      this.setState({
        cptList: res.cpt_codes,
      });
    });
    API.getReportById(report_id)
      .then(async res => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: res.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(res);
        if (!res.is_template) {
          verifyPermissions(res, this.props);
        }

        const resUpdate = moment(res.updatedAt).format('YYYY-MM-DDDD');
        const resCreate = moment(res.createdAt).format('YYYY-MM-DDDD');

        if (
          !res.is_template &&
          res.report_status_id === 1 &&
          res.report_template_id > 0 &&
          !res._medicare_costs &&
          !res.pricing_group_id &&
          !res.zip_url &&
          resUpdate === resCreate
        ) {
          this.setState({
            showRecalculateModal: true,
            is_template: res.is_template,
            reportData: res,
          });
        } else {
          this.setState({
            is_template: res.is_template,
            reportData: res,
          });
        }
      })
      .catch(err => forbiddenRedirect(err, this.props));
    API.getUnitForms().then(res => {
      this.setState({
        unitFormList: res.unit_forms,
      });
    });
    API.getMedicareData({ reportId: report_id }).then(res => {
      let beforePosition = -1;
      let medicareSorted = [
        ...res.medicare_cost_projections
          .sort((a, b) => a.position - b.position)
          .map(item => {
            const position =
              beforePosition !== -1 && item.position <= beforePosition ? beforePosition + 1 : item.position;
            beforePosition = position;
            return {
              ...item,
              position,
            };
          }),
      ];
      this.setState({
        medicareDataList: medicareSorted,
        loading: false,
      });
    });
  };

  openMedicareModal = (actionType, selectedItem) => {
    this.setState({
      actionType,
      selectedItem,
      showMedicareModal: true,
    });
  };

  closeMedicareModal = () => {
    this.setState({ showMedicareModal: false });
  };

  handleMedicareCost = params => {
    const { actionType, report_id, selectedItem } = this.state;

    this.closeMedicareModal();
    this.setState({ loading: true });
    if (actionType === ACTION_TYPE_ADD_MEDICARE) {
      let position = getPosition(this.state.medicareDataList, params.type);
      const param = {
        position,
        ...params,
        report_id,
      };
      API.createMedicareItem(param)
        .then(res => {
          API.getMedicareData({ reportId: report_id }).then(res => {
            this.setState({
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch(err => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: 'Error',
              });
            }
          );
        });
    } else {
      const param = {
        ...params,
        id: selectedItem.id,
      };
      API.updateMedicareItem(param)
        .then(res => {
          API.getMedicareData({ reportId: report_id }).then(res => {
            this.setState({
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch(err => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: 'Error',
              });
            }
          );
        });
    }
  };

  handleEdit = () => {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.updateMedicareItem(selectedItem.id)
      .then(res => {
        API.getMedicareData(report_id, selectedItem).then(res => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch(err => {
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: 'Error',
            });
          }
        );
      });
  };

  handleDelete = () => {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.deleteMedicareItem(selectedItem.id).then(res => {
      API.getMedicareData({ reportId: report_id }).then(res => {
        this.setState({
          medicareDataList: res.medicare_cost_projections,
          loading: false,
        });
      });
    });
  };

  openDeleteModal = selectedItem => {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  searchCodes = value => {
    API.getCPTData(value).then(res => {
      this.setState({
        cptList: res.cpt_codes,
      });
    });
  };

  handleChangePosition = (id, move) => {
    let result = get2NewPositions(this.state.medicareDataList, id, move);
    if (result.success) {
      let Promise1 = API.updateMedicareItem(result.update1);
      let Promise2 = API.updateMedicareItem(result.update2);
      Promise.all([Promise1, Promise2]).then(() => {
        // item.classList.remove("animate-row");
        // item = document.getElementById("table-item-" + id);
        // item.classList.add("animate-row");
        this.setState({ medicareDataList: result.newArr }, () => {
          const item2 = document.getElementById('table-item-' + result.update1.id + result.update1.position);
          const item1 = document.getElementById('table-item-' + result.update2.id + result.update2.position);

          item2.classList.remove('animate-' + (move === 'up' ? 'down' : 'up'));
          item1.classList.remove('animate-' + move);

          return;
        });
      });
    }
  };

  handleClone = item => {
    if (this.state.loadingClone) {
      alert('Cloning in Process, please wait! ');
      return;
    }
    this.setState({ loadingClone: true });
    const { report_id } = this.state;
    let position = getPosition(this.state.medicareDataList, item.type);
    let newItem = { ...item, position };
    delete newItem.id;
    delete newItem.updatedAt;
    delete newItem.createdAt;
    delete newItem.deletedAt;
    delete newItem.cpt_code;
    API.createMedicareItem(newItem)
      .then(res => {
        this.setState({ loadingClone: false });
        API.getMedicareData({ reportId: report_id }).then(res => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch(err => {
        this.setState({ loadingClone: false });
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: "Error, can't clone the item",
            });
          }
        );
      });
  };

  handleSeed = (id, isSeed, type) => {
    const params = { id: id, is_seed_calculated: !isSeed };
    API.updateMedicareItem(params).then(res => {
      if (type === 'futureList') {
        var futureList = this.state.medicareDataList.filter(item => {
          return item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE || item.type === FUTURE_MEDICAL_NEEDED;
        });
        for (var i = 0; i < futureList.length; i++) {
          if (futureList[i].id === id) {
            futureList[i].is_seed_calculated = !isSeed;
            this.setState({
              medicareDataList: [...this.state.medicareDataList, futureList],
            });
          }
        }
      } else if (type === 'surgeriesList') {
        var surgeriesList = this.state.medicareDataList.filter(item => {
          return item.type === DONT_CHANGE_COST_ON_THIS_TYPE || item.type === DONT_CHANGE_COST_ON_THIS_TYPE_2;
        });
        let i;
        for (i = 0; i < surgeriesList.length; i++) {
          if (surgeriesList[i].id === id) {
            surgeriesList[i].is_seed_calculated = !isSeed;
            this.setState({
              medicareDataList: [...this.state.medicareDataList, surgeriesList],
            });
          }
        }
      } else if (type === 'prescriptionList') {
        var prescriptionList = this.state.medicareDataList.filter(item => {
          return (
            item.type === PRESCRIPTION_MEDICATIONS_COVERED || item.type === PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE
          );
        });
        for (i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].id === id) {
            prescriptionList[i].is_seed_calculated = !isSeed;
            this.setState({
              medicareDataList: [...this.state.medicareDataList, prescriptionList],
            });
          }
        }
      }
    });
  };

  handleCopyFromMedicare = async () => {
    confirm({
      title: 'Are you sure you want to copy all data from medicare sections?',
      content: '',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: async () => {
        const { report_id } = this.state;
        this.setState({ loading: true });
        try {
          await API.cloneMedicareSection({ report_id });
          const res = await API.getMedicareData({ reportId: report_id });
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        } catch (e) {
          notifyApiError(e);
          this.setState({ loading: false });
        }
      },
    });
  };

  loadMedicare = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getMedicareData({ reportId: report_id }).then(res => {
      this.setState({
        medicareDataList: res.medicare_cost_projections,
        loading: false,
      });
    });
  };

  closeRecalculateModal = () => {
    this.setState({ showRecalculateModal: false });
  };

  render() {
    const {
      showMedicareModal,
      selectedItem,
      showDeleteModal,
      actionType,
      medicareDataList,
      cptList,
      unitFormList,
      specialityList,
      reportData,
      loading,
      showRecalculateModal,
      REPORT_PERMISSIONS,
    } = this.state;

    const futureList = medicareDataList.filter(item => {
      return item.type === FUTURE_MEDICAL_NEEDED;
    });
    const surgeriesList = medicareDataList.filter(item => {
      return item.type === DONT_CHANGE_COST_ON_THIS_TYPE_2;
    });
    const prescriptionList = medicareDataList.filter(item => {
      return item.type === PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE;
    });

    const { reportInfo } = this.props;
    const isStateNotSet =
      reportInfo.report_type_id !== REPORT_SURGICAL_TYPE_ID &&
      !reportInfo.is_template &&
      !reportInfo.juridiction_state_id &&
      !reportInfo.is_owcp;

    let showSettlement = '';
    if (reportInfo.pre_settlement) {
      showSettlement = 'Pre Settlement';
    }

    if (reportInfo.post_settlement) {
      showSettlement = 'Post Settlement';
    }

    return (
      <Row className="msa-non-medicare-cost-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown loading={loading} />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <Text className="title">Non-Medicare Cost Projection </Text>
                  <Tooltip placement="right" title="Copy data from medicare sections">
                    <CloneIcon className="icon" onClick={this.handleCopyFromMedicare} />
                  </Tooltip>
                </Col>
                <Col md={24}>
                  <Text className="title">{showSettlement}</Text>
                </Col>
              </Row>

              {REPORT_PERMISSIONS.edit && (
                <Row type="flex" justify="end" className="row">
                  <Tooltip
                    title={
                      isStateNotSet
                        ? 'Set State of Jurisdiction or Choose OWCP Pricing option on Demographic step '
                        : 'Add Medical Cost item'
                    }>
                    <Button
                      disabled={isStateNotSet}
                      type="primary"
                      size="default"
                      className="green-btn button"
                      onClick={() => this.openMedicareModal(ACTION_TYPE_ADD_MEDICARE, null)}>
                      Add Non-Medicare Cost
                    </Button>
                  </Tooltip>
                </Row>
              )}
              <Row className="section">
                <Row className="sub-title">
                  <Text>1. Medical Costs</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  data={futureList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                  handleSeed={(id, isSeed) => {
                    this.handleSeed(id, isSeed, 'futureList');
                  }}
                  canEdit={REPORT_PERMISSIONS.edit}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>2. Surgeries, Replacements and Procedures</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  data={surgeriesList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                  handleSeed={(id, isSeed) => {
                    this.handleSeed(id, isSeed, 'surgeriesList');
                  }}
                  canEdit={REPORT_PERMISSIONS.edit}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>3. Prescription Drug Data</Text>
                </Row>
                <PrescriptionTable
                  data={prescriptionList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                  handleSeed={(id, isSeed) => {
                    this.handleSeed(id, isSeed, 'prescriptionList');
                  }}
                  canEdit={REPORT_PERMISSIONS.edit}
                />
              </Row>
              <Divider className="divider" />
              <PrevNextStep />
            </Row>
          </SpinBox>
        </Row>
        <MedicareCostModal
          searchCodes={this.searchCodes}
          isOpen={showMedicareModal}
          actionType={actionType}
          modalType={MSA_NON_MEDICARE}
          specialityList={specialityList}
          cptList={cptList}
          unitFormList={unitFormList}
          selectedItem={selectedItem}
          reportData={reportData}
          onConfirm={this.handleMedicareCost}
          onCancel={this.closeMedicareModal}
        />
        <DeleteModal
          isOpen={showDeleteModal}
          title="Delete Non-Medicare Item"
          content={selectedItem && selectedItem.name}
          onConfirm={this.handleDelete}
          onCancel={this.closeDeleteModal}
        />
        <RecalculateMedicareCost
          reportData={reportInfo}
          isOpen={showRecalculateModal}
          medicareList={medicareDataList}
          onCancel={this.closeRecalculateModal}
          loadMedicare={this.loadMedicare}
          isMedicare={false}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  reportInfo: state.report.reportInfo,
  roles: state.auth.roles,
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, { updateReportInfo })(NonMedicareCost);
