import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getReportFiles } from 'redux/report';
import * as API from '../../utils/api';
import {
  // Spin,
  Empty,
  Button,
  // Radio,
  // Space,
  Collapse,
  // Tabs,
  Input,
  Row,
  Col,
  Typography,
  Select,
} from 'antd';
import { notifySuccess, notifyApiError, notifyInfo, notifyError } from 'utils/notification';
// import {
//   SettingOutlined,
//   RightSquareOutlined,
//   InfoCircleOutlined,
//   CloseOutlined,
//   BorderInnerOutlined,
// } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID, REPORT_MCP_TYPE_ID, REPORT_MSA_TYPE_ID } from 'constants/reports';

const { Search } = Input;
const {
  // Title,
  Text,
} = Typography;
const { Option } = Select;
function Odg({ getReportFiles }) {
  // const [openMenu, setOpenMenu] = useState(false);
  // const [placement, setPlacement] = useState('right');
  const [codes, setCodes] = useState([]);
  // const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  // const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [odgSearch, setOdgSearch] = useState('');
  const { reportInfo } = useSelector(state => state.report);
  const [searchedCodes, setSearchedCodes] = useState([]);
  const [icdCodeList, setIcdCodeList] = useState([]);
  const [joinReportLoading, setJoinReportLoading] = useState(false);
  const [allRelated_diadnosis, setAllRelated_diadnosis] = useState([]);
  let related_diagnosis = reportInfo.related_diagnosis
    ? reportInfo.related_diagnosis.split(',')
    : reportInfo.related_diagnosis;

  const isShowAllForThisReport = reportInfo.report_type_id === REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID;

  function callback(key) {}

  const asyncEffect = async () => {
    setLoading(true);

    const icdCodeData = await API.getIcdCodeData();

    setIcdCodeList([...icdCodeData.icd_codes]);

    if (isShowAllForThisReport) {
      related_diagnosis = icdCodeData.icd_codes.map(item =>
        JSON.stringify({
          code: item.code,
          med_descr: item.med_descr ? item.med_descr.replace(/,/g, '$') : '',
        }).replace(/,/g, '<')
      );

      setAllRelated_diadnosis(related_diagnosis);
    }

    if (related_diagnosis) {
      let promiseArr = [];
      related_diagnosis.map((el, index) => {
        let objEl = el.split('$').join(',').split('<').join(',');
        objEl = JSON.parse(objEl);
        // const Index = index;
        promiseArr.push(API.GetOdgGuidelines(objEl.code));
      });
      Promise.all(promiseArr).then(procs => {
        procs = procs.map(proc => getText(proc.data));
        setCodes([...procs]);
      });
    }
    // else if (isShowAllForThisReport) {
    //   let promiseArrLegalDemand = [];
    //   icdCodeData.icd_codes.forEach(item => {
    //     promiseArrLegalDemand.push(API.GetOdgGuidelines(item.code));
    //   });

    //   const allGet = await Promise.allSettled(promiseArrLegalDemand)
    //     .then(procs => procs)
    //     .catch(err => false);

    //   const procs = allGet.map(proc => getText(proc.value.data));
    //   setCodes(procs);
    // }
    setLoading(false);
  };

  React.useEffect(() => {
    asyncEffect();
    return () => {};
  }, [reportInfo.related_diagnosis]);

  const getText = data => {
    const regLink = /<a href="(.*?)">(.*)<\/a>/g;
    const regLinkNumber = /\(<a href="(.*?)">[0-9]{1,4}<\/a>\)/g;
    const regEG = /\(EG [0-9]{1,3}\)/g;
    return data.map(item => {
      const fullsumhtml = item.fullsumhtml.replace(regLinkNumber, '').replace(regLink, '$2').replace(regEG, '');
      return {
        ...item,
        fullsumhtml,
      };
    });
  };
  const search = e => {
    setLoading(true);
    API.GetOdgGuidelines(e)
      .then(res => {
        setLoading(false);
        if (res.data.length < 1) {
          notifyInfo('Not Found', 'No data found for this code');
        } else {
          const data = getText(res.data);
          setSearchedCodes([data]);
        }
      })
      .catch(err => {
        notifyError(err);
      });
  };
  const handleSearchIcdCodes = value => {
    API.getIcdCodeData(value).then(res => {
      let correctArr = [...res.icd_codes];
      setIcdCodeList([...correctArr]);
    });
  };
  const handleJoinToReport = code => {
    const { fullsumhtml = 'Not found', reltopichtml = 'Not found', odgcrithtml = 'Not found' } = code;

    const params = {
      summary: fullsumhtml,
      statement: reltopichtml,
      visits: odgcrithtml,
    };
    setJoinReportLoading(true);
    API.joinODGData(reportInfo.id, params)
      .then(res => {
        setJoinReportLoading(false);
        notifySuccess();
        getReportFiles(reportInfo.id);
      })
      .catch(res => {
        setJoinReportLoading(false);
        notifyApiError(res);
      });
  };

  const { Panel } = Collapse;
  if (codes.length === 0) {
    return (
      <div align="center">
        <Empty description={'No Data'} image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
      </div>
    );
  } else {
    return (
      <div>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="row"
          style={{ marginBottom: 30, marginTop: 20 }}>
          <Col md={24}>
            <Row className="label">
              <Text>Search New Diagnosis</Text>
            </Row>
            <Row>
              <Select
                className="dropdown"
                // optionFilterProp="filter"
                placeholder="Please enter"
                filterOption={false}
                showSearch={true}
                onSearch={value => {
                  if (value) {
                    handleSearchIcdCodes(value);
                  }
                }}
                onSelect={value => {
                  setOdgSearch(value);
                  let objEl = value.split('$').join(',').split('<').join(',');
                  objEl = JSON.parse(objEl);

                  search(objEl.code);
                }}
                onChange={value => {
                  // setOdgSearch(value);
                  // let objEl = value.split("$").join(",").split("<").join(",");
                  // objEl = JSON.parse(objEl);
                  // search(objEl.code);
                }}>
                {icdCodeList.map((item, index) => {
                  let value = JSON.stringify({
                    code: item.code,
                    med_descr: item.med_descr ? item.med_descr.replace(/,/g, '$') : '',
                  }).replace(/,/g, '<');
                  return (
                    <Option
                      key={index}
                      //filter={item.code.concat("_" + item.med_descr)}
                      value={value}>
                      {`${item.med_descr} (${item.code})`}
                    </Option>
                  );
                })}
              </Select>
            </Row>
          </Col>
        </Row>

        <Collapse onChange={callback}>
          {searchedCodes.length > 0 &&
            searchedCodes.map((el, index) => {
              let objEl = odgSearch.split('$').join(',').split('<').join(',');
              objEl = JSON.parse(objEl);

              if (searchedCodes[index] && searchedCodes[index].length < 1) {
                return <span></span>;
              }
              return (
                <Panel
                  style={{ border: '2px dashed #00D374', marginBottom: 10 }}
                  header={`${objEl.med_descr}(${objEl.code})`}
                  key={99999999999}>
                  <Search
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className="search-box"
                  />
                  <Collapse onChange={callback}>
                    {searchedCodes[index] && searchedCodes[index].length > 0 ? (
                      searchedCodes[index]
                        .filter(item => item.topic.toLowerCase().includes(searchQuery.toLowerCase()))
                        .map((code, index2) => {
                          return (
                            <Panel key={1000 * index + index2} header={code ? code.topic : ''}>
                              <b>
                                <h2>Full Summary</h2>
                              </b>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: code.fullsumhtml ? code.fullsumhtml : 'Not found',
                                }}></div>{' '}
                              <b>
                                <h2>Related Statement</h2>
                              </b>
                              {code.reltopictext ? code.reltopictext : 'Not found'}
                              <b>
                                <h2>Visits</h2>
                              </b>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: code.odgcrithtml ? code.odgcrithtml : 'Not found',
                                }}></div>
                              {(reportInfo.report_type.id === REPORT_MSA_TYPE_ID || reportInfo.report_type.id === REPORT_MCP_TYPE_ID) && (
                                <Row>
                                  <Button
                                    type="primary"
                                    className="green-btn add-icd-code_button"
                                    onClick={() => handleJoinToReport(code)}
                                    loading={joinReportLoading}>
                                    Join to Report
                                  </Button>
                                </Row>
                              )}
                            </Panel>
                          );
                        })
                    ) : (
                      <span></span>
                    )}
                  </Collapse>
                </Panel>
              );
            })}

          {related_diagnosis || allRelated_diadnosis
            ? (related_diagnosis || allRelated_diadnosis).map((el, index) => {
                let objEl = el.split('$').join(',').split('<').join(',');
                objEl = JSON.parse(objEl);
                let isEmpty = codes[index] && codes[index].length < 1;
                return (
                  <Panel
                    header={
                      isEmpty
                        ? `${objEl.med_descr}(${objEl.code}) - (No information available)`
                        : `${objEl.med_descr}(${objEl.code})`
                    }
                    key={index * 40}
                    disabled={isEmpty}>
                    <Search
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)}
                      className="search-box"
                    />
                    <Collapse onChange={callback}>
                      {codes[index] && codes[index].length > 0 ? (
                        codes[index]
                          .filter(item => item.topic.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((code, index2) => {
                            return (
                              <Panel key={1000 * index + index2} header={code ? code.topic : ''}>
                                <b>
                                  <h2>Full Summary</h2>
                                </b>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: code.fullsumhtml ? code.fullsumhtml : 'Not found',
                                  }}></div>{' '}
                                <b>
                                  <h2>Related Statement</h2>
                                </b>
                                {code.reltopictext ? code.reltopictext : 'Not found'}
                                <b>
                                  <h2>Visits</h2>
                                </b>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: code.odgcrithtml ? code.odgcrithtml : 'Not found',
                                  }}></div>
                                <Row>
                                  <Button
                                    type="primary"
                                    className="green-btn add-icd-code_button"
                                    onClick={() => handleJoinToReport(code)}
                                    loading={joinReportLoading}>
                                    Join to Report
                                  </Button>
                                </Row>
                              </Panel>
                            );
                          })
                      ) : (
                        <span></span>
                      )}
                    </Collapse>
                  </Panel>
                );
              })
            : ''}
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    files: state.report.files,
    isLoading: state.report.isLoading,
  };
  return props;
};

export default connect(mapStateToProps, { getReportFiles })(Odg);
