//Fields
const RECORD_CHANGE = "Record Change Indicator";
const NDC_ORIG_CONFIG = "NDC Original Configuration Code";
const NDC = "NDC 5-4-2 Format";
const DEA_CLASS_CODE = "DEA Class Code";
const PRODUCT_CATEGORY = "Product Category Code";
const EXCEPTIONAL_DRUG = "Exceptional Drug Indicator";
const PRODUCT_NAME = "Product Name";
const FORM_CODE = "Form Code";
const STRENGTH_NAME = "Strength";
const SOLID_LIQUID = "Solid/Liquid Indicator";
const MFG_NAME_LONG = "Manufacturer Name";
const MEASURE_CODE = "Measure Code";
const PRODUCT_SIZE = "Product Weight/Volume (Liquid Size)";
const PACKAGE_SIZE = "Package Size (Solid Size)";
const PACKAGE_QTY_CODE = "Package Quantity Code";
const THERA_CLASS_CODE = "Therapeutic Class Code";
const GCR_CODE = "Generic Cross Reference Code";
const UNIT_DOSE_FLAG = "Unit Dose Flag";
const SINGLE_SOURCE_FLAG = "Single Source Indicator";
const DESI_DRUG_FLAG = "DESI Drug Indicator";
const GFC_CODE = "Generic Formulation Code";
const AWP_EFF_DATE = "Current AWP Effective Date";
const AWP_P_PRICE = "Current AWP Package Price";
const AWP_U_PRICE = "Current AWP Unit Price";
const AWP1PRE_U_PRICE = "First Previous AWP Unit Price";
const DP_EFF_DATE = "Current DP Effective Date";
const DP_P_PRICE = "Current DP Package Price";
const DP_U_PRICE = "Current DP Unit Price";
const DP1PRE_U_PRICE = "First Previous DP Unit Price";
const FUL_EFF_DATE = "Current FUL Effective Date";
const FUL_P_PRICE = "Current FUL Package Price";
const FUL_U_PRICE = "Current FUL Unit Price";
const FULPRE_U_PRICE = "First Previous FUL Unit Price";
const PREV_NDC_DISC_DATE = "Previous NDC Discontinuation Date";
const PREV_NDC_CONFIG = "Previous NDC Original Configuration Code";
const PREV_NDC = "Previous NDC in 5-4-2 Format";
const ADDITIONAL_DESC = "Additional Description";
const ROA_CODE = "Route of Administration Code";
const MASTER_FORM_CODE = "Master Form Code";

const FIELD_NAMES = {
  [RECORD_CHANGE]: "recordChange",
  [NDC_ORIG_CONFIG]: "ndcOrigConfig",
  [NDC]: "ndc",
  [DEA_CLASS_CODE]: "deaClassCode",
  [PRODUCT_CATEGORY]: "productCategory",
  [EXCEPTIONAL_DRUG]: "exceptionalDrug",
  [PRODUCT_NAME]: "productName",
  [FORM_CODE]: "formCode",
  [STRENGTH_NAME]: "strengthName",
  [SOLID_LIQUID]: "solidLiquid",
  [MFG_NAME_LONG]: "mfgNameLong",
  [MEASURE_CODE]: "measureCode",
  [PRODUCT_SIZE]: "productSize",
  [PACKAGE_SIZE]: "packageSize",
  [PACKAGE_QTY_CODE]: "packageQtyCode",
  [THERA_CLASS_CODE]: "theraClassCode",
  [GCR_CODE]: "gcrCode",
  [UNIT_DOSE_FLAG]: "unitDoseFlag",
  [SINGLE_SOURCE_FLAG]: "singleSourceFlag",
  [DESI_DRUG_FLAG]: "desiDrugFlag",
  [GFC_CODE]: "gfcCode",
  [AWP_EFF_DATE]: "awpEffDate",
  [AWP_P_PRICE]: "awpPPrice",
  [AWP_U_PRICE]: "awpUPrice",
  [AWP1PRE_U_PRICE]: "awp1preUPrice",
  [DP_EFF_DATE]: "dpEffDate",
  [DP_P_PRICE]: "dpPPrice",
  [DP_U_PRICE]: "dpUPrice",
  [DP1PRE_U_PRICE]: "dp1preUPrice",
  [FUL_EFF_DATE]: "fulEffDate",
  [FUL_P_PRICE]: "fulPPrice",
  [FUL_U_PRICE]: "fulUPrice",
  [FULPRE_U_PRICE]: "fulpreUPrice",
  [PREV_NDC_DISC_DATE]: "prev_ndcDiscDate",
  [PREV_NDC_CONFIG]: "prev_ndcConfig",
  [PREV_NDC]: "prev_ndc",
  [ADDITIONAL_DESC]: "additionalDesc",
  [ROA_CODE]: "roaCode",
  [MASTER_FORM_CODE]: "masterFormCode",
};

export {
  FIELD_NAMES,
  RECORD_CHANGE,
  NDC_ORIG_CONFIG,
  NDC,
  DEA_CLASS_CODE,
  PRODUCT_CATEGORY,
  EXCEPTIONAL_DRUG,
  PRODUCT_NAME,
  FORM_CODE,
  STRENGTH_NAME,
  SOLID_LIQUID,
  MFG_NAME_LONG,
  MEASURE_CODE,
  PRODUCT_SIZE,
  PACKAGE_SIZE,
  PACKAGE_QTY_CODE,
  THERA_CLASS_CODE,
  GCR_CODE,
  UNIT_DOSE_FLAG,
  SINGLE_SOURCE_FLAG,
  DESI_DRUG_FLAG,
  GFC_CODE,
  AWP_EFF_DATE,
  AWP_P_PRICE,
  AWP_U_PRICE,
  AWP1PRE_U_PRICE,
  DP_EFF_DATE,
  DP_P_PRICE,
  DP_U_PRICE,
  DP1PRE_U_PRICE,
  FUL_EFF_DATE,
  FUL_P_PRICE,
  FUL_U_PRICE,
  FULPRE_U_PRICE,
  PREV_NDC_DISC_DATE,
  PREV_NDC_CONFIG,
  PREV_NDC,
  ADDITIONAL_DESC,
  ROA_CODE,
  MASTER_FORM_CODE,
};
