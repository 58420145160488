const createQueryString = (params = {}) => {
  const keys = Object.keys(params).filter(key => !!params[key]);

  if (keys.length === 0) return '';

  return keys.reduce((arr, key) => {
    if (arr.length === 0) {
      arr.push(`?${key}=${params[key]}`);
      return arr;
    }

    arr.push(`${key}=${params[key]}`);

    return arr;
  }, []).join('&');
};

export default createQueryString;
