import { SUPER_ADMIN_ID } from 'layout/roles';
import * as API from 'utils/api';

export const createTenantAndUser = async ({ formData }) => {
  let params = {
    user: {
      given_name: (formData?.user?.given_name || '').trim(),
      family_name: (formData?.user?.family_name || '').trim(),
      locale: (formData?.user?.locale || '').trim(),
      email: (formData?.user?.email || '').trim(),
      rol: SUPER_ADMIN_ID,
      is_tenant_owner: 1,
      package_id: formData?.selected_plan,
    },
    tenant: {
      tenant_name: (formData?.user?.company_name || '').trim(),
      website: '',
      address_line: (formData?.user?.address || '').trim(),
      phone_number_1: (formData?.user?.locale || '').trim(),
      phone_number_2: '',
      city: formData?.creditCard?.billing_city || '',
      state: formData?.creditCard?.billing_state || '',
      zip: formData?.creditCard?.billing_zipCode || '',
      client_logo: '',
      logo: '',
      subscription_status: 0,
      package_id: formData?.selected_plan,
      limit_reviewers: formData?.a_la_carte?.selectCount || null,
      // serviceQuickbook: formData?.a_la_carte?.selectQuickbook,
    },
  };

  const res = await API.addNewTenantPublic(params);
  return res;
};
