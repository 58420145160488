export const openDrawer = () => {
  return {
    type: "SHOW",
  };
};

export const closeDrawer = () => {
  return {
    type: "HIDE",
  };
};

export const displayData = (value) => {
  return {
    type: "DATA",
    value: value,
  };
};

export const displayAge = (number) => {
  return {
    type: "age",
    number: number,
  };
};

export const openChatDrawer = () => {
  return {
    type: "SHOW_CHAT",
  };
};

export const closeChatDrawer = () => {
  return {
    type: "HIDE_CHAT",
  };
};
