import * as API from 'utils/api';

import {
  REPORT_MSA_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  REPORT_SURGICAL_TYPE_ID,
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_MCP_MSA_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  REPORT_DEMAND_LETTER_TYPE_ID,
  REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID,
} from 'constants/reports';

export const CREATE_SET_UP_PDF = {
  [REPORT_MSA_TYPE_ID]: API.setUpReport,
  [REPORT_MCP_TYPE_ID]: API.setUpMCPReport,
  [REPORT_SURGICAL_TYPE_ID]: API.setUpSurgicalReport,
  [REPORT_EARLY_INTERVENTION_TYPE_ID]: API.setUpEarlyInterventionReport,
  [REPORT_MCP_MSA_TYPE_ID]: API.setUpMcpMsaReport,
  [REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID]: API.setUpLegalDemandTemplateReport,
  [REPORT_LIFE_CARE_PLAN_TYPE_ID]: API.setUpLifeCarePlanReport,
  [REPORT_DEMAND_LETTER_TYPE_ID]: API.setUpLegalDemandTemplateReport,
  [REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID]: API.setUpLegalDemandTemplateReport,
};
