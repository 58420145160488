import React, { useState } from 'react';
import * as API from 'utils/api';

// Components
import { ModalLoading } from 'components/Modal/ModalLoading';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';

// Icons
import { PdfIcon } from 'components/Svg';
import { notifyApiError, notifyError } from 'utils/notification';

const CVcomponent = ({ userId, cvFile, fileName }) => {
  const activeCv = userId || cvFile;

  const [pdfView, setPdfView] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearchCv = async () => {
    try {
      setLoading(true);
      if (cvFile) return setPdfView({ presigned_url: cvFile, name: fileName });

      const file = await API.getFileByUser({ path: 'CV', userId });

      if (file === null) return notifyError('He has not uploaded CV');
      setPdfView(file);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ModalLoading />}
      <PdfIcon active={activeCv} onClick={activeCv ? handleSearchCv : undefined} />
      {pdfView && (
        <ModalPdf path={pdfView.presigned_url} close={() => setPdfView('')} expand download fileInfo={pdfView} />
      )}
    </>
  );
};

export default CVcomponent;
