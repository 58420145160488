import { Col, Icon, Row, Select, Typography } from 'antd';
import InputBox from 'components/InputBox';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import * as API from 'utils/api';
import './Address.scss';
import { sortArrayObjectForKey } from 'utils/utils';

const { Text } = Typography;
const { Option } = Select;

export default class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address_line_1: '',
      address_line_2: '',
      city: '',
      juridiction_list: [],
      juridiction: '',
      zip: '',
    };
  }

  componentDidMount = () => {
    API.getJuridictionStateList().then(res => {
      this.setState({ juridiction_list: sortArrayObjectForKey({ array: res.juridictionStates, key: 'name' }) });
    });
    if (this.props.address) {
      this.setFields();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.address !== prevProps.address) {
      this.setFields();
    }
  }

  setFields = () => {
    const addressArr = this.props.address.split(',');
    this.setState({
      address_line_1: addressArr[0],
      address_line_2: addressArr[1],
      city: addressArr[2],
      juridiction: addressArr[3],
      zip: addressArr[4],
    });
  };

  createFinalAddress = () => {
    const removeCommas = strings => strings.map(str => (str ? str.replace(/,/g, '') : ''));

    const { address_line_1, address_line_2, city, juridiction, zip } = this.state;

    const address = removeCommas([address_line_1, address_line_2, city, juridiction, zip]).join(',');

    this.props.getAddress(address);
  };

  componentWillReceiveProps = props => {
    if (props.address === '') {
      this.setState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        juridiction: '',
        zip: '',
      });
    }
  };

  render() {
    const { address_line_1, address_line_2, city, juridiction_list, juridiction, zip } = this.state;
    return (
      <>
        <Row className="label">
          <Text>Address Line 1</Text>
        </Row>
        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col md={24} className="row">
            <InputBox
              placeholder={this.props.required ? 'Required' : ' '}
              value={address_line_1}
              onChange={value => this.setState({ address_line_1: value }, this.createFinalAddress)}
            />
          </Col>
        </Row>
        <Row className="label">
          <Text>Address Line 2</Text>
        </Row>
        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col md={24} className="row">
            <InputBox
              placeholder=" "
              value={address_line_2}
              onChange={value => this.setState({ address_line_2: value }, this.createFinalAddress)}
            />
          </Col>
        </Row>

        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col md={15} className="row">
            <Row className="label">
              <Text>City</Text>
            </Row>
            <InputBox
              placeholder={this.props.required ? 'Required' : ' '}
              value={city}
              onChange={value => this.setState({ city: value }, this.createFinalAddress)}
            />
          </Col>
          <Col md={4} className="row">
            <Row className="label">
              <Text>State</Text>
            </Row>
            <Select
              showSearch
              placeholder="Please select"
              optionFilterProp="children"
              className="dropdown"
              value={juridiction}
              onChange={value => {
                this.setState({ juridiction: value }, this.createFinalAddress);
              }}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              {juridiction_list.map((el, i) => {
                return (
                  <Option key={i} value={el.name}>
                    {el.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col md={3} className="row">
            <Row className="label more_margin">
              <Text>ZIP Code</Text>
            </Row>
            <InputMask
              mask="99999"
              placeholder={this.props.required ? 'Required' : ' '}
              className="input-ssn"
              value={zip}
              onChange={e => this.setState({ zip: e.target.value }, this.createFinalAddress)}
            />
          </Col>
        </Row>
      </>
    );
  }
}
