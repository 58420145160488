import * as React from 'react';
import { BLUE_ICON } from 'containers/Calendar/src/src/lib/utils/colors';

const UpIcon = ({ color = BLUE_ICON, onClick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" onClick={onClick}>
    <path
      fill={color}
      d="M216.49 168.49a12 12 0 0 1-17 0L128 97l-71.51 71.49a12 12 0 0 1-17-17l80-80a12 12 0 0 1 17 0l80 80a12 12 0 0 1 0 17Z"
    />
  </svg>
);

export default UpIcon;
