import React from 'react';
import { Row } from 'antd';
import { LCPMedicareCostTableHeader, LCPMedicareCostTableContent } from '.';

import './resetLcpMedicareCostTable.scss';

const LCPMedicareCostTable = ({
  data,
  handleEdit,
  handleDelete,
  handleClone,
  handleChangePosition,
  canEdit,
  report,
  structure,
}) => {

  return (
    <Row className="msa-medicare-cost-table-container LCP_table_reset">
      <LCPMedicareCostTableHeader structure={structure} />
      <LCPMedicareCostTableContent
        data={data}
        report={report}
        canEdit={canEdit}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleClone={handleClone}
        handleChangePosition={handleChangePosition}
        structure={structure}
      />
    </Row>
  );
};

export default LCPMedicareCostTable;
