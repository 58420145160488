import React, { Component } from 'react';
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  AutoComplete,
  Icon,
  Input,
  Modal,
  Tooltip,
  Radio,
  Alert,
  DatePicker,
} from 'antd';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import TextAreaBox from 'components/TextAreaBox';
import InputBox from 'components/InputBox';
import InputCurrencyBox from 'components/InputCurrencyBox';
import InputNumberBox from 'components/InputNumberBox';
import * as API from 'utils/api';
import { Auth } from 'aws-amplify';
import ClaimantInfo from 'components/ClaimantInfoOnWizard';
import './MedicareCostModal.scss';
import getInflation from '../../utils/inflation';
import { formatCurrency } from '../../utils/utils';
import { notifyApiError, notifyError, notifyInfo } from '../../utils/notification';
import { FINDACODE_FEES, PERCENTAGES, OWCP_FEES } from '../../constants/find-a-code-fees';
import SpinBox from '../SpinBox';
import { getRedbookData } from '../../utils/api-redbook';
import {
  AWP_U_PRICE,
  FIELD_NAMES,
  MFG_NAME_LONG,
  NDC,
  PRODUCT_NAME,
  STRENGTH_NAME,
} from '../../constants/redbook-definitions';
import {
  REPORT_SURGICAL_TYPE_ID,
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_MSA_REDUCTION_TYPE_ID,
} from 'constants/reports';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

// DATA
import {
  getTitleModal,
  getDefaultType,
  MSA_NON_MEDICARE,
  OWCP_STATUS_COVERED,
  FUTURE_MEDICAL_NEEDED,
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  DONT_CHANGE_COST_ON_THIS_TYPE,
  DONT_CHANGE_COST_ON_THIS_TYPE_2,
  NON_MEDICARE_PROJECTION_TYPES,
  MEDICARE_PROJECTION_TYPES,
  MCP_MEDICARE,
  MSA_MEDICARE,
  LCP_MEDICARE,
  LIST_TYPE_TABLE_DEFAULT,
} from './data';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;

window.WEBSPELLCHECKER_CONFIG = {
  autoSearch: true,
  enableGrammar: true,
  serviceId: 'jdvmqulLhWbINzr',
  autoStartup: true,
};

class MedicareCostModal extends Component {
  constructor(props) {
    super(props);
    this.ndcRef = React.createRef();

    const { modalType } = this.props;
    const totalYears = this.props.reportData.life_expectancy;
    if (this.props.selectedItem) {
      let selectedItem = this.props.selectedItem;

      this.state = {
        name: selectedItem.name,
        type: selectedItem.type,
        prescriptions: selectedItem.prescriptions,
        ndc: selectedItem.ndc,
        unit_form_id: selectedItem.unit_form_id,
        prescription_strength: selectedItem.prescription_strength,
        frequency: selectedItem.frequency,
        units_needed: selectedItem.units_needed,
        units_per_refill: selectedItem.units_per_refill,
        every_x_year: selectedItem.every_x_year,
        refills_per_year: selectedItem.refills_per_year,
        total_years: selectedItem.total_years,
        per_unit_cost: selectedItem.per_unit_cost,
        total_cost: selectedItem.total_cost,
        annual_cost: selectedItem.annual_cost,
        inflation_total: selectedItem.inflation_total,
        comments: selectedItem.comments,
        speciality_id: selectedItem.speciality_id,
        cpt_hcpcs_id: selectedItem.cpt_hcpcs_id,
        is_seed_calculated: selectedItem.is_seed_calculated,
        findACodeData: {},
        percentage: 1,
        fetching: false,
        redbookFilteredList: [],
        redbookList: [],
        ndcFoundList: [],
        strengthFoundList: [],
        showContactsModal: false,
        backupZip: 0,
        ndcOpen: false,
        isRoleQA: isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY),
        age_at_start: selectedItem?.age_at_start || null,
        start_date: moment(selectedItem?.start_date) || null,
        age_at_end: selectedItem?.age_at_end || null,
        end_date: moment(selectedItem?.end_date) || null,
        recommended_by: selectedItem?.recommended_by || null,
        purpose: selectedItem?.purpose || null,
      };
    } else {
      this.state = {
        loading: false,
        isOpenSelectPrice: false,
        name: '',
        type: getDefaultType({ modalType }),
        frequency: null,
        units_needed: null,
        units_per_refill: null,
        every_x_year: null,
        refills_per_year: null,
        total_years: totalYears,
        per_unit_cost: 0,
        total_cost: 0,
        annual_cost: 0,
        inflation_total: null,
        has_inflation: false,
        interest_rate: '0',
        comments: '',
        speciality_id: undefined,
        cpt_hcpcs_id: undefined,
        is_seed_calculated: false,
        code: '',
        isOpen1: false,
        customCode: '',
        customDescription: '',
        report_claim_id: '',
        findACodeData: {},
        percentage: 1,
        fetching: false,
        redbookFilteredList: [],
        redbookList: [],
        ndcFoundList: [],
        strengthFoundList: [],
        backupZip: 0,
        ndcOpen: false,
        isRoleQA: isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY),
      };
    }
  }

  setInitial = () => {
    const { modalType } = this.props;
    let state = {
      loading: false,
      name: '',
      type: modalType === MSA_NON_MEDICARE ? FUTURE_MEDICAL_NEEDED : FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
      prescriptions: '',
      ndc: '',
      unit_form_id: undefined,
      prescription_strength: '',
      frequency: null,
      units_needed: null,
      units_per_refill: null,
      every_x_year: null,
      refills_per_year: null,
      total_years: this.props.reportData ? this.props.reportData.life_expectancy : '',
      per_unit_cost: 0,
      total_cost: 0,
      annual_cost: 0,
      inflation_total: null,
      comments: '',
      speciality_id: undefined,
      cpt_hcpcs_id: undefined,
      is_seed_calculated: false,
      code: '',
      isOpen1: false,
      customCode: '',
      customDescription: '',
      findACodeData: {},
      percentage: 1,
      redbookFilteredList: [],
      redbookList: [],
      ndcFoundList: [],
      strengthFoundList: [],
      backupZip: 0,
      ndcOpen: false,
    };

    Auth.currentAuthenticatedUser().then(user => {
      const tenantId = user?.attributes['custom:tenant_id'] || 0;
      API.getTenantCompanyProfile(tenantId).then(res => {
        const zip = res?.tenant?.zip || 0;
        this.setState({
          ...state,
          zip,
        });
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    let { selectedItem, searchCodes } = this.props;

    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (selectedItem === null) {
        if (JSON.stringify(prevProps.cptList) === JSON.stringify(this.props.cptList)) {
          this.setInitial();
        }
      } else {
        // si hay prescriptions buscamos las listas
        if (!!selectedItem.prescriptions) {
          this.debounceRedbook(selectedItem.prescriptions, selectedItem.prescription_strength);
        }
        this.setState({
          name: selectedItem.name,
          type: selectedItem.type,
          prescriptions: selectedItem.prescriptions,
          ndc: selectedItem.ndc,
          unit_form_id: selectedItem.unit_form_id,
          prescription_strength: selectedItem.prescription_strength,
          frequency: selectedItem.frequency,
          units_needed: selectedItem.units_needed,
          units_per_refill: selectedItem.units_per_refill,
          every_x_year: selectedItem.every_x_year,
          refills_per_year: selectedItem.refills_per_year,
          total_years: selectedItem.total_years,
          per_unit_cost: selectedItem.per_unit_cost,
          total_cost: selectedItem.total_cost,
          annual_cost: selectedItem.annual_cost,
          inflation_total: selectedItem.inflation_total,
          comments: selectedItem.comments,
          speciality_id: selectedItem.speciality_id,
          cpt_hcpcs_id: selectedItem.cpt_hcpcs_id,
          is_seed_calculated: selectedItem.is_seed_calculated,
          age_at_end: selectedItem?.age_at_end || null,
          age_at_start: selectedItem?.age_at_start || null,
          start_date: selectedItem?.start_date || null,
          end_date: selectedItem.end_date || null,
          recommended_by: selectedItem?.recommended_by || '',
          purpose: selectedItem?.purpose || '',
        });

        if (!this.state.cptSearchValue) {
          searchCodes(selectedItem.cpt_hcpcs_id, selectedItem.cpt_hcpcs_id);
        }
      }
    }

    const { units_needed, units_per_refill, per_unit_cost, every_x_year, refills_per_year, total_years } = this.state;

    let perUnitCostWithComma = per_unit_cost;

    if (perUnitCostWithComma.length > 0) {
      perUnitCostWithComma = perUnitCostWithComma.replace(/,/g, '');
    }

    if (
      prevState.units_needed !== units_needed ||
      prevState.units_per_refill !== units_per_refill ||
      prevState.per_unit_cost !== per_unit_cost ||
      prevState.every_x_year !== every_x_year ||
      prevState.refills_per_year !== refills_per_year ||
      prevState.total_years !== total_years
    ) {
      let total_cost = 0;
      let annual_cost = 0;

      if (refills_per_year && units_per_refill) {
        total_cost = Number(units_per_refill * perUnitCostWithComma * refills_per_year * total_years).toFixed(2);
        annual_cost = Number(units_per_refill * perUnitCostWithComma * refills_per_year).toFixed(2);
      } else if (every_x_year && units_needed) {
        total_cost = Number(units_needed * perUnitCostWithComma * (total_years / every_x_year)).toFixed(2);
        annual_cost = Number((units_needed * perUnitCostWithComma) / every_x_year).toFixed(2);
      }

      this.setState({ total_cost, annual_cost });
    }
  }

  debounceRedbook = debounce(300, async (search, prescribedStrengthDefault = '') => {
    if (!search) return;
    try {
      this.setState({ fetching: true, ndcOpen: false });
      const res = await getRedbookData({ search });
      const redbookList = res && Array.isArray(res) ? res : [];
      const redbookFilteredList = redbookList
        //remove duplicates
        .filter((redbook, i, all) => all.findIndex(rb => rb.productName === redbook.productName) === i)
        .map(item => item[FIELD_NAMES[PRODUCT_NAME]]);
      const ndcFoundList = redbookList.map(item => ({
        ndc: item[FIELD_NAMES[NDC]],
        manufacturer: item[FIELD_NAMES[MFG_NAME_LONG]],
        strength: item[FIELD_NAMES[STRENGTH_NAME]],
        awp: item[FIELD_NAMES[AWP_U_PRICE]],
      }));

      let strengthFoundList = [];

      if (!prescribedStrengthDefault) {
        strengthFoundList = redbookList
          .map(item => item[FIELD_NAMES[STRENGTH_NAME]] || 'NA')
          .filter((strength, i, all) => strength !== 'NA' && all.findIndex(s => s === strength) === i);
      } else {
        redbookList.forEach(item => {
          if (item.productName === search) {
            if (!strengthFoundList.includes(item.strengthName) && !!item.strengthName) {
              strengthFoundList.push(item.strengthName);
            }
          }
        });
      }

      this.setState({ redbookList, redbookFilteredList, ndcFoundList, strengthFoundList });

      // si viene una lista por default de <Prescribed Strength>, buscamos la lista del campo <NDC> esto ocurre solo en type <Prescription medications covered>
      if (!!prescribedStrengthDefault) {
        this.handleChangeStrength(prescribedStrengthDefault, redbookList);
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ fetching: false });
    }
  });

  handleChangePrescription = value => {
    const { redbookList } = this.state;
    const filteredRedbook = redbookList.filter(item => item[FIELD_NAMES[PRODUCT_NAME]] === value);
    const ndcFoundList = filteredRedbook.map(item => ({
      ndc: item[FIELD_NAMES[NDC]],
      manufacturer: item[FIELD_NAMES[MFG_NAME_LONG]],
      strength: item[FIELD_NAMES[STRENGTH_NAME]],
      awp: item[FIELD_NAMES[AWP_U_PRICE]],
    }));
    const strengthFoundList = filteredRedbook
      .map(item => `${item[FIELD_NAMES[STRENGTH_NAME]] || 'NA'}`)
      .filter((strength, i, all) => all.findIndex(s => s === strength) === i);
    this.setState({
      ndcOpen: false,
      prescriptions: value,
      ndc: '',
      unit_form_id: null,
      prescription_strength: '',
      ndcFoundList,
      strengthFoundList,
    });
  };

  handleChangeNDC = value => {
    const { redbookList } = this.state;
    const drug = redbookList.find(item => item[FIELD_NAMES[NDC]] === value);
    if (drug) {
      const price = Number.parseFloat(drug[FIELD_NAMES[AWP_U_PRICE]]).toFixed(2);
      this.addPrice(price);

      this.setState({
        ndcOpen: false,
        prescriptions: drug[FIELD_NAMES[PRODUCT_NAME]],
        ndc: value,
        prescription_strength: drug[FIELD_NAMES[STRENGTH_NAME]],
        unit_form_id:
          this.props.unitFormList.find(
            item => (item.name || '').trim().toUpperCase() === drug.formcode.name.toUpperCase()
          ).id || null,
      });
      this.ndcRef.current.blur();
    }
  };

  handleChangeStrength = (value, listDefault = []) => {
    const { redbookList } = this.state;
    let filteredRedbook = redbookList || listDefault;
    if (value) {
      filteredRedbook = redbookList.filter(item => item[FIELD_NAMES[STRENGTH_NAME]] === value);
    }
    const ndcFoundList = filteredRedbook.map(item => ({
      ndc: item[FIELD_NAMES[NDC]],
      manufacturer: item[FIELD_NAMES[MFG_NAME_LONG]],
      strength: item[FIELD_NAMES[STRENGTH_NAME]],
      awp: item[FIELD_NAMES[AWP_U_PRICE]],
    }));

    this.setState({
      ndcOpen: !!listDefault ? false : true,
      prescription_strength: value,
      ndcFoundList,
    });
  };

  addCptCode = () => {
    API.addCptCode({
      code: this.state.customCode,
      med_descr: this.state.customDescription,
      short_descr: this.state.customDescription,
      long_descr: this.state.customDescription,
      search_column: `${this.state.customDescription}  ${this.state.customCode}`,
      data_subset: 'ACTIVE',
    }).then(res => {
      this.setState({
        cpt_hcpcs_id: res.id,
        code: this.state.customCode,
        name: this.state.customDescription,
        isOpen1: false,

        customCode: '',
        customDescription: '',
      });
    });
  };

  handleConfirm = () => {
    this.setState({ cptSearchValue: '' });
    const { onConfirm } = this.props;
    const {
      name,
      type,
      prescriptions,
      ndc,
      unit_form_id,
      prescription_strength,
      frequency,
      units_needed,
      units_per_refill,
      every_x_year,
      refills_per_year,
      total_years,
      per_unit_cost,
      total_cost,
      annual_cost,
      inflation_total,
      comments,
      speciality_id,
      cpt_hcpcs_id,
      is_seed_calculated,
      age_at_end,
      age_at_start,
      start_date,
      end_date,
      recommended_by,
      purpose,
    } = this.state;

    let perUnitCostWithComma = per_unit_cost;

    if (perUnitCostWithComma.length > 0) {
      perUnitCostWithComma = perUnitCostWithComma.replace(/,/g, '');
    }

    const NoneValues = () => {
      Modal.confirm({
        width: 700,
        onCancel: () => {},
        onOk: () => {
          let none = 'None';
          let nonNumber = 0;
          const params = {
            name: name ? name : none,
            type,
            prescriptions: prescriptions ? prescriptions : none,
            ndc: ndc ? ndc : none,
            unit_form_id,
            prescription_strength: prescription_strength ? prescription_strength : none,
            frequency: frequency ? frequency : nonNumber,
            units_needed: units_needed ? units_needed : nonNumber,
            units_per_refill: units_per_refill ? units_per_refill : null,
            every_x_year: every_x_year ? every_x_year : nonNumber,
            refills_per_year: refills_per_year || nonNumber,
            total_years: total_years ? total_years : nonNumber,
            per_unit_cost: perUnitCostWithComma ? Number(perUnitCostWithComma) : nonNumber,
            total_cost: total_cost ? total_cost : nonNumber,
            annual_cost: annual_cost ? annual_cost : nonNumber,
            inflation_total,
            comments: comments ? comments : '',
            speciality_id,
            cpt_hcpcs_id,
            is_seed_calculated,
            age_at_end,
            age_at_start,
            start_date,
            end_date,
            recommended_by,
            purpose,
          };
          this.setInitial();
          onConfirm(params);

          this.setState({ code: '' });
        },
        okButtonProps: {
          style: { background: '#00D374', borderColor: 'white' },
        },
        content: 'It appears you have left some fields blank. Do you want to continue and set these values as None?',
      });
    };
    if (type.includes('Prescription Medication')) {
      if (!prescriptions) {
        NoneValues();
        return;
      }
      if (!ndc) {
        NoneValues();
        return;
      }
      if (!unit_form_id) {
        NoneValues();
        return;
      }
      if (!prescription_strength) {
        NoneValues();
        return;
      }
    } else {
      if (!cpt_hcpcs_id) {
        NoneValues();
        return;
      }
      if (!speciality_id) {
        NoneValues();
        return;
      }
    }
    if (!units_needed && !units_per_refill) {
      NoneValues();
      return;
    }
    if (!per_unit_cost) {
      NoneValues();
      return;
    }
    if (!every_x_year && !refills_per_year) {
      NoneValues();
      return;
    }
    if (!total_years) {
      NoneValues();
      return;
    }

    const params = {
      name,
      type,
      prescriptions,
      ndc,
      unit_form_id,
      prescription_strength,
      frequency,
      units_needed,
      units_per_refill,
      every_x_year,
      refills_per_year,
      total_years,
      per_unit_cost: perUnitCostWithComma ? Number(perUnitCostWithComma) : 0,
      total_cost,
      annual_cost,
      inflation_total,
      comments,
      speciality_id,
      cpt_hcpcs_id,
      is_seed_calculated,
      age_at_end,
      age_at_start,
      start_date,
      end_date,
      recommended_by,
      purpose,
    };
    this.setInitial();
    onConfirm(params);

    this.setState({ code: '' });
  };

  fetchSurgical = () => {
    if (this.props.reportData.pricing_group_id) {
      API.getPriceForSurgical(this.props.reportData.id, this.state.cpt_hcpcs_id).then(response => {
        if (response.surgical_pricing_plans.length > 0) {
          this.setState({
            surgical_pricing_plans: response.surgical_pricing_plans,
          });
        }
      });
    }
  };

  onChangeBlueBook = () => {
    if (this.props.reportData.pricing_group_id) {
      return;
    }
    if (!this.state.cpt_hcpcs_id) return;
    if (this.state.type === DONT_CHANGE_COST_ON_THIS_TYPE || this.state.type === DONT_CHANGE_COST_ON_THIS_TYPE_2) {
      let cptCode = this.props.cptList.find(cpt => cpt.id === this.state.cpt_hcpcs_id); //bluebook
      let address = this.props.reportData.claimant
        ? this.props.reportData.claimant.address.split(',')
        : [this.state.zip];
      let currentZip = address[address.length - 1];
      API.getBlueBook(cptCode.id, currentZip)
        .then(res => {
          if (res !== null) {
            this.setState({ blueBook: res, OpenBlueBook: true }, () => {});
          } else {
            this.setState({
              loading: false,
              prescriptions: '',
              ndc: '',
              unit_form_id: undefined,
              prescription_strength: '',
              frequency: null,
              units_needed: null,
              units_per_refill: null,
              every_x_year: null,
              refills_per_year: null,
              total_years: this.props.reportData ? this.props.reportData.life_expectancy : '',
              per_unit_cost: 0,
              total_cost: 0,
              annual_cost: 0,
              inflation_total: null,
              comments: '',
              speciality_id: undefined,
              is_seed_calculated: false,
              code: '',
              isOpen1: false,
              customCode: '',
              customDescription: '',
              percentage: 1,
              redbookFilteredList: [],
              redbookList: [],
              ndcFoundList: [],
              strengthFoundList: [],
              backupZip: 0,
              ndcOpen: false,
              blueBookError: true,
            });
            notifyInfo('Not Found', `The Code ${cptCode.id} was not found on Bluebook Codes`);
          }
        })
        .catch(e => {
          this.setState({
            loading: false,
            prescriptions: '',
            ndc: '',
            unit_form_id: undefined,
            prescription_strength: '',
            frequency: null,
            units_needed: null,
            units_per_refill: null,
            every_x_year: null,
            refills_per_year: null,
            total_years: this.props.reportData ? this.props.reportData.life_expectancy : '',
            per_unit_cost: 0,
            total_cost: 0,
            annual_cost: 0,
            inflation_total: null,
            comments: '',
            speciality_id: undefined,
            is_seed_calculated: false,
            code: '',
            isOpen1: false,
            customCode: '',
            customDescription: '',
            percentage: 1,
            redbookFilteredList: [],
            redbookList: [],
            ndcFoundList: [],
            strengthFoundList: [],
            backupZip: 0,
            ndcOpen: false,
            blueBookError: true,
          });
          notifyApiError(e);
        });

      return;
    }
  };

  isOWCP = () => {
    return (
      ![
        REPORT_SURGICAL_TYPE_ID,
        REPORT_EARLY_INTERVENTION_TYPE_ID,
        REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
        REPORT_MSA_REDUCTION_TYPE_ID,
      ].includes(this.props.reportData.report_type_id) && !this.props.reportData.juridiction_state_id
    );
  };

  onChangeFindACode = async () => {
    if (this.props.reportData.pricing_group_id) return;

    if (this.props.reportData.is_template) return;

    if (this.state.cpt_hcpcs_id) {
      //find a code
      const cptCode = this.props.cptList.find(cpt => cpt.id === this.state.cpt_hcpcs_id);
      const address = this.props.reportData?.claimant?.address.split(',') || '';

      if (!cptCode) return;

      const zip =
        address && Array.isArray(address) && address.length > 3
          ? address[address.length - 1]
          : this.props.reportData?.claimant?.zip_code || null;
      if (!zip) {
        notifyError('Zip code not found', "Fill Claimant's Zip code");
        return;
      }

      const isOWCPRequest = this.isOWCP();

      try {
        this.setState({ loading: true });

        const findACodeData = await API.FindACode(cptCode.code, zip, isOWCPRequest);

        if (!findACodeData) {
          notifyInfo('Data not found', 'A match for this CPT code and claimant zip code could not be found.');
          return;
        }

        this.setState({
          isOpenSelectPrice: true,
          findACodeData,
        });
      } catch (e) {
        notifyApiError(e);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  addPrice = price => {
    if (isNaN(price) || !price) {
      return;
    }

    this.setState({ per_unit_cost: price });
  };

  addComments = comments => {
    this.setState({ comments });
  };

  handleUpdateEndDate = ({ date, totalYears }) => {
    const cloneDate = _.cloneDeep(date);
    this.setState({ end_date: cloneDate && totalYears ? cloneDate.add(totalYears, 'years') : '' });
  };

  render() {
    const { modalType, actionType, cptList, unitFormList, specialityList, isOpen, onCancel, typeList } = this.props;

    const isOWCPRequest = this.isOWCP();

    const {
      loading,
      name,
      type,
      prescriptions,
      ndc,
      unit_form_id,
      prescription_strength,
      units_needed,
      units_per_refill,
      every_x_year,
      refills_per_year,
      total_years,
      per_unit_cost,
      total_cost,
      annual_cost,
      comments,
      speciality_id,
      cpt_hcpcs_id,
      isOpen1,
      customCode,
      customDescription,
      findACodeData,
      percentage,
      redbookFilteredList,
      ndcFoundList,
      strengthFoundList,
      cptSearchValue,
      ndcOpen,
      isRoleQA,
      age_at_end,
      age_at_start,
      start_date,
      end_date,
      recommended_by,
      purpose,
    } = this.state;

    const isPrescription = ['Prescription Medications Covered', 'Prescription Drug Data'].includes(type);

    return (
      <>
        <Modal
          visible={isOpen}
          onCancel={() => {
            onCancel();
            this.setState({ cptSearchValue: '' });
          }}
          footer={null}
          centered={true}
          closable={true}
          width={1000}
          className="medicare-cost-modal"
          title={getTitleModal({ modalType, actionType })}
          destroyOnClose>
          <SpinBox loading={loading} title="Pending...">
            <Row type="flex" align="middle" className="report-body">
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <Col md={24}>
                    {!this.props.reportData.is_template && (
                      <ClaimantInfo
                        surgical={this.props.surgical}
                        claimant={{
                          ...this.props.reportData.claimant,
                          report_claim_id: this.props.reportData.report_claim_id,
                        }}
                      />
                    )}
                  </Col>
                  <Row>
                    <Text>Type</Text>
                  </Row>
                  <Row>
                    {modalType === MSA_NON_MEDICARE && (
                      <Select
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        className="dropdown"
                        value={type}
                        disabled={isRoleQA}
                        onChange={value => {
                          this.setState({ type: value }, () => {
                            this.onChangeBlueBook();
                            this.onChangeFindACode();
                          });
                        }}
                        suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                        {NON_MEDICARE_PROJECTION_TYPES.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    )}

                    {[MCP_MEDICARE, MSA_MEDICARE].includes(modalType) && (
                      <Select
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        className="dropdown"
                        value={type}
                        disabled={isRoleQA}
                        onChange={value => {
                          this.setState({ type: value });
                        }}
                        suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                        {MEDICARE_PROJECTION_TYPES.map(item => (
                          <Option key={item}>{item}</Option>
                        ))}
                      </Select>
                    )}

                    {modalType === LCP_MEDICARE && (
                      <Select
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        className="dropdown"
                        value={type}
                        disabled={isRoleQA}
                        onChange={value => {
                          this.setState({ type: value });
                        }}
                        suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                        {typeList.map(item => (
                          <Option key={item}>{item}</Option>
                        ))}
                      </Select>
                    )}
                  </Row>
                </Col>
              </Row>
              {isPrescription ? (
                <>
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={17} className="row">
                      <Row>
                        <Text>Prescription item</Text>
                      </Row>
                      <AutoComplete
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        allowClear
                        placeholder="Search drug record by name"
                        className="dropdown"
                        onChange={value => this.setState({ prescriptions: value })}
                        onFocus={() => this.setState({ ndcOpen: false })}
                        value={prescriptions}
                        disabled={isRoleQA}
                        onSearch={this.debounceRedbook}
                        onSelect={this.handleChangePrescription}
                        dataSource={redbookFilteredList}
                      />
                    </Col>
                    <Col md={6} className="row">
                      <Row>
                        <Text>Prescribed Strength</Text>
                      </Row>
                      <Select
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        className="dropdown"
                        disabled={isRoleQA}
                        value={prescription_strength}
                        onChange={this.handleChangeStrength}
                        onSelect={this.handleChangeStrength}
                        suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                        {strengthFoundList.map(item => (
                          <Option value={item}>{item}</Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={17} className="row">
                      <Row>
                        <Text>NDC</Text>
                      </Row>
                      <Select
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        showSearch
                        placeholder="Search drug record by NDC"
                        optionFilterProp="children"
                        className="dropdown"
                        ref={this.ndcRef}
                        disabled={isRoleQA}
                        value={ndc}
                        onChange={value => this.setState({ ndc: value ? value.substring(0, 11) : '' })}
                        onSelect={this.handleChangeNDC}
                        onBlur={() => this.setState({ ndcOpen: false })}
                        onFocus={() => this.setState({ ndcOpen: true })}
                        filterOption={(inputValue, option) =>
                          option.props.children.toUpperCase().includes(inputValue.toUpperCase())
                        }
                        suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                        {ndcFoundList.map(item => (
                          <Option key={item.ndc} value={item.ndc}>
                            {`${item.ndc} ($${item.awp}; ${item.strength}, ${item.manufacturer})`}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col md={6} className="row">
                      <Row>
                        <Text>Unit Form</Text>
                      </Row>
                      <Row>
                        <Select
                          getPopupContainer={triggerNode => triggerNode.parentNode}
                          showSearch
                          placeholder="Please select"
                          optionFilterProp="filter"
                          className="dropdown"
                          value={unit_form_id}
                          disabled={isRoleQA}
                          onChange={value => this.setState({ unit_form_id: value })}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                          {unitFormList.map(item => (
                            <Option value={item.id} filter={item.id.toString().concat('_' + item.name)} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={5} className="row">
                      <InputNumberBox
                        label="Total Years"
                        value={total_years}
                        min={0}
                        disabled={isRoleQA}
                        onChange={value => this.setState({ total_years: value })}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={24} className="row">
                      <Row>
                        <Text>CPT/HCPCS{isOWCPRequest && ' (Federal OWCP Fee Calculator will be used)'}</Text>
                      </Row>
                      <Row>
                        <Select
                          getPopupContainer={triggerNode => triggerNode.parentNode}
                          showSearch
                          disabled={isRoleQA}
                          placeholder="Please select"
                          filterOption={false}
                          className="dropdown"
                          notFoundContent={`${
                            cptSearchValue && !cptList.length
                              ? 'No data'
                              : 'Start searching and result will appear here'
                          }`}
                          onSearch={value => {
                            this.setState({ cptSearchValue: value });

                            if (value) {
                              this.props.searchCodes(value);
                            }
                          }}
                          value={cpt_hcpcs_id}
                          onChange={(value, option) => {
                            this.setState(
                              {
                                cpt_hcpcs_id: value,
                                name: option.props.name,
                                code: option.props.code,
                              },
                              () => {
                                this.onChangeBlueBook();
                                this.fetchSurgical();
                                this.onChangeFindACode();
                              }
                            );
                          }}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                          {(cptSearchValue || cpt_hcpcs_id) &&
                            cptList &&
                            cptList.map(item => (
                              <Option
                                value={item.id}
                                name={item.short_descr}
                                code={item.code}
                                filter={item.code.concat('_' + item.med_descr)}
                                key={item.id}>
                                <Tooltip placement="right" title={item.long_descr}>
                                  {item.code + ' (' + item.short_descr + ')'}
                                </Tooltip>
                              </Option>
                            ))}
                        </Select>
                        {cpt_hcpcs_id && (
                          <Button
                            className="change-cost"
                            disabled={isRoleQA}
                            onClick={() => {
                              this.onChangeBlueBook();
                              this.fetchSurgical();
                              this.onChangeFindACode();
                            }}>
                            Change Cost
                          </Button>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: 20,
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}>
                    <Button
                      type="primary"
                      size="default"
                      disabled={isRoleQA}
                      className="green-btn button"
                      onClick={() => {
                        this.setState({ isOpen1: true });
                      }}>
                      Add Custom Code
                    </Button>
                  </Row>

                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={24} className="row">
                      <InputBox
                        label="Item Name"
                        value={name}
                        disabled={true}
                        onChange={value => this.setState({ name: value })}
                      />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={11} className="row">
                      <Row>
                        <Text>Specialty</Text>
                      </Row>
                      <Row>
                        <Select
                          getPopupContainer={triggerNode => triggerNode.parentNode}
                          showSearch
                          placeholder="Please select"
                          optionFilterProp="children"
                          className="dropdown"
                          disabled={isRoleQA}
                          value={speciality_id}
                          onChange={value => {
                            this.setState({ speciality_id: value });
                          }}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                          {specialityList.map(item => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col md={11} className="row">
                      <InputNumberBox
                        label="Total Years"
                        value={total_years}
                        disabled={isRoleQA}
                        min={0}
                        onChange={value => {
                          this.setState({ total_years: value, age_at_end: value + (age_at_start || 0) });
                          this.handleUpdateEndDate({ date: start_date, totalYears: value });
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={18}>
                  <Row type="flex" justify="start" align="middle">
                    <Col md={4} className="row">
                      {!isPrescription ? (
                        <InputNumberBox
                          label="Units Needed"
                          value={units_needed}
                          placeholder="Required"
                          min={0}
                          disabled={isRoleQA}
                          onChange={value => this.setState({ units_needed: value })}
                        />
                      ) : (
                        <InputNumberBox
                          className="row-container"
                          label="Units per Refill"
                          value={units_per_refill}
                          placeholder="Required"
                          min={0}
                          disabled={isRoleQA}
                          onChange={value => this.setState({ units_per_refill: value })}
                        />
                      )}
                    </Col>
                    {!isPrescription && (
                      <Row className="row-container">
                        <Row>
                          <Text>Every</Text>
                        </Row>
                        <Row>
                          <Input defaultValue="Every" disabled={true} />
                        </Row>
                      </Row>
                    )}
                    {!isPrescription ? (
                      <Col md={4} className="row">
                        <InputNumberBox
                          label="Years"
                          value={every_x_year}
                          min={0}
                          disabled={isRoleQA}
                          onChange={value => this.setState({ every_x_year: value })}
                        />
                      </Col>
                    ) : (
                      <Col md={4} className="row">
                        <InputNumberBox
                          label="Refills per Year"
                          value={refills_per_year}
                          min={0}
                          disabled={isRoleQA}
                          onChange={value => this.setState({ refills_per_year: value })}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col md={5} className="row">
                  <InputCurrencyBox
                    label="Per Unit cost"
                    value={per_unit_cost}
                    min={0}
                    disabled={isRoleQA}
                    onChange={value => {
                      if (value === per_unit_cost) {
                        return;
                      }
                      this.setState({ per_unit_cost: value });
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputCurrencyBox
                    label="Total Cost"
                    disabled={true}
                    value={total_cost}
                    onChange={value => this.setState({ total_cost: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputCurrencyBox
                    label="Annual Cost"
                    disabled={true}
                    value={annual_cost}
                    onChange={value => this.setState({ annual_cost: value })}
                  />
                </Col>

                {this.props.reportData.has_inflation && (
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={24} className="row">
                      <InputCurrencyBox
                        label="Inflation Total"
                        placeholder=" "
                        disabled={true}
                        value={getInflation(
                          { interest_rate: this.props.reportData.interest_rate },
                          total_cost,
                          total_years,
                          true
                        )}
                        onChange={value => this.setState({ inflation_total: value })}
                      />
                    </Col>
                  </Row>
                )}
              </Row>

              {modalType === LCP_MEDICARE && type && !LIST_TYPE_TABLE_DEFAULT.includes(type) && (
                <>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="main-info medicareCostModal_newInputs">
                    <Col md={5} className="row">
                      <InputNumberBox
                        label="Age at Start"
                        value={age_at_start}
                        min={0}
                        disabled={isRoleQA}
                        onChange={value =>
                          this.setState({ age_at_start: value, age_at_end: Number(value || 0) + total_years })
                        }
                      />
                    </Col>
                    <Col md={5} className="row">
                      <p>Start Date</p>
                      <DatePicker
                        className="addClaimantDatePicker"
                        format="MM/DD/YYYY"
                        allowClear={false}
                        value={start_date}
                        placeholder="DD / MM / YYYY"
                        onChange={date => {
                          this.setState({
                            start_date: date,
                          });
                          this.handleUpdateEndDate({ date, totalYears: total_years });
                        }}
                      />
                    </Col>

                    <Col md={5} className="row">
                      <InputNumberBox
                        label="Age at End"
                        value={age_at_end}
                        min={0}
                        disabled
                        onChange={value => this.setState({ age_at_end: value })}
                      />
                    </Col>

                    <Col md={5} className="row">
                      <p>End Date</p>
                      <DatePicker
                        className="addClaimantDatePicker"
                        format="MM/DD/YYYY"
                        disabled
                        allowClear={false}
                        value={end_date}
                        placeholder="DD / MM / YYYY"
                        onChange={date => this.setState({ end_date: date })}
                      />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={24} className="row">
                      <TextAreaBox
                        label="Recommended by"
                        placeholder="Optional"
                        value={recommended_by}
                        onChange={value => this.setState({ recommended_by: value })}
                      />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="middle" className="main-info">
                    <Col md={24} className="row">
                      <TextAreaBox
                        label="Purpose"
                        placeholder="Optional"
                        value={purpose}
                        onChange={value => this.setState({ purpose: value })}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <TextAreaBox
                    label="Comment"
                    placeholder="Optional"
                    value={comments}
                    onChange={value => this.setState({ comments: value })}
                  />
                </Col>
              </Row>
              {!this.props.selectedItem ? (
                <Button type="primary" size="large" className="green-btn button" onClick={this.handleConfirm}>
                  Create
                </Button>
              ) : (
                <Button type="primary" size="large" className="green-btn button" onClick={this.handleConfirm}>
                  Save Changes
                </Button>
              )}
            </Row>
          </SpinBox>
        </Modal>
        <Modal
          visible={isOpen1}
          onCancel={() => {
            this.setState({ isOpen1: false });
          }}
          footer={null}
          centered={true}
          closable={true}
          width={500}
          className="add-icd-code-modal"
          title={'Add Custom Code'}>
          <Row className="modal-body">
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Code</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={customCode}
                  onChange={e => this.setState({ customCode: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Description</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={customDescription}
                  onChange={e => this.setState({ customDescription: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Button
                type="primary"
                className="green-btn button"
                onClick={() => {
                  this.addCptCode();
                }}>
                ADD
              </Button>
            </Row>
          </Row>
        </Modal>
        {this.props.reportData.pricing_group_id && this.state.surgical_pricing_plans && (
          <Modal
            visible={true}
            onCancel={() => {
              this.setState({ surgical_pricing_plans: false });
            }}
            footer={null}
            centered={true}
            closable={true}
            width={500}
            className="add-icd-code-modal"
            title="Select Price">
            <Row className="modal-body">
              <Select
                showSearch
                placeholder="Please select"
                optionFilterProp="children"
                className="dropdown"
                onChange={value => {
                  this.setState({ surgical_pricing_plans: null }, () => {
                    this.addPrice(value);
                  });
                }}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                {[
                  {
                    price: this.state.surgical_pricing_plans[0].price,
                    level_number: 'Base',
                  },
                  ...this.state.surgical_pricing_plans[0].levels,
                ].map((item, index) => (
                  <Option value={item.price} key={index}>
                    {item.level_number + ` (${formatCurrency(item.price)})`}
                  </Option>
                ))}
              </Select>
            </Row>
          </Modal>
        )}
        {this.state.isOpenSelectPrice && (
          <Modal
            visible={this.state.isOpenSelectPrice}
            onCancel={() => {
              this.setState({ isOpenSelectPrice: false });
            }}
            footer={null}
            centered={true}
            closable={true}
            width={500}
            className="add-icd-code-modal"
            title="Select Price"
            destroyOnClose>
            <Row className="modal-body">
              <Radio.Group
                className="percentage-options"
                onChange={e => {
                  this.setState({
                    percentage: e.target.value,
                  });
                }}
                value={percentage}>
                {PERCENTAGES.map(item => (
                  <Radio value={item.value} key={item.label}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
              <Select
                showSearch
                placeholder="Please select"
                optionFilterProp="children"
                className="dropdown dropdown__fees"
                onChange={value => {
                  this.setState({ isOpenSelectPrice: false }, () => {
                    const price = Number.parseFloat(findACodeData[value] * percentage).toFixed(2);
                    this.addPrice(price);
                  });
                }}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                {(isOWCPRequest ? OWCP_FEES : FINDACODE_FEES).map(item => {
                  const fee = Object.keys(findACodeData).length > 0 ? Number.parseFloat(findACodeData[item.key]) : null;
                  if (Number.isNaN(fee)) {
                    return <></>;
                  }

                  return (
                    <Option value={item.value} key={item.key} disabled={!fee}>
                      {item.label + ` (${formatCurrency(Number.parseFloat(fee) * percentage)})`}
                    </Option>
                  );
                })}
              </Select>
              {isOWCPRequest && findACodeData?.status && findACodeData?.status?.status_code !== OWCP_STATUS_COVERED && (
                <Alert
                  message={findACodeData?.status?.definition}
                  description={findACodeData?.status?.action}
                  type="warning"
                  showIcon
                />
              )}
            </Row>
          </Modal>
        )}
        {this.state.OpenBlueBook && (
          <Modal
            visible={this.state.OpenBlueBook}
            onCancel={() => {
              this.setState({ OpenBlueBook: false });
            }}
            footer={null}
            centered={true}
            closable={true}
            width={500}
            className="add-icd-code-modal"
            title={'Select Price'}>
            <Row className="modal-body">
              <Select
                showSearch
                placeholder="Please select"
                optionFilterProp="children"
                className="dropdown"
                onChange={value => {
                  this.setState({ OpenBlueBook: false }, () => {});
                  if (value === 1) {
                    this.addPrice(
                      parseFloat(
                        this.state.blueBook.procedure_details.PricingInformations[0].TotalHighPrice1.substring(1)
                          .split(',')
                          .join('')
                      )
                    );
                    let sections = this.state.blueBook.procedure_details.PricingInformations[0].PricingSections;
                    let comments = '';
                    sections.map(section => {
                      comments +=
                        formatCurrency(parseFloat(section.HighFee.substring(1).split(',').join(''))) +
                        ' : ' +
                        section.FeeDetail +
                        '\n';
                    });
                    this.addComments(comments);
                  }
                  if (value === 2) {
                    this.addPrice(
                      parseFloat(
                        this.state.blueBook.procedure_details.PricingInformations[0].TotalLine1.substring(1)
                          .split(',')
                          .join('')
                      )
                    );
                    let sections = this.state.blueBook.procedure_details.PricingInformations[0].PricingSections;
                    let comments = '';
                    sections.map(section => {
                      comments +=
                        formatCurrency(parseFloat(section.Fee.substring(1).split(',').join(''))) +
                        ' : ' +
                        section.FeeDetail +
                        '\n';
                    });
                    this.addComments(comments);
                  }
                  if (value === 3) {
                    this.addPrice(
                      parseFloat(
                        this.state.blueBook.procedure_details.PricingInformations[0].TotalLowPrice1.substring(1)
                          .split(',')
                          .join('')
                      )
                    );
                    let sections = this.state.blueBook.procedure_details.PricingInformations[0].PricingSections;
                    let comments = '';
                    sections.map(section => {
                      comments +=
                        formatCurrency(parseFloat(section.LowFee.substring(1).split(',').join(''))) +
                        ' : ' +
                        section.FeeDetail +
                        '\n';
                    });
                    this.addComments(comments);
                  }
                }}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                {[
                  {
                    id: 1,
                    label: 'Total High Price',
                    price: this.state.blueBook.procedure_details.PricingInformations[0].TotalHighPrice1,
                  },
                  {
                    id: 2,
                    label: 'Total Line Price',
                    price: this.state.blueBook.procedure_details.PricingInformations[0].TotalLine1,
                  },
                  {
                    id: 3,
                    label: 'Total Low Price',
                    price: this.state.blueBook.procedure_details.PricingInformations[0].TotalLowPrice1,
                  },
                ].map(item => {
                  return (
                    <Option value={item.id} key={item.label}>
                      {item.label + `(${item.price})`}
                    </Option>
                  );
                })}
              </Select>
            </Row>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    settings: state.auth.settings,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {})(MedicareCostModal);
