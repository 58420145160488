import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Empty, Typography } from 'antd';
import SpinBox from 'components/SpinBox';
import './Medication.scss';
import { DetailsIcon } from '../../../components/Icons';
import { goFirstStep } from '../../../utils/report-routing';
import { REPORT_SECTIONS_DEFAULT, REPORT_TYPES_BY_ID } from '../../../constants/reports';
import { TAB_REPORT, TAB_MEDICATION } from '../Search';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { useSelector } from 'react-redux';
import { ModalYesOrNo } from 'components/Modal/ModalYesOrNo';

const { Text } = Typography;

export default function Medication(props) {
  const history = useHistory();
  const { medicationData, selectedTab, reportData = [] } = props;
  const { roles } = useSelector(state => state.auth);

  const [openModalUpgradedPlan, setOpenModalUpgradedPlan] = useState(false);

  const goToReport = report => {
    if (
      !validateOpenReportForPackage({
        tenant_id: roles.user_roles.tenant_id,
        report_type: report.report_type_id,
      })
    )
      return setOpenModalUpgradedPlan(true);

    goFirstStep({
      sections: report?.sections || REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[report.report_type_id]],
      reportType: REPORT_TYPES_BY_ID[report.report_type_id],
      report_id: report.id,
      history,
    });
  };

  if (selectedTab === TAB_MEDICATION) {
    return (
      <>
        <Row type="flex" className="table-header">
          <>
            <Col md={6}>Report Name</Col>
            <Col md={6}>Claimant Name</Col>
            <Col md={6}>Prescription</Col>
            <Col md={6} style={{ textAlign: 'center' }}>
              Prescription Strength
            </Col>
          </>
        </Row>
        <Row className="table-content">
          <SpinBox loading={props.loading}>
            {selectedTab === TAB_MEDICATION && medicationData.length > 0 ? (
              <Row>
                {medicationData.map((item, index) => (
                  <Row key={index} className="search-record">
                    <Col md={6}>
                      <Text>{item['report.report_name']}</Text>
                    </Col>
                    <Col md={6}>
                      <Text>
                        {item['report.claimant.claimant_name'] + ' ' + item['report.claimant.claimant_last_name']}
                      </Text>
                    </Col>
                    <Col md={6}>
                      <Text>{item['prescriptions']}</Text>
                    </Col>
                    <Col md={6}>
                      <Text className="prescription-strength">{item['prescription_strength']}</Text>
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <>
                {selectedTab === TAB_MEDICATION && (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
                )}
              </>
            )}

            {selectedTab === TAB_REPORT && reportData.length > 0 ? (
              <Row>
                {medicationData.map((item, index) => (
                  <Row key={index} className="search-record">
                    <Col md={7}>
                      <Text>{item['report.report_name']}</Text>
                    </Col>
                    <Col md={7}>
                      <Text>
                        {item['report.claimant.claimant_name'] + ' ' + item['report.claimant.claimant_last_name']}
                      </Text>
                    </Col>
                    <Col md={7}>
                      <Text>{item['prescriptions']}</Text>
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <>{selectedTab === TAB_REPORT && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />}</>
            )}
          </SpinBox>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row type="flex" className="table-header">
          <>
            <Col md={6}>Report Name</Col>
            <Col md={6}>Claimant Name</Col>
            <Col md={6}>Client</Col>
          </>
        </Row>
        <Row className="table-content">
          <SpinBox loading={props.loading}>
            {selectedTab === TAB_REPORT && reportData.length > 0 ? (
              <Row>
                {reportData.map((item, index) => (
                  <Row key={index} className="search-record">
                    <Col md={6}>
                      <Text>{item['report_name']}</Text>
                    </Col>
                    <Col md={6}>
                      <Text>{item['claimantName']}</Text>
                    </Col>
                    <Col md={6}>
                      <Text>{item['clientName']}</Text>
                    </Col>
                    <Col md={5}></Col>
                    <Col md={1}>
                      <DetailsIcon className="icon" onClick={() => goToReport(item)} />
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <>{selectedTab === TAB_REPORT && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />}</>
            )}
          </SpinBox>
        </Row>

        {openModalUpgradedPlan && (
          <ModalYesOrNo
            title="This report is not included in your package"
            text="Would you like to be moved to a higher package tier to include this feature? You will be billed at the new package rate $299 when your next billing cycle is due"
            onClose={() => setOpenModalUpgradedPlan(false)}
          />
        )}
      </>
    );
  }
}
