import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import InputBox from 'components/InputBox';
import UploadLogo from 'components/UploadLogo/UploadLogo';
import { Row, Col, Button, Divider } from 'antd';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Utils
import * as API from 'utils/api';
import { notifyApiError, notifyInfo } from 'utils/notification';

function CompanyInfoTap() {
  // Const
  const { id } = useParams();

  // State
  const [clientName, setClientName] = useState('');
  const [clientlogo, setClientlogo] = useState('');
  const [loading, setLoading] = useState(false);

  // Effect
  const getFolderInfo = async () => {
    try {
      setLoading(true);
      const res = await API.getParentFolderById({ id });
      setClientName(res.name);
      setClientlogo(res.logo);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFolderInfo();
  }, []);

  // Handel
  const onSubmit = async e => {
    e.preventDefault();

    const params = {
      clientFolderUpdate: {
        name: clientName,
        logo: clientlogo
      },
    };

    try {
      setLoading(true);
      await API.updateParentFolder({ body: params, id });
      notifyInfo('Parent Folder was updated');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="company-info-container">
      <form onSubmit={onSubmit}>
        <Row type="flex" justify="space-around" className="client-info" gutter={40}>
          <Col md={7}>
            <div className="logo">
              <UploadLogo
                changeImage={(client_logo) => {
                  setClientlogo(client_logo);
                }}
                client_logo={clientlogo}
              />
              {clientlogo && (
                <div onClick={() => setClientlogo('')} className="remove_logo_btn">
                  Remove
                </div>
              )}
            </div>
          </Col>
          <Col md={17} className="content">
            <Row className="row">
              <InputBox label="Client - Company Name" value={clientName} onChange={value => setClientName(value)} />
            </Row>
          </Col>
        </Row>
        <Divider className="divider-space" />

        <Row type="flex" justify="center">
          <Button type="primary" htmlType="submit" size="large" className="green-btn button">
            Save
          </Button>
        </Row>
      </form>

      {loading && <ModalLoading />}
    </div>
  );
}

export default CompanyInfoTap;
