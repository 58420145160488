import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Divider } from 'antd';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import { updateReportInfo } from 'redux/report';
import { formatCurrency } from 'utils/utils';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MSA_NAME } from 'constants/reports';
import {
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  PRESCRIPTION_MEDICATIONS_COVERED,
  DONT_CHANGE_COST_ON_THIS_TYPE,
} from 'components/MedicareCostModal/data';

import './CalculationTable.scss';

const { Title, Text } = Typography;

class CalculationTable extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    this.state = {
      report_id: report_id,
      medicareDataList: [],
      loadingTitle: 'Loading',
      loading: false,
    };
  }

  componentDidMount = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async res => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: res.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.setState({ is_template: res.is_template });
        if (!res.is_template) {
          verifyPermissions(res, this.props);
        }

        this.props.updateReportInfo(res.report);
      })
      .catch(err => forbiddenRedirect(err, this.props));
    API.getMedicareData({ reportId: report_id }).then(res => {
      this.setState({
        medicareDataList: res.medicare_cost_projections,
        loading: false,
      });
    });
  };

  render() {
    const { medicareDataList, loading } = this.state;

    const futureList = medicareDataList.filter(item => {
      return item.type.includes(FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE);
    });
    const surgeriesList = medicareDataList.filter(item => {
      return item.type.includes(DONT_CHANGE_COST_ON_THIS_TYPE);
    });
    const prescriptionList = medicareDataList.filter(item => {
      return item.type.includes(PRESCRIPTION_MEDICATIONS_COVERED);
    });

    let futureTotal = 0,
      surgeryTotal = 0,
      prescriptionTotal = 0,
      total = 0;

    medicareDataList.forEach(item => {
      if (item.type.includes(FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE)) futureTotal += item.annual_cost;
      if (item.type.includes(DONT_CHANGE_COST_ON_THIS_TYPE)) surgeryTotal += item.annual_cost;
      if (item.type.includes(PRESCRIPTION_MEDICATIONS_COVERED)) prescriptionTotal += item.annual_cost;
      total += item.annual_cost;
    });

    return (
      <Row className="msa-calculation-table-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown loading={loading} />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <Text className="title">MSA Calculation Table</Text>
                </Col>
              </Row>
              <Row>
                <Row type="flex" className="table-header">
                  <Col md={8}>Service</Col>
                  <Col md={2}>Frequency</Col>
                  <Col md={3}>Every X Years</Col>
                  <Col md={3}>Number of Years</Col>
                  <Col md={4}>Price Per Service</Col>
                  <Col md={4}>Total</Col>
                </Row>
                <Row className="table-content">
                  <Row className="section-title">
                    <Text>Periodic Items and Services</Text>
                  </Row>
                  <Row>
                    {futureList.map((item, index) => (
                      <Row key={index} className="record">
                        <Col md={8} className="text-left">
                          <Text>{item.name}</Text>
                        </Col>
                        <Col md={2}>
                          <Text>{item.units_needed}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.every_x_year}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.total_years}</Text>
                        </Col>
                        <Col md={4} className="text-right">
                          <Text>{formatCurrency(item.total_cost)}</Text>
                        </Col>
                        <Col md={4} className="text-right">
                          <Text>{formatCurrency(item.annual_cost)}</Text>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left">
                      <Text>Sub Total</Text>
                    </Col>
                    <Col md={4} className="text-right">
                      <Text>{formatCurrency(futureTotal)}</Text>
                    </Col>
                  </Row>
                  <Row className="section-title">
                    <Text>Surgeries, Replacements and Procedures</Text>
                  </Row>
                  <Row>
                    {surgeriesList.map((item, index) => (
                      <Row key={index} className="record">
                        <Col md={8} className="text-left">
                          <Text>{item.name}</Text>
                        </Col>
                        <Col md={2}>
                          <Text>{item.units_needed}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.every_x_year}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.total_years}</Text>
                        </Col>
                        <Col md={4} className="text-right">
                          <Text>{formatCurrency(item.total_cost)}</Text>
                        </Col>
                        <Col md={4} className="text-right">
                          <Text>{formatCurrency(item.annual_cost)}</Text>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left">
                      <Text>Sub Total</Text>
                    </Col>
                    <Col md={4} className="text-right">
                      <Text>{formatCurrency(surgeryTotal)}</Text>
                    </Col>
                  </Row>
                  <Row className="section-title">
                    <Text>Prescriptions</Text>
                  </Row>
                  <Row>
                    {prescriptionList.map((item, index) => (
                      <Row key={index} className="record">
                        <Col md={8} className="text-left">
                          <Text>{item.prescriptions}</Text>
                        </Col>
                        <Col md={2}>
                          <Text>{item.units_needed}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.every_x_year}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.total_years}</Text>
                        </Col>
                        <Col md={4} className="text-right">
                          <Text>{formatCurrency(item.total_cost)}</Text>
                        </Col>
                        <Col md={4} className="text-right">
                          <Text>{formatCurrency(item.annual_cost)}</Text>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left">
                      <Text>Sub Total</Text>
                    </Col>
                    <Col md={4} className="text-right">
                      <Text>{formatCurrency(prescriptionTotal)}</Text>
                    </Col>
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left">
                      <Text>Total</Text>
                    </Col>
                    <Col md={4} className="text-right">
                      <Text>{formatCurrency(total)}</Text>
                    </Col>
                  </Row>
                </Row>
              </Row>
              <Divider className="divider" />
              <PrevNextStep />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(CalculationTable);
