import React from 'react';

// Components
import { Switch } from 'antd';

// Style
import './notificationSettingItem.scss';

const NotificationSettingItem = ({ title, onChange, active }) => (
  <div className="notificationSettingItem">
    <div onClick={e => e.stopPropagation()}>
      <Switch className="litigation_switch" defaultChecked={active} onChange={onChange} checked={active} />
    </div>
    <p>{title}</p>
  </div>
);

export default NotificationSettingItem;
