import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Typography, DatePicker, Input, Divider } from 'antd';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MCP_MSA_COMBO_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

import './SubmitCoverLetter.scss';

const { Title, Text } = Typography;

class SubmitCoverLetter extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      proposed_settlement_date: moment(),
      age_at_proposed_settlement_date: 0,
      loadingTitle: 'Loading',
      loading: false,
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const { claimant } = reportInfo;
        let age = 0;
        if (claimant) {
          age = moment().diff(claimant.dob, 'years');
        }
        this.setState({
          is_template: reportInfo.is_template,
          proposed_settlement_date: reportInfo.proposed_settlement_date
            ? moment(reportInfo.proposed_settlement_date)
            : moment(),
          age_at_proposed_settlement_date: age,
          loading: false,
        });
        this.props.updateReportInfo(reportInfo);
      })
      .catch(err => forbiddenRedirect(err, this.props));
  };

  componentDidUpdate = prevProps => {
    const { reportInfo } = this.props;
    const { claimant } = reportInfo;
    let age = 0;
    if (claimant) {
      age = moment().diff(claimant.dob, 'years');
    }
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        proposed_settlement_date: reportInfo.proposed_settlement_date
          ? moment(reportInfo.proposed_settlement_date)
          : moment(),
        age_at_proposed_settlement_date: age,
        loading: false,
      });
    }
  };

  handleDateChange = (date, dateString) => {
    const { claimant } = this.props.reportInfo;
    let age = 0;
    if (claimant) {
      age = moment(date).diff(claimant.dob, 'years');
    }
    this.setState({
      proposed_settlement_date: date,
      age_at_proposed_settlement_date: age,
    });
  };

  handleUpdateReport = async (isNextStep = false) => {
    const { report_id, proposed_settlement_date, age_at_proposed_settlement_date } = this.state;
    const params = {
      id: report_id,
      proposed_settlement_date: moment(proposed_settlement_date).format('MM/DD/YYYY'),
      age_at_proposed_settlement_date,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { proposed_settlement_date, age_at_proposed_settlement_date, loadingTitle, loading, REPORT_PERMISSIONS } =
      this.state;
    const { reportInfo } = this.props;
    const claimant = reportInfo.claimant ? reportInfo.claimant : null;

    return (
      <Row className="msa-submit-cover-letter-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MCP_MSA_COMBO_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown loading={loading} beforeNext={async () => await this.handleUpdateReport(false)} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Submitter Cover Letter</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Name</Text>
                </Col>
                <Text className="text">
                  {claimant &&
                    claimant.claimant_name + ' ' + claimant.claimant_middle_name + ' ' + claimant.claimant_last_name}
                </Text>
              </Row>
              {claimant && claimant.address && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Address</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.address + ' ' + claimant.additional_addres}</Text>
                </Row>
              )}
              {claimant && claimant.claimant_phone && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Phone</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.claimant_phone}</Text>
                </Row>
              )}
              {claimant && claimant.fax && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Fax</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.fax}</Text>
                </Row>
              )}
              {claimant && claimant.claimant_email && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Email</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.claimant_email}</Text>
                </Row>
              )}
              {claimant && claimant.SSN && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>SSN</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.SSN}</Text>
                </Row>
              )}
              {claimant && claimant.HICN && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>HICN</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.HICN}</Text>
                </Row>
              )}
              {claimant && claimant.Gender && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Gender</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.Gender}</Text>
                </Row>
              )}
              <Row type="flex" align="middle" className="row">
                <Col className="label">
                  <Text>Proposed settlement date</Text>
                </Col>
                <DatePicker
                  className="date"
                  allowClear={false}
                  disabled={!REPORT_PERMISSIONS.edit}
                  format="MM/DD/YYYY"
                  value={proposed_settlement_date}
                  onChange={this.handleDateChange}
                />
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Age at proposed settlement date</Text>
                </Col>
                <Input className="input" value={age_at_proposed_settlement_date} disabled={true} />
              </Row>
              {(claimant && claimant.rated_age !== null) ||
                (claimant && claimant.rated_age !== 0 && (
                  <Row type="flex" className="row">
                    <Col className="label">
                      <Text>Rated Age</Text>
                    </Col>
                    <Text className="text">{claimant && claimant.rated_age}</Text>
                  </Row>
                ))}
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Life Expectancy</Text>
                </Col>
                <Text className="text">{reportInfo && reportInfo.life_expectancy}</Text>
              </Row>
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    roles: state.auth.roles,
    userInfo: state.auth.userInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(SubmitCoverLetter);
