import {
  SUPER_ADMIN_ID,
  MEDICAL_REVIEWER_ID,
  NURSE_WRITER_ID,
  INTERNAL_QA_ID,
  CLIENT_USER_ID,
  ATTORNEY_MANAGER_ID,
  EXTERNAL_LITIGATION_ATTORNEY_ID,
  CLIENT_ADMIN_ID,
  SALES_MANAGER_ID,
  SALES_REP_ID,
  MEDICAL_ADMIN_ID,
  CLIENT_SUPER_ADMIN_ID,
  DIRECTOR_OF_SALES_ID,
} from 'layout/roles';

import { SPECIALTY_LEGAL_NURSE, SPECIALTY_MD_NEUROSURGEON, SPECIALTY_MD_PMR } from 'constants/specialty';

export const ASSIGNMENTS_GROUP = 'Assignments';
export const REPORT_GROUP = 'Reports';
export const COMMENTS_GROUP = 'Comments';
export const INVOICES_GROUP = 'Invoices';
export const REGISTRATION_GROUP = 'Registration';
export const FILES_GROUP = 'Files';
export const ROLES_GROUP = 'Roles';
export const EXTERNAL_LITIGATION_GROUP = 'External Litigation Attorney';
export const SALES_REP_GROUP = 'Sales Rep';
export const REDUCTION_REVISION_GROUP = 'Reduction / Revision';
export const NURSE_WRITER_AND_QA_GROUP = 'Nurse Writer / QA';
export const MEDICAL_REVIEW_GROUP = 'Medical Review';
export const SALES_GROUP = 'Sales';
export const REVIEW_REQUESTED_GROUP = 'Review Requested';

export const NOTIFICATION_ID_5 = {
  name: 'New files have been uploaded for a claimant',
  notificationId: 5,
};
export const NOTIFICATION_ID_8 = {
  name: 'An additional report has been requested for an existing claimant',
  notificationId: 8,
};
export const NOTIFICATION_ID_11 = {
  name: 'A comment has been left on a claimant folder',
  notificationId: 11,
};
export const NOTIFICATION_ID_13 = {
  name: 'An invoice has been added to a claimant',
  notificationId: 13,
};
export const NOTIFICATION_ID_14 = {
  name: 'A revision has been requested',
  notificationId: 14,
};
export const NOTIFICATION_ID_24 = {
  name: 'Report has returned from QA Attorney',
  notificationId: 24,
};
export const NOTIFICATION_ID_25 = {
  name: 'Report is ready',
  notificationId: 25,
};
export const NOTIFICATION_ID_27 = {
  name: 'A reduction has been requested',
  notificationId: 27,
};
export const NOTIFICATION_ID_29 = {
  name: 'User has assigned a report to you',
  notificationId: 29,
};
export const NOTIFICATION_ID_34 = {
  name: 'Medical review requested on a report',
  notificationId: 34,
};
export const NOTIFICATION_ID_36 = {
  name: 'A new law firm has registered',
  notificationId: 36,
};
export const NOTIFICATION_ID_37 = {
  name: 'Sales Rep has been assigned to Sales Manager',
  notificationId: 37,
};
export const NOTIFICATION_ID_38 = {
  name: 'Sales Rep has been assigned to a client',
  notificationId: 38,
};
export const NOTIFICATION_ID_39 = {
  name: 'A new user has attempted to register under a law firm',
  notificationId: 39,
};
export const NOTIFICATION_ID_40 = {
  name: 'Client user registration has been accepted by law firm',
  notificationId: 40,
};
export const NOTIFICATION_ID_41 = {
  name: 'Client user registration has been rejected by Client Admin',
  notificationId: 41,
};
export const NOTIFICATION_ID_42 = {
  name: 'You have an unpaid invoice',
  notificationId: 42,
};
export const NOTIFICATION_ID_43 = {
  name: 'A report has been requested for a new claimant',
  notificationId: 43,
};
export const NOTIFICATION_ID_45 = {
  name: 'Client Admin has created a new Client Super Admin',
  notificationId: 45,
};
export const NOTIFICATION_ID_46 = {
  name: 'Client Admin has a new External Litigation Attorney',
  notificationId: 46,
};
export const NOTIFICATION_ID_47 = {
  name: 'Law firm has assigned an External Litigation Attorney to a claimant folder',
  notificationId: 47,
};
export const NOTIFICATION_ID_50 = {
  name: 'User has assigned a Sales Manager to a Client folder',
  notificationId: 50,
};

export const QA_ATTORNEY_NOTIFICATION = [
  {
    name: ASSIGNMENTS_GROUP,
    notification: [
      {
        name: 'User has assigned you a report for QA',
        notificationId: 17,
      },
    ],
  },
];

export const REDUCTION_ATTORNEY_NOTIFICATION = [
  {
    name: ASSIGNMENTS_GROUP,
    notification: [
      {
        name: 'User has assigned a report to you for Reduction',
        notificationId: 15,
      },
    ],
  },
];

export const EXTERNAL_LITIGATION_ATTORNEY_NOTIFICATION = [
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_8, NOTIFICATION_ID_25],
  },
  {
    name: ASSIGNMENTS_GROUP,
    notification: [
      {
        name: 'You,ve been assigned to a claimant folder',
        notificationId: 48,
      },
    ],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
];

export const CLIENT_USER_NOTIFICATION = [
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_25],
  },
  {
    name: INVOICES_GROUP,
    notification: [
      NOTIFICATION_ID_13,
      // NOTIFICATION_ID_42
    ],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
  // al client ocultar las 43
];

export const CLIENT_ADMIN_NOTIFICATION = [
  {
    name: REGISTRATION_GROUP,
    notification: [NOTIFICATION_ID_39],
  },
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_25],
  },
  {
    name: INVOICES_GROUP,
    notification: [
      NOTIFICATION_ID_13,
      // NOTIFICATION_ID_42
    ],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
];

export const ClIENT_SUPER_ADMIN_NOTIFICATION = [
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_43, NOTIFICATION_ID_8, NOTIFICATION_ID_25],
  },
  {
    name: INVOICES_GROUP,
    notification: [
      NOTIFICATION_ID_13,
      // NOTIFICATION_ID_42
    ],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
];

export const NURSE_WRITER_NOTIFICATION = [
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_8, NOTIFICATION_ID_14, NOTIFICATION_ID_24],
  },
  {
    name: ASSIGNMENTS_GROUP,
    notification: [NOTIFICATION_ID_29],
  },
  {
    name: FILES_GROUP,
    notification: [NOTIFICATION_ID_5],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
];

export const SALES_REP_NOTIFICATION = [
  {
    name: REGISTRATION_GROUP,
    notification: [NOTIFICATION_ID_41],
  },
  {
    name: ASSIGNMENTS_GROUP,
    notification: [
      {
        name: 'You have been assigned to a Client',
        notificationId: NOTIFICATION_ID_38.notificationId,
      },
    ],
  },
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_43, NOTIFICATION_ID_8, NOTIFICATION_ID_25, NOTIFICATION_ID_27, NOTIFICATION_ID_14],
  },
  {
    name: INVOICES_GROUP,
    notification: [
      NOTIFICATION_ID_13,
      // NOTIFICATION_ID_42
    ],
  },
  {
    name: FILES_GROUP,
    notification: [NOTIFICATION_ID_5],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
  {
    name: ROLES_GROUP,
    notification: [NOTIFICATION_ID_45],
  },
  {
    name: EXTERNAL_LITIGATION_GROUP,
    notification: [
      NOTIFICATION_ID_46,
      {
        name: 'External Litigation Attorney has been assigned to a claimant folder',
        notificationId: 49,
      },
    ],
  },
];

export const SALES_MANAGER_NOTIFICATION = [
  {
    name: REGISTRATION_GROUP,
    notification: [
      NOTIFICATION_ID_36,
      NOTIFICATION_ID_40,
      {
        name: 'A client has been rejected by a Client Admin',
        notificationId: 41,
      },
    ],
  },
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_43, NOTIFICATION_ID_8, NOTIFICATION_ID_25, NOTIFICATION_ID_27, NOTIFICATION_ID_14],
  },
  {
    name: INVOICES_GROUP,
    notification: [
      NOTIFICATION_ID_13,
      // NOTIFICATION_ID_42
    ],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
  {
    name: ASSIGNMENTS_GROUP,
    notification: [
      {
        name: 'You have been assigned to a law firm folder',
        notificationId: 49,
      },
    ],
  },
  {
    name: SALES_REP_GROUP,
    notification: [
      {
        name: 'Sales Rep has been assigned to you',
        notificationId: 50,
      },
      NOTIFICATION_ID_38,
    ],
  },
  {
    name: EXTERNAL_LITIGATION_GROUP,
    notification: [NOTIFICATION_ID_46, NOTIFICATION_ID_47],
  },
  {
    name: ROLES_GROUP,
    notification: [NOTIFICATION_ID_45],
  },
];

export const DIRECTOR_OF_SALES_NOTIFICATION = [
  {
    name: REGISTRATION_GROUP,
    notification: [
      NOTIFICATION_ID_36,
      {
        name: 'Client Admin has reject a Client',
        notificationId: NOTIFICATION_ID_41.notificationId,
      },
    ],
  },
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_43, NOTIFICATION_ID_8],
  },
  {
    name: INVOICES_GROUP,
    notification: [
      NOTIFICATION_ID_13,
      // NOTIFICATION_ID_42
    ],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
  {
    name: SALES_REP_GROUP,
    notification: [NOTIFICATION_ID_37, NOTIFICATION_ID_38, NOTIFICATION_ID_50],
  },
  {
    name: ROLES_GROUP,
    notification: [
      {
        name: 'Super Admin has created a new user (role type)',
        notificationId: 44,
      },
      NOTIFICATION_ID_45,
    ],
  },
  {
    name: EXTERNAL_LITIGATION_GROUP,
    notification: [NOTIFICATION_ID_46, NOTIFICATION_ID_47],
  },
];

export const MEDICAL_ADMIN_NOTIFICATION = [
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_43, NOTIFICATION_ID_8, NOTIFICATION_ID_27, NOTIFICATION_ID_14],
  },
  {
    name: ASSIGNMENTS_GROUP,
    notification: [NOTIFICATION_ID_29],
  },
  {
    name: FILES_GROUP,
    notification: [NOTIFICATION_ID_5],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
];

export const SUPER_ADMIN_NOTIFICATION = [
  {
    name: REGISTRATION_GROUP,
    notification: [NOTIFICATION_ID_36, NOTIFICATION_ID_39, NOTIFICATION_ID_40, NOTIFICATION_ID_41],
  },
  {
    name: REPORT_GROUP,
    notification: [NOTIFICATION_ID_43, NOTIFICATION_ID_8, NOTIFICATION_ID_25],
  },
  {
    name: REDUCTION_REVISION_GROUP,
    notification: [
      NOTIFICATION_ID_27,
      {
        name: 'User has assigned a reduction request for claimant',
        notificationId: 15,
      },
      {
        name: 'A reduction request is ready',
        notificationId: 16,
      },
      NOTIFICATION_ID_14,
      {
        name: 'User has assigned a report for claimant to QA',
        notificationId: 17,
      },
    ],
  },
  {
    name: INVOICES_GROUP,
    notification: [
      NOTIFICATION_ID_13,
      // NOTIFICATION_ID_42
    ],
  },
  {
    name: FILES_GROUP,
    notification: [NOTIFICATION_ID_5],
  },
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
  {
    name: ROLES_GROUP,
    notification: [
      NOTIFICATION_ID_45,
      {
        name: 'Sales Head has created a new user (role type)',
        notificationId: 44,
      },
    ],
  },
  {
    name: NURSE_WRITER_AND_QA_GROUP,
    notification: [
      {
        name: 'User has assigned a report to Nurse Writer',
        notificationId: NOTIFICATION_ID_29.notificationId,
      },
      NOTIFICATION_ID_24,
    ],
  },
  {
    name: MEDICAL_REVIEW_GROUP,
    notification: [
      {
        name: 'Nurse Writer has requested a Legal Nurse review',
        notificationId: 30,
      },
      {
        name: 'Nurse Writer needs a secondary signature on a report',
        notificationId: 31,
      },
      {
        name: 'A medical Review has been requested for a report',
        notificationId: NOTIFICATION_ID_34.notificationId,
      },
    ],
  },
  {
    name: SALES_GROUP,
    notification: [NOTIFICATION_ID_38, NOTIFICATION_ID_37, NOTIFICATION_ID_50],
  },
  {
    name: EXTERNAL_LITIGATION_GROUP,
    notification: [NOTIFICATION_ID_46, NOTIFICATION_ID_47],
  },
];

export const MEDICAL_REVIEWER_NOTIFICATION = [
  {
    name: COMMENTS_GROUP,
    notification: [NOTIFICATION_ID_11],
  },
];

export const LEGAL_NURSE_SUPERVISOR_NOTIFICATION = [
  {
    name: REVIEW_REQUESTED_GROUP,
    notification: [
      {
        name: 'Legal Nurse review requested',
        notificationId: 30,
      },
      {
        name: 'Secondary signature needed on a report',
        notificationId: 31,
      },
    ],
  },
];

export const NEUROSURGEIN_MD_NOTIFICATION = [
  {
    name: REVIEW_REQUESTED_GROUP,
    notification: [NOTIFICATION_ID_34],
  },
];

export const PMR_MD_NOTIFICATION = [
  {
    name: REVIEW_REQUESTED_GROUP,
    notification: [NOTIFICATION_ID_34],
  },
];

export const NOTIFICATION_BY_ROLE_ID = {
  [SUPER_ADMIN_ID]: SUPER_ADMIN_NOTIFICATION,
  [MEDICAL_REVIEWER_ID]: [],
  [NURSE_WRITER_ID]: NURSE_WRITER_NOTIFICATION,
  [INTERNAL_QA_ID]: QA_ATTORNEY_NOTIFICATION,
  [CLIENT_USER_ID]: CLIENT_USER_NOTIFICATION,
  [ATTORNEY_MANAGER_ID]: REDUCTION_ATTORNEY_NOTIFICATION,
  [EXTERNAL_LITIGATION_ATTORNEY_ID]: EXTERNAL_LITIGATION_ATTORNEY_NOTIFICATION,
  [CLIENT_ADMIN_ID]: CLIENT_ADMIN_NOTIFICATION,
  [SALES_MANAGER_ID]: SALES_MANAGER_NOTIFICATION,
  [SALES_REP_ID]: SALES_REP_NOTIFICATION,
  [MEDICAL_ADMIN_ID]: MEDICAL_ADMIN_NOTIFICATION,
  [CLIENT_SUPER_ADMIN_ID]: ClIENT_SUPER_ADMIN_NOTIFICATION,
  [DIRECTOR_OF_SALES_ID]: DIRECTOR_OF_SALES_NOTIFICATION,
};

export const NOTIFICATION_BY_MEDICAL_REVIEWER = {
  0: MEDICAL_REVIEWER_NOTIFICATION,
  [SPECIALTY_LEGAL_NURSE.id]: LEGAL_NURSE_SUPERVISOR_NOTIFICATION,
  [SPECIALTY_MD_NEUROSURGEON.id]: NEUROSURGEIN_MD_NOTIFICATION,
  [SPECIALTY_MD_PMR.id]: PMR_MD_NOTIFICATION,
};
