import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Progress } from 'antd';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import ExportButton from '../../ReportCabinet/components/ExportButton';
import { DASHBOARD_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';
import {
  NurseAssignementIcon,
  NurseReportIcon,
  NurseClientsIcon,
  NurseIconUsers,
  AssignmentsIcon,
  ReportsIcon,
  ClientIcon,
  ClaimantsIcon,
} from 'components/Icons';
import SpinBox from 'components/SpinBox';
import { logout } from 'redux/auth/actions';
import * as API from 'utils/api';

import './NurseDash.scss';

const { Title } = Typography;
const MonthInfo = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};
const CompleteMonthName = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

const friendlyKeys = {
  report_id: 'Report ID',
  name: 'Report',
  'claimant.claimantName': 'Claimant',
  'claimant.client.client_name': 'Client',
  link: 'Link',
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    // Permissions
    const { DASHBOARD_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [DASHBOARD_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      chartData: [],
      loading: false,
      selectedPeriod: 'Current Year',
      visible: false,
      chartDataCopy: [],
      list_reports: [],
      DASHBOARD_PERMISSIONS,
      totalCounts: {},
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    Promise.all([
      API.getDashboardData().then(res => {
        let chartData = res.reportCountLast12Month;
        chartData.sort(function (a, b) {
          return a.dt > b.dt ? 1 : -1;
        });
        this.setState({
          chartData: chartData,
          chartDataCopy: chartData,
          loading: false,
        });
      }),
      API.getDashboardCounts().then(res => {
        this.setState({ totalCounts: res });
      }),
    ]);
  }

  handleMenuClick(e) {
    if (e.key === '00') {
      let ch = this.state.chartDataCopy;
      this.setState({
        visible: false,
        chartData: ch,
        selectedPeriod: e.item.props.children,
        list_reports: [],
      });
    } else {
      API.getReportsByMonth(e.key)
        .then(res => {
          let ch = res.day_count;
          ch.sort(function (a, b) {
            return a.dt > b.dt ? 1 : -1;
          });
          this.setState({
            visible: false,
            chartData: ch,
            selectedPeriod: e.item.props.children,
            list_reports: res.reports_month,
          });
        })
        .catch(err => console.error(err));
    }
  }

  handleVisibleChange(flag) {
    this.setState({ visible: flag });
  }

  render() {
    const {
      chartData,
      loading,
      DASHBOARD_PERMISSIONS,
      totalCounts,
    } = this.state;

    let ratio = parseInt(
      (parseInt(totalCounts.count_reports || 0) /
        (parseInt(totalCounts.count_claimants || 0) + parseInt(totalCounts.count_reports || 0))) *
        100
    );

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="00">Current year</Menu.Item>
        <Menu.Item key="01">January</Menu.Item>
        <Menu.Item key="02">February</Menu.Item>
        <Menu.Item key="03">March</Menu.Item>
        <Menu.Item key="04">April</Menu.Item>
        <Menu.Item key="05">May</Menu.Item>
        <Menu.Item key="06">June</Menu.Item>
        <Menu.Item key="07">July</Menu.Item>
        <Menu.Item key="08">August</Menu.Item>
        <Menu.Item key="09">September</Menu.Item>
        <Menu.Item key="010">October</Menu.Item>
        <Menu.Item key="011">November</Menu.Item>
        <Menu.Item key="012">December</Menu.Item>
      </Menu>
    );

    return (
      <Row className="dashboard-container">
        <Row type="flex" align="middle" justify="center" className="header">
          <Title level={4} className="title">
            Dashboard
          </Title>
        </Row>
        <SpinBox loading={loading}>
          {DASHBOARD_PERMISSIONS.view && (
            <Row type="flex" justify="space-between" className="dashboard-inner-wrapper">
              <Col onClick={() => this.props.history.push('/app/Assign')} className="dashboard-cards-wrapper">
                <Row className="badge-container">
                  <div className="badge-cards-icon">
                    <span>{totalCounts.count_my_assignments || 0}</span>
                  </div>
                </Row>
                <Row className="icon">
                  <NurseAssignementIcon />
                </Row>
                <Row className="text ">My Assignments</Row>
              </Col>
              <Col
                onClick={() => this.props.history.push('/app/reports')}
                className="dashboard-cards-wrapper button-item">
                <Row className="badge-container">
                  <div className="badge-cards-icon">
                    <span>{totalCounts.count_reports || 0}</span>
                  </div>
                </Row>
                <Row className="icon">
                  <NurseReportIcon />
                </Row>

                <Row className="text">Reports</Row>
              </Col>
              <Col
                onClick={() => this.props.history.push('/app/clients')}
                className="dashboard-cards-wrapper button-item">
                <Row className="badge-container">
                  <div className="badge-cards-icon">
                    <span>{totalCounts.count_clients || 0}</span>
                  </div>
                </Row>
                <Row className="icon">
                  <NurseClientsIcon />
                </Row>
                <Row className="text">My Clients</Row>
              </Col>
              <Col
                onClick={() => this.props.history.push('/app/claimants')}
                className="dashboard-cards-wrapper button-item">
                <Row className="badge-container">
                  <div className="badge-cards-icon">
                    <span>{totalCounts.count_claimants || 0}</span>
                  </div>
                </Row>
                <Row className="icon">
                  <NurseIconUsers />
                </Row>
                <Row className="text">My Claimants</Row>
              </Col>
            </Row>
          )}
          <Row type="flex" justify="space-between" className="chart">
            <Col md={16} xl={16} className="area-chart">
              <Row className="chart-header">
                <Col className="title">Reports Overview</Col>
                <div className="buttons-in-line">
                  <Dropdown
                    overlay={menu}
                    onVisibleChange={this.handleVisibleChange}
                    visible={this.state.visible}
                    className="drop-down-space-between">
                    <a onClick={e => e.preventDefault()}>
                      {this.state.selectedPeriod} <DownOutlined />
                    </a>
                  </Dropdown>
                  <ExportButton
                    friendlyKeys={friendlyKeys}
                    reports={this.state.list_reports}
                    createdAt={null}
                    inlineStyle={true}>
                    <DownloadOutlined />
                  </ExportButton>
                </div>
              </Row>
              <Row className="main-chart">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={chartData} margin={{ top: 10, right: 40, left: 10, bottom: 30 }}>
                    <CartesianGrid vertical={false} strokeDasharray="2 4" />
                    <defs>
                      <linearGradient id="colorReport" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgba(131, 227, 99, 0.8)" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="rgba(131, 227, 99, 0)" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="dt"
                      height={30}
                      padding={{ left: 0 }}
                      tick={<CustomizedAxisTick />}
                      tickLine={false}
                    />
                    <YAxis dataKey="count" dx={-5} axisLine={false} tickLine={false} />
                    <Tooltip content={renderTooltipContent} />
                    <Area
                      type="monotone"
                      dataKey="count"
                      stroke="#83e363"
                      fillOpacity={1}
                      fill="url(#colorReport)"
                      dot={<CustomizedDot />}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Row>
            </Col>
            <Col sm={16} md={12} xl={7} className="circle-chart">
              <Progress
                percent={100}
                successPercent={ratio}
                type="circle"
                width={250}
                strokeWidth={7}
                strokeColor="#7e77ff"
                strokeLinecap="square"
                className="main-chart"
                format={() => {
                  return (
                    <Row>
                      <Row className="percentage">
                        {totalCounts.count_claimants || 0} / {totalCounts.count_reports || 0}
                      </Row>
                      <Row className="description">Claimants / Reports</Row>
                    </Row>
                  );
                }}
              />
              <Row type="flex" justify="space-between" className="description">
                <Col className="label">
                  <span className="icon-1 icon-title" />
                  Claimants
                </Col>
                <Col className="label">
                  <span className="icon-2" />
                  Reports
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" className="footer-container">
            <Col className="dashboard-footer-wrapper">
              <div className="title-card">Total Open Assignments</div>
              <div className="content-container">
                <div className="count">{totalCounts.count_open_assignments || 0}</div>
                <AssignmentsIcon />
              </div>
            </Col>
            <Col className="dashboard-footer-wrapper">
              <div className="title-card">Total Reports</div>
              <div className="content-container">
                <div className="count">{totalCounts.count_reports || 0}</div>
                <ReportsIcon />
              </div>{' '}
            </Col>
            <Col className="dashboard-footer-wrapper">
              <div className="title-card">Total Clients</div>
              <div className="content-container">
                <div className="count">{totalCounts.count_clients || 0}</div>
                <ClientIcon />
              </div>{' '}
            </Col>
            <Col className="dashboard-footer-wrapper">
              <div className="title-card">Total Claimants</div>
              <div className="content-container">
                <div className="count">{totalCounts.count_claimants || 0}</div>
                <ClaimantsIcon />
              </div>{' '}
            </Col>
          </Row>
        </SpinBox>
      </Row>
    );
  }
}

class CustomizedDot extends React.Component {
  render() {
    const { cx, cy } = this.props;

    return <circle cx={cx} cy={cy} r={4} stroke="#83e363" strokeWidth={2} fill="white" />;
  }
}

class CustomizedAxisTick extends React.Component {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={12} y={10} dy={16} textAnchor="end" fill="#666">
          {MonthInfo[payload.value] || payload.value}
        </text>
      </g>
    );
  }
}

const renderTooltipContent = o => {
  const { payload, label } = o;
  return (
    <div className="tooltip-box">
      {payload?.map((entry, index) => (
        <Fragment key={index}>
          <p className="tooltip-box-text-title" key={`Month-${index}`} style={{ color: entry.color }}>{`${
            CompleteMonthName[label] || label
          }`}</p>
          <p className="tooltip-box-text" key={`Count-${index}`}>
            {`Total Reports: ${entry.value}`}
          </p>
        </Fragment>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, { logout })(Dashboard);
