import React from 'react';
import 'antd/dist/antd.css';
import { Menu, Dropdown, Typography } from 'antd';
import { MoreOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { EditIcon, RemoveIcon, CloneIcon } from 'components/Icons';

const MedicareCostMenu = (props) => {
    const { handleClone, handleDelete, handleEdit, handleChangePosition, item } = props;
    const userMenu = (
        <Menu key="1" style={{ width: 170 }}>
            <Menu.Item kay="1" onClick={() => handleClone(item)} >
                <Typography> <CloneIcon className="icon" style={{ marginRight: '5px' }} /> Clone </Typography>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleEdit('EDIT', item)} >
                <Typography> <EditIcon className="icon" style={{ marginRight: '5px' }} /> Edit </Typography>
            </Menu.Item>
            <Menu.Item key="3" onClick={() => handleDelete(item)} >
                <Typography> <RemoveIcon className="icon" style={{ marginRight: '5px' }} /> Delete </Typography>
            </Menu.Item>
            {handleChangePosition && (
                <>
                    <Menu.Item key="4" onClick={() => { handleChangePosition(item.id, 'up'); }}>
                        <Typography> <UpOutlined className="icon" style={{ fontSize: 15, fontWeight: 'bold', color: '#0082FF', marginRight: '8px' }} />Move Item Up </Typography>
                    </Menu.Item>
                    <Menu.Item key="5" onClick={() => { handleChangePosition(item.id, 'down'); }}>
                        <Typography> <DownOutlined className="icon" style={{ fontSize: 15, fontWeight: 'bold', color: '#0082FF', marginRight: '8px' }} /> Move Item Down </Typography>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );
    return (
        <Dropdown overlay={userMenu} placement="bottomRight" trigger={['click']}>
            <MoreOutlined style={{ fontSize: '22px', color: '#0082FF', marginLeft: '3px' }}/>
        </Dropdown>
    );
};

export default MedicareCostMenu;