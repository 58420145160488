import {API} from "aws-amplify";

export const fetchFeedback = async (data) => {
  return await API.get('Chat', `/chat`, {
    queryStringParameters: {
      isFeedback: true,
      include: 'messages,user',
      ...data
    },
  });
};

export const createFeedback = async (data) => {
  return await API.post('Chat', `/chat`, {
    body: {
      is_feedback: true,
      ...data
    }
  });
};

export const sendReply = async (id, description) => {
  return await API.post('Chat', `/chat/${id}/messages`, {
    body: {
      description,
    },
  });
};
