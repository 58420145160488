import React, { useState } from 'react';
import { Modal, Form, Input, Button, Icon } from 'antd';

import { notifyApiError } from 'utils/notification';
import { promiseHandler } from 'utils/promiseHandler';
import * as API from 'utils/api';

const CustomCodeModal = ({ setCustomCode, customCodeModal, setCustomCodeModal, form }) => {
  const { getFieldDecorator, validateFields, resetFields } = form;

  const [loading, setLoading] = useState(false);

  const createCode = e => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);

        const normalizedCode = values.code.trim();
        const normalizedDesc = values.description.trim();

        const customCptCode = {
          code: normalizedCode,
          short_descr: normalizedDesc,
          med_descr: normalizedDesc,
          long_descr: normalizedDesc,
          data_subset: 'ACTIVE',
          search_column: `${normalizedDesc}  ${normalizedCode}`,
        };

        const [res, error] = await promiseHandler(API.addCptCode, customCptCode);

        if (error) {
          setLoading(false);
          return notifyApiError(error);
        }

        setCustomCode(res.cptCode);
        closeModal();
      }
    });
  };

  const closeModal = () => {
    setCustomCodeModal(false);
    setLoading(false);
    resetFields();
  };

  return (
    <Modal
      className="custom-code-modal report-modal"
      title="Add Custom Code"
      onCancel={closeModal}
      visible={customCodeModal}
      footer={null}
      centered>
      <Form onSubmit={createCode} layout="horizontal" colon={false}>
        <Form.Item label="Code" required={false} labelCol={{ span: 5 }} wrapperCol={{ span: 18, offset: 1 }}>
          {getFieldDecorator('code', {
            rules: [{ required: true, message: 'Code cannot be empty' }],
          })(<Input placeholder="Required" name="code" />)}
        </Form.Item>

        <Form.Item label="Description" required={false} labelCol={{ span: 5 }} wrapperCol={{ span: 18, offset: 1 }}>
          {getFieldDecorator('description', {
            rules: [{ required: true, message: 'Description cannot be empty' }],
          })(<Input placeholder="Required" name="description" />)}
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 21 }} style={{ marginBottom: 0 }}>
          <Button disabled={loading} htmlType="submit" type="primary" size="large" className="button green-btn">
            {loading ? <Icon type="loading" /> : 'Add'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'custom-code-form' })(CustomCodeModal);
