import { Col, Modal, Row, Typography } from "antd";
import React from "react";
import "../ReportModal/ReportModal.scss";

const { Text } = Typography;

export default function FilePhotoModal({
  isOpen,
  onCancel,

  renderUploadedImage,
}) {
  // const [loading, setLoading] = useState(true);
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Update File"}
      >
        <Row type="flex" align="middle" className="report-body">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <Col md={24}>
              <Text>Your Uploaded File</Text>
              <img
                src={renderUploadedImage.url}
                style={{ width: "100%" }}
                alt="uploaded-img"
              />
            </Col>
          </Row>
        </Row>
      </Modal>
    </div>
  );
}
