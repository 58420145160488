/* global process  */
import React, { useState, useEffect } from 'react';
import { useAcceptJs } from 'react-acceptjs';

// Components
import { Button, Typography } from 'antd';
import { CardSummary } from './Components/CardSummary';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { PaymentTenant } from './Components/PaymentTenant';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { notifyApiError } from 'utils/notification';

// Hooks
import { useCreditCard } from 'hooks';

// Utils
import { createTenantAndUser } from 'utils/createTenantAndUser';
import * as API from 'utils/api';
import { handleValidExpiration } from 'containers/Reports/utils/helpers';
import { INDIVIDUAL_BASIC_ID, MULTIPLE_BASIC_ID } from '../StepPackageTier/components/PlanCard/planList';

const { Text } = Typography;

const StepCheckout = ({ onSubmit, prevStep, selectedPlan, typePackage, goToStep, publicKey, dataGlobal, aLaCarte }) => {
  const [confirnEdit, setConfirnEdit] = useState(false);
  const [creditCard, setCreditCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listPriceAlaCarte, setListPriceAlaCarte] = useState({});
  const [selectEditStep, setSelectEditStep] = useState(null);

  const { addCreditCardWithUser, createCustomerProfile } = useCreditCard();
  const { dispatchData } = useAcceptJs({
    authData: {
      apiLoginID: '5KhK7a7WA',
      clientKey: publicKey,
    },
    environment: 'PRODUCTION'
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await API.getPricingReviews();
        const data = res?.data?.pricing_reviewers;

        setListPriceAlaCarte({
          5: data[0].price,
          10: data[1].price,
          15: data[2].price,
        });
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const newTenantAndUser = await createTenantAndUser({ formData: { ...dataGlobal, creditCard } });

      const result = await createCustomerProfile({
        firstName: dataGlobal.user.given_name,
        lastName: dataGlobal.user.family_name,
        address: dataGlobal.user.address,
        city: creditCard?.billing_state || '',
        state: creditCard?.billing_city || '',
        zip: creditCard?.billing_zipCode || '',
        number: dataGlobal.user.locale,
        email: dataGlobal.user.email,
        client_id: null,
        tenant_id: newTenantAndUser.data.tenant.id,
      });

      const response = await dispatchData({
        cardData: {
          cardNumber: creditCard.cardNumber,
          month: creditCard.expirationDate.substring(0, 2),
          year: creditCard.expirationDate.slice(-2),
          cardCode: creditCard.cvv,
        },
      });

      if (!response?.opaqueData?.dataValue) return notifyApiError('The card details are incorrect');

      const resAddCard = await addCreditCardWithUser({
        token: response.opaqueData.dataValue,
        profileCustomerId: result.data.customer,
        user_id: newTenantAndUser.data.user.id,
        tenant_id: newTenantAndUser.data.tenant.id,
        selectDefault: true,
      });

      await API.paySubcriptionWithCreditCard({
        customer_profile_id: result.data.customer,
        customer_payment_profile_id: resAddCard.data.customer_payment_profile_id,
        package_id: dataGlobal.selected_plan,
        customer: newTenantAndUser.data.user.id,
      });

      onSubmit({ sectionStep: 'creditCard', payload: creditCard });
    } catch (e) {
      console.error(e);
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirnEdit = ({ step }) => {
    setSelectEditStep(step);
    setConfirnEdit(true);
  };

  const gotToEdit = () => {
    goToStep({ step: selectEditStep });
  };

  const handleChange = ({ field, payload }) => {
    setCreditCard({
      ...creditCard,
      [field]: payload,
    });
  };

  const validPay =
    creditCard?.name &&
    creditCard?.cardNumber &&
    creditCard?.cvv &&
    creditCard?.expirationDate &&
    creditCard?.zipCode &&
    creditCard?.billing_city &&
    creditCard?.billing_state &&
    creditCard?.billing_zipCode &&
    handleValidExpiration({ value: creditCard?.expirationDate });

  return (
    <div className="stepContainer">
      <div className="stepCheckoutAddTenant">
        <CardSummary
          selectedPlan={selectedPlan}
          typePackage={typePackage}
          onConfirnEdit={handleConfirnEdit}
          aLaCarte={aLaCarte}
          listPriceAlaCarte={listPriceAlaCarte}
        />
        <PaymentTenant creditCard={creditCard} onChange={handleChange} />
      </div>
      <div className="stepCheckoutAddTenant_btn">
        <Button type="primary" size="default" className="transparent-btn button checkoutTenant_prev" onClick={prevStep}>
          PREVIOUS STEP
        </Button>
        <Button
          type="primary"
          size="default"
          className="stepNextButton checkoutTenant_pay"
          disabled={!validPay}
          onClick={handleSubmit}>
          Pay
        </Button>
        {[INDIVIDUAL_BASIC_ID, MULTIPLE_BASIC_ID].includes(selectedPlan) && (
          <Text style={{ gridColumn: '2/3' }}>
            Your subscription will auto-renew and you'll be charged if the 7-day free trial is not cancelled before it
            ends.
            <br />
            <br />
            If you cancel the 7-day free trial, you will not be charged. However, if reports are generated during the
            trial, charges may apply.
          </Text>
        )}
      </div>

      {confirnEdit && (
        <ModalConfirm
          title={`This will take you back to Step ${selectEditStep.number}`}
          text="Are you sure you want to continue?"
          onClose={() => setConfirnEdit(false)}
          btnCancel="Cancel"
          onConfirm={gotToEdit}
        />
      )}

      {loading && <ModalLoading />}
    </div>
  );
};

export default StepCheckout;
