import text from './RichText';
import email from './Text';
import number from './Text';
import color from './Text';
import checkbox from './CheckBox';
import file from './FileComponent';
import select from './Select';
import date from './DateComponent';
import textarea from './RichText';

export default {
  text,
  email,
  checkbox,
  file,
  select,
  number,
  date,
  color,
  textarea,
};
