export const LIST_ALL_STATES_USA = [
  {
    name: 'Alabama',
    contraction: 'AL',
  },
  {
    name: 'Alaska',
    contraction: 'AK',
  },
  {
    name: 'Arizona',
    contraction: 'AZ',
  },
  {
    name: 'Arkansas',
    contraction: 'AR',
  },
  {
    name: 'California',
    contraction: 'CA',
  },
  {
    name: 'Colorado',
    contraction: 'CO',
  },
  {
    name: 'Connecticut',
    contraction: 'CT',
  },
  {
    name: 'Delaware',
    contraction: 'DE',
  },
  {
    name: 'Florida',
    contraction: 'FL',
  },
  {
    name: 'Georgia',
    contraction: 'GA',
  },
  {
    name: 'Hawai',
    contraction: 'HI',
  },
  {
    name: 'Idaho',
    contraction: 'ID',
  },
  {
    name: 'Illinois',
    contraction: 'IL',
  },
  {
    name: 'Indiana',
    contraction: 'IN',
  },
  {
    name: 'Iowa',
    contraction: 'IA',
  },
  {
    name: 'Kansas',
    contraction: 'KS',
  },
  {
    name: 'Kentucky',
    contraction: 'KY',
  },
  {
    name: 'Louisiana',
    contraction: 'LA',
  },
  {
    name: 'Maine',
    contraction: 'ME',
  },
  {
    name: 'Maryland',
    contraction: 'MD',
  },
  {
    name: 'Massachusetts',
    contraction: 'MA',
  },
  {
    name: 'Michigan',
    contraction: 'MI',
  },
  {
    name: 'Minnesota',
    contraction: 'MN',
  },
  {
    name: 'Mississippi',
    contraction: 'MS',
  },
  {
    name: 'Missouri',
    contraction: 'MO',
  },
  {
    name: 'Montana',
    contraction: 'MT',
  },
  {
    name: 'Nebraska',
    contraction: 'NE',
  },
  {
    name: 'Nevada',
    contraction: 'NV',
  },
  {
    name: 'New Hampshire',
    contraction: 'NH',
  },
  {
    name: 'New Jersey',
    contraction: 'NJ',
  },
  {
    name: 'New Mexico',
    contraction: 'NM',
  },
  {
    name: 'New York',
    contraction: 'NY',
  },
  {
    name: 'North Carolina',
    contraction: 'NC',
  },
  {
    name: 'North Dakota',
    contraction: 'ND',
  },
  {
    name: 'Ohio',
    contraction: 'OH',
  },
  {
    name: 'Oklahoma',
    contraction: 'OK',
  },
  {
    name: 'Oregon',
    contraction: 'OR',
  },
  {
    name: 'Pennsylvania',
    contraction: 'PA',
  },
  {
    name: 'Rhode Island',
    contraction: 'RI',
  },
  {
    name: 'South Carolina',
    contraction: 'SC',
  },
  {
    name: 'South Dakota',
    contraction: 'SD',
  },
  {
    name: 'Tennessee',
    contraction: 'TN',
  },
  {
    name: 'Texas',
    contraction: 'TX',
  },
  {
    name: 'Utah',
    contraction: 'UT',
  },
  {
    name: 'Vermont',
    contraction: 'VT',
  },
  {
    name: 'Virginia',
    contraction: 'VA',
  },
  {
    name: 'Washington',
    contraction: 'WA',
  },
  {
    name: 'West Virginia',
    contraction: 'WV',
  },
  {
    name: 'Wisconsin',
    contraction: 'WI',
  },
  {
    name: 'Wyoming',
    contraction: 'WY',
  },
];
