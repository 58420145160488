import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Typography, Button } from 'antd';
import AuthSideBar from '../../../components/AuthSideBar';

// Components
import { BarStepSignUp } from 'components/BarStepSignUp';
import { StepAccountInfo } from './components/StepAccountInfo';
import { StepTypeOfPackage } from './components/StepTypeOfPackage';
import { StepPackageTier } from './components/StepPackageTier';
import { StepAlaCarta } from './components/StepAlaCarta';
import { StepCheckout } from './components/StepCheckout';
import { ModalMessage } from 'components/Modal/ModalMessage';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { ModalCustomPackage } from 'components/Modal/ModalCustomPackage';

// Hooks
import { useCreditCard } from 'hooks';

// Const
import { MULTIPLE_CUSTOM_ID } from './components/StepPackageTier/components/PlanCard/planList';
import { TYPE_OF_PACKAGE_OPTIONS } from './components/StepTypeOfPackage/StepTypeOfPackage';

// Utils
import { createTenantAndUser } from 'utils/createTenantAndUser';

// Style
import './tenantCreate.scss';
import { SECONDARY_GREY } from 'containers/Calendar/src/src/lib/utils/colors';

const { Title, Text } = Typography;

export const STEP_ACCOUNT_INFO = { number: 1, name: 'Account Info' };
export const STEP_TYPE_OF_PACKAGE = { number: 2, name: 'Type of Package' };
export const STEP_PACKAGE_TIER = { number: 3, name: 'Package Tier' };
export const STEP_A_LA_CARTA = { number: 4, name: 'A la Carte' };
export const STEP_CHECKOUT = { number: 5, name: 'Checkout' };

const LIST_STEPS = [STEP_ACCOUNT_INFO, STEP_TYPE_OF_PACKAGE, STEP_PACKAGE_TIER, STEP_A_LA_CARTA, STEP_CHECKOUT];

const TenantCreate = () => {
  const [step, setStep] = useState(STEP_ACCOUNT_INFO);
  const [dataGlobal, setDataGlobal] = useState({});
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessCustom, setModalSuccessCustom] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { publicKey, loadingCreditCard, getPublicKey } = useCreditCard();

  const handleChangeStep = ({ step }) => {
    setStep(step);
  };

  const handleSaveData = async ({ sectionStep, payload, nextStep }) => {
    try {
      if (
        nextStep === STEP_A_LA_CARTA.number &&
        payload === MULTIPLE_CUSTOM_ID &&
        dataGlobal.type_package === TYPE_OF_PACKAGE_OPTIONS.MULTIPLE_USERS
      ) {
        // si entramos aqui es porque el plan elegido en custom de multi users
        // creamos el tenant y el usuario para mostar un modal de success y lo mandamos al login

        setLoading(true);

        const response = await createTenantAndUser({
          formData: {
            ...dataGlobal,
            selected_plan: MULTIPLE_CUSTOM_ID,
          },
        });

        if (!response.data.success) {
          notifyApiError('Internal server error');
        }

        setLoading(false);
        return setModalSuccessCustom(true);
      }

      const stepUpdate = LIST_STEPS.find(step => step.number === nextStep);

      if (stepUpdate && stepUpdate.number === STEP_CHECKOUT.number) await getPublicKey();

      if (sectionStep)
        setDataGlobal({
          ...dataGlobal,
          [sectionStep]: payload,
        });

      if (nextStep && Number(nextStep)) return setStep(stepUpdate);

      setModalSuccess(true);
    } catch (error) {
      setLoading(false);
      notifyApiError(error);
    }
  };

  return (
    <Row type="flex" className="login-container">
      <Col xs={0} xl={8}>
        <AuthSideBar />
      </Col>
      <div className="addTenant_content">
        <div className="addTenant_maxContent">
          <div className="loginClient_content_title">
            <Title level={1} className="loginClient_title">
              Sign Up
            </Title>
            <Text>
              Already have an account?
              <Button type="link" href="/login">
                Sign In
              </Button>
            </Text>
          </div>

          <BarStepSignUp step={step} listSteps={LIST_STEPS} handleChangeStep={handleChangeStep} />

          {step.number === STEP_ACCOUNT_INFO.number && (
            <StepAccountInfo data={dataGlobal?.user || {}} onSubmit={handleSaveData} />
          )}
          {step.number === STEP_TYPE_OF_PACKAGE.number && (
            <StepTypeOfPackage
              data={dataGlobal?.type_package || ''}
              onSubmit={handleSaveData}
              prevStep={() => setStep(STEP_ACCOUNT_INFO)}
            />
          )}
          {step.number === STEP_PACKAGE_TIER.number && (
            <StepPackageTier
              data={dataGlobal?.selected_plan || ''}
              onSubmit={handleSaveData}
              typePackage={dataGlobal.type_package}
            />
          )}
          {step.number === STEP_A_LA_CARTA.number && (
            <StepAlaCarta
              data={dataGlobal?.a_la_carte || ''}
              onSubmit={handleSaveData}
              prevStep={() => setStep(STEP_PACKAGE_TIER)}
            />
          )}

          {step.number === STEP_CHECKOUT.number && (
            <StepCheckout
              dataGlobal={dataGlobal}
              typePackage={dataGlobal.type_package}
              selectedPlan={dataGlobal?.selected_plan || {}}
              aLaCarte={dataGlobal?.a_la_carte || {}}
              onSubmit={handleSaveData}
              prevStep={() => setStep(STEP_A_LA_CARTA)}
              goToStep={({ step }) => setStep(step)}
              publicKey={publicKey}
            />
          )}
        </div>
      </div>

      {modalSuccess && (
        <ModalMessage
          onClose={() => setModalSuccess(false)}
          title="Thank you for registering with us!"
          message={
            <>
              You will soon receive an email with a unique link to sign into the Wrkfloz platform. Complete registration within 24hours before the link expires 
              <br />
              <br />
              <span style={{ color: SECONDARY_GREY }}>Please check your spam folder if you can't find it in your inbox</span>
            </>
          }
          textButton="Ok"
          noneIcon
          onConfirm={() => history.push('/')}
        />
      )}

      {modalSuccessCustom && <ModalCustomPackage />}

      {loading || (loadingCreditCard && <ModalLoading />)}
    </Row>
  );
};

export default TenantCreate;
