import React from 'react';
import { Typography } from 'antd';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DownIcon, ElipsisIcon, UpIcon } from 'components/Svg';
import { PRIMARY_BORDER_GREY } from 'containers/Calendar/src/src/lib/utils/colors';

const { Title } = Typography;

const TemplateLifeCarePlanDragExisting = ({
  handleToggleSectionExisting,
  droppableExisting,
  toggleSectionExisting,
  templateCreated,
}) => {
  return (
    <>
      <Title level={4} className="title-text lifeCarePlanTemplate_click" onClick={handleToggleSectionExisting}>
        Existing Templates {toggleSectionExisting ? <DownIcon color="black" /> : <UpIcon color="black" />}
      </Title>

      <Droppable droppableId={droppableExisting} type="FIELD" direction="vertical" isDropDisabled={true}>
        {provided => (
          <div
            className={`lifeCarePlanTemplate_layout_items ${
              toggleSectionExisting ? 'lifeCarePlanTemplate_layout_items_unshow' : ''
            }`}
            {...provided.droppableProps}
            ref={provided.innerRef}>
            {templateCreated.map((item, index) => (
              <Draggable key={item.report.id} draggableId={`${item.report.id}s`} index={index}>
                {provided => (
                  <div
                    className="lifeCarePlanTemplate_layout_items_item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <ElipsisIcon color={PRIMARY_BORDER_GREY} />
                    {item.report.report_name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default TemplateLifeCarePlanDragExisting;
