import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { Col, Row, Typography, Button } from 'antd';
import DropDown from 'components/Dropdown';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import { sortArrayObjectForKey } from 'utils/utils';
import { notifyApiError } from 'utils/notification';
import { STATUS_REDUCTION_COMPLETED } from 'constants/report_status';
import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import './reportPreView.scss';

const { Title } = Typography;

const PreviewReport = () => {
  let { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const claimantInfo = location.state.data.claimantInfo;

  const [loading, setLoading] = useState(true);
  const [listState, setListState] = useState([]);
  const [related_diagnosis, setRelated_diagnosis] = useState([]);

  const [step, setStep] = useState(1);
  const [reportInfo, setReportInfo] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const report = await API.getReportById(id, true);
        const getReportView = await API.getViewReport(report.id);
        const states = await API.getJuridictionStateList();
        const orderState = sortArrayObjectForKey({ array: states.juridictionStates, key: 'name' });

        let reportInfos;

        if (getReportView && getReportView?.id) {
          reportInfos = { ...getReportView, claimant: report.claimant };
        } else {
          reportInfos = report;
        }

        if (reportInfos.related_diagnosis) {
          const getRelatedDiagnosis = reportInfos.related_diagnosis.split(',');
          setRelated_diagnosis(getRelatedDiagnosis);
        }

        setReportInfo(reportInfos);

        setListState(orderState);
      } catch (err) {
        console.error({ err });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const params = {
        id,
        isAttorney: true,
        report_status_id: STATUS_REDUCTION_COMPLETED.id,
        juridiction_state_id: reportInfo.juridiction_state_id,
      };

      await API.updateReport(params);

      await Promise.all([
        API.setUpMsaReport(params.id),
        API.setUpReductionReport(params.id),
        API.setUpLetterReport(params.id),
      ]);

      const file = await API.getReportFilesReductionProcess({ reportId: params.id });

      if (file) {
        Modal.success({
          content: 'Report is generated',
          onOk: () => {
            window.open(file, '_blank');
          },
        });
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="msa-demographic-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Medicare Set-Aside Letter
          </Title>
        </Col>
        <Col className="step">
          <DropDown loading={loading} disabled={true} goBack={() => history.goBack()} />
        </Col>
      </Row>
      <Row className="main">
        <SpinBox loading={loading}>
          <div className="content reportPreView">
            {step === 1 && (
              <Step1
                listState={listState}
                claimantInfo={claimantInfo}
                data={{
                  today: reportInfo.today,
                  juridiction_state_id: reportInfo.juridiction_state_id,
                  clientsAttorneyName: reportInfo.clientsAttorneyName,
                  fileNo: reportInfo.fileNo,
                  submitter_attorney_name: reportInfo.submitter_attorney_name,
                  date_of_injury: reportInfo.date_of_injury,
                  related_diagnosis,
                }}
              />
            )}

            {step === 2 && (
              <Step2
                data={{
                  clientsAttorneyName: reportInfo.clientsAttorneyName,
                  dob: reportInfo.claimant.dob,
                  date_of_injury: reportInfo.date_of_injury,
                  SSN: reportInfo.claimant.SSN,
                  submitter_attorney_name: reportInfo.submitter_attorney_name,
                  submitter_attorney_firm_name: reportInfo.submitter_attorney_firm_name,
                  submitter_attorney_phone: reportInfo.submitter_attorney_phone,
                  submitter_attorney_email: reportInfo.submitter_attorney_email,
                  settlement_amount: reportInfo.settlement_amount,
                  present_value_case: reportInfo.present_value_case,
                  actual_settlement_amount: reportInfo.actual_settlement_amount,
                  total_attorney_fees: reportInfo.total_attorney_fees,
                }}
              />
            )}

            {step === 1 && (
              <div className="reportPreView_next">
                <Button className="button_simple button" onClick={() => setStep(2)}>
                  NEXT PAGE
                </Button>
              </div>
            )}

            {step === 2 && (
              <div className="reportPreView_prev">
                <Button className="button_simple button" onClick={() => setStep(1)}>
                  PREVIOUS PAGE
                </Button>
                <Button type="primary" size="large" onClick={() => history.goBack()} className="purple-btn btn-normal">
                  Edit
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="btn-normal green-btn"
                  disabled={false}
                  onClick={() => handleSubmit()}>
                  Complete Report
                </Button>
              </div>
            )}
          </div>
        </SpinBox>
      </Row>
    </Row>
  );
};

export default PreviewReport;
