import React from 'react';

// Components
import { Modal } from 'antd';

// Style
import './modalVideo.scss';

const ModalVideo = ({ video, close }) => {
  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      className='modalPdf'>
      <div
        className="modalVideo"
        dangerouslySetInnerHTML={{
          __html: video,
        }}></div>
    </Modal>
  );
};

export default ModalVideo;
