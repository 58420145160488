import { API } from 'aws-amplify';

export const fetchNotifications = async query => {
  return await API.get('Chat', `/notifications`, {
    queryStringParameters: {
      ...query,
    },
  });
};

export const fetchNotificationsClaimant = async ({ idClaimant, offset, limit }) => {
  return await API.get('Chat', `/notifications/claimant/${idClaimant}?limit=${limit}&offset=${offset}`);
};

export const markNotificationsRead = async query => {
  return await API.put('Chat', `/notifications/view-all`, {});
};
