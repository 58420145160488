import { Select } from "antd";
import React, { useEffect, useState } from "react";
import * as API from "utils/api";
import "./AssigmentFile.scss";

const { Option } = Select;

export default function Index({
  OnRoleChange,
  assignment_id,
  claimant_id,
}) {
  const [files, setFiles] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [assignmentFiles, setAssignmentFiles] = useState([]);
  useEffect(() => {
    API.GetReportFiles(claimant_id).then((files) => {
      const filesIds = files.files.map(item => item.id);
      API.getAssigmentFiles(assignment_id).then((res) => {
        setAssignmentFiles(res.assignment_files);
        setSelectedValues(res.assignment_files.map((el) => el.file_id));
        const filtered = res.assignment_files
          .map(item => item.file)
          .filter(item => !filesIds.includes(item.id))
        setFiles([...files.files, ...filtered]);
      });
    });
  }, [assignment_id, claimant_id]);
  const children = [];
  files.map((item, i) => {
    children.push(
      <Option key={i.toString(36) + i} value={item.id}>
        {item.name}
      </Option>
  )})

  return (
    <div>
      <Select
        mode="multiple"
        placeholder="Please select"
        onChange={OnRoleChange}
        value={selectedValues}
        onSelect={(value) => {
          API.CreateAssignementFile(value, assignment_id);
          setSelectedValues([...selectedValues, value]);
        }}
        onDeselect={(value) => {
          let assgFile = assignmentFiles.find((item) => item.file_id === value);
          API.DeleteAssigmentFile(assgFile.id, assignment_id);
          setSelectedValues([
            ...selectedValues.filter((item) => item !== value),
          ]);
        }}
        style={{ width: "100%" }}
      >
        {children}
      </Select>
    </div>
  );
}
