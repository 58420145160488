import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Icon, Row, Col, Typography, Modal } from 'antd';
import { getUserInfo, logout } from '../../redux/auth/actions';
import { notifyInfo, notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import {
  DashboardIcon,
  TemplatesIcon,
  ClientsIcon,
  UserIcon,
  ContactsIcon,
  LogoutIcon,
  HideIcon,
  ShowIcon,
  LibraryResIcon,
  ReviewIcon,
  SettingsIcon,
  ChatIcon,
  HelpIcon,
  SyringeIcon,
  CalendarIcon,
  NotificationIcon,
  TemplateIcon,
  // ReportQueueIcon,
  MarketingIcon,
  LitigationAttorney,
} from '../../components/Icons';
import isAllowed, {
  ROLES_DIVISIONS,
  getClientId,
  DISPLAY_ROLE_SUPER_ADMIN,
  DISPLAY_ROLE_MEDICAL_REVIEWER,
  DISPLAY_ROLE_NURSE_WRITER,
  DISPLAY_ROLE_INTERNAL_QA,
  DISPLAY_ROLE_EXTERNAL_QA,
  DISPLAY_ROLE_CLIENT,
  DISPLAY_ROLE_EXTERNAL_NURSE,
  DISPLAY_ROLE_ATTORNEY_MANAGER,
  DISPLAY_ROLE_EXTERNAL_AR,
  DISPLAY_ROLE_INTERNAL_ATTORNEY,
  DISPLAY_ROLE_EXTERNAL_LITIGATION_ATTORNEY,
  DISPLAY_ROLE_CLIENT_ADMIN,
  DISPLAY_ROLE_CLIENT_SUPER_ADMIN,
  DISPLAY_ROLE_SALES_MANAGER,
  DISPLAY_ROLE_SALES_REP,
  DISPLAY_ROLE_MSA_MEDICAL_REVIEWER,
  ROLE_SALES_REP,
  ROLE_SUPERADMIN,
  ROLE_DIRECTOR_OF_SALES,
  ROLE_MEDICAL_REVIEWER,
  ROLE_NURSE_WRITER,
  EXTERNAL_NURSE,
  ROLE_QA_NURSE,
  ROLE_EXTERNAL_QA,
  ROLE_CLIENT_ADMIN,
  ROLE_CLIENT,
  ATTORNEY_MANAGER,
  ROLE_EXTERNAL_AR,
  INTERNAL_ATTORNEY,
  ROLE_EXTERNAL_LITIGATION_ATTORNEY,
  ROLE_SALES_MANAGER,
  ROLE_MEDICAL_ADMIN,
  DISPLAY_ROLE_MEDICAL_ADMIN,
  ROLE_CLIENT_SUPER_ADMIN,
  DISPLAY_ROLE_DIRECTOR_OF_SALES,
  ROLE_MSA_MEDICAL_REVIEWER,
} from '../../layout/roles';
import { Notifications } from 'msa-components';
import './Navigation.scss';
import ReportCabinetModal from 'components/ReportCabinet';
// import ReportQueueModal from 'components/ReportQueue';
import { getNotifications, markAsRead } from '../../redux/notification';
import { openChatDrawer } from '../../redux/Drawer/DrawerActions';
import {
  MARKETING_NAV,
  HELP_NAV,
  SUPPORT_NAV,
  SETTINGS_NAV,
  ATTORNEY_CLAIMANT_NAV,
  ATTORNEY_CLAIMANT_REPORT_NAV,
  ATTORNEY_IN_REVIEW_NAV,
  CLAIMANTS_BY_CLIENT_NAV,
  TEMPLATE_NAV,
  MAIN_NAV,
  SEARCH_NAV,
  PARENT_FOLDER_NAV,
  CLIENTS_NAV,
  ASSIGNMENTBOARD_NAV,
  CLAIMANT_BY_USER_NAV,
  LITIGATION_ATTORNEY_NAV,
} from 'layout/navigate_const';
import AddAssignForm from 'containers/Calendar/src/src/containers/base/AddAssignForm';
import { OPTIONS_DROPDOWN_FILTER_NOTIFICATION } from 'constants/filterNotification';
import { NOTIFICATIONS_GET_LIST_FILTER } from 'redux/notification/actionTypes';
import { arrayToObject } from 'utils/utils';
import { ASSIGNMENT_TYPE_ATTORNEY } from 'constants/assignment-types';
import { SPECIALTY_IDS_OBJECT } from 'constants/specialty';
import {
  SIDEBAR_PERMISSIONS_NAME,
  PARENT_FOLDER_NAME,
  REDIRECT_DASHBOARD_NAME,
  getPermissionsBySection,
} from 'permissions';

// Components
import { ModalLoading } from 'components/Modal/ModalLoading';

// Services
import { onActionNotifications } from 'services/Notifications.service';
import { INDIVIDUAL_BASIC_ID } from 'containers/Tenant/tenantCreate/components/StepPackageTier/components/PlanCard/planList';

const { Sider } = Layout;
const { Text } = Typography;

class Navigation extends Component {
  constructor(props) {
    super();

    // Permissions
    const { SIDEBAR_PERMISSIONS, PARENT_FOLDER_PERMISSIONS, REDIRECT_DASHBOARD_PERMISSIONS } = getPermissionsBySection({
      roles: props.roles,
      permissions: [SIDEBAR_PERMISSIONS_NAME, PARENT_FOLDER_NAME, REDIRECT_DASHBOARD_NAME],
    });
    // end permissions

    this.state = {
      collapsed: false,
      selectedKey: isAllowed(props.roles, [...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.EXTERNAL_ONLY])
        ? 'claimants'
        : MAIN_NAV.key,
      openReportCabinetModal: false,
      // openReportQueueModal: false,
      notificationsOpened: false,
      openAssigToAttorney: false,
      reportTypes: [],
      loading: false,
      SIDEBAR_PERMISSIONS,
      PARENT_FOLDER_PERMISSIONS,
      REDIRECT_DASHBOARD_PERMISSIONS,
    };

    this.handleSelectedKey = this.handleSelectedKey.bind(this);
    this.onCollapse = this.onCollapse.bind(this);
    this.selectClaimantPath = this.selectClaimantPath.bind(this);
    this.onSelectMenu = this.onSelectMenu.bind(this);
    this.displayroles = this.displayroles.bind(this);
    this.onCloseNotifications = this.onCloseNotifications.bind(this);
    this.fetchMoreNotifications = this.fetchMoreNotifications.bind(this);
    this.handleSwitchAssigToAttorney = this.handleSwitchAssigToAttorney.bind(this);
    this.hanldeSearchNotifications = this.hanldeSearchNotifications.bind(this);
    this.handleAssigToAttorney = this.handleAssigToAttorney.bind(this);
    this.actionNotifications = this.actionNotifications.bind(this);
    this.redirectDashboard = this.redirectDashboard.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.location.pathname.includes('main')) {
        if (this.state.REDIRECT_DASHBOARD_PERMISSIONS.parentFolder) this.props.history.push(PARENT_FOLDER_NAV.path);

        if (this.state.REDIRECT_DASHBOARD_PERMISSIONS.myClients) this.props.history.push(CLIENTS_NAV.path);

        if (this.state.REDIRECT_DASHBOARD_PERMISSIONS.assignmentBoard)
          this.props.history.push(ASSIGNMENTBOARD_NAV.path);

        if (this.state.REDIRECT_DASHBOARD_PERMISSIONS.claimantsByClient)
          this.props.history.push(CLAIMANTS_BY_CLIENT_NAV({ id: getClientId(this.props.roles) }).path);
      }

      if (this.state.REDIRECT_DASHBOARD_PERMISSIONS.claimantsByUser) {
        this.props.history.push(CLAIMANT_BY_USER_NAV({ id: this.props.roles.user_roles.id }).path);
      }
    }, 10);

    this.handleSelectedKey();

    API.getReportTypes().then(res => {
      const reports = res.reportTypes.map(reportType => reportType.name);
      this.setState({ reportTypes: reports });
    });
    this.props.getUserInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleSelectedKey();
    }
  }

  redirectDashboard() {}

  handleSelectedKey() {
    if (this.props.location.pathname.includes('/main')) {
      this.setState({
        selectedKey: MAIN_NAV.key,
      });
    }
    if (this.props.location.pathname.includes(ASSIGNMENTBOARD_NAV.path)) {
      this.setState({
        selectedKey: ASSIGNMENTBOARD_NAV.key,
      });
    }

    if (this.props.location.pathname.includes(LITIGATION_ATTORNEY_NAV.path))
      this.setState({ selectedKey: LITIGATION_ATTORNEY_NAV.key });

    if (
      this.props.location.pathname.includes(CLIENTS_NAV.path) ||
      this.props.location.pathname.includes(PARENT_FOLDER_NAV.path)
    ) {
      this.setState({
        selectedKey: CLIENTS_NAV.key,
      });
    }
    if (this.props.location.pathname.includes(CLAIMANTS_BY_CLIENT_NAV().basePath)) {
      this.setState({
        selectedKey: CLAIMANTS_BY_CLIENT_NAV().key,
      });
    }
    if (this.props.location.pathname.includes('/users')) {
      this.setState({
        selectedKey: 'users',
      });
    }
    if (this.props.location.pathname.includes('/users-client')) {
      this.setState({
        selectedKey: 'users-client',
      });
    }
    if (this.props.location.pathname.includes(TEMPLATE_NAV.path)) {
      this.setState({
        selectedKey: TEMPLATE_NAV.key,
      });
    }
    if (this.props.location.pathname.includes(ATTORNEY_CLAIMANT_NAV.path)) {
      this.setState({
        selectedKey: ATTORNEY_CLAIMANT_NAV.key,
      });
    }
    if (this.props.location.pathname.includes(ATTORNEY_CLAIMANT_REPORT_NAV.path)) {
      this.setState({
        selectedKey: ATTORNEY_CLAIMANT_REPORT_NAV.key,
      });
    }
    if (this.props.location.pathname.includes('/contacts')) {
      this.setState({
        selectedKey: 'contacts',
      });
    }
    if (this.props.location.pathname.includes('/ResourceLibrary')) {
      this.setState({
        selectedKey: 'ResourceLibrary',
      });
    }
    if (this.props.location.pathname.includes(SETTINGS_NAV.path)) {
      this.setState({
        selectedKey: SETTINGS_NAV.key,
      });
    }

    if (this.props.location.pathname.includes('/Reviews')) {
      this.setState({
        selectedKey: 'Reviews',
      });
    }

    if (this.props.location.pathname.includes(ATTORNEY_IN_REVIEW_NAV.path)) {
      this.setState({
        selectedKey: ATTORNEY_IN_REVIEW_NAV.key,
      });
    }
    if (this.props.location.pathname.includes('/app/pricing/groups')) {
      this.setState({
        selectedKey: 'pricing',
      });
    }
    if (this.props.location.pathname.includes('/app/pricing/')) {
      this.setState({
        selectedKey: 'pricing',
      });
    }
    if (this.props.location.pathname.includes(SEARCH_NAV.path)) {
      this.setState({
        selectedKey: SEARCH_NAV.key,
      });
    }

    if (this.props.location.pathname.includes(MARKETING_NAV.path)) {
      this.setState({
        selectedKey: MARKETING_NAV.key,
      });
    }

    if (this.props.location.pathname.includes(HELP_NAV.path)) {
      this.setState({
        selectedKey: HELP_NAV.key,
      });
    }

    if (this.props.location.pathname.includes(SUPPORT_NAV.path)) {
      this.setState({
        selectedKey: SUPPORT_NAV.key,
      });
    }

    if (this.props.location.pathname.includes(CLAIMANT_BY_USER_NAV().basePath)) {
      this.setState({
        selectedKey: CLAIMANT_BY_USER_NAV().key,
      });
    }
  }

  onCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  selectClaimantPath() {
    if (isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT)) {
      this.setState({ selectedKey: 'claimants' }, () => {
        this.props.history.push(`${CLAIMANTS_BY_CLIENT_NAV({ id: getClientId(this.props.roles) }).path}`);
      });
    } else if (isAllowed(this.props.roles, ROLES_DIVISIONS.EXTERNAL_ONLY)) {
      this.setState({ selectedKey: 'claimants' }, () => {
        this.props.history.push('/app/claimants/' + this.props.roles.user_roles.id);
      });
    }
  }

  onSelectMenu(item) {
    const isReviewerActive = this.props.isActiveReviewer;

    if (item.key !== 'chat' && isReviewerActive) {
      this.setState({
        selectedKey: item.key,
      });
    }

    switch (item.key) {
      case MAIN_NAV.key:
        this.props.history.push(MAIN_NAV.path);
        return;
      case TEMPLATE_NAV.key:
        this.props.history.push(TEMPLATE_NAV.path);
        return;
      case CLIENTS_NAV.key:
        if (this.state.PARENT_FOLDER_PERMISSIONS.open) return this.props.history.push(PARENT_FOLDER_NAV.path);
        return this.props.history.push(CLIENTS_NAV.path);
      case 'users':
        this.props.history.push('/app/users');
        return;
      case 'users-client':
        this.props.history.push('/app/users-client');
        return;
      case 'contacts':
        this.props.history.push('/app/contacts');
        return;
      case 'uploads':
        this.props.history.push('/app/uploadCode');
        return;
      case 'ResourceLibrary':
        this.props.history.push('/app/ResourceLibrary');
        return;
      case SETTINGS_NAV.key:
        this.props.history.push(SETTINGS_NAV.path);
        return;
      case ASSIGNMENTBOARD_NAV.key:
        this.props.history.push(ASSIGNMENTBOARD_NAV.path);
        return;
      case LITIGATION_ATTORNEY_NAV.key:
        this.props.history.push(LITIGATION_ATTORNEY_NAV.path);
        return;
      case 'claimants':
        this.selectClaimantPath();
        return;
      case 'Reviews':
        this.props.history.push(
          isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? ATTORNEY_IN_REVIEW_NAV.path : '/app/Reviews'
        );
        return;
      case 'chat':
        this.props.openChatDrawer();
        return;
      case 'notifications':
        isReviewerActive && this.setState({ notificationsOpened: true });
        return;
      case 'gabinet':
        isReviewerActive && this.setState({ openReportCabinetModal: true });
        return;
      case 'pricing':
        this.props.history.push('/app/pricing/groups');
        return;
      case SEARCH_NAV.key:
        this.props.history.push(SEARCH_NAV.path);
        return;
      case HELP_NAV.key:
        this.props.history.push(HELP_NAV.path);
        return;
      case SUPPORT_NAV.key:
        this.props.history.push(SUPPORT_NAV.path);
        return;
      case MARKETING_NAV.key:
        this.props.history.push(MARKETING_NAV.path);
        return;
      default:
        return;
    }
  }

  displayroles(roles) {
    const roles_name = roles.map(role => {
      return role.name;
    });

    if (roles_name.includes(ROLE_SUPERADMIN)) {
      return DISPLAY_ROLE_SUPER_ADMIN;
    } else if (roles_name.includes(ROLE_MEDICAL_REVIEWER)) {
      return DISPLAY_ROLE_MEDICAL_REVIEWER;
    } else if (roles_name.includes(ROLE_NURSE_WRITER)) {
      return DISPLAY_ROLE_NURSE_WRITER;
    } else if (roles_name.includes(EXTERNAL_NURSE)) {
      return DISPLAY_ROLE_EXTERNAL_NURSE;
    } else if (roles_name.includes(ROLE_QA_NURSE)) {
      return DISPLAY_ROLE_INTERNAL_QA;
    } else if (roles_name.includes(ROLE_CLIENT_ADMIN)) {
      return DISPLAY_ROLE_CLIENT_ADMIN;
    } else if (roles_name.includes(ROLE_CLIENT)) {
      return DISPLAY_ROLE_CLIENT;
    } else if (roles_name.includes(ATTORNEY_MANAGER)) {
      return DISPLAY_ROLE_ATTORNEY_MANAGER;
    } else if (roles_name.includes(INTERNAL_ATTORNEY)) {
      return DISPLAY_ROLE_INTERNAL_ATTORNEY;
    } else if (roles_name.includes(ROLE_EXTERNAL_LITIGATION_ATTORNEY)) {
      return DISPLAY_ROLE_EXTERNAL_LITIGATION_ATTORNEY;
    } else if (roles_name.includes(ROLE_SALES_MANAGER)) {
      return DISPLAY_ROLE_SALES_MANAGER;
    } else if (roles_name.includes(ROLE_SALES_REP)) {
      return DISPLAY_ROLE_SALES_REP;
    } else if (roles_name.includes(ROLE_MEDICAL_ADMIN)) {
      return DISPLAY_ROLE_MEDICAL_ADMIN;
    } else if (roles_name.includes(ROLE_CLIENT_SUPER_ADMIN)) {
      return DISPLAY_ROLE_CLIENT_SUPER_ADMIN;
    } else if (roles_name.includes(ROLE_DIRECTOR_OF_SALES)) {
      return DISPLAY_ROLE_DIRECTOR_OF_SALES;
    } else if (roles_name.includes(ROLE_EXTERNAL_QA)) {
      return DISPLAY_ROLE_EXTERNAL_QA;
    } else if (roles_name.includes(ROLE_EXTERNAL_AR)) {
      return DISPLAY_ROLE_EXTERNAL_AR;
    } else if (roles_name.includes(ROLE_MSA_MEDICAL_REVIEWER)) {
      return DISPLAY_ROLE_MSA_MEDICAL_REVIEWER;
    }
  }

  async onCloseNotifications() {
    setTimeout(() => this.setState({ notificationsOpened: false }), 100);
    const { unreadCount, markAsRead } = this.props;
    if (unreadCount > 0) await markAsRead();
  }

  async fetchMoreNotifications() {
    const { fetchedCount, getNotifications } = this.props;
    await getNotifications({
      queryData: { limit: 10, offset: fetchedCount },
      role: this.props.roles,
      userId: this.props.userInfo.username,
    });
  }

  handleSwitchAssigToAttorney(info) {
    if (!info) return this.setState({ openAssigToAttorney: info });

    const {
      claimant,
      report: { report },
    } = info;

    const data = {
      claimant_id: claimant.id,
      claimant,
      report_id: report.id,
      report,
    };

    this.onCloseNotifications();
    this.setState({ openAssigToAttorney: data });
  }

  async handleAssigToAttorney(newAssignement) {
    try {
      await API.createAssignementUser(newAssignement);
      notifyInfo('Assignment created');
      return true;
    } catch (e) {
      notifyApiError(e);
      return false;
    }
  }

  async hanldeSearchNotifications(filters) {
    let paramsUser = arrayToObject({ array: Object.values(OPTIONS_DROPDOWN_FILTER_NOTIFICATION) });

    filters.forEach(filter => {
      paramsUser[OPTIONS_DROPDOWN_FILTER_NOTIFICATION[filter.type]] += `${filter.text},`;
    });
    const { getNotifications } = this.props;

    Object.keys(paramsUser).forEach(reportKey => {
      if (!paramsUser[reportKey]) {
        delete paramsUser[reportKey];
      } else {
        paramsUser[reportKey] = paramsUser[reportKey].slice(0, -1);
      }
    });
    await getNotifications({
      queryData: { ...paramsUser },
      typedispatch: NOTIFICATIONS_GET_LIST_FILTER,
      role: this.props.roles,
      userId: this.props.userInfo.username,
    });
  }

  async actionNotifications({ action, payload }) {
    try {
      this.setState({ loading: true });
      this.onCloseNotifications();
      await onActionNotifications({ action, payload });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      collapsed,
      selectedKey,
      notificationsOpened,
      openAssigToAttorney,
      reportTypes,
      SIDEBAR_PERMISSIONS,
      loading,
    } = this.state;
    const { userInfo, notifications, count, fetchedCount, unreadCount, isActiveReviewer } = this.props;

    return (
      <Sider
        width={250}
        className={`navigation-container ${collapsed ? 'menu-wrap-collapse' : 'menu-wrap-normal'}`}
        collapsed={collapsed}>
        <div className="menu-wrap" style={{ width: collapsed ? 80 : 250 }}>
          <Row type="flex" align="middle" className="logo" onClick={() => this.onSelectMenu({ key: SETTINGS_NAV.key })}>
            <Col>
              <Row type="flex" justify="center" align="middle" className="avatar">
                <Text>
                  {userInfo &&
                    userInfo.attributes &&
                    (userInfo.attributes.given_name ? userInfo.attributes.given_name.substr(0, 1).toUpperCase() : '') +
                      (userInfo.attributes.family_name
                        ? userInfo.attributes.family_name.substr(0, 1).toUpperCase()
                        : '')}
                </Text>
              </Row>
            </Col>
            {!collapsed && (
              <Col>
                <Row className="user-info">
                  <Text ellipsis className="name">
                    {userInfo &&
                      userInfo.attributes &&
                      (userInfo.attributes.given_name ? userInfo.attributes.given_name : '') +
                        ' ' +
                        (userInfo.attributes.family_name ? userInfo.attributes.family_name : '')}
                  </Text>
                </Row>
                <Row className="user-info">
                  <Text ellipsis className="email">
                    {userInfo && userInfo.attributes && userInfo.attributes.email}
                  </Text>
                </Row>
                <Row className="user-info">
                  <Text ellipsis className="roles">
                    {this.props.roles.user_roles?.package_id === INDIVIDUAL_BASIC_ID
                      ? 'Writer'
                      : this.displayroles(this.props.roles.user_roles.roles)}
                  </Text>
                </Row>
                {this.props.roles.user_roles?.specialty && (
                  <Row className="user-info">
                    <Text ellipsis className="roles">
                      {SPECIALTY_IDS_OBJECT[this.props.roles.user_roles.specialty].name || ''}
                    </Text>
                  </Row>
                )}
              </Col>
            )}
          </Row>

          <Menu
            selectedKeys={[selectedKey]}
            mode="inline"
            className="menu"
            theme="dark"
            onClick={this.onSelectMenu}
            onSelect={this.onSelectMenu}>
            <Menu.Divider className="divider" />
            {SIDEBAR_PERMISSIONS.myClaimants && isActiveReviewer && (
              <Menu.Item key="claimants" className="menu-item">
                <Icon component={ClientsIcon} className="menu-item" />
                <span>My Claimants</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.dashboard && isActiveReviewer && (
              <Menu.Item key={MAIN_NAV.key} className="menu-item">
                <Icon component={DashboardIcon} className="menu-item" />
                <span>{MAIN_NAV.name}</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.myClients && isActiveReviewer && (
              <Menu.Item key={CLIENTS_NAV.key} className="menu-item" data-id-test="sidebar-my-client">
                <Icon component={ClientsIcon} className="menu-item" />
                <span>My Clients</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.myUsers && isActiveReviewer && (
              <Menu.Item key="users" className="menu-item" data-id-test="sidebar-my-Users-admin">
                <Icon component={UserIcon} className="menu-item" />
                <span>My Users</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.myUsersClient && isActiveReviewer && (
              <Menu.Item key="users-client" className="menu-item">
                <Icon component={UserIcon} className="menu-item" />
                <span>My Users</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.litigationAttorney && isActiveReviewer && (
              <Menu.Item key={LITIGATION_ATTORNEY_NAV.key} className="menu-item">
                <Icon component={LitigationAttorney} className="menu-item" />
                <span>{LITIGATION_ATTORNEY_NAV.name}</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.assignmentBoard && isActiveReviewer && (
              <Menu.Item key={ASSIGNMENTBOARD_NAV.key} className="menu-item">
                <Icon component={CalendarIcon} className="menu-item" />
                <span>{ASSIGNMENTBOARD_NAV.name}</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.notifications && isActiveReviewer && (
              <Menu.Item key="notifications" className="menu-item">
                <Icon component={NotificationIcon} className="menu-item" />
                <span>Notifications</span>
                <Notifications
                  mode="green"
                  notifications={notifications}
                  openModal={notificationsOpened}
                  count={count}
                  fetchedCount={fetchedCount}
                  searchOptions={Object.keys(OPTIONS_DROPDOWN_FILTER_NOTIFICATION)}
                  reportTypes={reportTypes}
                  isAdmin={isAllowed(this.props.roles, [
                    ...ROLES_DIVISIONS.SUPERADMIN,
                    ...ROLES_DIVISIONS.SALES_MANAGER,
                  ])}
                  isClient={isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT)}
                  fetchMore={this.fetchMoreNotifications}
                  onAssignAtt={info => this.handleSwitchAssigToAttorney(info)}
                  onSearch={this.hanldeSearchNotifications}
                  onClose={this.onCloseNotifications}
                  onActions={this.actionNotifications}
                />
                {unreadCount > 0 && !collapsed && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 20,
                      width: 22,
                      height: 22,
                      backgroundColor: 'rgba(255, 255, 255, 0.16)',
                    }}>
                    {unreadCount}
                  </div>
                )}
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.inReview && isActiveReviewer && (
              <Menu.Item key="Reviews" className="menu-item">
                <Icon component={ReviewIcon} className="menu-item" />
                <span>In Review</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.support && isActiveReviewer && (
              <Menu.Item key={SUPPORT_NAV.key} className="menu-item">
                <Icon component={ChatIcon} className="menu-item" />
                <span>{SUPPORT_NAV.name}</span>
              </Menu.Item>
            )}

            {/* {SIDEBAR_PERMISSIONS.chat && isActiveReviewer && (
              <Menu.Item key="chat" className="menu-item">
                <Icon component={ChatIcon} className="menu-item" />
                <span>Chat</span>
              </Menu.Item>
            )} */}

            {SIDEBAR_PERMISSIONS.search && isActiveReviewer && (
              <Menu.Item key={SEARCH_NAV.key} className="menu-item">
                <Icon component={ReviewIcon} className="menu-item" />
                <span>{SEARCH_NAV.name}</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.cabinet && isActiveReviewer && (
              <Menu.Item key="gabinet" className="menu-item">
                <Icon component={TemplatesIcon} className="menu-item" />
                <span>Report Cabinet</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.template && isActiveReviewer && (
              <Menu.Item key={TEMPLATE_NAV.key} className="menu-item">
                <Icon component={TemplateIcon} className="menu-item" />
                <span>{TEMPLATE_NAV.name}</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.surgical && isActiveReviewer && (
              <Menu.Item key="pricing" className="menu-item">
                <Icon component={SyringeIcon} className="menu-item" />
                <span>Surgical Pricing Plans</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.resourceLinks && isActiveReviewer && (
              <Menu.Item key="ResourceLibrary" className="menu-item">
                <Icon component={LibraryResIcon} className="menu-item" />
                <span>Resource Links</span>
              </Menu.Item>
            )}

            {SIDEBAR_PERMISSIONS.contacts && isActiveReviewer && (
              <Menu.Item key="contacts" className="menu-item">
                <Icon component={ContactsIcon} className="menu-item" />
                <span>Contacts</span>
              </Menu.Item>
            )}

            {/* {SIDEBAR_PERMISSIONS.queue && isActiveReviewer && (
              <Menu.Item key="8" onClick={() => this.setState({ openReportQueueModal: true })} className="menu-item">
                <Icon component={ReportQueueIcon} className="menu-item" />
                <span>Report Queue</span>
              </Menu.Item>
            )} */}

            {SIDEBAR_PERMISSIONS.marketing && isActiveReviewer && (
              <Menu.Item key={MARKETING_NAV.key} className="menu-item">
                <Icon component={MarketingIcon} className="menu-item navigationIconMoreWidth" />
                <span className="navigation_lineHeight">{MARKETING_NAV.name}</span>
              </Menu.Item>
            )}

            <Menu.Item key={SETTINGS_NAV.key} className="menu-item">
              <Icon component={SettingsIcon} className="menu-item" />
              <span>{SETTINGS_NAV.name}</span>
            </Menu.Item>

            {SIDEBAR_PERMISSIONS.helpTutorials && isActiveReviewer && (
              <Menu.Item key={HELP_NAV.key} className="menu-item">
                <Icon component={HelpIcon} className="menu-item" />
                <span>{HELP_NAV.name}</span>
              </Menu.Item>
            )}

            <Menu.Divider className="divider" />

            <Menu.Item key="6" className="menu-item" onClick={this.props.logout} data-id-test="logout-page-sidebar">
              <Icon component={LogoutIcon} className="menu-item" />
              <span>Logout</span>
            </Menu.Item>
            <Menu.Divider className="divider" />
          </Menu>
          {!collapsed && (
            <Row>
              <Row className="text">
                <Text>Copyright © {new Date().getFullYear()} MSA Technologies, Inc.</Text>
              </Row>
              <Row className="link">
                <a href="/terms">Terms and conditions</a>
              </Row>
            </Row>
          )}
        </div>

        <ReportCabinetModal
          visible={this.state.openReportCabinetModal}
          onCancel={() => this.setState({ openReportCabinetModal: false })}
        />
        {/* <ReportQueueModal
          visible={this.state.openReportQueueModal}
          onCancel={() => this.setState({ openReportQueueModal: false })}
        /> */}
        {openAssigToAttorney && (
          <Modal
            visible={true}
            onCancel={() => this.handleSwitchAssigToAttorney(false)}
            footer={null}
            centered={true}
            closable={true}
            width={700}
            className="calendar-modal-container">
            <AddAssignForm
              onSubmit={async data => {
                await this.handleAssigToAttorney(data);
                this.setState({ openAssigToAttorney: false });
              }}
              disabledField={{
                assignmentType: true,
                client: true,
                claimant: true,
                report: true,
              }}
              defaultAssignmentType={ASSIGNMENT_TYPE_ATTORNEY}
              edit={openAssigToAttorney}
            />
          </Modal>
        )}
        <Row className="collapse" style={{ width: collapsed ? 80 : 250 }}>
          <Col style={{ height: '100%' }} onClick={this.onCollapse}>
            {collapsed ? (
              <Col className="collapse-content">
                <ShowIcon />
              </Col>
            ) : (
              <Col className="collapse-content">
                <HideIcon />
                <span className="collapse-text">Hide</span>
              </Col>
            )}
          </Col>
        </Row>

        {loading && <ModalLoading />}
      </Sider>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
    notifications: state.notification.notifications,
    count: state.notification.count,
    fetchedCount: state.notification.fetchedCount,
    unreadCount: state.notification.unreadCount,
    isActiveReviewer: state.auth.isActiveReviewer,
  };
  return props;
};

export default connect(mapStateToProps, {
  getUserInfo,
  logout,
  openChatDrawer,
  getNotifications,
  markAsRead,
})(Navigation);
