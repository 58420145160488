import React from 'react';
import { Modal, Spin, Typography, Button } from 'antd';
import { ModalManagerItem } from '.';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TrashIcon } from 'components/Svg';

const { Text } = Typography;

const ModalManagerComponent = ({
  loading,
  handleChangePosition,
  handleAddFieldNewData,
  listData,
  listIdDataEdit,
  handleSelectEditItem,
  handleConfirmEdit,
  handleDeleteItem,
  handleDeleteAllItem,
  handleChangeNewItem,
  addItem,
  handleSave,
  close,
}) => {
  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      title="Header Manager"
      className="ModalManager">
      <DragDropContext onDragEnd={handleChangePosition}>
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>

        <div className="modalManager_table">
          <div className="modalManager_table_header modalManager_table_item modalManager_grid2">
            <Text>Name</Text>
            <Text className="modalManager_table_add" onClick={handleAddFieldNewData}>
              +
            </Text>
          </div>
          <Droppable droppableId="listManager">
            {droppableProvided => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                className="modalManager_table_body">
                {listData.length > 0 &&
                  listData.map((item, index) => (
                    <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
                      {draggableProvided => (
                        <div
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.dragHandleProps}>
                          <ModalManagerItem
                            edit={listIdDataEdit.includes(item.id)}
                            item={item}
                            onSelectEdit={handleSelectEditItem}
                            onConfirmEdit={handleConfirmEdit}
                            onDelete={handleDeleteItem}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}

                {droppableProvided.placeholder}

                {addItem.length > 0 && (
                  <>
                    {addItem.map(item => (
                      <ModalManagerItem key={item.id} newItem item={item} onChangeNewItem={handleChangeNewItem} />
                    ))}
                  </>
                )}
              </div>
            )}
          </Droppable>
        </div>
        <div className="modalManagers_deleteAll modalManager_mTop modalManager_pRight">
          <button className="button_simple button" onClick={handleDeleteAllItem}>
            Remove All
            <TrashIcon color="#0082FF" />
          </button>
        </div>

        <Button type="primary" className="green-btn button" onClick={handleSave}>
          Add
        </Button>
        <Button className="button_simple" onClick={close}>
          cancel
        </Button>
      </DragDropContext>
    </Modal>
  );
};

export default ModalManagerComponent;
