import dayjs from 'dayjs';
import {
  NOTIFICATIONS_LOADING_START,
  NOTIFICATIONS_LOADING_END,
  NEW_NOTIFICATION,
  NOTIFICATIONS_GET_LIST,
  NOTIFICATIONS_GET_UNREAD_COUNT,
  NOTIFICATION_MARK_READ,
  NOTIFICATIONS_GET_LIST_FILTER,
} from './actionTypes';

const LIMIT = 10;

const initialState = {
  isLoading: true,
  pending: false,
  notifications: {},
  count: 0,
  fetchedCount: 0,
  unreadCount: 0,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };

    case NOTIFICATIONS_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };

    case NOTIFICATIONS_GET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload || 0,
      };

    case NOTIFICATIONS_GET_LIST: {
      // Notifications from server
      const list = action.payload.rows || [];

      if (list.length === 0) {
        return {
          ...state,
          count: state.fetchedCount,
        };
      }

      // Object to split notifications by date
      const dateObj = { ...state.notifications };

      for (let item of list) {
        const notification = item.notification;
        if (!notification) continue;

        const dateKey = dayjs(notification.createdAt).format('YYYY-MM-DD');

        if (!dateObj[dateKey]) {
          dateObj[dateKey] = [];
        }

        if (!dateObj[dateKey].some(notif => notif.notification.id === item.notification.id)) {
          dateObj[dateKey].push(item);
        }
      }

      const newFetchedCount = state.fetchedCount + list.length;

      return {
        ...state,
        notifications: { ...state.notifications, ...dateObj },
        count: list.length < LIMIT ? newFetchedCount : newFetchedCount + 1, //infinitely continued fetching till the end
        fetchedCount: newFetchedCount,
      };
    }

    case NOTIFICATIONS_GET_LIST_FILTER: {
      // Notifications from server
      const list = action.payload.rows || [];

      if (list.length === 0) {
        return {
          ...state,
          notifications: {},
          count: state.fetchedCount,
        };
      }

      // Object to split notifications by date
      const dateObj = {};

      for (let item of list) {
        const notification = item.notification;
        if (!notification) continue;

        const dateKey = dayjs(notification.createdAt).format('YYYY-MM-DD');

        if (!dateObj[dateKey]) {
          dateObj[dateKey] = [];
        }

        if (!dateObj[dateKey].some(notif => notif.notification.id === item.notification.id)) {
          dateObj[dateKey].push(item);
        }
      }

      const newFetchedCount = state.fetchedCount + list.length;
      return {
        ...state,
        notifications: {...dateObj },
        count: list.length < LIMIT ? newFetchedCount : newFetchedCount + 1, //infinitely continued fetching till the end
        fetchedCount: newFetchedCount,
      };
    }

    case NEW_NOTIFICATION: {
      const { type, body } = action.payload;
      if (!type || !body || !body.info) {
        return {
          ...state,
        };
      }

      const dateKey = dayjs(body.createdAt).format('YYYY-MM-DD');
      const dateArr = [...(state.notifications[dateKey] || [])];

      dateArr.unshift({ notification: { ...body } });

      return {
        ...state,
        notifications: { ...state.notifications, [dateKey]: dateArr },
        count: state.count + 1,
        fetchedCount: state.fetchedCount + 1,
        unreadCount: state.unreadCount + 1,
      };
    }

    case NOTIFICATION_MARK_READ: {
      const dateObj = {};
      for (const [key, dayArr] of Object.entries(state.notifications)) {
        for (const notification of dayArr) {
          notification.is_view = true;
        }
        dateObj[key] = dayArr;
      }
      return {
        ...state,
        notifications: dateObj,
        unreadCount: 0,
      };
    }

    default:
      return state;
  }
};

export default notification;
