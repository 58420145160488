import React from 'react';
import ReactInputMask from 'react-input-mask';
import { Icon, Select, Typography } from 'antd';

// Components
import InputBox from 'components/InputBox';
import { InputCardNumber } from 'components/InputCardNumber';

// Utils
import { formatExpired, validInputNumber } from 'utils/utils';

// Style
import './paymentTenant.scss';
import { LIST_ALL_STATES_USA } from 'constants/statesUSA';

const { Text } = Typography;
const { Option } = Select;

const PaymentTenant = ({ creditCard, onChange }) => (
  <div className="paymentTenant">
    <div className="paymentTenant_card">
      <div className="cardSummary_header">
        <h2>Billing Information</h2>
      </div>

      <div className="paymentTenant_billing">
        <div className="paymentTenant_zip">
          <Text>State</Text>

          <Select
            showSearch
            placeholder="Please select"
            optionFilterProp="children"
            className="dropdown"
            value={creditCard?.billing_state || ''}
            onChange={value => onChange({ field: 'billing_state', payload: value })}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            {LIST_ALL_STATES_USA.map(item => (
              <Option value={item.contraction} key={item.contraction}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>

        <InputBox
          label="City"
          placeholder="Required"
          value={creditCard?.billing_city || ''}
          onChange={value => onChange({ field: 'billing_city', payload: value })}
        />

        <div className="paymentTenant_zip">
          <Text>Zip code</Text>
          <ReactInputMask
            mask="99999"
            placeholder="Required"
            className="input-ssn"
            value={creditCard?.billing_zipCode || ''}
            onChange={e => onChange({ field: 'billing_zipCode', payload: e.target.value })}
          />
        </div>
      </div>
    </div>

    <div className="paymentTenant_card">
      <div className="cardSummary_header">
        <h2>Payment Details</h2>
        <p>Complete your purchase item by providing your payment details orders</p>
      </div>

      <InputBox
        label="Full name"
        placeholder="Required"
        value={creditCard?.name || ''}
        onChange={value => onChange({ field: 'name', payload: value })}
      />

      <div className="paymentTenant_gruop">
        <InputCardNumber
          cardNumber={creditCard?.cardNumber || ''}
          onChange={value => onChange({ field: 'cardNumber', payload: value })}
        />
        <InputBox
          label="CVV Code"
          value={creditCard?.cvv || ''}
          onChange={value => onChange({ field: 'cvv', payload: validInputNumber({ value: value, max: 4 }) })}
          placeholder="CVV Code"
        />
      </div>
      <div className="paymentTenant_gruop">
        <InputBox
          label="Expiration Date"
          value={creditCard?.expirationDate || ''}
          onChange={value => onChange({ field: 'expirationDate', payload: formatExpired({ value: value }) })}
          placeholder="MM/YY"
        />

        <div className="paymentTenant_zip">
          <Text>Zip code</Text>
          <ReactInputMask
            mask="99999"
            placeholder="Required"
            className="input-ssn"
            value={creditCard?.zipCode || ''}
            onChange={e => onChange({ field: 'zipCode', payload: e.target.value })}
          />
        </div>
      </div>
    </div>
  </div>
);

export default PaymentTenant;
