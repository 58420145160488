import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import * as QueryString from 'query-string';

import qs from '../../utils/create-query-string';
import { Row, Col, Typography, Input, Empty, Button, Skeleton } from 'antd';
import PaginationHOC from '../../components/Pagination/PaginationHOC';
import { DetailsIcon } from 'components/Icons';
import { getAssignments } from '../../redux/review';
import {
  ASSIGNMENT_STATUS_IN_PROGRESS,
  ASSIGNMENT_STATUS_PENDING_REVIEW,
  ASSIGNMENT_STATUS_RETURNED,
} from './assignment-statuses';
import { ATTORNEY_CLAIMANT_REPORT_NAV } from 'layout/navigate_const';
import './reviews.scss';

//display only these statuses
const REVIEW_STATUSES = [ASSIGNMENT_STATUS_PENDING_REVIEW, ASSIGNMENT_STATUS_RETURNED, ASSIGNMENT_STATUS_IN_PROGRESS];

const ReviewsAttorney = () => {
  const { Title, Text } = Typography;
  const { Search } = Input;

  const history = useHistory();
  const dispatch = useDispatch();

  const { limit } = useSelector(({ application }) => application.settings);
  const { roles } = useSelector(state => state.auth);

  const { isLoading, assignments, count } = useSelector(({ review }) => review);

  const { url } = useRouteMatch();
  const query = QueryString.parse(history.location.search);
  const page = query.page || 0;
  const qaSearch = query.qaSearch?.trim() || '';
  const [searchInput, setSearchInput] = useState(qaSearch);

  useEffect(() => {
    const loadAssignments = () => {
      const apiCall = tenant_only =>
        getAssignments({ qaSearch, page, limit, status: REVIEW_STATUSES, tenant_only })(dispatch);

      apiCall(true);
    };

    loadAssignments();
    // eslint-disable-next-line
  }, [dispatch, page, qaSearch, limit, roles]);

  const handleGotoReport = selectedReport => async () => {
    if (selectedReport.report.report_status_id === 18) return history.push(ATTORNEY_CLAIMANT_REPORT_NAV.path, { data: selectedReport.claimant });
  };

  const onChange = e => setSearchInput(e.target.value);

  const onSearch = value => {
    delete query.page;
    value = value.trim();
    history.push(`${url}${qs({ ...query, qaSearch: encodeURIComponent(value) })}`);
  };

  return (
    <div className="review-container">
      <Row type="flex" align="middle" justify="space-between" className="header">
        <Col className="search">
          <Search
            placeholder="Search..."
            className="search-box"
            onChange={onChange}
            value={searchInput}
            onSearch={onSearch}
          />
        </Col>
        <Col className="text-center">
          <Title level={4} className="review-title">
            Review
          </Title>
        </Col>
        <Col className="pagination">
          <PaginationHOC count={count} />
        </Col>
      </Row>

      <Row type="flex" className="table-header">
        <Col md={3}>Report ID</Col>
        <Col md={3}>Claimant</Col>
        <Col md={3}>Claimant ID</Col>
        <Col md={3}>Client</Col>
        <Col md={4}>Nurse Assigned</Col>
        <Col md={3}>Return Date</Col>
        <Col md={3}>Status</Col>
        <Col md={2} align="end">
          Actions
        </Col>
      </Row>

      <Row className="table-content">
        {isLoading ? (
          <Skeleton loading={isLoading} active title={false} paragraph={{ rows: 10, width: '100%' }} />
        ) : count > 0 ? (
          assignments.map(item => (
            <Row key={item.id} className="record">
              <Col xs={3} className="user-column">
                <Text className="user-name" type={!item.report?.report_uuid && !item.report_uuid && 'secondary'}>
                  {item.report?.report_uuid || item.report_uuid || 'N/A'}
                </Text>
              </Col>
              <Col xs={3}>
                <Text>
                  {item.claimant?.claimant_name && item.claimant?.claimant_last_name
                    ? `
                    ${item.claimant?.claimant_name} 
                    ${item.claimant?.claimant_last_name}
                  `
                    : item.claimant_full_name}
                </Text>
              </Col>
              <Col xs={3}>
                <Text>{item.claimant_id}</Text>
              </Col>
              <Col xs={3}>
                <Text>{item.claimant?.client?.client_name || item.client_name}</Text>
              </Col>
              <Col xs={4}>
                <Text>{item?.nurse?.given_name || ''}</Text>
              </Col>
              <Col xs={3}>
                <Text className="wrapped-text">{dayjs(item.end_date).format('MMM DD, YYYY')}</Text>
              </Col>
              <Col xs={3}>
                <Text className="wrapped-text">{item.status?.name || 'N/A'}</Text>
              </Col>
              <Col xs={2} className="actions text-right">
                <Button type="link" shape="circle" ghost onClick={handleGotoReport(item)}>
                  <DetailsIcon className="icon" />
                </Button>
              </Col>
            </Row>
          ))
        ) : (
          <Empty
            description={isLoading ? false : 'No Data'}
            image={!isLoading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
            className="empty-icon"
          />
        )}
      </Row>
    </div>
  );
};

export default ReviewsAttorney;
