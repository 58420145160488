import React, { useEffect, useState } from 'react';
import { Chat } from 'msa-components';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';

const chatProps = {
  portal: 'nurse', // admin, nurse
  mode: 'green', // green, orange
  apiUrl: process.env.REACT_APP_API_URL,
  socketUrl: process.env.REACT_APP_API_CHAT_URL,
  getToken: () => {},
  token: null,
};

const ChatComponent = () => {
  const [init, setInit] = useState(false);
  const [token, setToken] = useState(null);
  const { isAuthenticated } = useSelector(({ auth }) => auth);

  //force unmount chat to release WS connection
  useEffect(() => {
    if (!isAuthenticated) {
      setInit(false);
      return;
    }
    const initialization = async () => {
      const token = await Auth.currentSession();
      if (token) {
        setToken(token);
        setInit(true);
      }
    };
    initialization();
  }, [isAuthenticated]);

  return init && token && <Chat {...chatProps} token={token.accessToken.jwtToken} />;
};

export default ChatComponent;
