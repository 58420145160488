import React, { useState, useEffect } from 'react';
import { Row, Col, Upload, Typography, DatePicker } from 'antd';
import InputBox from 'components/InputBox';
import moment from 'moment';
import { getPresignedPublicUrlLogo } from 'utils/api';

const { Text } = Typography;

const InputSignatureReport = ({
  is_signature_photo,
  signature,
  onChangeSignature,
  beforeUpload,
  imageUrl,
  referral_date,
  index,
  disabled,
}) => {
  const [data, setData] = useState({});

  const handleChange = ({ signature, imageUrl, is_signature_photo, referral_date }) => {
    setData({
      signature,
      imageUrl,
      is_signature_photo,
      index,
      referral_date,
    });
  };

  useEffect(() => {
    setData({ imageUrl, signature, is_signature_photo, referral_date });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (is_signature_photo && Boolean(signature)) {
        const res = await getPresignedPublicUrlLogo(signature);
        handleChange({ signature, imageUrl: res?.url || res.data?.url, is_signature_photo, referral_date });
      }
    })();
    // eslint-disable-next-line
  }, [signature]);

  const handleUpdateSignature = () => {
    onChangeSignature({
      imageUrl: data.imageUrl,
      signature: data.signature,
      is_signature_photo: data.is_signature_photo,
      index,
      referral_date: data.referral_date,
    });
  };

  return (
    <Row type="flex" justify="space-between" align="middle" className="row">
      <Col md={11}>
        {!is_signature_photo && (
          <InputBox
            label="Signature"
            placeholder="Required"
            value={data.is_signature_photo ? null : data.signature}
            dataTestId={`signature_${index}`}
            disabled={disabled}
            onBlur={handleUpdateSignature}
            onChange={value =>
              handleChange({
                signature: value,
                imageUrl: null,
                is_signature_photo: false,
                referral_date: data.referral_date,
              })
            }
          />
        )}

        {is_signature_photo && (
          <>
            <Row className="label">
              <Text>Upload a picture of signature</Text>
            </Row>
            <Row type="flex" justify="center" align="middle" className="signatureReport">
              <Upload
                name="logo"
                listType="text"
                className="logo-uploader"
                disabled={disabled}
                showUploadList={false}
                beforeUpload={beforeUpload}>
                {imageUrl ? (
                  <img src={imageUrl} alt="signature" className="signature-image" />
                ) : (
                  <Text className="logo-button">Upload a picture of signature</Text>
                )}
              </Upload>
            </Row>
          </>
        )}
      </Col>
      <Col md={11}>
        <Row className="label">
          <Text>Signature Date</Text>
        </Row>
        <Row>
          <DatePicker
            className="date"
            format="MM/DD/YYYY"
            allowClear={false}
            name={`dateSignature_${index}`}
            defaultValue={moment()}
            value={data.referral_date}
            onBlur={handleUpdateSignature}
            disabled={disabled}
            onChange={date => {
              handleChange({
                referral_date: date ? moment(date) : undefined,
                signature: data.signature,
                imageUrl: data.imageUrl,
                is_signature_photo: data.is_signature_photo,
              });
            }}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default InputSignatureReport;
