import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import InputBox from 'components/InputBox';
import UploadLogo from 'components/UploadLogo/UploadLogo';
import { notifyApiError, notifyInfo } from 'utils/notification';
import * as API from 'utils/api';
import { Row, Col, Button, Divider } from 'antd';
import { ModalLoading } from 'components/Modal/ModalLoading';

import '../EditClient.scss';

function CompanyInfoTap() {
  const history = useHistory();
  const clientInfo = history.location.state?.data || {};
  const [clientName, setClientName] = useState(clientInfo?.client_name || clientInfo?.name || '');
  const [clientlogo, setClientlogo] = useState(clientInfo?.client_logo || clientInfo?.logo || '');
  const [s3key, setS3key] = useState(clientInfo.s3key);
  const [loading, setLoading] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();

    const params = {
      id: clientInfo.id,
      client_name: clientName,
    };

    if (s3key) params.client_logo = s3key;
    if (clientlogo === '') params.client_logo = '';

    try {
      setLoading(true);
      await API.updateClient(params);

      notifyInfo('Client updated');
      history.goBack();
    } catch (e) {
      notifyApiError(e);
      setLoading(false);
    }
  };
  return (
    <div className="company-info-container">
      {loading && <ModalLoading />}
      <form onSubmit={onSubmit}>
        <Row type="flex" justify="space-around" className="client-info" gutter={40}>
          <Col md={7}>
            <div className="logo">
              <UploadLogo
                changeImage={(client_logo, s3key) => {
                  setClientlogo(client_logo);
                  setS3key(s3key);
                }}
                client_logo={clientlogo}
              />
              {clientlogo && (
                <div onClick={() => setClientlogo('')} className="remove_logo_btn">
                  Remove
                </div>
              )}
            </div>
          </Col>
          <Col md={17} className="content">
            <Row className="row">
              <InputBox label="Client - Company Name" value={clientName} onChange={value => setClientName(value)} />
            </Row>
          </Col>
        </Row>
        <Divider className="divider-space" />

        <Row type="flex" justify="center">
          <Button type="primary" htmlType="submit" size="large" className="green-btn button">
            Save
          </Button>
        </Row>
      </form>
    </div>
  );
}

export default CompanyInfoTap;
