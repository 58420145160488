import React, { useState } from 'react';
import * as API from 'utils/api';
import { useSelector } from 'react-redux';

// Components
import { Row, Col, Typography, Empty, Switch, Collapse, Avatar } from 'antd';
import SpinBox from 'components/SpinBox';
import { ModalAddUserToMSA } from '../Modal';
import { CVcomponent } from '../CVcomponent';
import { EXTERNAL_LITIGATION_ATTORNEY_ID } from 'layout/roles';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Permission
import { LITIGATION_ATTORNEY_NAME, getPermissionsBySection } from 'permissions';

// Icons
import { StartIcon } from 'components/Svg';

const { Text } = Typography;
const { Panel } = Collapse;

const StepClientAttorney = ({ listLitigation, handleChangeFavorite, updateList }) => {
  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { LITIGATION_ATTORNEY_PERMISSION } = getPermissionsBySection({
    roles: roles,
    permissions: [LITIGATION_ATTORNEY_NAME],
  });
  // end permissions

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [openModalToUser, setOpenModalToUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChandeSwitch = async ({ user, switchUser, client }) => {
    try {
      setLoading(true);

      await API.EditRole(user.user_id, EXTERNAL_LITIGATION_ATTORNEY_ID, {
        visibility: switchUser,
      });

      setOpenModalToUser(switchUser ? 'add' : 'removed');
      setSelectedUser(user);
      setSelectedClient(client);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModalToUser(false);
    setSelectedUser(null);
    updateList();
  };

  return (
    <>
      {loading && <ModalLoading />}
      <Row type="flex" className="table-header">
        <Col xs={20}>Client</Col>
        <Col xs={4} className="litigation_posRight">
          Attorneys
        </Col>
      </Row>

      <Row className="table-content nonePadding">
        <SpinBox loading={false}>
          {listLitigation.length > 0 ? (
            <Collapse>
              {listLitigation.map(client => (
                <Panel
                  header={
                    <div className="litigation_client_info">
                      <div className="litigation_client_group">
                        <Avatar size={48} src={client.client_logo} />
                        <Text className="client-name">{client.client_name}</Text>
                      </div>
                      <Text className="client-name">{client.user_roles.length}</Text>
                    </div>
                  }
                  key={client.id}>
                  <Row>
                    {client.user_roles.map(litigation => (
                      <Row key={litigation.id} className="record" gutter={[5, 40]}>
                        {/* Name of Attorney */}
                        <Col xs={6}>
                          <Text className="wrapped-text user-name">
                            {litigation?.user?.given_name || ''} {litigation?.user?.family_name || ''}
                          </Text>
                        </Col>

                        {/* Visibility Type */}
                        <Col xs={3}>
                          <Text
                            className={`litigation_visibility ${
                              litigation?.visibility ? 'litigation_visibility_active' : ''
                            }`}>
                            {litigation?.visibility ? 'Public' : 'Private'}
                          </Text>
                        </Col>

                        {/* Email */}
                        <Col xs={7}>
                          <Text className="wrapped-text">{litigation?.user?.email}</Text>
                        </Col>

                        {/* Phone */}
                        <Col xs={4}>
                          <Text>{litigation?.user?.locale}</Text>
                        </Col>

                        {/* CV */}
                        <Col xs={1} className={`litigation_pdf ${litigation?.CV ? 'litigation_pdf_pointer' : ''}`}>
                          <CVcomponent cvFile={litigation?.user?.CV} fileName={litigation?.user?.nameCV} />
                        </Col>

                        {/* Swicth */}
                        <Col xs={2} className="litigation_posCenter">
                          <Switch
                            className="litigation_switch"
                            defaultChecked={litigation?.visibility}
                            onChange={e => handleChandeSwitch({ switchUser: e, user: litigation, client })}
                          />
                        </Col>

                        {/* Actions */}
                        <Col xs={1} className="actions litigation_posRight litigation_start">
                          {LITIGATION_ATTORNEY_PERMISSION.setFavorite && (
                            <StartIcon
                              active={litigation.range}
                              onClick={() =>
                                handleChangeFavorite({ userId: litigation.user_id, range: litigation.range })
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Row>
                </Panel>
              ))}
            </Collapse>
          ) : (
            <Empty description="No Data" image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
          )}
        </SpinBox>
      </Row>

      {openModalToUser && (
        <ModalAddUserToMSA
          option={openModalToUser}
          onClose={handleCloseModal}
          user={selectedUser}
          client={selectedClient}
        />
      )}
    </>
  );
};

export default StepClientAttorney;
