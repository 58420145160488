import React, { useEffect, useState } from 'react';
import './App.css';
import 'antd/dist/antd.css';
import Routes from './route';
import { useIdleTimer } from 'react-idle-timer';
import Amplify from 'aws-amplify';
import config from './config';
import { Auth } from 'aws-amplify';
import './assets/scss/main.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoles, logout } from './redux/auth';
import { notifyInfo } from './utils/notification';
import dayjs from 'dayjs';
import { SET_LAST_ACTIVE } from './redux/auth/actionTypes';

Amplify.configure(config.amplify);
Auth.configure(config.amplify);
window.isEmptyOrSpaces = str => {
  return str === null || str.match(/^ *$/) !== null;
};
function App() {
  const dispatch = useDispatch();
  const { lastActiveAt, isAuthenticated } = useSelector(({ auth }) => auth);
  const [timerActive, setTimerActive] = useState(false);

  const handleOnIdle = async () => {
    console.info(`User is Idle timeout...`);
    try {
      const res = await Auth.currentUserInfo();
      if (res !== null) {
        logout()(dispatch);
        notifyInfo('Signed out after 20min idle timeout');
        setTimerActive(false);
      }
    } catch (e) {
      notifyInfo('Error while logout');
    }
  };

  const handleOnAction = async () => {
    if (!isAuthenticated || !timerActive) return;

    //check if cache is clear to prevent further working
    const isAuthenticatedStorage = localStorage.getItem(`isAuthenticated`);
    if (isAuthenticated && !isAuthenticatedStorage) {
      await logout()(dispatch);
      return;
    }
    //do not often refresh localstorage (one time per minute)
    const now = dayjs();
    const difference = now.diff(dayjs(lastActiveAt), 'second');
    if (difference > 60) {
      localStorage.setItem('lastActiveAt', now.toISOString());
      dispatch({ type: SET_LAST_ACTIVE, payload: now });
    }
  };

  const { reset, pause } = useIdleTimer({
    timeout: 1000 * 60 * 20,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 1000,
    startOnMount: false,
    stopOnIdle: true,
  });

  let inrv;
  useEffect(() => {
    inrv = setInterval(() => {
      var wscInstances = window.WEBSPELLCHECKER.getInstances();
      if (wscInstances.length < 1 && document.querySelector('#fixspell')) {
        window.WEBSPELLCHECKER.init({
          container: document.querySelector('#fixspell'),
        });
      } else {
        if (document.querySelector('#fixspell')) {
          window.WEBSPELLCHECKER.init({
            container: document.querySelector('#fixspell'),
          });
        }

        function isObjectEmpty(obj) {
          return Object.keys(obj).length === 0;
        }
        if (isObjectEmpty(wscInstances[0] ? wscInstances[0] : {})) {
        }
      }
      // localStorage.setItem("beforeUnloadDate", moment());
    }, 5000);
    if (isAuthenticated && !timerActive) {
      setTimerActive(true);
      reset();
      return;
    }
    if (timerActive && !isAuthenticated) {
      setTimerActive(false);
      pause();
    }
  }, [pause, reset, timerActive, isAuthenticated]);

  useEffect(() => {
    const checkTimeout = async () => {
      if (isAuthenticated) {
        const lastActiveAt = localStorage.getItem(`lastActiveAt`);
        //check if open link after 20 min timeout
        const difference = dayjs().diff(dayjs(lastActiveAt), 'second');
        if (difference > 60 * 20) {
          await logout()(dispatch);
          notifyInfo('User has been signed out due to 20 min last activity timeout.');
        }
      }
    };
    const obtainRoles = async () => {
      if (isAuthenticated) {
        await fetchRoles()(dispatch);
      }
    };

    checkTimeout();
    obtainRoles();
  }, [isAuthenticated, dispatch]);

  return <Routes />;
}

export default App;
