import React from 'react';

// Components
import { Modal, Typography, Button, Avatar } from 'antd';

// icons
import { CheckBoxIcon } from 'components/Svg';

import './modal.scss';

const { Title, Text } = Typography;

const ModalAddUserToMSA = ({ onClose, user, option, client }) => (
  <Modal
    visible={true}
    onCancel={onClose}
    footer={null}
    centered={true}
    closable={false}
    width={600}
    className="modalMessage"
    title={null}>
    <div className="litigationModal">
      <div className="litigationModal_icon">
        <CheckBoxIcon />
      </div>

      <div className="litigationModal_header">
        <Title level={4} className="litigationModal_title">
          Attorney has been {option === 'add' ? 'added' : 'removed'} to the MSA Attorneys list
        </Title>
        <Text className="litigationModal_description">
          {option === 'add'
            ? 'For public use by other law firms'
            : 'And is no longer avaialable for other law firms to use'}
        </Text>
      </div>

      <div className="litigationModal_user">
        <Avatar src={client?.client_logo || ''} size={48} />
        <div className="litigationModal_user_name">
          <p className="litigationModal_user_name_item">
            <strong>{`${user?.user?.given_name || 'given name'} ${user?.user?.family_name || 'family name'}`}</strong>
          </p>
          <p className="litigationModal_email">{user?.user?.email || ''}</p>
        </div>
      </div>

      <Button type="primary" size="large" className="m-2 purple-btn button btn-normal" onClick={onClose}>
        Ok
      </Button>
    </div>
  </Modal>
);

export default ModalAddUserToMSA;
