import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import * as API from 'utils/api';
import { useParams } from 'react-router-dom';

// Components
import { Modal, Button, Typography } from 'antd';
import { CustomCheckBox } from 'components/CustomCheckBox';
import InputBox from 'components/InputBox';

// Const
import { COLUMNS_TABLE_LIFE_CARE_PLAN } from '../../TemplateLifeCarePlanTable/columns';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';

const { Text } = Typography;

const ModalCreateTable = ({ onClose, orderNewTable, onUpdateOrderStructure, editTable, onUpdaleList }) => {
  const { idReport } = useParams();

  const [newTable, setNewTable] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);

  const disableCreateTable = COLUMNS_TABLE_LIFE_CARE_PLAN.some(item => newTable[item.field]) && newTable.name;

  useEffect(() => {
    const columns = {};
    COLUMNS_TABLE_LIFE_CARE_PLAN.forEach(item => {
      columns[item.field] = true;
    });

    if (editTable) return setNewTable(editTable);

    setNewTable({
      name: '',
      order: orderNewTable,
      report_id: idReport,
      ...columns,
    });
  }, []);

  const handleChangeNameNewTable = ({ name }) => {
    setNewTable(prevState => {
      return {
        ...prevState,
        name,
      };
    });
  };

  const handleChangeCheck = ({ field }) => {
    setNewTable(prevState => {
      return {
        ...prevState,
        [field]: !prevState[field],
      };
    });
  };

  const handleCreateTable = async () => {
    try {
      setLoadingCreate(true);
      const cloneTable = _.cloneDeep(newTable);

      const createTable = await API.createTemplateLifeCarePlanTable({ body: cloneTable });

      onUpdateOrderStructure && onUpdateOrderStructure({ tableInfo: { ...newTable, id: createTable.id } });
      onUpdaleList && onUpdaleList();
      onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleUpdateTable = async () => {
    try {
      setLoadingCreate(true);
      await API.updateTemplateLifeCarePlanTable({ body: newTable });
      onUpdaleList && onUpdaleList();
      onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoadingCreate(false);
    }
  };

  return (
    <Modal title="New Table" onCancel={onClose} visible={true} className="report-modal" footer={false} centered>
      {loadingCreate && <ModalLoading />}
      <div className="templateLifeCarePlanTable_createInput">
        <Text>Table Name</Text>
        <InputBox
          placeholder="Table Name"
          value={newTable?.name || ''}
          onChange={value => handleChangeNameNewTable({ name: value })}
        />

        <div className="templateLifeCarePlanTable_columns">
          <Text>Columns</Text>

          <div className="templateLifeCarePlanTable_column_items">
            {COLUMNS_TABLE_LIFE_CARE_PLAN.map(item => (
              <React.Fragment key={item.id}>
                <CustomCheckBox
                  optionSignature={true}
                  onChange={() => handleChangeCheck({ field: item.field })}
                  label={item.name}
                  htmlId={`check${item.id}`}
                  value={!!newTable[item.field]}
                />
              </React.Fragment>
            ))}
          </div>

          <div className="templateLifeCarePlanTable_create_btn">
            <Button
              disabled={!disableCreateTable}
              type="primary"
              size="default"
              className="green-btn button"
              onClick={newTable.id ? handleUpdateTable : handleCreateTable}>
              {newTable.id ? 'Edit table' : 'Setup table'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateTable;
