export const TABLE_DRAGGABLE_ID = 'table';
export const TABLE_MEDICAL_COSTS_DRAGGABLE_ID = 'medicalCosts';
export const TABLE_SURGERIES_DRAGGABLE_ID = 'surgeries';
export const TABLE_PRESCRIPTION_DRAGGABLE_ID = 'Prescription';

export const FILED_SPECIAL_LIFE_TABLE = 'life_expectancy_source';

export const TABLE_MEDICAL_COSTS_NAME = 'Medical Costs';
export const TABLE_SURGERIES_NAME = 'Surgeries, Replacements and Procedures';
export const TABLE_PRESCRIPTION_NAME = 'Prescription Drug Data';

export const ITEMS_TABLE_DRAGGALE = [
  { id: TABLE_DRAGGABLE_ID, name: 'Table Custom' },
  { id: TABLE_MEDICAL_COSTS_DRAGGABLE_ID, name: TABLE_MEDICAL_COSTS_NAME },
  { id: TABLE_SURGERIES_DRAGGABLE_ID, name: TABLE_SURGERIES_NAME },
  { id: TABLE_PRESCRIPTION_DRAGGABLE_ID, name: TABLE_PRESCRIPTION_NAME },
];

export const LIST_NO_EDIT_TABLE = [TABLE_MEDICAL_COSTS_NAME, TABLE_SURGERIES_NAME, TABLE_PRESCRIPTION_NAME];

export const COLUMNS_TABLE_BY_TYPE = {
  [TABLE_MEDICAL_COSTS_DRAGGABLE_ID]: {
    name: ITEMS_TABLE_DRAGGALE.find(item => item.id === TABLE_MEDICAL_COSTS_DRAGGABLE_ID).name,
    service_name: true,
    speciality_id: true,
    cpt_hcpcs_code: true,
    frequecy_and_duration_of_need: true,
    unit_cost: true,
    annual_cost: true,
    total_years: true,
    total_cost: true,
    comment: true,
    inflation_total: false,
    start_date: false,
    age_at_start: false,
    end_date: false,
    age_at_end: false,
    recommended_by: false,
    purpose: false,
    codes: false,
    ndc: false,
    unit_form_id: false,
    prescription_strength: false,
    units_per_refill: false,
    refills_per_year: false,
  },
  [TABLE_SURGERIES_DRAGGABLE_ID]: {
    name: ITEMS_TABLE_DRAGGALE.find(item => item.id === TABLE_SURGERIES_DRAGGABLE_ID).name,
    service_name: true,
    speciality_id: true,
    cpt_hcpcs_code: true,
    frequecy_and_duration_of_need: true,
    unit_cost: true,
    annual_cost: true,
    total_years: true,
    total_cost: true,
    comment: true,
    inflation_total: false,
    start_date: false,
    age_at_start: false,
    end_date: false,
    age_at_end: false,
    recommended_by: false,
    purpose: false,
    codes: false,
    ndc: false,
    unit_form_id: false,
    prescription_strength: false,
    units_per_refill: false,
    refills_per_year: false,
  },
  [TABLE_PRESCRIPTION_DRAGGABLE_ID]: {
    name: ITEMS_TABLE_DRAGGALE.find(item => item.id === TABLE_PRESCRIPTION_DRAGGABLE_ID).name,
    prescriptions: true,
    ndc: true,
    unit_form_id: true,
    prescription_strength: true,
    prescribed_frequency: true,
    pricing_policy: true,
    inflation_total: false,
    start_date: false,
    age_at_start: false,
    end_date: false,
    age_at_end: false,
    recommended_by: false,
    purpose: false,
    codes: false,
    cpt_hcpcs_code: false,
    frequecy_and_duration_of_need: false,
    service_name: false,
    speciality_id: false,
    unit_cost: false,
    annual_cost: false,
    total_years: false,
    total_cost: false,
    comment: false,
  },
};
