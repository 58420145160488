import React, { useState } from 'react';
import { Modal, Typography, Button, Select, Icon } from 'antd';
import './modalAssignUser.scss';

const { Title } = Typography;
const { Option } = Select;

const ModalAssignUser = ({ onClose, title, listUser, onAssign }) => {
  const [userId, setUserId] = useState(null);

  const handleAssignUser = () => {
    onAssign({ userId });
  };

  return (
    <Modal visible={true} onCancel={onClose} footer={null} centered={true} closable={true} width={550}>
      <div className="modalMessage">
        <Title level={3}>{title}</Title>
        <Select
          getPopupContainer={triggerNode => triggerNode.parentNode}
          showSearch
          optionFilterProp="children"
          className="dropdown addClaimant_select"
          value={userId}
          onChange={id => setUserId(id)}
          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
          <Option disabled value={null}>
            Type user name
          </Option>
          {listUser.map(user => (
            <Option value={user.id} key={user.id}>
              {user.given_name || ''} {user.family_name || ''}
            </Option>
          ))}
        </Select>
        <Button type="primary" size="large" className="m-2 green-btn button btn-normal" onClick={handleAssignUser}>
          Assign
        </Button>
      </div>
    </Modal>
  );
};

export default ModalAssignUser;
