import React, { useState } from 'react';
import { Row, Typography, Button, Modal, Col, Empty, Radio, Select, Icon, Alert } from 'antd';
import * as API from 'utils/api';
import { notifyApiError, notifyError, notifyInfo } from '../../utils/notification';
import './RecalculateMedicareCost.scss';

import { formatCurrency } from 'utils/utils';
import { FINDACODE_FEES, PERCENTAGES, OWCP_FEES } from '../../constants/find-a-code-fees';
import SpinBox from 'components/SpinBox';
import {
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  FUTURE_MEDICAL_NEEDED,
  PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE,
  PRESCRIPTION_MEDICATIONS_COVERED,
  DONT_CHANGE_COST_ON_THIS_TYPE_2,
  DONT_CHANGE_COST_ON_THIS_TYPE,
} from 'components/MedicareCostModal/data';
import { REPORT_SURGICAL_TYPE_ID } from 'constants/reports';

const OWCP_STATUS_COVERED = 'C';
const { Text } = Typography;
const { Option } = Select;

export default function RecalculateMedicareCost({
  isMedicare,
  reportData,
  loadMedicare,
  isOpen,
  medicareList,
  onCancel,
}) {
  const [OpenBlueBook, setOpenBlueBook] = useState(false);
  const [isOpenSelectPrice, setIsOpenSelectPrice] = useState(false);
  const [findACodeData, setFindACodeData] = useState([]);
  const [blueBook, setBlueBook] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentCost, setCurrentCost] = useState({});

  const [percentage, setPercentage] = useState(1);

  const isOWCPRequest = reportData.report_type_id !== REPORT_SURGICAL_TYPE_ID && !reportData.juridiction_state_id;

  const onChangeBlueBook = async cpt_hcpcs_id => {
    if (!cpt_hcpcs_id) return;
    setLoading(true);
    const newCptList = (await API.getCPTById(cpt_hcpcs_id)) || [];
    let cptCode = newCptList?.cpt_codes.find(cpt => cpt.id === cpt_hcpcs_id); //bluebook
    let address = reportData.claimant.address.split(',');
    let currentZip = address[address.length - 1];

    API.getBlueBook(cptCode.id, currentZip)
      .then(res => {
        if (res.procedure_details.Errors.length > 0) {
          console.error('error');
        } else {
          setBlueBook(res);
          setOpenBlueBook(true);
        }
      })
      .catch(e => {
        notifyApiError(e);
      })
      .finally(() => {
        setLoading(false);
      });
    return;
  };

  const onChangeFindACode = async (type, cpt_hcpcs_id) => {
    if (reportData.is_template) {
      return;
    }

    if (cpt_hcpcs_id) {
      if (type === FUTURE_MEDICAL_NEEDED || type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE) {
        const newCptList = await API.getCPTById(cpt_hcpcs_id);
        const cptCode = newCptList?.cpt_codes.find(cpt => cpt.id === cpt_hcpcs_id) || null;

        const address = reportData?.claimant?.address.split(',') || '';
        if (!cptCode) {
          return;
        }

        const zip = address && Array.isArray(address) && address.length > 3 ? address[address.length - 1] : null;
        if (!zip) {
          notifyError('Zip code not found', "Fill Claimant's Zip code");
          return;
        }

        try {
          setLoading(true);
          const findACodeData = await API.FindACode(cptCode.code, address[address.length - 1], isOWCPRequest);
          if (!findACodeData) {
            notifyInfo('Data not found', 'A match for this CPT code and claimant zip code could not be found.');
            return;
          }

          setFindACodeData(findACodeData);
          setIsOpenSelectPrice(true);
        } catch (e) {
          notifyApiError(e);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const Recalculate = async item => {
    setCurrentCost(item);
    if (item.type === DONT_CHANGE_COST_ON_THIS_TYPE || item.type === DONT_CHANGE_COST_ON_THIS_TYPE_2) {
      await onChangeBlueBook(item.cpt_hcpcs_id);
    } else if (item.type === FUTURE_MEDICAL_NEEDED || item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE) {
      await onChangeFindACode(item.type, item.cpt_hcpcs_id);
    }
  };

  const recalculateTotal = costPerUnit => {
    const {
      refills_per_year = null,
      units_per_refill = null,
      total_years = 0,
      every_x_year = 0,
      units_needed = 0,
    } = currentCost;
    let total_cost = 0;
    let annual_cost = 0;
    if (refills_per_year && units_per_refill) {
      total_cost = Number(units_per_refill * costPerUnit * refills_per_year * total_years).toFixed(2);
      annual_cost = Number(units_per_refill * costPerUnit * refills_per_year).toFixed(2);
    } else if (every_x_year && units_needed) {
      total_cost = Number(units_needed * costPerUnit * (total_years / every_x_year)).toFixed(2);
      annual_cost = Number((units_needed * costPerUnit) / every_x_year).toFixed(2);
    }
    const newCost = {
      ...currentCost,
      total_cost,
      annual_cost,
      per_unit_cost: costPerUnit,
    };
    setLoading(true);
    API.updateMedicareItem(newCost)
      .then(() => {
        loadMedicare();
      })
      .catch(() => {
        Modal.error({
          content: 'Error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={false}
        width={900}
        className="report-complite-modal"
        title={null}>
        <Row type="flex" align="middle" className="delete-body">
          <Row>
            <Text className="label">Recalculate prices</Text>
          </Row>
          <Row className="content-info">
            <SpinBox loading={loading} title={'...Loading'}>
              <Row id="recalculate_table" className="msa-medicare-cost-table-container">
                <Row type="flex" align="middle" className="table-header">
                  <Col xs={5} className="border-right center">
                    Future Medical Need
                  </Col>
                  <Col xs={4} className="border-right center">
                    Specialty
                  </Col>
                  <Col xs={4} className="border-right center">
                    CPT/HCPCS Code
                  </Col>
                  <Col xs={4} className="border-right center">
                    Per Unit cost
                  </Col>
                  <Col xs={3} className="border-right center">
                    Total Cost
                  </Col>
                  <Col xs={4}></Col>
                </Row>
                <Row className="table-content">
                  {medicareList.length > 0 ? (
                    <Row>
                      {medicareList
                        .filter(
                          item =>
                            item.type !== PRESCRIPTION_MEDICATIONS_COVERED &&
                            item.type !== PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE &&
                            ((isMedicare &&
                              (item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE ||
                                item.type === DONT_CHANGE_COST_ON_THIS_TYPE)) ||
                              (!isMedicare &&
                                (item.type === FUTURE_MEDICAL_NEEDED || item.type === DONT_CHANGE_COST_ON_THIS_TYPE_2)))
                        )
                        .map((item, index) => {
                          return (
                            <React.Fragment key={`${index}-${item.id}`}>
                              <Row
                                key={`${index}-${item.id}`}
                                className="record"
                                id={'table-item-' + item.id + item.position}>
                                <Col xs={5} className="text-left">
                                  <Text>{item.name}</Text>
                                </Col>
                                <Col xs={4}>
                                  <Text>{item.speciality && item.speciality.name}</Text>
                                </Col>
                                <Col xs={4}>
                                  <Text>{item.cpt_code && item.cpt_code.code}</Text>
                                </Col>
                                <Col xs={4}>
                                  <Text>{formatCurrency(item.per_unit_cost)}</Text>
                                </Col>
                                <Col xs={3}>
                                  <Text>{formatCurrency(item.total_cost)}</Text>
                                </Col>
                                <Col xs={4}>
                                  <Button
                                    disabled={loading}
                                    type="primary"
                                    size="default"
                                    className="green-btn button"
                                    onClick={() => Recalculate(item)}>
                                    Recalculate
                                  </Button>
                                </Col>
                                {isOpenSelectPrice && currentCost.id === item.id && (
                                  <Col xs={24} className="select-price-findacode">
                                    <Radio.Group
                                      className="percentage-options"
                                      onChange={e => setPercentage(e.target.value)}
                                      value={percentage}>
                                      {PERCENTAGES.map(perc => (
                                        <Radio value={perc.value} key={perc.label}>
                                          {perc.label}
                                        </Radio>
                                      ))}
                                    </Radio.Group>
                                    <Select
                                      showSearch
                                      placeholder="Please select"
                                      optionFilterProp="children"
                                      className="dropdown dropdown__fees"
                                      onChange={value => {
                                        const price = Number.parseFloat(findACodeData[value] * percentage).toFixed(2);
                                        recalculateTotal(price);
                                        setIsOpenSelectPrice(false);
                                      }}
                                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                                      {(isOWCPRequest ? OWCP_FEES : FINDACODE_FEES).map(item => {
                                        const fee = Number.parseFloat(findACodeData[item.key]);
                                        if (Number.isNaN(fee)) {
                                          return <></>;
                                        }

                                        return (
                                          <Option value={item.value} key={item.key} disabled={!fee}>
                                            {item.label + ` (${formatCurrency(Number.parseFloat(fee) * percentage)})`}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                    {isOWCPRequest &&
                                      findACodeData.status &&
                                      findACodeData.status?.status_code !== OWCP_STATUS_COVERED && (
                                        <Alert
                                          message={findACodeData.status?.definition}
                                          description={findACodeData.status?.action}
                                          type="warning"
                                          showIcon
                                        />
                                      )}
                                  </Col>
                                )}
                                {OpenBlueBook && currentCost.id === item.id && (
                                  <Col xs={24} className="select-price-findacode">
                                    <Select
                                      showSearch
                                      placeholder="Please select"
                                      optionFilterProp="children"
                                      className="dropdown"
                                      onChange={value => {
                                        if (value === 1) {
                                          const price = parseFloat(
                                            blueBook.procedure_details.PricingInformations[0].TotalHighPrice1.substring(
                                              1
                                            )
                                              .split(',')
                                              .join('')
                                          );
                                          recalculateTotal(price);
                                        }
                                        if (value === 2) {
                                          const price = parseFloat(
                                            blueBook.procedure_details.PricingInformations[0].TotalLine1.substring(1)
                                              .split(',')
                                              .join('')
                                          );
                                          recalculateTotal(price);
                                        }
                                        if (value === 3) {
                                          const price = parseFloat(
                                            blueBook.procedure_details.PricingInformations[0].TotalLowPrice1.substring(
                                              1
                                            )
                                              .split(',')
                                              .join('')
                                          );
                                          recalculateTotal(price);
                                        }
                                        setOpenBlueBook(false);
                                      }}
                                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                                      {[
                                        {
                                          id: 1,
                                          label: 'Total High Price',
                                          price: blueBook.procedure_details.PricingInformations[0].TotalHighPrice1,
                                        },
                                        {
                                          id: 2,
                                          label: 'Total Line Price',
                                          price: blueBook.procedure_details.PricingInformations[0].TotalLine1,
                                        },
                                        {
                                          id: 3,
                                          label: 'Total Low Price',
                                          price: blueBook.procedure_details.PricingInformations[0].TotalLowPrice1,
                                        },
                                      ].map(item => {
                                        return (
                                          <Option value={item.id} key={item.label}>
                                            {item.label + `(${item.price})`}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Col>
                                )}
                              </Row>
                            </React.Fragment>
                          );
                        })}
                    </Row>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
                  )}
                </Row>
              </Row>
            </SpinBox>
          </Row>
          <Row>
            <Button type="primary" size="large" className="green-btn button" onClick={onCancel}>
              Save changes
            </Button>
          </Row>
        </Row>
      </Modal>
    </>
  );
}
