import React, { Component } from 'react';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import Navigation from '../components/Navigation';
import Assignments from '../containers/Dashboard/Assignments';
import Reports from '../containers/Dashboard/Reports';
import Templates from '../containers/Templates';
import AddTemplate from '../containers/Templates/AddTemplate';
import EditTemplate from '../containers/Templates/EditTemplate';
import EditTemplateContent from '../containers/Templates/EditTemplate/EditTemplateContent.jsx';
import { connect } from 'react-redux';
import MyClients from '../containers/MyClients';
import AddClient from '../containers/MyClients/AddClient';
import EditClient from '../containers/MyClients/EditClient';
import { MyUsers } from '../containers/MyUsers';
import { MyUsersClient } from '../containers/MyUsers';
import AddUser from '../containers/MyUsers/AddUser';
import EditUser from '../containers/MyUsers/EditUser';
import Contacts from '../containers/Contacts';
import AddContact from '../containers/Contacts/AddContact';
import EditContact from '../containers/Contacts/EditContact';
import Claimants from '../containers/Claimants';
import AttorneyClaimants from '../containers/AttorneyClaimants';
import ViewMsaReports from '../containers/AttorneyClaimants/ViewMsaReports';
import ViewMsaReportsWithIdReport from 'containers/AttorneyClaimants/ViewMsaReportsWithIdReport';
import ReportChanges from '../containers/ReportChanges/ReportChanges';
import AddClaimant from '../containers/Claimants/AddClaimant';
import EditClaimant from '../containers/Claimants/EditClaimant';
import DetailedClaimant from '../containers/Claimants/DetailedClaimant';
import MSADemographic from '../containers/Reports/MSA/Demographic';
import MSAMedicalContent from '../containers/Reports/MSA/MedicalContent';
import MSAMedicareCost from '../containers/Reports/MSA/MedicareCost';
import MSAMedicareTotals from '../containers/Reports/MSA/MedicareTotals';
import MSANonMedicareCost from '../containers/Reports/MSA/NonMedicareCost';
import MSANonMedicareTotals from '../containers/Reports/MSA/NonMedicareTotals';
import MSASubmitCoverLetterTotals from '../containers/Reports/MSA/SubmitCoverLetter';
import MSAIntro from '../containers/Reports/MSA/Intro';
import MSAInjuryInfo from '../containers/Reports/MSA/InjuryInfo';
import MSASettlementInfo from '../containers/Reports/MSA/SettlementInfo';
import MSAProposedMedicare from '../containers/Reports/MSA/ProposedMedicare';
import MSAAnnuityInfo from '../containers/Reports/MSA/AnnuityInfo';
import MSACalculationMethod from '../containers/Reports/MSA/CalculationMethod';
import MSAFuturePrescription from '../containers/Reports/MSA/FuturePrescription';
import MSACalculationTable from '../containers/Reports/MSA/CalculationTable';
import MSAContactInfo from '../containers/Reports/MSA/ContactInfo';
import MSADocumentUpload from '../containers/Reports/MSA/DocumentUpload';
import MCPDemographic from '../containers/Reports/MCP/Demographic';
import MCPMedicalContent from '../containers/Reports/MCP/MedicalContent';
import MCPMedicareCost from '../containers/Reports/MCP/MedicareCost';
import MCPMedicareTotals from '../containers/Reports/MCP/MedicareTotals';
import MCPDocumentUpload from '../containers/Reports/MCP/DocumentUpload';

// IMPORTS VIEWS NEW REPORTS
// IMPORT UN DEMAND
import DEMAND_LETTERDemographic from 'containers/Reports/DEMAND_LETTER/Demographic';
import DEMAND_LETTERMedicareCost from 'containers/Reports/DEMAND_LETTER/MedicareCost';
import DEMAND_LETTERMedicareTotals from 'containers/Reports/DEMAND_LETTER/MedicareTotals';
import DEMAND_LETTERDocumentUpload from 'containers/Reports/DEMAND_LETTER/DocumentUpload';

// IMPORT BI DEMAND 1
import BI_DEMAND_1Demographic from 'containers/Reports/BI_DEMAND_1/Demographic';
import BI_DEMAND_1MedicareCost from '../containers/Reports/BI_DEMAND_1/MedicareCost';
import BI_DEMAND_1MedicareTotals from 'containers/Reports/BI_DEMAND_1/MedicareTotals';
import BI_DEMAND_1DocumentUpload from 'containers/Reports/BI_DEMAND_1/DocumentUpload';

// IMPORT BI DEMAND 2
import BI_DEMAND_2Demographic from 'containers/Reports/BI_DEMAND_2/Demographic';
import BI_DEMAND_2MedicareCost from '../containers/Reports/BI_DEMAND_2/MedicareCost';
import BI_DEMAND_2MedicareTotals from 'containers/Reports/BI_DEMAND_2/MedicareTotals';
import BI_DEMAND_2DocumentUpload from 'containers/Reports/BI_DEMAND_2/DocumentUpload';

// IMPORT EARLY INTERVENTION REPORT
import EARLY_INTERVENTIONMedicalContent from 'containers/Reports/EARLY_INTERVENTION/MedicalContent';
import EARLY_INTERVENTIONDocumentUpload from 'containers/Reports/EARLY_INTERVENTION/DocumentUpload';

// IMPORT LEGAL DEMAND TEMPLATE
import LEGAL_DEMAND_TEMPLATEMedicalContent from 'containers/Reports/LEGAL_DEMAND_TEMPLATE/MedicalContent';
import LEGAL_DEMAND_TEMPLATEMedicareCost from '../containers/Reports/LEGAL_DEMAND_TEMPLATE/MedicareCost';
import LEGAL_DEMAND_TEMPLATEMedicareTotals from 'containers/Reports/LEGAL_DEMAND_TEMPLATE/MedicareTotals';
import LEGAL_DEMAND_TEMPLATEDocumentUpload from 'containers/Reports/LEGAL_DEMAND_TEMPLATE/DocumentUpload';

// IMPORT MCP MSA
import MCPMSADemographicMCP from '../containers/Reports/MCP_MSA/MCPDemographic';
import MCPMSAMedicalContentMCP from '../containers/Reports/MCP_MSA/MCPMedicalContent';
import MCPMSAMedicareCostMCP from '../containers/Reports/MCP_MSA/MCPMedicareCost';
import MCPMSAMedicareTotalsMCP from '../containers/Reports/MCP_MSA/MCPMedicareTotals';
import MCPMSADocumentUploadMCP from '../containers/Reports/MCP_MSA/MCPDocumentUpload';
import MCPMSADemographicMSA from '../containers/Reports/MCP_MSA/MSADemographic';
import MCPMSAMedicalContentMSA from '../containers/Reports/MCP_MSA/MSAMedicalContent';
import MCPMSAMedicareCostMSA from '../containers/Reports/MCP_MSA/MSAMedicareCost';
import MCPMSAMedicareTotalsMSA from '../containers/Reports/MCP_MSA/MSAMedicareTotals';
import MCPMSANonMedicareCostMSA from '../containers/Reports/MCP_MSA/MSANonMedicareCost';
import MCPMSANonMedicareTotalsMSA from '../containers/Reports/MCP_MSA/MSANonMedicareTotals';
import MCPMSASubmitCoverLetterTotalsMSA from '../containers/Reports/MCP_MSA/MSASubmitCoverLetter';
import MCPMSAIntroMSA from '../containers/Reports/MCP_MSA/MSAIntro';
import MCPMSAInjuryInfoMSA from '../containers/Reports/MCP_MSA/MSAInjuryInfo';
import MCPMSASettlementInfoMSA from '../containers/Reports/MCP_MSA/MSASettlementInfo';
import MCPMSAProposedMedicareMSA from '../containers/Reports/MCP_MSA/MSAProposedMedicare';
import MCPMSAAnnuityInfoMSA from '../containers/Reports/MCP_MSA/MSAAnnuityInfo';
import MCPMSACalculationMethodMSA from '../containers/Reports/MCP_MSA/MSACalculationMethod';
import MCPMSAFuturePrescriptionMSA from '../containers/Reports/MCP_MSA/MSAFuturePrescription';
import MCPMSACalculationTableMSA from '../containers/Reports/MCP_MSA/MSACalculationTable';
import MCPMSAContactInfoMSA from '../containers/Reports/MCP_MSA/MSAContactInfo';
import MCPMSADocumentUploadMSA from '../containers/Reports/MCP_MSA/MSADocumentUpload';

// IMPORT LIFE CARE PLAN
import { DemographicLCP } from 'containers/Reports/LIFE_CARE_PLAN/DemographicLCP';
import { MedicalContentLCP } from 'containers/Reports/LIFE_CARE_PLAN/MedicalCostLCP';
import { MedicareTotalsLCP } from 'containers/Reports/LIFE_CARE_PLAN/MedicareTotalsLCP';
import { IntroductionLCP } from 'containers/Reports/LIFE_CARE_PLAN/IntroductionLCP';

// IMPORT MSA REDUCTION
import MSA_REDUCTION_MedicalReduction from '../containers/Reports/MSA_REDUCTION/MedicalReduction';
import MSA_REDUCTION_MedicareCost from '../containers/Reports/MSA_REDUCTION/MedicareCost';
import MSA_REDUCTION_MedicareTotals from '../containers/Reports/MSA_REDUCTION/MedicareTotals';
import MSA_REDUCTION_DocumentUpload from '../containers/Reports/MSA_REDUCTION/DocumentUpload';

// IMPORT MSA LETTER
import MSA_Letter from 'containers/Reports/MSA_LETTER/Letter';
import { ReportPreview } from 'containers/Reports/MSA_LETTER/ReportPreview';

// IMPORT TEMPLATE
import { TemplateLifeCarePlanCreate } from 'containers/Templates/TemplateLifeCarePlan/TemplateLifeCarePlanCreate';
import { TemplateLifeCarePlanTable } from 'containers/Templates/TemplateLifeCarePlan/TemplateLifeCarePlanTable';
import { TemplateLifeCarePlanColor } from 'containers/Templates/TemplateLifeCarePlan/TemplateLifeCarePlanColor';

// IMPORT GENERAL PAGE REPORT
import { DesingReportPage } from 'containers/Reports/GeneralPage/DesingReportPage';

// FINISHED SECTION VIEWS NEW REPORTS

// Parent Folder
import { ParentFolderPage } from '../containers/ParentFolderPage';
import { CreateParentFolder } from 'containers/ParentFolderPage/CreateParentFolderPage';
import { EditParentFolder } from 'containers/ParentFolderPage/EditParentFolder';
// end Parent Folder

// start Litigation Attorney Page
import { LitigationAttorneyPage } from 'containers/LitigationAttorneyPage';
// end Litigation Attorney Page

import UploadCode from '../containers/UploadCode';
import CompanyProfile from 'containers/Settings/Settings.jsx';
import ResourceLibrary from 'containers/ResourceLibrary/index.jsx';
import Reviews from 'containers/Reviews/index.jsx';
import { ReportCompare } from 'containers/Reviews/ReportCompare';
import ReportView from 'containers/Reviews/ReportView.jsx';
import ResourceLink from 'containers/ResourceLibrary/ResourceLink';
import './CustomLayout.scss';
import '../containers/Calendar/src/src/App.css';
import CalendarAssign from 'containers/Calendar/src/src/containers/pages/AssignmentPage/index.js';
import NurseDashboard from '../containers/Dashboard/Dashboard/NurseDashboard';
import Lottie from 'react-lottie';
import * as animationData from './21474-medical-frontliners.json';
import Route from './RouteWrapper';
import { ROLES_DIVISIONS } from './roles';
import SurgicalRoutes from './SurgicalRoutes';
import PricingPlan from 'containers/Pricing/PricingPlan';
import AddPricingPLan from 'containers/Pricing/AddPricingPlan/AddPricing';
import PricingGroups from 'containers/Pricing/PricingGroups/PricingGroups';
import Search from 'containers/Search';
// import Help from '../containers/Help/Help';
// import HelpConstruction from '../containers/Help/HelpConstruction';
import HelpNew from 'containers/Help/HelpNew';
import Feedback from '../containers/Help/Feedback';
import UpdateReportCabinet from 'containers/ReportCabinet/UpdateReportCabinet';
import Marketing from 'containers/Marketing';
import MergeClient from 'containers/MyClients/MergeClient';
import ReviewAttorney from 'containers/Reviews/ReviewAttorney';

// NAVIGATION ROUTES
import {
  MARKETING_NAV,
  HELP_NAV,
  SETTINGS_NAV,
  ATTORNEY_CLAIMANT_NAV,
  ATTORNEY_CLAIMANT_REPORT_NAV,
  ATTORNEY_IN_REVIEW_NAV,
  CLAIMANTS_BY_CLIENT_NAV,
  REPORT_COMPARE_NAV,
  CLAIMANT_DETAIL_NAV,
  CLAIMANT_INFO_NAV,
  TEMPLATE_CREATE_STRUCTURE_NAV,
  TEMPLATE_EDIT_STRUCTURE_NAV,
  TEMPLATE_CREATE_TABLE_NAV,
  TEMPLATE_NAV,
  TEMPLATE_EDIT_TABLE_NAV,
  MAIN_NAV,
  SEARCH_NAV,
  USER_EDIT_NAV,
  PARENT_FOLDER_NAV,
  PARENT_FOLDER_CREATE_NAV,
  PARENT_FOLDER_EDIT_NAV,
  CLIENTS_NAV,
  CLAIMANT_BY_USER_NAV,
  LITIGATION_ATTORNEY_NAV,
  TEMPLATE_CREATE_COLOR_NAV,
  TEMPLATE_EDIT_COLOR_NAV,
  CLAIMANT_EDIT,
  SUPPORT_NAV,
  NEW_LITIGATION_ATTORNEY_NAV,
} from './navigate_const';
import {
  REPORT_DEMAND_LETTER,
  REPORT_DEMAND_LETTER_PROJECTIONS,
  REPORT_SECTION_DEMOGRAPHIC,
  REPORT_SECTION_DESIGN,
  REPORT_SECTION_DOCUMENTUPLOAD,
  REPORT_SECTION_MEDICARECOST,
  REPORT_SECTION_MEDICARETOTALS,
} from 'constants/reports';
import { SupportPage } from 'containers/SupportPage';
import { LitigationAttorneyCreatePage } from 'containers/LitigationAttorneyCreatePage';

class CustomLayout extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    if (!this.props.areRolesFetched) {
      return <Lottie options={defaultOptions} height={600} width={600} isStopped={false} isPaused={false} />;
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Navigation {...this.props} />
        <Layout className="max_width_content_app">
          {!this.props.isActiveReviewer ? (
            <Switch>
              <Route path={SETTINGS_NAV.path} roles={ROLES_DIVISIONS.ALL} exact component={CompanyProfile} />
              <Route path="*" roles={ROLES_DIVISIONS.ALL} component={() => <Redirect to={SETTINGS_NAV.path} />} />
            </Switch>
          ) : (
            <Switch>
              <Route path={SETTINGS_NAV.path} roles={ROLES_DIVISIONS.ALL} exact component={CompanyProfile} />

              <Route roles={ROLES_DIVISIONS.ALL} path={MAIN_NAV.path} exact component={NurseDashboard} />

              <Route roles={ROLES_DIVISIONS.ALL} path="/app/assignments" exact component={Assignments} />
              <Route roles={ROLES_DIVISIONS.ALL} path="/app/reports" exact component={Reports} />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.NURSE_ONLY,
                ]}
                path={TEMPLATE_NAV.path}
                exact
                component={Templates}
              />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.EXTERNAL_AR,
                ]}
                path="/app/templates/add"
                exact
                component={AddTemplate}
              />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.EXTERNAL_AR,
                ]}
                path="/app/templates/edit"
                exact
                component={EditTemplate}
              />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.EXTERNAL_AR,
                ]}
                path="/app/templates/edit/section"
                exact
                component={EditTemplateContent}
              />
              <Route
                path="/app/Assign"
                roles={[
                  ...ROLES_DIVISIONS.INTERNAL,
                  ...ROLES_DIVISIONS.QA_ONLY,
                  ...ROLES_DIVISIONS.EXTERNAL_QA,
                  ...ROLES_DIVISIONS.EXTERNAL_AR,
                  ...ROLES_DIVISIONS.MSA_MEDICAL_REVIEWER,
                  ...ROLES_DIVISIONS.EXTERNAL_ONLY,
                ]}
                exact
                component={CalendarAssign}
              />

              <Route roles={ROLES_DIVISIONS.ALL} path={CLIENTS_NAV.path} exact component={MyClients} />
              <Route roles={ROLES_DIVISIONS.SUPERADMIN} path="/app/clients/merge" exact component={MergeClient} />
              <Route roles={ROLES_DIVISIONS.INTERNAL_NURSE} path="/app/clients/edit" exact component={EditClient} />
              <Route
                roles={ROLES_DIVISIONS.ALL}
                path={`${CLIENTS_NAV.path}/:parentFolderId`}
                exact
                component={MyClients}
              />
              <Route
                roles={ROLES_DIVISIONS.INTERNAL_NURSE}
                path="/app/clients/:parentFolderId/add"
                exact
                component={AddClient}
              />

              <Route roles={ROLES_DIVISIONS.MANAGEMENT} path="/app/users" exact component={MyUsers} />
              <Route
                roles={[...ROLES_DIVISIONS.CLIENT_ADMIN, ...ROLES_DIVISIONS.CLIENT_SUPER_ADMIN]}
                path="/app/users-client"
                exact
                component={MyUsersClient}
              />
              <Route roles={ROLES_DIVISIONS.SUPER_ADMIN_CLIENT_ADMIN} path="/app/users/add" exact component={AddUser} />
              <Route
                roles={ROLES_DIVISIONS.SUPER_ADMIN_CLIENT_ADMIN}
                path={`${USER_EDIT_NAV.path}/:idUser`}
                exact
                component={EditUser}
              />
              <Route
                roles={ROLES_DIVISIONS.SUPER_ADMIN_CLIENT_ADMIN}
                path={USER_EDIT_NAV.path}
                exact
                component={EditUser}
              />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.NURSE_ONLY,
                ]}
                path="/app/contacts"
                exact
                component={Contacts}
              />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.NURSE_ONLY,
                ]}
                path="/app/contacts/add"
                exact
                component={AddContact}
              />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.NURSE_ONLY,
                ]}
                path="/app/contacts/edit"
                exact
                component={EditContact}
              />
              <Route roles={ROLES_DIVISIONS.ALL} path="/app/claimants" exact component={Claimants} />
              <Route
                roles={ROLES_DIVISIONS.ATTORNEY_ONLY}
                path={ATTORNEY_CLAIMANT_NAV.path}
                exact
                component={AttorneyClaimants}
              />
              <Route
                roles={ROLES_DIVISIONS.ATTORNEY_ONLY}
                path={ATTORNEY_CLAIMANT_REPORT_NAV.path}
                exact
                component={ViewMsaReports}
              />
              <Route
                roles={ROLES_DIVISIONS.ATTORNEY_ONLY}
                path={`${ATTORNEY_CLAIMANT_REPORT_NAV.path}/:idClaimant`}
                exact
                component={ViewMsaReports}
              />
              <Route
                roles={ROLES_DIVISIONS.ATTORNEY_ONLY}
                path={`${ATTORNEY_CLAIMANT_REPORT_NAV.path}/:idReport`}
                exact
                component={ViewMsaReportsWithIdReport}
              />
              <Route roles={ROLES_DIVISIONS.ALL} path="/app/reportChanges" exact component={ReportChanges} />
              <Route
                roles={ROLES_DIVISIONS.ALL}
                path={`${CLAIMANTS_BY_CLIENT_NAV().path}`}
                exact
                component={Claimants}
              />
              <Route
                roles={[...ROLES_DIVISIONS.EXTERNAL_ONLY, ...ROLES_DIVISIONS.ATTORNEY_ONLY]}
                path={CLAIMANT_BY_USER_NAV().path}
                exact
                component={Claimants}
              />

              <Route roles={ROLES_DIVISIONS.ALL} path="/app/claimants/add" exact component={AddClaimant} />
              <Route roles={ROLES_DIVISIONS.ALL} path={CLAIMANT_EDIT.path} exact component={EditClaimant} />
              <Route roles={ROLES_DIVISIONS.ALL} path={CLAIMANT_DETAIL_NAV.path} exact component={DetailedClaimant} />
              <Route
                roles={ROLES_DIVISIONS.ALL}
                path={`${CLAIMANT_INFO_NAV.path}/:id`}
                exact
                component={DetailedClaimant}
              />
              <Route
                roles={ROLES_DIVISIONS.ALL}
                path="/app/reports/:id/msa/demographic"
                exact
                component={MSADemographic}
              />
              {SurgicalRoutes.map((e, index) => (
                <Route
                  key={`surgical-${index}`}
                  roles={ROLES_DIVISIONS.ALL}
                  path={e.path}
                  exact
                  component={e.component}
                />
              ))}
              <Route
                path="/app/reports/:id/msa/medicalContent"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAMedicalContent}
              />
              <Route
                path="/app/reports/:id/msa/medicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAMedicareCost}
              />
              <Route
                path="/app/reports/:id/msa/medicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAMedicareTotals}
              />
              <Route
                path="/app/reports/:id/msa/nonMedicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSANonMedicareCost}
              />
              <Route
                path="/app/reports/:id/msa/nonMedicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSANonMedicareTotals}
              />
              <Route
                path="/app/reports/:id/msa/submitCoverLetter"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSASubmitCoverLetterTotals}
              />
              <Route path="/app/reports/:id/msa/intro" roles={ROLES_DIVISIONS.ALL} exact component={MSAIntro} />
              <Route
                path="/app/reports/:id/msa/injuryInfo"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAInjuryInfo}
              />
              <Route
                path="/app/reports/:id/msa/settlementInfo"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSASettlementInfo}
              />
              <Route
                path="/app/reports/:id/msa/proposedMedicare"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAProposedMedicare}
              />
              <Route
                path="/app/reports/:id/msa/annuityInfo"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAAnnuityInfo}
              />
              <Route
                path="/app/reports/:id/msa/calculationMethod"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSACalculationMethod}
              />
              <Route
                path="/app/reports/:id/msa/futurePrescription"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAFuturePrescription}
              />
              <Route
                path="/app/reports/:id/msa/calculationTable"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSACalculationTable}
              />
              <Route
                path="/app/reports/:id/msa/contactInfo"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSAContactInfo}
              />
              <Route
                path="/app/reports/:id/msa/documentUpload"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSADocumentUpload}
              />
              <Route
                path="/app/reports/:id/msa/design"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              <Route
                path="/app/reports/:id/mcp/demographic"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPDemographic}
              />
              <Route
                path="/app/reports/:id/mcp/medicalContent"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMedicalContent}
              />
              <Route
                path="/app/reports/:id/mcp/medicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMedicareCost}
              />
              <Route
                path="/app/reports/:id/mcp/medicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMedicareTotals}
              />
              <Route
                path="/app/reports/:id/mcp/documentUpload"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPDocumentUpload}
              />
              <Route
                path="/app/reports/:id/mcp/design"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              {/* PARENT FOLDER */}
              <Route path={PARENT_FOLDER_NAV.path} roles={ROLES_DIVISIONS.ALL} exact component={ParentFolderPage} />
              <Route
                path={PARENT_FOLDER_CREATE_NAV.path}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={CreateParentFolder}
              />
              <Route
                path={PARENT_FOLDER_EDIT_NAV().path}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={EditParentFolder}
              />

              {/* NEW ROUTES FOR NEW REPORTS */}
              {/* ROUTES FOR REPORT DEMAND LETTER */}
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER}/${REPORT_SECTION_DEMOGRAPHIC}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DEMAND_LETTERDemographic}
              />
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER}/${REPORT_SECTION_DOCUMENTUPLOAD}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DEMAND_LETTERDocumentUpload}
              />
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER}/${REPORT_SECTION_DESIGN}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              {/* ROUTES FOR REPORT DEMAND LETTER PROJECTIONS */}
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER_PROJECTIONS}/${REPORT_SECTION_DEMOGRAPHIC}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DEMAND_LETTERDemographic}
              />
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER_PROJECTIONS}/${REPORT_SECTION_MEDICARECOST}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DEMAND_LETTERMedicareCost}
              />
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER_PROJECTIONS}/${REPORT_SECTION_MEDICARETOTALS}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DEMAND_LETTERMedicareTotals}
              />
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER_PROJECTIONS}/${REPORT_SECTION_DOCUMENTUPLOAD}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DEMAND_LETTERDocumentUpload}
              />
              <Route
                path={`/app/reports/:id/${REPORT_DEMAND_LETTER_PROJECTIONS}/${REPORT_SECTION_DESIGN}`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              {/* ROUTES FOR REPORT BI DEMAND 1 */}
              <Route
                path="/app/reports/:id/bi_demand_1/medicalContent"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_1Demographic}
              />
              <Route
                path="/app/reports/:id/bi_demand_1/medicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_1MedicareCost}
              />
              <Route
                path="/app/reports/:id/bi_demand_1/medicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_1MedicareTotals}
              />
              <Route
                path="/app/reports/:id/bi_demand_1/documentUpload"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_1DocumentUpload}
              />

              {/* ROUTES FOR REPORT BI DEMAND 2 */}
              <Route
                path="/app/reports/:id/bi_demand_2/demographic"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_2Demographic}
              />
              <Route
                path="/app/reports/:id/bi_demand_2/medicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_2MedicareCost}
              />
              <Route
                path="/app/reports/:id/bi_demand_2/medicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_2MedicareTotals}
              />
              <Route
                path="/app/reports/:id/bi_demand_2/documentUpload"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={BI_DEMAND_2DocumentUpload}
              />

              {/* ROUTES FOR REPORT EARLY INTERVENTION REPORT */}
              <Route
                path="/app/reports/:id/early_intervention/medicalContent"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={EARLY_INTERVENTIONMedicalContent}
              />
              <Route
                path="/app/reports/:id/early_intervention/documentUpload"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={EARLY_INTERVENTIONDocumentUpload}
              />
              <Route
                path="/app/reports/:id/early_intervention/design"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              {/* ROUTES FOR REPORT LEGAL DEMAND TEMPLATE */}
              <Route
                path="/app/reports/:id/legal_demand_template/medicalContent"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={LEGAL_DEMAND_TEMPLATEMedicalContent}
              />
              <Route
                path="/app/reports/:id/legal_demand_template/medicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={LEGAL_DEMAND_TEMPLATEMedicareCost}
              />
              <Route
                path="/app/reports/:id/legal_demand_template/medicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={LEGAL_DEMAND_TEMPLATEMedicareTotals}
              />
              <Route
                path="/app/reports/:id/legal_demand_template/documentUpload"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={LEGAL_DEMAND_TEMPLATEDocumentUpload}
              />
              <Route
                path="/app/reports/:id/legal_demand_template/design"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              {/* ROUTES BY REPORT LIFE CARE PLAN */}
              <Route
                path="/app/reports/:id/life_care_plan/demographic"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DemographicLCP}
              />
              <Route
                path="/app/reports/:id/life_care_plan/introduction"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={IntroductionLCP}
              />
              <Route
                path="/app/reports/:id/life_care_plan/medicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MedicalContentLCP}
              />
              <Route
                path="/app/reports/:id/life_care_plan/medicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MedicareTotalsLCP}
              />
              <Route
                path="/app/reports/:id/life_care_plan/design"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              {/* ROUTES FOR REPORT MCP_MSA */}
              <Route
                path="/app/reports/:id/mcp_msa/demographicMCP"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSADemographicMCP}
              />
              <Route
                path="/app/reports/:id/mcp_msa/medicalContentMCP"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAMedicalContentMCP}
              />
              <Route
                path="/app/reports/:id/mcp_msa/medicareCostMCP"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAMedicareCostMCP}
              />
              <Route
                path="/app/reports/:id/mcp_msa/medicareTotalsMCP"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAMedicareTotalsMCP}
              />
              <Route
                path="/app/reports/:id/mcp_msa/documentUploadMCP"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSADocumentUploadMCP}
              />
              <Route
                roles={ROLES_DIVISIONS.ALL}
                path="/app/reports/:id/mcp_msa/demographicMSA"
                exact
                component={MCPMSADemographicMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/medicalContentMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAMedicalContentMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/medicareCostMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAMedicareCostMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/medicareTotalsMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAMedicareTotalsMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/nonMedicareCostMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSANonMedicareCostMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/nonMedicareTotalsMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSANonMedicareTotalsMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/submitCoverLetterMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSASubmitCoverLetterTotalsMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/introMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAIntroMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/injuryInfoMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAInjuryInfoMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/settlementInfoMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSASettlementInfoMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/proposedMedicareMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAProposedMedicareMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/annuityInfoMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAAnnuityInfoMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/calculationMethodMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSACalculationMethodMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/futurePrescriptionMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAFuturePrescriptionMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/calculationTableMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSACalculationTableMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/contactInfoMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSAContactInfoMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/documentUploadMSA"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MCPMSADocumentUploadMSA}
              />
              <Route
                path="/app/reports/:id/mcp_msa/design"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={DesingReportPage}
              />

              {/* ROUTES FOR REPORT REDUCTION */}
              <Route
                path="/app/reports/:id/msa_reduction/medicalReduction"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSA_REDUCTION_MedicalReduction}
              />
              <Route
                path="/app/reports/:id/msa_reduction/medicareCost"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSA_REDUCTION_MedicareCost}
              />
              <Route
                path="/app/reports/:id/msa_reduction/medicareTotals"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSA_REDUCTION_MedicareTotals}
              />
              <Route
                path="/app/reports/:id/msa_reduction/documentUpload"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSA_REDUCTION_DocumentUpload}
              />

              {/* ROUTES FOR MSA LETTER */}
              <Route
                path="/app/reports/:id/msa_letter/letter"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={MSA_Letter}
              />
              <Route
                path="/app/reports/:id/msa_letter/preview"
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={ReportPreview}
              />

              {/* TEMPLATE */}
              <Route
                path={`${TEMPLATE_CREATE_STRUCTURE_NAV.path}/:idReport`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={TemplateLifeCarePlanCreate}
              />

              <Route
                path={`${TEMPLATE_EDIT_STRUCTURE_NAV.path}/:idReport`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={TemplateLifeCarePlanCreate}
              />

              <Route
                path={`${TEMPLATE_CREATE_TABLE_NAV.path}/:idReport`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={TemplateLifeCarePlanTable}
              />

              <Route
                path={`${TEMPLATE_EDIT_TABLE_NAV.path}/:idReport`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={TemplateLifeCarePlanTable}
              />

              <Route
                path={`${TEMPLATE_CREATE_COLOR_NAV.path}/:idReport`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={TemplateLifeCarePlanColor}
              />

              <Route
                path={`${TEMPLATE_EDIT_COLOR_NAV.path}/:idReport`}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={TemplateLifeCarePlanColor}
              />

              {/* FINAL NEW ROUTES */}

              <Route path="/app/uploadCode" roles={ROLES_DIVISIONS.MANAGEMENT} exact component={UploadCode} />
              <Route path="/app/ResourceLibrary" exact roles={ROLES_DIVISIONS.ALL} component={ResourceLibrary} />
              <Route
                roles={[
                  ...ROLES_DIVISIONS.MANAGEMENT,
                  ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
                  ...ROLES_DIVISIONS.EXTERNAL_AR,
                ]}
                path="/app/ResourceLibrary/AddLink"
                exact
                component={ResourceLink}
              />

              <Route
                path="/app/reviews"
                roles={[
                  ...ROLES_DIVISIONS.INTERNAL,
                  ...ROLES_DIVISIONS.QA_ONLY,
                  ...ROLES_DIVISIONS.EXTERNAL_QA,
                  ...ROLES_DIVISIONS.EXTERNAL_AR,
                  ...ROLES_DIVISIONS.MSA_MEDICAL_REVIEWER,
                  ...ROLES_DIVISIONS.ATTORNEY_ONLY,
                ]}
                exact
                component={Reviews}
              />
              <Route
                path={`${REPORT_COMPARE_NAV.path}/:idReport`}
                roles={ROLES_DIVISIONS.NURSE_ONLY}
                exact
                component={ReportCompare}
              />
              <Route
                path={ATTORNEY_IN_REVIEW_NAV.path}
                roles={ROLES_DIVISIONS.ATTORNEY_ONLY}
                exact
                component={ReviewAttorney}
              />
              <Route
                path="/app/reviews/assignment/:id"
                roles={[
                  ...ROLES_DIVISIONS.QA_ONLY,
                  ...ROLES_DIVISIONS.EXTERNAL_QA,
                  ...ROLES_DIVISIONS.EXTERNAL_AR,
                  ...ROLES_DIVISIONS.MSA_MEDICAL_REVIEWER,
                  ...ROLES_DIVISIONS.INTERNAL,
                ]}
                exact
                component={ReportView}
              />

              {/* LITIGATION ATTORNEY */}
              <Route
                path={LITIGATION_ATTORNEY_NAV.path}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={LitigationAttorneyPage}
              />

              <Route
                path={NEW_LITIGATION_ATTORNEY_NAV.path}
                roles={ROLES_DIVISIONS.ALL}
                exact
                component={LitigationAttorneyCreatePage}
              />

              <Route path="/app/uploadCode" exact component={UploadCode} />

              <Route
                path="/app/pricing/groups"
                roles={ROLES_DIVISIONS.INTERNAL_NURSE}
                exact
                component={PricingGroups}
              />
              <Route path="/app/pricing/:id" roles={ROLES_DIVISIONS.INTERNAL_NURSE} exact component={PricingPlan} />
              <Route
                path="/app/pricing/add/:id"
                roles={ROLES_DIVISIONS.INTERNAL_NURSE}
                exact
                component={AddPricingPLan}
              />
              <Route path={SEARCH_NAV.path} roles={ROLES_DIVISIONS.ALL} exact component={Search} />
              <Route path={HELP_NAV.path} roles={ROLES_DIVISIONS.ALL} exact component={HelpNew} />
              <Route path={SUPPORT_NAV.path} roles={ROLES_DIVISIONS.ALL} exact component={SupportPage} />
              <Route path={`${HELP_NAV.path}/feedback`} roles={ROLES_DIVISIONS.ALL} exact component={Feedback} />
              <Route path="/app/reportcabinet/update" exact component={UpdateReportCabinet} />

              <Route path={MARKETING_NAV.path} roles={ROLES_DIVISIONS.ALL} exact component={Marketing} />
            </Switch>
          )}
        </Layout>
      </Layout>
    );
  }
}
const mapStateToProps = state => {
  const props = {
    areRolesFetched: state.auth.areRolesFetched,
    isActiveReviewer: state.auth.isActiveReviewer,
  };
  return props;
};

export default connect(mapStateToProps, {})(withRouter(CustomLayout));
