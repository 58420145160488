export const WC = 'wc';
export const UCR = 'ucr';
export const MEDICARE_ALLOWED = 'medicare_allowed';
export const MEDICARE_BILLED = 'medicare_billed';
export const OWCP = 'owcp';

export const FINDACODE_FEES = [
  {
    label: 'WC FEE',
    value: WC,
    key: WC,
  },
  {
    label: 'UCR FEE',
    value: UCR,
    key: UCR,
  },
  {
    label: 'MEDICARE ALLOWED',
    value: MEDICARE_ALLOWED,
    key: MEDICARE_ALLOWED,
  },
  {
    label: 'MEDICARE BILLED',
    value: MEDICARE_BILLED,
    key: MEDICARE_BILLED,
  },
];

export const OWCP_FEES = [
  {
    label: 'OWCP RATE',
    value: OWCP,
    key: OWCP,
  },
];

export const PERCENTAGES = [
  {
    label: '80%',
    value: 0.8,
  },
  {
    label: '100%',
    value: 1,
  },
  {
    label: '120%',
    value: 1.2,
  },
];
