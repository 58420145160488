import React from 'react';
import { Modal, Typography, Button } from 'antd';
import './ModalReturnToSender.scss';

const { Text } = Typography;

const ModalReturnToSender = ({ onClose, returnName }) => (
  <Modal
    visible={true}
    onCancel={onClose}
    footer={null}
    centered={true}
    closable={true}
    width={550}
    className="report-modal">
    <div className="modalReturnToSender">
      <Text>The report has been sent back to</Text>
      <Text strong>{returnName}</Text>
      <Button type="primary" size="large" className="m-2 green-btn button btn-normal" onClick={onClose}>
        Ok
      </Button>
    </div>
  </Modal>
);

export default ModalReturnToSender;
