import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { ElipsisIcon, TrashIcon } from 'components/Svg';
import { PRIMARY_BORDER_GREY, PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { TemplateLifeCarePlanFields } from '../TemplateLifeCarePlanFields';

const TemplateLifeCarePlanFieldOnly = ({
  dataStructure,
  index,
  handleSaveFieldSection,
  onDelete,
  listReportType,
}) => {
  return (
    <Draggable draggableId={`${dataStructure.id}s`} index={index}>
      {provided => (
        <div
          className="lifeCarePlanTemplate_structure_box_content"
          ref={provided.innerRef}
          {...provided.draggableProps}>
          <div className="lifeCarePlanTemplate_structure_box_content_title">
            <div
              {...provided.dragHandleProps}
              className="lifeCarePlanTemplate_structure_box_content_title_drag lifeCarePlanTemplate_only_drag_100">
              <ElipsisIcon color={PRIMARY_BORDER_GREY} />
              <div
                className={`lifeCarePlanTemplate_structure_box_content_droppable lifeCarePlanTemplate_only_droppable_100`}>
                {dataStructure.template_fields.length > 0 && (
                  <>
                    {dataStructure.template_fields.map((field, index) => (
                      <React.Fragment key={field.id}>
                        <TemplateLifeCarePlanFields
                          onlyField
                          field={field}
                          onSave={({ fieldEdit }) => handleSaveFieldSection({ fieldEdit, index })}
                          index={index}
                          listReportType={listReportType}
                        />
                      </React.Fragment>
                    ))}
                  </>
                )}
              </div>
              <TrashIcon
                className="lifeCarePlanTemplate_only_deleteIcon"
                color={PRIMARY_LIGHT_BLUE}
                onClick={() => onDelete({ idStructure: dataStructure.id })}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TemplateLifeCarePlanFieldOnly;
