//feedback is chat with isFeedback flag
import {
  HELP_CREATE_FEEDBACK,
  HELP_FEEDBACK_NEW_MESSAGE,
  HELP_GET_FEEDBACK,
  HELP_LOADING_END,
  HELP_LOADING_START
} from "./actionTypes";
import {normalize, schema} from 'normalizr';

export const userSchema = new schema.Entity('users');
export const messageSchema = new schema.Entity('messages', {
  user: userSchema,
});
const chatUsersSchema = {user: userSchema};
export const chatSchema = new schema.Entity('chats', {
  chat_users: [chatUsersSchema],
  user: userSchema,
  messages: [messageSchema],
});
export const messageListSchema = [messageSchema];
export const chatListSchema = [chatSchema];

const initialState = {
  isLoading: true,
  pending: false,
  entities: {chats: {}, users: {}, messages: {}}, //message sending
  chats: [],
  chat: null,
};


const help = (state = initialState, action) => {
  switch (action.type) {
    case HELP_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };

    case HELP_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };

    case HELP_GET_FEEDBACK: {
      const normalizedData = normalize(action.payload.rows, chatListSchema);

      return {
        ...state,
        entities: {...normalizedData.entities},
        chats: normalizedData.result,
      };
    }

    case HELP_CREATE_FEEDBACK: {
      const body = action.payload;
      const newChatNormalized = normalize(body, chatSchema);
      newChatNormalized.entities.chats[body.id].user = body.user_id;
      newChatNormalized.entities.chats[body.id].messages = [];
      return {
        ...state,
        chats: [newChatNormalized.result, ...state.chats],
        entities: {
          ...state.entities,
          chats: {...state.entities.chats, ...newChatNormalized.entities.chats},
        },
      };
    }

    case HELP_FEEDBACK_NEW_MESSAGE: {
      const {type, body} = action.payload;
      switch (type) {
        case 'FEEDBACK_NEW_MESSAGE':
          if (!body.chat_id || !body.id || !body.description || !body.createdAt || !body.user_id) {
            //check required fields
            return {
              ...state,
            };
          }
          const newMessageNormalized = normalize(body, messageSchema);
          const targetChat = {...state.entities.chats[body.chat_id]};
          if (!Object.keys(targetChat).length) {
            return {...state};
          }
          targetChat.messages.unshift(newMessageNormalized.result);
          //add userId for replay message schema as from API
          newMessageNormalized.entities.messages[body.id].user = body.user_id;
          return {
            ...state,
            entities: {
              ...state.entities,
              messages: {...state.entities.messages, ...newMessageNormalized.entities.messages},
              users: {...state.entities.users, ...newMessageNormalized.entities.users},
              chats: {...state.entities.chats, [body.chat_id]: targetChat},
            },
          };

        default:
          return state;
      }

    }

    default:
      return state;
  }
};

export default help;
