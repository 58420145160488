import React, { useState, useEffect } from 'react';

// Components
import { Divider, Button } from 'antd';
import InputBox from 'components/InputBox';
import UploadLogo from 'components/UploadLogo/UploadLogo';
import { CustomCheckBox } from 'components/CustomCheckBox';

// Const
import { CHILD_FOLDER, PARENT_FOLDER } from 'constants/typeFolder';
import { notifyError } from 'utils/notification';

const StepFolderInfo = ({
  onClick,
  dataGlobal = {},
  onSelectTypeFolder,
  hidenTypeFolder,
  noneTypeFolder,
  textBtnCrate = 'Next Step',
}) => {
  // State
  const [client_logo, setClient_logo] = useState('');
  const [s3Key, setS3Key] = useState('');
  const [clientName, setClientName] = useState('');
  const [typeFolder, setTypeFolder] = useState('');

  // Handlers
  useEffect(() => {
    if (Object.keys(dataGlobal).length === 0) return;

    setClient_logo(dataGlobal?.client_logo || '');
    setS3Key(dataGlobal?.s3Key || '');
    setClientName(dataGlobal?.clientName || '');
    setTypeFolder(dataGlobal?.typeFolder || '');
  }, [dataGlobal]);

  const handleChangeLogo = ({ client_logo, s3key }) => {
    setClient_logo(client_logo);
    setS3Key(s3key);
  };

  const onSubmit = () => {
    if (!clientName) return notifyError('Folder name is required')
    if (!noneTypeFolder && !typeFolder) return notifyError('Type folder is required')

    onClick({ data: { clientName, s3Key, client_logo, typeFolder } });
  };

  const handleSelected = ({ selected }) => {
    setTypeFolder(selected);
  };

  return (
    <>
      <div className="createParentFolder_folderInfo">
        <div className="logo">
          <UploadLogo
            changeImage={(client_logo, s3key) => {
              handleChangeLogo({ client_logo, s3key });
            }}
            client_logo={client_logo}
          />
          {client_logo && (
            <div
              onClick={() => {
                setClient_logo('');
                setS3Key('');
              }}
              className="remove_logo_btn">
              Remove
            </div>
          )}
        </div>

        <div className="createParentFolder_folderInfo_inputs">
          {!hidenTypeFolder && (
            <div className="createParentFolder_folderInfo_options">
              <CustomCheckBox
                optionSignature={typeFolder}
                onChange={() => {
                  handleSelected({ selected: PARENT_FOLDER });
                  onSelectTypeFolder && onSelectTypeFolder({ type: PARENT_FOLDER });
                }}
                label="Master Folder"
                htmlId={PARENT_FOLDER}
                value={PARENT_FOLDER}
              />
              <CustomCheckBox
                optionSignature={typeFolder}
                onChange={() => {
                  handleSelected({ selected: CHILD_FOLDER });
                  onSelectTypeFolder && onSelectTypeFolder({ type: CHILD_FOLDER });
                }}
                label="Main Folder"
                htmlId={CHILD_FOLDER}
                value={CHILD_FOLDER}
              />
            </div>
          )}
          <InputBox
            label="Folder Name"
            placeholder="Required"
            value={clientName}
            onChange={value => setClientName(value)}
          />
        </div>
      </div>
      <Divider />

      <div className="createParentFolder_btn">
        <Button type="primary" size="large" className="green-btn button" onClick={onSubmit}>
          {textBtnCrate}
        </Button>
      </div>
    </>
  );
};

export default StepFolderInfo;
