import React, { useState } from 'react';
import InputBox from 'components/InputBox';
import { Typography } from 'antd';
import { validatedFormatEmail } from 'utils/utils';
import './listInputsLCP.scss';

const { Text: TextComponent } = Typography;

const Text = ({ placeholder, label, type, value, onChange, disabled }) => {
  const [errorInput, setErrorInput] = useState(false);

  const handleChange = value => {
    if (type === 'email') {
      if (!validatedFormatEmail({ email: value }) && !errorInput) setErrorInput(true);

      if (validatedFormatEmail({ email: value }) && errorInput) setErrorInput(false);
    }

    onChange(value);
  };

  return (
    <div className={`listInputsLCP_input ${type === 'text' ? 'listInputsLCP_input_text' : ''}`}>
      <TextComponent>{label}</TextComponent>
      <InputBox disabled={disabled} classes={errorInput ? 'input_error' : ''} type={type} placeholder={placeholder} value={value} onChange={handleChange} />
    </div>
  );
};

export default Text;
