import React, { useEffect } from 'react';
import { Row, Col, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { getReportResourceLinks } from 'redux/report';

const { Text } = Typography;

export const ResourceLinkFiles = ({ reportId }) => {
  const { resourceLinksFiles } = useSelector(state => state.report);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportResourceLinks(reportId));
  }, [reportId, dispatch]);

  return resourceLinksFiles.length ? (
    <Row style={{ marginTop: 10 }}>
      <Text strong>Attached by default:</Text>
      {resourceLinksFiles.map(file => (
        <Row type="flex" justify="space-between" key={file.id}>
          <Col sm={22} className="item">
            <Tooltip title={file.name}>
              <div
                style={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={() => {
                  window.open(file.url, '_blank');
                }}>
                {file.name}
              </div>
            </Tooltip>
          </Col>
          <Col sm={2}>
            <Tooltip
              placement="right"
              title="You cannot remove this file as it is a part of included by default resource link">
              <QuestionCircleOutlined
                style={{
                  fontSize: 12,
                  marginTop: 7,
                  marginLeft: 10,
                  borderRadius: 360,
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      ))}
    </Row>
  ) : (
    <></>
  );
};
