import {
  NOTIFICATIONS_DATA_LOADING_END,
  NOTIFICATIONS_DATA_LOADING_START,
  NEW_NOTIFICATION,
  NOTIFICATIONS_LOADING_END,
  NOTIFICATIONS_LOADING_START,
  NOTIFICATIONS_GET_LIST,
  NOTIFICATIONS_GET_UNREAD_COUNT,
  NOTIFICATION_MARK_READ,
} from './actionTypes';
import { notifyApiError } from '../../utils/notification';
import { fetchNotifications, markNotificationsRead } from '../../utils/api-notifications';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import * as API from 'utils/api';

export const onLoadingAction = {
  type: NOTIFICATIONS_LOADING_START,
};

export const offLoadingAction = {
  type: NOTIFICATIONS_LOADING_END,
};

export const onDataLoadingAction = {
  type: NOTIFICATIONS_DATA_LOADING_START,
};

export const offDataLoadingAction = {
  type: NOTIFICATIONS_DATA_LOADING_END,
};

export const onNotificationMessageReceived = data => {
  return {
    type: NEW_NOTIFICATION,
    payload: data,
  };
};

export const getUnreadCount = () => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const data = await fetchNotifications({ unread: true, count: true });
    dispatch({ type: NOTIFICATIONS_GET_UNREAD_COUNT, payload: data?.count });
  } catch (e) {
    console.error(e);
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction);
  }
};

export const getNotifications =
  ({ queryData, typedispatch = NOTIFICATIONS_GET_LIST, role, userId }) =>
  async dispatch => {
    dispatch(onLoadingAction);
    try {
      const responses = await Promise.all([
        fetchNotifications(queryData),
        API.getNotificationManagement({ idUser: userId }),
      ]);

      let data = responses[0];
      const notifications = responses[1];

      // obtenemos las notificaciones desactivadas
      const notificationsDisabled = notifications
        .filter(item => ![null, 1].includes(item['allowed.is_allowed']))
        .map(item => item.id);

      // Filtramos las notificaciones desactivadas
      data.rows = data.rows.filter(item => !notificationsDisabled.includes(item.notification.notification_type_id));

      // sacamos la notificacion 17 de los roles de client
      if (isAllowed(role, ROLES_DIVISIONS.ALL_CLIENT))
        data.rows = data?.rows.filter(item => item.notification.notification_type_id !== 17);

      dispatch({ type: typedispatch, payload: data });
    } catch (e) {
      console.error(e);
      notifyApiError(e);
    } finally {
      dispatch(offLoadingAction);
    }
  };

export const markAsRead = () => async dispatch => {
  dispatch(onLoadingAction);
  try {
    await markNotificationsRead();
    dispatch({ type: NOTIFICATION_MARK_READ });
  } catch (e) {
    console.error(e);
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction);
  }
};
