import React, { useState, useEffect } from 'react';
import InputBox from 'components/InputBox';
import ReactInputMask from 'react-input-mask';
import { Col, Row, Typography } from 'antd';
import * as API from 'utils/api';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { validatePhone } from 'utils/utils';

const { Title, Text } = Typography;

const ChangeAddressAndPhoneReport = ({ reportId, phone, address, disabledField }) => {
  const [loading, setLoading] = useState(false);
  const [reportPhone, setReportPhone] = useState(phone || '');
  const [reportAddress, setReportAddress] = useState(address || '');

  useEffect(() => {
    setReportPhone(phone);
    setReportAddress(address);
  }, [phone, address])

  const handleUpdatePhoneAndAddress = async () => {
    try {
      setLoading(true);
      const body = {
        id: reportId,
        address: reportAddress,
      };

      if (validatePhone(reportPhone, true)) body.phone = reportPhone;

      await API.updateReportNurse(body);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ModalLoading />}
      <div className="templateLifeCarePlanTable_header">
        <Title level={4} className="title-text">
          Information
        </Title>
        <Row type="flex" align="top" className="row" gutter={[32, 32]}>
          <Col md={8}>
            <Text>Phone Number</Text>
            <ReactInputMask
              mask="999-999-9999"
              className={`input-ssn ${disabledField ? 'inputMask_desabled' : ''}`}
              placeholder="Phone Number"
              value={reportPhone}
              onChange={e => setReportPhone(e.target.value)}
              onBlur={handleUpdatePhoneAndAddress}
              disabled={disabledField}
            />
          </Col>
          <Col md={8}>
            <Text>Address</Text>
            <InputBox
              placeholder="Address"
              value={reportAddress}
              onChange={value => setReportAddress(value)}
              onBlur={handleUpdatePhoneAndAddress}
              disabled={disabledField}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChangeAddressAndPhoneReport;
