import React from 'react';

// Style
import './barStepSignUp.scss';

const BarStepSignUp = ({ step, handleChangeStep, listSteps }) => {
  return (
    <div className="batStepSignUp">
      {listSteps.map(stepItem => {
        const stepCompleted = step.number > stepItem.number;
        const stepActive = stepItem.number === step.number;

        return (
          <div
            key={stepItem.number}
            onClick={() => stepCompleted && handleChangeStep({ step: stepItem })}
            className={`batStepSignUp_step ${stepActive ? 'batStepSignUp_stepActive' : ''} ${stepCompleted ? 'batStepSignUp_stepComplete' : ''}`}>
            <div className="span">{stepItem.number}</div> {stepItem.name}
          </div>
        );
      })}
    </div>
  );
};

export default BarStepSignUp;
