import {
  HELP_CREATE_FEEDBACK,
  HELP_DATA_LOADING_END,
  HELP_DATA_LOADING_START, HELP_FEEDBACK_NEW_MESSAGE,
  HELP_GET_FEEDBACK,
  HELP_LOADING_END,
  HELP_LOADING_START
} from "./actionTypes";
import {createFeedback, fetchFeedback, sendReply} from "../../utils/api-feedback";
import {notifyApiError} from "../../utils/notification";

export const onLoadingAction = {
  type: HELP_LOADING_START,
};

export const offLoadingAction = {
  type: HELP_LOADING_END,
};

export const onDataLoadingAction = {
  type: HELP_DATA_LOADING_START,
};

export const offDataLoadingAction = {
  type: HELP_DATA_LOADING_END,
};

export const onFeedbackMessageReceived = data => ({
  type: HELP_FEEDBACK_NEW_MESSAGE,
  payload: data,
});

export const addFeedback = body => async dispatch => {
  dispatch(onDataLoadingAction);
  try {
    const data = await createFeedback(body);
    dispatch({type: HELP_CREATE_FEEDBACK, payload: data})
    return true;
  } catch (e) {
    console.error(e);
    notifyApiError(e);
    return false;
  } finally {
    dispatch(offDataLoadingAction)
  }

}

export const getFeedback = queryData => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const data = await fetchFeedback(queryData);
    dispatch({type: HELP_GET_FEEDBACK, payload: data})
  } catch (e) {
    console.error(e);
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction)
  }

}

export const reply = (id, description) => async dispatch => {
  try {
    dispatch(onDataLoadingAction);
    await sendReply(id, description);
    return true;
  } catch (e) {
    console.error(e);
    notifyApiError(e);
    return false;
  } finally {
    dispatch(offDataLoadingAction);
  }
};
