import React from 'react';
import { TemplateLifeCarePlanSection } from '../TemplateLifeCarePlanSection';
import { TemplateLifeCarePlanSectionTable } from '../TemplateLifeCarePlanSectionTable';

const TemplateLifeCarePlanStructure = ({ dataList, onDelete, onSave, listReportType, updateList }) => {
  return (
    <>
      {dataList.map((item, index) => (
        <React.Fragment key={item.id}>
          {
            item.alias ? (
              // si tiene alias es porque es una estructura de campos
              <TemplateLifeCarePlanSection item={item} index={index} onDelete={onDelete} onSave={onSave} listReportType={listReportType} />
            ): (
              // si no tiene alias es porque es una tabla
              <TemplateLifeCarePlanSectionTable item={item} index={index} updateList={updateList} />
            )
          }
        </React.Fragment>
      ))}
    </>
  );
};

export default TemplateLifeCarePlanStructure;
