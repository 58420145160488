

export const EXTERNAL_QA_ID = 3;
export const DISPLAY_ROLE_EXTERNAL_QA = 'MSA Quality Assurance';
export const ROLE_EXTERNAL_QA = 'QA';

export const EXTERNAL_AR_ID = 4;
export const DISPLAY_ROLE_EXTERNAL_AR = 'MSA Reduction Review';
export const ROLE_EXTERNAL_AR = 'AR';

export const EXTERNAL_MEDICAL_REVIEWER_MSA_ID = 5;
export const DISPLAY_ROLE_MEDICAL_REVIEWER_MSA = 'External RA';
export const ROLE_MEDICAL_REVIEWER_MSA = 'RA';

export const SUPER_ADMIN_ID = 6;
export const DISPLAY_ROLE_SUPER_ADMIN = 'Super Admin';
export const ROLE_SUPERADMIN = 'Nurse SuperAdmin';

export const MEDICAL_REVIEWER_ID = 7;
export const DISPLAY_ROLE_MEDICAL_REVIEWER = 'Medical Reviewer';
export const ROLE_MEDICAL_REVIEWER = 'Nurse Manager';

export const NURSE_WRITER_ID = 9;
export const DISPLAY_ROLE_NURSE_WRITER = 'Nurse Writer';
export const ROLE_NURSE_WRITER = 'Nurse';

export const GOLD_QA_ID = 10;
export const DISPLAY_ROLE_GOLD_QA = 'QA';
export const ROLE_GOLD_QA = 'QA';

export const INTERNAL_QA_ID = 11;
export const DISPLAY_ROLE_INTERNAL_QA = 'QA Attorney';
export const ROLE_QA_NURSE = 'QA Nurse';

export const CLIENT_USER_ID = 12;
export const DISPLAY_ROLE_CLIENT = 'Client User';
export const ROLE_CLIENT = 'Client user';

export const EXTERNAL_NURSE_ID = 13;
export const DISPLAY_ROLE_EXTERNAL_NURSE = 'External Nurse';
export const EXTERNAL_NURSE = 'External Nurse';

export const ATTORNEY_MANAGER_ID = 14;
export const DISPLAY_ROLE_ATTORNEY_MANAGER = 'Reduction Attorney';
export const ATTORNEY_MANAGER = 'Reduction Attorney';

export const INTERNAL_ATTORNEY_ID = 15;
export const DISPLAY_ROLE_INTERNAL_ATTORNEY = 'Internal Attorney';
export const INTERNAL_ATTORNEY = 'Internal Attorney';

export const EXTERNAL_LITIGATION_ATTORNEY_ID = 16;
export const DISPLAY_ROLE_EXTERNAL_LITIGATION_ATTORNEY = 'External Litigation Attorney';
export const ROLE_EXTERNAL_LITIGATION_ATTORNEY = 'External Attorney';

export const CLIENT_ADMIN_ID = 17;
export const DISPLAY_ROLE_CLIENT_ADMIN = 'Client Admin';
export const ROLE_CLIENT_ADMIN = 'Client Admin';

export const SALES_MANAGER_ID = 18;
export const DISPLAY_ROLE_SALES_MANAGER = 'Sales Manager';
export const ROLE_SALES_MANAGER = 'Sales Manager';

export const SALES_REP_ID = 19;
export const DISPLAY_ROLE_SALES_REP = 'Sales Rep';
export const ROLE_SALES_REP = 'Sales Rep';

export const MEDICAL_ADMIN_ID = 20;
export const DISPLAY_ROLE_MEDICAL_ADMIN = 'Medical Admin';
export const ROLE_MEDICAL_ADMIN = 'Medical Admin';

export const CLIENT_SUPER_ADMIN_ID = 21;
export const DISPLAY_ROLE_CLIENT_SUPER_ADMIN = 'Client Super Admin';
export const ROLE_CLIENT_SUPER_ADMIN = 'Client Super Admin';

export const DIRECTOR_OF_SALES_ID = 22;
export const DISPLAY_ROLE_DIRECTOR_OF_SALES = 'Director of Sales';
export const ROLE_DIRECTOR_OF_SALES = 'Director of Sales';

export const MSA_MEDICAL_REVIEWER_ID = 23;
export const DISPLAY_ROLE_MSA_MEDICAL_REVIEWER = 'MSA Medical Reviewer';
export const ROLE_MSA_MEDICAL_REVIEWER = 'Tech MR';

export const ROLE_NAME_BY_ID = {
  [CLIENT_USER_ID]: 'Client',
  [CLIENT_ADMIN_ID]: ROLE_CLIENT_ADMIN,
  [CLIENT_SUPER_ADMIN_ID]: ROLE_CLIENT_SUPER_ADMIN,
};

export const ROL_ID_BY_NAME = {
  Client: CLIENT_USER_ID,
  [ROLE_CLIENT_ADMIN]: CLIENT_ADMIN_ID,
  [ROLE_CLIENT_SUPER_ADMIN]: CLIENT_SUPER_ADMIN_ID,
  [DISPLAY_ROLE_EXTERNAL_LITIGATION_ATTORNEY]: EXTERNAL_LITIGATION_ATTORNEY_ID,
};

export const ROLE_MSA_ID = [
  EXTERNAL_QA_ID,
  EXTERNAL_AR_ID,
  EXTERNAL_MEDICAL_REVIEWER_MSA_ID,
  GOLD_QA_ID,
  MSA_MEDICAL_REVIEWER_ID,
];

export const getRoleId = rolesCurrentUserHas => {
  if (!rolesCurrentUserHas.user_roles) {
    return false;
  }
  if (!rolesCurrentUserHas.user_roles.roles) {
    return false;
  }

  let roles = rolesCurrentUserHas.user_roles.roles.map(role => {
    return role.id;
  });

  return roles[0];
};

export default (rolesCurrentUserHas, rolesNeeded) => {
  if (!rolesCurrentUserHas.user_roles) {
    return false;
  }
  if (!rolesCurrentUserHas.user_roles.roles) {
    return false;
  }

  let roles = rolesCurrentUserHas.user_roles.roles.map(role => {
    return role.name;
  });

  return rolesNeeded.some(item => roles.includes(item));
};
export const ROLES_DIVISIONS = {
  SUPERADMIN: [ROLE_SUPERADMIN],
  MANAGER: [ROLE_MEDICAL_REVIEWER],
  MSA_MEDICAL_REVIEWER: [ROLE_MSA_MEDICAL_REVIEWER],
  NURSE_ONLY: [ROLE_NURSE_WRITER],
  EXTERNAL_ONLY: [EXTERNAL_NURSE],
  QA_ONLY: [ROLE_QA_NURSE],
  EXTERNAL_QA: [ROLE_EXTERNAL_QA],
  CLIENT_ADMIN: [ROLE_CLIENT_ADMIN],
  CLIENT: [ROLE_CLIENT],
  MANAGER_ATTORNEY: [ATTORNEY_MANAGER],
  EXTERNAL_AR: [ROLE_EXTERNAL_AR],
  INTERNAL_ATTORNEY: [INTERNAL_ATTORNEY],
  EXTERNAL_LITIGATION_ATTORNEY: [ROLE_EXTERNAL_LITIGATION_ATTORNEY],
  SALES_MANAGER: [ROLE_SALES_MANAGER],
  SALES_REP: [ROLE_SALES_REP],
  MEDICAL_ADMIN: [ROLE_MEDICAL_ADMIN],
  CLIENT_SUPER_ADMIN: [ROLE_CLIENT_SUPER_ADMIN],
  SALES_HEAD: [ROLE_DIRECTOR_OF_SALES],

  MANAGEMENT: [
    ROLE_SUPERADMIN,
    ROLE_MEDICAL_REVIEWER,
    ROLE_SALES_MANAGER,
    ROLE_SALES_REP,
    ROLE_MEDICAL_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  PURE_MANAGEMENT: [ROLE_MEDICAL_REVIEWER],
  INTERNAL: [
    ROLE_SUPERADMIN,
    ROLE_MEDICAL_REVIEWER,
    ROLE_NURSE_WRITER,
    ATTORNEY_MANAGER,
    INTERNAL_ATTORNEY,
    ROLE_SALES_MANAGER,
    ROLE_SALES_REP,
    ROLE_MEDICAL_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  INTERNAL_NURSE: [
    ROLE_SUPERADMIN,
    ROLE_MEDICAL_REVIEWER,
    ROLE_NURSE_WRITER,
    ROLE_MEDICAL_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  NURSE: [
    ROLE_NURSE_WRITER,
    ROLE_SUPERADMIN,
    ROLE_SALES_MANAGER,
    ROLE_SALES_REP,
    ROLE_MEDICAL_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  NURSE_MANAGEMENT: [ROLE_MEDICAL_REVIEWER, ROLE_NURSE_WRITER],
  ALL: [
    ROLE_SUPERADMIN,
    ROLE_MEDICAL_REVIEWER,
    ROLE_MSA_MEDICAL_REVIEWER,
    ROLE_NURSE_WRITER,
    ROLE_CLIENT,
    ROLE_QA_NURSE,
    ROLE_EXTERNAL_QA,
    EXTERNAL_NURSE,
    ROLE_EXTERNAL_LITIGATION_ATTORNEY,
    INTERNAL_ATTORNEY,
    ATTORNEY_MANAGER,
    ROLE_EXTERNAL_AR,
    ROLE_CLIENT_ADMIN,
    ROLE_SALES_MANAGER,
    ROLE_SALES_REP,
    ROLE_MEDICAL_ADMIN,
    ROLE_CLIENT_SUPER_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  SUPERMANGER: [
    ROLE_SUPERADMIN,
    ROLE_MEDICAL_REVIEWER,
    ROLE_MSA_MEDICAL_REVIEWER,
    ROLE_NURSE_WRITER,
    EXTERNAL_NURSE,
    ROLE_SALES_MANAGER,
    ROLE_SALES_REP,
    ROLE_MEDICAL_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  ADMIN: [
    ROLE_SUPERADMIN,
    ROLE_MEDICAL_REVIEWER,
    ROLE_MSA_MEDICAL_REVIEWER,
    ROLE_SALES_MANAGER,
    ROLE_SALES_REP,
    ROLE_MEDICAL_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  QA: [ROLE_MEDICAL_REVIEWER, ROLE_QA_NURSE, ROLE_EXTERNAL_QA, ROLE_MSA_MEDICAL_REVIEWER],
  ATTORNEY_ONLY: [INTERNAL_ATTORNEY, ATTORNEY_MANAGER, ROLE_EXTERNAL_AR, ROLE_EXTERNAL_LITIGATION_ATTORNEY],
  SUPER_ADMIN_CLIENT_ADMIN: [
    ROLE_SUPERADMIN,
    ROLE_MEDICAL_REVIEWER,
    ROLE_CLIENT_ADMIN,
    ROLE_SALES_MANAGER,
    ROLE_SALES_REP,
    ROLE_MEDICAL_ADMIN,
    ROLE_CLIENT_SUPER_ADMIN,
    ROLE_DIRECTOR_OF_SALES,
  ],
  ALL_CLIENT: [ROLE_CLIENT, ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN],
};

export const getClientId = rolesCurrentUserHas => {
  const client = rolesCurrentUserHas.user_roles.roles.find(role => !!role.user_roles?.client_id);
  const client_id = client?.user_roles.client_id;
  return client_id;
};

export const isClient = rolesCurrentUserHas => {
  let is_client = false;
  rolesCurrentUserHas.user_roles.roles.map(role => {
    if (ROLES_DIVISIONS.ALL_CLIENT.includes(role.name)) {
      is_client = true;
    }
    return null;
  });
  return is_client;
};

export const isAttorney = rolesCurrentUserHas => {
  let is_attorney = false;
  rolesCurrentUserHas.user_roles.roles.map(role => {
    if (
      role.name === ATTORNEY_MANAGER ||
      role.name === ROLE_EXTERNAL_AR ||
      role.name === INTERNAL_ATTORNEY ||
      role.name === ROLE_EXTERNAL_LITIGATION_ATTORNEY
    ) {
      is_attorney = true;
    }
    return null;
  });
  return is_attorney;
};

export const isExternal = rolesCurrentUserHas => {
  let is_external = false;
  rolesCurrentUserHas.user_roles.roles.forEach(role => {
    if (role.name === ROLES_DIVISIONS.EXTERNAL_ONLY[0]) {
      is_external = true;
    }
  });
  return is_external;
};
