import React from 'react';

import { Col, Row, Typography } from 'antd';

import {
  COLUMNS_TABLE_LIFE_CARE_PLAN,
  FIELD_TABLE_LIFE_PLAN_AGE_AT_END,
  FIELD_TABLE_LIFE_PLAN_AGE_AT_START,
  FIELD_TABLE_LIFE_PLAN_ANNUAL_COST,
  FIELD_TABLE_LIFE_PLAN_CODES,
  FIELD_TABLE_LIFE_PLAN_COMMENT,
  FIELD_TABLE_LIFE_PLAN_CPT_HCPCS_CODE,
  FIELD_TABLE_LIFE_PLAN_END_DATE,
  FIELD_TABLE_LIFE_PLAN_FREQUECY_AND_DURATION_OF_NEED,
  FIELD_TABLE_LIFE_PLAN_INFLATION_TOTAL,
  FIELD_TABLE_LIFE_PLAN_PURPUSE,
  FIELD_TABLE_LIFE_PLAN_RECOMMENDED_BY,
  FIELD_TABLE_LIFE_PLAN_SERVICE_NAME,
  FIELD_TABLE_LIFE_PLAN_START_DATE,
  FIELD_TABLE_LIFE_PLAN_TOTAL_COST,
  FIELD_TABLE_LIFE_PLAN_TOTAL_YEARS,
  FIELD_TABLE_LIFE_PLAN_UNIT_COST,
} from 'containers/Templates/TemplateLifeCarePlan/TemplateLifeCarePlanTable/columns';
import { formatCurrency } from 'utils/utils';
import getInflation from 'utils/inflation';
import SeeMore from 'components/SeeMore';
import moment from 'moment';

const { Text } = Typography;

const LCPMedicareCostTableContentCustom = ({ structure, item, report }) => {
  return (
    <>
      {COLUMNS_TABLE_LIFE_CARE_PLAN.map(colunm => {
        if (colunm.field === FIELD_TABLE_LIFE_PLAN_SERVICE_NAME && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item.name}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_CPT_HCPCS_CODE && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col}>
              <Text>{item.cpt_code && item.cpt_code.code}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_CODES && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text></Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_FREQUECY_AND_DURATION_OF_NEED && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Row type="flex" align="middle">
                {colunm.children.map(subColunm => (
                  <Col key={subColunm.field || subColunm.show} xs={subColunm.col}>
                    <Text>{item[subColunm.field] || subColunm.show}</Text>
                  </Col>
                ))}
              </Row>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_UNIT_COST && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col}>
              <Text>{formatCurrency(item.per_unit_cost)}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_ANNUAL_COST && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col}>
              <Text>{formatCurrency(item.annual_cost)}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_TOTAL_COST && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col}>
              <Text>{formatCurrency(item.total_cost)}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_INFLATION_TOTAL && structure[colunm.field] === 1)
          return (
            <React.Fragment key={colunm.field}>
              {report.has_inflation ? (
                <Col xs={colunm.col}>
                  <Text>
                    {formatCurrency(
                      getInflation(
                        {
                          interest_rate: report.interest_rate,
                        },
                        item.total_cost,
                        item.total_years
                      )
                    )}
                  </Text>
                </Col>
              ) : (
                <Col xs={colunm.col}>
                  <Text></Text>
                </Col>
              )}
            </React.Fragment>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_START_DATE && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item?.start_date ? moment(item.start_date).format('MM/DD/YYYY') : ''}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_AGE_AT_START && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item.age_at_start}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_TOTAL_YEARS && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item.total_years}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_END_DATE && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item?.end_date ? moment(item.end_date).format('MM/DD/YYYY') : ''}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_AGE_AT_END && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item.age_at_end}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_RECOMMENDED_BY && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item.recommended_by}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_PURPUSE && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>{item.purpose}</Text>
            </Col>
          );

        if (colunm.field === FIELD_TABLE_LIFE_PLAN_COMMENT && structure[colunm.field] === 1)
          return (
            <Col key={colunm.field} xs={colunm.col} className="text-left">
              <Text>
                <SeeMore text={item.comments} />
              </Text>
            </Col>
          );

        return <React.Fragment key={colunm.field}></React.Fragment>;
      })}
    </>
  );
};

export default LCPMedicareCostTableContentCustom;
