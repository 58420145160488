import * as React from 'react';

const PayIcon = ({ color }) => (
  <svg fill="none" width={18} height={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path
      d="M13.018 18c-.348-.089-.708-.143-1.04-.272-1.675-.65-2.66-1.866-2.94-3.646-.01-.058-.025-.115-.04-.182-2.622.615-4.953.082-6.894-1.804C.594 10.626-.1 8.79.012 6.69A7.05 7.05 0 0 1 7.594.02c4.454.327 7.51 4.663 6.298 8.979.196.035.392.063.584.108 1.89.446 3.208 1.892 3.487 3.822.005.03.024.06.037.09v.964c-.013.03-.033.058-.037.088-.256 1.85-1.527 3.3-3.323 3.783-.216.058-.438.098-.657.146h-.965Zm-5.795-5.141c.165-.017.494-.032.816-.088.307-.055.606-.157.91-.23.129-.03.178-.108.213-.228.483-1.62 1.537-2.672 3.158-3.15.126-.038.181-.101.222-.22.316-.93.394-1.88.23-2.848C12.237 2.957 9.227.83 6.09 1.374 2.987 1.91.904 4.8 1.36 7.937c.406 2.803 2.858 4.92 5.863 4.921Zm6.282 3.854c1.762.003 3.211-1.455 3.21-3.228-.003-1.742-1.46-3.212-3.2-3.194-1.862.019-3.2 1.448-3.225 3.185-.025 1.78 1.434 3.235 3.216 3.237Z"
      fill={color}
    />
    <path
      d="M7.715 3.954c.69.278 1.121.768 1.262 1.494.043.229.069.465-.065.68a.669.669 0 0 1-.713.295.656.656 0 0 1-.483-.594c-.028-.371-.197-.581-.53-.658a.623.623 0 0 0-.663.303c-.256.446.07.944.627.958A1.92 1.92 0 0 1 8.96 7.92c.202.928-.242 1.813-1.11 2.21l-.135.064v.318a.65.65 0 0 1-.65.648.65.65 0 0 1-.634-.646v-.32c-.688-.281-1.124-.768-1.26-1.496a1.314 1.314 0 0 1 .002-.534c.077-.318.39-.49.734-.445.273.036.5.297.523.6.026.37.198.583.531.657a.624.624 0 0 0 .662-.303c.251-.44-.064-.947-.608-.954C6.097 7.708 5.342 7 5.18 6.19c-.171-.853.227-1.734 1.008-2.116.188-.092.26-.184.249-.396-.02-.375.197-.616.565-.677.333-.056.696.235.712.574.006.127 0 .254 0 .382v-.002ZM13.046 13.551c.438-.438.83-.84 1.236-1.228a.908.908 0 0 1 .37-.22.613.613 0 0 1 .7.305.629.629 0 0 1-.096.76 124.61 124.61 0 0 1-1.733 1.732c-.262.255-.639.247-.903-.008a32.267 32.267 0 0 1-.851-.852.634.634 0 0 1-.004-.903c.253-.259.644-.264.916-.001.129.124.238.27.365.414v.001Z"
      fill={color}
    />
  </svg>
);

export default PayIcon;
