import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as API from 'utils/api';

import { Row, Col, Typography } from 'antd';
import SpinBox from 'components/SpinBox';
import { BackIcon } from 'components/Icons';
import { notifyApiError, notifyInfo } from 'utils/notification';

import { StepFolderInfo } from 'containers/ParentFolderPage/CreateParentFolderPage/Components';

// Routes
import { CLIENTS_NAV } from 'layout/navigate_const';

// Style
import 'containers/ParentFolderPage/CreateParentFolderPage/createParentFolder.scss';

const { Title } = Typography;

const CreateParentFolder = () => {
  // Const
  const history = useHistory();
  const { parentFolderId } = useParams();

  // State
  const [loading, setLoading] = useState(false);
  const [parentFolder, setParentFolder] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await API.getParentFolderById({ id: parentFolderId });
        setParentFolder(res);
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Handlers
  const handleCreateClient = async ({ data } = {}) => {
    try {
      setLoading(true);
      const params = {
        client_name: data.clientName,
        client_logo: data.client_logo,
      };

      const createChild = await API.createClient(params);

      const body = {
        add: {
          client_id: createChild.id,
        },
      };

      await API.updateParentFolder({ id: parentFolderId, body });

      notifyInfo('New client created');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="add-client-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            {parentFolder?.name ? `Create new folder for: ${parentFolder?.name}` : 'Create new folder'}
          </Title>
        </Col>
        <Col className="back" onClick={() => history.push(`${CLIENTS_NAV.path}/${parentFolderId}`)}>
          <BackIcon className="icon" />
        </Col>
      </Row>

      <Row className="main">
        <SpinBox loading={loading}>
          <div className="createParentFolder">
            <StepFolderInfo onClick={handleCreateClient} hidenTypeFolder noneTypeFolder textBtnCrate="Create client" />
          </div>
        </SpinBox>
      </Row>
    </Row>
  );
};

export default CreateParentFolder;
