export const SPECIALTY_LEGAL_NURSE = {
  id: 1,
  name: 'Legal Nurse Supervisor',
};

export const SPECIALTY_MD_NEUROSURGEON = {
  id: 2,
  name: 'MD Neurosurgeon',
  messageReport: 'This review will be an additional $750 charge',
};
export const SPECIALTY_MD_PMR = {
  id: 3,
  name: 'MD PMR',
  messageReport: 'This review will be an additional $500 charge',
};
export const SPECIALTY_MD_TBD_1 = {
  id: 4,
  name: 'MD (TBD)',
  messageReport: 'This review will be an additional $500 charge',
};
export const SPECIALTY_MD_TBD_2 = {
  id: 5,
  name: 'MD (TBD) 2',
  messageReport: 'This review will be an additional $500 charge',
};

export const SPECIALTY_REPORT = [
  SPECIALTY_MD_NEUROSURGEON,
  SPECIALTY_MD_PMR,
  // SPECIALTY_MD_TBD_1,
  // SPECIALTY_MD_TBD_2
];

export const SPECIALTY_LIST = [
  SPECIALTY_LEGAL_NURSE,
  SPECIALTY_MD_NEUROSURGEON,
  SPECIALTY_MD_PMR,
  // SPECIALTY_MD_TBD_1,
  // SPECIALTY_MD_TBD_2,
];

export const SPECIALTY_IDS_OBJECT = {
  [SPECIALTY_LEGAL_NURSE.id]: SPECIALTY_LEGAL_NURSE,
  [SPECIALTY_MD_NEUROSURGEON.id]: SPECIALTY_MD_NEUROSURGEON,
  [SPECIALTY_MD_PMR.id]: SPECIALTY_MD_PMR,
  // [SPECIALTY_MD_TBD_1.id]: SPECIALTY_MD_TBD_1,
  // [SPECIALTY_MD_TBD_2.id]: SPECIALTY_MD_TBD_2,
};
