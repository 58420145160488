import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Select, Icon, Typography } from 'antd';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Icons
import { TrashIcon } from 'components/Svg';

// Const
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

// Uitls
import { getPermissionsBySection, PARENT_FOLDER_NAME } from 'permissions';

const { Title, Text } = Typography;
const { Option } = Select;

const FolderTab = () => {
  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { PARENT_FOLDER_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [PARENT_FOLDER_NAME],
  });
  // end permissions

  // State
  const [listSubFolder, setListSubFolder] = useState([]);
  const [listClients, setListClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addAdditionalSubFolder, setAddAdditionalSubFolder] = useState(false);

  // Const
  const selectSubFolder = listSubFolder.length === 0 || addAdditionalSubFolder;
  const { id } = useParams();

  const getAllClients = async () => {
    try {
      setLoading(true);
      const res = await API.getClientWithoutFolder({ pageSize: 1000000 });
      setListClients(res.rows);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await API.getParentFolderById({ id, model: 'client' });

        if (res.clients.length === 0) getAllClients();

        setListSubFolder(res.clients);
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // handle
  const selectedSubFolder = async ({ subFolderId }) => {
    try {
      setLoading(true);
      const getClientInfo = listClients.find(client => client.id === subFolderId);

      if (addAdditionalSubFolder) setAddAdditionalSubFolder(false);

      if (listSubFolder.some(subFolder => subFolder.id === getClientInfo.id)) return;

      // add
      await API.updateParentFolder({
        id,
        body: {
          add: {
            client_id: getClientInfo.id,
          },
        },
      });

      setListSubFolder(prevState => [...prevState, getClientInfo]);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const addNewSubFolder = async () => {
    if (listClients.length === 0) await getAllClients();
    setAddAdditionalSubFolder(true);
  };

  const handleRemoveSubFolder = async ({ subFolderId }) => {
    try {
      setLoading(true);
      await API.updateParentFolder({
        id,
        body: {
          remove: {
            client_id: subFolderId,
          },
        },
      });
      const updateList = listSubFolder.filter(subFolder => subFolder.id !== subFolderId);

      if (updateList.length === 0 && listClients.length === 0) await getAllClients();

      setListSubFolder(updateList);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="assignAdminTab">
      <div className="createParentFolder_addFolder">
        {selectSubFolder ? (
          <>
            <Title level={4}>Select Child-folder</Title>
            <Select
              getPopupContainer={triggerNode => triggerNode.parentNode}
              showSearch
              optionFilterProp="children"
              className="dropdown addClaimant_select"
              value=""
              onChange={id => selectedSubFolder({ subFolderId: id })}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option disabled value={''}>
                Type sub-folder name
              </Option>
              {listClients.map(client => (
                <Option value={client.id} key={client.id}>
                  {client.client_name}
                </Option>
              ))}
            </Select>

            {listSubFolder.length > 0 && (
              <button className="cancelContactButton mt-3" onClick={() => setAddAdditionalSubFolder(false)}>
                cancel
              </button>
            )}
          </>
        ) : (
          <div className="listCard_createParentFolder">
            {listSubFolder.map(subFolder => (
              <div key={subFolder.id} className="cardSubFolder_createParentFolder">
                <div className="cardSubFolder_createParentFolder_logo">
                  {subFolder?.client_logo && <img src={subFolder.client_logo} alt={subFolder.client_name} />}
                </div>
                <div className="cardSubFolder_createParentFolder_content">
                  <Title level={4}>{subFolder.client_name}</Title>
                  <Text>Child Folder</Text>
                </div>
                {
                  PARENT_FOLDER_PERMISSIONS.removeFolder && (
                    <div className="cardSubFolder_createParentFolder_btn">
                      <TrashIcon
                        color={PRIMARY_LIGHT_BLUE}
                        onClick={() => handleRemoveSubFolder({ subFolderId: subFolder.id })}
                      />
                    </div>
                  )
                }
              </div>
            ))}

            <button className="addAdditionalContactButton margin-button" onClick={addNewSubFolder}>
              Add Folder
            </button>
          </div>
        )}
      </div>

      {loading && <ModalLoading />}
    </div>
  );
};

export default FolderTab;
