import React from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { Button } from 'antd';

// Utils
import { goNextStep, goPrevStep } from 'utils/report-routing';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

// Const
import { REPORT_MSA, REPORT_SECTIONS_DEFAULT } from 'constants/reports';

// Style
import './allBtnReport.scss';

const AllBtnReport = ({
  handleGenerateReport,
  openModal,
  roles,
  returnToSender,
  previewReport,
  saveDraft,
  isTemplateSave,
}) => {
  const isInternalQa = isAllowed(roles, ROLES_DIVISIONS.QA_ONLY);
  const history = useHistory();
  const [, , , report_id, reportType, step] = history.location.pathname.split('/');

  const onPrevStep = async () => {
    goPrevStep({
      sections: REPORT_SECTIONS_DEFAULT[reportType],
      currentStep: step,
      report_id,
      reportType,
      history,
      roles,
    });
  };

  const onNextStep = async () => {
    goNextStep({
      sections: REPORT_SECTIONS_DEFAULT[reportType],
      currentStep: step,
      report_id,
      reportType,
      history,
      roles,
    });
  };

  return (
    <div className={`allBtnReport ${isInternalQa ? 'oneBtn' : ''}`}>
      <Button type="primary" size="default" className="transparent-btn button" onClick={onPrevStep}>
        Previous Step
      </Button>

      {isTemplateSave && (
        <div className="newUploadFiles_createBtn" onClick={isTemplateSave}>
          Save Template
        </div>
      )}

      {!isInternalQa ? (
        <>
          {handleGenerateReport && (
            <div className="newUploadFiles_createBtn" onClick={handleGenerateReport}>
              Generate Report
            </div>
          )}

          {openModal && (
            <Button type="primary" size="large" className="purple-btn button btn-normal" onClick={openModal}>
              Assign
            </Button>
          )}
        </>
      ) : (
        <>
          {previewReport && (
            <div className="newUploadFiles_createBtn" onClick={previewReport}>
              Preview Report
            </div>
          )}

          {saveDraft && (
            <div className="newUploadFiles_createBtn purple-btn" onClick={saveDraft}>
              Save Draft
            </div>
          )}

          <div className="newUploadFiles_createBtn" onClick={returnToSender}>
            Return to Sender
          </div>
        </>
      )}

      {reportType === REPORT_MSA && (
        <Button type="primary" size="default" className="transparent-btn button" onClick={onNextStep}>
          Next Step
        </Button>
      )}
    </div>
  );
};

export default AllBtnReport;
