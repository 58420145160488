import * as types from './actionTypes';
import { GET_ASSIGNMENT_HIGHLIGHTS } from './actionTypes';
import { ASSIGNMENT_STATUS_PENDING_REVIEW, STATUS_VALUES } from '../../containers/Reviews/assignment-statuses';

const initialState = {
  isLoading: false,
  loadingTitle: 'Loading',
  autoSavingTime: 5 * 60 * 1000, // every 5 minutes
  reportInfo: {},
  errorMessage: '',
  assignments: [],
  highlights: [],
  highlightLogs: [],
  files: [],
  allFiles: [],
  resourceLinksFiles: [],
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case types.GET_REPORT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportInfo: action.reportInfo,
      };
    case types.GET_REPORT_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.UPDATE_REPORT_DATA:
      return {
        ...state,
        reportInfo: { ...state.reportInfo, ...action.reportInfo },
      };

    case types.GET_REPORT_DATA_END:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_REPORT_ASSIGNMENTS: {
      const assignments = action.payload?.assignments || [];
      return {
        ...state,
        assignments,
      };
    }

    case types.GET_REPORT_FILES: {
      return { ...state, files: action.payload };
    }

    case types.GET_REPORT_FILES_ALL: {
      return { ...state, allFiles: action.payload };
    }

    case types.GET_REPORT_RESOURCE_LINKS: {
      return { ...state, resourceLinksFiles: action.payload };
    }

    case GET_ASSIGNMENT_HIGHLIGHTS: {
      return {
        ...state,
        highlights: action.payload.highlights,
        highlightLogs: action.payload.highlightLogs,
      };
    }

    case types.REPORT_UPDATE_ASSIGNMENT_STATUS: {
      return {
        ...state,
        assignments: [
          ...state.assignments.map(a =>
            a.id !== action.payload.assignment.id
              ? a
              : {
                  ...a,
                  status: {
                    key: ASSIGNMENT_STATUS_PENDING_REVIEW,
                    name: STATUS_VALUES[ASSIGNMENT_STATUS_PENDING_REVIEW],
                  },
                }
          ),
        ],
      };
    }

    default:
      return state;
  }
};

export default report;
