import React from 'react';

// Utils
import { getIconCard } from 'components/Modal/ModalAddCardPayInvoices/typeCreditCard';

// Style
import './creditCardInfo.scss';

const CreditCardInfo = ({ cardInfo }) => {
  const IconCard = getIconCard({ type: cardInfo.payment.creditCard.cardType }).Icon;

  return (
    <div className="creditCardInfo_list_infoCard">
      <div className="creditCardInfo_list_icon">
        <IconCard />
      </div>
      <div className="creditCardInfo_list_numberCard">{cardInfo.payment.creditCard.cardNumber}</div>
    </div>
  );
};

export default CreditCardInfo;
