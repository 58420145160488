import React, { useState } from 'react';
import { Alert, Button, Checkbox, Col, Input, Row, Typography, Upload, Icon } from 'antd';

import { BackIconBlack, BackIcon } from 'components/Icons';
import * as API from 'utils/api';
import { notifyApiError, notifyInfo, notifyError } from 'utils/notification';
import { UploadPresigned } from 'utils/upload';
import { linkValidation } from 'constants/regexes';
import FileIcons from 'constants/resource-links-icons';

import './ResourceLink.scss';

const { Title, Text } = Typography;
const { TextArea } = Input;

export default function ResourceLink(props) {
  // Link data
  const [resourceLinkData, setResourceLinkData] = useState({
    name: '',
    link: '',
    description: '',
    default_link: false,
    presignedFile: null,
  });

  // Link validation
  const [validateName, setValidateName] = useState('');
  const [validateLink, setValidateLink] = useState('');
  const [descriptionMessage, setDescriptionMessage] = useState('');
  const [validateFile, setValidateFile] = useState('');

  const [disalbeBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const HandleChanges = e => {
    const { name, value } = e.target;

    setResourceLinkData(prev => ({
      ...prev,
      [name]: value,
    }));

    setValidateName('');
    setDescriptionMessage('');
    setValidateLink('');
  };

  const handleSubmit = async () => {
    const data = {
      name: resourceLinkData.name.trim(),
      link: resourceLinkData.link.trim(),
      description: resourceLinkData.description.trim(),
      default_link: resourceLinkData.default_link,
      presignedFile: resourceLinkData.presignedFile,
    };

    if (!data.name) {
      setValidateName('Name cannot be empty');
    } else if (!data.link && !data.presignedFile) {
      notifyError('You must select document or enter a link');
    } else if (data.link && !linkValidation.test(data.link)) {
      setValidateLink('Link should be in the correct format!');
    } else if (!data.description) {
      setDescriptionMessage('Description cannot be empty!');
    } else if (data.description.length > 100) {
      setDescriptionMessage('Description cannot be more than 100 characters!');
    } else {
      try {
        setDisableBtn(true);
        let file = null;

        if (data.presignedFile) {
          // Load file to server
          file = await API.ReportFileUpload(null, data.presignedFile);
        }

        delete data.presignedFile;
        await API.createResourceLInk({ ...data, file_id: file?.file.id || null });

        props.history.push('/app/ResourceLibrary');
        notifyInfo('Resource link was created');
      } catch (e) {
        notifyApiError(e);
        setDisableBtn(false);
      }
    }
  };

  /**
   * This method do all necessary validation
   * @param {object} file - user's file instance
   * @param {number} file.lastModified - date of when file was modified (in unix timestamp)
   * @param {number} file.lastModifiedDate - date of when file was modified (in date format)
   * @param {string} file.name - original name of the file
   * @param {number} file.size - size of the file
   * @param {number} file.type - type of the file (example: text/plain, application/pdf)
   * @param {number} file.uid - unique id of the file
   * @returns {boolean} condition whether file respond to our rules
   */
  const beforeDocUpload = file => {
    // If there is no file, we doing nothing
    if (!file) return false;

    // Conditions for file types
    const isValid = file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === '.docx';

    if (!isValid) {
      setValidateFile('You can only upload .pdf, .doc and .docx files');
    }

    return isValid;
  };

  /**
   * This method allows user upload documents (*.pdf)
   * @param {object} info - all information about user's uploaded file
   */
  const handleDocUpload = async info => {
    setLoading(true);

    // instance of the user's file
    const { file } = info;

    if (beforeDocUpload(file.originFileObj)) {
      try {
        // getting presigned file
        const presignedFile = await UploadPresigned(file);

        setValidateFile('');
        setResourceLinkData(prev => ({ ...prev, presignedFile }));
        setLoading(false);
      } catch (e) {
        notifyApiError(e);
      }
    } else {
      setLoading(false);
    }
  };

  const removePresignedFile = () => setResourceLinkData(prev => ({ ...prev, presignedFile: null }));

  return (
    <Row className="resourceLink-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Add Resource Link
          </Title>
        </Col>
        <Col className="back" onClick={() => props.history.goBack()}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <Row className="resource-link-content">
        <div className="wrapper-links">
          <Row gutter={[16]}>
            <Col span={12}>
              <Row>
                <Text>Name</Text>
              </Row>
              <Input
                size="large"
                className="resource-inputs"
                placeholder="Required"
                value={resourceLinkData.name}
                onChange={HandleChanges}
                name="name"
              />
              {validateName && <Alert type="error" message={validateName} banner />}
            </Col>
            <Col span={12}>
              <Row>
                <Text>
                  Document <em>(*.pdf, *.doc or *.txt only)</em>
                </Text>
              </Row>
              <Row>
                {!resourceLinkData.presignedFile ? (
                  <Upload
                    name="document"
                    accept="application/pdf,.doc,.docx"
                    listType="text"
                    showUploadList={false}
                    className="document-upload"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    onChange={handleDocUpload}
                    disabled={loading}>
                    {loading ? <Icon type="loading" /> : 'Upload'}
                  </Upload>
                ) : (
                  <Row type="flex" justify="space-between" align="bottom" className="uploaded-document">
                    <Text className="document-name" ellipsis title={resourceLinkData.presignedFile.name}>
                      <Icon type={FileIcons[resourceLinkData.presignedFile.type]} />
                      &nbsp;
                      {resourceLinkData.presignedFile.name}
                    </Text>
                    <Button
                      className="document-delete"
                      ghost
                      type="danger"
                      icon="delete"
                      onClick={removePresignedFile}
                    />
                  </Row>
                )}
              </Row>
              {validateFile && <Alert type="error" message={validateFile} banner className="file-error" />}
            </Col>
          </Row>
          <Row>
            <Text>Link</Text>
          </Row>
          <Row>
            <Input
              size="large"
              className="resource-inputs resource-link"
              name="link"
              value={resourceLinkData.link}
              onChange={HandleChanges}
              placeholder="Optional"
            />
          </Row>
          {validateLink && <Alert type="error" message={validateLink} banner />}
          <Row>
            <Text>Short Description</Text>
            <Text style={{ float: 'right' }}>{resourceLinkData.description.length}/100</Text>
          </Row>

          <TextArea
            placeholder="Required"
            className="resource-inputs"
            name="description"
            value={resourceLinkData.description}
            onChange={HandleChanges}
            style={{ padding: '23px', resize: 'none' }}
            maxLength={100}
          />
          {descriptionMessage && <Alert type="error" message={descriptionMessage} banner />}
          <Row>
            <Checkbox
              checked={resourceLinkData.default_link}
              onChange={e =>
                setResourceLinkData({
                  ...resourceLinkData,
                  default_link: e.target.checked,
                })
              }>
              Included in Report by default
            </Checkbox>
          </Row>
          <Button className="resource-link-btn" onClick={handleSubmit} disabled={loading} loading={disalbeBtn}>
            Create
          </Button>
        </div>
      </Row>
    </Row>
  );
}
