import { useState } from 'react';
import { notifyApiError, notifyInfo } from 'utils/notification';
import * as API from 'utils/api';
import { updateReportInfo } from 'redux/report';
import { useDispatch } from 'react-redux';

export default function useReport() {
  const dispatch = useDispatch();
  const [loadingReport, setLoadingReport] = useState(false);
  const [reportInfo, setReportInfo] = useState('');

  const getReportInfo = async ({ idReport }) => {
    try {
      setLoadingReport(true);
      const report = await API.getReportById(idReport);
      dispatch(updateReportInfo(report));
      setReportInfo(report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingReport(false);
    }
  };

  const updateReport = async ({ body }) => {
    try {
      setLoadingReport(true);
      const res = await API.updateReport(body);

      if (res?.status_code === 201) {
        notifyInfo('', res.message);
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingReport(false);
    }
  };

  const getFieldsForUpdateReportLCP = async ({ params, idTemplateLCP }) => {
    try {
      const reportStructure = await API.getReportLifeCarePlan({ idReport: idTemplateLCP });

      if (reportStructure.reportStructure.report_structure.length > 0) {
        reportStructure.reportStructure.report_structure.forEach(item => {
          item.template_fields.forEach(subItem => {
            params[subItem.report_ref || subItem.ref] = subItem.placeholder;
          });
        });
      }

      return params;
    } catch (e) {
      console.error(e);
      return params;
    }
  };

  return {
    loadingReport,
    reportInfo,
    getReportInfo,
    updateReport,
    getFieldsForUpdateReportLCP,
  };
}
