import React from 'react';
import { Modal, Typography, Button } from 'antd';
import './modalConfirm.scss';

const { Title, Text } = Typography;

const ModalConfirm = ({
  onClose,
  title,
  text,
  onConfirm,
  btnSuccess = 'Continue',
  btnCancel = 'Go Back',
  disabledBtnClose,
}) => {
  return (
    <Modal
      width={700}
      maskClosable={false}
      bodyStyle={{ padding: 0, minWidth: 450 }}
      visible={true}
      onCancel={onClose}
      keyboard={false}
      footer={null}>
      <div className="modalConfirm">
        <Title level={3}>{title}</Title>
        {text && <Text>{text}</Text>}
        <div className="modalConfirm_btns">
          <Button type="primary" size="large" className="green-btn button btn-normal" onClick={onConfirm}>
            {btnSuccess}
          </Button>

          {!disabledBtnClose && (
            <Button size="large" className="btn_transparent_secundary button btn-normal" onClick={onClose}>
              {btnCancel}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
