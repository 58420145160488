import React from 'react';

import { Col, Row, Typography } from 'antd';

import { formatCurrency } from 'utils/utils';
import SeeMore from 'components/SeeMore';

const { Text } = Typography;

const LCPMedicareCostTableContentPrescription = ({ item }) => {
  return (
    <>
      <Col md={2} className="text-left">
        <Text>{item.prescriptions}</Text>
      </Col>
      <Col md={1}>
        <Text>{item.ndc}</Text>
      </Col>
      <Col md={1}>
        <Text>{item.unit_form && item.unit_form.name}</Text>
      </Col>
      <Col md={2}>
        <Text>{item.prescription_strength}</Text>
      </Col>
      <Col md={3}>
        <Row>
          <Col md={12}>
            <Text className="text-center">{item.units_per_refill}</Text>
          </Col>
          <Col md={12}>
            <Text className="text-center">{item.refills_per_year}</Text>
          </Col>
        </Row>
      </Col>
      <Col md={15}>
        <Row className="AWP-container">
          <Col md={4}>
            <Text>{formatCurrency(item.per_unit_cost)}</Text>
          </Col>
          <Col md={4}>
            <Text>{formatCurrency(item.annual_cost)}</Text>
          </Col>
          <Col md={4}>
            <Text>{item.total_years}</Text>
          </Col>
          <Col md={4}>
            <Text>{formatCurrency(item.total_cost)}</Text>
          </Col>
          <Col md={8} className="text-left">
            <Text>
              <SeeMore text={item.comments} />
            </Text>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default LCPMedicareCostTableContentPrescription;
