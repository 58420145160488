import React from 'react';

// Components
import { Modal, Typography } from 'antd';

// Style
import './modalRushFees.scss';

const { Title } = Typography;

const ModalRushFees = ({ onConfirm, onChange }) => {
  return (
    <Modal visible={true} footer={null} centered={true} closable={true} width={550} onCancel={onChange}>
      <div className="modalRushFees">
        <Title level={4}>Rush fees will apply to this report as it's within ten working days</Title>

        <div className="modalRushFees_btn">
          <button className="buttonBig buttonBig_green" onClick={onConfirm}>
            Accept Fee
          </button>
          <button className="buttonBig buttonBig_purple" onClick={onChange}>
            Change Date
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRushFees;
