import React, { useEffect, useState } from 'react';

// Components
import { ModalLoading } from 'components/Modal/ModalLoading';
import { Typography } from 'antd';

// Hooks
import { useBillDue, usePricingGlobal, useSubscription } from 'hooks';

import './planSubscription.scss';

import {
  CUSTOM_PACKAGE_ID,
  PLAN_LIST_ID_BASIC,
  PLAN_LIST_ID_ELITE,
  PLAN_LIST_ID_INDIVIDUAL,
  PLAN_LIST_ID_MULTIPLE,
  PLAN_LIST_ID_PROFESSIONAL,
} from 'containers/Tenant/tenantCreate/components/StepPackageTier/components/PlanCard/planList';
import {
  ACTUAL_PLAN,
  DOWNGRADE_PLAN,
  LIST_SUBSCRIPTION,
  TPYE_PLAN_BASIC,
  TPYE_PLAN_ELITE,
  TYPE_PLAN_CUSTOM,
  TYPE_PLAN_PRODESSIONAL,
  UPGRADE_PLAN,
} from './const';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { ModalMessage } from 'components/Modal/ModalMessage';
import { CardSubscription } from '../CardSubscription';

const { Title } = Typography;

const PlanSubscription = ({ packageId, tenant_id, customer, updateTenant, billings, availableUntil }) => {
  const [selectPlan, setSelectPlan] = useState(null);
  const [modalUpgrade, setModalUpgrade] = useState(false);
  const [modalDowngrade, setModalDowngrade] = useState(false);
  const [modalCanceled, setModalCanceled] = useState(false);
  const [modalConfirmChange, setModalConfirmChange] = useState(false);
  const [modalConfirmCaceled, setModalConfirmCanceled] = useState(false);
  const [typeChange, setTypeChange] = useState(null);

  const { loadingSubscription, updateSubscription, cancelSubscriptionHook } = useSubscription();
  const { listPricingGlobal, loadingPricingGlobal, getPricingGlobal } = usePricingGlobal();
  const { billDue, getBillDue } = useBillDue();

  const loading = loadingSubscription || loadingPricingGlobal;

  const costPackage = billings.find(item => item.billing_name === 'Cost Package');
  const isGlobalPricing = costPackage?.is_global_price;
  const customPrice = !isGlobalPricing ? costPackage?.billing_price : 0;

  useEffect(() => {
    (async () => {
      await Promise.allSettled([getPricingGlobal(), getBillDue()])
    })();
    // eslint-disable-next-line
  }, []);

  const handleDownActualOrUpgradePlan = ({ type }) => {
    let infoPlan = {};

    if (PLAN_LIST_ID_BASIC.includes(packageId))
      infoPlan = {
        [TPYE_PLAN_BASIC]: ACTUAL_PLAN,
        [TYPE_PLAN_PRODESSIONAL]: UPGRADE_PLAN,
        [TPYE_PLAN_ELITE]: UPGRADE_PLAN,
      };

    if (PLAN_LIST_ID_PROFESSIONAL.includes(packageId))
      infoPlan = {
        [TPYE_PLAN_BASIC]: DOWNGRADE_PLAN,
        [TYPE_PLAN_PRODESSIONAL]: ACTUAL_PLAN,
        [TPYE_PLAN_ELITE]: UPGRADE_PLAN,
      };

    if (PLAN_LIST_ID_ELITE.includes(packageId))
      infoPlan = {
        [TPYE_PLAN_BASIC]: DOWNGRADE_PLAN,
        [TYPE_PLAN_PRODESSIONAL]: DOWNGRADE_PLAN,
        [TPYE_PLAN_ELITE]: ACTUAL_PLAN,
      };

    return infoPlan[type];
  };

  const handleOpenModalConfirmUpgrade = ({ subscription, action }) => {
    let newSubscription;

    if (PLAN_LIST_ID_INDIVIDUAL.includes(packageId)) newSubscription = subscription.individual;
    if (PLAN_LIST_ID_MULTIPLE.includes(packageId)) newSubscription = subscription.multiple;

    const packageSelect = listPricingGlobal.find(item => item.id === newSubscription);

    if (action === UPGRADE_PLAN) setModalUpgrade(true);
    if (action === DOWNGRADE_PLAN) setModalDowngrade(true);

    setTypeChange(action);
    setSelectPlan(packageSelect);
  };

  const handleChangeSubscription = async () => {
    await updateSubscription({ tenant_id, package_id: selectPlan.id, customer });

    await updateTenant();

    if (typeChange === UPGRADE_PLAN) {
      setModalUpgrade(false);
    }

    if (typeChange === DOWNGRADE_PLAN) {
      setModalDowngrade(false);
    }

    setModalConfirmChange(true);
  };

  const handleOpenModalCanceledPlan = () => setModalCanceled(true);

  const handleCancelPlan = async () => {
    await cancelSubscriptionHook({ tenant_id });
    await updateTenant();
    setModalCanceled(false);
    setModalConfirmCanceled(true);
  };

  const handleGetPriceByPackage = ({ type }) => {
    if (PLAN_LIST_ID_INDIVIDUAL.includes(packageId)) {
      return listPricingGlobal.find(item => item.type === 'Individual' && item.name === type);
    }

    if (PLAN_LIST_ID_MULTIPLE.includes(packageId)) {
      return listPricingGlobal.find(item => item.type === 'Multi-User' && item.name === type);
    }

    return listPricingGlobal.find(item => item.type === type);
  };

  return (
    <>
      {loading && <ModalLoading />}
      <div className="subscriptionSetting">
        <div className="subscriptionSetting_header">
          <Title level={4}>Subscription Management</Title>
        </div>

        {packageId !== CUSTOM_PACKAGE_ID && (
          <>
            {LIST_SUBSCRIPTION.map(item => {
              const actualPlan = handleDownActualOrUpgradePlan({ type: item.type }) === ACTUAL_PLAN;
              const upgradePlan = handleDownActualOrUpgradePlan({ type: item.type }) === UPGRADE_PLAN;

              const pricePackage = handleGetPriceByPackage({ type: item.type })?.price || 0;

              return (
                <CardSubscription
                  type={item.type}
                  actualPlan={actualPlan}
                  upgradePlan={upgradePlan}
                  name={item.name}
                  includes={item.includes}
                  pricePackage={pricePackage}
                  packageId={packageId}
                  onOpenModalCanceledPlan={handleOpenModalCanceledPlan}
                  onOpenModalConfirmUpgrade={handleOpenModalConfirmUpgrade}
                  onDownActualOrUpgradePlan={handleDownActualOrUpgradePlan}
                  billDue={billDue}
                />
              );
            })}
          </>
        )}

        {packageId === CUSTOM_PACKAGE_ID && (
          <CardSubscription
            type={TYPE_PLAN_CUSTOM}
            actualPlan={true}
            name={TYPE_PLAN_CUSTOM}
            pricePackage={
              !isGlobalPricing ? customPrice : handleGetPriceByPackage({ type: TYPE_PLAN_CUSTOM })?.price || 0
            }
            includes={['All reports']}
            packageId={packageId}
            noneActions
            availableUntil={availableUntil}
            billDue={billDue}
          />
        )}
      </div>

      {modalUpgrade && (
        <ModalConfirm
          onClose={() => setModalUpgrade(false)}
          title={`Upgrade your subscription will cost ${selectPlan.price}/month`}
          text="Once you continue, you'll be able to access the new report types, and the payment will be charged to your registered payment method"
          onConfirm={handleChangeSubscription}
          btnCancel="Cancel"
        />
      )}

      {modalDowngrade && (
        <ModalConfirm
          onClose={() => setModalDowngrade(false)}
          title={`Are you sure you want to switch to a lower-level subscription`}
          onConfirm={handleChangeSubscription}
          btnCancel="No"
          btnSuccess="Yes"
        />
      )}

      {modalCanceled && (
        <ModalConfirm
          onClose={() => setModalCanceled(false)}
          title={`Are you certain you want to cancel your subscription`}
          onConfirm={handleCancelPlan}
          btnCancel="Cancel"
        />
      )}

      {modalConfirmChange && (
        <ModalMessage
          onClose={() => setModalConfirmChange(false)}
          title={`Congratulations, your subscription has been ${typeChange} successfully`}
          textButton="Close"
          noneIcon
        />
      )}

      {modalConfirmCaceled && (
        <ModalMessage
          onClose={() => setModalConfirmCanceled(false)}
          title="Your cancellation request has been submitted"
          text="Please reach out to the MSA Support Manager to proceed with your cancellation"
          textButton="Close"
          noneIcon
        />
      )}
    </>
  );
};

export default PlanSubscription;
