import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Select, Typography, Divider } from 'antd';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

// Components
import { SettingsCard } from './SettingsCard';
import { PlanSubscription } from '../Components/PlanSubscription';

// Utils
import * as API from 'utils/api';
import { fetchQuickbooksCustomerInfo } from 'utils/api-transactions';
import { notifyApiError, notifySuccess } from '../../../utils/notification';

// Hooks
import {
  useCreditCard,
} from 'hooks';

// Style
import './CompanyProfile.scss';
// import { BillDue } from '../Components/BillDue';
import { ModalLoading } from 'components/Modal/ModalLoading';

const BillingInfoSubscription = () => {
  const { userInfo } = useSelector(state => state.auth);

  const { Option } = Select;
  const { Text } = Typography;
  const [stateCountries, setStateCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [thereIsCustomerProfile, setThereIsCustomerProfile] = useState(false);
  const [tenant, setTenant] = useState(null);

  // Hooks
  const { creditCardList, loadingCreditCard, getCreditCardListTenant, getCustomerProfileUser, createCustomerProfile } =
    useCreditCard();

  useEffect(() => {
    (async () => {
      try {
        const state = await API.getStates();
        setStateCountries(state.data.states);

        const { tenant } = await API.getTenantById({ id: userInfo?.attributes['custom:tenant_id'] });
        setTenant(tenant);

        const quickBookId = tenant?.quickbooks_customer_id || '';
        const customer_profile_id = tenant?.customer_profile_id || '';
        let dataBilling = {};
        if (quickBookId) {
          dataBilling = await fetchQuickbooksCustomerInfo(quickBookId);
        } else {
          // if the user does not have a quickbooks id, it should create one
          const params = {
            given_name: tenant?.tenant_name || '',
            tenant_name: tenant?.tenant_name || '',
            email: tenant?.email || '',
            phone_number_1: tenant?.phone_number_1 || '',
            state: tenant?.state || '',
            city: tenant?.city || '',
            zipCode: tenant?.zip || '',
            address_line: tenant?.address_line || '',
          };
          const data = await API.createQuickbooksCustomer({
            params,
            tenant_id: tenant?.id,
          });
          dataBilling = await fetchQuickbooksCustomerInfo(data.Id);
        }

        const billTo = {
          displayName: dataBilling?.DisplayName || '',
          companyName: dataBilling?.CompanyName || '',
          address: dataBilling?.BillAddr?.Line1 || '',
          city: dataBilling?.BillAddr?.City || '',
          state: dataBilling?.BillAddr?.CountrySubDivisionCode || '',
          zipCode: dataBilling?.BillAddr?.PostalCode || '',
          email: dataBilling?.PrimaryEmailAddr?.Address || '',
          phone: dataBilling?.PrimaryPhone?.FreeFormNumber || '',
          quickbooks_customer_id: quickBookId,
        };
        let customerProfile = false;

        if (customer_profile_id) {
          customerProfile = await getCustomerProfileUser(customer_profile_id);
        }

        if (customerProfile !== false) {
          // if the user has a customer profile we look for their cards
          await getCreditCardListTenant(customer_profile_id);
          setThereIsCustomerProfile(true);
        } else if (customerProfile === false && quickBookId) {
          // if the billing is complete and the user does not have a customer profile, we create the customer profile
          const result = await createCustomerProfile({
            firstName: billTo.companyName,
            lastName: billTo.companyName,
            address: billTo.address,
            city: billTo.city,
            state: billTo.state,
            zip: billTo.zipCode,
            number: billTo.phone,
            email: billTo.email,
            client_id: null,
            tenant_id: tenant.id,
          });

          setThereIsCustomerProfile(!!result);
        }

        setData(billTo);
      } finally {
        setIsLoading(false);
      }
    })();
    return () => {};
  }, []);

  const handleChange = ({ field, value }) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const validForm = () => {
    return Object.keys(data).some(item => !data[item]);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const params = {
        id: userInfo?.attributes['custom:tenant_id'],
        quickbooks_customer_id: data.quickbooks_customer_id,
        set_quickbooks: true,
        fields: {
          displayName: data.displayName,
          companyName: data.companyName,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
          email: data.email,
          phone: data.phone,
        },
      };
      await API.updateTenant(params);

      if (!thereIsCustomerProfile) {
        await createCustomerProfile({
          firstName: data.companyName,
          lastName: data.companyName,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
          number: data.phone,
          email: data.email,
          client_id: null,
          tenant_id: tenant.id,
        });
      }

      notifySuccess('Updated Successfully');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const StyledInput = {
    fontSize: '14px',
  };

  const updateList = async () => {
    const customer_profile_id = tenant?.customer_profile_id || '';
    await getCreditCardListTenant(customer_profile_id);
  };

  const updateTenant = async () => {
    const { tenant } = await API.getTenantById({ id: userInfo?.attributes['custom:tenant_id'] });
    setTenant(tenant);
  };

  const LOADING = isLoading || loadingCreditCard;

  if (LOADING) return <ModalLoading />;

  return (
    <Row className="company_container" type="flex" justify="space-between">
      <Row type="flex" justify="start" align="middle">
        <h1 className="company__title">Billing Information</h1>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Display Name</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="displayName"
            value={data?.displayName || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Company Name</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="companyName"
            value={data?.companyName || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={24}>
          <Row className="company__inputs">
            <Text>Address</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="address"
            value={data?.address || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={11}>
          <Row className="company__inputs">
            <Text>City</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="city"
            value={data?.city || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
        <Col md={11}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={11}>
              <Row className="company__inputs">
                <Text>State</Text>
              </Row>
              <Select
                showSearch
                placeholder="Required"
                className="clients-select"
                value={data?.state}
                onChange={name => handleChange({ field: 'state', value: name })}
                style={{ width: '100%' }}>
                {stateCountries.map(state => (
                  <Option key={state.id} value={state.name}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={11}>
              <Row className="company__inputs">
                <Text>Zip code</Text>
              </Row>
              <InputMask
                mask="99999"
                placeholder="Required"
                className="input-ssn"
                value={data?.zipCode || ''}
                onChange={e => handleChange({ field: 'zipCode', value: e.target.value })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Email</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="email"
            value={data?.email || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Phone</Text>
          </Row>
          <InputMask
            className="input-ssn"
            mask="999-999-9999"
            size="large"
            placeholder="Required"
            value={data?.phone || ''}
            name="phone"
            style={StyledInput}
            onChange={e => handleChange({ field: 'phone', value: e.target.value })}
          />
        </Col>
      </Row>

      <Row type="flex" justify="end">
        <Col md={6}>
          <button disabled={validForm()} className="company_form_btn mb-4 mt-3" onClick={onSubmit}>
            Save
          </button>
        </Col>
      </Row>

      <Divider />

      <PlanSubscription
        packageId={tenant?.package_id}
        tenant_id={tenant?.id}
        customer={tenant?.customer_id}
        updateTenant={updateTenant}
        billings={tenant?.billings}
        availableUntil={tenant?.available_until || ''}
      />

      <Divider />

      <SettingsCard listCard={creditCardList} updateList={async () => await updateList()} />
      {/* <BillDue /> */}
    </Row>
  );
};

export default BillingInfoSubscription;
