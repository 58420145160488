import * as API from 'utils/api';

import { STATUS_IN_QA_REVIEW } from 'constants/report_status';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

export const existsAssigment = async ({ reportInfo, roles }) => {
  let params = { report_status_id: STATUS_IN_QA_REVIEW.id, id: reportInfo.id };

  const res = await API.updateReport(params);

  if (!res.report.report_status_id === STATUS_IN_QA_REVIEW) return;

  let finalAssigned = {
    claimant: reportInfo.claimant,
    report: reportInfo,
    report_id: reportInfo.id,
    claimant_id: reportInfo.claimant_id,
  };

  let assignWriter = true;

  const { assignments } = await API.fetchReportAssignments({
    reportId: reportInfo.id,
    claimantId: reportInfo.claimant_id,
  });

  finalAssigned = { ...finalAssigned, ...assignments[0] };

  if (!isAllowed(roles, ROLES_DIVISIONS.NURSE_ONLY)) assignWriter = false;

  return { finalAssigned, assignWriter };
};
