import React, { useState } from 'react';

// Components
import { Typography, Button } from 'antd';
import { ModalAddCardPayInvoices } from 'components/Modal/ModalAddCardPayInvoices';
import { SettingsListCard } from '../SettingsListCard';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Hooks
import { useCreditCard } from 'hooks';

// Style
import './settingsCard.scss';
import { notifyApiError } from 'utils/notification';

const { Title } = Typography;

const SettingsCard = ({ listCard, updateList, canCreate }) => {
  const [modalAddCard, setModalAddCard] = useState(false);

  const { publicKey, loadingCreditCard, getPublicKey, deleteCreditCard } = useCreditCard();

  const handleUpdateListCard = () => {
    updateList && updateList();
    setModalAddCard(false);
  };

  const handleDeleteCard = async ({ card }) => {
    await deleteCreditCard({ profileCustomerPaymentId: card.customerPaymentProfileId });
    updateList();
  };

  const handleModalAddCard = async () => {
    if (!canCreate) return notifyApiError('Please complete your billing information before adding a card');

    await getPublicKey();
    setModalAddCard(true);
  };

  return (
    <>
      {loadingCreditCard && <ModalLoading />}
      <div className="settingsCard">
        <div className="settingsCard_header">
          <Title level={4}>Cards saved</Title>
          <Button type="primary" className="green-btn" onClick={handleModalAddCard}>
            Add New Card
          </Button>
        </div>

        <SettingsListCard listCard={listCard} onDelete={handleDeleteCard} />

        {modalAddCard && (
          <ModalAddCardPayInvoices
            onClose={() => setModalAddCard(false)}
            title="Add New Card"
            textBtn="Add"
            updateListCard={handleUpdateListCard}
            publicKey={publicKey}
          />
        )}
      </div>
    </>
  );
};

export default SettingsCard;
