import * as React from "react"
import { BLUE_ICON } from "containers/Calendar/src/src/lib/utils/colors"

const TrashIcon = ({ color = BLUE_ICON, onClick, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox='0 0 18 18'
    onClick={onClick}
    className={className || ''}
  >
    <defs>
      <path id="a" d="M0 0h18v18H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        stroke={color}
        strokeWidth={1.2}
        d="M3.6 4.6V15A1.4 1.4 0 0 0 5 16.4h8a1.4 1.4 0 0 0 1.4-1.4V4.6H3.6z"
        mask="url(#b)"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M1.5 4.5h15m-9 4v4m3-4v4"
        mask="url(#b)"
      />
      <path
        stroke={color}
        strokeWidth={1.2}
        d="M5.5 4v-.5a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2V4"
        mask="url(#b)"
      />
    </g>
  </svg>
)

export default TrashIcon
