import React from 'react';

import { Row, Select, Col, Icon, Typography } from 'antd';
import { REPORT_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Text } = Typography;

export const LIFE_TYPE = {
  SOURCE: 'source',
  TABLE: 'table',
};

const LifeSourceAndLifeTable = ({ lifeTables, life_table_id, source_id, lifeSources, onChange }) => {
  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });
  // end permissions

  return (
    <Row type="flex" justify="space-between" align="middle" className="row">
      <Col md={11}>
        <Row>
          <Text>Life Source</Text>
        </Row>
        <Row>
          <Select
            showSearch
            placeholder="Please select"
            optionFilterProp="children"
            className="dropdown"
            disabled={!REPORT_PERMISSIONS.edit}
            value={source_id}
            onChange={value => onChange({ life: LIFE_TYPE.SOURCE, value })}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            {lifeSources.map(item => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Row>
      </Col>
      <Col md={11}>
        <Row>
          <Text>Life Table</Text>
        </Row>
        <Row>
          <Select
            showSearch
            placeholder="Please select"
            optionFilterProp="children"
            className="dropdown"
            value={life_table_id}
            disabled={!REPORT_PERMISSIONS.edit}
            onChange={value => onChange({ life: LIFE_TYPE.TABLE, value })}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            {lifeTables.map(item => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Row>
      </Col>
    </Row>
  );
};

export default LifeSourceAndLifeTable;
