//MCP and Surgical
export const COURT_DOCUMENTS = 'Court-WC Board Documents';
export const MEDICAL_RECORDS = 'Medical Records';
export const PAYMENT_INFORMATION = 'Payment Information';
export const SUPPLEMENTAL_DOCUMENTATION = 'Supplemental Documentation-Additional Info';

//MSA
export const CONSENT_FORM = '10ConsentForm';
export const RATED_AGES = '15RatedAges';
export const COURT_DOCUMENTS_MSA = '25Court-WCBoardDocuments';
export const ADMINISTRATION_AGREEMENT = '30WCMSAAdministrationAgreement';
export const MEDICAL_RECORDS_MSA = '35MedicalRecords';
export const PAYMENT_INFORMATION_MSA = '40PaymentInformation';
export const SUPPLEMENTAL_DOCUMENTATION_MSA = '50Supplemental-AdditionalInformation';
export const REDUCTION_LETTER_MSA = '60ReductionLetter';

// DEMAND LETTER
export const INJURY_DESCRIPTION = 'injurydescription';
export const MEDICALS = 'medicals';
export const DAMAGES = 'damages';
export const LEGAL_DOCS = 'legaldocs';