import React from 'react';

import PrevNextStep from 'components/PrevNextStep';
import { Button } from 'antd';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

const BtnGenerateReport = ({
  handleUpdateReport,
  handleGenerateReport,
  openModal,
  customClass,
  roles,
  oneBtn,
  returnToSender,
  previewReport,
  saveDraft,
  isTemplateSave,
}) => {
  const isInternalQa = isAllowed(roles, ROLES_DIVISIONS.QA_ONLY);

  return (
    <div className={`newUploadFiles_generalBtn ${customClass ? customClass : ''} ${oneBtn ? 'oneBtn' : ''}`}>
      {handleUpdateReport && <PrevNextStep beforeNext={handleUpdateReport} />}

      {isTemplateSave && (
        <div className="newUploadFiles_createBtn" onClick={isTemplateSave}>
          Save Template
        </div>
      )}

      {!isInternalQa ? (
        <>
          {handleGenerateReport && (
            <div className="newUploadFiles_createBtn" onClick={handleGenerateReport}>
              Generate Report
            </div>
          )}

          {openModal && (
            <Button type="primary" size="large" className="purple-btn button btn-normal" onClick={openModal}>
              Assign
            </Button>
          )}
        </>
      ) : (
        <>
          {previewReport && (
            <div className="newUploadFiles_createBtn" onClick={previewReport}>
              Preview Report
            </div>
          )}

          {saveDraft && (
            <div className="newUploadFiles_createBtn purple-btn" onClick={saveDraft}>
              Save Draft
            </div>
          )}

          {returnToSender && (
            <div className="newUploadFiles_createBtn" onClick={returnToSender}>
              Return to Sender
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BtnGenerateReport;
