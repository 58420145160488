import React from 'react';
import { Button, Typography } from 'antd';
import { Droppable } from 'react-beautiful-dnd';
import { TemplateLafeCarePlanStructure } from '../TemplateLifeCarePlanStructure';

const { Title, Text } = Typography;

const TemplateLifeCarePlanDragStructure = ({
  templateStructure,
  handleDeleteStructure,
  handleSaveStructure,
  listReportType,
  handleDeleteTable,
  onClickNextStep,
}) => {
  return (
    <div className="lifeCarePlanTemplate_structure">
      <Title level={4} className="title-text">
        Generated Report
      </Title>
      <Droppable droppableId="templateStructure" type="FIELD" direction="vertical">
        {(provided, snapshot) => (
          <div
            className={`lifeCarePlanTemplate_structure_box ${snapshot.isDraggingOver ? 'active' : ''}`}
            {...provided.droppableProps}
            ref={provided.innerRef}>
            {templateStructure.length === 0 ? (
              <Text className="lifeCarePlanTemplate_structure_empty">
                Please drag and drop the items you want to be displayed in your report
              </Text>
            ) : (
              <TemplateLafeCarePlanStructure
                dataList={templateStructure}
                onDelete={handleDeleteStructure}
                onSave={handleSaveStructure}
                listReportType={listReportType}
                updateList={handleDeleteTable}
              />
            )}
            {provided.placeholder}
            <div className="lifeCarePlanTemplate_structure_box_btn">
              <Button type="primary" size="default" className="green-btn button" onClick={onClickNextStep}>
                Next Step
              </Button>
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default TemplateLifeCarePlanDragStructure;
