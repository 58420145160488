import React, { useState } from 'react';

// Components
import { Button } from 'antd';

// Icons
import { CheckCircleIcon, CloseCircleIcon } from 'components/Svg';

// Const
import { TYPE_OF_PACKAGE_OPTIONS } from '../../../StepTypeOfPackage/StepTypeOfPackage';
import { PLAN_LIST_INDIVIDUAL, PLAN_LIST_MULTIPLE_USERS } from './planList';

// Colors
import { PRIMARY_LIGHT_GREEN, PRIMARY_RED } from 'containers/Calendar/src/src/lib/utils/colors';

// Style
import './planCard.scss';

const PlanCard = ({ onSubmit, selectedPlan, typePackage, listPricingGlobal }) => {
  const [selectPackage, setSelectPackage] = useState(selectedPlan || '');

  const isMultiple = typePackage === TYPE_OF_PACKAGE_OPTIONS.MULTIPLE_USERS;

  const LIST_PLAN = isMultiple ? PLAN_LIST_MULTIPLE_USERS : PLAN_LIST_INDIVIDUAL;

  return (
    <div className="planCard_container">
      <div className="planCard_list">
        {LIST_PLAN.map(plan => (
          <div
            className={`planCard ${plan.id === selectPackage ? 'planCard_active' : ''}`}
            key={plan.id}
            onClick={() => setSelectPackage(plan.id)}>
            <div className="planCard_name">{plan.name}</div>

            {plan.description && <div className="planCard_name mt-3">{plan.description}</div>}

            {plan.cost && (
              <div className="planCard_cost">
                ${listPricingGlobal.find(item => item.id === plan.id)?.price || 0} <span>per month</span>
              </div>
            )}

            {plan.users && <div className="planCard_name">Up to {plan.users} Writers</div>}
            {plan.extra_cost && (
              <div className="planCard_feature_name_additional">${listPricingGlobal.find(item => item.id === plan.id_extra)?.price || 0}/mo per additional Writer</div>
            )}

            <div className="planCard_features">
              {plan.features &&
                plan.features.map(feature => (
                  <div className="planCard_feature" key={feature.name}>
                    <div className="planCard_feature_name">
                      {feature.allowed ? (
                        <CheckCircleIcon color={PRIMARY_LIGHT_GREEN} />
                      ) : (
                        <CloseCircleIcon color={PRIMARY_RED} />
                      )}
                      {feature.name}
                    </div>
                    <ul className="planCard_feature_description">
                      {feature.descriptions &&
                        feature.descriptions.map(description => <li key={description}>{description}</li>)}
                    </ul>
                  </div>
                ))}
            </div>

            <div className="planCard_btn">
            {plan?.trialFree && (
              <h4>{plan.trialFree}</h4>
            )}
              <Button
                type="primary"
                size="default"
                className="stepNextButton"
                disabled={selectPackage !== plan.id}
                onClick={() => onSubmit({ selectedPlan: plan.id })}>
                Select
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanCard;
