import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Button, Divider, Modal, Tooltip } from 'antd';
import SpinBox from 'components/SpinBox';
import DeleteModal from 'components/DeleteModal';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import MedicareCostModal from 'components/MedicareCostModal';
import MedicareCostTable from 'components/MedicareCostTable';
import PrescriptionTable from 'components/PrescriptionTable';
import { getPosition, get2NewPositions } from '../../../../utils/shared';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import moment from 'moment';
import {
  MCP_MEDICARE,
  ACTION_TYPE_ADD_MEDICARE,
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  PRESCRIPTION_MEDICATIONS_COVERED,
  DONT_CHANGE_COST_ON_THIS_TYPE,
} from 'components/MedicareCostModal/data';

import './MedicareCost.scss';
import RecalculateMedicareCost from 'components/RecalculateMedicareCost';
import { REPORT_BI_DEMAND_1_TYPE_ID } from 'constants/reports';

const { Title, Text } = Typography;

class MedicareCost extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    this.state = {
      report_id,
      contactsList: [],
      medicareDataList: [],
      cptList: [],
      unitFormList: [],
      specialityList: [],
      actionType: ACTION_TYPE_ADD_MEDICARE,
      selectedItem: null,
      showMedicareModal: false,
      loadingTitle: 'Loading...',
      loading: false,
      showRecalculateModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getSpeciality().then(res => {
      this.setState({
        specialityList: res.specialities,
      });
    });
    API.getCPTData().then(res => {
      this.setState({
        cptList: res.cpt_codes,
      });
    });
    API.getReportById(report_id)
      .then(res => {
        const resUpdate = moment(res.updatedAt).format('YYYY-MM-DDDD');
        const resCreate = moment(res.createdAt).format('YYYY-MM-DDDD');

        if (
          !res.is_template &&
          res.report_status_id === 1 &&
          res.report_template_id > 0 &&
          !res._medicare_costs &&
          !res.pricing_group_id &&
          !res.zip_url &&
          resUpdate === resCreate
        ) {
          this.setState({
            showRecalculateModal: true,
            is_template: res.is_template,
          });
        } else {
          this.setState({
            is_template: res.is_template,
          });
        }
        if (!res.is_template) {
          verifyPermissions(res, this.props);
        }

        this.props.updateReportInfo(res);
      })
      .catch(err => forbiddenRedirect(err, this.props));
    API.getUnitForms().then(res => {
      this.setState({
        unitFormList: res.unit_forms,
      });
    });
    API.getMedicareData({ reportId: report_id }).then(res => {
      let beforePosition = -1;
      let medicareSorted = [
        ...res.medicare_cost_projections
          .sort((a, b) => a.position - b.position)
          .map(item => {
            const position =
              beforePosition !== -1 && item.position <= beforePosition ? beforePosition + 1 : item.position;
            beforePosition = position;
            return {
              ...item,
              position,
            };
          }),
      ];
      this.setState({
        medicareDataList: medicareSorted,
        loading: false,
      });
    });
  }

  openMedicareModal = (actionType, selectedItem) => {
    this.setState({
      actionType,
      selectedItem,
      showMedicareModal: true,
    });
  };

  closeMedicareModal = () => {
    this.setState({ showMedicareModal: false });
  };

  handleChangePosition = (id, move) => {
    let result = get2NewPositions(this.state.medicareDataList, id, move);
    if (result.success) {
      let Promise1 = API.updateMedicareItem(result.update1);
      let Promise2 = API.updateMedicareItem(result.update2);
      Promise.all([Promise1, Promise2]).then(() => {
        // item.classList.remove("animate-row");
        // item = document.getElementById("table-item-" + id);
        // item.classList.add("animate-row");
        this.setState({ medicareDataList: result.newArr }, () => {
          const item2 = document.getElementById('table-item-' + result.update1.id + result.update1.position);
          const item1 = document.getElementById('table-item-' + result.update2.id + result.update2.position);

          item2.classList.remove('animate-' + (move === 'up' ? 'down' : 'up'));
          item1.classList.remove('animate-' + move);
        });
      });
    }
  };
  handleMedicareCost = (params, position) => {
    const { actionType, report_id, selectedItem } = this.state;

    this.closeMedicareModal();
    this.setState({ loading: true });
    if (actionType === ACTION_TYPE_ADD_MEDICARE) {
      let position = getPosition(this.state.medicareDataList, params.type);
      const param = {
        ...params,
        position,
        report_id,
      };
      API.createMedicareItem(param)
        .then(res => {
          API.getMedicareData({ reportId: report_id }).then(res => {
            this.setState({
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch(err => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: 'Error',
              });
            }
          );
        });
    } else {
      const param = {
        ...params,
        id: selectedItem.id,
      };
      API.updateMedicareItem(param)
        .then(res => {
          API.getMedicareData({ reportId: report_id }).then(res => {
            this.setState({
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch(err => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: 'Error',
              });
            }
          );
        });
    }
  };

  handleEdit = () => {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.updateMedicareItem(selectedItem.id)
      .then(res => {
        API.getMedicareData({ reportId: report_id }).then(res => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch(err => {
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: 'Error',
            });
          }
        );
      });
  };

  handleDelete = () => {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.deleteMedicareItem(selectedItem.id).then(res => {
      API.getMedicareData({ reportId: report_id }).then(res => {
        this.setState({
          medicareDataList: res.medicare_cost_projections,
          loading: false,
        });
      });
    });
  };

  searchCodes = value => {
    API.getCPTData(value).then(res => {
      this.setState({
        cptList: res.cpt_codes,
      });
    });
  };

  openDeleteModal = selectedItem => {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  handleClone = item => {
    const { report_id } = this.state;
    if (this.state.loadingClone) {
      alert('Cloning in Process, please wait! ');
      return;
    }
    this.setState({ loadingClone: true });
    let position = getPosition(this.state.medicareDataList, item.type);
    let newItem = { ...item, position };
    delete newItem.id;
    delete newItem.updatedAt;
    delete newItem.createdAt;
    delete newItem.deletedAt;
    delete newItem.cpt_code;
    API.createMedicareItem(newItem)
      .then(res => {
        this.setState({ loadingClone: false });
        API.getMedicareData({ reportId: report_id }).then(res => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch(err => {
        this.setState({ loadingClone: true });
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: "Error, can't clone the item",
            });
          }
        );
      });
  };
  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  loadMedicare = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getMedicareData({ reportId: report_id }).then(res => {
      this.setState({
        medicareDataList: res.medicare_cost_projections,
        loading: false,
      });
    });
  };

  closeRecalculateModal = () => {
    this.setState({ showRecalculateModal: false });
  };

  render() {
    const {
      showMedicareModal,
      selectedItem,
      showDeleteModal,
      actionType,
      medicareDataList,
      cptList,
      unitFormList,
      specialityList,
      loading,
      showRecalculateModal,
    } = this.state;

    const { reportInfo } = this.props;

    const futureList = medicareDataList.filter(item => {
      return item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE;
    });
    const surgeriesList = medicareDataList.filter(item => {
      return item.type === DONT_CHANGE_COST_ON_THIS_TYPE;
    });
    const prescriptionList = medicareDataList.filter(item => {
      return item.type === PRESCRIPTION_MEDICATIONS_COVERED;
    });

    const isStateNotSet =
      !reportInfo.is_template &&
      // descomentar
      reportInfo.report_type_id !== REPORT_BI_DEMAND_1_TYPE_ID;

    // borrar
    // reportInfo.report_type_id !== 1;

    return (
      <Row className="msa-medicare-cost-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup BI Demand #1
            </Title>
          </Col>
          <Col className="step">
            <DropDown loading={loading} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <Text className="title">Medical Cost Projection</Text>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="row">
                <Tooltip
                  title={
                    isStateNotSet
                      ? 'Set State of Jurisdiction or Choose OWCP Pricing option on Demographic step '
                      : 'Add Medical Cost item'
                  }>
                  <Button
                    disabled={isStateNotSet}
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={() => this.openMedicareModal(ACTION_TYPE_ADD_MEDICARE, null)}>
                    Add Medical Cost
                  </Button>
                </Tooltip>
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>1. Medical Costs</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  isMcp={true}
                  data={futureList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>2. Surgeries, Replacements and Procedures</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  isMcp={true}
                  data={surgeriesList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>3. Prescription Drug Data</Text>
                </Row>
                <PrescriptionTable
                  isMcp={true}
                  data={prescriptionList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                />
              </Row>
              <Divider className="divider" />
              <PrevNextStep />
            </Row>
          </SpinBox>
        </Row>
        <MedicareCostModal
          searchCodes={this.searchCodes}
          isOpen={showMedicareModal}
          actionType={actionType}
          modalType={MCP_MEDICARE}
          specialityList={specialityList}
          cptList={cptList}
          unitFormList={unitFormList}
          selectedItem={this.state.selectedItem}
          reportData={reportInfo}
          onConfirm={this.handleMedicareCost}
          onCancel={this.closeMedicareModal}
        />
        <DeleteModal
          isOpen={showDeleteModal}
          title="Delete Medicare Item"
          content={selectedItem && selectedItem.name}
          onConfirm={this.handleDelete}
          onCancel={this.closeDeleteModal}
        />
        <RecalculateMedicareCost
          reportData={reportInfo}
          isOpen={showRecalculateModal}
          medicareList={medicareDataList}
          onCancel={this.closeRecalculateModal}
          loadMedicare={this.loadMedicare}
          isMedicare={true}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
};

export default connect(mapStateToProps, { updateReportInfo })(MedicareCost);
