// import { React } from "react";
// import { Route } from "react-router-dom";
// import SurgicalDemographic from "../containers/Reports/Surgical/Demographic";
import SurgicalMedicalContent from "../containers/Reports/Surgical/MedicalContent";
import SurgicalMedicareCost from "../containers/Reports/Surgical/MedicareCost";
import SurgicalMedicareTotals from "../containers/Reports/Surgical/MedicareTotals";
import SurgicalDocumentUpload from "../containers/Reports/Surgical/DocumentUpload";
import { DesingReportPage } from "containers/Reports/GeneralPage/DesingReportPage";

export default [
  {
    path: "/app/reports/:id/surgical/medicalContent",
    component: SurgicalMedicalContent,
  },

  // {
  //   path: "/app/reports/:id/surgical/demographic",
  //   component: SurgicalDemographic,
  // },
  {
    path: "/app/reports/:id/surgical/medicareCost",
    component: SurgicalMedicareCost,
  },
  {
    path: "/app/reports/:id/surgical/medicareTotals",
    component: SurgicalMedicareTotals,
  },
  {
    path: "/app/reports/:id/surgical/documentUpload",
    component: SurgicalDocumentUpload,
  },
  {
    path: "/app/reports/:id/surgical/design",
    component: DesingReportPage,
  },
];
