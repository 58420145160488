import React, {useRef} from 'react';
import { Upload } from 'antd';

import './boxDragAndDrop.scss';

const BoxDragAndDrop = ({ handleAddFiles, beforeUpload, multiple, allFile, input }) => {
  const inputFileRef = useRef(null)
  const hanldeChangeInputFile = e => {
    handleAddFiles({ file: e.target.files });

    inputFileRef.current.value = null;
  };

  return (
    <div className="boxDragAndDrop">
      {input ? (
        <>
          <div className="modalUploadFile_text">
            <p>Drag and Drop files here</p>
            <p>or</p>
            <span>Browse Files</span>
          </div>
          <input ref={inputFileRef} multiple={multiple} type="file" onChange={hanldeChangeInputFile} />
        </>
      ) : (
        <Upload
          name="logo"
          accept={allFile ? '' : 'application/pdf'}
          listType="text"
          className="logo-uploader"
          multiple={multiple}
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload && beforeUpload}
          onChange={info => {
            handleAddFiles({ file: info.file });
          }}>
          <div className="modalUploadFile_text">
            <p>Drag and Drop files here</p>
            <p>or</p>
            <span>Browse Files</span>
          </div>
        </Upload>
      )}
    </div>
  );
};

export default BoxDragAndDrop;
