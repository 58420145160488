import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Typography } from 'antd';
import moment from 'moment';

import { EditIcon } from '../Icons';
import * as API from 'utils/api';

import './ClaimantInfo.scss';

const { Text } = Typography;

class ClaimantInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      injuryDates: [],
      lifeSources: [],
      lifeTables: [],
      life_expectancy: '',
      updating: true,
    };
  }

  getInjuryDates(all) {
    if (!all) {
      return;
    }
    if (all.length === 0) {
      var injuryDates = [];
      injuryDates.push(moment().format('MM/DD/YYYY'));
      this.setState({
        injuryDates,
      });
    }
    if (all && !this.props.surgical) {
      if (all.length == 0) {
        var injuryDates = [];
        injuryDates.push(moment().format('MM/DD/YYYY'));
        this.setState({
          injuryDates,
        });
      } else {
        var arrayFromComma = all.split(',');
        var injuryDates = [];
        arrayFromComma.map(el => {
          if (el.split('$')[0]) {
            injuryDates.push(moment(el.split('$')[0] * 1000).format('MM/DD/YYYY'));
          }
        });
        this.setState({
          injuryDates,
        });
      }
    }
  }

  componentDidUpdate = () => {
    const { reportInfo } = this.props;
    const { lifeSources, lifeTables, life_expectancy, updating } = this.state;

    if (reportInfo.claimant_id && lifeSources.length && lifeTables.length && !life_expectancy && updating) {
      const param = {
        source_id: lifeSources[0].id,
        life_table_id: lifeTables[0].id,
      };
      API.getLifeExpect(reportInfo.claimant_id, param)
        .then(res => {
          this.setState({ life_expectancy: Math.round(res.years_left) , updating: false});
        })
        .catch(err => {
          this.setState({ life_expectancy: '', updating: false });
        });
    }
  };

  componentDidMount() {
    this._isMounted = true;

    API.getLifeSources().then(res => {
      this.setState({
        lifeSources: res.life_expects,
      });
    });
    API.getLifeTables().then(res => {
      this.setState({
        lifeTables: res.life_expects,
      });
    });

    setTimeout(() => {
      if (!this._isMounted) return;
      var all = this.props.claimant.report_claim_id;
      this.getInjuryDates(all);
    }, 750);
  }

  componentWillReceiveProps(nextprops) {
    var all = nextprops.claimant.report_claim_id;
    this.getInjuryDates(all);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.props.reportInfo.is_template) {
      return <></>;
    }
    var claimant = this.props.claimant;
    var first_name = claimant.claimant_name ? claimant.claimant_name : '';
    var middle_name = claimant.claimant_middle_name ? claimant.claimant_middle_name : '';
    var last_name = claimant.claimant_last_name ? claimant.claimant_last_name : '';
    return (
      <Col md={24} style={{ width: '100%', marginBottom: 25 }}>
        <Row>
          <Text className="title">Claimant Info</Text>
          {this.props.goToEditClaimant && <EditIcon className="icon" onClick={this.props.goToEditClaimant} />}
        </Row>
        <Row type="flex" justify="space-between" align="middle" className="row-custom">
          <Col md={11}>
            <Text>Name:{` ${first_name} ${middle_name} ${last_name}`}</Text>
          </Col>
          <Col md={11}>
            <Text>
              Date of Birth: &nbsp;
              {this.props.claimant && claimant.dob && moment(claimant.dob).format('MM/DD/YYYY')}
            </Text>
          </Col>
          <Col md={2}>
            <Text>
              Age: &nbsp;
              {this.props.claimant && claimant.dob && moment().diff(moment(claimant.dob), 'years')}
            </Text>
          </Col>
        </Row>

        {!this.props.surgical && (
          <Row type="flex" justify="space-between" align="top" className="row-custom">
            <Col md={11}>
              <Text>
                Life expectancy: &nbsp;
                {this.state.life_expectancy ? this.state.life_expectancy : '-'}
                <br />
                Rated Age: &nbsp;
                {'  ' + this.props.claimant.rated_age ? this.props.claimant.rated_age : ''}
              </Text>
            </Col>
            <Col md={11}>
              <div className="date-injury">
                Date of Injury: &nbsp;
                {this.props.claimant &&
                  this.state.injuryDates.map((el, index) => {
                    return <Text key={`dateinj-${index}`}>{el + '\xa0\xa0\xa0'}</Text>;
                  })}
              </div>
            </Col>
            <Col md={2} />
          </Row>
        )}
      </Col>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    reportInfo: state.report.reportInfo,
  };
  return props;
};
export default connect(mapStateToProps, {})(ClaimantInfo);
