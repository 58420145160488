import * as types from './actionTypes';
import dayjs from 'dayjs';

const lastActiveAt = localStorage.getItem('lastActiveAt');

const initialState = {
  settings: {
    inflation: '1',
    interest_rate: '3.2',
    interest_period_per_year: '0.01',
  },
  isLoading: false,
  isNewPasswordRequest: false,
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  userInfo: {},
  errorMessage: '',
  roles: [],
  areRolesFetched: false,
  lastActiveAt: lastActiveAt ? dayjs(lastActiveAt) : null,
  isActiveReviewer: localStorage.getItem('isActiveReviewer') === 'true',
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ROLES:
      return { ...state, roles: action.roles, areRolesFetched: true };
    case types.IS_ACTIVE_REVIEWER:
      return {
        ...state,
        isActiveReviewer: action.activeReviewer,
      };
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isNewPasswordRequest: action.isNewPasswordRequest,
        userInfo: action.userInfo,
        isLoading: false,
        lastActiveAt: action.signInTime,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        lastActiveAt: null,
        errorMessage: action.errorMessage,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        lastActiveAt: null,
      };
    case types.SEND_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case types.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.SEND_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.SET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case types.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isNewPasswordRequest: false,
      };
    case types.SET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_USER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case types.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfo: action.userInfo,
      };
    case types.GET_USER_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.SET_LAST_ACTIVE:
      return {
        ...state,
        lastActiveAt: action.payload,
      };
    default:
      return state;
  }
};

export default auth;
