import React from 'react';
import './CustomCheckBox.scss';

const CustomCheckBox = ({ optionSignature, onChange, label, htmlId, value, disabled }) => (
  <label htmlFor={htmlId} className="customCheckBox">
    <div className={`circleCheckBox ${optionSignature === value ? 'circleCheckBox_active' : ''}`} />
    {label && label}
    <input
      disabled={disabled}
      type="checkbox"
      id={htmlId}
      checked={optionSignature === value}
      onChange={() => onChange(value)}
    />
  </label>
);

export default CustomCheckBox;
