import {
  REVIEWS_GET_ASSIGNMENT,
  REVIEWS_GET_USER_ASSIGNMENTS,
  REVIEWS_LOADING_END,
  REVIEWS_LOADING_START,
  REVIEWS_PENDING_END,
  REVIEWS_PENDING_START,
  REVIEWS_UPDATE_HIGHLIGHTS,
  REVIEWS_UPDATE_ASSIGNMENT_ID
} from './actionTypes';
import {
  fetchAssignmentById,
  fetchAssignments,
  updateAssignmentById as apiUpdateAssignment,
} from '../../utils/api-reviews';
import { notifyApiError } from '../../utils/notification';

export const onLoadingAction = {
  type: REVIEWS_LOADING_START,
};

export const offLoadingAction = {
  type: REVIEWS_LOADING_END,
};

export const onPendingAction = {
  type: REVIEWS_PENDING_START,
};

export const offPendingAction = {
  type: REVIEWS_PENDING_END,
};

export const updateHighlights = highlights => ({
  type: REVIEWS_UPDATE_HIGHLIGHTS,
  payload: highlights,
});

export const updateAssignmentId = id => ({
  type: REVIEWS_UPDATE_ASSIGNMENT_ID,
  payload: id,
});

export const getAssignments = queryData => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const data = await fetchAssignments(queryData);
    dispatch({ type: REVIEWS_GET_USER_ASSIGNMENTS, payload: data });
  } catch (e) {
    console.error(e);
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction);
  }
};

export const getAssignmentById = id => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const data = await fetchAssignmentById(id);
    dispatch({ type: REVIEWS_GET_ASSIGNMENT, payload: data });
  } catch (e) {
    console.error(e);
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction);
  }
};

export const updateAssignmentById = (id, body) => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const data = await apiUpdateAssignment(id, body);
    dispatch({ type: REVIEWS_GET_ASSIGNMENT, payload: data });
    return true;
  } catch (e) {
    console.error(e);
    notifyApiError(e);
    return false;
  } finally {
    dispatch(offLoadingAction);
  }
};

export const setHighlights = (id, highlights) => async dispatch => {
  try {
    dispatch(onPendingAction);
    await apiUpdateAssignment(id, { highlights });
    dispatch(updateHighlights(highlights));
    return true;
  } catch (e) {
    notifyApiError(e);
    return false;
  } finally {
    dispatch(offPendingAction);
  }
};
