import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Col, Divider, Input, Modal, Row, Typography, DatePicker } from 'antd';
import TemplateFields from 'components/TemplateFields';
import InputCurrencyBox from 'components/InputCurrencyBox';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { displayData, openDrawer } from '../../../../redux/Drawer/DrawerActions';
import { forbiddenRedirect } from '../../utils/verify-permissions';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';
import InputBox from 'components/InputBox';
import InputMask from 'react-input-mask';
import { EditIcon, RemoveIcon } from 'components/Icons/';
import { REPORT_MSA_REDUCTION_TYPE_ID } from 'constants/reports';
import { STATUS_REDUCTION_REQUESTED } from 'constants/report_status';
import { formatMoneyToNumber } from 'utils/utils';
import { createViewReportIfNotExists } from 'utils/report';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { SignatureReport } from 'containers/Reports/components/SignatureReport';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';
import { getListSignature, disableFutureDay } from 'containers/Reports/utils/helpers';
import { validatedFormatEmail } from 'utils/utils';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

import './MedicalReduction.scss';
import { CLAIMANT_EDIT } from 'layout/navigate_const';

const { Title, Text } = Typography;

class Demographic extends Component {
  constructor(props) {
    super(props);

    let report_id = '';

    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      icd_code: '',
      icd_descr: '',
      isModalOpen: false,
      loadingTitle: 'Loading...',
      loading: false,
      employerList: [],
      is_template: false,
      insurance_company: '',
      dob: null,
      date_of_injury: moment(),
      SSN: '',
      submitter_attorney_name: '',
      submitter_attorney_firm_name: '',
      submitter_attorney_phone: '',
      submitter_attorney_email: '',
      present_value_case: '',
      actual_settlement_amount: '',
      policy_limits: '',
      total_attorney_fees: '',
      disbursements: '',
      openModal: '',
      modalDataRecord: {},
      list_record: [],
      isEditRecord: null,
      signature: [],
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });

    let searchCopy = false;

    // si eres Internal QA buscamos la copia del reporte si no existe la creamos
    if (isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY)) {
      await createViewReportIfNotExists({ reportId: report_id });
      searchCopy = true;
    }

    API.getReportByIdReduction({ report_id, copy: searchCopy })
      .then(reportInfo => {
        this.props.updateReportInfo(reportInfo);
        this.setState({
          is_template: reportInfo.is_template,
          report_template_id: reportInfo.report_template_id,
          loading: false,
          insurance_company: reportInfo.insurance_company || '',
          dob: moment(this.props.reportInfo?.claimant?.dob || ''),
          date_of_injury: reportInfo.date_of_injury ? moment(reportInfo.date_of_injury) : moment(),
          SSN: this.props.reportInfo?.claimant?.SSN || '',
          submitter_attorney_name: reportInfo.submitter_attorney_name || '',
          submitter_attorney_firm_name: reportInfo.submitter_attorney_firm_name || '',
          submitter_attorney_phone: reportInfo.submitter_attorney_phone || '',
          submitter_attorney_email: reportInfo.submitter_attorney_email || '',
          present_value_case: reportInfo.present_value_case || '',
          actual_settlement_amount: reportInfo.actual_settlement_amount || '',
          policy_limits: reportInfo.policy_limits || '',
          total_attorney_fees: reportInfo.total_attorney_fees || '',
          disbursements: reportInfo.disbursements || '',
          list_record: reportInfo.list_record || [],
          signature: getListSignature({ signature: reportInfo.signature }),
        });
      })
      .catch(err => {
        forbiddenRedirect(err, this.props);
      });
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  sumListRecords = () => {
    return this.state.list_record
      .map(item => parseFloat(item.total_lien))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  };

  handleUpdateReport = async (isNextStep = false) => {
    const {
      report_id,
      insurance_company,
      dob,
      date_of_injury,
      SSN,
      submitter_attorney_name,
      submitter_attorney_firm_name,
      submitter_attorney_phone,
      submitter_attorney_email,
      present_value_case,
      actual_settlement_amount,
      policy_limits,
      total_attorney_fees,
      disbursements,
      list_record,
      signature,
    } = this.state;

    const params = {
      id: report_id,
      insurance_company,
      dob,
      date_of_injury: moment(date_of_injury).format('MM/DD/YYYY'),
      SSN,
      submitter_attorney_name,
      submitter_attorney_firm_name,
      submitter_attorney_phone,
      submitter_attorney_email,
      present_value_case: formatMoneyToNumber({ money: present_value_case }),
      actual_settlement_amount: formatMoneyToNumber({ money: actual_settlement_amount }),
      policy_limits: formatMoneyToNumber({ money: policy_limits }),
      total_attorney_fees: formatMoneyToNumber({ money: total_attorney_fees }),
      disbursements: formatMoneyToNumber({ money: disbursements }),
      list_record,
      total_liens: formatMoneyToNumber({
        money: this.sumListRecords() || 0,
      }),
      signature: signature.map(item => {
        delete item.imageUrl;
        return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
      }),
    };

    if (this?.props?.location?.state?.data?.resquestedReduction) {
      params.report_type_id = REPORT_MSA_REDUCTION_TYPE_ID;
      params.report_status_id = STATUS_REDUCTION_REQUESTED.id;
      params.claimant_id = this.props.location.state.data.claimantInfo.id;
    }

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        res = await API.updateReportClient(params);
        clearTimeout(this.timeoutId);
      } else {
        res = await API.updateReportClient(params);
      }
      if (res.status === 201) {
        notifyInfo('', res.message);
      }
      this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  goToEditClaimant = () => {
    const claimant = this.props.reportInfo.claimant;
    this.props.history.push(CLAIMANT_EDIT.path, { data: claimant });
  };

  handleAddRecord = () => {
    const addList = [...this.state.list_record, this.state.modalDataRecord];
    this.setState({
      list_record: addList.map(item => ({
        ...item,
        total_lien: formatMoneyToNumber({ money: item.total_lien }),
        lien_amount: formatMoneyToNumber({ money: item.lien_amount }),
      })),
      openModal: '',
      modalDataRecord: {},
      isEditRecord: null,
    });
  };

  handleUpdateRecord = () => {
    const new_list_record = Array.from(this.state.list_record);
    new_list_record[this.state.isEditRecord] = this.state.modalDataRecord;

    this.setState({
      list_record: new_list_record.map(item => ({
        ...item,
        total_lien: parseFloat(item.total_lien).toFixed(2),
        lien_amount: parseFloat(item.lien_amount).toFixed(2),
      })),
      openModal: '',
      modalDataRecord: {},
      isEditRecord: null,
    });
  };

  handleCloseModalRecord = () => {
    this.setState({
      openModal: '',
      modalDataRecord: {},
      isEditRecord: null,
    });
  };
  handleOpenEditRecord = ({ data: { provider_name, total_lien, lien_amount }, index }) => {
    this.setState({
      openModal: 'record',
      modalDataRecord: {
        provider_name,
        total_lien: parseFloat(total_lien).toFixed(2),
        lien_amount: parseFloat(lien_amount).toFixed(2),
      },
      isEditRecord: index,
    });
  };

  handleRemoveRecord = ({ index }) => {
    const new_list_record = Array.from(this.state.list_record);
    new_list_record.splice(index, 1);
    this.setState({ list_record: new_list_record });
  };

  handleChangeSignature = data => {
    this.setState(data);
  };

  handleDeleteSignature = ({ index }) => {
    this.setState({ signature: this.state.signature.filter((item, inde) => inde !== index) });
  };

  handleAddSignature = () => {
    const add = [...this.state.signature, INIT_SIGNATURE];
    this.setState({ signature: add });
  };

  render() {
    const {
      icd_code,
      icd_descr,
      isModalOpen,
      loadingTitle,
      loading,
      is_template,
      insurance_company,
      dob,
      date_of_injury,
      SSN,
      submitter_attorney_name,
      submitter_attorney_firm_name,
      submitter_attorney_phone,
      submitter_attorney_email,
      present_value_case,
      actual_settlement_amount,
      policy_limits,
      total_attorney_fees,
      disbursements,
      openModal,
      modalDataRecord,
      list_record,
      isEditRecord,
      signature,
      REPORT_PERMISSIONS,
    } = this.state;

    // disbable del boton
    const notValid =
      !!insurance_company &&
      !!dob._isValid &&
      !!date_of_injury._isValid &&
      !!submitter_attorney_name &&
      !!submitter_attorney_firm_name &&
      !!submitter_attorney_phone &&
      !!present_value_case &&
      !!actual_settlement_amount &&
      !!policy_limits &&
      !!total_attorney_fees &&
      !!disbursements &&
      !!list_record.length > 0 &&
      !!validatedFormatEmail({ email: submitter_attorney_email }) &&
      !!signature;

    // disable btn modal add
    const validModal = !modalDataRecord.provider_name || !modalDataRecord.total_lien || !modalDataRecord.lien_amount;

    return (
      <Row className="msa-demographic-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Medicare Set-Aside Reduction Worksheet
            </Title>
          </Col>
          <Col className="step">
            {this.props.history?.location?.state?.data?.assignmentAttorney ? (
              <DropDown loading={loading} disabled={!notValid} goBack={() => this.props.history.goBack()} />
            ) : (
              <DropDown
                beforeNext={async () => await this.handleUpdateReport(false)}
                loading={loading}
                disabled={!notValid}
              />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <InputBox
                    type="text"
                    label="Name of Injured Party"
                    placeholder="Required"
                    value={insurance_company}
                    disabled={!REPORT_PERMISSIONS.editReduction}
                    onChange={value => {
                      this.setState({ insurance_company: value.replace(/[^a-zA-Z\s]/gi, '') });
                    }}
                  />
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>Date of Birth</Text>
                  </Row>
                  <Row>
                    <DatePicker
                      className="MSA_REDUCTION_newDatePicker"
                      format="MM/DD/YYYY"
                      allowClear={false}
                      disabledDate={disableFutureDay}
                      value={dob}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      placeholder="DD / MM / YYYY"
                      onChange={(date, dateString) => this.setState({ dob: date })}
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <Row className="label">
                    <Text>Date of Injury</Text>
                  </Row>
                  <Row>
                    <DatePicker
                      className="MSA_REDUCTION_newDatePicker"
                      format="MM/DD/YYYY"
                      allowClear={false}
                      value={date_of_injury}
                      placeholder="DD / MM / YYYY"
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      disabledDate={disableFutureDay}
                      onChange={(date, dateString) => this.setState({ date_of_injury: date })}
                    />
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>SSN</Text>
                  </Row>
                  <Row>
                    <InputMask
                      mask="999-99-9999"
                      placeholder="Required"
                      className="MSA_REDUCTION_inputMask"
                      value={SSN}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={e => this.setState({ SSN: e.target.value })}
                    />
                  </Row>
                </Col>
              </Row>

              <Divider className="divider" />

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <InputBox
                    type="text"
                    label="Submitter Attorney Name"
                    placeholder="Required"
                    value={submitter_attorney_name}
                    disabled={!REPORT_PERMISSIONS.editReduction}
                    onChange={value => {
                      this.setState({ submitter_attorney_name: value });
                    }}
                  />
                </Col>
                <Col md={11}>
                  <InputBox
                    type="text"
                    label="Firm Name"
                    placeholder="Required"
                    value={submitter_attorney_firm_name}
                    disabled={!REPORT_PERMISSIONS.editReduction}
                    onChange={value => {
                      this.setState({ submitter_attorney_firm_name: value });
                    }}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <Row>
                    <InputMask
                      mask="999-999-9999"
                      placeholder="Required"
                      className="MSA_REDUCTION_inputMask"
                      value={submitter_attorney_phone}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      name="phone"
                      onChange={value => {
                        this.setState({ submitter_attorney_phone: value.target.value });
                      }}
                    />
                  </Row>
                </Col>
                <Col md={11}>
                  <InputBox
                    classes={`input ${
                      submitter_attorney_email && !validatedFormatEmail({ email: submitter_attorney_email })
                        ? 'input_error'
                        : ''
                    }`}
                    type="email"
                    label="Email"
                    placeholder="Required"
                    value={submitter_attorney_email}
                    disabled={!REPORT_PERMISSIONS.editReduction}
                    onChange={value => {
                      this.setState({
                        submitter_attorney_email: value,
                      });
                    }}
                  />
                </Col>
              </Row>

              <Divider className="divider" />

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <Row className="label">
                    <Text>Attorney Estimated Value of Case</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={present_value_case}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={value => this.setState({ present_value_case: value }, this.createFinalAddress)}
                    />
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>Actual Settlement Amount</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={actual_settlement_amount}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={value => this.setState({ actual_settlement_amount: value })}
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <Row className="label">
                    <Text>Applicable Insurance Policy Limits</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={policy_limits}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={value => this.setState({ policy_limits: value }, this.createFinalAddress)}
                    />
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>Total Attorney Fees</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={total_attorney_fees}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={value => this.setState({ total_attorney_fees: value }, this.createFinalAddress)}
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <Row className="label">
                    <Text>Costs/Disbursements</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={disbursements}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={value => this.setState({ disbursements: value }, this.createFinalAddress)}
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Title level={4} className="title-text">
                    Medical Expenses / Lien Resolution
                  </Title>
                </Col>

                {list_record.length > 0 && (
                  <>
                    <Col md={24}>
                      <div className="MSA_REDUCTION_table">
                        <div className="MSA_REDUCTION_gridTemplate">
                          <p>Provider Name</p>
                          <p>Total Lien</p>
                          <p>Resolved Lien Amount</p>
                          <></>
                        </div>

                        {list_record.map((record, index) => (
                          <div key={index} className="MSA_REDUCTION_gridTemplate">
                            <p>{record.provider_name}</p>
                            <p>{`$ ${record.total_lien}`}</p>
                            <p>{`$ ${record.lien_amount}`}</p>
                            {REPORT_PERMISSIONS.editReduction && (
                              <div className="MSA_REDUCTION_tableBtns">
                                <EditIcon
                                  className="icon"
                                  onClick={() => this.handleOpenEditRecord({ data: record, index })}
                                />
                                <RemoveIcon className="icon" onClick={() => this.handleRemoveRecord({ index })} />
                              </div>
                            )}
                          </div>
                        ))}
                        <div className="MSA_REDUCTION_gridTemplate">
                          <p />
                          <Row>
                            <p className="textBold">{'$ ' + this.sumListRecords()}</p>
                            <p>Total</p>
                          </Row>
                          <Row>
                            <p className="textBold">
                              {'$ ' +
                                list_record
                                  .map(item => parseFloat(item.lien_amount))
                                  .reduce((a, b) => a + b, 0)
                                  .toFixed(2)}
                            </p>
                            <p>Total</p>
                          </Row>
                          <p />
                        </div>
                      </div>
                    </Col>
                  </>
                )}

                {REPORT_PERMISSIONS.editReduction && (
                  <Col md={24}>
                    <Button
                      size="large"
                      className="btn_transparent_secundary"
                      onClick={() => this.setState({ openModal: 'record' })}>
                      Add Record
                    </Button>
                  </Col>
                )}
              </Row>

              {(is_template || this.state.report_template_id) && (
                <TemplateFields
                  is_template={is_template}
                  section_id={20}
                  report_template_id={this.state.report_template_id}
                  report_id={this.state.report_id}
                />
              )}

              {REPORT_PERMISSIONS.signature && (
                <SignatureReport
                  defaultSignature={signature}
                  onChangeSignature={this.handleChangeSignature}
                  onDeleteSignature={this.handleDeleteSignature}
                  onAddSignature={this.handleAddSignature}
                  disabled={REPORT_PERMISSIONS.editReduction}
                />
              )}
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                disabled={!notValid}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>

        {openModal === 'record' && (
          <Modal
            visible={true}
            onCancel={this.handleCloseModalRecord}
            footer={null}
            centered={true}
            closable={true}
            width={500}
            className="add-icd-code-modal"
            title={'Medical Expenses / Lien Resolution'}>
            <Row className="modal-body">
              <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
                <Col md={24} className="row">
                  <Row className="label_start">
                    <Text>Provider Name</Text>
                  </Row>
                  <Row>
                    <InputBox
                      placeholder="Required"
                      value={modalDataRecord.provider_name || ''}
                      onChange={value =>
                        this.setState({ modalDataRecord: { ...modalDataRecord, provider_name: value } })
                      }
                      classes="addClaimantInput"
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
                <Col md={11} className="row">
                  <Row className="label_start">
                    <Text>Total Lien</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={modalDataRecord.total_lien || ''}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={value =>
                        this.setState({
                          modalDataRecord: {
                            ...modalDataRecord,
                            total_lien: value,
                          },
                        })
                      }
                    />
                  </Row>
                </Col>
                <Col md={11} className="row">
                  <Row className="label_start">
                    <Text>Resolved Lien Amount</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={modalDataRecord.lien_amount || ''}
                      disabled={!REPORT_PERMISSIONS.editReduction}
                      onChange={value =>
                        this.setState({
                          modalDataRecord: {
                            ...modalDataRecord,
                            lien_amount: value,
                          },
                        })
                      }
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Button
                  disabled={validModal}
                  type="primary"
                  className="green-btn button"
                  onClick={isEditRecord !== null ? this.handleUpdateRecord : this.handleAddRecord}>
                  {isEditRecord !== null ? 'Update' : 'Add'}
                </Button>
                <Button className="button_simple button" onClick={this.handleCloseModalRecord}>
                  cancel
                </Button>
              </Row>
            </Row>
          </Modal>
        )}
        <Modal
          visible={isModalOpen}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={true}
          width={500}
          className="add-icd-code-modal"
          title={'Add Custom Code'}>
          <Row className="modal-body">
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Code</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={icd_code}
                  onChange={e => this.setState({ icd_code: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Description</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={icd_descr}
                  onChange={e => this.setState({ icd_descr: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Button type="primary" className="green-btn button" onClick={this.handleAddNewICDCode}>
                ADD
              </Button>
            </Row>
          </Row>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {
  updateReportInfo,
  displayData,
  openDrawer,
})(Demographic);
