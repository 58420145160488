import React from 'react';
import { CustomCheckBox } from 'components/CustomCheckBox';

const CheckBox = ({ value, label, id, onChange, disabled }) => {
  const handleChange = () => {
    onChange(!value);
  };

  return (
    <CustomCheckBox
      optionSignature={true}
      onChange={handleChange}
      label={label}
      htmlId={`checkBox${id}`}
      value={value}
      disabled={disabled}
    />
  );
};

export default CheckBox;
