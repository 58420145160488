import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Icon, Select } from 'antd';
import InputMask from 'react-input-mask';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { fetchQuickbooksCustomerInfo } from 'utils/api-transactions';
import * as API from 'utils/api';
import { Row, Button, Divider, Modal, Spin } from 'antd';

import '../EditClient.scss';

function BillingInformationTap() {
  const { Option } = Select;
  const history = useHistory();
  const clientInfo = history.location.state?.data || {};
  const [juridictionList, setJuridictionList] = useState([]); 
  const [displayName, setDisplayName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAdress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [loading, setLoading] = useState(true);

  const getStates = async () => {
    let juridictionRes = await API.getStates();
    return setJuridictionList(juridictionRes.data.states);
  };

  const getBillingInfo = async () => {
    const data = await fetchQuickbooksCustomerInfo(clientInfo.quickbooks_customer_id);
    setDisplayName(data.DisplayName);
    setCompanyName(data.CompanyName);
    setEmail(data.PrimaryEmailAddr?.Address);
    setPhone(data.PrimaryPhone?.FreeFormNumber);
    setAdress(data.BillAddr?.Line1);
    setCity(data.BillAddr?.City);
    setState(data.BillAddr?.CountrySubDivisionCode);
    setZip(data.BillAddr?.PostalCode);
    return;
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        if (clientInfo.quickbooks_customer_id) {
          await getBillingInfo();
        }
        await getStates();
      } catch (e) {
        Modal.error({
          content: e.response?.data?.message || 'Error getting Quickbooks Customer Info',
        });
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    const params = {
      id: clientInfo.id,
      set_quickbooks: true,
      quickbooks_customer_id: clientInfo.quickbooks_customer_id,
    };

    params.fields = {
      displayName,
      companyName,
      email,
      phone,
      address,
      city,
      state,
      zip,
    };

    try {
      setLoading(true);
      if (params.quickbooks_customer_id) {
        await API.updateClient(params);
      } else {
        await API.setQuickbook(params);
      }

      notifyInfo('Client updated');
      history.push('/app/clients');
    } catch (e) {
      notifyApiError(e);
      setLoading(false);
    }
  };

  const fieldRequired = displayName && companyName && email && phone && address && city && state && zip;

  return (
    <Spin spinning={loading}>
      <form onSubmit={onSubmit}>
        <div className="msa-stepContainer">
          <div className="stepFormContainer">
            <div className="stepFormGroup">
              <label htmlFor="displayName">Billing Contact Name</label>
              <input
                onChange={e => setDisplayName(e.target.value)}
                type="text"
                name="displayName"
                id="displayName"
                placeholder="Required"
                required
                value={displayName}
              />
            </div>
            <div className="stepFormGroup">
              <label htmlFor="companyName">Company Name</label>
              <input
                onChange={e => setCompanyName(e.target.value)}
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Required"
                required
                value={companyName || ''}
              />
            </div>
            <div className="stepFormGroup">
              <label htmlFor="email">Billing Contact Email </label>
              <input
                onChange={e => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
                placeholder="Required"
                required
                value={email}
              />
            </div>
            <div className="stepFormGroup">
              <label htmlFor="phone">Phone Number</label>
              <InputMask
                mask="999-999-9999"
                placeholder="Required"
                value={phone}
                name="phone"
                id="phone"
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className="stepFormGroup stepCol2">
              <label htmlFor="address">Billing Address</label>
              <input
                onChange={e => setAdress(e.target.value)}
                type="text"
                name="address"
                id="address"
                placeholder="Required"
                required
                value={address}
              />
            </div>
            <div className="stepFormGroup">
              <label htmlFor="city">City</label>
              <input
                onChange={e => setCity(e.target.value)}
                type="text"
                value={city}
                name="city"
                id="city"
                placeholder="Required"
                required
              />
            </div>
            <div className="stepFormContainer">
              <div className="stepFormGroup">
                <label htmlFor="state">State</label>
                <Select
                  showSearch
                  placeholder="Please select"
                  optionFilterProp="children"
                  className="dropdown inputSelect"
                  value={state}
                  onChange={value => setState(value)}
                  name="state"
                  suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                  <Option disabled value="">
                    Required
                  </Option>
                  {juridictionList.map((el, i) => {
                    return (
                      <Option key={i} value={el.name}>
                        {el.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="stepFormGroup">
                <label htmlFor="zip">Zip Code</label>
                <InputMask
                  mask="99999"
                  placeholder="Required"
                  name="zip"
                  id="zip"
                  value={zip}
                  onChange={e => setZip(e.target.value)}
                />
              </div>
            </div>
          </div>
          <Divider className="divider-space" />
          <Row type="flex" justify="center">
            <Button
              disabled={!fieldRequired}
              type="primary"
              htmlType="submit"
              size="large"
              className="green-btn button">
              Save
            </Button>
          </Row>
        </div>
      </form>
    </Spin>
  );
}

export default BillingInformationTap;
