import React, { Component } from 'react';
import { Row, Col, Typography, Input, Empty, Select, Icon, Modal } from 'antd';
import moment from 'moment';

import { BackIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import Pagination from 'components/Pagination';
import * as API from 'utils/api';

import './Assignments.scss';

const { Search } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

export default class Assignments extends Component {
  constructor() {
    super();

    this.state = {
      pageIndex: 0,
      searchQuery: '',
      assignmentsData: [],
      totalCount: 0,
      querySearched: false,
      loadingTitle: 'Loading...',
      loading: false,
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handlePagination = pageIndex => {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  };

  getStatusColor = item => {
    // let now = moment();
    // let dueDate = moment(item.end_date);
    // let diff = dueDate.diff(now, "days");
    // if (diff < 0) {
    //   return "red";
    // } else if (diff <= 5) {
    //   return "yellow";
    // } else {
    //   return "green"
    // }
    if (item.status_id === 1) return 'red';
    else return 'green';
  };

  handleSearch = value => {
    const { searchQuery, pageIndex, querySearched  } = this.state;
    this.setState({ loading: true, loadingTitle: 'Loading...' });
    let index = searchQuery ? 0 : pageIndex;
    API.getAssignmentsData(searchQuery, index).then(res => {
      if(searchQuery && searchQuery !== '' && !querySearched){
        this.setState({ querySearched : true });
        this.setState({ pageIndex: 0 })
      } else {
        this.setState({ querySearched : false });
      }
      this.setState({
        assignmentsData: res.notes,
        totalCount: res.total_count,
        loading: false,
      });
    });
  };

  handleUpdateAssignment = (id, value) => {
    const params = {
      id: id,
      status_id: value,
    };

    this.setState({
      loading: true,
      loadingTitle: 'Saving...',
    });
    API.updateAssignment(params)
      .then(res => {
        this.setState({ loading: false });
        Modal.success({
          content: 'Success',
          onOk: () => this.handleSearch(),
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason ? error.response.data.reason.message : 'Error';
        Modal.error({
          content: errorMessage,
        });
      });
  };

  render() {
    const { pageIndex, totalCount, assignmentsData, searchQuery, loadingTitle, loading } = this.state;

    return (
      <Row className="assignments-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Assignments
            </Title>
          </Col>
          <Col className="back-icon">
            <BackIcon className="icon" onClick={() => this.props.history.goBack()} />
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              value={searchQuery}
              onChange={e => this.setState({ searchQuery: e.target.value })}
              onSearch={this.handleSearch}
              className="search-box"
            />
          </Col>
          <Col className="pagination">
            <Pagination onChange={this.handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={13}>Note</Col>
          <Col md={7}>Due Date</Col>
          <Col md={4}>Report Status</Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {assignmentsData.length > 0 ? (
              <Row>
                {assignmentsData.map((item, index) => (
                  <Row key={index} className={this.getStatusColor(item)}>
                    <Row className="record">
                      <Col md={13}>
                        <Text>{item.note}</Text>
                      </Col>
                      <Col md={7}>
                        <Text>{moment(item.end_date).format('MMMM DD, YYYY')}</Text>
                      </Col>
                      <Col md={4} className="report-status">
                        <Select
                          placeholder="Please select"
                          className="dropdown"
                          value={item.status_id}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}
                          onChange={value => this.handleUpdateAssignment(item.id, value)}>
                          <Option value={1}>Open</Option>
                          <Option value={2}>Closed</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                description={loading ? false : 'No Data'}
                image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
