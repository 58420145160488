import React from 'react';

import { Tooltip } from 'components/Tooltip';
import { ExclamationCircleIcon } from 'components/Svg';

import './tooltipAboutFileMSA.scss';

const TooltipAboutFileMSA = ({ text }) => {
  return (
    <div className="tooltipAboutFileMSA">
      <Tooltip
        className="tooltipAboutFileMSA_text"
        content={
          <div className="tooltipAboutFileMSA_content">
            <p>For MSA reports, please ensure the following files match this naming format:</p>
            <ul>
              <li>Consent Forms</li>
              <li>Rated Ages</li>
              <li>Court/WC Board Documents</li>
              <li>MSA Administration Agreement</li>
              <li>(Year) Medical Records (ex. 2023 Medical Records)</li>
              <li>(Year) Payment Information</li>
              <li>Supplemental Doc</li>
              <li>Reduction Letter</li>
            </ul>
          </div>
        }>
        <ExclamationCircleIcon />
        {text && <p>{text}</p>}
      </Tooltip>
    </div>
  );
};

export default TooltipAboutFileMSA;
