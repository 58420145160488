import React from 'react';
import { Modal, Typography, Button } from 'antd';
import './modalYesOrNo.scss';

const { Title, Text } = Typography;

const ModalConfirm = ({ onClose, title, onConfirm, text }) => {
  return (
    <Modal
      width={500}
      maskClosable={false}
      bodyStyle={{ padding: 0, minWidth: 450 }}
      visible={true}
      onCancel={onClose}
      keyboard={false}
      footer={null}>
      <div className="modalYesOrNo">
        <Title level={4}>{title}</Title>
        {text && <Text>{text}</Text>}
        <div className="modalConfirm_btns">
          <Button type="primary" className="green-btn" onClick={onConfirm}>
            Yes
          </Button>
          <Button type="primary" className="pink-btn" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
