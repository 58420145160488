import React, { Component } from "react";
import { Row, Typography } from "antd";
import "./AuthSideBar.scss";

const { Text } = Typography;

export default class AuthSideBar extends Component {
  render() {
    return (
      <Row className="auth-sidebar-container">
        <Row className="image" />
        <a href="/">
          <Row type="flex" className="logo" />
        </a>
        <Row className="copyright">
          <Text className="text">Copyright @ 2024 Wrkfloz, Inc</Text>
          <Row className="link">
            <a href="/terms">Terms and conditions</a>
            <a href="/">Privacy Policy</a>
          </Row>
        </Row>
      </Row>
    );
  }
}
