import React, { useEffect, useState } from 'react';
import { CreditCardOutlined, QuestionCircleOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Icon, Input, Modal, Row, Select, Steps, Tooltip, Typography } from 'antd';
import CreditCard from 'components/CreditCard/CreditCard.jsx';
import { validateDate } from 'components/CreditCard/helpers/index';
import UploadLogo from 'components/UploadLogo/UploadLogo';
import 'containers/Auth/SignUp/SignUp.scss';
import 'react-credit-cards/lib/styles.scss';
import InputMask from 'react-input-mask';
import * as API from 'utils/api';
import { notifyApiError } from 'utils/notification';
import AuthSideBar from '../../../components/AuthSideBar';

export default function Signup(props) {
  const { Text } = Typography;
  const { Option } = Select;

  const { Step } = Steps;
  const steps = [
    {
      title: 'Personal Information',
      content: 'First-content',
      key: 1,
      icon: <UserOutlined />,
    },
    {
      title: 'Organization',
      content: 'Second-content',
      key: 2,
      icon: <SolutionOutlined />,
    },
    {
      title: 'Pay',
      content: 'Last-content',
      key: 3,
      icon: <CreditCardOutlined />,
    },
  ];
  const [current, setCurrent] = useState(0);
  const [stateError, setStateError] = useState('');
  const [userData, setUserData] = useState({
    email: '',
    given_name: '',
    family_name: '',
    locale: '',
    errorName: '',
    errorLname: '',
    errorPhone: '',
    errorEmail: '',
  });
  const [tenantData, setTenantData] = useState({
    tenant_name: '',
    TenantNameError: '',
    website: '',
    WebsiteErrorr: '',
    address_line: '',
    adressError: '',
    address_line_2: '',
    phone_number_1: '',
    TenantPhoneError: '',
    phone_number_2: '',
    client_logo: '',
    clientLogoError: '',
  });
  const [cardFields, setCardFields] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
  });

  const [juridiction_list, setJuridiction_list] = useState([]);
  const [state, setStatename] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [existEmailError, setExistEmailError] = useState('');
  const [cvcError, setcvcError] = useState('');

  const createFinalAddress = () => {
    var address = `${tenantData.address_line.replace(/,/g, '')},${tenantData.address_line_2.replace(
      /,/g,
      ''
    )},${city.replace(/,/g, '')},${state.replace(/,/g, '')},${zip.replace(/,/g, '')}`;
  };

  const handleNextStep = async () => {
    if (current === 0) {
      if (userData.given_name.trim() === '') {
        setUserData({
          ...userData,
          errorName: 'Name cannot be empty!',
        });
        return;
      }

      if (userData.family_name.trim() === '') {
        setUserData({
          ...userData,
          errorLname: 'Last Name cannot be empty!',
        });
        return;
      }

      if (userData.locale.trim() === '') {
        setUserData({
          ...userData,
          errorPhone: 'Phone cannot be empty!',
        });
        return;
      }

      if (userData.email.trim() === '') {
        setUserData({
          ...userData,
          errorEmail: 'Email cannot be empty!',
        });
        return;
      }

      if (userData['email']) {
        const emailValidation = /.+@.+\..+/;

        if (!emailValidation.test(userData['email'])) {
          setUserData({
            ...userData,
            errorEmail: 'Email is not valid',
          });
          return;
        }
      }
      setCurrent(1);
      return;
    }

    if (current === 1) {
      await createTenantWithUser();
    }
  };

  const handlePreviousStep = () => setCurrent(current - 1);

  const handleCardFocus = e => setCardFields({ ...cardFields, focus: e.target.name });

  const handleCardInput = e => {
    const { name, value } = e.target;

    let checkForValidName = /^[A-Za-z]+$/;

    if (name === 'name' && !checkForValidName.test(value)) {
      return;
    }
    if (name === 'cvc' && value.length > 3) {
      return;
    }

    if (name === 'cvc' && value < 0) {
      return false;
    }

    if (name === 'number' && value.length > 16) {
      return;
    }
    if (name === 'expiry' && value.length > 4) {
      return;
    }
    setCardFields({ ...cardFields, [name]: value });
    setcvcError('');
  };

  const handleUserInfo = e => {
    let letters = /^[A-Za-z]+$/;
    if (e.target.value === '' || letters.test(e.target.value)) {
      setUserData({
        ...userData,
        errorName: '',
        errorLname: '',
        errorPhone: '',
        errorEmail: '',
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleTenantData = e => {
    const re = /^[a-zA-Z\\:/.\s]*$/;

    if (re.test(e.target.value)) {
      setTenantData({
        ...tenantData,
        TenantNameError: '',
        WebsiteErrorr: '',
        TenantPhoneError: '',
        adressError: '',
        [e.target.name]: e.target.value.replace(/\s/g, ''),
      });
    }
  };

  const ValidateOnlyLetters = e => {
    let letters = /^[a-zA-Z\s]*$/;
    if (e.target.value === '' || letters.test(e.target.value)) {
      setTenantData({
        ...tenantData,

        [e.target.name]: e.target.value.replace(/\s/g, ''),
        WebsiteErrorr: '',
        TenantNameError: '',
      });
    }
  };

  const createTenantWithUser = async () => {
    const data = { ...tenantData };
    //if uploaded new logo
    if (data.s3key) {
      data.client_logo = data.s3key;
      data.logo = data.s3key;
      delete data.s3key;
    }

    let params = {
      user: {
        given_name: userData.given_name.trim(),
        family_name: userData.family_name.trim(),
        locale: userData.locale.trim(),
        email: userData.email.trim(),
      },
      tenant: {
        tenant_name: data.tenant_name.trim(),
        website: data.website.trim(),
        address_line: data.address_line.trim(),
        phone_number_1: data.phone_number_1.trim(),
        phone_number_2: data.phone_number_1.trim(),
        city: city.trim(),
        state: state.trim(),
        zip: zip.trim(),
        client_logo: data.client_logo,
        logo: data.logo,
      },
    };

    if (tenantData.tenant_name.trim() === '') {
      setCurrent(current);
      setTenantData({
        ...tenantData,
        TenantNameError: 'Name cannot be empty!',
      });
      return;
    }

    if (tenantData.website.trim() === '') {
      setTenantData({
        ...tenantData,
        WebsiteErrorr: 'Website cannot be empty!',
      });
      return;
    }

    if (tenantData.address_line.trim() === '') {
      setTenantData({
        ...tenantData,
        adressError: 'Adress cannot be empty!',
      });
      return;
    }

    if (tenantData.phone_number_1.trim() === '') {
      setTenantData({
        ...tenantData,
        TenantPhoneError: 'Phone cannot be empty!',
      });
      return;
    }

    if (state.trim() === '') {
      setStateError('State cannot be empty!');
      return;
    }

    if (!tenantData.client_logo) {
      setTenantData({
        ...tenantData,
        clientLogoError: 'Logo cannot be empty!',
      });
      return;
    }

    try {
      await API.createUserTenant(params);
      setCurrent(2);
    } catch (error) {
      notifyApiError(error);
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error;
        if (errorMessage === 'An account with the given email already exists.') {
          setExistEmailError(errorMessage);
        }
      }
    }
  };

  useEffect(() => {
    const loadStates = async () => {
      try {
        const res = await API.getStates();

        setJuridiction_list(res.data.states);
      } catch (e) {
        notifyApiError(e);
      }
    };

    loadStates();
  }, []);

  const createUserBank = async data => {
    try {
      API.createUserBankData(data);

      Modal.success({
        content: 'Success! Check your email to login.',
      });

      props.history.push('/login');
    } catch (e) {
      Modal.error({
        title: 'Oops something went wrong!',
        content: e.response.data.error,
      });
    }
  };

  const CreatePaymentProfile = async () => {
    let data = {
      firstName: cardFields.name,
      lastName: userData.lastname,
      cardNumber: cardFields.number,
      expirationDate: cardFields.expiry,
      cvc: cardFields.cvc,
      address: tenantData.address_line,
      city: city,
      state: state,
      zip: zip,
      number: userData.locale,
      email: userData.email,
    };

    if (!data.cvc) {
      return setcvcError('Cvc cannot be empty!');
    }
    if (cardFields.expiry) {
      if (cardFields.expiry.length < 4) {
        return Modal.error({
          content: 'Expiry date should be in proper format!',
        });
      }
      let first = parseInt(cardFields.expiry[0] + cardFields.expiry[1]);
      let second = parseInt(cardFields.expiry[2] + cardFields.expiry[3]);
      if (first > 12 || first < 0) {
        return Modal.error({
          content: 'Expiry date is not valid!',
        });
      }
      if (second > 31 || second < 0) {
        return Modal.error({
          content: 'Expiry date is not valid!',
        });
      }
    }
    if (validateDate(data.expirationDate)) {
      return Modal.error({
        content: 'Expiry date should be in proper format!',
      });
    }

    await createUserBank(data);
  };

  return (
    <>
      <Row type="flex" justify="center" className="login-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16} className="main_login_container">
          <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <div className="auth_wrapper">
              <Text>Have an Account?</Text>
              <Button type="primary" onClick={() => props.history.push('/login')} className="signup_cta_btn" ghost>
                Sign in
              </Button>
            </div>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={20} md={24}>
              <Row type="flex" justify="space-around">
                <Row
                  type="flex"
                  justify="center"
                  style={{
                    flexDirection: 'column',
                    width: '80%',
                    paddingBottom: '20px',
                  }}
                  align="middle">
                  <Steps current={current}>
                    {steps.map(item => (
                      <Step key={item.title} title={item.title} icon={item.icon} />
                    ))}
                  </Steps>
                  <div className="steps-content">
                    {steps[current].content === 'First-content' && (
                      <div style={{ marginTop: '18px' }}>
                        <Row>
                          <Text>Name</Text>
                          <Tooltip title="Enter only letters">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>
                        <Input
                          placeholder="Required"
                          className="card_input"
                          name="given_name"
                          value={userData.given_name}
                          onChange={handleUserInfo}
                        />

                        <Alert
                          type="error"
                          message={userData.errorName}
                          banner
                          style={userData.errorName ? { display: 'block', marginBottom: '6px' } : { display: 'none' }}
                        />
                        <Row>
                          <Text>Last Name</Text>
                          <Tooltip title="Enter only letters">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>
                        <Input
                          placeholder="Required"
                          className="card_input"
                          name="family_name"
                          value={userData.family_name}
                          onChange={handleUserInfo}
                        />
                        <Alert
                          type="error"
                          message={userData.errorLname}
                          banner
                          style={userData.errorLname ? { display: 'block', marginBottom: '6px' } : { display: 'none' }}
                        />

                        <Row>
                          <Text>Phone</Text>
                          <Tooltip title="Enter only numbers">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>
                        <InputMask
                          style={{ width: '100%' }}
                          mask="999-999-9999"
                          placeholder="Required"
                          name="locale"
                          className="card_input"
                          value={userData.locale}
                          onChange={e =>
                            setUserData({
                              ...userData,
                              locale: e.target.value,
                              errorPhone: '',
                            })
                          }
                        />
                        <Alert
                          type="error"
                          message={userData.errorPhone}
                          banner
                          style={userData.errorPhone ? { display: 'block', marginBottom: '6px' } : { display: 'none' }}
                        />
                        <Row>
                          <Text>Email</Text>
                        </Row>
                        <Input
                          placeholder="Required"
                          className="card_input"
                          name="email"
                          value={userData.email}
                          onChange={e =>
                            setUserData({
                              ...userData,
                              email: e.target.value,
                              errorEmail: '',
                            })
                          }
                        />
                        <Alert
                          type="error"
                          message={userData.errorEmail}
                          banner
                          style={userData.errorEmail ? { display: 'block', marginBottom: '6px' } : { display: 'none' }}
                        />
                        {existEmailError && <Alert type="error" message={existEmailError} banner />}
                      </div>
                    )}
                    {steps[current].content === 'Second-content' && (
                      <div style={{ marginTop: '18px' }} className="organization-step-wrapper">
                        <Row>
                          <Text>Company Name</Text>
                          <Tooltip title="Enter only letters">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>

                        <Input
                          placeholder="Required"
                          name="tenant_name"
                          value={tenantData.tenant_name}
                          onChange={ValidateOnlyLetters}
                          className="card_input"
                        />
                        <Alert
                          type="error"
                          message={tenantData.TenantNameError}
                          banner
                          style={
                            tenantData.TenantNameError ? { display: 'block', marginBottom: '6px' } : { display: 'none' }
                          }
                        />
                        <Row>
                          <Text>Website</Text>
                        </Row>

                        <Input
                          placeholder="Required"
                          name="website"
                          value={tenantData.website}
                          onChange={handleTenantData}
                          className="card_input"
                        />
                        <Alert
                          type="error"
                          message={tenantData.WebsiteErrorr}
                          banner
                          style={
                            tenantData.WebsiteErrorr ? { display: 'block', marginBottom: '6px' } : { display: 'none' }
                          }
                        />
                        <Row>
                          <Text>Address</Text>
                        </Row>

                        <Input
                          placeholder="Required"
                          className="card_input"
                          name="address_line"
                          value={tenantData.address_line}
                          onChange={handleTenantData}
                        />

                        <Alert
                          type="error"
                          message={tenantData.adressError}
                          banner
                          style={
                            tenantData.adressError ? { display: 'block', marginBottom: '6px' } : { display: 'none' }
                          }
                        />
                        <Row>
                          <Text>City</Text>
                          <Tooltip title="Enter only letters">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>
                        <Input
                          placeholder="Required"
                          className="card_input"
                          value={city}
                          onChange={e => {
                            let letters = /^[A-Za-z]+$/;
                            if (e.target.value === '' || letters.test(e.target.value)) {
                              setCity(e.target.value);
                            }
                          }}
                        />

                        <Row>
                          <Text>State</Text>
                        </Row>
                        <Select
                          showSearch
                          placeholder="Required"
                          optionFilterProp="children"
                          className="dropdown  state-list-container"
                          style={{
                            background: '#f2f2f2',
                            marginBottom: '10px',
                          }}
                          value={state}
                          onChange={value => {
                            setStatename(value);
                          }}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                          {juridiction_list.map((el, i) => {
                            return (
                              <Option key={i} value={el.name}>
                                {el.name}
                              </Option>
                            );
                          })}
                        </Select>
                        <Alert
                          type="error"
                          message={stateError}
                          banner
                          style={stateError ? { display: 'block', marginBottom: '6px' } : { display: 'none' }}
                        />
                        <Row>
                          <Text>Zip</Text>
                          <Tooltip title="Enter only numbers">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>
                        <InputMask
                          mask="99999"
                          placeholder="Required"
                          className="input-ssn card_input"
                          value={zip}
                          onChange={e => setZip(e.target.value, createFinalAddress)}
                        />
                        <Row>
                          <Text>Phone</Text>
                          <Tooltip title="Enter only Numbers">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>
                        <InputMask
                          style={{ width: '100%' }}
                          mask="999-999-9999"
                          placeholder="Required"
                          name="phone_number_1"
                          className="card_input"
                          value={tenantData.phone_number_1}
                          onChange={e =>
                            setTenantData({
                              ...tenantData,
                              phone_number_1: e.target.value,
                              TenantPhoneError: '',
                            })
                          }
                        />
                        <Alert
                          type="error"
                          message={tenantData.TenantPhoneError}
                          banner
                          style={
                            tenantData.TenantPhoneError
                              ? { display: 'block', marginBottom: '6px' }
                              : { display: 'none' }
                          }
                        />
                        <Row>
                          <Text>Company Logo *</Text>
                          <Tooltip title="Required. JPEG and PNG images up to 2 Mb allowed">
                            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </Row>
                        <UploadLogo
                          changeImage={(client_logo, s3key) => {
                            setTenantData({ ...tenantData, client_logo, s3key, clientLogoError: '' });
                          }}
                          removeImage={() => {
                            setTenantData({ ...tenantData, client_logo: '' });
                          }}
                          client_logo={tenantData.client_logo}
                        />
                        {tenantData.clientLogoError && (
                          <Alert type="error" message={tenantData.clientLogoError} banner />
                        )}
                      </div>
                    )}
                    {steps[current].content === 'Last-content' && (
                      <>
                        <Row style={{ width: '100%' }}>
                          <div id="App-payment">
                            <CreditCard
                              cardFields={cardFields}
                              handleCardInput={handleCardInput}
                              handleCardFocus={handleCardFocus}
                              CreatePaymentProfile={CreatePaymentProfile}
                              cvcError={cvcError}
                            />
                          </div>
                        </Row>
                      </>
                    )}
                  </div>
                  <div className="steps-action">
                    {current === 1 && (
                      <Button style={{ margin: '0 8px' }} className="secondary_action_btn" onClick={handlePreviousStep}>
                        Previous
                      </Button>
                    )}

                    {current < 2 && (
                      <Button
                        type="primary"
                        size="large"
                        id={'input' + current}
                        className="green-btn button secondary_action_btn"
                        onClick={handleNextStep}>
                        Next
                      </Button>
                    )}

                    {current === 2 && (
                      <Button
                        type="primary"
                        size="large"
                        id={'input' + current}
                        className="green-btn button secondary_action_btn"
                        onClick={CreatePaymentProfile}>
                        Done
                      </Button>
                    )}
                  </div>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>{' '}
    </>
  );
}
