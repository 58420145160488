import React from 'react';
import { Modal } from 'antd';
export default function SeeMore({ text }) {
  if (text) {
    if (text.length > 256) {
      return (
        <div>
          {text.substring(0, 256)}...
          <a
            onClick={() => {
              Modal.info({ content: text, width: 700 });
            }}>
            See more
          </a>
        </div>
      );
    } else {
      return text;
    }
  } else {
    return text;
  }
}
