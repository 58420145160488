import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Divider, Upload, Modal, Spin } from 'antd';
import DropDown from 'components/Dropdown';
import { updateReportInfo, getReportFiles } from 'redux/report';
import * as API from 'utils/api';
import { UploadPresigned, BulkAddFilesToReport } from 'utils/upload';
import { notifyApiError, notifyInfo } from 'utils/notification';
import SortableList from '../../components/SortableList';
import { INJURY_DESCRIPTION, MEDICALS, DAMAGES, LEGAL_DOCS } from '../../../../constants/report-file-types';
import { verifyPermissions, forbiddenRedirect, qaHaveAssignedReport } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import { REPORT_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';
import moment from 'moment';

// Components
import { SignatureReport } from 'containers/Reports/components/SignatureReport';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';

// Utils
import { getListSignature } from 'containers/Reports/utils/helpers';

import './DocumentUpload.scss';
import { REPORT_DEMAND_LETTER_NAME } from 'constants/reports';
import { MAIN_NAV } from 'layout/navigate_const';

const { Title, Text } = Typography;

const beforeUpload = file => {
  if (!file) return false;
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    Modal.error({
      content: 'You can only upload PDF file!',
    });
  }
  return isPdf;
};

class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }
    // const { reportInfo } = this.props;
    // const { injury_description, medicals, damages, legal_docs } = reportInfo;

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      loadingText: 'Loading...',
      loading: false,
      is_template: false,
      injury_description: false,
      medicals: false,
      damages: false,
      legal_docs: false,
      [INJURY_DESCRIPTION]: false,
      [MEDICALS]: false,
      [DAMAGES]: false,
      [LEGAL_DOCS]: false,
      required_qa: true,
      REPORT_PERMISSIONS,
      signature: [],
    };

    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleChangeSignature = this.handleChangeSignature.bind(this);
    this.handleDeleteSignature = this.handleDeleteSignature.bind(this);
    this.handleAddSignature = this.handleAddSignature.bind(this);
    this.handleDocUpload = this.handleDocUpload.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.renderFiles = this.renderFiles.bind(this);
    this.moveArrayItemToNewIndex = this.moveArrayItemToNewIndex.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;
    this.props.getReportFiles(this.props.match.params.id);
    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);

        const { injury_description, medicals, damages, legal_docs } = reportInfo;

        this.setState({
          injury_description,
          medicals,
          damages,
          legal_docs,
          loading: false,
          is_template: reportInfo.is_template,
          required_qa: reportInfo.required_qa,
          signature: getListSignature({ signature: reportInfo.signature }),
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const { injury_description, medicals, damages, legal_docs } = reportInfo;

      this.setState({
        injury_description,
        medicals,
        damages,
        legal_docs,
        loading: false,
      });
    }
  }

  // documentRequired = [];

  async handleUpdateReport(isNextStep = false) {
    const { report_id, required_qa, signature } = this.state;
    const params = {
      id: report_id,
      required_qa,
      signature: signature.map(item => {
        delete item.imageUrl;
        return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
      }),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        res = await API.updateReport(params);
      }
      if (res.status_code === 201) {
        notifyInfo('', res.message);
      }
      this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleChangeSignature(data) {
    this.setState(data);
  }

  handleDeleteSignature({ index }) {
    this.setState({ signature: this.state.signature.filter((item, inde) => inde !== index) });
  }

  handleAddSignature() {
    const add = [...this.state.signature, INIT_SIGNATURE];
    this.setState({ signature: add });
  }

  async handleDocUpload(info, type) {
    let promiseArray = [];
    let hasUploading = false;
    info.fileList.forEach(el => {
      if (beforeUpload(el.originFileObj)) {
        if ('uploading' === el.status) {
          hasUploading = true;
        }
        promiseArray.push(el);
      }
    });

    if (hasUploading) {
      return;
    }

    const uploadArrayWithPromises = [];
    try {
      //filter duplicates and upload unique files
      promiseArray
        .filter(
          (file, index, fileList) =>
            fileList.findIndex(f => f.name === file.name && f.type === file.type && f.size === file.size) === index
        )
        .forEach(file => {
          uploadArrayWithPromises.push(UploadPresigned(file));
        });

      this.setState(state => (state[type] = true));
      const urls = await Promise.all(uploadArrayWithPromises);
      await BulkAddFilesToReport(urls, type, this.props.reportInfo?.claimant?.id, this.state.report_id);
      await this.props.getReportFiles(this.props.match.params.id);
      //force clean component files after success uploading
      info.fileList.length = 0;
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  }

  async handleGenerateReport() {
    const { report_id } = this.state;
    const { allFiles } = this.props;

    this.setState({
      loading: true,
      loadingText: 'Generating the report...',
    });

    try {
      let has_allDocuments = [];
      this.documentRequired.map(doc => {
        allFiles.map(file =>
          file.type === doc ? (has_allDocuments.includes(file.type) ? '' : has_allDocuments.push(file.type)) : ''
        );
      });

      if (has_allDocuments.length >= this.documentRequired.length) {
        await API.setUpBD1(report_id);
        this.setState({
          loading: true,
          loadingText: 'Report generated , packaging the files... ',
        });
        const reportFileObj = await API.GenerateReport(this.state.report_id);
        this.setState({ loading: false });
        Modal.success({
          content: 'Report is generated',
          onOk: () => {
            window.open(reportFileObj.url);
          },
        });
        await this.handleUpdateReport(false);
      } else {
        const missingDocuments = this.documentRequired.filter(file => !has_allDocuments.includes(file));
        notifyInfo(
          'Documents Required',
          'All documents are required to generate the report: ' + `${missingDocuments.map(type => ' ' + type)}`
        );
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async deleteFile(type, el) {
    this.setState(state => (state[type] = true));
    try {
      await API.deleteReportDocument(el.id);
      await this.props.getReportFiles(this.props.match.params.id);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  }

  renderFiles(list, type) {
    const allFilesOfThisType = list
      .filter(item => item.type === type)
      .sort((a, b) => {
        return a.order - b.order;
      });

    if (allFilesOfThisType.length < 1) {
      if (this.state[type]) {
        return <Spin />;
      }
      return <Text>No documents uploaded yet...</Text>;
    } else {
      return (
        <Row className="example horizontal" style={{ marginTop: 10 }}>
          <SortableList
            deleteFile={this.deleteFile}
            distance={1}
            items={allFilesOfThisType}
            type={type}
            axis={'y'}
            onSortEnd={e => {
              this.onSortEnd(e, allFilesOfThisType, type);
            }}
          />
        </Row>
      );
    }
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  onSortEnd({ oldIndex, newIndex }, items, type) {
    let isUploading = { ...this.state.isUploading };
    isUploading[type] = true;
    this.setState({ isUploading });
    let newList = [...items];
    newList = this.moveArrayItemToNewIndex(newList, oldIndex, newIndex);
    newList = newList.map((item, index) => {
      return { id: item.id, order: index };
    });

    API.BulkUpdateReportDocuments(newList)
      .then(() => {
        this.setState({ loading: false });
        isUploading[type] = false;
        this.setState({ isUploading });
        this.props.getReportFiles(this.props.match.params.id);
      })
      .catch(() => {
        this.setState({ loading: false });
        isUploading[type] = false;
        this.setState({ isUploading });
      });
  }

  render() {
    const { loading, injury_description, medicals, damages, legal_docs, REPORT_PERMISSIONS, signature, is_template } =
      this.state;

    const { isLoading } = this.props;

    return (
      <>
        <Row className="msa-document-upload-container">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Setup {REPORT_DEMAND_LETTER_NAME} {is_template ? 'Template' : 'Report'}
              </Title>
            </Col>
            <Col className="step">
              <DropDown loading={loading} beforeNext={async () => await this.handleUpdateReport(false)} />
            </Col>
          </Row>

          <Row className="main">
            <Row className="content">
              {loading ? (
                <div className="loading-wrapper">
                  <Spin loading={loading.toString() || isLoading.toString()} tip={this.state.loadingText} />
                </div>
              ) : (
                <>
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={24}>
                      <Text className="title">Document Upload</Text>
                    </Col>
                  </Row>

                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={24}>
                      <Row className="label">
                        <Text>Injury Description</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          listType="text"
                          accept="application/pdf"
                          className="logo-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={info => this.handleDocUpload(info, INJURY_DESCRIPTION)}
                          ref={node => {
                            this['10ConsentForm'] = node;
                          }}>
                          {injury_description ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, INJURY_DESCRIPTION)}</Row>
                    </Col>
                    <Col md={24}>
                      <Row className="label">
                        <Text>Medicals</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          listType="text"
                          className="logo-uploader"
                          accept="application/pdf"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          onChange={info => this.handleDocUpload(info, MEDICALS)}
                          ref={node => {
                            this['15RatedAges'] = node;
                          }}>
                          {medicals ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, MEDICALS)}</Row>
                    </Col>
                  </Row>

                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={24}>
                      <Row className="label">
                        <Text>Damages</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          accept="application/pdf"
                          listType="text"
                          className="logo-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={info => this.handleDocUpload(info, DAMAGES)}
                          ref={node => {
                            this['25Court-WCBoardDocuments'] = node;
                          }}>
                          {damages ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, DAMAGES)}</Row>
                    </Col>
                    <Col md={24}>
                      <Row className="label">
                        <Text>Legal Docs</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          accept="application/pdf"
                          listType="text"
                          className="logo-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={info => this.handleDocUpload(info, LEGAL_DOCS)}
                          ref={node => {
                            this['30WCMSAAdministrationAgreement'] = node;
                          }}>
                          {legal_docs ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, LEGAL_DOCS)}</Row>
                    </Col>
                  </Row>

                  <Divider className="divider" />

                  {REPORT_PERMISSIONS.signature && !is_template && (
                    <div>
                      <SignatureReport
                        defaultSignature={signature}
                        onChangeSignature={this.handleChangeSignature}
                        onDeleteSignature={this.handleDeleteSignature}
                        onAddSignature={this.handleAddSignature}
                        disabled={!REPORT_PERMISSIONS.edit}
                      />
                    </div>
                  )}

                  <Divider className="divider" />

                  <PrevNextStep
                    saveDraft={REPORT_PERMISSIONS.saveDraft}
                    beforeNext={async () => await this.handleUpdateReport(true)}
                  />
                </>
              )}
            </Row>
          </Row>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    reportInfo: state.report.reportInfo,
    files: state.report.files,
    resourceLinksFiles: state.report.resourceLinksFiles,
    allFiles: state.report.allFiles,
    isLoading: state.report.isLoading,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };

  return props;
};

export default connect(mapStateToProps, { updateReportInfo, getReportFiles })(DocumentUpload);
