import { API } from 'aws-amplify';

export const fetchAssignments =  async (data) => {
  return await API.get('MyAPI', `/clientservice/assignments/user`, {
    queryStringParameters: {
      isTable: true,
      ...data,
    },
  });
};

export const fetchAssignmentById = async (id) => {
  return await API.get('MyAPI', `/clientservice/assignments/${id}`);
};

export const updateAssignmentById = async (id, data) => {
  return await API.patch('MyAPI', `/clientservice/assignments/${id}`, { body: data });
};
