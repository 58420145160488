import React, { Component } from 'react';
import { Row, Col, Typography, Button, Select, Icon, DatePicker, Checkbox } from 'antd';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import InputBox from 'components/InputBox';
import { BackIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import TextAreaBox from 'components/TextAreaBox';
import * as API from 'utils/api';
import { isClient } from '../../../layout/roles';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { notifyApiError, notifyError, notifySuccess } from 'utils/notification';
import InputCurrencyBox from 'components/InputCurrencyBox';
import {
  REPORT_MSA_TYPE_ID,
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  EARLY_MESSAGE,
  MCP_MESSAGE,
} from 'constants/reports';
import './AddClaimant.scss';
import { REPORT_FIELD_MEDICAL_RECORDS } from 'constants/reportfieldsUploadFile';
import { SpecialtyCheck } from 'components/SpecialtyCheck';
import { UploadPresigned, BulkAddFilesToReport } from 'utils/upload';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Componenst Steps
import { StepCheckout } from './Components/StepCheckout';
import { StepMedicalReviewer } from './Components/StepMedicalReviewer';
import { StepUpload } from './Components/StepUpload';

// Uitls
import { sortArrayObjectForKey, formatMoneyToNumber, validatedFormatEmail, getRushFees } from 'utils/utils';
import { ModalRushFees } from 'components/Modal/ModalRushFees';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

const { Title, Text } = Typography;
const { Option } = Select;

const STEP_CLAIMANT_INFO = 'claimantInfo';
const STEP_CHECKOUT = 'checkout';
const STEP_MEDICAL_REVIEWER = 'medicalReviewer';
const STEP_UPLOAD_DOCUMENT = 'uploadDocument';

class AddClaimant extends Component {
  constructor(props) {
    super(props);

    let client_id = '';
    if (this.props.location && this.props.location.state) {
      client_id = this.props.location.state.data;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      isClient: isClient(props.roles),
      clients: [],
      client_id: client_id,
      notClientDefault: client_id === undefined,
      claimant_name: '',
      claimant_middle_name: '',
      claimant_last_name: '',
      claimant_title: '',
      claimant_email: '',
      claimant_phone: '',
      dob: null,
      SSN: '',
      HICN: '',
      Gender: '',
      rated_age: '',
      insurance_carrier: '',
      claim_number: '',
      race_id: undefined,
      address: '',
      additional_addres: '',
      loadingTitle: 'Loading...',
      loading: false,
      isImport: false,
      claimants: [],
      juridiction_list: [],
      city: '',
      state: '',
      zip_code: '',
      reportTypes: [],
      report_type_id: '',
      additional_comments: '',
      policy_limits: '',
      referring_source: '',
      body_parts: '',
      present_value_case: '',
      needed_by: null,
      settlement: '',
      listClient: [],
      md_tbd: null,
      modalRushFees: false,
      step: STEP_CLAIMANT_INFO,
      REPORT_PERMISSIONS,
    };

    this.handleSetValue = this.handleSetValue.bind(this);
    this.validateRequired = this.validateRequired.bind(this);
    this.handleCreateClaimant = this.handleCreateClaimant.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.fetchClients = this.fetchClients.bind(this);
    this.fetchClaimants = this.fetchClaimants.bind(this);
    this.handleChangeStep = this.handleChangeStep.bind(this);
    this.handleChangeMdTbd = this.handleChangeMdTbd.bind(this);
    this.handleChangeNeededBy = this.handleChangeNeededBy.bind(this);
    this.handleCloseRushFees = this.handleCloseRushFees.bind(this);
    this.handleUpdateTypeReport = this.handleUpdateTypeReport.bind(this);
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const res = await API.getClientsData('', 0, 10000, false);

      if (res?.clients.length > 0) {
        this.setState({ listClient: res.clients });
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillMount() {
    this.fetchClients();

    API.getJuridictionStateList().then(res => {
      this.setState({ juridiction_list: sortArrayObjectForKey({ array: res.juridictionStates, key: 'name' }) });
    });

    API.getReportTypes().then(res => {
      this.setState({ reportTypes: res.reportTypes });
    });
  }

  handleSetValue(type, value) {
    this.setState({
      [type]: value,
    });
  }

  handleChangeNeededBy({ needed_by }) {
    let openModal = false;

    if (
      this.state.REPORT_PERMISSIONS.alertRushFees &&
      getRushFees({ createdAt: new Date(), needed_by, max_days: 14 })
    ) {
      openModal = true;
    }

    this.setState({
      needed_by,
      modalRushFees: openModal,
    });
  }

  validateRequired() {
    const { claimant_name, claimant_last_name, claimant_title, dob, SSN, claimant_phone, report_type_id, settlement } =
      this.state;
    let isFormValid = true;

    if (!claimant_title) {
      notifyError('Title must not be empty');
      isFormValid = false;
    }

    if (!claimant_name) {
      notifyError('First name must not be empty');
      isFormValid = false;
    }
    if (!claimant_last_name) {
      notifyError('Last name must not be empty');
      isFormValid = false;
    }

    if (dob === moment()) {
      notifyError('Please enter correct date of birth');
      isFormValid = false;
    }

    if (!SSN || SSN.includes('_')) {
      notifyError('Please enter SSN correctly');
      isFormValid = false;
    }

    if (!claimant_phone || claimant_phone.includes('_')) {
      notifyError('Please enter Phone Number correctly');
      isFormValid = false;
    }

    if (report_type_id === REPORT_MSA_TYPE_ID && settlement === '') {
      notifyError('Please select Pre Settlement or Post Settlement');
      isFormValid = false;
    }

    return isFormValid;
  }

  handleUpdateTypeReport({ changeType }) {
    this.setState({ report_type_id: changeType });
  }

  async handleCreateClaimant({ listFile }) {
    const {
      client_id,
      clients,
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      SSN,
      HICN,
      Gender,
      rated_age,
      insurance_carrier,
      claim_number,
      race_id,
      address,
      additional_addres,
      city,
      state,
      zip_code,
      report_type_id,
      additional_comments,
      policy_limits,
      referring_source,
      body_parts,
      present_value_case,
      needed_by,
      settlement,
      md_tbd,
    } = this.state;

    const param = {
      client_id,
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      SSN,
      HICN,
      Gender,
      rated_age,
      address: `${address},${city},${state},${zip_code}`,
      additional_addres,
      city,
      state,
      zip_code,
      present_value_case,
      referring_source,
      claim_number,
      policy_limits: formatMoneyToNumber({ money: policy_limits }),
      insurance_carrier,
      report: {
        pre_settlement: settlement === 'pre',
        post_settlement: settlement === 'post',
        report_type_id,
        policy_limits: formatMoneyToNumber({ money: policy_limits }),
        body_parts,
        additional_comments,
        needed_by,
        report_name: `Default claimant ${claimant_name}`,
        report_status_id: 1,
        md_tbd,
      },
      race_id: race_id ? race_id : null,
    };

    if (this.validateRequired()) {
      this.setState({ loadingTitle: 'Saving...', loading: true });

      try {
        let clientTenantId = '';
        if (isClient && this.props.roles.user_roles.roles.length === 1) {
          clientTenantId = this.props.roles.user_roles.tenant_id;
        } else {
          clientTenantId = clients.find(client => client.id === Number(client_id)).tenant_id;
        }

        const body = { ...param, tenant_id: clientTenantId };

        const create = await API.createClaimant(body);

        const uploadArrayWithPromises = listFile.map(file => UploadPresigned(file));
        const urls = await Promise.all(uploadArrayWithPromises);
        await BulkAddFilesToReport(urls, REPORT_FIELD_MEDICAL_RECORDS, create.id, create.reportId);
        notifySuccess('Claimant created');
      } catch (e) {
        notifyApiError(e);
      } finally {
        this.setState({ loading: false });
      }

      this.handleGoBack();
    }
  }

  handleGoBack() {
    this.props.history.goBack();
  }

  handleCopy() {
    API.copy_claimant(this.state.client_id, this.state.selected_claimant).then(res => {
      res.reports.map(report => {
        API.createReport({
          claimant_id: res.claimant.id,
          feeding_id: report.id,
        });
      });
      this.props.history.goBack();
    });
  }

  async fetchClients() {
    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

    try {
      const tenantOnly = isClient(this.props.roles) || (isUserNurseManager && !isUserAdmin);

      const res = await API.getClientsData('', 0, 1000, tenantOnly);

      this.setState({ clients: res.clients });
    } catch (e) {
      notifyApiError(e);
    }
  }

  async fetchClaimants() {
    try {
      const res = await API.getClaimantsData({ clientId: this.state.selected_client, pageSize: 1000 });

      this.setState({ claimants: res.claimants });
    } catch (e) {
      notifyApiError(e);
    }
  }

  handleChangeStep({ step }) {
    this.setState({ step });
  }

  handleChangeMdTbd({ mdTbd }) {
    this.setState({ md_tbd: mdTbd });
  }

  handleCloseRushFees({ confirm } = {}) {
    if (!confirm) return this.setState({ modalRushFees: false, needed_by: null });

    this.setState({ modalRushFees: false });
  }

  render() {
    const {
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      SSN,
      HICN,
      Gender,
      rated_age,
      insurance_carrier,
      claim_number,
      address,
      additional_addres,
      loadingTitle,
      loading,
      isImport,
      city,
      state,
      zip_code,
      juridiction_list,
      reportTypes,
      report_type_id,
      additional_comments,
      policy_limits,
      referring_source,
      body_parts,
      present_value_case,
      needed_by,
      settlement,
      listClient,
      client_id,
      notClientDefault,
      md_tbd,
      step,
      modalRushFees,
      REPORT_PERMISSIONS,
    } = this.state;

    const isFormValid =
      claimant_name &&
      claimant_last_name &&
      claimant_title &&
      claimant_phone &&
      dob &&
      SSN &&
      Gender &&
      insurance_carrier &&
      claim_number &&
      address &&
      city &&
      state &&
      zip_code &&
      report_type_id &&
      policy_limits &&
      referring_source &&
      client_id &&
      validatedFormatEmail({ email: claimant_email }) &&
      needed_by;

    const isNotClient = !isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT);

    const showAlertReportType = [
      REPORT_MSA_TYPE_ID,
      REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
      REPORT_EARLY_INTERVENTION_TYPE_ID,
      REPORT_LIFE_CARE_PLAN_TYPE_ID,
    ].includes(report_type_id);

    const SHOW_ALERT =
      [REPORT_MCP_TYPE_ID, REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID].includes(report_type_id) || showAlertReportType;

    return (
      <>
        {loading && <ModalLoading />}

        {step === STEP_CLAIMANT_INFO && (
          <Row className="add-claimant-container newClaimantStyle">
            <Row type="flex" align="middle" className="header">
              <Col className="title">
                <Title level={4} className="title-text">
                  Add New Claimant
                </Title>
              </Col>
              <Col className="back" onClick={() => this.props.history.goBack()}>
                <BackIcon className="icon" />
              </Col>
              {isImport ? (
                <Col
                  className="back"
                  style={{
                    position: 'absolute',
                    textAlign: 'center',
                    right: '20px',
                  }}
                  onClick={() => this.setState({ isImport: false })}>
                  <Text>Create New Claimant</Text>
                </Col>
              ) : (
                <>
                  {!this.state.isClient ? (
                    <Col
                      className="back"
                      style={{
                        position: 'absolute',
                        textAlign: 'center',
                        right: '20px',
                      }}
                      onClick={() => this.setState({ isImport: true })}>
                      <Text>Import Claimant from another Client</Text>
                    </Col>
                  ) : null}
                </>
              )}
            </Row>

            {isImport ? (
              <Row className="main">
                <SpinBox loading={loading} title={loadingTitle}>
                  <Row className="content">
                    <Row type="flex" justify="space-between" align="middle" className="main-info">
                      <Col md={11} className="row">
                        <Row className="label">
                          <Text>Client</Text>
                        </Row>
                        <Row>
                          <Select
                            showSearch
                            placeholder="Please select"
                            optionFilterProp="children"
                            className="dropdown"
                            value={this.state.selected_client}
                            onChange={value => {
                              this.setState({ selected_client: value }, () => {
                                this.fetchClaimants();
                              });
                            }}
                            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                            {this.state.clients.map(client => {
                              return <Option value={client.id}>{client.client_name}</Option>;
                            })}
                          </Select>
                        </Row>
                      </Col>
                      {this.state.selected_client && (
                        <Col md={11} className="row">
                          <Row className="label">
                            <Text>Claimant</Text>
                          </Row>
                          <Row>
                            <Select
                              showSearch
                              placeholder="Please select"
                              optionFilterProp="children"
                              className="dropdown"
                              value={this.state.selected_claimant}
                              onChange={value =>
                                this.setState({ selected_claimant: value }, () => {
                                  this.setState({ finishedSelecting: true });
                                })
                              }
                              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                              {this.state.claimants.map(claimant => {
                                return (
                                  <Option value={claimant.id}>
                                    {claimant.claimant_name + ' ' + claimant.claimant_last_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    {this.state.selected_claimant && (
                      <Row type="flex" justify="center" className="confirm-button">
                        <Button type="primary" size="large" className="green-btn button" onClick={this.handleCopy}>
                          Import
                        </Button>
                      </Row>
                    )}
                  </Row>
                </SpinBox>
              </Row>
            ) : (
              <Row className="main">
                <SpinBox loading={loading} title={loadingTitle}>
                  <Row className="content">
                    {notClientDefault && (
                      <>
                        <Title level={4} className="title-text">
                          Client
                        </Title>

                        <div className="addClaimantContainer">
                          <div className="addClaimant_label">
                            <p>Client</p>
                            <Select
                              getPopupContainer={triggerNode => triggerNode.parentNode}
                              showSearch
                              optionFilterProp="children"
                              className="dropdown addClaimant_select"
                              value={client_id || ''}
                              onChange={value => this.setState({ client_id: value })}
                              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                              <Option disabled value={''}>
                                Required
                              </Option>
                              {listClient.map(item => (
                                <Option value={item.id} key={item.id}>
                                  {item.client_name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </>
                    )}

                    <Title level={4} className={`title-text ${notClientDefault ? 'addClaimantMt_4' : ''}`}>
                      Report Information
                    </Title>

                    <div className="addClaimantContainer">
                      <div className="addClaimant_label">
                        <p>Report Type</p>
                        <Select
                          getPopupContainer={triggerNode => triggerNode.parentNode}
                          showSearch
                          optionFilterProp="children"
                          className="dropdown addClaimant_select"
                          value={report_type_id}
                          onChange={value => this.setState({ report_type_id: value })}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                          <Option disabled value={''}>
                            Required
                          </Option>
                          {reportTypes.map(item => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </div>

                      <InputBox
                        label="Referring source"
                        value={referring_source || ''}
                        onChange={value => this.setState({ referring_source: value })}
                        classes="addClaimantInput"
                        placeholder="Required"
                      />

                      {SHOW_ALERT && (
                        <div className="addClaimant_alert_reportType">
                          {[
                            REPORT_MCP_TYPE_ID,
                            REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
                            REPORT_LIFE_CARE_PLAN_TYPE_ID,
                          ].includes(report_type_id) && (
                            <SpecialtyCheck
                              value={md_tbd}
                              onChange={({ event, specialty }) => {
                                this.setState({
                                  md_tbd: event.target.checked ? Number(specialty.id) : null,
                                });
                              }}
                            />
                          )}

                          {showAlertReportType && (
                            <>
                              {report_type_id === REPORT_EARLY_INTERVENTION_TYPE_ID && (
                                <Row className="main-info createReport_alert">
                                  <Text>{EARLY_MESSAGE}</Text>
                                </Row>
                              )}

                              {isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT) && (
                                <>
                                  {report_type_id === REPORT_LIFE_CARE_PLAN_TYPE_ID && (
                                    <Row className="main-info createReport_alert">
                                      <Text>Nurse will reach out directly to provide a quote</Text>
                                    </Row>
                                  )}
                                </>
                              )}

                              {report_type_id === REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID && (
                                <Row className="main-info createReport_alert">
                                  <Text>{MCP_MESSAGE}</Text>
                                </Row>
                              )}

                              {report_type_id === REPORT_MSA_TYPE_ID && (
                                <Row type="flex" justify="start" className="main-info" style={{ width: '100%' }}>
                                  <Checkbox
                                    checked={settlement === 'pre'}
                                    onChange={() => {
                                      this.setState({
                                        settlement: 'pre',
                                      });
                                    }}>
                                    Pre Settlement
                                  </Checkbox>
                                  <Checkbox
                                    checked={settlement === 'post'}
                                    onChange={() => {
                                      this.setState({
                                        settlement: 'post',
                                      });
                                    }}>
                                    Post Settlement
                                  </Checkbox>
                                </Row>
                              )}
                            </>
                          )}
                        </div>
                      )}

                      <div className="addClaimant_label">
                        <p>Needed By</p>
                        <DatePicker
                          className="addClaimantDatePicker"
                          format="MM/DD/YYYY"
                          allowClear={false}
                          value={needed_by}
                          placeholder="DD / MM / YYYY"
                          disabledDate={current => {
                            return moment().add(-1, 'days') >= current;
                          }}
                          onChange={date => this.handleChangeNeededBy({ needed_by: date })}
                        />
                      </div>
                    </div>

                    <Title level={4} className="title-text addClaimantMt_4">
                      Claimant Information
                    </Title>

                    <div className="addClaimantContainer">
                      <div className="addClaimant_col2LeftSmall">
                        <InputBox
                          label="Title"
                          value={claimant_title}
                          onChange={value => this.setState({ claimant_title: value })}
                          classes="addClaimantInput"
                          placeholder="ie. Mr."
                        />
                        <InputBox
                          label="First Name"
                          value={claimant_name}
                          onChange={value => this.setState({ claimant_name: value })}
                          classes="addClaimantInput"
                        />
                      </div>

                      <div className="addClaimant_col2LeftSmall">
                        <InputBox
                          label="Middle Name"
                          placeholder="Optional"
                          value={claimant_middle_name}
                          onChange={value => this.setState({ claimant_middle_name: value })}
                          classes="addClaimantInput"
                        />
                        <InputBox
                          label="Last Name"
                          value={claimant_last_name}
                          onChange={value => this.setState({ claimant_last_name: value })}
                          classes="addClaimantInput"
                        />
                      </div>

                      <div className="addClaimant_label">
                        <p>Date of Birth</p>
                        <DatePicker
                          className="addClaimantDatePicker"
                          format="MM/DD/YYYY"
                          allowClear={false}
                          value={dob}
                          placeholder="DD / MM / YYYY"
                          onChange={date => this.handleSetValue('dob', date)}
                        />
                      </div>

                      <div className={isNotClient ? 'addClaimant_col2LeftSmall' : ''}>
                        {isNotClient && (
                          <div className="addClaimant_label">
                            <p>Rated Age</p>
                            <InputMask
                              className="addClaimantInput"
                              placeholder="Optional"
                              value={rated_age}
                              onChange={e => this.setState({ rated_age: e.target.value }, this.createFinalAddress)}
                            />
                          </div>
                        )}
                        <div className="addClaimant_label">
                          <p>Gender</p>
                          <Select
                            showSearch
                            placeholder="Gender"
                            optionFilterProp="children"
                            className="dropdown addClaimant_select"
                            value={Gender || ''}
                            onChange={value => this.handleSetValue('Gender', value)}
                            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                            <Option disabled value={''}>
                              Required
                            </Option>
                            <Option value={'Male'}>Male</Option>
                            <Option value={'Female'}>Female</Option>
                          </Select>
                        </div>
                      </div>

                      <div className="addClaimant_label">
                        <p>SSN</p>
                        <InputMask
                          mask="999-99-9999"
                          placeholder="Required"
                          className="addClaimantInput"
                          value={SSN}
                          onChange={e => this.setState({ SSN: e.target.value })}
                        />
                      </div>

                      <InputBox
                        label="MBI"
                        placeholder="Optional"
                        classes="addClaimantInput"
                        value={HICN}
                        onChange={value => this.setState({ HICN: value })}
                      />

                      <div className="addClaimant_label">
                        <p>Phone</p>
                        <InputMask
                          mask="999-999-9999"
                          className="addClaimantInput"
                          placeholder="Required"
                          value={claimant_phone}
                          onChange={e => this.setState({ claimant_phone: e.target.value })}
                        />
                      </div>

                      <InputBox
                        classes={`addClaimantInput ${
                          claimant_email && !validatedFormatEmail({ email: claimant_email }) ? 'input_error' : ''
                        }`}
                        type="email"
                        label="Email"
                        placeholder="Required"
                        value={claimant_email}
                        onChange={value => this.setState({ claimant_email: value })}
                      />

                      <div className="addClaimant_colAll">
                        <InputBox
                          label="Address"
                          value={address}
                          onChange={value => this.setState({ address: value }, this.createFinalAddress)}
                          classes="addClaimantInput"
                        />
                      </div>

                      <div className="addClaimant_colAll">
                        <InputBox
                          label="Additional Address"
                          placeholder="Optional"
                          value={additional_addres}
                          onChange={value => this.setState({ additional_addres: value }, this.createFinalAddress)}
                          classes="addClaimantInput"
                        />
                      </div>

                      <InputBox
                        label="City"
                        value={city}
                        classes="addClaimantInput"
                        onChange={value => this.setState({ city: value }, this.createFinalAddress)}
                      />
                      <div className="addClaimant_col2">
                        <div className="addClaimant_label">
                          <p>State</p>
                          <Select
                            showSearch
                            placeholder="Please select"
                            optionFilterProp="children"
                            className="dropdown addClaimant_select"
                            value={state}
                            onChange={value => {
                              this.setState({ state: value }, this.createFinalAddress);
                            }}
                            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                            <Option disabled value={''}>
                              Required
                            </Option>
                            {juridiction_list.map((el, i) => {
                              return (
                                <Option key={i} value={el.name}>
                                  {el.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>

                        <div className="addClaimant_label">
                          <p>Zip Code</p>
                          <InputMask
                            mask="99999"
                            className="addClaimantInput"
                            placeholder="Required"
                            value={zip_code}
                            onChange={e => this.setState({ zip_code: e.target.value }, this.createFinalAddress)}
                          />
                        </div>
                      </div>
                    </div>

                    <Title level={4} className="title-text addClaimantMt_4">
                      Claim Information
                    </Title>

                    <div className="addClaimantContainer">
                      <InputBox
                        label="Claim #"
                        value={claim_number}
                        onChange={value => this.setState({ claim_number: value })}
                        classes="addClaimantInput"
                        placeholder="Required"
                      />

                      <InputBox
                        label="Attorney Estimated Value of Case"
                        placeholder="Optional"
                        value={present_value_case}
                        onChange={value => this.setState({ present_value_case: value }, this.createFinalAddress)}
                        classes="addClaimantInput"
                        type="number"
                      />

                      <div className="addClaimant_label">
                        <InputBox
                          label="Insurance carrier"
                          value={insurance_carrier}
                          onChange={value => this.setState({ insurance_carrier: value })}
                          classes="addClaimantInput"
                        />
                      </div>

                      <InputCurrencyBox
                        label="Policy Limits"
                        placeholder="Please enter"
                        value={policy_limits}
                        onChange={value => this.setState({ policy_limits: value })}
                        classes="addClaimantInput"
                      />

                      <div className="addClaimant_colAll addClaimant_textarea">
                        <TextAreaBox
                          label="Body Parts Accepted by Claims"
                          placeholder="Optional"
                          value={body_parts}
                          onChange={value => this.setState({ body_parts: value })}
                        />
                      </div>

                      <div className="addClaimant_colAll addClaimant_textarea">
                        <RichTextEditor
                          label="Additional Comment"
                          placeholder="Optional"
                          value={additional_comments}
                          onChange={value => this.setState({ additional_comments: value })}
                        />
                      </div>

                      <div className="addClaimant_colAll addClaimant_button">
                        <Button
                          disabled={!isFormValid}
                          type="primary"
                          size="large"
                          className="green-btn button"
                          onClick={() => this.handleChangeStep({ step: STEP_CHECKOUT })}>
                          Create
                        </Button>
                      </div>
                    </div>
                  </Row>
                </SpinBox>
              </Row>
            )}
          </Row>
        )}

        {step === STEP_CHECKOUT && (
          <StepCheckout
            reportType={report_type_id}
            onNextStep={() =>
              this.handleChangeStep({
                step: [
                  REPORT_MCP_TYPE_ID,
                  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
                  REPORT_LIFE_CARE_PLAN_TYPE_ID,
                ].includes(report_type_id)
                  ? STEP_MEDICAL_REVIEWER
                  : STEP_UPLOAD_DOCUMENT,
              })
            }
            onPrevStep={() => this.handleChangeStep({ step: STEP_CLAIMANT_INFO })}
            changeTypeReport={this.handleUpdateTypeReport}
          />
        )}

        {step === STEP_MEDICAL_REVIEWER && (
          <StepMedicalReviewer
            reportType={report_type_id}
            onNextStep={() =>
              this.handleChangeStep({
                step: STEP_UPLOAD_DOCUMENT,
              })
            }
            onPrevStep={() => this.handleChangeStep({ step: STEP_CHECKOUT })}
            onChangeMdTbd={this.handleChangeMdTbd}
            mdTbd={md_tbd}
          />
        )}

        {step === STEP_UPLOAD_DOCUMENT && (
          <StepUpload
            reportType={report_type_id}
            onPrevStep={() =>
              this.handleChangeStep({
                step: [
                  REPORT_MCP_TYPE_ID,
                  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
                  REPORT_LIFE_CARE_PLAN_TYPE_ID,
                ].includes(report_type_id)
                  ? STEP_MEDICAL_REVIEWER
                  : STEP_CHECKOUT,
              })
            }
            onCreate={this.handleCreateClaimant}
          />
        )}

        {REPORT_PERMISSIONS.alertRushFees && modalRushFees && (
          <ModalRushFees
            onConfirm={() => this.handleCloseRushFees({ confirm: true })}
            onChange={() => this.handleCloseRushFees()}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {})(AddClaimant);
