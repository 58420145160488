import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Input, Typography } from 'antd';
import { loginBackground } from '../../../redux/auth/actions';
import AuthSideBar from '../../../components/AuthSideBar';
import './SetPassword.scss';

const { Title, Text } = Typography;

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      changeFlag: false,
      error: '',
    };

    this.setPassword = this.setPassword.bind(this);
  }

  async setPassword() {
    const { newPassword, confirmPassword } = this.state;

    if (!newPassword) {
      this.setState({
        error: 'Invalid Password',
        changeFlag: false,
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      this.setState({
        error: 'Password should be same',
        changeFlag: false,
      });
      return;
    }

    const regex = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}$/;

    if (!regex.test(newPassword)) {
      this.setState({
        error:
          'Use a password that has at least 8 characters, use at least one number, one uppercase letter, one lowercase letter and one special symbol.',
        changeFlag: false,
      });
      return;
    }
    const url = window.location.href;
    const userName = this.getUserNameInUrl({ url });
    const confirmCode = this.extractParamValue(url);

    console.log({ userName, confirmCode });

    const resetSuccess = await this.props.loginBackground({ userName, newPassword, confirmCode });

    if (resetSuccess) {
      this.setState({ changeFlag: false, error: '' });
      this.props.history.push('/confirmSet');
    } else {
      this.setState({
        error: 'An unexpected error has occurred',
        changeFlag: false,
      });
    }
  }

  extractParamValue(urlString){
    const confirmationCodeStart = urlString.indexOf('confirmation_code=') + 'confirmation_code='.length;
    const confirmationCodeEnd = urlString.indexOf('&user_name', confirmationCodeStart);
    const confirmationCode = urlString.substring(confirmationCodeStart, confirmationCodeEnd !== -1 ? confirmationCodeEnd : undefined);
    const specialCharsRegex = /(%22|%20|%24|%25|%E2|%80|%93|%27|%21|%23|%24|%26|%27|%28|%29|%2A|%2B|%2C|%2F|%3A|%3B|%3D|%3F|%40|%5B|%5D|%20|%25|%2D|%2E|%3C|%3E|%5C|%5E|%5F|%60|%7B|%7C|%7D|%7E)/g;

    if (specialCharsRegex.test(confirmationCode)) {
      const decodedUrl = confirmationCode.replace(specialCharsRegex, match => decodeURIComponent(match));
      return decodedUrl;
    }
    return confirmationCode;
  }

  getUserNameInUrl({ url }) {
    const regex = /user_name=([^&]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  render() {
    const { newPassword, confirmPassword, changeFlag, error } = this.state;
    const { isLoading, errorMessage, userInfo } = this.props;

    return (
      <Row type="flex" justify="center" className="setPassword-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row type="flex" justify="center">
            <Col xs={20} md={20}>
              <Row type="flex" justify="space-around" className="main-container">
                <Col style={{ margin: 'auto' }}>
                  <Row type="flex" justify="center" align="middle" style={{ flexDirection: 'column' }}>
                    <Title level={2} className="tit">
                      Welcome to MSA,
                      {userInfo &&
                        userInfo.challengeParam &&
                        userInfo.challengeParam.userAttributes &&
                        (userInfo.challengeParam.userAttributes.given_name
                          ? userInfo.challengeParam.userAttributes.given_name
                          : '') +
                          ' ' +
                          (userInfo.challengeParam.userAttributes.family_name
                            ? userInfo.challengeParam.userAttributes.family_name
                            : '')}
                    </Title>
                    <Title level={1} className="sub-title">
                      Please set your password
                    </Title>
                    <Text className="label">
                      For more security the password should contain numbers and uppercase letters
                    </Text>
                    <Row type="flex" justify="space-around" className="input-row">
                      <Input
                        size="large"
                        placeholder="Your Password"
                        className="password"
                        value={newPassword}
                        type="password"
                        onChange={e =>
                          this.setState({
                            newPassword: e.target.value,
                            changeFlag: true,
                          })
                        }
                      />
                      <Input
                        size="large"
                        placeholder="Confirm Your Password"
                        className="password"
                        value={confirmPassword}
                        type="password"
                        onChange={e =>
                          this.setState({
                            confirmPassword: e.target.value,
                            changeFlag: true,
                          })
                        }
                      />
                    </Row>
                    <Row className="error-text">
                      {(error || errorMessage) && !changeFlag && <Text type="danger">{error || errorMessage}</Text>}
                    </Row>
                    <Row>
                      {isLoading ? (
                        <Button type="primary" size="large" className="green-btn button" loading>
                          Loading
                        </Button>
                      ) : (
                        <Button type="primary" size="large" onClick={this.setPassword} className="green-btn button">
                          Create Account
                        </Button>
                      )}
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    isLoading: state.auth.isLoading,
    errorMessage: state.auth.errorMessage,
    userInfo: state.auth.userInfo,
  };
  return props;
};

export default connect(mapStateToProps, { loginBackground })(SetPassword);
