import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Empty, Row, Typography } from 'antd';

import { BackIcon, EditIcon, RemoveIcon } from 'components/Icons';
import Pagination from 'components/Pagination';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import { formatCurrency } from 'utils/utils';
import UpdatePricingPlan from './UpdatePricingPlan';
import { updateSurgicalPlanData } from 'utils/api';
import { notifyApiError, notifyInfo } from '../../utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

import './PricingPlan.scss';

export default function Index(props) {
  const { limit } = useSelector(({ application }) => application.settings);
  const { roles } = useSelector(state => state.auth);

  const id = props.match.params.id;
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [open, setOpen] = useState(false);
  const [allPricingPlans, setAllPricingPlans] = useState([]);
  const [pricingPlanData, setPricingPlanData] = useState('');
  const [addLevel, setAddLevel] = useState([]);
  const [currentEditPlanLevels, setCurrentEditPlanLevels] = useState();
  const [priceLevelError, setPriceLevelError] = useState('');
  const [levelError, setLevelError] = useState('');
  const [cptList, setCptList] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const getPricingPlans = useCallback(async () => {
    setLoading(true);
    API.getSurgicalPlans(props.match.params.id, page, limit).then(res => {
      setAllPricingPlans(res.surgical_pricing_plans);
      setTotal(res.count || 0);
      setLoading(false);
    });
  }, [limit, page, props.match.params.id]);

  useEffect(() => {
    getPricingPlans();
    API.getCPTData().then(res => {
      setCptList(res.cpt_codes);
    });
  }, [getPricingPlans, props.match.params.id]);

  const deletePricingPlan = id => {
    setLoading(true);
    API.deleteSurgicalPlan(id).then(() => {
      let tempArr = [...allPricingPlans];
      const filteredPlans = tempArr.filter(data => data.id !== id);
      setLoading(false);
      setAllPricingPlans(filteredPlans);
    });
  };

  const addLevels = () => {
    setAddLevel(prev => [...prev, { price: 0.0, level: '' }]);

    setPriceLevelError('');
  };

  const deleteAddedLevels = index => {
    let tempArr = [...addLevel];
    tempArr.splice(index, 1);

    setAddLevel(tempArr);
    setPriceLevelError('');
    setLevelError('');
  };

  const deleteLevel = async id => {
    setPending(true);

    try {
      await API.deleteSurgicalPlanLevel(id);

      setPricingPlanData(prev => ({
        ...prev,
        levels: prev.levels.filter(level => level.id !== id),
      }));

      setCurrentEditPlanLevels(prev => prev.filter(level => level.id !== id));
    } catch (e) {
      notifyApiError(e);
    } finally {
      setPending(false);
    }
  };

  const updateSurgicalPlan = async () => {
    setLoading(true);
    setOpen(false);
    const { id = 0 } = pricingPlanData;

    const updateLevelData = currentEditPlanLevels.map(data => ({
      id: data.id,
      level_number: +data.level_number,
      price: +data.price,
      surgical_pricing_plan_id: +data.surgical_pricing_plan_id,
    }));

    const addedLevels = addLevel.map(data => ({
      level_number: +data.level,
      price: Number(data.price),
      surgical_pricing_plan_id: +id,
    }));

    setPending(true);

    try {
      await updateSurgicalPlanData(id, {
        code_id: pricingPlanData.code_id,
        price: pricingPlanData.price,
        pricing_group_id: pricingPlanData.pricing_group_id,
      });

      if (updateLevelData.length) await API.updateSurgicalPlanLevel(updateLevelData);

      if (addedLevels.length) {
        await API.createSurgicalPricingPlan(addedLevels);
        setAddLevel([]);
      }

      setPriceLevelError('');
      notifyInfo('Pricing plan updated');

      await getPricingPlans();
    } catch (e) {
      notifyApiError(e);
      setOpen(true);
    } finally {
      setLoading(false);
      setPending(false);
    }
  };

  const searchCodes = value => {
    API.getCPTData(value).then(res => {
      setCptList(res.cpt_codes);
    });
  };

  const handleExistingLevelChange = (key, value, index) => {
    let tempArr = [...currentEditPlanLevels];
    tempArr[index][key] = value;

    setCurrentEditPlanLevels(tempArr);
    setPriceLevelError('');
    setLevelError('');
  };

  const handleNewLevelChange = (key, value, index) => {
    let tempArr = [...addLevel];
    tempArr[index][key] = value;
    setAddLevel(tempArr);
    setPriceLevelError('');
    setLevelError('');
  };

  const handleChange = useCallback((value, option) => {
    setPricingPlanData(prev => ({
      ...prev,
      code_id: value,
      cpt_code: option ? { code: option.props.code } : prev.cpt_code,
    }));
  }, []);

  const changeCptList = useCallback(code => {
    setCptList(prev => [...prev, code]);
  }, []);

  const countCols = width => {
    if (!isAllowed(roles, ROLES_DIVISIONS.MANAGEMENT)) {
      return width + 2;
    }

    return width;
  };

  return (
    <Row className="users-container">
      <Row type="flex" align="middle" className="header">
        <Col className="back-pricing-btn">
          <BackIcon style={{ cursor: 'pointer' }} onClick={() => props.history.goBack()} />
        </Col>
        <Col className="title">
          <Title level={4} className="title-text">
            Pricing Matrix
          </Title>
        </Col>
        <Col className="pagination">
          <Pagination pageSize={limit} pageIndex={page} totalCount={total} onChange={setPage} />
        </Col>
        {isAllowed(roles, ROLES_DIVISIONS.MANAGEMENT) && (
          <Col className="add-button dropdown-container">
            <Button type="primary" className="green-btn" onClick={() => props.history.push('/app/pricing/add/' + id)}>
              Add Pricing Item
            </Button>
          </Col>
        )}
      </Row>

      <Row type="flex" className="table-header">
        <Col xs={countCols(12)}>Code</Col>
        <Col xs={countCols(8)}>Price</Col>
        {isAllowed(roles, ROLES_DIVISIONS.MANAGEMENT) && (
          <Col xs={4} style={{ textAlign: 'right' }}>
            Actions
          </Col>
        )}
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading}>
          {allPricingPlans.length > 0 ? (
            <Row>
              {allPricingPlans
                .sort((a, b) => {
                  if (a.cpt_code.code < b.cpt_code.code) {
                    return -1;
                  }

                  if (a.cpt_code.code > b.cpt_code.code) {
                    return 1;
                  }
                  return 0;
                })

                .map((data, index) => (
                  <Row key={index} className="record rendered-data">
                    <Col md={countCols(12)}>
                      <Text className="rendered-name" ellipsis>
                        {data.cpt_code.code + ` ( ${data.cpt_code.med_descr} )`}
                      </Text>
                    </Col>
                    <Col md={countCols(8)}>
                      <Text className="rendered-name">{formatCurrency(data.price)}</Text>
                    </Col>
                    {isAllowed(roles, ROLES_DIVISIONS.MANAGEMENT) && (
                      <Col md={4} style={{ textAlign: 'right' }}>
                        <EditIcon
                          className="icon"
                          onClick={() => {
                            setOpen(true);
                            setPricingPlanData(data);
                            setCurrentEditPlanLevels(data.levels);
                          }}
                        />
                        <RemoveIcon className="icon" onClick={() => deletePricingPlan(data.id)} />
                      </Col>
                    )}
                  </Row>
                ))}
              <>
                <UpdatePricingPlan
                  isOpen={open}
                  onCancel={() => {
                    setOpen(false);
                    setPriceLevelError('');
                    setLevelError('');
                    setAddLevel([]);
                  }}
                  loading={pending}
                  pricingPlanData={pricingPlanData}
                  onPlanPriceChange={value => {
                    setPricingPlanData({
                      ...pricingPlanData,
                      price: value,
                    });
                  }}
                  onLevelChange={handleExistingLevelChange}
                  onLevelNewChange={handleNewLevelChange}
                  deleteLevel={deleteLevel}
                  updateSurgicalPlan={updateSurgicalPlan}
                  addLevel={addLevel}
                  addLevels={addLevels}
                  deleteAddedLevels={deleteAddedLevels}
                  priceLevelError={priceLevelError}
                  levelError={levelError}
                  searchCodes={searchCodes}
                  cptList={cptList}
                  handleChange={handleChange}
                  changeCptList={changeCptList}
                />
              </>
            </Row>
          ) : (
            <Empty
              description={loading ? false : 'No Data'}
              image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
              className="empty-icon"
            />
          )}
        </SpinBox>
      </Row>
    </Row>
  );
}
