const getInflation = (settings, P, t, format) => {
  if (P === 0) {
    return 0;
  }
  if (t === 1) {
    return P;
  }
  
  //P is the totalCost,number of years
  let get1YearInflation = (settings, P, t) => {
    settings.interest_period_per_year = "1.0";
    let A = 0;
    let r = parseFloat(settings.interest_rate) / 100;
    let n = parseFloat(settings.interest_period_per_year);
    let base = 1 + r / n;
    let power = n * t;
    A = P * Math.pow(base, power);

    return A.toFixed(2);
  };

  let lastyear = parseFloat(P) / t;
  let sum = 0.0;
  for (let i = 0; i < t; i++) {
    lastyear = get1YearInflation(settings, lastyear, 1);
    sum = sum + parseFloat(lastyear);
  }

  if (format) {
    return sum.toFixed(2);
  }
  return sum;
};
export default getInflation;
