import React from 'react';

// Components
import { Row, Col, Typography, Button } from 'antd';
import { BackIcon } from 'components/Icons';
import { SpecialtyCheck } from 'components/SpecialtyCheck';

// Const
import {
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  REPORT_TYPE_NAME_BY_ID,
} from 'constants/reports';

// Style
import './stepMedicalReviewer.scss';

const { Title } = Typography;

const StepMedicalReviewer = ({ onPrevStep, onNextStep, reportType, onChangeMdTbd, mdTbd }) => (
  <Row className="add-claimant-container newClaimantStyle">
    <Row type="flex" align="middle" className="header">
      <Col className="title">
        <Title level={4} className="title-text">
          Check-out: {REPORT_TYPE_NAME_BY_ID[reportType]}
        </Title>
      </Col>
      <Col className="back" onClick={onPrevStep}>
        <BackIcon className="icon" />
      </Col>
    </Row>

    <div className="stepMedicalReviewer">
      <div className="stepMedicalReviewer_content">
        <Title level={4} className="title-text">
          Review Add Ons
        </Title>
        <div className="stepMedicalReviewer_checkboxs">
          {[REPORT_MCP_TYPE_ID, REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID, REPORT_LIFE_CARE_PLAN_TYPE_ID].includes(
            reportType
          ) && (
            <SpecialtyCheck
              value={mdTbd}
              onChange={({ event, specialty }) => {
                onChangeMdTbd({ mdTbd: event.target.checked ? Number(specialty.id) : null });
              }}
            />
          )}
        </div>

        <p>
          <strong>Important Information</strong>
        </p>

        <ul>
          <li>MD Sign offs are available and can be added to any report</li>
          <li>There is an additional fee which is a pass-through fee paid to the provider.</li>
          <li>The MD will then attend the deposition and or trial if for an additional fee if needed. </li>
          <li>We have a list in our portal to choose from.</li>
          <li>
            If you decide you would like the patient's provider to sign off vs a non-treating provider we can make
            arrangements for that as well.
          </li>
          <li>
            MD will NOT provide their opinion and only go by the treating MD's recommendations. All reports will remain
            evidenced based.
          </li>
        </ul>

        <p className="title-text">See DEARTA ANDERSON-MOODY and SANDRA ANDERSON,..., --- So.3d ---- (2023)</p>
      </div>

      <div className="stepCheckout_btn">
        <Button type="primary" size="large" className="transparent-btn button" onClick={onPrevStep}>
          Previous Step
        </Button>
        <Button type="primary" size="large" className="green-btn button" onClick={onNextStep}>
          Next Step
        </Button>
      </div>
    </div>
  </Row>
);

export default StepMedicalReviewer;
