import React from 'react';
import { Route as ActualRoute } from 'react-router-dom';
import { useSelector } from 'react-redux';

import isAllowed from './roles.js';
import Spinbox from 'components/SpinBox';

const Route = props => {
  let loading = false;
  const state = useSelector(state => state.auth);
  let roles = state.roles;

  if (!isAllowed(roles, props.roles || [])) {
    loading = true;

    return <Spinbox loading={loading} />;
  }

  loading = false;
  return <ActualRoute {...props} />;
};

export default Route;
