import React, {forwardRef} from 'react';
import { Col, Row, Typography, Input} from 'antd';
import DatePicker from 'react-datepicker';
import { BorderOutlined, CalendarTwoTone } from '@ant-design/icons';

import './DateFilter.scss';

const { Text } = Typography;

const DateFilter = ({ createdAt, setCreatedAt, endDay, setEndDay  }) => {

  const CalendarCustomInput = forwardRef(({ onClick, onChange }, ref) => (
    <Input
      prefix={<CalendarTwoTone style={{ fontSize: '17px' }}/>}
      className="inputdate"
      defaultValue={`${createdAt ? new Intl.DateTimeFormat('en').format(createdAt) : ''} ${endDay ? '-' : ''} ${endDay ? new Intl.DateTimeFormat('en').format(endDay) : ''}`} 
      onClick={onClick} 
      onBlur={onChange}
      onChange={onChange}
      ref={ref} 
      placeholder="Select a date range"/>
  ));
  
  const handleDatesRange = (dates) => {
    const [start, end] = dates;
    setCreatedAt(start);
    setEndDay(end);
  };
  return (
    <Row gutter={[16]} className="date-filtering">
      <Col md={24} className="filter-label">
        <Text strong>Filter by date range </Text>
      </Col>
      <Col md={24}>
      <DatePicker
          minDate={new Date('2000-01-01')}
          showMonthDropdown
          showYearDropdown
          placeholderText="Date of Creation"
          selectsRange
          startDate={createdAt}
          endDate={endDay}
          selected={createdAt}
          onChange={handleDatesRange}
          customInput={<CalendarCustomInput />}
          shouldCloseOnSelect={false}
        />
      </Col>
    </Row>
  );
};

export default DateFilter;
