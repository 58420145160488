import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Spin, Typography } from 'antd';
import * as API from 'utils/api';
import UploadLogo from 'components/UploadLogo/UploadLogo';
import './CompanyProfile.scss';
import { notifyApiError, notifyInfo } from '../../utils/notification';
import { useSelector } from 'react-redux';
import { getClientId } from 'layout/roles';

export default function CompanyProfile() {
  const { Text } = Typography;
  const { roles } = useSelector(state => state.auth);
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);

    const params = {
      client_name: companyName,
      client_logo: companyLogo.client_logo,
      id: getClientId(roles),
    };

    //if uploaded new logo
    if (companyLogo.s3key) {
      params.client_logo = companyLogo.s3key;
    } else {
      //remove logo if not modified. they contains long signed urls
      if (companyLogo.client_logo.includes('https://')) {
        delete companyLogo.client_logo;
      }
    }

    try {
      const res = await API.updateClient(params);
      if (res?.success && res.success === 'success') return notifyInfo('Company profile updated');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const clientInfo = await API.getCLientForId({ id: getClientId(roles) });

        setCompanyName(clientInfo.clients[0].client_name);
        setCompanyLogo({
          client_logo: clientInfo.clients[0].client_logo,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [roles]);

  const validForm = !companyName;

  const StyledInput = {
    fontSize: '14px',
    with: '100%',
  };

  return (
    <form onSubmit={onSubmit}>
      <Spin tip="Loading..." spinning={isLoading}>
        <Row className="company_container" type="flex" justify="space-between">
          <Row type="flex" justify="start" align="middle">
            <h1 className="company__title">Company Profile</h1>
          </Row>
          <Row type="flex" justify="center" align="top">
            <Col md={11}>
              <Row className="company__inputs">
                <Text>{companyLogo.client_logo ? 'Your logo' : 'Upload your logo'}</Text>
              </Row>
              <UploadLogo
                changeImage={(client_logo, s3key) => {
                  setCompanyLogo({ ...companyLogo, client_logo, s3key });
                }}
                client_logo={companyLogo.client_logo}
              />

              {companyLogo.client_logo && (
                <div
                  onClick={() => {
                    setCompanyLogo({
                      ...companyLogo,
                      client_logo: '',
                    });
                  }}
                  className="remove_logo_btn">
                  Remove
                </div>
              )}
            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Company Name</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="companyName"
                value={companyName}
                style={StyledInput}
                onChange={e => setCompanyName(e.target.value)}
              />
            </Col>
          </Row>

          <Row type="flex" justify="end">
            <Col md={6}>
              <button disabled={validForm} className="company_form_btn" type="submit">
                Save
              </button>
            </Col>
          </Row>
        </Row>
      </Spin>
    </form>
  );
}
