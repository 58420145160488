import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Typography, Spin, Row, Col } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { updateReportInfo } from 'redux/report';

// Components
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { notifyApiError } from 'utils/notification';
import { ModalCreateTable } from '../components/ModalCreateTable';

// Utils
import * as API from 'utils/api';
import { reorder } from 'containers/Reports/utils/helpers';

// Icons
import { CloseIcon } from 'components/Svg';

// Const
import { TEMPLATE_EDIT_TABLE_NAV, TEMPLATE_CREATE_TABLE_NAV, TEMPLATE_NAV } from 'layout/navigate_const';
import { STATUS_OPEN } from 'constants/report_status';
import { REPORT_LIFE_CARE_PLAN_NAME } from 'constants/reports';
import {
  ITEMS_TABLE_DRAGGALE,
  TABLE_DRAGGABLE_ID,
  TABLE_MEDICAL_COSTS_DRAGGABLE_ID,
  TABLE_SURGERIES_DRAGGABLE_ID,
  TABLE_PRESCRIPTION_DRAGGABLE_ID,
  COLUMNS_TABLE_BY_TYPE,
} from '../const/itemsTableDragable';

import { TemplateLifeCarePlanDragStructure } from '../components/TemplateLifeCarePlanDragStructure';
import { TemplateLifeCarePlanDragExisting } from '../components/TemplateLifeCarePlanDragExisting';
import { TemplateLifeCarePlanDragLayout } from '../components/TemplateLifeCarePlanDragLayout';

import './TemplateLifeCarePlanCreate.scss';

const { Title } = Typography;
const droppableLayout = 'Layout';
const droppableExisting = 'Existing';

function TemplateLifeCarePlanCreate() {
  const dispatch = useDispatch();

  const { idReport } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [templateStructure, setTemplateStructure] = useState([]);
  const [layout, setLayout] = useState([]);
  const [backupLayout, setBackupLayout] = useState([]);
  const [reportInfo, setReportInfo] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [templateCreated, setTemplateCreated] = useState([]);
  const [toggleSectionExisting, setToggleSectionExisting] = useState(false);
  const [listReportType, setListReportType] = useState([]);
  const [modalCreateTable, setModalCreateTable] = useState(false);
  const [positionNewTable, setPositionNewTable] = useState(0);

  const createBody = ({ template_structure }) => {
    return {
      report: {
        id: reportInfo.id,
        report_name: reportInfo.report_name,
        is_template: true,
        report_type_id: reportInfo.report_type_id,
        report_status_id: STATUS_OPEN.id,
      },
      template_structure,
    };
  };

  const getTemplate = async () => {
    const template = await API.getTemplateAndTableLifeCarePlanByReportId({ idReport });

    let structureList = [];

    if (template.success) {
      structureList = template.templateStructure.template_structure.sort(function (a, b) {
        const firstPosition = a.position ?? a.order;
        const secondPosition = b.position ?? b.order;

        if (firstPosition > secondPosition) {
          return 1;
        }
        if (firstPosition < secondPosition) {
          return -1;
        }
        return 0;
      });
    }

    setTemplateStructure(structureList);

    return template;
  };

  const getExcludeSection = ({ list }) => {
    return list.filter(item => item.template_fields).map(item => item.template_fields[0].template_section_id);
  };

  const getExcludeTable = ({ list }) => {
    return list.filter(item => item.name).map(item => item.name);
  };

  const getAllData = async () => {
    try {
      const results = await Promise.allSettled([
        API.getLayoutLifeCarePlan(),
        API.getReportById(idReport, true),
        API.getTemplateCreatedLCP({ id: idReport }),
        API.getReportTypes(),
      ]);

      const template = await getTemplate();

      let excludeSection = [];
      let excludeTable = [];

      if (template?.success) {
        excludeSection = getExcludeSection({ list: template.templateStructure.template_structure });
        excludeTable = getExcludeTable({ list: template.templateStructure.template_structure });
      }

      const itemSectionLayout = [
        ...ITEMS_TABLE_DRAGGALE.filter(item => !excludeTable.includes(item.name)),
        ...results[0].value.filter(item => !excludeSection.includes(item.id)),
      ];

      // redux
      dispatch(updateReportInfo(results[1]?.value || {}));

      // State
      setLayout(itemSectionLayout);
      setBackupLayout([...ITEMS_TABLE_DRAGGALE, ...results[0].value]);
      setReportInfo(results[1]?.value || {});
      setTemplateCreated(results[2].value.templateStructure);
      setListReportType(results[3].value.reportTypes);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const passExistingToStructure = async ({ source, destination }) => {
    const clone = _.cloneDeep(templateCreated);
    const add = clone[source.index];

    // creamos el body que se enviara al back
    const body = createBody({
      template_structure: add.template_structure.map(section => ({
        alias: section.alias,
        position: section.position,
        template_fields: section.template_fields,
      })),
    });

    // mandamos a crear el structure
    const res = await API.createTemplateLifeCarePlan({ body });

    // obtenemos los id de las secciones que ya existian en la estructura
    const idOld = templateStructure.map(item => item.id);

    // obtenemos los registros que no tengan los ids que se busco arriba idOld
    const newStructureCreated = res.templateStructure.template_structure.filter(item => !idOld.includes(item.id));
    const cloneTemplateStructure = _.cloneDeep(templateStructure);

    newStructureCreated.forEach((element, index) => {
      cloneTemplateStructure.splice(destination.index + index, 0, element);
    });

    const reOrderTemplateStructure = reOrderTemplateStructureByIndex({ list: cloneTemplateStructure }).flat();

    await updatePositionStructureAndTable({ list: reOrderTemplateStructure });

    // sacamos el sections del layput
    const excludeSection = getExcludeSection({ list: cloneTemplateStructure });
    setLayout(backupLayout.filter(item => !excludeSection.includes(item.id)));

    await getTemplate();
  };

  const passLayoutToStructure = async ({ source, destination }) => {
    const clone = _.cloneDeep(layout);
    const add = clone[source.index];

    if (
      ![
        TABLE_DRAGGABLE_ID,
        TABLE_MEDICAL_COSTS_DRAGGABLE_ID,
        TABLE_SURGERIES_DRAGGABLE_ID,
        TABLE_PRESCRIPTION_DRAGGABLE_ID,
      ].includes(add.id)
    ) {
      // no es una tabla
      // creamos el body que se enviara al back
      const body = createBody({
        template_structure: [
          {
            alias: add.name,
            position: destination.index,
            template_fields: add.template_fields.map(item => {
              delete item.id;
              delete item.template_structure_id;

              return {
                ...item,
                is_default: false,
              };
            }),
          },
        ],
      });

      // mandamos a crear el structure
      const res = await API.createTemplateLifeCarePlan({ body });

      // obtenemos los id de las secciones que ya existian en la estructura
      const idOld = templateStructure.map(item => item.id);

      // obtenemos los registros que no tengan los ids que se busco arriba idOld
      const newStructureCreated = res.templateStructure.template_structure.filter(item => !idOld.includes(item.id));

      const cloneTemplateStructure = _.cloneDeep(templateStructure);
      cloneTemplateStructure.splice(destination.index, 0, newStructureCreated[0]);

      const reOrderTemplateStructure = reOrderTemplateStructureByIndex({ list: cloneTemplateStructure });

      await updatePositionStructureAndTable({ list: reOrderTemplateStructure });

      // sacamos el sections del layout
      const templateSectionId = add.template_fields[0].template_section_id;

      const updateField = clone.filter(item => item.id !== templateSectionId);

      setLayout(updateField);

      return await getTemplate();
    } else {
      if (TABLE_DRAGGABLE_ID === add.id) {
        setModalCreateTable(true);
        return setPositionNewTable(destination.index);
      }

      const tableInfo = {
        ...COLUMNS_TABLE_BY_TYPE[add.id],
        order: destination.index,
        report_id: idReport,
      };

      const createTable = await API.createTemplateLifeCarePlanTable({ body: tableInfo });

      tableInfo.id = createTable.id;

      await onUpdateOrderStructure({ tableInfo });

      const updateField = clone.filter(item => item.name !== add.name);

      setLayout(updateField);
    }
  };

  const changePositionStructure = async ({ data }) => {
    const oldPosition = data.source.index;
    const newPosition = data.destination.index;

    if (oldPosition === newPosition) return;

    const newTemplateStructure = reorder(templateStructure, oldPosition, newPosition);

    const reOrderTemplateStructure = reOrderTemplateStructureByIndex({ list: newTemplateStructure });

    await updatePositionStructureAndTable({ list: reOrderTemplateStructure });

    return await getTemplate();
  };

  const onDragEnd = async data => {
    try {
      setLoadingPage(true);
      const { source, destination } = data;

      // pasamos de existing a report
      if (
        source &&
        destination &&
        source.droppableId === droppableExisting &&
        source.droppableId !== destination.droppableId
      ) {
        return await passExistingToStructure({ source, destination });
      }

      // pasamos de layout a report
      if (
        source &&
        destination &&
        source.droppableId === droppableLayout &&
        source.droppableId !== destination.droppableId
      ) {
        return await passLayoutToStructure({ source, destination });
      }

      // cambiamos de posicion en templateStructure
      if (source && destination && source.droppableId === destination.droppableId) {
        return await changePositionStructure({ data });
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoadingPage(false);
    }
  };

  const updatePositionStructureAndTable = async ({ list }) => {
    const updateOrderStructure = list.filter(item => item.template_fields);

    const updateOrderTable = list
      .filter(item => !item.template_fields)
      .map(item => ({ id: item.id, order: item.order }));

    const listPromise = [];

    if (updateOrderStructure.length > 0)
      listPromise.push(
        API.editTemplateLifeCarePlanStructure({
          body: createBody({ template_structure: updateOrderStructure }),
        })
      );

    if (updateOrderTable.length > 0) API.updateOrderTemplateLifeCarePlanTable({ body: updateOrderTable });

    await Promise.all(listPromise);
  };

  const reOrderTemplateStructureByIndex = ({ list }) => {
    return list.map((item, index) => {
      if (item.position !== undefined) {
        // es una estructura
        return {
          ...item,
          position: index,
        };
      }

      // es una tabla
      return {
        ...item,
        order: index,
      };
    });
  };

  const handleDeleteStructure = async ({ idStructure }) => {
    try {
      setLoadingPage(true);
      const clone = _.cloneDeep(templateStructure);
      const removed = clone.findIndex(item => item.id === idStructure);
      clone.splice(removed, 1);
      await API.deleteTemplateLifeCarePlanStructure({ idStructure });

      setTemplateStructure([...clone]);

      const backupTable = backupLayout.filter(item => !Number(item.id));
      const backupStructure = backupLayout.filter(item => Number(item.id));

      let excludeSection = [];
      let excludeTable = [];

      if (clone.length) {
        excludeSection = getExcludeSection({ list: clone });
        excludeTable = getExcludeTable({ list: clone });
      }

      const itemSectionLayout = [
        ...backupTable.filter(item => !excludeTable.includes(item.name)),
        ...backupStructure.filter(item => !excludeSection.includes(item.id)),
      ];

      setLayout(itemSectionLayout);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSaveStructure = async ({ update }) => {
    try {
      setLoadingPage(true);
      const clone = _.cloneDeep(templateStructure);

      const structureIndex = clone.findIndex(item => item.id === update.id);
      clone[structureIndex] = update;

      // sacamos las tablas
      const structureWithoutTable = clone.filter(item => item.alias);

      const body = createBody({ template_structure: structureWithoutTable });

      await API.editTemplateLifeCarePlanStructure({ body });
      await getTemplate();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }

    // setTemplateStructure(body.template_structure);
  };

  const handleToggleSectionExisting = () => setToggleSectionExisting(prevState => !prevState);

  const toggleModalCreate = () => setModalCreateTable(prevState => !prevState);

  const onUpdateOrderStructure = async ({ tableInfo }) => {
    try {
      setLoadingPage(true);
      const cloneTemplateStructure = _.cloneDeep(templateStructure);
      cloneTemplateStructure.splice(tableInfo.order, 0, tableInfo);

      const reOrderTemplateStructure = reOrderTemplateStructureByIndex({ list: cloneTemplateStructure });

      await updatePositionStructureAndTable({ list: reOrderTemplateStructure });

      await getTemplate();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleDeleteTable = async () => {
    // if (addTableInLayout)
    const template = await getTemplate();

    let excludeSection = [];
    let excludeTable = [];

    if (template?.success) {
      excludeSection = getExcludeSection({ list: template.templateStructure.template_structure });
      excludeTable = getExcludeTable({ list: template.templateStructure.template_structure });
    }

    const backupTable = backupLayout.filter(item => !Number(item.id));
    const backupStructure = backupLayout.filter(item => Number(item.id));

    const itemSectionLayout = [
      ...backupTable.filter(item => !excludeTable.includes(item.name)),
      ...backupStructure.filter(item => !excludeSection.includes(item.id)),
    ];

    // State
    setLayout(itemSectionLayout);
  };

  return (
    <>
      <Spin spinning={loadingPage}>
        <Row className="clients-container">
          <Row type="flex" align="middle" className="header templateLifeCarePlan_header">
            <Col className="title">
              <Title level={4} className="title-text">
                {REPORT_LIFE_CARE_PLAN_NAME}
              </Title>
            </Col>
            <Col className="closeTemplate" onClick={() => history.push(TEMPLATE_NAV.path)}>
              <CloseIcon color={PRIMARY_LIGHT_BLUE} /> Close
            </Col>
            <Col className="pagination add-button"></Col>
          </Row>

          <div className="table-content">
            <div className="lifeCarePlanTemplate">
              <DragDropContext onDragEnd={onDragEnd}>
                <TemplateLifeCarePlanDragStructure
                  templateStructure={templateStructure}
                  handleDeleteStructure={handleDeleteStructure}
                  handleSaveStructure={handleSaveStructure}
                  listReportType={listReportType}
                  handleDeleteTable={handleDeleteTable}
                  onClickNextStep={() =>
                    history.push(
                      pathname.includes('edit')
                        ? `${TEMPLATE_EDIT_TABLE_NAV.path}/${idReport}`
                        : `${TEMPLATE_CREATE_TABLE_NAV.path}/${idReport}`
                    )
                  }
                />

                <div className="lifeCarePlanTemplate_layout">
                  <TemplateLifeCarePlanDragExisting
                    handleToggleSectionExisting={handleToggleSectionExisting}
                    droppableExisting={droppableExisting}
                    toggleSectionExisting={toggleSectionExisting}
                    templateCreated={templateCreated}
                  />

                  <TemplateLifeCarePlanDragLayout droppableLayout={droppableLayout} layout={layout} />
                </div>
              </DragDropContext>
            </div>
          </div>
        </Row>
      </Spin>

      {modalCreateTable && (
        <ModalCreateTable
          onClose={() => {
            toggleModalCreate();
          }}
          orderNewTable={positionNewTable}
          onUpdateOrderStructure={onUpdateOrderStructure}
        />
      )}
    </>
  );
}

export default TemplateLifeCarePlanCreate;
