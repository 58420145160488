import * as React from 'react';

// Colors
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

const AmericanExpressIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill={PRIMARY_LIGHT_BLUE}
      d="M48 480c-26.51 0-48-21.5-48-48V80c0-26.51 21.49-48 48-48h480c26.5 0 48 21.49 48 48v2.43h-75.5l-17 47.57-16.9-47.57h-97.2v63.17l-28.1-63.17h-78.6L181 267.1h65.8v163.8h203.7l31.9-35.1 31.9 35.1H576v1.1c0 26.5-21.5 48-48 48H48zm434.6-116-42.2 46.3h-49.9l67.5-71.7-67.5-72.5h51.4l41.5 46.7 42-46.7H576l-68 72.1 68 72.1h-51.4l-42-46.3zm93.4-67.1v83.3l-39.3-41.9 39.3-41.4zm-268.4 80.2h83v33.2h-122V267.1h122v33.1h-83v22.4h80.9v32.3h-80.9v22.3-.1zm229.7-231.4-36.9 100.6H466L429.2 146v100.3h-38.7V103h61.2l31.9 89.3 32.2-89.3H576v143.3h-38.7V145.7zm-202.8 71.9h-65.9l-11.9 28.7h-43L276.1 103h51.2l63.3 143.3h-44.1l-12-28.7zm-33-79.1L282 185.4h38.9l-19.4-46.9z"
    />
  </svg>
);

export default AmericanExpressIcon;
