export const REPORT_EARLY_INTERVENTION = 'early_intervention';
export const REPORT_MCP = 'mcp';
export const REPORT_LEGAL_DEMAND = 'legal_demand_template';
export const REPORT_MSA = 'msa';
export const REPORT_MCP_MSA = 'mcp_msa';
export const REPORT_SURGICAL = 'surgical';
export const REPORT_MSA_REDUCTION = 'msa_reduction';

export const REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS = 'Court-WC Board Documents';
export const REPORT_FIELD_MEDICAL_RECORDS = 'Medical Records';
export const REPORT_FILED_PAYMENT_INFORMATION = 'Payment Information';
export const REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO = 'Supplemental Documentation-Additional Info';
export const REPORT_FILED_CONSENT_FORMS = 'Consent Forms';
export const REPORT_FIELD_RATED_AGES = 'Rated Ages';
export const REPORT_FIELD_MSA_ADMINISTRATION_AGREEMENT = 'MSA Administration Agreement';
export const REPORT_FIELD_REDUCTION_LETTER = 'Reduction Letter';

export const REPORT_FIELDS_UPLOAD = {
  [REPORT_EARLY_INTERVENTION]: [
    REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS,
    REPORT_FIELD_MEDICAL_RECORDS,
    REPORT_FILED_PAYMENT_INFORMATION,
    REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO,
  ],
  [REPORT_MCP]: [
    REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS,
    REPORT_FIELD_MEDICAL_RECORDS,
    REPORT_FILED_PAYMENT_INFORMATION,
    REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO,
  ],
  [REPORT_LEGAL_DEMAND]: [
    REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS,
    REPORT_FIELD_MEDICAL_RECORDS,
    REPORT_FILED_PAYMENT_INFORMATION,
    REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO,
  ],
  [REPORT_MSA]: [
    REPORT_FILED_CONSENT_FORMS,
    REPORT_FIELD_RATED_AGES,
    REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS,
    REPORT_FIELD_MSA_ADMINISTRATION_AGREEMENT,
    REPORT_FIELD_MEDICAL_RECORDS,
    REPORT_FILED_PAYMENT_INFORMATION,
    REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO,
    REPORT_FIELD_REDUCTION_LETTER,
  ],
  [REPORT_MCP_MSA]: [
    REPORT_FILED_CONSENT_FORMS,
    REPORT_FIELD_RATED_AGES,
    REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS,
    REPORT_FIELD_MSA_ADMINISTRATION_AGREEMENT,
    REPORT_FIELD_MEDICAL_RECORDS,
    REPORT_FILED_PAYMENT_INFORMATION,
    REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO,
    REPORT_FIELD_REDUCTION_LETTER,
  ],
  [REPORT_SURGICAL]: [
    REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS,
    REPORT_FIELD_MEDICAL_RECORDS,
    REPORT_FILED_PAYMENT_INFORMATION,
    REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO,
  ],
  [REPORT_MSA_REDUCTION]: [
    REPORT_FIELD_COURT_WC_BOARD_DOCUMENTS,
    REPORT_FIELD_MEDICAL_RECORDS,
    REPORT_FILED_PAYMENT_INFORMATION,
    REPORT_FILED_SUPPLEMENTAL_DOCUMENT_ADDITIONAL_INFO,
  ],
};
