import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Icon, Row, Select, Tooltip, Typography } from 'antd';

import CustomCodeModal from '../../components/CustomCodeModal';
import InputCurrencyBox from 'components/InputCurrencyBox';

import './PricingForm.scss';

const { Option } = Select;
const { Text } = Typography;

export default function PricingForm({
  cptList,
  changeCptList,
  searchCodes,
  cptData,
  priceError,
  codeErrorr,
  onPriceChange,
  onChange,
  setCptData,
}) {
  const [customCodeModal, setCustomCodeModal] = useState(false);
  const [customCode, setCustomCode] = useState(null);

  if (customCode) {
    cptData.cpt_hcpcs_id = customCode.id;
  }

  useEffect(() => {
    if (customCode) {
      changeCptList(customCode);
      setCptData({ name: customCode.short_descr, code: customCode.id, cpt_hcpcs_id: customCode.id });
    }
  }, [customCode, setCptData, changeCptList]);

  return (
    <Row type="flex" justify="space-between" align="middle" className="main-info">
      <Col md={16} className="row">
        <Row>
          <Text>Code</Text>
        </Row>
        <Select
          showSearch
          placeholder="Required"
          filterOption={false}
          className="dropdown"
          onSearch={value => {
            if (value) {
              searchCodes(value);
            }
          }}
          onChange={(value, option) => {
            setCustomCode(null);
            onChange(value, option);
          }}
          value={cptData.cpt_hcpcs_id || customCode?.id}
          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
          {cptList &&
            cptList
              .sort((a, b) => {
                if (a.code < b.code) {
                  return -1;
                }
                if (a.code > b.code) {
                  return 1;
                }
                return 0;
              })
              .map(item => (
                <Option
                  value={item.id}
                  name={item.short_descr}
                  code={item.code}
                  filter={item.code.concat('_' + item.med_descr)}
                  key={item.id}>
                  <Tooltip placement="right" title={item.long_descr}>
                    {item.code + ' (' + item.short_descr + ')'}
                  </Tooltip>
                </Option>
              ))}
        </Select>
      </Col>
      <Col md={6} style={{ marginRight: 33 }} className="row">
        <Row>
          <span>Price</span>
        </Row>
        <InputCurrencyBox
          name="price"
          noNegative={true}
          placeholder="Required"
          value={cptData.price}
          onChange={value => onPriceChange(value)}
        />
      </Col>
      <Row>
        <Button type="primary" className="green-btn button custom-code" onClick={() => setCustomCodeModal(true)}>
          Add Custom Code
        </Button>
      </Row>
      <Row style={{ width: '95%' }}>
        {priceError && <Alert type="error" message={priceError} banner />}
        {codeErrorr && <Alert type="error" message={codeErrorr} banner />}
      </Row>
      <CustomCodeModal
        setCustomCode={setCustomCode}
        customCodeModal={customCodeModal}
        setCustomCodeModal={setCustomCodeModal}
      />
    </Row>
  );
}
