import React from 'react';
import { Row, Typography } from 'antd';
import { formatInputMoney } from 'utils/utils';
import './InputCurrencyBox.scss';

const { Text } = Typography;

const InputCurrencyBox = ({ label, placeholder, classes, disabled, value, onChange }) => {
  const onlyNumber = value2 => {
    onChange(formatInputMoney({ number: value2, decimals: 2 }));
  };

  return (
    <Row className="input-currency-box-container">
      {label && (
        <Row className="label">
          <Text>{label}</Text>
        </Row>
      )}
      <Row>
        <div className='inputMoney'>
          <input
            type="text"
            placeholder={placeholder || 'Required'}
            className={`inputMoney ${classes || ''}`}
            disabled={disabled || false}
            value={value}
            onChange={e => onlyNumber(String(e.target.value))}
          />
          <p>$</p>
        </div>
      </Row>
    </Row>
  );
};

export default InputCurrencyBox;
