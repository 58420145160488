import React, { Component } from 'react';
import { Row, Col, Typography, Button, Select, Icon } from 'antd';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { BackIcon } from 'components/Icons';
import InputBox from 'components/InputBox';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import Address from 'components/Address';
import { notifyApiError, notifyError, notifyInfo } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import './EditContact.scss';

const { Text, Title } = Typography;
const { Option } = Select;

class EditContact extends Component {
  constructor(props) {
    super(props);
    let contactInfo = {};

    if (this.props.location && this.props.location.state) {
      contactInfo = this.props.location.state.data;
    }

    if (this.props.data) {
      contactInfo = this.props.data;
    }

    this.state = {
      contact_id: contactInfo.id,
      contact_name: contactInfo.contact_name,
      organisation_name: contactInfo.organisation_name,
      address: contactInfo.address,
      phone_number: contactInfo.phone_number,
      fax_number: contactInfo.fax_number,
      email: contactInfo.email,
      contact_type: contactInfo.contact_type_id,
      loading: false,
      loadingTitle: 'Loading...',
      contact_list: [],
    };

    this.handleSetValue = this.handleSetValue.bind(this);
    this.handleSetContactType = this.handleSetContactType.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.handleEditContact = this.handleEditContact.bind(this);
    this.getContactTypes = this.getContactTypes.bind(this);
  }
  handleSetValue(type, value) {
    this.setState({
      [type]: value,
    });
  }

  handleSetContactType(value) {
    this.handleSetValue('contact_type', value);
  }

  getAddress(address) {
    this.setState({ address });
  }

  async handleEditContact() {
    const {
      contact_id,
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type,
    } = this.state;
    const param = {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type_id: contact_type,
    };
    if (!contact_name) return notifyError('Please input the Contact Name');

    this.setState({ loadingTitle: 'Saving...', loading: true });

    try {
      const isUserAdmin = isAllowed(this.props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.SALES_MANAGER,
      ]);
      const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);
      const params = isUserNurseManager && !isUserAdmin ? { tenantOnly: true, ...param } : param;

      await API.updateContact(contact_id, params);

      notifyInfo('Contact was updated');

      if (this.props.history) return this.props.history.push('/app/contacts');

      this.props.onClose({ contact_id, contact_type });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  getContactTypes() {
    API.getContactTypes().then(res => {
      this.setState({ contact_list: res.contact_type });
    });
  }

  componentDidMount() {
    this.getContactTypes();
  }

  render() {
    const {
      contact_name,
      organisation_name,
      phone_number,
      fax_number,
      email,
      contact_type,
      loadingTitle,
      loading,
      contact_list,
    } = this.state;
    return (
      <Row className="add-user-container">
        {!this.props.removeHeader && (
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Edit Contact
              </Title>
            </Col>
            <Col className="back" onClick={() => this.props.history.goBack()}>
              <BackIcon className="icon" />
            </Col>
          </Row>
        )}
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox
                    label="Contact Name"
                    value={contact_name}
                    onChange={value => this.setState({ contact_name: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Organization Name"
                    value={organisation_name}
                    onChange={value => this.setState({ organisation_name: value })}
                    placeholder=''
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <Address getAddress={this.getAddress} address={this.state.address} required={false} />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    placeholder="Phone"
                    className="input-ssn"
                    value={phone_number}
                    onChange={e => this.setState({ phone_number: e.target.value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Fax Number</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    placeholder="Fax Number"
                    className="input-ssn"
                    value={fax_number}
                    onChange={e => this.setState({ fax_number: e.target.value })}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox
                    placeholder=''
                    label="Email"
                    value={email}
                    onChange={value => this.setState({ email: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <Row>
                    <Text>Contact Type</Text>
                  </Row>
                  <Row>
                    <Select
                      disabled={this.props.disableTypeEditing}
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={contact_type}
                      onChange={value => this.handleSetContactType(value)}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {contact_list.map((el, i) => {
                        return (
                          <Option key={i} value={el.id}>
                            {el.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button type="primary" size="large" className="green-btn button" onClick={this.handleEditContact}>
                  Save Changes
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
const mapStateToProps = state => ({
  roles: state.auth.roles,
});
export default connect(mapStateToProps, null)(EditContact);
