import React, { useState, useRef } from 'react';

// Components
import { Row, Col, Typography, Button } from 'antd';
import { BackIcon } from 'components/Icons';
import { ModalYesOrNo } from 'components/Modal/ModalYesOrNo';

// Const
import {
  REPORT_TYPE_NAME_BY_ID,
  REPORT_TYPE_FULL_NAME,
  REPORT_MSA_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  ADDITIONAL_TYPE_FULL_NAME,
  REPORT_REVISION_TYPE_ID,
  REPORT_MSA_REDUCTION_TYPE_ID,
  REPORT_MCP_MSA_TYPE_ID,
} from 'constants/reports';

// Style
import './stepCheckout.scss';

const { Title } = Typography;

const StepCheckout = ({ reportType, onPrevStep, onNextStep, changeTypeReport }) => {
  const topDiv = useRef(null);

  const [selectedReport, setSelectedReport] = useState(reportType);
  const [modalConfirmChangeReport, setModalConfirmChangeReport] = useState(false);
  const [selectedReportChange, setSelectedReportChange] = useState(null);

  const handleChangeReportType = ({ changeReport }) => {
    topDiv.current.scrollTop = 0;
    setSelectedReport(changeReport);
  };

  const handleUpdateReportType = ({ changeReport }) => {
    changeTypeReport({ changeType: changeReport });
  };

  const handleOpenModalConfirmChangeReportType = ({ selectedReport }) => {
    setModalConfirmChangeReport(true);
    setSelectedReportChange({
      id: selectedReport,
      name: REPORT_TYPE_FULL_NAME.find(item => item.id === selectedReport).name,
    });
  };

  const renderTitleRerpot = ({ reportId, additional }) => {
    const listReportName = additional ? ADDITIONAL_TYPE_FULL_NAME : REPORT_TYPE_FULL_NAME;

    return (
      <>
        <h2 className="stepCheckout_card_text_title">{listReportName.find(item => item.id === reportId).name}</h2>
        {!additional && (
          <>
            {reportType === reportId ? (
              <p className="stepCheckout_card_text_title_selected">
                <b>This is your current selection</b>
              </p>
            ) : (
              <p
                className="stepCheckout_card_text_title_changeReport"
                onClick={() => handleOpenModalConfirmChangeReportType({ selectedReport: reportId })}>
                <b>Click here</b> if you would like to change your selection to this report type
              </p>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Row className="add-claimant-container newClaimantStyle">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Check-out: {REPORT_TYPE_NAME_BY_ID[reportType]}
            </Title>
          </Col>
          <Col className="back" onClick={onPrevStep}>
            <BackIcon className="icon" />
          </Col>
        </Row>

        <div className="stepCheckout">
          <div className="stepCheckout_card">
            <div className="stepCheckout_card_report">
              <Title level={4} className="title-text">
                Did I select the right report?
              </Title>
              {REPORT_TYPE_FULL_NAME.map(report => (
                <div
                  key={report.id}
                  className={`stepCheckout_content_report stepCheckout_report_${report.id} ${
                    selectedReport === report.id ? 'stepCheckout_report_active' : ''
                  }`}
                  onClick={() => handleChangeReportType({ changeReport: report.id })}>
                  <div className="stepCheckout_changeRerport">
                    <div
                      className={`stepCheckout_circle ${
                        reportType === report.id ? 'stepCheckout_report_check_active' : ''
                      }`}
                      onClick={() => handleOpenModalConfirmChangeReportType({ selectedReport: report.id })}
                    />
                    <p className="stepCheckout_report">{report.name}</p>
                  </div>
                  {reportType === report.id && <p className="stepCheckout_report_selected">Your Selection</p>}
                </div>
              ))}
              <p>* Additional Information</p>
              {ADDITIONAL_TYPE_FULL_NAME.map(report => (
                <div
                  key={report.id}
                  onClick={() => handleChangeReportType({ changeReport: report.id })}
                  className={`stepCheckout_content_report stepCheckout_report_${report.id} ${
                    selectedReport === report.id ? 'stepCheckout_report_active' : ''
                  }`}>
                  <p className="stepCheckout_report">{report.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="stepCheckout_card" ref={topDiv}>
            <div className="stepCheckout_card_text">
              {selectedReport === REPORT_MCP_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_MCP_TYPE_ID })}
                  <p>
                    Does the patient have one or more injuries?
                    <br />
                    Does the patient have Multiple treating providers? <br />
                    Has the patient had Multiple procedures or surgeries? <br />
                    Have you been unable to settle the case with an initial demand?
                  </p>

                  <p className="stepCheckout_card_red_texts">
                    If you answered yes to one or more of the above, then a medical cost Projection would be beneficial.
                  </p>

                  <strong>What types of claims would you use an MCP?</strong>

                  <ul>
                    <li>Pre-settlement</li>
                    <li>When submitting an Initial Demand</li>
                    <li>When submitting Final Demand</li>
                    <li>Litigated Cases</li>
                  </ul>

                  <strong>MCP Information Required:</strong>

                  <ul>
                    <li>
                      Last two years of Medical Treatment records including office visit notes, diagnostics, Labs,
                      surgery/procedure note, hospital or rehab records, future treatment letters
                    </li>
                    <li>LOP's/Billing Ledgers</li>
                    <li>Report of Accident</li>
                    <li>List of denied/controverted body parts/conditions</li>
                    <li>
                      If medicals exceed 3K pages then an additional charge of $750 will be applied. If medicals, then
                      exceed 6K and additional $750 will be added and anything over 12K pages would add another $750.
                      Example if a case was submitted that had 12K pages the additional cost would be $2,250.00.
                    </li>
                  </ul>

                  <strong>Turn Around Time</strong>

                  <ul>
                    <li>MCP reports are turned around in 10-14 business days</li>
                    <li>Rush requests are available for an additional charge of ____</li>
                  </ul>
                </>
              )}

              {selectedReport === REPORT_MSA_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_MSA_TYPE_ID })}
                  <p>
                    Is the patient receiving Medicare Benefits age 65? <br />
                    Is the patient within 30 months of receiving Medicare benefits 62.5 years of age? <br />
                    Is the patient receiving SSDI benefits or any other Federal Benefit such a Medicaid? <br />
                    Has the patient applied for SSDI and not yet accepted? <br />
                    Does the patient have a catastrophic injury? <br />
                    Does the patient have end stage kidney disease Does the patient receive benefits for Black Lung?
                  </p>

                  <p className="stepCheckout_card_red_texts">
                    If you answered yes to one or more of the above, then a Medicare Set-Aside would be beneficial.
                  </p>

                  <strong>What types of claims would you use an MSA?</strong>

                  <ul>
                    <li>Pre-Settlement</li>
                    <li>Post-Settlement</li>
                    <li>
                      If you are unable to settle after initial demand and used an MCP. This will help show a “Federal
                      Exposure” and add additional value to your claim at time of settlement.
                    </li>
                    <li>When submitting a final demand</li>
                  </ul>

                  <p className="stepCheckout_card_red_texts">
                    Litigated cases with potential Federal Exposure for Medicare, Medicaid, SSDI, SSI etc.
                  </p>

                  <strong>Information Required</strong>

                  <ul>
                    <li>
                      Last two years of Medical Treatment records including office visit notes, diagnostics, Labs,
                      surgery/procedure note, hospital or rehab records, future treatment letters
                    </li>
                    <li>LOP's/Billing Ledgers</li>
                    <li>Report of Accident</li>
                    <li>Copy of Medicare card</li>
                    <li>Copy of SSDI approval or application</li>
                    <li>Medicare Lien Information</li>
                    <li>List of denied/controverted body parts/conditions</li>
                    <li>
                      If medicals exceed 3K pages then an additional charge of $750 will be applied. If medicals, then
                      exceed 6K and additional $750 will be added and anything over 12K pages would add another $750.
                      Example if a case was submitted that had 12K pages the additional cost would be $2,250.00.
                    </li>
                  </ul>

                  <strong>Turn Around Time</strong>

                  <ul>
                    <li>MSA reports are turned around in 10-14 business days</li>
                    <li>Rush requests are available for an additional charge of ____</li>
                  </ul>
                </>
              )}

              {selectedReport === REPORT_MCP_MSA_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_MCP_MSA_TYPE_ID })}
                  <p>Refer to MSA and MCP information to learn about this report type.</p>
                  <p>
                    Additionally, please refer to your Sales Rep to make sure this report type is suitable for your
                    needs.
                  </p>
                </>
              )}

              {selectedReport === REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID })}
                  <p>
                    Are the medical records less than 150 pages unprocessed? <br />
                    Are the policy limits low? Under 50K <br />
                    Does the patient have one or two basic injuries that are uncomplicated? <br />
                  </p>

                  <p className="stepCheckout_card_red_texts">
                    If you answered yes to one or more of the above, then a Mini Projection would be appropriate.
                  </p>

                  <strong>What types of claims would you use a Mini Projection?</strong>

                  <ul>
                    <li>Non-Litigated cases</li>
                    <li>Low Policy Limits</li>
                    <li>Minimal Medical Care</li>
                    <li>cal Care</li>
                    <li>Pre-Settlement</li>
                    <li>Used for Initial Demands</li>
                    <li>
                      Not support for Trial or Depositions (this is a cheap way to obtain future medical costs on lower
                      policy limit cases)
                    </li>
                  </ul>

                  <strong>Information Required</strong>

                  <ul>
                    <li>
                      Last two years of Medical Treatment records including office visit notes, diagnostics, Labs,
                      surgery/procedure note, hospital or rehab records, future treatment letters (Must be less than 150
                      pages unprocessed). If over 150 pages we will process as a regular MCP.
                    </li>
                    <li>LOP's/Billing Ledgers</li>
                    <li>Report of Accident</li>
                    <li>List of denied/controverted body parts/conditions</li>
                  </ul>

                  <strong>Turn Around Time</strong>

                  <ul>
                    <li>Mimi reports are turned around in 10-14 business days</li>
                    <li>Rush requests are available for an additional charge of ____</li>
                    <li>We do not support depositions/trial for any Mini report</li>
                    <li>
                      Comments are not placed in the spreadsheets with Mini reports, only full MSA and MCP reports.
                    </li>
                  </ul>
                </>
              )}

              {selectedReport === REPORT_EARLY_INTERVENTION_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_EARLY_INTERVENTION_TYPE_ID })}
                  <p>
                    Are you unsure of the value of your case or where to find value in a case? <br />
                    Are the medicals limited? <br />
                    Are the policy limits low?
                  </p>

                  <p className="stepCheckout_card_red_texts">
                    If you answered yes to one or more of the above, then a Mini Projection would be appropriate.
                  </p>

                  <strong>What types of claims would you use a Mini Projection?</strong>

                  <ul>
                    <li>Non-Litigated cases</li>
                    <li>Low Policy Limits</li>
                    <li>Minimal Medical Care</li>
                    <li>Pre-Settlement</li>
                    <li>Used for Initial Demands</li>
                    <li>
                      Not support for Trial or Depositions (this is a cheap way to obtain future medical costs on lower
                      policy limit cases)
                    </li>
                  </ul>

                  <strong>Information Required</strong>

                  <ul>
                    <li>
                      Last two years of Medical Treatment records including office visit notes, diagnostics, Labs,
                      surgery/procedure note, hospital or rehab records, future treatment letters (Must be less than 150
                      pages unprocessed). If over 150 pages we will process as a regular MCP.
                    </li>
                    <li>LOP's/Billing Ledgers</li>
                    <li>Report of Accident</li>
                    <li>List of denied/controverted body parts/conditions</li>
                  </ul>

                  <p>
                    The nurse will provide recommendations on how to move forward and will indicate what is required to
                    get file to settlement. Example: if a patient had complained of headaches and blurry vision and no
                    evaluation had been done on the brain, we would recommend referral to specialists to evaluate for
                    TBI.
                  </p>

                  <strong>Turn Around Time</strong>

                  <ul>
                    <li>EIR reports are turned around in 10-14 business days</li>
                    <li>Rush requests are available for an additional charge of ____</li>
                    <li>We do not support depositions/trial for any EIR report</li>
                    <li>
                      Comments are not placed in the spreadsheets with Mini reports, only full MSA and MCP reports.
                    </li>
                    <li>
                      If the case becomes more complex and an MSA or MCP is required in the future half the cost of the
                      EIR report will be discounted off the new referral.
                    </li>
                  </ul>
                </>
              )}

              {selectedReport === REPORT_LIFE_CARE_PLAN_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_LIFE_CARE_PLAN_TYPE_ID })}
                  <p>
                    Is the claim litigated and scheduled for court in the future or are depositions/trial anticipated in
                    the future?
                  </p>

                  <p>
                    Is the patient catastrophically injured (Traumatic Brain Injury, Loss of Limb, Spinal Cord Injury,
                    Chronic Pain, Spinal Cord Stimulator, 2nd of 3rd degree burns, multiple fractures and or injuries
                    which require lifetime medical care)
                  </p>

                  <strong>What types of claims would you use a Life Care Plan?</strong>

                  <ul>
                    <li>Pre-Settlement/Litigated Claims</li>
                    <li>Catastrophic Claims</li>
                    <li>Traumatic Brain Injury</li>
                    <li>Loss of Limb</li>
                    <li>Spinal Cord Injury</li>
                    <li>Chronic Pain</li>
                    <li>Spinal Cord Stimulator</li>
                    <li>2nd of 3rd degree burns</li>
                    <li>Multiple fractures and or injuries which require lifetime medical care</li>
                  </ul>

                  <strong>Information Required</strong>

                  <ul>
                    <li>Report of injury</li>
                    <li>
                      All medical records including hospital records, rehab records, office visits, diagnostics,
                      procedures/surgeries reports, physical therapy/chiropractic, home health care records, DME/home
                      modification evaluations, driving evaluations, hearing/vision evaluations, TBI rehab, TBI
                      evaluations.
                    </li>
                    <li>
                      List any areas of concern regarding the case such as possible denial due to pre-existing
                      conditions etc
                    </li>
                    <li>Policy limits</li>
                    <li>Estimated date of trial/depo/settlement</li>
                    <li>Specific instructions prior to starting the case</li>
                  </ul>

                  <strong>Turn Around Time</strong>

                  <ul>
                    <li>
                      Turnaround times vary on all LCP cases. This is based on case complexity and number of medical
                      records provided at time of referral.
                    </li>
                  </ul>
                </>
              )}

              {selectedReport === REPORT_REVISION_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_REVISION_TYPE_ID, additional: true })}
                  <p>Are there updated medical records?</p>

                  <p>Are the medical records less than 100 pages?</p>

                  <p>Is the file ready for settlement?</p>

                  <p>If you answered yes to one or more of the above, then a revision would be appropriate.</p>

                  <strong>Information Required</strong>

                  <ul>
                    <li>
                      Updated Medical records since the time the last report was completed (look at the prior report at
                      the last note documented and any records after that date would need to be provided.
                    </li>
                    <li>Updated billing ledgers/LOPs</li>
                    <li>
                      You get one free revision if the update is required within 6 months of completion of the report
                      and the medicals are 100 pages or less.
                    </li>
                    <li>
                      If medicals are over 100 pages, then a revision fee of $750 will be charged. If excessive medicals
                      are received for the revision then additional fees will apply based on the number of additional
                      pages.
                    </li>
                  </ul>

                  <strong>Turnaround time</strong>

                  <ul>
                    <li>File revisions are turned around in 10-14 business days</li>
                  </ul>
                </>
              )}

              {selectedReport === REPORT_MSA_REDUCTION_TYPE_ID && (
                <>
                  {renderTitleRerpot({ reportId: REPORT_MSA_REDUCTION_TYPE_ID, additional: true })}
                  <p>Has the MSA file settled?</p>

                  <p>If you answered yes, then a Medicare Set-Aside reduction report would be beneficial.</p>

                  <p>
                    If your MSA case has settle we will need the following information in order to complete the MSA
                    reduction
                  </p>

                  <strong>Information Required</strong>

                  <ul>
                    <li>MSA Reduction Worksheet (Provided by MSA Settlements Attorney)</li>
                    <li>Total Value of Case</li>
                    <li>Settlement Amount</li>
                    <li>Policy Limits</li>
                    <li>Total Attorney Fees</li>
                    <li>Cost/Disbursements</li>
                    <li>List of Lien providers and amount of liens and proof of payment</li>
                  </ul>

                  <strong>Turnaround time</strong>

                  <ul>
                    <li>MSA reductions are turned around in 5-business days</li>
                  </ul>
                </>
              )}
            </div>
          </div>

          <div className="stepCheckout_btn">
            <Button type="primary" size="large" className="transparent-btn button" onClick={onPrevStep}>
              Previous Step
            </Button>
            <Button type="primary" size="large" className="green-btn button" onClick={onNextStep}>
              Next Step
            </Button>
          </div>
        </div>
      </Row>

      {modalConfirmChangeReport && (
        <ModalYesOrNo
          onClose={() => {
            setModalConfirmChangeReport(false);
            setSelectedReportChange(null);
          }}
          title={`Are you sure you want to change to an ${selectedReportChange.name} type`}
          onConfirm={() => {
            handleUpdateReportType({ changeReport: selectedReportChange.id });
            setModalConfirmChangeReport(false);
            setSelectedReportChange(null);
          }}
        />
      )}
    </>
  );
};

export default StepCheckout;
