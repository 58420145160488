import React from 'react';
import { Typography } from 'antd';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ElipsisIcon } from 'components/Svg';
import { PRIMARY_BORDER_GREY } from 'containers/Calendar/src/src/lib/utils/colors';

const { Title } = Typography;

const TemplateLifeCarePlanDragLayout = ({ droppableLayout, layout }) => {
  return (
    <>
      <Title level={4} className="title-text">
        Report Layout
      </Title>

      <Droppable droppableId={droppableLayout} type="FIELD" direction="vertical" isDropDisabled={true}>
        {provided => (
          <div className="lifeCarePlanTemplate_layout_items" {...provided.droppableProps} ref={provided.innerRef}>
            {layout.map((item, index) => (
              <Draggable key={item.id} draggableId={`${item.id}s`} index={index}>
                {provided => (
                  <div
                    className="lifeCarePlanTemplate_layout_items_item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <ElipsisIcon color={PRIMARY_BORDER_GREY} />
                    {item.name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default TemplateLifeCarePlanDragLayout;
