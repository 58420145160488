import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UploadLogo from '../../../../../components/UploadLogo/UploadLogo';
import { ModalMessage } from 'components/Modal/ModalMessage';
import useDebounce from 'hooks/useDebounce';
import * as API from 'utils/api';
import '../Step.scss';
import { notifyApiError } from 'utils/notification';

const Step2 = ({ data, onSubmit, user, tenant_id }) => {
  const [companyInfo, setCompanyInfo] = useState(data);
  const [modalDuplicated, setModalDuplicated] = useState(false);
  const [tenant_name, setTenantName] = useState(data.tenant_name || '');
  const [suggestCompany, setSuggestCompany] = useState([]);
  const [noneSuggest, setNoneSuggest] = useState(false);

  const history = useHistory();

  const debouncedSearch = useDebounce(tenant_name, 1000);

  const suggestCorrectCompanyName = async ({ name }) => {
    try {
      const suggest = await API.suggestCorrectCompanyName({ name, tenant_id });
      setSuggestCompany(suggest.data.clients);
    } catch (e) {
      notifyApiError(e);
    }
  };

  useEffect(() => {
    if (debouncedSearch === companyInfo.tenant_name || tenant_name.split('').length < 3 || noneSuggest) return;

    suggestCorrectCompanyName({ name: tenant_name });
  }, [debouncedSearch]);

  const handleChange = e => {
    if (noneSuggest) setNoneSuggest(false);

    if (e.target.value.split('').length < 3 && suggestCompany.length > 0) {
      setSuggestCompany([])
    }
    setTenantName(e.target.value);
  };

  const handleSubmit = async () => {
    // validamos si no existe la empresa
    const isExists = await API.getClientModeStrict({ client_name: tenant_name, tenant_id });

    if (isExists.data.clients.length > 0) {
      setModalDuplicated(true);
      const client = isExists.data.clients[0];
      // creamos el usuarios a la firma que ya existe
      const body = {
        ...user,
        restrictions: 'restrictions',
        client_id: client.id,
        tenant_id: tenant_id,
      };

      await API.createUserWhenLawFirmIsExists({ body });
      return;
    }

    onSubmit && onSubmit({ sectionStep: 'tenant', payload: { ...companyInfo, tenant_name, tenant_id }, nextStep: 3 });
  };

  const redirectToLogin = () => {
    history.push('/');
  };

  const validForm = () => {
    return Boolean(tenant_name);
  };

  const handlerSelectedSuggest = ({ name }) => {
    setNoneSuggest(true);
    setTenantName(name);
  };

  return (
    <div className="stepContainer">
      <div className="stepTitleContainer">
        <p className="stepText">Step 2</p>
        <h1 className="stepTitle">Company Information</h1>
      </div>

      <div className="stepFormContainerStep2">
        <div className="stepFormGroup">
          <div className={`stepAvatar ${companyInfo.logo ? 'isAvatar' : ''}`}>
            <UploadLogo
              changeImage={(logo, s3key) => {
                setCompanyInfo({ ...companyInfo, logo, s3key });
              }}
              client_logo={companyInfo.logo}
            />
            {companyInfo.logo && (
              <div
                onClick={() => setCompanyInfo({ ...companyInfo, logo: '', s3key: '' })}
                className="remove_logo_btn">
                Remove
              </div>
            )}
          </div>
          <label htmlFor="avatar" className="stepLabelLinkAvatar">
            Upload Company Logo
          </label>
        </div>
        <div className="stepFormGroup">
          <input
            onChange={handleChange}
            type="text"
            value={tenant_name || ''}
            className="width4 textCenter"
            name="tenant_name"
            placeholder="Company Name"
            required
          />
          {!noneSuggest && suggestCompany.length > 0 && (
            <div className="didYouMean">
              {suggestCompany.map((item, index) => (
                <p key={index} onClick={() => handlerSelectedSuggest({ name: item.client_name })}>
                  Did you mean: <strong>{item.client_name}</strong>
                </p>
              ))}
            </div>
          )}
        </div>
      </div>

      <button className="stepNextButton" disabled={!validForm()} onClick={handleSubmit}>
        Next Step
      </button>

      {modalDuplicated && (
        <ModalMessage
          message="You will have full access once your system admin has accepted you. For additional help please reach out to Nicole@msasettlements.com"
          title={`Your firm ${companyInfo?.tenant_name} has already registered with us and your firm admin has been notified of your attempt`}
          textButton="Ok"
          onClose={redirectToLogin}
        />
      )}
    </div>
  );
};

export default Step2;
