// import React from 'react';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import React from 'react';
import './listInputsLCP.scss';

const Text = ({ placeholder, label, value, onChange, disabled }) => {
  return (
    <div className='listInputsLCP_input listInputsLCP_input_text'>
      <RichTextEditor
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Text;
