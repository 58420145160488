import React from 'react';
import moment from 'moment';
import { Typography, Divider } from 'antd';

const { Title, Text } = Typography;

const Step2 = ({ data }) => {
  return (
    <>
      <Text>
        At your request, I have gone ahead and prepared an MSA reduction for your personal injury case based on the
        legal principles outlined in various judicial decisions and federal regulations concerning the MSP Act, which is
        legally defensible. I would strongly recommend your client does not submit this reduced amount to CMS, their
        Regional Office. Should your client choose to proceed with the reduced allocation, they should also explain the
        MSP Act to their client, along with CMS's position against reducing an allocation and its potential adverse
        ramifications.
      </Text>
      <Title level={1} className="reportPreView_title">
        MSA REDUCTION RECOMMENDATION
      </Title>

      <div className="reportPreView_col2">
        <div className="reportPreView_col2_item">
          <Text>Name of Injured Party</Text>
          {data.clientsAttorneyName}
        </div>
        <div className="reportPreView_col2_item">
          <Text>DOB</Text>
          {moment(data.dob).format('DD/MM/YYYY')}
        </div>
      </div>

      <div className="reportPreView_col2">
        <div className="reportPreView_col2_item">
          <Text>Date of Injury</Text>
          {moment(data.date_of_injury).format('DD/MM/YYYY')}
        </div>
        <div className="reportPreView_col2_item">
          <Text>SSN</Text>
          {data.SSN}
        </div>
      </div>

      <div className="reportPreView_col2">
        <div className="reportPreView_col2_item">
          <Text>Attorney Name</Text>
          <Text>{data.submitter_attorney_name}</Text>
        </div>
        <div className="reportPreView_col2_item">
          <Text>Firm Name</Text>
          <Text>{data.submitter_attorney_firm_name}</Text>
        </div>
      </div>

      <div className="reportPreView_col2">
        <div className="reportPreView_col2_item">
          <Text>Phone</Text>
          <Text>{data.submitter_attorney_phone}</Text>
        </div>
        <div className="reportPreView_col2_item">
          <Text>Email</Text>
          <Text>{data.submitter_attorney_email}</Text>
        </div>
      </div>
      <div className="reportPreView_col2">
        <div className="reportPreView_col2_item">
          <Text>MSA as prepared</Text>
          {data.settlement_amount}
        </div>
        <div className="reportPreView_col2_item">
          <Text>Total Value of Case</Text>
          <Text>{data.present_value_case}</Text>
        </div>
      </div>
      <div className="reportPreView_col2">
        <div className="reportPreView_col2_item">
          <Text>Actual Settlement Amount</Text>
          <Text>{data.actual_settlement_amount}</Text>
        </div>
        <div className="reportPreView_col2_item">
          <Text>Total Attorney Fees</Text>
          <Text>{data.total_attorney_fees}</Text>
        </div>
      </div>

      <Divider className="divider" />
    </>
  );
};

export default Step2;
