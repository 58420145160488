import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Spin, Row, Col, Typography, Divider } from 'antd';
import { useSelector } from 'react-redux';

// API
import * as API from 'utils/api';

// Components
import ListInputsLCP from 'components/ListInputsLCP';
import { ChooseTemplate } from '../components/ChooseTemplate';
import DropDown from 'components/Dropdown';
import { notifyApiError, notifyError } from 'utils/notification';
// import { SignatureReport } from '../../components/SignatureReport';
import PrevNextStep from 'components/PrevNextStep';

// Const
import { REPORT_LIFE_CARE_PLAN_NAME } from 'constants/reports';
// import { INIT_SIGNATURE } from 'containers/Reports/utils/const';
import { MAIN_NAV, CLAIMANT_DETAIL_NAV, TEMPLATE_CREATE_STRUCTURE_NAV, CLAIMANT_EDIT } from 'layout/navigate_const';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { OPTION_CREATE_LCP } from 'containers/Claimants/DetailedClaimant/ReportModal/ReportModal';

// Utils
// import { getListSignature } from '../../utils/helpers';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { assignedTemplateInReportLCP } from '../../utils/assignedTemplateInReportLCP';

// Style
import './introductionLCP.scss';
import { useReport } from 'hooks';
import ClaimantInfo from 'components/ClaimantInfoOnWizard';
import { FILED_SPECIAL_LIFE_TABLE } from 'containers/Templates/TemplateLifeCarePlan/const/itemsTableDragable';
import { LifeSourceAndLifeTable } from 'containers/Reports/components/LifeSourceAndLifeTable';
import { matchIdToElement, matchSourceToItem } from 'containers/Reports/utils/helpers';
import { LIFE_TYPE } from 'containers/Reports/components/LifeSourceAndLifeTable/LifeSourceAndLifeTable';

const { Title, Text } = Typography;

const IntroductionLCP = () => {
  const { id } = useParams();
  const history = useHistory();
  const { roles } = useSelector(state => state.auth);

  const { getFieldsForUpdateReportLCP } = useReport();

  const [reportStructureLCP, setReportStructureLCP] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [modalSelectTemplate, setModalSelectTemplate] = useState(false);
  // const [signature, setSignature] = useState([]);
  const [reportInfo, setReportInfo] = useState({});
  const [report_claim_id, setReportClaimId] = useState(0);
  const [lifeSources, setLifeSources] = useState([]);
  const [source_id, setSource_id] = useState(0);
  const [lifeTables, setLifeTables] = useState([]);
  const [life_table_id, setLife_table_id] = useState(0);

  // Permissions
  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const toggleModalSelectTemplate = () => setModalSelectTemplate(prevState => !prevState);

  const getReportStructure = async () => {
    const reportStructure = await API.getReportLifeCarePlan({ idReport: id });

    if (
      (Array.isArray(reportStructure?.reportStructure) && reportStructure?.reportStructure.length === 0) ||
      (!Array.isArray(reportStructure?.reportStructure) &&
        reportStructure?.reportStructure?.report_structure.length === 0)
    ) {
      return toggleModalSelectTemplate();
    }

    let report = reportInfo;

    if (Object.keys(report).length === 0) {
      if (REPORT_PERMISSIONS.cloneReport) {
        report = await API.getReportById(id);

        if (report === false) {
          report = await API.getReportById(id, true);
          await API.createViewReport(report);
        }
      } else {
        report = await API.getReportById(id, true);
      }

      const redirect = await qaHaveAssignedReport({
        roles: roles,
        reportId: id,
        claimantId: report.claimant_id,
      });

      if (redirect) return history.push(MAIN_NAV.path);

      setReportInfo(report);
      setReportClaimId(
        report.report_claim_id ? report.report_claim_id.split('$')[0] : Math.floor(Date.now() / 1000) + ''
      );
    }

    // setSignature(getListSignature({ signature: report.signature }));

    if (reportStructure.reportStructure.report_structure.length === 0) {
      notifyError('The fields contained in this report are temporarily invalid');
      return history.push(CLAIMANT_DETAIL_NAV.path);
    }

    setReportStructureLCP(reportStructure.reportStructure.report_structure);

    if (
      reportStructure.reportStructure.report_structure.some(item =>
        item.template_fields.some(item2 => item2.ref === FILED_SPECIAL_LIFE_TABLE)
      )
    ) {
      return report;
    }
  };

  const getLifeSourceAndTable = async ({ reportInfo }) => {
    try {
      const result = await Promise.allSettled([API.getLifeSources(), API.getLifeTables()]);

      const lifeSource = result[0]?.value || {};
      const lifeTable = result[1]?.value || {};

      let idSource = matchSourceToItem(reportInfo.life_expectancy_source, lifeSource?.life_expects || []);
      setLifeSources(lifeSource.life_expects);
      setSource_id(!idSource ? lifeSource.life_expects[0].id : idSource);

      let idTable = matchSourceToItem(reportInfo.life_expectancy_source, lifeTable?.life_expects || []);
      setLifeTables(lifeTable.life_expects);
      setLife_table_id(!idTable ? lifeTable.life_expects[0].id : idTable);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const getAllData = async () => {
    try {
      const report = await getReportStructure();
      if (report) await getLifeSourceAndTable({ reportInfo: report });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSelectTemplate = async ({ chooseTemplateId }) => {
    try {
      setLoadingPage(true);

      if (chooseTemplateId === OPTION_CREATE_LCP) {
        const report = await API.getReportById(id, true);

        const params = {
          show_additional_comments: true,
          report_name: report.report_name,
          report_type_id: report.report_type_id,
          report_status_id: report.report_status_id,
          is_template: true,
          is_private: true,
          from_report: id,
        };

        // creamos el template lcp
        const createTemplate = await API.createReport(params);

        // nos vamos al template lcp
        return history.push(`${TEMPLATE_CREATE_STRUCTURE_NAV.path}/${createTemplate.report.id}`);
      } else {
        const result = await assignedTemplateInReportLCP({ chooseTemplateId, reportId: id });

        if (result === false) {
          notifyApiError('The template does not contain fields');
          return history.push(CLAIMANT_DETAIL_NAV.path);
        }

        // si es LCP y usa un template buscamos la info del template y asignamos los placeholder como value al reporte
        let updateParams = {};
        const reportStructure = await API.getReportLifeCarePlan({ idReport: chooseTemplateId });

        if (reportStructure.reportStructure.report_structure.length > 0) {
          updateParams = await getFieldsForUpdateReportLCP({ idTemplateLCP: chooseTemplateId, params: updateParams });
        }

        updateParams.id = id;
        await API.updateReportNurse(updateParams);

        toggleModalSelectTemplate();
        await getAllData();
        setLoadingPage(false);
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleChangeField = ({ value, ref }) => {
    setReportInfo(prevState => {
      return {
        ...prevState,
        [ref]: value,
      };
    });
  };

  // const handleChangeSignature = data => {
  //   setSignature(data.signature);
  // };

  // const handleDeleteSignature = ({ index }) => {
  //   setSignature(signature.filter((item, inde) => inde !== index));
  // };

  // const handleAddSignature = () => {
  //   const add = [...signature, INIT_SIGNATURE];
  //   setSignature(add);
  // };

  const updateReport = async () => {
    let life_expectancy_source = '';

    if (source_id !== 0 && life_table_id !== 0) {
      const yrly_src = matchIdToElement(lifeSources, source_id);
      const lyfe_table = matchIdToElement(lifeTables, life_table_id);

      life_expectancy_source = yrly_src.name + '.' + lyfe_table.name + ' ';
    }

    const body = {
      ...reportInfo,
      life_table_id,
      ...(!!life_expectancy_source && { life_expectancy_source }),
    };

    // body.signature = signature;

    delete body.logo;

    await API.updateReport(body);
  };

  const handleUpdateReport = async () => {
    try {
      setLoadingPage(true);
      await updateReport();
      setLoadingPage(false);
    } catch (e) {
      notifyApiError(e);
      setLoadingPage(false);
    }
  };

  const goToEditClaimant = () => {
    const claimant = reportInfo.claimant;
    history.push(CLAIMANT_EDIT.path, { data: claimant });
  };

  const handleChangeLife = ({ life, value }) => {
    if (life === LIFE_TYPE.SOURCE) {
      return setSource_id(value);
    }

    setLife_table_id(value);
  };

  return (
    <div className="reportLifeCarePlan">
      <Modal visible={loadingPage} footer={null} className="modal-loader">
        <Spin />
      </Modal>

      {reportStructureLCP.length > 0 && (
        <Row className="msa-medical-content-container">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                {`${REPORT_LIFE_CARE_PLAN_NAME} Report`}
              </Title>
            </Col>
            <Col className="step">
              <DropDown
                loading={loadingPage}
                goBack={() =>
                  history.push(REPORT_PERMISSIONS.goBackClaimant ? CLAIMANT_DETAIL_NAV.path : MAIN_NAV.path)
                }
              />
            </Col>
          </Row>
          <Row className="main">
            <Row className="content">
              <Col md={24}>
                <ClaimantInfo
                  goToEditClaimant={REPORT_PERMISSIONS.edit && goToEditClaimant}
                  claimant={{
                    ...reportInfo.claimant,
                    report_claim_id,
                  }}
                />
              </Col>
              <Divider className="divider" />

              {reportStructureLCP.map(structure => (
                <React.Fragment key={structure.id}>
                  <Row className="LCP_report_content">
                    <Col md={24} className="LCP_report_content_structure">
                      <Text className="title">{structure.alias}</Text>

                      {structure.template_fields.map(field => {
                        if (field.ref === FILED_SPECIAL_LIFE_TABLE) {
                          return (
                            <LifeSourceAndLifeTable
                              lifeTables={lifeTables}
                              life_table_id={life_table_id}
                              source_id={source_id}
                              lifeSources={lifeSources}
                              onChange={handleChangeLife}
                            />
                          );
                        }

                        const Component = ListInputsLCP[field.type];

                        return (
                          <React.Fragment key={field.id}>
                            <Component
                              placeholder={field.placeholder}
                              label={field.label}
                              type={field.type}
                              value={
                                field?.ref
                                  ? reportInfo[field.ref] || ''
                                  : reportInfo[field.label.replace(/ /g, '')] || ''
                              }
                              id={field.id}
                              disabled={!REPORT_PERMISSIONS.edit}
                              onChange={value =>
                                handleChangeField({
                                  value,
                                  fieldId: field.id,
                                  structureId: structure.id,
                                  ref: field?.ref ? field.ref : field.label.replace(/ /g, ''),
                                })
                              }
                            />
                          </React.Fragment>
                        );
                      })}
                    </Col>
                  </Row>
                  <Divider className="divider" />
                </React.Fragment>
              ))}

              {/* {REPORT_PERMISSIONS.signature && (
                <>
                  <SignatureReport
                    defaultSignature={signature}
                    onChangeSignature={handleChangeSignature}
                    onDeleteSignature={handleDeleteSignature}
                    onAddSignature={handleAddSignature}
                    disabled={!REPORT_PERMISSIONS.edit}
                  />

                  <Divider className="divider" />
                </>
              )} */}

              <PrevNextStep saveDraft={REPORT_PERMISSIONS.saveDraft} beforeNext={handleUpdateReport} />
            </Row>
          </Row>
        </Row>
      )}

      {modalSelectTemplate && <ChooseTemplate onClose={() => history.goBack()} onConfirm={handleSelectTemplate} />}
    </div>
  );
};

export default IntroductionLCP;
