//parse different api errors and return string for ant notification
import { notification } from 'antd';

export const notifyApiError = (e, showStatusCode = true) => {
  if (typeof e === 'string') {
    notification['error']({
      message: 'Error',
      description: e,
    });
    return;
  }

  if (e.response?.status === 422) {
    notification['error']({
      message: 'Data is not valid',
      description:
        e.response?.data?.details && Array.isArray(e.response?.data?.details)
          ? e.response?.data?.details[0]?.message
          : 'Please check input data',
    });
    return;
  }

  //simple error from backend
  if (e.response?.data?.error) {
    notification['error']({
      message: 'Error',
      description: e.response.data.error,
    });
    return;
  }

  if (e.response && e.response.data && e.response.data.status && e.response.data.message) {
    const { status, message } = e.response.data;
    notification['error']({
      message: `Error ${status}`,
      description: message,
    });
    return;
  }

  //AWS parse
  if (e.response?.data && e.response.data.includes && e.response.data.includes('xml version')) {
    notification['error']({
      message: 'Error',
      description: 'Cloud services general error',
    });
    return;
  }

  if (!e.isAxiosError || !e.response || !e.response.data || !e.response.data.message) {
    notification['error']({
      message: `Error ${showStatusCode ? e.response?.status || '' : ''}`,
      description: e.message,
    });
    return;
  }
  //axios object
  const { data, status } = e.response;
  notification['error']({
    message: `Error ${showStatusCode ? status : ''}`,
    description: data?.message,
  });
};

export const notifyInfo = (message = 'No message', description = '') => {
  notification['info']({
    message,
    description,
  });
};

export const notifyError = (message = 'Error!', description = '') => {
  notification['error']({
    message,
    description,
  });
};

export const notifySuccess = (message = 'Success', description = '') => {
  notification['success']({
    message,
    description,
  });
};
