import { useState } from 'react';
import * as API from 'utils/api';

export default function useBillDue() {
  const [loadingBillDue, setLoadingBillDue] = useState(false);
  const [billDue, setBillDue] = useState({});

  const getBillDue = async () => {
    try {
      setLoadingBillDue(true);
      const resBillDue = await API.getBillDueService();
      setBillDue(resBillDue);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingBillDue(false);
    }
  };

  return {
    loadingBillDue,
    billDue,
    getBillDue,
  };
}
