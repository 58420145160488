import React, { Component } from 'react';
import { Row, Select, Icon, Modal } from 'antd';
import './index.scss';
import { connect } from 'react-redux';
import * as API from 'utils/api';
import ReportCompliteModal from 'components/ReportCompliteModal';
import isAllowed, { isClient, isExternal, ROLES_DIVISIONS } from '../../layout/roles';
import { notifyApiError, notifyInfo } from 'utils/notification';
import AddAssignForm from '../../containers/Calendar/src/src/containers/base/AddAssignForm/index.js';
import { STATUS_COMPLETED, STATUS_IN_ATTORNEY_REVIEW, STATUS_IN_QA_REVIEW } from 'constants/report_status';
import {
  CONTACT_TYPE_PLAINTIFF_ATTORNEY,
  CONTACT_TYPE_DEFENSE_ATTORNEY,
  CONTACT_TYPE_INSURANCE_CARRIER,
} from 'constants/contacts-types';
import { debounce } from 'throttle-debounce';

const { Option } = Select;
const { confirm } = Modal;

const initialClaimantState = {
  isAdmin: false,
  claimantInfo: {},
  reportStatuses: [],
  Status: 'Pending',
  name: '',
  type: '',
  loadingSearch: false,
};

class DropdownStatus extends Component {
  constructor(props) {
    super(props);

    let claimantInfo = {};
    if (this.props.location && this.props.location.state) {
      claimantInfo = this.props.location.state.data;
    }
    if (!claimantInfo.id) {
      claimantInfo = JSON.parse(localStorage.getItem('claimant'));
    }

    this.state = {
      report_status: null,
      modalOpen: false,
      ...initialClaimantState,
      claimantInfo,
      isClient: isClient(props.roles),
      isAdmin: isAllowed(props.roles, ROLES_DIVISIONS.MANAGEMENT),
      isNurseManager: isAllowed(props.roles, ROLES_DIVISIONS.SUPERMANGER),
      contacts: [],
      isExternal: isExternal(props.roles),
      showNewAssignForm: false,
      users: [],
      debounceGetUsers: debounce(1000, async ({ search }) => this.handleGetUsers({ search })),
    };

    this.updateReport = this.updateReport.bind(this);
    this.handleGetUsers = this.handleGetUsers.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.handleOpenModalTransaction = this.handleOpenModalTransaction.bind(this);
  }

  async componentDidMount() {
    this.setState({
      report_status: this.props.data.report_status.id,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location?.state?.data &&
      prevProps.location?.state?.data &&
      JSON.stringify(prevProps.location.state.data) !== JSON.stringify(this.props.location.state.data)
    ) {
      let claimantInfo = {};
      if (this.props.location && this.props.location.state) {
        claimantInfo = this.props.location.state.data;
      }
      this.setState({
        ...initialClaimantState,
        claimantInfo,
        isClient: isClient(this.props.roles),
        isAdmin: isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGEMENT),
        isNurseManager: isAllowed(this.props.roles, ROLES_DIVISIONS.SUPERMANGER),
        isExternal: isExternal(this.props.roles),
      });
    }
  }

  updateReport(value) {
    this.setState({ report_status: value });
    let params = { report_status_id: value, id: this.props.data.id };

    API.updateReport(params).then(() => {
      this.setState({ report_status: value });

      if (value === STATUS_IN_QA_REVIEW.id) {
        this.setState({ showNewAssignForm: true });
      }
      if (value === STATUS_IN_ATTORNEY_REVIEW.id) {
        this.setState({ showNewAssignForm: true });
      }
      if (value === STATUS_COMPLETED.id) {
        this.sendEmail();
      }
    });
  }

  // seart section search users
  async handleGetUsers({ search = '' } = {}) {
    this.setState({ loadingSearch: true });
    const users = await API.getUsersDataSynchronised({ searchQuery: search });
    this.setState({ modalOpen: true, users: users?.users || [], loadingSearch: false });
  }
  // end section search users

  sendEmail() {
    confirm({
      title: 'Would you like to alert the client that this report is ready?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: this.handleGetUsers,
      onCancel: () => this.handleOpenModalTransaction(),
    });
  }

  async onSubmit(assignment) {
    this.setState({
      loading: true,
      loadingText: 'pending...',
    });
    try {
      await API.createAssignementUser(assignment);
      this.setState({ showNewAssignForm: false });
      notifyInfo('Assignment created');
      return true;
    } catch (e) {
      notifyApiError(e);
      return false;
    } finally {
      this.setState({ loading: false });
    }
  }

  getContacts(tenant_id) {
    const params = {
      tenant_id,
      contact_type_id: [CONTACT_TYPE_PLAINTIFF_ATTORNEY, CONTACT_TYPE_DEFENSE_ATTORNEY, CONTACT_TYPE_INSURANCE_CARRIER],
    };
    API.getContactsByType(params).then(res => {
      this.setState({ contacts: res.result.rows });
    });
  }

  handleOpenModalTransaction() {
    if (isAllowed(this.props.roles, [...ROLES_DIVISIONS.MANAGEMENT, ...ROLES_DIVISIONS.NURSE_ONLY])) {
      this.props.openModalTransaction && this.props.openModalTransaction();
    }
  }

  render() {
    const { loadingSearch, report_status, users, modalOpen, isNurseManager, showNewAssignForm } = this.state;
    const { reportStatuses, data } = this.props;
    const tenant_id = data?.tenant_id ? data.tenant_id : '0';

    return (
      <Row>
        <Select
          bordered={false}
          disabled={isNurseManager ? false : true}
          showSearch
          placeholder="Please select"
          optionFilterProp="children"
          className="dropdown_status"
          value={report_status}
          onChange={value => this.updateReport(value)}
          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
          {reportStatuses.map(item => (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <ReportCompliteModal
          isOpen={modalOpen}
          title="Send to additional recipients if needed"
          onCancel={() => {
            this.setState({ modalOpen: false });
            this.handleOpenModalTransaction();
          }}
          tenant_id={tenant_id}
          contacts={users}
          report_id={this.props.data.id}
          onSearch={this.state.debounceGetUsers}
          loadingSearch={loadingSearch}
        />
        <Modal
          width={700}
          maskClosable={false}
          bodyStyle={{ padding: 0, minWidth: 450 }}
          visible={showNewAssignForm}
          onCancel={() => this.setState({ showNewAssignForm: false })}
          keyboard={false}
          footer={null}>
          <AddAssignForm onSubmit={this.onSubmit} reportId={this.props.data.id} />
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps)(DropdownStatus);
