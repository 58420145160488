import React from 'react';
import { useSelector } from 'react-redux';

// Components
import { Row, Col, Typography, Empty } from 'antd';
import SpinBox from 'components/SpinBox';
import { CVcomponent } from '../CVcomponent';

// Icons
import { StartIcon } from 'components/Svg';

// Permission
import { LITIGATION_ATTORNEY_NAME, getPermissionsBySection } from 'permissions';

const { Text } = Typography;

const StepMSAAttorney = ({ listLitigation, handleChangeFavorite }) => {
  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { LITIGATION_ATTORNEY_PERMISSION } = getPermissionsBySection({
    roles: roles,
    permissions: [LITIGATION_ATTORNEY_NAME],
  });
  // end permissions

  return (
    <>
      <Row type="flex" className="table-header">
        <Col xs={6}>Name of Attorney</Col>
        <Col xs={7}>Email</Col>
        <Col xs={4}>Phone</Col>
        <Col xs={1}>CV</Col>
        <Col xs={3}>Client Law Firm</Col>
        <Col xs={3} className="litigation_posRight">
          Featured
        </Col>
      </Row>

      <Row className="table-content">
        <SpinBox loading={false}>
          {listLitigation.length > 0 ? (
            <Row>
              {listLitigation.map(litigation => {
                return (
                  <Row key={litigation.id} className="record" gutter={[5, 40]}>
                    {/* Name of Attorney */}
                    <Col xs={6}>
                      <Text className="wrapped-text user-name">
                        {litigation?.given_name || ''} {litigation?.family_name || ''}
                      </Text>
                    </Col>

                    {/* Email */}
                    <Col xs={7}>
                      <Text className="wrapped-text">{litigation?.email || ''}</Text>
                    </Col>

                    {/* Phone */}
                    <Col xs={4}>
                      <Text>{litigation?.locale || ''}</Text>
                    </Col>

                    {/* CV */}
                    <Col xs={1} className={`litigation_pdf ${litigation?.CV ? 'litigation_pdf_pointer' : ''}`}>
                      <CVcomponent cvFile={litigation?.CV || ''} fileName={litigation?.nameCV || ''} />
                    </Col>

                    {/* Client Law Firm */}
                    <Col xs={3}>
                      <Text>Public</Text>
                    </Col>

                    {/* Actions */}
                    <Col xs={3} className="actions litigation_posRight litigation_start">
                      {LITIGATION_ATTORNEY_PERMISSION.setFavorite && (
                        <StartIcon
                          active={litigation.user_roles[0].range}
                          onClick={() =>
                            handleChangeFavorite({ userId: litigation.id, range: litigation?.user_roles[0]?.range })
                          }
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Row>
          ) : (
            <Empty description="No Data" image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
          )}
        </SpinBox>
      </Row>
    </>
  );
};

export default StepMSAAttorney;
