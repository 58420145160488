import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Col, Modal, Row, Typography, Icon, Upload } from 'antd';

import InputBox from 'components/InputBox';
import FileIcons from 'constants/resource-links-icons';
import { notifyApiError } from 'utils/notification';
import { UploadPresigned } from 'utils/upload';

import './ResourceLink.scss';
import './ResourceLibrary.scss';
import SpinBox from 'components/SpinBox';

const { Text } = Typography;

export default function Index({
  isOpen,
  onCancel,
  validateName,
  loading,
  ViewResource,
  handleUpdateResourceLinks,
  UpdateResourceLink,
  validateLink,
  validateDescription,
}) {
  const [fileLoading, setFileLoading] = useState(false);
  const [fileValidate, setFileValidate] = useState(''); 

  const beforeDocUpload = file => {
    // If there is no file, we doing nothing
    if (!file) return false;

    // Conditions for file types
    const isPdf = file.type === 'application/pdf';

    if (!isPdf) {
      setFileValidate('You can only upload .pdf files');
    }

    return isPdf;
  };

  const handleDocUpload = async info => {
    setFileLoading(true);

    const { file } = info;

    if (beforeDocUpload(file)) {
      try {
        // getting presigned file
        const presignedFile = await UploadPresigned(file);

        setFileValidate('');
        handleUpdateResourceLinks('file', presignedFile);
        setFileLoading(false);
      } catch (e) {
        notifyApiError(e);
      }
    } else {
      setFileLoading(false);
    }
  };

  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={'Update Resource Links'}>
        <SpinBox loading={loading}>
          <Row type="flex" align="middle" className="report-body">
            <Row type="flex" justify="space-between" align="middle" className="main-info">
              <Col md={12}>
                <Row style={{ paddingBottom: 10 }}>
                  <Text>Name</Text>
                </Row>
                <InputBox
                  name="name"
                  value={ViewResource.name}
                  placeholder="Required"
                  onChange={value => {
                    handleUpdateResourceLinks('name', value);
                  }}
                />
              </Col>
              <Col md={12} style={{ paddingLeft: 8 }}>
                <Row style={{ paddingBottom: 10 }}>
                  <Text>Link</Text>
                </Row>
                <Row>
                  <InputBox
                    name="link"
                    placeholder="Optional"
                    value={ViewResource.link}
                    onChange={value => {
                      handleUpdateResourceLinks('link', value);
                    }}
                  />
                </Row>
              </Col>
              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col>
                  {validateName && <Alert type="error" message={validateName} banner />}
                  {validateLink && <Alert type="error" message={validateLink} banner />}
                </Col>
                <Col md={24} style={{ marginBottom: 10 }}>
                  {ViewResource.file ? (
                    <>
                      <Row style={{ paddingBottom: 10 }}>
                        <Text>Document</Text>
                      </Row>
                      <Row type="flex" justify="space-between">
                        <Text ellipsis title={ViewResource.file.id ? ViewResource.file.name : 'Update the resource to get a link to the file'} style={{ width: '90%' }}>
                          <Icon type={FileIcons[ViewResource.file.type]} />
                          &nbsp;
                          {
                            (
                              ViewResource.file.type === 'application/pdf'
                              || ViewResource.file.type === 'text/plain'
                            )
                            && ViewResource.file.id
                              ? <a href={ViewResource.file.url} target="_blank">{ViewResource.file.name}</a>
                              : ViewResource.file.name
                          }
                        </Text>
                        <Button
                          ghost
                          type="danger"
                          icon="delete"
                          size="small"
                          onClick={async () => {
                            handleUpdateResourceLinks('file', null);
                            handleUpdateResourceLinks('file_id', null);
                          }}
                        />
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Text>Document</Text>
                      </Row>
                      <Row>
                        <Upload
                          name="document"
                          accept="application/pdf"
                          listType="text"
                          showUploadList={false}
                          className="document-upload"
                          style={{ height: 46 }}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          onChange={handleDocUpload}
                          disabled={fileLoading}>
                          {fileLoading ? <Icon type="loading" /> : 'Upload'}
                        </Upload>
                      </Row>
                    </>
                  )}
                  {fileValidate && <Alert type="error" message={fileValidate} banner style={{ marginTop: 10 }} />}
                </Col>
                <Col md={24}>
                  <Row style={{ paddingBottom: 10 }}>
                    <Text>Short Description</Text>
                  </Row>
                  <InputBox
                    name="description"
                    value={ViewResource.description}
                    onChange={value => handleUpdateResourceLinks('description', value)}
                  />
                  {validateDescription && (
                    <Alert type="error" message={validateDescription} banner style={{ marginTop: 10 }} />
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Checkbox
                  checked={!!ViewResource.default_link}
                  onChange={e => handleUpdateResourceLinks('default_link', e.target.checked)}>
                  Included in report by default
                </Checkbox>
              </Row>
            </Row>

            <Button
              type="primary"
              size="large"
              className="green-btn button"
              disabled={fileLoading}
              loading={loading}
              onClick={UpdateResourceLink}>
              Update
            </Button>
          </Row>
        </SpinBox>
      </Modal>
    </div>
  );
}
