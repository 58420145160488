import React, { useState, useEffect } from 'react';

import { Select, Icon, Divider, Button, Typography } from 'antd';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Icons
import { TrashIcon } from 'components/Svg';

// Const
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { CLIENT_SUPER_ADMIN_ID } from 'layout/roles';

const { Title } = Typography;
const { Option } = Select;

const StepAddAdmin = ({ goBackStep, onClick, dataGlobal }) => {
  // State
  const [listAdminAssign, setListAdminAssign] = useState([]);
  const [listClientSuperAdmin, setListClientSuperAdmin] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addAdditionalUser, setAddAdditionalUser] = useState(false);

  // Const
  const selectClientSuperAdmin = listAdminAssign.length === 0 || addAdditionalUser;

  const getAllClientSuperAdmin = async () => {
    try {
      setLoading(true);
      const res = await API.getUsersByRoleId({ roleId: CLIENT_SUPER_ADMIN_ID });
      setListClientSuperAdmin(res.users);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setListAdminAssign(dataGlobal?.listAdminAssign || []);
  }, [dataGlobal]);

  useEffect(() => {
    listClientSuperAdmin.length === 0 ? getAllClientSuperAdmin() : setLoading(false);
  }, []);

  // handle
  const selectedClientSuperAdmin = ({ clientSuperAdminId }) => {
    const getClientInfo = listClientSuperAdmin.find(client => client.id === clientSuperAdminId);

    if (addAdditionalUser) setAddAdditionalUser(false);

    if (listAdminAssign.some(clientSuperAdmin => clientSuperAdmin.id === getClientInfo.id)) return;

    setListAdminAssign(prevState => [...prevState, getClientInfo]);
  };

  const handleRemoveSubFolder = ({ clientSuperAdminId }) => {
    const updateList = listAdminAssign.filter(clientSuperAdmin => clientSuperAdmin.id !== clientSuperAdminId);
    setListAdminAssign(updateList);
  };

  const handleSubmit = ({ action }) => {
    const data = {
      listAdminAssign,
    };

    if (action === 'back') return goBackStep({ data });

    onClick({ data });
  };

  return (
    <>
      <div className="createParentFolder_addFolder">
        {selectClientSuperAdmin ? (
          <>
            <Title level={4}>Search Admin</Title>
            <Select
              getPopupContainer={triggerNode => triggerNode.parentNode}
              showSearch
              optionFilterProp="children"
              className="dropdown addClaimant_select"
              value=""
              onChange={id => selectedClientSuperAdmin({ clientSuperAdminId: id })}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option disabled value={''}>
                Type user name
              </Option>
              {listClientSuperAdmin.map(user => (
                <Option value={user.id} key={user.id}>
                  {user.given_name} {user.family_name}
                </Option>
              ))}
            </Select>
          </>
        ) : (
          <div className="listCard_createParentFolder">
            {listAdminAssign.map(item => (
              <div key={item.id} className="stepContainerContact">
                <div className="stepContactItem">
                  <h2>
                    {item.given_name || ''} {item?.family_name || ''}
                  </h2>
                </div>
                <div className="stepContactItemButtons">
                  <TrashIcon
                    color={PRIMARY_LIGHT_BLUE}
                    onClick={() => handleRemoveSubFolder({ clientSuperAdminId: item.id })}
                  />
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">User Type</p>
                  <p className="stepContactItemValue">Client Super Admin</p>
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">Email</p>
                  <p className="stepContactItemValue">{item?.email}</p>
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">Phone</p>
                  <p className="stepContactItemValue">{item?.locale || ''}</p>
                </div>
              </div>
            ))}

            <button className="addAdditionalContactButton margin-button" onClick={() => setAddAdditionalUser(true)}>
              Assign New Admin
            </button>
          </div>
        )}
      </div>

      <Divider />

      <div className="createParentFolder_btn createParentFolder_btn_between">
        <Button
          type="primary"
          size="large"
          className="transparent-btn button"
          onClick={() => handleSubmit({ action: 'back' })}>
          Previous Step
        </Button>
        <Button type="primary" size="large" className="green-btn button" onClick={handleSubmit}>
          Create Folder
        </Button>
      </div>

      {loading && <ModalLoading />}
    </>
  );
};

export default StepAddAdmin;
