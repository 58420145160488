import React, { Component } from 'react';
import { Row, Col, Typography, Empty } from 'antd';
import { connect } from 'react-redux';
import { formatCurrency } from 'utils/utils';
import getInflation from '../../utils/inflation';
import './MedicareCostTable.scss';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SeeMore from 'components/SeeMore/index';
import MedicareCostMenu from '../MedicareCostMenu';

const { Text } = Typography;
class MedicareCostTable extends Component {
  render() {
    const { data, handleEdit, handleDelete, handleSeed, handleClone, handleChangePosition, canEdit } =
      this.props;
    return (
      <Row className="msa-medicare-cost-table-container">
        <Row type="flex" align="middle" className="table-header">
          <Col xs={3} className="border-right center">
            {this.props.isMcp ? 'Future Medical Need' : 'Future Medical Need Of The Type Normally Covered By Medicare'}
          </Col>
          <Col xs={2} className="border-right center">
            Specialty
          </Col>
          <Col xs={2} className="border-right center">
            CPT/HCPCS Code
          </Col>
          <Col xs={3} className="border-right">
            <Row className="sub-group-header">
              <Col xs={24}>Frequency And Duration Of Need</Col>
            </Row>
            <Row type="flex" align="middle" className="sub-header border-top">
              <Col xs={8} className="border-right center">
                Units Needed
              </Col>
              <Col xs={8} className="border-right center">
                Every
              </Col>
              <Col xs={8} className="center">
                'X' Years
              </Col>
            </Row>
          </Col>
          <Col xs={2} className="border-right center">
            Per Unit cost
          </Col>
          <Col xs={2} className="border-right center">
            Annual Cost
          </Col>
          <Col xs={1} className="border-right center">
            Total Years
          </Col>
          <Col xs={2} className="border-right center">
            Total Cost
          </Col>
          {this.props.report.has_inflation && (
            <Col xs={2} className="border-right center">
              Inflation Total
            </Col>
          )}
          <Col xs={this.props.report.has_inflation ? 3 : 5} className="border-right center">
            Comments
          </Col>
          {!this.props.isMcp && (
            <Col xs={2} className="border-right center">
              Used in seed money calculation
            </Col>
          )}
          <Col xs={!this.props.isMcp ? 1 : 3} className="action"></Col>
        </Row>
        <Row className="table-content">
          {data.length > 0 ? (
            <Row>
              {data
                .sort((a, b) => {
                  return a.position - b.position;
                })
                .map((item, index) => {
                  return (
                    <Row key={`${index}-${item.id}`} className="record" id={'table-item-' + item.id + item.position}>
                      <Col xs={3} className="text-left">
                        <Text>{item.name}</Text>
                      </Col>
                      <Col xs={2}>
                        <Text>{item.speciality && item.speciality.name}</Text>
                      </Col>
                      <Col xs={2}>
                        <Text>{item.cpt_code && item.cpt_code.code}</Text>
                      </Col>
                      <Col xs={3}>
                        <Row type="flex" align="middle">
                          <Col xs={8}>
                            <Text>{item.units_needed}</Text>
                          </Col>
                          <Col xs={8}>
                            <Text>{'Every'}</Text>
                          </Col>
                          <Col xs={8}>
                            <Text>{item.every_x_year}</Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2}>
                        <Text>{formatCurrency(item.per_unit_cost)}</Text>
                      </Col>
                      <Col xs={2}>
                        <Text>{formatCurrency(item.annual_cost)}</Text>
                      </Col>
                      <Col xs={1}>
                        <Text>{item.total_years}</Text>
                      </Col>
                      <Col xs={2}>
                        <Text>{formatCurrency(item.total_cost)}</Text>
                      </Col>
                      {this.props.report.has_inflation && (
                        <Col xs={2}>
                          <Text>
                            {formatCurrency(
                              getInflation(
                                {
                                  interest_rate: this.props.report.interest_rate,
                                },
                                item.total_cost,
                                item.total_years
                              )
                            )}
                          </Text>
                        </Col>
                      )}

                      <Col xs={this.props.report.has_inflation ? 3 : 4} className="text-left">
                        <Text>
                          <SeeMore text={item.comments} />
                        </Text>
                      </Col>
                      <Col xs={this.props.report.has_inflation ? 0 : 1}></Col>
                      {!this.props.isMcp && canEdit && (
                        <Col xs={1}>
                          <Text>
                            {!item.is_seed_calculated ? (
                              <CloseCircleOutlined
                                onClick={() => {
                                  handleSeed(item.id, item.is_seed_calculated);
                                }}
                                style={{
                                  color: 'gray',
                                  fontSize: 17,
                                  fontWeight: 'bold',
                                }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                onClick={() => {
                                  handleSeed(item.id, item.is_seed_calculated);
                                }}
                                style={{
                                  color: 'green',
                                  fontSize: 17,
                                  fontWeight: 'bold',
                                }}
                              />
                            )}
                          </Text>
                        </Col>
                      )}

                      <Col xs={1}>
                        {canEdit && (
                          <MedicareCostMenu
                            handleClone={handleClone}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            handleChangePosition={handleChangePosition}
                            item={item}
                          />
                        )}
                      </Col>
                    </Row>
                  );
                })}
            </Row>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
          )}
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.auth.settings,
    report: state.report.reportInfo,
  };
};

export default connect(mapStateToProps, {})(MedicareCostTable);
