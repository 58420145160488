export const TYPE_INLINECONTACT_DEFE = [
    "defense_attorney",
];

export const TYPE_INLINECONTACT_EMPLO = [
    "employer_demographics"
];

export const TYPE_INLINECONTACT_PLAINTIFF = [
    "plaintiff_attorney"
];

export const TYPE_SELECTMULTI = [
    "related_diagnosis"
];

export const TYPE_INLINECONTACT_TREATING = [
    "treating_providers"
];

export const TYPE_SELECT = [
    "juridiction_state_id","life_table_id"
];

export const TYPE_CLAIM = [
    "claim_number"
];

export const TYPE_TAGSINPUTBOX = [
    "additional_comments","body_parts","missing_medical_records","pre_existing_conditions","insurance_company", "introduction","medical_records_reviewed","description_of_injury",
    "medical_summary","medical_status","surgeries_procedures","projected_treatment_plan",
    "prognosis","current_treatment_plan","social_security_disability_status","medicare_status",
    "medicare_lien_status","insurance_driver","summary"
];

export const TYPE_INPUTCURRENCYBOX = [
    "allocation_future_amount","settlement_amount","proposed_amount_for_future_from_medicare",
    "proposed_amount_for_future_not_covered_from_medicare","cost_of_first_surgery_and_first_procedure_replacement",
    "total_allocation_minus_first_surgery_procedure_replacement","seed_money_or_initial_deposit",
    "annuity_payment_over_remaining_expectancy_at_starting_date", "total_attorney_fees", "disbursements","policy_limits"
];

export const TYPE_DATEPICKERSIMPLE = [
    "date_of_injury","referral_date","date_of_report","annuity_start_date","proposed_settlement_date", "dob"
];

export const TYPE_INPUT = [
    "icd_code","icd_descr", "insurance_carrier","submitter_attorney_name", "submitter_attorney_firm_name", "submitter_attorney_email"
];

export const TYPE_INPUTBOX = [
     "createdBy","medicare_allocation_administration","life_expectancy","age_at_proposed_settlement_date"
];

export const TYPE_PRESCRIPTIONTABLE = [
     "Prescription Medications Covered"
];

export const TYPE_TEXTAREA = [
    "createdBy","rated_ages_text","compensation","checkboxes_other","signature"
];

export const TYPE_CHECKBOX = [
    "applied_SSDB","applied_SSDB","denied_SSDB","process_SSDB","years_months_days",
    "ESRD","medicare_partAB", "is_signature_photo"
];

export const TYPE_SELECTRELEASES = [
    "releases_attached"
];

export const TYPE_SELECTMETHOD = [
    "msa_calculation_method"
];

export const TYPE_SELECTFUTURE = [
    "future_prescription_drug_treatment_calculation_method"
];

export const TYPE_SELECTSETTETYPEA = [
    "settlement_type"
];

