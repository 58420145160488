import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Divider, Icon, Radio, Row, Select, Typography } from 'antd';
import ContactModal from 'components/ContactModal';
import ContactsOnReport from 'components/ContactsOnReport';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MSA_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

import './InjuryInfo.scss';

const { Title, Text } = Typography;
const { Option } = Select;

class InjuryInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      contactsList: [],
      releases_attached: true,
      msa_administrator: null,
      isModalOpen: false,
      loadingTitle: 'Loading',
      loading: false,
      report_contacts: [],
      defenseList: [],
      plaintifList: [],
      insuranceList: [],
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getContactsData({ pageSize: 1000 }) //Getting all contacts data
      .then(res => {
        this.setState({
          contactsList: res.contacts,
        });
      });
    API.getReportById(report_id)
      .then(async reportInfo => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        this.setState({
          is_template: reportInfo.is_template,
          releases_attached: reportInfo.releases_attached !== null ? reportInfo.releases_attached : true,
          msa_administrator: reportInfo.msa_administrator ? reportInfo.msa_administrator : null,
          loading: false,
        });
        this.props.updateReportInfo(reportInfo);
      })
      .catch(err => forbiddenRedirect(err, this.props));
    API.getReportContacts(report_id).then(res => {
      const list = res.report_contacts.rows;

      const plaintifList = list.filter(el => el.type === 'plaintiff_attorney');
      const defenseList = list.filter(el => el.type === 'defense_attorney');
      const insuranceList = list.filter(el => el.type === 'insurance_carrier');
      this.setState({
        report_contacts: list,
        defenseList,
        plaintifList,
        insuranceList,
      });
    });
  };

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        releases_attached: reportInfo.releases_attached !== null ? reportInfo.releases_attached : true,
        msa_administrator: reportInfo.msa_administrator ? reportInfo.msa_administrator : null,
        loading: false,
      });
    }
  }

  handleUpdateReport = async (isNextStep = false) => {
    const { report_id, releases_attached, msa_administrator } = this.state;

    const params = {
      id: report_id,
      releases_attached,
      msa_administrator,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleConfirmModal = () => {
    this.setState({ isModalOpen: false });
    API.getContactsData({ pageSize: 1000 }) //Getting all contacts data
      .then(res => {
        this.setState({
          contactsList: res.contacts,
        });
      });
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      contactsList,
      releases_attached,
      msa_administrator,
      isModalOpen,
      loadingTitle,
      loading,
      defenseList,
      plaintifList,
      insuranceList,
      REPORT_PERMISSIONS,
    } = this.state;
    const { reportInfo } = this.props;

    return (
      <Row className="msa-injury-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown beforeNext={async () => await this.handleUpdateReport(false)} loading={loading} />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Injury Information</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Description of Injury</Text>
                </Col>
                <div
                  dangerouslySetInnerHTML={{
                    __html: reportInfo.description_of_injury,
                  }}></div>
              </Row>
              {/* <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Date of Injury</Text>
                </Col>
                <Text className="text">{reportInfo.date_of_injury}</Text>
              </Row> */}
              <Row type="flex" className="row">
                <Col md="24">
                  <Text className="label">Diagnostic codes and description</Text>
                  <div style={{ height: 5 }} />
                  {reportInfo.related_diagnosis &&
                    reportInfo.related_diagnosis.split(',').map(item => {
                      if (item)
                        if (item) {
                          let item2;
                          try {
                            item2 = JSON.parse(item.replace(/</g, ','));
                          } catch (error) {
                            return '';
                          }
                          return (
                            <>
                              <Text className="text">{`${item2.med_descr.replace('$', '')} (${item2.code})`}</Text>
                              <br />
                            </>
                          );
                        }
                      return '';
                    })}
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Releases attached</Text>
                </Col>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    disabled={!REPORT_PERMISSIONS.edit}
                    className="dropdown"
                    value={releases_attached ? 'yes' : 'no'}
                    onChange={value =>
                      this.setState({
                        releases_attached: value === 'yes' ? true : false,
                      })
                    }
                    suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                    <Option value={'yes'} key={'1'}>
                      Yes
                    </Option>
                    <Option value={'no'} key={'2'}>
                      No
                    </Option>
                  </Select>
                </Row>
              </Row>
              <Row type="flex" className="row" style={{ marginBottom: 10 }}>
                <Row></Row>
                <Col className="label">
                  <Text className="label">MSA administrator</Text>
                </Col>
                <Col className="input-col">
                  <Row className="radio-item">
                    <Radio.Group
                      disabled={!REPORT_PERMISSIONS.edit}
                      onChange={e =>
                        this.setState({
                          msa_administrator: e.target.value === 'Claimant' ? null : e.target.value,
                        })
                      }
                      value={msa_administrator ? 'Other' : 'Claimant'}>
                      <Radio value={'Claimant'}>Claimant</Radio>
                      <Radio value={'Other'}>Other</Radio>
                    </Radio.Group>
                  </Row>
                </Col>
              </Row>
              {msa_administrator && (
                <Row align="middle" type="flex">
                  <Col className="label"></Col>
                  <Col md={8}>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={msa_administrator}
                      onChange={value => this.setState({ msa_administrator: value })}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {contactsList.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.contact_name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      className="green-btn add-administrator_button"
                      onClick={this.handleOpenModal}>
                      Add Administrator
                    </Button>
                  </Col>
                </Row>
              )}
              <Text className="label">Claimant Attorney</Text>

              <Row>
                <br />
                <ContactsOnReport contactList={plaintifList} />
              </Row>
              <Text className="label">Defense Attorney</Text>

              <Row>
                <br />
                <ContactsOnReport contactList={defenseList} />
              </Row>
              <Text className="label">Insurance Carrier</Text>

              <Row>
                <br />
                <ContactsOnReport contactList={insuranceList} />
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">State of Jurisdiction</Text>
                </Col>
                <Text className="text">{reportInfo.juridiction_state && reportInfo.juridiction_state.name}</Text>
              </Row>
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
        <ContactModal
          title={'Add Administrator'}
          isOpen={isModalOpen}
          contactType={1}
          onConfirm={this.handleConfirmModal}
          onCancel={this.handleCloseModal}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(InjuryInfo);
