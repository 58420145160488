import React, { useState } from 'react';
import { Row, Col, Input, Spin, Typography } from 'antd';
import { changePassword } from 'redux/auth';
import './CompanyProfile.scss';
import { notifyApiError, notifySuccess } from '../../utils/notification';
import { validatePassword } from 'utils/utils';

export default function ChangePassword() {
  const { Text } = Typography;

  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const StyledInput = {
    fontSize: '14px',
  };

  const clearForm = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validatePassword({ password: newPassword }))
      return notifyApiError(
        'Password should be 8 or more characters and should contain uppercase and lowercase letters, numbers and symbol characters'
      );

    if (newPassword !== confirmPassword) return notifyApiError('Confirm Password is not equal to New Password');

    try {
      setIsLoading(true);
      const res = await changePassword({ oldPassword, newPassword });

      if (res.error) return notifyApiError(res.message);

      notifySuccess(res.message);
      clearForm();
    } finally {
      setIsLoading(false);
    }
  };

  const validForm = !oldPassword || !newPassword || !confirmPassword;

  return (
    <form onSubmit={handleSubmit}>
      <Spin tip="Loading..." spinning={isLoading}>
        <Row className="company_container" type="flex" justify="space-between">
          <Row type="flex" justify="start" align="middle">
            <Col md={24}>
              <h1 className="company__title">Reset Password</h1>
            </Col>
            <Col md={24}>
              <p>For more security the password should contain numbers and uppercase letters</p>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Col md={7}>
              <Row className="company__inputs">
                <Text>Old Password</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                type="password"
                name="displayName"
                value={oldPassword}
                style={StyledInput}
                onChange={e => setOldPassword(e.target.value)}
              />
            </Col>
            <Col md={7}>
              <Row className="company__inputs">
                <Text>New Password</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                type="password"
                name="displayName"
                value={newPassword}
                style={StyledInput}
                onChange={e => setNewPassword(e.target.value)}
              />
            </Col>
            <Col md={7}>
              <Row className="company__inputs">
                <Text>Confirm New Password</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                type="password"
                name="company_name"
                value={confirmPassword}
                style={StyledInput}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col md={6}>
              <button disabled={validForm} className="company_form_btn" type="submit">
                Change Password
              </button>
            </Col>
          </Row>
        </Row>
      </Spin>
    </form>
  );
}
