import React from 'react';
import { Modal, Button } from 'antd';
import DiffMatchPatch from 'diff-match-patch';

import './modalCompare.scss';

const ModalCompare = ({ onClose, subTitle, textOriginal, textRevision }) => {
  const dmp = new DiffMatchPatch();
  const diff = dmp.diff_main(textOriginal, textRevision);
  dmp.diff_cleanupSemantic(diff)


  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={null}
      centered={true}
      closable={true}
      width={500}
      className="modalCompare"
      title={
        <div className="modalCompare_title">
          <h2>Comparison</h2>
          <p>{subTitle || 'subTitle'}</p>
        </div>
      }>
      <div className="modalCompare_content">
        <div
          className='modalCompare_compare'
          dangerouslySetInnerHTML={{
            __html: `${diff
              .map(item => {
                if (item[0] === 1) {
                  return `<span class='modalCompare_itemAdd'>${item[1]}</span>`;
                }

                if (item[0] === -1) {
                  return `<span class='modalCompare_itemDelete'>${item[1]}</span>`;
                }

                return item[1];
              })
              .join('')}`,
          }}
        />
        <div className="modalCompare_save">
          <Button type="primary" size="large" onClick={onClose} className="btn-normal green-btn modalCompare_save">
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCompare;
