import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import useWebSocket from 'react-use-websocket';
import CustomLayout from './CustomLayout';
import { onFeedbackMessageReceived } from '../redux/help';
import { getNotifications, getUnreadCount, onNotificationMessageReceived } from '../redux/notification';

const WebsocketWrapper = props => {
  const dispatch = useDispatch();

  const getSocketUrl = useCallback(async () => {
    return new Promise(resolve => {
      Auth.currentSession()
        .then(session => {
          resolve(`${process.env.REACT_APP_API_CHAT_URL}?token=${session.getAccessToken().getJwtToken()}`);
        })
        .catch(err => {
          console.error(err);
        });
      // const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    });
  }, []);

  useWebSocket(getSocketUrl, {
    share: true,
    reconnectInterval: 10000,
    reconnectAttempts: 10,
    onOpen: () => {
      console.info('APP socket connection opened');
      // dispatch(connectedAction);
    },
    onClosed: () => {
      console.info('socket connection CLOSED');
      // dispatch(disconnectedAction);
    },
    onError: e => console.error(e),
    onMessage: event => {
      console.info(`Websocket message`, JSON.parse(event.data));
      const dataJson = event.data || '{}';
      const data = JSON.parse(dataJson);
      const type = data?.type;
      const body = data?.body;
      if (!type) return;
      if (type.includes('FEEDBACK_')) {
        dispatch(onFeedbackMessageReceived({ type, body }));
      }
      if (type.includes('NOTIFICATION')) {
        dispatch(onNotificationMessageReceived({ type, body }));
      }
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: closeEvent => true,
  });

  useEffect(() => {
    getUnreadCount()(dispatch);
    // getNotifications()(dispatch);
  }, [dispatch]);

  return <CustomLayout {...props} />;
};

export default WebsocketWrapper;
