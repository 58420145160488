import * as React from 'react';

// Colors
import { PRIMARY_LIGHT_BLUE, SECONDARY_GREY } from 'containers/Calendar/src/src/lib/utils/colors';

const PdfIcon = ({ onClick, active }) => (
  <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <path
      fill={active ? PRIMARY_LIGHT_BLUE : SECONDARY_GREY}
      d="M200 164v8h12a12 12 0 0 1 0 24h-12v12a12 12 0 0 1-24 0v-56a12 12 0 0 1 12-12h32a12 12 0 0 1 0 24Zm-108 8a32 32 0 0 1-32 32h-4v4a12 12 0 0 1-24 0v-56a12 12 0 0 1 12-12h16a32 32 0 0 1 32 32Zm-24 0a8 8 0 0 0-8-8h-4v16h4a8 8 0 0 0 8-8Zm100 8a40 40 0 0 1-40 40h-16a12 12 0 0 1-12-12v-56a12 12 0 0 1 12-12h16a40 40 0 0 1 40 40Zm-24 0a16 16 0 0 0-16-16h-4v32h4a16 16 0 0 0 16-16ZM36 108V40a20 20 0 0 1 20-20h96a12 12 0 0 1 8.49 3.52l56 56A12 12 0 0 1 220 88v20a12 12 0 0 1-24 0v-4h-48a12 12 0 0 1-12-12V44H60v64a12 12 0 0 1-24 0Zm124-51v23h23Z"
    />
  </svg>
);

export default PdfIcon;
