import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Input, Empty, Button, Select, Icon } from 'antd';
import { connect } from 'react-redux';

import { EditIcon, RemoveIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import Pagination from 'components/Pagination';
import * as API from 'utils/api';
import { notifyApiError, notifyInfo } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { getPermissionsBySection, USERS_PERMISSIONS_NAME } from 'permissions';
import { DeleteUserModal } from 'components/Modal/DeleteUserModal';
import { USER_EDIT_NAV } from 'layout/navigate_const';

import './MyUsers.scss';

const { Search } = Input;
const { Title, Text } = Typography;
const { Option } = Select;
class MyUsers extends Component {
  constructor(props) {
    super();

    // Permissions
    const { USERS_PERMISSIONS } = getPermissionsBySection({
      roles: props.roles,
      permissions: [USERS_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      pageIndex: 0,
      showModal: false,
      usersData: [],
      totalCount: 0,
      selectedUser: {},
      searchQuery: '',
      querySearched: false,
      loading: true,
      loadingTitle: 'Loading...',
      USERS_PERMISSIONS,
      userType_list: [],
      client_list: [],
      userTypeFilter: [],
      lawFirmFilter: [],
    };

    this.handlePagination = this.handlePagination.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.countCols = this.countCols.bind(this);
  }

  async componentDidMount() {
    try {
      this.handleSearch({ activeLoading: false });

      const getRoles = await API.getNurseRoles(false);
      const clientData = await API.getClientsData('', 0, 10000);
      this.setState({ userType_list: getRoles.roles, client_list: clientData.clients });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handlePagination(pageIndex) {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
    });
  }

  handleOpenModal(item) {
    this.setState({
      showModal: true,
      selectedUser: item,
    });
  }

  handleEditUser(item) {
    this.props.history.push(USER_EDIT_NAV.path, { data: item });
  }

  async handleDeleteUser(item) {
    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: 'Deleting...',
    });

    try {
      await API.deleteUserSynchronised(item.id);
      await API.deleteUser(item.email);

      notifyInfo('User was deleted');

      this.setState({
        usersData: this.state.usersData.filter(user => user.id !== item.id),
        totalCount: this.state.totalCount - 1,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleSearch({
    activeLoading = true,
    userTypeFilter = this.state.userTypeFilter,
    lawFirmFilter = this.state.lawFirmFilter,
  } = {}) {
    if (activeLoading) this.setState({ loading: true, loadingTitle: 'Loading...' });

    let { searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: 'Loading' });

    searchQuery = encodeURIComponent(searchQuery.trim());
    let index = pageIndex;
    const apiCall = tenantOnly =>
      API.getUsersDataSynchronised({
        searchQuery,
        pageIndex: index,
        tenantOnly,
        filterType: userTypeFilter.join(','),
        filterLawFirm: lawFirmFilter.join(','),
      });

    const isAdminUser = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isManagerUser = isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER);

    try {
      const res = isManagerUser && !isAdminUser ? await apiCall(true) : await apiCall();
      this.setState({
        usersData: res.users,
        totalCount: res.total_count,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  countCols(width) {
    if (!this.state.USERS_PERMISSIONS.edit) {
      return width + 2;
    }

    return width;
  }

  handleFilter({ field, value }) {
    const { userTypeFilter, lawFirmFilter } = this.state;
    const search = {
      userTypeFilter,
      lawFirmFilter,
      [field]: value,
    };

    this.setState({ [field]: value });
    this.handleSearch({ userTypeFilter: search.userTypeFilter, lawFirmFilter: search.lawFirmFilter });
  }

  render() {
    const {
      pageIndex,
      totalCount,
      usersData,
      showModal,
      selectedUser,
      loading,
      loadingTitle,
      USERS_PERMISSIONS,
      userType_list,
      client_list,
    } = this.state;
    const { userInfo } = this.props;

    return (
      <Row className="users-container">
        <Row type="flex" align="middle" className="header header_filter">
          <Col className="title">
            <Title level={4} className="title-text">
              My Users
            </Title>
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              className="search-box"
              onSearch={async value => {
                await this.setState({ searchQuery: value, pageIndex: 0 });
                this.handleSearch();
              }}
            />

            <div className="search_filter">
              <Select
                showSearch
                placeholder="User Type"
                optionFilterProp="children"
                className="dropdown"
                mode="multiple"
                onChange={value => this.handleFilter({ field: 'userTypeFilter', value })}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                {userType_list.map(role => {
                  return (
                    <Option key={role.id} value={role.id}>
                      {role.display_name}
                    </Option>
                  );
                })}
              </Select>

              <Select
                showSearch
                placeholder="Law Firm"
                optionFilterProp="children"
                className="dropdown"
                mode="multiple"
                onChange={value => this.handleFilter({ field: 'lawFirmFilter', value })}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                {client_list.map(client => {
                  return (
                    <Option key={client.id} value={client.id}>
                      {client.client_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Col>
          <Col className="pagination">
            <Pagination onChange={this.handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
          </Col>
          <Col className="add-button">
            {USERS_PERMISSIONS.create && (
              <Button type="primary" className="green-btn" onClick={() => this.props.history.push('/app/users/add')}>
                Add New User
              </Button>
            )}
          </Col>
        </Row>
        <Row type="flex" className="table-header" gutter={[16, 16]}>
          <Col xs={this.countCols(9)}>User</Col>
          <Col xs={this.countCols(3)}>User Type</Col>
          <Col xs={this.countCols(5)}>Law Firm</Col>
          <Col xs={this.countCols(5)}>Date Added</Col>
          {USERS_PERMISSIONS.edit && (
            <Col xs={2} style={{ textAlign: 'right' }}>
              Actions
            </Col>
          )}
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {usersData.length > 0 ? (
              <Row>
                {usersData.map((item, index) => {
                  const isUserCurrentUser = item.id === userInfo.username;

                  return (
                    <Row key={index} className="record" gutter={[16, 16]}>
                      <Col xs={this.countCols(9)}>
                        <Text className="wrapped-text user-name">
                          <span className="user-name-name">{item.given_name + ' ' + item.family_name}</span>
                          <span>{item.email}</span>
                        </Text>
                      </Col>
                      <Col xs={this.countCols(3)}>
                        <Text>{item.roles[0]?.display_name}</Text>
                      </Col>
                      <Col xs={this.countCols(5)}>
                        <Text className="wrapped-text">{item?.clients[0]?.client_name || ''}</Text>
                      </Col>
                      <Col xs={this.countCols(5)}>
                        <Text>{moment(item.createdAt).format('MMMM DD, YYYY')}</Text>
                      </Col>
                      {USERS_PERMISSIONS.edit && (
                        <Col xs={2} className="actions">
                          <EditIcon className="icon" onClick={() => this.handleEditUser(item)} />

                          {USERS_PERMISSIONS.delete && (
                            <RemoveIcon
                              data-id-test={item.email}
                              className={`icon ${isUserCurrentUser ? 'disabled' : ''}`}
                              onClick={() => (!isUserCurrentUser ? this.handleOpenModal(item) : null)}
                            />
                          )}
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </Row>
            ) : (
              <Empty
                description={loading ? false : 'No Data'}
                image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>

        <DeleteUserModal
          open={showModal}
          onClose={this.handleCloseModal}
          onDelete={() => this.handleDeleteUser(selectedUser)}
          data={selectedUser}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, null)(MyUsers);
