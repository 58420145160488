import * as React from "react"
import { BLUE_ICON } from "containers/Calendar/src/src/lib/utils/colors"

const ElipsisIcon = ({ color = BLUE_ICON }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path fill={color} d="M11.61 10a2.22 2.22 0 0 1 2.24-2.25A2.32 2.32 0 0 1 16.12 10a2.29 2.29 0 0 1-2.26 2.26A2.18 2.18 0 0 1 11.61 10ZM13.87.05a2.28 2.28 0 0 1 2.25 2.27 2.32 2.32 0 0 1-2.33 2.25 2.23 2.23 0 0 1-2.18-2.41A2.2 2.2 0 0 1 13.87.05ZM11.61 17.7a2.19 2.19 0 0 1 2.22-2.25 2.25 2.25 0 1 1 0 4.5 2.19 2.19 0 0 1-2.22-2.25ZM8.38 2.33a2.23 2.23 0 0 1-2.25 2.24A2.3 2.3 0 0 1 3.88 2.3 2.26 2.26 0 0 1 6.19.05a2.19 2.19 0 0 1 2.19 2.28ZM6.16 15.45A2.25 2.25 0 0 1 6.1 20a2.28 2.28 0 0 1-2.22-2.29 2.23 2.23 0 0 1 2.28-2.26ZM8.38 10a2.19 2.19 0 0 1-2.29 2.24A2.28 2.28 0 0 1 3.88 10a2.31 2.31 0 0 1 2.29-2.27A2.23 2.23 0 0 1 8.38 10Z" />
  </svg>
)

export default ElipsisIcon
