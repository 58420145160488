import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import * as API from 'utils/api';

// Components
import { Empty, Icon, Row, Col, Typography, Menu, Dropdown, Modal, Spin, Button } from 'antd';
import DeleteModal from 'components/DeleteModal';
import SpinBox from 'components/SpinBox';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import { INVOICE_STATUS_UNPAID } from 'components/DropdownStatusInvoice/const';
import { ModalSelectCardPay } from 'components/Modal/ModalSelectCardPay';
import { ModalMessage } from 'components/Modal/ModalMessage';

// Icons
import { PayIcon } from 'components/Svg';
import { EditIcon, RemoveIcon, DetailsIcon } from '../../../../components/Icons';

// Utils
import { deleteTransaction } from '../../../../utils/api-transactions';
import { notifyError, notifySuccess, notifyInfo, notifyApiError } from 'utils/notification';
import { fetchTransaction, sendPaymentTransaction } from '../../../../utils/api-transactions';
import { INVOICES_NAME, getPermissionsBySection } from 'permissions';
import { getClientId } from 'layout/roles';

// Hooks
import { useCreditCard } from 'hooks';

// Const
import { BLUE_ICON } from 'containers/Calendar/src/src/lib/utils/colors';
import { SETTINGS_NAV } from 'layout/navigate_const';

import './transactions.scss';
import { INVOICE_SERVICE_TYPE_ID } from 'constants/serviceType';

const { Text } = Typography;

const TransactionList = ({ data, setData, handleEditTransactions = () => {}, loading, refresh }) => {
  const history = useHistory();

  const [removeTransModalOpen, setRemoveTransModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [pdfBase64, setPdfBase64] = useState('');
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [openModalAddCard, setOpenModalAddCard] = useState(false);
  const [invoicePay, setInvoicePay] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [openModalCreateCustomer, setOpenModalCreateCustomer] = useState(false);

  // Hook
  const { getCustomerProfile } = useCreditCard();

  const { roles, userInfo } = useSelector(state => state.auth);
  // Permissions
  const { INVOICES_PERMISSION } = getPermissionsBySection({
    roles: roles,
    permissions: [INVOICES_NAME],
  });
  // end permissions

  const COL_TABLE = {
    DATE: 4,
    REPORT: INVOICES_PERMISSION.payInvoice ? 6 : 7,
    NOTES: 3,
    STATUS: 4,
    INVOICES: 3,
    BTN: 3,
    ACTIONS: INVOICES_PERMISSION.payInvoice ? 1 : 2,
  };

  const handleOpenRemoveTransModal = record => {
    setRemoveTransModalOpen(true);
    setSelectedItem(record);
  };

  const handleRemoveTransaction = () => {
    deleteTransaction(selectedItem.id)
      .then(res => {
        const transactions = data.filter(d => d.id !== selectedItem.id);
        setData(transactions);
        notifySuccess(res.successMessage);
      })
      .catch(error => {
        notifyError(error.response.data.message);
      });
    setRemoveTransModalOpen(false);
  };

  const handleCloseRemoveTransaction = () => {
    setRemoveTransModalOpen(false);
  };

  const handleOpenInvoicePdf = async ({ invoice, download }) => {
    try {
      if (invoice?.invoice_id) {
        setLoadingInternal(true);
        const res = await fetchTransaction({ id: invoice.id });
        const base64Response = await fetch(`data:pdf;base64,${res.pdf}`);
        const blob = await base64Response.blob();
        const url = URL.createObjectURL(blob);

        if (download) {
          const link = document.createElement('a');
          link.href = url;
          link.download = `invoice-${invoice?.invoice_id}.pdf`;
          document.body.appendChild(link);
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        } else {
          setPdfBase64(url);
        }
      } else {
        notifyInfo('The invoice is not completed');
      }
    } catch (e) {
      notifyError(e.message);
    } finally {
      setLoadingInternal(false);
    }
  };

  const handleSendPayment = async ({ invoice }) => {
    try {
      if (invoice?.invoice_id) {
        setLoadingInternal(true);
        const res = await sendPaymentTransaction({ body: { invoice_id: invoice.invoice_id } });

        if (res?.success) {
          notifyInfo('The invoice is being processed');
        }

        refresh && refresh();
      } else {
        notifyInfo('The invoice is not completed');
      }
    } catch (e) {
      notifyError(e.message);
    } finally {
      setLoadingInternal(false);
    }
  };

  const handleOpenPayInvoices = async ({ invoice }) => {
    try {
      setLoadingInternal(true);
      
      if (invoice !== null) {
        const customerProfile = await getCustomerProfile();
        
        if (customerProfile === false) return setOpenModalCreateCustomer(true);
      }
  
      setOpenModalAddCard(prevState => !prevState);
      setInvoicePay(invoice);
    }catch (e) {
      notifyApiError(e)
    }finally {
      setLoadingInternal(false);
    }
  };

  const menu = item => (
    <Menu>
      {INVOICES_PERMISSION.receivePayment && (
        <Menu.Item>
          <div className="item-menu" onClick={() => handleSendPayment({ invoice: item })}>
            <PayIcon color={BLUE_ICON} />
            Receive Payment
          </div>
        </Menu.Item>
      )}
      <Menu.Item>
        <div className="item-menu" onClick={() => handleOpenInvoicePdf({ invoice: item })}>
          <DetailsIcon className="icon" />
          View
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="item-menu" onClick={() => handleOpenInvoicePdf({ invoice: item, download: true })}>
          <DownloadOutlined
            height={'20px'}
            style={{ color: BLUE_ICON, fontSize: 18 }}
            width={'20px'}
            className="icon"
          />
          Download
        </div>
      </Menu.Item>
      {INVOICES_PERMISSION.edit && (
        <Menu.Item>
          <div className="item-menu" onClick={() => handleEditTransactions(item)}>
            <EditIcon className="icon" />
            Edit
          </div>
        </Menu.Item>
      )}
      {INVOICES_PERMISSION.delete && (
        <Menu.Item>
          <div className="item-menu" onClick={() => handleOpenRemoveTransModal(item)}>
            <RemoveIcon className="icon" />
            Remove
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  const handlePayInvoice = async ({ selectedCard }) => {
    try {
      setLoadingInternal(true);
      const client_id = getClientId(roles)
      
      const customerProfile = await API.getCustomerProfileByLawFirm({ idLawFirm: client_id });

      const paymentAuthorize = await API.payInvoiceWithCreditCard({
        customerProfileId: customerProfile.customer.customerProfileId,
        paymentProfileId: selectedCard,
        invoice_id: invoicePay.invoice_id,
      });

      await API.createPurchases({
        payment_invoices_id: paymentAuthorize.payment_invoices_id,
        customer: userInfo.username,
        invoice_id: invoicePay.id,
        service_type_id: INVOICE_SERVICE_TYPE_ID,
      })

      const reports_name = invoicePay.data.map(report => report.report.report_name).join(', ');

      setOpenModalAddCard(false);
      setInvoicePay(null);
      setModalMessage(`Your payment of report ${reports_name} has been successul`);
      refresh && refresh();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingInternal(false);
    }
  };

  return (
    <>
      {loadingInternal && (
        <Modal visible={loadingInternal} footer={null} className="modal-loader">
          <Spin />
        </Modal>
      )}
      <Row className="reports-list-container">
        <Row type="flex" className="table-header table-header__reports">
          <Col md={COL_TABLE.DATE}>Date</Col>
          <Col md={COL_TABLE.REPORT}>Reports</Col>
          <Col md={COL_TABLE.NOTES}>Notes</Col>
          <Col md={COL_TABLE.STATUS}>Status</Col>
          <Col md={COL_TABLE.INVOICES}>Invoice No.</Col>
          {INVOICES_PERMISSION.payInvoice && <Col md={COL_TABLE.BTN}></Col>}
          <Col md={COL_TABLE.ACTIONS} style={{ textAlign: 'right' }}>
            Actions
          </Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading}>
            {data.length > 0 ? (
              <Row>
                {data.map(item => (
                  <Row key={item.id} className="record">
                    <Col md={COL_TABLE.DATE}>{moment(item.createdAt).format('MM/DD/YYYY')}</Col>
                    <Col md={COL_TABLE.REPORT} className="transactionList_reports">
                      {item.data.map(info => (
                        <React.Fragment key={info?.report?.report_uuid}>
                          <Text>{`${info?.report?.report_uuid} ${info?.report?.report_name || '-'}`}</Text>
                          <br />
                        </React.Fragment>
                      ))}
                    </Col>
                    <Col md={COL_TABLE.NOTES}>{item?.notes || '-'}</Col>
                    <Col md={COL_TABLE.STATUS}>
                      <Text>{item?.status || INVOICE_STATUS_UNPAID}</Text>
                    </Col>
                    <Col md={COL_TABLE.INVOICES}>
                      <Text>{item?.invoice || '-'}</Text>
                    </Col>
                    {INVOICES_PERMISSION.payInvoice && (
                      <Col md={COL_TABLE.BTN}>
                        {item.status === null && (
                          <Button
                            type="primary"
                            size="default"
                            className="green-btn button buttonRevision"
                            onClick={() => handleOpenPayInvoices({ invoice: item })}>
                            Pay Now
                          </Button>
                        )}
                      </Col>
                    )}
                    <Col md={COL_TABLE.ACTIONS} style={{ textAlign: 'right' }} className="actions">
                      <Dropdown overlay={menu(item)} placement="bottomRight" trigger={['click']}>
                        <Icon type="ellipsis" className="dots-icon" />
                      </Dropdown>
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                description={loading ? false : 'No Data'}
                image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
      </Row>

      {pdfBase64 && <ModalPdf path={pdfBase64} close={() => setPdfBase64('')} />}

      <DeleteModal
        title="Delete this Invoicing?"
        content={selectedItem ? `Number: ${selectedItem.id} Service: ${selectedItem.data[0].service.name}` : ''}
        isOpen={removeTransModalOpen}
        onConfirm={handleRemoveTransaction}
        onCancel={handleCloseRemoveTransaction}
      />

      {openModalAddCard && (
        <ModalSelectCardPay onClose={() => handleOpenPayInvoices({ invoice: null })} onPay={handlePayInvoice} />
      )}

      {modalMessage && (
        <ModalMessage
          onClose={() => setModalMessage('')}
          message="For public use by other law firms"
          title={modalMessage}
          textButton="Close"
        />
      )}

      {openModalCreateCustomer && (
        <ModalMessage
          onClose={() => setOpenModalCreateCustomer('')}
          title="Please complete your billing information before adding a card"
          textButton="Go to Settings"
          noneIcon
          onConfirm={() => history.push(SETTINGS_NAV.path)}
        />
      )}
    </>
  );
};

export default TransactionList;
