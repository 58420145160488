import axios from 'axios';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Row, Upload } from 'antd';
import React, { Component } from 'react';
import * as API from 'utils/api';
import { getPresignedPublicUrlLogo } from 'utils/api';
import { notifyApiError } from '../../utils/notification';

export default class UploadLogo extends Component {
  state = { loading: false };

  upload = async file => {
    this.setState({ loading: true });

    try {
      const {
        data: { url: data },
      } = await API.getPresignedLogo({
        key: file.name,
        type: file.type,
      });

      const formData = new FormData();
      for (const key in data.url.fields) {
        formData.append(key, data.url.fields[key]);
      }
      formData.append('file', file);

      await axios.post(data.url.url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      let s3key = data.logoUrl;
      const res = await getPresignedPublicUrlLogo(s3key);
      const url = res.url || res.data.url;

      this.props.changeImage(url, s3key);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error({
        content: 'You can only upload JPG/PNG file!',
        style: {
          background: '#ffa39e !important',
        },
      });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error({
        content: 'Image must be smaller than 2 MB!',
        style: {
          background: '#ffa39e !important',
        },
      });
      return false;
    }
    this.upload(file);
    return false; //do not upload automatically
    // return isJpgOrPng && isLt2M;
  };

  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Row className="tags-input-box-container">
        <div className="upload_logo_wrapper">
          <Upload
            name="avatar"
            listType="picture-card"
            className={`avatar-uploader ${this.props.disabledField ? 'avatar-uploader-disabled' : ''}`}
            disabled={this.props.disabledField}
            showUploadList={false}
            beforeUpload={this.beforeUpload}>
            {this.props.client_logo ? (
              <img src={this.props.client_logo} alt="avatar" style={{ height: 180 }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </Row>
    );
  }
}
