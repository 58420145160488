import * as React from 'react';

// COLORS
import { PRIMARY_LIGHT_BLUE, SECONDARY_GREY } from 'containers/Calendar/src/src/lib/utils/colors';

const StartIcon = ({ active, onClick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" onClick={onClick}>
    <path
      d="m8.998 14.52 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72 3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41-1.89-4.46c-.34-.81-1.5-.81-1.84 0l-1.89 4.45-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5Z"
      fill={active ? PRIMARY_LIGHT_BLUE : SECONDARY_GREY}
    />
  </svg>
);

export default StartIcon;
