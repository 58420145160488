import * as React from 'react';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

const UserRoundedIcon = ({ color = PRIMARY_LIGHT_BLUE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 32 32' fill="none">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.213 14.493a2.43 2.43 0 0 0-.44 0 5.893 5.893 0 0 1-5.693-5.906c0-3.267 2.64-5.92 5.92-5.92a5.924 5.924 0 0 1 5.92 5.92c-.013 3.2-2.533 5.8-5.707 5.906ZM9.547 19.413c-3.227 2.16-3.227 5.68 0 7.827 3.666 2.453 9.68 2.453 13.346 0 3.227-2.16 3.227-5.68 0-7.827-3.653-2.44-9.666-2.44-13.346 0Z"
    />
  </svg>
);
export default UserRoundedIcon;
