import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RemoveIcon, EditIcon } from 'components/Icons';
import { Spin } from 'antd';
import * as API from 'utils/api';
import { Row, Col, Button, Typography, Divider, Select } from 'antd';
import InputBox from 'components/InputBox';
import InputMask from 'react-input-mask';
import { validateEmail, validatePhone, validateName } from 'utils/utils';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { DeleteUserModal } from 'components/Modal/DeleteUserModal';
import { ROL_ID_BY_NAME } from 'layout/roles';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { useSelector } from 'react-redux';

const { Text } = Typography;
const { Option } = Select;

const CREATE_EDIT_INIT = {
  open: false,
  data: {},
};

const UsersCreate = ({ data, onClose, onSubmit }) => {
  // Const
  const { id } = useParams();

  // State
  const [loading, setLoading] = useState(true);
  const [listRoles, setListRoles] = useState([]);
  const [given_name, setGivenName] = useState(data?.given_name || '');
  const [family_name, setFamilyName] = useState(data?.family_name || '');
  const [locale, setLocale] = useState(data?.locale || '');
  const [email, setEmail] = useState(data?.email || '');
  const [role_id, setRole_id] = useState(data?.roles[0]?.id || '');
  const { roles } = useSelector(state => state.auth);
  const [client_id, setClient_id] = useState(data?.client?.id || '');
  const [listClient, setListClient] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const results = await Promise.allSettled([
          API.getNurseRoles(),
          API.getParentFolderById({ id, model: 'client' }),
        ]);

        const error = results.find(item => item.status === 'rejected');
        if (error) throw new Error(error.reason.message);

        const rolesClient = results[0].value.roles.filter(rol => rol.name.toLowerCase().includes('client'));
        if (data?.role_name) setRole_id(rolesClient.find(rol => rol.display_name === data.role_name).id);

        setListClient(results[1].value.clients);
        setListRoles(rolesClient);
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    onSubmit({
      data: {
        given_name,
        family_name,
        locale,
        email,
        role_id,
        client_id,
      },
      client: listClient.find(client => client.id === client_id),
      idEdit: data?.id || null,
    });
  };

  const isFormValid =
    given_name &&
    family_name &&
    role_id &&
    validateEmail(email) &&
    validatePhone(locale) &&
    validateName(given_name) &&
    validateName(family_name);

  const disabledField = isAllowed(roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

  return (
    <Spin spinning={loading}>
      <form onSubmit={handleSubmit} className="form-create-user">
        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col md={11} className="row">
            <InputBox label="First Name" value={given_name} onChange={value => setGivenName(value)} />
          </Col>
          <Col md={11} className="row">
            <InputBox label="Last Name" value={family_name} onChange={value => setFamilyName(value)} />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col md={11} className="row">
            <InputBox label="Email" value={email} disabled={disabledField} onChange={value => setEmail(value)} />
          </Col>
          <Col md={11} className="row">
            <Row className="label">
              <Text>Phone</Text>
            </Row>
            <Row>
              <InputMask
                mask="999-999-9999"
                placeholder="Optional"
                className="input-ssn"
                value={locale}
                onChange={e => setLocale(e.target.value)}
              />
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" align="middle" className="main-info">
          <Col span={11} className="row">
            <Row className="row" style={{ marginBottom: '10px' }}>
              <Text className="label">Roles</Text>
            </Row>
            <Row>
              <Select
                disabled={disabledField}
                value={role_id}
                placeholder="Required"
                onChange={value => setRole_id(value)}
                style={{ width: '100%' }}>
                {listRoles.map(rol => (
                  <Option key={rol.id} value={rol.id}>
                    {rol.display_name}
                  </Option>
                ))}
              </Select>
            </Row>
          </Col>

          <Col span={11} className="row">
            <Row className="row" style={{ marginBottom: '10px' }}>
              <Text className="label">Associated Main Firm Folder</Text>
            </Row>
            <Row>
              <Select
                disabled={disabledField}
                value={client_id}
                placeholder="Required"
                onChange={value => setClient_id(value)}
                style={{ width: '100%' }}>
                {listClient.map(client => (
                  <Option key={client.id} value={client.id}>
                    {client.client_name}
                  </Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>
        <Divider className="divider-space" />

        <Row type="flex" justify="space-between" className="confirm-button">
          <Col md={11} className="row" justify="center" align="end">
            <Button
              type="primary"
              size="large"
              className="green-btn button"
              disabled={!isFormValid}
              onClick={handleSubmit}>
              {data?.id ? 'Update User' : 'Create User'}
            </Button>
          </Col>
          <Col md={11} justify="center" className="row">
            <Button type="primary" size="large" className="transparent-btn" onClick={onClose}>
              cancel
            </Button>
          </Col>
        </Row>
      </form>
    </Spin>
  );
};

const UsersTab = () => {
  // Const
  const { id } = useParams();

  // State
  const [loading, setLoading] = useState(true);
  const [listUsers, setListUsers] = useState([]);
  const [createOrEdit, setCreateOrEdit] = useState(CREATE_EDIT_INIT);
  const [selectUserDelete, setSelectUserDelete] = useState({
    open: false,
    user: null,
  });

  // Effect
  const getUsers = async () => {
    try {
      const res = await API.getParentFolderById({ id, model: 'user' });
      setListUsers(res.users);
    } catch (e) {
      notifyApiError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler
  const handleDelete = async data => {
    try {
      setLoading(true);
      await API.deleteUserSynchronised(data.id);
      await API.deleteUser(data.email);
      const updateListUser = listUsers.filter(user => user.id !== data.id);
      setListUsers(updateListUser);
      notifyInfo('User was deleted');
      setSelectUserDelete({});
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleAddUser = async ({ data }) => {
    try {
      setLoading(true);
      const param = {
        ...data,
        restrictions: 'restrictions',
        // tenant_id: folderInfo.tenant_id,
      };
      const res = await API.createUser(param);
      param.user_id = res.data.User.Username;
      await API.synchroniseUser({ ...param, id: res.data.User.Username });
      await API.AppendRoles([
        {
          user_id: param.user_id,
          client_id: param.client_id,
          role_id: param.role_id,
        },
      ]);

      notifyInfo('User was created');
      setCreateOrEdit(CREATE_EDIT_INIT);
      await getUsers();
    } catch (error) {
      notifyApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = async ({ data, client }) => {
    try {
      const params = {
        email: data.email,
        given_name: data.given_name,
        family_name: data.family_name,
        locale: data.locale,
        'custom:restrictions': ',',
      };
      setLoading(true);

      let rolUser = createOrEdit.data.roles[0].id;

      // Si el rol al editar es diferente al que ya tenia lo actualizamos
      if (ROL_ID_BY_NAME[createOrEdit.data.roles[0].display_name] !== data.role_id) {
        const old_role_id = ROL_ID_BY_NAME[createOrEdit.data.roles[0].display_name];
        rolUser = data.role_id;
        const id_user = createOrEdit.data.id;
        const client_id = client.id;
        await API.DeleteRoles(id_user, old_role_id);
        await API.AddRole(id_user, rolUser, { client_id });
      }

      // Si el client al editar es diferente al que ya tenia lo actualizamos
      if (data.client_id !== createOrEdit.data.client.id) {
        await API.EditRole(createOrEdit.data.id, rolUser, { client_id: data.client_id });
      }

      await API.updateUser(params.email, params);
      await API.updateUserSynchronised({
        ...data,
        tenant_id: client.tenant_id,
      });
      const updateListUser = listUsers.map(user => {
        if (user.id !== data.id) return user;
        return {
          ...user,
          ...data,
        };
      });
      setListUsers(updateListUser);
      setCreateOrEdit(CREATE_EDIT_INIT);
      await getUsers();
    } catch (e) {
      notifyApiError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = ({ data, idEdit, client }) => {
    if (idEdit) return handleEditUser({ data: { ...data, id: idEdit }, client });
    handleAddUser({ data });
  };

  const thereIsUsers = listUsers.length > 0;

  return (
    <Spin spinning={loading}>
      <div className="msa-stepContainer">
        {!createOrEdit.open && (
          <div className="stepFormContainerStep2">
            {thereIsUsers &&
              listUsers.map(item => (
                <div className="stepContainerContact" key={item.id}>
                  <div className="stepContactItem">
                    <h2>{`${item.given_name} ${item.family_name}`}</h2>
                  </div>
                  <div className="stepContactItemButtons">
                    <button>
                      <EditIcon
                        onClick={() =>
                          setCreateOrEdit({
                            open: true,
                            data: item,
                          })
                        }
                      />
                    </button>
                    <button onClick={() => setSelectUserDelete({ open: true, user: item })}>
                      <RemoveIcon />
                    </button>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Roles</p>
                    <p className="stepContactItemValue">{item?.roles[0]?.name || ''}</p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Firm Name</p>
                    <p className="stepContactItemValue">{item?.client?.client_name || ''}</p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Email</p>
                    <p className="stepContactItemValue">{item?.email || ''}</p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Phone</p>
                    <p className="stepContactItemValue">{item?.locale || ''}</p>
                  </div>
                </div>
              ))}

            <button
              className="addAdditionalContactButton margin-button"
              onClick={() =>
                setCreateOrEdit({
                  open: true,
                  date: null,
                })
              }>
              Add New User
            </button>
          </div>
        )}

        {createOrEdit.open && (
          <UsersCreate
            data={createOrEdit.data}
            onClose={() => setCreateOrEdit(CREATE_EDIT_INIT)}
            onSubmit={handleSubmit}
          />
        )}

        {selectUserDelete?.open && (
          <DeleteUserModal
            open={selectUserDelete.open}
            onClose={() => setSelectUserDelete({})}
            onDelete={handleDelete}
            data={selectUserDelete.user || {}}
          />
        )}
      </div>
    </Spin>
  );
};

export default UsersTab;
