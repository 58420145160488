import React, { useState } from 'react';
import { Row, Typography, Divider, Button } from 'antd';
import * as API from 'utils/api';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';

import { ModalMessage } from 'components/Modal/ModalMessage';
import { ModalLoading } from 'components/Modal/ModalLoading';

import './supportPage.scss';
import { notifyError } from 'utils/notification';

const { Title, Text } = Typography;

const SupportPage = () => {
  const [message, setMessage] = useState('');
  const [listFiles, setListFiles] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddFiles = ({ file }) => {
    if (listFiles.some(item => item.name === file[0].name)) return ;
    setListFiles([...listFiles, ...file]);
  };

  const removeFile = ({ fileName }) => {
    setListFiles(listFiles.filter(file => file.name !== fileName));
  };

  const removeAllFiles = () => {
    setListFiles([]);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleSubmit = async () => {
    try {
      if (!message) return notifyError('Message is required');
      setLoading(true);
      const sendFile = [];

      await Promise.all(
        listFiles.map(async file => {
          const base64 = await getBase64(file);

          sendFile.push({
            filename: file.name,
            contentType: file.type,
            content: base64,
          });
        })
      );

      await API.sendSupportService({ attachments: sendFile, message });

      setSuccess(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setSuccess(false);
    setMessage('');
    setListFiles([]);
  };

  return (
    <Row className="dashboard-container">
      {loading && <ModalLoading />}
      <Row type="flex" align="middle" justify="center" className="header">
        <Title level={4} className="title">
          Submit Support Inquiries
        </Title>
      </Row>

      <div className="supportPage">
        <Text>
          Please provide specific details about the issues you've encountered. Upload screenshots or other relevant
          attachments to better illustrate these issues your experiencing.
        </Text>

        <div className="supportPage_group">
          <label htmlFor="message">Message</label>
          <textarea id="message" placeholder="required" value={message} onChange={e => setMessage(e.target.value)} />
        </div>

        <BoxDragAndDrop multiple handleAddFiles={handleAddFiles} input />

        {listFiles.length > 0 && (
          <TableFileBoxDragAndDrop listFiles={listFiles} removeFile={removeFile} removeAllFiles={removeAllFiles} />
        )}

        <Divider />
        <div className="supportPage_btn">
          <Button type="primary" className="green-btn" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>

      {success && (
        <ModalMessage
          noneIcon
          title="You have successfully submitted your support request"
          textButton="Ok"
          onClose={handleClear}
        />
      )}
    </Row>
  );
};

export default SupportPage;
