import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Col, Row, Typography, DatePicker, Select, Icon, Divider, Button, Tooltip, Modal } from 'antd';
import DropDown from 'components/Dropdown';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import InputBox from 'components/InputBox';
import { sortArrayObjectForKey, formatMoneyToNumber } from 'utils/utils';
import InputMask from 'react-input-mask';
import InputCurrencyBox from 'components/InputCurrencyBox';
import moment from 'moment';
import { notifyApiError } from 'utils/notification';
import { STATUS_REDUCTION_COMPLETED } from 'constants/report_status';
import { disableFutureDay } from 'containers/Reports/utils/helpers';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { useSelector } from 'react-redux';

const { Title, Text } = Typography;
const { Option } = Select;

const Letter = () => {
  let { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const claimantInfo = location.state.data.claimantInfo;
  const { roles } = useSelector(state => state.auth);

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

  const [loading, setLoading] = useState(true);
  const [listState, setListState] = useState([]);
  const [today, setToday] = useState(moment());
  const [juridiction_state_id, setJuridictionStateId] = useState('');
  const [clientsAttorneyName, setClientsAttorneyName] = useState('');
  const [fileNo, setFileNo] = useState('');
  const [idcCodes, setIdcCodes] = useState('');
  const [insurance_company, setInsurance_company] = useState('');
  const [dob, setDob] = useState(null);
  const [date_of_injury, setDate_of_injury] = useState(null);
  const [SSN, setSsn] = useState('');
  const [submitter_attorney_name, setSubmitter_attorney_name] = useState('');
  const [submitter_attorney_firm_name, setSubmitter_attorney_firm_name] = useState('');
  const [submitter_attorney_phone, setSubmitter_attorney_phone] = useState('');
  const [submitter_attorney_email, setSubmitter_attorney_email] = useState('');
  const [settlement_amount, setSettlementAmount] = useState('');
  const [present_value_case, setPresent_value_case] = useState('');
  const [actual_settlement_amount, setActualSettlementAmount] = useState(0);
  const [total_attorney_fees, setTotal_attorney_fees] = useState('');
  const [disbursements, setDisbursements] = useState('');
  const [total_liens, setTotalLiens] = useState('');
  const [settlement_net, setSettlementNet] = useState('');
  const [proposed_reduction_amount, setProposedReductionAmount] = useState('');
  const [related_diagnosis, setRelated_diagnosis] = useState([]);
  const [report_name, setReportName] = useState('');
  const [report_claim_id, setReportClaimantId] = useState('');
  const [report_type_id, setReportTypeId] = useState('');
  const [report_status_id, setReportStatusId] = useState('');
  const [isReportView, setIsReportView] = useState(false);
  const [report_uuid, setReportUuid] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const report = await API.getReportById(id, true);

        let getReportView;

        try {
          getReportView = await API.getViewReport(report.id);
        } catch {
          getReportView = false;
        }

        const states = await API.getJuridictionStateList();
        const orderState = sortArrayObjectForKey({ array: states.juridictionStates, key: 'name' });
        const listIcd = await API.getIcdCodeData('');

        let reportInfos;

        if (getReportView && getReportView?.id) {
          reportInfos = getReportView;
          setIsReportView(true);
        } else {
          reportInfos = report;
        }

        if (reportInfos.related_diagnosis) {
          const getRelatedDiagnosis = reportInfos.related_diagnosis.split(',');
          setRelated_diagnosis(getRelatedDiagnosis);
        }

        setReportUuid(reportInfos.report_uuid);
        setDate_of_injury(moment(reportInfos.date_of_injury) || moment());
        setDob(moment(reportInfos.dob) || moment());
        setInsurance_company(reportInfos.insurance_company);
        setSsn(report.claimant.SSN || '');
        setSubmitter_attorney_name(reportInfos.submitter_attorney_name || '');
        setSubmitter_attorney_firm_name(reportInfos.submitter_attorney_firm_name || '');
        setSubmitter_attorney_email(reportInfos.submitter_attorney_email || '');
        setSubmitter_attorney_phone(reportInfos.submitter_attorney_phone || '');
        setPresent_value_case(reportInfos.present_value_case || '');
        setActualSettlementAmount(reportInfos.actual_settlement_amount || 0);
        setTotal_attorney_fees(reportInfos.total_attorney_fees || '');
        setJuridictionStateId(reportInfos.juridiction_state_id || '');
        setDisbursements(reportInfos.disbursements || '');
        setSettlementAmount(reportInfos.settlement_amount);
        setProposedReductionAmount(reportInfos.proposed_reduction_amount);
        setTotalLiens(reportInfos.total_liens || 0);
        setReportName(reportInfos.report_name);
        setReportClaimantId(reportInfos.report_claim_id);
        setReportTypeId(reportInfos.report_type_id);
        setReportStatusId(reportInfos.report_status_id);
        setClientsAttorneyName(reportInfos.clientsAttorneyName);
        setFileNo(reportInfos.fileNo);
        setSettlementNet(reportInfos.settlement_net);
        setIdcCodes(listIcd.icd_codes || []);
        setListState(orderState);
      } catch (err) {
        console.error({ err });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSearchIcdCodes = async value => {
    const result = await API.getIcdCodeData(value);
    setIdcCodes(result.icd_codes);
  };

  const handleSubmit = async ({ draft, preview } = {}) => {
    try {
      setLoading(true);
      const new_related_diagnosis = [];
      related_diagnosis.forEach(value => {
        let item = idcCodes.find(ele => value === ele.code);
        if (item) {
          new_related_diagnosis.push(JSON.stringify(item).replace(/,/g, '|'));
        } else {
          new_related_diagnosis.push(value);
        }
      });

      if (!juridiction_state_id) return notifyApiError('Select a State');

      const params = {
        today: moment(today),
        juridiction_state_id,
        clientsAttorneyName,
        fileNo,
        insurance_company,
        date_of_injury: moment(date_of_injury),
        submitter_attorney_name,
        submitter_attorney_firm_name,
        submitter_attorney_phone,
        submitter_attorney_email,
        settlement_amount: formatMoneyToNumber({ money: settlement_amount }),
        present_value_case: formatMoneyToNumber({ money: present_value_case }),
        actual_settlement_amount: formatMoneyToNumber({ money: actual_settlement_amount }),
        total_attorney_fees: formatMoneyToNumber({ money: total_attorney_fees }),
        disbursements: formatMoneyToNumber({ money: disbursements }),
        total_liens: formatMoneyToNumber({ money: total_liens }),
        settlement_net: formatMoneyToNumber({ money: settlement_net }),
        proposed_reduction_amount: formatMoneyToNumber({ money: proposed_reduction_amount }),
        related_diagnosis: new_related_diagnosis.toString(),
        report_name,
        report_claim_id,
        report_type_id,
        report_status_id,
        id,
        report_uuid,
      };

      if (draft && !isReportView) {
        await API.createViewReport(params);
        setIsReportView(true);

        if (preview) return history.push(`/app/reports/${id}/msa_letter/preview`, { data: location.state.data });
      } else {
        await API.updateReportQA(params);

        if (preview) return history.push(`/app/reports/${id}/msa_letter/preview`, { data: location.state.data });

        if (!draft) {
          params.isAttorney = true;
          params.report_status_id = STATUS_REDUCTION_COMPLETED.id;
          await API.updateReport(params);
          await Promise.all([
            API.setUpMsaReport(params.id),
            API.setUpReductionReport(params.id),
            API.setUpLetterReport(params.id),
          ]);
          const file = await API.getReportFilesReductionProcess({ reportId: params.id });

          if (file) {
            Modal.success({
              content: 'Report is generated',
              onOk: () => {
                window.open(file, '_blank');
              },
            });
          }
        }
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      if (!preview) setLoading(false);
    }
  };

  const validForm =
    !!juridiction_state_id &&
    !!clientsAttorneyName &&
    !!fileNo &&
    !!idcCodes &&
    !!insurance_company &&
    !!dob &&
    !!date_of_injury &&
    !!SSN &&
    !!submitter_attorney_name &&
    !!submitter_attorney_firm_name &&
    !!submitter_attorney_phone &&
    !!submitter_attorney_email &&
    !!settlement_amount &&
    !!present_value_case &&
    !!total_attorney_fees &&
    !!disbursements &&
    !!total_liens &&
    !!actual_settlement_amount &&
    !!proposed_reduction_amount;

  return (
    <Row className="msa-demographic-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Medicare Set-Aside Letter
          </Title>
        </Col>
        <Col className="step">
          <DropDown loading={loading} disabled={!validForm} goBack={() => history.goBack()} />
        </Col>
      </Row>
      <Row className="main">
        <SpinBox loading={loading}>
          <form className="content">
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={6}>
                <Text>Today`s date</Text>
                <DatePicker
                  className="MSA_REDUCTION_newDatePicker"
                  format="DD/MM/YYYY"
                  allowClear={false}
                  value={today}
                  placeholder="DD / MM / YYYY"
                  onChange={date => setToday({ today: date })}
                  disabled={!REPORT_PERMISSIONS.edit}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={24}>
                <InputBox
                  type="text"
                  label="Client Name"
                  placeholder="Type Client Name"
                  value={claimantInfo.client.client_name}
                  disabled
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={16}>
                <InputBox
                  type="text"
                  label="Client Address"
                  placeholder="Type Client Street address"
                  value={claimantInfo.address}
                  disabled
                />
              </Col>
              <Col md={6}>
                <Text>State</Text>
                <Select
                  showSearch
                  optionFilterProp="children"
                  className="dropdown"
                  value={juridiction_state_id}
                  onChange={value => setJuridictionStateId(value)}
                  disabled={!REPORT_PERMISSIONS.edit}
                  suffixIcon={<Icon type="caret-down" theme="filled" />}>
                  <Option disabled value="">
                    Type State
                  </Option>
                  {listState.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={16}>
                <InputBox
                  type="text"
                  label="Client`s Attorney Name"
                  placeholder="Type Name of Attorney Client"
                  value={clientsAttorneyName}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setClientsAttorneyName(value)}
                />
              </Col>
              <Col md={6}>
                <InputBox
                  type="text"
                  label="File No."
                  placeholder="Type File No."
                  value={fileNo}
                  onChange={value => setFileNo(value)}
                  disabled={!REPORT_PERMISSIONS.edit}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={24}>
                <Text>List Of ICD Codes</Text>
                <Select
                  mode="multiple"
                  className="dropdown"
                  filterOption={false}
                  placeholder="Insert List of ICD Codes"
                  disabled={!REPORT_PERMISSIONS.edit}
                  value={related_diagnosis}
                  onSearch={value => handleSearchIcdCodes(value)}
                  onChange={value => setRelated_diagnosis(value)}>
                  {idcCodes &&
                    idcCodes.map((item, index) => {
                      let value = JSON.stringify({
                        code: item.code,
                        med_descr: item.med_descr ? item.med_descr.replace(/,/g, '$') : '',
                      }).replace(/,/g, '<');
                      const sortId =
                        related_diagnosis.indexOf(value) !== -1
                          ? related_diagnosis.indexOf(value)
                          : related_diagnosis.length + index;
                      return (
                        <Option key={index} value={value}>
                          <Tooltip
                            overlayStyle={{ maxWidth: 300 }}
                            placement="right"
                            title={` (${item.code}) ${item.med_descr}`}>
                            <span
                              draggable="true"
                              onMouseDown={e => {
                                e.stopPropagation();
                              }}
                              onDragStart={e => this.handleDiagnosisDragStart(e)}
                              onDragEnd={e => this.handleDiagnosisDragEnd(e)}
                              data-id={sortId}
                              data-rid={`${item.code}-${item.med_descr}`}
                              className="related-list-item">
                              {` (${item.code}) ${item.med_descr}`}
                            </span>
                          </Tooltip>
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
            <Divider className="divider" />
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <InputBox
                  type="text"
                  label="Name of Injured Party"
                  placeholder="Type Injury Party Full Name"
                  disabled={!REPORT_PERMISSIONS.edit}
                  value={insurance_company}
                  onChange={value => setInsurance_company(value)}
                />
              </Col>
              <Col md={11}>
                <Text>Birthday of injured Party</Text>
                <DatePicker
                  className="MSA_REDUCTION_newDatePicker"
                  format="DD/MM/YYYY"
                  allowClear={false}
                  value={moment(claimantInfo.dob)}
                  placeholder="DD / MM / YYYY"
                  disabled
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <Text>Date of Injury</Text>
                <DatePicker
                  className="MSA_REDUCTION_newDatePicker"
                  disabledDate={disableFutureDay}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  value={date_of_injury}
                  placeholder="DD / MM / YYYY"
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={date => setDate_of_injury(date)}
                />
              </Col>
              <Col md={11}>
                <Text>SSN</Text>
                <InputMask
                  mask="999-99-9999"
                  placeholder="SNN"
                  className="MSA_REDUCTION_inputMask"
                  value={claimantInfo.SSN}
                  disabled
                />
              </Col>
            </Row>
            <Divider className="divider" />
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <InputBox
                  type="text"
                  label="Attorney Name"
                  placeholder="Type Client Name"
                  value={submitter_attorney_name}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setSubmitter_attorney_name(value)}
                />
              </Col>
              <Col md={11}>
                <InputBox
                  type="text"
                  label="Firm Name"
                  disabled={!REPORT_PERMISSIONS.edit}
                  placeholder="Type Client Law Firm"
                  value={submitter_attorney_firm_name}
                  onChange={value => setSubmitter_attorney_firm_name(value)}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <Text>Phone</Text>
                <InputMask
                  mask="999-999-9999"
                  placeholder="Type Client Phone Number"
                  className="MSA_REDUCTION_inputMask"
                  value={submitter_attorney_phone}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setSubmitter_attorney_phone(value.target.value)}
                />
              </Col>
              <Col md={11}>
                <InputBox
                  type="text"
                  label="Email"
                  placeholder="Type Client Email"
                  value={submitter_attorney_email}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setSubmitter_attorney_email(value)}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <InputBox
                  type="text"
                  label="MSA as prepared"
                  placeholder="Type MSA as prepared"
                  value={settlement_amount}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setSettlementAmount(value)}
                />
              </Col>
              <Col md={11}>
                <Text>Total Value of Case</Text>
                <InputCurrencyBox
                  placeholder="Type Actual Settlement Amount"
                  value={present_value_case || ''}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setPresent_value_case(value)}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <Text>Actual Settlement Amount</Text>
                <InputCurrencyBox
                  placeholder="Type Actual Settlement Amount"
                  value={actual_settlement_amount || ''}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setActualSettlementAmount(value)}
                />
              </Col>
              <Col md={11}>
                <Text>Total Attorney Fees</Text>
                <InputCurrencyBox
                  placeholder="Type Total Attorney Fees"
                  value={total_attorney_fees || ''}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setTotal_attorney_fees(value)}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <Text>Taxable Costs/Disbursements</Text>
                <InputCurrencyBox
                  placeholder="Type Taxable Costs/Disbursements"
                  value={disbursements || ''}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setDisbursements(value)}
                />
              </Col>
              <Col md={11}>
                <Text>Total Medical Liens</Text>
                <InputCurrencyBox
                  placeholder="Type Total Medical Liens"
                  value={total_liens || ''}
                  onChange={value => setTotalLiens(value)}
                  disabled
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <Text>Net Settlement</Text>
                <InputCurrencyBox
                  placeholder="Type Net Settlement"
                  value={settlement_net || ''}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setSettlementNet(value)}
                />
              </Col>
              <Col md={11}>
                <Text>Proposed MSA Reduction Amount</Text>
                <InputCurrencyBox
                  placeholder="Type Proposed MSA Reduction Amount"
                  value={proposed_reduction_amount || ''}
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={value => setProposedReductionAmount(value)}
                />
              </Col>
            </Row>
            <Divider className="divider" />

            {
              REPORT_PERMISSIONS.edit && (
                <Row type="flex" justify="space-between" className="row">
                  <Col md={11}>
                    <Row type="flex" className="row">
                      <Col md={11}>
                        <Button
                          type="primary"
                          size="large"
                          className="purple-btn btn-normal"
                          onClick={() => handleSubmit({ draft: true, preview: true })}>
                          Preview
                        </Button>
                      </Col>
                      <Col md={11}>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => handleSubmit({ draft: true })}
                          className="purple-btn btn-normal">
                          Save Draft
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={11} align="end">
                    <Button
                      type="primary"
                      size="large"
                      className="btn-normal green-btn"
                      disabled={false}
                      onClick={() => handleSubmit()}>
                      Complete Report
                    </Button>
                  </Col>
                </Row>
              )
            }
          </form>
        </SpinBox>
      </Row>
    </Row>
  );
};

export default Letter;
