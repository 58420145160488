import React from 'react';
import { Modal, Spin, Select, Icon, Row, Col, Checkbox, Button } from 'antd';
import InputBox from 'components/InputBox';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';

const { Option } = Select;

const READ_ONLY = 'read_only';

const ModalMaterialsComponent = ({
  loading,
  newData,
  handleChangeNewData,
  listSection,
  listFiles,
  beforeUpload,
  handleAddFiles,
  removeFile,
  removeAllFiles,
  handleSave,
  close,
  handleClientOrInternal,
  title,
  embeddedVideo,
}) => {
  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      title={title}
      className="ModalMaterials">
      <div className="modalMaterials_content">
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>

        <InputBox label="Name" value={newData.name} onChange={value => handleChangeNewData({ field: 'name', value })} />

        <div className="modalMaterials_group">
          <label>Section</label>
          <Select
            showSearch
            className="dropdown"
            value={newData.section_id}
            onChange={value => handleChangeNewData({ field: 'section_id', value })}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            <Option disabled value={0}>
              Required
            </Option>

            {listSection.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>

        <Row className="modalMaterials_checks" style={{ width: '100%' }}>
          <Col md={6} className="row">
            <Checkbox
              checked={newData[READ_ONLY]}
              onChange={() => handleChangeNewData({ field: READ_ONLY, value: true })}>
              View Only
            </Checkbox>
          </Col>
          <Col md={6} className="row">
            <Checkbox
              checked={!newData[READ_ONLY]}
              onChange={() => handleChangeNewData({ field: READ_ONLY, value: false })}>
              Download
            </Checkbox>
          </Col>
        </Row>

        <BoxDragAndDrop beforeUpload={beforeUpload} handleAddFiles={handleAddFiles} />

        {handleClientOrInternal && (
          <Row className="modalMaterials_checks" style={{ width: '100%' }}>
            <Col md={6} className="row">
              <Checkbox
                checked={newData.is_client}
                onChange={e => handleChangeNewData({ field: 'is_client', value: e.target.checked })}>
                Client
              </Checkbox>
            </Col>
            <Col md={6} className="row">
              <Checkbox
                checked={newData.is_internal}
                onChange={e => handleChangeNewData({ field: 'is_internal', value: e.target.checked })}>
                Internal
              </Checkbox>
            </Col>
          </Row>
        )}

        {embeddedVideo && (
          <InputBox
            placeholder="Video"
            value={newData.video_url || ''}
            onChange={value => handleChangeNewData({ field: 'video_url', value })}
          />
        )}

        {listFiles.length > 0 && (
          <TableFileBoxDragAndDrop listFiles={listFiles} removeFile={removeFile} removeAllFiles={removeAllFiles} />
        )}
      </div>
      <Button type="primary" className="green-btn button" onClick={handleSave}>
        Save
      </Button>
      <Button className="button_simple" onClick={close}>
        cancel
      </Button>
    </Modal>
  );
};

export default ModalMaterialsComponent;
