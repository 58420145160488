import React, { useState } from 'react';
import { Row, Upload, Typography, Modal, Spin } from 'antd';
import { notifyError, notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import axios from 'axios';
import { getPresignedPublicUrlLogo } from 'utils/api';

const { Text } = Typography;

const FileComponent = ({ placeholder, label, value, onChange }) => {
  const [loading, setLoading] = useState(false);

  const uploadSignature = async file => {
    try {
      setLoading(true);
      const { url } = await API.getPresignUrlFile({
        name: file.name,
        type: file.type,
      });

      const formData = new FormData();
      for (const key in url.data.fields) {
        formData.append(key, url.data.fields[key]);
      }
      formData.append('file', file);

      await axios.post(url.data.url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      let s3key = url.fileUrl;
      const res = await getPresignedPublicUrlLogo(s3key);
      const signedUrl = res.url || res.data.url;

      onChange(signedUrl);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = async file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notifyError('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notifyError('Image must smaller than 2MB!');
    }
    await uploadSignature(file);
    return false;
  };

  return (
    <div className="listInputsLCP_file">
      <Modal visible={loading} footer={null} className="modal-loader">
        <Spin />
      </Modal>

      <Row className="label">
        <Text>{label}</Text>
      </Row>
      <Row type="flex" justify="center" align="middle" className="signatureReport">
        <Upload
          name="logo"
          listType="text"
          className="logo-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}>
          {value ? (
            <img src={value} alt="signature" className="signature-image" />
          ) : (
            <Text className="logo-button">{placeholder || label}</Text>
          )}
        </Upload>
      </Row>
    </div>
  );
};

export default FileComponent;
