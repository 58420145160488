const initialState = {
  show: false,
  showChat: false,

  data: {},
};
const DrawerActions = (state = initialState, action) => {

  switch (action.type) {
    case "SHOW":
      return {...state, show: true};

    case "HIDE":
      return {...state, show: false};
    case "DATA":
      return {...state, data: action.value};

    case "SHOW_CHAT":
      return {...state, showChat: true};
    case "HIDE_CHAT":
      return {...state, showChat: false};

    default:
      return state;
  }
};

export default DrawerActions;
