export const getPosition = (arr, type) => {
  let filteredItems = arr.filter((item) => item.type === type);
  let max = 0;
  filteredItems.forEach((item) => {
    if (item.position > max) {
      max = item.position;
    }
  });
  return max + 1;
};

export const get2NewPositions = (stateArray, id, direction) => {
  let ItemMoving = stateArray.filter((item) => item.id === id);

  if (ItemMoving.length < 1) {
    return { success: false, newArr: [], update1: {}, update2: {} };
  }
  let TotalItems = stateArray
    .filter((item) => item.type === ItemMoving[0].type)
    .sort((a, b) => a.position - b.position);

  let ModifiedArray = [...stateArray];
  let Item2;
  TotalItems.forEach((totalItem, index) => {
    if (totalItem.id === id) {
      if (direction === "up" && index !== 0) {
        Item2 = TotalItems[index - 1];
      }
      if (direction === "down" && index !== TotalItems.length - 1) {
        Item2 = TotalItems[index + 1];
      }
    }
  });
  if (!Item2) {
    return { success: false };
  }

  let item = document.getElementById("table-item-" + Item2.id + Item2.position);
  const animate = "animate-"
  const down = "down"
  const up = "up"
  if (direction === "up") {
    item.classList.add(`${animate}${down}`);
  } else {
    item.classList.add(`${animate}${up}`);
  }
  let item1 = document.getElementById("table-item-" + id + ItemMoving[0].position);

  item1.classList.add("animate-" + direction);
  ModifiedArray = ModifiedArray.map((item) => {
    if (item.id === id) {
      const position =
        item.position === Item2.position
          && direction === 'up'
          ? Item2.position++
          : Item2.position;
      return { ...item, position: position };
    }
    if (item.id === Item2.id) {
      const position =
        item.position === ItemMoving[0].position
          && direction === 'down'
          ? ItemMoving[0].position++
          : ItemMoving[0].position;
      return {
        ...item,
        position: position,
      };
    } else {
      return item;
    }
  });

  return {
    success: true,
    newArr: ModifiedArray,
    update1: { id, position: Item2.position },
    update2: { id: Item2.id, position: ItemMoving[0].position },
  };
};
//   let filterItems = stateArray.filter)
