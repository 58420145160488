import React from 'react';
import { Row, Col } from 'antd';

import {
  COLUMNS_TABLE_LIFE_CARE_PLAN,
  COLUMNS_TABLE_LIFE_CARE_PLAN_MEDICAL_COST,
  COLUMNS_TABLE_LIFE_CARE_PLAN_PRESCRIPTION,
} from 'containers/Templates/TemplateLifeCarePlan/TemplateLifeCarePlanTable/columns';
import {
  TABLE_MEDICAL_COSTS_NAME,
  TABLE_PRESCRIPTION_NAME,
  TABLE_SURGERIES_NAME,
} from 'containers/Templates/TemplateLifeCarePlan/const/itemsTableDragable';

const LCPMedicareCostTableHeader = ({ structure, className }) => {
  let LIST_ITEMS = COLUMNS_TABLE_LIFE_CARE_PLAN;

  if ([TABLE_MEDICAL_COSTS_NAME, TABLE_SURGERIES_NAME].includes(structure.name))
    LIST_ITEMS = COLUMNS_TABLE_LIFE_CARE_PLAN_MEDICAL_COST;

  if (TABLE_PRESCRIPTION_NAME === structure.name) LIST_ITEMS = COLUMNS_TABLE_LIFE_CARE_PLAN_PRESCRIPTION;

  return (
    <Row type="flex" align="middle" className={`table-header ${className ? className : ''}`}>
      {LIST_ITEMS.map(item => (
        <React.Fragment key={item.id}>
          {structure[item.field] === 1 && (
            <>
              {item?.children ? (
                <Col xs={item.col} className={`border-right ${className ? `${className}_child` : ''}`}>
                  <Row className="sub-group-header">
                    <Col xs={24}>{item.name}</Col>
                  </Row>
                  <Row type="flex" align="middle" className="sub-header border-top">
                    {item.children.map(subItem => (
                      <Col xs={subItem.col} key={subItem.name} className="border-right center">
                        {subItem.name}
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : (
                <Col xs={item.col} className={`border-right center ${className ? `${className}_child` : ''}`}>
                  {item.name}
                </Col>
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </Row>
  );
};

export default LCPMedicareCostTableHeader;
