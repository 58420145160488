import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Row, Col, Typography, Input, Empty } from 'antd';

import SpinBox from 'components/SpinBox';
import { BackIcon, DetailsIcon } from 'components/Icons';
import Pagination from 'components/Pagination';
import { ATTORNEY_CLAIMANT_REPORT_NAV, CLAIMANT_DETAIL_NAV } from 'layout/navigate_const';
import * as API from 'utils/api';
import { notifyApiError } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

import './AttorneyClaimants.scss';

const { Search } = Input;
const { Title, Text } = Typography;

const AttorneyClaimants = () => {
  const history = useHistory();
  const location = useLocation();
  const clientInfo = location.state.data;
  const { roles } = useSelector(state => state.auth);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [claimantsData, setClaimantsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async ({ searchQuery = '', pageIndex = 0 } = {}) => {
    setLoading(true);
    let index = pageIndex;

    searchQuery = encodeURIComponent(searchQuery.trim());

    try {
      const res = await API.getClaimantsData({ clientId: clientInfo.id, searchQuery, pageIndex: index });
      setClaimantsData(res.claimants);
      setTotalCount(res.totalCount);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = pageIndex => {
    setPageIndex(pageIndex, () => handleSearch({ searchQuery, pageIndex }));
  };

  const goReports = item => {
    if (isAllowed(roles, ROLES_DIVISIONS.EXTERNAL_LITIGATION_ATTORNEY))
      return history.push(`${CLAIMANT_DETAIL_NAV.path}?id_claimant=${item.id}`);
    
    history.push(ATTORNEY_CLAIMANT_REPORT_NAV.path, { data: item });
  };

  return (
    <Row className="contacts-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Reduction Claimants
          </Title>
          <Text>{clientInfo.client_name}</Text>
        </Col>
        <Col className="back-icon">
          <BackIcon className="icon" onClick={() => history.push('/app/clients')} />
        </Col>
        <Col className="search">
          <Search
            placeholder="Search..."
            className="search-box"
            onSearch={async value => {
              setSearchQuery(value);
              setPageIndex(0);
              handleSearch({ searchQuery: value });
            }}
          />
        </Col>
        <Col className="pagination">
          <Pagination onChange={handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
        </Col>
      </Row>
      <Row type="flex" className="table-header">
        <Col md={5}>Full Name</Col>
        <Col md={5}>Title</Col>
        <Col md={6}>Email</Col>
        <Col md={4}>Phone</Col>
        <Col md={4} style={{ textAlign: 'right' }}>
          Actions
        </Col>
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading}>
          {claimantsData.length > 0 ? (
            <Row>
              {claimantsData.map((item, index) => (
                <Row key={index} className="record">
                  <Col md={5}>
                    <Text className="textPointer" onClick={() => goReports(item)}>
                      {item.claimant_name + ' ' + item.claimant_middle_name + ' ' + item.claimant_last_name}
                    </Text>
                  </Col>
                  <Col md={5}>
                    <Text>{item.claimant_title}</Text>
                  </Col>
                  <Col md={6}>
                    <Text>{item.claimant_email}</Text>
                  </Col>
                  <Col md={4}>
                    <Text>{item.claimant_phone}</Text>
                  </Col>
                  <Col md={4} className="actions">
                    <DetailsIcon className="icon" onClick={() => goReports(item)} />
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Empty
              description={loading ? false : 'No Data'}
              image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
              className="empty-icon"
            />
          )}
        </SpinBox>
      </Row>
    </Row>
  );
};

export default AttorneyClaimants;
