import React, { useState } from 'react';
import { Button, Col, Input, Modal, Row, Typography } from 'antd';

import * as API from 'utils/api';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import Spinbox from 'components/SpinBox';

import './AddTemplate.scss';

export default function AddTemplateFields({
  isOpen,
  onCancel,
  report_id,
  setTemplateFields,
  templateFields,
  section_id,
  closeModal,
}) {
  const { Text } = Typography;
  const [displayName, setDisplayName] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    const data = {
      display_name: displayName,
      default_value: defaultValue,
      report_template_id: parseInt(report_id),
      report_section_id: section_id,
    };
    setLoading(true);
    API.createTemplateField(data).then(res => {
      setTemplateFields([...templateFields, res.template_fields]);
      setDisplayName('');
      setDefaultValue('');
      closeModal();
      setLoading(false);
    });
  };
  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      centered={true}
      closable={false}
      width={600}
      className="add-template-modal"
      title="Create template fields">
      <Row className="modal-body">
        <Spinbox loading={loading}>
          <Row type="flex" justify="start" align="middle" className="row">
            <Row className="template-label">
              <Text className="label">Display Name</Text>
            </Row>
            <Col className="input-col" md={24}>
              <Input
                placeholder="Display Name"
                className="input"
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
              />
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="row">
            <Row className="template-label">
              <Text className="label">Default Value</Text>
            </Row>
            <Col className="input-col" md={24}>
              {isOpen && (
                <RichTextEditor
                  modal={true}
                  placeholder="Default Value"
                  className="input"
                  value={defaultValue}
                  onChange={value => setDefaultValue(value)}
                />
              )}
            </Col>
          </Row>
          <Row type="flex" justify="center" className="template-btn-wrapper">
            <Button type="primary" className="green-btn button" onClick={onSubmit}>
              Create
            </Button>
          </Row>
        </Spinbox>
      </Row>
    </Modal>
  );
}
