import * as API from 'utils/api';

export const assignedTemplateInReportLCP = async ({ chooseTemplateId, reportId }) => {
  try {
    const template = await API.getTemplateLifeCarePlanByReportId({ idReport: chooseTemplateId });

    if (!template?.success) throw new Error();

    if (template.templateStructure?.template_structure.length === 0) throw new Error();

    if (template?.templateStructure?.id) delete template.templateStructure.id;

    const template_structure = template.templateStructure?.template_structure.map(item => {
      delete item.reportId;
      delete item.id;

      const template_fields = item.template_fields.map(field => {
        delete field.id;
        delete field.template_structure_id;

        return field;
      });

      return {
        ...item,
        template_fields,
      };
    });

    const report = await API.getReportById(reportId, true);

    const body = {
      template_id: chooseTemplateId,
      report: {
        id: report.id,
        report_name: report.report_name,
        is_template: report.is_template,
        report_type_id: report.report_type_id,
        report_status_id: report.report_status_id,
      },
      template_structure,
    };

    await API.assignTemplateInReportLCP({ body });
  } catch (e) {
    return false;
  }
};
