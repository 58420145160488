import React, { Component } from 'react';
import { Row, Col, Typography, Input, Empty, Button, Modal, Menu, Dropdown, Icon } from 'antd';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import DeleteModal from 'components/DeleteModal';
import SpinBox from 'components/SpinBox';
import { BackIcon, RemoveIcon, DetailsIcon, UserPlus } from 'components/Icons';
import * as API from 'utils/api';
import Pagination from 'components/Pagination';
import { notifyApiError, notifyInfo } from 'utils/notification';
import isAllowed, { EXTERNAL_LITIGATION_ATTORNEY_ID, ROLES_DIVISIONS } from 'layout/roles';
import { CLAIMANT_DETAIL_NAV, CLIENTS_NAV, PARENT_FOLDER_NAV } from 'layout/navigate_const';
import { CLAIMANT_PERMISSIONS_NAME, getPermissionsBySection, PARENT_FOLDER_NAME } from 'permissions';
import { ModalAssignUser } from 'components/Modal/ModalAssignUser';
import { ModalLoading } from 'components/Modal/ModalLoading';

import './Claimants.scss';

const { Search } = Input;
const { Title, Text } = Typography;

class Claimant extends Component {
  constructor(props) {
    super(props);

    let clientInfo = {};

    if (this.props.location && this.props.location.state) {
      clientInfo = this.props.location.state.data;
    }

    // Permissions
    const { CLAIMANT_PERMISSIONS, PARENT_FOLDER_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [CLAIMANT_PERMISSIONS_NAME, PARENT_FOLDER_NAME],
    });
    // end permissions

    const defaultState = {
      client_id: null,
      client_name: clientInfo?.client_name || '',
      pageIndex: 0,
      showModal: false,
      claimantsData: [],
      totalCount: 0,
      selectedClaimant: {},
      searchQuery: '',
      loadingTitle: 'Loading...',
      loading: false,
      CLAIMANT_PERMISSIONS,
      PARENT_FOLDER_PERMISSIONS,
      parentFolderId: null,
      openModalAssign: null,
      listLitigation: [],
    };

    if (this.props.match.params.id) {
      this.state = {
        ...defaultState,
        client_id: this.props.match.params.id,
      };
    } else if (this.props.location.state?.data.clientId) {
      this.state = {
        ...defaultState,
        client_id: this.props.location.state.data.clientId,
      };
    } else {
      this.state = {
        ...defaultState,
        client_id: clientInfo.id,
      };
    }

    this.handlePagination = this.handlePagination.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.goToClientAddPage = this.goToClientAddPage.bind(this);
    this.handleEditClaimant = this.handleEditClaimant.bind(this);
    this.handleDeleteClaimant = this.handleDeleteClaimant.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getInfoClient = this.getInfoClient.bind(this);
    this.goBack = this.goBack.bind(this);
    this.menu = this.menu.bind(this);
    this.handleOpenAssign = this.handleOpenAssign.bind(this);
    this.handleAssigUserToClaimant = this.handleAssigUserToClaimant.bind(this);
  }

  componentDidMount() {
    this.handleSearch();
    this.getInfoClient();
  }

  async getInfoClient() {
    try {
      const res = await API.getCLientForId({ id: this.state.client_id });

      if (res?.clients.length === 0) return;

      const client = res.clients[0];

      if (client?.client_folders.length === 0) return;

      this.setState({ parentFolderId: client.client_folders[0].id });
    } catch (e) {
      notifyApiError(e);
    }
  }

  handlePagination(pageIndex) {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
    });
  }

  handleOpenModal(item) {
    this.setState({
      showModal: true,
      selectedClaimant: item,
    });
  }

  goToClientAddPage() {
    const { client_id } = this.state;
    this.props.history.push('/app/claimants/add', { data: client_id });
  }

  handleEditClaimant(item) {
    localStorage.setItem('claimant', JSON.stringify(item));
    this.props.history.push(CLAIMANT_DETAIL_NAV.path, { data: item });
  }

  handleDeleteClaimant(item) {
    const params = {
      id: item.id,
    };

    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: 'Deleting...',
    });
    API.deleteClaimant(params)
      .then(() => {
        this.setState({ loading: false });
        Modal.success({
          content: 'Success',
          onOk: () => this.handleSearch(),
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        notifyApiError(error);
      });
  }

  async handleSearch() {
    let { client_id, searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: 'Loading...' });
    let index = pageIndex;
    searchQuery = encodeURIComponent(searchQuery.trim());
    if (client_id === this.props.roles.user_roles.id) {
      try {
        const res = await API.getClaimantsDataByUserId(client_id, searchQuery, index);
        this.setState({
          claimantsData: res.claimants,
          totalCount: res.total_count,
        });
      } catch (e) {
        notifyApiError(e);
      } finally {
        this.setState({ loading: false });
      }
    } else if (client_id) {
      try {
        let userId;

        // si eres nurse writer buscamos los claimants por el user id
        if (isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_ONLY)) {
          const getCurrentUser = await Auth.currentAuthenticatedUser();
          userId = getCurrentUser.attributes.sub;
        }

        const res = await API.getClaimantsData({ userId, clientId: client_id, searchQuery, pageIndex: index });
        this.setState({
          claimantsData: res.claimants,
          totalCount: res.total_count,
        });
      } catch (e) {
        notifyApiError(e);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      try {
        const res = await API.getAllClaimantsData(searchQuery, index);
        this.setState({
          claimantsData: res.claimants,
          totalCount: res.total_count,
        });
      } catch (e) {
        notifyApiError(e);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  goBack() {
    if (this.state.PARENT_FOLDER_PERMISSIONS.open) {
      if (this.state.parentFolderId) return this.props.history.push(`${CLIENTS_NAV.path}/${this.state.parentFolderId}`);

      return this.props.history.push(PARENT_FOLDER_NAV.path);
    }

    this.props.history.goBack();
  }

  async handleOpenAssign({ claimant }) {
    try {
      this.setState({ loading: true });

      if (this.state.listLitigation.length === 0) {
        const litigationAttorney = await API.getUsersByRoleId({ roleId: EXTERNAL_LITIGATION_ATTORNEY_ID });
        this.setState({ listLitigation: litigationAttorney.users });
      }

      this.setState({ openModalAssign: claimant });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleAssigUserToClaimant({ userId }) {
    try {
      this.setState({ loading: true });
      await API.assigUserToClaimant({ user_id: userId, claimant_id: this.state.openModalAssign });
      await this.handleSearch();
      this.setState({ openModalAssign: null });
      notifyInfo('The user was successfully assigned');
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  menu(item) {
    return (
      <Menu className="menuClaimant">
        {this.state.CLAIMANT_PERMISSIONS.assignLitigation && (
          <Menu.Item>
            <a onClick={() => this.handleOpenAssign({ claimant: item.id })} className="item-menu">
              <UserPlus className="icon iconUserPlus" />
              Assign
            </a>
          </Menu.Item>
        )}

        <Menu.Item>
          <a onClick={() => this.handleEditClaimant(item)} className="item-menu">
            <DetailsIcon className="icon" />
            Preview
          </a>
        </Menu.Item>

        {this.state.CLAIMANT_PERMISSIONS.delete && (
          <Menu.Item>
            <a onClick={() => this.handleOpenModal(item)} className="item-menu">
              <RemoveIcon className="icon" />
              Delete
            </a>
          </Menu.Item>
        )}
      </Menu>
    );
  }

  render() {
    const {
      pageIndex,
      totalCount,
      claimantsData,
      client_name,
      selectedClaimant,
      showModal,
      loading,
      loadingTitle,
      CLAIMANT_PERMISSIONS,
      openModalAssign,
      listLitigation,
    } = this.state;

    return (
      <>
        <Row className="contacts-container">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Claimant
              </Title>
              <Text>{client_name}</Text>
            </Col>
            {!isAllowed(this.props.roles, [...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.EXTERNAL_ONLY]) && (
              <Col className="back-icon">
                <BackIcon className="icon" onClick={this.goBack} />
              </Col>
            )}
            <Col className="search">
              <Search
                placeholder="Search..."
                className="search-box"
                onSearch={async value => {
                  await this.setState({ searchQuery: value, pageIndex: 0 });
                  this.handleSearch();
                }}
              />
            </Col>
            <Col className="pagination">
              <Pagination
                onChange={this.handlePagination}
                totalCount={totalCount}
                pageIndex={pageIndex}
                pageSize={10}
              />
            </Col>

            {CLAIMANT_PERMISSIONS.create && (
              <Col className="add-button">
                <Button type="primary" className="green-btn" onClick={this.goToClientAddPage}>
                  Add New Claimant
                </Button>
              </Col>
            )}
          </Row>

          <Row type="flex" className="table-header">
            <Col md={4}>Claimant Name</Col>
            <Col md={5}>Claimant Email</Col>
            <Col md={4}>Claimant Phone</Col>
            <Col md={5}>Requested by</Col>
            <Col md={5}>Litigation Attorney</Col>
            <Col md={1} style={{ textAlign: 'right' }}>
              Actions
            </Col>
          </Row>
          <Row className="table-content">
            <SpinBox loading={loading} title={loadingTitle}>
              {claimantsData.length > 0 ? (
                <Row>
                  {claimantsData.map((item, index) => (
                    <Row key={index} className="record">
                      {/* Claimant Name */}
                      <Col md={4}>
                        <Text data-test-id="claimant_name" onClick={() => this.handleEditClaimant(item)} className="textPointer">
                          {item.claimant_name + ' ' + item.claimant_middle_name + ' ' + item.claimant_last_name}
                        </Text>
                      </Col>

                      {/* Claimant Email */}
                      <Col md={5}>
                        <Text>{item.claimant_email}</Text>
                      </Col>

                      {/* Claimant Phone */}
                      <Col md={4}>
                        <Text>{item.claimant_phone}</Text>
                      </Col>

                      {/* Requested By */}
                      <Col md={5}>
                        <Text>
                          {item?.info_user_created_by?.given_name || ''} {item?.info_user_created_by?.family_name || ''}{' '}
                          <br /> <span className="textSmall">{item?.info_user_created_by?.email || ''}</span>
                        </Text>
                      </Col>

                      {/* Litigation Attorney */}
                      <Col md={5}>
                        <Text>
                          {item?.info_litigation_attorney?.given_name || ''}{' '}
                          {item?.info_litigation_attorney?.family_name || ''}
                          <br /> <span className="textSmall">{item?.info_litigation_attorney?.email || ''}</span>
                        </Text>
                      </Col>

                      {/* Actions */}
                      {CLAIMANT_PERMISSIONS.view && (
                        <Col md={1} className="actions">
                          <Dropdown overlay={() => this.menu(item)} placement="bottomRight" trigger={['click']}>
                            <Icon type="ellipsis" className="dots-icon" />
                          </Dropdown>
                        </Col>
                      )}
                    </Row>
                  ))}
                </Row>
              ) : (
                <Empty
                  description={loading ? false : 'No Data'}
                  image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                  className="empty-icon"
                />
              )}
            </SpinBox>
          </Row>
          <DeleteModal
            isOpen={showModal}
            title="Delete this Claimant?"
            content={selectedClaimant.claimant_title}
            onConfirm={() => this.handleDeleteClaimant(selectedClaimant)}
            onCancel={this.handleCloseModal}
          />
        </Row>

        {openModalAssign !== null && (
          <ModalAssignUser
            title="Assign External Litigation Attorney"
            onClose={() => this.setState({ openModalAssign: null })}
            listUser={listLitigation}
            onAssign={this.handleAssigUserToClaimant}
          />
        )}

        {loading && <ModalLoading />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, null)(Claimant);
