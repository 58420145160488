import React from 'react';
import { Modal, Typography, Button } from 'antd';
import './modalMessage.scss';
import { CheckIcon } from 'components/Svg';

const { Title, Text } = Typography;

const ModalMessage = ({ onClose, message, title, textButton = 'Continue', noneIcon, onConfirm }) => (
  <Modal visible={true} onCancel={onClose} footer={null} centered={true} closable={true} width={600}>
    <div className="modalMessage">
      {!noneIcon && (
        <div className="modalMessage_icon">
          <CheckIcon />
        </div>
      )}
      <Title level={3}>{title}</Title>
      {message && <Text>{message}</Text>}
      <Button type="primary" size="large" className="m-2 green-btn button btn-normal" onClick={onConfirm || onClose}>
        {textButton}
      </Button>
    </div>
  </Modal>
);

export default ModalMessage;
