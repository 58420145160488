export const EARLY_MESSAGE = 'After medical records are broken down must be 100 pages or less';
export const MCP_MESSAGE = 'This product is for cases with 125 medical pages or less';

export const REPORT_LEGAL_DEMAND_NAME = 'Mini Cost Projection';
export const REPORT_EARLY_INTERVENTION_NAME = 'Early Intervention';
export const REPORT_LIFE_CARE_PLAN_NAME = 'Life Care Plan';
export const REPORT_SURGICAL_NAME = 'Surgical Therapeutic Projections';
export const REPORT_MSA_NAME = 'MSA';
export const REPORT_MCP_NAME = 'MCP';
export const REPORT_MCP_MSA_COMBO_NAME = 'MCP/MSA';
export const REPORT_MSA_REDUCTION_NAME = 'MSA Reduction';
export const REPORT_MSA_LETTER_NAME = 'MSA Letter';
export const REPORT_DEMAND_LETTER_NAME = 'Demand Letter / AFFIDAVIT Response'
export const REPORT_DEMAND_LETTER_PROJECTION_NAME = 'Demand Letter / AFFIDAVIT Response (Projections)'

export const REPORT_MSA = 'msa';
export const REPORT_MCP = 'mcp';
export const REPORT_SURGICAL = 'surgical';
export const REPORT_DEMAND_LETTER = 'demand_letter';
export const REPORT_DEMAND_LETTER_PROJECTIONS = 'demand_letter_projections';
export const REPORT_BI_DEMAND_1 = 'bi_demand_1';
export const REPORT_BI_DEMAND_2 = 'bi_demand_2';
export const REPORT_EARLY_INTERVENTION = 'early_intervention';
export const REPORT_MCP_MSA = 'mcp_msa';
export const REPORT_LEGAL_DEMAND_TEMPLATE = 'legal_demand_template';
export const REPORT_MSA_REDUCTION = 'msa_reduction';
export const REPORT_REVISION = 'Revision';
export const REPORT_LIFE_CARE_PLAN = 'life_care_plan';
export const REPORT_MSA_LETTER = 'msa_letter';

export const REPORT_MSA_TYPE_ID = 1;
export const REPORT_MCP_TYPE_ID = 3;
export const REPORT_SURGICAL_TYPE_ID = 4;
export const REPORT_DEMAND_LETTER_TYPE_ID = 5;
export const REPORT_BI_DEMAND_1_TYPE_ID = 6;
export const REPORT_BI_DEMAND_2_TYPE_ID = 7;
export const REPORT_EARLY_INTERVENTION_TYPE_ID = 8;
export const REPORT_MCP_MSA_TYPE_ID = 9;
export const REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID = 10;
export const REPORT_MSA_REDUCTION_TYPE_ID = 11;
export const REPORT_REVISION_TYPE_ID = 12;
export const REPORT_LIFE_CARE_PLAN_TYPE_ID = 13;
export const REPORT_MSA_LETTER_TYPE_ID = 15; 
export const REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID = 14;

export const REPORT_TYPE_NAME_BY_ID = {
  [REPORT_MSA_TYPE_ID]: REPORT_MSA_NAME,
  [REPORT_MCP_TYPE_ID]: REPORT_MCP_NAME,
  [REPORT_SURGICAL_TYPE_ID]: REPORT_SURGICAL_NAME,
  [REPORT_EARLY_INTERVENTION_TYPE_ID]: REPORT_EARLY_INTERVENTION_NAME,
  [REPORT_MCP_MSA_TYPE_ID]: REPORT_MCP_MSA_COMBO_NAME,
  [REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID]: REPORT_LEGAL_DEMAND_NAME,
  [REPORT_MSA_REDUCTION_TYPE_ID]: REPORT_MSA_REDUCTION_NAME,
  [REPORT_MSA_LETTER_TYPE_ID]: REPORT_MSA_LETTER_NAME,
  [REPORT_LIFE_CARE_PLAN_TYPE_ID]: REPORT_LIFE_CARE_PLAN_NAME,
  [REPORT_DEMAND_LETTER_TYPE_ID]: REPORT_DEMAND_LETTER_NAME,
  [REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID]: REPORT_DEMAND_LETTER_PROJECTION_NAME,
};

export const REPORT_TYPE_FULL_NAME = [
  {
    id: REPORT_MSA_TYPE_ID,
    name: 'MSA Report',
  },
  {
    id: REPORT_MCP_TYPE_ID,
    name: 'Medical Cost Projection',
  },
  {
    id: REPORT_MCP_MSA_TYPE_ID,
    name: 'MSA/MCP Combo Report',
  },
  {
    id: REPORT_EARLY_INTERVENTION_TYPE_ID,
    name: 'EIR',
  },
  {
    id: REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
    name: 'Mini Projection',
  },
  {
    id: REPORT_LIFE_CARE_PLAN_TYPE_ID,
    name: 'Life Care Plan Evidenced Base or Day in the Life',
  },
  {
    id: REPORT_DEMAND_LETTER_TYPE_ID,
    name: REPORT_DEMAND_LETTER_NAME,
  },
  {
    id: REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID,
    name: REPORT_DEMAND_LETTER_PROJECTION_NAME,
  },
];

export const ADDITIONAL_TYPE_FULL_NAME = [
  {
    id: REPORT_REVISION_TYPE_ID,
    name: 'Revision',
  },
  {
    id: REPORT_MSA_REDUCTION_TYPE_ID,
    name: 'Reduction',
  },
];

export const REPORT_TYPES_BY_ID = {
  [REPORT_MSA_TYPE_ID]: REPORT_MSA,
  [REPORT_MCP_TYPE_ID]: REPORT_MCP,
  [REPORT_SURGICAL_TYPE_ID]: REPORT_SURGICAL,
  [REPORT_DEMAND_LETTER_TYPE_ID]: REPORT_DEMAND_LETTER,
  [REPORT_BI_DEMAND_1_TYPE_ID]: REPORT_BI_DEMAND_1,
  [REPORT_BI_DEMAND_2_TYPE_ID]: REPORT_BI_DEMAND_2,
  [REPORT_EARLY_INTERVENTION_TYPE_ID]: REPORT_EARLY_INTERVENTION,
  [REPORT_MCP_MSA_TYPE_ID]: REPORT_MCP_MSA,
  [REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID]: REPORT_LEGAL_DEMAND_TEMPLATE,
  [REPORT_MSA_REDUCTION_TYPE_ID]: REPORT_MSA_REDUCTION,
  [REPORT_MSA_LETTER_TYPE_ID]: REPORT_MSA_LETTER,
  [REPORT_LIFE_CARE_PLAN_TYPE_ID]: REPORT_LIFE_CARE_PLAN,
  [REPORT_REVISION_TYPE_ID]: REPORT_REVISION,
  [REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID]: REPORT_DEMAND_LETTER_PROJECTIONS,
};

export const REPORT_SECTION_DEMOGRAPHIC = 'demographic';
export const REPORT_SECTION_MEDICALCONTENT = 'medicalContent';
export const REPORT_SECTION_MEDICARECOST = 'medicareCost';
export const REPORT_SECTION_MEDICARETOTALS = 'medicareTotals';
export const REPORT_SECTION_NONMEDICARECOST = 'nonMedicareCost';
export const REPORT_SECTION_NONMEDICARETOTALS = 'nonMedicareTotals';
export const REPORT_SECTION_SUBMITCOVERLETTER = 'submitCoverLetter';
export const REPORT_SECTION_INTRO = 'intro';
export const REPORT_SECTION_INJURYINFO = 'injuryInfo';
export const REPORT_SECTION_SETTLEMENTINFO = 'settlementInfo';
export const REPORT_SECTION_PROPOSEDMEDICARE = 'proposedMedicare';
export const REPORT_SECTION_ANNUITYINFO = 'annuityInfo';
export const REPORT_SECTION_CALCULATIONMETHOD = 'calculationMethod';
export const REPORT_SECTION_FUTUREPRESCRIPTION = 'futurePrescription';
export const REPORT_SECTION_CALCULATIONTABLE = 'calculationTable';
export const REPORT_SECTION_CONTACTINFO = 'contactInfo';
export const REPORT_SECTION_DOCUMENTUPLOAD = 'documentUpload';
export const REPORT_SECTION_MEDICALREDUCTION = 'medicalReduction';
export const REPORT_SECTION_LETTER = 'letter';
export const REPORT_SECTION_DESIGN = 'design';
export const REPORT_SECTION_INTRODUCTION = 'introduction';

export const REPORT_SECTIONS_DEFAULT = {
  [REPORT_MSA]: [
    REPORT_SECTION_DEMOGRAPHIC,
    REPORT_SECTION_MEDICALCONTENT,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_NONMEDICARECOST,
    REPORT_SECTION_NONMEDICARETOTALS,
    REPORT_SECTION_DESIGN,
    REPORT_SECTION_SUBMITCOVERLETTER,
    REPORT_SECTION_INTRO,
    REPORT_SECTION_INJURYINFO,
    REPORT_SECTION_SETTLEMENTINFO,
    REPORT_SECTION_PROPOSEDMEDICARE,
    REPORT_SECTION_ANNUITYINFO,
    REPORT_SECTION_CALCULATIONMETHOD,
    REPORT_SECTION_FUTUREPRESCRIPTION,
    REPORT_SECTION_CALCULATIONTABLE,
    REPORT_SECTION_CONTACTINFO,
    REPORT_SECTION_DOCUMENTUPLOAD,
  ],
  [REPORT_MCP]: [
    REPORT_SECTION_DEMOGRAPHIC,
    REPORT_SECTION_MEDICALCONTENT,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DOCUMENTUPLOAD,
    REPORT_SECTION_DESIGN,
  ],
  [REPORT_SURGICAL]: [
    REPORT_SECTION_MEDICALCONTENT,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DOCUMENTUPLOAD,
    REPORT_SECTION_DESIGN,
  ],
  [REPORT_EARLY_INTERVENTION]: [REPORT_SECTION_MEDICALCONTENT, REPORT_SECTION_DOCUMENTUPLOAD, REPORT_SECTION_DESIGN],
  [REPORT_LEGAL_DEMAND_TEMPLATE]: [
    REPORT_SECTION_MEDICALCONTENT,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DOCUMENTUPLOAD,
    REPORT_SECTION_DESIGN,
  ],
  [REPORT_MCP_MSA]: [
    `${REPORT_SECTION_DEMOGRAPHIC}MCP`,
    `${REPORT_SECTION_MEDICALCONTENT}MCP`,
    `${REPORT_SECTION_MEDICARECOST}MCP`,
    `${REPORT_SECTION_MEDICARETOTALS}MCP`,
    `${REPORT_SECTION_DOCUMENTUPLOAD}MCP`,
    `${REPORT_SECTION_DEMOGRAPHIC}MSA`,
    `${REPORT_SECTION_MEDICALCONTENT}MSA`,
    `${REPORT_SECTION_MEDICARECOST}MSA`,
    `${REPORT_SECTION_MEDICARETOTALS}MSA`,
    `${REPORT_SECTION_NONMEDICARECOST}MSA`,
    `${REPORT_SECTION_NONMEDICARETOTALS}MSA`,
    `${REPORT_SECTION_SUBMITCOVERLETTER}MSA`,
    `${REPORT_SECTION_INTRO}MSA`,
    `${REPORT_SECTION_INJURYINFO}MSA`,
    `${REPORT_SECTION_SETTLEMENTINFO}MSA`,
    `${REPORT_SECTION_PROPOSEDMEDICARE}MSA`,
    `${REPORT_SECTION_ANNUITYINFO}MSA`,
    `${REPORT_SECTION_CALCULATIONMETHOD}MSA`,
    `${REPORT_SECTION_FUTUREPRESCRIPTION}MSA`,
    `${REPORT_SECTION_CALCULATIONTABLE}MSA`,
    `${REPORT_SECTION_CONTACTINFO}MSA`,
    `${REPORT_SECTION_DOCUMENTUPLOAD}MSA`,
    REPORT_SECTION_DESIGN,
  ],
  [REPORT_MSA_REDUCTION]: [
    REPORT_SECTION_MEDICALREDUCTION,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DOCUMENTUPLOAD,
  ],
  [REPORT_MSA_LETTER]: [REPORT_SECTION_LETTER],
  [REPORT_LIFE_CARE_PLAN]: [
    REPORT_SECTION_DEMOGRAPHIC,
    REPORT_SECTION_INTRODUCTION,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DESIGN,
  ],
  [REPORT_REVISION]: [REPORT_SECTION_DEMOGRAPHIC],
  [REPORT_DEMAND_LETTER]: [
    REPORT_SECTION_DEMOGRAPHIC,
    REPORT_SECTION_DOCUMENTUPLOAD,
    REPORT_SECTION_DESIGN,
  ],
  [REPORT_BI_DEMAND_1]: [
    REPORT_SECTION_DEMOGRAPHIC,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DOCUMENTUPLOAD,
  ],
  [REPORT_BI_DEMAND_2]: [
    REPORT_SECTION_DEMOGRAPHIC,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DOCUMENTUPLOAD,
  ],
  [REPORT_DEMAND_LETTER_PROJECTIONS]: [
    REPORT_SECTION_DEMOGRAPHIC,
    REPORT_SECTION_MEDICARECOST,
    REPORT_SECTION_MEDICARETOTALS,
    REPORT_SECTION_DOCUMENTUPLOAD,
    REPORT_SECTION_DESIGN,
  ]
};

export const REPORT_SECTIONS_DEFAULT_CLIENT = {
  [REPORT_MSA_REDUCTION]: [REPORT_SECTION_MEDICALREDUCTION, REPORT_SECTION_DOCUMENTUPLOAD],
};

export const REPORT_SECTION_NAMES = {
  [REPORT_SECTION_DEMOGRAPHIC]: 'Demographic Information',
  [REPORT_SECTION_MEDICALCONTENT]: 'Medical Content',
  [REPORT_SECTION_MEDICARECOST]: 'Medicare Cost Projection',
  [REPORT_SECTION_MEDICARETOTALS]: 'Totals and Allocations',
  [REPORT_SECTION_NONMEDICARECOST]: 'Non-Medicare Cost Projection',
  [REPORT_SECTION_NONMEDICARETOTALS]: 'Totals and Allocations (Non Medicare)',
  [REPORT_SECTION_SUBMITCOVERLETTER]: 'Submitter Cover Letter',
  [REPORT_SECTION_INTRO]: 'Introduction',
  [REPORT_SECTION_INJURYINFO]: 'Injury Information',
  [REPORT_SECTION_SETTLEMENTINFO]: 'Settlement Information',
  [REPORT_SECTION_PROPOSEDMEDICARE]: 'Proposed Medicare Set-aside Amount',
  [REPORT_SECTION_ANNUITYINFO]: 'Annuity Information',
  [REPORT_SECTION_CALCULATIONMETHOD]: 'MSA Calculation Method',
  [REPORT_SECTION_FUTUREPRESCRIPTION]: 'Future Prescription Drug Treatment Calculation Method',
  [REPORT_SECTION_CALCULATIONTABLE]: 'MSA Calculation Table',
  [REPORT_SECTION_CONTACTINFO]: 'Contact Information',
  [REPORT_SECTION_DOCUMENTUPLOAD]: 'Document Upload',
  [REPORT_SECTION_MEDICALREDUCTION]: 'Medicare Set-Aside Reduction Worksheet',
  [REPORT_SECTION_DESIGN]: 'Design Report',
  [REPORT_SECTION_INTRODUCTION]: 'Introduction',

  [`${REPORT_SECTION_DEMOGRAPHIC}MCP`]: 'MCP Demographic Information',
  [`${REPORT_SECTION_MEDICALCONTENT}MCP`]: 'MCP Medical Content',
  [`${REPORT_SECTION_MEDICARECOST}MCP`]: 'MCP Medicare Cost Projection',
  [`${REPORT_SECTION_MEDICARETOTALS}MCP`]: 'MCP Totals and Allocations',
  [`${REPORT_SECTION_DOCUMENTUPLOAD}MCP`]: 'MCP Document Upload',
  [`${REPORT_SECTION_DEMOGRAPHIC}MSA`]: 'MSA Demographic Information',
  [`${REPORT_SECTION_MEDICALCONTENT}MSA`]: 'MSA Medical Content',
  [`${REPORT_SECTION_MEDICARECOST}MSA`]: 'MSA Medicare Cost Projection',
  [`${REPORT_SECTION_MEDICARETOTALS}MSA`]: 'MSA Totals and Allocations',
  [`${REPORT_SECTION_NONMEDICARECOST}MSA`]: 'MSA Non-Medicare Cost Projection',
  [`${REPORT_SECTION_NONMEDICARETOTALS}MSA`]: 'MSA Totals and Allocations (Non Medicare)',
  [`${REPORT_SECTION_SUBMITCOVERLETTER}MSA`]: 'MSA Submitter Cover Letter',
  [`${REPORT_SECTION_INTRO}MSA`]: 'MSA Introduction',
  [`${REPORT_SECTION_INJURYINFO}MSA`]: 'MSA Injury Information',
  [`${REPORT_SECTION_SETTLEMENTINFO}MSA`]: 'MSA Settlement Information',
  [`${REPORT_SECTION_PROPOSEDMEDICARE}MSA`]: 'MSA Proposed Medicare Set-aside Amount',
  [`${REPORT_SECTION_ANNUITYINFO}MSA`]: 'MSA Annuity Information',
  [`${REPORT_SECTION_CALCULATIONMETHOD}MSA`]: 'MSA Calculation Method',
  [`${REPORT_SECTION_FUTUREPRESCRIPTION}MSA`]: 'MSA Future Prescription Drug Treatment Calculation Method',
  [`${REPORT_SECTION_CALCULATIONTABLE}MSA`]: 'MSA Calculation Table',
  [`${REPORT_SECTION_CONTACTINFO}MSA`]: 'MSA Contact Information',
  [`${REPORT_SECTION_DOCUMENTUPLOAD}MSA`]: 'MSA Document Upload',
};

export const REPORT_URL_BY_REPORT_ID = {
  [REPORT_MSA_TYPE_ID]: REPORT_MSA,
  [REPORT_MCP_TYPE_ID]: REPORT_MCP,
  [REPORT_SURGICAL_TYPE_ID]: REPORT_SURGICAL,
  [REPORT_EARLY_INTERVENTION_TYPE_ID]: REPORT_EARLY_INTERVENTION,
  [REPORT_MCP_MSA_TYPE_ID]: REPORT_MCP_MSA,
  [REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID]: REPORT_LEGAL_DEMAND_TEMPLATE,
  [REPORT_LIFE_CARE_PLAN_TYPE_ID]: REPORT_LIFE_CARE_PLAN,
  [REPORT_DEMAND_LETTER_TYPE_ID]: REPORT_DEMAND_LETTER,
  [REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID]: REPORT_DEMAND_LETTER_PROJECTIONS,

};

export const IGNORE_FIELD_REPORT_SELECT_TEMPLATE_AND_COPY = [
  'logo',
  'logo_name',
  'address',
  'phone',
  'color',
  'id',
  'report_name',
  'createdBy',
  'updatedBy',
  'deletedBy',
  'tenant_id',
  'claimant_id',
  'nurse_id',
  'signature',
  'is_signature_photo',
  'report_uuid',
  'is_template',
  'report_template_id',
  'required_qa',
  'qaRevisionDate',
  'is_private',
  'needed_by',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'claimant',
  'created_by',
  'nurse',
  'report_type',
  'report_status',
  'report_claim_id',
  'report_type_id',
  'report_status_id',
  'owner_ids',
  'pricing_group_id',
  'settlement_type',
  'msa_calculation_method',
  'md_tbd',
  'sections',
  'settlement_net',
  '_medicare_costs',
  'zip_url',
  'juridiction_state',
  'juridiction_state_id',
  'related_diagnosis',
];

export const PRIORITIZE_FIELD_SELECT_TEMPLATE_AND_COPY = [
  'referral_date', // date
  'date_of_injury', // date
  'date_of_report', // date
  'interest_rate', // number
  'pre_settlement', // boolean
  'post_settlement', // boolean
  'show_additional_comments', //boolean,
  'age_at_proposed_settlement_date', // number
  'annuity_payment_over_remaining_expectancy_at_starting_date', // number
  'annuity_start_date', // date
  'life_expectancy', // number,
  'proposed_amount_for_future_from_medicare', // number
  'proposed_settlement_date', // date
  'releases_attached', // boolean
  'seed_money_or_initial_deposit', // number
  'settlement_amount', // number
  'show_additional_comments', // boolean
  'total_allocation_minus_first_surgery_procedure_replacement', // number
];
