import { sortArrayObjectForKey } from 'utils/utils';

export const orderMarketingSection = ({ list }) => {
  // sacamos lo que tengan order null para dejarlos a lo ultimo
  const listNull = list.filter(item => item.order === null);

  // sacamos los que si tengan order para ordenarlos
  const listOrder = list.filter(item => item.order !== null);

  // ordenamos
  const resultOrder = sortArrayObjectForKey({ array: listOrder, key: 'order', type: 'number' });

  return [...resultOrder, ...listNull];
};
