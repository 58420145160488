import React, { useState } from 'react';
import { Button, Icon, Select } from 'antd';

import { Tooltip } from 'components/Tooltip';

// Style
import './stepAlaCarta.scss';
import { LIST_COUNT_TYPE_REVIEWER, LIST_TYPE_USER_BY_A_LA_CARTE } from 'constants/contact-types';
import { ExclamationCircleIcon } from 'components/Svg';

const { Option } = Select;

const StepAlaCarta = ({ data, onSubmit, prevStep }) => {
  const [selectCount, setSelectCount] = useState(data?.selectCount || '');
  const [selectQuickbook, setSelectQuickbook] = useState(data?.selectQuickbook || '');

  const handleSubmit = async () => {
    onSubmit({
      sectionStep: 'a_la_carte',
      payload: {
        selectCount,
        selectQuickbook,
      },
      nextStep: 5,
    });
  };

  return (
    <div className="stepContainer stepContainerNewTenant">
      <div className="stepTitleContainer">
        <p className="stepText">Step 4</p>
        <h1 className="stepTitle">A la Carte Options</h1>
      </div>

      <div className="alacarte">
        <div className="alacarte_item">
          <p>
            These are the types of reviewers that we offer. Please select the number of profiles you would like to add,
            later on you will be able to let us know which ones you want to use specifically.
          </p>

          <div className="alacarte_list_user">
            {LIST_TYPE_USER_BY_A_LA_CARTE.map(item => (
              <div>
                <div className="alacarte_item_toltip">
                  <p className="alacarte_item_toltip_type">{item.name}</p>
                  <div key={item.name}>
                    <Tooltip
                      id={item.name}
                      content={
                        <div className="alacarte_toltip_content">
                          <p>{item.toltip}</p>
                        </div>
                      }>
                      <ExclamationCircleIcon />
                    </Tooltip>
                  </div>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>

          <div className="alacarte_select_count">
            <p>Number of users</p>
            <Select
              placeholder="Please select"
              optionFilterProp="children"
              className="alacarte_select_count_select"
              value={selectCount}
              onChange={value => setSelectCount(value)}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option value="">Select</Option>
              {LIST_COUNT_TYPE_REVIEWER.map(el => {
                return (
                  <Option key={el.value} value={el.value}>
                    {el.label}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>

        {/* otra parte */}
        <div className="alacarte_item">
          <div className="alacarte_list_user">
            <div>
              <div className="alacarte_item_toltip">
                <p className="alacarte_item_toltip_type">Set Up QuickBooks or Internal Invoicing</p>
                <div>
                  <Tooltip
                    id="{item.name}"
                    content={
                      <div className="alacarte_toltip_content">
                        <p>
                          We evaluated the extent to which your invoices reflect your organization's branding and convey
                          a professional image to clients. Our review focused on the design elements, layout, and
                          language used in your invoices
                        </p>
                      </div>
                    }>
                    <ExclamationCircleIcon />
                  </Tooltip>
                </div>
              </div>
              <p>Call for Quote</p>
            </div>

            <div className="alacarte_quickbook_select">
              <Select
                placeholder="Please select"
                optionFilterProp="children"
                className="alacarte_select_count_select"
                value={selectQuickbook}
                onChange={value => setSelectQuickbook(value)}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                <Option value="">Select</Option>
                {[true, false].map(el => {
                  return (
                    <Option key={el ? 'yes' : 'no'} value={el}>
                      {el ? 'Yes' : 'No'}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="add_new_tenant">
        <Button type="primary" size="default" className="transparent-btn button" onClick={prevStep}>
          PREVIOUS STEP
        </Button>
        <Button type="primary" size="default" className="stepNextButton" onClick={handleSubmit}>
          Next Step
        </Button>
      </div>
    </div>
  );
};

export default StepAlaCarta;
