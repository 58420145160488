import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Select, Icon, Typography } from 'antd';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Icons
import { TrashIcon } from 'components/Svg';

// Const
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { CLIENT_SUPER_ADMIN_ID } from 'layout/roles';

// Utils
import { getPermissionsBySection, PARENT_FOLDER_NAME } from 'permissions';

const { Title } = Typography;
const { Option } = Select;

const AssignAdminTab = () => {
  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { PARENT_FOLDER_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [PARENT_FOLDER_NAME],
  });
  // end permissions

  // State
  const [listAdminAssign, setListAdminAssign] = useState([]);
  const [listClientSuperAdmin, setListClientSuperAdmin] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addAdditionalUser, setAddAdditionalUser] = useState(false);

  // Const
  const selectClientSuperAdmin = listAdminAssign.length === 0 || addAdditionalUser;
  const { id } = useParams();

  const getAllClientSuperAdmin = async () => {
    try {
      setLoading(true);
      const res = await API.getUsersByRoleId({ roleId: CLIENT_SUPER_ADMIN_ID });
      setListClientSuperAdmin(res.users);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await API.getParentFolderById({ id, model: 'admin' });

        if (res.admins.length === 0) getAllClientSuperAdmin();

        setListAdminAssign(res.admins);
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // handle
  const selectedClientSuperAdmin = async ({ clientSuperAdminId }) => {
    try {
      setLoading(true);
      const getClientInfo = listClientSuperAdmin.find(client => client.id === clientSuperAdminId);

      if (addAdditionalUser) setAddAdditionalUser(false);

      if (listAdminAssign.some(clientSuperAdmin => clientSuperAdmin.id === getClientInfo.id)) return;

      // add
      await API.updateParentFolder({
        id,
        body: {
          add: {
            user_id: getClientInfo.id,
          },
        },
      });

      setListAdminAssign(prevState => [...prevState, getClientInfo]);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const addNewClientSuperAdmin = async () => {
    if (listClientSuperAdmin.length === 0) await getAllClientSuperAdmin();
    setAddAdditionalUser(true);
  };

  const handleRemoveSubFolder = async ({ clientSuperAdminId }) => {
    try {
      setLoading(true);
      await API.updateParentFolder({
        id,
        body: {
          remove: {
            user_id: clientSuperAdminId,
          },
        },
      });
      const updateList = listAdminAssign.filter(clientSuperAdmin => clientSuperAdmin.id !== clientSuperAdminId);

      if (updateList.length === 0 && listClientSuperAdmin.length === 0) await getAllClientSuperAdmin();

      setListAdminAssign(updateList);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="assignAdminTab">
      <div className="createParentFolder_addFolder">
        {selectClientSuperAdmin && PARENT_FOLDER_PERMISSIONS.assignClientSuperAdmin ? (
          <>
            <Title level={4}>Search Admin</Title>
            <Select
              getPopupContainer={triggerNode => triggerNode.parentNode}
              showSearch
              optionFilterProp="children"
              className="dropdown addClaimant_select"
              value=""
              onChange={id => selectedClientSuperAdmin({ clientSuperAdminId: id })}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option disabled value={''}>
                Type user name
              </Option>
              {listClientSuperAdmin.map(user => (
                <Option value={user.id} key={user.id}>
                  {user.given_name} {user.family_name}
                </Option>
              ))}
            </Select>

            {listAdminAssign.length > 0 && (
              <button className="cancelContactButton mt-3" onClick={() => setAddAdditionalUser(false)}>
                cancel
              </button>
            )}
          </>
        ) : (
          <div className="listCard_createParentFolder">
            {listAdminAssign.map(item => (
              <div key={item.id} className="stepContainerContact">
                <div className="stepContactItem listCard_createParentFolder_name">
                  <h2>
                    {item.given_name || ''} {item?.family_name || ''}
                  </h2>
                </div>

                {PARENT_FOLDER_PERMISSIONS.deleteClientSuperAdmin && (
                  <div className="stepContactItemButtons listCard_createParentFolder_btn">
                    <TrashIcon
                      color={PRIMARY_LIGHT_BLUE}
                      onClick={() => handleRemoveSubFolder({ clientSuperAdminId: item.id })}
                    />
                  </div>
                )}
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">User Type</p>
                  <p className="stepContactItemValue">Client Super Admin</p>
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">Email</p>
                  <p className="stepContactItemValue">{item?.email}</p>
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">Phone</p>
                  <p className="stepContactItemValue">{item?.locale || ''}</p>
                </div>
              </div>
            ))}

            {PARENT_FOLDER_PERMISSIONS.assignClientSuperAdmin && (
              <button className="addAdditionalContactButton margin-button" onClick={addNewClientSuperAdmin}>
                Assign New Admin
              </button>
            )}
          </div>
        )}
      </div>

      {loading && <ModalLoading />}
    </div>
  );
};

export default AssignAdminTab;
