import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Input, Button, Modal, Spin } from 'antd';
import { ModalPdf } from './components/ModalPdf';
import { DownloadOutlined } from '@ant-design/icons';
import { EditIcon, RemoveIcon } from 'components/Icons';
import { connect } from 'react-redux';
import { ModalManager } from './components/ModalManager';
import { ModalMaterials } from './components/ModalMaterials';
import * as API from 'utils/api';
import { orderMarketingSection } from './utils/orderMarketingSection';
import { getPermissionsBySection, MARKETING_PERMISSIONS_NAME } from 'permissions';

import './marketing.scss';

const { Title, Text } = Typography;
const { Search } = Input;

const Marketing = props => {
  const [selectPdf, setSelectPdf] = useState(null);
  const [listDocumentDefault, setListDocumentDefault] = useState([]);
  const [listDocument, setListDocument] = useState([]);
  const [modalManager, setModalManager] = useState(false);
  const [modalMaterial, setModalMaterial] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [listSection, setListSection] = useState([]);
  const [materialEdit, setMaterialEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  // Permissions
  const { MARKETING_PERMISSIONS } = getPermissionsBySection({
    roles: props.roles,
    permissions: [MARKETING_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    getSections();
    getDataComponent();
  }, []);

  const getSections = async () => {
    // buscando las secciones desde la API
    const getSections = await API.getMarketingSection();
    const getListSections = getSections.users;

    // ordenamos la lista
    const result = orderMarketingSection({ list: getListSections });

    setListSection(result);
  };

  const getDataComponent = async () => {
    const res = await API.getMarketing();
    setListDocumentDefault(res.marketing);
    setListDocument(res.marketing);
    setLoadingPage(false);
  };

  const handleSearch = value => {
    if (value) {
      setListDocument(listDocumentDefault.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
    } else {
      setListDocument(listDocumentDefault);
    }
  };

  const handleToggleManager = () => setModalManager(!modalManager);
  const handleToggleMaterial = () => setModalMaterial(!modalMaterial);

  const handleCloseModalManager = () => {
    getSections();
    handleToggleManager();
  };

  const handleCreateMaterial = () => {
    getDataComponent();
    handleToggleMaterial();
  };

  const handleDeleteMaterial = async ({ material }) => {
    await API.deleteMarketing({ id: material.id });
    getDataComponent();
  };

  const handleEditMaterial = async ({ material }) => {
    setMaterialEdit(material);
    handleToggleMaterial();
  };

  const getAllowedSectionWithFiles = () => {
    const ordenList = listSection.map(section => {
      return { section, docuemnts: listDocument.filter(file => file.section_id === section.id) };
    });

    if (!MARKETING_PERMISSIONS.viewAllDocument) {
      return ordenList.filter(item => item.docuemnts.length > 0);
    }

    return ordenList;
  };

  const handleDownloadDocument = async ({ document }) => {
    setLoading(true);
    await API.downloadDocumentMarketing({ url: document.url, nameDocument: document.document_name });
    setLoading(false);
  };

  return (
    <Row className="clients-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Marketing Materials and Sample Reports
          </Title>
        </Col>
        <Col className="search">
          <Search placeholder="Search..." className="search-box" onSearch={handleSearch} />
        </Col>
        <Col className="pagination add-button">
          {MARKETING_PERMISSIONS.createHeader && (
            <Button type="primary" className="green-btn" onClick={handleToggleManager}>
              Header Manager
            </Button>
          )}
        </Col>
      </Row>

      <div className="marketing_content">
        {loadingPage ? (
          <Modal visible={loadingPage} footer={null} className="modal-loader">
            <Spin />
          </Modal>
        ) : (
          <>
            {loading && (
              <Modal visible={loading} footer={null} className="modal-loader">
                <Spin />
              </Modal>
            )}
            {MARKETING_PERMISSIONS.createMaterials && (
              <div className="marketing_add_material">
                <Button type="primary" className="purple-btn" onClick={handleToggleMaterial}>
                  Add Materials
                </Button>
              </div>
            )}

            {getAllowedSectionWithFiles().map(item => (
              <div key={item.section.id}>
                <Title level={4} className="textCenter">
                  {item.section.name}
                </Title>

                {item.docuemnts.map(document => (
                  <Row key={document.name} className="marketingPage">
                    <Text className="marketingPage_item">
                      <Text className="marketingPage_title" onClick={() => setSelectPdf(document)}>
                        {document.name}
                      </Text>

                      <div className="marketing_actions">
                        {!document.read_only && (
                          <Text onClick={() => handleDownloadDocument({ document })}>
                            <DownloadOutlined
                              height={'20px'}
                              style={{ color: '#0082ff', fontSize: 18 }}
                              width={'20px'}
                              className="icon"
                            />
                          </Text>
                        )}

                        {MARKETING_PERMISSIONS.editMaterial && (
                          <EditIcon className="icon" onClick={() => handleEditMaterial({ material: document })} />
                        )}
                        {MARKETING_PERMISSIONS.deleteMaterial && (
                          <RemoveIcon className="icon" onClick={() => handleDeleteMaterial({ material: document })} />
                        )}
                      </div>
                    </Text>
                  </Row>
                ))}
              </div>
            ))}
          </>
        )}
      </div>

      {selectPdf?.id && (
        <ModalPdf
          path={selectPdf.url}
          close={() => setSelectPdf(null)}
          expand
          download={!selectPdf.read_only}
          fileInfo={selectPdf}
        />
      )}
      {modalManager && <ModalManager close={handleCloseModalManager} />}
      {modalMaterial && (
        <ModalMaterials
          close={() => {
            setMaterialEdit(null);
            handleToggleMaterial();
          }}
          onCreate={handleCreateMaterial}
          dataEdit={materialEdit}
        />
      )}
    </Row>
  );
};

const mapStateToProps = state => {
  const props = {
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps)(Marketing);
