import React, { useState, useEffect } from 'react';
import { Select, Typography, Row, Col, Switch, Modal } from 'antd';
import { useSelector } from 'react-redux';

import * as API from 'utils/api';
import isAllowed, {
  ROLES_DIVISIONS,
  CLIENT_USER_ID,
  CLIENT_ADMIN_ID,
  CLIENT_SUPER_ADMIN_ID,
  MEDICAL_REVIEWER_ID,
  SALES_REP_ID,
  EXTERNAL_LITIGATION_ATTORNEY_ID,
} from 'layout/roles';
import { notifyApiError } from 'utils/notification';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';

import { SPECIALTY_LIST } from 'constants/specialty';

import './UserRole.scss';
import { getPermissionsBySection, USERS_PERMISSIONS_NAME } from 'permissions';

const { Text } = Typography;
const { Option } = Select;

export default function Index({
  OnRoleChange,
  roleValue,
  clients,
  verifyClientsPermissions,
  loadClients,
  disabledCLient,
  salesReps,
  salesManager,
  defaultSalesReps,
  defaultClient,
  uploadFile,
  visibility,
  onChangeVisibility,
  specialtyData,
  disabledSelectRole,
  isNewAttorney,
  hiddenVisibility,
}) {
  const [GetRoles, setGetRoles] = useState([]);
  const [clientMode, setClientMode] = useState(false);
  const [client_id, setClientId] = useState(disabledCLient && defaultClient ? defaultClient : null);
  const [sales_rep_id, setSales_rep_id] = useState(disabledCLient && defaultSalesReps ? defaultSalesReps : null);
  const [medicalReviewerMode, setMedicalReviewerMode] = useState(false);
  const [specialty, setSpecialty] = useState(specialtyData || '');
  const [salesRepMode, setSalesRepMode] = useState(false);
  const [salesManagerId, setSalesManagerId] = useState(null);
  const [litigationExtMode, setLitigationExtMode] = useState(false);
  const [listFiles, setListFiles] = useState([]);

  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { USERS_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [USERS_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    if (
      roleValue.some(role =>
        [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID, EXTERNAL_LITIGATION_ATTORNEY_ID].includes(role)
      )
    ) {
      loadClients();
    }
  }, []);

  useEffect(() => {
    const getNurseRoles = async () => {
      const tenantOnly = verifyClientsPermissions();

      try {
        const res = await API.getNurseRoles(tenantOnly);

        let roles = res.roles;

        if (disabledCLient)
          roles = roles.filter(rol =>
            [...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.EXTERNAL_LITIGATION_ATTORNEY].includes(rol.name)
          );

        if (!USERS_PERMISSIONS.createLitigation)
          roles = roles.filter(rol => rol.id !== EXTERNAL_LITIGATION_ATTORNEY_ID);

        setGetRoles(roles);
      } catch (e) {
        notifyApiError(e);
      }
    };

    getNurseRoles();
    // eslint-disable-next-line
  }, [roles]);

  useEffect(() => {
    OnRoleChange && OnRoleChange({ roleValue, client_id, sales_rep_id });
    // eslint-disable-next-line
  }, [client_id, roleValue, sales_rep_id]);

  useEffect(() => {
    OnRoleChange && OnRoleChange({ roleValue, specialty });
    // eslint-disable-next-line
  }, [specialty, roleValue]);

  useEffect(() => {
    OnRoleChange && OnRoleChange({ sales_manager_id: salesManagerId, roleValue });
    // eslint-disable-next-line
  }, [salesManagerId]);

  useEffect(() => {
    if (roleValue.find(role => [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role)))
      return setClientMode(true);

    if (roleValue.find(role => role === MEDICAL_REVIEWER_ID)) return setMedicalReviewerMode(true);

    if (!disabledCLient) setClientId(null);
    OnRoleChange && OnRoleChange({ roleValue });
    // eslint-disable-next-line
  }, [roleValue]);

  const handleSelectRole = ({ selectedRole }) => {
    setClientMode(selectedRole.some(role => [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role)));
    setMedicalReviewerMode(selectedRole.some(role => role === MEDICAL_REVIEWER_ID));
    setSalesRepMode(selectedRole.some(role => role === SALES_REP_ID));
    setLitigationExtMode(selectedRole.some(role => role === EXTERNAL_LITIGATION_ATTORNEY_ID));

    OnRoleChange && OnRoleChange({ roleValue: selectedRole });
  };

  const beforeUpload = file => {
    if (!file) return false;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Modal.error({
        content: 'You can only upload PDF file!',
      });
    }

    return isPdf;
  };

  const handleAddFiles = ({ file }) => {
    setListFiles([file]);
    uploadFile({ file });
  };

  const removeFile = () => {
    setListFiles('');
  };

  const removeAllFiles = () => {
    setListFiles('');
  };

  return (
    <Row type="flex" justify="space-between" align="top">
      <Col span={11}>
        <Row className="row" style={{ marginBottom: '10px' }}>
          <Text className="label">Roles</Text>
          <Select
            mode="multiple"
            value={roleValue}
            placeholder="Required"
            onChange={selectedRole => handleSelectRole({ selectedRole })}
            disabled={disabledSelectRole}
            style={{ width: '100%' }}>
            {GetRoles.map(role => (
              <Option key={role.id} value={role.id}>
                {role.display_name}
              </Option>
            ))}
          </Select>
        </Row>
      </Col>

      {clientMode && (
        <>
          <Col span={11}>
            <Text className="label">Client</Text>
            <Select
              placeholder="Required"
              className="clients-select"
              defaultValue={client_id}
              disabled={disabledCLient}
              onChange={id => setClientId(id)}
              style={{ width: '100%' }}>
              {clients.map((client, i) => (
                <Option key={i.toString(36) + i} value={client.id}>
                  {client.client_name}
                </Option>
              ))}
            </Select>
          </Col>

          {roleValue.some(role => [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role)) && (
            <>
              {isAllowed(roles, ROLES_DIVISIONS.CLIENT_ADMIN) ? (
                <Col span={11} className="sales_rep_by_client_admin">
                  <Text className="label">Assigned Sales Rep</Text>
                  <Text>
                    {salesReps.find(item => item.id === sales_rep_id)?.given_name || ''}{' '}
                    {salesReps.find(item => item.id === sales_rep_id)?.family_name || ''}
                  </Text>
                </Col>
              ) : (
                <Col span={11}>
                  <Text className="label">Assign Sales Rep</Text>
                  <Select
                    disabled={disabledCLient}
                    placeholder="Required"
                    className="clients-select"
                    defaultValue={sales_rep_id}
                    onChange={id => setSales_rep_id(id)}
                    style={{ width: '100%' }}>
                    {salesReps.map(userRep => (
                      <Option key={userRep.id} value={userRep.id}>
                        {userRep.given_name || ''} {userRep.family_name || ''}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </>
          )}
        </>
      )}

      {medicalReviewerMode && (
        <Col span={11}>
          <Text className="label">Specialty</Text>
          <Select
            placeholder="Required"
            className="clients-select"
            onChange={id => setSpecialty(id)}
            style={{ width: '100%' }}>
            {SPECIALTY_LIST.map(specialty => {
              return (
                <Option key={specialty.id} value={specialty.id}>
                  {specialty.name}
                </Option>
              );
            })}
          </Select>
        </Col>
      )}

      {salesRepMode && (
        <Col span={11}>
          <Text className="label">Sales Manager</Text>
          <Select
            placeholder="Required"
            className="clients-select"
            onChange={id => setSalesManagerId(id)}
            style={{ width: '100%' }}>
            {salesManager.map(userManager => (
              <Option key={userManager.id} value={userManager.id}>
                {userManager.given_name || ''} {userManager.family_name || ''}
              </Option>
            ))}
          </Select>
        </Col>
      )}

      {litigationExtMode | isNewAttorney && (
        <>
          <Col span={11}>
            <Text className="label">Firm</Text>
            <Select
              placeholder="Required"
              className="clients-select"
              defaultValue={client_id}
              disabled={disabledCLient}
              onChange={id => setClientId(id)}
              style={{ width: '100%' }}>
              {clients.map((client, i) => (
                <Option key={i.toString(36) + i} value={client.id}>
                  {client.client_name}
                </Option>
              ))}
            </Select>
          </Col>

          {!hiddenVisibility && (
            <Col span={24} className="userRoles_section">
              <Text className="label">Visible at MSA Attorney's List</Text>
              <br />
              <Switch
                className="litigation_switch"
                defaultChecked={visibility}
                checked={visibility}
                onChange={() => onChangeVisibility({ visibility: !visibility, client_id })}
              />
            </Col>
          )}

          <Col span={24} className="userRoles_section">
            <Text className="label">CV</Text>
            <BoxDragAndDrop beforeUpload={beforeUpload} handleAddFiles={handleAddFiles} />

            {listFiles.length > 0 && (
              <TableFileBoxDragAndDrop listFiles={listFiles} removeFile={removeFile} removeAllFiles={removeAllFiles} />
            )}
          </Col>
        </>
      )}
    </Row>
  );
}
