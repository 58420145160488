import {
  REPORT_EARLY_INTERVENTION_NAME,
  REPORT_LEGAL_DEMAND_NAME,
  REPORT_LIFE_CARE_PLAN_NAME,
  REPORT_MCP_MSA_COMBO_NAME,
  REPORT_MCP_NAME,
  REPORT_MSA_NAME,
  REPORT_MSA_REDUCTION_NAME,
  REPORT_SURGICAL_NAME,
} from 'constants/reports';

import {
  INDIVIDUAL_BASIC_ID,
  INDIVIDUAL_ELITE_ID,
  INDIVIDUAL_PROFESSIONAL_ID,
  MULTIPLE_BASIC_ID,
  MULTIPLE_ELITE_ID,
  MULTIPLE_PROFESSIONAL_ID,
} from 'containers/Tenant/tenantCreate/components/StepPackageTier/components/PlanCard/planList';

export const TPYE_PLAN_BASIC = 'Basic';
export const TYPE_PLAN_PRODESSIONAL = 'Upgraded';
export const TPYE_PLAN_ELITE = 'Elite';
export const TYPE_PLAN_CUSTOM = 'Custom';

export const INCLUDE_ALL_REPORTS_PLAN = [
  REPORT_MCP_NAME,
  REPORT_MSA_NAME,
  REPORT_LIFE_CARE_PLAN_NAME,
  REPORT_MSA_REDUCTION_NAME,
  REPORT_LEGAL_DEMAND_NAME,
  REPORT_EARLY_INTERVENTION_NAME,
  REPORT_MCP_MSA_COMBO_NAME,
  REPORT_SURGICAL_NAME,
];

export const LIST_SUBSCRIPTION = [
  {
    type: TPYE_PLAN_BASIC,
    name: TPYE_PLAN_BASIC,
    includes: [REPORT_MCP_NAME, REPORT_MSA_NAME, REPORT_LIFE_CARE_PLAN_NAME, REPORT_MSA_REDUCTION_NAME],
    packageId: {
      individual: INDIVIDUAL_BASIC_ID,
      multiple: MULTIPLE_BASIC_ID,
    },
  },
  {
    type: TYPE_PLAN_PRODESSIONAL,
    name: 'Professional',
    includes: [
      REPORT_MCP_NAME,
      REPORT_MSA_NAME,
      REPORT_LIFE_CARE_PLAN_NAME,
      REPORT_MSA_REDUCTION_NAME,
      REPORT_LEGAL_DEMAND_NAME,
      REPORT_EARLY_INTERVENTION_NAME,
    ],
    packageId: {
      individual: INDIVIDUAL_PROFESSIONAL_ID,
      multiple: MULTIPLE_PROFESSIONAL_ID,
    },
  },
  {
    type: TPYE_PLAN_ELITE,
    name: TPYE_PLAN_ELITE,
    includes: INCLUDE_ALL_REPORTS_PLAN,

    packageId: {
      individual: INDIVIDUAL_ELITE_ID,
      multiple: MULTIPLE_ELITE_ID,
    },
  },
];

export const ACTUAL_PLAN = 'actual';
export const UPGRADE_PLAN = 'upgraded';
export const DOWNGRADE_PLAN = 'downgraded';
