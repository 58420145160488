import React from 'react';
import CompanyInfoTaps from './Taps/CompanyInfoTap';
import BillingInformationTap from './Taps/BillingInformationTap';
import ContactsTab from './Taps/ContactsTab';
import UsersTab from './Taps/UsersTab';
import AssignAdminTab from './Taps/AssignAdminTab';
import FolderTab from './Taps/FolderTab';
import { useHistory } from 'react-router-dom';

import { BackIcon } from 'components/Icons';
import { Row, Col, Typography, Tabs } from 'antd';

import '../../MyClients/EditClient/EditClient.scss';

const { TabPane } = Tabs;
const { Title } = Typography;

function EditParentFolder() {
  const history = useHistory();

  return (
    <div className="edit-client-container max-wodth-content-app">
      <Row type="flex" align="middle" className="header ">
        <Col className="title">
          <Title level={4} className="title-text">
            Update Folder
          </Title>
        </Col>
        <Col className="back" onClick={() => history.goBack()}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <Row title='width-collapse' className='edit-client-form'>
        <Col className="msa-taps-container" span={14}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Company Information" key="1">
              <CompanyInfoTaps />
            </TabPane>
            <TabPane tab="Users" key="2">
              <UsersTab />
            </TabPane>
            <TabPane tab="Contacts" key="3">
              <ContactsTab />
            </TabPane>
            <TabPane tab="Billing Information" key="4">
              <BillingInformationTap />
            </TabPane>
            <TabPane tab="Assign Admin" key="5">
              <AssignAdminTab />
            </TabPane>
            <TabPane tab="Folder" key="6">
              <FolderTab />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default EditParentFolder;
