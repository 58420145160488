import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as API from 'utils/api';
import { useSelector } from 'react-redux';

import { Row, Col, Typography } from 'antd';
import SpinBox from 'components/SpinBox';
import { BackIcon } from 'components/Icons';
import { notifyApiError, notifyInfo } from 'utils/notification';

import { StepAddAdmin, StepFolderInfo, StepAddChildFolder } from './Components';

// Routes
import { PARENT_FOLDER_NAV } from 'layout/navigate_const';

// Const
import { CHILD_FOLDER, PARENT_FOLDER } from 'constants/typeFolder';

// Uitls
import { getPermissionsBySection, PARENT_FOLDER_NAME } from 'permissions';

// Style
import './createParentFolder.scss';

// Const
const STEP_FOLDER_INFO = 1;
const STEP_ADD_FOLDER = 2;
const STEP_ADD_ADMIN = 3;

const { Title } = Typography;

const CreateParentFolder = () => {
  // Const
  const history = useHistory();
  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { PARENT_FOLDER_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [PARENT_FOLDER_NAME],
  });
  // end permissions

  // State
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(STEP_FOLDER_INFO);
  const [dataGlobal, setDataGlobal] = useState({});
  const [typeFolder, setTypeFolder] = useState('');

  // Handlers

  const handleCreateClient = async ({ data } = {}) => {
    try {
      setLoading(true);
      const allData = { ...dataGlobal, ...data };

      if (dataGlobal.typeFolder === PARENT_FOLDER) {
        const body = {
          clientFolderData: {
            name: allData.clientName,
            logo: allData.client_logo,
          },
        };

        if (allData?.listSubFolder && allData.listSubFolder.length > 0)
          body.listSubFolder = allData?.listSubFolder.map(item => item.id);
        if (allData?.listAdminAssign && allData.listAdminAssign.length > 0)
          body.listAdminAssign = allData?.listAdminAssign.map(item => item.id);

        await API.createParentFolder({ body });

        notifyInfo('New Folder Created');
      } else {
        // creando child folder
        const params = {
          client_name: allData.clientName,
          client_logo: allData.client_logo,
        };

        // create client
        const createChild = await API.createClient(params);

        if (allData?.listSubFolder.length > 0) {
          const parentFolder = allData.listSubFolder[0];

          const body = {
            add: {
              client_id: createChild.id,
            },
          };

          await API.updateParentFolder({ id: parentFolder.id, body });
        }

        notifyInfo('New client created');
      }
      history.push(PARENT_FOLDER_NAV.path);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading({ loading: false });
    }
  };

  const handleChangeStep = ({ step }) => {
    setStep(step);
  };

  const nextStep = ({ data }) => {
    setStep(prevState => prevState + 1);
    setDataGlobal({
      ...dataGlobal,
      ...data,
    });
  };

  const goBackStep = ({ data }) => {
    setStep(prevState => prevState - 1);
    setDataGlobal({
      ...dataGlobal,
      ...data,
    });
  };

  const handleSelectTypeFolder = ({ type }) => {
    setTypeFolder(type);
  };

  return (
    <Row className="add-client-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Add New Folder
          </Title>
        </Col>
        <Col className="back" onClick={() => history.push(PARENT_FOLDER_NAV.path)}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <Row className="main">
        <SpinBox loading={loading}>
          <div className="createParentFolder">
            <div className="loginClient_steps">
              <div
                onClick={() => step > STEP_FOLDER_INFO && handleChangeStep({ step: 1 })}
                className={`loginClient_step ${step === STEP_FOLDER_INFO ? 'active' : ''} ${
                  step > STEP_FOLDER_INFO ? 'stepComplete' : ''
                }`}>
                <div className="span">1</div> Folder Info
              </div>
              <div
                onClick={() => step > STEP_ADD_FOLDER && handleChangeStep({ step: 2 })}
                className={`loginClient_step ${step === STEP_ADD_FOLDER ? 'active' : ''} ${
                  step > STEP_ADD_FOLDER ? 'stepComplete' : ''
                }`}>
                <div className="span">2</div> Add Folder
              </div>

              {typeFolder !== CHILD_FOLDER && PARENT_FOLDER_PERMISSIONS.assignClientSuperAdmin && (
                <div className={`loginClient_step ${step === STEP_ADD_ADMIN ? 'active' : ''}`}>
                  <div className="span">3</div> Add Admin
                </div>
              )}
            </div>

            {step === STEP_FOLDER_INFO && (
              <StepFolderInfo onClick={nextStep} dataGlobal={dataGlobal} onSelectTypeFolder={handleSelectTypeFolder} />
            )}
            {step === STEP_ADD_FOLDER && (
              <StepAddChildFolder
                onClick={
                  typeFolder === CHILD_FOLDER || !PARENT_FOLDER_PERMISSIONS.assignClientSuperAdmin
                    ? handleCreateClient
                    : nextStep
                }
                dataGlobal={dataGlobal}
                goBackStep={goBackStep}
              />
            )}
            {step === STEP_ADD_ADMIN && (
              <StepAddAdmin onClick={handleCreateClient} dataGlobal={dataGlobal} goBackStep={goBackStep} />
            )}
          </div>
        </SpinBox>
      </Row>
    </Row>
  );
};

export default CreateParentFolder;
