import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Input, Row, Typography, Icon } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

import * as API from 'utils/api';
// import { EditIcon, RemoveIcon } from 'components/Icons';
import Pagination from 'components/Pagination';
import { linkValidation } from 'constants/regexes';
import FileIcons from 'constants/resource-links-icons';
import DeleteResourceLinkModal from './DeleteModal';
import ResourceModal from './ViewSingleResource';
import { notifyApiError, notifyError, notifyInfo } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import SpinBox from 'components/SpinBox';

import './ResourceLibrary.scss';
import { getPermissionsBySection, RESOURCES_LINKS_NAME } from 'permissions';

// Icons
import { ArrowDownIcon, CopyIcon, PencilIcon, TrashIcon, UpIcon } from 'components/Svg';

// Colors
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;

function Index(props) {
  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { RESOURCES_LINKS_PERMISSION } = getPermissionsBySection({
    roles: roles,
    permissions: [RESOURCES_LINKS_NAME],
  });
  // end permissions

  const canAction = RESOURCES_LINKS_PERMISSION.edit || RESOURCES_LINKS_PERMISSION.delete;
  const COLUMNS_TABLE = {
    NAME: 4,
    DESCRIPTION: 6,
    FILE: 4,
    INTRODUCED: 4,
    CREATED: 4,
    LINK: canAction ? 1 : 2,
    ACTION: 1,
  };

  const [searchKeyword, setSearchKeyWord] = useState('');
  const [resourceLinks, setResourceLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [ViewResource, setViewResource] = useState('');
  const [validateName, setValidateName] = useState('');
  const [validateLink, setValidateLink] = useState('');
  const [validateDescription, setValidateDescription] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [deleteData, setDeleteData] = useState('');
  const [activeCollapse, setActiveCollapse] = useState([]);

  const HandleSearch = async (page_selected = 0) => {
    setPageIndex(page_selected);
    const isUserNurseManager = isAllowed(roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);
    const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);

    const tenantOnly = isUserNurseManager && !isUserAdmin;

    try {
      const res = await API.SearchResourceLink(searchKeyword, page_selected, tenantOnly);

      setLoading(false);
      setTotalCount(res.totalCount);
      setResourceLinks(res.resource_links);
    } catch (e) {
      notifyApiError(e);
    }
  };

  useEffect(() => {
    HandleSearch(pageIndex);
  }, [pageIndex]);

  const openModal = id => {
    setOpen(true);
    setViewResource(id);
  };

  const handleCancel = () => {
    setOpen(false);
    setValidateDescription('');
    setValidateLink('');
    setValidateName('');
  };

  const handleUpdateResourceLinks = (key, value) => {
    if (key === 'description' && value.length > 100) {
      setValidateDescription('Description cannot be more than 100 characters!');
      return false;
    } else {
      setViewResource(prev => ({
        ...prev,
        [key]: value,
      }));
    }

    setValidateName('');
    setValidateLink('');
    setValidateDescription('');
  };

  const DeleteResourceLink = async id => {
    setDeleteModal(false);
    setLoading(true);
    setResourceLinks(prev => prev.filter(el => el.id !== id));

    try {
      await API.DeleteResourceLink(id);
      setResourceLinks(prev => prev.filter(el => el.id !== id));

      notifyInfo('Resource link was deleted');

      setTotalCount(prev => prev - 1);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = async pi => {
    await setPageIndex(pi);
    HandleSearch(pi);
  };

  const UpdateResourceLink = async () => {
    const id = ViewResource.id;
    setOpen(false);

    const data = {
      name: ViewResource.name.trim(),
      link: ViewResource.link?.trim() || '',
      description: ViewResource.description.trim(),
      default_link: ViewResource.default_link,
      file: ViewResource.file,
    };

    if (!data.name) {
      setValidateName('Name cannot be empty!');
      setOpen(true);
    } else if (!data.link && !data.file) {
      notifyError('You must select document or enter a link');
      setOpen(true);
    } else if (data.link && !linkValidation.test(data.link)) {
      setValidateLink('Link should be in the correct format!');
      setOpen(true);
    } else if (!data.description) {
      setValidateDescription('Description cannot be empty!');
      setOpen(true);
    } else {
      try {
        setLoading(true);

        let document = null;

        if (data.file && !data.file.id) {
          document = await API.ReportFileUpload(null, data.file);
        }

        delete data.file;
        const resource_link = await API.UpdateResourceLink(id, {
          ...data,
          file_id: document?.file.id || data.file?.id || ViewResource.file_id,
        });

        setResourceLinks(prev =>
          prev.map(link => {
            if (link.id === resource_link.id) {
              return resource_link;
            }

            return link;
          })
        );

        notifyInfo(`Resource link was updated`);
      } catch (e) {
        notifyApiError(e);
        setOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCopy = ({ copy }) => {
    navigator.clipboard.writeText(copy);
    notifyInfo('Link copied to clipboard');
  };

  const handleActiveCollapse = ({ id }) => {
    const texts = activeCollapse;

    if (texts.includes(id)) {
      const index = texts.indexOf(id);
      texts.splice(index, 1);
    } else {
      texts.push(id);
    }

    setActiveCollapse([...texts]);
  };

  return (
    <Row className="users-container resource-wrapper resourcelink">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Resource Library
          </Title>
        </Col>
        <Col className="search">
          <Search
            placeholder="Search..."
            className="search-box"
            onSearch={() => {
              HandleSearch(0);
            }}
            onChange={({ target }) => setSearchKeyWord(target.value)}
          />
        </Col>
        <Col className="pagination">
          {totalCount ? (
            <Pagination onChange={handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
          ) : (
            <></>
          )}
        </Col>
        {RESOURCES_LINKS_PERMISSION.create && (
          <Col className="add-button dropdown-container">
            <Button
              type="primary"
              onClick={() => props.history.push('/app/ResourceLibrary/AddLink')}
              className="green-btn">
              Add Resource
            </Button>
          </Col>
        )}
      </Row>

      <Row type="flex" className="table-header">
        <Col xs={COLUMNS_TABLE.NAME}>Name</Col>
        <Col xs={COLUMNS_TABLE.DESCRIPTION}>Description</Col>
        <Col xs={COLUMNS_TABLE.FILE} style={{ paddingLeft: 15 }}>
          File
        </Col>
        <Col xs={COLUMNS_TABLE.INTRODUCED} style={{ paddingLeft: 10 }}>
          Introduced in Report
        </Col>
        <Col xs={COLUMNS_TABLE.CREATED} style={{ paddingLeft: 10 }}>
          Created
        </Col>
        <Col xs={COLUMNS_TABLE.LINK} style={{ paddingLeft: 15 }}>
          Link
        </Col>
        {canAction && (
          <Col xs={COLUMNS_TABLE.ACTION} style={{ textAlign: 'right' }}>
            Actions
          </Col>
        )}
      </Row>

      <Row className="table-content">
        <SpinBox loading={loading}>
          {resourceLinks.length > 0 ? (
            <Row>
              {resourceLinks.map(item => (
                <Row key={item.id} className="record resourcelink_table_items">
                  {/* Name */}
                  <Col xs={COLUMNS_TABLE.NAME} className="user-column">
                    <Col className="resourceLink_name">
                      {activeCollapse.includes(item.id) ? (
                        <UpIcon color="black" onClick={() => handleActiveCollapse({ id: item.id })} />
                      ) : (
                        <ArrowDownIcon onClick={() => handleActiveCollapse({ id: item.id })} />
                      )}

                      <Text className="user-name">{item.name}</Text>
                    </Col>
                  </Col>

                  {/* Description */}
                  <Col xs={COLUMNS_TABLE.DESCRIPTION}>
                    <Text
                      className={`resourcelink_collapse ${
                        activeCollapse.includes(item.id) ? 'resourcelink_collapse_open' : ''
                      }`}>
                      {item.description}
                    </Text>
                  </Col>

                  {/* File */}
                  <Col xs={COLUMNS_TABLE.FILE} style={{ paddingLeft: 15 }}>
                    <Text type={!item.file && 'secondary'}>
                      {item.file ? (
                        <Paragraph className="file-name" ellipsis onClick={() => window.open(item.file.url, '_blank')}>
                          {item.file.name}
                          &nbsp;
                          <Icon type={FileIcons[item.file?.type]} />
                        </Paragraph>
                      ) : (
                        'file is empty'
                      )}
                    </Text>
                  </Col>

                  {/* Introduced in Report */}
                  <Col xs={COLUMNS_TABLE.INTRODUCED} style={{ paddingLeft: 15 }}>
                    <Text>{item.default_link ? 'Yes' : 'No'}</Text>
                  </Col>

                  {/* Created */}
                  <Col xs={COLUMNS_TABLE.CREATED}>
                    <Text>{moment(item.createdAt).format('MMMM DD, YYYY')}</Text>
                  </Col>

                  {/* Link */}
                  <Col xs={COLUMNS_TABLE.LINK} style={{ paddingLeft: 15 }}>
                    <Text className="resourcelink_iconCopy">
                      <CopyIcon
                        onClick={() =>
                          handleCopy({ copy: item.link.includes('https://') ? item.link : 'https://' + item.link })
                        }
                      />
                    </Text>
                  </Col>

                  {/* Actions */}
                  {canAction && (
                    <Col xs={COLUMNS_TABLE.ACTION} className="actions resourcelink_actions">
                      {RESOURCES_LINKS_PERMISSION.edit && (
                        <PencilIcon color={PRIMARY_LIGHT_BLUE} onClick={() => openModal(item)} />
                      )}

                      {RESOURCES_LINKS_PERMISSION.delete && (
                        <TrashIcon
                          color={PRIMARY_LIGHT_BLUE}
                          onClick={() => {
                            setDeleteModal(true);
                            setDeleteData(item);
                          }}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              ))}
            </Row>
          ) : (
            <div className="empty-icon">{!loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</div>
          )}
          <ResourceModal
            isOpen={open}
            onConfirm={openModal}
            onCancel={handleCancel}
            loading={loading}
            ViewResource={ViewResource}
            handleUpdateResourceLinks={handleUpdateResourceLinks}
            UpdateResourceLink={UpdateResourceLink}
            validateName={validateName}
            validateLink={validateLink}
            validateDescription={validateDescription}
          />
          <DeleteResourceLinkModal
            isOpen={deleteModal}
            onCancel={() => setDeleteModal(false)}
            DeleteResourceLink={DeleteResourceLink}
            deleteData={deleteData}
          />
        </SpinBox>
      </Row>
    </Row>
  );
}

export default Index;
