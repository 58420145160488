import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Select, Spin, Typography, Divider } from 'antd';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

// Components
import { SettingsCard } from './Components/SettingsCard';

// Utils
import * as API from 'utils/api';
import { getClientId } from 'layout/roles';
import { fetchQuickbooksCustomerInfo } from 'utils/api-transactions';
import { notifySuccess } from '../../utils/notification';

// Hooks
import { useCreditCard } from 'hooks';

// Style
import './CompanyProfile.scss';

const BillingInformation = () => {
  const { roles } = useSelector(state => state.auth);

  const { Option } = Select;
  const { Text } = Typography;
  const [stateCountries, setStateCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [clientId, setClientId] = useState(null);
  const [thereIsCustomerProfile, setThereIsCustomerProfile] = useState(false);

  // Hooks
  const { creditCardList, loadingCreditCard, getCreditCardList, getCustomerProfile, createCustomerProfile } =
    useCreditCard();

  useEffect(() => {
    (async () => {

      try {
        const clientInfo = await API.getCLientForId({ id: getClientId(roles) });
        const quickBookId = clientInfo?.clients[0]?.quickbooks_customer_id || '';
        const dataBilling = await fetchQuickbooksCustomerInfo(quickBookId);
        const state = await API.getStates();

        const billTo = {
          displayName: dataBilling?.DisplayName || '',
          companyName: dataBilling?.CompanyName || '',
          address: dataBilling?.BillAddr?.Line1 || '',
          city: dataBilling?.BillAddr?.City || '',
          state: dataBilling?.BillAddr?.CountrySubDivisionCode || '',
          zipCode: dataBilling?.BillAddr?.PostalCode || '',
          email: dataBilling?.PrimaryEmailAddr?.Address || '',
          phone: dataBilling?.PrimaryPhone?.FreeFormNumber || '',
          quickbooks_customer_id: quickBookId,
        };

        const customerProfile = await getCustomerProfile();

        if (customerProfile !== false) {
          // si el client tiene customer profile buscamos sus tarjetas
          await getCreditCardList();
          setThereIsCustomerProfile(true);
        } else if (!Object.values(billTo).some(billTo => !billTo)) {
          // si no tiene customer profile y el billing esta completo creamos el customer profile
          const result = await createCustomerProfile({
            firstName: billTo.companyName,
            lastName: billTo.companyName,
            address: billTo.address,
            city: billTo.city,
            state: billTo.state,
            zip: billTo.zipCode,
            number: billTo.phone,
            email: billTo.email,
            client_id: clientInfo?.clients[0]?.id,
          });
          setThereIsCustomerProfile(!!result);
        }

        setData(billTo);
        setClientId(clientInfo?.clients[0]?.id);
        setStateCountries(state.data.states);
      } finally {
        setIsLoading(false);
      }
    })();
    return () => {};
  }, []);

  const handleChange = ({ field, value }) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const validForm = () => {
    return Object.keys(data).some(item => !data[item]);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const params = {
      id: getClientId(roles),
      quickbooks_customer_id: data.quickbooks_customer_id,
      set_quickbooks: true,
      fields: {
        displayName: data.displayName,
        companyName: data.companyName,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zipCode,
        email: data.email,
        phone: data.phone,
      },
    };

    await API.updateClient(params);

    if (!thereIsCustomerProfile) {
      await createCustomerProfile({
        firstName: data.companyName,
        lastName: data.companyName,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zipCode,
        number: data.phone,
        email: data.email,
        client_id: clientId,
      });
    }

    notifySuccess('Updated Successfully');
    setIsLoading(false);
  };

  const StyledInput = {
    fontSize: '14px',
  };

  const LOADING = isLoading || loadingCreditCard;

  return (
    <div>
      <Spin tip="Loading..." spinning={LOADING}>
        <Row className="company_container" type="flex" justify="space-between">
          <Row type="flex" justify="start" align="middle">
            <h1 className="company__title">Billing Information</h1>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Display Name</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="displayName"
                value={data?.displayName || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Company Name</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="companyName"
                value={data?.companyName || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Col md={24}>
              <Row className="company__inputs">
                <Text>Address</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="address"
                value={data?.address || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Col md={11}>
              <Row className="company__inputs">
                <Text>City</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="city"
                value={data?.city || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
            <Col md={11}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={11}>
                  <Row className="company__inputs">
                    <Text>State</Text>
                  </Row>
                  <Select
                    showSearch
                    placeholder="Required"
                    className="clients-select"
                    value={data?.state}
                    onChange={name => handleChange({ field: 'state', value: name })}
                    style={{ width: '100%' }}>
                    {stateCountries.map(state => (
                      <Option key={state.id} value={state.name}>
                        {state.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={11}>
                  <Row className="company__inputs">
                    <Text>Zip code</Text>
                  </Row>
                  <InputMask
                    mask="99999"
                    placeholder="Required"
                    className="input-ssn"
                    value={data?.zipCode || ''}
                    onChange={e => handleChange({ field: 'zipCode', value: e.target.value })}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Email</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="email"
                value={data?.email || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Phone</Text>
              </Row>
              <InputMask
                className="input-ssn"
                mask="999-999-9999"
                size="large"
                placeholder="Required"
                value={data?.phone || ''}
                name="phone"
                style={StyledInput}
                onChange={e => handleChange({ field: 'phone', value: e.target.value })}
              />
            </Col>
          </Row>

          <Row type="flex" justify="end">
            <Col md={6}>
              <button disabled={validForm()} className="company_form_btn mb-4 mt-3" onClick={onSubmit}>
                Save
              </button>
            </Col>
          </Row>

          <Divider />

          <SettingsCard
            canCreate={!Object.values(data).some(billTo => !billTo)}
            listCard={creditCardList}
            updateList={async () => await getCreditCardList()}
          />
        </Row>
      </Spin>
    </div>
  );
};

export default BillingInformation;
