import React from 'react';
import { Alert, Button, Col, Input, Row, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { validateInteger, validatePrice } from '../../../../../utils/utils';

import InputCurrencyBox from 'components/InputCurrencyBox';

const { Text } = Typography;

export default function InputForm({
  levelsData,
  handleDuplicatedInput,
  levelError,
  priceLevelError,
  duplicateInput,
  handleRemove,
}) {
  let disabled = false;

  return (
    <>
      {levelsData.map((data, indx) => {
        if (!data.price || !validatePrice(data.price) || Number(data.price) <= 0) {
          disabled = true;
        }
        if (!data.number || !validateInteger(data.number)) {
          disabled = true;
        }

        return (
          <div key={indx}>
            <Row type="flex" justify="space-between" align="middle" className="main-info" style={{ width: '100%' }}>
              <Col className="row" md={16}>
                <Row className="label">Level</Row>
                <Row>
                  <Input
                    name="number"
                    onChange={e => {
                      handleDuplicatedInput('number', e.target.value, indx);
                    }}
                    type={'number'}
                    value={data.number}
                    mask="999-99-9999"
                    placeholder="Required"
                    className="input-ssn"
                  />
                </Row>
              </Col>
              <Col className="row" md={6} style={{ marginLeft: 11 }}>
                <Row className="label">
                  <Text>Price</Text>
                </Row>
                <InputCurrencyBox
                  name="price"
                  placeholder="Required"
                  onChange={value => {
                    handleDuplicatedInput('price', value, indx);
                  }}
                  value={data.price}
                />
              </Col>
              <Col>
                <DeleteOutlined onClick={() => handleRemove(indx)} style={{ fontSize: 18, color: '#ff7272' }} />
              </Col>
            </Row>
            <Row style={{ width: '95%', paddingBottom: 10 }}>
              {levelError && <Alert type="error" message={levelError} banner />}
              {priceLevelError && <Alert type="error" message={priceLevelError} banner />}
            </Row>
          </div>
        );
      })}
      <div className="form-btn-wrapper">
        <Button type="primary" disabled={disabled} className="green-btn button" onClick={duplicateInput}>
          {levelsData.length ? 'Add Another' : 'Add Level'}
        </Button>
      </div>
    </>
  );
}
