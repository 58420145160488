import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ModalMessage } from '../ModalMessage';

const ModalCustomPackage = () => {
  const history = useHistory();

  return (
    <ModalMessage
      onClose={() => history.push('/')}
      title="One of our Admins has been notified of your interest in a custom package!"
      message={
        <>
          We will reach out to you soon, or feel free to contact us at: <br />
          <br />{' '}
          <a className="linkColorPurple" href="mailto:requestademo@msatech.io">
            requestademo@msatech.io
          </a>{' '}
          or contact Sam Singleton at 727-776-8867
        </>
      }
      textButton="Ok"
      noneIcon
      onConfirm={() => history.push('/')}
    />
  );
};

export default ModalCustomPackage;
