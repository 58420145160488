import { Modal } from 'antd';
import React from 'react';

export default function RequestModal({
  isOpen,
  onCancel,
  body,
  title,
  className,
  width
}) {
  
  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      centered={true}
      closable={true}
      width={width}
      className={className}
      title={title}
      destroyOnClose>
      {body}
    </Modal>
  );
}
