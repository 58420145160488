import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const DatePickerComponent = ({ label, value, onChange, disabled }) => {
  const dateValue = value ? moment(value) : null;

  return (
    <div className="listInputsLCP_date">
      <p>{label}</p>
      <DatePicker
        format="MM/DD/YYYY"
        disabled={disabled}
        allowClear={false}
        value={dateValue}
        placeholder="DD / MM / YYYY"
        onChange={date => onChange(date)}
      />
    </div>
  );
};

export default DatePickerComponent;
