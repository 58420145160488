import moment from 'moment';

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getFindACodeTableFromList = name => {
  if (name === 'Durable Medical Equipment') {
    return 'dme_ucr';
  }
  if (name === 'Genetic Testing' || name === 'Laboratory' || name === 'Labs') {
    return 'lab_ucr';
  } else {
    return 'rvu_wc_ucr';
  }
};

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export const compareOrder = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};

export const formatCurrency = value => {
  return '$' + (value || 0).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const validateSSN = value => {
  let ssnPattern = /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/;
  return ssnPattern.test(value);
};

export const validatePrice = value => {
  return /^\d+(\.\d+)?$/.test(value);
};

export const validateInteger = value => {
  return /^\d+?$/.test(value);
};

export const validateEmail = email => {
  const re =
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

  return re.test(String(email).toLowerCase());
};

export const validateName = name => {
  return /^[a-z ,.'-]+$/i.test(name);
};

export const splitString = originalString => {
  var attrs = [];

  var RefString = function (s) {
    this.value = s;
  };
  RefString.prototype.toString = function () {
    return this.value;
  };
  RefString.prototype.charAt = String.prototype.charAt;
  var data = new RefString(originalString);

  var getBlock = function (endChr, restString) {
    var block = '';
    var currChr = '';
    while (currChr !== endChr && restString.value !== '') {
      if (/'|"/.test(currChr)) {
        block = block.trim() + getBlock(currChr, restString);
      } else if (/\{/.test(currChr)) {
        block = block.trim() + getBlock('}', restString);
      } else if (/\[/.test(currChr)) {
        block = block.trim() + getBlock(']', restString);
      } else {
        block += currChr;
      }
      currChr = restString.charAt(0);
      restString.value = restString.value.slice(1);
    }
    return block.trim();
  };

  do {
    var attr = getBlock(',', data);
    attrs.push(attr);
  } while (data.value !== '');
  return attrs;
};

export const validatePassword = ({ password }) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/.test(password);
};

export const validatePhone = (value, required = false) => {
  if (!required && (!value || value === '___-___-____')) return true;
  return /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value);
};

export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const isObjectEmpty = object => Object.keys(object).length === 0;

export const pluralize = (str, num) => `${num} ${str}${num !== 1 ? 's' : ''}`;

export const formatDatePart = num => (String(num).length > 1 ? num : `0${num}`);

// Function to sort any array of objects by sending the key to sort
export function sortArrayObjectForKey({ array = [], key, type = 'string' }) {
  // validate that the key exists in the array and the array is not empty
  if (!!array.length && array[0][key]) {
    array.sort((item1, item2) => {
      // We get the title and put it in lowercase so that all the text is the same
      const value1 = type === 'string' ? item1[key].toLowerCase() : item1[key];
      const value2 = type === 'string' ? item2[key].toLowerCase() : item2[key];

      if (value1 < value2) {
        return -1;
      } else if (value1 > value2) {
        return 1;
      } else {
        return 0;
      }
    });

    return array;
  } else {
    return array;
  }
}

// Funcion para reordenar una lista
export const reOrderList = ({ list, startIndex, endIndex }) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const onlyMaxDecimals = ({ number, decimals = 3 }) => {
  const numberSplit = number.split('.');
  let result = number;
  if (numberSplit.length > 1 && numberSplit[1].length > decimals) {
    numberSplit[1] = numberSplit[1].slice(0, decimals);
    result = numberSplit.join('.');
  }

  return result;
};

export const formatInputMoney = ({ number, decimals }) => {
  const onlyFloat = number.replace(/[^0-9.]/g, '');

  const only2decimal = onlyMaxDecimals({ number: onlyFloat, decimals });

  return only2decimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const arrayToObject = ({ array }) => {
  const fields = {};

  array.forEach(e => {
    fields[e] = '';
  });

  return fields;
};

export const formatMoneyToNumber = ({ money }) => {
  if (typeof money !== 'string') return money;

  return parseFloat(money.replaceAll(',', ''));
};

export const formatNumberToMoney = ({ number }) =>
  number
    .replace(/,/g, '|')
    .replace(/\./g, ',')
    .replace(/\|/g, '.')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const filterObject = ({ raw, allowed, notAllowed = [], noRepeat, notNull } = {}) => {
  let filtered = Object.keys(raw).filter(key => !notAllowed.includes(key) && allowed.includes(key));

  if (notNull) filtered = filtered.filter(key => Boolean(raw[key]));

  if (noRepeat) filtered = filtered.filter(key => raw[key] !== noRepeat[key]);

  const result = filtered.reduce((obj, key) => {
    obj[key] = raw[key];
    return obj;
  }, {});

  return result;
};

export const validatedFormatEmail = ({ email }) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.replace(/ /g, ''));
};

export const capitalizeList = ({ list, field }) => {
  return list.map(item => {
    return {
      ...item,
      [field]: item[field].replace(/\b\w/g, l => l.toUpperCase()),
    };
  });
};

export const getRushFees = ({ createdAt, needed_by, max_days }) => {
  if (!needed_by) return '';

  const difference = moment(needed_by).diff(moment(createdAt), 'days');

  return difference < max_days;
};

export const formatExpired = ({ value }) => {
  return value
    .replace(/\D/g, '') // Elimina todos los caracteres no numéricos
    .substring(0, 4) // Obtén solo los primeros cuatro dígitos
    .replace(/(\d{2})(\d)/g, function (match, firstTwo, rest) {
      // Verifica si los primeros dos dígitos son mayores a 12
      if (parseInt(firstTwo) > 12) {
        firstTwo = '12';
      }

      return firstTwo + '/' + rest;
    });
};

export const validInputNumber = ({ value, max }) => {
  const cvv = value.replace(/\D/g, '');

  if (cvv.length > max) {
    value = cvv.slice(0, max);
  } else {
    value = cvv;
  }

  return value;
};
