import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as API from 'utils/api';

// Components
import { Collapse } from 'antd';
import { NotificationSettingItem } from './Components';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { notifyApiError } from 'utils/notification';

// Utils
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { NOTIFICATION_BY_MEDICAL_REVIEWER, NOTIFICATION_BY_ROLE_ID } from './const/notificationByRole';
import { getRoleId } from 'layout/roles';

// Style
import './notificationSetting.scss';

const { Panel } = Collapse;

const NotificationSettings = () => {
  const { roles, userInfo } = useSelector(state => state.auth);

  const [listNotification, setListNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [userSpecialty, setUserSpecialty] = useState(null);

  const getNotifications = async () => {
    const notifications = await API.getNotificationManagement({ idUser: userInfo.username });
    setListNotification(notifications);
  };

  useEffect(() => {
    (async () => {
      try {
        await getNotifications();

        // Si eres medical reviewer buscamos la especialidad
        if (isAllowed(roles, ROLES_DIVISIONS.MANAGER)) {
          const user = await API.GetUserRoles(userInfo.username);
          setUserSpecialty(user.user_roles.specialty);
        }
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoadingPage(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleAllChangeItem = async ({ allNotification, active }) => {
    try {
      setLoading(true);
      const listPromise = allNotification.map(notification => {
        const getInfoNoti = listNotification.find(item => item.id === notification.notificationId);

        if (getInfoNoti['allowed.id'] !== null)
          return API.updateNotificationManagement({
            notification_allow_id: getInfoNoti['allowed.id'],
            is_allowed: active,
          });

        return API.createNotificationManagement({
          notification_type_id: notification.notificationId,
          user_id: userInfo.username,
        });
      });

      await Promise.all(listPromise);

      await getNotifications();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeItem = async ({ idNotification, active }) => {
    try {
      setLoading(true);
      // validamos si tenemos que crear o actualizar
      const notification = listNotification.find(item => item.id === idNotification);
      const isCreate = notification?.['allowed.is_allowed'] === null;

      let result = {};
      if (isCreate) {
        result = await API.createNotificationManagement({
          notification_type_id: idNotification,
          user_id: userInfo.username,
        });
      } else {
        const updateById = notification['allowed.id'];
        await API.updateNotificationManagement({ notification_allow_id: updateById, is_allowed: active });

        result = {
          id: updateById,
          is_allowed: active,
        };
      }

      // actualizamos la lista
      setListNotification(prevState =>
        prevState.map(item => {
          if (item.id === idNotification)
            return {
              ...item,
              'allowed.id': result.id,
              'allowed.is_allowed': result.is_allowed,
            };

          return item;
        })
      );
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const isActiveNotification = ({ notificationId }) => {
    const notification = listNotification.find(item => item.id === notificationId);

    let isActive = notification?.['allowed.is_allowed'] ?? null;
    if (isActive === null) isActive = true;

    return !!isActive;
  };

  const isActiveAllNotification = ({ allNotification }) => {
    const notification = listNotification.filter(item =>
      allNotification.map(notiParam => notiParam.notificationId).includes(item.id)
    );

    const active = notification.some(item => item['allowed.is_allowed'] === null || item['allowed.is_allowed']);

    return active;
  };

  const renderListNotification = !userSpecialty
    ? NOTIFICATION_BY_ROLE_ID[getRoleId(roles)]
    : NOTIFICATION_BY_MEDICAL_REVIEWER[userSpecialty];

  if (loadingPage) return <ModalLoading />;

  return (
    <>
      {loading && <ModalLoading />}
      <div className="notificationSettings">
        <div className="notificationSettings_content">
          <h1 className="company__title">Notification Settings</h1>
          <p>
            By toggling the switch, you can choose to receive notifications for specific users, clients, claimants,
            reports, updates, or alerts
          </p>

          <Collapse>
            {renderListNotification.map(group => (
              <Panel
                header={
                  <NotificationSettingItem
                    onChange={active => handleAllChangeItem({ allNotification: group.notification, active })}
                    title={group.name}
                    active={isActiveAllNotification({ allNotification: group.notification })}
                  />
                }
                key={group.name}>
                <div className="notificationSetting">
                  {group.notification.map(notification => (
                    <NotificationSettingItem
                      key={notification.name}
                      onChange={value =>
                        handleChangeItem({ idNotification: notification.notificationId, active: value })
                      }
                      title={notification.name}
                      active={isActiveNotification({ notificationId: notification.notificationId })}
                    />
                  ))}
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default NotificationSettings;
