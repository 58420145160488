import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Radio, Checkbox, Divider } from 'antd';
import SpinBox from 'components/SpinBox';
import TextAreaBox from 'components/TextAreaBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import { notifyApiError } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MCP_MSA_COMBO_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

import './Intro.scss';

const { Title, Text } = Typography;

class Intro extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      medicare_partAB: null,
      applied_SSDB: false,
      denied_SSDB: false,
      process_SSDB: false,
      years_months_days: false,
      ESRD: false,
      checkboxes_other: '',
      loadingText: 'Loading...',
      loading: false,
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.setState({
          is_template: reportInfo.is_template,
          medicare_partAB: reportInfo.medicare_partAB,
          applied_SSDB: reportInfo.applied_SSDB,
          denied_SSDB: reportInfo.denied_SSDB,
          process_SSDB: reportInfo.process_SSDB,
          years_months_days: reportInfo.years_months_days,
          ESRD: reportInfo.ESRD,
          checkboxes_other: reportInfo.checkboxes_other,
          loading: false,
        });
        this.props.updateReportInfo(reportInfo);
      })
      .catch(err => forbiddenRedirect(err, this.props));
  };

  componentDidUpdate = prevProps => {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        medicare_partAB: reportInfo.medicare_partAB,
        applied_SSDB: reportInfo.applied_SSDB,
        denied_SSDB: reportInfo.denied_SSDB,
        process_SSDB: reportInfo.process_SSDB,
        years_months_days: reportInfo.years_months_days,
        ESRD: reportInfo.ESRD,
        checkboxes_other: reportInfo.checkboxes_other,
        loading: false,
      });
    }
  };

  handleUpdateReport = async (isNextStep = false) => {
    const {
      report_id,
      medicare_partAB,
      applied_SSDB,
      denied_SSDB,
      process_SSDB,
      years_months_days,
      ESRD,
      checkboxes_other,
    } = this.state;

    const params = {
      id: report_id,
      medicare_partAB,
      applied_SSDB,
      denied_SSDB,
      process_SSDB,
      years_months_days,
      ESRD,
      checkboxes_other,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: 'Saving...',
      });
      try {
        let res = {};
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);

        if (res?.report) this.props.updateReportInfo(res.report);
      } catch (e) {
        notifyApiError(e);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      if (this.state.REPORT_PERMISSIONS.edit) API.updateReport(params);
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      medicare_partAB,
      applied_SSDB,
      denied_SSDB,
      process_SSDB,
      years_months_days,
      ESRD,
      checkboxes_other,
      loadingTitle,
      loading,
      REPORT_PERMISSIONS,
    } = this.state;
    const { reportInfo } = this.props;
    const claimant = reportInfo.claimant ? reportInfo.claimant : null;
    const patientName = claimant
      ? claimant.claimant_name + ' ' + claimant.claimant_middle_name + ' ' + claimant.claimant_last_name
      : '';

    return (
      <Row className="msa-intro-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MCP_MSA_COMBO_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown loading={loading} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Introduction</Text>
                </Col>
              </Row>
              <Row className="row">
                <Col>
                  <p>
                    Dear Sir/Madam, <br />
                    <br /> We represent {patientName} and have been asked by the parties to refer the above case to your
                    office for review and approval of the Worker'sCompensation Medicare Set-aside Arrangement ("WCMSA")
                    outlined in the attached settlement documents. The following is the pertinent information in regard
                    to the above-captioned claimant
                  </p>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Medicare checkboxes</Text>
                </Col>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  disabled={!REPORT_PERMISSIONS.edit}
                  onChange={e =>
                    this.setState({
                      medicare_partAB: e.target.checked ? 'A' : null,
                      applied_SSDB: false,
                      denied_SSDB: false,
                      process_SSDB: false,
                      years_months_days: false,
                      ESRD: false,
                    })
                  }
                  checked={medicare_partAB ? true : false}>
                  <Text className="checkbox-label">Select Medicare part A or B</Text>
                </Checkbox>
              </Row>
              {medicare_partAB && (
                <Row className="radio-item">
                  <Radio.Group
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={e => this.setState({ medicare_partAB: e.target.value })}
                    value={medicare_partAB}>
                    <Radio value={'A'}>A</Radio>
                    <Radio value={'B'}>B</Radio>
                  </Radio.Group>
                </Row>
              )}
              <Row className="checkbox-item">
                <Checkbox
                  onChange={e => this.setState({ applied_SSDB: e.target.checked })}
                  disabled={!REPORT_PERMISSIONS.edit || medicare_partAB}
                  checked={applied_SSDB}>
                  <Text className="checkbox-label">
                    Claimant has applied for Social Security disability benefits(SSDB)
                  </Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={e => this.setState({ denied_SSDB: e.target.checked })}
                  disabled={!REPORT_PERMISSIONS.edit || medicare_partAB}
                  checked={denied_SSDB}>
                  <Text className="checkbox-label">Claimant has been denied SSDB but anticipates an appeal</Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={e => this.setState({ process_SSDB: e.target.checked })}
                  disabled={!REPORT_PERMISSIONS.edit || medicare_partAB}
                  checked={process_SSDB}>
                  <Text className="checkbox-label">
                    Claimant is in the process of appealing and/or re-filing for SSDB
                  </Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={e => this.setState({ years_months_days: e.target.checked })}
                  disabled={!REPORT_PERMISSIONS.edit || medicare_partAB}
                  checked={years_months_days}>
                  <Text className="checkbox-label">
                    Claimant is (or will be) at least 62 years and 6 months old 120 days from today
                  </Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={e => this.setState({ ESRD: e.target.checked })}
                  disabled={!REPORT_PERMISSIONS.edit || medicare_partAB}
                  checked={ESRD}>
                  <Text className="checkbox-label">
                    Claimant has end stage renal disease (ESRD) but does not yet qualify for Medicare based on ESRD
                  </Text>
                </Checkbox>
              </Row>
              <Row>
                <TextAreaBox
                  value={checkboxes_other}
                  label="Other, Please Explain"
                  disabled={!REPORT_PERMISSIONS.edit}
                  placeholder="Optional"
                  rows={8}
                  onChange={value => this.setState({ checkboxes_other: value })}
                />
              </Row>
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(Intro);
