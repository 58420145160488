import React, { useState } from 'react';
import * as API from 'utils/api';

import { Modal } from 'antd';
import { Viewer } from '@react-pdf-viewer/core';
import { ArrowsAltOutlined, ShrinkOutlined, DownloadOutlined } from '@ant-design/icons';
import { ModalLoading } from 'components/Modal/ModalLoading';

import '@react-pdf-viewer/core/lib/styles/index.css';
import './modalPdf.scss';
import { notifyApiError } from 'utils/notification';

const ModalPdf = ({ path, close, expand, download, fileInfo }) => {
  const [expandPdf, setExpandPdf] = useState(false);
  const [laoding, setLoading] = useState(false);

  const handleExpandToggle = () => {
    setExpandPdf(prevState => {
      return !prevState;
    });
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      await API.downloadDocumentMarketing({ url: path, nameDocument: fileInfo?.document_name || fileInfo?.name || '' });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      className={`modalPdf ${expandPdf ? 'modalPdf_full' : ''}`}>
      {laoding && <ModalLoading />}

      {download && (
        <div className="modalPdf_icon modalPdf_download" onClick={handleDownload}>
          <DownloadOutlined />
        </div>
      )}
      {expand && (
        <div className="modalPdf_icon modalPdf_expand" onClick={handleExpandToggle}>
          {expandPdf ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
        </div>
      )}

      {!expand && (
        <>
          {Array.isArray(path) ? (
            <>
              {path.map(item => (
                <Viewer fileUrl={item} defaultScale={1.4} />
              ))}
            </>
          ) : (
            <Viewer fileUrl={path} defaultScale={expandPdf ? 3 : 1.4} />
          )}
        </>
      )}

      {expand && <Viewer fileUrl={path} defaultScale={3} />}
    </Modal>
  );
};

export default ModalPdf;
