import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePickerSimple from 'react-datepicker';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Input, Layout, Row, Select, Typography } from 'antd';
import * as API from 'utils/api';
import CustomButton from '../../../components/common/CustomButton';
import { PRIMARY_LIGHT, PRIMARY_LIGHT_GREEN } from '../../../lib/utils/colors';
import messages from './messages';
import SpinBox from 'components/SpinBox';
import {
  ASSIGNMENT_TYPE_QA_INTERNAL,
  ASSIGNMENT_TYPE_ATTORNEY,
  ASSIGNMENT_TYPE_RA,
  ASSIGNMENT_TYPE_LEGAL_NURSE,
  ASSIGNMENT_TYPE_MEDICAL_REVIEWER,
  ASSIGNMENT_TYPE_NURSE,
  ASSIGNMENT_ROLES_ADMIN_PORTAL,
  ASSIGNMENT_TYPE_AQ,
} from 'constants/assignment-types';
import { notifyApiError } from 'utils/notification';
import { SPECIALTY_LEGAL_NURSE, SPECIALTY_REPORT } from 'constants/specialty';
import { STATUS_IN_QA_REVIEW, STATUS_IN_ATTORNEY_REVIEW } from 'constants/report_status';
import { REPORT_MCP_TYPE_ID, REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID } from 'constants/reports';
import { useSelector } from 'react-redux';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

import '../../../App.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ASSIGNMENT_BOARD_NAME, getPermissionsBySection } from 'permissions';
import { CustomCheckBox } from 'components/CustomCheckBox';

import './addAssignForm.scss';

const { Title, Text } = Typography;
const { Content } = Layout;
const { Option } = Select;

const AddAssignForm = ({
  onSubmit,
  reportId,
  edit = {},
  disabledField,
  defaultAssignmentType,
  onlyAssign,
  assignmentBoardPage,
}) => {
  const { roles } = useSelector(state => state.auth);
  // Permissions
  const { ASSIGNMENT_BOARD_PERMISSION } = getPermissionsBySection({
    roles: roles,
    permissions: [ASSIGNMENT_BOARD_NAME],
  });
  // end permissions

  const [assignmentTypes, setAssignmentTypes] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [allClaimants, setAllClaimants] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const [activeSelectSpecialty, setActiveSelectSpecialty] = useState(false);
  const [selectedClient, setSelectedClient] = useState(edit.claimant?.client_id || null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    Number.isInteger(Number(edit.user?.specialty)) ? Number(edit.user.specialty) : null
  );
  const [alertMessage, setAlertMessage] = useState('');

  const [newAssignment, setNewAssignment] = useState({
    id: edit.id,
    user_id: edit.user_id || null,
    report_id: edit.report_id || null,
    claimant_id: edit.claimant_id || null,
    assignment_type_id: edit.assignment_type_id || defaultAssignmentType || null,
    status: edit.status?.key || 2,
    start_date: edit.start_date ? moment(edit.start_date) : moment(new Date()),
    end_date: edit.end_date ? moment(edit.end_date) : moment(new Date()),
    note: edit.note || null,
    report: edit.report,
    num_meds: edit?.num_meds || null,
    rush_fees: edit?.rush_fees || false,
  });
  const [pending, setPending] = useState(false);

  const isAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);

  useEffect(() => {
    //prefill form if assign for generated report
    const asyncFetchData = async () => {
      setPending(true);
      try {
        if (reportId) {
          const report = await API.getReportById(reportId);
          let assignmentTypeId;
          let data;

          if (report.report_status_id === STATUS_IN_QA_REVIEW.id) {
            data = await API.getUsersByAssignmentTypeId(ASSIGNMENT_TYPE_QA_INTERNAL);
            assignmentTypeId = ASSIGNMENT_TYPE_QA_INTERNAL;
            setAllUsers(data.users);
          } else if (report.report_status_id === STATUS_IN_ATTORNEY_REVIEW.id) {
            data = await API.getUsersByAssignmentTypeId(ASSIGNMENT_TYPE_ATTORNEY);
            assignmentTypeId = ASSIGNMENT_TYPE_ATTORNEY;
            setAllUsers(data.users);
          }

          if (isAdmin) {
            assignmentTypeId = null;
          }

          setNewAssignment({
            ...newAssignment,
            report_id: report.id,
            claimant_id: report.claimant_id,
            assignment_type_id: assignmentTypeId,
            report,
          });
        }

        let res = await API.getAllAssignmentsType();

        if (Array.isArray(onlyAssign) && onlyAssign.length > 0) {
          const onlyList = res.assignment_types.filter(item => onlyAssign.includes(item.id));

          if (!onlyList.some(item => item.id === newAssignment.assignment_type_id)) {
            setNewAssignment(prevState => {
              return {
                ...prevState,
                assignment_type_id: null,
                user_id: null,
              };
            });
          }

          setAssignmentTypes(onlyList);
        } else {
          setAssignmentTypes(res.assignment_types);
        }

        res = await API.getClientsData('', 0, 1000);
        setAllClients(res.clients);

        // validamos que ya haya un assignment type
        if (newAssignment.assignment_type_id) {
          if (newAssignment.assignment_type_id === ASSIGNMENT_TYPE_MEDICAL_REVIEWER) {
            // si es medical reviewer activamos el select de specialty y asignamos el specialty del user
            setActiveSelectSpecialty(true);
            await handleGetUsersSpecial({ specialtyId: selectedSpecialty });
          } else if (newAssignment.assignment_type_id === ASSIGNMENT_TYPE_LEGAL_NURSE) {
            // si es legal nurse buscamos los usuarios con su specialty
            await handleGetUsersSpecial({ specialtyId: SPECIALTY_LEGAL_NURSE.id });
          } else {
            // buscamos los usuarios del tipo de assignment
            res = await API.getUsersByAssignmentTypeId(newAssignment.assignment_type_id);
            setAllUsers(res.users);
          }
        }

        if (edit.claimant?.client_id) {
          res = await API.GetClaimantByClientId(edit.claimant.client_id);
          setAllClaimants(res.claimants);
        }

        if (edit.claimant_id) {
          res = await API.getReportsByClaimantId(edit.claimant.id);
          setAllReports(res.reports);
        }

        handleActiveAlert({ report: newAssignment?.report, assignmentId: newAssignment?.assignment_type_id });
      } catch (e) {
        notifyApiError(e);
      } finally {
        setPending(false);
      }
    };

    asyncFetchData();
    // eslint-disable-next-line
  }, []);

  const onChangeDatePicker = dateString => {
    const newDate = moment(dateString || new Date());
    setNewAssignment({
      ...newAssignment,
      start_date: newDate,
      end_date: newAssignment.end_date.isBefore(newDate) ? newDate : newAssignment.end_date,
    });
  };

  const onFinishDatePicker = dateString => {
    const newDate = moment(dateString || new Date());
    setNewAssignment({
      ...newAssignment,
      end_date: newDate,
      start_date: newAssignment.start_date.isAfter(newDate) ? newDate : newAssignment.start_date,
    });
  };

  const onSubmitAssignment = async () => {
    setPending(true);

    delete newAssignment.report;

    const res = await onSubmit(newAssignment);
    setPending(false);
    if (res && !reportId) {
      setNewAssignment({
        user_id: null,
        report_id: null,
        claimant_id: null,
        assignment_type_id: null,
        status: '1',
        start_date: moment(new Date()),
        end_date: moment(new Date()),
        note: null,
      });
    }
    if (newAssignment.assignment_type_id === ASSIGNMENT_TYPE_QA_INTERNAL) {
      updateReport({ report_status_id: STATUS_IN_QA_REVIEW.id });
    }
  };

  const updateReport = ({ report_status_id }) => {
    let params = { report_status_id, id: newAssignment.report_id };
    API.updateReport(params);
  };

  const handleGetUsersSpecial = async ({ specialtyId }) => {
    try {
      setPending(true);
      const users = await API.getUsersBySpecialty({ specialtyId });
      setSelectedSpecialty(specialtyId);
      setAllUsers(users.users);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setPending(false);
    }
  };

  const handleSelectAssigmentType = async id => {
    const selectDiferentId = id !== newAssignment.assignment_type_id;

    setNewAssignment({
      ...newAssignment,
      assignment_type_id: id,
      user_id: selectDiferentId ? null : newAssignment.user_id,
      report_id: id === ASSIGNMENT_TYPE_RA ? null : newAssignment.report_id,
    });

    const options = {
      assignmentId: id,
      reportId: newAssignment.report_id,
    };

    if (allReports.length === 0 && newAssignment?.report) options.report = newAssignment.report;

    if (newAssignment?.report_id) handleActiveAlert(options);

    if (!selectDiferentId) return;

    if (activeSelectSpecialty) setActiveSelectSpecialty(false);

    if (Number.isInteger(selectedSpecialty)) {
      setSelectedSpecialty(null);
    }

    if (id === ASSIGNMENT_TYPE_LEGAL_NURSE)
      return await handleGetUsersSpecial({ specialtyId: SPECIALTY_LEGAL_NURSE.id });

    if (id === ASSIGNMENT_TYPE_MEDICAL_REVIEWER) {
      setActiveSelectSpecialty(true);
      setAllUsers([]);
      return;
    }

    const res = await API.getUsersByAssignmentTypeId(id);
    setAllUsers(res.users);
  };

  const handleChangeSpecialty = async id => {
    setNewAssignment(prevState => {
      return { ...prevState, user_id: null };
    });
    await handleGetUsersSpecial({ specialtyId: id });
  };

  const handleSelectedReport = id => {
    handleActiveAlert({ reportId: id, assignmentId: newAssignment?.assignment_type_id });
    setNewAssignment({ ...newAssignment, report_id: id, report: allReports.find(report => report.id === id) });
  };

  const handleActiveAlert = ({ reportId, assignmentId, report }) => {
    // si el reporte no es mcp ni mini cost y se tiene seleccionado medical reviewer mostramos una alerta que no se puede asignar
    let report_type_id = {};

    if (reportId) report_type_id = allReports.find(report => report.id === reportId)?.report_type_id;

    if (report) report_type_id = report.report_type_id;

    if (
      ![REPORT_MCP_TYPE_ID, REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID].includes(report_type_id) &&
      assignmentId === ASSIGNMENT_TYPE_MEDICAL_REVIEWER
    ) {
      setAlertMessage('This report cannot be assigned a Medical Reviewer');
    } else if (alertMessage) {
      setAlertMessage('');
    }
  };

  const handleChangeClient = id => {
    setSelectedClient(id);
    setAllReports([]);

    if (alertMessage) setAlertMessage('');

    API.GetClaimantByClientId(id).then(res => {
      setAllClaimants(res.claimants);
      setNewAssignment({
        ...newAssignment,
        claimant_id: null,
        report_id: null,
      });
    });
  };

  const handleChangeClaimant = id => {
    setNewAssignment({ ...newAssignment, claimant_id: id });
    setAllReports([]);
    if (alertMessage) setAlertMessage('');

    API.getReportsByClaimantId(id).then(res => {
      setAllReports(res.reports);
    });
  };

  const isValidAssignment =
    newAssignment.assignment_type_id &&
    newAssignment.user_id &&
    newAssignment.claimant_id &&
    newAssignment.start_date &&
    !alertMessage &&
    newAssignment.end_date &&
    ((newAssignment.assignment_type_id !== ASSIGNMENT_TYPE_RA && newAssignment.report_id) ||
      newAssignment.assignment_type_id === ASSIGNMENT_TYPE_RA);

  const disabledSelect =
    !isAllowed(roles, [
      ...ROLES_DIVISIONS.SUPERADMIN,
      ...ROLES_DIVISIONS.NURSE_ONLY,
      ...ROLES_DIVISIONS.SALES_MANAGER,
    ]) && newAssignment?.assignment_type_id === ASSIGNMENT_TYPE_MEDICAL_REVIEWER;

  const disabledAssignmentType = isAdmin
    ? !!disabledField?.assignmentType
    : !!reportId || !!disabledField?.assignmentType;

  return (
    <SpinBox loading={pending}>
      <Row align="middle">
        <Col xs={24}>
          <Row center="xs" justify="center">
            <Title level={4} style={{ marginTop: '15px', textAlign: 'center' }}>
              {edit.user_id ? messages.headerTitleEdit : messages.headerTitle}
            </Title>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ borderTop: '1px solid #e0dcdc' }}></Col>
      </Row>

      <Content style={{ padding: '20px 50px' }} data-test-id="modalAssig">
        <Row type="flex" align="middle" className="report-body">
          {alertMessage && (
            <Row type="flex" justify="center" align="middle" className="w-100 createReport_alert textCenter">
              <Text level={4} type="secondary">
                {alertMessage}
              </Text>
            </Row>
          )}

          <Row type="flex" justify="space-between" align="middle" className="w-100">
            <Col md={24} className="row">
              <Row className="padding-5">
                <Text level={4} type="secondary">
                  Assignment Type
                </Text>
              </Row>
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                disabled={disabledAssignmentType}
                showSearch
                data-test-id="modalAssig_assignmentType"
                placeholder="Please Select"
                optionFilterProp="children"
                style={{ width: '100%' }}
                value={newAssignment.assignment_type_id}
                onChange={handleSelectAssigmentType}>
                {assignmentTypes
                  .filter(item => ![ASSIGNMENT_TYPE_RA, ASSIGNMENT_TYPE_AQ].includes(item.id))
                  .sort((x, y) => x.order - y.order)
                  .map(assignment => (
                    <Option value={assignment.id} key={assignment.id}>
                      {assignment.type_information}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>

          {activeSelectSpecialty && (
            <Row type="flex" justify="space-between" align="middle" className="w-100">
              <Col md={24} className="row">
                <Row className="padding-5">
                  <Text level={4} type="secondary">
                    Specialties
                  </Text>
                </Row>
                <Select
                  showSearch
                  disabled={disabledSelect}
                  placeholder="Please Select Specialty"
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  value={selectedSpecialty}
                  onChange={handleChangeSpecialty}>
                  {SPECIALTY_REPORT.map(specialty => (
                    <Option value={specialty.id} key={specialty.id}>
                      {specialty.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}

          {!reportId && (
            <Row type="flex" justify="space-between" align="middle" className="w-100">
              <Col md={11} className="row">
                <Row className="padding-5">
                  <Text level={5} type="secondary">
                    Client
                  </Text>
                </Row>
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  disabled={!!reportId || !!disabledField?.client || disabledSelect}
                  showSearch
                  value={selectedClient}
                  data-test-id="modalAssig_client"
                  placeholder={messages.placeholder}
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  onChange={handleChangeClient}>
                  {allClients.map((client, index) => {
                    return (
                      <Option value={client.id} key={index}>
                        <Avatar size={24} icon={<UserOutlined />} />
                        <span level={5} style={{ marginLeft: '5px' }} />
                        {client.client_name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col md={11} className="row">
                <Row className="padding-5">
                  <Text level={5} type="secondary">
                    Claimants
                  </Text>
                </Row>
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  disabled={!!disabledField?.claimant || disabledSelect}
                  showSearch
                  placeholder="Select a Claimant"
                  data-test-id="modalAssig_claimant"
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  value={pending ? null : newAssignment.claimant_id}
                  onChange={handleChangeClaimant}>
                  {allClaimants.map((claimant, index) => {
                    return (
                      <Option value={claimant.id} key={index}>
                        {claimant.claimant_name + ' ' + claimant.claimant_last_name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          )}

          <Row type="flex" justify="space-between" align="start" className="w-100">
            <Col md={!reportId ? 11 : 24} className="row">
              <Row className="padding-5">
                <Text level={5} type="secondary">
                  Assign To
                </Text>
              </Row>
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                disabled={!newAssignment.assignment_type_id || disabledSelect || disabledField?.user}
                value={pending ? null : newAssignment.user_id}
                showSearch
                data-test-id="modalAssig_assignTo"
                placeholder="Select a User"
                optionFilterProp="children"
                style={{ width: '100%' }}
                onChange={id => {
                  setNewAssignment({ ...newAssignment, user_id: id });
                }}>
                {allUsers.map(user => {
                  return (
                    <Option value={user.id} key={user.id} className="assigmentOption_assignTo">
                      {`${user.given_name} ${user.family_name} ${
                        ASSIGNMENT_ROLES_ADMIN_PORTAL.includes(newAssignment.assignment_type_id)
                          ? `$${user?.cost_per_review || '0'}`
                          : ''
                      }`}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {!reportId && (
              <Col md={11} className="row">
                <Row className="padding-5">
                  <Text level={5} type="secondary">
                    Report
                  </Text>
                </Row>
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  showSearch
                  placeholder="Select Report"
                  optionFilterProp="children"
                  data-test-id="modalAssig_report"
                  style={{ width: '100%' }}
                  value={pending ? null : newAssignment.report_id}
                  disabled={
                    newAssignment.assignment_type_id === ASSIGNMENT_TYPE_RA || !!disabledField?.report || disabledSelect
                  }
                  onChange={handleSelectedReport}>
                  {allReports.map((report, index) => {
                    return (
                      <Option key={index} value={report.id}>
                        {report.report_uuid} - {report.report_name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            )}
          </Row>

          <Row type="flex" justify="space-between" align="middle" className="w-100">
            <Col md={11} className="row">
              <Row className="padding-5">
                <Text level={5} type="secondary">
                  Start Date
                </Text>
              </Row>
              <DatePickerSimple
                onChange={onChangeDatePicker}
                style={{ width: '100%' }}
                selected={newAssignment.start_date.toDate()}
                minDate={new Date()}
                defaultValue={newAssignment.start_date.toDate()}
                required
                todayButton="Today"
              />
            </Col>
            <Col md={11} className="row">
              <Row className="padding-5">
                <Text level={5} type="secondary">
                  Due Date
                </Text>
              </Row>
              <DatePickerSimple
                onChange={onFinishDatePicker}
                style={{ width: '100%' }}
                selected={newAssignment.end_date.toDate()}
                minDate={newAssignment.start_date.toDate()}
                defaultValue={newAssignment.start_date.toDate()}
                required
                todayButton="Today"
              />
            </Col>
          </Row>

          {assignmentBoardPage && ASSIGNMENT_BOARD_PERMISSION.viewNeedBy && (
            <Row type="flex" justify="space-between" align="middle" className="w-100">
              <Col md={11} className="row">
                <Row className="padding-5">
                  <Text level={5} type="secondary">
                    Need By
                  </Text>
                </Row>
                <DatePickerSimple
                  disabled
                  onChange={onChangeDatePicker}
                  style={{ width: '100%' }}
                  selected={newAssignment?.report?.needed_by ? moment(newAssignment.report.needed_by).toDate() : null}
                  minDate={new Date()}
                  defaultValue={
                    newAssignment?.report?.needed_by ? moment(newAssignment.report.needed_by).toDate() : null
                  }
                  required
                  todayButton="Today"
                />
              </Col>
            </Row>
          )}

          {assignmentBoardPage && newAssignment.assignment_type_id === ASSIGNMENT_TYPE_NURSE && (
            <>
              <Row type="flex" justify="space-between" align="middle" className="w-100" style={{ paddingBottom: 10 }}>
                <Col md={24}>
                  <Row className="padding-5">
                    <Text level={5} type="secondary">
                      Number of Medicals
                    </Text>
                  </Row>
                  <Input
                    size="large"
                    placeholder="Please enter your Number of Medicals"
                    style={{ width: '100%' }}
                    value={newAssignment.num_meds}
                    name="note"
                    type="number"
                    onChange={e => setNewAssignment({ ...newAssignment, num_meds: e.target.value })}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="w-100" style={{ paddingBottom: 10 }}>
                <Col md={24}>
                  <Row className="padding-5">
                    <Text level={5} type="secondary">
                      Do rush fees apply?
                    </Text>
                  </Row>
                  <div className="assignment_applyFees">
                    <CustomCheckBox
                      optionSignature={true}
                      onChange={() => setNewAssignment({ ...newAssignment, rush_fees: true })}
                      label="Yes"
                      htmlId="rushFeesYes"
                      value={newAssignment.rush_fees}
                    />
                    <CustomCheckBox
                      optionSignature={false}
                      onChange={() => setNewAssignment({ ...newAssignment, rush_fees: false })}
                      label="No"
                      htmlId="rushFeesNo"
                      value={newAssignment.rush_fees}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row type="flex" justify="space-between" align="middle" className="w-100" style={{ paddingBottom: 10 }}>
            <Col md={24}>
              <Row className="padding-5">
                <Text level={5} type="secondary">
                  Note
                </Text>
              </Row>
              <Input
                size="large"
                placeholder="Please enter your Note"
                style={{ width: '100%' }}
                value={newAssignment.note}
                data-test-id="modalAssignment_note"
                name="note"
                onChange={e => setNewAssignment({ ...newAssignment, note: e.target.value })}
              />
            </Col>
          </Row>

          {/* if you select a role from the admin portal, show an extra charge alert */}
          {ASSIGNMENT_ROLES_ADMIN_PORTAL.includes(newAssignment.assignment_type_id) && newAssignment.user_id && (
            <Row type="flex" justify="space-between" align="middle" className="w-100" style={{ paddingBottom: 10 }}>
              <Col md={24} className="assigment_alert">
                <Text level={3} type="secondary">
                  <strong>
                    This will cost ${allUsers.find(user => user.id === newAssignment.user_id)?.cost_per_review || 0}
                  </strong>
                  <br /> You will be charged immediately to your default card on file
                </Text>
              </Col>
            </Row>
          )}
        </Row>

        <Row justify="center">
          <CustomButton
            disabled={!isValidAssignment}
            backgroundcolor={PRIMARY_LIGHT_GREEN}
            color={PRIMARY_LIGHT}
            size="large"
            onClick={onSubmitAssignment}
            style={{ marginTop: '15px' }}
            loading={pending}>
            {edit.user_id ? 'Update' : messages.confirmLabel}
          </CustomButton>
        </Row>
      </Content>
    </SpinBox>
  );
};

export default AddAssignForm;
