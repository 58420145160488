import isAllowed, {
  ROLE_SUPERADMIN,
  ROLE_MEDICAL_REVIEWER,
  ROLE_NURSE_WRITER,
  ROLE_QA_NURSE,
  ROLE_CLIENT,
  EXTERNAL_NURSE,
  ATTORNEY_MANAGER,
  INTERNAL_ATTORNEY,
  ROLE_SALES_MANAGER,
  ROLE_SALES_REP,
  ROLE_CLIENT_ADMIN,
  ROLE_EXTERNAL_LITIGATION_ATTORNEY,
  ROLE_MEDICAL_ADMIN,
  ROLE_CLIENT_SUPER_ADMIN,
  ROLE_DIRECTOR_OF_SALES,
  ROLE_EXTERNAL_QA,
  ROLE_MSA_MEDICAL_REVIEWER,
  ROLE_EXTERNAL_AR,
  ROLE_MEDICAL_REVIEWER_MSA,
} from 'layout/roles';

export const SIDEBAR_PERMISSIONS_NAME = 'sidebar';
export const REPORT_PERMISSIONS_NAME = 'report';
export const HELP_TUTORIALS_PERMISSIONS_NAME = 'tutorials';
export const DASHBOARD_PERMISSIONS_NAME = 'dashboard';
export const CLAIMANT_PERMISSIONS_NAME = 'claimant';
export const USERS_PERMISSIONS_NAME = 'users';
export const CABINET_PERMISSIONS_NAME = 'cabinet';
export const MARKETING_PERMISSIONS_NAME = 'marketing';
export const CLIENT_PERMISSIONS_NAME = 'client';
export const NOTIFICATION_PERMISSIONS_NAME = 'notifications';
export const PARENT_FOLDER_NAME = 'parentFolder';
export const REDIRECT_DASHBOARD_NAME = 'redirectDashboard';
export const SETTINGS_NAME = 'settings';
export const LITIGATION_ATTORNEY_NAME = 'litigationAttorney';
export const RESOURCES_LINKS_NAME = 'resourcesLinks';
export const ASSIGNMENT_BOARD_NAME = 'assignmentBoard';
export const INVOICES_NAME = 'invoices';
export const CONTACT_NAME = 'contact';

export const getPermissionsBySection = ({ roles, permissions }) => {
  let SIDEBAR_PERMISSIONS = {};
  let DASHBOARD_PERMISSIONS = {};
  let REPORT_PERMISSIONS = {};
  let HELP_TUTORIALS_PERMISSIONS = {};
  let CLAIMANT_PERMISSIONS = {};
  let USERS_PERMISSIONS = {};
  let CABINET_PERMISSIONS = {};
  let MARKETING_PERMISSIONS = {};
  let CLIENT_PERMISSIONS = {};
  let NOTIFICATION_PERMISSIONS = {};
  let PARENT_FOLDER_PERMISSIONS = {};
  let REDIRECT_DASHBOARD_PERMISSIONS = {};
  let SETTINGS_PERMISSIONS = {};
  let LITIGATION_ATTORNEY_PERMISSION = {};
  let RESOURCES_LINKS_PERMISSION = {};
  let ASSIGNMENT_BOARD_PERMISSION = {};
  let INVOICES_PERMISSION = {};
  let CONTACT_PERMISSION = {};

  if (permissions.includes(REDIRECT_DASHBOARD_NAME))
    REDIRECT_DASHBOARD_PERMISSIONS = {
      parentFolder: isAllowed(roles, [ROLE_DIRECTOR_OF_SALES, ROLE_SALES_MANAGER, ROLE_SALES_REP, ROLE_NURSE_WRITER]),
      myClients: isAllowed(roles, [
        ROLE_MEDICAL_REVIEWER,
        ATTORNEY_MANAGER,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        INTERNAL_ATTORNEY,
      ]),
      assignmentBoard: isAllowed(roles, [ROLE_QA_NURSE, ROLE_EXTERNAL_QA, ROLE_EXTERNAL_AR, ROLE_MSA_MEDICAL_REVIEWER]),
      claimantsByClient: isAllowed(roles, [ROLE_CLIENT, ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN]),
      claimantsByUser: isAllowed(roles, [EXTERNAL_NURSE]),
    };

  if (permissions.includes(SIDEBAR_PERMISSIONS_NAME))
    SIDEBAR_PERMISSIONS = {
      myClaimants: isAllowed(roles, [EXTERNAL_NURSE, ROLE_CLIENT_ADMIN, ROLE_CLIENT, ROLE_CLIENT_SUPER_ADMIN]),
      dashboard: isAllowed(roles, [
        ROLE_SUPERADMIN,
        // ROLE_MEDICAL_REVIEWER,
        // ROLE_NURSE_WRITER,
        // ATTORNEY_MANAGER,
        // INTERNAL_ATTORNEY,
        // ROLE_SALES_MANAGER,
        // ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        // ROLE_DIRECTOR_OF_SALES,
        // ROLE_QA_NURSE,
      ]),
      myClients: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ATTORNEY_MANAGER,
        INTERNAL_ATTORNEY,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      myUsers: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      myUsersClient: isAllowed(roles, [ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN]),
      litigationAttorney: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      assignmentBoard: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        INTERNAL_ATTORNEY,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        EXTERNAL_NURSE,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      notifications: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_CLIENT,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        EXTERNAL_NURSE,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_CLIENT_ADMIN,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      inReview: isAllowed(roles, [
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_SALES_MANAGER,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      support: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_CLIENT,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        EXTERNAL_NURSE,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_CLIENT_ADMIN,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      chat: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_CLIENT,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        EXTERNAL_NURSE,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_CLIENT_ADMIN,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      search: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_NURSE_WRITER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      cabinet: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_NURSE_WRITER, ROLE_EXTERNAL_LITIGATION_ATTORNEY]),
      template: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_MEDICAL_REVIEWER, ROLE_NURSE_WRITER, ROLE_MEDICAL_ADMIN]),
      surgical: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_MEDICAL_REVIEWER, ROLE_NURSE_WRITER, ROLE_MEDICAL_ADMIN]),
      resourceLinks: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_NURSE_WRITER,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
      ]),
      contacts: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ATTORNEY_MANAGER,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_NURSE_WRITER,
      ]),
      queue: isAllowed(roles, []),
      marketing: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_NURSE_WRITER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
      ]),
      helpTutorials: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_CLIENT,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        EXTERNAL_NURSE,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_CLIENT_ADMIN,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
    };

  if (permissions.includes(DASHBOARD_PERMISSIONS_NAME))
    DASHBOARD_PERMISSIONS = {
      view: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
    };

  if (permissions.includes(CLIENT_PERMISSIONS_NAME))
    CLIENT_PERMISSIONS = {
      searchOnlyMe: isAllowed(roles, [ROLE_MEDICAL_REVIEWER, ROLE_NURSE_WRITER, ROLE_SALES_REP, ROLE_SALES_MANAGER]),
      create: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES]),
      mergeClient: isAllowed(roles, [ROLE_SUPERADMIN]),
      edit: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      delete: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
    };

  if (permissions.includes(LITIGATION_ATTORNEY_NAME))
    LITIGATION_ATTORNEY_PERMISSION = {
      setFavorite: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_SALES_MANAGER, ROLE_SALES_REP, ROLE_DIRECTOR_OF_SALES]),
      create: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_ADMIN,
      ])
    };

  if (permissions.includes(CABINET_PERMISSIONS_NAME))
    CABINET_PERMISSIONS = {
      view: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
      ]),
      download: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_MEDICAL_ADMIN,
      ]),
    };

  if (permissions.includes(USERS_PERMISSIONS_NAME))
    USERS_PERMISSIONS = {
      create: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      edit: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      delete: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      createLitigation: isAllowed(roles, [
        ROLE_CLIENT_ADMIN,
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
      ]),
    };

  if (permissions.includes(CLAIMANT_PERMISSIONS_NAME))
    CLAIMANT_PERMISSIONS = {
      view: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        EXTERNAL_NURSE,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      delete: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_MEDICAL_ADMIN,
      ]),
      create: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      edit: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      assignLitigation: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_CLIENT, ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN]),
      requestRatedAge: isAllowed(roles, [ROLE_SUPERADMIN]),
      requestAddInvoice: isAllowed(roles, [ROLE_SUPERADMIN]),
    };

  if (permissions.includes(REPORT_PERMISSIONS_NAME))
    REPORT_PERMISSIONS = {
      create: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      view: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
      ]),
      edit: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_MEDICAL_ADMIN,
      ]),
      editReduction: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      createTable: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_MEDICAL_ADMIN,
      ]),
      download: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT,
        ROLE_MEDICAL_REVIEWER,
        INTERNAL_ATTORNEY,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_SALES_MANAGER,
      ]),
      preview: isAllowed(roles, [
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
      ]),
      assign: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_MEDICAL_ADMIN,
      ]),
      delete: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_MEDICAL_ADMIN,
      ]),
      signature: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
      ]),
      saveDraft: isAllowed(roles, [ROLE_QA_NURSE, ROLE_EXTERNAL_QA, ROLE_MSA_MEDICAL_REVIEWER]),
      cloneReport: isAllowed(roles, [ROLE_QA_NURSE, ROLE_EXTERNAL_QA, ROLE_MSA_MEDICAL_REVIEWER]),
      goBackClaimant: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
      ]),
      alertRushFees: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_CLIENT, ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN]),
      editDesing: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_NURSE_WRITER, ROLE_MEDICAL_ADMIN]),
      notEditLabelUMDemand: isAllowed(roles, [ROLE_CLIENT_ADMIN, ROLE_CLIENT, ROLE_CLIENT_SUPER_ADMIN, ROLE_QA_NURSE]),
      editTableAndPrice: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_NURSE_WRITER]),
    };

  if (permissions.includes(HELP_TUTORIALS_PERMISSIONS_NAME))
    HELP_TUTORIALS_PERMISSIONS = {
      viewDocumentInternal: isAllowed(roles, [
        ROLE_MEDICAL_REVIEWER,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
      ]),
      viewDocumentClient: isAllowed(roles, [ROLE_CLIENT, ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN]),
      viewAllDocument: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      createHeader: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      createMaterials: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      editMaterial: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      deleteMaterial: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
    };

  if (permissions.includes(MARKETING_PERMISSIONS_NAME))
    MARKETING_PERMISSIONS = {
      viewDocumentInternal: isAllowed(roles, [
        ROLE_MEDICAL_REVIEWER,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
      ]),
      viewDocumentClient: isAllowed(roles, [ROLE_CLIENT, ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN]),
      viewAllDocument: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      createHeader: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      createMaterials: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      editMaterial: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
      deleteMaterial: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_MEDICAL_ADMIN]),
    };

  if (permissions.includes(NOTIFICATION_PERMISSIONS_NAME))
    NOTIFICATION_PERMISSIONS = {
      viewTab: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_NURSE_WRITER,
        ROLE_MEDICAL_REVIEWER,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_CLIENT,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_MEDICAL_ADMIN,
      ]),
    };

  if (permissions.includes(PARENT_FOLDER_NAME))
    PARENT_FOLDER_PERMISSIONS = {
      open: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_NURSE_WRITER,
      ]),
      create: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_NURSE_WRITER,
      ]),
      share: isAllowed(roles, [ROLE_SUPERADMIN]),
      assignClientSuperAdmin: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_NURSE_WRITER,
      ]),
      deleteClientSuperAdmin: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_NURSE_WRITER,
      ]),
      edit: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_NURSE_WRITER,
      ]),
      delete: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_NURSE_WRITER,
      ]),
      removeFolder: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_NURSE_WRITER,
      ]),
      assignSalesManager: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_DIRECTOR_OF_SALES, ROLE_NURSE_WRITER]),
    };

  if (permissions.includes(SETTINGS_NAME))
    SETTINGS_PERMISSIONS = {
      assignSalesRep: isAllowed(roles, [ROLE_CLIENT, ROLE_CLIENT_ADMIN, ROLE_CLIENT_SUPER_ADMIN]),
      assignSalesManager: isAllowed(roles, [ROLE_SALES_REP]),
      timeOff: isAllowed(roles, [
        ROLE_QA_NURSE,
        ROLE_MSA_MEDICAL_REVIEWER,
        ATTORNEY_MANAGER,
        
        ROLE_MEDICAL_REVIEWER,
        ROLE_EXTERNAL_QA,
        ROLE_EXTERNAL_AR,
        ROLE_MEDICAL_REVIEWER_MSA,
      ]),
      notification: isAllowed(roles, [ROLE_EXTERNAL_QA, ROLE_EXTERNAL_AR, ROLE_MSA_MEDICAL_REVIEWER]),
      billingInfo: isAllowed(roles, [ROLE_SUPERADMIN, ROLE_NURSE_WRITER, ROLE_CLIENT_ADMIN]),
    };

  if (permissions.includes(RESOURCES_LINKS_NAME))
    RESOURCES_LINKS_PERMISSION = {
      create: isAllowed(roles, [ROLE_SUPERADMIN]),
      edit: isAllowed(roles, [ROLE_SUPERADMIN]),
      delete: isAllowed(roles, [ROLE_SUPERADMIN]),
    };

  if (permissions.includes(ASSIGNMENT_BOARD_NAME))
    ASSIGNMENT_BOARD_PERMISSION = {
      viewNeedBy: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_NURSE_WRITER,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_MEDICAL_REVIEWER,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_MEDICAL_ADMIN,
      ]),
    };

  if (permissions.includes(INVOICES_NAME))
    INVOICES_PERMISSION = {
      actions: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        ROLE_QA_NURSE,
        ROLE_EXTERNAL_QA,
        ROLE_MSA_MEDICAL_REVIEWER,
        ROLE_CLIENT_ADMIN,
        ROLE_CLIENT,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        INTERNAL_ATTORNEY,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_CLIENT_SUPER_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
      ]),
      receivePayment: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
      ]),
      edit: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
      ]),
      delete: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_NURSE_WRITER,
        EXTERNAL_NURSE,
        INTERNAL_ATTORNEY,
        ATTORNEY_MANAGER,
        ROLE_EXTERNAL_AR,
        ROLE_EXTERNAL_LITIGATION_ATTORNEY,
      ]),
      payInvoice: isAllowed(roles, [ROLE_CLIENT_ADMIN, ROLE_CLIENT, ROLE_CLIENT_SUPER_ADMIN]),
    };

  if (permissions.includes(CONTACT_NAME))
    CONTACT_PERMISSION = {
      actions: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ATTORNEY_MANAGER,
        ROLE_NURSE_WRITER,
      ]),
      edit: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ATTORNEY_MANAGER,
        ROLE_NURSE_WRITER,
      ]),
      delete: isAllowed(roles, [
        ROLE_SUPERADMIN,
        ROLE_MEDICAL_REVIEWER,
        ROLE_SALES_MANAGER,
        ROLE_SALES_REP,
        ROLE_MEDICAL_ADMIN,
        ROLE_DIRECTOR_OF_SALES,
        ATTORNEY_MANAGER,
        ROLE_NURSE_WRITER,
      ]),
    };

  return {
    REPORT_PERMISSIONS,
    HELP_TUTORIALS_PERMISSIONS,
    SIDEBAR_PERMISSIONS,
    DASHBOARD_PERMISSIONS,
    CLAIMANT_PERMISSIONS,
    USERS_PERMISSIONS,
    CABINET_PERMISSIONS,
    MARKETING_PERMISSIONS,
    CLIENT_PERMISSIONS,
    NOTIFICATION_PERMISSIONS,
    PARENT_FOLDER_PERMISSIONS,
    REDIRECT_DASHBOARD_PERMISSIONS,
    SETTINGS_PERMISSIONS,
    LITIGATION_ATTORNEY_PERMISSION,
    RESOURCES_LINKS_PERMISSION,
    ASSIGNMENT_BOARD_PERMISSION,
    INVOICES_PERMISSION,
    CONTACT_PERMISSION,
  };
};
