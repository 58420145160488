import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as QueryString from 'query-string';
import qs from '../../utils/create-query-string';
import Pagination from "./Pagination";

const PaginationHOC = props => {
  const history = useHistory();

  const { count = 100 } = props;
  const { limit } = useSelector(({ application }) => application.settings);

  const query = QueryString.parse(history.location.search);
  const page = query.page || 0;

  const { url } = useRouteMatch();

  const handleChangePage = newPage => {
    history.push(`${url}${qs({ ...query, page: newPage })}`);
  };

  return (
    <Pagination
      totalCount={count}
      pageIndex={+page}
      onChange={handleChangePage}
      pageSize={limit}
    />
  );
};

export default PaginationHOC;
