import { useState } from 'react';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';

export default function useTransaction() {
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [listTransaction, setListTransaction] = useState([]);
  const [countTransaction, setCountTransaction] = useState(0);
  const [pageTransaction, setPageTransaction] = useState(0);

  const [pageMonthToDate, setPageMonthToDate] = useState(0);
  const [listMonthToDate, setListMonthToDate] = useState([]);
  const [countMonthToDate, setCountMonthToDate] = useState(0);
  const [totalPriceMonthToDate, setTotalPriceMonthToDate] = useState(0);

  const getTransactions = async ({ page = 0 } = {}) => {
    try {
      setLoadingTransaction(true);
      const transactions = await API.getTransactionHistory({ page });
      setPageTransaction(page);
      setListTransaction(transactions?.rows || []);
      setCountTransaction(transactions?.count || 0);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingTransaction(false);
    }
  };

  const getMonthToDateCostHook = async ({ page = 0 } = {}) => {
    try {
      setLoadingTransaction(true);
      const transactions = await API.getMonthToDateCostService({ page });
      setPageMonthToDate(page);
      setListMonthToDate(transactions?.reports?.rows || []);
      setCountMonthToDate(transactions?.reports?.count || 0);
      setTotalPriceMonthToDate(transactions?.total ?? 0);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingTransaction(false);
    }
  };

  const downLoadTransactionHook = async ({ id }) => {
    try {
      setLoadingTransaction(true);
      const res = await API.downloadTransactionService({ id });
      return res;
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingTransaction(false);
    }
  }

  const paginationTransaction = async ({ page }) => {
    if (page < 0) {
      return await getTransactions();
    }

    await getTransactions({ page });
  };

  const paginationMonthToDateHook = async ({ page }) => {
    if (page < 0) {
      return await getMonthToDateCostHook();
    }

    await getMonthToDateCostHook({ page });
  };  

  return {
    loadingTransaction,
    listTransaction,
    countTransaction,
    pageTransaction,
    pageMonthToDate,
    listMonthToDate,
    countMonthToDate,
    totalPriceMonthToDate,
    getTransactions,
    paginationTransaction,
    getMonthToDateCostHook,
    downLoadTransactionHook,
    paginationMonthToDateHook,
  };
}
