import { Button, Col, Modal, Row } from "antd";
import InputBox from "components/InputBox";
import SpinBox from "components/SpinBox";
import React from "react";
import "../ReportModal/ReportModal.scss";

export default function Index({
  isOpen,
  onCancel,
  onChangeName,
  EditFile,
  onFileSubmit,
  loading,
}) {
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Update File"}
      >
        <Row type="flex" align="middle" className="report-body">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <SpinBox loading={loading}>
              <Col md={24}>
                <InputBox
                  label="Name"
                  name="name"
                  value={EditFile.name}
                  onChange={onChangeName}
                />
              </Col>
            </SpinBox>
          </Row>

          <Button
            type="primary"
            size="large"
            className="green-btn button"
            onClick={onFileSubmit}
          >
            Update
          </Button>
        </Row>
      </Modal>
    </div>
  );
}
