import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Empty, Input, Modal, Row, Typography, Select, Icon } from 'antd';

// Components
import { EditIcon, RemoveIcon, TrashIcon } from 'components/Icons';
import Pagination from 'components/Pagination';
import SpinBox from 'components/SpinBox';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

// Utils
import * as API from 'utils/api';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { getOrganizations } from 'utils/api-contacts';
import { capitalizeList } from 'utils/utils';
import { CONTACT_NAME, getPermissionsBySection } from 'permissions';

// Style
import './Contacts.scss';

const { Search } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

class Contacts extends Component {
  constructor(props) {
    super();

    // Permissions
    const { CONTACT_PERMISSION } = getPermissionsBySection({
      roles: props.roles,
      permissions: [CONTACT_NAME],
    });
    // end permissions

    this.state = {
      pageIndex: 0,
      querySearched: false,
      showModal: false,
      contactsData: [],
      selectedUser: {},
      searchQuery: '',
      loadingTitle: 'Loading...',
      loading: true,
      contact_list: [],
      organization_list: [],
      contactTypeFilter: [],
      organizationFilter: [],
      CONTACT_PERMISSION,
    };

    this.handlePagination = this.handlePagination.bind(this);
    this.handleDeleteContact = this.handleDeleteContact.bind(this);
    this.handleEditContact = this.handleEditContact.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.countCols = this.countCols.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  async componentDidMount() {
    try {

      this.handleSearch({ activeLoading: false });

      const contectType = await API.getContactTypes();
      const { contacts = [] } = await getOrganizations({ all: true, tenant_only: false });

      const uniqueArr = contacts.reduce((acc, current) => {
        const isExist = acc.find(item => item.organisation_name === current.organisation_name);

        if (!isExist) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      this.setState({
        contact_list: capitalizeList({ list: contectType.contact_type, field: 'name' }),
        organization_list: uniqueArr,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handlePagination(pageIndex) {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  }

  async handleDeleteContact(item) {
    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: 'Deleting...',
    });

    try {
      const isUserAdmin = isAllowed(this.props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.SALES_MANAGER,
      ]);
      const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

      const params = isUserNurseManager && !isUserAdmin ? { tenantOnly: true } : {};

      await API.deleteContact(item.id, params);

      notifyInfo('Contact was deleted');

      this.setState({
        contactsData: this.state.contactsData.filter(contact => contact.id !== item.id),
        totalCount: this.state.totalCount - 1,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleEditContact(item) {
    this.props.history.push('/app/contacts/edit', { data: item });
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
    });
  }

  handleOpenModal(item) {
    this.setState({
      showModal: true,
      selectedUser: item,
    });
  }

  async handleSearch({
    activeLoading = true,
    contactTypeFilter = this.state.contactTypeFilter,
    organizationFilter = this.state.organizationFilter,
  } = {}) {
    const { searchQuery, pageIndex } = this.state;
    if (activeLoading) this.setState({ loading: true, loadingTitle: 'Loading...' });

    let index = pageIndex;
    const apiCall = tenantOnly =>
      API.getContactsData({
        searchQuery,
        pageIndex: index,
        tenantOnly,
        filterType: contactTypeFilter.join(','),
        filterOrganization: organizationFilter.join(','),
      });

    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

    try {
      const res = isUserNurseManager && !isUserAdmin ? await apiCall(true) : await apiCall();
      this.setState({
        contactsData: res.contacts,
        totalCount: res.total_count,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      if (activeLoading) this.setState({ loading: false });
    }
  }

  countCols(width) {
    if (!this.state.CONTACT_PERMISSION.actions) {
      return width + 1;
    }

    return width;
  }

  handleFilter({ field, value }) {
    const { contactTypeFilter, organizationFilter } = this.state;
    const search = {
      contactTypeFilter,
      organizationFilter,
      [field]: value,
    };

    this.setState({ [field]: value });
    this.handleSearch({ contactTypeFilter: search.contactTypeFilter, organizationFilter: search.organizationFilter });
  }

  render() {
    const {
      pageIndex,
      totalCount,
      contactsData,
      loading,
      loadingTitle,
      showModal,
      selectedUser,
      contact_list,
      organization_list,
      CONTACT_PERMISSION,
    } = this.state;

    return (
      <Row className="contacts-container">
        <Row type="flex" align="middle" className="header header_filter">
          <Col className="title">
            <Title level={4} className="title-text">
              Contacts
            </Title>
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              className="search-box"
              onChange={e => this.setState({ searchQuery: e.target.value, pageIndex: 0 })}
              onSearch={this.handleSearch}
            />

            <div className="search_filter">
              <Select
                showSearch
                placeholder="Contact Type"
                optionFilterProp="children"
                className="dropdown"
                mode="multiple"
                onChange={value => this.handleFilter({ field: 'contactTypeFilter', value })}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                {contact_list.map(el => {
                  return (
                    <Option key={el.id} value={el.name}>
                      {el.name}
                    </Option>
                  );
                })}
              </Select>

              <Select
                showSearch
                placeholder="Organization Name"
                optionFilterProp="children"
                className="dropdown"
                mode="multiple"
                onChange={value => this.handleFilter({ field: 'organizationFilter', value })}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                {organization_list.map(el => {
                  return (
                    <Option key={el.id} value={el.organisation_name}>
                      {el.organisation_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Col>
          <Col className="pagination">
            <Pagination onChange={this.handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
          </Col>
          <Col className="add-button">
            <Button type="primary" className="green-btn" onClick={() => this.props.history.push('/app/contacts/add')}>
              Add Contacts
            </Button>
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={this.countCols(5)}>Contact Name</Col>
          <Col md={this.countCols(5)}>Contact Type</Col>
          <Col md={this.countCols(5)}>Organization Name</Col>
          <Col md={this.countCols(5)}>Email</Col>
          {CONTACT_PERMISSION.actions && (
            <Col md={4} style={{ textAlign: 'right' }}>
              Actions
            </Col>
          )}
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {contactsData.length > 0 ? (
              <Row>
                {contactsData.map((item, index) => (
                  <Row key={index} className="record">
                    <Col md={this.countCols(5)}>
                      <Text className="textCapitalize">{item.contact_name}</Text>
                    </Col>
                    <Col md={this.countCols(5)}>
                      <Text className="textCapitalize">{item.contact_type ? item.contact_type.name : ''}</Text>
                    </Col>
                    <Col md={this.countCols(5)}>
                      <Text>{item.organisation_name}</Text>
                    </Col>
                    <Col md={this.countCols(5)}>
                      <Text>{item.email}</Text>
                    </Col>
                    {CONTACT_PERMISSION.actions && (
                      <Col md={4} className="actions">
                        <Row>
                          {CONTACT_PERMISSION.edit && (
                            <EditIcon className="icon" onClick={() => this.handleEditContact(item)} />
                          )}
                          {CONTACT_PERMISSION.delete && (
                            <RemoveIcon className="icon" onClick={() => this.handleOpenModal(item)} />
                          )}
                        </Row>
                      </Col>
                    )}
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                description={loading ? false : 'No Data'}
                image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
        <Modal
          visible={showModal}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={false}
          width={400}
          className="delete-modal"
          title={null}>
          <Row type="flex" align="middle" className="delete-body">
            <Row className="trash-icon">
              <TrashIcon />
            </Row>
            <Row>
              <Text className="label">Delete this contact?</Text>
            </Row>
            <Row type="flex" align="middle" className="user-info">
              <Col className="user-name">
                <Row>
                  <Text ellipsis className="text-row name">
                    {selectedUser.contact_name}
                  </Text>
                </Row>
                <Row>
                  <Text ellipsis className="text-row">
                    {selectedUser.email}
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Button type="primary" className="pink-btn button" onClick={() => this.handleDeleteContact(selectedUser)}>
                Delete
              </Button>
            </Row>
            <Row className="cancel-button">
              <Text onClick={this.handleCloseModal}>Cancel</Text>
            </Row>
          </Row>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, null)(Contacts);
