import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RemoveIcon } from 'components/Icons';
import AddContactModal from './Components/AddContactModal';
import { Spin } from 'antd';
import * as API from 'utils/api';
import dynamicData from 'dynamicData';
import '../EditClient.scss';

const ContactsTab = () => {
  const history = useHistory();
  const clientInfo = history.location.state?.data || {};

  const [openAddModal, setOpenAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listContact, setListContact] = useState([]);

  const getContacts = async () => {
    try {
      const res = await API.getContactsByTenant({ tenant_id: clientInfo.tenant_id, exclude: dynamicData().treating_providers_id });
      setListContact(res.contacts);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async ({ id }) => {
    setLoading(true);
    await API.deleteContact(id, {});
    const listRemove = listContact.filter(contact => contact.id !== id);
    setListContact(listRemove);
    setLoading(false);
  };

  const handleAddContact = async ({ data }) => {
    setLoading(true);
    await API.updateContact(data.id, { tenant_id: clientInfo.tenant_id });
    setListContact(prevListContact => [data, ...prevListContact]);
    setLoading(false);
  };

  const thereIsContact = listContact.length > 0;

  return (
    <Spin spinning={loading}>
      <div className="msa-stepContainer">
        <div className="stepFormContainerStep2">
          {thereIsContact &&
            listContact.map(item => (
              <div className="stepContainerContact" key={item.id}>
                <div className="stepContactItem stepCol2">
                  <h2>{item.contact_name}</h2>
                </div>
                <div className="stepContactItemButtons">
                  <button onClick={() => handleDelete({ id: item.id })}>
                    <RemoveIcon />
                  </button>
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">Contact Type</p>
                  <p className="stepContactItemValue">{item.contact_type?.name || ''}</p>
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">Email</p>
                  <p className="stepContactItemValue">{item.email}</p>
                </div>
                <div className="stepContactItem">
                  <p className="stepContactItemLabel">Phone</p>
                  <p className="stepContactItemValue">{item.phone_number}</p>
                </div>
                <div className="stepContactItem stepCol3">
                  <p className="stepContactItemLabel">Full Address</p>
                  <p className="stepContactItemValue">{item.address}</p>
                </div>
              </div>
            ))}

          <button className="addAdditionalContactButton margin-button" onClick={() => setOpenAddModal(true)}>
            Add Additional Contact
          </button>
        </div>

        {openAddModal && <AddContactModal myContact={listContact.map(item => item.id)} onAdd={handleAddContact} close={() => setOpenAddModal(false)} />}
      </div>
    </Spin>
  );
};

export default ContactsTab;
