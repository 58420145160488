import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import AddAssignForm from 'containers/Calendar/src/src/containers/base/AddAssignForm/index.js';
import {
  Badge,
  Button,
  Col,
  Divider,
  Empty,
  message,
  Modal,
  Row,
  Tabs,
  Typography,
  Upload,
  Collapse,
  Input,
  Select,
  Icon,
  DatePicker,
} from 'antd';
import moment from 'moment';
import axios from 'axios';
import {
  ATTORNEY_CLAIMANT_NAV,
  CLAIMANTS_BY_CLIENT_NAV,
  CLAIMANT_BY_USER_NAV,
  CLAIMANT_EDIT,
  TEMPLATE_CREATE_STRUCTURE_NAV,
} from 'layout/navigate_const';
import DeleteModal from 'components/DeleteModal';
import { BackIcon, DetailsIcon, EditIcon, RemoveIcon } from 'components/Icons';
import Pagination from 'components/Pagination';
import SpinBox from 'components/SpinBox';
import CalculatedRatedAges from 'containers/CalculatedRatedAges';
import RatedAgesData from 'containers/RatedAges/RatedAgesData';
import * as API from 'utils/api';
import isAllowed, { isClient, isExternal, ROLES_DIVISIONS, getClientId } from '../../../layout/roles';
import { fetchTransactions } from '../../../utils/api-transactions';
import { notifyApiError, notifyError, notifyInfo, notifySuccess } from '../../../utils/notification';
import FilePhotoModal from './FilesModal/FilePhotoModal';
import UpdateFile from './FilesModal/UpdateFile';
import InternalComments from './InternalComments/InternalComments';
import NoteModal from './NoteModal';
import ReportModal from './ReportModal';
import RequestAgeModal from './RequestAgeModal/Index';
import RequestRatedAgeModal from './RequestRatedAgeModal/RequestModal';
import ChooseReportsModal from './Transactions/ChooseReportsModal';
import TransactionList from './Transactions/TransactionList';
import { clearReportFiles } from 'redux/report/actions';
import uploadValidFileTypes from '../../../constants/report-upload-file-types';
import {
  REPORT_SECTIONS_DEFAULT,
  REPORT_TYPES_BY_ID,
  REPORT_MSA_REDUCTION_TYPE_ID,
  REPORT_MSA_TYPE_ID,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  IGNORE_FIELD_REPORT_SELECT_TEMPLATE_AND_COPY,
  PRIORITIZE_FIELD_SELECT_TEMPLATE_AND_COPY,
} from '../../../constants/reports';
import { goFirstStep } from '../../../utils/report-routing';
import './DetailedClaimant.scss';
import { ASSIGNMENT_STATUS_COMPLETED } from 'containers/Reviews/assignment-statuses';
import UploadFileModal from 'containers/Reports/components/UploadFileModal/UploadFileModal';
import { REPORT_FIELD_MEDICAL_RECORDS } from 'constants/reportfieldsUploadFile';
import {
  TAB_REPORTS_NUMBER,
  TAB_CHAT_NUMBER,
  TAB_COMMENTS_NUMBER,
  TAB_FILES_NUMBER,
  TAB_RATED_AGE_NUMBER,
  TAB_INVOICING_NUMBER,
  TAB_NOTES_NUMBER,
  TAB_NOTIFICATION_NUMBER,
} from 'constants/tabClaimantDetails';
import ModalReview from './ModalReview/ModalReview';
import {
  STATUS_REVISION_REQUESTED,
  STATUS_REDUCTION_COMPLETED,
  STATUS_IN_SUPERVISOR_REVIEW,
} from 'constants/report_status';
import { ASSIGNMENT_TYPE_NURSE, ASSIGNMENT_TYPE_LEGAL_NURSE } from 'constants/assignment-types';
import { ReportList } from './Reports';
import { assignedTemplateInReportLCP } from 'containers/Reports/utils/assignedTemplateInReportLCP';
import {
  CLAIMANT_PERMISSIONS_NAME,
  getPermissionsBySection,
  REPORT_PERMISSIONS_NAME,
  NOTIFICATION_PERMISSIONS_NAME,
} from 'permissions';
import { BadgeNotification, TabNotifications } from './TabNotifications';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { OPTION_CREATE_LCP } from './ReportModal/ReportModal';
import { TooltipAboutFileMSA } from 'components/TooltipAboutFileMSA';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { ModalUpgratePackageOpenReport } from 'components/Modal/ModalUpgratePackageOpenReport';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { confirm, warning } = Modal;
const { Panel } = Collapse;
const { Search } = Input;
const { Option } = Select;

const INTERNAL_COMMENTS = 'InternalComments';
const EXTERNAL_COMMENTS = 'ExternalComments';

const initialClaimantState = {
  totalComments: 0,
  isAdmin: false,
  claimantInfo: {},
  isShowDetail: false,
  selectedTab: TAB_REPORTS_NUMBER,
  reportsData: [],
  reportsTotalCount: 0,
  reportsPageIndex: 0,
  reportStatuses: [],
  reportTypes: [],
  notesData: [],
  notesTotalCount: 0,
  notesPageIndex: 0,
  usersData: [],
  selectedReport: null,
  showReportDeleteModal: false,
  showReportModal: false,
  selectedNote: {},
  showNoteDeleteModal: false,
  showNoteModal: false,
  showAgeModal: false,
  showRequestModal: false,
  showRequestModalNoAdmin: false,
  modalType: 'Add',
  loading: true,
  ReportName: 'Sample',
  RequestName: 'John',
  SentToName: 'Kyle',
  Status: 'Pending',
  reportFiles: '',
  name: '',
  type: '',
  url: '',
  uploadedFiles: [],
  showFileModal: false,
  showFilePhotoModal: false,
  EditFile: '',
  renderUploadedImage: {},
  GetAllAssignemetData: [],
  selectUser: '',
  endDate: '',
  notes: '',
  fileValue: [],
  commentView: EXTERNAL_COMMENTS,
  openCalculateModal: false,
  templates: [],
  showChooseReportsModal: false,
  reportDefaultTransaction: null,
  transactionsData: [],
  transactionsTotalCount: 0,
  editedTransaction: null,
  chatMessages: [],
  filter: {
    search: '',
    type: '',
    date: '',
  },
  fileBackOut: [],
  openModalReview: {
    open: false,
    report: null,
  },
  dataUploadFile: {},
  openModalUploadFile: false,
  modalAssignOpen: false,
  editedparams: {},
  assignedReportId: null,
  assignmentTypeDisabled: false,
  loadingInternal: false,
  assignedUserDisabled: false,
  openModalUpgradedPlan: false,
};

const typeFileList = ['jpg', 'pdf', 'png', 'zip'];

class DetailedClaimant extends Component {
  constructor(props) {
    super(props);

    let claimantInfo = {};
    if (this.props.location && this.props.location.state) {
      claimantInfo = this.props.location.state.data;
    }
    if (!claimantInfo.id) {
      claimantInfo = JSON.parse(localStorage.getItem('claimant'));
    }

    // Permissions
    const { REPORT_PERMISSIONS, CLAIMANT_PERMISSIONS, NOTIFICATION_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME, CLAIMANT_PERMISSIONS_NAME, NOTIFICATION_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      ...initialClaimantState,
      claimantInfo,
      isClient: isClient(props.roles),
      isAdmin: isAllowed(props.roles, ROLES_DIVISIONS.MANAGEMENT),
      isNurseManager: isAllowed(props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.MANAGER,
        ...ROLES_DIVISIONS.NURSE_ONLY,
        ...ROLES_DIVISIONS.EXTERNAL_ONLY,
      ]),
      isShowTabInvoicing: isAllowed(props.roles, [...ROLES_DIVISIONS.SUPERMANGER, ...ROLES_DIVISIONS.ALL_CLIENT]),
      contacts: [],
      isExternal: isExternal(props.roles),
      isAttorney: isAllowed(props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY),
      openModalRev: false,
      reportIdRev: '',
      REPORT_PERMISSIONS,
      CLAIMANT_PERMISSIONS,
      NOTIFICATION_PERMISSIONS,
    };

    this.changeRatedAges = this.changeRatedAges.bind(this);
    this.getCommentsLength = this.getCommentsLength.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.getChatMessages = this.getChatMessages.bind(this);
    this.loadClaimantData = this.loadClaimantData.bind(this);
    this.getFileToAssign = this.getFileToAssign.bind(this);
    this.getAllData = this.getAllData.bind(this);
    this.deleteAssignementData = this.deleteAssignementData.bind(this);
    this.getReportTypes = this.getReportTypes.bind(this);
    this.goToEditClaimant = this.goToEditClaimant.bind(this);
    this.getClaimantData = this.getClaimantData.bind(this);
    this.getReportsData = this.getReportsData.bind(this);
    this.getTransactionsData = this.getTransactionsData.bind(this);
    this.handleEditTransactions = this.handleEditTransactions.bind(this);
    this.insertTransactionRow = this.insertTransactionRow.bind(this);
    this.getReportStatuses = this.getReportStatuses.bind(this);
    this.getNotesData = this.getNotesData.bind(this);
    this.getUsersData = this.getUsersData.bind(this);
    this.handleReportDelete = this.handleReportDelete.bind(this);
    this.openReportDeleteModal = this.openReportDeleteModal.bind(this);
    this.closeReportDeleteModal = this.closeReportDeleteModal.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.openReportModal = this.openReportModal.bind(this);
    this.closeReportModal = this.closeReportModal.bind(this);
    this.handleNoteDelete = this.handleNoteDelete.bind(this);
    this.closeNoteDeleteModal = this.closeNoteDeleteModal.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handleFileModal = this.handleFileModal.bind(this);
    this.openNoteModal = this.openNoteModal.bind(this);
    this.closeNoteModal = this.closeNoteModal.bind(this);
    this.openAgeModal = this.openAgeModal.bind(this);
    this.closeAgeModal = this.closeAgeModal.bind(this);
    this.openRequestModal = this.openRequestModal.bind(this);
    this.closeRequestModal = this.closeRequestModal.bind(this);
    this.openRequestModalNoAdmin = this.openRequestModalNoAdmin.bind(this);
    this.closeRequestModalNoAdmin = this.closeRequestModalNoAdmin.bind(this);
    this.closeFileModal = this.closeFileModal.bind(this);
    this.closePhotoModal = this.closePhotoModal.bind(this);
    this.handleNotesPagination = this.handleNotesPagination.bind(this);
    this.openReportFiles = this.openReportFiles.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.GetUploadedFiles = this.GetUploadedFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.changeCommentView = this.changeCommentView.bind(this);
    this.openCalculateRatedAgesModal = this.openCalculateRatedAgesModal.bind(this);
    this.submitRAAssignment = this.submitRAAssignment.bind(this);
    this.changeCount = this.changeCount.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.generateAllReports = this.generateAllReports.bind(this);
    this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleCloseModalUploadFile = this.handleCloseModalUploadFile.bind(this);
    this.handleDownloadAllFiles = this.handleDownloadAllFiles.bind(this);
    this.handleOpenModalReview = this.handleOpenModalReview.bind(this);
    this.handleConfirmSendReview = this.handleConfirmSendReview.bind(this);
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.removeRequest = this.removeRequest.bind(this);
    this.openAssingModel = this.openAssingModel.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.handleOpenModalTransaction = this.handleOpenModalTransaction.bind(this);
    this.handleOpenModalRev = this.handleOpenModalRev.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.getFieldTemplateAndCopy = this.getFieldTemplateAndCopy.bind(this);
  }

  // FileUpload = React.createRef();

  changeRatedAges(rated_age) {
    this.setState({
      claimantInfo: { ...this.state.claimantInfo, rated_age },
      openCalculateModal: false,
    });
  }

  getCommentsLength({ claimantInfo }) {
    let claimant = this.state.claimantInfo;

    if (!claimant) {
      claimant = claimantInfo;
    }

    API.loadMoreComments(0, 9999999, true, claimant.id).then(res => {
      API.loadMoreComments(0, 9999999, false, claimant.id).then(res2 => {
        this.setState({ totalComments: res.claimant_comments.length + res2.claimant_comments.length });
      });
    });
  }

  async getTemplates() {
    try {
      const isUserAdmin = isAllowed(this.props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.SALES_MANAGER,
      ]);
      const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

      const tenantOnly = isUserNurseManager && !isUserAdmin;

      const res = await API.getAllTemplateReports({ tenantOnly });

      this.setState({ templates: res.reports });
    } catch (e) {
      notifyApiError(e);
    }
  }

  async getChatMessages({ claimantInfo }) {
    try {
      let claimant = this.state.claimantInfo;

      if (!claimant) {
        claimant = claimantInfo;
      }

      const res = await API.getChatClaimant(claimant.id);
      this.setState({ chatMessages: res });
    } catch (e) {
      notifyError(e.message);
    }
  }

  async loadClaimantData() {
    let { claimantInfo, selectedTab } = this.state;

    // obtenemos el id del claimant en la url
    let search = this.props.location.search;
    let params = new URLSearchParams(search);

    if (!!params.get('id_claimant') || this.props.match.params.id) {
      const idClaimantUrl = params.get('id_claimant') || this.props.match.params.id;

      // toda esta seccion es porque llegan email para ir directo a los tabs
      if (params.get('comment')) {
        selectedTab = TAB_COMMENTS_NUMBER;
      } else if (params.get('file')) {
        selectedTab = TAB_FILES_NUMBER;
      } else if (params.get('transaction')) {
        selectedTab = TAB_INVOICING_NUMBER;
      }

      if (params.get('icomment')) {
        this.setState({ commentView: INTERNAL_COMMENTS });
      }

      const request = await API.getClaimantsDataByClaimantId(idClaimantUrl);
      localStorage.setItem('claimant', JSON.stringify(request.claimant));
      claimantInfo = request.claimant;
    }
    this.setState({ claimantInfo, selectedTab });

    Promise.all([
      this.getChatMessages({ claimantInfo }),
      this.getTemplates(),
      this.getCommentsLength({ claimantInfo }),
      this.getClaimantData({ claimantInfo }),
      this.getTransactionsData({ claimantInfo }),
      this.getReportStatuses(),
      this.getNotesData({ claimantInfo }),
      this.getUsersData(),
      this.GetUploadedFiles({ claimantInfo }),
      this.getAllData({ claimantInfo }),
      this.getReportTypes(),
      this.getReportsData({ claimantInfo, useLoading: false }),
      this.props.clearReportFiles(),
    ])
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        console.error(err);
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.loadClaimantData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location?.state?.data &&
      prevProps.location?.state?.data &&
      JSON.stringify(prevProps.location.state.data) !== JSON.stringify(this.props.location.state.data)
    ) {
      let claimantInfo = {};
      if (this.props.location && this.props.location.state) {
        claimantInfo = this.props.location.state.data;
      }
      this.setState(
        {
          ...initialClaimantState,
          claimantInfo,
          isClient: isClient(this.props.roles),
          isAdmin: isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGEMENT),
          isExternal: isExternal(this.props.roles),
          isNurseManager: isAllowed(this.props.roles, [
            ...ROLES_DIVISIONS.SUPERADMIN,
            ...ROLES_DIVISIONS.MANAGER,
            ...ROLES_DIVISIONS.NURSE_ONLY,
            ...ROLES_DIVISIONS.EXTERNAL_ONLY,
          ]),
        },
        () => this.loadClaimantData()
      );
    }
  }

  getFileToAssign(value) {
    this.setState({ fileValue: value });
  }

  getAllData({ claimantInfo }) {
    let claimant = this.state.claimantInfo;

    if (!claimant) {
      claimant = claimantInfo;
    }

    API.GetCreatedAssignement(claimant.id).then(res => {
      this.setState({
        GetAllAssignemetData: res.assignments,
      });
    });
  }

  deleteAssignementData(id) {
    const filteredItems = this.state.GetAllAssignemetData.filter(el => el.id !== id);
    this.setState({ loading: true });
    API.deleteAssignement(id).then(() => {
      this.setState({
        GetAllAssignemetData: filteredItems,
        loading: false,
      });
    });
  }

  getReportTypes() {
    API.getReportTypes().then(res => {
      this.setState({ reportTypes: res.reportTypes });
    });
  }

  goToEditClaimant() {
    const { claimantInfo } = this.state;
    this.props.history.push(CLAIMANT_EDIT.path, { data: claimantInfo });
  }

  getClaimantData({ claimantInfo }) {
    let claimant = this.state.claimantInfo;

    if (!claimant) {
      claimant = claimantInfo;
    }

    API.getClaimantsDataByClaimantId(claimant.id).then(res => {
      this.setState({
        claimantInfo: res.claimant,
      });
    });
  }

  async getReportsData({ claimantInfo = null, useLoading = true } = {}) {
    let claimant = this.state.claimantInfo || claimantInfo;
    const { reportsPageIndex } = this.state;
    const isExternalNurse = isAllowed(this.props.roles, ROLES_DIVISIONS.EXTERNAL_ONLY);

    if (useLoading) this.setState({ loading: true });

    try {
      if (isExternalNurse) {
        const ReportsDataByUserId = await API.getReportsDataByUserId(
          claimant.id,
          this.props.roles.user_roles.id,
          reportsPageIndex,
          10000
        );
        this.setState({
          reportsData: ReportsDataByUserId.reports,
          reportsTotalCount: ReportsDataByUserId.total_count,
        });
      } else {
        let userId;

        const ReportsDataByClaimantId = await API.getReportsDataByClaimantId({
          userId,
          claimantId: claimant.id,
          pageIndex: reportsPageIndex,
        });
        this.setState({
          reportsData: ReportsDataByClaimantId.reports,
          reportsTotalCount: ReportsDataByClaimantId.total_count,
        });
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      if (useLoading) this.setState({ loading: false });
    }
  }

  async getTransactionsData({ claimantInfo, useLoading }) {
    try {
      if (useLoading) this.setState({ loading: true });
      let claimant = this.state.claimantInfo;

      if (!claimant) {
        claimant = claimantInfo;
      }

      const res = await fetchTransactions({ claimant_id: claimant.id });

      this.setState({
        transactionsData: res.rows || [],
        transactionsTotalCount: res.count || 0,
      });
    } catch (err) {
      notifyApiError(err);
    } finally {
      if (useLoading) this.setState({ loading: false });
    }
  }

  handleEditTransactions(transaction) {
    this.setState({
      editedTransaction: transaction,
      showChooseReportsModal: true,
    });
  }

  insertTransactionRow({ transaction, invoice }) {
    const { transactionsData, transactionsTotalCount, editedTransaction } = this.state;
    if (editedTransaction) {
      const index = transactionsData.findIndex(t => t.id === transaction.id);
      transactionsData.splice(index, 1, transaction);
      this.setState({
        transactionsData: [...transactionsData],
      });
      const invoiceId = invoice?.DocNumber || invoice?.Id || null;
      notifyInfo('Transaction updated', `${invoiceId ? 'Invoice #' + invoiceId + ' has been generated.' : ''}`);
    } else {
      this.setState({
        transactionsData: [transaction, ...transactionsData],
        transactionsTotalCount: transactionsTotalCount + 1,
      });
      notifyInfo(
        'Invoicing created',
        `${invoice?.DocNumber ? 'Invoice #' + invoice?.DocNumber + ' has been generated.' : ''}`
      );
    }
  }

  getReportStatuses() {
    API.getReportStatuses().then(res => {
      this.setState({
        reportStatuses: res.reportStatuses,
      });
    });
  }

  getNotesData({ claimantInfo }) {
    let claimant = this.state.claimantInfo;
    const { notesPageIndex } = this.state;

    if (!claimant) {
      claimant = claimantInfo;
    }

    API.getNotesDataByClaimantId(claimant.id, notesPageIndex).then(res => {
      this.setState({
        notesData: res.notes,
        notesTotalCount: res.total_count,
      });
    });
  }

  getUsersData() {
    API.getUsersDataSynchronised().then(res => {
      this.setState({
        usersData: res.users,
      });
    });
  }

  async handleReportDelete() {
    const { selectedReport } = this.state;
    const param = {
      id: selectedReport.id,
    };

    const res = await API.getAllAssignments('');

    const deleteAssigments = res.assignments.find(item => item.report_id === param.id);

    if (deleteAssigments)
      await API.updateAssignmentById(deleteAssigments.id, {
        status: ASSIGNMENT_STATUS_COMPLETED,
      });

    this.closeReportDeleteModal();
    API.deleteReport(param).then(() => {
      this.getReportsData();
    });
  }

  openReportDeleteModal(item) {
    this.setState({
      selectedReport: item,
      showReportDeleteModal: true,
    });
  }

  closeReportDeleteModal() {
    this.setState({ showReportDeleteModal: false });
  }

  async handleReport(param, templatesList, is_template) {
    try {
      this.setState({ loadingInternal: true });
      const { modalType, selectedReport, claimantInfo } = this.state;

      this.closeReportModal();

      if (modalType === 'ADD') {
        const params = {
          claimant_id: claimantInfo.id,
          ...param,
        };

        if (param.feeding_id === OPTION_CREATE_LCP) {
          delete params.report_template_id;
          delete params.feeding_id;
          delete params.feeding_id_template;
        }

        if (params.feeding_id && !params.feeding_id_copy && !params.feeding_id_template) {
          let feedingBefore = [...this.state.reportsData, ...templatesList].filter(
            report => report.id === param.feeding_id
          );
          feedingBefore = feedingBefore[0];
          if (feedingBefore.report_type_id === 4 && param.report_type_id !== 4) {
            params.report_claim_id = null;
            params.additional_comments = null;
          }

          if (
            (feedingBefore.report_type_id === 3 || feedingBefore.report_type_id === 1) &&
            param.report_type_id === 4
          ) {
            params.report_claim_id = null;
            params.additional_comments = null;
          }
        }

        if (is_template && !isNaN(param.feeding_id)) {
          params.report_template_id = param.feeding_id;
          params.is_template = false;
        }

        // si es LCP y usa un template buscamos la info del template y asignamos los placeholder como value al reporte
        if (param.report_type_id === REPORT_LIFE_CARE_PLAN_TYPE_ID && Number.isInteger(param.feeding_id)) {
          const reportStructure = await API.getReportLifeCarePlan({ idReport: param.feeding_id });

          if (reportStructure.reportStructure.report_structure.length > 0) {
            reportStructure.reportStructure.report_structure.forEach(item => {
              item.template_fields.forEach(subItem => {
                params[subItem.report_ref || subItem.ref] = subItem.placeholder;
              });
            });
          }
        }

        // creamos el reporte
        const createReport = await API.createReport(params);

        if (param.feeding_id === OPTION_CREATE_LCP) {
          // creamos el template lcp
          params.is_template = true;
          params.is_private = true;
          params.from_report = createReport.report.id;
          delete params.claimant_id;

          const createTemplate = await API.createReport(params);

          // nos vamos al template lcp
          return this.props.history.push(`${TEMPLATE_CREATE_STRUCTURE_NAV.path}/${createTemplate.report.id}`);
        }

        if (param.feeding_id_copy && param.feeding_id_template) {
          const updateDataReport = this.getFieldTemplateAndCopy({
            feeding_id_copy: param.feeding_id_copy,
            feeding_id_template: param.feeding_id_template,
          });

          updateDataReport.id = createReport.report.id;
          await API.updateReportNurse({ ...updateDataReport });
        }

        this.getReportsData();

        if (createReport?.report.report_type_id === REPORT_LIFE_CARE_PLAN_TYPE_ID && params?.report_template_id) {
          await assignedTemplateInReportLCP({
            chooseTemplateId: params.report_template_id,
            reportId: createReport.report.id,
          });
        }

        if (this.state.isClient) {
          this.setState({
            openModalUploadFile: true,
            dataUploadFile: {
              reportId: createReport?.report.id,
              claimantId: createReport?.report.claimant_id,
              reportTypeId: createReport?.report.report_type_id,
            },
          });

          if (createReport?.report.report_type_id !== REPORT_MSA_REDUCTION_TYPE_ID)
            return this.setState({ loadingInternal: false });
        }

        const validOpenRerpot = await validateOpenReportForPackage({
          tenant_id: this.props.roles.user_roles.tenant_id,
          report_type: createReport?.report.report_type_id,
        });

        if (!validOpenRerpot.open)
          return this.setState({
            ...validOpenRerpot,
            open: true,
            tenant_id: this.props.roles.user_roles.tenant_id,
            customer: this.props.roles.user_roles.id,
          });

        this.setState({ loadingInternal: false });

        goFirstStep({
          sections:
            createReport?.report?.sections ||
            REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[createReport?.report.report_type_id]],
          reportType: REPORT_TYPES_BY_ID[createReport?.report.report_type_id],
          report_id: createReport?.report.id,
          history: this.props.history,
        });
      }

      if (modalType === 'EDIT') {
        const params = {
          ...param,
          id: selectedReport.id,
          claimant_id: claimantInfo.id,
        };

        if (param.feeding_id === OPTION_CREATE_LCP) {
          // creamos el template lcp
          params.is_template = true;
          params.is_private = true;
          params.from_report = params.id;
          delete params.claimant_id;
          delete params.feeding_id_template;
          delete params.feeding_id;
          delete params.id;

          const createTemplate = await API.createReport(params);

          // nos vamos al template lcp
          return this.props.history.push(`${TEMPLATE_CREATE_STRUCTURE_NAV.path}/${createTemplate.report.id}`);
        }

        if (param.feeding_id && !param.feeding_id_copy && !param.feeding_id_template) {
          let feedingBefore = [...this.state.reportsData, ...templatesList].filter(
            report => report.id === param.feeding_id
          );
          feedingBefore = feedingBefore[0];
          if (feedingBefore.report_type_id === 4 && param.report_type_id !== 4) {
            params.report_claim_id = null;
            params.additional_comments = null;
          }

          if (
            (feedingBefore.report_type_id === 3 || feedingBefore.report_type_id === 1) &&
            param.report_type_id === 4
          ) {
            params.report_claim_id = null;
            params.additional_comments = null;
          }
        }

        if (is_template) {
          params.report_template_id = param.feeding_id;
          params.is_template = false;
        }

        let fields = {};

        if (param.feeding_id_copy && param.feeding_id_template)
          fields = this.getFieldTemplateAndCopy({
            feeding_id_copy: param.feeding_id_copy,
            feeding_id_template: param.feeding_id_template,
          });

        await API.updateReport({ ...params, ...fields });

        // if (createReport?.report.report_type_id === REPORT_LIFE_CARE_PLAN_TYPE_ID && params?.report_template_id) {
        //   await assignedTemplateInReportLCP({
        //     chooseTemplateId: params.report_template_id,
        //     reportId: createReport.report.id,
        //   });
        // }

        this.getReportsData();
        this.setState({ loadingInternal: false });
      }

      if (modalType === 'CLONE') {
        let report_type_id = param.report_type_id === selectedReport.report_type_id ? null : param.report_type_id;
        const params = {
          id: selectedReport.id,
          report_type_id,
        };

        const res = await API.copyReport(params);
        this.getReportsData();
        await API.updateReport({
          ...param,
          id: res.id,
          claimant_id: claimantInfo.id,
        });
      }
    } catch (e) {
      notifyApiError(e);
      this.setState({ loadingInternal: false });
    }
  }

  getFieldTemplateAndCopy({ feeding_id_template, feeding_id_copy }) {
    const params = [];

    const dataReportTemplate = this.state.templates.find(item => item.id === feeding_id_template);
    const dataReportCopy = this.state.reportsData.find(item => item.id === feeding_id_copy);

    const listKey = [...Object.keys(dataReportTemplate), ...Object.keys(dataReportCopy)];
    const setKeysNoRepeat = new Set(listKey);
    const listKeyNoRepeat = Array.from(setKeysNoRepeat).filter(
      item => !IGNORE_FIELD_REPORT_SELECT_TEMPLATE_AND_COPY.includes(item)
    );

    listKeyNoRepeat.forEach(item => {
      if (PRIORITIZE_FIELD_SELECT_TEMPLATE_AND_COPY.includes(item)) {
        let pushItem = `${dataReportCopy[item] || dataReportTemplate[item] || ''}`.trim();

        if (/^(true|false)$/.test(pushItem)) pushItem = Boolean(pushItem);
        if (!isNaN(pushItem)) pushItem = Number(pushItem);

        params[item] = pushItem;
      } else {
        params[item] = `${dataReportTemplate[item] || ''} ${dataReportCopy[item] || ''}`.trim();
      }
    });

    return params;
  }

  openReportModal(type, item) {
    this.setState({
      modalType: type,
      selectedReport: item,
      showReportModal: true,
    });
  }

  closeReportModal() {
    this.setState({ showReportModal: false, selectedReport: null });
  }

  handleNoteDelete() {
    const { selectedNote } = this.state;
    const param = {
      id: selectedNote.id,
    };

    this.closeNoteDeleteModal();
    API.deleteNote(param).then(() => {
      this.getNotesData();
    });
  }

  closeNoteDeleteModal() {
    this.setState({ showNoteDeleteModal: false });
  }

  handleNote(param) {
    const { modalType, claimantInfo } = this.state;

    this.closeNoteModal();
    if (modalType === 'ADD') {
      const params = {
        claimant_id: claimantInfo.id,
        ...param,
      };
      API.createNote(params).then(() => {
        this.getNotesData();
      });
    } else {
      const params = {
        ...param,
      };
      API.updateNote(params).then(() => {
        this.getNotesData();
      });
    }
  }

  handleFileModal(EditFile) {
    this.setState({
      showFileModal: true,
      EditFile: EditFile,
    });
  }

  openNoteModal(type, item) {
    this.setState({
      modalType: type,
      selectedNote: item,
      showNoteModal: true,
    });
  }

  closeNoteModal() {
    this.setState({ showNoteModal: false });
  }

  openAgeModal() {
    this.setState({
      showAgeModal: true,
    });
  }

  closeAgeModal() {
    this.setState({
      showAgeModal: false,
      notes: '',
    });
  }

  openRequestModal() {
    this.setState({
      showRequestModal: true,
    });
  }

  closeRequestModal() {
    this.setState({
      showRequestModal: false,
    });
  }

  openRequestModalNoAdmin() {
    this.setState({
      showRequestModalNoAdmin: true,
    });
  }

  closeRequestModalNoAdmin() {
    this.setState({
      showRequestModalNoAdmin: false,
    });
  }

  closeFileModal() {
    this.setState({
      showFileModal: false,
    });
  }

  closePhotoModal() {
    this.setState({
      showFilePhotoModal: false,
    });
  }

  handleNotesPagination(pageIndex) {
    this.setState(
      {
        notesPageIndex: pageIndex,
      },
      () => this.getNotesData()
    );
  }

  async openReportFiles({ report }) {
    try {
      let res;
      if (report.report_type_id === REPORT_MSA_TYPE_ID && report.report_status_id === STATUS_REDUCTION_COMPLETED.id) {
        await Promise.all([
          API.setUpMsaReport(report.id),
          API.setUpReductionReport(report.id),
          API.setUpLetterReport(report.id),
        ]);
        res = await API.getReportFilesReductionProcess({ reportId: report.id });
      } else {
        res = await API.getReportFiles(report.id);
      }
      if (res) {
        window.open(res, '_blank');
      } else {
        notifyInfo('Report is not generated yet!');
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data === 'Report is not completed')
        return notifyInfo('Report is not completed');
      notifyApiError(e);
    }
  }

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    this.upload(file);
    return isJpgOrPng && isLt2M;
  }

  onNoteChange(e) {
    this.setState({
      notes: e.target.value,
    });
  }

  GetUploadedFiles({ claimantInfo }) {
    let claimant = this.state.claimantInfo;

    if (!claimant) {
      claimant = claimantInfo;
    }

    const aFiles = [];
    API.GetReportFiles(claimant.id).then(res => {
      API.GetCreatedAnnuityQuote(claimant.id).then(aqRes => {
        if (aqRes.assignments && aqRes.assignments.length > 0 && res.files && res.files.length > 0) {
          aqRes.assignments.map(assignment => {
            assignment.assignment_files.map(assignmentFile => aFiles.push(assignmentFile.file));
          });
        }
        const files = {
          files: [...res.files, ...aFiles],
        };
        this.setState({
          uploadedFiles: files,
          fileBackOut: [...files.files],
        });
      });
    });
  }

  async upload(file) {
    if (!file.type) {
      notifyError('Error', 'File type undefined');
      return false;
    }

    const { claimantInfo } = this.state;
    this.setState({ loading: true });

    try {
      const data = await API.getPresignUrlFile({
        name: file.name,
        type: file.type,
      });

      const formData = new FormData();

      Object.entries(data.url.data.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append('file', file);

      await axios.post(data.url.data.url, formData);

      let url = data.url.data.fields['key'];
      let UploadData = {
        name: file.name,
        type: file.type,
        url: url,
      };

      const res = await API.ReportFileUpload(claimantInfo.id, UploadData);
      this.setState({
        uploadedFiles: {
          files: [...this.state.uploadedFiles.files, res.file],
        },
        fileBackOut: [...this.state.uploadedFiles.files, res.file],
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  changeCommentView(page) {
    this.setState({ commentView: page });
  }

  openCalculateRatedAgesModal() {
    this.setState({
      openCalculateModal: true,
    });
  }

  async submitRAAssignment() {
    const { selectUser, claimantInfo, notes, fileValue, GetAllAssignemetData, endDate } = this.state;
    try {
      const data = {
        user_id: selectUser,
        claimant_id: claimantInfo.id,
        assignment_type_id: 3,
        status: 1,
        note: notes,
        end_date: endDate,
      };
      if (!this.state.claimantInfo.dob) {
        return Modal.error({ content: 'Please make sure the claimant date of birth is correct.' });
      }
      this.setState({ loading: true });
      const res = await API.CreateRateAgeUserAssignment(data);
      const assignment_id = res.assignment.id;

      let params = fileValue.map(file_id => {
        return { assignment_id, file_id };
      });
      await API.CreateAssignementFiles(params);

      this.setState({
        GetAllAssignemetData: [...GetAllAssignemetData, res.assignment],
        showAgeModal: false,
        notes: '',
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  changeCount(newAdd) {
    this.setState({ totalComments: this.state.totalComments + newAdd });
    // this,
  }

  deleteFile(file) {
    const isInReport = !!file['report_file.report.id'];
    if (isInReport) {
      warning({
        title: 'The file can not be deleted',
        content: `This file is included to report: "${file['report_file.report.report_name']}"`,
      });
      return;
    }

    confirm({
      title: 'Are you sure you want to delete file?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        this.setState({ loading: true });
        try {
          const filteredItems = this.state.uploadedFiles.files.filter(el => el.id !== file.id);
          await API.deleteFile(file.id);
          this.setState({
            loading: false,
            uploadedFiles: { files: [...filteredItems] },
            fileBackOut: [...filteredItems],
          });
          notifySuccess('File deleted');
        } catch (e) {
          notifyApiError(e);
          this.setState({ loading: false });
        }
      },
    });
  }

  async generateAllReports() {
    this.setState({
      loading: true,
    });

    let res = await API.GenerateAllReport({ claimantId: this.state.claimantInfo.id });

    this.setState({
      loading: false,
    });

    if (res?.file?.url) {
      Modal.success({
        content: 'All Reports is generated',
        onOk: () => {
          window.open(res?.file?.url);
        },
      });
    } else {
      Modal.error({ content: 'No reports.' });
    }
  }

  handleSubmitFilter({ filter }) {
    let listFilter = Array.from(this.state.fileBackOut);

    if (filter.search) {
      listFilter = listFilter.filter(file => file.name.toLowerCase().includes(filter.search.toLowerCase()));
    }

    if (filter.type) {
      listFilter = listFilter.filter(file => file.type === filter.type);
    }

    if (filter.date) {
      listFilter = listFilter.filter(file => file.createdAt.includes(filter.date.format().split('T')[0]));
    }

    this.setState({
      uploadedFiles: {
        files: listFilter,
      },
    });
  }

  handleChangeFilter({ field, value }) {
    const data = { ...this.state.filter, [field]: value };
    this.setState({ filter: data });

    if (field !== 'search') {
      this.handleSubmitFilter({ filter: data });
    }
  }

  handleCloseModalUploadFile() {
    this.setState({ openModalUploadFile: false });
  }

  async handleDownloadAllFiles() {
    try {
      if (this.state?.uploadedFiles?.files.length > 0) {
        this.setState({
          loading: true,
        });
        const listIdFiles = this.state?.uploadedFiles?.files.map(file => file.id);

        let res = await API.DownloadAllReportFiles({ listIdFiles });

        this.setState({
          loading: false,
        });

        if (res?.file?.url) {
          window.open(res?.file?.url);

          Modal.success({
            content: 'All Files have been Downloaded',
          });
        } else {
          Modal.error({ content: 'There is not files' });
        }
      }
    } catch {
      Modal.error({ content: 'An error has occurred' });
    }
  }

  handleOpenModalReview(report) {
    this.setState({
      openModalReview: {
        open: !this.state.openModalReview.open,
        report,
      },
    });
  }

  handleConfirmSendReview() {
    const report = this.state.openModalReview.report;
    if (report) {
      const body = {
        id: report.id,
        report_status_id: STATUS_REVISION_REQUESTED.id,
      };

      API.updateReportClient(body).then(() => {
        this.handleOpenModalReview(null);
        this.getReportsData();
      });
    }
  }

  async handleUploadFiles({ claimantInfo }) {
    await this.GetUploadedFiles({ claimantInfo });
    this.handleCloseModalUploadFile();
  }

  removeRequest() {
    const body = {
      id: this.state.reportIdRev,
      report_status_id: 1,
    };

    API.updateReportClient(body).then(() => {
      this.getReportsData();
    });
  }

  async openAssingModel({ report, assignmentTypeDisabled, clearUser, defaultUser }) {
    if (this.state.isClient) return;

    let disabledUser = false;

    let finalAssigned = {
      claimant: report.claimant,
      report,
      report_id: report.id,
      claimant_id: report.claimant_id,
    };
    const { assignments } = await API.fetchReportAssignments({
      reportId: report.id,
      claimantId: report.claimant_id,
    });

    finalAssigned = { ...finalAssigned, ...assignments[0] };

    if (clearUser && assignments[0]?.assignment_type_id !== ASSIGNMENT_TYPE_NURSE) delete finalAssigned.user_id;

    if (defaultUser && isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_ONLY)) {
      finalAssigned = { ...finalAssigned, user_id: this.props?.roles?.user_roles?.id || null };
      disabledUser = true;
    }

    this.setState({
      editedparams: finalAssigned,
      modalAssignOpen: true,
      assignmentTypeDisabled,
      assignedUserDisabled: disabledUser,
    });
  }

  async saveEdit(edit) {
    try {
      const hasAssigment = Boolean(edit.id);

      if (!hasAssigment) {
        await API.createAssignementUser(edit);
      } else {
        let newEdit = { ...edit };
        delete newEdit.id;
        delete newEdit.report;
        delete newEdit.claimant;
        delete newEdit.status;
        delete newEdit.user;
        delete newEdit.assignment_type;
        delete newEdit.tenant_id;

        newEdit.start_date = moment(newEdit.start_date).format('YYYY-MM-DD');
        newEdit.end_date = moment(newEdit.end_date).format('YYYY-MM-DD');

        const isUserAdmin = isAllowed(this.props.roles, [
          ...ROLES_DIVISIONS.SUPERADMIN,
          ...ROLES_DIVISIONS.SALES_MANAGER,
        ]);
        const isUserQA = isAllowed(this.props.roles, ROLES_DIVISIONS.QA);

        const params = isUserQA && !isUserAdmin ? { ...newEdit, tenantOnly: true } : newEdit;

        const res = await API.UpdateAssignement(edit.id, params);

        if (res?.assignment?.assignment_type_id === ASSIGNMENT_TYPE_LEGAL_NURSE) {
          await API.updateReportNurse({
            id: res.assignment.report_id,
            report_status_id: STATUS_IN_SUPERVISOR_REVIEW.id,
          });
        }
      }

      this.setState({ modalAssignOpen: false });
      this.getReportsData();
      notifyInfo(edit.id ? 'Assignment updated' : 'Assignment created');
    } catch (e) {
      notifyApiError(e);
    }
  }

  handleOpenModalTransaction({ report } = {}) {
    const update = {
      showChooseReportsModal: true,
    };

    if (report) update.reportDefaultTransaction = report.id;

    this.setState(update);
  }

  handleOpenModalRev({ item }) {
    this.setState({ openModalRev: true, reportIdRev: item.id });
  }

  handleGoBack() {
    if (isAllowed(this.props.roles, ROLES_DIVISIONS.EXTERNAL_LITIGATION_ATTORNEY))
      return this.props.history.push(ATTORNEY_CLAIMANT_NAV.path, { data: this.state.claimantInfo.client });

    if (this.state.isExternal || this.state.isAttorney) {
      this.props.history.push(CLAIMANT_BY_USER_NAV({ id: this.props.roles.user_roles.id }).path);
    } else if (this.state.isClient) {
      this.props.history.push(`${CLAIMANTS_BY_CLIENT_NAV({ id: getClientId(this.props.roles) }).path}`);
    } else {
      this.props.history.push('/app/claimants', { data: { clientId: this.state.claimantInfo.client_id } });
    }
  }

  render() {
    const {
      openModalRev,
      isShowDetail,
      selectedTab,
      claimantInfo,
      reportsData,
      reportsTotalCount,
      reportStatuses,
      notesTotalCount,
      notesPageIndex,
      usersData,
      selectedReport,
      showReportDeleteModal,
      showReportModal,
      modalType,
      selectedNote,
      endDate,
      selectUser,
      notes,
      showNoteDeleteModal,
      showNoteModal,
      loading,
      showAgeModal,
      showRequestModal,
      showRequestModalNoAdmin,
      uploadedFiles,
      showFileModal,
      EditFile,
      renderUploadedImage,
      showFilePhotoModal,
      GetAllAssignemetData,
      commentView,
      showChooseReportsModal,
      transactionsData,
      transactionsTotalCount,
      editedTransaction,
      isClient,
      isAdmin,
      chatMessages,
      isNurseManager,
      filter,
      openModalReview,
      openModalUploadFile,
      dataUploadFile,
      editedparams,
      modalAssignOpen,
      assignedReportId,
      assignmentTypeDisabled,
      reportDefaultTransaction,
      isShowTabInvoicing,
      REPORT_PERMISSIONS,
      CLAIMANT_PERMISSIONS,
      NOTIFICATION_PERMISSIONS,
      loadingInternal,
      assignedUserDisabled,
      openModalUpgradedPlan,
    } = this.state;

    const { files } = uploadedFiles;

    const RenderImage = item => {
      API.getPresignLogoUrl(item.id).then(res => {
        window.open(res.url, '_blank');
      });
    };
    let InternalCommentsCount;

    const isCreateReport = isNurseManager || isClient;

    if (!claimantInfo) return <></>;

    return (
      <Row className="detailed-claimant-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Claimant Details
            </Title>
            <Text>
              {claimantInfo.claimant_name +
                ' ' +
                claimantInfo.claimant_middle_name +
                ' ' +
                claimantInfo.claimant_last_name}
            </Text>
          </Col>
          <Col className="back" onClick={() => this.handleGoBack()}>
            <BackIcon className="icon" />
          </Col>
        </Row>
        <Row className="main">
          {/* CLAIMANT INFO */}
          <Row className="first-content">
            <Row type="flex" justify="space-between" align="middle" className="row title-row">
              <Text className="title">Claimant Information</Text>
              {CLAIMANT_PERMISSIONS.edit && <EditIcon className="icon" onClick={this.goToEditClaimant} />}
            </Row>
            <Row type="flex" className="row">
              <Col className="label">
                <Text>Claimant ID</Text>
              </Col>
              <Text className="text">{claimantInfo.id}</Text>
            </Row>
            <Row type="flex" className="row">
              <Col className="label">
                <Text>Title</Text>
              </Col>
              <Text className="text">{claimantInfo.claimant_title}</Text>
            </Row>
            <Row type="flex" className="row">
              <Col className="label">
                <Text className="label">Full Name</Text>
              </Col>
              <Text className="text">
                {claimantInfo.claimant_name +
                  ' ' +
                  claimantInfo.claimant_middle_name +
                  ' ' +
                  claimantInfo.claimant_last_name}
              </Text>
            </Row>
            {isShowDetail && (
              <>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Date of birth</Text>
                  </Col>
                  <Text className="text">{moment(claimantInfo.dob).format('MM.DD.YYYY')}</Text>
                </Row>
                {isNurseManager && (
                  <Row type="flex" className="row">
                    <Col className="label">
                      <Text>Rated Age</Text>
                    </Col>
                    <Text className="text">{claimantInfo.rated_age} y.o.</Text>
                  </Row>
                )}
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Gender</Text>
                  </Col>
                  <Text className="text">{claimantInfo.Gender}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Insurance Carrier</Text>
                  </Col>
                  <Text className="text">{claimantInfo.insurance_carrier}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Claim #</Text>
                  </Col>
                  <Text className="text">{claimantInfo.claim_number}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>SSN</Text>
                  </Col>
                  <Text className="text">{claimantInfo.SSN}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>HICN</Text>
                  </Col>
                  <Text className="text">{claimantInfo.HICN || ''}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Address</Text>
                  </Col>
                  <Text className="text">{claimantInfo.address}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Email</Text>
                  </Col>
                  <Text className="text">{claimantInfo.claimant_email}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Phone</Text>
                  </Col>
                  <Text className="text">{claimantInfo.claimant_phone}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Attorney Estimated Value of Case</Text>
                  </Col>
                  <Text className="text">{claimantInfo.present_value_case}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Policy Limits</Text>
                  </Col>
                  <Text className="text">${claimantInfo.policy_limits}</Text>
                </Row>
              </>
            )}
            <Divider className="divider" />
            {isShowDetail ? (
              <Row type="flex" justify="center">
                <Text className="textButton" onClick={() => this.setState({ isShowDetail: false })}>
                  Hide Details
                </Text>
              </Row>
            ) : (
              <Row type="flex" justify="center">
                <Text className="textButton" onClick={() => this.setState({ isShowDetail: true })}>
                  Show Details
                </Text>
              </Row>
            )}
          </Row>

          {/* TABS AND CONTENT */}
          <Row className="second-content tableScroll">
            {/* BTNS BY TAB */}
            <Row type="flex" justify="end" className={`gap-16 ${isClient ? 'tableScrollItem860' : 'tableScrollItem'}`}>
              {selectedTab === TAB_REPORTS_NUMBER && (
                <>
                  {REPORT_PERMISSIONS.download && (
                    <DownloadOutlined
                      height={'20px'}
                      width={'20px'}
                      className="icon btnDownloadAllReports"
                      onClick={this.generateAllReports}
                    />
                  )}
                  {REPORT_PERMISSIONS.create && (
                    <Button
                      type="primary"
                      size="default"
                      className="green-btn button"
                      onClick={() => this.openReportModal('ADD', {})}>
                      Add Report
                    </Button>
                  )}
                </>
              )}

              {selectedTab === TAB_NOTES_NUMBER && (
                <>
                  <Pagination
                    onChange={this.handleNotesPagination}
                    totalCount={notesTotalCount}
                    pageIndex={notesPageIndex}
                    pageSize={10}
                  />
                  <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={() => this.openNoteModal('ADD', {})}>
                    Add Note
                  </Button>
                </>
              )}
              {selectedTab === TAB_RATED_AGE_NUMBER && (
                <div>
                  {
                    <>
                      {/* <Button type="primary" size="default" className="green-btn button" onClick={this.openAgeModal}>
                        Request Rated Age
                      </Button> */}
                      {CLAIMANT_PERMISSIONS.requestRatedAge && (
                        <Button
                          type="primary"
                          size="default"
                          className="green-btn button"
                          onClick={this.openRequestModal}>
                          Request Rated Age
                        </Button>
                      )}
                      {!CLAIMANT_PERMISSIONS.requestRatedAge && (
                        <Button
                          type="primary"
                          size="default"
                          className="green-btn button"
                          onClick={this.openRequestModalNoAdmin}>
                          Request Rated Age
                        </Button>
                      )}
                      <Button className="calculate-btn" onClick={this.openCalculateRatedAgesModal}>
                        Calculate Rated Age
                      </Button>
                    </>
                  }
                </div>
              )}

              {selectedTab === TAB_COMMENTS_NUMBER && <div style={{ paddingTop: 40 }}></div>}

              {isNurseManager && selectedTab === TAB_INVOICING_NUMBER && (
                <>
                  {/* <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={this.handleOpenModalTransaction}>
                    Add Invoicing
                  </Button>
                  */}
                  {CLAIMANT_PERMISSIONS.requestAddInvoice && (
                    <Button type="primary" size="default" className="green-btn button" onClick={this.openRequestModal}>
                      Add Invoicing
                    </Button>
                  )}
                  {!CLAIMANT_PERMISSIONS.requestAddInvoice && (
                    <Button
                      type="primary"
                      size="default"
                      className="green-btn button"
                      onClick={this.openRequestModalNoAdmin}>
                      Add Invoicing
                    </Button>
                  )}
                </>
              )}
            </Row>

            {/* TABS AND CONTENT */}
            <Tabs
              className={`tab ${isClient ? 'tableScrollItem860' : 'tableScrollItem'}`}
              activeKey={selectedTab}
              onChange={value => this.setState({ selectedTab: value })}>
              <TabPane
                tab={
                  <span>
                    Reports
                    <Badge
                      overflowCount={9999}
                      showZero
                      count={reportsTotalCount}
                      className={selectedTab === TAB_REPORTS_NUMBER ? 'selected-badge-icon' : 'badge-icon'}
                    />
                  </span>
                }
                key={TAB_REPORTS_NUMBER}>
                <ReportList
                  loading={loading}
                  reportsData={reportsData}
                  reportStatuses={reportStatuses}
                  handleOpenModalTransaction={this.handleOpenModalTransaction}
                  openAssingModel={this.openAssingModel}
                  openReportFiles={this.openReportFiles}
                  openReportModal={this.openReportModal}
                  openReportDeleteModal={this.openReportDeleteModal}
                  handleOpenModalReview={this.handleOpenModalReview}
                  handleOpenModalRev={this.handleOpenModalRev}
                  claimantInfo={claimantInfo}
                />
              </TabPane>
              {isNurseManager && (
                <TabPane
                  tab={
                    <span>
                      Rated Age
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={GetAllAssignemetData.length}
                        className={selectedTab === TAB_RATED_AGE_NUMBER ? 'selected-badge-icon' : 'badge-icon'}
                      />
                    </span>
                  }
                  key={TAB_RATED_AGE_NUMBER}>
                  <RatedAgesData
                    claimantInfo={claimantInfo}
                    getAllData={this.getAllData}
                    GetAllAssignemetData={this.state.GetAllAssignemetData}
                    loading={this.state.loading}
                    deleteAssignementData={this.deleteAssignementData}
                  />
                </TabPane>
              )}
              <TabPane
                tab={
                  <span>
                    Files
                    <Badge
                      overflowCount={9999}
                      showZero
                      count={files && files.length}
                      className={selectedTab === TAB_FILES_NUMBER ? 'selected-badge-icon' : 'badge-icon'}
                    />
                  </span>
                }
                key={TAB_FILES_NUMBER}>
                <div className="newFilterFile">
                  <div className="newFilterFile_sections">
                    <p className="newFilterFile_step ">
                      Files <TooltipAboutFileMSA />
                    </p>
                    <Search
                      placeholder="Search by Name"
                      value={filter.search || ''}
                      name="search"
                      onChange={e => this.handleChangeFilter({ field: e.target.name, value: e.target.value })}
                      onSearch={() => this.handleSubmitFilter({ filter: this.state.filter })}
                      className="search-box newFilterFile_search"
                    />
                    <Select
                      optionFilterProp="children"
                      className="dropdown newFilterFile_select"
                      value={filter.type || ''}
                      name="type"
                      onChange={value => this.handleChangeFilter({ field: 'type', value })}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      <Option value={''}>All Types</Option>
                      {typeFileList.map(type => (
                        <Option key={type} value={`application/${type}`}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                    <DatePicker
                      className="newFilterFile_datePicker"
                      format="MM/DD/YYYY"
                      allowClear={false}
                      value={filter.date || ''}
                      placeholder="Date Created"
                      onChange={date => this.handleChangeFilter({ field: 'date', value: date })}
                    />
                  </div>

                  <div className="newFilterFile_sections">
                    {isCreateReport && (
                      <>
                        <button className="newFilterFile_btnDownload" onClick={this.handleDownloadAllFiles}>
                          <DownloadOutlined height={'20px'} width={'20px'} className="icon" />
                          <p>Download All</p>
                        </button>

                        <Upload
                          name="logo"
                          listType="text"
                          accept={uploadValidFileTypes.common.join()}
                          className="logo-uploader green-btn button"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={this.upload}
                          style={{ padding: 25, cursor: 'pointer' }}>
                          <span style={{ color: 'white' }}> Add File</span>
                        </Upload>
                      </>
                    )}
                  </div>
                </div>

                {/* TAB FILES */}
                <Row className="reports-list-container">
                  <Row type="flex" className="table-header">
                    <Col md={8}>Name</Col>
                    <Col md={4}>Type</Col>
                    <Col md={6}>Created</Col>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      Actions
                    </Col>
                  </Row>

                  <Row className="table-content">
                    <SpinBox loading={loading}>
                      {files && files.length > 0 ? (
                        <Row>
                          {files.map(item => (
                            <Row key={item.id} className="record">
                              <Col md={8}>
                                <Paragraph ellipsis={{ rows: 6 }}>{item.name}</Paragraph>
                              </Col>
                              <Col md={4}>
                                <Paragraph ellipsis={{ rows: 6 }}>{item.type}</Paragraph>
                              </Col>

                              <Col md={6}>
                                <Text style={{ paddingLeft: '5px' }}>
                                  {moment(item.createdAt).format('MMM DD, YYYY')}{' '}
                                </Text>
                              </Col>

                              {isCreateReport && (
                                <Col md={6} className="actions">
                                  <DetailsIcon className="icon" onClick={() => RenderImage(item)} />
                                  {isAdmin && (
                                    <>
                                      {!item.name.includes('Generated Report') && (
                                        <EditIcon className="icon" onClick={() => this.handleFileModal(item)} />
                                      )}

                                      <RemoveIcon
                                        className={`icon ${item['report_file.report.id'] ? 'icon__disabled' : ''}`}
                                        onClick={() => this.deleteFile(item)}
                                      />
                                    </>
                                  )}
                                </Col>
                              )}
                            </Row>
                          ))}
                        </Row>
                      ) : (
                        <Empty
                          description={loading ? false : 'No Data'}
                          image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                          className="empty-icon"
                        />
                      )}
                    </SpinBox>
                  </Row>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    Comments
                    <Badge
                      overflowCount={9999}
                      showZero
                      count={this.state.totalComments}
                      className={selectedTab === TAB_COMMENTS_NUMBER ? 'selected-badge-icon' : 'badge-icon'}
                    />
                  </span>
                }
                key={TAB_COMMENTS_NUMBER}>
                {!isClient && (
                  <div style={{ width: isNurseManager ? 321 : 178 }} className="comment-tab-wrapper">
                    {isNurseManager && (
                      <a
                        onClick={() => this.changeCommentView(INTERNAL_COMMENTS)}
                        style={{ marginRight: '3%' }}
                        className={commentView === INTERNAL_COMMENTS ? 'active-comment' : 'inactive-comment'}>
                        Internal Comments
                      </a>
                    )}

                    <a
                      onClick={() => this.changeCommentView(EXTERNAL_COMMENTS)}
                      className={commentView === EXTERNAL_COMMENTS ? 'active-comment' : 'inactive-comment'}>
                      External Comments
                    </a>
                  </div>
                )}
                {commentView === INTERNAL_COMMENTS ? (
                  <InternalComments
                    changeCount={this.changeCount}
                    addOneComment={() => {
                      this.setState({ totalComments: this.state.totalComments + 1 });
                    }}
                    isExternal={false}
                    claimantInfo={claimantInfo}
                    InternalCommentsCount={InternalCommentsCount}
                  />
                ) : (
                  <InternalComments
                    changeCount={this.changeCount}
                    addOneComment={() => {
                      this.setState({ totalComments: this.state.totalComments + 1 });
                    }}
                    isExternal={true}
                    claimantInfo={claimantInfo}
                    InternalCommentsCount={InternalCommentsCount}
                    noneMargin={isClient}
                  />
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    Chat
                    <Badge
                      overflowCount={9999}
                      showZero
                      count={chatMessages.length}
                      className={selectedTab === TAB_CHAT_NUMBER ? 'selected-badge-icon' : 'badge-icon'}
                    />
                  </span>
                }
                key={TAB_CHAT_NUMBER}>
                {chatMessages !== [] && (
                  <Collapse defaultActiveKey={['1']}>
                    {chatMessages &&
                      chatMessages.map(chat => {
                        return (
                          <Panel
                            header={`${chat.chat.name} - ${chat.chat.description} (Report: ${chat.chat.report?.report_name})`}
                            key={chat.chat.id}>
                            {chat.messages &&
                              chat.messages.map(message => {
                                return (
                                  <p>
                                    <strong> {`${message.user.given_name} ${message.user.family_name}: `}</strong>
                                    {`${message.description}`}
                                    <p className="chat-date">
                                      {`(${moment(message.updatedAt).format('MM/DD/YYYY HH:mm')})`}
                                    </p>
                                  </p>
                                );
                              })}
                          </Panel>
                        );
                      })}
                  </Collapse>
                )}
              </TabPane>
              {isShowTabInvoicing && (
                <TabPane
                  tab={
                    <span>
                      Invoicing
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={transactionsTotalCount}
                        className={`${selectedTab === TAB_INVOICING_NUMBER ? 'selected-' : ''}badge-icon`}
                      />
                    </span>
                  }
                  key={TAB_INVOICING_NUMBER}>
                  <Row className="transactions-container">
                    <TransactionList
                      data={transactionsData}
                      setData={value => this.setState({ transactionsData: value })}
                      claimantInfo={claimantInfo}
                      loading={loading}
                      handleEditTransactions={this.handleEditTransactions}
                      refresh={() => this.getTransactionsData({ claimantInfo, useLoading: true })}
                    />
                  </Row>
                </TabPane>
              )}

              {/* notifications */}
              {NOTIFICATION_PERMISSIONS.viewTab && (
                <TabPane tab={<BadgeNotification />} key={TAB_NOTIFICATION_NUMBER}>
                  <TabNotifications claimant={claimantInfo} />
                </TabPane>
              )}
            </Tabs>
          </Row>
        </Row>

        {/* MODALS */}
        {showReportModal && (
          <ReportModal
            reportsData={this.state.reportsData}
            isOpen={showReportModal}
            type={modalType}
            templates={this.state.templates}
            reportTypes={this.state.reportTypes}
            data={selectedReport}
            claimant_id={this.state.claimantInfo.id}
            reportStatuses={reportStatuses}
            onConfirm={this.handleReport}
            onCancel={this.closeReportModal}
          />
        )}

        {showReportDeleteModal && (
          <DeleteModal
            title="Delete this Report?"
            content={selectedReport?.report_name}
            isOpen={showReportDeleteModal}
            onConfirm={this.handleReportDelete}
            onCancel={this.closeReportDeleteModal}
          />
        )}

        <NoteModal
          isOpen={showNoteModal}
          type={modalType}
          data={selectedNote}
          reportsData={reportsData}
          usersData={usersData}
          onConfirm={this.handleNote}
          onCancel={this.closeNoteModal}
        />

        {showNoteDeleteModal && (
          <DeleteModal
            title="Delete this Note?"
            content={selectedNote.note}
            isOpen={showNoteDeleteModal}
            onConfirm={this.handleNoteDelete}
            onCancel={this.closeNoteDeleteModal}
          />
        )}

        <RequestAgeModal
          isOpen={showAgeModal}
          onConfirm={this.handleNote}
          onCancel={this.closeAgeModal}
          claimantInfo={claimantInfo}
          selectedUser={selectUser}
          onFieldChange={value => {
            this.setState({ selectUser: value });
          }}
          onDateChange={value => {
            this.setState({ endDate: value });
          }}
          onAssignSubmit={this.submitRAAssignment}
          getFileToAssign={this.getFileToAssign}
          loading={loading}
          onNoteChange={this.onNoteChange}
          notes={notes}
          endDate={endDate}
        />

        <RequestRatedAgeModal
          isOpen={showRequestModal}
          onCancel={this.closeRequestModal}
          body={
            <Row type="flex" align="middle" className="delete-body">
              <Row className="titles">
                <Text className="label title">This feature is not yet include on your account.</Text>
              </Row>
              <Row>
                <Text className="label">Please contact our admin to discuss pricing.</Text>
              </Row>
              <Row>
                <Text className="label">
                  Email: <a href="mailto: nsingleton@msatech.io">nsingleton@msatech.io</a>
                </Text>
              </Row>
              <Row>
                <Text className="label">Phone Number: 8134448335</Text>
              </Row>
              <Row className="btn_modal">
                <Button
                  type="primary"
                  size="default"
                  className="green-btn button custom-btn"
                  onClick={this.closeRequestModal}>
                  Close
                </Button>
              </Row>
            </Row>
          }
          title={null}
          className="request-modal-rated"
          width={600}></RequestRatedAgeModal>
        <RequestRatedAgeModal
          isOpen={showRequestModalNoAdmin}
          onCancel={this.closeRequestModalNoAdmin}
          body={
            <Row type="flex" align="middle" className="delete-body">
              <Row className="titles">
                <Text className="label title">This feature is not yet include on your account.</Text>
              </Row>
              <Row>
                <Text className="label">Please contact your admin to discuss the feature.</Text>
              </Row>
              <Row className="btn_modal">
                <Button
                  type="primary"
                  size="default"
                  className="green-btn button custom-btn"
                  onClick={this.closeRequestModalNoAdmin}>
                  Close
                </Button>
              </Row>
            </Row>
          }
          title={null}
          className="request-modal-rated"
          width={600}></RequestRatedAgeModal>

        <FilePhotoModal
          isOpen={showFilePhotoModal}
          renderUploadedImage={renderUploadedImage}
          onCancel={this.closePhotoModal}
        />

        <CalculatedRatedAges
          changeRatedAges={this.changeRatedAges}
          isOpen={this.state.openCalculateModal}
          claimant_id={this.state.claimantInfo.id}
          onCancel={() => this.setState({ openCalculateModal: false })}
        />

        {showChooseReportsModal && (
          <ChooseReportsModal
            isOpened={showChooseReportsModal}
            claimantInfo={claimantInfo}
            reportDefault={reportDefaultTransaction}
            onClose={() =>
              this.setState({
                showChooseReportsModal: false,
                editedTransaction: null,
                reportDefaultTransaction: null,
              })
            }
            addRow={this.insertTransactionRow}
            editedTransaction={editedTransaction}
          />
        )}

        {openModalUploadFile && (
          <UploadFileModal
            close={this.handleCloseModalUploadFile}
            returnFiles={() => this.handleUploadFiles({ claimantInfo })}
            reportName={REPORT_TYPES_BY_ID[dataUploadFile.reportTypeId]}
            reportId={dataUploadFile.reportId}
            claimId={dataUploadFile.claimantId}
            only={[REPORT_FIELD_MEDICAL_RECORDS]}
            title="Add Records"
          />
        )}

        {openModalReview.open && (
          <ModalReview
            confirm={this.handleConfirmSendReview}
            close={() => this.handleOpenModalReview(null)}
            reportId={openModalReview.report.id}
            claimId={openModalReview.report.claimant_id}
            only={[REPORT_FIELD_MEDICAL_RECORDS]}
            title="Add records"
          />
        )}

        {openModalRev && (
          <Modal visible={openModalRev} footer={null} onCancel={() => this.setState({ openModalRev: false })}>
            <div className="msa-modal-content">
              <Title level={4} className="title-text">
                You’re about to remove your requested revision
              </Title>
              <p style={{ fontSize: '15px' }}>Are you sure you want to continue?</p>
              <div className="msa-modal-btns">
                <Button
                  type="primary"
                  className="green-btn"
                  onClick={() => {
                    this.removeRequest();
                    this.setState({ openModalRev: false });
                  }}>
                  Remove revision requested
                </Button>
                <Button
                  type="primary"
                  className="purple-btn-outline"
                  onClick={() => this.setState({ openModalRev: false })}>
                  Go back
                </Button>
              </div>
            </div>
          </Modal>
        )}

        {modalAssignOpen && (
          <Modal
            width={700}
            maskClosable={false}
            bodyStyle={{ padding: 0, minWidth: 450 }}
            visible={modalAssignOpen}
            onCancel={() => this.setState({ modalAssignOpen: false })}
            keyboard={false}
            footer={null}>
            <AddAssignForm
              onSubmit={this.saveEdit}
              edit={editedparams}
              reportId={assignedReportId}
              disabledField={{
                assignmentType: assignmentTypeDisabled,
                client: true,
                claimant: true,
                report: true,
                user: assignedUserDisabled,
              }}
              defaultAssignmentType={assignmentTypeDisabled ? ASSIGNMENT_TYPE_NURSE : null}
            />
          </Modal>
        )}

        {showFileModal && (
          <UpdateFile
            isOpen={showFileModal}
            onConfirm={this.handleFileModal}
            onCancel={this.closeFileModal}
            EditFile={EditFile}
            onChangeName={value =>
              this.setState({
                EditFile: { ...EditFile, name: value },
              })
            }
            onFileSubmit={() => {
              this.setState({ loading: true });
              const { id, name } = EditFile;
              const data = {
                name: name,
              };
              API.updateUploadedFile(id, data).then(res => {
                const temp = [...files];
                const fileToBeUpdated = temp.find(file => file.id === res.file.id);
                fileToBeUpdated.name = res.file.name;
                this.setState({
                  loading: false,
                  uploadedFiles: {
                    files: temp,
                  },
                  showFileModal: false,
                });
              });
            }}
            loading={this.state.loading}
          />
        )}

        {loadingInternal && <ModalLoading />}

        {openModalUpgradedPlan?.open && (
          <ModalUpgratePackageOpenReport
            data={openModalUpgradedPlan}
            onClose={() => this.setState({ openModalUpgradedPlan: false })}
          />
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, { clearReportFiles })(DetailedClaimant);