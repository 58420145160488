import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Typography, Button, Modal, notification, Empty } from 'antd';
import { NavLink } from 'react-router-dom';
import * as API from 'utils/api';

import SpinBox from '../../components/SpinBox';
import PdfPage from '../../components/PdfPage/PdfPageClass';
import { Backicon } from 'components/Icons';
import { getAssignmentById, setHighlights, updateAssignmentById } from '../../redux/review';
import {
  ASSIGNMENT_STATUS_APPROVED,
  ASSIGNMENT_STATUS_IN_PROGRESS,
  ASSIGNMENT_STATUS_PENDING_REVIEW,
  ASSIGNMENT_STATUS_REFUSED,
  ASSIGNMENT_STATUS_RETURNED,
} from './assignment-statuses';
import { REVIEWS_GET_ASSIGNMENT } from '../../redux/review/actionTypes';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

import './report.scss';

export default function ReportView() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [dialogOpened, setDialogOpened] = useState(false);
  const { Title, Text } = Typography;
  const { id } = useParams();
  const { isLoading, isPending, assignment } = useSelector(({ review }) => review);
  const { roles } = useSelector(state => state.auth);

  const highlights = assignment.highlights || [];
  const highlightLogs = assignment.highlight_logs || [];
  const hasNoHighlights = highlights.length === 0;
  const isUnlocked = assignment.status?.key !== ASSIGNMENT_STATUS_PENDING_REVIEW;
  const isApproved = assignment.status?.key === ASSIGNMENT_STATUS_APPROVED;

  useEffect(() => {
    dispatch({ type: REVIEWS_GET_ASSIGNMENT, payload: { assignment: {} } });
    getAssignmentById(id)(dispatch);
  }, [id, dispatch]);

  const onReject = async () => {
    const result = await updateAssignmentById(assignment.id, {
      status: ASSIGNMENT_STATUS_REFUSED,
    })(dispatch);
    if (!result) return;
    history.push('/app/reviews');
    notification['warning']({ message: 'Report refused' });
  };

  const onAccept = async () => {
    await updateAssignmentById(assignment.id, {
      status: ASSIGNMENT_STATUS_IN_PROGRESS,
    })(dispatch);
  };

  const onReturn = async () => {
    const result = await updateAssignmentById(assignment.id, {
      status: ASSIGNMENT_STATUS_RETURNED,
    })(dispatch);
    if (!result) return;
    history.push('/app/reviews');
    notification['warning']({ message: 'Report returned for revision' });
  };

  const onComplete = async () => {
    const result = await updateAssignmentById(assignment.id, {
      status: ASSIGNMENT_STATUS_APPROVED,
    })(dispatch);
    setDialogOpened(false);
    if (!result) return;
    history.push('/app/reviews');
    notification['success']({ message: 'Report approved' });
    updateReport(4);
  };

  const onChange = async highlights => {
    const result = await setHighlights(assignment.id, highlights)(dispatch);
    if (highlights.length === 0 || !result) return;
    notification['success']({ message: 'Highlights have been saved' });
  };

  const updateReport = (value) => {
    let params = { report_status_id: value, id: assignment.report.id };
    API.updateReport(params);
  };

  const EndButtonGroup = () => (
    <>
      {isLoading || isApproved ? (
        <div />
      ) : !isUnlocked ? (
        <div className="report__end-buttons">
          <Button type="danger" onClick={onReject}>
            Reject
          </Button>
          <Button type="primary" onClick={onAccept}>
            Accept
          </Button>
        </div>
      ) : (
        <div className="report__end-buttons report__end-buttons--single">
          {hasNoHighlights ? (
            <Button type="primary" onClick={() => setDialogOpened(true)} style={{ marginRight: 15 }}>
              Approve
            </Button>
          ) : (
            <Button type="danger" onClick={onReturn}>
              Return for Revision
            </Button>
          )}
        </div>
      )}
    </>
  );

  const documentUrl = assignment?.report?.report_doc;
  const showAction = isAllowed(roles, [...ROLES_DIVISIONS.INTERNAL, ...ROLES_DIVISIONS.QA_ONLY]);

  const countCol = width => {
    if (!showAction) {
      return width + 2;
    }

    return width;
  };

  return (
    <div className="report">
      <Row type="flex" justify="space-between" align="middle" className="header">
        <Col span={countCol(4)}>
          <NavLink to="/app/reviews">
            <Backicon />
          </NavLink>
        </Col>
        <Col span={countCol(16)} className="report__title">
          <Title level={4} className="title-text">
            Report
          </Title>
          <Text>{!isLoading ? assignment.claimant?.claimant_name || '...' : '...'}</Text>
        </Col>
        {showAction && (
          <Col span={4}>
            <EndButtonGroup />
          </Col>
        )}
      </Row>
      <div className="review-root">
        <SpinBox loading={isLoading} title="Pending...">
          {documentUrl ? (
            <PdfPage
              url={documentUrl}
              startHighlights={highlights}
              highlightLogs={highlightLogs}
              loading={isLoading}
              pending={isPending}
              onChange={onChange}
              disabled={!isUnlocked}
              isAdmin={false}
              disableHighlights={isApproved}
            />
          ) : (
            <Row className="table-content">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="empty-icon"
                description={<span>No report pdf file found...</span>}
              />
            </Row>
          )}
        </SpinBox>
      </div>
      <Modal
        title="Complete report?"
        visible={dialogOpened}
        onOk={onComplete}
        confirmLoading={isLoading}
        onCancel={() => setDialogOpened(false)}>
        <Text> You will not be able to make changes after confirmation</Text>
      </Modal>
    </div>
  );
}
