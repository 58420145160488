import React, { useState } from 'react';

// Components
import { Row, Col, Typography, Modal, Button } from 'antd';
import { BackIcon } from 'components/Icons';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';

// Const
import {
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_MCP_MSA_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  REPORT_MSA_TYPE_ID,
  REPORT_TYPE_NAME_BY_ID,
} from 'constants/reports';

// Style
import './stepUpload.scss';

const { Title } = Typography;

const StepUpload = ({ reportType, onPrevStep, onCreate }) => {
  const [listFiles, setListFiles] = useState([]);

  const beforeUpload = file => {
    if (!file) return false;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Modal.error({
        content: 'You can only upload PDF file!',
      });
    }

    return isPdf;
  };

  const handleAddFiles = ({ file }) => {
    const nameOld = listFiles.find(oldFile => oldFile.name === file.name);

    if (nameOld) return;

    setListFiles([...listFiles, file]);
  };

  const removeFile = ({ fileName }) => {
    setListFiles(listFiles.filter(file => file.name !== fileName));
  };

  const removeAllFiles = () => {
    setListFiles([]);
  };

  return (
    <Row className="add-claimant-container newClaimantStyle">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Check-out: {REPORT_TYPE_NAME_BY_ID[reportType]}
          </Title>
        </Col>
        <Col className="back" onClick={onPrevStep}>
          <BackIcon className="icon" />
        </Col>
      </Row>

      <div className="stepUpload">
        <div className="stepUpload_listCard">
          <div
            className={`stepUpload_card stepUpload_card_1 ${
              reportType === REPORT_MCP_TYPE_ID ? 'stepUpload_card_active' : ''
            }`}>
            <p className="stepUpload_card_1_title">
              <strong>Medical Cost Projection</strong>
            </p>
            <div className="stepUpload_card_content">
              <strong>WHAT TO KNOW:</strong>
              <p>
                On LOP files ONLY send MD future pricing for surgeries/procedures. We do not need any other future care
                recommendations. Procedures/Surgeries would include all surgeries, ESI, Medical branch blocks, RFA etc.
                In future care letters MD's recommendations are generally not inclusive of all services which could be
                used in the report and likely will limit the overall value of the report.
              </p>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT TO SEND:</strong>
              <p>From the attorney's office;</p>
              <ul>
                <li>Last two years of medical records (most current)</li>
                <li>Report of accident/injury</li>
                <li>All diagnostic reports</li>
                <li>All procedure reports</li>
                <li>All surgical reports</li>
                <li>LOP pricing for surgeries and or procedures ONLY</li>
              </ul>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT NOT TO SEND</strong>
              <ul>
                <li>Billing ledgers</li>
              </ul>
            </div>
          </div>
          <div
            className={`stepUpload_card stepUpload_card_2 ${
              reportType === REPORT_MSA_TYPE_ID ? 'stepUpload_card_active' : ''
            }`}>
            <p className="stepUpload_card_2_title">
              <strong>MSA Report</strong>
            </p>
            <div className="stepUpload_card_content">
              <strong>WHAT TO KNOW:</strong>
              <p>
                On LOP files DO NOT send MD future pricing for surgeries/procedures. We DO NOT need any other future
                care recommendations. MSA will use usual and customary pricing for procedures, surgeries, ESI, medical
                branch blocks, RFA, etc. on presettlement MSAs. On postsettlement MSAs, we will use the lowest fee
                schedule allowed by CMS so more monies can be allocated to the injured/your client at the time of
                settlement.
              </p>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT TO SEND:</strong>
              <p>From the attorney's office;</p>
              <ul>
                <li>Last two years of medical records (most current)</li>
                <li>Report of accident/injury</li>
                <li>All diagnostic reports</li>
                <li>All procedure reports</li>
                <li>All surgical reports</li>
                <li>LOP pricing for surgeries and or procedures ONLY</li>
              </ul>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT NOT TO SEND</strong>
              <ul>
                <li>Billing ledgers</li>
              </ul>
            </div>
          </div>
          <div
            className={`stepUpload_card stepUpload_card_3 ${
              reportType === REPORT_EARLY_INTERVENTION_TYPE_ID ? 'stepUpload_card_active' : ''
            }`}>
            <p className="stepUpload_card_3_title">
              <strong>EIR</strong>
            </p>
            <div className="stepUpload_card_content">
              <strong>WHAT TO KNOW:</strong>
              <p>
                On LOP files ONLY send MD future pricing for surgeries/procedures. We do not need any other future care
                recommendations. Procedures/Surgeries would include all surgeries, ESI, Medical branch blocks, RFA etc.
                In future care letters MD's recommendations are generally not inclusive of all services which could be
                used in the report and likely will limit the overall value of the report.
              </p>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT TO SEND:</strong>
              <p>From the attorney's office;</p>
              <ul>
                <li>Last two years of medical records (most current)</li>
                <li>Report of accident/injury</li>
                <li>All diagnostic reports</li>
                <li>All procedure reports</li>
                <li>All surgical reports</li>
                <li>LOP pricing for surgeries and or procedures ONLY</li>
              </ul>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT NOT TO SEND</strong>
              <ul>
                <li>Billing ledgers</li>
              </ul>
            </div>
          </div>
          <div
            className={`stepUpload_card stepUpload_card_4 ${
              reportType === REPORT_MCP_MSA_TYPE_ID ? 'stepUpload_card_active' : ''
            }`}>
            <p className="stepUpload_card_4_title">
              <strong>MCP / MSA Combo</strong>
            </p>
            <div className="stepUpload_card_content">
              <strong>WHAT TO KNOW:</strong>
              <p>
                On LOP files DO NOT send MD future pricing for surgeries/procedures. We DO NOT need any other future
                care recommendations. MSA will use usual and customary pricing for procedures, surgeries, ESI, medical
                branch blocks, RFA, etc. on presettlement MSAs. On postsettlement MSAs, we will use the lowest fee
                schedule allowed by CMS so more monies can be allocated to the injured/your client at the time of
                settlement.
              </p>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT TO SEND:</strong>
              <p>From the attorney's office;</p>
              <ul>
                <li>Last two years of medical records (most current)</li>
                <li>Report of accident/injury</li>
                <li>All diagnostic reports</li>
                <li>All procedure reports</li>
                <li>All surgical reports</li>
                <li>LOP pricing for surgeries and or procedures ONLY</li>
              </ul>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT NOT TO SEND</strong>
              <ul>
                <li>Billing ledgers</li>
              </ul>
            </div>
          </div>
          <div
            className={`stepUpload_card stepUpload_card_5 ${
              reportType === REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID ? 'stepUpload_card_active' : ''
            }`}>
            <p className="stepUpload_card_5_title">
              <strong>Mini Cost Projection</strong>
            </p>
            <div className="stepUpload_card_content">
              <strong>WHAT TO KNOW:</strong>
              <p>
                On LOP files ONLY send MD future pricing for surgeries/procedures. We do not need any other future care
                recommendations. Procedures/Surgeries would include all surgeries, ESI, Medical branch blocks, RFA etc.
                In future care letters MD's recommendations are generally not inclusive of all services which could be
                used in the report and likely will limit the overall value of the report.
              </p>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT TO SEND:</strong>
              <p>From the attorney's office;</p>
              <ul>
                <li>Last two years of medical records (most current)</li>
                <li>Report of accident/injury</li>
                <li>All diagnostic reports</li>
                <li>All procedure reports</li>
                <li>All surgical reports</li>
                <li>LOP pricing for surgeries and or procedures ONLY</li>
              </ul>
            </div>
            <div className="stepUpload_card_content">
              <strong>WHAT NOT TO SEND</strong>
              <ul>
                <li>Billing ledgers</li>
              </ul>
            </div>
          </div>
        </div>

        <BoxDragAndDrop multiple beforeUpload={beforeUpload} handleAddFiles={handleAddFiles} />

        {listFiles.length > 0 && (
          <TableFileBoxDragAndDrop listFiles={listFiles} removeFile={removeFile} removeAllFiles={removeAllFiles} />
        )}

        <div className="stepCheckout_btn">
          <Button type="primary" size="large" className="transparent-btn button" onClick={onPrevStep}>
            Previous Step
          </Button>
          <Button
            type="primary"
            size="large"
            className="green-btn button"
            onClick={() => onCreate({ listFile: listFiles })}>
            Create
          </Button>
        </div>
      </div>
    </Row>
  );
};

export default StepUpload;
