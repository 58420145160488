import React from 'react';
import { Row, Empty } from 'antd';

import MedicareCostMenu from '../MedicareCostMenu';

import {
  LCPMedicareCostTableContentCustom,
  LCPMedicareCostTableContentMedicalCost,
  LCPMedicareCostTableContentPrescription,
} from '.';
import {
  LIST_NO_EDIT_TABLE,
  TABLE_MEDICAL_COSTS_NAME,
  TABLE_PRESCRIPTION_NAME,
  TABLE_SURGERIES_NAME,
} from 'containers/Templates/TemplateLifeCarePlan/const/itemsTableDragable';

const LCPMedicareCostTableContent = ({
  data,
  report,
  canEdit,
  handleClone,
  handleDelete,
  handleEdit,
  handleChangePosition,
  structure,
}) => {
  const orderData = data.sort((a, b) => {
    return a.position - b.position;
  });

  return (
    <Row className="table-content">
      {data.length > 0 ? (
        <Row>
          {orderData.map((item, index) => {
            return (
              <Row key={`${index}-${item.id}`} className="record" id={'table-item-' + item.id + item.position}>
                {[TABLE_SURGERIES_NAME, TABLE_MEDICAL_COSTS_NAME].includes(item.type) && (
                  <LCPMedicareCostTableContentMedicalCost structure={structure} item={item} report={report} />
                )}
                
                {item.type === TABLE_PRESCRIPTION_NAME && (
                  <LCPMedicareCostTableContentPrescription structure={structure} item={item} report={report} />
                )}

                {!LIST_NO_EDIT_TABLE.includes(item.type) && (
                  <LCPMedicareCostTableContentCustom structure={structure} item={item} report={report} />
                )}

                {canEdit && (
                  <div className="btnMore_lcp_table">
                    <MedicareCostMenu
                      handleClone={handleClone}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      handleChangePosition={handleChangePosition && handleChangePosition}
                      item={item}
                    />
                  </div>
                )}
              </Row>
            );
          })}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
      )}
    </Row>
  );
};

export default LCPMedicareCostTableContent;
