import React, { useState, useEffect } from 'react';
import { Col, Empty, Modal, Button, Row, Typography } from 'antd';
import { connect } from 'react-redux';

import { DetailsIcon, EditIcon, RemoveIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import CreatePricingGroup from './CreatePricingGroups/CreatePricing';
import * as API from 'utils/api';
import UpdatePricing from './UpdatePricing/UpdatePricing';
import Pagination from 'components/Pagination';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { notifyApiError } from 'utils/notification';

import './PricingGroup.scss';

function PricingGroups(props) {
  const { Title, Text } = Typography;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getAllPricingGroups, setgetAllPricingGroups] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [viewPricingName, setViewPricingName] = useState('');

  useEffect(() => {
    setLoading(true);
    API.getResourceGroups(10, pageIndex)
      .then(res => {
        setgetAllPricingGroups(res.pricing_groups);
        setTotalCount(res.count);
      })
      .catch(err => notifyApiError(err))
      .finally(() => setLoading(false));
  }, [pageIndex]);

  const handleChange = value => {
    setName(value);
    setNameError('');
  };

  const onSubmit = async () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      setNameError('Name cannot be empty!');
      return;
    }
    if (!name) {
      setNameError('Name cannot be empty!');
      return;
    }
    const data = {
      name,
    };

    try {
      setLoading(true);
      await API.createPricingGroupName(data).then(res => {
        setgetAllPricingGroups([...getAllPricingGroups, res.pricing_group]);
        setLoading(false);
        setIsOpen(false);
        setName('');
      });
    } catch (error) {
      notifyApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePricingName = id => {
    setLoading(true);
    API.deletePricingGroup(id).then(() => {
      let tempArr = [...getAllPricingGroups];
      const filteredNames = tempArr.filter(data => data.id !== id);
      setgetAllPricingGroups(filteredNames);
      setLoading(false);
    });
  };

  const handleUpdate = async () => {
    const id = viewPricingName.id;
    const data = {
      name: viewPricingName.name,
    };
    if (!viewPricingName.name) return setNameError('Name cannot be empty!');
    if (!viewPricingName.name.trim()) return setNameError('Name cannot be empty!');
    

    try {
      setLoading(true);
      await API.updatePricingGroup(id, data).then(res => {
        let tempArr = [...getAllPricingGroups];
        const nameTobeUpdated = tempArr.find(data => data.id === res.pricing_group.id);
        nameTobeUpdated.name = res.pricing_group.name;
        setgetAllPricingGroups(tempArr);
        Modal.success({
          content: 'Success! ',
        });
        setLoading(false);
        setOpenUpdateModal(false);
      });
    } catch (error) {
      notifyApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="users-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Pricing Groups
          </Title>
        </Col>

        <Col className="pagination">
          <Pagination totalCount={totalCount} pageIndex={pageIndex} pageSize={10} onChange={setPageIndex} />
        </Col>
        {isAllowed(props.roles, ROLES_DIVISIONS.MANAGEMENT) && (
          <Col className="add-button dropdown-container">
            <Button type="primary" onClick={() => setIsOpen(true)} className="green-btn">
              Create Pricing Group
            </Button>
          </Col>
        )}
      </Row>
      <Row type="flex" className="table-header">
        <Col xs={12} md={18}>
          Name
        </Col>
        {isAllowed(props.roles, ROLES_DIVISIONS.INTERNAL_NURSE) && (
          <Col xs={12} md={6} style={{ paddingLeft: '2%', textAlign: 'right' }}>
            Actions
          </Col>
        )}
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading}>
          {getAllPricingGroups.length > 0 ? (
            <Row>
              {getAllPricingGroups.map(data => (
                <Row key={data.id} className="record rendered-data">
                  <Col md={18}>
                    <Text className="rendered-name">{data.name}</Text>
                  </Col>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <DetailsIcon className="icon" onClick={() => props.history.push(`/app/pricing/${data.id}`)} />
                    {isAllowed(props.roles, ROLES_DIVISIONS.MANAGEMENT) && (
                      <>
                        <EditIcon
                          className="icon"
                          onClick={() => {
                            setViewPricingName(data);
                            setOpenUpdateModal(true);
                          }}
                        />
                        <RemoveIcon className="icon" onClick={() => deletePricingName(data.id)} />
                      </>
                    )}
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Empty
              description={loading ? false : 'No Data'}
              image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
              className="empty-icon"
            />
          )}
        </SpinBox>
      </Row>
      <CreatePricingGroup
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        loading={loading}
        onSubmit={onSubmit}
        nameError={nameError}
        handleChange={handleChange}
        name={name}
      />
      <UpdatePricing
        isOpen={openUpdateModal}
        onCancel={() => {
          setOpenUpdateModal(false);

          setNameError('');
        }}
        loading={loading}
        nameError={nameError}
        viewPricingName={viewPricingName}
        onNameChange={value => {
          setViewPricingName({
            ...viewPricingName,
            name: value,
          });
          setNameError('');
        }}
        handleUpdate={handleUpdate}
      />
    </Row>
  );
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, null)(PricingGroups);
