import React, { Component } from 'react';
import { Button, Col, Divider, Icon, Input, Modal, Row, Select, Tooltip, Typography } from 'antd';
import dynamicData from 'dynamicData';
import moment from 'moment';
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import DatePickerSimple from 'react-datepicker';
import ClaimAndInjury from 'components/ClaimAndInjury/index';
import ClaimantInfo from 'components/ClaimantInfoOnWizard';
import InlineContact from 'components/InlineContact';
import InputCurrencyBox from 'components/InputCurrencyBox';
import SpinBox from 'components/SpinBox';
import TagsInputBox from 'components/TagsInputBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';
import * as Logic from '../ProposedMedicare/logic';
import DropDown from 'components/Dropdown';
import TemplateFields from 'components/TemplateFields';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import PrevNextStep from '../../../../components/PrevNextStep';
import { sortArrayObjectForKey } from 'utils/utils';
import InputBox from 'components/InputBox';
import { getValueInsuranceCarrier } from 'containers/Reports/utils/helpers';
import { createViewReportIfNotExists } from 'utils/report';
import { SignatureReport } from 'containers/Reports/components/SignatureReport';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';
import { getListSignature } from 'containers/Reports/utils/helpers';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { CLAIMANT_EDIT, MAIN_NAV } from 'layout/navigate_const';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { REPORT_MSA_NAME } from 'constants/reports';
import { ModalLoading } from 'components/Modal/ModalLoading';

import './Demographic.scss';

const { Title, Text } = Typography;
const { Option } = Select;

const templateFields = [
  {
    display_name: 'name',
    default_value: 'mege',
  },
  {
    display_name: 'name1',
    default_value: 'mege2',
  },
  {
    display_name: 'name4',
    default_value: 'value4',
  },
  {
    display_name: 'name3',
    default_value: 'mge6',
  },
];

class Demographic extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      contactsList: [],
      icdCodeList: [],
      juridictionStateList: [],
      plaintiff_attorney: undefined,
      defense_attorney: undefined,
      insurance_carrier: undefined,
      employer_demographics: undefined,
      report_claim_id: '',
      body_parts: '',
      show_additional_comments: false,
      additional_comments: '',
      related_diagnosis: [],
      referral_date: moment(),
      date_of_report: moment(),
      juridiction_state_id: undefined,
      is_owcp: undefined,
      date_of_injury: moment(),
      icd_code: '',
      icd_descr: '',
      isModalOpen: false,
      medicareInfo: {},
      loading: false,
      loadingTitle: 'Loading...',
      report_contacts: [],
      plaintifList: [],
      defenseList: [],
      employerList: [],
      medicare: [],
      is_template: false,
      isClaimCompleted: false,
      signature: [],
      REPORT_PERMISSIONS,
      loadingInit: true,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);

    this.loadReportContacts = this.loadReportContacts.bind(this);
    this.getContactLists = this.getContactLists.bind(this);
    this.getReportContacts = this.getReportContacts.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAddNewICDCode = this.handleAddNewICDCode.bind(this);
    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleSearchIcdCodes = this.handleSearchIcdCodes.bind(this);
    this.goToEditClaimant = this.goToEditClaimant.bind(this);
    this.handleDiagnosisDragStart = this.handleDiagnosisDragStart.bind(this);
    this.handleDiagnosisDragEnd = this.handleDiagnosisDragEnd.bind(this);
    this.handleDiagnosisDragOver = this.handleDiagnosisDragOver.bind(this);
    this.handleChangeSignature = this.handleChangeSignature.bind(this);
    this.handleDeleteSignature = this.handleDeleteSignature.bind(this);
    this.handleAddSignature = this.handleAddSignature.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.timeoutId);
  }

  async loadReportContacts() {
    try {
      const res = await API.getReportContacts(this.state.report_id);

      this.setState({ report_contacts: res.report_contacts.rows });
    } catch (e) {
      notifyApiError(e);
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });

    this.getContactLists();

    Promise.all([
      API.getIcdCodeData('').then(res => {
        this.setState(
          {
            icdCodeList: res.icd_codes,
          },
          () => {}
        );
      }),
      API.getJuridictionStateList().then(res => {
        this.setState({ juridictionStateList: sortArrayObjectForKey({ array: res.juridictionStates, key: 'name' }) });
      }),
      API.getMedicareData({ reportId: report_id }).then(res => {
        this.setState({
          loading: false,
          medicare: res.medicare_cost_projections,
        });
      }),
    ]);

    this.loadReportContacts();

    const flag = isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY);

    // si eres Internal QA buscamos la copia del reporte si no existe la creamos
    if (isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY)) {
      await createViewReportIfNotExists({ reportId: report_id });
    }

    try {
      const reportInfo = await API.getReportById(report_id, flag);

      if (!reportInfo.is_template) {
        verifyPermissions(reportInfo, this.props);
      }

      const redirect = await qaHaveAssignedReport({
        roles: this.props.roles,
        reportId: report_id,
        claimantId: reportInfo.claimant_id,
      });

      if (redirect) return this.props.history.push(MAIN_NAV.path);

      this.props.updateReportInfo(reportInfo);
      let related_diagnosis = [];
      let newIcdCodeList = [];
      let finallIcdCodeList = [];
      if (reportInfo.related_diagnosis && reportInfo.related_diagnosis !== '') {
        related_diagnosis = reportInfo.related_diagnosis.split(',');
        newIcdCodeList = related_diagnosis
          .map(item => {
            return {
              ...JSON.parse(item.split('<').join(',').split('$').join(',')),
            };
          })
          .filter(item => !this.state.icdCodeList.find(diagnosis => diagnosis.code === item.code));
        finallIcdCodeList = [...newIcdCodeList, ...this.state.icdCodeList];
        this.setState({ icdCodeList: finallIcdCodeList });
      }

      this.setState({
        is_template: reportInfo.is_template,
        report_template_id: reportInfo.report_template_id,
        oldDiagnosis: reportInfo.related_diagnosis,
        plaintiff_attorney: reportInfo.plaintiff_attorney || undefined,
        defense_attorney: reportInfo.defense_attorney || undefined,
        insurance_carrier: reportInfo.insurance_carrier || undefined,
        report_claim_id: reportInfo.report_claim_id || '',
        employer_demographics: reportInfo.employer_demographics || undefined,
        body_parts: reportInfo.body_parts,
        show_additional_comments: reportInfo.show_additional_comments,
        additional_comments: reportInfo.additional_comments,
        related_diagnosis,
        referral_date: reportInfo.referral_date ? moment(reportInfo.referral_date) : moment(),
        date_of_report: reportInfo.date_of_report ? moment(reportInfo.date_of_report) : moment(),
        juridiction_state_id: reportInfo.is_owcp ? 1000 : reportInfo.juridiction_state_id,
        is_owcp: reportInfo.is_owcp,
        date_of_injury: reportInfo.date_of_injury ? moment(reportInfo.date_of_injury) : moment(),
        loading: false,
        signature: getListSignature({ signature: reportInfo.signature }),
        loadingInit: false,
      });
    } catch (err) {
      forbiddenRedirect(err, this.props);
    }
  }

  getContactLists() {
    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

    const tenantOnly = isUserNurseManager && !isUserAdmin;

    //Getting all contacts data
    API.getContactsData({ pageSize: 1000, tenantOnly }).then(res => {
      const dynamic = dynamicData();

      this.setState({
        contactsList: res.contacts,
        plaintifList: res.contacts.filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.plaintiff_attorney
        ),
        defenseList: res?.contacts.filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.defense_attorney
        ),
        // insuranceList: res?.contacts.filter(
        //   item => (item.contact_type ? item.contact_type.name : '') === dynamic.insurance_carrier
        // ),
        employerList: res?.contacts.filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.employer_demographics
        ),
      });
    });
  }

  async getReportContacts() {
    const { report_id } = this.state;
    let res = await API.getReportContacts(report_id);
    this.setState({ report_contacts: res.report_contacts.rows });
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  handleAddNewICDCode() {
    const { icd_code, icd_descr, icdCodeList, related_diagnosis } = this.state;
    if (window.isEmptyOrSpaces(icd_code) || window.isEmptyOrSpaces(icd_descr)) {
      return alert('Please input valid data');
    }
    const newItem = { code: icd_code, med_descr: icd_descr };
    if (icd_code === '' || icd_descr === '') {
      this.setState({ isModalOpen: false, icd_code: '', icd_descr: '' });
      notifyApiError('Please input the values');

      return;
    }

    newItem.med_descr = newItem.med_descr.split(',').join('$');
    icdCodeList.push(newItem);
    related_diagnosis.push(JSON.stringify(newItem).split(',').join('<'));

    this.setState({ isModalOpen: false, icd_code: '', icd_descr: '' });
  }

  async handleUpdateReport(isNextStep = false) {
    const {
      report_id,
      plaintiff_attorney,
      defense_attorney,
      insurance_carrier,
      report_claim_id,
      employer_demographics,
      body_parts,
      show_additional_comments,
      additional_comments,
      related_diagnosis,
      referral_date,
      date_of_report,
      juridiction_state_id,
      is_owcp,
      date_of_injury,
      icdCodeList,
      signature,
    } = this.state;
    const new_related_diagnosis = [];

    related_diagnosis.forEach(value => {
      let item = icdCodeList.find(ele => value === ele.code);
      if (item) {
        new_related_diagnosis.push(JSON.stringify(item).replace(/,/g, '|'));
      } else {
        new_related_diagnosis.push(value);
      }
    });

    const params = {
      id: report_id,
      plaintiff_attorney,
      defense_attorney,
      insurance_carrier,
      report_claim_id,
      employer_demographics,
      body_parts,
      show_additional_comments,
      additional_comments,
      related_diagnosis: new_related_diagnosis.toString(),
      referral_date: moment(referral_date).format('MM/DD/YYYY'),
      date_of_report: moment(date_of_report).format('MM/DD/YYYY'),
      juridiction_state_id: is_owcp ? null : juridiction_state_id,
      is_owcp,
      date_of_injury: moment(date_of_injury).format('MM/DD/YYYY'),
      signature: signature.map(item => {
        delete item.imageUrl;
        return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
      }),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSearchIcdCodes(value) {
    API.getIcdCodeData(value).then(res => {
      let correctArr = [...res.icd_codes];
      this.setState({
        icdCodeList: correctArr,
      });
    });
  }

  goToEditClaimant() {
    const claimant = this.props.reportInfo.claimant;
    this.props.history.push(CLAIMANT_EDIT.path, { data: claimant });
  }

  handleDiagnosisDragStart(e) {
    this.dragged = e.currentTarget.parentElement.parentElement;
  }

  handleDiagnosisDragEnd(e) {
    this.dragged.style.display = 'block';

    e.target.parentElement.parentElement.classList.remove('drag-up');
    this.over.classList.remove('drag-up');

    e.target.parentElement.parentElement.classList.remove('drag-down');
    this.over.classList.remove('drag-down');

    const from = this.dragged.querySelector('.related-list-item').dataset.id;
    const to = this.over.querySelector('.related-list-item').dataset.id;
    const data = arrayMove(this.state.related_diagnosis, from, to);

    this.setState({ related_diagnosis: data });
  }

  handleDiagnosisDragOver(e) {
    e.preventDefault();

    this.dragged.style.display = 'none';

    if (e.target.tagName !== 'LI') return;

    const dgIndex = this.dragged.querySelector('.related-list-item').dataset.id;
    const taIndex = e.target.querySelector('.related-list-item').dataset.id;
    const animateName = dgIndex > taIndex ? 'drag-up' : 'drag-down';

    if (
      this.over &&
      e.target.querySelector('.related-list-item').dataset.rid !==
        this.over.querySelector('.related-list-item').dataset.rid
    ) {
      this.over.classList.remove('drag-up', 'drag-down');
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      this.over = e.target;
    }
  }

  handleChangeSignature(data) {
    this.setState(data);
  }

  handleDeleteSignature({ index }) {
    this.setState({ signature: this.state.signature.filter((item, inde) => inde !== index) });
  }

  handleAddSignature() {
    const add = [...this.state.signature, INIT_SIGNATURE];
    this.setState({ signature: add });
  }

  render() {
    let dataDynamic = { ...dynamicData() };

    const {
      icdCodeList,
      juridictionStateList,
      report_claim_id,
      body_parts,
      additional_comments,
      related_diagnosis,
      referral_date,
      date_of_report,
      juridiction_state_id,
      is_owcp,
      icd_code,
      icd_descr,
      isModalOpen,
      loading,
      loadingTitle,
      plaintifList,
      defenseList,
      employerList,
      is_template,
      signature,
      REPORT_PERMISSIONS,
      loadingInit,
    } = this.state;

    const notValid = !is_template && !juridiction_state_id && !is_owcp;

    if (loadingInit) return <ModalLoading />;

    return (
      <Row className="msa-demographic-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                disabled={notValid}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown
                beforeNext={async () => await this.handleUpdateReport(false)}
                loading={loading}
                disabled={notValid}
              />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <ClaimantInfo
                    goToEditClaimant={REPORT_PERMISSIONS.edit && this.goToEditClaimant}
                    claimant={{
                      ...this.props.reportInfo.claimant,
                      report_claim_id,
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Demographic Information</Text>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    getContactLists={this.getContactLists}
                    contact_type_id={dataDynamic.plaintiff_attorney_id}
                    another={false}
                    label="Plaintiff Attorney"
                    disabled={!REPORT_PERMISSIONS.edit}
                    report_id={this.state.report_id}
                    contactsList={plaintifList}
                    type={'plaintiff_attorney'}
                    value={this.state.report_contacts
                      .filter(item => item.type === 'plaintiff_attorney')
                      .map(item => {
                        if (!item.contact) {
                          return null;
                        }
                        return item.contact.id;
                      })
                      .filter(x => x)}
                  />
                </Col>
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    getContactLists={this.getContactLists}
                    contact_type_id={dataDynamic.defense_attorney_id}
                    another={false}
                    label="Defense Attorney"
                    report_id={this.state.report_id}
                    disabled={!REPORT_PERMISSIONS.edit}
                    contactsList={defenseList}
                    type={'defense_attorney'}
                    value={this.state.report_contacts
                      .filter(item => item.type === 'defense_attorney')
                      .map(item => item.contact.id)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <InputBox
                    label="Insurance carrier"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={getValueInsuranceCarrier({
                      value: this.state.insurance_carrier,
                      reportContact: this.state.report_contacts,
                      claimant: this.props.reportInfo.claimant,
                    })}
                    onChange={value => this.setState({ insurance_carrier: value })}
                    classes="addClaimantInput"
                  />
                </Col>
                <Col style={{ marginTop: -52 }} md={11}>
                  <Row>
                    <Text className={notValid ? 'jurisdiction-state-label' : ''}>State of Jurisdiction</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      disabled={!REPORT_PERMISSIONS.edit}
                      className="dropdown"
                      value={is_owcp ? 1000 : juridiction_state_id}
                      onChange={value => {
                        if (value === 1000) {
                          this.setState({ juridiction_state_id: null, is_owcp: true });
                        } else {
                          this.setState({ juridiction_state_id: value, is_owcp: null });
                        }
                      }}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {juridictionStateList.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    getContactLists={this.getContactLists}
                    contact_type_id={dataDynamic.employer_demographics_id}
                    another={false}
                    disabled={!REPORT_PERMISSIONS.edit}
                    label="Employer Demographics"
                    report_id={this.state.report_id}
                    contactsList={employerList}
                    type={'employer_demographics'}
                    value={this.state.report_contacts
                      .filter(item => item.type === 'employer_demographics')
                      .map(item => item.contact.id)}
                  />
                </Col>
              </Row>
              <ClaimAndInjury
                onChange={value => this.setState({ report_claim_id: value })}
                report_claim_id={report_claim_id}
                disabled={!REPORT_PERMISSIONS.edit}
                onClaimUpdated={value => this.setState({ isClaimCompleted: value })}
              />
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <TagsInputBox
                    label="Body Parts Accepted by Claims"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={body_parts}
                    classes={`editable-content ${!REPORT_PERMISSIONS.edit ? 'editable-content-disabled' : ''}`}
                    onChange={value => this.setState({ body_parts: value })}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Additional Comments"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={additional_comments}
                    onChange={value => this.setState({ additional_comments: value })}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Row className="label">
                    <Text>Related Diagnosis</Text>
                  </Row>
                  <Row>
                    <div className="related-list" onDragOver={e => this.handleDiagnosisDragOver(e)}>
                      <Select
                        mode="multiple"
                        className="dropdown"
                        filterOption={false}
                        placeholder="Please enter"
                        value={related_diagnosis}
                        disabled={!REPORT_PERMISSIONS.edit}
                        onSearch={value => {
                          if (value) {
                            this.handleSearchIcdCodes(value);
                          }
                        }}
                        onChange={value => {
                          this.setState({ related_diagnosis: value });
                        }}>
                        {icdCodeList.map((item, index) => {
                          let value = JSON.stringify({
                            code: item.code,
                            med_descr: item.med_descr ? item.med_descr.replace(/,/g, '$') : '',
                          }).replace(/,/g, '<');
                          const sortId =
                            related_diagnosis.indexOf(value) !== -1
                              ? related_diagnosis.indexOf(value)
                              : related_diagnosis.length + index;
                          return (
                            <Option key={index} value={value}>
                              <Tooltip
                                overlayStyle={{ maxWidth: 300 }}
                                placement="right"
                                title={` (${item.code}) ${item.med_descr}`}>
                                <span
                                  draggable="true"
                                  onMouseDown={e => {
                                    e.stopPropagation();
                                  }}
                                  onDragStart={e => this.handleDiagnosisDragStart(e)}
                                  onDragEnd={e => this.handleDiagnosisDragEnd(e)}
                                  data-id={sortId}
                                  data-rid={`${item.code}-${item.med_descr}`}
                                  className="related-list-item">
                                  {` (${item.code}) ${item.med_descr}`}
                                </span>
                              </Tooltip>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Row>
                  <Row>
                    <Button
                      disabled={!REPORT_PERMISSIONS.edit}
                      type="primary"
                      className="green-btn add-icd-code_button"
                      onClick={this.handleOpenModal}>
                      Add Custom Code
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    label="MSA Allocation Amount"
                    placeholder=" "
                    disabled={true}
                    value={Logic.anticipated_medicare_cost(this.state.medicare).toFixed(2) || 0}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <Row className="label">
                    <Text>Referral Date</Text>
                  </Row>
                  <Row>
                    <DatePickerSimple
                      className="demographic-date"
                      placeholderText="Select Referral Date"
                      selected={referral_date?.toDate()}
                      onChange={date => this.setState({ referral_date: date ? moment(date) : undefined })}
                      minDate={new Date('2000-01-01')}
                      showYearDropdown
                      disabled={!REPORT_PERMISSIONS.edit}
                      showMonthDropdown
                    />
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>Date of Report</Text>
                  </Row>
                  <Row>
                    <DatePickerSimple
                      className="demographic-date"
                      placeholderText="Select Date of Report"
                      selected={date_of_report?.toDate()}
                      onChange={date => this.setState({ date_of_report: date ? moment(date) : undefined })}
                      minDate={new Date('2000-01-01')}
                      showYearDropdown
                      disabled={!REPORT_PERMISSIONS.edit}
                      showMonthDropdown
                    />
                  </Row>
                </Col>
              </Row>

              {(is_template || this.state.report_template_id) && (
                <TemplateFields
                  is_template={is_template}
                  section_id={1}
                  report_template_id={this.state.report_template_id}
                  templateFields={templateFields}
                  report_id={this.state.report_id}
                />
              )}

              {REPORT_PERMISSIONS.signature && !this.state.is_template && (
                <SignatureReport
                  defaultSignature={signature}
                  onChangeSignature={this.handleChangeSignature}
                  onDeleteSignature={this.handleDeleteSignature}
                  onAddSignature={this.handleAddSignature}
                  disabled={!REPORT_PERMISSIONS.edit}
                />
              )}
              <Divider className="divider" />

              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                disabled={notValid}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
        <Modal
          visible={isModalOpen}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={true}
          width={500}
          className="add-icd-code-modal"
          title={'Add Custom Code'}>
          <Row className="modal-body">
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Code</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={icd_code}
                  onChange={e => this.setState({ icd_code: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Description</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={icd_descr}
                  onChange={e => this.setState({ icd_descr: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Button type="primary" className="green-btn button" onClick={this.handleAddNewICDCode}>
                ADD
              </Button>
            </Row>
          </Row>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    roles: state.auth.roles,
    userInfo: state.auth.userInfo,
  };
};

export default connect(mapStateToProps, { updateReportInfo })(Demographic);
