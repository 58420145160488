import React from 'react';
import { Modal, Typography, Button } from 'antd';

const { Title, Text } = Typography;

const ModalAdverUpgradePlan = ({ onClose }) => {
  return (
    <Modal
      width={500}
      maskClosable={false}
      visible={true}
      onCancel={onClose}
      keyboard={false}
      className="modalUpgradedPackageOpenReport"
      footer={null}>
      <div className="modalUpgradedPackageOpenReport_content">
        <Title level={4}>Your current subscription does not include this feature.</Title>
        <Text>Please contact your Super Admin to request an upgrade to your package in order to use this feature</Text>
        <div className="modalConfirm_btns">
          <Button type="primary" className="green-btn" onClick={onClose}>
            close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAdverUpgradePlan;
