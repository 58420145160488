export const GET_REPORT_DATA_REQUEST = 'REPORT/GET_REPORT_DATA_REQUEST';
export const GET_REPORT_DATA_SUCCESS = 'REPORT/GET_REPORT_DATA_SUCCESS';
export const GET_REPORT_DATA_FAILURE = 'REPORT/GET_REPORT_DATA_FAILURE';
export const UPDATE_REPORT_DATA = 'REPORT/UPDATE_REPORT_DATA';
export const GET_REPORT_ASSIGNMENTS = 'REPORT/GET_REPORT_ASSIGNMENTS';
export const GET_ASSIGNMENT_HIGHLIGHTS = 'REPORT/GET_ASSIGNMENT_HIGHLIGHTS';
export const REPORT_UPDATE_ASSIGNMENT_STATUS = 'REPORT/UPDATE_ASSIGNMENT_STATUS';
export const GET_REPORT_DATA_END = 'REPORT/GET_REPORT_DATA_END';
export const GET_REPORT_FILES = 'REPORT/GET_REPORT_FILES';
export const GET_REPORT_FILES_ALL = 'REPORT/GET_REPORT_FILES_ALL';
export const GET_REPORT_RESOURCE_LINKS = 'REPORT/GET_REPORT_RESOURCE_LINKS';
