import React, { useState } from 'react';
import InputMask from 'react-input-mask';

// Components
import InputBox from 'components/InputBox';

// Utils
import { validatedFormatEmail } from 'utils/utils';

// Style
import '../stepNewTenant.scss';

const StepAccountInfo = ({ data, onSubmit }) => {
  const [accountInfo, setAccountInfo] = useState(data);

  const handleChange = e => {
    setAccountInfo({
      ...accountInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    onSubmit && onSubmit({ sectionStep: 'user', payload: accountInfo, nextStep: 2 });
  };

  const validForm = () =>
    accountInfo?.given_name &&
    accountInfo?.family_name &&
    accountInfo?.email &&
    validatedFormatEmail({ email: accountInfo.email }) &&
    accountInfo?.locale &&
    accountInfo?.company_name &&
    accountInfo?.address;

  return (
    <div className="stepContainer stepContainerNewTenant">
      <div className="stepTitleContainer">
        <p className="stepText">Step 1</p>
        <h1 className="stepTitle">Account Information</h1>
      </div>

      <div className="stepFormContainer stepFormContainer_newTenant">
        <InputBox
          label="First Name"
          placeholder="Required"
          value={accountInfo?.given_name || ''}
          onChange={value => handleChange({ target: { name: 'given_name', value } })}
        />

        <InputBox
          label="Last Name"
          placeholder="Required"
          value={accountInfo?.family_name || ''}
          onChange={value => handleChange({ target: { name: 'family_name', value } })}
        />

        <InputBox
          classes={`${accountInfo.email && !validatedFormatEmail({ email: accountInfo.email }) ? 'input_error' : ''} newTenantEmail`}
          type="email"
          label="Email"
          placeholder="Required"
          value={accountInfo.email}
          onChange={value => handleChange({ target: { name: 'email', value } })}
        />

        <div className="stepFormGroupNewTenant">
          <p>Phone</p>
          <InputMask
            mask="999-999-9999"
            placeholder="Required"
            value={accountInfo?.locale || ''}
            name="locale"
            id="locale"
            onChange={handleChange}
          />
        </div>

        <InputBox
          label="Address"
          placeholder="Required"
          value={accountInfo?.address || ''}
          onChange={value => handleChange({ target: { name: 'address', value } })}
        />

        <InputBox
          label="Company Name"
          placeholder="Required"
          value={accountInfo?.company_name}
          onChange={value => handleChange({ target: { name: 'company_name', value } })}
        />
      </div>

      <div className="stepTitleContainer">
        <p className="stepText">By continuing, you accept our</p>
        <div className="termsAndCondition">
          <a href="/terms">Terms and conditions</a>&<a href="/">Privacy Policy</a>
        </div>
      </div>

      <button className="stepNextButton" disabled={!validForm()} onClick={handleSubmit}>
        Next Step
      </button>
    </div>
  );
};

export default StepAccountInfo;
