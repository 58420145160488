import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Comment, Empty, Modal, Row, Tooltip, Typography, Mentions } from 'antd';
import { Auth } from 'aws-amplify';
import { EditIcon, RemoveIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as API from 'utils/api';
import './InternalComment.scss';
import UpdateInternalComment from './UpdateInternalComment';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { useSelector } from 'react-redux';

export default function InternalComments({ claimantInfo, isExternal, addOneComment, changeCount, noneMargin }) {
  let pageSize = 10;
  const { confirm } = Modal;
  const { Option } = Mentions;

  const { roles } = useSelector(state => state.auth);

  const [writeComment, setWriteComment] = useState('');
  const [commentErrorr, setCommmentErrorr] = useState('');
  const [userId, setUserId] = useState('');
  const [comments, setComments] = useState([]);
  const { Text } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateCommentModal, setUpdateCommentModal] = useState(false);
  const [UpdateComment, setUpdateComment] = useState('');

  const [users, setGetUsers] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const isCreated = isAllowed(roles, [
    ...ROLES_DIVISIONS.SUPERADMIN,
    ...ROLES_DIVISIONS.MANAGER,
    ...ROLES_DIVISIONS.NURSE_ONLY,
    ...ROLES_DIVISIONS.EXTERNAL_ONLY,
    ...ROLES_DIVISIONS.ALL_CLIENT,
  ]);

  // InternalCommentsCount = getAllComments.length;
  useEffect(() => {
    setPageIndex(0);
    Auth.currentAuthenticatedUser().then(res => {
      setUserId(res.attributes.sub);
    });
    API.loadMoreComments(0, pageSize, isExternal, claimantInfo.id).then(res => {
      setComments(res.claimant_comments);
    });

    API.getUsersDataSynchronised({ pageSize: 1000 }).then(res => {
      setGetUsers(res.users);
    });
    setWriteComment('');
  }, [claimantInfo.id, isExternal, pageSize]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const CreateComment = () => {
    const data = {
      text: writeComment,
      claimant_id: claimantInfo.id,
      user_id: userId,
      is_external: isExternal,
    };
    if (window.isEmptyOrSpaces(writeComment)) {
      setCommmentErrorr('Comment Cannot be empty!');
      return;
    }
    if (!writeComment) {
      setCommmentErrorr('Comment Cannot be empty!');
      return;
    }
    setLoading(true);

    API.createComment(data).then(res => {
      addOneComment();
      // changeCount(-1);
      setComments([...comments, res.claimant_comment]);
      Modal.success({
        content: 'Success',
        onOk: () => setIsModalVisible(false),
      });
      setLoading(false);
      setWriteComment('');
    });
  };

  const openEditModal = datas => {
    setUpdateCommentModal(true);
    setUpdateComment(datas);
  };
  const deleteComment = id => {
    confirm({
      title: 'Do you Want to delete this comment?',
      icon: <ExclamationCircleOutlined />,
      content: 'This Action cannot be undone!',
      onOk() {
        setLoading(true);

        const filter = comments.filter(comment => comment.id !== id);
        API.deleteComment(id).then(res => {
          changeCount(-1);
          setComments(filter);
          setLoading(false);
        });
      },
      onCancel() {},
    });
  };
  const updateCommentDatas = () => {
    const { text, id } = UpdateComment;
    const data = {
      text,
    };
    if (!text) {
      setCommmentErrorr('Comment cannot be empty!');
      return;
    }
    setLoading(true);
    API.updateComment(id, data).then(res => {
      const tempArr = [...comments];
      const CommentTobeUpdated = tempArr.find(comment => comment.id === res.claimant_comment.id);
      CommentTobeUpdated.text = res.claimant_comment.text;
      setUpdateCommentModal(false);
      setComments(tempArr);
      setLoading(false);
    });
  };

  const handleMention = value => {
    setWriteComment(value);
    setCommmentErrorr('');
  };

  const handleMentionSelect = () => {};

  const formatName = ({ name, email }) => {
    // ponemos en mayuscula la primera letra de cada palata
    let textWithoutIndex = name.replace(/(^\w{1})|(\s+\w{1})/g, letra => letra.toUpperCase());

    // separamos el usuario mencionado
    return `${textWithoutIndex.split(' ').join('')} (${email})`;
  };

  const handleLoadMoreComments = () => {
    // pageIndex++

    API.loadMoreComments(pageIndex + 1, pageSize, isExternal, claimantInfo.id).then(res => {
      setPageIndex(pageIndex + 1);
      setComments([...comments, ...res.claimant_comments]);
    });
  };

  return (
    <>
      <div className={`internal-btn ${noneMargin ? 'noneMargin' : ''}`}>
        {isCreated && (
          <Button
            style={{ borderRadius: '5px' }}
            type="primary"
            size="small"
            className="green-btn button bordered-btn"
            onClick={showModal}>
            Add New Comment
          </Button>
        )}
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          centered={true}
          closable={true}
          width={550}
          className="report-modal"
          title={'Create Comment'}>
          <SpinBox loading={loading}>
            <Row type="flex" align="middle" className="report-body">
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <Row style={{ paddingBottom: 10 }}>
                    <Text>Comment</Text>
                  </Row>
                  <Mentions
                    rows={8}
                    style={{ width: '100%' }}
                    placeholder="input @ to mention users."
                    onChange={handleMention}
                    onSelect={handleMentionSelect}
                    defaultValue="@afc163"
                    value={writeComment}>
                    {users &&
                      users.map((data, index) => (
                        <Option
                          value={formatName({ name: `${data.given_name} ${data.family_name}`, email: data.email })}
                          key={index}>
                          {`${data.given_name} ${data.family_name}`}
                        </Option>
                      ))}
                  </Mentions>
                  {commentErrorr && <Alert type="error" style={{ marginTop: 10 }} message={commentErrorr} banner />}
                </Col>
              </Row>

              <Button type="primary" size="large" className="green-btn button" onClick={CreateComment}>
                Create
              </Button>
            </Row>
          </SpinBox>
        </Modal>
      </div>
      {comments.length > 10 && (
        <a className="load-btn" onClick={handleLoadMoreComments}>
          Show more
        </a>
      )}

      {comments.length > 0 ? (
        comments
          .sort((a, b) => {
            return moment(a.createdAt) - moment(b.createdAt);
          })
          .map((data, index) => {
            return (
              <div key={index}>
                <Comment
                  author={
                    <a>
                      {userId === data.user_id
                        ? 'You'
                        : data.user
                        ? data.user.given_name + ' ' + data.user.family_name
                        : ''}
                    </a>
                  }
                  content={
                    <>
                      <div className="comment-btns">
                        {userId === data.user_id && (
                          <>
                            <EditIcon style={{ marginRight: '2%' }} onClick={() => openEditModal(data)} />
                            <RemoveIcon onClick={() => deleteComment(data.id)} />
                          </>
                        )}
                      </div>
                      <p>{data.text}</p>
                    </>
                  }
                  datetime={
                    <Tooltip title={moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{moment(data.createdAt).fromNow()}</span>
                    </Tooltip>
                  }
                />
              </div>
            );
          })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <UpdateInternalComment
        isOpen={updateCommentModal}
        onCancel={() => setUpdateCommentModal(false)}
        loading={loading}
        UpdateComment={UpdateComment}
        onChangeField={value => {
          setUpdateComment({ ...UpdateComment, text: value });
          setCommmentErrorr('');
        }}
        updateCommentDatas={updateCommentDatas}
        commentErrorr={commentErrorr}
      />
    </>
  );
}
