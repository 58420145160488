import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { notifyApiError, notifyError } from 'utils/notification';
import { REPORT_MSA_REDUCTION_TYPE_ID } from 'constants/reports';
import * as API from 'utils/api';
import { Auth } from 'aws-amplify';
import { MAIN_NAV } from 'layout/navigate_const';

export const verifyPermissions = (report, props) => {
  const permission = isAllowed(props.roles, [
    ...ROLES_DIVISIONS.NURSE,
    ...ROLES_DIVISIONS.EXTERNAL_ONLY,
    ...ROLES_DIVISIONS.QA_ONLY,
    ...ROLES_DIVISIONS.ATTORNEY_ONLY,
    ...ROLES_DIVISIONS.MANAGER,
  ]);
  if (!permission && report.claimant?.client?.tenant_id !== props?.userInfo?.attributes['custom:tenant_id']) {
    notifyError('You cannot view this report');
    return props.history.push(MAIN_NAV.path);
  }
};

export const verifyPermissionsReduction = ({ report, props, flag }) => {
  if (flag) return;

  if (report?.report_type_id !== REPORT_MSA_REDUCTION_TYPE_ID) {
    notifyError('You cannot view this report');
    return props.history.push(MAIN_NAV.path);
  }
};

export const forbiddenRedirect = (err, props) => {
  notifyApiError(err);

  const isUserClient = isAllowed(props.roles, ROLES_DIVISIONS.ALL_CLIENT);

  if (isUserClient) {
    return props.history.push('/app/claimants');
  }

  props.history.push('/app/clients');
};

export const qaHaveAssignedReport = async ({ roles, reportId, claimantId }) => {
  if (isAllowed(roles, ROLES_DIVISIONS.QA_ONLY)) {
    const assignment = await API.fetchReportAssignments({
      reportId,
      claimantId,
    });

    const currentUser = await Auth.currentAuthenticatedUser();
    const userAssigment = assignment.assignments[0].user_id;

    const idCurrentUser = currentUser.username;

    if (userAssigment !== idCurrentUser) {
      notifyError("You don't have permissions to open this report");
      return true;
    }
  }

  return false;
};
