import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import API, { graphqlOperation } from "@aws-amplify/api";
import Amplify from "aws-amplify";
// import store from "./redux/store";
import config from "./config";
export let listener = function (event) {
  // event.preventDefault();
  // localStorage.setItem("beforeUnloadDate", moment());
  // event.returnValue = "Write something clever here..";
};
Amplify.configure(config.amplify);
API.configure(config.amplify.API);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// window.onbeforeunload = function (event) {};
serviceWorker.unregister();
