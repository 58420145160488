import React, { Component } from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import { notifyApiError } from '../../../../utils/notification';
import { updateReportInfo } from '../../../../redux/report';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { REPORT_MSA_NAME } from 'constants/reports';

import './ContactInfo.scss';

const { Title, Text } = Typography;

class ContactInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      reportInfo: {},
      msa_calculation_method: 'Fee Schedule',
      loading: false,
      user: {},
      REPORT_PERMISSIONS,
    };
  }

  componentDidMount = () => {
    const { report_id } = this.state;
    Auth.currentUserInfo().then(res => {
      this.setState({ user: res.attributes });
    });
    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.setState({ is_template: reportInfo.is_template });
        this.props.updateReportInfo(reportInfo);
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const { msa_calculation_method } = reportInfo;
        this.setState({
          reportInfo,
          msa_calculation_method: msa_calculation_method || 'Fee Schedule',
          loading: false,
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  };

  handleUpdateReport = async () => {
    const { report_id, msa_calculation_method } = this.state;

    const params = {
      id: report_id,
      msa_calculation_method,
    };
    try {
      if (this.state.REPORT_PERMISSIONS.edit) await API.updateReport(params);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { reportInfo, loading } = this.state;

    return (
      <Row className="msa-contact-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown loading={loading} />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Contact Information</Text>
                </Col>
              </Row>
              <Row className="row">
                <Col>
                  <p>If you have any questions or require additional information, please contact me at:</p>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Signature</Text>
                </Col>
                <Col className="input-col signature-info">
                  {reportInfo.signature &&
                    reportInfo.signature.map(item => {
                      if (!item.is_signature_photo) return <Text className="text">{item.signature}</Text>;

                      return <img src={item.imageUrl} alt="signature" className="signature-image-info" />;
                    })}
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Date</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">{reportInfo.date_of_report}</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Phone</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">{this.state.user.locale ? this.state.user.locale : '-'}</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Email</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">{this.state.user.email}</Text>
                </Col>
              </Row>
              <Divider className="divider" />
              <PrevNextStep beforeNext={this.handleUpdateReport} />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, { updateReportInfo })(ContactInfo);
