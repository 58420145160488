import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import * as QueryString from 'query-string';
import * as API from 'utils/api';
import { updateAssignmentId } from '../../redux/review';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import qs from '../../utils/create-query-string';
import { Row, Col, Typography, Input, Empty, Button, Skeleton, Menu, Dropdown, Icon, Modal, Spin } from 'antd';
import PaginationHOC from '../../components/Pagination/PaginationHOC';
import { DetailsIcon } from 'components/Icons';
import { getAssignments } from '../../redux/review';
import { goFirstStep } from '../../utils/report-routing';
import { REPORT_SECTIONS_DEFAULT, REPORT_TYPES_BY_ID } from '../../constants/reports';
import {
  ASSIGNMENT_STATUS_IN_PROGRESS,
  ASSIGNMENT_STATUS_PENDING_REVIEW,
  ASSIGNMENT_STATUS_RETURNED,
} from './assignment-statuses';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { REPORT_COMPARE_NAV } from 'layout/navigate_const';
import { NOT_ACCESS_REPORT_COMPARATION } from 'utils/constant';
import { notifyError } from 'utils/notification';
import './reviews.scss';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { ModalUpgratePackageOpenReport } from 'components/Modal/ModalUpgratePackageOpenReport';

//display only these statuses
const REVIEW_STATUSES = [ASSIGNMENT_STATUS_PENDING_REVIEW, ASSIGNMENT_STATUS_RETURNED, ASSIGNMENT_STATUS_IN_PROGRESS];

const Reviews = () => {
  const { Title, Text } = Typography;
  const { Search } = Input;

  const history = useHistory();
  const dispatch = useDispatch();

  const { limit } = useSelector(({ application }) => application.settings);
  const { roles } = useSelector(state => state.auth);

  const { isLoading, assignments, count } = useSelector(({ review }) => review);

  const { url } = useRouteMatch();
  const query = QueryString.parse(history.location.search);
  const page = query.page || 0;
  const qaSearch = query.qaSearch?.trim() || '';

  const [update] = useState(false);
  const [searchInput, setSearchInput] = useState(qaSearch);
  const [selectPdf, setSelectPdf] = useState('');
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [openModalUpgradedPlan, setOpenModalUpgradedPlan] = useState(false);

  const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
  const isReviewUser = isAllowed(roles, [...ROLES_DIVISIONS.QA, ...ROLES_DIVISIONS.ATTORNEY_ONLY]);
  const isQaAttorney = isAllowed(roles, ROLES_DIVISIONS.QA_ONLY);
  const allowedPreviewPdf = isAllowed(roles, ROLES_DIVISIONS.QA);

  useEffect(() => {
    const loadAssignments = () => {
      const apiCall = tenant_only =>
        getAssignments({ qaSearch, page, limit, status: REVIEW_STATUSES, tenant_only })(dispatch);

      isReviewUser && !isUserAdmin ? apiCall(true) : apiCall();
    };

    loadAssignments();
    // eslint-disable-next-line
  }, [dispatch, page, qaSearch, limit, roles, update]);

  const handleGotoReport = selectedReport => async () => {
    const validOpenRerpot = await validateOpenReportForPackage({
      report_type: selectedReport.report_type_id,
      tenant_id: roles.user_roles.tenant_id,
    });

    if (!validOpenRerpot.open)
      return setOpenModalUpgradedPlan({
        ...validOpenRerpot,
        open: true,
        tenant_id: roles.user_roles.tenant_id,
        customer: roles.user_roles.id,
      });

    const isManager = isAllowed(roles, ROLES_DIVISIONS.SUPERMANGER);
    const isNurseWriter = isAllowed(roles, ROLES_DIVISIONS.NURSE_ONLY);

    if (
      isNurseWriter &&
      selectedReport?.report?.qaRevisionDate &&
      !NOT_ACCESS_REPORT_COMPARATION.includes(selectedReport.report?.report_status_id)
    )
      return history.push(`${REPORT_COMPARE_NAV.path}/${selectedReport.report.id}`);

    dispatch(updateAssignmentId(selectedReport.id));
    if (isManager) {
      if (selectedReport.status.key === 5) {
        await API.getViewReportById(selectedReport.report.id).then(async report => {
          if (report) {
            createMedicare(selectedReport.report.id);
            history.push('/app/reportChanges', { data: selectedReport });
          } else {
            await API.getReportById(selectedReport.report.id, true).then(async reportInfo => {
              await API.createViewReport(reportInfo).then(() => {
                createMedicare(selectedReport.report.id);
                history.push('/app/reportChanges', { data: selectedReport });
              });
            });
          }
        });
      } else {
        goFirstStep({
          sections:
            selectedReport.report.sections ||
            REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id]],
          reportType: REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id],
          report_id: selectedReport.report.id,
          history: history,
        });
      }
    } else {
      await API.getViewReportById(selectedReport.report.id).then(async report => {
        if (report) {
          // if (selectedReport.status.key === 5) {
          goFirstStep({
            sections:
              selectedReport.report.sections ||
              REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id]],
            reportType: REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id],
            report_id: selectedReport.report.id,
            history: history,
          });
          // } else {
          // await API.getReportById(selectedReport.report.id, true).then(reportInfo => {
          //   API.updateReportQA(reportInfo).then(async () => {
          //     await createMedicare(selectedReport.report.id);
          //     await updateMedicare(selectedReport.report.id);
          //     goFirstStep({
          //       sections:
          //         selectedReport.report.sections ||
          //         REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id]],
          //       reportType: REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id],
          //       report_id: selectedReport.report.id,
          //       history: history,
          //     });
          //   });
          // });
          // }
        } else {
          await API.getReportById(selectedReport.report.id, true).then(reportInfo => {
            API.createViewReport(reportInfo).then(async () => {
              await createMedicare(selectedReport.report.id);
              goFirstStep({
                sections:
                  selectedReport.report.sections ||
                  REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id]],
                reportType: REPORT_TYPES_BY_ID[selectedReport.report.report_type?.id],
                report_id: selectedReport.report.id,
                history: history,
              });
            });
          });
        }
      });
    }
  };

  const createMedicare = async id => {
    const medicareDataNurse = await API.getMedicareDataOri(id);
    const medicareDataQA = await API.getMedicareChangesData(id);
    const arraysNurse = medicareDataNurse.medicare_cost_projections;
    const arrayQA = medicareDataQA.medicare_cost_projections;
    if (arrayQA.length === 0) {
      arraysNurse.map(async element => {
        await API.createMedicareChangesItem(element);
      });
    } else {
      let array = arraysNurse.filter(friend => {
        let res = arrayQA.find(following => {
          return following.id == friend.id;
        });
        return res == undefined;
      });
      array.map(async ele => {
        await API.createMedicareChangesItem(ele);
      });
    }
  };

  // const updateMedicare = async id => {
  //   const medicareDataNurse = await API.getMedicareDataOri(id);
  //   const arraysNurse = medicareDataNurse.medicare_cost_projections;
  //   arraysNurse.map(async element => {
  //     await API.updateMedicareItemQA(element);
  //   });
  // };

  const onChange = e => setSearchInput(e.target.value);

  const onSearch = value => {
    delete query.page;
    value = value.trim();
    history.push(`${url}${qs({ ...query, qaSearch: encodeURIComponent(value) })}`);
  };

  const showAction = isAllowed(roles, [
    ...ROLES_DIVISIONS.INTERNAL,
    ...ROLES_DIVISIONS.QA_ONLY,
    ...ROLES_DIVISIONS.ATTORNEY_ONLY,
  ]);

  const menu = item => (
    <Menu>
      <Menu.Item>
        <a className="item-menu" onClick={handleGotoReport(item)}>
          <DetailsIcon className="icon" />
          View Report
        </a>
      </Menu.Item>
      <Menu.Item>
        <a className="item-menu" onClick={() => handlePreviewReport(item)}>
          <DetailsIcon className="icon" />
          Preview Report
        </a>
      </Menu.Item>
    </Menu>
  );

  const handlePreviewReport = async report => {
    try {
      setLoadingInternal(true);
      const getViewReport = await API.getViewReportById(report.report_id);

      if (getViewReport === false) {
        const reportInfo = await API.getReportById(report.report_id, true);
        await API.createViewReport(reportInfo);
      }

      const res = await API.getReviewReportCompare({ idReport: report.report_id });

      if (res?.report_url) setSelectPdf(res.report_url);
    } catch (err) {
      notifyError(err);
    } finally {
      setLoadingInternal(false);
    }
  };

  return (
    <div className="review-container">
      <Row type="flex" align="middle" justify="space-between" className="header">
        <Col className="search">
          <Search
            placeholder="Search..."
            className="search-box"
            onChange={onChange}
            value={searchInput}
            onSearch={onSearch}
          />
        </Col>
        <Col className="text-center">
          <Title level={4} className="review-title">
            In Review
          </Title>
        </Col>
        <Col className="pagination">
          <PaginationHOC count={count} />
        </Col>
      </Row>

      <Row type="flex" className="table-header">
        <Col md={3}>Report ID</Col>
        <Col md={!isReviewUser ? 4 : 3}>Claimant</Col>
        <Col md={3}>Claimant ID</Col>
        <Col md={!isReviewUser ? 4 : 3}>Client</Col>
        {isReviewUser && <Col md={4}>Nurse Assigned</Col>}
        <Col md={3}>{isQaAttorney ? 'Date Received' : 'Return Date'}</Col>
        <Col md={3}>Status</Col>
        {!isReviewUser && <Col md={2}>Assignee</Col>}
        {showAction && <Col md={!isReviewUser ? 1 : 2} />}
      </Row>

      <Row className="table-content">
        {isLoading ? (
          <Skeleton loading={isLoading} active title={false} paragraph={{ rows: 10, width: '100%' }} />
        ) : count > 0 ? (
          assignments.map(item => (
            <Row key={item.id} className="record">
              {/* report id */}
              <Col xs={3} className="user-column">
                <Text className="user-name" type={!item.report?.report_uuid && !item.report_uuid && 'secondary'}>
                  {item.report?.report_uuid || item.report_uuid || 'N/A'}
                </Text>
              </Col>

              {/* claimant */}
              <Col xs={!isReviewUser ? 4 : 3}>
                <Text>
                  {item.claimant?.claimant_name && item.claimant?.claimant_last_name
                    ? `
                    ${item.claimant?.claimant_name} 
                    ${item.claimant?.claimant_last_name}
                  `
                    : item.claimant_full_name}
                </Text>
              </Col>

              {/* claimant id */}
              <Col xs={3}>
                <Text>{item.claimant_id}</Text>
              </Col>

              {/* client */}
              <Col xs={!isReviewUser ? 4 : 3}>
                <Text>{item.claimant?.client?.client_name || item.client_name}</Text>
              </Col>

              {/* nurse assigned */}
              {isReviewUser && (
                <Col xs={4}>
                  <Text>{item?.report?.nurse?.given_name || ''}</Text>
                </Col>
              )}

              {/* date */}
              <Col xs={3}>
                <Text className="wrapped-text">
                  {isQaAttorney
                    ? item?.assignment_date
                      ? dayjs(item.assignment_date).format('MMM DD, YYYY')
                      : '-'
                    : dayjs(item.end_date).format('MMM DD, YYYY')}
                </Text>
              </Col>

              {/* status */}
              <Col xs={3}>
                <Text className="wrapped-text">{item.status?.name || 'N/A'}</Text>
              </Col>

              {/* Assignee */}
              {!isReviewUser && (
                <Col xs={2}>
                  <Text className="wrapped-text">{`${item.user?.given_name || ''} ${
                    item.user?.family_name || ''
                  }`}</Text>
                </Col>
              )}

              {/* actions */}
              {showAction && (
                <Col xs={2} className="actions text-right">
                  <>
                    {item.report ? (
                      <div>
                        {allowedPreviewPdf ? (
                          <Dropdown overlay={menu(item)} placement="bottomRight" trigger={['click']}>
                            <Icon type="ellipsis" className="dots-icon" />
                          </Dropdown>
                        ) : (
                          <Button type="link" shape="circle" ghost onClick={handleGotoReport(item)}>
                            <DetailsIcon className="icon" />
                          </Button>
                        )}
                      </div>
                    ) : (
                      <Text type="secondary"> N/A </Text>
                    )}
                  </>
                </Col>
              )}
            </Row>
          ))
        ) : (
          <Empty
            description={isLoading ? false : 'No Data'}
            image={!isLoading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
            className="empty-icon"
          />
        )}
      </Row>

      {loadingInternal && (
        <Modal visible={true} footer={null} className="modal-loader">
          <Spin />
        </Modal>
      )}
      {selectPdf && <ModalPdf path={selectPdf} close={() => setSelectPdf('')} />}

      {openModalUpgradedPlan?.open && (
        <ModalUpgratePackageOpenReport data={openModalUpgradedPlan} onClose={() => setOpenModalUpgradedPlan({})} />
      )}
    </div>
  );
};

export default Reviews;
