import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer } from "antd";
import { closeChatDrawer } from "redux/Drawer/DrawerActions";
import ChatComponent from "../Chat";
import "./chatbar.scss";

export default function ChatBar() {
  const dispatch = useDispatch();
  const { showChat } = useSelector((state) => state.Drawer);

  const onClose = () => {
    dispatch(closeChatDrawer());
  };

  return (
    <Drawer
      className="chat-drawer"
      key="chat-drawer"
      // title="Chat"
      placement="right"
      closable={false}
      visible={showChat}
      width="80vw"
    >
      <ChatComponent />
      <Button
        onClick={onClose}
        shape="circle"
        icon="close"
        style={{
          position: "absolute",
          top: 5,
          left: 5,
          border: 0,
          background: "rgba(50, 50, 50, 0.05)",
        }}
      />
    </Drawer>
  );
}
