import React from 'react';
import { Row, Col, Avatar, Button, Modal, Typography } from 'antd';
import { TrashIcon } from 'components/Icons';

const { Text } = Typography;

const DeleteUserModal = ({ open, data, onClose, onDelete }) => (
  <Modal
    visible={open}
    onCancel={onClose}
    footer={null}
    centered={true}
    closable={false}
    width={400}
    className="delete-modal"
    title={null}>
    <Row type="flex" align="middle" className="delete-body">
      <Row className="trash-icon">
        <TrashIcon />
      </Row>
      <Row>
        <Text className="label">Delete this User?</Text>
      </Row>
      <Row type="flex" align="middle" className="user-info">
        <Avatar src={data.client_logo} size={48} />
        <Col className="user-name">
          <Row>
            <Text ellipsis className="text-row name">
              {data.given_name + ' ' + data.family_name}
            </Text>
          </Row>
          <Row>
            <Text ellipsis className="text-row">
              {data.email}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row>
        <Button type="primary" className="pink-btn button" onClick={() => onDelete(data)}>
          Delete
        </Button>
      </Row>
      <Row className="cancel-button">
        <Text onClick={onClose}>Cancel</Text>
      </Row>
    </Row>
  </Modal>
);

export default DeleteUserModal;
