export const REPORT_IGNOREDKEYS = [
    "createdBy",
    "createdAt",
    "report_uuid",
    "claimant",
    "juridiction_state",
    "report_status",
    "report_type",
    "deletedBy",
    "updatedBy",
    "updatedAt",
    "report_claim_id",
    "tenant_id",
    "claimant_id",
    "report_type_id",
    "report_status_id",
    "juridiction_state_id",
    "pricing_group_id",
    "report_uuid",
    "is_template",
    "report_template_id",
    "deletedAt",
    "id",
    "required_qa",
    "sections",
    "length_of_annuity",
    "life_expectancy_source",
    "damage_photo",
    "future_medical_expenses",
    "loss_of_enjoyment",
    "pricing_group",
    "is_owcp",
    "life_expectancy",
    "age_at_proposed_settlement_date",
    "editable_field_names",
    "pre_settlement",
    "post_settlement",
    "signature"
];
    