import React, { useState } from 'react';
import { Col, Input, Modal, Row } from 'antd';

import ReportCabinet from 'containers/ReportCabinet';
import useDebounce from '../../hooks/useDebounce';

import './ReportCabinet.scss';

export default function ReportCabinetModal({ onCancel, visible }) {
  const { Search } = Input;

  const [search, setSearch] = useState('');

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const debouncedSearch = useDebounce(search, 200);

  return (
    <Modal
      title={
        <Row type="flex">
          <Col xs={6}>
            <Search placeholder="Search..." className="search-box" onChange={onSearch} value={search} />
          </Col>
          <Col xs={12}>Report Cabinet</Col>
          <Col xs={6}></Col>
        </Row>
      }
      centered
      visible={visible}
      onCancel={onCancel}
      width={1000}
      footer={null}
      className="report-cabinet-modal"
      destroyOnClose>
      <ReportCabinet search={debouncedSearch} visible={visible} onCancel={onCancel} />
    </Modal>
  );
}
