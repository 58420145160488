import { getPresignedUrl } from './api';
import axios from 'axios';
import * as API from 'utils/api';

export const UploadFile = async (fileBase64, name) => {
  let presignedUrl = await getPresignedUrl(name);
  let res = await fetch(fileBase64);
  let blob = await res.blob();
  const fd = new FormData();
  const file = new File([blob], '234f.pdf');
  fd.append('file', file);

  try {
    await axios({
      url: presignedUrl.url,
      method: 'put',
      // headers: { "Content-Type": "multipart/form-data" },
      data: fd,
    });
  } catch (error) {
    return { error: 'Cannot upload' };
  }
  return presignedUrl.url.split('?')[0];
};

export const UploadPresigned = async file => {
  const data = await API.getPresignUrlFile({
    name: file.name,
    type: file.type,
  });

  const formData = new FormData();
  // formData.append("Content-Type", file.type);

  Object.entries(data.url.data.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });

  formData.append('file', file.originFileObj);
  await fetch(data.url.data.url, {
    method: 'POST',
    body: formData,
  });

  return {
    name: file.name,
    type: file.type,
    // url: url,
    url: data.url.data.fields['key'],
  };
};

export const BulkAddFilesToReport = async (files, type, claimant_id = null, report_id) => {
  let Files = await Promise.all(files.map(file => API.ReportFileUpload(claimant_id, file)));
  await Promise.all(
    Files.map(file => {
      return API.CreateReportDocuments({
        report_id,
        file_id: file.file.id,
        type,
      });
    })
  );
  return Files;
};

export const BulkAddExistingFilesToReport = async (files = [], type, report_id) => {
  await Promise.all(
    files.map(file => {
      return API.CreateReportDocuments({
        report_id,
        file_id: file.id,
        type,
      });
    })
  );
};

export const UploadFileCVUser = async ({ file, isEdit, user_id, idFileEdit }) => {
  const urlUpload = await API.getPresignUrlFile({
    name: file.name,
    path: 'CV',
    user_id,
    type: file.type,
  });

  const formData = new FormData();

  Object.entries(urlUpload.url.data.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });

  formData.append('file', file.originFileObj);
  await fetch(urlUpload.url.data.url, {
    method: 'POST',
    body: formData,
  });

  const body = {
    name: file.name,
    type: file.type,
    url: urlUpload.url.fileUrl,
  };

  if (!isEdit) {
    body.idUser = user_id;
    await API.uploadFileToUser(body);
  } else {
    await API.updateUploadedFile(idFileEdit, body);
  }
};
