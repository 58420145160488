import { Button, Col, Modal, Row, Alert, Mentions, Typography } from 'antd';
import InputBox from 'components/InputBox';
import SpinBox from 'components/SpinBox';
import React, { useState, useEffect } from 'react';
import '../ReportModal/ReportModal.scss';
import { Auth } from 'aws-amplify';
import * as API from 'utils/api';
const { Option } = Mentions;
export default function UpdateInternalComment({
  isOpen,
  onCancel,
  loading,
  UpdateComment,
  onChangeField,
  updateCommentDatas,
  commentErrorr,
}) {
  const [writeComment, setWriteComment] = useState('');
  const [userId, setUserId] = useState('');
  const [comments, setComments] = useState([]);
  const { Text } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateCommentModal, setUpdateCommentModal] = useState(false);

  const [users, setGetUsers] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(res => {
      setUserId(res.attributes.sub);
    });

    // API.loadMoreComments().then((res) => {
    //   setGetAllComments(res.claimant_comments);
    // });
    API.getUsersDataSynchronised().then(res => {
      setGetUsers(res.users);
    });
  }, []);
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={'Update Comment'}>
        <Row type="flex" align="middle" className="report-body">
          <Row type="flex" justify="space-between" align="middle" className="main-info">
            <Col md={24}>
              <Row style={{ paddingBottom: 10 }}>
                <Text>Comment</Text>
              </Row>
              <Mentions
                rows={8}
                style={{ width: '100%' }}
                placeholder="input @ to mention users."
                onChange={onChangeField}
                // onSelect={handleMentionSelect}
                defaultValue="@afc163"
                value={UpdateComment.text}>
                {users &&
                  users.map((data, index) => (
                    <Option value={data.given_name + data.family_name} key={index}>
                      {data.given_name + ' ' + data.family_name}
                    </Option>
                  ))}
              </Mentions>
              {/* <InputBox label="Comment" name="name" value={UpdateComment.text} onChange={onChangeField} /> */}
            </Col>
            {commentErrorr && <Alert type="error" style={{ marginTop: 10 }} message={commentErrorr} banner />}{' '}
          </Row>

          <Button type="primary" size="large" className="green-btn button" onClick={updateCommentDatas}>
            Update
          </Button>
        </Row>
      </Modal>
    </div>
  );
}
