import React, { useState } from 'react';
import { Col, Row, Typography, Button, Icon } from 'antd';
import TextAreaBox from 'components/TextAreaBox';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { INPUTS_RICH, INPUTS_DATE, INPUTS_TEXT } from 'constants/reportFields';

const { Text } = Typography;

const ComparedFields = ({
  fieldTitle = 'non Title',
  field,
  originalText = 'empty original',
  revisionText = 'empty edited text',
  currentText = 'empty current text',
  handleTextChange,
  handleSelectText,
  onOpenCompare,
  reportId,
}) => {
  const [useOriginalText, setUseOriginalText] = useState(false);
  const [useEditedText, setUseEditedText] = useState(false);

  const selectOriginal = (inputName, inputValue) => {
    setUseOriginalText(true);
    setUseEditedText(false);
    handleSelectText && handleSelectText({ field: inputName, selectedText: inputValue, reportId });
  };
  const selectedited = (inputName, inputValue) => {
    setUseOriginalText(false);
    setUseEditedText(true);
    handleSelectText && handleSelectText({ field: inputName, selectedText: inputValue, reportId });
  };

  return (
    <Row gutter={50} type="flex" align="middle" className="main-info mb-6" data-test-id='itemsCompare'>
      <Col md={5} className="row">
        <Text className="compare-title">{fieldTitle}</Text>
      </Col>
      <Col md={9} className="row">
        <div className="inputs_compare disabled_rich">
          <div className={`selected-field ${currentText === originalText || useOriginalText ? 'active' : ''}`}>
            <p>Selected</p>
            <Icon type="check-square" theme="twoTone" twoToneColor="#52c41a" />
          </div>

          {INPUTS_RICH.includes(field) && (
            <RichTextEditor
              disabled
              value={originalText}
              onChange={value => {
                handleTextChange({ value, name: field });
              }}
              name={field}
            />
          )}

          {[...INPUTS_TEXT, ...INPUTS_DATE].includes(field) && (
            <TextAreaBox
              disabled
              placeholder=""
              value={originalText}
              rows={4}
              onChange={(value, name) => {
                handleTextChange({ value, name });
              }}
              className="resz-none text-compared"
              name={field}
            />
          )}
          <Button type="primary" className="purple-btn mt-3" onClick={() => selectOriginal(field, originalText)}>
            Use Original
          </Button>
        </div>
      </Col>
      <Col md={9} className="row">
        <div className="inputs_compare">
          <div className={`selected-field ${currentText === revisionText || useEditedText ? 'active' : ''}`}>
            <p>Selected</p>
            <Icon type="check-square" theme="twoTone" twoToneColor="#52c41a" />
          </div>

          {INPUTS_RICH.includes(field) && (
            <RichTextEditor
              value={revisionText}
              onChange={value => {
                handleTextChange({ value, name: field });
              }}
              name={field}
            />
          )}

          {[...INPUTS_TEXT, ...INPUTS_DATE].includes(field) && (
            <TextAreaBox
              placeholder=""
              value={revisionText}
              rows={4}
              onChange={(value, name) => {
                handleTextChange({ value, name });
              }}
              className="resz-none text-compared"
              name={field}
            />
          )}
          <div className="separed-btn-container mt-3">
            <Button type="primary" className="green-btn" onClick={() => selectedited(field, revisionText)}>
              Use Revision
            </Button>
            <Button
              type="primary"
              className="green-btn-outline"
              onClick={() =>
                onOpenCompare({
                  textOriginal: originalText,
                  textRevision: revisionText,
                  field,
                })
              }>
              Compare
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ComparedFields;
