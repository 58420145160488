import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Icon, Input, Spin, Typography } from 'antd';
import { TemplateEditIcon, MessageReportIcon } from 'components/Icons';
import './style/Sidebar.scss';
import dayjs from 'dayjs';

const updateHash = highlight => {
  document.location.hash = `highlight-${highlight.id}`;
};

const Sidebar = ({
  loading,
  highlights,
  highlightLogs,
  onResetHighlights,
  deleteHighlight,
  editHighlightComment,
  disabled = false,
  isAdmin = false,
  shrink = false,
  noEdit = false,
}) => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;

  const [editHighlightIndex, setEditHighlightIndex] = useState(null);
  const [editText, setEditText] = useState();

  const onStartEdit = index => () => {
    setEditHighlightIndex(index);
    setEditText(highlights[index].comment.text);
  };
  const onFinishEdit = () => {
    editHighlightComment(editHighlightIndex, editText);
    setEditHighlightIndex(null);
    setEditText(null);
  };

  const onDelete = index => () => {
    setEditHighlightIndex(null);
    deleteHighlight(index);
  };

  const onChange = event => {
    setEditText(event.target.value);
  };

  const handleTextFieldKeyPressed = event => {
    switch (event.key) {
      case 'Enter':
        onFinishEdit();
        break;
      case 'Escape':
        setEditHighlightIndex(null);
        setEditText(null);
        break;
      default:
        break;
    }
  };

  const isEditHighlight = editHighlightIndex !== null;
  return (
    <div className="pdf-sidebar__root">
      {loading ? (
        <div className={clsx('pdf-page__loader-wrap', shrink && 'pdf-page__loader-wrap--shrink')}>
          <Spin
            tip="processing..."
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            className="spinner"
            prefixCls="prefix-spinner"
          />
        </div>
      ) : disabled ? (
        <div className="pdf-sidebar__no-highlights">
          <TemplateEditIcon className="pdf-sidebar__big-icon" />
          <Title level={4} className="pdf-sidebar__empty-header">
            Pending Review
          </Title>
          {!isAdmin && !noEdit && (
            <Text>To start review press Accept button or press Reject button for refusing and return.</Text>
          )}
        </div>
      ) : highlights.length > 0 ? (
        <>
          <div className="pdf-sidebar__header">
            <Title level={4}>Highlights</Title>
            {!noEdit && (
              <Button variant="text" color="primary" size="small" onClick={onResetHighlights}>
                Clear All
              </Button>
            )}
          </div>
          <div>
            {highlights.map((highlight, index) => (
              <div
                key={highlight.id}
                className="pdf-sidebar__card"
                onClick={() => {
                  updateHash(highlight);
                }}>
                <div className="pdf-sidebar__card-header">
                  <Text>Page {highlight.position.pageNumber}</Text>
                  <div className="pdf-sidebar__buttons">
                    {isEditHighlight && editHighlightIndex === index && (
                      <Button
                        className="pdf-sidebar__done-button"
                        onClick={onFinishEdit}
                        disabled={!editText}
                        icon="check"
                        size="small"
                      />
                    )}
                    {!noEdit && !isEditHighlight && (
                      <Button
                        color="secondary"
                        aria-label="edit"
                        className="pdf-sidebar__edit-button"
                        onClick={onStartEdit(index)}
                        icon="edit"
                        size="small"
                      />
                    )}
                    {!noEdit && (
                      <Button
                        aria-label="delete"
                        className="pdf-sidebar__delete-button"
                        type="danger"
                        onClick={onDelete(index)}
                        icon="delete"
                        size="small"
                      />
                    )}
                  </div>
                </div>

                {highlight.content.text ? (
                  <Text strong className="pdf-sidebar__card-title">
                    {`${highlight.content.text.slice(0, 30).trim()}…`}
                  </Text>
                ) : null}
                {highlight.content.image ? (
                  <div className="pdf-sidebar__image">
                    <img src={highlight.content.image} alt={'Screenshot'} />
                  </div>
                ) : null}
                <div className="pdf-sidebar__comment">
                  <div className="pdf-sidebar__comment-icon">
                    <MessageReportIcon />
                  </div>
                  {isEditHighlight && editHighlightIndex === index ? (
                    <TextArea
                      rows={4}
                      value={editText}
                      size="small"
                      className="pdf-sidebar__edit-comment"
                      required
                      onChange={onChange}
                      onKeyDown={handleTextFieldKeyPressed}
                      autoFocus
                    />
                  ) : (
                    <Text variant="body2" align="left" color="textSecondary">
                      {highlight.comment.text}
                    </Text>
                  )}
                </div>
                <div className="highlight__location"></div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="pdf-sidebar__no-highlights">
          <TemplateEditIcon className="pdf-sidebar__big-icon" />
          <Title level={4} className="pdf-sidebar__empty-header">
            No highlights added yet
          </Title>
          {!noEdit && <Text>To create area highlight hold Option key (Alt), then click and drag</Text>}
        </div>
      )}
      {!loading && highlightLogs.length > 0 && (
        <div className="pdf-sidebar__highlight-logs">
          <Title level={4}>Edit History</Title>
          <div>
            {highlightLogs.map(highlightLog => (
              <div key={highlightLog.id} className="pdf-sidebar__card">
                <div className="pdf-sidebar__card-header">
                  <Text>
                    {dayjs(highlightLog.createdAt).format('HH:mm MM/DD')},&nbsp;
                    {`${highlightLog.user?.given_name || ''} ${highlightLog.user?.family_name || ''}`}
                  </Text>
                </div>
                <div className="pdf-sidebar__history-item">
                  {highlightLog.data &&
                    Array.isArray(highlightLog.data) &&
                    highlightLog.data.length > 0 &&
                    highlightLog.data.map((note, index) => (
                      <div>
                        {index + 1}) ...{note.content?.text?.substring(0,25) || 'IMAGE'}... on page {note.position?.pageNumber}
                        <br />
                        <div className="pdf-sidebar__history-comment">
                          <MessageReportIcon /> <span>{note.comment?.text}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
