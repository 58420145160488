import React, { useState, useEffect } from 'react';
import { Modal, Spin, Input, Table, Button } from 'antd';
import { SearchOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import * as API from 'utils/api';
import useDebouce from 'hooks/useDebounce';
import { notifyApiError } from 'utils/notification';

const columns = [
  {
    title: 'Contact Name',
    dataIndex: 'contact_name',
    width: 200,
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact_type',
    with: 150,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 350,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: 80,
    align: 'center',
  },
];

const AddContactModal = ({ close, onAdd, myContact }) => {
  const [listContact, setListContact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const setSearchDebounce = useDebouce(search, 500);

  const createBodyTable = ({ contacts }) => {
    return contacts.map(item => ({
      key: item.id,
      id: item.id,
      contact_name: item.contact_name || '',
      contact_type: item.contact_type?.name || item.contact_type || '',
      email: item.email,
      actions: myContact.includes(item.id) ? (
        <Button disabled type="primary" className="green-btn-disabled">
          <CheckOutlined style={{ fontWeight: 'bold', fontSize: '16px', color: '#1DCD67' }} />
        </Button>
      ) : (
        <Button type="primary" className="green-btn" onClick={() => onAdd({ data: item })}>
          <PlusOutlined style={{ fontWeight: 'bold', fontSize: '16px', color: 'white' }} />
        </Button>
      ),
    }));
  };
  const onContactSearch = async () => {
    try {
      setLoading(true);
      const res = await API.getContactsData({ searchQuery: setSearchDebounce, pageSize: 1000, tenantOnly: false });

      if (!res.contacts) return;

      const list = createBodyTable({ contacts: res.contacts });
      setListContact(list);
      setLoading(false);
    } catch (e) {
      notifyApiError(e);
    }
  };

  useEffect(() => {
    const list = createBodyTable({ contacts: listContact });
    setListContact(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myContact]);

  useEffect(() => {
    onContactSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchDebounce]);

  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={900}
      bodyStyle={{
        padding: '0px',
      }}
      title="Add contact">
      <div className="modalAddContact">
        {/* LOADER */}
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>
        {/* END LOADER */}

        <Input
          onChange={e => setSearch(e.target.value)}
          size="large"
          placeholder="Search..."
          prefix={<SearchOutlined style={{ fontSize: '18px' }} />}
        />
        <Table
          columns={columns}
          dataSource={listContact}
          scroll={{
            y: 300,
          }}
          pagination={false}
          size="middle"
        />
      </div>
    </Modal>
  );
};

export default AddContactModal;
