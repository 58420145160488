import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Modal, Checkbox, Spin } from 'antd';
import DropDown from 'components/Dropdown';
import { updateReportInfo, getReportFiles } from 'redux/report';
import * as API from 'utils/api';
import { UploadPresigned, BulkAddFilesToReport } from 'utils/upload';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { forbiddenRedirect } from '../../utils/verify-permissions';
import { DownloadOutlined } from '@ant-design/icons';
import './DocumentUpload.scss';
import UploadFileModal from '../../components/UploadFileModal/UploadFileModal';
import AddAssignForm from '../../../Calendar/src/src/containers/base/AddAssignForm';
import { REPORT_FIELDS_UPLOAD, REPORT_MSA_REDUCTION } from 'constants/reportfieldsUploadFile';
import { ItemsTableUploadFile } from 'containers/Reports/components/ItemsTableUploadFile/ItemsTableUploadFile';
import TrashIcon from 'components/Svg/TrashIcon';
import { REPORT_FIELD_MEDICAL_RECORDS } from 'constants/reportfieldsUploadFile';
import isAllowed, { isClient, ROLES_DIVISIONS } from 'layout/roles';
import { BtnGenerateReport } from 'containers/Reports/components/BtnGenerateReport';
import { returnToSenderReport } from 'containers/Reports/utils/returnToSender';
import { ModalReturnToSender } from 'containers/Reports/components/ModalReturnToSender';
import { CLAIMANT_DETAIL_NAV, CLAIMANT_INFO_NAV } from 'layout/navigate_const';
import { STATUS_GENERATED } from 'constants/report_status';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

const { Title, Text } = Typography;

const beforeUpload = file => {
  if (!file) return false;
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    Modal.error({
      content: 'You can only upload PDF file!',
    });
  }
  return isPdf;
};

class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }
    this.state = {
      report_id,
      loadingText: 'Loading...',
      loading: false,
      is_template: false,
      injury_description: [],
      medicals: [],
      damages: [],
      legal_docs: [],
      required_qa: true,
      modalUploadFile: isClient(props.roles) ? true : false,
      showNewAssignForm: false,
      isClient: isClient(props.roles),
      show_additional_comments: false,
      modalReturnToSender: false,
      assignmentReturn: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;
    this.props.getReportFiles(this.props.match.params.id);
    this.setState({ loading: true });
    API.getReportByIdReduction({ report_id })
      .then(reportInfo => {
        this.props.updateReportInfo(reportInfo);

        this.setState({
          loading: false,
          is_template: reportInfo.is_template,
          required_qa: reportInfo.required_qa,
          show_additional_comments: reportInfo.show_additional_comments,
          claimant: reportInfo.claimant,
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const { injury_description, medicals, damages, legal_docs } = reportInfo;

      this.setState({
        injury_description,
        medicals,
        damages,
        legal_docs,
        loading: false,
      });
    }
  }

  documentRequired = [];

  handleUpdateReport = async (isNextStep = false) => {
    const { report_id, required_qa } = this.state;
    const params = {
      id: report_id,
      required_qa,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        res = await API.updateReportClient(params);
        clearTimeout(this.timeoutId);
      } else {
        res = await API.updateReportClient(params);
      }
      if (res.status_code === 201) {
        notifyInfo('', res.message);
      }
      this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleDocUpload = async (info, type) => {
    let promiseArray = [];
    let hasUploading = false;
    info.fileList.forEach(el => {
      if (beforeUpload(el.originFileObj)) {
        if ('uploading' === el.status) {
          hasUploading = true;
        }
        promiseArray.push(el);
      }
    });

    if (hasUploading) {
      return;
    }

    const uploadArrayWithPromises = [];
    try {
      promiseArray
        .filter(
          (file, index, fileList) =>
            fileList.findIndex(f => f.name === file.name && f.type === file.type && f.size === file.size) === index
        )
        .forEach(file => {
          uploadArrayWithPromises.push(UploadPresigned(file));
        });

      this.setState(state => (state[type] = true));
      const urls = await Promise.all(uploadArrayWithPromises);
      await BulkAddFilesToReport(urls, type, this.props.reportInfo?.claimant?.id, this.state.report_id);
      await this.props.getReportFiles(this.props.match.params.id);
      info.fileList.length = 0;
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  };

  handleGenerateReport = async () => {
    const { report_id } = this.state;

    this.setState({
      loading: true,
      loadingText: 'Generating the report...',
    });
    try {
      await API.setUpReductionReport(report_id);
      this.setState({
        loading: true,
        loadingText: 'Report generated , packaging the files... ',
      });
      const reportFileObj = await API.GenerateReport(this.state.report_id);

      if (isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER)) {
        await API.updateReportNurse({ id: report_id, report_status_id: STATUS_GENERATED.id });
      }

      this.setState({ loading: false });
      Modal.success({
        content: 'Report is generated',
        onOk: () => {
          // if (required_qa) {
          //   let params = { report_status_id: 14, id: this.state.report_id };
          //   API.updateReportClient(params).then(res => {
          //     if (res.report.report_status_id === 14) {
          //       this.setState({ showNewAssignForm: true });
          //     }
          //   });
          // }
          window.open(reportFileObj.url);
        },
      });
      await this.handleUpdateReport(false);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  openModal = () => {
    let params = { report_status_id: 14, id: this.state.report_id };
    API.updateReportClient(params).then(res => {
      if (res.report.report_status_id === 14) {
        this.setState({ showNewAssignForm: true });
      }
    });
  };

  deleteFile = async el => {
    try {
      this.setState({ loading: true });
      await API.deleteReportDocument(el.id);
      await this.props.getReportFiles(this.props.match.params.id);
      this.setState({ loading: false });
    } catch (e) {
      notifyApiError(e);
    }
  };

  renderFiles = (list, type) => {
    const allFilesOfThisType = list
      .filter(item => item.type === type)
      .sort((a, b) => {
        return a.order - b.order;
      });

    return allFilesOfThisType;
  };

  handleGoToClaimantDetail = () => {
    this.props.history.push(CLAIMANT_DETAIL_NAV.path, { data: this.props.reportInfo.claimant });
  };

  handleModalUploadFile = () => {
    if (this.state.isClient) {
      this.handleGoToClaimantDetail();
    } else {
      this.setState({ modalUploadFile: !this.state.modalUploadFile });
    }
  };

  filesUploaded = () => {
    this.handleModalUploadFile();
    this.props.getReportFiles(this.props.match.params.id);
  };

  handleRemoveAll = async () => {
    this.setState({ loading: true });
    const listFile = REPORT_FIELDS_UPLOAD[REPORT_MSA_REDUCTION].map(field =>
      this.renderFiles(this.props.files, field)
    ).flat();

    if (listFile.length > 0) {
      await Promise.all(listFile.filter(file => this.deleteFile(file)));
    }
    this.setState({ loading: false });
  };

  handleDownloadAll = async () => {
    try {
      this.setState({ loading: true });
      const listFile = REPORT_FIELDS_UPLOAD[REPORT_MSA_REDUCTION].map(field =>
        this.renderFiles(this.props.files, field)
      ).flat();

      if (listFile.length > 0) {
        const res = await API.DownloadAllReportFiles({ listIdFiles: listFile.map(file => file.file.id) });

        if (res?.file?.url) {
          window.open(res?.file?.url);

          Modal.success({
            content: 'All Files have been Downloaded',
          });
        } else {
          Modal.error({ content: 'There is not files' });
        }
      }
    } catch {
      Modal.error({ content: 'An error has occurred' });
    } finally {
      this.setState({ loading: false });
    }
  };

  onSubmit = async assignment => {
    this.setState({
      loading: true,
      loadingText: 'pending...',
    });
    try {
      await API.createAssignementUser(assignment);
      this.setState({ showNewAssignForm: false });
      notifyInfo('Assignment created');
      this.setState({ loading: false });

      if (isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_ONLY)) {
        this.props.history.push(`${CLAIMANT_INFO_NAV.path}/${this.state.claimant.id}`);
      }
      return true;
    } catch (e) {
      notifyApiError(e);
      this.setState({ loading: false });
      return false;
    }
  };

  handleReturnToSender = async () => {
    try {
      this.setState({ loading: true });
      const { report_id, claimant } = this.state;
      const claimant_id = claimant.id;
      const res = await returnToSenderReport({ report_id, claimant_id });

      if (res?.assignment?.id) {
        this.setState({
          modalReturnToSender: true,
          assignmentReturn: `${res.assignment.user.given_name} ${res.assignment?.user?.family_name || ''}`,
        });
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      loading,
      isClient,
      modalUploadFile,
      showNewAssignForm,
      report_id,
      show_additional_comments,
      modalReturnToSender,
      assignmentReturn,
    } = this.state;

    const { files } = this.props;

    return (
      <>
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>

        {!isClient && (
          <>
            <Row className="msa-document-upload-container newUploadFile">
              <Row type="flex" align="middle" className="header">
                <Col className="title">
                  <Title level={4} className="title-text">
                    Medicare Set-Aside Reduction Worksheet
                  </Title>
                </Col>
                <Col className="step">
                  <DropDown loading={loading} beforeNext={async () => await this.handleUpdateReport(false)} />
                </Col>
              </Row>

              <Row className="main">
                <div className="content newUploadFile_content">
                  <div className="newUploadFile_titleContent">
                    <Text className="title">Document Upload</Text>
                    <Text>Documents to upload: Injury Description, Medicals, Damages, Legal docs.</Text>
                  </div>

                  <Row className="newUploadFile_openModal" onClick={this.handleModalUploadFile}>
                    Upload Files
                  </Row>

                  {files.length > 0 && (
                    <>
                      <div className="newUploadFile_content">
                        <div className="newUploadFile_tableFiles">
                          <div className="newUploadFile_tableFiles_header newUploadFile_tableFiles_item newUploadFile_grid3">
                            <Text>Type</Text>
                            <Text>Name</Text>
                            <Text className="newUploadFile_tableFiles_btnEnd">Actions</Text>
                          </div>

                          <div className="newUploadFile_tableFiles_body">
                            {REPORT_FIELDS_UPLOAD[REPORT_MSA_REDUCTION].map(field => (
                              <>
                                {this.renderFiles(files, field).map(item => (
                                  <ItemsTableUploadFile
                                    item={item}
                                    type={field}
                                    onDownload={this.generateAllReports}
                                    onDelete={this.deleteFile}
                                  />
                                ))}
                              </>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="newUploadFile_btns">
                        <div
                          className="newUploadFiles_downloadAll newUploadFile_iconDownload"
                          onClick={this.handleRemoveAll}>
                          Remove All
                          <TrashIcon color={PRIMARY_LIGHT_BLUE} />
                        </div>
                        <div className="newUploadFiles_downloadAll" onClick={this.handleDownloadAll}>
                          Download All
                          <DownloadOutlined className="icon newUploadFile_iconDownload" />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="newUploadFile_divider" />

                  {!isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT) && (
                    <Row type="flex" justify="space-between" align="top" className="row">
                      <Col md={11} className="row"></Col>
                      <Col md={11} className="row">
                        <Checkbox
                          checked={show_additional_comments}
                          onChange={value => {
                            this.setState({
                              show_additional_comments: !show_additional_comments,
                            });
                            API.updateReport({ id: report_id, show_additional_comments: !show_additional_comments });
                          }}>
                          Show comment sections
                          <br />
                          on the PDF
                        </Checkbox>
                      </Col>
                    </Row>
                  )}

                  <BtnGenerateReport
                    handleUpdateReport={async () => await this.handleUpdateReport(false)}
                    handleGenerateReport={this.handleGenerateReport}
                    openModal={!isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER) ? this.openModal : null}
                    roles={this.props.roles}
                    oneBtn={isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY)}
                    returnToSender={this.handleReturnToSender}
                  />
                </div>
              </Row>
            </Row>
          </>
        )}

        {modalReturnToSender && (
          <ModalReturnToSender
            onClose={() => this.props.history.push('/app/Reviews')}
            returnName={assignmentReturn || ''}
          />
        )}

        {modalUploadFile && (
          <>
            {isClient ? (
              <UploadFileModal
                close={this.handleModalUploadFile}
                returnFiles={this.filesUploaded}
                reportName={REPORT_MSA_REDUCTION}
                reportId={this.props.match.params.id}
                claimId={this.props.reportInfo?.claimant?.id}
                only={[REPORT_FIELD_MEDICAL_RECORDS]}
                title="Add Records"
              />
            ) : (
              <UploadFileModal
                close={this.handleModalUploadFile}
                returnFiles={this.filesUploaded}
                reportName={REPORT_MSA_REDUCTION}
                reportId={this.props.match.params.id}
                claimId={this.props.reportInfo?.claimant?.id}
                title="Upload Files"
              />
            )}
          </>
        )}

        {showNewAssignForm && (
          <Modal
            width={700}
            maskClosable={false}
            bodyStyle={{ padding: 0, minWidth: 450 }}
            visible={showNewAssignForm}
            onCancel={() => this.setState({ showNewAssignForm: false })}
            keyboard={false}
            footer={null}>
            <AddAssignForm onSubmit={this.onSubmit} reportId={report_id} />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    reportInfo: state.report.reportInfo,
    files: state.report.files,
    resourceLinksFiles: state.report.resourceLinksFiles,
    allFiles: state.report.allFiles,
    isLoading: state.report.isLoading,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };

  return props;
};

export default connect(mapStateToProps, { updateReportInfo, getReportFiles })(DocumentUpload);
