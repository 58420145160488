import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Typography } from 'antd';
import { ElipsisIcon, PencilIcon, SaveIcon, TrashIcon } from 'components/Svg';
import { PRIMARY_BORDER_GREY, PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { TemplateLifeCarePlanFields } from '../TemplateLifeCarePlanFields';

const { Title } = Typography;

const TemplateLifeCarePlanFieldGroup = ({
  dataStructure,
  index,
  hanldeChangeAlias,
  alias,
  editName,
  toggleEdit,
  handleSaveAlias,
  onDelete,
  onDragEnd,
  handleSaveFieldSection,
  listReportType,
  showSaveOrder,
  handleSaveOrder,
}) => {
  return (
    <Draggable draggableId={`${dataStructure.id}s`} index={index}>
      {provided => (
        <div
          className="lifeCarePlanTemplate_structure_box_content"
          ref={provided.innerRef}
          {...provided.draggableProps}>
          <div className="lifeCarePlanTemplate_structure_box_content_title">
            <div {...provided.dragHandleProps} className="lifeCarePlanTemplate_structure_box_content_title_drag">
              <Title level={4} className="title-text">
                <ElipsisIcon color={PRIMARY_BORDER_GREY} />
                {editName ? (
                  <input
                    className="lifeCarePlanTemplate_structure_box_content_title_input"
                    type="text"
                    value={alias}
                    placeholder="Alias"
                    name="alias"
                    onChange={hanldeChangeAlias}
                  />
                ) : (
                  <>{dataStructure.alias}</>
                )}
              </Title>
            </div>

            <div className="lifeCarePlanTemplate_structure_box_content_title_btns">
              {!editName ? (
                <PencilIcon color={PRIMARY_LIGHT_BLUE} onClick={toggleEdit} />
              ) : (
                <SaveIcon color={PRIMARY_LIGHT_BLUE} onClick={handleSaveAlias} />
              )}

              <TrashIcon color={PRIMARY_LIGHT_BLUE} onClick={() => onDelete({ idStructure: dataStructure.id })} />
            </div>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="structureField" type="FIELD" direction="vertical">
              {(provided, snapshot) => (
                <div
                  className={`lifeCarePlanTemplate_structure_box_content_droppable ${
                    snapshot.isDraggingOver ? 'active' : ''
                  }`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {dataStructure.template_fields.length > 0 && (
                    <>
                      {dataStructure.template_fields.map((field, index) => (
                        <Draggable key={field.id} draggableId={`${field.id}s`} index={index}>
                          {provided => (
                            <div
                              key={field.id}
                              className="lifeCarePlanTemplate_structure_box_items_item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              <TemplateLifeCarePlanFields
                                field={field}
                                onSave={({ fieldEdit }) => handleSaveFieldSection({ fieldEdit, index })}
                                index={index}
                                listReportType={listReportType}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </>
                  )}
                  {provided.placeholder}

                  {showSaveOrder && (
                    <div className="lifeCarePlanTemplate_structure_box_content_droppable_btn">
                      <Button type="primary" size="default" className="green-btn button" onClick={handleSaveOrder}>
                        Save Order
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </Draggable>
  );
};

export default TemplateLifeCarePlanFieldGroup;
