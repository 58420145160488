import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as API from 'utils/api';
import { useSelector } from 'react-redux';

import { Row, Col, Typography, Divider } from 'antd';
import DropDown from 'components/Dropdown';
import SpinBox from 'components/SpinBox';
import { notifyApiError } from 'utils/notification';

// const
import { REPORT_LIFE_CARE_PLAN_NAME } from 'constants/reports';
import { MAIN_NAV, CLAIMANT_DETAIL_NAV } from 'layout/navigate_const';
import 'constants/assignment-types';

// utils
import { formatCurrency } from 'utils/utils';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { qaHaveAssignedReport } from 'containers/Reports/utils/verify-permissions';
import PrevNextStep from 'components/PrevNextStep';

const { Title, Text } = Typography;

const MedicareTotalsLCP = () => {
  const { id } = useParams();
  const { roles } = useSelector(state => state.auth);
  const history = useHistory();

  const [medicareInfo, setMedicareInfo] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  // Permissions
  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    getMedicareInfo();
  }, []);

  const getMedicareInfo = async () => {
    try {
      const res = await API.getMedicareInfoData(id);

      let report = {};

      if (REPORT_PERMISSIONS.cloneReport) {
        report = await API.getReportById(id);

        if (report === false) {
          report = await API.getReportById(id, true);
          await API.createViewReport(report);
        }
      } else {
        report = await API.getReportById(id, true);
      }

      const redirect = await qaHaveAssignedReport({
        roles: roles,
        reportId: id,
        claimantId: report.claimant_id,
      });

      if (redirect) return history.push(MAIN_NAV.path);

      const anualCost = res.totals.annual_cost_total;
      const totalCost = res.totals.total_cost_total;

      delete res.totals.annual_cost_total;
      delete res.totals.total_cost_total;

      const update = {
        'Annual Cost Total': anualCost,
        ...res.totals,
        'Total Cost': totalCost,
      };

      setMedicareInfo(update);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <Row className="msa-medical-totals-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            {REPORT_LIFE_CARE_PLAN_NAME} Report
          </Title>
        </Col>
        <Col className="step">
          <DropDown
            loading={loadingPage}
            goBack={() => history.push(REPORT_PERMISSIONS.goBackClaimant ? CLAIMANT_DETAIL_NAV.path : MAIN_NAV.path)}
          />
        </Col>
      </Row>
      <Row className="main">
        <SpinBox loading={loadingPage}>
          <Row className="content">
            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={24}>
                <Text className="title">Totals and Allocations</Text>
              </Col>
            </Row>
            {Object.keys(medicareInfo).map(item => (
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>{item}</Text>
                </Col>
                <Text className="text">{formatCurrency(medicareInfo[item] || 0)}</Text>
              </Row>
            ))}

            <Divider className="divider" />
            <PrevNextStep />
          </Row>
        </SpinBox>
      </Row>
    </Row>
  );
};

export default MedicareTotalsLCP;
