import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Typography, Modal, Checkbox, Spin } from 'antd';
import DropDown from 'components/Dropdown';
import { updateReportInfo, getReportFiles } from 'redux/report';
import * as API from 'utils/api';
import { UploadPresigned, BulkAddFilesToReport } from 'utils/upload';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import { DownloadOutlined } from '@ant-design/icons';
import './DocumentUpload.scss';
import UploadFileModal from '../../components/UploadFileModal/UploadFileModal';
// import AddAssignForm from '../../../Calendar/src/src/containers/base/AddAssignForm';
import { REPORT_FIELDS_UPLOAD, REPORT_SURGICAL } from 'constants/reportfieldsUploadFile';
import { ItemsTableUploadFile } from 'containers/Reports/components/ItemsTableUploadFile/ItemsTableUploadFile';
import TrashIcon from 'components/Svg/TrashIcon';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
// import { BtnGenerateReport } from 'containers/Reports/components/BtnGenerateReport';
// import { returnToSenderReport } from 'containers/Reports/utils/returnToSender';
// import { ModalReturnToSender } from 'containers/Reports/components/ModalReturnToSender';
import // ASSIGNMENT_TYPE_QA_INTERNAL,
// ASSIGNMENT_TYPE_LEGAL_NURSE,
// ASSIGNMENT_TYPE_MEDICAL_REVIEWER,
'constants/assignment-types';
import { WaterMarkCheck } from 'components/WaterMarkCheck';
import // STATUS_IN_SUPERVISOR_REVIEW,
// STATUS_GENERATED
'constants/report_status';
import {
  // CLAIMANT_INFO_NAV,
  MAIN_NAV,
} from 'layout/navigate_const';
// import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { REPORT_SURGICAL_NAME } from 'constants/reports';
// import { existsAssigment } from 'utils/assigment';

// Components
import { SignatureReport } from 'containers/Reports/components/SignatureReport';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';

// Utils
import { getListSignature } from 'containers/Reports/utils/helpers';
import PrevNextStep from 'components/PrevNextStep';

const { Title, Text } = Typography;

const beforeUpload = file => {
  if (!file) return false;
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    Modal.error({
      content: 'You can only upload PDF file!',
    });
  }
  return isPdf;
};

class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      loadingText: 'Loading...',
      loading: false,
      is_template: false,
      injury_description: [],
      medicals: [],
      damages: [],
      legal_docs: [],
      required_qa: true,
      modalUploadFile: false,
      // showNewAssignForm: false,
      show_additional_comments: false,
      // modalReturnToSender: false,
      // assignmentReturn: '',
      // assignWriter: false,
      // editedparams: {},
      is_watermark: false,
      // selectPdf: '',
      REPORT_PERMISSIONS,
      signature: [],
    };

    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleUpdateWaterMark = this.handleUpdateWaterMark.bind(this);
    this.handleDocUpload = this.handleDocUpload.bind(this);
    // this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.renderFiles = this.renderFiles.bind(this);
    this.handleModalUploadFile = this.handleModalUploadFile.bind(this);
    this.filesUploaded = this.filesUploaded.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
    this.handleDownloadAll = this.handleDownloadAll.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.handleReturnToSender = this.handleReturnToSender.bind(this);
    // this.handlePreviewReport = this.handlePreviewReport.bind(this);
    // this.openModal = this.openModal.bind(this);
    this.handleChangeSignature = this.handleChangeSignature.bind(this);
    this.handleDeleteSignature = this.handleDeleteSignature.bind(this);
    this.handleAddSignature = this.handleAddSignature.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;
    this.props.getReportFiles(this.props.match.params.id);
    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        this.props.updateReportInfo(reportInfo);

        this.setState({
          loading: false,
          is_template: reportInfo.is_template,
          required_qa: reportInfo.required_qa,
          show_additional_comments: reportInfo.show_additional_comments,
          claimant: reportInfo.claimant,
          is_watermark: reportInfo.is_watermark,
          signature: getListSignature({ signature: reportInfo.signature }),
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const { injury_description, medicals, damages, legal_docs } = reportInfo;

      this.setState({
        injury_description,
        medicals,
        damages,
        legal_docs,
        loading: false,
      });
    }
  }

  async handleUpdateReport(isNextStep = false) {
    const { report_id, required_qa, signature } = this.state;
    const params = {
      id: report_id,
      required_qa,
      signature: signature.map(item => {
        delete item.imageUrl;
        return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
      }),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status_code === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleUpdateWaterMark(value) {
    try {
      const checked = value.target.checked;

      this.setState({
        loading: true,
        loadingTitle: 'Saving...  ',
        is_watermark: checked,
      });

      const { report_id } = this.state;
      const params = {
        id: report_id,
        is_watermark: checked,
      };

      await API.updateReport(params);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleDocUpload(info, type) {
    let promiseArray = [];
    let hasUploading = false;
    info.fileList.forEach(el => {
      if (beforeUpload(el.originFileObj)) {
        if ('uploading' === el.status) {
          hasUploading = true;
        }
        promiseArray.push(el);
      }
    });

    if (hasUploading) {
      return;
    }

    const uploadArrayWithPromises = [];
    try {
      promiseArray
        .filter(
          (file, index, fileList) =>
            fileList.findIndex(f => f.name === file.name && f.type === file.type && f.size === file.size) === index
        )
        .forEach(file => {
          uploadArrayWithPromises.push(UploadPresigned(file));
        });

      this.setState(state => (state[type] = true));
      const urls = await Promise.all(uploadArrayWithPromises);
      await BulkAddFilesToReport(urls, type, this.props.reportInfo?.claimant?.id, this.state.report_id);
      await this.props.getReportFiles(this.props.match.params.id);
      info.fileList.length = 0;
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  }

  // async handleGenerateReport() {
  //   const { report_id } = this.state;

  //   this.setState({
  //     loading: true,
  //     loadingText: 'Generating the report...',
  //   });
  //   try {
  //     await API.setUpSurgicalReport(report_id);
  //     this.setState({
  //       loading: true,
  //       loadingText: 'Report generated , packaging the files... ',
  //     });

  //     if (isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER)) {
  //       await API.updateReportNurse({ id: report_id, report_status_id: STATUS_GENERATED.id });
  //     }

  //     const reportFileObj = await API.GenerateReport(this.state.report_id);
  //     this.setState({ loading: false });
  //     Modal.success({
  //       content: 'Report is generated',
  //       onOk: () => {
  //         window.open(reportFileObj.url.url);
  //       },
  //     });
  //     await this.handleUpdateReport(false);
  //   } catch (e) {
  //     notifyApiError(e);
  //   } finally {
  //     this.setState({ loading: false });
  //   }
  // }

  // async openModal() {
  //   try {
  //     this.setState({ loading: true });
  //     const { finalAssigned, assignWriter } = await existsAssigment({
  //       reportInfo: this.props.reportInfo,
  //       roles: this.props.roles,
  //     });
  //     this.setState({ showNewAssignForm: true, editedparams: finalAssigned, assignWriter });
  //   } catch (e) {
  //     notifyApiError(e);
  //   } finally {
  //     this.setState({ loading: false });
  //   }
  // }

  async deleteFile(el) {
    try {
      this.setState({ loading: true });
      await API.deleteReportDocument(el.id);
      await this.props.getReportFiles(this.props.match.params.id);
      this.setState({ loading: false });
    } catch (e) {
      notifyApiError(e);
    }
  }

  renderFiles(list, type) {
    const allFilesOfThisType = list
      .filter(item => item.type === type)
      .sort((a, b) => {
        return a.order - b.order;
      });

    return allFilesOfThisType;
  }

  handleModalUploadFile() {
    this.setState({ modalUploadFile: !this.state.modalUploadFile });
  }

  filesUploaded() {
    this.handleModalUploadFile();
    this.props.getReportFiles(this.props.match.params.id);
  }

  async handleRemoveAll() {
    this.setState({ loading: true });
    const listFile = REPORT_FIELDS_UPLOAD[REPORT_SURGICAL].map(field =>
      this.renderFiles(this.props.files, field)
    ).flat();

    if (listFile.length > 0) {
      await Promise.all(listFile.filter(file => this.deleteFile(file)));
    }
    this.setState({ loading: false });
  }

  async handleDownloadAll() {
    try {
      this.setState({ loading: true });
      const listFile = REPORT_FIELDS_UPLOAD[REPORT_SURGICAL].map(field =>
        this.renderFiles(this.props.files, field)
      ).flat();

      if (listFile.length > 0) {
        const res = await API.DownloadAllReportFiles({ listIdFiles: listFile.map(file => file.file.id) });

        if (res?.file?.url) {
          window.open(res?.file?.url);

          Modal.success({
            content: 'All Files have been Downloaded',
          });
        } else {
          Modal.error({ content: 'There is not files' });
        }
      }
    } catch {
      Modal.error({ content: 'An error has occurred' });
    } finally {
      this.setState({ loading: false });
    }
  }

  // async onSubmit(assignment) {
  //   const { report_id, signature } = this.state;
  //   this.setState({
  //     loading: true,
  //     loadingText: 'pending...',
  //   });
  //   try {
  //     const hasAssigment = Boolean(assignment.id);

  //     if (!hasAssigment) {
  //       const res = await API.createAssignementUser(assignment);

  //       if (res?.assignment?.assignment_type_id === ASSIGNMENT_TYPE_LEGAL_NURSE) {
  //         await API.updateReportNurse({
  //           id: res.assignment.report_id,
  //           report_status_id: STATUS_IN_SUPERVISOR_REVIEW.id,
  //         });
  //       }

  //       notifyInfo('Assignment created');
  //     } else {
  //       let newEdit = { ...assignment };
  //       delete newEdit.id;
  //       delete newEdit.status;

  //       newEdit.start_date = moment(newEdit.start_date).format('YYYY-MM-DD');
  //       newEdit.end_date = moment(newEdit.end_date).format('YYYY-MM-DD');

  //       const isUserAdmin = isAllowed(this.props.roles, [
  //         ...ROLES_DIVISIONS.SUPERADMIN,
  //         ...ROLES_DIVISIONS.SALES_MANAGER,
  //       ]);
  //       const isUserQA = isAllowed(this.props.roles, ROLES_DIVISIONS.QA);

  //       const params = isUserQA && !isUserAdmin ? { ...newEdit, tenantOnly: true } : newEdit;

  //       await API.UpdateAssignement(assignment.id, params);
  //       notifyInfo('Assignment updated');
  //     }

  //     const params = {
  //       id: report_id,
  //       signature: signature.map(item => {
  //         delete item.imageUrl;
  //         return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
  //       }),
  //     };

  //     await API.updateReport(params);

  //     this.setState({
  //       // showNewAssignForm: false, assignWriter: false,
  //       loading: false,
  //     });

  //     if (isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_ONLY)) {
  //       this.props.history.push(`${CLAIMANT_INFO_NAV.path}/${this.state.claimant.id}`);
  //     }
  //     return true;
  //   } catch (e) {
  //     notifyApiError(e);
  //     this.setState({
  //       // showNewAssignForm: false, assignWriter: false,
  //       loading: false,
  //     });
  //     return false;
  //   }
  // }

  // async handleReturnToSender() {
  //   try {
  //     this.setState({ loading: true });
  //     const { report_id, claimant } = this.state;
  //     const claimant_id = claimant.id;

  //     const res = await returnToSenderReport({ report_id, claimant_id });

  //     if (res?.assignment?.id) {
  //       this.setState({
  //         modalReturnToSender: true,
  //         assignmentReturn: `${res.assignment.user.given_name} ${res.assignment?.user?.family_name || ''}`,
  //       });
  //     }
  //   } catch (e) {
  //     console.error(e.message);
  //   } finally {
  //     this.setState({ loading: false });
  //   }
  // }

  // async handlePreviewReport() {
  //   try {
  //     this.setState({ loading: true });
  //     const res = await API.getReviewReportCompare({ idReport: this.state.report_id });
  //     if (res?.report_url) this.setState({ selectPdf: res.report_url });
  //   } catch (err) {
  //     notifyApiError(err);
  //   } finally {
  //     this.setState({ loading: false });
  //   }
  // }

  handleChangeSignature(data) {
    this.setState(data);
  }

  handleDeleteSignature({ index }) {
    this.setState({ signature: this.state.signature.filter((item, inde) => inde !== index) });
  }

  handleAddSignature() {
    const add = [...this.state.signature, INIT_SIGNATURE];
    this.setState({ signature: add });
  }

  render() {
    const {
      loading,
      modalUploadFile,
      // showNewAssignForm,
      report_id,
      show_additional_comments,
      // modalReturnToSender,
      // assignmentReturn,
      // assignWriter,
      // editedparams,
      is_watermark,
      // selectPdf,
      REPORT_PERMISSIONS,
      signature,
    } = this.state;

    const { files } = this.props;

    return (
      <>
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>
        <Row className="msa-document-upload-container newUploadFile">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Setup {REPORT_SURGICAL_NAME} {this.state.is_template ? 'Template' : 'Report'}
              </Title>
            </Col>
            <Col className="step">
              <DropDown loading={loading} beforeNext={async () => await this.handleUpdateReport(false)} />
            </Col>
          </Row>

          <Row className="main">
            <div className="content newUploadFile_content">
              <div className="newUploadFile_titleContent">
                <Text className="title">Document Upload</Text>
                <Text>Documents to upload: Injury Description, Medicals, Damages, Legal docs.</Text>
              </div>

              {REPORT_PERMISSIONS.edit && (
                <Row className="newUploadFile_openModal" onClick={this.handleModalUploadFile}>
                  Upload Files
                </Row>
              )}

              {files.length > 0 && (
                <>
                  <div className="newUploadFile_content">
                    <div className="newUploadFile_tableFiles">
                      <div className="newUploadFile_tableFiles_header newUploadFile_tableFiles_item newUploadFile_grid3">
                        <Text>Type</Text>
                        <Text>Name</Text>
                        <Text className="newUploadFile_tableFiles_btnEnd">Actions</Text>
                      </div>

                      <div className="newUploadFile_tableFiles_body">
                        {REPORT_FIELDS_UPLOAD[REPORT_SURGICAL].map(field => (
                          <>
                            {this.renderFiles(files, field).map(item => (
                              <ItemsTableUploadFile
                                item={item}
                                type={field}
                                onDownload={this.generateAllReports}
                                onDelete={this.deleteFile}
                                onlyView={!REPORT_PERMISSIONS.edit}
                              />
                            ))}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>

                  {REPORT_PERMISSIONS.edit && (
                    <div className="newUploadFile_btns">
                      <div
                        className="newUploadFiles_downloadAll newUploadFile_iconDownload"
                        onClick={this.handleRemoveAll}>
                        Remove All
                        <TrashIcon color={PRIMARY_LIGHT_BLUE} />
                      </div>
                      <div className="newUploadFiles_downloadAll" onClick={this.handleDownloadAll}>
                        Download All
                        <DownloadOutlined className="icon newUploadFile_iconDownload" />
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="newUploadFile_divider" />

              {REPORT_PERMISSIONS.edit && (
                <>
                  {!isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT) && (
                    <Row type="flex" justify="space-between" align="top" className="row">
                      <Col md={11} className="row"></Col>
                      <Col md={11} className="row">
                        <Checkbox
                          checked={show_additional_comments}
                          onChange={() => {
                            this.setState({
                              show_additional_comments: !show_additional_comments,
                            });
                            API.updateReport({ id: report_id, show_additional_comments: !show_additional_comments });
                          }}>
                          Show comment sections
                          <br />
                          on the PDF
                        </Checkbox>
                      </Col>
                    </Row>
                  )}

                  {isAllowed(this.props.roles, [...ROLES_DIVISIONS.MANAGEMENT, ...ROLES_DIVISIONS.NURSE_ONLY]) && (
                    <WaterMarkCheck checked={is_watermark} onChange={this.handleUpdateWaterMark} />
                  )}
                </>
              )}

              {REPORT_PERMISSIONS.signature && !this.state.is_template && (
                <div>
                  <SignatureReport
                    defaultSignature={signature}
                    onChangeSignature={this.handleChangeSignature}
                    onDeleteSignature={this.handleDeleteSignature}
                    onAddSignature={this.handleAddSignature}
                    disabled={!REPORT_PERMISSIONS.edit}
                  />
                </div>
              )}

              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />

              {/* <BtnGenerateReport
                handleUpdateReport={async () => await this.handleUpdateReport(false)}
                handleGenerateReport={REPORT_PERMISSIONS.edit && !this.state.is_template && this.handleGenerateReport}
                openModal={REPORT_PERMISSIONS.assign && !this.state.is_template && this.openModal}
                roles={this.props.roles}
                oneBtn={isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY)}
                returnToSender={this.handleReturnToSender}
                previewReport={this.handlePreviewReport}
              /> */}
            </div>
          </Row>
        </Row>

        {/* {modalReturnToSender && (
          <ModalReturnToSender
            onClose={() => this.props.history.push('/app/Reviews')}
            returnName={assignmentReturn || ''}
          />
        )} */}

        {modalUploadFile && (
          <UploadFileModal
            close={this.handleModalUploadFile}
            returnFiles={this.filesUploaded}
            reportName={REPORT_SURGICAL}
            reportId={this.props.match.params.id}
            claimId={this.props.reportInfo?.claimant?.id}
            title="Upload Files"
          />
        )}

        {/* {showNewAssignForm && (
          <Modal
            width={700}
            maskClosable={false}
            bodyStyle={{ padding: 0, minWidth: 450 }}
            visible={showNewAssignForm}
            onCancel={() => this.setState({ showNewAssignForm: false })}
            keyboard={false}
            footer={null}>
            <AddAssignForm
              onSubmit={this.onSubmit}
              edit={editedparams}
              disabledField={{
                client: true,
                claimant: true,
                report: true,
              }}
              onlyAssign={
                assignWriter && [
                  ASSIGNMENT_TYPE_QA_INTERNAL,
                  ASSIGNMENT_TYPE_LEGAL_NURSE,
                  ASSIGNMENT_TYPE_MEDICAL_REVIEWER,
                ]
              }
            />
          </Modal>
        )} */}

        {/* {selectPdf && <ModalPdf path={selectPdf} close={() => this.setState({ selectPdf: '' })} />} */}
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    reportInfo: state.report.reportInfo,
    files: state.report.files,
    resourceLinksFiles: state.report.resourceLinksFiles,
    allFiles: state.report.allFiles,
    isLoading: state.report.isLoading,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };

  return props;
};

export default connect(mapStateToProps, { updateReportInfo, getReportFiles })(DocumentUpload);
