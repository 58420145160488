export const LOGIN_REQUEST = "AUTH/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "AUTH/LOGIN_FAILURE";
export const LOGOUT_REQUEST = "AUTH/LOGOUT_REQUEST";
export const SEND_EMAIL_REQUEST = "AUTH/SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "AUTH/SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "AUTH/SEND_EMAIL_FAILURE";
export const RESET_PASSWORD_REQUEST = "AUTH/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "AUTH/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "AUTH/RESET_PASSWORD_FAILURE";
export const SET_PASSWORD_REQUEST = "AUTH/SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "AUTH/SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "AUTH/SET_PASSWORD_FAILURE";
export const GET_USER_INFO_REQUEST = "AUTH/GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "AUTH/GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILURE = "AUTH/GET_USER_INFO_FAILURE";
export const FETCH_ROLES = "AUTH/FETCH_ROLES";
export const SET_LAST_ACTIVE = "AUTH/LAST_ACTIVE";
export const IS_ACTIVE_REVIEWER = "AUTH/IS_ACTIVE_REVIEWER";