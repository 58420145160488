import React from 'react';
import moment from 'moment';
import { Typography, Divider } from 'antd';

const { Title, Text } = Typography;

const Step1 = ({ claimantInfo, listState, data }) => {
  return (
    <>
      {/* aqui una imagen pegado a la derecha u de color negro */}
      <Text>{moment(data.today).format('DD/MM/YYYY')}</Text>
      <Text>{claimantInfo.client.client_name}</Text>
      <div className="contentBetween">
        <Text>{claimantInfo.address}</Text>
        <Text>{listState.find(item => item.id === data.juridiction_state_id)?.name || ''}</Text>
      </div>
      <Text>RE: MSA Reduction Letter {data.clientsAttorneyName}</Text>
      <Text>File No.: 10180 - {data.fileNo}</Text>
      <Text>Dear, {data.submitter_attorney_name}</Text>
      <Text>
        Thank you for contacting us regarding the Medicare Set-aside (MSA) reduction considerations in the
        above-entitled matter. Please let us kno if any information in this letter is not accurate as it may influence
        the analysis.
      </Text>
      <Title level={1} className="reportPreView_title">
        FACTS OF THE CASE
      </Title>
      <Text>
        Information regarding the injury related to {data.submitter_attorney_name} was outlined in the file materials
        you provided. It is my understanding she sustained the following personal injuries on{' '}
        {moment(data.date_of_injury).format('DD/MM/YYYY')} which was drafted in the MSA allocation report your firm
        prepared:
      </Text>

      {(data.related_diagnosis || []).map(item => {
        const value = JSON.parse(item.replace(/</g, ','));
        return (
          <Text key={`${value.code}-${item.value}`}>
            {value.code} {value.med_descr}
          </Text>
        );
      })}

      <Text>
        An allocation was prepared at your client's request based on the need for reasonable future medical care and
        treatment. This was based on medical records and other information related to {data.clientsAttorneyName}'s
        medical care provided to your office. I have not had a chance to review these records but agree your allocation
        looks complete based on the limited background information I received.
      </Text>

      <Text>Please let me know if any of the above information is not accurate.</Text>

      <Title level={1} className="reportPreView_title">
        ANALYSIS
      </Title>

      <Text>
        As you are aware, pursuant to 42 U.S.C. §1395y(b)(2) (the Medicare Secondary Payer Act), Medicare is the
        “secondary payer” in all workers' compensation, no-fault/automobile, and liability claims. Under the MSP Act,
        the Centers for Medicare and Medicaid Services/Medicare (CMS) position that they are the secondary payer
        regardless of liability. CMS has also asserted a statutory right of recovery against any entity that has
        received payment from a primary plan or the proceeds of a primary plan's payment to any entity…,” which also
        includes the possibility of a “double damages” penalty. 42 U.S.C. §1395y(b)(2)(B)(iii); 42 C.F.R. §411.24
        (c)(2), (d) and (g); and See generally CMS Policy Memorandum, April 22, 2003, p. 5.
      </Text>

      <Text>
        CMS retains its rights of subrogation against such individuals or entities who misappropriate designated
        Medicare funds. 42 U.S.C. §1395y(b)(2)(B)(iv). Pursuant to 42 C.F.R. §411 et. seq., it is also CMS's position
        that if the burden is shifted onto Medicare and Medicare is made the primary payer, they will consider the
        settlement agreement null and void and not recognize the settlement. See also 42 C.F.R. 411.46(b)(2).
      </Text>

      <Text>
        Based upon the MSP Act, federal regulations supporting the Act, and CMS policy, it is highly recommended parties
        in cases like these establish an MSA. In non-workers' compensation personal injury cases, full recovery is not
        possible secondary to comparative negligence, policy limits, damage caps, and extremely conservative
        jurisdictions. While CMS does not endorse the practice of reducing the MSA amount, courts and multiple
        jurisdictions have endorsed the process to promote judicial economy, protect the interests of the Medicare Trust
        Fund, and allow for reasonable recoveries for parties suffering personal injuries.
      </Text>

      <Divider className="divider" />
    </>
  );
};

export default Step1;
