import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Row, Col, Typography, Divider, Select, Icon, Tooltip, Button, Input } from 'antd';
import { useSelector } from 'react-redux';
import DatePickerSimple from 'react-datepicker';
import * as Logic from '../../MSA/ProposedMedicare/logic';

// API
import * as API from 'utils/api';

// Components
import DropDown from 'components/Dropdown';
import { notifyApiError } from 'utils/notification';
import { SignatureReport } from '../../components/SignatureReport';
import PrevNextStep from 'components/PrevNextStep';

// Const
import { REPORT_LIFE_CARE_PLAN_NAME } from 'constants/reports';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';
import { MAIN_NAV, CLAIMANT_DETAIL_NAV, CLAIMANT_EDIT } from 'layout/navigate_const';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

// Utils
import { getListSignature, getValueInsuranceCarrier } from '../../utils/helpers';

// Style
import './demographicLCP.scss';
import SpinBox from 'components/SpinBox';
import ClaimantInfo from 'components/ClaimantInfoOnWizard';
import InlineContact from 'components/InlineContact';
import InputBox from 'components/InputBox';
import ClaimAndInjury from 'components/ClaimAndInjury';
import InputCurrencyBox from 'components/InputCurrencyBox';
import { createViewReportIfNotExists } from 'utils/report';
import dynamicData from 'dynamicData';
import { sortArrayObjectForKey } from 'utils/utils';
import arrayMove from 'array-move';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

const DemographicLCP = () => {
  const { id } = useParams();
  const history = useHistory();
  const { roles } = useSelector(state => state.auth);

  const [loadingPage, setLoadingPage] = useState(true);
  const [signature, setSignature] = useState([]);
  const [reportInfo, setReportInfo] = useState({});
  const [report_claim_id, setReportClaimId] = useState(0);
  const [reportContacts, setReportContacts] = useState([]);
  const [plaintifList, setPlaintifList] = useState([]);
  const [defenseList, setDefenseList] = useState([]);
  const [employerList, setEmployerList] = useState([]);
  const [juridictionStateList, setJuridictionStateList] = useState([]);
  const [related_diagnosis, setRelated_diagnosis] = useState([]);
  const [icdCodeList, setIcdCodeList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [icd_code, setIcd_code] = useState('');
  const [icd_descr, setIcd_descr] = useState('');
  const [medicare, setMedicare] = useState([]);

  // Permissions
  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    (async () => {
      try {
        if (REPORT_PERMISSIONS.cloneReport) {
          await createViewReportIfNotExists({ reportId: id });
        }

        const result = await Promise.allSettled([
          API.getReportById(id),
          getReportContacts(),
          getContactLists(),
          API.getJuridictionStateList(),
          handleSearchIcdCodes(),
          API.getMedicareData({ reportId: id }),
        ]);

        const report = result[0]?.value || [];
        const stateList = result[3]?.value || [];
        const medicareRes = result[5]?.value || {};

        let related_diagnosis = [];
        let newIcdCodeList = [];
        let finallIcdCodeList = [];

        if (report.related_diagnosis && report.related_diagnosis !== '') {
          related_diagnosis = report.related_diagnosis.split(',');
          newIcdCodeList = related_diagnosis
            .map(item => {
              return {
                ...JSON.parse(item.split('<').join(',').split('$').join(',')),
              };
            })
            .filter(item => !this.state.icdCodeList.find(diagnosis => diagnosis.code === item.code));
          finallIcdCodeList = [...newIcdCodeList, ...this.state.icdCodeList];
          this.setState({ icdCodeList: finallIcdCodeList });
        }

        setSignature(getListSignature({ signature: report.signature }));
        setMedicare(medicareRes?.medicare_cost_projections || []);
        setRelated_diagnosis(related_diagnosis);
        setJuridictionStateList(sortArrayObjectForKey({ array: stateList.juridictionStates, key: 'name' }));
        setReportInfo(report);
        setReportClaimId(
          report.report_claim_id ? report.report_claim_id.split('$')[0] : Math.floor(Date.now() / 1000) + ''
        );
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoadingPage(false);
      }
    })();
  }, []);

  const goToEditClaimant = () => {
    const claimant = reportInfo.claimant;
    history.push(CLAIMANT_EDIT.path, { data: claimant });
  };

  const getReportContacts = async () => {
    try {
      const res = await API.getReportContacts(id);
      setReportContacts(res?.report_contacts?.rows || []);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const getContactLists = async () => {
    try {
      const res = await API.getContactsData({ pageSize: 1000 });
      const dynamic = dynamicData();

      setDefenseList(
        (res?.contacts || []).filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.defense_attorney
        )
      );
      setEmployerList(
        (res?.contacts || []).filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.employer_demographics
        )
      );
      setPlaintifList(
        res.contacts.filter(item => (item.contact_type ? item.contact_type.name : '') === dynamic.plaintiff_attorney)
      );
    } catch (e) {
      notifyApiError(e);
    }
  };

  const handleDiagnosisDragStart = e => {
    this.dragged = e.currentTarget.parentElement.parentElement;
  };

  const handleDiagnosisDragOver = e => {
    e.preventDefault();

    this.dragged.style.display = 'none';

    if (e.target.tagName !== 'LI') return;

    const dgIndex = this.dragged.querySelector('.related-list-item').dataset.id;
    const taIndex = e.target.querySelector('.related-list-item').dataset.id;
    const animateName = dgIndex > taIndex ? 'drag-up' : 'drag-down';

    if (
      this.over &&
      e.target.querySelector('.related-list-item').dataset.rid !==
        this.over.querySelector('.related-list-item').dataset.rid
    ) {
      this.over.classList.remove('drag-up', 'drag-down');
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      this.over = e.target;
    }
  };

  const handleDiagnosisDragEnd = e => {
    this.dragged.style.display = 'block';

    e.target.parentElement.parentElement.classList.remove('drag-up');
    this.over.classList.remove('drag-up');

    e.target.parentElement.parentElement.classList.remove('drag-down');
    this.over.classList.remove('drag-down');

    const from = this.dragged.querySelector('.related-list-item').dataset.id;
    const to = this.over.querySelector('.related-list-item').dataset.id;
    const data = arrayMove(related_diagnosis, from, to);

    setRelated_diagnosis(data);
  };

  const handleSearchIcdCodes = async value => {
    try {
      const res = await API.getIcdCodeData(value);
      let correctArr = [...res.icd_codes];

      setIcdCodeList(correctArr);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const handleAddNewICDCode = () => {
    try {
      if (window.isEmptyOrSpaces(icd_code) || window.isEmptyOrSpaces(icd_descr))
        return alert('Please input valid data');

      const newItem = { code: icd_code, med_descr: icd_descr };
      if (icd_code === '' || icd_descr === '') return notifyApiError('Please input the values');

      newItem.med_descr = newItem.med_descr.split(',').join('$');
      icdCodeList.push(newItem);
      related_diagnosis.push(JSON.stringify(newItem).split(',').join('<'));
    } finally {
      setIsModalOpen(false);
      setIcd_code('');
      setIcd_descr('');
    }
  };

  const handleChangeSignature = data => {
    setSignature(data.signature);
  };

  const handleDeleteSignature = ({ index }) => {
    setSignature(signature.filter((item, inde) => inde !== index));
  };

  const handleAddSignature = () => {
    const add = [...signature, INIT_SIGNATURE];
    setSignature(add);
  };
  const updateReport = async () => {
    const body = reportInfo;
    body.signature = signature;

    delete body.logo;

    await API.updateReport(body);
  };

  const handleUpdateReport = async () => {
    try {
      setLoadingPage(true);
      await updateReport();
      setLoadingPage(false);
    } catch (e) {
      notifyApiError(e);
      setLoadingPage(false);
    }
  };

  const notValid = !reportInfo.juridiction_state_id && !reportInfo.is_owcp;
  const dataDynamic = dynamicData();

  return (
    <Row className="msa-demographic-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            {`${REPORT_LIFE_CARE_PLAN_NAME} Report`}
          </Title>
        </Col>
        <Col className="step">
          <DropDown
            disabled={notValid}
            loading={loadingPage}
            goBack={() => history.push(REPORT_PERMISSIONS.goBackClaimant ? CLAIMANT_DETAIL_NAV.path : MAIN_NAV.path)}
            beforeNext={async () => await handleUpdateReport()}
          />
        </Col>
      </Row>
      <Row className="main">
        <SpinBox loading={loadingPage}>
          <Row className="content">
            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={24}>
                <ClaimantInfo
                  goToEditClaimant={REPORT_PERMISSIONS.edit && goToEditClaimant}
                  claimant={{
                    ...reportInfo.claimant,
                    report_claim_id,
                  }}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={24}>
                <Text className="title">Demographic Information</Text>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top" className="row">
              <Col md={11}>
                <InlineContact
                  getReportContacts={getReportContacts}
                  getContactLists={getContactLists}
                  contact_type_id={dataDynamic.plaintiff_attorney_id}
                  another={false}
                  label="Plaintiff Attorney"
                  disabled={!REPORT_PERMISSIONS.edit}
                  report_id={id}
                  contactsList={plaintifList}
                  type={'plaintiff_attorney'}
                  value={reportContacts
                    .filter(item => item.type === 'plaintiff_attorney')
                    .map(item => {
                      if (!item.contact) {
                        return null;
                      }
                      return item.contact.id;
                    })
                    .filter(x => x)}
                />
              </Col>
              <Col md={11}>
                <InlineContact
                  getReportContacts={getReportContacts}
                  getContactLists={getContactLists}
                  contact_type_id={dataDynamic.defense_attorney_id}
                  another={false}
                  label="Defense Attorney"
                  report_id={id}
                  disabled={!REPORT_PERMISSIONS.edit}
                  contactsList={defenseList}
                  type={'defense_attorney'}
                  value={reportContacts.filter(item => item.type === 'defense_attorney').map(item => item.contact.id)}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={11}>
                <InputBox
                  label="Insurance carrier"
                  disabled={!REPORT_PERMISSIONS.edit}
                  value={getValueInsuranceCarrier({
                    value: reportInfo.insurance_carrier || undefined,
                    reportContact: reportContacts,
                    claimant: reportInfo.claimant,
                  })}
                  onChange={value => setReportInfo({ ...reportInfo, insurance_carrier: value })}
                  classes="addClaimantInput"
                />
              </Col>
              <Col md={11}>
                <Row>
                  <Text className={notValid ? 'jurisdiction-state-label' : ''}>State of Jurisdiction</Text>
                </Row>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    disabled={!REPORT_PERMISSIONS.edit}
                    className="dropdown"
                    value={reportInfo.is_owcp ? 1000 : reportInfo.juridiction_state_id}
                    onChange={value => {
                      if (value === 1000)
                        return setReportInfo({ ...reportInfo, juridiction_state_id: null, is_owcp: true });

                      setReportInfo({ ...reportInfo, juridiction_state_id: value, is_owcp: null });
                    }}
                    suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                    {juridictionStateList.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>
            </Row>

            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={11}>
                <InlineContact
                  getReportContacts={getReportContacts}
                  getContactLists={getContactLists}
                  contact_type_id={dataDynamic.employer_demographics_id}
                  another={false}
                  disabled={!REPORT_PERMISSIONS.edit}
                  label="Employer Demographics"
                  report_id={id}
                  contactsList={employerList}
                  type={'employer_demographics'}
                  value={reportContacts
                    .filter(item => item.type === 'employer_demographics')
                    .map(item => item.contact.id)}
                />
              </Col>
            </Row>

            <ClaimAndInjury
              onChange={value => setReportInfo({ ...reportInfo, report_claim_id: value })}
              report_claim_id={reportInfo.report_claim_id}
              disabled={!REPORT_PERMISSIONS.edit}
              onClaimUpdated={() => {}}
            />

            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={24}>
                <Row className="label">
                  <Text>Related Diagnosis</Text>
                </Row>
                <Row>
                  <div className="related-list" onDragOver={e => handleDiagnosisDragOver(e)}>
                    <Select
                      mode="multiple"
                      className="dropdown"
                      filterOption={false}
                      placeholder="Please enter"
                      value={related_diagnosis}
                      disabled={!REPORT_PERMISSIONS.edit}
                      onSearch={value => {
                        if (value) handleSearchIcdCodes(value);
                      }}
                      onChange={value => setRelated_diagnosis(value)}>
                      {icdCodeList.map((item, index) => {
                        let value = JSON.stringify({
                          code: item.code,
                          med_descr: item.med_descr ? item.med_descr.replace(/,/g, '$') : '',
                        }).replace(/,/g, '<');
                        const sortId =
                          related_diagnosis.indexOf(value) !== -1
                            ? related_diagnosis.indexOf(value)
                            : related_diagnosis.length + index;
                        return (
                          <Option key={index} value={value}>
                            <Tooltip
                              overlayStyle={{ maxWidth: 300 }}
                              placement="right"
                              title={` (${item.code}) ${item.med_descr}`}>
                              <span
                                draggable="true"
                                onMouseDown={e => {
                                  e.stopPropagation();
                                }}
                                onDragStart={e => handleDiagnosisDragStart(e)}
                                onDragEnd={e => handleDiagnosisDragEnd(e)}
                                data-id={sortId}
                                data-rid={`${item.code}-${item.med_descr}`}
                                className="related-list-item">
                                {` (${item.code}) ${item.med_descr}`}
                              </span>
                            </Tooltip>
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Row>
                <Row>
                  <Button
                    disabled={!REPORT_PERMISSIONS.edit}
                    type="primary"
                    className="green-btn add-icd-code_button"
                    onClick={() => setIsModalOpen(true)}>
                    Add Custom Code
                  </Button>
                </Row>
              </Col>
            </Row>

            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={24}>
                <InputCurrencyBox
                  label="MSA Allocation Amount"
                  placeholder=" "
                  disabled={true}
                  value={Logic.anticipated_medicare_cost(medicare).toFixed(2) || 0}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" align="middle" className="row">
              <Col md={11}>
                <Row className="label">
                  <Text>Referral Date</Text>
                </Row>
                <Row>
                  <DatePickerSimple
                    className="demographic-date"
                    placeholderText="Select Referral Date"
                    selected={reportInfo.referral_date ? moment(reportInfo.referral_date).toDate() : moment().toDate()}
                    onChange={date => setReportInfo({ ...reportInfo, referral_date: date ? moment(date) : undefined })}
                    minDate={new Date('2000-01-01')}
                    showYearDropdown
                    disabled={!REPORT_PERMISSIONS.edit}
                    showMonthDropdown
                  />
                </Row>
              </Col>
              <Col md={11}>
                <Row className="label">
                  <Text>Date of Report</Text>
                </Row>
                <Row>
                  <DatePickerSimple
                    className="demographic-date"
                    placeholderText="Select Date of Report"
                    selected={
                      reportInfo.date_of_report ? moment(reportInfo.date_of_report).toDate() : moment().toDate()
                    }
                    onChange={date => setReportInfo({ ...reportInfo, date_of_report: date ? moment(date) : undefined })}
                    minDate={new Date('2000-01-01')}
                    showYearDropdown
                    disabled={!REPORT_PERMISSIONS.edit}
                    showMonthDropdown
                  />
                </Row>
              </Col>
            </Row>

            {REPORT_PERMISSIONS.signature && (
              <SignatureReport
                defaultSignature={signature}
                onChangeSignature={handleChangeSignature}
                onDeleteSignature={handleDeleteSignature}
                onAddSignature={handleAddSignature}
                disabled={!REPORT_PERMISSIONS.edit}
              />
            )}

            <Divider className="divider" />

            <PrevNextStep
              saveDraft={REPORT_PERMISSIONS.saveDraft}
              beforeNext={handleUpdateReport}
              disabled={notValid}
            />
          </Row>
        </SpinBox>
      </Row>

      {isModalOpen && (
        <Modal
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          centered={true}
          closable={true}
          width={500}
          className="add-icd-code-modal"
          title={'Add Custom Code'}>
          <Row className="modal-body">
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Code</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={icd_code}
                  onChange={e => setIcd_code(e.target.value)}
                />
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Description</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={'Required'}
                  className="input"
                  disabled={false}
                  value={icd_descr}
                  onChange={e => setIcd_descr(e.target.value)}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Button type="primary" className="green-btn button" onClick={handleAddNewICDCode}>
                ADD
              </Button>
            </Row>
          </Row>
        </Modal>
      )}
    </Row>
  );
};

export default DemographicLCP;