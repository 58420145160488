import React, { useEffect, useState } from 'react';
import { Typography, Select, Row, Col, Switch, Modal } from 'antd';
import { useSelector } from 'react-redux';

import * as API from 'utils/api';
import isAllowed, {
  ROLES_DIVISIONS,
  CLIENT_USER_ID,
  CLIENT_ADMIN_ID,
  MEDICAL_REVIEWER_ID,
  ATTORNEY_MANAGER_ID,
  SALES_MANAGER_ID,
  SALES_REP_ID,
  CLIENT_SUPER_ADMIN_ID,
  EXTERNAL_LITIGATION_ATTORNEY_ID,
} from 'layout/roles';
import { notifyApiError } from 'utils/notification.js';
import { SPECIALTY_LIST } from 'constants/specialty';
import { BoxDragAndDrop, TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';
import { ModalLoading } from 'components/Modal/ModalLoading';

import './EditUserRoles.scss';

const { Text } = Typography;
const { Option } = Select;

export default function EditUserRoles({
  user_id,
  onRoleChange,
  GetRoles,
  selectedRoles,
  clientId,
  onClientChange,
  clients,
  fetchClients,
  disabledCLient,
  specialtyId,
  onSpecialtyChange,
  salesReps,
  salesRepId,
  salesManagers,
  salesManagerId,
  cv,
  newCv,
  uploadFile,
  onChangeVisibility,
  visibility,
}) {
  const { roles } = useSelector(state => state.auth);

  const [selectedValues, setSelectedValues] = useState(selectedRoles || []);
  const [clientMode, setClientMode] = useState(false);
  const [client_id, setClientId] = useState(clientId || undefined);
  const [medicalReviewerMode, setMedicalReviewerMode] = useState(false);
  const [specialty, setSpecialty] = useState(specialtyId || '');
  const [sales_rep_id, setSales_rep_id] = useState(salesRepId);
  const [salesManagerMode, setSalesManagerMode] = useState(false);
  const [sales_manager_id, setSalesManagerId] = useState(salesManagerId);
  const [listFiles, setListFiles] = useState(newCv || cv ? [newCv || cv] : []);
  const [litigationExtMode, setLitigationExtMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      selectedRoles.some(role =>
        [
          CLIENT_ADMIN_ID,
          CLIENT_SUPER_ADMIN_ID,
          CLIENT_USER_ID,
          ATTORNEY_MANAGER_ID,
          EXTERNAL_LITIGATION_ATTORNEY_ID,
        ].includes(role.id)
      )
    ) {
      fetchClients();
    }
  }, [selectedRoles, fetchClients]);

  useEffect(() => {
    if (
      selectedValues.some(role =>
        [...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.MANAGER_ATTORNEY].includes(role.name)
      )
    )
      setClientMode(true);

    if (selectedValues.some(role => role.id === MEDICAL_REVIEWER_ID)) setMedicalReviewerMode(true);

    if (selectedValues.some(role => role.id === SALES_REP_ID)) setSalesManagerMode(true);

    if (selectedValues.some(role => role.id === EXTERNAL_LITIGATION_ATTORNEY_ID)) setLitigationExtMode(true);

    onRoleChange(selectedValues);
    // eslint-disable-next-line
  }, [selectedValues]);

  useEffect(() => {
    onClientChange({ client_id, sales_rep_id });
    // eslint-disable-next-line
  }, [client_id, sales_rep_id]);

  const onDeselect = async role_id => {
    let roles = selectedValues.filter(role => role.id !== role_id);

    if (!roles.length) {
      return notifyApiError('User should have at least one role');
    }

    if (roles.some(role => ![...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.MANAGER_ATTORNEY].includes(role.name))) {
      setClientMode(false);
      setClientId(undefined);
      setSales_rep_id(undefined);
    }

    if (roles.some(role => role.id !== MEDICAL_REVIEWER_ID)) {
      setMedicalReviewerMode(false);
      setSpecialty(undefined);
    }

    if (roles.some(role => role.id !== SALES_MANAGER_ID)) {
      setSalesManagerMode(false);
      setSalesManagerId(undefined);
    }

    if (roles.some(role => role.id !== EXTERNAL_LITIGATION_ATTORNEY_ID)) {
      setLitigationExtMode(false);
      // quitar la firma, el switch y los archivos
    }

    setSelectedValues([...roles]);

    try {
      await API.DeleteRoles(user_id, role_id);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const onSelect = async role_id => {
    let roles = [...selectedValues];
    let role = GetRoles.find(role => {
      if (role.id === role_id) {
        return {
          id: role.id,
          name: role.name,
        };
      }
    });

    const isClientInRol = [...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.MANAGER_ATTORNEY].includes(role.name);

    if (isClientInRol) {
      setClientMode(true);
      fetchClients();
    }

    const isMedicalReviewer = ROLES_DIVISIONS.MANAGER.includes(role.name);

    roles.push(role);

    setSelectedValues([...roles]);

    try {
      let body = {};

      if (isClientInRol && clientId) body.clientId = clientId;

      if (isMedicalReviewer && specialty) body.specialty = specialty;

      await API.AddRole(user_id, role_id, body);
    } catch (e) {
      notifyApiError(e.message);
    }
  };

  // Cuando se selecciona un client se ejecuta esta funcion
  const continueAddingRole = async ({ id, salesRepId, salesManId, onlyClient, onlyLitigation }) => {
    try {
      const clientRole = selectedValues.find(role =>
        [...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.MANAGER_ATTORNEY].includes(role.name)
      );

      if (salesRepId) {
        await API.EditRole(user_id, clientRole.id, { client_id: id, sales_rep_id: salesRepId });
      }

      if (salesManId) {
        await API.EditRole(user_id, SALES_REP_ID, { sales_manager_id: salesManId });
      }

      if (onlyClient) {
        await API.EditRole(user_id, clientRole.id, { client_id: id });
      }

      if (onlyLitigation) {
        await API.EditRole(user_id, EXTERNAL_LITIGATION_ATTORNEY_ID, { client_id: id });
      }

      onRoleChange(selectedValues, id);
    } catch (e) {
      notifyApiError(e.message);
    }
  };

  const beforeUpload = file => {
    if (!file) return false;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      Modal.error({
        content: 'You can only upload PDF file!',
      });
    }

    return isPdf;
  };

  const handleAddFiles = ({ file }) => {
    setListFiles([file]);
    uploadFile({ file });
  };

  const removeFile = async () => {
    try {
      setLoading(true);

      setListFiles('');
      if (!cv?.id) return;

      await API.deleteFile(cv.id);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row type="flex" align="top" justify="space-between">
      {loading && <ModalLoading />}

      <Col md={11}>
        <Row className="row" style={{ marginBottom: '10px' }}>
          <Text className="label">Roles</Text>
          <Select
            mode="multiple"
            value={selectedValues.map(role => role.id)}
            onDeselect={onDeselect}
            placeholder="Required"
            onSelect={onSelect}
            style={{ width: '100%' }}>
            {GetRoles.map(role => (
              <Option key={role.id} value={role.id}>
                {role.display_name}
              </Option>
            ))}
          </Select>
        </Row>
      </Col>

      {clientMode && (
        <>
          <Col md={11}>
            <Row className="label">
              <Text>Client</Text>
            </Row>
            <Select
              placeholder="Required"
              className="clients-select"
              value={client_id}
              disabled={disabledCLient}
              onChange={id => {
                continueAddingRole({ id, onlyClient: true });
                setClientId(id);
              }}
              style={{ width: '100%' }}>
              {clients.map((client, i) => {
                return (
                  <Option key={i.toString(36) + i} value={client.id}>
                    {client.client_name}
                  </Option>
                );
              })}
            </Select>
          </Col>

          {selectedValues.some(role => [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role.id)) && (
            <>
              {isAllowed(roles, ROLES_DIVISIONS.CLIENT_ADMIN) ? (
                <Col span={11} className="sales_rep_by_client_admin">
                  <Text className="label">Assigned Sales Rep</Text>
                  <Text>
                    {salesReps.find(item => item.id === sales_rep_id)?.given_name || ''}{' '}
                    {salesReps.find(item => item.id === sales_rep_id)?.family_name || ''}
                  </Text>
                </Col>
              ) : (
                <Col span={11}>
                  <Text className="label">Assign Sales Rep</Text>
                  <Select
                    placeholder="Required"
                    className="clients-select"
                    value={sales_rep_id}
                    disabled={disabledCLient}
                    onChange={id => {
                      continueAddingRole({ salesRepId: id, id: client_id });
                      setSales_rep_id(id);
                    }}
                    style={{ width: '100%' }}>
                    {salesReps.map(userRep => (
                      <Option key={userRep.id} value={userRep.id}>
                        {userRep.given_name || ''} {userRep.family_name || ''}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </>
          )}
        </>
      )}

      {medicalReviewerMode && (
        <Col span={11}>
          <Text className="label">Specialty</Text>
          <Select
            placeholder="Required"
            className="clients-select"
            value={Number(specialty) || Number(specialtyId)}
            onChange={id => {
              setSpecialty(id);
              onSpecialtyChange({ specialty_id: id });
            }}
            style={{ width: '100%' }}>
            {SPECIALTY_LIST.map(specialty => {
              return (
                <Option key={specialty.id} value={Number(specialty.id)}>
                  {specialty.name}
                </Option>
              );
            })}
          </Select>
        </Col>
      )}

      {salesManagerMode && (
        <Col span={11}>
          <Text className="label">Sales Manager</Text>
          <Select
            placeholder="Required"
            className="clients-select"
            value={sales_manager_id}
            onChange={id => {
              continueAddingRole({ salesManId: id, id: client_id });
              setSalesManagerId(id);
            }}
            style={{ width: '100%' }}>
            {salesManagers.map(userRep => (
              <Option key={userRep.id} value={userRep.id}>
                {userRep.given_name || ''} {userRep.family_name || ''}
              </Option>
            ))}
          </Select>
        </Col>
      )}

      {litigationExtMode && (
        <>
          <Col span={11}>
            <Text className="label">Firm</Text>
            <Select
              placeholder="Required"
              className="clients-select"
              defaultValue={client_id}
              disabled={disabledCLient}
              onChange={id => {
                continueAddingRole({ id, onlyLitigation: true });
                setClientId(id);
              }}
              style={{ width: '100%' }}>
              {clients.map((client, i) => (
                <Option key={i.toString(36) + i} value={client.id}>
                  {client.client_name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={24} className="userRoles_section">
            <Text className="label">Visible at MSA Attorney's List</Text>
            <br />
            <Switch
              className="litigation_switch"
              defaultChecked={visibility}
              checked={visibility}
              onChange={() => onChangeVisibility({ visibility: !visibility, client_id })}
            />
          </Col>

          <Col span={24} className="userRoles_section">
            <Text className="label">CV</Text>
            <BoxDragAndDrop multiple beforeUpload={beforeUpload} handleAddFiles={handleAddFiles} />

            {listFiles.length > 0 && (
              <TableFileBoxDragAndDrop listFiles={listFiles} removeFile={removeFile} removeAllFiles={removeFile} />
            )}
          </Col>
        </>
      )}
    </Row>
  );
}
