import React, { Component } from 'react';
import { Button, Checkbox, Col, Icon, InputNumber, Modal, Row, Select, Typography, DatePicker } from 'antd';
import moment from 'moment';

import InputBox from 'components/InputBox';
import * as API from 'utils/api';
import { notifyApiError } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { REPORT_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';
import './ReportModal.scss';
import { connect } from 'react-redux';
import {
  REPORT_MSA_TYPE_ID,
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  EARLY_MESSAGE,
  MCP_MESSAGE,
} from 'constants/reports';
import { SpecialtyCheck } from 'components/SpecialtyCheck';
import { getRushFees } from 'utils/utils';
import { ModalRushFees } from 'components/Modal/ModalRushFees';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { ModalAdverUpgradePlan } from 'components/Modal/ModalAdverUpgradePlan';
import { ModalLoading } from 'components/Modal/ModalLoading';

const { Text } = Typography;
const { Option } = Select;

const INITIAL_STATE = {
  report_name: '',
  report_type: 1,
  report_status: 1,
  allReports: [],
  feeding_id: null,
  show_additional_comments: true,
  has_inflation: false,
  templates: [],
  interest_rate: '0',
  settlement: '',
  md_tbd: null,
  is_private: false,
  optionAddReportTemplate: false,
  optionAddReportCopy: false,
  modalUpgadePlan: false,
  loading: false,
};

const TYPE_MODAL = {
  ADD: 'ADD',
  CLONE: 'CLONE',
  EDIT: 'EDIT',
};

export const OPTION_CREATE_LCP = 'add template';

class ReportModal extends Component {
  constructor(props) {
    super(props);

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions
    const { data } = this.props;

    this.state = {
      ...INITIAL_STATE,
      REPORT_PERMISSIONS,
      report_name: data?.report_name || '',
      pricing_group_id: data?.pricing_group_id,
      report_type: data?.report_type_id || 1,
      show_additional_comments: data?.show_additional_comments ? data?.show_additional_comments : true,
      has_inflation: data?.has_inflation,
      interest_rate: data?.interest_rate ? data?.interest_rate : '0',
      report_status: data?.report_status_id || 1,
      settlement: data?.pre_settlement ? 'pre' : data?.post_settlement ? 'post' : '',
      md_tbd: data?.md_tbd || null,
      is_private: data?.is_private, // field by template
      needed_by: data?.needed_by ? moment(data.needed_by) : undefined,
      modalRushFees: false,
    };

    this.loadTemplates = this.loadTemplates.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.handleChangeNeededBy = this.handleChangeNeededBy.bind(this);
    this.handleCloseRushFees = this.handleCloseRushFees.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      const { data } = this.props;

      this.setState({
        report_name: data?.report_name || '',
        pricing_group_id: data?.pricing_group_id,
        report_type: data?.report_type_id || 1,
        show_additional_comments: data?.show_additional_comments ? data?.show_additional_comments : true,
        has_inflation: data?.has_inflation,
        interest_rate: data?.interest_rate ? data?.interest_rate : '0',
        report_status: data?.report_status_id || 1,
        settlement: data?.pre_settlement ? 'pre' : data?.post_settlement ? 'post' : '',
        md_tbd: data?.md_tbd || null,
        is_private: data?.is_private, // field by template
      });
    }
  }

  loadTemplates(statePlace) {
    const apiCall = tenantOnly => API.getAllTemplateReports({ tenantOnly });

    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserManager = isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER);

    apiCall(isUserManager && !isUserAdmin ? true : false)
      .then(res => this.setState({ [statePlace]: res.reports }))
      .catch(e => notifyApiError(e));
  }

  async handleChange(value) {
    try {
      this.setState({ loading: true });

      const validOpenRerpot = await validateOpenReportForPackage({
        report_type: value,
        tenant_id: this.props.roles.user_roles.tenant_id,
      });

      if (!validOpenRerpot.open) return this.setState({ modalUpgadePlan: true });

      if (value === REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID)
        return this.setState({ report_type: value, show_additional_comments: false, feeding_id: null });

      return this.setState({ report_type: value, show_additional_comments: true, feeding_id: null });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    if (!this.props.isTemplates) {
      this.loadTemplates('templates');
    }
    API.getResourceGroups().then(res => this.setState({ pricing_groups: res.pricing_groups }));
    if (this.props.isTemplates) {
      this.loadTemplates('allReports');
    } else {
      API.getReportsDataByClaimantId({ claimantId: this.props.claimant_id }).then(res => {
        this.setState({ allReports: res.reports });
      });
    }
  }

  async handleConfirm() {
    const { onConfirm, isTemplates } = this.props;
    const {
      report_name,
      report_type,
      report_status,
      show_additional_comments,
      has_inflation,
      interest_rate,
      pricing_group_id,
      settlement,
      md_tbd,
      is_private,
      needed_by,
      feeding_id,
      feeding_id_template,
      feeding_id_copy,
    } = this.state;

    if (!isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT) && report_name.trim() === '')
      return Modal.error({
        content: `Please Enter ${isTemplates ? 'Template' : 'Report'} Name`,
      });

    if (report_type === REPORT_MSA_TYPE_ID && !settlement)
      return Modal.error({
        content: `Please Select Pre or Post Settlement`,
      });

    const param = {
      show_additional_comments,
      report_name: report_name.trim(),
      report_type_id: report_type,
      report_status_id: report_status,
      has_inflation,
      interest_rate,
      pricing_group_id: pricing_group_id ? pricing_group_id : null,
      feeding_id: feeding_id,
      pre_settlement: settlement === 'pre',
      post_settlement: settlement === 'post',
      md_tbd,
      needed_by,
      feeding_id_template,
      feeding_id_copy,
    };

    if (isTemplates) param.is_private = is_private;

    await onConfirm(param, this.state.templates, this.state.is_template);
  }

  cancelModal() {
    this.props.onCancel();

    this.setState({
      feeding_id: null,
      is_template: undefined,
      copy: undefined,
    });
  }

  handleChangeNeededBy({ needed_by }) {
    let openModal = false;

    if (
      this.state.REPORT_PERMISSIONS.alertRushFees &&
      getRushFees({ createdAt: new Date(), needed_by, max_days: 14 })
    ) {
      openModal = true;
    }

    this.setState({
      needed_by,
      modalRushFees: openModal,
    });
  }

  handleCloseRushFees({ confirm } = {}) {
    if (!confirm) return this.setState({ modalRushFees: false, needed_by: null });

    this.setState({ modalRushFees: false });
  }

  render() {
    const { reportStatuses, type, isOpen, reportTypes, roles, isTemplates } = this.props;
    const {
      report_name,
      report_type,
      report_status,
      show_additional_comments,
      has_inflation,
      interest_rate,
      settlement,
      md_tbd,
      is_private,
      REPORT_PERMISSIONS,
      modalRushFees,
      needed_by,
      optionAddReportTemplate,
      optionAddReportCopy,
      feeding_id_template,
      feeding_id_copy,
      modalUpgadePlan,
      loading,
    } = this.state;

    let ResourceName = this.props.isTemplates ? 'Template' : 'Report';

    let listTemplate = [];

    if (this.props.templates) {
      listTemplate =
        report_type === REPORT_LIFE_CARE_PLAN_TYPE_ID
          ? this.props?.templates.filter(item => item.report_type_id === REPORT_LIFE_CARE_PLAN_TYPE_ID)
          : this.props?.templates.filter(item => item.report_type_id !== REPORT_LIFE_CARE_PLAN_TYPE_ID);
    }

    return (
      <>
        <Modal
          visible={isOpen}
          onCancel={this.cancelModal}
          footer={null}
          centered={true}
          closable={true}
          width={550}
          className="report-modal"
          title={
            type === TYPE_MODAL.ADD
              ? `Add ${this.props.isTemplates ? 'Template' : 'Report'}`
              : `Edit ${this.props.isTemplates ? 'Template' : 'Report'}`
          }>
          <Row type="flex" align="middle" className="report-body">
            {!isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT) && (
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <InputBox
                    label={`${ResourceName} Name`}
                    value={report_name}
                    onChange={value => this.setState({ report_name: value })}
                  />
                </Col>
              </Row>
            )}

            {type !== TYPE_MODAL.CLONE && (
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <Row>
                    <Text>{ResourceName} type</Text>
                  </Row>
                  <Row>
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      showSearch
                      id="selectReportTypeByReportModal"
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={report_type}
                      onChange={this.handleChange}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {reportTypes.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col>

                {isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT) && (
                  <Col md={11}>
                    <Row className="label">
                      <Text>Needed By</Text>
                    </Row>
                    <Row>
                      <DatePicker
                        className="date"
                        placeholder="DD / MM / YYYY"
                        format="DD/MM/YYYY"
                        allowClear={false}
                        value={needed_by}
                        id="neededByReportCreate"
                        disabledDate={current => {
                          return moment().add(-1, 'days') >= current;
                        }}
                        onChange={date => this.handleChangeNeededBy({ needed_by: date ? moment(date) : undefined })}
                      />
                    </Row>
                  </Col>
                )}

                {REPORT_PERMISSIONS.edit && !this.props.isTemplates && (
                  <Col md={12} className="row">
                    <Row>
                      <Text>{ResourceName} Status</Text>
                    </Row>
                    <Row>
                      <Select
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        className="dropdown"
                        value={report_status}
                        onChange={value => this.setState({ report_status: value })}
                        suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                        {reportStatuses.map(item => (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                )}
              </Row>
            )}

            {isTemplates && (
              <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
                <Col md={12} className="row">
                  <Checkbox
                    checked={!is_private}
                    onChange={() => {
                      this.setState({
                        is_private: false,
                      });
                    }}>
                    Share (Public)
                  </Checkbox>
                </Col>
                <Col md={12} className="row">
                  <Checkbox
                    checked={is_private}
                    onChange={() => {
                      this.setState({
                        is_private: true,
                      });
                    }}>
                    Personal (Private)
                  </Checkbox>
                </Col>
              </Row>
            )}

            {report_type === REPORT_MSA_TYPE_ID && (
              <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
                <Col md={12} className="row">
                  <Checkbox
                    checked={settlement === 'pre'}
                    onChange={() => {
                      this.setState({
                        settlement: 'pre',
                      });
                    }}>
                    Pre Settlement
                  </Checkbox>
                </Col>
                <Col md={12} className="row">
                  <Checkbox
                    checked={settlement === 'post'}
                    onChange={() => {
                      this.setState({
                        settlement: 'post',
                      });
                    }}>
                    Post Settlement
                  </Checkbox>
                </Col>
              </Row>
            )}

            {[REPORT_MCP_TYPE_ID, REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID, REPORT_LIFE_CARE_PLAN_TYPE_ID].includes(
              report_type
            ) && (
              <SpecialtyCheck
                value={md_tbd}
                onChange={({ event, specialty }) => {
                  this.setState({
                    md_tbd: event.target.checked ? Number(specialty.id) : null,
                  });
                }}
              />
            )}

            {report_type === REPORT_EARLY_INTERVENTION_TYPE_ID && (
              <Row className="main-info createReport_alert">
                <Text>{EARLY_MESSAGE}</Text>
              </Row>
            )}

            {isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT) && (
              <>
                {report_type === REPORT_LIFE_CARE_PLAN_TYPE_ID && (
                  <Row className="main-info createReport_alert">
                    <Text>Nurse will reach out directly to provide a quote</Text>
                  </Row>
                )}
              </>
            )}

            {report_type === REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID && (
              <Row className="main-info createReport_alert">
                <Text>{MCP_MESSAGE}</Text>
              </Row>
            )}

            {REPORT_PERMISSIONS.edit && !this.props.isTemplates && (
              <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
                <Col md={12} className="row">
                  <Checkbox
                    checked={optionAddReportTemplate}
                    onChange={e => {
                      this.setState({
                        optionAddReportTemplate: e.target.checked,
                        copy: false,
                        feeding_id: undefined,
                      });
                    }}>
                    Use Templates
                  </Checkbox>
                </Col>
                <Col md={12} className="row">
                  <Checkbox
                    checked={optionAddReportCopy}
                    onChange={e => {
                      this.setState({
                        optionAddReportCopy: e.target.checked,
                        is_template: false,
                        feeding_id: undefined,
                      });
                    }}>
                    Copy data from other report
                  </Checkbox>
                </Col>
              </Row>
            )}

            {!this.props.isTemplates && optionAddReportTemplate && REPORT_PERMISSIONS.edit && (
              <Row className="main-info">
                <Col md={24} className="row">
                  <Row>
                    <Text>Select Template </Text>
                  </Row>
                  <Row>
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={feeding_id_template}
                      onChange={value =>
                        this.setState({ feeding_id_template: value, feeding_id: value, is_template: true })
                      }
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {report_type === REPORT_LIFE_CARE_PLAN_TYPE_ID &&
                        [TYPE_MODAL.ADD, TYPE_MODAL.EDIT].includes(type) && (
                          <Option className="optionLink" value={OPTION_CREATE_LCP}>
                            Add Template
                          </Option>
                        )}
                      {listTemplate.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.report_name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
            )}

            {this.state.report_type === 4 && !this.state.is_template && (
              <Row className="main-info">
                <Col md={24} className="row">
                  <Row>
                    <Text>Surgical Pricing Plan </Text>
                  </Row>
                  <Row>
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={this.state.pricing_group_id}
                      onChange={value => this.setState({ pricing_group_id: value })}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {this.state.pricing_groups.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
            )}

            <Row type="flex" justify="space-between" align="middle" className="main-info">
              {this.state.allReports.length > 0 && (
                <>
                  {optionAddReportCopy && (
                    <Col md={24} className="row main-info">
                      <Row>
                        <Text>Copy data from {ResourceName}:</Text>
                      </Row>
                      <Row>
                        <Select
                          getPopupContainer={triggerNode => triggerNode.parentNode}
                          placeholder="Please select"
                          optionFilterProp="children"
                          className="dropdown"
                          value={feeding_id_copy}
                          onChange={value => this.setState({ feeding_id_copy: value, feeding_id: value, copy: true })}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                          {this.state.allReports.map(item => (
                            <Option value={item.id} key={item.id}>
                              {item.report_name}
                            </Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  )}
                </>
              )}

              <Row type="flex" justify="space-between" style={{ width: '100%', marginTop: 8 }}>
                {REPORT_PERMISSIONS.edit && (
                  <Col md={12} className="row">
                    <Checkbox
                      checked={show_additional_comments}
                      onChange={() => {
                        this.setState({
                          show_additional_comments: !show_additional_comments,
                        });
                      }}>
                      Show comment sections
                      <br />
                      on the PDF
                    </Checkbox>
                  </Col>
                )}
                <Col md={12} className="row">
                  <Checkbox
                    checked={has_inflation}
                    onChange={() => {
                      this.setState({
                        has_inflation: !has_inflation,
                      });
                    }}>
                    Include Inflation
                  </Checkbox>
                </Col>
              </Row>

              {has_inflation && (
                <Col md={24} className="row" style={{ marginTop: 20 }}>
                  <Row>
                    <Text>Inflation Rate</Text>
                  </Row>

                  <InputNumber
                    className="input"
                    defaultValue={1000}
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    value={interest_rate.replace(/(?!-)[^0-9.]/g, '0')}
                    formatter={value => ` ${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    onChange={value => {
                      this.setState({ interest_rate: value + '' });
                    }}
                  />
                </Col>
              )}
            </Row>
          </Row>

          <Button type="primary" size="large" className="green-btn button" onClick={this.handleConfirm}>
            {type === TYPE_MODAL.EDIT ? 'Save' : 'Setup ' + ResourceName}
          </Button>
        </Modal>

        {REPORT_PERMISSIONS.alertRushFees && modalRushFees && (
          <ModalRushFees
            onConfirm={() => this.handleCloseRushFees({ confirm: true })}
            onChange={() => this.handleCloseRushFees()}
          />
        )}

        {modalUpgadePlan && <ModalAdverUpgradePlan onClose={() => this.setState({ modalUpgadePlan: false })} />}
        {loading && <ModalLoading />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, null)(ReportModal);