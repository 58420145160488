import { Button, Col, Row, Typography } from 'antd';
import { BackIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import React, { useCallback, useEffect, useState } from 'react';
import * as API from 'utils/api';
import './AddPricing.scss';
import PricingForm from './PricingForm';
import AddInputsToForm from './PricingForm/AddInputsToForm';
import { validateInteger, validatePrice } from '../../../utils/utils';
import { notifyApiError, notifyInfo } from '../../../utils/notification';

const { Title } = Typography;

export default function AddPricing(props) {
  const id = props.match.params.id;
  const [priceError, setPriceErrorr] = useState('');
  const [codeErrorr, setCodeErrorr] = useState('');
  const [priceLevelError, setPriceLevelError] = useState('');
  const [levelsData, setLevelsData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [cptData, setCptData] = useState({
    cpt_hcpcs_id: '',
    name: '',
    code: '',
    price: 0.0,
  });
  const [cptList, setCptList] = useState([]);
  const [levelError, setLevelError] = useState('');

  useEffect(() => {
    API.getCPTData().then(res => {
      setCptList(res.cpt_codes);
    });
  }, []);

  const handleDuplicatedInput = (key, value, index) => {
    let tempArr = [...levelsData];
    tempArr[index][key] = value;
    setLevelsData(tempArr);
    setPriceLevelError('');
  };

  const duplicateInput = () => {
    setLevelsData([...levelsData, { number: '', price: 0.0 }]);
    setPriceLevelError('');
    setLevelError('');
  };

  const handleRemove = index => {
    let tempArr = [...levelsData];
    tempArr.splice(index, 1);
    setLevelsData(tempArr);
    setPriceLevelError('');
    setLevelError('');
  };

  const searchCodes = value => {
    API.getCPTData(value).then(res => {
      setCptList(res.cpt_codes);
    });
  };

  const onSubmit = async () => {
    const data = {
      pricing_group_id: id,
      price: cptData.price,
      code_id: cptData.code,
    };

    setLoading(true);
    try {
      const res = await API.cretePricingPlan(data);

      //do not send empty level fields
      if (levelsData.length < 1 && !validatePrice(levelsData[0]?.price)) {
        props.history.goBack();
        notifyInfo('Pricing Plan created');
        return;
      }

      const params = levelsData.map(data => {
        return {
          level_number: data.number,
          price: data.price,
          surgical_pricing_plan_id: res.surgical_pricing_plan.id,
        };
      });

      await API.createSurgicalPricingPlan(params);
      props.history.goBack();
      notifyInfo('Pricing Plan with level(s) created');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  let createDisabled = false;
  if (!cptData.code || !validatePrice(cptData.price)) {
    createDisabled = true;
  }
  //Levels are optional. Do not check initial. Just not submit to backend
  if (levelsData.length) {
    for (const data of levelsData) {
      if (!data.number || !validateInteger(data.number) || !validatePrice(data.price) || data.price <= 0) {
        createDisabled = true;
        break;
      }
    }
  }

  const changeCptList = useCallback(code => {
    setCptList(prev => [...prev, code]);
  }, []);

  return (
    <Row className="add-user-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Create Pricing Plan
          </Title>
        </Col>
        <Col className="back" onClick={() => props.history.goBack()}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <Row className="main">
        <SpinBox loading={loading}>
          <Row className="content">
            <PricingForm
              cptList={cptList}
              changeCptList={changeCptList}
              cptData={cptData}
              codeErrorr={codeErrorr}
              priceError={priceError}
              searchCodes={searchCodes}
              onChange={(value, option) => {
                setCptData(prev => ({
                  ...prev,
                  cpt_hcpcs_id: value,
                  name: option.name,
                  code: value,
                }));
                setCodeErrorr('');
              }}
              onPriceChange={value => {
                setCptData({
                  ...cptData,
                  price: value,
                });
                setPriceErrorr('');
              }}
              setCptData={setCptData}
            />

            <AddInputsToForm
              levelsData={levelsData}
              handleDuplicatedInput={handleDuplicatedInput}
              levelError={levelError}
              priceLevelError={priceLevelError}
              duplicateInput={duplicateInput}
              handleRemove={handleRemove}
            />

            <Row type="flex" justify="center" className="confirm-button">
              <Button
                type="primary"
                size="large"
                className="green-btn button"
                onClick={onSubmit}
                disabled={createDisabled}>
                Create
              </Button>
            </Row>
          </Row>
        </SpinBox>
      </Row>
    </Row>
  );
}
