import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import TrashIcon from 'components/Svg/TrashIcon';
import { Typography } from 'antd';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

export const ItemsTableUploadFile = ({ item, type, onDownload, onDelete, onlyView }) => {
  const { Text } = Typography;

  return (
    <div className="newUploadFile_tableFiles_item newUploadFile_grid3">
      <Text>{type}</Text>
      <Text>{item.file.name}</Text>
      {!onlyView && (
        <Text className="newUploadFile_tableFiles_btnEnd">
          <button className="icon newUploadFile_iconDownload" onClick={() => onDelete(item)}>
            <TrashIcon color={PRIMARY_LIGHT_BLUE} />
          </button>
          <a href={item.file.url} target="_blank" rel="noopener noreferrer">
            <DownloadOutlined
              height={'20px'}
              width={'20px'}
              className="icon newUploadFile_iconDownload"
              onClick={onDownload}
            />
          </a>
        </Text>
      )}
    </div>
  );
};
