import React, { Component } from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import { connect } from 'react-redux';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { formatCurrency } from 'utils/utils';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import { updateReportInfo } from '../../../../redux/report';

import './MedicareTotals.scss';

const { Title, Text } = Typography;

class MedicareTotals extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    this.state = {
      report_id,
      medicareInfo: {},
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });

    API.getReportById(report_id)
      .then(res => {
        this.setState({ is_template: res.is_template });
        this.props.updateReportInfo(res);
        if (!res.is_template) {
          verifyPermissions(res, this.props);
        }
      })
      .catch(err => forbiddenRedirect(err, this.props));

    API.getMedicareInfoData(report_id).then(res => {
      this.setState({
        medicareInfo: res.totals,
        loading: false,
      });
    });
  }

  render() {
    const { medicareInfo, loading } = this.state;

    return (
      <Row className="msa-medical-totals-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup BI Demand #2
            </Title>
          </Col>
          <Col className="step">
            <DropDown loading={loading} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Totals and Allocations</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Annual Cost Total</Text>
                </Col>
                <Text className="text">{formatCurrency(medicareInfo.annual_cost_total || 0)}</Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Recommended Medical Allocation</Text>
                </Col>
                <Text className="text">{formatCurrency(medicareInfo.recommended_medical_allocation || 0)}</Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Surgeries, Replacements and Procedures Allocation</Text>
                </Col>
                <Text className="text">
                  {formatCurrency(medicareInfo.surgeries_replacements_procedures_allocation || 0)}
                </Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Recommended Prescription Allocation</Text>
                </Col>
                <Text className="text">{formatCurrency(medicareInfo.prescription_allocation || 0)}</Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Recommended Allocation</Text>
                </Col>
                <Text className="text">
                  {formatCurrency(
                    medicareInfo.surgeries_replacements_procedures_allocation +
                      medicareInfo.recommended_medical_allocation +
                      medicareInfo.prescription_allocation || 0
                  )}
                </Text>
              </Row>
              <Divider className="divider" />
              <PrevNextStep />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, { updateReportInfo })(MedicareTotals);
