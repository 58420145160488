import React from 'react';
import { Modal, Typography } from 'antd';

// Style
import './modalAddCardPayInvoices.scss';
import { FormAddCreditCard } from '../Form/FormAddCreditCard';

const { Title } = Typography;

const ModalAddCardPayInvoices = ({ onClose, title, textBtn, updateListCard, publicKey }) => (
  <Modal
    visible={true}
    onCancel={onClose}
    footer={null}
    centered={true}
    closable={true}
    width={750}
    title={<Title level={4}>{title}</Title>}>
    <FormAddCreditCard publicKey={publicKey} textBtn={textBtn} afterAdd={() => updateListCard()} />
  </Modal>
);

export default ModalAddCardPayInvoices;
