import React, { useEffect, useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { Menu, Dropdown, Icon, Alert, Spin, notification, Modal, Typography } from 'antd';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearReportFiles, updateReportInfo } from 'redux/report/actions';
import {
  REPORT_SECTIONS_DEFAULT,
  REPORT_SECTION_NAMES,
  REPORT_SECTIONS_DEFAULT_CLIENT,
  REPORT_MSA_REDUCTION,
  REPORT_MSA_REDUCTION_TYPE_ID,
  REPORT_MSA_TYPE_ID,
  REPORT_SECTION_DOCUMENTUPLOAD,
} from '../../constants/reports';
import isAllowed, { ROLES_DIVISIONS } from '../../layout/roles';
import { notifyApiError, notifySuccess } from '../../utils/notification';
import { updateReport } from '../../utils/api';
import { switchStep } from '../../utils/report-routing';
import { updateAssignmentById } from '../../redux/review';
import { ASSIGNMENT_STATUS_APPROVED } from '../../containers/Reviews/assignment-statuses';
import { CLAIMANT_DETAIL_NAV, TEMPLATE_NAV } from 'layout/navigate_const';
import './DropDown.scss';

export default function DropDown({ beforeNext = () => {}, loading = false, disabled = false, goBack }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [, , , report_id, reportType, step] = history.location.pathname.split('/');
  const [dialogOpened, setDialogOpened] = useState(false);
  const { roles } = useSelector(state => state.auth);
  const { assignment } = useSelector(({ review }) => review);
  const { reportInfo, isLoading } = useSelector(state => state.report);
  const [dirty, setDirty] = useState(false);
  const [pending, setPending] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    let listItems = [];
    const isClientAndReduction = isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT) && reportType === REPORT_MSA_REDUCTION;
    listItems = isClientAndReduction ? REPORT_SECTIONS_DEFAULT_CLIENT[reportType] : REPORT_SECTIONS_DEFAULT[reportType];
    listItems = listItems.filter(
      item => !(item === REPORT_SECTION_DOCUMENTUPLOAD && isAllowed(roles, ROLES_DIVISIONS.QA_ONLY))
    );

    setSections(
      reportInfo.sections?.map((section, id) => {
        return {
          id,
          section,
        };
      }) || listItems.map((section, id) => ({ id, section }))
    );
    // eslint-disable-next-line
  }, [reportInfo.sections, reportType]);

  const isSuperAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
  const isManager = isAllowed(roles, ROLES_DIVISIONS.SUPERMANGER);
  const isClient = isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT);
  const isNurseWriter = isAllowed(roles, ROLES_DIVISIONS.NURSE_ONLY);

  const isEditOrder = isSuperAdmin || isNurseWriter;

  const itemRenderer = item => (
    <div className={`dropdown-item-wrap ${item.section === step ? 'dropdown-active' : ''}`}>
      <div
        className="dropdown-item"
        onClick={() => {
          if (beforeNext) {
            beforeNext();
          }
          switchStep({ report_id, reportType, step: item.section, history });
        }}
        key={item.section}>
        {REPORT_SECTION_NAMES[item.section]}
      </div>
      {isEditOrder && <Icon className="dropdown-icon" type="menu" />}
    </div>
  );

  const onComplete = async () => {
    const result = await updateAssignmentById(assignment.id, {
      status: ASSIGNMENT_STATUS_APPROVED,
    })(dispatch);
    setDialogOpened(false);
    if (!result) return;
    history.push('/app/reviews');
    notification['success']({ message: 'Report approved' });
    updateReport(4);
  };

  const onItemMoved = items => {
    if (!isEditOrder) return;
    setSections(items);
    setDirty(true);
  };

  const onVisibleChange = async isVisible => {
    if (!isVisible && dirty) {
      setPending(true);
      try {
        await updateReport({ id: report_id, sections: sections.map(item => item.section) });
        dispatch(updateReportInfo({ sections: sections.map(item => item.section) }));
        setDirty(false);
        notifySuccess('Report step order updated');
      } catch (e) {
        notifyApiError(e);
      } finally {
        setPending(false);
      }
    }
  };

  return (
    <div className="dropdown-wrapper">
      <Spin spinning={pending || isLoading || loading}>
        <Dropdown
          disabled={disabled}
          overlay={
            <Menu className="dropdown-inner">
              <RLDD
                cssClasses={`list-content ${
                  reportInfo.report_type_id === REPORT_MSA_TYPE_ID ? 'list_conent_section_report_msa' : ''
                }`}
                items={sections}
                itemRenderer={itemRenderer}
                onChange={onItemMoved}
              />
              {dirty && (
                <Alert
                  className="info-box"
                  message="Click outside of menu to close and update step order"
                  type="info"
                  showIcon
                />
              )}
            </Menu>
          }
          onVisibleChange={onVisibleChange}
          trigger={['click']}
          placement="bottomRight">
          <div className={`ant-dropdown-link ${disabled && 'disabled'}`} onClick={e => e.preventDefault()}>
            <span className="active-step">Step {sections.findIndex(item => item.section === step) + 1}</span>
            <DownOutlined />
            <span className="dropdown-steps">of {sections.length}</span>
          </div>
        </Dropdown>

        <CloseOutlined
          id="btnCloseReport"
          onClick={() => {
            if (goBack) return goBack();

            if (reportInfo.is_template) return history.push(TEMPLATE_NAV.path);

            if (isManager || (isClient && reportInfo.report_type_id === REPORT_MSA_REDUCTION_TYPE_ID)) {
              history.push(CLAIMANT_DETAIL_NAV.path, { data: reportInfo.claimant });
            } else {
              history.push('/app/reviews');
            }
            dispatch(clearReportFiles());
          }}
          style={{ paddingLeft: 20 }}
        />
      </Spin>
      <Modal
        title="Complete report?"
        visible={dialogOpened}
        onOk={onComplete}
        confirmLoading={isLoading}
        onCancel={() => setDialogOpened(false)}>
        <Text> You will not be able to make changes after confirmation</Text>
      </Modal>
    </div>
  );
}
