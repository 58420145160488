import React, { Component } from 'react';
import { Row, Typography, Input } from 'antd';

import './TextAreaBox.scss';

const { Text } = Typography;
const { TextArea } = Input;

export default class InputBox extends Component {
  render() {
    const { label, value, disabled, rows, placeholder, onChange, ...rest } = this.props;

    return (
      <Row>
        <Row className="label">
          <Text>{label}</Text>
        </Row>
        <Row>
          <TextArea
            id="fixspell"
            placeholder={placeholder || 'Required'}
            disabled={disabled || false}
            className="input"
            rows={rows || 3}
            value={value}
            onChange={e => onChange(e.target.value, e.target.name)}
            {...rest}
          />
        </Row>
      </Row>
    );
  }
}
