import React, { useState, useEffect } from 'react';
import * as API from 'utils/api';

// Components
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { Typography } from 'antd';
import UploadLogo from 'components/UploadLogo/UploadLogo';

// Style
import './reportLogo.scss';

const { Text } = Typography;

const ReportLogo = ({ urlPublic, idReport, disabledField }) => {
  const [clientlogo, setClientlogo] = useState(urlPublic);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setClientlogo(urlPublic);
  }, [urlPublic]);

  const handleUploadFile = async (client_logo, s3key) => {
    try {
      setLoading(true);
      setClientlogo(client_logo);
  
      const body = {
        logo: s3key,
        id: idReport,
      };
  
      await API.updateReportNurse(body);
    }catch (e) {
      notifyApiError(e)
    }finally {
      setLoading(false);
    }
  }

  return (
      <div className='reportLogo'>
        {loading && <ModalLoading />}
        <UploadLogo
          changeImage={handleUploadFile}
          client_logo={clientlogo}
          disabledField={disabledField}
        />
        <Text>Logo</Text>
      </div>
    

  );
};

export default ReportLogo;
