import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Select, Icon, Divider } from 'antd';
import InlineContact from 'components/InlineContact';
import InputBox from 'components/InputBox';
import TagsInputBox from 'components/TagsInputBox';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import TemplateFields from 'components/TemplateFields';
import dynamicData from 'dynamicData';
import ClaimantInfo from 'components/ClaimantInfoOnWizard';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';
import DropDown from 'components/Dropdown';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import { EMPTY_TEXT } from 'constants/shortcodes';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { CLAIMANT_EDIT, MAIN_NAV } from 'layout/navigate_const';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { REPORT_MCP_NAME } from 'constants/reports';
import './MedicalContent.scss';
import PrevNextStep from '../../../../components/PrevNextStep';
import { LifeSourceAndLifeTable } from 'containers/Reports/components/LifeSourceAndLifeTable';
import { LIFE_TYPE } from 'containers/Reports/components/LifeSourceAndLifeTable/LifeSourceAndLifeTable';
import { matchIdToElement, matchSourceToItem } from 'containers/Reports/utils/helpers';

const { Title, Text } = Typography;
const { Option } = Select;

class MedicalContent extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      introduction: '',
      medical_records_reviewed: '',
      description_of_injury: '',
      medical_summary: '',
      missing_medical_records: '',
      related_diagnosis: '',
      pre_existing_conditions: '',
      surgeries_procedures: '',
      treating_providers: undefined,
      current_treatment_plan: '',
      life_expectancy: '',
      projected_treatment_plan: '',
      social_security_disability_status: '',
      medicare_status: '',
      medicare_lien_status: '',
      medicare_allocation_administration: '',
      contactsList: [],
      lifeSources: [],
      lifeTables: [],
      loading: false,
      medical_status: '',
      rated_ages_text: '',
      report_contacts: [],
      prognosis: '',
      references: '',
      treatingList: [],
      additional_comments: '',
      report_claim_id: '',
      REPORT_PERMISSIONS,
      life_table_id: 1,
    };

    this.handleChangeLife = this.handleChangeLife.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });

    this.getContactLists();

    API.getReportContacts(report_id).then(res => {
      this.setState({ report_contacts: res.report_contacts.rows });
    });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);

        API.getLifeSources().then(res => {
          let id = matchSourceToItem(reportInfo.life_expectancy_source, res.life_expects);
          if (!id) {
            this.setState({
              lifeSources: res.life_expects,
              source_id: res.life_expects[0].id,
            });
          } else {
            this.setState({
              lifeSources: res.life_expects,
              source_id: id,
            });
          }
        });
        API.getLifeTables().then(res => {
          let id = matchSourceToItem(reportInfo.life_expectancy_source, res.life_expects);
          if (!id) {
            this.setState({
              lifeTables: res.life_expects,
              life_table_id: res.life_expects[0].id,
            });
          } else {
            this.setState({
              lifeTables: res.life_expects,
              life_table_id: id,
            });
          }
        });
        const defaultIntroduction = `The file of ${reportInfo?.claimant?.claimant_name} 
          ${reportInfo?.claimant?.claimant_last_name} was referred by _____________________________________ for analysis of 
          relevant information and preparation of Medical Cost Projection. This Medical Cost Projection was 
          prepared from a review of the medical records provided and research regarding Mr/Ms. 
          ${reportInfo?.claimant?.claimant_last_name} diagnosis, medical treatment and prognosis. 
          The proposed Medical Cost Projection addresses Mr/Ms. ${reportInfo?.claimant?.claimant_last_name} 
          current medical status and his/her future medical and rehabilitation requirements.`;
        const currentIntroduction = reportInfo.introduction
          ? reportInfo.introduction
          : reportInfo.introduction === EMPTY_TEXT
          ? ''
          : defaultIntroduction;
        this.setState({
          is_template: reportInfo.is_template,
          report_template_id: reportInfo.report_template_id,
          introduction: currentIntroduction,
          medical_records_reviewed: reportInfo.medical_records_reviewed,
          description_of_injury: reportInfo.description_of_injury,
          medical_summary: reportInfo.medical_summary,
          missing_medical_records: reportInfo.missing_medical_records,
          related_diagnosis: reportInfo.related_diagnosis,
          pre_existing_conditions: reportInfo.pre_existing_conditions,
          surgeries_procedures: reportInfo.surgeries_procedures,
          treating_providers: reportInfo.treating_providers || undefined,
          current_treatment_plan: reportInfo.current_treatment_plan,
          projected_treatment_plan: reportInfo.projected_treatment_plan,
          social_security_disability_status: reportInfo.social_security_disability_status,
          medicare_status: reportInfo.medicare_status,
          medicare_lien_status: reportInfo.medicare_lien_status,
          medicare_allocation_administration: reportInfo.medicare_allocation_administration,
          rated_ages_text: reportInfo.rated_ages_text,
          medical_status: reportInfo.medical_status,
          report_claim_id: reportInfo.report_claim_id || '',

          claimant_id: reportInfo.claimant_id,
          loading: false,
          prognosis: reportInfo.prognosis,
          references: reportInfo.references,
          additional_comments: reportInfo.additional_comments,
          life_table_id: reportInfo?.life_table_id,
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  getContactLists = () => {
    API.getContactsData({ pageSize: 1000 }) //Getting all contacts data
      .then(res => {
        const dynamic = dynamicData();
        this.setState({
          contactsList: res.contacts,
          treatingList: res.contacts.filter(
            item => (item.contact_type ? item.contact_type.name : '') === dynamic.treating_providers
          ),
        });
      });
  };

  getReportContacts = async () => {
    const { report_id } = this.state;
    let res = await API.getReportContacts(report_id);
    this.setState({ report_contacts: res.report_contacts.rows });
  };

  componentDidUpdate(prevProps, prevState) {
    let { reportInfo } = this.props;
    reportInfo = { ...reportInfo };
    const { lifeSources, lifeTables, life_expectancy } = this.state;

    if (reportInfo.claimant_id && lifeSources.length > 0 && lifeTables.length > 0 && !life_expectancy) {
      const param = {
        source_id: lifeSources[0].id,
        life_table_id: lifeTables[0].id,
      };
      API.getLifeExpect(reportInfo.claimant_id, param)
        .then(res => {
          this.setState({
            life_expectancy: Math.round(res.years_left),
          });
        })
        .catch(err =>
          this.setState({
            life_expectancy: '',
          })
        );
    }
  }

  handleUpdateReport = async (isNextStep = false) => {
    const {
      life_table_id,
      report_id,
      introduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      treating_providers,
      current_treatment_plan,
      life_expectancy,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      rated_ages_text,
      medical_status,
      prognosis,
      references,
      additional_comments,
    } = this.state;

    let yrly_src = matchIdToElement(this.state.lifeSources, this.state.source_id);
    let lyfe_table = matchIdToElement(this.state.lifeTables, life_table_id);

    let life_expectancy_source = yrly_src?.name + '.' + lyfe_table?.name + ' ';
    const currentIntroduction = introduction === '' ? EMPTY_TEXT : introduction;
    const params = {
      life_expectancy_source,
      prognosis,
      references,
      id: report_id,
      introduction: currentIntroduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      treating_providers,
      current_treatment_plan,
      life_expectancy,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      rated_ages_text,
      medical_status,
      additional_comments,
      life_table_id,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleUpdateTextArea = (type, value) => {
    var duration = 1000;
    this.setState({
      [type]: value,
    });
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(this.handleUpdateReport, duration);
  };

  getLifeExpectancy = () => {
    const param = {
      source_id: this.state.source_id,
      life_table_id: this.state.life_table_id,
    };

    API.getLifeExpect(this.state.claimant_id, param)
      .then(res => {
        this.setState({
          life_expectancy: Math.round(res.years_left),
        });
      })
      .catch(err =>
        this.setState({
          life_expectancy: '',
        })
      );
  };

  goToEditClaimant = () => {
    const claimant = this.props.reportInfo.claimant;
    this.props.history.push(CLAIMANT_EDIT.path, { data: claimant });
  };

  handleChangeLife = ({ life, value }) => {
    if (life === LIFE_TYPE.SOURCE) {
      return this.setState({ source_id: value }, () => {
        this.getLifeExpectancy();
      });
    }

    this.setState({ life_table_id: value }, () => {
      this.getLifeExpectancy();
    });
  };

  render() {
    const {
      is_template,
      introduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      pre_existing_conditions,
      surgeries_procedures,
      projected_treatment_plan,
      life_expectancy,
      report_claim_id,
      source_id,
      life_table_id,
      lifeSources,
      lifeTables,
      loading,
      loadingTitle,
      medical_status,
      prognosis,
      treatingList,
      additional_comments,
      REPORT_PERMISSIONS,
    } = this.state;

    const dataDynamic = { ...dynamicData() };
    const currentIntroduction = introduction === EMPTY_TEXT ? '' : introduction;

    return (
      <Row className="msa-medical-content-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MCP_NAME} {is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown beforeNext={async () => await this.handleUpdateReport(false)} loading={loading} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <ClaimantInfo
                    goToEditClaimant={REPORT_PERMISSIONS.edit && this.goToEditClaimant}
                    claimant={{
                      ...this.props.reportInfo.claimant,
                      report_claim_id,
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Medical Content</Text>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Introduction"
                    placeholder="Please enter"
                    value={currentIntroduction}
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('introduction', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Records Reviewed"
                    placeholder="Please enter"
                    value={medical_records_reviewed}
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('medical_records_reviewed', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Description of Injury"
                    placeholder="Please enter"
                    value={description_of_injury}
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('description_of_injury', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col sm={24}>
                  <RichTextEditor
                    onChange={value => this.handleUpdateTextArea('medical_summary', value)}
                    value={medical_summary}
                    label="Medical Summary"
                    disabled={!REPORT_PERMISSIONS.edit}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <TagsInputBox
                    label="Missing Medical Records"
                    placeholder="Please enter"
                    value={missing_medical_records}
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('missing_medical_records', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <TagsInputBox
                    label="Pre-existing Conditions"
                    placeholder="Please enter"
                    value={pre_existing_conditions}
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('pre_existing_conditions', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Surgeries/Procedures related to injury"
                    placeholder="Please enter"
                    value={surgeries_procedures}
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('surgeries_procedures', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    getContactLists={this.getContactLists}
                    contact_type_id={dataDynamic.treating_providers_id}
                    another={true}
                    label="Treating Providers"
                    report_id={this.state.report_id}
                    contactsList={treatingList}
                    disabled={!REPORT_PERMISSIONS.edit}
                    type={'treating_providers'}
                    value={this.state.report_contacts
                      .filter(item => item.type === 'treating_providers' && item.contact)
                      .map(item => item.contact.id)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Current Medical Status"
                    placeholder="Please enter"
                    value={medical_status}
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('medical_status', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col sm={24}>
                  <RichTextEditor
                    disabled={!REPORT_PERMISSIONS.edit}
                    onChange={value => this.handleUpdateTextArea('projected_treatment_plan', value)}
                    value={projected_treatment_plan}
                    label="Projected Future Medical Care"
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    disabled={!REPORT_PERMISSIONS.edit}
                    label="Prognosis"
                    placeholder="Please enter"
                    value={prognosis}
                    onChange={value => this.handleUpdateTextArea('prognosis', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Comments"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={additional_comments}
                    onChange={value => this.handleUpdateTextArea('additional_comments', value)}
                  />
                </Col>
              </Row>

              <LifeSourceAndLifeTable
                source_id={source_id}
                lifeSources={lifeSources}
                life_table_id={life_table_id}
                lifeTables={lifeTables}
                onChange={this.handleChangeLife}
              />

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <InputBox label="Life Expectancy" disabled={true} placeholder=" " value={life_expectancy} />
                </Col>
              </Row>
              {(is_template || this.state.report_template_id) && (
                <TemplateFields
                  is_template={is_template}
                  section_id={21}
                  report_template_id={this.state.report_template_id}
                  report_id={this.state.report_id}
                />
              )}
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(MedicalContent);
