import { Button, Icon, Modal, Row, Select, Tooltip, Typography } from 'antd';
import React, { Component } from 'react';
import * as API from 'utils/api';
import Add from '../../containers/Contacts/AddContact/index';
import EditContact from '../../containers/Contacts/EditContact/index';
import { EditIcon } from 'components/Icons';
import './index.scss';
const { Option } = Select;
const { Text } = Typography;
export default class InputBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      isModalOpen: false,
      extraContacts: [],
      oldValue: {
        id: '',
        contact_type_id: undefined,
      },
      placeholder: props.placeholder || 'Please select',
    };
  }
  handleRemoveContact = async contact_id => {
    await API.removeReportContact(this.props.report_id, contact_id, this.props.type).then(res => {});
  };
  handleCloseModal = async newData => {
    if (newData?.contact_id) {
      this.props.getContactLists && (await this.props.getContactLists());
      this.props.getReportContacts && (await this.props.getReportContacts());
    }
    this.setState({ isModalOpen: false });
  };
  handleAddContact = async (contact_id, custom = true, contactRes) => {
    const { value } = this.state;
    let extra = false;
    if (contactRes !== undefined) {
      extra = true;
      //Remove using extra contact array. Add new contact to select directly and set value. Refresh data from Backend
      // extraContacts.push(contactRes);
    }
    await API.addReportContact(this.props.report_id, contact_id, this.props.type);
    this.props.getContactLists && (await this.props.getContactLists());
    this.props.getReportContacts && (await this.props.getReportContacts());
    if (extra) {
      this.setState({ value: [...value, contactRes.id] });
      // this.setState({ extraContacts });
    }
    await this.handleCloseModal();
  };
  componentWillReceiveProps = nextProps => {
    this.setState({ value: nextProps.value });
  };
  //it can be modal with Edit contact flow. in type will be Contact object
  handleOpenModal = type => () => {
    this.setState({
      oldValue: {
        id: type.id,
        contact_type_id: type.contact_type_id,
      },
    });
    this.setState({ isModalOpen: type });
  };
  render() {
    const { isModalOpen, placeholder } = this.state;
    const { onChange = null, disabledAdd = false, className = '', disabled, idCypress = '' } = this.props;
    return (
      <>
        <Row className="label">
          <Text>{this.props.label}</Text>
        </Row>
        <Row>
          <div className="contact-select">
            <Select
              onDeselect={this.handleRemoveContact}
              onSelect={this.handleAddContact}
              showSearch
              placeholder={placeholder}
              mode={'multiple'}
              id={idCypress}
              optionFilterProp="filter"
              disabled={disabled}
              className={`dropdown ${className && className}`}
              value={this.state.value}
              onChange={(value, option) => {
                if (onChange) {
                  onChange(value);
                } else {
                  this.setState({ value: value });
                }
              }}
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              {this.props.contactsList.map((item, index) => {
                return (
                  <Option
                    filter={item.id
                      .toString()
                      .concat('_' + item.organisation_name)
                      .concat('_' + item.contact_name)
                      .concat('_' + item.email)}
                    title=""
                    value={item.id}
                    id={`${idCypress}_option_${index}`}
                    key={`contact-${item.id}`}
                    className="contact-option">
                    <Tooltip
                      overlayStyle={{ maxWidth: 300 }}
                      placement="right"
                      title={
                        <>
                          <div style={{ fontSize: 16 }}>{`${item.organisation_name} (${item.contact_name})`}</div>
                          {item.address.length !== 0 ? <div>{`Address: ${item.address}`}</div> : null}
                          {item.email.length !== 0 ? <div>{`E-mail: ${item.email}`}</div> : null}
                          {item.phone_number.length !== 0 ? <div>{`Phone Number: ${item.phone_number}`}</div> : null}
                        </>
                      }>
                      <EditIcon style={{ height: 16 }} onClick={this.handleOpenModal(item)} />{' '}
                      {`${item.organisation_name} (${item.contact_name})`}
                    </Tooltip>
                  </Option>
                );
              })}
              {this.state.extraContacts.map(item => {
                return (
                  <Option
                    title=""
                    value={item.id}
                    key={`extra-${item.id}`}
                    filter={item.id
                      .toString()
                      .concat('_' + item.organisation_name)
                      .concat('_' + item.contact_name)
                      .concat('_' + item.email)}>
                    <EditIcon
                      style={{ height: 16 }}
                      onClick={this.handleOpenModal({
                        ...item,
                        extra: item.id, //store item.id from extra array(it's not in props from parent)
                      })}
                    />{' '}
                    {item.organisation_name}
                  </Option>
                );
              })}
            </Select>
            {!disabledAdd && (
              <Button
                disabled={disabled}
                type="primary"
                className="green-btn add-icd-code_button"
                onClick={this.handleOpenModal('add')}>
                Add Custom Contact
              </Button>
            )}
          </div>
        </Row>
        <Modal
          visible={!!isModalOpen}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={true}
          width={800}
          className="add-icd-code-modal"
          title={isModalOpen === 'add' ? 'Add Custom Contact' : 'Edit Contact'}
          destroyOnClose>
          {isModalOpen === 'add' ? (
            <Add
              disableTypeEditing
              contact_type_id={this.props.contact_type_id}
              another={this.props.another}
              removeHeader
              func={res => {
                this.handleAddContact(res.id, true, res);
              }}
            />
          ) : (
            <EditContact data={isModalOpen} onClose={this.handleCloseModal} removeHeader disableTypeEditing />
          )}
        </Modal>
      </>
    );
  }
}
