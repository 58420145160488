import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as API from 'utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { updateReportInfo } from 'redux/report';

// Components
import { Spin, Row, Col, Typography, Button } from 'antd';
import { notifyApiError, notifySuccess } from 'utils/notification';
import { TableFileBoxDragAndDrop } from 'components/BoxDragAndDrop';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Const
import {
  REPORT_LIFE_CARE_PLAN_NAME,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  REPORT_SECTIONS_DEFAULT,
  REPORT_TYPES_BY_ID,
} from 'constants/reports';
import { CloseIcon } from 'components/Svg';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

// Routes
import { TEMPLATE_CREATE_TABLE_NAV, TEMPLATE_EDIT_TABLE_NAV, TEMPLATE_NAV } from 'layout/navigate_const';
import { ChangeAddressAndPhoneReport } from 'components/ChangeAddressAndPhoneReport';
import { ChangeReportLogoAndColor } from 'components/ChangeReportLogoAndColor';
import { assignedTemplateInReportLCP } from 'containers/Reports/utils/assignedTemplateInReportLCP';
import { goFirstStep } from 'utils/report-routing';
import { useReport } from 'hooks';

const { Title } = Typography;

const TemplateLifeCarePlanColor = () => {
  const { idReport } = useParams();
  const history = useHistory();
  const location = useLocation();
  const isEdit = location.pathname.includes('edit');

  const { getFieldsForUpdateReportLCP } = useReport();

  // Redux
  const { resourceLinksFiles } = useSelector(state => state.report);
  const dispatch = useDispatch();

  // States
  const [loading, setLoading] = useState(false);
  const [resourcesLink, setResourcesLink] = useState([]);
  const [urlPublic, setUrlPublic] = useState(''); // logo
  const [color, setColor] = useState('#000000');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [templateInfo, setTemplateInfo] = useState(null);

  useEffect(() => {
    getReportInfo();
  }, [resourceLinksFiles]);

  const getReportInfo = async () => {
    try {
      setLoading(true);
      const results = await Promise.allSettled([
        API.getReportById(idReport, true),
        API.getResourceLinksByReport(idReport),
      ]);

      let extension = '';
      let file = null;

      if (results[0].value?.logo) {
        extension = results[0].value.logo.split('avatars/')[1].split('?')[0].split('.')[1];
        const logoFile = await fetch(results[0].value.logo);
        const contentType = logoFile.headers.get('Content-Type');
        const type = contentType;
        const logoBlob = await logoFile.blob();
        file = new File(
          [logoBlob],
          results[0].value?.logo_name ? `${results[0].value.logo_name}.${extension}` : `logo.${extension}`,
          {
            type,
          }
        );

        file.url = results[0].value.logo;
      }

      const filesResource = (results[1]?.value?.report_resource_links || []).map(item => {
        return {
          ...item.resource_link.file,
          idResource: item.id,
        };
      });

      const listFiles = [];

      if (filesResource.length) listFiles.push(...filesResource);

      // redux
      dispatch(updateReportInfo(results[0].value));
      setResourcesLink(listFiles);
      setUrlPublic(results[0].value.logo);
      setColor(results[0].value.color);
      setPhone(results[0].value.phone);
      setAddress(results[0].value.address);
      setTemplateInfo(results[0].value);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  // Handlers
  const deleteLogo = async () => {
    try {
      setLoading(true);
      const body = {
        logo_name: '',
        logo: '',
        id: idReport,
      };

      await API.updateReportNurse(body);
      await getReportInfo();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const removeFiles = async ({ file }) => {
    // es el logo
    if (!file.idResource) return deleteLogo();

    try {
      // es un archivo de resource links
      setLoading(true);

      await API.deleteReportResourceLinks(file.idResource);

      await getReportInfo();
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadLogo = async ({ file }) => {
    try {
      setLoading(true);
      await API.downloadDocumentMarketing({ url: file.url, nameDocument: file.name });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    // si este campo no llega es porque el template no se creo desde un reporte
    if (!templateInfo?.from_report) {
      notifySuccess('The template was created successfully');
      return history.push(TEMPLATE_NAV.path);
    }

    // flujo cuando un template se crea desde un reporte
    try {
      setLoading(true);

      // obtenemos la informacion actualizada del template
      const getTemplateInfo = await API.getReportById(idReport, true);

      // obtenemos la url del logo
      const regex = /\/([a-f\d-]+\/avatars\/[^?]+)/i;
      const match = (getTemplateInfo?.logo && getTemplateInfo.logo.match(regex)) || '';
      let image = '';

      if (match) image = match[1];

      // revisamos si el reporte ya tenia una estructura
      const reportStructure = await API.getReportLifeCarePlan({ idReport: getTemplateInfo.from_report });

      if (
        Array.isArray(reportStructure?.reportStructure?.report_structure) &&
        reportStructure.reportStructure.report_structure.length > 0
      ) {
        // si el reporte tiene una estructura se la eliminamos para asignarle la nueva
        await API.deleteStructureReportLCP({ report_id: getTemplateInfo.from_report });
      }

      // asignamos el template al reporte de donde viene este template
      await assignedTemplateInReportLCP({
        chooseTemplateId: idReport,
        reportId: getTemplateInfo.from_report,
        claimant_id: null,
      });

      // actualizamos el reporte con la info del template
      let paramsUpdateReport = {
        address: getTemplateInfo.address,
        logo: image,
        phone: getTemplateInfo.phone,
        color: getTemplateInfo.color,
        id: getTemplateInfo.from_report,
        report_name: getTemplateInfo.report_name,
      };

      // agarramos los placeholder del template y se los pasamos como value al reporte
      paramsUpdateReport = await getFieldsForUpdateReportLCP({ idTemplateLCP: idReport, params: paramsUpdateReport });

      await API.updateReportNurse(paramsUpdateReport);

      // actualizamos el template para que no vuelva hacer eso
      await API.updateReportNurse({
        id: Number(idReport),
        from_report: null,
      });

      // vamos hacia el reporte
      goFirstStep({
        sections: REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[REPORT_LIFE_CARE_PLAN_TYPE_ID]],
        reportType: REPORT_TYPES_BY_ID[REPORT_LIFE_CARE_PLAN_TYPE_ID],
        report_id: getTemplateInfo.from_report,
        history,
      });
    } catch (error) {
      notifyApiError(error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ModalLoading />}
      <Spin spinning={false}>
        <Row className="clients-container">
          <Row type="flex" align="middle" className="header templateLifeCarePlan_header">
            <Col className="title">
              <Title level={4} className="title-text">
                {REPORT_LIFE_CARE_PLAN_NAME}
              </Title>
            </Col>
            <Col className="closeTemplate" onClick={() => history.push(TEMPLATE_NAV.path)}>
              <CloseIcon color={PRIMARY_LIGHT_BLUE} /> Close
            </Col>
            <Col className="pagination add-button"></Col>
          </Row>

          <div className="table-content templateLifeCarePlanTable">
            <ChangeAddressAndPhoneReport reportId={idReport} phone={phone} address={address} />
            <ChangeReportLogoAndColor urlPublic={urlPublic} idReport={idReport} color={color} />

            {resourcesLink.length > 0 && (
              <div className="templateLifeCarePlanTable_references">
                <Title level={4} className="title-text">
                  References
                </Title>
                <TableFileBoxDragAndDrop
                  listFiles={resourcesLink}
                  removeFile={removeFiles}
                  download={handleDownloadLogo}
                />
              </div>
            )}

            <div className="templateLifeCarePlanTable_endBtn">
              <Button
                type="primary"
                size="default"
                className="transparent-btn button"
                onClick={() =>
                  history.push(
                    isEdit
                      ? `${TEMPLATE_EDIT_TABLE_NAV.path}/${idReport}`
                      : `${TEMPLATE_CREATE_TABLE_NAV.path}/${idReport}`
                  )
                }>
                PREVIOUS STEP
              </Button>

              <Button type="primary" size="default" className="purple-btn button" onClick={handleSave}>
                Save and Close
              </Button>
            </div>
          </div>
        </Row>
      </Spin>
    </>
  );
};

export default TemplateLifeCarePlanColor;
