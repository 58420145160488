import React, { useState, useEffect } from 'react';
import { Col, Row, Typography, Checkbox, Divider, Icon, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as API from '../../utils/api';
import { notifyApiError } from 'utils/notification';
import { getReportResourceLinks } from 'redux/report';
import ResourceLinkIcons from 'constants/resource-links-icons';

import './ResourceLinks.scss';

export default function ResourceLinks({ reportInfo, toggleOpen }) {
  const { Text } = Typography;
  const [links, setLinks] = useState([]);
  const [selectedLinks, setSelectedLinks] = useState([]);
  const { roles } = useSelector(state => state.auth);
  const { userInfo } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const report_id = reportInfo.id;

  useEffect(() => {
    const loadResourceLinks = async () => {
      try {
        const tenant_only = true;

        const { resource_links } = await API.GetResourceLink(tenant_only);
        const { report_resource_links } = await API.getResourceLinksByReport(report_id);

        setLinks(resource_links);
        setSelectedLinks(report_resource_links);
      } catch (e) {
        notifyApiError(e);
      }
    };

    loadResourceLinks();
  }, [report_id, roles, toggleOpen]);

  const getIsBydefault = id => {
    let item = selectedLinks.find(link => link.resource_link_id === id);

    return item !== undefined;
  };

  const onDeselect = id => {
    let item = selectedLinks.find(link => link.resource_link_id === id);

    API.deleteReportResourceLinks(item.id)
      .then(() => {
        setSelectedLinks(prev => prev.filter(link => link.id !== item.id));
        dispatch(getReportResourceLinks(report_id));
      })
      .catch(err => notifyApiError(err));
  };

  const onSelect = link => {
    API.addReportResourceLinks(report_id, link.id)
      .then(({ report_resource_link }) => {
        setSelectedLinks(prev => [...prev, report_resource_link]);
        dispatch(getReportResourceLinks(report_id));
      })
      .catch(err => notifyApiError(err));
  };

  const isReportBelognsToUser = userInfo?.attributes['custom:tenant_id'] === reportInfo?.tenant_id;

  return (
    <Row>
      <Col md={16}>
        <Text>Resource Links</Text>
      </Col>
      <Col md={8}>
        <Text>Include in Report</Text>
      </Col>
      <Divider style={{ margin: '10px 0px' }} />
      {links.map(link => (
        <Row key={link.id}>
          <Col md={16}>
            <b>{link.name}</b>
            <Row>
              <a href={link.link.includes('https://') ? link.link : 'https://' + link.link} target="_blank">
                {link.link}
              </a>
            </Row>
            {link.file && (
              <Row className="file-name" onClick={() => window.open(link.file.url, '_blank')}>
                <Icon type={ResourceLinkIcons[link.file.type]} />
                &nbsp;
                {link.file.name}
              </Row>
            )}
            <Row>{link.description}</Row>
          </Col>
          <Col md={8}>
            <Tooltip
              title={
                !isReportBelognsToUser &&
                'This report does not belong to your tenant, so you cannot attach resource link to it'
              }>
              <Checkbox
                disabled={!isReportBelognsToUser}
                onChange={e => {
                  e.target.checked ? onSelect(link) : onDeselect(link.id);
                }}
                checked={getIsBydefault(link.id)}
              />
            </Tooltip>
          </Col>
          <Divider style={{ margin: '10px 0px' }} />
        </Row>
      ))}
    </Row>
  );
}
