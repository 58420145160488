import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Empty, Row, Typography } from 'antd';
import { DetailsIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import { notifyApiError, notifyError } from 'utils/notification';
import DateFilter from './components/DateFilter';
import ExportButton from './components/ExportButton';
import { pluralize } from 'utils/utils';
import { DownloadOutlined } from '@ant-design/icons';
import { goFirstStep } from '../../utils/report-routing';
import { REPORT_SECTIONS_DEFAULT, REPORT_TYPES_BY_ID } from '../../constants/reports';
import { connect } from 'react-redux';
import { CABINET_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';

import './ReportCabinet.scss';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { ModalUpgratePackageOpenReport } from 'components/Modal/ModalUpgratePackageOpenReport';

const { Text, Paragraph } = Typography;

function ReportCabinet({ search = '', onCancel = () => {}, roles }) {
  const history = useHistory();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [createdAt, setCreatedAt] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const friendlyKeys = {
    name: 'Report',
    report_id: 'Report ID',
    claimantName: 'Claimant',
    clientName: 'Client',
    link: 'Link',
  };
  const [openModalUpgradedPlan, setOpenModalUpgradedPlan] = useState(false);

  // Permissions
  const { CABINET_PERMISSIONS } = getPermissionsBySection({
    roles,
    permissions: [CABINET_PERMISSIONS_NAME],
  });
  // end permissions

  useEffect(() => {
    setLoading(true);
    if (createdAt === null && endDay === null) {
      API.ReportCabinet('', '')
        .then(res => {
          setReports(
            res.reports
              .map(rep => {
                rep.report_id = rep.report_uuid;
                rep.name = rep.report_name;
                rep.claimantName = `${rep['claimant.claimant_name']} ${rep['claimant.claimant_last_name']}`;
                rep.clientName = rep['claimant.client.client_name'];

                delete rep['claimant.claimant_name'];
                delete rep['claimant.claimant_last_name'];
                delete rep.report_uuid;
                delete rep.report_name;
                delete rep['claimant.client.client_name'];

                return rep;
              })
              .reverse()
          );
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          notifyApiError(e);
        });
    } else {
      API.ReportCabinet(createdAt, endDay)
        .then(res => {
          setReports(
            res.reports
              .map(rep => {
                rep.report_id = rep.report_uuid;
                rep.name = rep.report_name;
                rep.claimantName = `${rep['claimant.claimant_name']} ${rep['claimant.claimant_last_name']}`;
                rep.clientName = rep['claimant.client.client_name'];

                delete rep['claimant.claimant_name'];
                delete rep['claimant.claimant_last_name'];
                delete rep.report_uuid;
                delete rep.report_name;
                delete rep['claimant.client.client_name'];

                return rep;
              })
              .reverse()
          );
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          notifyApiError(e);
        });
    }
  }, [createdAt, endDay]);

  const openReportFiles = id => {
    API.getReportFiles(id).then(res => {
      if (res) {
        window.open(res, '_blank');
      } else {
        notifyError('Report is not generated yet!');
      }
    });
  };

  const goToReport = useCallback(
    async report => {
      try {
        setLoading(true);
        const validOpenRerpot = await validateOpenReportForPackage({
          tenant_id: roles.user_roles.tenant_id,
          report_type: report.report_type_id,
        });

        if (!validOpenRerpot.open)
          return setOpenModalUpgradedPlan({
            ...validOpenRerpot,
            open: true,
            tenant_id: roles.user_roles.tenant_id,
            customer: roles.user_roles.id,
          });

        goFirstStep({
          sections: report?.sections || REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[report.report_type_id]],
          reportType: REPORT_TYPES_BY_ID[report.report_type_id],
          report_id: report.id,
          history,
        });
        onCancel();
      } catch (e) {
        setLoading(false);
        notifyApiError(e);
      }
    },
    [history, onCancel]
  );

  useEffect(() => {
    if (search) {
      setSearchData(
        reports.filter(r => {
          const s = search.trim().toLowerCase();
          const claimantName = r['claimantName']?.toLowerCase();
          const clientName = r['clientName']?.toLowerCase();
          const reportName = r['name']?.toLowerCase();
          const id = String(r['report_id']);

          return reportName?.includes(s) || claimantName?.includes(s) || clientName?.includes(s) || id?.includes(s);
        })
      );
    } else {
      setSearchData(reports);
    }
  }, [search, reports]);

  return useMemo(
    () => (
      <>
        <DateFilter createdAt={createdAt} setCreatedAt={setCreatedAt} endDay={endDay} setEndDay={setEndDay} />{' '}
        <div className="meta">
          <Text>{(search || createdAt) && `Found ${pluralize('report', searchData.length)}`}</Text>
          <ExportButton friendlyKeys={friendlyKeys} reports={searchData} createdAt={createdAt}>
            Export Report List as CSV
          </ExportButton>
        </div>
        <Row type="flex" className="table-header">
          <Col xs={CABINET_PERMISSIONS.view ? 5 : 6}>Report ID</Col>
          <Col xs={CABINET_PERMISSIONS.view ? 5 : 6}>Report</Col>
          <Col xs={CABINET_PERMISSIONS.view ? 5 : 6}>Claimant</Col>
          <Col xs={CABINET_PERMISSIONS.view ? 5 : 6}>Client</Col>
          {CABINET_PERMISSIONS.view && (
            <Col xs={4} style={{ textAlign: 'right' }}>
              Actions
            </Col>
          )}
        </Row>
        <Row className={`table-content report-cabinet-table ${loading && 'table-loading'}`}>
          <SpinBox loading={loading}>
            {searchData.length ? (
              searchData.map(item => (
                <Row key={item.id} className="search-report-record">
                  <Col xs={CABINET_PERMISSIONS.view ? 5 : 6} className="user-column">
                    <Col>
                      <Text className="user-name">{item.report_id}</Text>
                    </Col>
                  </Col>
                  <Col xs={CABINET_PERMISSIONS.view ? 5 : 6} style={{ paddingRight: 15 }}>
                    <Paragraph style={{ marginBottom: 0 }} ellipsis>
                      {item.name}
                    </Paragraph>
                  </Col>
                  <Col xs={CABINET_PERMISSIONS.view ? 5 : 6}>
                    <Text className="wrapped-text">{item.claimantName}</Text>
                  </Col>
                  <Col xs={CABINET_PERMISSIONS.view ? 5 : 6}>
                    <Text className="wrapped-text">{item.clientName}</Text>
                  </Col>

                  {CABINET_PERMISSIONS.view && (
                    <Col
                      xs={4}
                      style={{
                        textAlign: 'right',
                        cursor: 'pointer',
                      }}>
                      <DetailsIcon onClick={() => goToReport(item)} />

                      {CABINET_PERMISSIONS.download && (
                        <DownloadOutlined
                          style={{ color: '#0082ff', fontSize: 18, marginLeft: 10 }}
                          className="icon"
                          onClick={() => {
                            openReportFiles(item.id);
                          }}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              ))
            ) : (
              <>{!loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />}</>
            )}
          </SpinBox>
        </Row>
        {openModalUpgradedPlan?.open && (
          <ModalUpgratePackageOpenReport data={openModalUpgradedPlan} onClose={() => setOpenModalUpgradedPlan({})} />
        )}
      </>
    ),
    [createdAt, search, searchData, loading, goToReport, openModalUpgradedPlan]
  );
}
const mapStateToProps = state => {
  const props = {
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {})(ReportCabinet);
