import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ReactHtmlParser from 'react-html-parser';
import * as API from 'utils/api';

import { Row, Col, Typography, Empty, Button, Skeleton, Modal, Divider, Collapse, Icon } from 'antd';
import { DetailsIcon } from 'components/Icons';
import { getHighlights, getReportAssignments, updateAssignmentById } from '../../../redux/report';
import PdfPage from '../../../components/PdfPage/PdfPageClass';
import { ASSIGNMENT_STATUS_PENDING_REVIEW, ASSIGNMENT_STATUS_RETURNED } from '../../Reviews/assignment-statuses';
import {
  ASSIGNMENT_TYPE_QA,
  ASSIGNMENT_TYPE_AR,
  ASSIGNMENT_TYPE_QA_INTERNAL,
} from '../../../constants/assignment-types';

import './assignments-tab.scss';

const customPanelStyle = {
  background: '#fEfEfE',
  marginBottom: 0,
  paddingTop: 0,
  border: 0,
  overflow: 'hidden',
};

const AssignmentsTab = props => {
  const { Text } = Typography;
  const { Panel } = Collapse;

  const dispatch = useDispatch();

  const { isLoading, assignments, reportInfo, highlights, highlightLogs } = useSelector(({ report }) => report);

  const [assignment, setAssignment] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);

  useEffect(() => {
    if (!reportInfo.id) return;
    getReportAssignments(reportInfo.id, reportInfo.claimant_id)(dispatch);
  }, [dispatch, reportInfo]);

  const handleClose = useCallback(() => {
    setAssignment(null);
    setModalOpened(false);
  }, []);

  const onOpenAssignment = assignment => async () => {
    let url = await API.getReportFiles(assignment.report_id);
    if (!url) {
      return alert('Report is not generated!');
    }
    setReportUrl(url);

    setAssignment(assignment);
    await getHighlights(assignment.id)(dispatch);
    setModalOpened(true);
  };

  const returnAssignmentToReview = async () => {
    const result = await updateAssignmentById(assignment.id, {
      status: ASSIGNMENT_STATUS_PENDING_REVIEW,
    })(dispatch);
    if (!result) return;
    setAssignment(null);
    setModalOpened(false);
    // notification['success']({message: 'Assignment status changed to "Pending for Review"'});
  };

  /*const onChange = (highlights) => {
    console.info(`Highlights`, highlights);
  }*/

  const ModalHeader = () => (
    <div className="rtab-modal-header">
      <div>Review</div>
      {assignment?.status?.key === ASSIGNMENT_STATUS_RETURNED ? (
        <Button type="primary" className="green-btn rtab-return-button" onClick={returnAssignmentToReview}>
          Return to Review
        </Button>
      ) : (
        <div />
      )}
    </div>
  );

  return (
    <>
      <div className="rtab-container">
        <ModalHeader />
        {!assignment ? (
          <div className="rtab-root">
            <Row type="flex" className="table-header">
              <Col span={8}>User</Col>
              <Col span={3} className="text-center">
                Type
              </Col>
              <Col span={6}>Date</Col>
              <Col span={6}>Status</Col>
              <Col span={1} />
            </Row>
            <Row className="table-content">
              {isLoading ? (
                <Skeleton loading={isLoading} active title={false} paragraph={{ rows: 10, width: '100%' }} />
              ) : assignments.length ? (
                assignments.map((item, index) => (
                  <>
                    <Row key={`assignment-${index}`} className="record" type="flex" align="middle">
                      <Col span={8} className="user-column">
                        <Text className="user-name">{`${item.user?.given_name} ${item.user?.family_name}`}</Text>
                      </Col>
                      <Col span={3} className="text-center">
                        <Text>{item.assignment_type?.name}</Text>
                      </Col>
                      <Col span={6}>
                        <Text className="wrapped-text">{dayjs(item.start_date).format('MMM DD, YYYY')}</Text>
                      </Col>
                      <Col span={6}>
                        <Text className="wrapped-text">{item.status?.name || 'N/A'}</Text>
                      </Col>
                      <Col span={1} className="actions text-right">
                        {[ASSIGNMENT_TYPE_QA, ASSIGNMENT_TYPE_AR, ASSIGNMENT_TYPE_QA_INTERNAL].includes(
                          item.assignment_type_id
                        ) &&
                          item.report?.report_files &&
                          Array.isArray(item.report.report_files) &&
                          item.report.report_files.length && (
                            <Button type="link" shape="circle" ghost onClick={onOpenAssignment(item)}>
                              <DetailsIcon className="icon" />
                            </Button>
                          )}
                      </Col>
                    </Row>
                    {!item?.note && <div className="spacer"></div>}
                    {item?.note && (
                      <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
                        <Panel header="Note:" key="1" style={customPanelStyle}>
                          <Text className="note-text">{ReactHtmlParser(item.note)}</Text>
                        </Panel>
                      </Collapse>
                    )}

                    <Divider />
                  </>
                ))
              ) : (
                <Empty
                  description={isLoading ? false : 'No Data'}
                  image={!isLoading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                  className="empty-icon"
                />
              )}
            </Row>
          </div>
        ) : (
          <div className="rtab-review-root">
            <PdfPage
              url={reportUrl}
              startHighlights={highlights}
              loading={isLoading}
              // onChange={onChange}
              disabled={assignment?.status?.key === ASSIGNMENT_STATUS_PENDING_REVIEW}
              isAdmin={false}
              shrink
              noEdit
            />
          </div>
        )}
      </div>
      <Modal
        style={{ top: 30, maxHeight: window.innerHeight - 60 }}
        visible={modalOpened}
        onCancel={handleClose}
        footer={null}
        closable={true}
        width={window.innerWidth - 100}
        className="add-icd-code-modal"
        title={<ModalHeader />}>
        {assignment && (
          <div className="ab-review-root">
            <PdfPage
              url={reportUrl}
              startHighlights={highlights}
              highlightLogs={highlightLogs}
              loading={isLoading}
              // onChange={onChange}
              disabled={assignment?.status?.key === ASSIGNMENT_STATUS_PENDING_REVIEW}
              isAdmin={false}
              shrink
              noEdit
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default AssignmentsTab;
