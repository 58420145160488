import React, { useState, useEffect } from "react";
import { Button, Col, Input, Modal, Row, Typography } from "antd";
import * as API from "utils/api";
import Spinbox from "components/SpinBox";
export default function UpdateTemplateFields({
  isOpen,
  onCancel,
  template,
  fetchAllTemplateFields,
}) {
  useEffect(() => {
    setUpdateTemplate(template);
  }, [template]);
  const [updateTemplate, setUpdateTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateTemplate({
      ...updateTemplate,
      [name]: value,
    });
  };
  const { Text } = Typography;
  const handleUpdate = () => {
    const { id, default_value, display_name } = updateTemplate;
    const data = {
      display_name,
      default_value,
    };
    setLoading(true);
    API.updateTemplateField(id, data).then((res) => {
      setLoading(false);
      fetchAllTemplateFields();
    });
  };
  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      centered={true}
      closable={false}
      width={600}
      className="add-template-modal"
      title={"Update template fields"}
    >
      <Row className="modal-body">
        <Spinbox loading={loading}>
          <Row type="flex" justify="start" align="middle" className="row">
            <Row className="template-label">
              <Text className="label">Display Name</Text>
            </Row>
            <Col className="input-col" md={24}>
              <Input
                placeholder={"Display Name"}
                name="display_name"
                className="input"
                value={updateTemplate && updateTemplate.display_name}
                onChange={handleOnChange}
              />
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="row">
            <Row className="template-label">
              <Text className="label">Default Value</Text>
            </Row>
            <Col className="input-col" md={24}>
              <Input
                placeholder={"Default Value"}
                className="input"
                name="default_value"
                value={updateTemplate && updateTemplate.default_value}
                onChange={handleOnChange}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" className="template-btn-wrapper">
            <Button
              type="primary"
              className="green-btn button"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Row>
        </Spinbox>
      </Row>
    </Modal>
  );
}
