import * as React from 'react';

const ArrowDownIcon = ({ color = 'black', onClick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" onClick={onClick}>
    <path
      fill={color}
      d="m216.49 104.49-80 80a12 12 0 0 1-17 0l-80-80a12 12 0 0 1 17-17L128 159l71.51-71.52a12 12 0 0 1 17 17Z"
    />
  </svg>
);

export default ArrowDownIcon;
