import moment from 'moment';

import {
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  DONT_CHANGE_COST_ON_THIS_TYPE,
  PRESCRIPTION_MEDICATIONS_COVERED,
  FUTURE_MEDICAL_NEEDED,
  DONT_CHANGE_COST_ON_THIS_TYPE_2,
  PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE,
} from 'components/MedicareCostModal/data';

export const totalByType = (medicareArray, typeArray) => {
  let total = 0.0;
  medicareArray.forEach(medicareItem => {
    if (typeArray.includes(medicareItem.type)) {
      total = total + medicareItem.total_cost;
    }
  });
  return total;
};

export const total_anticipated_medicare_cost = medicareArray => {
  let total = 0.0;
  medicareArray.forEach(medicareItem => {
    total = total + medicareItem.total_cost;
  });
  return total;
};

export const anticipated_non_medicare_cost = medicareArray => {
  let total = 0.0;
  medicareArray.forEach(medicareItem => {
    if (
      medicareItem.type === FUTURE_MEDICAL_NEEDED ||
      medicareItem.type === DONT_CHANGE_COST_ON_THIS_TYPE_2 ||
      medicareItem.type === PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE
    ) {
      total = total + medicareItem.total_cost;
    }
  });
  return total;
};

export const anticipated_medicare_cost = medicareArray => {
  let total = 0.0;
  medicareArray.forEach(medicareItem => {
    if (
      medicareItem.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE ||
      medicareItem.type === DONT_CHANGE_COST_ON_THIS_TYPE ||
      medicareItem.type === PRESCRIPTION_MEDICATIONS_COVERED
    ) {
      total = total + medicareItem.total_cost;
    }
  });

  return total;
};

export const first_surgery_cost = medicareArray => {
  let total = 0.0;

  medicareArray.forEach(medicareItem => {
    if (medicareItem.type === DONT_CHANGE_COST_ON_THIS_TYPE && medicareItem.is_seed_calculated) {
      total += medicareItem.per_unit_cost;
    }
  });

  return total;
};

export const seedMoney = (medicareArray, report) => {
  let first_surgery_ = first_surgery_cost(medicareArray);
  const step2 = first_surgery_;
  const step3 = anticipated_medicare_cost(medicareArray) - first_surgery_;

  const step4 = step3 / report.life_expectancy;

  const step5 = step4 * 2;
  const step6_seed_money = step2 + step5;
  const step7a_annuity_over_life_expectancy = anticipated_medicare_cost(medicareArray) - step6_seed_money;
  let step7b_annual_annuity = step7a_annuity_over_life_expectancy / (report.life_expectancy - 1);

  return {
    seed_money: step6_seed_money,
    annuity_amount_over_life: step7a_annuity_over_life_expectancy,
    annual_annuity: step7b_annual_annuity,
  };
};

export const annuity_payment_over_life = (medicareArray, report) => {
  return anticipated_medicare_cost(medicareArray) - seedMoney(medicareArray, report).seed_money;
};

export const lengthOfAnnuity = report => {
  const today = moment();
  const annuityStartDate = moment(report.annuity_start_date, 'YYYY-MM-DD');

  const yearDif = annuityStartDate.diff(today, 'years');
  let life_expectancy = report.life_expectancy;
  return life_expectancy - yearDif;
};

export const annuity_amount = (medicareArray, report) => {
  return annuity_payment_over_life(medicareArray, report) / lengthOfAnnuity(report);
};
