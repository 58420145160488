import { useState } from 'react';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';
import { getClientId } from 'layout/roles';
import { useSelector } from 'react-redux';
import { createClientProfileAuthorize } from 'utils/api-authorize';

export default function useCreditCard() {
  const [loadingCreditCard, setLoadingCreditCard] = useState(false);
  const [creditCardList, setCreditCardList] = useState([]);
  const [publicKey, setPublicKey] = useState('');

  const { roles } = useSelector(state => state.auth);

  const getCustomerProfile = async () => {
    try {
      setLoadingCreditCard(true);

      const customerProfile = await API.getCustomerProfileByLawFirm({ idLawFirm: getClientId(roles) });

      return customerProfile;
    } catch (e) {
      return false;
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const createCustomerProfile = async ({
    firstName,
    lastName,
    address,
    city,
    state,
    zip,
    phone,
    client_id,
    email,
    tenant_id,
  }) => {
    try {
      setLoadingCreditCard(true);
      const clientProfileAuthorize = {
        firstName,
        lastName,
        address,
        city,
        state,
        zip,
        number: phone,
        client_id,
        email,
        tenant_id,
      };

      const response = await createClientProfileAuthorize({ body: clientProfileAuthorize });
      return response;
    } catch (e) {
      return false;
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const getCreditCardList = async () => {
    try {
      setLoadingCreditCard(true);
      const getListCard = await API.getListCredictCardByLawFirm({ idLawFirm: getClientId(roles) });
      setCreditCardList(getListCard.paymentProfiles || []);
    } catch (e) {
      return false;
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const getPublicKey = async () => {
    try {
      setLoadingCreditCard(true);
      const result = await API.getPublicKeyAuthorize();

      if (result.data.success) {
        setPublicKey(result.data.response.publicClientKey);
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const addCreditCard = async ({ token, selectDefault }) => {
    try {
      setLoadingCreditCard(true);

      const client_id = getClientId(roles);

      const profileCustomer = await API.getCustomerProfileByLawFirm({ idLawFirm: client_id });

      if (!profileCustomer?.customer?.customerProfileId) notifyApiError('not found');

      await API.addCreditCard({
        profileCustomerId: profileCustomer.customer.customerProfileId,
        token,
        defaultPayment: selectDefault,
        client_id,
      });
    } catch (e) {
      notifyApiError('Error get public key');
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const addCreditCardWithUser = async ({ token, user_id, tenant_id, profileCustomerId, selectDefault, billingInfo }) => {
    try {
      setLoadingCreditCard(true);

      const res = await API.addCreditCardWithUser({
        profileCustomerId,
        token,
        user_id,
        tenant_id,
        default_payment: selectDefault,
        billingInfo: billingInfo, 
      });

      return res;
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const deleteCreditCard = async ({ profileCustomerPaymentId }) => {
    try {
      setLoadingCreditCard(true);
      const profileCustomer = await API.getCustomerProfileByLawFirm({ idLawFirm: getClientId(roles) });

      if (!profileCustomer?.customer?.customerProfileId) notifyApiError('not found');

      await API.deleteCreditCard({
        profileCustomerPaymentId,
        profileCustomerId: profileCustomer.customer.customerProfileId,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const updateDefaultCard = async ({ profileCustomerPaymentId, defaultPayment }) => {
    try {
      setLoadingCreditCard(true);
      const profileCustomer = await API.getCustomerProfileByLawFirm({ idLawFirm: getClientId(roles) });

      if (!profileCustomer?.customer?.customerProfileId) notifyApiError('not found');

      await API.updateDefaultCard({
        profileCustomerPaymentId,
        defaultPayment,
        profileCustomerId: profileCustomer.customer.customerProfileId,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const getCustomerProfileUser = async (id) => {
    try {
      setLoadingCreditCard(true);

      const customerProfile = await API.getCustomerProfileByUser({ id: id });

      return customerProfile;
    } catch (e) {
      return false;
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const getCreditCardListTenant = async (id) => {
    try {
      setLoadingCreditCard(true);
      const getListCard = await API.getListCredictCardById({ id: id });
      setCreditCardList(getListCard.paymentProfiles || []);
    } catch (e) {
      return false;
    } finally {
      setLoadingCreditCard(false);
    }
  };

  const deleteCreditCardUser = async ({ profileCustomerPaymentId, tenant_id }) => {
    try {
      setLoadingCreditCard(true);
      const { tenant } = await API.getTenantById({ id: tenant_id });
      const profileCustomer = tenant?.customer_profile_id || '';
      if (!profileCustomer?.customer?.customerProfileId) notifyApiError('not found');

      await API.deleteCreditCard({
        profileCustomerPaymentId,
        profileCustomerId: profileCustomer,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingCreditCard(false);
    }
  };

  return {
    loadingCreditCard,
    creditCardList,
    publicKey,
    getPublicKey,
    getCreditCardList,
    getCreditCardListTenant,
    addCreditCard,
    deleteCreditCard,
    getCustomerProfile,
    getCustomerProfileUser,
    createCustomerProfile,
    updateDefaultCard,
    deleteCreditCardUser,
    addCreditCardWithUser,
  };
}
