import React, { useState } from 'react';
import { Typography, Row } from 'antd';

// Components
import InputBox from 'components/InputBox';

// Assets
import { EditIcon, CheckIcon } from 'components/Icons';

// Styles
import './LabelDinamic.scss';

const LabelDinamic = ({ text, onChange, disabled }) => {
  const { Text } = Typography;
  const [isChange, setIsChange] = useState(false);
  const [textValue, setTextValue] = useState(text);

  // evento para activar el cambio del label
  const hanldeChangeActive = () => {
    setIsChange(!isChange);
  };

  // Evento que envia el nuevo valor al padre
  const handleSave = () => {
    onChange && onChange(textValue);
    setIsChange(!isChange);
  };

  if (isChange)
    return (
      <Row type="flex">
        <InputBox
          classes="inputLabelChange"
          type="text"
          value={textValue}
          onChange={value => {
            setTextValue(value);
          }}
        />
        <CheckIcon className="icon" style={{ marginLeft: 10 }} onClick={handleSave} />
      </Row>
    );

  return (
    <Row type="flex">
      <Text>{text}</Text>
      {!disabled && <EditIcon className="icon" style={{ marginLeft: 10 }} onClick={hanldeChangeActive} />}
    </Row>
  );
};

export default LabelDinamic;
