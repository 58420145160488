import { REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID, REPORT_DEMAND_LETTER_TYPE_ID } from 'constants/reports';
import * as API from 'utils/api';

export const REPORT_TYPE_MSA = 1;
export const REPORT_TYPE_STANDART = 2;
export const REPORT_TYPE_MCP = 3;
export const REPORT_TYPE_SURGICAL = 4;

export const generateReportLink = report => {
  switch (report?.report_type?.id) {
    case REPORT_TYPE_MSA:
      return `${process.env.REACT_APP_BASE_URL}/app/reports/${report.id}/msa/demographic`;
    case REPORT_TYPE_MCP:
      return `${process.env.REACT_APP_BASE_URL}/app/reports/${report.id}/mcp/demographic`;
    case REPORT_TYPE_SURGICAL:
      return `${process.env.REACT_APP_BASE_URL}/app/reports/${report.id}/surgical/medicalContent`;
    default:
      return `${process.env.REACT_APP_BASE_URL}/error`;
  }
};

export const generateReportRedirectLink = (id, type) => {
  switch (Number.parseInt(type)) {
    case REPORT_TYPE_MSA:
      return `/app/reports/${id}/msa/demographic`;
    case REPORT_TYPE_MCP:
      return `/app/reports/${id}/mcp/demographic`;
    case REPORT_TYPE_SURGICAL:
      return `/app/reports/${id}/surgical/medicalContent`;
    default:
      return `/error`;
  }
};

export const createViewReportIfNotExists = async ({ reportId }) => {
  let viewReport = null;

  try {
    viewReport = await API.getViewReportById(reportId);
  } catch (e) {
    console.error();
  }

  if (!viewReport) {
    const report = await API.getReportById(reportId, true);

    const body = {
      ...report,
    };

    if ([REPORT_DEMAND_LETTER_TYPE_ID, REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID].includes(report.report_type_id)) {
      body.current_medical_expenses = report.current_medical_expenses.map(item => ({
        name: item.name,
        cost: item.cost,
      }));

      body.future_medical_expenses = report.future_medical_expenses.map(item => ({
        name: item.name,
        cost: item.cost,
      }));
    }

    await API.createViewReport(body);
  }
};
