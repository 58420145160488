import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from '@tinymce/tinymce-react';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Row, Typography, Label } from 'antd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.scss';
import { stateFromHTML } from 'draft-js-import-html';
const { Text } = Typography;
window.WEBSPELLCHECKER_CONFIG = {
  autoSearch: true,
  enableGrammar: true,
  serviceId: 'LCTPrYa3HeRRhE5',
};
export default class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);
    const html = '<div></div>';
  }

  onEditorStateChange = editorState => {
    this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/font-family/g, 'f'));
    this.setState({
      editorState,
    });
  };
  componentDidMount = () => {
    if (this.props.value) {
      const html = this.props.value;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }
  };
  handlePastedText = (text, html) => {
    const { editorState } = this.state;
    const blockMap = stateFromHTML(html).blockMap;
    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap
    );
    this.setState({
      editorState: EditorState.push(editorState, newState, 'insert-fragment'),
    });
    return true;
  };
  render() {
    return (
      <Row>
        <Row className="label">
          <Text>{this.props.label}</Text>
        </Row>
        <Row>
          <Editor
            id={this.props.idCypress}
            apiKey={process.env.REACT_APP_TINY_MCE}
            initialValue={this.props.value}
            disabled={this.props.disabled}
            init={{
              min_height: 40,
              menubar: false,
              inline: this.props.modal ? false : true,

              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect |underline | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify  bullist numlist | ',
            }}
            onEditorChange={state => {
              this.props.onChange(state);
            }}
          />
        </Row>
      </Row>
    );
  }
}
