import React, { useState } from 'react';

// Components
import { ReviewRolesNewTenant } from './components/ReviewRolesNewTenant';

// Icons
import { UserGroupIcon, UserRoundedIcon } from 'components/Svg';

// Color
import { Button } from 'antd';

export const TYPE_OF_PACKAGE_OPTIONS = {
  INDIVIDUAL: 1,
  MULTIPLE_USERS: 2,
};

const StepTypeOfPackage = ({ data, onSubmit, prevStep }) => {
  const [typePackage, setTypePackage] = useState(data || '');
  const [showMessageRoles, setShowMessageRole] = useState(false);

  const handleSubmit = async () => {
    onSubmit({ sectionStep: 'type_package', payload: typePackage, nextStep: 3 });
  };

  const handleValidSelectedOption = () => {
    if (typePackage === TYPE_OF_PACKAGE_OPTIONS.MULTIPLE_USERS) {
      setShowMessageRole(true);
    } else {
      handleSubmit();
    }
  };

  if (showMessageRoles)
    return <ReviewRolesNewTenant handleSubmit={handleSubmit} close={() => setShowMessageRole(false)} />;

  return (
    <div className="stepContainer stepContainerNewTenant">
      <div className="stepTitleContainer">
        <p className="stepText">Step 2</p>
        <h1 className="stepTitle">Type of Package</h1>
      </div>

      <div className="stepTypeOfPackage_options">
        <div
          className={`stepTypeOfPackage_option ${
            typePackage === TYPE_OF_PACKAGE_OPTIONS.INDIVIDUAL ? 'stepTypeOfPackage_option_active' : ''
          }`}
          onClick={() => setTypePackage(TYPE_OF_PACKAGE_OPTIONS.INDIVIDUAL)}>
          <div className="stepTypeOfPackage_circle" />
          <UserRoundedIcon />
          <div className="stepTypeOfPackage_name">Individual</div>
          <div className="stepTypeOfPackage_description">
            Our Individual option is designed for independent medical professionals (limit one user)
          </div>
        </div>
        <div
          className={`stepTypeOfPackage_option ${
            typePackage === TYPE_OF_PACKAGE_OPTIONS.MULTIPLE_USERS ? 'stepTypeOfPackage_option_active' : ''
          }`}
          onClick={() => setTypePackage(TYPE_OF_PACKAGE_OPTIONS.MULTIPLE_USERS)}>
          <div className="stepTypeOfPackage_circle" />
          <UserGroupIcon />
          <div className="stepTypeOfPackage_name">Multiple Users</div>
          <div className="stepTypeOfPackage_description">
            The Multiple Users option is designed for businesses with up to ten users and allows them to customize their
            workflows
          </div>
        </div>
      </div>

      <div className="add_new_tenant">
        <Button type="primary" size="default" className="transparent-btn button" onClick={prevStep}>
          PREVIOUS STEP
        </Button>
        <Button
          type="primary"
          size="default"
          className="stepNextButton"
          disabled={!typePackage}
          onClick={handleValidSelectedOption}>
          Next Step
        </Button>
      </div>
    </div>
  );
};

export default StepTypeOfPackage;
