import React from 'react';
import { Badge } from 'antd';

import { TAB_NOTIFICATION_NUMBER } from 'constants/tabClaimantDetails';

const BadgeNotification = ({ selectedTab }) => (
  <span>
    Activity Log 
    <Badge
      overflowCount={9999}
      showZero
      className={selectedTab === TAB_NOTIFICATION_NUMBER ? 'selected-badge-icon' : 'badge-icon'}
    />
  </span>
);

export default BadgeNotification;
