import * as API from 'utils/api';
import { ASSIGNMENT_TYPE_NURSE } from 'constants/assignment-types';
import moment from 'moment';

export const returnToSenderReport = async ({ report_id, claimant_id }) => {
  try {
    const assignment = await API.fetchReportAssignments({
      reportId: report_id,
      claimantId: claimant_id,
    });
    const idAssignment = assignment.assignments[0].id;
    const userOldAssignment = assignment.assignments[0].assigned_by;
    // const id = assignment.assignments[0].id;

    const updateAssigment = await API.updateAssignmentById(idAssignment, {
      user_id: userOldAssignment,
      assignment_type_id: ASSIGNMENT_TYPE_NURSE,
      qaRevisionDate: moment(),
    });

    return updateAssigment;
  } catch (e) {
    console.error(e.message);
    return e;
  }
};
