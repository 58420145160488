import React, { useState, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import SpinBox from 'components/SpinBox';
import { BackIcon, DetailsIcon } from 'components/Icons';
import { goFirstStep } from 'utils/report-routing';
import { useHistory, useParams } from 'react-router-dom';
import {
  REPORT_SECTIONS_DEFAULT,
  REPORT_TYPES_BY_ID,
  REPORT_MSA_TYPE_ID,
  REPORT_MSA_LETTER_TYPE_ID,
  REPORT_MSA_REDUCTION_TYPE_ID,
} from 'constants/reports';
import * as API from 'utils/api';
import { notifyApiError } from 'utils/notification';
import moment from 'moment';
import { ATTORNEY_CLAIMANT_NAV } from 'layout/navigate_const';

import './AttorneyClaimants.scss';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { useSelector } from 'react-redux';
import { ModalUpgratePackageOpenReport } from 'components/Modal/ModalUpgratePackageOpenReport';

const { Title, Text } = Typography;

const ViewMsaReportsWithIdReport = () => {
  const history = useHistory();
  const { roles } = useSelector(state => state.auth);
  const { idReport } = useParams();
  const [loading, setLoading] = useState(true);
  const [reportInfo, setReportInfo] = useState({});
  const [claimant, setClaimant] = useState({});
  const [client, setClient] = useState({});
  const [openModalUpgradedPlan, setOpenModalUpgradedPlan] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const data = await API.getReportById(idReport, true);
        const claimantInfo = data.claimant;
        const clientInfo = await API.getCLientForId({ id: claimantInfo.client.id });

        setReportInfo(data);
        setClaimant(claimantInfo);
        setClient(clientInfo.clients[0]);
        setLoading(false);
      } catch (e) {
        notifyApiError(e);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleGotoReportEdit = async ({ type_report } = {}) => {
    try {
      setLoading(true);
      const validOpenRerpot = await validateOpenReportForPackage({
        tenant_id: roles.user_roles.tenant_id,
        report_type: type_report,
      });

      if (!validOpenRerpot.open)
        return setOpenModalUpgradedPlan({
          ...validOpenRerpot,
          open: true,
          tenant_id: roles.user_roles.tenant_id,
          customer: roles.user_roles.id,
        });

      const sendDataHistory = {
        claimantInfo: claimant,
        assignmentAttorney: true,
      };

      goFirstStep({
        sections: REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[type_report]],
        reportType: REPORT_TYPES_BY_ID[type_report],
        report_id: reportInfo.id,
        history,
        sendDataHistory,
      });
    } catch (e) {
      setLoading(false);
      notifyApiError(e);
    }
  };

  if (loading) return <SpinBox loading={loading}></SpinBox>;

  return (
    <Row className="contacts-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            {`${claimant.claimant_name || ''} ${claimant.claimant_last_name || ''}`}
          </Title>
          <Text>{client.client_name}</Text>
        </Col>
        <Col className="back-icon">
          <BackIcon className="icon" onClick={() => history.push(ATTORNEY_CLAIMANT_NAV.path, { data: client })} />
        </Col>
      </Row>
      <Row type="flex" className="table-header">
        <Col md={10}>Type of report</Col>
        <Col md={8}>Last Update</Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          Actions
        </Col>
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading}>
          <Row>
            <Row className="record">
              <Col md={10}>
                <Text>MSA Letter</Text>
              </Col>
              <Col md={8}>
                <Text>{moment(reportInfo.updatedAt).format('MM/DD/YYYY')}</Text>
              </Col>
              <Col md={6} className="actions">
                <DetailsIcon
                  className="icon"
                  onClick={() => handleGotoReportEdit({ type_report: REPORT_MSA_LETTER_TYPE_ID })}
                />
              </Col>
            </Row>
            <Row className="record">
              <Col md={10}>
                <Text>MSA Worksheet</Text>
              </Col>
              <Col md={8}>
                <Text>{moment(reportInfo.updatedAt).format('MM/DD/YYYY')}</Text>
              </Col>

              <Col md={6} className="actions">
                <DetailsIcon
                  className="icon"
                  onClick={() => handleGotoReportEdit({ type_report: REPORT_MSA_REDUCTION_TYPE_ID })}
                />
              </Col>
            </Row>
            <Row className="record">
              <Col md={10}>
                <Text>MSA Report</Text>
              </Col>
              <Col md={8}>
                <Text>{moment(reportInfo.updatedAt).format('MM/DD/YYYY')}</Text>
              </Col>
              <Col md={6} className="actions">
                <DetailsIcon
                  className="icon"
                  onClick={() => handleGotoReportEdit({ type_report: REPORT_MSA_TYPE_ID })}
                />
              </Col>
            </Row>
          </Row>
        </SpinBox>
      </Row>

      {openModalUpgradedPlan?.open && (
        <ModalUpgratePackageOpenReport data={openModalUpgradedPlan} onClose={() => setOpenModalUpgradedPlan({})} />
      )}
    </Row>
  );
};

export default ViewMsaReportsWithIdReport;
