import React, { useState, useEffect } from 'react';
import * as API from 'utils/api';
import { reOrderList } from 'utils/utils';
import { orderMarketingSection } from 'containers/Marketing/utils/orderMarketingSection';
import { ModalManagerComponent } from 'containers/Marketing/components/ModalManager';

import 'containers/Marketing/components/ModalManager/modalManager.scss';

const ModalManagerHelp = ({ close }) => {
  const [addItem, setAddItem] = useState([]);
  const [listIdDataEdit, setListIdDataEdit] = useState([]);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    asyncEffect();
  }, []);

  const asyncEffect = async () => {
    try {
      setLoading(true);
      const getData = await API.getTutorialsSection();

      if (!getData.users && getData.users.length === 0) return;

      const result = orderMarketingSection({ list: getData.users });

      setListData(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAddFieldNewData = () => {
    setAddItem([
      ...addItem,
      {
        id: addItem.length,
        name: '',
      },
    ]);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (addItem.length > 0) {
        await API.createTutorialSection({ body: { tutorial: addItem.map(item => ({ name: item.name })) } });
      }

      await API.updateOrderMaterialTutorial({ body: { ids: listData.map(item => item.id) } });

      close && close();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectEditItem = ({ id }) => {
    if (listIdDataEdit.includes(id)) {
      setListIdDataEdit(listIdDataEdit.filter(item => item !== id));
    } else {
      setListIdDataEdit([...listIdDataEdit, id]);
    }
  };

  const handleConfirmEdit = async ({ data }) => {
    try {
      setLoading(true);
      await API.updateTutorialSection({ body: data });

      setListData(
        listData.map(item => {
          if (item.id === data.id) {
            return data;
          }

          return item;
        })
      );

      handleSelectEditItem({ id: data.id });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = async ({ id }) => {
    try {
      setLoading(true);
      await API.deleteTutorialSection({ body: id });
      setListData(listData.filter(item => item.id !== id));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeNewItem = ({ item, value }) => {
    setAddItem(
      addItem.map(data => {
        if (data.id === item.id) {
          return {
            ...data,
            name: value,
          };
        }

        return data;
      })
    );
  };

  const handleDeleteAllItem = async () => {
    try {
      setListData(true);
      await API.deleteAllTutorialSection({ body: listData.map(item => item.id) });
      await asyncEffect();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePosition = async ({ source, destination }) => {
    if (!destination || source.index === destination.index) return;

    const getListSection = listData.map(item => ({ id: item.id, name: item.name }));
    const reOrder = reOrderList({ list: getListSection, startIndex: source.index, endIndex: destination.index });

    setListData(reOrder);
  };

  return (
    <ModalManagerComponent
      loading={loading}
      handleChangePosition={handleChangePosition}
      handleAddFieldNewData={handleAddFieldNewData}
      listData={listData}
      listIdDataEdit={listIdDataEdit}
      handleSelectEditItem={handleSelectEditItem}
      handleConfirmEdit={handleConfirmEdit}
      handleDeleteItem={handleDeleteItem}
      handleDeleteAllItem={handleDeleteAllItem}
      handleChangeNewItem={handleChangeNewItem}
      addItem={addItem}
      handleSave={handleSave}
      close={close}
    />
  );
};

export default ModalManagerHelp;
