import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Row, Col, Layout, Modal, Typography } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiFilterVariant, mdiViewList, mdiViewModule, mdiPlus } from '@mdi/js';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PRIMARY_TEXT_DARK, PRIMARY_LIGHT_GREEN, PRIMARY_LIGHT } from '../../../lib/utils/colors';
import AddAssignForm from '../../base/AddAssignForm';
import DataTable from '../../base/DataTable';
import Pagination from 'components/Pagination';
import CustomToolbar from '../../base/CustomCalendarToolbar';
import FilterSetting from '../../base/FilterSetting';
import FilterWrapper from '../../../components/FilterWrapper/index';
import Devider from '../../../components/common/Devider';
import StatusIcon from '../../../components/common/StatusIcon';
import HideFullScreen from '../../../components/common/HideFullScreen';
import CustomButton from '../../../components/common/CustomButton';
import messages from './messages';
import * as API from 'utils/api';
import { isObjectEmpty } from 'utils/utils';
import AssignedModalData from './AssignedModalData';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { calculateColor } from '../../../lib/utils/calculateColor';
import SpinBox from 'components/SpinBox';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../react-calendar-custom.scss';
import '../../../react-datepicker-custom.scss';
import { ATTORNEY_CLAIMANT_REPORT_NAV } from 'layout/navigate_const';
import { ASSIGNMENT_TYPE_LEGAL_NURSE } from 'constants/assignment-types';
import {
  STATUS_REDUCTION_REQUESTED,
  STATUS_REDUCTION_COMPLETED,
  STATUS_COMPLETED,
  STATUS_IN_SUPERVISOR_REVIEW,
} from 'constants/report_status';
import { ASSIGNMENT_STATUS_COMPLETED } from 'containers/Reviews/assignment-statuses';
import { updateAssignmentById } from 'utils/api-reviews';

const { Content } = Layout;
const { Title, Text } = Typography;
const { confirm } = Modal;
const localizer = momentLocalizer(moment);

const Assignment = () => {
  const state = useSelector(state => state.auth);
  const { limit } = useSelector(({ application }) => application.settings);
  const [viewMode, setViewMode] = useState(messages.calendar);
  const [showFilterSetting, setShowFilterSetting] = useState(false);
  const [showNewAssignForm, setShowNewAssignForm] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState('');
  const [edit, setEdit] = useState({});
  const [filters, setFilters] = useState({});
  const [labels, setLabels] = useState({});
  const [loading, setLoading] = useState(true);
  const [handleEdit, setHandleEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const changeEditMode = value => setHandleEdit(value);

  const fetchAssignments = useCallback(async () => {
    const isAdminRole = isAllowed(state.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isManagerRole = isAllowed(state.roles, ROLES_DIVISIONS.MANAGER);

    const searchFilters = { ...filters };

    if (viewMode === messages.table) {
      searchFilters.page = page;
      searchFilters.limit = limit;
    }

    const queryString =
      searchFilters && Object.keys(searchFilters).length > 0
        ? Object.keys(searchFilters).reduce((accum, filter) => {
            if (!searchFilters[filter] || searchFilters[filter] === null) {
              return accum;
            }
            let value = searchFilters[filter];
            if (filter === 'start_date' || filter === 'end_date') {
              value = value.format('YYYY-MM-DD');
            }
            return accum + `${!accum ? '?' : '&'}${filter === 'note' ? 'search' : filter}=${value}`;
          }, '')
        : '';

    setLoading(true);

    try {
      const apiFunc = isAdminRole ? API.getAllAssignments : API.GetUserAssignments;
      const res =
        isManagerRole && !isAdminRole
          ? await API.GetUserAssignments(`${queryString ? queryString + '&' : '?'}`)
          : await apiFunc(queryString);

      let data = [];
      viewMode === messages.table
        ? (data = res.assignments)
        : res.assignments.map(assignment => {
            if (assignment.status.key !== 6) {
              // Assignment completed and deleted
              data.push({
                start: moment(assignment.start_date).toDate(),
                end: moment(assignment.start_date).toDate(),
                end_date: moment(assignment.end_date).toDate(),
                title: assignment.report
                  ? `${assignment.report.report_uuid} - ${assignment.report.report_name}`
                  : `Claimant: ${assignment?.claimant?.claimant_title || ''}`,
                id: assignment.id,
                borderColor: 'green',
                claimant: assignment.claimant,
                status: assignment.status,
                name: assignment.user ? assignment.user.given_name : '',
                lastname: assignment.user ? assignment.user.family_name : '',
                notes: assignment.note,
                report_type_id: assignment.report ? assignment.report.report_type_id : '',
                report_status_id: assignment.report ? assignment.report.report_status_id : '',
                report_id: assignment.report ? assignment.report.id : '',
                report: assignment.report ? assignment.report : {},
                extendedProps: {
                  status: 'done',
                  data: assignment,
                },
              });
            }
          });

      setAssignments(data);
      setTotalCount(res.count !== undefined ? res.count : res.total_count !== undefined ? res.total_count : 0);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  }, [filters, page, limit, viewMode, state.roles]);

  useEffect(() => {
    fetchAssignments();
  }, [fetchAssignments]);

  const changeFilters = (newFilters, newLabels) => {
    setPage(0);
    setFilters({ ...filters, ...newFilters });
    setLabels({ ...labels, ...newLabels });
  };

  const resetFilters = () => {
    setFilters({});
    setLabels({});
  };

  const handleSetViewMode = mode => {
    setViewMode(mode);
    if (mode === messages.table) {
      handleShowFilterSetting(false);
      handleShowAddAssignForm(false);
    }
  };

  const handleShowFilterSetting = e => setShowFilterSetting(e);

  const handleShowAddAssignForm = e => setShowNewAssignForm(e);

  const onSubmit = async newAssignement => {
    setLoading(true);
    try {
      const res = await API.createAssignementUser(newAssignement);

      if (res?.assignment?.assignment_type_id === ASSIGNMENT_TYPE_LEGAL_NURSE) {
        await API.updateReportNurse({ id: res.assignment.report_id, report_status_id: STATUS_IN_SUPERVISOR_REVIEW.id });
      }

      handleShowAddAssignForm(false);
      fetchAssignments();
      setAssignments([...assignments, res.assignment]);
      notifyInfo('Assignment created');
      return true;
    } catch (e) {
      notifyApiError(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const onDelete = id => {
    confirm({
      title: 'Are you sure you want to complete this assignment?',
      content: 'If the review contains any highlights they will be completed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        setLoading(true);

        try {
          let report_status_id;
          const updateAssignment = assignments.find(item => item.id === id);

          if (updateAssignment.report_status_id !== STATUS_REDUCTION_REQUESTED.id) {
            report_status_id = STATUS_COMPLETED.id;
          } else {
            report_status_id = STATUS_REDUCTION_COMPLETED.id;
          }

          if (viewMode === messages.calendar) setOpen(!open);
          await API.updateReport({ id: updateAssignment.report_id, report_status_id });
          await updateAssignmentById(id, {
            status: ASSIGNMENT_STATUS_COMPLETED,
          });

          setAssignments(assignments.filter(el => el.id !== id));
          setTotalCount(prev => prev - 1);
        } catch (e) {
          notifyApiError(e);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const onEdit = async data => {
    setHandleEdit(true);
    await setEdit(data.extendedProps.data);
  };
  const updateAssignedUserEdit = change => {
    setEdit({
      ...edit,
      ...change,
    });
  };
  const saveEdit = async edit => {
    let newEdit = { ...edit };
    delete newEdit.id;
    delete newEdit.report;
    delete newEdit.claimant;
    delete newEdit.status;
    delete newEdit.user;
    delete newEdit.assignment_type;
    delete newEdit.tenant_id;

    try {
      const res = await API.UpdateAssignement(edit.id, newEdit);

      if (res?.assignment?.assignment_type_id === ASSIGNMENT_TYPE_LEGAL_NURSE) {
        await API.updateReportNurse({ id: res.assignment.report_id, report_status_id: STATUS_IN_SUPERVISOR_REVIEW.id });
      }

      changeEditMode(false);
      setOpen(false);
      fetchAssignments();
      notifyInfo('Assignment updated');
    } catch (e) {
      notifyApiError(e);
    }
  };

  const handleViewReduction = ({ data }) => {
    if (data.extendedProps.data.report.report_status_id !== STATUS_REDUCTION_REQUESTED.id)
      return history.push('/app/reportChangesAttorney', { data });
    history.push(`${ATTORNEY_CLAIMANT_REPORT_NAV.path}/${data.claimant.id}`);
  };

  let managerUser = isAllowed(state.roles, ROLES_DIVISIONS.MANAGEMENT);
  const nurseUser = isAllowed(state.roles, ROLES_DIVISIONS.NURSE_ONLY);

  return (
    <SpinBox loading={loading}>
      <Layout>
        <Row>
          <Row type="flex" align="middle" className="assignement-board-header">
            <Col className="assignement-board-title">
              <Title level={4} className="title-text">
                {messages.headerTitle}
              </Title>
              <Text type="secondary" strong>
                {managerUser ? messages.headerSubTitle : 'My Assignments'}
              </Text>
            </Col>
            <Col className="assign-filter-button">
              <FilterWrapper>
                <Icon
                  color={!isObjectEmpty(filters) ? PRIMARY_LIGHT_GREEN : PRIMARY_TEXT_DARK}
                  path={mdiFilterVariant}
                  size={1}
                  onClick={() => handleShowFilterSetting(true)}
                />
              </FilterWrapper>
              <Devider height={67}></Devider>
            </Col>
            <Col className="view-mode-container">
              <Title
                level={4}
                style={{
                  marginTop: 3,
                  fontSize: '14px',
                  color: '#1B1B1B',
                }}
                className="view-mode">
                {messages.viewMode}
              </Title>
              <div className="d-flex">
                <Icon
                  path={mdiViewList}
                  size={1}
                  color={viewMode === messages.table ? PRIMARY_LIGHT_GREEN : PRIMARY_TEXT_DARK}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                  onClick={() => handleSetViewMode(messages.table)}
                />
                <Icon
                  path={mdiViewModule}
                  size={1}
                  color={viewMode === messages.calendar ? PRIMARY_LIGHT_GREEN : PRIMARY_TEXT_DARK}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                  onClick={() => handleSetViewMode(messages.calendar)}
                />
              </div>
            </Col>
            <Col className="assign-pagination">
              {viewMode !== 'calendar' && totalCount > 0 && (
                <Pagination onChange={setPage} totalCount={totalCount} pageIndex={page} pageSize={limit} />
              )}
            </Col>

            {(managerUser || nurseUser) && (
              <Col className="assignement-btn-wrapper">
                <CustomButton
                  className="header_btn"
                  size={'large'}
                  backgroundcolor={PRIMARY_LIGHT_GREEN}
                  onClick={() => handleShowAddAssignForm(true)}>
                  <Icon path={mdiPlus} size={1} color={PRIMARY_LIGHT} />
                </CustomButton>
              </Col>
            )}
          </Row>
        </Row>

        <Content className="site-layout">
          {showFilterSetting && (
            <>
              <FilterSetting
                onSetMode={handleSetViewMode}
                setShowFilterSetting={setShowFilterSetting}
                changeFilters={changeFilters}
                resetFilters={resetFilters}
                initFilters={filters}
                initLabels={labels}
                closeSetting={() => handleShowFilterSetting(false)}
              />
              <HideFullScreen zIndex={5} onClick={() => handleShowFilterSetting(false)} />
            </>
          )}
          <Modal
            width={700}
            bodyStyle={{ padding: 0, minWidth: 450 }}
            visible={showNewAssignForm}
            onOk={() => handleShowAddAssignForm(true)}
            onCancel={() => handleShowAddAssignForm(false)}
            footer=""
            destroyOnClose>
            <AddAssignForm onSubmit={onSubmit} assignmentBoardPage />
          </Modal>
          {viewMode === messages.calendar ? (
            <Calendar
              localizer={localizer}
              defaultDate={new Date()}
              defaultView={'month'}
              events={assignments}
              style={{ height: 'calc( 100vh - 67px )' }}
              popup={true}
              onSelectEvent={event => {
                setOpen(true);
                setData(event);
              }}
              components={{
                toolbar: CustomToolbar,

                month: {
                  // eslint-disable-next-line react/display-name
                  dateHeader: props => (
                    <Fragment>
                      <a href="#">{Number(props.label)}</a>
                    </Fragment>
                  ),
                },

                event: e => EventComponent(e),
              }}
            />
          ) : (
            <DataTable
              filters={filters}
              fetchAssignments={fetchAssignments}
              assignments={assignments}
              onDelete={onDelete}
              loading={loading}
            />
          )}
        </Content>
      </Layout>
      <AssignedModalData
        changeEditMode={changeEditMode}
        saveEdit={saveEdit}
        visible={open}
        open={open}
        onCancel={() => {
          setOpen(!open);
          setHandleEdit(false);
        }}
        managerUser={managerUser}
        edit={edit}
        data={data}
        onDelete={onDelete}
        handleEdit={handleEdit}
        onEdit={onEdit}
        updateAssignedUserEdit={updateAssignedUserEdit}
        history={history}
        onViewReduction={handleViewReduction}
      />
    </SpinBox>
  );
};

const EventComponent = event => {
  const color = calculateColor(event.event.end_date);

  return (
    <Fragment>
      <StatusIcon bgColor={color} />
      {event.title}
    </Fragment>
  );
};

export default Assignment;
