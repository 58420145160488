import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Typography, Modal, Checkbox, Spin } from 'antd';
import DropDown from 'components/Dropdown';
import { updateReportInfo, getReportFiles } from 'redux/report';
import * as API from 'utils/api';
import { UploadPresigned, BulkAddFilesToReport } from 'utils/upload';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import { DownloadOutlined } from '@ant-design/icons';
import './DocumentUpload.scss';
import UploadFileModal from '../../components/UploadFileModal/UploadFileModal';
import { REPORT_FIELDS_UPLOAD, REPORT_MCP } from 'constants/reportfieldsUploadFile';
import { ItemsTableUploadFile } from 'containers/Reports/components/ItemsTableUploadFile/ItemsTableUploadFile';
import TrashIcon from 'components/Svg/TrashIcon';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import {} from 'constants/assignment-types';
import { WaterMarkCheck } from 'components/WaterMarkCheck';
import {} from 'constants/report_status';
import { MAIN_NAV } from 'layout/navigate_const';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import { SPECIALTY_IDS_OBJECT } from 'constants/specialty';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { REPORT_MCP_NAME } from 'constants/reports';

// Components
import { SignatureReport } from 'containers/Reports/components/SignatureReport';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';

// Utils
import { getListSignature } from 'containers/Reports/utils/helpers';
import PrevNextStep from 'components/PrevNextStep';

const { Title, Text } = Typography;

const beforeUpload = file => {
  if (!file) return false;
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    Modal.error({
      content: 'You can only upload PDF file!',
    });
  }
  return isPdf;
};

class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      loadingText: 'Loading...',
      loading: false,
      is_template: false,
      injury_description: [],
      medicals: [],
      damages: [],
      legal_docs: [],
      required_qa: true,
      modalUploadFile: false,
      show_additional_comments: false,
      is_watermark: false,
      md_tbd: null,
      REPORT_PERMISSIONS,
      signature: [],
    };

    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleUpdateWaterMark = this.handleUpdateWaterMark.bind(this);
    this.handleDocUpload = this.handleDocUpload.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.renderFiles = this.renderFiles.bind(this);
    this.handleModalUploadFile = this.handleModalUploadFile.bind(this);
    this.filesUploaded = this.filesUploaded.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
    this.handleDownloadAll = this.handleDownloadAll.bind(this);
    this.handleChangeSignature = this.handleChangeSignature.bind(this);
    this.handleDeleteSignature = this.handleDeleteSignature.bind(this);
    this.handleAddSignature = this.handleAddSignature.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;
    this.props.getReportFiles(this.props.match.params.id);
    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);

        this.setState({
          loading: false,
          is_template: reportInfo.is_template,
          required_qa: reportInfo.required_qa,
          show_additional_comments: reportInfo.show_additional_comments,
          claimant: reportInfo.claimant,
          is_watermark: reportInfo.is_watermark,
          md_tbd: reportInfo.md_tbd,
          signature: getListSignature({ signature: reportInfo.signature }),
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const { injury_description, medicals, damages, legal_docs } = reportInfo;

      this.setState({
        injury_description,
        medicals,
        damages,
        legal_docs,
        loading: false,
      });
    }
  }

  async handleUpdateReport(isNextStep = false) {
    const { report_id, required_qa, signature } = this.state;
    const params = {
      id: report_id,
      required_qa,
      signature: signature.map(item => {
        delete item.imageUrl;
        return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
      }),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status_code === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleUpdateWaterMark(value) {
    try {
      const checked = value.target.checked;

      this.setState({
        loading: true,
        loadingTitle: 'Saving...  ',
        is_watermark: checked,
      });

      const { report_id } = this.state;
      const params = {
        id: report_id,
        is_watermark: checked,
      };

      await API.updateReport(params);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleDocUpload(info, type) {
    let promiseArray = [];
    let hasUploading = false;
    info.fileList.forEach(el => {
      if (beforeUpload(el.originFileObj)) {
        if ('uploading' === el.status) {
          hasUploading = true;
        }
        promiseArray.push(el);
      }
    });

    if (hasUploading) {
      return;
    }

    const uploadArrayWithPromises = [];
    try {
      promiseArray
        .filter(
          (file, index, fileList) =>
            fileList.findIndex(f => f.name === file.name && f.type === file.type && f.size === file.size) === index
        )
        .forEach(file => {
          uploadArrayWithPromises.push(UploadPresigned(file));
        });

      this.setState(state => (state[type] = true));
      const urls = await Promise.all(uploadArrayWithPromises);
      await BulkAddFilesToReport(urls, type, this.props.reportInfo?.claimant?.id, this.state.report_id);
      await this.props.getReportFiles(this.props.match.params.id);
      info.fileList.length = 0;
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  }

  async deleteFile(el) {
    try {
      this.setState({ loading: true });
      await API.deleteReportDocument(el.id);
      await this.props.getReportFiles(this.props.match.params.id);
      this.setState({ loading: false });
    } catch (e) {
      notifyApiError(e);
    }
  }

  renderFiles(list, type) {
    const allFilesOfThisType = list
      .filter(item => item.type === type)
      .sort((a, b) => {
        return a.order - b.order;
      });

    return allFilesOfThisType;
  }

  handleModalUploadFile() {
    this.setState({ modalUploadFile: !this.state.modalUploadFile });
  }

  filesUploaded() {
    this.handleModalUploadFile();
    this.props.getReportFiles(this.props.match.params.id);
  }

  async handleRemoveAll() {
    this.setState({ loading: true });
    const listFile = REPORT_FIELDS_UPLOAD[REPORT_MCP].map(field => this.renderFiles(this.props.files, field)).flat();

    if (listFile.length > 0) {
      await Promise.all(listFile.filter(file => this.deleteFile(file)));
    }
    this.setState({ loading: false });
  }

  async handleDownloadAll() {
    try {
      this.setState({ loading: true });
      const listFile = REPORT_FIELDS_UPLOAD[REPORT_MCP].map(field => this.renderFiles(this.props.files, field)).flat();

      if (listFile.length > 0) {
        const res = await API.DownloadAllReportFiles({ listIdFiles: listFile.map(file => file.file.id) });

        if (res?.file?.url) {
          window.open(res?.file?.url);

          Modal.success({
            content: 'All Files have been Downloaded',
          });
        } else {
          Modal.error({ content: 'There is not files' });
        }
      }
    } catch {
      Modal.error({ content: 'An error has occurred' });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleChangeSignature(data) {
    this.setState(data);
  }

  handleDeleteSignature({ index }) {
    this.setState({ signature: this.state.signature.filter((item, inde) => inde !== index) });
  }

  handleAddSignature() {
    const add = [...this.state.signature, INIT_SIGNATURE];
    this.setState({ signature: add });
  }

  render() {
    const {
      loading,
      modalUploadFile,
      report_id,
      is_template,
      show_additional_comments,
      is_watermark,
      md_tbd,
      REPORT_PERMISSIONS,
      signature,
    } = this.state;

    const { files } = this.props;

    return (
      <>
        <Modal visible={loading} footer={null} className="modal-loader">
          <Spin />
        </Modal>
        <Row className="msa-document-upload-container newUploadFile">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Setup {REPORT_MCP_NAME} {is_template ? 'Template' : 'Report'}
              </Title>
            </Col>
            <Col className="step">
              <DropDown loading={loading} beforeNext={async () => await this.handleUpdateReport(false)} />
            </Col>
          </Row>

          <Row className="main">
            <div className="content newUploadFile_content">
              <div className="newUploadFile_titleContent">
                {isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_ONLY) && Number.isInteger(md_tbd) && (
                  <Text className="report_client_requested">
                    Client Requested: <b>{SPECIALTY_IDS_OBJECT[md_tbd].name}</b>
                  </Text>
                )}
                <Text className="title">Document Upload</Text>
                <Text>Documents to upload: Injury Description, Medicals, Damages, Legal docs.</Text>
              </div>

              {REPORT_PERMISSIONS.edit && (
                <Row className="newUploadFile_openModal" onClick={this.handleModalUploadFile}>
                  Upload Files
                </Row>
              )}

              {files.length > 0 && (
                <>
                  <div className="newUploadFile_content">
                    <div className="newUploadFile_tableFiles">
                      <div className="newUploadFile_tableFiles_header newUploadFile_tableFiles_item newUploadFile_grid3">
                        <Text>Type</Text>
                        <Text>Name</Text>
                        <Text className="newUploadFile_tableFiles_btnEnd">Actions</Text>
                      </div>

                      <div className="newUploadFile_tableFiles_body">
                        {REPORT_FIELDS_UPLOAD[REPORT_MCP].map((field, index) => (
                          <React.Fragment key={index}>
                            {this.renderFiles(files, field).map(item => (
                              <ItemsTableUploadFile
                                item={item}
                                type={field}
                                onDownload={this.generateAllReports}
                                onDelete={this.deleteFile}
                                key={item.id}
                              />
                            ))}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>

                  {REPORT_PERMISSIONS.edit && (
                    <div className="newUploadFile_btns">
                      <div
                        className="newUploadFiles_downloadAll newUploadFile_iconDownload"
                        onClick={this.handleRemoveAll}>
                        Remove All
                        <TrashIcon color={PRIMARY_LIGHT_BLUE} />
                      </div>
                      <div className="newUploadFiles_downloadAll" onClick={this.handleDownloadAll}>
                        Download All
                        <DownloadOutlined className="icon newUploadFile_iconDownload" />
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* <DocumentDefault reportId={this.props.match.params.id} /> */}

              <div className="newUploadFile_divider" />

              {REPORT_PERMISSIONS.edit && (
                <>
                  {!isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT) && (
                    <Row type="flex" justify="space-between" align="top" className="row">
                      <Col></Col>
                      <Col>
                        <Checkbox
                          checked={show_additional_comments}
                          onChange={() => {
                            this.setState({
                              show_additional_comments: !show_additional_comments,
                            });
                            API.updateReport({ id: report_id, show_additional_comments: !show_additional_comments });
                          }}>
                          Show comment sections
                          <br />
                          on the PDF
                        </Checkbox>
                      </Col>
                    </Row>
                  )}

                  {isAllowed(this.props.roles, [...ROLES_DIVISIONS.MANAGEMENT, ...ROLES_DIVISIONS.NURSE_ONLY]) && (
                    <WaterMarkCheck checked={is_watermark} onChange={this.handleUpdateWaterMark} />
                  )}
                </>
              )}

              {REPORT_PERMISSIONS.signature && !is_template && (
                <div>
                  <SignatureReport
                    defaultSignature={signature}
                    onChangeSignature={this.handleChangeSignature}
                    onDeleteSignature={this.handleDeleteSignature}
                    onAddSignature={this.handleAddSignature}
                    disabled={!REPORT_PERMISSIONS.edit}
                  />
                </div>
              )}

              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </div>
          </Row>
        </Row>
        {modalUploadFile && (
          <UploadFileModal
            close={this.handleModalUploadFile}
            returnFiles={this.filesUploaded}
            reportName={REPORT_MCP}
            reportId={this.props.match.params.id}
            claimId={this.props.reportInfo?.claimant?.id}
            title="Upload Files"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    reportInfo: state.report.reportInfo,
    files: state.report.files,
    resourceLinksFiles: state.report.resourceLinksFiles,
    allFiles: state.report.allFiles,
    isLoading: state.report.isLoading,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };

  return props;
};

export default connect(mapStateToProps, { updateReportInfo, getReportFiles })(DocumentUpload);
