import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from 'react-router-dom';
import * as dayjs from "dayjs";
import {Row, Col, Typography} from "antd";
import "./feedback.scss"
import FeedbackItem from "../../components/FeedbackItem";
import {Backicon} from "../../components/Icons";
import {getFeedback} from "../../redux/help";

const Help = () => {
  const {Title} = Typography;

  const dispatch = useDispatch();

  const {chats, entities} = useSelector(({help}) => help);

  useEffect(() => {
    getFeedback({})(dispatch);
  }, [dispatch])

  let currentFeedbackDate = dayjs(0); //if chat created month is another month - display month and change this var

  return (
    <div className="help-container">
      <Row type="flex" justify="space-between" align="middle" className="header">
        <Col span={3} offset={1}>
          <NavLink to="/app/help">
            <Backicon/>
          </NavLink>
        </Col>
        <Col span={16} className="report__title">
          <Title level={4} className="title-text">
            My Feedback
          </Title>
        </Col>
        <Col span={4}></Col>
      </Row>
      <div className="help-content">
        <div className="feedback-container">
          {chats && chats.length ? (chats.map(chatId => {

                const chat = entities.chats[chatId];
                let displayMonth = false;
                if (!currentFeedbackDate.isSame(dayjs(chat.createdAt), 'month')) {
                  displayMonth = true;
                  currentFeedbackDate = dayjs(chat.createdAt);
                }

                return (
                  <div key={`feedback-${chat.id}`} className="feedback-item">
                    {
                      displayMonth && (<div className="feedback-month">{currentFeedbackDate.format('MMMM, YYYY')}</div>)
                    }
                    <div className="help-content-card">
                      <FeedbackItem chat={chat}/>
                    </div>
                  </div>
                )
              })
            )
            :
            (
              <div style={{
                marginTop: 200,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                No feedback yet...
              </div>
            )
          }
        </div>


      </div>


    </div>
  );
}

export default Help;
