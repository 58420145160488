import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Typography, Modal, Button, Icon, Tag } from 'antd';
import * as API from 'utils/api';
import './TimeOff.scss';
import { connect } from 'react-redux';
import { notifyApiError, notifySuccess } from '../../../utils/notification';
import { DatePicker } from 'antd';
import moment from 'moment';
import { EditSvgIcon, DeleteSvgIcon } from 'components/Icons';
import { useSelector } from 'react-redux';

const TimeOff = () => {
  const { userInfo } = useSelector(state => state.auth);
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [currentVacation, SetCurrentVacation] = useState([{}]);
  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();
  const [endOpen, setEndOpen] = useState(false);
  const [selectVacation, SetSelectVacation] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editButton, setEditButton] = useState(false);

  const onStartChange = (value) => {
    setStartValue(value)
  };

  const onEndChange = value => {
    setEndValue(value)
  };

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(open)
    }
  };

  const handleEndOpenChange = open => {
    setEndOpen(open)
  };

  const handleOpenCloseForm = () => {
    setShowForm(!showForm)
    setEndValue(null);
    setStartValue(null);
    setEditButton(false);
  };
  

  const disabledDate=(current)=> {
    return current && current < moment().add(7, 'days');
  };

  const handleOpenModal = (item) => {
    setShowModal(true)
    SetSelectVacation(item)
  };
  const handleCloseModal= () => {
    setShowModal(false)
  };
  const onDelete = async () => {
    setIsLoading(true);
    await API.deleteUserVacation(
      selectVacation.id
    );
    notifySuccess('Deleted Successfully');
    setShowModal(false);
    await getVacation();
    setIsLoading(false);
  };

  const onDeletes = async (id) => {
    await API.deleteUserVacation(
      id
    );
  };

  const handleSubmit = async () => {
    if(!startValue || !endValue) {
      notifyApiError('Please select date');
      return;
    }
    if(currentVacation.length > 0) {
      const isOverlap = currentVacation.some(dateRange => {
        const rangeStart = moment(dateRange.start_date, "YYYY-MM-DD");
        const rangeEnd = moment(dateRange.end_date, "YYYY-MM-DD");
        
        return startValue.isBetween(rangeStart, rangeEnd, null, '[]') ||
        endValue.isBetween(rangeStart, rangeEnd, null, '[]') ||
          rangeStart.isBetween(startValue, endValue, null, '[]') ||
          rangeEnd.isBetween(startValue, endValue, null, '[]');
      });
      if (isOverlap) {
        notifyApiError( 'the date is already taken');
        return;
      }
    }
    const newDate = new Date(moment(startValue).format('YYYY/MM/DD')).toISOString().slice(0, 10);
    const newDate2 = new Date(moment(endValue).format('YYYY/MM/DD')).toISOString().slice(0, 10);
    try{
      setIsLoading(true);
      await API.createUserVacation({
        dateStart: newDate,
        dateEnd: newDate2,
      });
      notifySuccess('Created Successfully');
      resetForm();
      await getVacation();
      setIsLoading(false);
    }catch(e){
      notifyApiError(e);
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setShowForm(false);
    setEndValue(null);
    setStartValue(null);
  };

  const handleEditUser = (item) => {
    setStartValue(moment(item.start_date).utc())
    setEndValue(moment(item.end_date).utc())
    SetSelectVacation(item)
    setShowForm(true)
    setEditButton(true)
  };
  const handleEditSubmit = async () => {
    if(!startValue || !endValue) {
      notifyApiError('Please select date');
      return;
    }
    if(currentVacation.length > 0) {
      const isOverlap = currentVacation.some(dateRange => {
        if(dateRange.id === selectVacation.id) {
          return false;
        }
        const rangeStart = moment(dateRange.start_date, "YYYY-MM-DD");
        const rangeEnd = moment(dateRange.end_date, "YYYY-MM-DD");
        
        return startValue.isBetween(rangeStart, rangeEnd, null, '[]') ||
        endValue.isBetween(rangeStart, rangeEnd, null, '[]') ||
          rangeStart.isBetween(startValue, endValue, null, '[]') ||
          rangeEnd.isBetween(startValue, endValue, null, '[]');
      });
      if (isOverlap) {
        notifyApiError( 'the date is already taken');
        return;
      }
    }
    const newDate = new Date(moment(startValue)).toISOString().slice(0, 10);
    const newDate2 = new Date(moment(endValue)).toISOString().slice(0, 10);
    try{
      setIsLoading(true);
      await API.editUserVacation(
        selectVacation.id,
        {
          dateStart: newDate,
          dateEnd: newDate2,
        }
      );
      notifySuccess('Updated Successfully');
      resetForm();
      await getVacation();
      setIsLoading(false);
    }catch(e){
      notifyApiError(e);
      setIsLoading(false);
    }
  };

  const getVacation = async () => {
    const currentVacations = await API.getUserVacations(userInfo?.username);
    SetCurrentVacation(currentVacations);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await getVacation();
      } catch (e) {
        notifyApiError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div>
      <Spin tip="Loading..." spinning={isLoading}>
        <Row className="time_container" type="flex" justify="space-between">
          <Row type="flex" justify="start" align="middle">
            <Col md={24}>
              <h1 className="company__title">Time Off</h1>
            </Col>
            {currentVacation && currentVacation.length === 0 && (
            <Col md={24}>
              <p>There is no period created</p>
            </Col>
            )}
          </Row>
          {showForm === false && currentVacation && currentVacation.length > 0 && (
            currentVacation.map((item, index) => {
              if(item.end_date < moment().format("YYYY-MM-DD")){
                onDeletes(item.id)
                return null
              }
              return (
              <Row type="flex" justify="start" align="middle" key={index} className='periods'>
                <Col xs={24}>
                  <div className='period_title'>Period {index}</div>
                  <div>
                      <span>From {moment(item.start_date).utc().format("DD/MM/YYYY")} </span>
                      <Icon type="arrow-right" className='arrow_icon'/> 
                      <span>To {moment(item.end_date).utc().format("DD/MM/YYYY")} </span>
                      <span className="custom_icon" onClick={()=> handleEditUser(item)}>
                        <EditSvgIcon 
                          color="#6f66fd" 
                          width={15}
                          height={15}
                        />
                      </span>
                      <span className="custom_icon" onClick={()=> handleOpenModal(item)}>
                      <DeleteSvgIcon
                        color="#6f66fd"
                        width={15}
                        height={15}
                      />
                      </span>
                  </div>
                </Col>
              </Row>
            )})
          )}
          {showForm === false && (
            <Row type="flex" justify="start">
              <Col md={24}>
                <button className="time_form_btn" onClick={()=> handleOpenCloseForm()}>
                  <Icon type="plus" />
                  <p className='form_btn_title'>{currentVacation && currentVacation.length > 0 ? 'Add Another Period' : 'Add Period' }</p>
                </button>
              </Col>
            </Row> 
          )}
          {
            showForm === true && (
              <>
              <Tag color="#a39dff" className="form_title1">You can only create a period of time off that starts at least 7 days from now.</Tag>
              <Row type="flex" justify="space-between"  align="middle" className='form_title'>
                <Col md={11}>
                  <Row className="company__inputs">
                    <Text>Date of Start</Text>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={startValue}
                      placeholder="DD/MM/YYYY"
                      name='start_date'
                      onChange={onStartChange}
                      onOpenChange={handleStartOpenChange}
                      disabledDate={disabledDate}
                    />
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="company__inputs">
                    <Text>Date of End</Text>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={endValue}
                      name='end_date'
                      placeholder="DD/MM/YYYY"
                      onChange={onEndChange}
                      open={endOpen}
                      onOpenChange={handleEndOpenChange}
                      disabledDate={disabledDate}
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="end" gutter={4}>
                <Col md={6}>
                    <button className="company_form_btn2" onClick={() => setShowForm(!showForm)}>
                      Cancel
                    </button>
                  </Col>
                  {editButton === true ? (
                    <Col md={6}>
                    <button className="company_form_btn" onClick={handleEditSubmit}>
                        Edit
                      </button>
                    </Col>
                  ):(
                    <Col md={6}>
                      <button className="company_form_btn" onClick={handleSubmit}>
                        Save
                      </button>
                    </Col>
                  )}
              </Row> 
              </>
            )
          }
        </Row>
        <Modal
            visible={showModal}
            onCancel={handleCloseModal}
            footer={null}
            centered={true}
            closable={false}
            width={500}
            className="delete-modal22"
            title={null}>
            <Row type="flex" align="middle" className="delete-body">
              <Row>
                <Text className="label">The administrator will be notified of your request to eliminate this vacation period</Text>
              </Row>
              <Row className='btn_modal'>
                <Button type="primary" size="default" className='green-btn button custom-btn' onClick={() => onDelete()}>
                  Ok
                </Button>
              </Row>
            </Row>
          </Modal>
      </Spin>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, null)(TimeOff);
