const defaultMessage = {
  headerTitle: 'Add New Assignment',
  note: 'Note',
  client: 'Client',
  placeholder: 'Select a person',
  claimants: 'Claimants',
  assignTo: 'Assign To',
  report: 'Report',
  startDate: 'Start Date',
  endDate: 'Due Date',
  confirmLabel: 'Create',
  headerTitleEdit: 'Edit Assignment',
};

export default defaultMessage;
