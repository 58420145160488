import React, { useState } from 'react';
import { CloseOutlined, ProfileOutlined } from '@ant-design/icons';
import { Button, Drawer, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { closeDrawer, openDrawer } from 'redux/Drawer/DrawerActions';
import ODG from '../ODG/Odg';
import ResourceLinks from '../ResourceLinks/ResourceLinks';
import AssignmentsTab from './components/AssignmentsTab';

import './Rightbar.scss';

const { TabPane } = Tabs;

export default function RightBar() {
  const [placement, setPlacement] = useState('right');
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { reportInfo } = useSelector(state => state.report);
  let history = useHistory();

  const onClose = () => {
    dispatch(openDrawer());
    setIsOpenDrawer(false);
  };

  setInterval(() => {
    let route = history.location.pathname;
    if (
      route.includes('app/report') ||
      route.includes('app/template/create/structure') ||
      route.includes('app/template/create/color') ||
      route.includes('app/template/edit/structure') || 
      route.includes('app/template/edit/color')
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, 2000);

  function callback() {}
  if (!show) {
    return <span></span>;
  }

  return (
    <>
      {!isOpenDrawer && (
        <div className="rightbar">
          <div
            className="rightbar-logo"
            onClick={() => {
              dispatch(closeDrawer());
              setIsOpenDrawer(true);
            }}>
            <ProfileOutlined />
          </div>
        </div>
      )}
      <Drawer
        title="Report Utilities"
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={isOpenDrawer}
        key={placement}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="ODG" key="1">
            <ODG />
          </TabPane>
          {!reportInfo.is_template && (
            <TabPane tab="Review" key="2">
              <AssignmentsTab />
            </TabPane>
          )}
          <TabPane tab="Resource Links" key="3">
            <ResourceLinks reportInfo={reportInfo} toggleOpen={isOpenDrawer} />
          </TabPane>
        </Tabs>

        <Button
          onClick={onClose}
          type="text"
          style={{
            position: 'absolute',
            top: 8,
            right: 7,
            border: 0,
            background: 'transparent',
          }}>
          <CloseOutlined />
        </Button>
      </Drawer>
    </>
  );
}
