import React, { useState, useEffect } from 'react';
import * as API from 'utils/api';
import { useHistory } from 'react-router-dom';

// Components
import { Input, Select, Icon, Button } from 'antd';
import Pagination from 'components/Pagination';
import { Header } from 'components/Header';
import { StepClientAttorney } from './Components/StepClientAttorney';
import { LitigationsTabs } from './Components/LitigationsTabs';
import { StepMSAAttorney } from './Components/StepMSAAttorney';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Const
import { STEP_CLIENT_ATTORNEY, STEP_MSA_ATTORNEY } from './litigationsConst';

// Style
import './litigationAttorneyPage.scss';
import { EXTERNAL_LITIGATION_ATTORNEY_ID } from 'layout/roles';
import { NEW_LITIGATION_ATTORNEY_NAV } from 'layout/navigate_const';
import { useSelector } from 'react-redux';
import { LITIGATION_ATTORNEY_NAME, getPermissionsBySection } from 'permissions';

const { Option } = Select;
const { Search } = Input;

const VISIBILITY_PRIVATE = 'private';
const VISIBILITY_PUBLIC = 'public';
const VISIBILITY_ALL = 'all';

const LitigationAttorneyPage = () => {
  const history = useHistory();

  const { roles } = useSelector(state => state.auth);

  // Permissions
  const { LITIGATION_ATTORNEY_PERMISSION } = getPermissionsBySection({
    roles: roles,
    permissions: [LITIGATION_ATTORNEY_NAME],
  });
  // end permissions

  // States
  const [step, setStep] = useState(STEP_MSA_ATTORNEY);
  const [listLitigationMSA, setListLitigationMSA] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [filterVisibility, setFilterVisibility] = useState(VISIBILITY_ALL);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getListLitigationMSA();
  }, [pageIndex]);

  // Handlers
  const getListLitigationMSA = async ({ visibility = VISIBILITY_PUBLIC, group } = {}) => {
    try {
      setLoading(true);

      const body = {
        pageIndex,
        search,
      };

      if (visibility !== VISIBILITY_ALL) body.visibility = visibility === VISIBILITY_PRIVATE ? false : true;
      if (group) body.group = true;

      const listLitigation = await API.getAllLitigationAttorney(body);
      setListLitigationMSA(listLitigation.rows);
      setTotalCount(listLitigation.count);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStep = async ({ step }) => {
    setStep(step);
    if (search) setSearch('');

    if (step === STEP_MSA_ATTORNEY) return await getListLitigationMSA();

    return await getListLitigationMSA({ visibility: VISIBILITY_ALL, group: true });
  };

  const handlePagination = pageIndex => {
    setPageIndex(pageIndex);
  };

  const handleChangeFavorite = async ({ userId, range }) => {
    try {
      setLoading(true);
      await API.EditRole(userId, EXTERNAL_LITIGATION_ATTORNEY_ID, {
        range: !range,
      });

      const body = {
        visibility: filterVisibility,
      };

      if (step === STEP_CLIENT_ATTORNEY) body.group = true;

      await getListLitigationMSA(body);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterVisibilityType = async ({ visibility }) => {
    try {
      setLoading(true);
      setFilterVisibility(visibility);

      if (step === STEP_MSA_ATTORNEY) return await getListLitigationMSA({ visibility });

      return await getListLitigationMSA({ visibility, group: true });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="litigationAttorney">
      {loading && <ModalLoading />}
      <Header
        left={
          <div className="litigationAttorney_left">
            <Search
              placeholder="Search..."
              className="search-box"
              value={search}
              onChange={e => setSearch(e.target.value)}
              onSearch={() =>
                step === STEP_MSA_ATTORNEY
                  ? getListLitigationMSA()
                  : getListLitigationMSA({ group: step === STEP_CLIENT_ATTORNEY, visibility: filterVisibility })
              }
            />

            {step === STEP_CLIENT_ATTORNEY && (
              <div className="search_filter">
                <Select
                  showSearch
                  placeholder="Visibility Type"
                  optionFilterProp="children"
                  className="dropdown"
                  onChange={value => handleFilterVisibilityType({ visibility: value })}
                  suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                  <Option value={VISIBILITY_ALL}>All</Option>
                  <Option value={VISIBILITY_PRIVATE}>Private</Option>
                  <Option value={VISIBILITY_PUBLIC}>Public</Option>
                </Select>
              </div>
            )}
          </div>
        }
        right={
          <div className="litigationAttorney_right">
            {LITIGATION_ATTORNEY_PERMISSION.create && (
              <Button
                type="primary"
                className="green-btn"
                onClick={() => history.push(NEW_LITIGATION_ATTORNEY_NAV.path)}>
                Add Attorney
              </Button>
            )}
            <div className="litigationAttorney_header_right">
              <Pagination onChange={handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
            </div>
          </div>
        }>
        Litigation Attorneys
      </Header>

      <LitigationsTabs step={step} onChange={handleChangeStep} />

      {step === STEP_MSA_ATTORNEY && (
        <StepMSAAttorney listLitigation={listLitigationMSA} handleChangeFavorite={handleChangeFavorite} />
      )}

      {step === STEP_CLIENT_ATTORNEY && (
        <StepClientAttorney
          listLitigation={listLitigationMSA}
          handleChangeFavorite={handleChangeFavorite}
          updateList={() => getListLitigationMSA({ group: true, visibility: filterVisibility })}
        />
      )}
    </div>
  );
};

export default LitigationAttorneyPage;
