import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Alert, Input, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import SpinBox from 'components/SpinBox';
import InputCurrencyBox from 'components/InputCurrencyBox';
import { validateInteger, validatePrice } from '../../../utils/utils';

import './UpdatePricingPlan.scss';
import CustomCodeModal from '../components/CustomCodeModal/CustomCodeModal';

const { Option } = Select;

export default function UpdatePricingPlan({
  isOpen,
  onCancel,
  loading,
  pricingPlanData,
  onLevelChange,
  onPlanPriceChange,
  deleteLevel,
  updateSurgicalPlan,
  addLevel,
  addLevels,
  onLevelNewChange,
  deleteAddedLevels,
  priceLevelError,
  levelError,
  searchCodes,
  cptList,
  handleChange,
  changeCptList,
}) {
  const [customCodeModal, setCustomCodeModal] = useState(false);
  const [customCode, setCustomCode] = useState(null);

  if (customCode) {
    pricingPlanData.cpt_code.code = customCode.code;
  }

  useEffect(() => {
    if (customCode) {
      changeCptList(customCode);
      handleChange(customCode.id);
    }
  }, [customCode, handleChange, changeCptList]);

  let disabled = false;

  let updateDisabled = false;
  if (!validatePrice(pricingPlanData.price)) {
    updateDisabled = true;
  }
  for (const data of addLevel) {
    if (!validateInteger(data.level) || !validatePrice(data.price) || data.price <= 0) {
      updateDisabled = true;
      break;
    }
  }

  if (pricingPlanData?.levels) {
    for (const data of pricingPlanData.levels) {
      if (!validateInteger(data.level_number) || !validatePrice(data.price) || data.price <= 0) {
        updateDisabled = true;
        break;
      }
    }
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      className="report-modal"
      title={'Update Pricing Plan'}>
      <Row type="flex" align="middle" className="report-body update-pricing-plan">
        <Row type="flex" align="middle" className="main-info">
          <SpinBox loading={loading}>
            <Row type="flex" className="main-price">
              <Col md={16} className="row">
                <Row>
                  <span>Code</span>
                </Row>
                <Row>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Required"
                    value={pricingPlanData.cpt_code?.code || customCode?.code}
                    onChange={handleChange}
                    onSearch={value => {
                      if (value) {
                        searchCodes(value);
                      }
                    }}>
                    {cptList &&
                      cptList.map(item => {
                        return (
                          <Option
                            value={item.id}
                            name={item.short_descr}
                            code={item.code}
                            filter={item.code.concat('_' + item.med_descr)}
                            key={item.id}>
                            {item.code + ' (' + item.short_descr + ')'}
                          </Option>
                        );
                      })}
                  </Select>
                </Row>
              </Col>
              <Col md={7} offset={1} className="row">
                <Row>
                  <span>Price</span>
                </Row>
                <InputCurrencyBox
                  name="price"
                  noNegative={true}
                  value={pricingPlanData.price}
                  onChange={onPlanPriceChange}
                  placeholder="Required"
                />
              </Col>
              <Button
                className="green-btn button custom-code-btn"
                type="primary"
                onClick={() => setCustomCodeModal(true)}>
                Add Custom Code
              </Button>
            </Row>

            {pricingPlanData.levels &&
              pricingPlanData.levels.map((data, index) => {
                if (!data.price || !validatePrice(data.price) || Number(data.price) <= 0) {
                  disabled = true;
                }
                if (!data.level_number || !validateInteger(data.level_number)) {
                  disabled = true;
                }

                return (
                  <Row type="flex" key={index} style={{ width: '100%' }}>
                    <Col md={16} className="row">
                      <Row>
                        <span>Level</span>
                      </Row>
                      <Input
                        placeholder="Required"
                        size="small"
                        name="level_number"
                        value={data.level_number}
                        onChange={e => {
                          onLevelChange('level_number', e.target.value, index);
                        }}
                      />
                    </Col>

                    <Col md={7} offset={1} className="row">
                      <Row>
                        <span>Price</span>
                      </Row>
                      <InputCurrencyBox
                        noNegative={true}
                        placeholder="Required"
                        value={data.price}
                        name="price"
                        onChange={value => onLevelChange('price', value, index)}
                      />
                      <DeleteOutlined onClick={() => deleteLevel(data.id)} className="delete-plan-btn" />
                    </Col>
                  </Row>
                );
              })}

            {addLevel &&
              addLevel.map((data, index) => {
                if (!data.price || Number(data.price) <= 0) {
                  disabled = true;
                }
                if (!data.level || !validateInteger(data.level)) {
                  disabled = true;
                }
                return (
                  <Row key={index} type="flex" align="middle" style={{ width: '100%' }}>
                    <Col md={16} className="row">
                      <Row>
                        <span>Level</span>
                      </Row>
                      <Input
                        size="small"
                        name="level"
                        placeholder="Required"
                        value={data.level}
                        onChange={e => {
                          onLevelNewChange('level', e.target.value, index);
                        }}
                      />
                    </Col>

                    <Col md={7} offset={1} className="row">
                      <Row>
                        <span>Price</span>
                      </Row>
                      <InputCurrencyBox
                        noNegative={true}
                        value={data.price}
                        name="price"
                        placeholder="Required"
                        onChange={value => {
                          onLevelNewChange('price', value, index);
                        }}
                      />
                      <DeleteOutlined onClick={() => deleteAddedLevels(index)} className="delete-plan-btn" />
                    </Col>
                  </Row>
                );
              })}
          </SpinBox>

          <Row style={{ width: '100%', marginTop: 10 }}>
            {priceLevelError && <Alert type="error" message={priceLevelError} banner />}
            {levelError && <Alert type="error" message={levelError} banner />}
          </Row>

          <Row>
            <Button
              disabled={disabled}
              type="primary"
              size="default"
              className="green-btn button"
              onClick={addLevels}
              loading={loading}>
              {addLevel?.length || pricingPlanData?.levels?.length ? 'Add Another' : 'Add Level'}
            </Button>
          </Row>
        </Row>

        <Button
          disabled={updateDisabled}
          type="primary"
          size="large"
          className="green-btn button"
          onClick={updateSurgicalPlan}
          loading={loading}>
          Update
        </Button>
      </Row>
      <CustomCodeModal
        setCustomCode={setCustomCode}
        customCodeModal={customCodeModal}
        setCustomCodeModal={setCustomCodeModal}
      />
    </Modal>
  );
}
