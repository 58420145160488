import React, { Component } from 'react';
import { Row, Col, Typography, Button, Select, Icon, Modal } from 'antd';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

import InputBox from 'components/InputBox';
import { BackIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';

import './AddTemplate.scss';

const { Title, Text } = Typography;
const { Option } = Select;

export default class AddTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportSections: [],
      reportType: 1,
      title: '',
      loadingTitle: 'Loading...',
      loading: false,
      reportTypes: [],
    };
  }

  componentDidMount() {
    API.getReportSections(this.state.reportType).then(res => {
      this.setState({
        reportSections: res.reportSections,
      });
    });
    API.getReportTypes().then(res => {
      this.setState({ reportTypes: res.reportTypes });
    });
  }

  handleSetValue = (type, value) => {
    this.setState(
      {
        [type]: value,
      },
      () => {
        if (type === 'reportType') {
          API.getReportSections(this.state.reportType).then(res => {
            this.setState({
              reportSections: res.reportSections,
            });
          });
        }
      }
    );
  };

  itemRenderer = (item, index) => {
    return (
      <div className="report_item">
        <div className="name">{item.name}</div>
        <img src={require('../../../assets/icons/ico-menu.png')} className="menu-icon" alt="menu" />
      </div>
    );
  };

  handleRLDDChange = items => {
    this.setState({ reportSections: items });
  };

  handleCreateTemplate = () => {
    const { title, reportType, reportSections } = this.state;

    const templateParam = {
      name: title,
      report_type_id: reportType,
    };
    this.setState({ loadingTitle: 'Saving...', loading: true });
    API.createTemplate(templateParam)
      .then(res => {
        const templateOrder = reportSections.map((item, index) => {
          return {
            template_id: res.id,
            report_section_id: item.id,
            order: index,
          };
        });
        const templateOrderParam = {
          template_orders: templateOrder,
        };
        API.createTemplateOrder(templateOrderParam)
          .then(res => {
            this.setState({ loading: false });
            Modal.success({
              content: 'Success',
              onOk: () => this.props.history.goBack(),
            });
          })
          .catch(error => {
            this.setState({ loading: false });
            const errorMessage = error.response.data.reason ? error.response.data.reason[0].message : 'Error';
            Modal.error({
              content: errorMessage,
            });
          });
      })
      .catch(error => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason ? error.response.data.reason[0].message : 'Error';
        Modal.error({
          content: errorMessage,
        });
      });
  };

  render() {
    const { reportSections, title, reportType, loading, loadingTitle, reportTypes } = this.state;
    return (
      <Row className="add-template-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Add New Template
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox label="Title" value={title} onChange={value => this.setState({ title: value })} />
                </Col>
                <Col md={11} className="row">
                  <Row>
                    <Text>Report type</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={reportType}
                      onChange={value => this.handleSetValue('reportType', value)}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {reportTypes.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row className="list">
                <RLDD
                  cssClasses="list-content"
                  items={reportSections}
                  itemRenderer={this.itemRenderer}
                  onChange={this.handleRLDDChange}
                />
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button type="primary" size="large" className="green-btn button" onClick={this.handleCreateTemplate}>
                  Create Template
                </Button>
              </Row>
              <Row type="flex" justify="center">
                <Row className="cancel-button" onClick={() => this.props.history.goBack()}>
                  <Text>Cancel</Text>
                </Row>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
