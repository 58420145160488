import React, { Component } from 'react';
import { Row, Col, List, Typography, Button, Select, Modal, Icon } from 'antd';
import InputMask from 'react-input-mask';
import Highlighter from 'react-highlight-words';
import dynamicData from 'dynamicData';

import InputBox from 'components/InputBox';
import { BackIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
// import * as Utils from 'utils/utils';
import { getOrganizations } from 'utils/api-contacts';
import Address from 'components/Address';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';

import './AddContact.scss';
import { connect } from 'react-redux';

const { Title, Text } = Typography;
const { Option } = Select;

class AddContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contact_name: '',
      organisation_name: '',
      address: '',
      phone_number: '',
      fax_number: '',
      email: '',
      contact_type: props.contact_type_id || null,
      loading: false,
      loadingTitle: 'Loading...',
      specialities: [],
      speciality_id: null,
      contact_list: [],
      allContacts: [],
      organizations: [],
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };
  handleSetContactType = value => {
    this.handleSetValue('contact_type', value);
    this.handleSetValue('speciality_id', null);
  };

  handleCreateUser = () => {
    const {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type,
      // specialities,
      speciality_id,
    } = this.state;
    const param =
      this.props.removeHeader && this.props.another
        ? {
            contact_name,
            organisation_name,
            address,
            phone_number,
            fax_number,
            email,
            contact_type_id: this.props.contact_type_id || contact_type,
            speciality_id,
          }
        : {
            contact_name,
            organisation_name,
            address,
            phone_number,
            fax_number,
            email,
            contact_type_id: this.props.contact_type_id || contact_type,
            speciality_id,
          };

    if (!contact_name) {
      Modal.error({ content: 'Please input the Contact Name' });
      return;
    }

    // if (!organisation_name) {
    //   Modal.error({ content: 'Please input the Organization Name' });
    //   return;
    // }

    // if (!phone_number || phone_number.includes('_')) {
    //   Modal.error({ content: 'Please input the Phone number' });
    //   return;
    // }

    // if (fax_number !== '' && fax_number.includes('_')) {
    //   Modal.error({ content: 'Please input the Fax Number' });
    //   return;
    // }
    if (!contact_type && !this.props.removeHeader) {
      Modal.error({ content: 'Please select a contact type' });
      return;
    }
    // if (
    //   contact_type === dynamicData().plaintiff_attorney_id ||
    //   contact_type === dynamicData().defense_attorney_id ||
    //   param.contact_type_id === dynamicData().plaintiff_attorney_id ||
    //   param.contact_type_id === dynamicData().defense_attorney_id
    // ) {
    //   if (!email || !Utils.validateEmail(email)) {
    //     Modal.error({ content: 'Please input the Valid Email' });
    //     return;
    //   }
    // }
    // let newAddress = address.split(',');
    // if (newAddress[0] === '' || newAddress[2] === '' || newAddress[3] === '' || newAddress[4] === '') {
    //   Modal.error({ content: 'Please input the Address correctly' });
    //   return;
    // }
    if (!param.contact_type_id) {
      delete param.contact_type_id;
    }
    this.setState({ loadingTitle: 'Saving...', loading: true });
    API.createContact(param)
      .then(res => {
        this.setState({ loading: false });
        Modal.success({
          content: 'Success',
          onOk: () => {
            this.setState({
              contact_name: '',
              organisation_name: '',
              address: '',
              phone_number: '',
              fax_number: '',
              email: '',
              contact_type: null,
              loading: false,
              loadingTitle: 'Loading...',
              speciality_id: null,
            });
            if (this.props.func) {
              return this.props.func(res.contact);
            }
            return this.props.history.goBack();
          },
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason ? error.response.data.reason[0].message : 'Error';
        Modal.error({
          content: errorMessage,
        });
      });
  };
  getAddress = address => {
    this.setState({ address });
  };
  getSpecialities = () => {
    API.getSpeciality().then(res => {
      this.setState({ specialities: res.specialities });
    });
  };
  getContactTypes = () => {
    API.getContactTypes().then(res => {
      this.setState({ contact_list: res.contact_type });
    });
  };

  getOrganizations = async () => {
    try {
      const isUserAdmin = isAllowed(this.props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.SALES_MANAGER,
      ]);
      const isUserManager = isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER);

      const tenant_only = isUserManager && !isUserAdmin;

      const { contacts = [] } = await getOrganizations({ all: true, tenant_only });
      this.setState({
        allContacts: contacts.filter(
          //remove duplicates
          (v, i, a) => a.findIndex(c => c.organisation_name === v.organisation_name && c.address === v.address) === i
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleFilterOrganization = async value => {
    const { allContacts } = this.state;
    const name = value.trimStart();
    this.setState({
      organisation_name: name,
      organizations: !name
        ? allContacts
        : allContacts.filter(c => c.organisation_name?.toLowerCase().includes(name.toLowerCase())),
    });
  };

  onSelectOrganization = organization => () => {
    this.setState({
      organization,
      address: organization.address,
      organisation_name: organization.organisation_name,
      phone_number: organization.phone_number,
      fax_number: organization.fax_number,
      organizations: [],
    });
  };

  componentDidMount = () => {
    this.getOrganizations();
    this.getSpecialities();
    this.getContactTypes();
  };

  render() {
    const {
      contact_name,
      organisation_name,
      phone_number,
      fax_number,
      email,
      contact_type,
      loading,
      loadingTitle,
      specialities,
      speciality_id,
      contact_list,
      organizations,
    } = this.state;

    return (
      <Row className="add-user-container">
        {!this.props.removeHeader && (
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Add New Contact
              </Title>
            </Col>
            <Col className="back" onClick={() => this.props.history.goBack()}>
              <BackIcon className="icon" />
            </Col>
          </Row>
        )}
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox
                    label="Contact Name"
                    value={contact_name}
                    onChange={value => this.setState({ contact_name: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Organization Name"
                    placeholder=""
                    value={organisation_name}
                    onChange={this.handleFilterOrganization}
                    onFocus={() => this.handleFilterOrganization(organisation_name)}
                    onBlur={() => setTimeout(() => this.setState({ organizations: [] }), 250)}
                  />
                  {organizations.length > 0 && (
                    <List
                      className="organization-list"
                      size="small"
                      bordered
                      dataSource={organizations}
                      renderItem={c => {
                        const formattedAddress = c.address
                          .split(',')
                          .reduce((acc, current) => acc + (current.trim() ? `,${current}` : ''));
                        return (
                          <List.Item onClick={this.onSelectOrganization(c)}>
                            <Highlighter
                              highlightStyle={{
                                backgroundColor: '#ffc069',
                                padding: 0,
                              }}
                              searchWords={[organisation_name]}
                              autoEscape
                              textToHighlight={c.organisation_name}
                            />
                            &nbsp;(
                            {formattedAddress.charAt(0) === ',' ? formattedAddress.substr(1) : formattedAddress})
                          </List.Item>
                        );
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <Address getAddress={this.getAddress} address={this.state.address} />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <InputMask
                    className="input-ssn"
                    mask="999-999-9999"
                    value={phone_number}
                    onChange={e => this.setState({ phone_number: e.target.value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Fax Number</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    placeholder=""
                    className="input-ssn"
                    value={fax_number}
                    onChange={e => this.setState({ fax_number: e.target.value })}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox
                    placeholder=''
                    label="Email"
                    value={email}
                    onChange={value => this.setState({ email: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <Row>
                    <Text>Contact Type</Text>
                  </Row>
                  <Row>
                    <Select
                      disabled={this.props.disableTypeEditing}
                      showSearch
                      placeholder=""
                      optionFilterProp="children"
                      className="dropdown"
                      value={contact_type || ''}
                      onChange={value => this.handleSetContactType(value)}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      <Option value="" disabled>
                        Required
                      </Option>
                      {contact_list.map((el, i) => {
                        return (
                          <Option key={i} value={el.id}>
                            {el.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Row>
                </Col>
                <Col md={11} className="row">
                  {contact_type === dynamicData().treating_providers_id ? (
                    <>
                      <Row>
                        <Text>Provider Specialty</Text>
                      </Row>
                      <Row>
                        <Select
                          showSearch
                          placeholder=""
                          optionFilterProp="children"
                          className="dropdown"
                          value={speciality_id}
                          onChange={value => this.handleSetValue('speciality_id', value)}
                          suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                          {specialities.map((el, i) => {
                            return (
                              <Option key={i} value={el.id}>
                                {el.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button type="primary" size="large" className="green-btn button" onClick={this.handleCreateUser}>
                  Create
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, {})(AddContact);
