import React from 'react';
import { Typography, Row, Col, Empty } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';

import './transactionHistory.scss';
import Pagination from 'components/Pagination';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { BLUE_ICON } from 'containers/Calendar/src/src/lib/utils/colors';
import { useTransaction } from 'hooks';

const { Title, Text } = Typography;

const TransactionHistory = ({ listTransaction, countTransaction, loading, pageTransaction, onPagination }) => {
  const { loadingTransaction, downLoadTransactionHook } = useTransaction();

  const dataURItoBlob = dataURI => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  };

  const handleDownload = async ({ item }) => {
    const data = await downLoadTransactionHook({ id: item.id });
    const blob = dataURItoBlob(data);
    const url = URL.createObjectURL(blob);

    window.open(url, '_blank');
  };

  return (
    <>
      {loadingTransaction && <ModalLoading />}
      <div className="transactionHistory">
        <Title level={4}>Transactions History</Title>
        <Row type="flex" className="transactionHistory_header">
          <Col md={7}>Transaction Number</Col>
          <Col md={7}>Date</Col>
          <Col md={7}>Paid</Col>
          <Col md={3} style={{ textAlign: 'right', paddingRight: '30px' }}>
            Actions
          </Col>
        </Row>
        <Row>
          {listTransaction.length > 0 ? (
            <>
              {listTransaction.map(item => (
                <Row className="transactionHistory_item" key={item.id}>
                  {/* Id */}
                  <Col md={7}>
                    <Text>{item?.payment_invoice?.transaction_id || '000000'}</Text>
                  </Col>
                  {/* Date */}
                  <Col md={7}>
                    <Text>{moment(item.date).format('DD/MM/YYYY')}</Text>
                  </Col>
                  {/* Paid */}
                  <Col md={7}>
                    <Text className={item?.service_type?.name === 'REFUND' ? 'transactionHistory_refund' : ''}>
                      {item?.service_type?.name === 'REFUND'
                        ? `+$${item?.payment_invoice?.payment ?? '0'}`
                        : `$${item?.payment_invoice?.payment ?? '0'}`}
                    </Text>
                  </Col>

                  {/* Actions */}
                  <Col md={3} className="transactionHistory_actions">
                    <DownloadOutlined
                      height={'20px'}
                      style={{ color: BLUE_ICON, fontSize: 18 }}
                      width={'20px'}
                      className="icon"
                      onClick={() => handleDownload({ item })}
                    />
                  </Col>
                </Row>
              ))}

              <div className="transactionHistory_pagination">
                <Pagination
                  onChange={newPage => onPagination({ page: newPage })}
                  totalCount={countTransaction}
                  pageIndex={pageTransaction}
                  pageSize={10}
                />
              </div>
            </>
          ) : (
            <Empty description="No Data" image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
          )}
        </Row>
      </div>

      {loading && <ModalLoading />}
    </>
  );
};

export default TransactionHistory;
