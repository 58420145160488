import React, { useState } from 'react';
import { Button, Col, Input, Modal, Row, Typography, Alert } from 'antd';
import SpinBox from 'components/SpinBox';
import { DeleteOutlined } from '@ant-design/icons';
import './CalculateRateAge.scss';
import * as API from 'utils/api';
import { useEffect } from 'react';
import {notifyApiError, notifySuccess} from "../../utils/notification";
import { parse } from 'query-string';
const { Text } = Typography;

export default function CalculateRateAge({ isOpen, onCancel, changeRatedAges, claimant_id }) {
  const [RatedAgesData, setRatedAgesData] = useState([
    {
      ratedAges: null,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [sourceId, setSourceId] = useState(0);

  const handleChange = (key, value, index) => {
    // const { name, value } = e.target;
    let tempArr = [...RatedAgesData];
    tempArr[index][key] = value;
    setRatedAgesData(tempArr);
  };

  const addRatedAgesFields = () => {
    setRatedAgesData([...RatedAgesData, { ratedAges: null }]);
  };
  const removeRatedAges = index => {
    let tempArr = [...RatedAgesData];
    tempArr.splice(index, 1);
    setRatedAgesData(tempArr);
  };
  const [total, setTotal] = useState(null);
  const [error, showError] = useState(null);
  const onCalculate = () => {
    let tempArr = [...RatedAgesData];
    let sum = 0;
    let nrs = 0;
    const data = tempArr.map(item => {
      if (parseFloat(item.ratedAges) && !isNaN(parseFloat(item.ratedAges))) {
        nrs++;
        sum += parseFloat(item.ratedAges);
      }
    });
    if (nrs < 1) {
      return Modal.error({ content: 'Please enter at least one valid number.' });
    }
    console.table(tempArr);
    if (tempArr.length === 1) {
      //return setTotal(sum + ' years old'); -- old
      return setTotal(sum);
    }

    let number = sum / nrs;
    if (number === 0) {
      setTotal(0);
    } else {
      setTotal(Math.round(number * 10) / 10);
    }
  };
  const changeLifeExpectency = (claimant_id, life_expectancy) => {
    API.changeReportsELife(claimant_id, life_expectancy)
      .then(() => {
        notifySuccess(`Claimant's life expoectency changed to ${life_expectancy}.`);
        notifySuccess(`You need to regenerate claimant's reports.`);
        setLoading(false);        
      })
      .catch(e => {
        notifyApiError(e);
        setLoading(false);
      });
  }
  const getLifeExpectency = () => {
    setLoading(true);
    const param = {
      source_id: sourceId,
      life_table_id: 1,
    };
    API.getLifeExpect(claimant_id, param)
      .then(res => {
        const life_expectancy = Math.round(res.years_left);
        changeLifeExpectency(claimant_id, life_expectancy);
      })
      .catch(e => {
        notifyApiError(e);
        setLoading(false);
      });
  }

  useEffect(()=>{
    API.getLifeSources().then(res => {
      setSourceId(res.life_expects[0].id);
    });
  },[])

  return (
    <div>
      {' '}
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={'Calculate Rated Age'}>
        <SpinBox loading={loading}>
          <Row type="flex" align="middle" className="report-body">
            {RatedAgesData.map((data, index) => (
              <Col md={24} className="row" key={index}>
                <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                  {' '}
                  <Text>{`Rated Age ${index + 1}`}</Text>
                </Row>
                <Input
                  name="ratedAges"
                  min={0}
                  type="number"
                  value={data.ratedAges}
                  onChange={e => {
                    if (e.target.value < 0) {
                      return showError('Number should be positive');
                    }
                    if (showError) {
                      showError(null);
                    }
                    handleChange('ratedAges', e.target.value, index);
                  }}
                />

                {index !== 0 && (
                  <DeleteOutlined onClick={() => removeRatedAges(index)} className="delete-calculated-btn" />
                )}
              </Col>
            ))}
          </Row>
          <Button className="rated-fields-btn" onClick={addRatedAgesFields}>
            Add Rated Age
          </Button>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            {error && <Alert type="error" message={error} banner style={{ display: 'block', marginBottom: '6px' }} />}
            <Button type="primary" size="default" className="green-btn button" onClick={onCalculate}>
              Calculate
            </Button>
          </Row>
          {total && (
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 10,
                paddingBottom: 10,
              }}>
              <hr />
              Average rated age : {total}
              <hr />
            </Row>
          )}
          {total && (
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="primary"
                size="default"
                className="green-btn button "
                onClick={() => {
                  API.updateClaimant({ 
                    id: claimant_id,
                    rated_age: total,
                  }).then(res => {
                    changeRatedAges(total);
                    setRatedAgesData([
                      {
                        ratedAges: '',
                      },
                    ]);
                    getLifeExpectency();
                  });
                }}>
                Edit rated age with this value
              </Button>
            </Row>
          )}
        </SpinBox>
      </Modal>
    </div>
  );
}
