import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TemplateLifeCarePlanSectionTableContentDraggable } from '../TemplateLifeCarePlanSectionTableContent';
import { ModalCreateTable } from '../ModalCreateTable';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';
import * as API from 'utils/api';

// Const
import { LIST_NO_EDIT_TABLE } from '../../const/itemsTableDragable';

const TemplateLifeCarePlanSectionTable = ({ item, index, updateList }) => {
  const { idReport } = useParams();
  const [editTable, setEditTable] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);

  const handleDeleteTable = async ({ idTable }) => {
    try {
      setLoadingPage(true);
      await API.deleteTemplateLifeCarePlanTable({ idTable, reportId: idReport, tableType: item.name });
      updateList && updateList();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleEditTable = ({ table }) => {
    setEditTable(table);
  };

  return (
    <>
      <TemplateLifeCarePlanSectionTableContentDraggable
        data={item}
        index={index}
        onDelete={handleDeleteTable}
        onEdit={!LIST_NO_EDIT_TABLE.includes(item.name) && handleEditTable}
        className="lifeCarePlanTemplate_table"
      />

      {loadingPage && <ModalLoading />}

      {editTable && (
        <ModalCreateTable onClose={() => setEditTable(null)} editTable={editTable} onUpdaleList={updateList} />
      )}
    </>
  );
};

export default TemplateLifeCarePlanSectionTable;
