import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Divider, Select, Input, Icon } from 'antd';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MSA_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

import './FuturePrescription.scss';

const { Title, Text } = Typography;
const { Option } = Select;

const calculationMethodItems = ['Average Wholesale Price', 'Actual Charges', 'Fee Schedule', 'Other'];

class FuturePrescription extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      future_prescription_drug_treatment_calculation_method: 'Average Wholesale Price',
      loadingTitle: 'Loading',
      loading: false,
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };

  componentDidMount = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        let { future_prescription_drug_treatment_calculation_method } = reportInfo;
        let future_prescription_drug_treatment_calculation_method_text = '';
        if (!calculationMethodItems.includes(future_prescription_drug_treatment_calculation_method)) {
          future_prescription_drug_treatment_calculation_method_text =
            future_prescription_drug_treatment_calculation_method;
          future_prescription_drug_treatment_calculation_method = 'Other';
        }
        this.setState({
          is_template: reportInfo.is_template,
          reportInfo,
          future_prescription_drug_treatment_calculation_method_text,
          future_prescription_drug_treatment_calculation_method:
            future_prescription_drug_treatment_calculation_method || 'Average Wholesale Price',
          loading: false,
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  };

  componentDidUpdate = prevProps => {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const { future_prescription_drug_treatment_calculation_method } = reportInfo;
      this.setState({
        future_prescription_drug_treatment_calculation_method:
          future_prescription_drug_treatment_calculation_method || 'Average Wholesale Price',
        loading: false,
      });
    }
  };

  handleUpdateReport = async (isNextStep = false) => {
    const {
      report_id,
      future_prescription_drug_treatment_calculation_method,
      future_prescription_drug_treatment_calculation_method_text,
    } = this.state;

    let future_prescription_drug_treatment_calculation_method_value =
      future_prescription_drug_treatment_calculation_method;
    if (future_prescription_drug_treatment_calculation_method === 'Other') {
      future_prescription_drug_treatment_calculation_method_value =
        future_prescription_drug_treatment_calculation_method_text;
    }

    const params = {
      id: report_id,
      future_prescription_drug_treatment_calculation_method:
        future_prescription_drug_treatment_calculation_method_value,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSelectChange = value => {
    this.setState({
      future_prescription_drug_treatment_calculation_method: value,
      future_prescription_drug_treatment_calculation_method_text: '',
    });
  };

  render() {
    const {
      future_prescription_drug_treatment_calculation_method,
      future_prescription_drug_treatment_calculation_method_text,
      loadingTitle,
      loading,
      REPORT_PERMISSIONS,
    } = this.state;

    return (
      <Row className="msa-future-prescription-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown beforeNext={async () => await this.handleUpdateReport(false)} loading={loading} />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Future Prescription Drug Treatment Calculation Method</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Calculation method</Text>
                </Col>
                <Col>
                  <Row>
                    <Select
                      disabled={!REPORT_PERMISSIONS.edit}
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={future_prescription_drug_treatment_calculation_method}
                      onChange={this.handleSelectChange}
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      {calculationMethodItems.map(item => (
                        <Option key={item}>{item}</Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              {future_prescription_drug_treatment_calculation_method === 'Other' && (
                <Row type="flex" className="row">
                  <Col className="label"></Col>
                  <Col>
                    <Row>
                      <Input
                        disabled={!REPORT_PERMISSIONS.edit}
                        placeholder=""
                        className="calculation_method-input"
                        value={future_prescription_drug_treatment_calculation_method_text}
                        onChange={e =>
                          this.setState({
                            future_prescription_drug_treatment_calculation_method_text: e.target.value,
                          })
                        }
                      />
                    </Row>
                  </Col>
                </Row>
              )}
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(FuturePrescription);
