import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

// Components
import { TemplateLifeCarePlanSectionTableContent } from '.';

const TemplateLifeCarePlanSectionTableContentDraggable = ({ data, index, onEdit, onDelete, className }) => {
  return (
    <Draggable draggableId={`${data.id}s`} index={index}>
      {provided => (
        <div className="" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <TemplateLifeCarePlanSectionTableContent
            className={className}
            data={data}
            onEdit={onEdit}
            onDelete={onDelete}
            isDraggable
          />
        </div>
      )}
    </Draggable>
  );
};

export default TemplateLifeCarePlanSectionTableContentDraggable;
