import React from 'react';
import { CSVLink } from 'react-csv';

import { formatDatePart } from 'utils/utils';
import { REPORT_SECTIONS_DEFAULT, REPORT_TYPES_BY_ID } from '../../../../constants/reports';

import './ExportButton.scss';

const ExportButton = ({ friendlyKeys, children, reports, createdAt, inlineStyle = false }) => {
  const convertArrayToCSV = args => {
    let result, ctr, keys, columnDelimiter, lineDelimiter, correctKeys, commaDelimiter;

    if (reports == null || !reports.length) {
      return null;
    }

    columnDelimiter = args?.columnDelimiter || '\t';
    lineDelimiter = args?.lineDelimiter || '\n';
    commaDelimiter = args?.commaDelimiter || ',';

    keys = Object.keys(reports[0]).concat(['link']);
    keys = keys.filter(key => {
      if (friendlyKeys[key]) {
        return key;
      }
    });
    correctKeys = keys.map(key => friendlyKeys[key]);

    result = '';
    //result += correctKeys.join(columnDelimiter);
    result += correctKeys.join(commaDelimiter);
    result += lineDelimiter;
    const linkOfReport = Object.keys(friendlyKeys).indexOf('link');
    reports.forEach(item => {
      ctr = 0;
      for (const key of keys) {
        if (ctr > 0) result += commaDelimiter;
        if (key === 'link' && linkOfReport >= 0) {
          const type = REPORT_TYPES_BY_ID[item.report_type_id];
          const firstStep =
            item.sections !== null && Array.isArray(item.sections) && item.sections.length
              ? item.sections[0]
              : REPORT_SECTIONS_DEFAULT[type][0];

          result += `${process.env.REACT_APP_BASE_URL}/app/reports/${item.id}/${type}/${firstStep}`;
        } else {
          result += item[key] ? item[key] : '';
        }

        ctr++;
      }
      result += lineDelimiter;
    });

    return result;
  };

  const generateFileName = baseName => {
    if (!createdAt) {
      return `${baseName}.csv`;
    }

    const date = new Date(createdAt);

    const fullDate = [formatDatePart(date.getMonth() + 1), formatDatePart(date.getDate()), date.getFullYear()];

    return `${baseName}-${fullDate.join('-')}.csv`;
  };

  return (
    <div className={inlineStyle ? 'export-wrapper-inline' : 'export-wrapper'}>
      {typeof convertArrayToCSV() === 'string' ? (
        <CSVLink data={convertArrayToCSV()} filename={generateFileName('report_cabinet')}>
          {children}
        </CSVLink>
      ) : (
        <a disabled>{children}</a>
      )}
    </div>
  );
};

export default ExportButton;
