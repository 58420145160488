import React, { Component } from 'react';
import { Row, Col, Typography, Input, Empty, Button } from 'antd';
import { connect } from 'react-redux';

import DeleteModal from 'components/DeleteModal';
import SpinBox from 'components/SpinBox';
import Pagination from 'components/Pagination';
import { EditIcon, RemoveIcon } from 'components/Icons';
import * as API from 'utils/api';
import ReportModal from '../Claimants/DetailedClaimant/ReportModal/ReportModal';
import { notifyApiError, notifyInfo } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { ModalMessage } from 'components/Modal/ModalMessage';

import {
  REPORT_SECTIONS_DEFAULT,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  REPORT_URL_BY_REPORT_ID,
  REPORT_DEMAND_LETTER_TYPE_ID,
  REPORT_SURGICAL_TYPE_ID,
  REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID,
} from 'constants/reports';

import { TEMPLATE_CREATE_STRUCTURE_NAV } from 'layout/navigate_const';

import './Templates.scss';

const { Search } = Input;
const { Title, Text } = Typography;

class Templates extends Component {
  constructor() {
    super();

    this.state = {
      pageIndex: 0,
      templatesData: [],
      totalCount: 0,
      showModal: false,
      selectedTemplate: {},
      searchQuery: '',
      loadingTitle: 'Loading...',
      loading: false,
      querySearched: false,
      reportStatuses: [],
      reportTypes: [],
      modalType: 'ADD',
      showReportModal: false,
      selectedReport: {},
      messageChangePrivacity: '',
    };

    this.getReportTypes = this.getReportTypes.bind(this);
    this.getReportStatuses = this.getReportStatuses.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.getAllTemplates = this.getAllTemplates.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.closeReportModal = this.closeReportModal.bind(this);
    this.openReportModal = this.openReportModal.bind(this);
    this.redirectTemplate = this.redirectTemplate.bind(this);
  }

  componentDidMount() {
    this.getAllTemplates();

    this.getReportStatuses();

    this.getReportTypes();
  }

  getReportTypes() {
    API.getReportTypes().then(res => {
      this.setState({
        reportTypes: res.reportTypes.filter(
          item => ![REPORT_DEMAND_LETTER_TYPE_ID, REPORT_SURGICAL_TYPE_ID, REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID].includes(item.id)
        ),
      });
    });
  }

  getReportStatuses() {
    API.getReportStatuses().then(res => {
      this.setState({
        reportStatuses: res.reportStatuses,
      });
    });
  }

  handlePagination(pageIndex) {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  }

  async getAllTemplates() {
    const { pageIndex } = this.state;

    const apiCall = tenantOnly => API.getAllTemplateReports({ pageIndex, pageSize: 10, tenantOnly });

    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserManager = isAllowed(this.props.roles, ROLES_DIVISIONS.PURE_MANAGEMENT);

    try {
      this.setState({ loading: true });
      const res = isUserManager && !isUserAdmin ? await apiCall(true) : await apiCall();

      this.setState({ templatesData: res.reports, totalCount: res.total_count });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleOpenModal(item) {
    this.setState({
      showModal: true,
      selectedTemplate: item,
    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
    });
  }

  redirectTemplate({ report_type_id, report_id }) {
    if (report_type_id === REPORT_LIFE_CARE_PLAN_TYPE_ID)
      return this.props.history.push(`${TEMPLATE_CREATE_STRUCTURE_NAV.path}/${report_id}`);

    this.props.history.push(
      `/app/reports/${report_id}/${REPORT_URL_BY_REPORT_ID[report_type_id]}/${
        REPORT_SECTIONS_DEFAULT[REPORT_URL_BY_REPORT_ID[report_type_id]][0]
      }`,
      {
        data: { report_id: report_id },
      }
    );
  }

  async handleReport(param) {
    const { modalType, selectedReport } = this.state;

    if (modalType === 'ADD') {
      const params = {
        is_template: true,
        ...param,
      };

      API.createReport(params).then(res => {
        this.redirectTemplate({ report_type_id: params.report_type_id, report_id: res.report.id });
      });
    } else if (modalType === 'EDIT') {
      const params = {
        ...param,
        id: selectedReport.id,
        claimant_id: null,
      };

      try {
        const isUserAdmin = isAllowed(this.props.roles, [
          ...ROLES_DIVISIONS.SUPERADMIN,
          ...ROLES_DIVISIONS.SALES_MANAGER,
        ]);
        const isUserManager = isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER);

        const apiParams = isUserManager && !isUserAdmin ? { tenantOnly: true, ...params } : params;

        const res = await API.updateReport(apiParams);

        if (param.is_private !== selectedReport.is_private)
          return this.setState({
            showReportModal: false,
            messageChangePrivacity: "This template's status can be changed to private or public",
          });

        this.redirectTemplate({ report_type_id: param.report_type_id, report_id: res.report.id });
      } catch (e) {
        notifyApiError(e);
      }
    } else {
      let report_type_id = param.report_type_id === selectedReport.report_type_id ? null : param.report_type_id;
      const params = {
        id: selectedReport.id,
        report_type_id,
      };
      API.copyReport(params).then(res => {
        this.handleSearch();
        API.updateReport({
          ...param,
          id: res.id,
          claimant_id: null,
        });
      });
    }
  }

  async handleDeleteTemplate(item) {
    const params = {
      id: item.id,
    };

    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: 'Deleting...',
    });

    try {
      const isUserAdmin = isAllowed(this.props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.SALES_MANAGER,
      ]);
      const isUserManager = isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER);

      const apiParams = isUserManager && !isUserAdmin ? { tenantOnly: true, ...params } : params;

      await API.deleteReport(apiParams);

      notifyInfo('Template was deleted');

      this.setState({
        templatesData: this.state.templatesData.filter(template => template.id !== item.id),
        totalCount: this.state.totalCount - 1,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.getAllTemplates();
      this.setState({ loading: false });
    }
  }

  async handleSearch() {
    const { searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: 'Loading...' });

    let index = pageIndex;
    const apiCall = tenantOnly =>
      API.getAllTemplateReports({ search: searchQuery, pageIndex: index, pageSize: 10, tenantOnly });

    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserManagement = isAllowed(this.props.roles, ROLES_DIVISIONS.PURE_MANAGEMENT);

    try {
      const res = isUserManagement && !isUserAdmin ? await apiCall(true) : await apiCall();
      this.setState({
        templatesData: res.reports,
        totalCount: res.total_count,
      });
    } catch (e) {
      notifyApiError(e);
      this.setState({
        templatesData: [],
        totalCount: 0,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  closeReportModal() {
    this.setState({ showReportModal: false });
  }

  openReportModal(type, item) {
    this.setState({
      modalType: type,
      selectedReport: item,
      showReportModal: true,
    });
  }

  render() {
    const {
      pageIndex,
      totalCount,
      templatesData,
      showModal,
      selectedTemplate,
      loading,
      loadingTitle,
      messageChangePrivacity,
      selectedReport,
      showReportModal,
      modalType,
      reportTypes,
      reportStatuses,
    } = this.state;

    return (
      <Row className="templates-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Templates
            </Title>
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              className="search-box"
              onChange={e => this.setState({ searchQuery: e.target.value, pageIndex: 0 })}
              onSearch={this.handleSearch}
            />
          </Col>
          <Col className="pagination">
            <Pagination onChange={this.handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
          </Col>
          {isAllowed(this.props.roles, [
            ...ROLES_DIVISIONS.MANAGEMENT,
            ...ROLES_DIVISIONS.MANAGER_ATTORNEY,
            ...ROLES_DIVISIONS.NURSE_ONLY,
          ]) && (
            <Col className="add-button">
              <Button type="primary" className="green-btn" onClick={() => this.openReportModal('ADD', {})}>
                Add Template
              </Button>
            </Col>
          )}
        </Row>
        <Row type="flex" className="table-header">
          <Col md={10}>Template Name</Col>
          <Col md={5}>Template Status</Col>
          <Col md={5}>Report Type</Col>
          <Col md={4} style={{ textAlign: 'right' }}>
            Actions
          </Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {templatesData.length > 0 ? (
              <Row>
                {templatesData.map(item => (
                  <Row key={item.id} className="record template_item">
                    <Col md={10}>
                      <Text
                        className="template_color_name"
                        onClick={() =>
                          this.redirectTemplate({ report_type_id: item.report_type_id, report_id: item.id })
                        }>
                        {item.report_name}
                      </Text>
                    </Col>
                    <Col md={5}>{item.report_status.name}</Col>
                    <Col md={5}>{item.report_type.name}</Col>

                    <Col md={4} style={{ textAlign: 'right' }} className="actions">
                      <EditIcon className="icon" onClick={() => this.openReportModal('EDIT', item)} />
                      <RemoveIcon className="icon" onClick={() => this.handleOpenModal(item)} />
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                description={loading ? false : 'No Data'}
                image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>

        {showReportModal && (
          <ReportModal
            isTemplates={true}
            isOpen={showReportModal}
            type={modalType}
            reportTypes={reportTypes}
            data={selectedReport}
            claimant_id={null}
            reportStatuses={reportStatuses}
            onConfirm={this.handleReport}
            onCancel={this.closeReportModal}
          />
        )}
        <DeleteModal
          title="Delete this Template?"
          content={selectedTemplate.report_name}
          isOpen={showModal}
          onConfirm={() => this.handleDeleteTemplate(selectedTemplate)}
          onCancel={this.handleCloseModal}
        />

        {messageChangePrivacity && (
          <ModalMessage
            title={messageChangePrivacity}
            onClose={() => {
              this.redirectTemplate({ report_type_id: selectedReport.report_type_id, report_id: selectedReport.id });
            }}
            textButton="Ok"
          />
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, null)(Templates);
