import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// Components
import { Typography, Button } from 'antd';
import { ModalAddCardPayInvoices } from '../ModalAddCardPayInvoices';
import { SettingsListCard } from '../SettingsListCard';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Hooks
import { useCreditCard } from 'hooks';

// Style
import './settingsCard.scss';
import { notifyApiError } from 'utils/notification';

const { Title } = Typography;

const SettingsCard = ({ listCard, updateList, disabled }) => {
  const { userInfo } = useSelector(state => state.auth);
  const [modalAddCard, setModalAddCard] = useState(false);

  const { publicKey, loadingCreditCard, getPublicKey, deleteCreditCardUser } = useCreditCard();

  const handleUpdateListCard = () => {
    updateList && updateList();
    setModalAddCard(false);
  };

  const handleDeleteCard = async ({ card }) => {
    if(!card) return notifyApiError('Card not found');
    if(listCard.length === 1) return notifyApiError('You must have at least one card saved');
    await deleteCreditCardUser({ profileCustomerPaymentId: card.customerPaymentProfileId, tenant_id: userInfo?.attributes["custom:tenant_id"] });
    updateList();
  };

  const handleModalAddCard = async () => {
    await getPublicKey();
    setModalAddCard(true);
  };

  return (
    <>
      {loadingCreditCard && <ModalLoading />}
      <div className="settingsCard">
        <div className="settingsCard_header">
          <Title level={4}>Cards saved</Title>
          <Button type="primary" className="green-btn" onClick={handleModalAddCard} disabled={disabled}>
            Add New Card
          </Button>
        </div>

        <SettingsListCard listCard={listCard} onDelete={handleDeleteCard} />

        {modalAddCard && (
          <ModalAddCardPayInvoices
            onClose={() => setModalAddCard(false)}
            title="Add New Card"
            textBtn="Add"
            updateListCard={handleUpdateListCard}
            publicKey={publicKey}
          />
        )}
      </div>
    </>
  );
};

export default SettingsCard;
