export const MSA_NON_MEDICARE = 'MSA_NON_MEDICARE';
export const MCP_MEDICARE = 'MCP_MEDICARE';
export const MSA_MEDICARE = 'MSA_MEDICARE';
export const LCP_MEDICARE = 'LCP_MEDICARE';

export const ACTION_TYPE_ADD_MEDICARE = 'ADD';

export const OWCP_STATUS_COVERED = 'C';

export const LIST_TYPE_TABLE_DEFAULT = [
  "Surgeries, Replacements and Procedures",
  "Medical Costs",
  "Prescription Drug Data",
]

export const FUTURE_MEDICAL_NEEDED = 'Future Medical Needed';
export const FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE = 'Future Medical Need covered by Medicare';
export const PRESCRIPTION_MEDICATIONS_COVERED = 'Prescription Medications Covered';
export const PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE = 'Prescription Medications Covered by Medicare';
export const DONT_CHANGE_COST_ON_THIS_TYPE = 'Surgeries, Replacements & Procedures';
export const DONT_CHANGE_COST_ON_THIS_TYPE_2 = 'Surgeries, Replacements & Procedures(non-medicare)';

export const MEDICARE_PROJECTION_TYPES = [
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  DONT_CHANGE_COST_ON_THIS_TYPE,
  PRESCRIPTION_MEDICATIONS_COVERED,
];

export const NON_MEDICARE_PROJECTION_TYPES = [
  FUTURE_MEDICAL_NEEDED,
  DONT_CHANGE_COST_ON_THIS_TYPE_2,
  PRESCRIPTION_MEDICATIONS_COVERED_BY_MEDICARE,
];

export const getTitleModal = ({ modalType, actionType }) => {
  if (modalType === MSA_NON_MEDICARE)
    return actionType === ACTION_TYPE_ADD_MEDICARE ? 'Add Non-Medicare Cost' : 'Edit Non-Medicare Cost';

  if (modalType === MCP_MEDICARE)
    return actionType === ACTION_TYPE_ADD_MEDICARE ? 'Add Medical Cost' : 'Edit Medical Cost';

  return actionType === ACTION_TYPE_ADD_MEDICARE ? 'Add Medicare Cost' : 'Edit Medicare Cost';
};

export const getDefaultType = ({ modalType }) => {
  if (modalType === MSA_NON_MEDICARE) return FUTURE_MEDICAL_NEEDED;

  if (modalType === LCP_MEDICARE) return '';

  return FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE;
};
