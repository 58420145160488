import React from "react";
import * as dayjs from "dayjs";

import {Avatar} from "antd";
import "./answeritem.scss"

const AnswerItem = props => {

  const {message = {}, user = {}} = props

  return (
    <div className="ai-container">
      {
        user?.avatar
          ? (<Avatar src={user.avatar} size={40} className="ai-avatar"/>)
          : (<Avatar icon="user" size={40} className="ai-avatar"/>)
      }
      <div className="ai-description-wrap">
        <div className="ai-header">
          <div className="ai-date">{dayjs(message.createdAt).format('MMM DD, YYYY')}</div>
          <div className="ai-time">{dayjs(message.createdAt).format('h:mm a')}</div>
        </div>
        <div className="ai-title">{`${user.given_name} ${user.family_name}`}</div>
        <div className="ai-description">{message.description}</div>
      </div>

    </div>
  )

}

export default AnswerItem;
