import { REPORT_SECTION_DOCUMENTUPLOAD } from 'constants/reports';
import { ROLES_DIVISIONS } from 'layout/roles';
import isAllowed from 'layout/roles';

export const switchStep = ({ report_id, reportType, step, history }) => {
  history.push({
    pathname: `/app/reports/${report_id}/${reportType}/${step}`,
    state: {
      data: { report_id, ...history?.location?.state?.data },
    },
  });
};

export const isLastStep = (sections, step) => {
  return sections.findIndex(section => section === step) === sections.length - 1;
};

export const isFirstStep = (sections = [], step) => {
  return sections.findIndex(section => section === step) === 0;
};

export const goNextStep = ({ sections, currentStep, reportType, report_id, history, roles }) => {
  if (isLastStep(sections, currentStep)) return;

  let nextStepIndex = sections.findIndex(section => section === currentStep) + 1;
  let section = sections[nextStepIndex];

  if (isAllowed(roles, ROLES_DIVISIONS.QA_ONLY) && section === REPORT_SECTION_DOCUMENTUPLOAD) {
    nextStepIndex = sections.findIndex(section => section === currentStep) + 2;
    section = sections[nextStepIndex];
  }

  history.push({
    pathname: `/app/reports/${report_id}/${reportType}/${sections[nextStepIndex]}`,
    state: {
      data: { report_id, ...history?.location?.state?.data },
    },
  });
};

export const goPrevStep = ({ sections, currentStep, reportType, report_id, history, roles }) => {
  if (isFirstStep(sections, currentStep)) return;
  const prevStepIndex = sections.findIndex(section => section === currentStep) - 1;

  let section = sections[prevStepIndex];

  if (isAllowed(roles, ROLES_DIVISIONS.QA_ONLY) && section === REPORT_SECTION_DOCUMENTUPLOAD) {
    const prevStepIndex = sections.findIndex(section => section === currentStep) - 2;

    section = sections[prevStepIndex];
  }

  history.push({
    pathname: `/app/reports/${report_id}/${reportType}/${sections[prevStepIndex]}`,
    state: {
      data: { report_id, ...history?.location?.state?.data },
    },
  });
};

export const goFirstStep = ({ sections, reportType, report_id, history, sendDataHistory = {} }) => {
  history.push({
    pathname: `/app/reports/${report_id}/${reportType}/${sections[0]}`,
    state: {
      data: { report_id, ...history?.location?.state?.data, ...sendDataHistory },
    },
  });
};
