import React, { useState } from 'react';
import * as API from 'utils/api';

// Components
import { Row, Typography } from 'antd';
import { ElipsisIcon, PencilIcon, TrashIcon } from 'components/Svg';
import { LCPMedicareCostTableHeader } from 'components/LCPMedicareCostTable';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Const
import { PRIMARY_BORDER_GREY, PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

// Style
import './templateLifeCarePlanSectionTableContent.scss';

const { Title } = Typography;

const TemplateLifeCarePlanSectionTableContent = ({ className, data, onEdit, onDelete, isDraggable, children }) => {
  const [loading, setLoading] = useState(false);
  const [footnote, setFootnote] = useState(data?.footnote || '');

  const handleUpdateFootnote = async () => {
    try {
      setLoading(true);

      await API.updateTemplateLifeCarePlanTable({
        body: {
          ...data,
          footnote,
        },
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Row
      className={`msa-medicare-cost-table-container templateLifeCarePlanTableList ${
        className ? `${className}_response` : ''
      }`}>
      <div className="templateLifeCarePlanTableList_title">
        <div className="templateLifeCarePlanTableList_title_text">
          {isDraggable && <ElipsisIcon color={PRIMARY_BORDER_GREY} />}
          <Title level={4}>{data.name}</Title>
        </div>

        <div className="templateLifeCarePlanTableList_title_btns">
          {onEdit && <PencilIcon color={PRIMARY_LIGHT_BLUE} onClick={() => onEdit({ table: data })} />}

          <TrashIcon color={PRIMARY_LIGHT_BLUE} onClick={() => onDelete({ idTable: data.id })} />
        </div>
      </div>

      <div>
        <LCPMedicareCostTableHeader structure={data} className={className} />
        {children}
        <input
          placeholder="Footnote"
          name="footnote"
          value={footnote}
          className="templateLifeCarePlanTableList_footnote"
          onChange={e => setFootnote(e.target.value)}
          onBlur={handleUpdateFootnote}
        />
      </div>

      {loading && <ModalLoading />}
    </Row>
  );
};

export default TemplateLifeCarePlanSectionTableContent;
