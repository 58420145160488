import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import dynamicData from 'dynamicData';
import { Col, Divider, Row, Typography } from 'antd';
import DatePickerSimple from 'react-datepicker';
import ClaimAndInjury from 'components/ClaimAndInjury/index';
import TemplateFields from 'components/TemplateFields';
import InlineContact from 'components/InlineContact';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { displayData, openDrawer } from '../../../../redux/Drawer/DrawerActions';
import { forbiddenRedirect, qaHaveAssignedReport, verifyPermissions } from '../../utils/verify-permissions';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';

// New
import InputBox from 'components/InputBox';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { SignatureReport } from 'containers/Reports/components/SignatureReport';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';
import { getListSignature } from 'containers/Reports/utils/helpers';
// finished new

import './Demographic.scss';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';
import { REPORT_DEMAND_LETTER_NAME, REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID } from 'constants/reports';
import { createViewReportIfNotExists } from 'utils/report';

import UmDemandTableCost from '../Components/UmDemandTableCost';
import InputCurrencyBox from 'components/InputCurrencyBox';

const { Title, Text } = Typography;

class Demographic extends Component {
  constructor(props) {
    super(props);

    let report_id = '';

    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      contactsList: [],
      report_claim_id: '',
      referral_date: moment(),
      date_of_report: moment(),
      loadingTitle: 'Loading...',
      loading: false,
      report_contacts: [],
      date_of_injury: moment(),
      insuranceList: [],
      is_template: false,
      signature: [],
      insurance_company: '',
      insurance_driver: '',
      REPORT_PERMISSIONS,
      description_of_accident: '',
      medical_treatment: '',
      loss_of_enjoyment: '',
      legal_analysis: '',
      per_diem_pain: '',
      wage_loss: '',
      conclusions: '',
      current_medical_expenses: [],
      future_medical_expenses: [],
      msa_settlement_future: '',
      past_pain_suffering: '',
      future_pain_suffering: '',
      loss_enjoyment_life: '',
      report_type_id: null,
    };

    // this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
    this.getContactLists = this.getContactLists.bind(this);
    this.getReportContacts = this.getReportContacts.bind(this);
    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleUpdateTextArea = this.handleUpdateTextArea.bind(this);
    this.handleChangeSignature = this.handleChangeSignature.bind(this);
    this.handleDeleteSignature = this.handleDeleteSignature.bind(this);
    this.handleAddSignature = this.handleAddSignature.bind(this);
    this.handleUpdateMedicalExpensives = this.handleUpdateMedicalExpensives.bind(this);
    this.handleUpdateFutureMedicalExpensives = this.handleUpdateFutureMedicalExpensives.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
  }

  // componentWillUnmount() {
  //   clearInterval(this.timeoutId);
  // }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });

    this.getContactLists();

    API.getReportContacts(report_id).then(res => {
      this.setState({ report_contacts: res.report_contacts.rows });
    });

    // si eres Internal QA buscamos la copia del reporte si no existe la creamos
    if (isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY)) {
      await createViewReportIfNotExists({ reportId: report_id });
    }

    await API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);

        const data = {
          is_template: reportInfo.is_template,
          report_template_id: reportInfo.report_template_id,
          report_claim_id: reportInfo.report_claim_id || '',
          date_of_injury: reportInfo.date_of_injury ? moment(reportInfo.date_of_injury) : moment(),
          referral_date: reportInfo.referral_date ? moment(reportInfo.referral_date) : moment(),
          date_of_report: reportInfo.date_of_report ? moment(reportInfo.date_of_report) : moment(),
          loading: false,
          insurance_company: reportInfo.insurance_company,
          insurance_driver: reportInfo.insurance_driver,
          signature: getListSignature({ signature: reportInfo.signature }),
          description_of_accident: reportInfo.description_of_accident,
          medical_treatment: reportInfo.medical_treatment,
          loss_of_enjoyment: reportInfo.loss_of_enjoyment,
          legal_analysis: reportInfo.legal_analysis,
          per_diem_pain: reportInfo.per_diem_pain,
          wage_loss: reportInfo.wage_loss,
          conclusions: reportInfo.conclusions,
          current_medical_expenses: reportInfo?.current_medical_expenses || [],
          future_medical_expenses: reportInfo?.future_medical_expenses || [],
          msa_settlement_future: reportInfo?.msa_settlement_future || '',
          past_pain_suffering: reportInfo?.past_pain_suffering || '',
          future_pain_suffering: reportInfo?.future_pain_suffering || '',
          loss_enjoyment_life: reportInfo?.loss_enjoyment_life || '',
          report_type_id: reportInfo?.report_type_id,
        };

        this.setState(data);
      })
      .catch(err => {
        forbiddenRedirect(err, this.props);
      });
  }

  getContactLists() {
    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

    const tenantOnly = isUserNurseManager && !isUserAdmin;

    //Getting all contacts data
    API.getContactsData({ pageSize: 1000, tenantOnly }).then(res => {
      const dynamic = dynamicData();

      this.setState({
        contactsList: res.contacts,
        insuranceList: res.contacts.filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.insurance_carrier
        ),
      });
    });
  }

  async getReportContacts() {
    const { report_id } = this.state;
    let res = await API.getReportContacts(report_id);
    this.setState({ report_contacts: res.report_contacts.rows });
  }

  async handleUpdateReport(isNextStep = false) {
    const {
      report_id,
      date_of_injury,
      report_claim_id,
      referral_date,
      date_of_report,
      insurance_company,
      insurance_driver,
      is_template,
      report_contacts,
      signature,
      description_of_accident,
      medical_treatment,
      loss_of_enjoyment,
      legal_analysis,
      per_diem_pain,
      wage_loss,
      conclusions,
      current_medical_expenses,
      future_medical_expenses,
      msa_settlement_future,
      past_pain_suffering,
      future_pain_suffering,
      loss_enjoyment_life,
    } = this.state;

    const params = {
      report_claim_id,
      id: report_id,
      date_of_injury: moment(date_of_injury).format('MM/DD/YYYY'),
      date_of_report: moment(date_of_report).format('MM/DD/YYYY'),
      referral_date: moment(referral_date).format('MM/DD/YYYY'),
      insurance_company,
      insurance_driver,
      is_template,
      report_contacts,
      description_of_accident,
      medical_treatment,
      loss_of_enjoyment,
      legal_analysis,
      per_diem_pain,
      wage_loss,
      conclusions,
      current_medical_expenses,
      future_medical_expenses,
      msa_settlement_future,
      past_pain_suffering,
      future_pain_suffering,
      loss_enjoyment_life,
      signature: signature.map(item => {
        return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
      }),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        res = await API.updateReport(params);
      } else {
        res = await API.updateReport(params);
      }
      if (res.status === 201) {
        notifyInfo('', res.message);
      }
      this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleUpdateTextArea(type, value) {
    this.setState({
      [type]: value,
    });
  }

  handleChangeSignature(data) {
    this.setState(data);
  }

  handleDeleteSignature({ index }) {
    this.setState({ signature: this.state.signature.filter((item, inde) => inde !== index) });
  }

  handleAddSignature() {
    const add = [...this.state.signature, INIT_SIGNATURE];
    this.setState({ signature: add });
  }

  handleUpdateMedicalExpensives({ list }) {
    this.setState({ current_medical_expenses: list });
  }

  handleUpdateFutureMedicalExpensives({ list }) {
    this.setState({ future_medical_expenses: list });
  }

  handleChangePrice({ field, value }) {
    this.setState({
      [field]: value,
    });
  }

  render() {
    let dataDynamic = { ...dynamicData() };
    const {
      report_claim_id,
      referral_date,
      loadingTitle,
      loading,
      insuranceList,
      is_template,
      insurance_driver,
      signature,
      REPORT_PERMISSIONS,
      description_of_accident,
      medical_treatment,
      loss_of_enjoyment,
      legal_analysis,
      per_diem_pain,
      wage_loss,
      conclusions,
      report_id,
      report_template_id,
      report_contacts,
      current_medical_expenses,
      future_medical_expenses,
      msa_settlement_future,
      past_pain_suffering,
      future_pain_suffering,
      loss_enjoyment_life,
      report_type_id,
    } = this.state;

    const isSend =
      !!insurance_driver &&
      !!msa_settlement_future &&
      !!past_pain_suffering &&
      !!future_pain_suffering &&
      !!loss_enjoyment_life;

    return (
      <Row className="msa-medical-content-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_DEMAND_LETTER_NAME} {is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown
              beforeNext={async () => await this.handleUpdateReport(false)}
              loading={loading}
              disabled={!isSend}
            />
          </Col>
        </Row>

        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Title level={4} className="title">
                    Basic Information
                  </Title>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={24}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    getContactLists={this.getContactLists}
                    contact_type_id={dataDynamic.insurance_carrier_id}
                    another={false}
                    label="Insurance Carrier"
                    report_id={report_id}
                    contactsList={insuranceList}
                    placeholder="Required"
                    type={'insurance_carrier'}
                    value={report_contacts
                      .filter(item => item.type === 'insurance_carrier')
                      .map(item => item.contact.id)}
                  />
                </Col>
              </Row>

              <ClaimAndInjury
                onChange={value => this.setState({ report_claim_id: value })}
                report_claim_id={report_claim_id}
                onClaimUpdated={() => {}}
                norequired
              />

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <InputBox
                    type="text"
                    label="Insurance Driver"
                    placeholder="Required"
                    value={insurance_driver}
                    onChange={value => {
                      this.setState({ insurance_driver: value });
                    }}
                  />
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>Date of Loss</Text>
                  </Row>
                  <Row>
                    <DatePickerSimple
                      className="date"
                      placeholderText="Select Referral Date"
                      selected={referral_date?.toDate()}
                      onChange={date => this.setState({ referral_date: date ? moment(date) : undefined })}
                      minDate={new Date('2000-01-01')}
                      showYearDropdown
                      showMonthDropdown
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24} className="style_textarea">
                  <RichTextEditor
                    label="Description of Accident and Liability"
                    placeholder="Please enter"
                    value={description_of_accident}
                    onChange={value => this.handleUpdateTextArea('description_of_accident', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24} className="style_textarea">
                  <RichTextEditor
                    label="Medical Treatment and Evaluation"
                    placeholder="Please enter"
                    value={medical_treatment}
                    onChange={value => this.handleUpdateTextArea('medical_treatment', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24} className="style_textarea">
                  <RichTextEditor
                    label="Loss of Enjoyment of Life"
                    placeholder="Please enter"
                    value={loss_of_enjoyment}
                    onChange={value => this.handleUpdateTextArea('loss_of_enjoyment', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24} className="style_textarea">
                  <RichTextEditor
                    label="Legal Analysis"
                    placeholder="Please enter"
                    value={legal_analysis}
                    onChange={value => this.handleUpdateTextArea('legal_analysis', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24} className="style_textarea">
                  <RichTextEditor
                    label="Per Diem Pain and Suffering Assessment"
                    placeholder="Please enter"
                    value={per_diem_pain}
                    onChange={value => this.handleUpdateTextArea('per_diem_pain', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24} className="style_textarea">
                  <RichTextEditor
                    label="Wage Loss"
                    placeholder="Please enter"
                    value={wage_loss}
                    onChange={value => this.handleUpdateTextArea('wage_loss', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24} className="style_textarea">
                  <RichTextEditor
                    label="Conclusions"
                    placeholder="Please enter"
                    value={conclusions}
                    onChange={value => this.handleUpdateTextArea('conclusions', value)}
                  />
                </Col>
              </Row>

              {report_type_id !== REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID && (
                <>
                  <UmDemandTableCost
                    dataList={current_medical_expenses}
                    onUpdateDataList={this.handleUpdateMedicalExpensives}
                    title="Medical Expenses"
                  />

                  <UmDemandTableCost
                    dataList={future_medical_expenses}
                    onUpdateDataList={this.handleUpdateFutureMedicalExpensives}
                    title="Future Medical Expenses"
                  />
                </>
              )}

              {(is_template || report_template_id) && (
                <TemplateFields
                  is_template={is_template}
                  section_id={20}
                  report_template_id={report_template_id}
                  report_id={report_id}
                />
              )}

              <Divider className="divider" />

              <Row type="flex" justify="space-between">
                <Col md={11} className="row">
                  <Row className="label_start">
                    <Text>Future Medical Expenses</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={msa_settlement_future}
                      disabled={!REPORT_PERMISSIONS.editReduction || !REPORT_PERMISSIONS.editTableAndPrice}
                      onChange={value => this.handleChangePrice({ field: 'msa_settlement_future', value })}
                    />
                  </Row>
                </Col>

                <Col md={11} className="row">
                  <Row className="label_start">
                    <Text>Past Pain and Suffering</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={past_pain_suffering}
                      disabled={!REPORT_PERMISSIONS.editReduction || !REPORT_PERMISSIONS.editTableAndPrice}
                      onChange={value => this.handleChangePrice({ field: 'past_pain_suffering', value })}
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between">
                <Col md={11} className="row">
                  <Row className="label_start">
                    <Text>Future Pain and Suffering</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={future_pain_suffering}
                      disabled={!REPORT_PERMISSIONS.editReduction || !REPORT_PERMISSIONS.editTableAndPrice}
                      onChange={value => this.handleChangePrice({ field: 'future_pain_suffering', value })}
                    />
                  </Row>
                </Col>

                <Col md={11} className="row">
                  <Row className="label_start">
                    <Text>Loss of enjoyment of life</Text>
                  </Row>
                  <Row>
                    <InputCurrencyBox
                      value={loss_enjoyment_life}
                      disabled={!REPORT_PERMISSIONS.editReduction || !REPORT_PERMISSIONS.editTableAndPrice}
                      onChange={value => this.handleChangePrice({ field: 'loss_enjoyment_life', value })}
                    />
                  </Row>
                </Col>
              </Row>

              {REPORT_PERMISSIONS.edit && !is_template && (
                <SignatureReport
                  defaultSignature={signature}
                  onChangeSignature={this.handleChangeSignature}
                  onDeleteSignature={this.handleDeleteSignature}
                  onAddSignature={this.handleAddSignature}
                  disabled={!REPORT_PERMISSIONS.edit}
                />
              )}

              <Divider className="divider" />

              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                disabled={!isSend}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    // autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {
  updateReportInfo,
  displayData,
  openDrawer,
})(Demographic);
