import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Spin, Typography } from 'antd';
import InputMask from 'react-input-mask';
import * as API from 'utils/api';
import './CompanyProfile.scss';
import { connect } from 'react-redux';
import { validateEmail } from 'utils/utils';
import { notifyApiError, notifySuccess } from '../../utils/notification';

const PersonalProfile = ({ userInfo }) => {
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) return;

    setIsLoading(true);
    const { attributes } = userInfo;
    setData({
      email: attributes?.email || '',
      locale: attributes?.locale || '',
      given_name: attributes?.given_name || '',
      family_name: attributes?.family_name || '',
      id: attributes?.sub || '',
    });
    setIsLoading(false);
  }, [userInfo]);

  const handleChange = ({ field, value }) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const validForm = () => {
    return Object.keys(data).some(item => !data[item]);
  };

  const handleSubmit = async () => {
    if (!validateEmail(data.email)) return notifyApiError('Please Enter a Valid Email');

    setIsLoading(true);
    await API.updateUser(data.email, {
      email: data.email,
      given_name: data.given_name,
      family_name: data.family_name,
      locale: data.locale,
    });

    await API.updateUserSynchronised(data);
    notifySuccess('Updated Successfully');
    setIsLoading(false);
  };

  const StyledInput = {
    fontSize: '14px',
  };

  return (
    <div>
      <Spin tip="Loading..." spinning={isLoading}>
        <Row className="company_container" type="flex" justify="space-between">
          <Row type="flex" justify="start" align="middle">
            <h1 className="company__title">Personal Profile</h1>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Name</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="given_name"
                value={data?.given_name || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Last Name</Text>
              </Row>
              <Input
                size="large"
                placeholder="Required"
                name="family_name"
                value={data?.family_name || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Email</Text>
              </Row>
              <Input
                disabled
                size="large"
                placeholder="Required"
                name="email"
                value={data?.email || ''}
                style={StyledInput}
                onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
              />
            </Col>
            <Col md={11}>
              <Row className="company__inputs">
                <Text>Phone</Text>
              </Row>
              <InputMask
                className="input-ssn"
                mask="999-999-9999"
                size="large"
                placeholder="Required"
                value={data?.locale || ''}
                name="phone_number_1"
                style={StyledInput}
                onChange={e => handleChange({ field: 'locale', value: e.target.value })}
              />
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col md={6}>
              <button className="company_form_btn" disabled={validForm()} onClick={handleSubmit}>
                Save
              </button>
            </Col>
          </Row>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, null)(PersonalProfile);
