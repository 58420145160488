import React, { useState, useEffect } from 'react';
import * as API from 'utils/api';
import { Typography } from 'antd';

// Components
import { ReportLogo } from 'containers/Reports/components/ReportLogo';
import { notifyApiError } from 'utils/notification';
import { ModalLoading } from 'components/Modal/ModalLoading';

const { Title, Text } = Typography;

const ChangeReportLogoAndColor = ({ urlPublic, idReport, color, disabledField }) => {
  const [loading, setLoading] = useState(false);
  const [reportColor, setReportColor] = useState(color || '#000000');

  useEffect(() => {
    setReportColor(color);
  }, [color]);

  const handleUpdateColor = async () => {
    try {
      setLoading(true);
      const body = {
        id: idReport,
        color: reportColor,
      };

      await API.updateReportNurse(body);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ModalLoading />}
      <div className="templateLifeCarePlanTable_design">
        <Title level={4} className="title-text">
          PDF Design
        </Title>

        <div className="templateLifeCarePlanTable_design_content">
          <ReportLogo urlPublic={urlPublic} idReport={idReport} disabledField={disabledField} />

          <div className="templateLifeCarePlanTable_references_color">
            <input
              className="templateLifeCarePlanTable_references_color_box"
              type="color"
              value={reportColor}
              onChange={e => setReportColor(e.target.value)}
              onBlur={handleUpdateColor}
              disabled={disabledField}
            />
            <Text>Color</Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeReportLogoAndColor;
