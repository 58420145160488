import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Button, Divider, Upload, Modal, Checkbox, Spin } from 'antd';
import DropDown from 'components/Dropdown';
import { updateReportInfo, getReportFiles } from 'redux/report';
import * as API from 'utils/api';
import { UploadPresigned, BulkAddFilesToReport, BulkAddExistingFilesToReport } from 'utils/upload';
import { notifyApiError, notifyInfo } from 'utils/notification';
import SortableList from '../../components/SortableList';
import {
  // ADMINISTRATION_AGREEMENT,
  // CONSENT_FORM,
  // COURT_DOCUMENTS_MSA,
  // MEDICAL_RECORDS_MSA,
  // PAYMENT_INFORMATION_MSA,
  // RATED_AGES,
  // SUPPLEMENTAL_DOCUMENTATION_MSA,
  // REDUCTION_LETTER_MSA,

  INJURY_DESCRIPTION,
  MEDICALS,
  DAMAGES,
  LEGAL_DOCS,
} from '../../../../constants/report-file-types';
// import { ResourceLinkFiles } from '../../components/ResourceLinkFiles';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';

import './DocumentUpload.scss';
import { isAttorney } from 'layout/roles';

const { Title, Text } = Typography;

const beforeUpload = file => {
  if (!file) return false;
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    Modal.error({
      content: 'You can only upload PDF file!',
    });
  }
  return isPdf;
};

class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }
    const { reportInfo } = this.props;

    const {
      // consent_forms,
      // rated_ages,
      // court_wc_board_documents,
      // msa_administration_agreement,
      // medical_records,
      // payment_information,
      // supplemental_documentation_additional_info,

      injury_description,
      medicals,
      damages,
      legal_docs,
    } = reportInfo;

    this.state = {
      report_id,
      // consent_forms,
      // rated_ages,
      // court_wc_board_documents,
      // msa_administration_agreement,
      // medical_records,
      // payment_information,
      // supplemental_documentation_additional_info,
      loadingText: 'Loading...',
      loading: false,
      is_template: false,
      // [CONSENT_FORM]: false,
      // [RATED_AGES]: false,
      // [COURT_DOCUMENTS_MSA]: false,
      // [ADMINISTRATION_AGREEMENT]: false,
      // [MEDICAL_RECORDS_MSA]: false,
      // [PAYMENT_INFORMATION_MSA]: false,
      // [SUPPLEMENTAL_DOCUMENTATION_MSA]: false,
      // [REDUCTION_LETTER_MSA]: false,

      injury_description,
      medicals,
      damages,
      legal_docs,
      [INJURY_DESCRIPTION]: false,
      [MEDICALS]: false,
      [DAMAGES]: false,
      [LEGAL_DOCS]: false,
      required_qa: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;
    this.props.getReportFiles(this.props.match.params.id);
    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        this.props.updateReportInfo(reportInfo);

        this.setState({ loading: false, is_template: reportInfo.is_template, required_qa: reportInfo.required_qa });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const {
        // consent_forms,
        // rated_ages,
        // court_wc_board_documents,
        // msa_administration_agreement,
        // medical_records,
        // payment_information,
        // supplemental_documentation_additional_info,
        injury_description,
        medicals,
        damages,
        legal_docs,
      } = reportInfo;

      this.setState({
        // consent_forms,
        // rated_ages,
        // court_wc_board_documents,
        // msa_administration_agreement,
        // medical_records,
        // payment_information,
        // supplemental_documentation_additional_info,
        injury_description,
        medicals,
        damages,
        legal_docs,
        loading: false,
      });
    }
  }

  documentRequired = [];

  handleUpdateReport = async (isNextStep = false) => {
    const { report_id, required_qa } = this.state;
    const params = {
      id: report_id,
      required_qa,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        res = await API.updateReport(params);
      }
      if (res.status_code === 201) {
        notifyInfo('', res.message);
      }
      this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleDocUpload = async (info, type) => {
    let promiseArray = [];
    let hasUploading = false;
    info.fileList.forEach(el => {
      if (beforeUpload(el.originFileObj)) {
        if ('uploading' === el.status) {
          hasUploading = true;
        }
        promiseArray.push(el);
      }
    });

    if (hasUploading) {
      return;
    }

    const uploadArrayWithPromises = [];
    try {
      //filter duplicates and upload unique files
      promiseArray
        .filter(
          (file, index, fileList) =>
            fileList.findIndex(f => f.name === file.name && f.type === file.type && f.size === file.size) === index
        )
        .forEach(file => {
          uploadArrayWithPromises.push(UploadPresigned(file));
        });

      this.setState(state => (state[type] = true));
      const urls = await Promise.all(uploadArrayWithPromises);
      await BulkAddFilesToReport(urls, type, this.props.reportInfo?.claimant?.id, this.state.report_id);
      await this.props.getReportFiles(this.props.match.params.id);
      //force clean component files after success uploading
      info.fileList.length = 0;
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  };

  handleGenerateReport = async () => {
    const { report_id, required_qa } = this.state;
    const { resourceLinksFiles, allFiles } = this.props;

    this.setState({
      loading: true,
      loadingText: 'Generating the report...',
    });

    try {
      // const resourceLinksFilesToUpload = resourceLinksFiles.filter(
      //   file => !allFiles.find(item => item.file_id === file.id)
      // );

      // if (resourceLinksFilesToUpload.length) {
      //   await BulkAddExistingFilesToReport(resourceLinksFilesToUpload, SUPPLEMENTAL_DOCUMENTATION_MSA, report_id);
      // }

      let has_allDocuments = [];
      this.documentRequired.map(doc => {
        const find = allFiles.map(file =>
          file.type === doc ? (has_allDocuments.includes(file.type) ? '' : has_allDocuments.push(file.type)) : ''
        );
      });

      if (has_allDocuments.length >= this.documentRequired.length) {
        await API.setUpBD1(report_id);
        this.setState({
          loading: true,
          loadingText: 'Report generated , packaging the files... ',
        });
        const reportFileObj = await API.GenerateReport(this.state.report_id);
        this.setState({ loading: false });
        Modal.success({
          content: 'Report is generated',
          onOk: () => {
            window.open(reportFileObj.url);
          },
        });
        await this.handleUpdateReport(false);
      } else {
        const missingDocuments = this.documentRequired.filter(file => !has_allDocuments.includes(file));
        notifyInfo(
          'Documents Required',
          'All documents are required to generate the report: ' + `${missingDocuments.map(type => ' ' + type)}`
        );
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  deleteFile = async (type, el) => {
    this.setState(state => (state[type] = true));
    try {
      await API.deleteReportDocument(el.id);
      await this.props.getReportFiles(this.props.match.params.id);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  };

  renderFiles = (list, type) => {
    const allFilesOfThisType = list
      .filter(item => item.type === type)
      .sort((a, b) => {
        return a.order - b.order;
      });
    if (allFilesOfThisType.length < 1) {
      if (this.state[type]) {
        return <Spin />;
      }
      return <Text>No documents uploaded yet...</Text>;
    } else {
      return (
        <Row className="example horizontal" style={{ marginTop: 10 }}>
          <SortableList
            deleteFile={this.deleteFile}
            distance={1}
            items={allFilesOfThisType}
            type={type}
            axis={'y'}
            onSortEnd={e => {
              this.onSortEnd(e, allFilesOfThisType, type);
            }}
          />
        </Row>
      );
    }
  };

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  onSortEnd = ({ oldIndex, newIndex }, items, type) => {
    let isUploading = { ...this.state.isUploading };
    isUploading[type] = true;
    this.setState({ isUploading });
    let newList = [...items];
    newList = this.moveArrayItemToNewIndex(newList, oldIndex, newIndex);
    newList = newList.map((item, index) => {
      return { id: item.id, order: index };
    });
    API.BulkUpdateReportDocuments(newList)
      .then(res => {
        this.setState({ loading: false });
        isUploading[type] = false;
        this.setState({ isUploading });
        this.props.getReportFiles(this.props.match.params.id);
      })
      .catch(err => {
        this.setState({ loading: false });
        isUploading[type] = false;
        this.setState({ isUploading });
      });
  };

  render() {
    const {
      // consent_forms,
      // rated_ages,
      // court_wc_board_documents,
      // msa_administration_agreement,
      // medical_records,
      // payment_information,
      // supplemental_documentation_additional_info,
      loading,
      required_qa,
      injury_description,
      medicals,
      damages,
      legal_docs,
    } = this.state;

    const { isLoading, files } = this.props;

    return (
      <>
        <Row className="msa-document-upload-container">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Setup BI Demand #1
              </Title>
            </Col>
            <Col className="step">
              <DropDown loading={loading} beforeNext={async () => await this.handleUpdateReport(false)} />
            </Col>
          </Row>

          <Row className="main">
            <Row className="content">
              {loading ? (
                <div className="loading-wrapper">
                  <Spin loading={loading.toString() || isLoading.toString()} tip={this.state.loadingText} />
                </div>
              ) : (
                <>
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={24}>
                      <Text className="title">Document Upload</Text>
                    </Col>
                  </Row>

                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={24}>
                      <Row className="label">
                        <Text>Injury Description</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          listType="text"
                          accept="application/pdf"
                          className="logo-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={info => this.handleDocUpload(info, INJURY_DESCRIPTION)}
                          ref={node => {
                            this['10ConsentForm'] = node;
                          }}>
                          {injury_description ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, INJURY_DESCRIPTION)}</Row>
                    </Col>
                    <Col md={24}>
                      <Row className="label">
                        <Text>Medicals</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          listType="text"
                          className="logo-uploader"
                          accept="application/pdf"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          onChange={info => this.handleDocUpload(info, MEDICALS)}
                          ref={node => {
                            this['15RatedAges'] = node;
                          }}>
                          {medicals ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, MEDICALS)}</Row>
                    </Col>
                  </Row>

                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={24}>
                      <Row className="label">
                        <Text>Damages</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          accept="application/pdf"
                          listType="text"
                          className="logo-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={info => this.handleDocUpload(info, DAMAGES)}
                          ref={node => {
                            this['25Court-WCBoardDocuments'] = node;
                          }}>
                          {damages ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, DAMAGES)}</Row>
                    </Col>
                    <Col md={24}>
                      <Row className="label">
                        <Text>Legal Docs</Text>
                      </Row>
                      <Row type="flex" justify="center" align="middle" className="upload">
                        <Upload
                          multiple={true}
                          name="logo"
                          accept="application/pdf"
                          listType="text"
                          className="logo-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={info => this.handleDocUpload(info, LEGAL_DOCS)}
                          ref={node => {
                            this['30WCMSAAdministrationAgreement'] = node;
                          }}>
                          {legal_docs ? (
                            <Text className="logo-button uploaded">Uploaded</Text>
                          ) : (
                            <Text className="logo-button">Upload</Text>
                          )}
                        </Upload>
                      </Row>
                      <Row>{this.renderFiles(this.props.files, LEGAL_DOCS)}</Row>
                    </Col>
                  </Row>

                  <Divider className="divider" />

                  <Row type="flex" justify="space-between" align="top" className="confirm-button row">
                    <PrevNextStep beforeNext={async () => await this.handleUpdateReport(true)} />
                    {!this.state.is_template && !isAttorney(this.props.roles) && (
                      <Row type="flex" justify="end">
                        <Col>
                          <Button
                            type="primary"
                            size="large"
                            className="green-btn button"
                            onClick={this.handleGenerateReport}>
                            Generate {this.state.is_template ? 'Template' : 'Report'}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Row>
                </>
              )}
            </Row>
          </Row>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    reportInfo: state.report.reportInfo,
    files: state.report.files,
    resourceLinksFiles: state.report.resourceLinksFiles,
    allFiles: state.report.allFiles,
    isLoading: state.report.isLoading,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };

  return props;
};

export default connect(mapStateToProps, { updateReportInfo, getReportFiles })(DocumentUpload);

{
  /* <Row type="flex" justify="space-between" align="top" className="row">
<Col md={11}>
  <Row className="label">
    <Text>35-Medical Records</Text>
  </Row>
  <Row type="flex" justify="center" align="middle" className="upload">
    <Upload
      multiple={true}
      name="logo"
      accept="application/pdf"
      listType="text"
      className="logo-uploader"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      beforeUpload={beforeUpload}
      onChange={info => this.handleDocUpload(info, MEDICAL_RECORDS_MSA)}
      ref={node => {
        this['35MedicalRecords'] = node;
      }}>
      {medical_records ? (
        <Text className="logo-button uploaded">Uploaded</Text>
      ) : (
        <Text className="logo-button">Upload</Text>
      )}
    </Upload>
  </Row>
  <Row>{this.renderFiles(this.props.files, MEDICAL_RECORDS_MSA)}</Row>
</Col>
<Col md={11}>
  <Row className="label">
    <Text>40-Payment Information</Text>
  </Row>
  <Row type="flex" justify="center" align="middle" className="upload">
    <Upload
      multiple={true}
      name="logo"
      listType="text"
      accept="application/pdf"
      className="logo-uploader"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      beforeUpload={beforeUpload}
      onChange={info => this.handleDocUpload(info, PAYMENT_INFORMATION_MSA)}
      ref={node => {
        this['40PaymentInformation'] = node;
      }}>
      {payment_information ? (
        <Text className="logo-button uploaded">Uploaded</Text>
      ) : (
        <Text className="logo-button">Upload</Text>
      )}
    </Upload>
  </Row>
  <Row>{this.renderFiles(this.props.files, PAYMENT_INFORMATION_MSA)}</Row>
</Col>
</Row>

<Row type="flex" justify="space-between" align="top" className="row">
<Col md={11}>
  <Row className="label">
    <Text>50-Supplemental Doc / Additional Info</Text>
  </Row>
  <Row type="flex" justify="center" align="middle" className="upload">
    <Upload
      multiple={true}
      name="logo"
      listType="text"
      accept="application/pdf"
      className="logo-uploader"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      beforeUpload={beforeUpload}
      onChange={info => this.handleDocUpload(info, SUPPLEMENTAL_DOCUMENTATION_MSA)}
      ref={node => {
        this['50Supplemental-AdditionalInformation'] = node;
      }}>
      {supplemental_documentation_additional_info ? (
        <Text className="logo-button uploaded">Uploaded</Text>
      ) : (
        <Text className="logo-button">Upload</Text>
      )}
    </Upload>
  </Row>
  <Row>{this.renderFiles(files, SUPPLEMENTAL_DOCUMENTATION_MSA)}</Row>
  <ResourceLinkFiles reportId={this.props.match.params.id} />
</Col>
<Col md={11}>
  <Row className="label">
    <Text>60-Reduction Letter</Text>
  </Row>
  <Row type="flex" justify="center" align="middle" className="upload">
    <Upload
      disabled={!isAttorney(this.props.roles) }
      multiple={true}
      name="logo"
      listType="text"
      accept="application/pdf"
      className="logo-uploader"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      beforeUpload={beforeUpload}
      onChange={info => this.handleDocUpload(info, REDUCTION_LETTER_MSA)}
      ref={node => {
        this[REDUCTION_LETTER_MSA] = node;
      }}>
      {supplemental_documentation_additional_info ? (
        <Text className="logo-button uploaded">Uploaded</Text>
      ) : (
        <Text className="logo-button">Upload</Text>
      )}
    </Upload>
  </Row>
  <Row>{this.renderFiles(files, REDUCTION_LETTER_MSA)}</Row>
  <ResourceLinkFiles reportId={this.props.match.params.id} />
</Col>
</Row> */
}
