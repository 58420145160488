import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Typography, Divider, Upload, Modal, Checkbox, Spin } from 'antd';
import DropDown from 'components/Dropdown';
import { updateReportInfo, getReportFiles } from 'redux/report';
import * as API from 'utils/api';
import { UploadPresigned, BulkAddFilesToReport, BulkAddExistingFilesToReport } from 'utils/upload';
import { notifyApiError, notifyInfo } from 'utils/notification';
import SortableList from '../../components/SortableList';
import {
  ADMINISTRATION_AGREEMENT,
  CONSENT_FORM,
  COURT_DOCUMENTS_MSA,
  MEDICAL_RECORDS_MSA,
  PAYMENT_INFORMATION_MSA,
  RATED_AGES,
  SUPPLEMENTAL_DOCUMENTATION_MSA,
  REDUCTION_LETTER_MSA,
} from '../../../../constants/report-file-types';
import { ResourceLinkFiles } from '../../components/ResourceLinkFiles';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import { WaterMarkCheck } from 'components/WaterMarkCheck';
import './DocumentUpload.scss';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { BtnGenerateReport } from 'containers/Reports/components/BtnGenerateReport';
import { returnToSenderReport } from 'containers/Reports/utils/returnToSender';
import { ModalReturnToSender } from 'containers/Reports/components/ModalReturnToSender';
import { STATUS_GENERATED } from 'constants/report_status';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV, TEMPLATE_NAV } from 'layout/navigate_const';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { REPORT_MSA_NAME } from 'constants/reports';

// Components
import { SignatureReport } from 'containers/Reports/components/SignatureReport';
import { INIT_SIGNATURE } from 'containers/Reports/utils/const';
import { TooltipAboutFileMSA } from 'components/TooltipAboutFileMSA';

// Utils
import { getListSignature } from 'containers/Reports/utils/helpers';

const { Title, Text } = Typography;

const beforeUpload = file => {
  if (!file) return false;
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    Modal.error({
      content: 'You can only upload PDF file!',
    });
  }
  return isPdf;
};

class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    const { reportInfo } = this.props;

    const {
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      medical_records,
      payment_information,
      supplemental_documentation_additional_info,
    } = reportInfo;

    this.state = {
      report_id,
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      medical_records,
      payment_information,
      show_additional_comments: false,
      supplemental_documentation_additional_info,
      loadingText: 'Loading...',
      loading: false,
      is_template: false,
      [CONSENT_FORM]: false,
      [RATED_AGES]: false,
      [COURT_DOCUMENTS_MSA]: false,
      [ADMINISTRATION_AGREEMENT]: false,
      [MEDICAL_RECORDS_MSA]: false,
      [PAYMENT_INFORMATION_MSA]: false,
      [SUPPLEMENTAL_DOCUMENTATION_MSA]: false,
      [REDUCTION_LETTER_MSA]: false,
      required_qa: true,
      modalReturnToSender: false,
      assignmentReturn: '',
      is_watermark: false,
      selectPdf: '',
      loadingInternal: false,
      REPORT_PERMISSIONS,
      signature: [],
    };

    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleUpdateWaterMark = this.handleUpdateWaterMark.bind(this);
    this.handleDocUpload = this.handleDocUpload.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.renderFiles = this.renderFiles.bind(this);
    this.moveArrayItemToNewIndex = this.moveArrayItemToNewIndex.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleReturnToSender = this.handleReturnToSender.bind(this);
    this.handlePreviewReport = this.handlePreviewReport.bind(this);
    this.handleChangeSignature = this.handleChangeSignature.bind(this);
    this.handleDeleteSignature = this.handleDeleteSignature.bind(this);
    this.handleAddSignature = this.handleAddSignature.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;
    this.props.getReportFiles(this.props.match.params.id);
    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);

        this.setState({
          loading: false,
          is_template: reportInfo.is_template,
          required_qa: reportInfo.required_qa,
          show_additional_comments: reportInfo.show_additional_comments,
          claimant: reportInfo.claimant,
          is_watermark: reportInfo.is_watermark,
          signature: getListSignature({ signature: reportInfo.signature }),
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const {
        consent_forms,
        rated_ages,
        court_wc_board_documents,
        msa_administration_agreement,
        medical_records,
        payment_information,
        supplemental_documentation_additional_info,
      } = reportInfo;

      this.setState({
        consent_forms,
        rated_ages,
        court_wc_board_documents,
        msa_administration_agreement,
        medical_records,
        payment_information,
        supplemental_documentation_additional_info,
        loading: false,
      });
    }
  }

  async handleUpdateReport(isNextStep = false, out = false) {
    const { report_id, required_qa, show_additional_comments, signature } = this.state;
    const params = {
      id: report_id,
      required_qa,
      show_additional_comments: show_additional_comments,
      signature: signature.map(item => {
        delete item.imageUrl;
        return { ...item, referral_date: moment(item.referral_date).format('MM/DD/YYYY') };
      }),
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status_code === 201) {
        notifyInfo('', res.message);
      }

      if (out) {
        notifyInfo('The template is updated');
      }

      if (res?.report) this.props.updateReportInfo(res.report);

      if (out) this.props.history.push(TEMPLATE_NAV.path);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleUpdateWaterMark(value) {
    try {
      const checked = value.target.checked;

      this.setState({
        loading: true,
        loadingTitle: 'Saving...  ',
        is_watermark: checked,
      });

      const { report_id } = this.state;
      const params = {
        id: report_id,
        is_watermark: checked,
      };

      await API.updateReport(params);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleDocUpload(info, type) {
    let promiseArray = [];
    let hasUploading = false;
    info.fileList.forEach(el => {
      if (beforeUpload(el.originFileObj)) {
        if ('uploading' === el.status) {
          hasUploading = true;
        }
        promiseArray.push(el);
      }
    });

    if (hasUploading) {
      return;
    }

    const uploadArrayWithPromises = [];
    try {
      //filter duplicates and upload unique files
      promiseArray
        .filter(
          (file, index, fileList) =>
            fileList.findIndex(f => f.name === file.name && f.type === file.type && f.size === file.size) === index
        )
        .forEach(file => {
          uploadArrayWithPromises.push(UploadPresigned(file));
        });

      this.setState(state => (state[type] = true));
      const urls = await Promise.all(uploadArrayWithPromises);
      await BulkAddFilesToReport(urls, type, this.props.reportInfo?.claimant?.id, this.state.report_id);
      await this.props.getReportFiles(this.props.match.params.id);
      //force clean component files after success uploading
      info.fileList.length = 0;
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  }

  async handleGenerateReport() {
    const { report_id } = this.state;
    const { resourceLinksFiles, allFiles } = this.props;

    this.setState({
      loading: true,
      loadingText: 'Generating the report...',
    });
    try {
      const resourceLinksFilesToUpload = resourceLinksFiles.filter(
        file => !allFiles.find(item => item.file_id === file.id)
      );

      if (resourceLinksFilesToUpload.length) {
        await BulkAddExistingFilesToReport(resourceLinksFilesToUpload, SUPPLEMENTAL_DOCUMENTATION_MSA, report_id);
      }

      if (isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGER)) {
        await API.updateReportNurse({ id: report_id, report_status_id: STATUS_GENERATED.id });
      }

      let has_allDocuments = [];

      if (has_allDocuments.length >= 0) {
        await API.setUpReport(report_id);
        this.setState({
          loading: true,
          loadingText: 'Report generated , packaging the files... ',
        });

        const reportFileObj = await API.GenerateReport(this.state.report_id);
        this.setState({ loading: false });
        Modal.success({
          content: 'Report is generated',
          onOk: () => {
            window.open(reportFileObj.url);
          },
        });
        await this.handleUpdateReport(false);
      } else {
        const missingDocuments = this.documentRequired.filter(file => !has_allDocuments.includes(file));
        notifyInfo(
          'Documents Required',
          'All documents are required to generate the report: ' + `${missingDocuments.map(type => ' ' + type)}`
        );
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async deleteFile(type, el) {
    this.setState(state => (state[type] = true));
    try {
      await API.deleteReportDocument(el.id);
      await this.props.getReportFiles(this.props.match.params.id);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState(state => (state[type] = false));
    }
  }

  renderFiles(list, type) {
    const allFilesOfThisType = list
      .filter(item => item.type === type)
      .sort((a, b) => {
        return a.order - b.order;
      });
    if (allFilesOfThisType.length < 1) {
      if (this.state[type]) {
        return <Spin />;
      }
      return <Text>No documents uploaded yet...</Text>;
    } else {
      return (
        <Row className="example horizontal" style={{ marginTop: 10 }}>
          <SortableList
            deleteFile={this.deleteFile}
            distance={1}
            items={allFilesOfThisType}
            type={type}
            axis={'y'}
            onSortEnd={e => {
              this.onSortEnd(e, allFilesOfThisType, type);
            }}
            canEdit={this.state.REPORT_PERMISSIONS.edit}
          />
        </Row>
      );
    }
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  onSortEnd({ oldIndex, newIndex }, items, type) {
    let isUploading = { ...this.state.isUploading };
    isUploading[type] = true;
    this.setState({ isUploading });
    let newList = [...items];
    newList = this.moveArrayItemToNewIndex(newList, oldIndex, newIndex);
    newList = newList.map((item, index) => {
      return { id: item.id, order: index };
    });

    API.BulkUpdateReportDocuments(newList)
      .then(() => {
        this.setState({ loading: false });
        isUploading[type] = false;
        this.setState({ isUploading });
        this.props.getReportFiles(this.props.match.params.id);
      })
      .catch(() => {
        this.setState({ loading: false });
        isUploading[type] = false;
        this.setState({ isUploading });
      });
  }

  async handleReturnToSender() {
    try {
      this.setState({ loading: true });
      const { report_id, claimant } = this.state;
      const claimant_id = claimant.id;

      const res = await returnToSenderReport({ report_id, claimant_id });

      if (res?.assignment?.id) {
        this.setState({
          modalReturnToSender: true,
          assignmentReturn: `${res.assignment.user.given_name} ${res.assignment?.user?.family_name || ''}`,
        });
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handlePreviewReport() {
    try {
      this.setState({ loadingInternal: true });
      const res = await API.getReviewReportCompare({ idReport: this.state.report_id });
      if (res?.report_url) this.setState({ selectPdf: res.report_url });
    } catch (err) {
      notifyApiError(err);
    } finally {
      this.setState({ loadingInternal: false });
    }
  }

  handleChangeSignature(data) {
    this.setState(data);
  }

  handleDeleteSignature({ index }) {
    this.setState({ signature: this.state.signature.filter((item, inde) => inde !== index) });
  }

  handleAddSignature() {
    const add = [...this.state.signature, INIT_SIGNATURE];
    this.setState({ signature: add });
  }

  render() {
    const {
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      show_additional_comments,
      medical_records,
      payment_information,
      supplemental_documentation_additional_info,
      loading,
      report_id,
      modalReturnToSender,
      assignmentReturn,
      is_watermark,
      selectPdf,
      loadingInternal,
      REPORT_PERMISSIONS,
      is_template,
      signature,
    } = this.state;

    const { isLoading, files } = this.props;

    return (
      <>
        <Row className="msa-document-upload-container">
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
              </Title>
            </Col>
            <Col className="step">
              {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
                <DropDown
                  loading={loading}
                  goBack={() =>
                    this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                      data: this.props.history.location.state.data.claimantInfo,
                    })
                  }
                />
              ) : (
                <DropDown beforeNext={async () => await this.handleUpdateReport(false)} loading={loading} />
              )}
            </Col>
          </Row>
          <Row className="main">
            <Row className="content">
              {loading ? (
                <div className="loading-wrapper">
                  <Spin loading={loading.toString() || isLoading.toString()} tip={this.state.loadingText} />
                </div>
              ) : (
                <>
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={24} className="title_report_tooltip">
                      <Text className="title">Document Upload</Text>
                      <TooltipAboutFileMSA />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={11}>
                      <Row className="label">
                        <Text>10-Consent Forms</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            listType="text"
                            accept="application/pdf"
                            className="logo-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={info => this.handleDocUpload(info, CONSENT_FORM)}
                            ref={node => {
                              this['10ConsentForm'] = node;
                            }}>
                            {consent_forms ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(this.props.files, CONSENT_FORM)}</Row>
                    </Col>
                    <Col md={11}>
                      <Row className="label">
                        <Text>15-Rated Ages</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            listType="text"
                            className="logo-uploader"
                            accept="application/pdf"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            onChange={info => this.handleDocUpload(info, RATED_AGES)}
                            ref={node => {
                              this['15RatedAges'] = node;
                            }}>
                            {rated_ages ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(this.props.files, RATED_AGES)}</Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={11}>
                      <Row className="label">
                        <Text>25-Court/WC Board Documents</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            accept="application/pdf"
                            listType="text"
                            className="logo-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={info => this.handleDocUpload(info, COURT_DOCUMENTS_MSA)}
                            ref={node => {
                              this['25Court-WCBoardDocuments'] = node;
                            }}>
                            {court_wc_board_documents ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(this.props.files, COURT_DOCUMENTS_MSA)}</Row>
                    </Col>
                    <Col md={11}>
                      <Row className="label">
                        <Text>30-MSA Administration Agreement</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            accept="application/pdf"
                            listType="text"
                            className="logo-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={info => this.handleDocUpload(info, ADMINISTRATION_AGREEMENT)}
                            ref={node => {
                              this['30WCMSAAdministrationAgreement'] = node;
                            }}>
                            {msa_administration_agreement ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(this.props.files, ADMINISTRATION_AGREEMENT)}</Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={11}>
                      <Row className="label">
                        <Text>35-Medical Records</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            accept="application/pdf"
                            listType="text"
                            className="logo-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={info => this.handleDocUpload(info, MEDICAL_RECORDS_MSA)}
                            ref={node => {
                              this['35MedicalRecords'] = node;
                            }}>
                            {medical_records ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(this.props.files, MEDICAL_RECORDS_MSA)}</Row>
                    </Col>
                    <Col md={11}>
                      <Row className="label">
                        <Text>40-Payment Information</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            listType="text"
                            accept="application/pdf"
                            className="logo-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={info => this.handleDocUpload(info, PAYMENT_INFORMATION_MSA)}
                            ref={node => {
                              this['40PaymentInformation'] = node;
                            }}>
                            {payment_information ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(this.props.files, PAYMENT_INFORMATION_MSA)}</Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="top" className="row">
                    <Col md={11}>
                      <Row className="label">
                        <Text>50-Supplemental Doc / Additional Info</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            listType="text"
                            accept="application/pdf"
                            className="logo-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={info => this.handleDocUpload(info, SUPPLEMENTAL_DOCUMENTATION_MSA)}
                            ref={node => {
                              this['50Supplemental-AdditionalInformation'] = node;
                            }}>
                            {supplemental_documentation_additional_info ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(files, SUPPLEMENTAL_DOCUMENTATION_MSA)}</Row>
                    </Col>
                    <Col md={11}>
                      <Row className="label">
                        <Text>60-Reduction Letter</Text>
                      </Row>
                      {REPORT_PERMISSIONS.edit && (
                        <Row type="flex" justify="center" align="middle" className="upload">
                          <Upload
                            multiple={true}
                            name="logo"
                            listType="text"
                            accept="application/pdf"
                            className="logo-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={info => this.handleDocUpload(info, REDUCTION_LETTER_MSA)}
                            ref={node => {
                              this[REDUCTION_LETTER_MSA] = node;
                            }}>
                            {supplemental_documentation_additional_info ? (
                              <Text className="logo-button uploaded">Uploaded</Text>
                            ) : (
                              <Text className="logo-button">Upload</Text>
                            )}
                          </Upload>
                        </Row>
                      )}
                      <Row>{this.renderFiles(files, REDUCTION_LETTER_MSA)}</Row>
                      <ResourceLinkFiles reportId={this.props.match.params.id} />
                    </Col>
                  </Row>

                  <Divider className="divider" />
                  {REPORT_PERMISSIONS.edit && (
                    <Row type="flex" justify="space-between" align="top" className="row">
                      <Col md={11} className="row"></Col>
                      <Col md={11} className="row">
                        <Checkbox
                          checked={show_additional_comments}
                          onChange={() => {
                            this.setState({
                              show_additional_comments: !show_additional_comments,
                            });
                            API.updateReport({ id: report_id, show_additional_comments: !show_additional_comments });
                          }}>
                          Show comment sections
                          <br />
                          on the PDF
                        </Checkbox>
                      </Col>
                    </Row>
                  )}

                  {REPORT_PERMISSIONS.edit && (
                    <WaterMarkCheck customClass="mb-3" checked={is_watermark} onChange={this.handleUpdateWaterMark} />
                  )}

                  {REPORT_PERMISSIONS.signature && !this.state.is_template && (
                    <div className="signatureSpace">
                      <SignatureReport
                        defaultSignature={signature}
                        onChangeSignature={this.handleChangeSignature}
                        onDeleteSignature={this.handleDeleteSignature}
                        onAddSignature={this.handleAddSignature}
                        disabled={!REPORT_PERMISSIONS.edit}
                      />
                    </div>
                  )}

                  <BtnGenerateReport
                    handleUpdateReport={async () => await this.handleUpdateReport(true)}
                    handleGenerateReport={REPORT_PERMISSIONS.edit && !is_template && this.handleGenerateReport}
                    roles={this.props.roles}
                    oneBtn
                    returnToSender={this.handleReturnToSender}
                    previewReport={this.handlePreviewReport}
                    isTemplateSave={is_template ? async () => await this.handleUpdateReport(true, true) : undefined}
                  />
                </>
              )}

              {modalReturnToSender && (
                <ModalReturnToSender
                  onClose={() => this.props.history.push('/app/Reviews')}
                  returnName={assignmentReturn || ''}
                />
              )}

              {loadingInternal && (
                <Modal visible={true} footer={null} className="modal-loader">
                  <Spin />
                </Modal>
              )}
              {selectPdf && <ModalPdf path={selectPdf} close={() => this.setState({ selectPdf: '' })} />}
            </Row>
          </Row>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    reportInfo: state.report.reportInfo,
    files: state.report.files,
    resourceLinksFiles: state.report.resourceLinksFiles,
    allFiles: state.report.allFiles,
    isLoading: state.report.isLoading,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };

  return props;
};

export default connect(mapStateToProps, { updateReportInfo, getReportFiles })(DocumentUpload);
