import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Row, Col, Empty, Select, Tag, Modal } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { EditIcon, RemoveIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import { GetAssignementsStatuses } from 'utils/api';
import { notifyApiError, notifyInfo } from '../../../../../../../utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import AddAssignForm from '../AddAssignForm';
import { calculateColor } from '../../../lib/utils/calculateColor';

import './data-table.scss';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const { Text } = Typography;
const { Option } = Select;

// eslint-disable-next-line react/prop-types
const DataTable = ({ fetchAssignments, assignments, onDelete, loading, filters }) => {
  const [statuses, setStatuses] = useState({}); //statuses map
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState({});

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const { statuses } = await GetAssignementsStatuses();
        //set status label as key for select field
        setStatuses(Object.assign({}, ...Object.entries(statuses).map(([a, b]) => ({ [b]: a }))));
      } catch (e) {
        notifyApiError(e);
      }
    };
    fetchStatuses();
  }, []);

  const { roles } = useSelector(state => state.auth);

  const saveEdit = async edit => {
    let newEdit = { ...edit };

    delete newEdit.id;
    delete newEdit.report;
    delete newEdit.claimant;
    delete newEdit.status;
    delete newEdit.user;
    delete newEdit.assignment_type;
    delete newEdit.tenant_id;

    newEdit.start_date = moment(newEdit.start_date).format('YYYY-MM-DD');
    newEdit.end_date = moment(newEdit.end_date).format('YYYY-MM-DD');

    try {
      const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
      const isUserQA = isAllowed(roles, ROLES_DIVISIONS.QA);

      const params = isUserQA && !isUserAdmin ? { ...newEdit, tenantOnly: true } : newEdit;

      await API.UpdateAssignement(edit.id, params);

      setOpen(false);
      setEdit(false);
      fetchAssignments();
      notifyInfo('Assignment updated');
    } catch (e) {
      notifyApiError(e);
    }
  };

  const onChangeStatus = assignmentId => async value => {
    try {
      await API.UpdateAssignement(assignmentId, { status: statuses[value] });
      notifyInfo('Assignment status updated');
    } catch (e) {
      notifyApiError(e);
    }
  };

  const friendlyTypes = {
    assignment_type_id: 'Assignment Type',
    claimant_id: 'Claimant',
    client_id: 'Client',
    end_date: 'Due date',
    note: 'Note',
    start_date: 'Start date',
    status: 'Status',
    user_id: 'Assignee',
  };

  return (
    <Fragment>
      <div className="assignment-table">
        <Row type="flex" className="assign-table-header">
          <Col md={3}>Due Date</Col>
          <Col md={4}>Assignee</Col>
          <Col md={4}>Report</Col>
          <Col md={6}>Note</Col>
          <Col md={4}>Status</Col>
          <Col md={3} className="text-right">
            Actions
          </Col>
        </Row>
        <Row>
          <SpinBox loading={loading}>
            {Object.keys(filters).some(key => !!filters[key]) ? (
              <div className="filter-indicator">
                <Text strong>
                  Filters: &nbsp;
                  {Object.keys(filters)
                    .filter(key => !!filters[key])
                    .map(key => (
                      <Tag key={key}>{friendlyTypes[key]}</Tag>
                    ))}
                </Text>
              </div>
            ) : (
              <></>
            )}
            {assignments.length > 0 ? (
              <>
                {assignments.map((data, index) => (
                  <Row
                    key={`${index}-${data.id}`}
                    className="assigned-data-content"
                    style={{ borderLeftColor: calculateColor(data.end_date) }}>
                    <Col md={3}>
                      <Text>{moment(data.end_date).format('MMM DD, YYYY')}</Text>
                    </Col>
                    <Col md={4}>
                      <Text>{data.user ? `${data.user.given_name} ${data.user.family_name}` : ''}</Text>
                    </Col>
                    <Col md={4}>
                      <Text>{data.report && data.report.report_name}</Text>
                    </Col>
                    <Col md={6}>
                      <Row type="flex" align="middle">
                        <Text>{data.note}</Text>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <div className="assignment-status-col">
                        <Select
                          className="assignment-status-select"
                          defaultValue={data.status.name}
                          onChange={onChangeStatus(data.id)}
                          size="small">
                          {Object.keys(statuses).map(statusLabel => (
                            <Option key={statusLabel} value={statusLabel}>
                              {statusLabel}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col md={3} className="actions">
                      <Row>
                        <EditIcon
                          onClick={() => {
                            setEdit(data);
                            setOpen(true);
                          }}
                          style={{ marginRight: 15, cursor: 'pointer' }}
                        />
                        <RemoveIcon style={{ cursor: 'pointer' }} onClick={() => onDelete(data.id)} />
                      </Row>
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              <Empty
                description={loading ? false : 'No Data'}
                image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                className="empty-icon"
              />
            )}
            <Modal
              width={700}
              bodyStyle={{ padding: 0, minWidth: 450 }}
              visible={open}
              onCancel={() => {
                setOpen(!open);
              }}
              footer=""
              destroyOnClose>
              <AddAssignForm onSubmit={saveEdit} edit={edit} />
            </Modal>
          </SpinBox>
        </Row>
      </div>
    </Fragment>
  );
};

export default DataTable;
