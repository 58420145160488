import React from 'react';
import { Col, Row, Checkbox, Typography } from 'antd';
import { SPECIALTY_REPORT, SPECIALTY_IDS_OBJECT } from 'constants/specialty';

const { Text } = Typography;

const SpecialtyCheck = ({ value, onChange }) => {
  return (
    <>
      <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
        {SPECIALTY_REPORT.map(specialty => (
          <Col md={12} className="row" key={specialty.id}>
            <Checkbox checked={value === specialty.id} onChange={e => onChange({ event: e, specialty })}>
              {specialty.name}
            </Checkbox>
          </Col>
        ))}
      </Row>

      {value && (
        <Row className="main-info-report createReport_alert">
          <Text>{SPECIALTY_IDS_OBJECT[value].messageReport}</Text>
        </Row>
      )}
    </>
  );
};

export default SpecialtyCheck;
