import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs'; // por alguna razon si borro esta linea la libreria de abajo se daña
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Button } from 'antd';
import { WaterMarkCheck } from 'components/WaterMarkCheck';
import './previewCompare.scss';

const PreviewCompare = ({ path, onPreview, watermark, onCheckWatermark, onConfirm, onAssignLegalNurse }) => {
  return (
    <div className="previewCompare table-content pt-5">
      <div className="previewCompare_pdf">
        {path.map(item => (
          <Viewer key={item} fileUrl={item} defaultScale={1.4} />
        ))}
      </div>

      <WaterMarkCheck checked={watermark} onChange={onCheckWatermark} />

      <div className="previewCompare_btn">
        <Button size="large" className="transparent-btn btn-normal" onClick={onPreview}>
          Go Back
        </Button>

        <Button type="primary" size="large" className="green-btn btn-normal" onClick={onConfirm}>
          Generate Report
        </Button>

        <Button type="primary" size="large" className="purple-btn btn-normal" onClick={onAssignLegalNurse}>
          Assign Legal Nurse
        </Button>
      </div>
    </div>
  );
};

export default PreviewCompare;
