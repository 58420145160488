import { useState } from 'react';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';

export default function usePricingGlobal() {
  const [loadingPricingGlobal, setLoadingPricingGlobal] = useState(false);
  const [listPricingGlobal, setListPricingGlobal] = useState([]);

  const getPricingGlobal = async () => {
    try {
      setLoadingPricingGlobal(true);
      const pricingGlobal = await API.getPricingGlobal();
      setListPricingGlobal(pricingGlobal?.data?.packages || []);
    }catch (e) {
      notifyApiError(e)
    }finally {
      setLoadingPricingGlobal(false);
    }
  }

  return {
    loadingPricingGlobal,
    listPricingGlobal,
    getPricingGlobal,
  };
}
