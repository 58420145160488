import React from 'react';
import { Modal, Spin } from 'antd';

const ModalLoading = () => (
  <div className="modalInportant">
    <Modal visible={true} footer={null} className="modal-loader" zIndex={99999999999}>
      <Spin />
    </Modal>
  </div>
);
export default ModalLoading;
