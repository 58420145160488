import React from "react";
import Cards from "react-credit-cards";
import { Col, Alert } from "antd";
//Credit Card Reusable Component
export default function CreditCard({
  cardFields,
  handleCardInput,
  handleCardFocus,
  cvcErrorr,
}) {
  return (
    <div>
      <Col md={24}>
        <Cards
          cvc={cardFields.cvc}
          expiry={cardFields.expiry}
          focused={cardFields.focus}
          name={cardFields.name}
          number={cardFields.number}
        />
      </Col>

      <Col md={24}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "15px",
          }}
        >
          <input
            className="card_input"
            pattern="[\d| ]{16,22}"
            type="tel"
            name="number"
            value={cardFields.number}
            placeholder="Card Number"
            onChange={handleCardInput}
            onFocus={handleCardFocus}
          />
          <input
            type="text"
            name="name"
            className="card_input"
            value={cardFields.name}
            placeholder="Name"
            onChange={handleCardInput}
            onFocus={handleCardFocus}
          />

          <input
            type="text"
            name="expiry"
            className="card_input"
            value={cardFields.expiry}
            placeholder="Valid Thru"
            onChange={handleCardInput}
            onFocus={handleCardFocus}
          />
          <input
            type="number"
            name="cvc"
            className="card_input"
            value={cardFields.cvc}
            placeholder="Cvc"
            min={0}
            onChange={handleCardInput}
            onFocus={handleCardFocus}
          />
          {cvcErrorr && <Alert type="error" message={cvcErrorr} banner />}
        </form>
      </Col>
    </div>
  );
}
