import { AmericanExpressIcon, DiscoverIcon, VisaIcon, MasterCardIcon, CreditCardIcon } from 'components/Svg';

export const getTypeCard = ({ cardNumber }) => {
  if (cardNumber.length === 15 || cardNumber.length === 16) {
    const bin = cardNumber.slice(0, 4);

    if (bin === '6011')
      return {
        type: 'Discover',
        Icon: DiscoverIcon,
      };

    if (bin.startsWith('34') || bin.startsWith('37'))
      return {
        type: 'American Express',
        Icon: AmericanExpressIcon,
      };

    if (bin.startsWith('4'))
      return {
        type: 'Visa',
        Icon: VisaIcon,
      };

    if (bin.startsWith('5'))
      return {
        type: 'Mastercard',
        Icon: MasterCardIcon,
      };
  }

  return {
    type: 'Stranger',
    Icon: CreditCardIcon,
  };
};

export const getIconCard = ({ type }) => {
  const cardType = {
    Discover: DiscoverIcon,
    AmericanExpress: AmericanExpressIcon,
    Visa: VisaIcon,
    MasterCard: MasterCardIcon,
    Default: CreditCardIcon,
  }

  // mostrar un default
  return {
    type,
    Icon: cardType[type || 'Default'],
  };
};

export const hiddenCreditCard = ({ card }) => {
	const lastFour = card.slice(-4)
	return card.slice(0, -4).replace(/./g, '*') + lastFour
}
