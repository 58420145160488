/* global process  */
import React, { useState } from 'react';
import { useAcceptJs } from 'react-acceptjs';
import { useSelector } from 'react-redux';

// Components
import InputBox from 'components/InputBox';
import { InputCardNumber } from 'components/InputCardNumber';
import { CustomCheckBox } from 'components/CustomCheckBox';
import { Button } from 'antd';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Hooks
import { useCreditCard } from 'hooks';

// Utils
import { formatExpired, validInputNumber } from 'utils/utils';
import { notifyApiError } from 'utils/notification';
import { getClientId } from 'layout/roles';

const FormAddCreditCard = ({
  publicKey,
  textBtn,
  afterAdd,
  goBack,
}) => {
  const { roles } = useSelector(state => state.auth);

  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [selectDefault, setSelectDefault] = useState(false);

  const { loadingCreditCard, addCreditCard } = useCreditCard();

  const { dispatchData } = useAcceptJs({
    authData: {
      apiLoginID: process.env.REACT_APP_AUTHORIZENET_API_KEY,
      clientKey: publicKey,
    },
    environment: 'PRODUCTION'
  });

  const handleSendData = async () => {
    const response = await dispatchData({
      cardData: {
        cardNumber,
        month: expirationDate.substring(0, 2),
        year: expirationDate.slice(-2),
        cardCode: cvv,
      },
    });
    if (!response?.opaqueData?.dataValue) return notifyApiError('The card details are incorrect');

    await addCreditCard({ token: response.opaqueData.dataValue, selectDefault, client_id: getClientId(roles) });
    afterAdd && afterAdd();
  };

  return (
    <>
      {loadingCreditCard && <ModalLoading />}
      <div className="modalAddCardPayInvoices">
        <div className="modalAddCardPayInvoices_gruop">
          <InputCardNumber cardNumber={cardNumber} onChange={setCardNumber} />
          <InputBox
            label="Expiration Date"
            value={expirationDate}
            onChange={value => setExpirationDate(formatExpired({ value: value }))}
            placeholder="MM/YY"
          />
        </div>
        <div className="modalAddCardPayInvoices_gruop">
          <InputBox
            label="CVV Code"
            value={cvv}
            onChange={value => setCvv(validInputNumber({ value: value, max: 4 }))}
            placeholder="CVV Code"
          />
        </div>

        <CustomCheckBox
          optionSignature={true}
          onChange={() => setSelectDefault(prevState => !prevState)}
          label="Select as default"
          htmlId="selectDefaultCreditCardAdd"
          value={selectDefault}
        />

        <div className="modalAddCardPayInvoices_btn">
          {goBack && (
            <Button type="primary" size="large" className="button btn_transparente" onClick={goBack}>
              close
            </Button>
          )}
          <Button type="primary" size="large" className="m-2 green-btn button btn-normal" onClick={handleSendData}>
            {textBtn}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddCreditCard;
