import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Divider } from 'antd';
import SpinBox from 'components/SpinBox';
import InputCurrencyBox from 'components/InputCurrencyBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import * as Logic from './logic';
import DropDown from 'components/Dropdown';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import { REPORT_MSA_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import {
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  FUTURE_MEDICAL_NEEDED,
  PRESCRIPTION_MEDICATIONS_COVERED,
  DONT_CHANGE_COST_ON_THIS_TYPE,
} from 'components/MedicareCostModal/data';

import './ProposedMedicare.scss';

const { Title, Text } = Typography;

class ProposedMedicare extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      medicare: [],
      report: {},
      report_id: report_id,
      contactsList: [],
      life_expectancy: 1,
      anticipated_medicare_costs: '',
      proposed_amount_for_future_from_medicare: '',
      proposed_amount_for_future_not_covered_from_medicare: '',
      cost_of_first_surgery_and_first_procedure_replacement: '',
      total_allocation_minus_first_surgery_procedure_replacement: 0,
      seed_money_or_initial_deposit: 0,
      annuity_payment_over_remaining_expectancy_at_starting_date: 0,
      loadingText: 'Loading',
      loading: false,
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };

  componentDidMount = () => {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        let anticipated_medicare_costs =
          reportInfo.cost_of_first_surgery_and_first_procedure_replacement +
          reportInfo.total_allocation_minus_first_surgery_procedure_replacement;

        API.getMedicareData({ reportId: report_id }).then(res => {
          let default_anticipated_medicare_costs = 0,
            default_proposed_amount_for_future_from_medicare = 0,
            default_proposed_amount_for_future_not_covered_from_medicare = 0,
            default_cost_of_first_surgery_and_first_procedure_replacement = 0;
          res.medicare_cost_projections.forEach(item => {
            if (
              item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE ||
              item.type === DONT_CHANGE_COST_ON_THIS_TYPE ||
              item.type === PRESCRIPTION_MEDICATIONS_COVERED
            ) {
              default_anticipated_medicare_costs += item.total_cost;
            }
            if (item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE) {
              default_proposed_amount_for_future_from_medicare += item.total_cost;
            }
            if (item.type === FUTURE_MEDICAL_NEEDED) {
              default_proposed_amount_for_future_not_covered_from_medicare += item.total_cost;
            }
            if (
              item.type === DONT_CHANGE_COST_ON_THIS_TYPE &&
              default_cost_of_first_surgery_and_first_procedure_replacement === 0
            ) {
              default_cost_of_first_surgery_and_first_procedure_replacement = item.total_cost;
            }
          });
          this.setState({
            is_template: reportInfo.is_template,
            report: reportInfo,
            medicare: res.medicare_cost_projections,
            life_expectancy: reportInfo.life_expectancy || 1,
            anticipated_medicare_costs: anticipated_medicare_costs || default_anticipated_medicare_costs,
            proposed_amount_for_future_from_medicare:
              reportInfo.proposed_amount_for_future_from_medicare || default_proposed_amount_for_future_from_medicare,
            proposed_amount_for_future_not_covered_from_medicare:
              reportInfo.proposed_amount_for_future_not_covered_from_medicare ||
              default_proposed_amount_for_future_not_covered_from_medicare,
            cost_of_first_surgery_and_first_procedure_replacement:
              reportInfo.cost_of_first_surgery_and_first_procedure_replacement ||
              default_cost_of_first_surgery_and_first_procedure_replacement,
            loading: false,
          });
          this.props.updateReportInfo(res.report);
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  };

  handleUpdateReport = async (isNextStep = false) => {
    const {
      report_id,
      life_expectancy,
      anticipated_medicare_costs,
      proposed_amount_for_future_from_medicare,
      proposed_amount_for_future_not_covered_from_medicare,
      cost_of_first_surgery_and_first_procedure_replacement,
    } = this.state;

    let total_allocation_minus_first_surgery_procedure_replacement = Number(
      anticipated_medicare_costs - cost_of_first_surgery_and_first_procedure_replacement
    ).toFixed(2);
    let seed_money_or_initial_deposit = Number(
      (total_allocation_minus_first_surgery_procedure_replacement / life_expectancy) * 2 +
        cost_of_first_surgery_and_first_procedure_replacement
    ).toFixed(2);
    let annuity_payment_over_remaining_expectancy_at_starting_date = Number(
      anticipated_medicare_costs - seed_money_or_initial_deposit
    ).toFixed(2);

    const params = {
      id: report_id,
      anticipated_medicare_costs,
      proposed_amount_for_future_from_medicare,
      proposed_amount_for_future_not_covered_from_medicare,
      cost_of_first_surgery_and_first_procedure_replacement,
      total_allocation_minus_first_surgery_procedure_replacement,
      seed_money_or_initial_deposit,
      annuity_payment_over_remaining_expectancy_at_starting_date,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status === 201) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const { loadingTitle, loading } = this.state;

    return (
      <Row className="msa-proposed-medicare-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MSA_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            {isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY) ? (
              <DropDown
                loading={loading}
                goBack={() =>
                  this.props.history.push('/app/AttorneyClaimants/ViewMsaReports', {
                    data: this.props.history.location.state.data.claimantInfo,
                  })
                }
              />
            ) : (
              <DropDown beforeNext={async () => await this.handleUpdateReport(false)} loading={loading} />
            )}
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Proposed Medicare Set-aside Amount</Text>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Anticipated Medicare Costs"
                    placeholder="Please enter"
                    value={Logic.total_anticipated_medicare_cost(this.state.medicare).toFixed(2)}
                    onChange={value => this.setState({ anticipated_medicare_costs: value })}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Proposed amount for future medical treatment from Medicare"
                    placeholder="Please enter"
                    value={Logic.anticipated_medicare_cost(this.state.medicare).toFixed(2)}
                    onChange={value =>
                      this.setState({
                        proposed_amount_for_future_from_medicare: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Proposed amount for future medical treatment not covered by medicare"
                    placeholder="Please enter"
                    value={Logic.anticipated_non_medicare_cost(this.state.medicare).toFixed(2)}
                    onChange={value =>
                      this.setState({
                        proposed_amount_for_future_not_covered_from_medicare: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Cost of surgery and first procedure/replacement"
                    placeholder="Please enter"
                    value={Logic.first_surgery_cost(this.state.medicare).toFixed(2)}
                    onChange={value =>
                      this.setState({
                        cost_of_first_surgery_and_first_procedure_replacement: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Total allocation minus cost of surgery and first procedure/replacement"
                    placeholder="$0.00"
                    value={
                      Logic.total_anticipated_medicare_cost(this.state.medicare).toFixed(2) -
                      Logic.first_surgery_cost(this.state.medicare).toFixed(2)
                    }
                    onChange={value =>
                      this.setState({
                        total_allocation_minus_first_surgery_procedure_replacement: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Seed money or initial deposit"
                    placeholder="$0.00"
                    value={Logic.seedMoney(this.state.medicare, this.state.report).seed_money.toFixed(2)}
                    onChange={value => this.setState({ seed_money_or_initial_deposit: value })}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    value={Logic.seedMoney(this.state.medicare, this.state.report).annuity_amount_over_life.toFixed(2)}
                    label="Annuity Payment over life expectancy remaining at annuity starting date"
                    placeholder="$0.00"
                    onChange={value =>
                      this.setState({
                        annuity_payment_over_remaining_expectancy_at_starting_date: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Divider className="divider" />
              <PrevNextStep beforeNext={async () => await this.handleUpdateReport(true)} />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    roles: state.auth.roles,
    userInfo: state.auth.userInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(ProposedMedicare);
