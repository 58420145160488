const defaultMessage = {
  headerTitle: 'Assignment Board',
  headerSubTitle: 'All Assignments',
  viewMode: 'View Mode',
  table: 'table',
  calendar: 'calendar',
  updateTitle: 'Update Assignment',
};

export default defaultMessage;
