import React from 'react';
import { Modal, Typography, Select, Button } from 'antd';
import { OPTION_CREATE_LCP } from 'containers/Claimants/DetailedClaimant/ReportModal/ReportModal';

import './modalChooseTemplateLCP.scss';

const { Text } = Typography;
const { Option } = Select;

const ModalChooseTemplateLCP = ({ close, handleChange, dataList, onConfirm, selectId }) => {
  return (
    <Modal
      visible={true}
      onCancel={close}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      className="report-modal"
      title="Choose Life Care Plan Template">
      <div className="reportLifeCarePlanModal_content">
        <Text>Select Template</Text>

        <Select
          getPopupContainer={triggerNode => triggerNode.parentNode}
          showSearch
          placeholder="Please Select"
          optionFilterProp="children"
          style={{ width: '100%' }}
          onChange={id => handleChange({ id })}>
          <Option className="optionLink" value={OPTION_CREATE_LCP}>
            Add Template
          </Option>
          {dataList.map(tempalte => (
            <Option value={tempalte.id} key={tempalte.id}>
              {tempalte.report_name}
            </Option>
          ))}
        </Select>

        <div className="reportLifeCarePlanModal_content_next">
          <Button
            disabled={selectId === null}
            type="primary"
            size="default"
            className="green-btn button"
            onClick={onConfirm}>
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalChooseTemplateLCP;
