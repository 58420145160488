import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Button, Divider, Modal, Tooltip } from 'antd';
import DeleteModal from 'components/DeleteModal';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import MedicareCostModal from 'components/MedicareCostModal';
import MedicareCostTable from 'components/MedicareCostTable';
import PrescriptionTable from 'components/PrescriptionTable';
import { getPosition, get2NewPositions } from 'utils/shared';
import { verifyPermissions, forbiddenRedirect } from '../../utils/verify-permissions';
import PrevNextStep from '../../../../components/PrevNextStep';
import RecalculateMedicareCost from 'components/RecalculateMedicareCost';
import moment from 'moment';
import { REPORT_MSA_TYPE_ID, REPORT_SURGICAL_TYPE_ID } from 'constants/reports';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { MAIN_NAV } from 'layout/navigate_const';
import './MedicareCost.scss';
import { REPORT_MCP_MSA_COMBO_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import {
  MSA_MEDICARE,
  ACTION_TYPE_ADD_MEDICARE,
  PRESCRIPTION_MEDICATIONS_COVERED,
  FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE,
  DONT_CHANGE_COST_ON_THIS_TYPE,
} from 'components/MedicareCostModal/data';

const { Title, Text } = Typography;

class MedicareCost extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id,
      reportData: {},
      contactsList: [],
      medicareDataList: [],
      cptList: [],
      cptCode: null,
      unitFormList: [],
      specialityList: [],
      actionType: ACTION_TYPE_ADD_MEDICARE,
      selectedItem: null,
      showMedicareModal: false,
      loading: false,
      loadingTitle: 'Loading...',
      showRecalculateModal: false,
      REPORT_PERMISSIONS,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);

    this.searchCodes = this.searchCodes.bind(this);
    this.searchCodeById = this.searchCodeById.bind(this);
    this.openMedicareModal = this.openMedicareModal.bind(this);
    this.closeMedicareModal = this.closeMedicareModal.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleMedicareCost = this.handleMedicareCost.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleSeed = this.handleSeed.bind(this);
    this.closeRecalculateModal = this.closeRecalculateModal.bind(this);
    this.handleClone = this.handleClone.bind(this);
    this.loadMedicare = this.loadMedicare.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.timeoutId);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getSpeciality().then(res => {
      this.setState({
        specialityList: res.specialities,
      });
    });

    API.getReportById(report_id)
      .then(async res => {
        if (!res.is_template) {
          verifyPermissions(res, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: res.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(res);
        const resUpdate = moment(res.updatedAt).format('YYYY-MM-DDDD');
        const resCreate = moment(res.createdAt).format('YYYY-MM-DDDD');

        if (
          !res.is_template &&
          res.report_status_id === 1 &&
          res.report_template_id > 0 &&
          !res._medicare_costs &&
          !res.pricing_group_id &&
          !res.zip_url &&
          resUpdate === resCreate
        ) {
          this.setState({
            showRecalculateModal: true,
            is_template: res.is_template,
            reportData: res,
          });
        } else {
          this.setState({
            is_template: res.is_template,
            reportData: res,
          });
        }
      })
      .catch(err => forbiddenRedirect(err, this.props));
    API.getUnitForms().then(res => {
      this.setState({
        unitFormList: res.unit_forms,
      });
    });
    API.getMedicareData({ reportId: report_id, report_type_id: REPORT_MSA_TYPE_ID }).then(res => {
      let beforePosition = -1;
      let medicareSorted = [
        ...res.medicare_cost_projections
          .sort((a, b) => a.position - b.position)
          .map(item => {
            const position =
              beforePosition !== -1 && item.position <= beforePosition ? beforePosition + 1 : item.position;
            beforePosition = position;
            return {
              ...item,
              position,
            };
          }),
      ];
      this.setState({
        medicareDataList: medicareSorted,
        loading: false,
      });
    });
  }

  searchCodes(value, id = null) {
    API.getCPTData(value, id).then(res => {
      this.setState({
        cptList: res.cpt_codes,
      });
    });
  }

  searchCodeById(id) {
    API.getCPTById(id).then(cpt_code => {
      this.setState({
        cptCode: cpt_code,
      });
    });
  }

  openMedicareModal(actionType, selectedItem) {
    this.setState({
      actionType,
      selectedItem,
      showMedicareModal: true,
    });
  }

  closeMedicareModal() {
    this.setState({ showMedicareModal: false, cptList: [] });
  }

  handleChangePosition(id, move) {
    let result = get2NewPositions(this.state.medicareDataList, id, move);
    if (result.success) {
      let Promise1 = API.updateMedicareItem(result.update1);
      let Promise2 = API.updateMedicareItem(result.update2);
      Promise.all([Promise1, Promise2]).then(() => {
        this.setState({ medicareDataList: result.newArr }, () => {
          const item2 = document.getElementById('table-item-' + result.update1.id + result.update1.position);
          const item1 = document.getElementById('table-item-' + result.update2.id + result.update2.position);

          item2.classList.remove('animate-' + (move === 'up' ? 'down' : 'up'));
          item1.classList.remove('animate-' + move);
        });
      });
    }
  }

  handleMedicareCost(params) {
    const { actionType, report_id, selectedItem } = this.state;

    this.closeMedicareModal();
    this.setState({ loading: true });
    if (actionType === ACTION_TYPE_ADD_MEDICARE) {
      let position = getPosition(this.state.medicareDataList, params.type);
      const param = {
        position,
        ...params,
        report_id,
        report_type_id: REPORT_MSA_TYPE_ID,
      };
      API.createMedicareItem(param)
        .then(() => {
          API.getMedicareData({ reportId: report_id, report_type_id: REPORT_MSA_TYPE_ID }).then(res => {
            this.setState({
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch(() => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: 'Error',
              });
            }
          );
        });
    } else {
      const param = {
        ...params,
        id: selectedItem.id,
      };
      API.updateMedicareItem(param)
        .then(() => {
          API.getMedicareData({ reportId: report_id, report_type_id: REPORT_MSA_TYPE_ID }).then(res => {
            this.setState({
              selectedItem: null,
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch(() => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: 'Error',
              });
            }
          );
        });
    }
  }

  handleEdit() {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.updateMedicareItem(selectedItem.id)
      .then(() => {
        API.getMedicareData({ reportId: report_id, report_type_id: REPORT_MSA_TYPE_ID }).then(res => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch(() => {
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: 'Error',
            });
          }
        );
      });
  }

  handleDelete() {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.deleteMedicareItem(selectedItem.id).then(() => {
      API.getMedicareData({ reportId: report_id, report_type_id: REPORT_MSA_TYPE_ID }).then(res => {
        this.setState({
          medicareDataList: res.medicare_cost_projections,
          loading: false,
        });
      });
    });
  }

  openDeleteModal(selectedItem) {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  }

  closeDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  handleSeed(id, isSeed, type) {
    const params = { id: id, is_seed_calculated: !isSeed };
    API.updateMedicareItem(params).then(() => {
      if (type === 'futureList') {
        var futureList = this.state.medicareDataList.filter(item => {
          return item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE;
        });
        for (var i = 0; i < futureList.length; i++) {
          if (futureList[i].id === id) {
            futureList[i].is_seed_calculated = !isSeed;
            this.setState({
              medicareDataList: [...this.state.medicareDataList, futureList],
            });
          }
        }
      } else if (type === 'surgeriesList') {
        var surgeriesList = this.state.medicareDataList.filter(item => {
          return item.type === DONT_CHANGE_COST_ON_THIS_TYPE;
        });
        let i;
        for (i = 0; i < surgeriesList.length; i++) {
          if (surgeriesList[i].id === id) {
            surgeriesList[i].is_seed_calculated = !isSeed;
            this.setState({
              medicareDataList: [...this.state.medicareDataList, surgeriesList],
            });
          }
        }
      } else if (type === 'prescriptionList') {
        var prescriptionList = this.state.medicareDataList.filter(item => {
          return item.type === PRESCRIPTION_MEDICATIONS_COVERED;
        });
        for (i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].id === id) {
            prescriptionList[i].is_seed_calculated = !isSeed;
            this.setState({
              medicareDataList: [...this.state.medicareDataList, prescriptionList],
            });
          }
        }
      }
    });
  }

  closeRecalculateModal() {
    this.setState({ showRecalculateModal: false });
  }

  handleClone(item) {
    const { report_id } = this.state;
    if (this.state.loadingClone) {
      alert('Cloning in Process, please wait! ');
      return;
    }
    this.setState({ loadingClone: true });
    this.setState({ loading: true });
    let position = getPosition(this.state.medicareDataList, item.type);
    let newItem = { ...item, position };

    delete newItem.id;
    delete newItem.updatedAt;
    delete newItem.createdAt;
    delete newItem.deletedAt;
    delete newItem.cpt_code;
    API.createMedicareItem(newItem)
      .then(() => {
        this.cloning = false;
        this.setState({ loadingClone: false });
        API.getMedicareData({ reportId: report_id, report_type_id: REPORT_MSA_TYPE_ID }).then(res => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch(() => {
        this.setState({ loadingClone: false });
        this.cloning = false;
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: "Error, can't clone the item",
            });
          }
        );
      });
  }

  loadMedicare() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getMedicareData({ reportId: report_id, report_type_id: REPORT_MSA_TYPE_ID }).then(res => {
      this.setState({
        medicareDataList: res.medicare_cost_projections,
        loading: false,
      });
    });
  }

  render() {
    const {
      showMedicareModal,
      selectedItem,
      showDeleteModal,
      actionType,
      medicareDataList,
      cptList,
      unitFormList,
      specialityList,
      loading,
      loadingTitle,
      cptCode,
      showRecalculateModal,
      REPORT_PERMISSIONS,
    } = this.state;

    const { reportInfo } = this.props;

    const futureList = medicareDataList.filter(item => {
      return item.type === FUTURE_MEDICAL_NEED_COVERED_BY_MEDICARE;
    });
    const surgeriesList = medicareDataList.filter(item => {
      return item.type === DONT_CHANGE_COST_ON_THIS_TYPE;
    });
    const prescriptionList = medicareDataList.filter(item => {
      return item.type === PRESCRIPTION_MEDICATIONS_COVERED;
    });

    const isStateNotSet =
      reportInfo.report_type_id !== REPORT_SURGICAL_TYPE_ID &&
      !reportInfo.is_template &&
      !reportInfo.juridiction_state_id &&
      !reportInfo.is_owcp;

    return (
      <Row className="msa-medicare-cost-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MCP_MSA_COMBO_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown loading={loading} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <Text className="title">Medicare Cost Projection</Text>
                </Col>
              </Row>

              {REPORT_PERMISSIONS.edit && (
                <Row type="flex" justify="end" className="row">
                  <Tooltip
                    title={
                      isStateNotSet
                        ? 'Set State of Jurisdiction or Choose OWCP Pricing option on Demographic step '
                        : 'Add Medical Cost item'
                    }>
                    <Button
                      disabled={isStateNotSet}
                      type="primary"
                      size="default"
                      className="green-btn button"
                      onClick={() => this.openMedicareModal(ACTION_TYPE_ADD_MEDICARE, null)}>
                      Add Medicare Cost
                    </Button>
                  </Tooltip>
                </Row>
              )}
              <Row className="section">
                <Row className="sub-title">
                  <Text>1. Medical Costs</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  data={futureList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleSeed={(id, isSeed) => {
                    this.handleSeed(id, isSeed, 'futureList');
                  }}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                  canEdit={!REPORT_PERMISSIONS.edit}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>2. Surgeries, Replacements and Procedures</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  data={surgeriesList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleSeed={(id, isSeed) => {
                    this.handleSeed(id, isSeed, 'surgeriesList');
                  }}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                  canEdit={!REPORT_PERMISSIONS.edit}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>3. Prescription Drug Data</Text>
                </Row>
                <PrescriptionTable
                  data={prescriptionList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleSeed={(id, isSeed) => {
                    this.handleSeed(id, isSeed, 'prescriptionList');
                  }}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                  canEdit={!REPORT_PERMISSIONS.edit}
                />
              </Row>
              <Divider className="divider" />
              <PrevNextStep />
            </Row>
          </SpinBox>
        </Row>
        <MedicareCostModal
          searchCodes={this.searchCodes}
          searchCodeById={this.searchCodeById}
          isOpen={showMedicareModal}
          actionType={actionType}
          modalType={MSA_MEDICARE}
          specialityList={specialityList}
          cptList={cptList}
          cptCode={cptCode}
          unitFormList={unitFormList}
          selectedItem={selectedItem}
          reportData={reportInfo}
          onConfirm={this.handleMedicareCost}
          onCancel={this.closeMedicareModal}
        />
        <DeleteModal
          isOpen={showDeleteModal}
          title="Delete Medicare Item"
          content={selectedItem && selectedItem.name}
          onConfirm={this.handleDelete}
          onCancel={this.closeDeleteModal}
        />
        <RecalculateMedicareCost
          reportData={reportInfo}
          isOpen={showRecalculateModal}
          medicareList={medicareDataList}
          onCancel={this.closeRecalculateModal}
          loadMedicare={this.loadMedicare}
          isMedicare={true}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
};

export default connect(mapStateToProps, { updateReportInfo })(MedicareCost);
