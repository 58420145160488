import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Button, Icon, Select, Modal, notification } from 'antd';

import { BackIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';

import './MergeClient.scss';
import { useEffect } from 'react';

const { Title } = Typography;
const { Option } = Select;

export default function MergeClient() {
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [ClientOne, setClientOne] = useState('');
  const [ClientTwo, setClientTwo] = useState('');
  const history = useHistory();

  const onSubmit = e => {
    e.preventDefault();
    setModalOpen(true);
  };

  const mergeClient = async () => {
    const status = await API.mergeClients({
      main_client_id: ClientOne,
      secondary_client_id: ClientTwo,
    });
    if (status.success) {
      notification['success']({
        message: 'Merged clients',
        description: 'The clients were merged',
      });
    } else {
      notification['error']({
        message: 'Error',
        description: 'Could not merge clients',
      });
    }
  };

  const getUsers = async () => {
    setLoading(true);
    const { clients } = await API.getClientsData('', 0, 1000, false);
    setClientList(clients);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Row className="msa-merge-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Merge Client
          </Title>
        </Col>
        <Col className="back" onClick={() => history.goBack()}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <div className="main">
        <SpinBox loading={loading} title={'Loading'}>
          <form onSubmit={onSubmit} style={{ height: '100%' }}>
            <div className="main-box">
              <div>
                <p className="title-merge">Merge Clients</p>
                <p className="description-merge">Select the two clients you want to merge into one claimant account.</p>

                <div className="stepFormContainer">
                  <div className="stepFormGroup">
                    <label htmlFor="state">Select first client</label>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={ClientOne}
                      onChange={value => setClientOne(value)}
                      name="state"
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      <Option disabled value="">
                        Required
                      </Option>
                      {clientList.map((el, i) => {
                        return (
                          <Option key={i} value={el.id}>
                            {el.client_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="stepFormGroup">
                    <label htmlFor="state">Select second client</label>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={ClientTwo}
                      onChange={value => setClientTwo(value)}
                      name="state"
                      suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
                      <Option disabled value="">
                        Required
                      </Option>
                      {clientList.map((el, i) => {
                        return (
                          <Option key={i} value={el.id}>
                            {el.client_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="merge-btn-container">
                <Button htmlType="submit" type="primary" className="purple-btn">
                  Merge Accounts
                </Button>
              </div>
            </div>
          </form>
          <Modal visible={modalOpen} footer={null} onCancel={() => setModalOpen(false)}>
            <div className="msa-modal-content">
              <Title level={4} className="title-text">
                You’re about to merge this two accounts
              </Title>
              <p style={{ fontSize: '15px' }}>Are you sure you want to continue?</p>
              <div className="msa-modal-btns">
                <Button
                  type="primary"
                  className="purple-btn"
                  onClick={() => {
                    mergeClient();
                    setModalOpen(false);
                  }}>
                  Merge clients
                </Button>
                <Button type="primary" className="purple-btn-outline" onClick={() => setModalOpen(false)}>
                  Go back
                </Button>
              </div>
            </div>
          </Modal>
        </SpinBox>
      </div>
    </Row>
  );
}
