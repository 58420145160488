import React, { useState, useEffect } from 'react';
import { Col, Row, Typography, Modal, Spin } from 'antd';
import { getPresignedPublicUrlLogo } from 'utils/api';
import { notifyApiError, notifyError } from 'utils/notification';
import * as API from 'utils/api';
import axios from 'axios';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CustomCheckBox } from 'components/CustomCheckBox';
import { TrashIcon } from 'components/Svg';
import { InputSignatureReport } from '.';
import { MAX_SIGNATURE } from 'containers/Reports/utils/const';
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { useSelector } from 'react-redux';

import './SignatureReport.scss';

const { Text } = Typography;

const SignatureReport = ({
  defaultSignature,
  onChangeSignature,
  onDeleteSignature,
  onAddSignature,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState([]);
  const { roles } = useSelector(state => state.auth);

  const getData = async () => {
    setSignature(defaultSignature);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [defaultSignature]);

  const handleChangeSignature = ({
    imageUrl,
    signature: signatureParams,
    is_signature_photo,
    index,
    referral_date: referralDateParams,
  }) => {
    const updateSignature = signature.map((item, ind) => {
      if (ind === index)
        return {
          ...item,
          signature: signatureParams,
          referral_date: referralDateParams,
          is_signature_photo,
          imageUrl,
        };

      return item;
    });

    onChangeSignature({ signature: updateSignature, is_signature_photo });
  };

  const uploadSignature = async (file, index) => {
    setLoading(true);

    try {
      const { url } = await API.getPresignUrlFile({
        name: file.name,
        type: file.type,
      });

      const formData = new FormData();
      for (const key in url.data.fields) {
        formData.append(key, url.data.fields[key]);
      }
      formData.append('file', file);

      await axios.post(url.data.url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      let s3key = url.fileUrl;
      const res = await getPresignedPublicUrlLogo(s3key);
      const signedUrl = res.url || res.data.url;

      handleChangeSignature({
        imageUrl: signedUrl,
        signature: s3key,
        is_signature_photo: true,
        index,
        referral_date: signature[index].referral_date,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = async (file, index) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notifyError('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notifyError('Image must smaller than 2MB!');
    }
    await uploadSignature(file, index);
    return false;
  };

  const signatureLength = signature.length;
  const isMedicalReviewer = isAllowed(roles, [...ROLES_DIVISIONS.MANAGER]);
  const isNurseWriter = isAllowed(roles, ROLES_DIVISIONS.NURSE_ONLY);
  const isAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);

  const moreSignature = isMedicalReviewer || isNurseWriter || isAdmin ? false : signatureLength >= MAX_SIGNATURE;

  return (
    <>
      <Modal visible={loading} footer={null} className="modal-loader">
        <Spin />
      </Modal>

      <Row type="flex" justify="space-between" align="middle" className="row">
        <Col md={24}>
          <Text className="title">Signature</Text>
        </Col>
      </Row>

      {signature.map((item, index) => (
        <React.Fragment key={item.id || index}>
          <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
            <Col md={11} className="row">
              <CustomCheckBox
                optionSignature={item.is_signature_photo ? 'img' : 'name'}
                onChange={() => handleChangeSignature({ ...item, is_signature_photo: false, index })}
                label="Name as signature"
                htmlId={`optionName${index}`}
                value="name"
                disabled={disabled}
              />
            </Col>
            <Col md={11} className="row">
              <CustomCheckBox
                optionSignature={item.is_signature_photo ? 'img' : 'name'}
                onChange={() => handleChangeSignature({ ...item, is_signature_photo: true, index })}
                label="Upload a picture of signature"
                htmlId={`optionImg${index}`}
                value="img"
                disabled={disabled}
              />
            </Col>
          </Row>

          <InputSignatureReport
            key={item.signature || index}
            is_signature_photo={item.is_signature_photo}
            signature={item.signature}
            onChangeSignature={handleChangeSignature}
            beforeUpload={file => beforeUpload(file, index)}
            referral_date={item.referral_date}
            imageUrl={item.imageUrl}
            index={index}
            disabled={disabled}
          />

          {signatureLength > 1 && (
            <div className="signatureReportDelete">
              <div onClick={() => onDeleteSignature({ index })}>
                <TrashIcon color={PRIMARY_LIGHT_BLUE} />
              </div>
            </div>
          )}
        </React.Fragment>
      ))}

      {!disabled && (
        <Row type="flex" justify="end" align="middle">
          {!moreSignature && (
            <PlusCircleOutlined
              onClick={onAddSignature}
              style={{ fontSize: '22px', cursor: 'pointer', color: PRIMARY_LIGHT_BLUE }}
            />
          )}
        </Row>
      )}
    </>
  );
};

export default SignatureReport;
