import {
  REPORT_DEMAND_LETTER,
  REPORT_DEMAND_LETTER_PROJECTIONS,
  REPORT_EARLY_INTERVENTION,
  REPORT_LEGAL_DEMAND_TEMPLATE,
  REPORT_LIFE_CARE_PLAN,
  REPORT_MCP,
  REPORT_MCP_MSA,
  REPORT_MSA,
  REPORT_MSA_REDUCTION,
  REPORT_SURGICAL,
} from './reports';

export const REPORT_FIELDS = {
  [REPORT_MCP_MSA]: [
    'plaintiff_attorney', // reportcontact
    'defense_attorney', // reportcontact
    'insurance_carrier',
    'juridiction_state_id', //en lista de ignorado igual no funciona si lo quitamos de la lista de ignorados
    'Employer_demographics', //select custom que no tiene nada
    'injury_date', //se guarda pegado con otros campos en report_claim_id
    'insurance_company', //se guarda pegado con otros campos en report_claim_id
    'body_parts',
    'related_diagnosis', // disabled ni se envia
    'msa_allocation_amount', // disabled ni se envia
    'future_medical_costs',
    'referral_date',
    'date_of_report',
    'signature',
    'introduction',
    'medical_records_reviewed',
    'description_of_injury',
    'medical_summary',
    'missing_medical_records',
    'pre_existing_conditions',
    'surgeries_procedures',
    'current_treatment_plan',
    'medical_status',
    'projected_treatment_plan',
    'social_security_disability_status',
    'medicare_status',
    'medicare_lien_status',
    'medicare_allocation_administration',
    'additional_comments',
    'treating_providers', // reportcontact (no se mapean los que van a este)
    'life_source', // se guerda en life_expectancy_source life_table
    'life_table', // se guerda en life_expectancy_source life_source
    'life_expectancy', // disabled
    'compensation', //! no se ve en el formulario qa revision
    'rated_ages_text',
    'prognosis',
    'current_injury_related_conditions', // diabled
    'proposed_settlement_date',
    'age_at_proposed_settlement_date', // disabled
    'ESRD',
    'applied_SSDB',
    'checkboxes_other',
    'denied_SSDB',
    'medicare_partAB',
    'process_SSDB',
    'years_months_days',
    'msa_administrator', //! no se esta guardando
    'releases_attached',
    'proposed_settlement_date',
    'settlement_amount',
    'settlement_type',
    'annuity_payment_over_remaining_expectancy_at_starting_date', //disabled
    'anticipated_medicare_costs', //disabled
    'cost_of_first_surgery_and_first_procedure_replacement', //disabled
    'proposed_amount_for_future_from_medicare', //disabled
    'proposed_amount_for_future_not_covered_from_medicare', //disabled
    'seed_money_or_initial_deposit', //disabled
    'total_allocation_minus_first_surgery_procedure_replacement', //disabled
    'annuity_amount', //disable y envia nan
    'length_of_annuity', // disable ni se envia
    'annuity_start_date',
    'msa_calculation_method',
    'future_prescription_drug_treatment_calculation_method',
  ],
  [REPORT_MSA]: [
    'plaintiff_attorney', // reportcontact
    'defense_attorney', // reportcontact
    'insurance_carrier',
    'juridiction_state_id', //en lista de ignorado igual no funciona si lo quitamos de la lista de ignorados
    'Employer_demographics', //select custom que no tiene nada
    'injury_date', //se guarda pegado con otros campos en report_claim_id
    'insurance_company', //se guarda pegado con otros campos en report_claim_id
    'body_parts',
    'additional_comments',
    'related_diagnosis',
    'msa_allocation_amount', // disabled
    'referral_date',
    'date_of_report',
    'signature',
    'introduction',
    'medical_records_reviewed',
    'description_of_injury',
    'medical_summary',
    'missing_medical_records',
    'current_injury_related_conditions', // diabled
    'pre_existing_conditions',
    'surgeries_procedures',
    'treating_providers', // reportcontact (no se mapean los que van a este)
    'current_treatment_plan',
    'medical_status',
    'projected_treatment_plan',
    'social_security_disability_status',
    'medicare_status',
    'medicare_lien_status',
    'medicare_allocation_administration',
    'life_source', //disabled
    'life_table', //disabled
    'life_expectancy', //disabled
    'compensation', //! no se ve en el formulario qa revision
    'rated_ages_text',
    'proposed_settlement_date',
    'msa_administrator', //! no se esta guardando
    'releases_attached',
    'settlement_amount',
    'settlement_type',
    'annuity_payment_over_remaining_expectancy_at_starting_date', //disabled
    'anticipated_medicare_costs', //disabled
    'cost_of_first_surgery_and_first_procedure_replacement', //disabled
    'proposed_amount_for_future_from_medicare', //disabled
    'proposed_amount_for_future_not_covered_from_medicare', //disabled
    'seed_money_or_initial_deposit', //disabled
    'total_allocation_minus_first_surgery_procedure_replacement', //disabled
    'annuity_start_date',
    'annuity_amount', // disabled
    'length_of_annuity', // disable ni se envia
    'msa_calculation_method',
    'future_prescription_drug_treatment_calculation_method',
    'ESRD',
    'applied_SSDB',
    'checkboxes_other',
    'denied_SSDB',
    'medicare_partAB',
    'process_SSDB',
    'years_months_days',
  ],
  [REPORT_MCP]: [
    'plaintiff_attorney',
    'yy',
    'insurance_carrier',
    'juridiction_state_id', //en lista de ignorado igual no funciona si lo quitamos de la lista de ignorados
    'injury_date',
    'insurance_company',
    'related_diagnosis',
    'claim_numbers',
    'future_medical_costs', // campo disabled
    'referral_date',
    'date_of_report',
    'signature',
    'introduction',
    'medical_records_reviewed',
    'description_of_injury',
    'medical_summary',
    'missing_medical_records',
    'pre_existing_conditions',
    'surgeries_procedures',
    'treating_providers',
    'medical_status',
    'projected_treatment_plan',
    'prognosis',
    'additional_comments',
    'life_source',
    'life_table',
    'life_expectancy',
  ],
  [REPORT_LEGAL_DEMAND_TEMPLATE]: [
    'date_of_injury',
    'report_claim_id',
    'medical_summary',
    'medical_status',
    'projected_treatment_plan',
    'referral_date',
    'date_of_report',
    'signature',
  ],
  [REPORT_SURGICAL]: [
    'date_of_injury',
    'medical_summary',
    'medical_status',
    'projected_treatment_plan',
    'additional_comments',
  ],
  [REPORT_EARLY_INTERVENTION]: ['date_of_injury', 'medical_summary', 'medical_recommendations'],
  [REPORT_MSA_REDUCTION]: [
    'SSN',
    'date_of_injury',
    'disbursements',
    'insurance_company',
    // 'is_signature_photo',
    'dob', //
    'list_record',
    'optionSignature',
    'policy_limits',
    'present_value_case',
    'referral_date',
    'settlement_amount',
    'signature',
    'submitter_attorney_email',
    'submitter_attorney_firm_name',
    'submitter_attorney_name',
    'submitter_attorney_phone',
    'total_attorney_fees',
  ],
  [REPORT_LIFE_CARE_PLAN]: ['description_of_injury', 'introduction', 'medical_records_reviewed'],
  [REPORT_DEMAND_LETTER]: [
    'referral_date',
    'insurance_driver',
    'description_of_accident',
    'medical_treatment',
    'loss_of_enjoyment',
    'legal_analysis',
    'per_diem_pain',
    'wage_loss',
    'conclusions',
  ],
  [REPORT_DEMAND_LETTER_PROJECTIONS]: [
    'referral_date',
    'insurance_driver',
    'description_of_accident',
    'medical_treatment',
    'loss_of_enjoyment',
    'legal_analysis',
    'per_diem_pain',
    'wage_loss',
    'conclusions',
  ],
};

export const REPORT_FIELDS_TRASLATE = {
  [REPORT_MCP_MSA]: {
    insurance_carrier: 'Insurance carrier',
    juridiction_state_id: 'State of Jurisdiction', // es un dropdwon
    body_parts: 'Body Parts Accepted by Claims',
    additional_comments: 'Additional Comments',
    referral_date: 'Referral Date',
    date_of_report: 'Date of Report',
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
    description_of_injury: 'Description of Injury',
    medical_summary: 'Medical Summary',
    missing_medical_records: 'Missing Medical Records',
    pre_existing_conditions: 'Pre-existing Conditions',
    surgeries_procedures: 'Surgeries/Procedures related to injury',
    current_treatment_plan: 'Current Treatment Plan',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Treatment Plan',
    social_security_disability_status: 'Social Security Disability Status',
    medicare_status: 'Medicare Status',
    medicare_lien_status: 'Medicare Lien Information',
    medicare_allocation_administration: 'Medicare Allocation Administration',
    compensation: 'Compensation',
    rated_ages_text: 'Rated Age',
    prognosis: 'Prognosis',
  },
  [REPORT_MSA]: {
    insurance_carrier: 'Insurance carrier',
    juridiction_state_id: 'State of Jurisdiction', // es un dropdwon
    body_parts: 'Body Parts Accepted by Claims',
    additional_comments: 'Additional Comments',
    referral_date: 'Referral Date',
    date_of_report: 'Date of Report',
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
    description_of_injury: 'Description of Injury',
    medical_summary: 'Medical Summary',
    missing_medical_records: 'Missing Medical Records',
    pre_existing_conditions: 'Pre-existing Conditions',
    surgeries_procedures: 'Surgeries/Procedures related to injury',
    current_treatment_plan: 'Current Treatment Plan',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Treatment Plan',
    social_security_disability_status: 'Social Security Disability Status',
    medicare_status: 'Medicare Status',
    medicare_lien_status: 'Medicare Lien Information',
    medicare_allocation_administration: 'Medicare Allocation Administration',
    compensation: 'Compensation',
    rated_ages_text: 'Rated Age',
  },
  [REPORT_MCP]: {
    insurance_carrier: 'Insurance carrier',
    juridiction_state_id: 'State of Jurisdiction', // es un dropdwon
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
    description_of_injury: 'Description of Injury',
    medical_summary: 'Medical Summary',
    missing_medical_records: 'Missing Medical Records',
    pre_existing_conditions: 'Pre-existing Conditions',
    surgeries_procedures: 'Surgeries/Procedures related to injury',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Future Medical Care',
    prognosis: 'Prognosis',
    additional_comments: 'Comments',
  },
  [REPORT_LEGAL_DEMAND_TEMPLATE]: {
    date_of_injury: 'Date of Accident/Injury',
    medical_summary: 'Medical Summary',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Future Medical Care',
    referral_date: 'Referral Date',
    date_of_report: 'Date of Report',
  },
  [REPORT_SURGICAL]: {
    date_of_injury: 'Date of Accident/Injury',
    medical_summary: 'Medical Care Summary',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Future Medical Care',
    additional_comments: 'Causation',
  },
  [REPORT_EARLY_INTERVENTION]: {
    date_of_injury: 'Date of Accident/Injury',
    medical_summary: 'Medical Summary',
    medical_recommendations: 'Medical Professional Recommendations',
  },
  [REPORT_LIFE_CARE_PLAN]: {
    description_of_injury: 'Description of Injury',
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
  },
  [REPORT_DEMAND_LETTER]: {
    referral_date: 'Referral Date',
    insurance_driver: 'Insurance Driver',
    description_of_accident: 'description_of_accident',
    medical_treatment: 'medical_treatment',
    loss_of_enjoyment: 'loss_of_enjoyment',
    legal_analysis: 'legal_analysis',
    per_diem_pain: 'per_diem_pain',
    wage_loss: 'wage_loss',
    conclusions: 'conclusions',
  },
  [REPORT_DEMAND_LETTER_PROJECTIONS]: {
    referral_date: 'Referral Date',
    insurance_driver: 'Insurance Driver',
    description_of_accident: 'description_of_accident',
    medical_treatment: 'medical_treatment',
    loss_of_enjoyment: 'loss_of_enjoyment',
    legal_analysis: 'legal_analysis',
    per_diem_pain: 'per_diem_pain',
    wage_loss: 'wage_loss',
    conclusions: 'conclusions',
  },
};

export const INPUTS_RICH = [
  'projected_treatment_plan',
  'medical_recommendations',
  'introduction',
  'medical_records_reviewed',
  'description_of_injury',
  'medical_summary',
  'surgeries_procedures',
  'medical_status',
  'prognosis',
  'current_treatment_plan',
  'social_security_disability_status',
  'medicare_status',
  'medicare_lien_status',
  'additional_comments',
  'summary',
  'description_of_accident',
  'medical_treatment',
  'loss_of_enjoyment',
  'legal_analysis',
  'per_diem_pain',
  'wage_loss',
  'conclusions',
];

export const INPUTS_TEXT = [
  'missing_medical_records',
  'pre_existing_conditions',
  'insurance_carrier',
  'body_parts',
  'medicare_allocation_administration',
  'compensation',
  'rated_ages_text',
];

export const INPUTS_DATE = ['date_of_injury', 'referral_date', 'date_of_report', 'insurance_driver'];

export const REPORT_FIELDS_TYPE_INPUT = {
  [REPORT_MCP_MSA]: {
    insurance_carrier: 'Insurance carrier',
    juridiction_state_id: 'State of Jurisdiction', // es un dropdwon
    body_parts: 'Body Parts Accepted by Claims',
    additional_comments: 'Additional Comments',
    referral_date: 'Referral Date',
    date_of_report: 'Date of Report',
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
    description_of_injury: 'Description of Injury',
    medical_summary: 'Medical Summary',
    missing_medical_records: 'Missing Medical Records',
    pre_existing_conditions: 'Pre-existing Conditions',
    surgeries_procedures: 'Surgeries/Procedures related to injury',
    current_treatment_plan: 'Current Treatment Plan',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Treatment Plan',
    social_security_disability_status: 'Social Security Disability Status',
    medicare_status: 'Medicare Status',
    medicare_lien_status: 'Medicare Lien Information',
    medicare_allocation_administration: 'Medicare Allocation Administration',
    compensation: 'Compensation',
    rated_ages_text: 'Rated Age',
    prognosis: 'Prognosis',
  },
  [REPORT_MSA]: {
    insurance_carrier: 'Insurance carrier',
    juridiction_state_id: 'State of Jurisdiction', // es un dropdwon
    body_parts: 'Body Parts Accepted by Claims',
    additional_comments: 'Additional Comments',
    referral_date: 'Referral Date',
    date_of_report: 'Date of Report',
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
    description_of_injury: 'Description of Injury',
    medical_summary: 'Medical Summary',
    missing_medical_records: 'Missing Medical Records',
    pre_existing_conditions: 'Pre-existing Conditions',
    surgeries_procedures: 'Surgeries/Procedures related to injury',
    current_treatment_plan: 'Current Treatment Plan',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Treatment Plan',
    social_security_disability_status: 'Social Security Disability Status',
    medicare_status: 'Medicare Status',
    medicare_lien_status: 'Medicare Lien Information',
    medicare_allocation_administration: 'Medicare Allocation Administration',
    compensation: 'Compensation',
    rated_ages_text: 'Rated Age',
  },
  [REPORT_MCP]: {
    insurance_carrier: 'Insurance carrier',
    juridiction_state_id: 'State of Jurisdiction', // es un dropdwon
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
    description_of_injury: 'Description of Injury',
    medical_summary: 'Medical Summary',
    missing_medical_records: 'Missing Medical Records',
    pre_existing_conditions: 'Pre-existing Conditions',
    surgeries_procedures: 'Surgeries/Procedures related to injury',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Future Medical Care',
    prognosis: 'Prognosis',
    additional_comments: 'Comments',
  },
  [REPORT_LEGAL_DEMAND_TEMPLATE]: {
    date_of_injury: 'Date of Accident/Injury',
    medical_summary: 'Medical Summary',
    medical_status: 'Current Medical Status',
    projected_treatment_plan: 'Projected Future Medical Care',
    referral_date: 'Referral Date',
    date_of_report: 'Date of Report',
  },
  [REPORT_EARLY_INTERVENTION]: {
    date_of_injury: 'Date of Accident/Injury',
    medical_summary: 'Medical Summary',
    medical_recommendations: 'Medical Professional Recommendations',
  },
  [REPORT_LIFE_CARE_PLAN]: {
    description_of_injury: 'Description of Injury',
    introduction: 'Introduction',
    medical_records_reviewed: 'Records Reviewed',
  },
  [REPORT_DEMAND_LETTER]: {
    referral_date: 'Referral Date',
    insurance_driver: 'Insurance Driver',
    description_of_accident: 'description_of_accident',
    medical_treatment: 'medical_treatment',
    loss_of_enjoyment: 'loss_of_enjoyment',
    legal_analysis: 'legal_analysis',
    per_diem_pain: 'per_diem_pain',
    wage_loss: 'wage_loss',
    conclusions: 'conclusions',
  },
  [REPORT_DEMAND_LETTER_PROJECTIONS]: {
    referral_date: 'Referral Date',
    insurance_driver: 'Insurance Driver',
    description_of_accident: 'description_of_accident',
    medical_treatment: 'medical_treatment',
    loss_of_enjoyment: 'loss_of_enjoyment',
    legal_analysis: 'legal_analysis',
    per_diem_pain: 'per_diem_pain',
    wage_loss: 'wage_loss',
    conclusions: 'conclusions',
  },
};
