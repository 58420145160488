import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Select, Typography, Divider } from 'antd';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { SettingsCard } from './SettingsCard';

// Utils
import * as API from 'utils/api';
import { fetchQuickbooksCustomerInfo } from 'utils/api-transactions';
import { notifyApiError, notifySuccess } from '../../../utils/notification';

// Hooks
import { useBillDue, useCreditCard, usePricingGlobal, useSubscription } from 'hooks';

// Style
import './CompanyProfile.scss';
import { ModalLoading } from 'components/Modal/ModalLoading';
import { CardSubscription } from '../Components/CardSubscription';
import {
  DISPLAY_NAME_PLAN_REVIEWERS,
  LICENSES_REVIEWER_BY_ROLE_REVIEWER,
} from 'containers/Tenant/tenantCreate/components/StepPackageTier/components/PlanCard/planList';
import { INCLUDE_ALL_REPORTS_PLAN } from '../Components/PlanSubscription/const';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { ModalMessage } from 'components/Modal/ModalMessage';
import { IS_ACTIVE_REVIEWER } from 'redux/auth/actionTypes';

const BillingInfoSubscription = ({ isActiveReviewer }) => {
  const dispatch = useDispatch();
  const { userInfo, roles } = useSelector(state => state.auth);
  const roleId = roles.user_roles.roles[0].id;
  

  const { Option } = Select;
  const { Text } = Typography;
  const [stateCountries, setStateCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [thereIsCustomerProfile, setThereIsCustomerProfile] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [selectPlan, setSelectPlan] = useState(null);
  const [confirmSubPlan, setConfirmSubPlan] = useState(false);
  const [modalConfirmChange, setModalConfirmChange] = useState(false);
  const [completedForm, setCompletedForm] = useState(false);
  const [modalCanceled, setModalCanceled] = useState(false);
  const [loadingInternal, setLoadingInternal] = useState(false);
  // Hooks
  const { listPricingGlobal, getPricingGlobal } = usePricingGlobal();
  const { creditCardList, loadingCreditCard, getCreditCardListTenant, getCustomerProfileUser, createCustomerProfile } =
    useCreditCard();
  const { cancelSubscriptionHook } = useSubscription();
  const [modalConfirmCaceled, setModalConfirmCanceled] = useState(false);
  const { billDue, getBillDue } = useBillDue();

  const PACKAGE_PRICE = !roles?.user_roles?.is_global_pricing
    ? roles.user_roles?.specific_pricing || 0
    : listPricingGlobal.find(item => item.id === LICENSES_REVIEWER_BY_ROLE_REVIEWER[roleId])?.price || 0;

  useEffect(() => {
    (async () => {
      try {
        await Promise.allSettled([getPricingGlobal(), getBillDue()]);

        const state = await API.getStates();
        setStateCountries(state.data.states);

        const { tenant } = await API.getTenantById({ id: userInfo?.attributes['custom:tenant_id'] });
        setTenant(tenant);

        const quickBookId = tenant?.quickbooks_customer_id || '';
        const customer_profile_id = tenant?.customer_profile_id || '';
        let dataBilling = {};
        if (quickBookId) {
          dataBilling = await fetchQuickbooksCustomerInfo(quickBookId);
        } else {
          // if the user does not have a quickbooks id, it should create one
          const params = {
            given_name: tenant?.tenant_name || '',
            tenant_name: tenant?.tenant_name || '',
            email: tenant?.email || '',
            phone_number_1: tenant?.phone_number_1 || '',
            state: tenant?.state || '',
            city: tenant?.city || '',
            zipCode: tenant?.zip || '',
            address_line: tenant?.address_line || '',
          };
          const data = await API.createQuickbooksCustomer({
            params,
            tenant_id: tenant?.id,
          });
          dataBilling = await fetchQuickbooksCustomerInfo(data.Id);
        }

        const billTo = {
          displayName: dataBilling?.DisplayName || '',
          companyName: dataBilling?.CompanyName || '',
          address: dataBilling?.BillAddr?.Line1 || '',
          city: dataBilling?.BillAddr?.City || '',
          state: dataBilling?.BillAddr?.CountrySubDivisionCode || '',
          zipCode: dataBilling?.BillAddr?.PostalCode || '',
          email: dataBilling?.PrimaryEmailAddr?.Address || '',
          phone: dataBilling?.PrimaryPhone?.FreeFormNumber || '',
          quickbooks_customer_id: quickBookId,
        };
        let customerProfile = false;

        if (customer_profile_id) {
          customerProfile = await getCustomerProfileUser(customer_profile_id);
        }

        if (customerProfile !== false) {
          // if the user has a customer profile we look for their cards
          await getCreditCardListTenant(customer_profile_id);
          setThereIsCustomerProfile(true);
        } else if (customerProfile === false && quickBookId) {
          // if the billing is complete and the user does not have a customer profile, we create the customer profile
          const result = await createCustomerProfile({
            firstName: billTo.companyName,
            lastName: billTo.companyName,
            address: billTo.address,
            city: billTo.city,
            state: billTo.state,
            zip: billTo.zipCode,
            number: billTo.phone,
            email: billTo.email,
            client_id: null,
            tenant_id: tenant.id,
          });

          setThereIsCustomerProfile(!!result);
        }

        setCompletedForm(
          billTo.companyName &&
            billTo.companyName &&
            billTo.address &&
            billTo.city &&
            billTo.state &&
            billTo.zipCode &&
            billTo.phone &&
            billTo.email
        );
        setData(billTo);
      } finally {
        setIsLoading(false);
      }
    })();
    return () => {};
  }, []);

  const handleChange = ({ field, value }) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const validForm = () => {
    return Object.keys(data).some(item => !data[item]);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const params = {
        id: userInfo?.attributes['custom:tenant_id'],
        quickbooks_customer_id: data.quickbooks_customer_id,
        set_quickbooks: true,
        fields: {
          displayName: data.displayName,
          companyName: data.companyName,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
          email: data.email,
          phone: data.phone,
        },
      };
      await API.updateTenant(params);

      if (!thereIsCustomerProfile) {
        await createCustomerProfile({
          firstName: data.companyName,
          lastName: data.companyName,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
          number: data.phone,
          email: data.email,
          client_id: null,
          tenant_id: tenant.id,
        });
      }

      setCompletedForm(true);
      await updateTenant()
      notifySuccess('Updated Successfully');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const StyledInput = {
    fontSize: '14px',
  };

  const updateList = async () => {
    const { tenant } = await API.getTenantById({ id: userInfo?.attributes['custom:tenant_id'] });
    const customer_profile_id = tenant?.customer_profile_id || '';
    await getCreditCardListTenant(customer_profile_id);
  };

  const updateTenant = async () => {
    const { tenant } = await API.getTenantById({ id: userInfo?.attributes['custom:tenant_id'] });
    setTenant(tenant);
  };

  const handleOpenModalConfirmUpgrade = () => {
    const subscriptionPlan = listPricingGlobal.find(item => item.id === LICENSES_REVIEWER_BY_ROLE_REVIEWER[roleId]);
    setSelectPlan(subscriptionPlan);
    setConfirmSubPlan(true);
  };

  const handleChangeSubscription = async () => {
    try {
      setLoadingInternal(true);
      await API.paySubcriptionWithCreditCard({
        customer_profile_id: tenant?.customer_profile_id,
        customer_payment_profile_id: creditCardList[0].customerPaymentProfileId,
        package_id: selectPlan.id,
        customer: tenant?.customer_id,
      });

      await updateTenant();

      dispatch({ type: IS_ACTIVE_REVIEWER, activeReviewer: true });
      localStorage.setItem('isActiveReviewer', true);

      setConfirmSubPlan(false);
      setModalConfirmChange(true);
    } catch (e) {
      notifyApiError(e.message);
    } finally {
      setLoadingInternal(false);
    }
  };

  const handleOpenModalCanceledPlan = () => setModalCanceled(true);

  const handleCancelPlan = async () => {
    await cancelSubscriptionHook({ tenant_id: tenant?.id });
    await updateTenant();
    setModalCanceled(false);
    setModalConfirmCanceled(true);
    dispatch({ type: IS_ACTIVE_REVIEWER, activeReviewer: false });
    localStorage.setItem('isActiveReviewer', false);
  };

  const LOADING = isLoading || loadingCreditCard;

  if (LOADING) return <ModalLoading />;

  return (
    <Row className="company_container" type="flex" justify="space-between">
      {loadingInternal && <ModalLoading />}

      {!completedForm && (
        <Row className="createReport_alert company_container_alert">
          <Text>Please complete the billing information section</Text>
        </Row>
      )}

      <Row type="flex" justify="start" align="middle">
        <h1 className="company__title">Billing Information</h1>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Display Name</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="displayName"
            value={data?.displayName || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Company Name</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="companyName"
            value={data?.companyName || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={24}>
          <Row className="company__inputs">
            <Text>Address</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="address"
            value={data?.address || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={11}>
          <Row className="company__inputs">
            <Text>City</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="city"
            value={data?.city || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
        <Col md={11}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={11}>
              <Row className="company__inputs">
                <Text>State</Text>
              </Row>
              <Select
                showSearch
                placeholder="Required"
                className="clients-select"
                value={data?.state}
                onChange={name => handleChange({ field: 'state', value: name })}
                style={{ width: '100%' }}>
                {stateCountries.map(state => (
                  <Option key={state.id} value={state.name}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={11}>
              <Row className="company__inputs">
                <Text>Zip code</Text>
              </Row>
              <InputMask
                mask="99999"
                placeholder="Required"
                className="input-ssn"
                value={data?.zipCode || ''}
                onChange={e => handleChange({ field: 'zipCode', value: e.target.value })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Email</Text>
          </Row>
          <Input
            size="large"
            placeholder="Required"
            name="email"
            value={data?.email || ''}
            style={StyledInput}
            onChange={e => handleChange({ field: e.target.name, value: e.target.value })}
          />
        </Col>
        <Col md={11}>
          <Row className="company__inputs">
            <Text>Phone</Text>
          </Row>
          <InputMask
            className="input-ssn"
            mask="999-999-9999"
            size="large"
            placeholder="Required"
            value={data?.phone || ''}
            name="phone"
            style={StyledInput}
            onChange={e => handleChange({ field: 'phone', value: e.target.value })}
          />
        </Col>
      </Row>

      <Row type="flex" justify="end">
        <Col md={6}>
          <button disabled={validForm()} className="company_form_btn mb-4 mt-3" onClick={onSubmit}>
            Save
          </button>
        </Col>
      </Row>

      <Divider />

      <SettingsCard listCard={creditCardList} updateList={async () => await updateList()} disabled={!completedForm} />

      <Divider />

      <Row style={{ margin: '2em 0px' }}>
        <CardSubscription
          actualPlan={isActiveReviewer && tenant.subscription_status}
          upgradePlan
          disabled={creditCardList.length === 0}
          name={`${DISPLAY_NAME_PLAN_REVIEWERS[roleId]}`}
          includes={INCLUDE_ALL_REPORTS_PLAN}
          pricePackage={PACKAGE_PRICE}
          onOpenModalCanceledPlan={handleOpenModalCanceledPlan}
          onOpenModalConfirmUpgrade={handleOpenModalConfirmUpgrade}
          billDue={billDue}
        />
      </Row>
      {confirmSubPlan && (
        <ModalConfirm
          onClose={() => setConfirmSubPlan(false)}
          title={`Your Subscription will cost ${PACKAGE_PRICE}/month`}
          text="Once you continue, you'll be able to access the entire application."
          onConfirm={handleChangeSubscription}
          btnCancel="Cancel"
        />
      )}

      {modalConfirmChange && (
        <ModalMessage
          onClose={() => setModalConfirmChange(false)}
          title={`Congratulations, your subscription has been successfully`}
          textButton="Close"
          noneIcon
        />
      )}

      {modalCanceled && (
        <ModalConfirm
          onClose={() => setModalCanceled(false)}
          title={`Are you certain you want to cancel your subscription`}
          onConfirm={handleCancelPlan}
          btnCancel="Cancel"
        />
      )}

      {modalConfirmCaceled && (
        <ModalMessage
          onClose={() => setModalConfirmCanceled(false)}
          title="Your cancellation request has been submitted"
          text="Please reach out to the MSA Support Manager to proceed with your cancellation"
          textButton="Close"
          noneIcon
        />
      )}
    </Row>
  );
};

export default BillingInfoSubscription;
