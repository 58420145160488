import React, { useState, useEffect } from 'react';
import { Icon, Select } from 'antd';
import InputMask from 'react-input-mask';
import * as API from 'utils/api';

const { Option } = Select;

const Step4 = ({ data, onSubmit }) => {
  const [billingInfo, setBillingInfo] = useState(data);
  const [juridictionList, setJuridictionList] = useState([]);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    await API.getStates().then(res => {
      setJuridictionList(res.data.states);
    });
  };

  const handleChange = e => {
    setBillingInfo({
      ...billingInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    onSubmit &&
      onSubmit({
        sectionStep: 'client',
        payload: {
          client_name: billingInfo.companyName,
          fields: billingInfo,
        },
        nextStep: 5,
      });
  };

  const validForm = () => {
    return (
      billingInfo?.displayName &&
      billingInfo?.companyName &&
      billingInfo?.email &&
      billingInfo?.phone &&
      billingInfo?.address &&
      billingInfo?.city &&
      billingInfo?.state &&
      billingInfo?.zip
    );
  };

  return (
    <div className="stepContainer">
      <div className="stepTitleContainer">
        <p className="stepText">Step 4</p>
        <h1 className="stepTitle">Billing Information</h1>
      </div>

      <div className="stepFormContainer">
        <div className="stepFormGroup">
          <label htmlFor="displayName">Display Name</label>
          <input
            onChange={handleChange}
            type="text"
            name="displayName"
            id="displayName"
            placeholder="Required"
            required
            value={billingInfo?.displayName || ''}
          />
        </div>
        <div className="stepFormGroup">
          <label htmlFor="companyName">Company Name</label>
          <input
            onChange={handleChange}
            type="text"
            name="companyName"
            id="companyName"
            placeholder="Required"
            required
            value={billingInfo?.companyName || ''}
          />
        </div>
        <div className="stepFormGroup">
          <label htmlFor="email">Email</label>
          <input
            onChange={handleChange}
            type="email"
            name="email"
            id="email"
            placeholder="Required"
            required
            value={billingInfo?.email || ''}
          />
        </div>
        <div className="stepFormGroup">
          <label htmlFor="phone">Phone Number</label>
          <InputMask
            mask="999-999-9999"
            placeholder="Required"
            value={billingInfo?.phone || ''}
            name="phone"
            id="phone"
            onChange={handleChange}
          />
        </div>
        <div className="stepFormGroup stepCol2">
          <label htmlFor="address">Address</label>
          <input
            onChange={handleChange}
            type="text"
            name="address"
            id="address"
            placeholder="Required"
            required
            value={billingInfo?.address || ''}
          />
        </div>
        <div className="stepFormGroup">
          <label htmlFor="city">City</label>
          <input
            onChange={handleChange}
            type="text"
            value={billingInfo?.city || ''}
            name="city"
            id="city"
            placeholder="Required"
            required
          />
        </div>
        <div className="stepFormContainer">
          <div className="stepFormGroup">
            <label htmlFor="state">State</label>
            <Select
              showSearch
              placeholder="Please select"
              optionFilterProp="children"
              className="dropdown inputSelect state-select"
              value={billingInfo?.state || ''}
              onChange={value => handleChange({ target: { name: 'state', value } })}
              name="state"
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option disabled value="">
                Required
              </Option>
              {juridictionList.map((el, i) => {
                return (
                  <Option key={i} value={el.name}>
                    {el.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="stepFormGroup">
            <label htmlFor="zip">Zip Code</label>
            <InputMask
              mask="99999"
              placeholder="Required"
              name="zip"
              id="zip"
              value={billingInfo?.zip || ''}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <button disabled={!validForm()} className="stepCompleteButton" onClick={handleSubmit}>
        Complete Registration
      </button>
    </div>
  );
};

export default Step4;
