import React from 'react';
import './CustomBoxCheckBox.scss';

const CustomBoxCheckBox = ({ optionSignature, onChange, label, htmlId, value, disabled }) => (
  <label htmlFor={htmlId} className="customCheckBox2">
    <input
      disabled={disabled}
      type="checkbox"
      id={htmlId}
      checked={optionSignature === value}
      onChange={() => onChange(value)}
      className={`squareCheckBox ${optionSignature === value ? ' squareCheckBox_active' : ''}`}
    />
    <span className="customCheckBox2_text">
    {label && label}
    </span>
  </label>
);

export default CustomBoxCheckBox;
