import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as API from 'utils/api';

// Components
import { ChangeAddressAndPhoneReport } from 'components/ChangeAddressAndPhoneReport';
import { ChangeReportLogoAndColor } from 'components/ChangeReportLogoAndColor';
import { Col, Modal, Row, Typography } from 'antd';
import AddAssignForm from 'containers/Calendar/src/src/containers/base/AddAssignForm';
import { ModalReturnToSender } from 'containers/Reports/components/ModalReturnToSender';
import DropDown from 'components/Dropdown';
import { AllBtnReport } from '../Components/AllBtnReport';

// Hooks
import { useReport } from 'hooks';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Permission
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';

// Utils
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { existsAssigment } from 'utils/assigment';
import {
  ASSIGNMENT_TYPE_LEGAL_NURSE,
  ASSIGNMENT_TYPE_MEDICAL_REVIEWER,
  ASSIGNMENT_TYPE_QA_INTERNAL,
} from 'constants/assignment-types';
import { STATUS_GENERATED, STATUS_IN_SUPERVISOR_REVIEW } from 'constants/report_status';
import { returnToSenderReport } from 'containers/Reports/utils/returnToSender';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import {
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_LIFE_CARE_PLAN_TYPE_ID,
  REPORT_MCP_MSA_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  REPORT_MSA_LETTER_TYPE_ID,
  REPORT_MSA_REDUCTION_TYPE_ID,
  REPORT_MSA_TYPE_ID,
  REPORT_SURGICAL_TYPE_ID,
  REPORT_TYPE_NAME_BY_ID,
  REPORT_DEMAND_LETTER_TYPE_ID,
  REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID,
} from 'constants/reports';
import moment from 'moment';
import { CLAIMANT_INFO_NAV, TEMPLATE_NAV } from 'layout/navigate_const';

const { Title } = Typography;

const DesingReportPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { roles } = useSelector(state => state.auth);
  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });

  // State
  const [showNewAssignForm, setShowNewAssignForm] = useState(false);
  const [editedparams, setEditedparams] = useState({});
  const [assignWriter, setAssignWriter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalReturnToSender, setModalReturnToSender] = useState(false);
  const [assignmentReturn, setAssignmentReturn] = useState('');
  const [selectPdf, setSelectPdf] = useState('');

  const reportTypes = {
    [REPORT_EARLY_INTERVENTION_TYPE_ID]: API.setUpEarlyInterventionReport,
    [REPORT_MCP_TYPE_ID]: API.setUpMCPReport,
    [REPORT_SURGICAL_TYPE_ID]: API.setUpSurgicalReport,
    [REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID]: API.setUpLegalDemandTemplateReport,
    [REPORT_MSA_REDUCTION_TYPE_ID]: API.setUpReductionReport,
    [REPORT_MSA_TYPE_ID]: API.setUpMsaReport,
    [REPORT_LIFE_CARE_PLAN_TYPE_ID]: API.setUpLifeCarePlanReport,
    [REPORT_MSA_LETTER_TYPE_ID]: API.setUpLetterReport,
    [REPORT_MCP_MSA_TYPE_ID]: API.setUpMcpMsaReport,
    [REPORT_DEMAND_LETTER_TYPE_ID]: API.setUpLegalDemandTemplateReport,
    [REPORT_DEMAND_LETTER_PROJECTIONS_TYPE_ID]: API.setUpLegalDemandTemplateReport,
  };

  // Hooks
  const { loadingReport, reportInfo, getReportInfo } = useReport();

  useEffect(() => {
    (async () => {
      await getReportInfo({ idReport: id });
    })();
  }, []);

  const handleGenerateReport = async () => {
    try {
      setLoading(true);

      const reportTypeHandler = reportTypes[reportInfo.report_type_id];
      await reportTypeHandler(id);

      if (isAllowed(roles, ROLES_DIVISIONS.MANAGER)) {
        await API.updateReportNurse({ id, report_status_id: STATUS_GENERATED.id });
      }

      const reportFileObj = await API.GenerateReport(id);
      Modal.success({
        content: 'Report is generated',
        onOk: () => {
          window.open(reportFileObj.url);
        },
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleReturnToSender = async () => {
    try {
      setLoading(true);
      const { claimant } = reportInfo;
      const claimant_id = claimant.id;

      const res = await returnToSenderReport({ report_id: id, claimant_id });

      if (res?.assignment?.id) {
        setModalReturnToSender(true);
        setAssignmentReturn(`${res.assignment.user.given_name} ${res.assignment?.user?.family_name || ''}`);
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewReport = async () => {
    try {
      setLoading(true);
      const res = await API.getReviewReportCompare({ idReport: id });

      if (res?.report_url) setSelectPdf(res.report_url);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const openModal = async () => {
    try {
      setLoading(true);
      const { finalAssigned, assignWriter } = await existsAssigment({
        reportInfo,
        roles,
      });

      setEditedparams(finalAssigned);
      setAssignWriter(assignWriter);
      setShowNewAssignForm(true);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async assignment => {
    try {
      setLoading(true);
      const hasAssigment = Boolean(assignment.id);

      if (!hasAssigment) {
        const res = await API.createAssignementUser(assignment);

        if (res?.assignment?.assignment_type_id === ASSIGNMENT_TYPE_LEGAL_NURSE) {
          await API.updateReportNurse({
            id: res.assignment.report_id,
            report_status_id: STATUS_IN_SUPERVISOR_REVIEW.id,
          });
        }

        notifyInfo('Assignment created');
      } else {
        let newEdit = { ...assignment };
        delete newEdit.id;
        delete newEdit.status;

        newEdit.start_date = moment(newEdit.start_date).format('YYYY-MM-DD');
        newEdit.end_date = moment(newEdit.end_date).format('YYYY-MM-DD');

        const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
        const isUserQA = isAllowed(roles, ROLES_DIVISIONS.QA);

        const params = isUserQA && !isUserAdmin ? { ...newEdit, tenantOnly: true } : newEdit;

        await API.UpdateAssignement(assignment.id, params);
        notifyInfo('Assignment updated');
      }

      setLoading(false);
      setShowNewAssignForm(false);

      if (isAllowed(roles, ROLES_DIVISIONS.NURSE_ONLY)) {
        history.push(`${CLAIMANT_INFO_NAV.path}/${reportInfo.claimant.id}`);
      }
      return true;
    } catch (e) {
      notifyApiError(e);
      setLoading(false);
      return false;
    }
  };

  const handleUpdateReportTemplate = () => {
    notifyInfo('The template is updated');

    history.push(TEMPLATE_NAV.path);
  };

  const isLoading = loading || loadingReport;

  return (
    <>
      <Row className="msa-document-upload-container newUploadFile">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              {REPORT_TYPE_NAME_BY_ID[reportInfo.report_type_id]} {reportInfo.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown loading={isLoading} beforeNext={() => {}} />
          </Col>
        </Row>

        <Row className="main">
          <div className="content newUploadFile_content">
            {reportInfo && (
              <>
                <ChangeAddressAndPhoneReport
                  reportId={id}
                  phone={reportInfo?.phone || ''}
                  address={reportInfo?.address || ''}
                  disabledField={!REPORT_PERMISSIONS.editDesing}
                />
                <ChangeReportLogoAndColor
                  urlPublic={reportInfo?.logo || ''}
                  idReport={id}
                  color={reportInfo?.color || ''}
                  disabledField={!REPORT_PERMISSIONS.editDesing}
                />

                <AllBtnReport
                  handleGenerateReport={REPORT_PERMISSIONS.edit && !reportInfo.is_template && handleGenerateReport}
                  openModal={REPORT_PERMISSIONS.assign && !reportInfo.is_template && openModal}
                  roles={roles}
                  returnToSender={handleReturnToSender}
                  previewReport={handlePreviewReport}
                  isTemplateSave={reportInfo.is_template ? handleUpdateReportTemplate : undefined}
                />
              </>
            )}
          </div>
        </Row>
      </Row>
      {isLoading && <ModalLoading />}

      {modalReturnToSender && (
        <ModalReturnToSender onClose={() => history.push('/app/Reviews')} returnName={assignmentReturn || ''} />
      )}

      {showNewAssignForm && (
        <Modal
          width={700}
          maskClosable={false}
          bodyStyle={{ padding: 0, minWidth: 450 }}
          visible={showNewAssignForm}
          onCancel={() => setShowNewAssignForm(false)}
          keyboard={false}
          footer={null}>
          <AddAssignForm
            onSubmit={onSubmit}
            edit={editedparams}
            disabledField={{
              client: true,
              claimant: true,
              report: true,
            }}
            onlyAssign={
              assignWriter && [
                ASSIGNMENT_TYPE_QA_INTERNAL,
                ASSIGNMENT_TYPE_LEGAL_NURSE,
                ASSIGNMENT_TYPE_MEDICAL_REVIEWER,
              ]
            }
          />
        </Modal>
      )}

      {selectPdf && <ModalPdf path={selectPdf} close={() => setSelectPdf('')} />}
    </>
  );
};

export default DesingReportPage;
