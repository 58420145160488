import React from 'react';
import { Row } from 'antd';
import './stepProgress.scss';

const StepProgress = ({ totalStep, totalSelectStep }) => {
  return (
    <Row type="flex" justify="center" className="stepProgress">
      <div className="stepProgress_bar">
        {new Array(totalStep).fill('').map((step, index) => (
          <div key={index} className={`stepProgress_item ${totalSelectStep > index ? 'stepProgress_active' : ''}`} />
        ))}
      </div>

      <p className="stepPropgress_info">
        {totalSelectStep} out of {totalStep}
      </p>
    </Row>
  );
};

export default StepProgress;
