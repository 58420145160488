export const DEFENSE_ATTORNEY_TYPE_ID = 2;
export const INSURANCE_ATTORNEY_TYPE_ID = 5;

export const PLAINTIFF_ATTORNEY_TYPE = {
  field: 'plaintiff_attorney',
  name: 'Plaintiff Attorney',
  id: 1,
};

export const MD_REVIEW_NAME = 'MD Review';
export const MSA_REDUCTION__REVIEWNAME = 'MSA Reduction Review';
export const QA_REVIEW_NAME = 'QA Review';

export const LIST_TYPE_USER_BY_A_LA_CARTE = [
  {
    name: MD_REVIEW_NAME,
    description: 'Prcing Varies Based on Specialty',
    toltip:
      "We reviewed your organization's compliance with applicable medical regulations, guidlines, and strandards. our team assessed the extent to which your medical practices align with industry-specific requirements.",
  },
  {
    name: MSA_REDUCTION__REVIEWNAME,
    description: 'Pricing Varies Based on Specialty',
    toltip:
      "In conductiong the review, we examined various aspects of your organization's cost reduction strategies, including expense management processes, procurement practicces, operational efficiencies, and resource utilization.",
  },
  {
    name: QA_REVIEW_NAME,
    description: 'Pricing Varies Based on Specialty',
    toltip:
      "In conductiong the review, we examined key aspects of your organization's quality management systems, including policies, procedures, documentation, training protocols, and quality control measures.",
  },
];

export const LIMIT_REVIEWER_5 = {
  price: 50,
  limit: 5,
};

export const LIMIT_REVIEWER_10 = {
  price: 100,
  limit: 10,
};
export const LIMIT_REVIEWER_15 = {
  price: 150,
  limit: 15,
};

export const LIST_COUNT_TYPE_REVIEWER = [
  {
    label: '1 to 5',
    value: LIMIT_REVIEWER_5.limit,
    price: LIMIT_REVIEWER_5.price,
  },
  {
    label: '6 to 10',
    value: LIMIT_REVIEWER_10.limit,
    price: LIMIT_REVIEWER_10.price,
  },
  {
    label: '11 to 15',
    value: LIMIT_REVIEWER_15.limit,
    price: LIMIT_REVIEWER_15.price,
  },
];
