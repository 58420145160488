import React, { useState } from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { EditIcon, RemoveIcon } from 'components/Icons';
import InputBox from 'components/InputBox';
import InputCurrencyBox from 'components/InputCurrencyBox';
import { REPORT_PERMISSIONS_NAME, getPermissionsBySection } from 'permissions';
import { useSelector } from 'react-redux';
import { formatMoneyToNumber, formatNumberToMoney } from 'utils/utils';

const { Title, Text } = Typography;

const UmDemandTableCost = ({ dataList, onUpdateDataList, title }) => {
  const { roles } = useSelector(state => state.auth);

  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });

  const permissionEdit = REPORT_PERMISSIONS.editReduction && REPORT_PERMISSIONS.editTableAndPrice

  const [openModal, setOpenModal] = useState(false);
  const [isEditRecord, setIsEditRecord] = useState(false);
  const [modalDataRecord, setModalDataRecord] = useState({});

  const validModal = !modalDataRecord.name || !modalDataRecord.cost;

  const handleCloseModalRecord = () => {
    setOpenModal(false);
    setModalDataRecord({});
    setIsEditRecord(false);
  };

  const handleOpenEditRecord = ({ data: { name, cost }, index }) => {
    setOpenModal(true);
    setModalDataRecord({
      name,
      cost,
    });
    setIsEditRecord(index);
  };

  const handleRemoveRecord = ({ index }) => {
    const new_dataList = Array.from(dataList);
    new_dataList.splice(index, 1);

    onUpdateDataList({ list: new_dataList });
  };

  const sumListRecords = () => {
    const number = dataList
      .map(item => formatMoneyToNumber({ money: item.cost }))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);

    return formatNumberToMoney({ number });
  };

  const handleUpdateRecord = () => {
    const new_dataList = Array.from(dataList);
    new_dataList[isEditRecord] = modalDataRecord;

    const newList = new_dataList.map(item => ({
      ...item,
      cost: item.cost,
    }));

    onUpdateDataList({ list: newList });
    handleCloseModalRecord();
  };

  const handleAddRecord = () => {
    const addList = [...dataList, modalDataRecord];
    const newList = addList.map(item => ({
      ...item,
      cost: item.cost,
    }));

    onUpdateDataList({ list: newList });
    handleCloseModalRecord();
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle" className="row">
        <Col md={24}>
          <Title level={4} className="title-text">
            {title}
          </Title>
        </Col>

        {dataList.length > 0 && (
          <>
            <Col md={24}>
              <div className="MSA_REDUCTION_table">
                <div className="MSA_REDUCTION_gridTemplate">
                  <p>Name</p>
                  <p />
                  <p>Cost</p>
                  <></>
                </div>

                {dataList.map((record, index) => (
                  <div key={index} className="MSA_REDUCTION_gridTemplate">
                    <p>{record.name}</p>
                    <p />
                    <p>{`$ ${record.cost}`}</p>
                    {permissionEdit && (
                      <div className="MSA_REDUCTION_tableBtns">
                        <EditIcon className="icon" onClick={() => handleOpenEditRecord({ data: record, index })} />
                        <RemoveIcon className="icon" onClick={() => handleRemoveRecord({ index })} />
                      </div>
                    )}
                  </div>
                ))}
                <div className="MSA_REDUCTION_gridTemplate">
                  <p />
                  <p />
                  <Row>
                    <p className="textBold">{'$ ' + sumListRecords()}</p>
                    <p>Total</p>
                  </Row>
                  <p />
                </div>
              </div>
            </Col>
          </>
        )}

        {permissionEdit && (
          <Col md={24}>
            <Button size="large" className="btn_transparent_secundary" onClick={() => setOpenModal(true)}>
              Add {title}
            </Button>
          </Col>
        )}
      </Row>

      {openModal && (
        <Modal
          visible={true}
          onCancel={handleCloseModalRecord}
          footer={null}
          centered={true}
          closable={true}
          width={500}
          className="add-icd-code-modal"
          title={title}>
          <Row className="modal-body">
            <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
              <Col md={24} className="row">
                <Row className="label_start">
                  <Text>Name</Text>
                </Row>
                <Row>
                  <InputBox
                    placeholder="Required"
                    value={modalDataRecord.name || ''}
                    onChange={value => setModalDataRecord({ ...modalDataRecord, name: value })}
                    classes="addClaimantInput"
                  />
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" className="main-info" style={{ width: '100%' }}>
              <Col md={11} className="row">
                <Row className="label_start">
                  <Text>Cost</Text>
                </Row>
                <Row>
                  <InputCurrencyBox
                    value={modalDataRecord.cost || ''}
                    disabled={!REPORT_PERMISSIONS.editReduction}
                    onChange={value =>
                      setModalDataRecord({
                        ...modalDataRecord,
                        cost: value,
                      })
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Button
                disabled={validModal}
                type="primary"
                className="green-btn button"
                onClick={isEditRecord !== false ? handleUpdateRecord : handleAddRecord}>
                {isEditRecord !== false ? 'Update' : 'Add'}
              </Button>
              <Button className="button_simple button" onClick={handleCloseModalRecord}>
                cancel
              </Button>
            </Row>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default UmDemandTableCost;
