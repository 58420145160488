import React from 'react';

// Const
import { STEP_CLIENT_ATTORNEY, STEP_MSA_ATTORNEY } from 'containers/LitigationAttorneyPage/litigationsConst';

// Style
import './litigationsTabs.scss';

const LitigationsTabs = ({ onChange, step }) => (
  <div className="litigationAttorney_tabs">
    <p
      onClick={() => onChange({ step: STEP_MSA_ATTORNEY })}
      className={`litigationAttorney_tab ${step === STEP_MSA_ATTORNEY ? 'litigationAttorney_stepActive' : ''}`}>
      Master List
    </p>
    <p
      onClick={() => onChange({ step: STEP_CLIENT_ATTORNEY })}
      className={`litigationAttorney_tab ${step === STEP_CLIENT_ATTORNEY ? 'litigationAttorney_stepActive' : ''}`}>
      Client Attorneys
    </p>
  </div>
);

export default LitigationsTabs;
