import { Col, Empty, Row, Typography } from 'antd';
import { EditIcon, RemoveIcon } from 'components/Icons';
import SpinBox from 'components/SpinBox';
import React, { useState } from 'react';
import * as API from 'utils/api';
import EditRatedAges from './EditRatedAges';
import './index.scss';

export default function RatedAgesData({
  claimantInfo,
  GetAllAssignemetData,
  loading,
  deleteAssignementData,
  getAllData,
}) {
  const { Text } = Typography;
  const [isOpen, setIsopen] = useState(false);

  const [s, setLoading] = useState(true);
  const [editRatedData, setEditRatedData] = useState('');

  const OpenModal = editRatedData => {
    setEditRatedData(editRatedData);
    setIsopen(true);
  };
  const saveEditRatedAges = () => {
    API.UpdateCreatedAssignement(editRatedData.id, {
      note: editRatedData.note,
    }).then(res => {
      getAllData();
      setIsopen(false);
    });
  };
  const CloseModal = () => {
    setIsopen(false);
  };

  const handleDataChanges = e => {
    let value = e.target.value;
    setEditRatedData({
      ...editRatedData,
      note: value,
    });
  };

  const RenderImage = item => {
    // let id = files.id;
    API.getPresignLogoUrl(item.id).then(res => {
      window.open(res.url, '_blank');
    });
  };
  return (
    <Row className="reports-list-container">
      <Row type="flex" className="table-header">
        <Col md={6}> Note</Col>
        <Col md={5} style={{ textAlign: 'center' }}>
          Sent To
        </Col>
        <Col md={4}>Status</Col>
        <Col md={6}>Files</Col>
        <Col md={3} style={{ textAlign: 'right' }}>
          Actions
        </Col>
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading}>
          {GetAllAssignemetData && GetAllAssignemetData.length > 0 ? (
            <Row>
              {GetAllAssignemetData.map((data, index) => (
                <Row key={index} className="record" style={{ justifyContent: 'initial' }}>
                  <Col md={6}>
                    <Text>{data.note}</Text>
                  </Col>

                  <Col md={5} style={{ textAlign: 'center' }}>
                    {data.user ? data.user.given_name + ' ' + data.user.family_name : ''}
                  </Col>

                  <Col md={4}>
                    <div className="status-wrapper">
                      {/* <div className="status-rounded"></div> */}
                      <Text style={{ paddingLeft: '5px' }}>{data.status && data.status.name}</Text>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="status-wrapper">
                      {data.assignment_files && data.assignment_files.filter(file => file.assigner_file).length > 0 ? (
                        data.assignment_files
                          .filter(file => file.assigner_file)
                          .map(file => {
                            return (
                              <div className="file-link" onClick={() => RenderImage(file.file)}>
                                {file.file?.name}
                              </div>
                            );
                          })
                      ) : (
                        <>No Files Returned Yet</>
                      )}
                      {/* <div className="status-rounded"></div> */}
                    </div>
                  </Col>
                  <Col md={3} style={{ textAlign: 'right' }} className="actions">
                    <EditIcon className="icon" onClick={() => OpenModal(data)} />
                    <RemoveIcon
                      className="icon"
                      onClick={() => deleteAssignementData(data.id)}
                      //   onClick={this.onStaticDelete}
                    />
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Empty
              description={loading ? false : 'No Data'}
              image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
              className="empty-icon"
            />
          )}
        </SpinBox>
      </Row>

      <EditRatedAges
        isOpen={isOpen}
        saveEditRatedAges={saveEditRatedAges}
        onCofirm={OpenModal}
        onCancel={CloseModal}
        claimant_id={claimantInfo.id}
        editRatedData={editRatedData}
        handleDataChanges={handleDataChanges}
        handleonSubmit={() => {
          const id = editRatedData.id;
          const data = {
            note: editRatedData.note,
          };
          setLoading(true);
          API.UpdateCreatedAssignement(id, data).then(res => {
            setLoading(false);
          });
        }}
        loading={loading}
      />
    </Row>
  );
}
