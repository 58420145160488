export const ASSIGNMENT_TYPE_QA = 1;
export const ASSIGNMENT_TYPE_AR = 2;
export const ASSIGNMENT_TYPE_RA = 3;
export const ASSIGNMENT_TYPE_AQ = 4;
export const ASSIGNMENT_TYPE_QA_INTERNAL = 5;
export const ASSIGNMENT_TYPE_EXTERNAL_NURSE = 6;
export const ASSIGNMENT_TYPE_NURSE = 7;
export const ASSIGNMENT_TYPE_ATTORNEY = 8;
export const ASSIGNMENT_TYPE_ROLE_EXTERNAL_LITIGATION_ATTORNEY = 9;
export const ASSIGNMENT_TYPE_LEGAL_NURSE = 10;
export const ASSIGNMENT_TYPE_MEDICAL_REVIEWER = 11;
export const ASSIGNMENT_TYPE_MSA_MEDICAL_REVIEWER = 13;

export const ASSIGNMENT_ROLES_ADMIN_PORTAL = [
  ASSIGNMENT_TYPE_QA,
  ASSIGNMENT_TYPE_AR,
  ASSIGNMENT_TYPE_RA,
  ASSIGNMENT_TYPE_AQ,
  ASSIGNMENT_TYPE_MSA_MEDICAL_REVIEWER,
];
