import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import CompanyProfile from './CompanyProfile';
import BillingInformation from './BillingInformation';
import BillingInfoSubscription from './BillingInfoSubscription/BillingInfoSubscription';
import BillingSubscriptionReviewer from './BillingInfoSubscription/BillingSubscriptionReviewerOwner';
import PersonalProfile from './PersolnalProfile';
import ChangePassword from './ChangePassword';
import AssignSalesRep from './AssignSalesRep';
import AssignSalesManager from './AssignSalesManager';
import NotificationSettings from './NotificationSettings';
import TimeOff from './TimeOff/TimeOff';
import { useSelector } from 'react-redux';
import isAllowed, { ROLES_DIVISIONS, ROLE_MSA_ID } from '../../layout/roles';
import { getPermissionsBySection, SETTINGS_NAME } from 'permissions';
import './SettingsSidebar.scss';
import { TransactionSettings } from './TransactionSettings';

const STEPS_SETTINGS = {
  PERSONAL_PROFILE: 1,
  BILLING_INFORMATION: 2,
  COMPANY_INFORMATION: 3,
  CHANGE_PASSWORD: 4,
  ASSIGN_SALES_REP: 5,
  ASSIGN_SALES_MANAGER: 6,
  NOTIFICATION_SETTINGS: 7,
  TIME_OFF: 8,
  BILLING_INFO_SUBSCRIPTION: 9,
  TRANSACTION: 10,
};

const { Content } = Layout;

export default function Settings() {
  const { roles, isActiveReviewer } = useSelector(state => state.auth);

  // Permissions
  const { SETTINGS_PERMISSIONS } = getPermissionsBySection({
    roles: roles,
    permissions: [SETTINGS_NAME],
  });
  // end permissions

  const [show, setShow] = useState(STEPS_SETTINGS.PERSONAL_PROFILE);

  const RenderComponents = show => {
    setShow(show);
  };

  const isClient = isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT);

  const isRoleMSA = ROLE_MSA_ID.includes(roles?.user_roles?.roles[0]?.id) && !roles?.user_roles?.tenant_id;

  const isOwnerTenant = roles?.user_roles?.is_tenant_owner;
  const openBillingTenantReviewer = ROLE_MSA_ID.includes(roles?.user_roles?.roles[0]?.id) && isOwnerTenant;
  const openBillingTenant = !openBillingTenantReviewer && (isRoleMSA || isOwnerTenant);
  const isOwner = openBillingTenantReviewer || openBillingTenant;

  return (
    <>
      <Layout>
        <div className="page-wrapper">
          <div className="settings_sidebar_wrapper">
            <h1 className="sidebar_title">Settings</h1>
            <Menu
              mode="inline"
              className="menu"
              defaultSelectedKeys={[STEPS_SETTINGS.PERSONAL_PROFILE]}
              defaultOpenKeys={['sub1']}>
              <Menu.Item
                key={STEPS_SETTINGS.PERSONAL_PROFILE}
                className="menu-item"
                onClick={() => RenderComponents(STEPS_SETTINGS.PERSONAL_PROFILE)}>
                Personal Profile
              </Menu.Item>
              {isClient && (
                <Menu.Item
                  key={STEPS_SETTINGS.BILLING_INFORMATION}
                  className="menu-item"
                  onClick={() => RenderComponents(STEPS_SETTINGS.BILLING_INFORMATION)}>
                  Billing Information
                </Menu.Item>
              )}
              {openBillingTenant | openBillingTenantReviewer && (
                <Menu.Item
                  key={STEPS_SETTINGS.BILLING_INFO_SUBSCRIPTION}
                  className="menu-item"
                  onClick={() => RenderComponents(STEPS_SETTINGS.BILLING_INFO_SUBSCRIPTION)}>
                  Billing Information
                </Menu.Item>
              )}

              {isOwner && (
                <Menu.Item
                key={STEPS_SETTINGS.TRANSACTION}
                className="menu-item"
                onClick={() => RenderComponents(STEPS_SETTINGS.TRANSACTION)}>
                Transactions
              </Menu.Item>
              )}

              {isClient && (
                <Menu.Item
                  key={STEPS_SETTINGS.COMPANY_INFORMATION}
                  className="menu-item"
                  onClick={() => RenderComponents(STEPS_SETTINGS.COMPANY_INFORMATION)}>
                  Company Profile
                </Menu.Item>
              )}

              {
                isActiveReviewer && (
                  <Menu.Item
                    key={STEPS_SETTINGS.CHANGE_PASSWORD}
                    className="menu-item"
                    onClick={() => RenderComponents(STEPS_SETTINGS.CHANGE_PASSWORD)}>
                    Reset Password
                  </Menu.Item>
                )
              }

              {SETTINGS_PERMISSIONS.assignSalesRep && (
                <Menu.Item
                  key={STEPS_SETTINGS.ASSIGN_SALES_REP}
                  className="menu-item"
                  onClick={() => RenderComponents(STEPS_SETTINGS.ASSIGN_SALES_REP)}>
                  Assigned Sales Rep
                </Menu.Item>
              )}

              {SETTINGS_PERMISSIONS.assignSalesManager && (
                <Menu.Item
                  key={STEPS_SETTINGS.ASSIGN_SALES_MANAGER}
                  className="menu-item"
                  onClick={() => RenderComponents(STEPS_SETTINGS.ASSIGN_SALES_MANAGER)}>
                  Assigned Sales Manager
                </Menu.Item>
              )}

              {!SETTINGS_PERMISSIONS.notification && (
                <Menu.Item
                  key={STEPS_SETTINGS.NOTIFICATION_SETTINGS}
                  className="menu-item"
                  onClick={() => RenderComponents(STEPS_SETTINGS.NOTIFICATION_SETTINGS)}>
                  Notification Settings
                </Menu.Item>
              )}

              {SETTINGS_PERMISSIONS.timeOff && isActiveReviewer && (
                <Menu.Item
                  key={STEPS_SETTINGS.TIME_OFF}
                  className="menu-item"
                  onClick={() => RenderComponents(STEPS_SETTINGS.TIME_OFF)}>
                  Time Off
                </Menu.Item>
              )}
            </Menu>
          </div>

          <Layout style={{ padding: '0 24px 24px', width: '100%' }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}>
              {show === STEPS_SETTINGS.PERSONAL_PROFILE && <PersonalProfile />}

              {isClient && (
                <>
                  {show === STEPS_SETTINGS.BILLING_INFORMATION && <BillingInformation />}
                  {show === STEPS_SETTINGS.COMPANY_INFORMATION && <CompanyProfile />}
                </>
              )}
              {show === STEPS_SETTINGS.CHANGE_PASSWORD && <ChangePassword />}

              {SETTINGS_PERMISSIONS.assignSalesRep && show === STEPS_SETTINGS.ASSIGN_SALES_REP && <AssignSalesRep />}

              {SETTINGS_PERMISSIONS.assignSalesManager && show === STEPS_SETTINGS.ASSIGN_SALES_MANAGER && (
                <AssignSalesManager />
              )}
              
              {show === STEPS_SETTINGS.NOTIFICATION_SETTINGS && <NotificationSettings />}

              {show === STEPS_SETTINGS.TIME_OFF && <TimeOff />}

              {openBillingTenant && show === STEPS_SETTINGS.BILLING_INFO_SUBSCRIPTION && <BillingInfoSubscription />}
              
              {openBillingTenantReviewer && show === STEPS_SETTINGS.BILLING_INFO_SUBSCRIPTION && (
                <BillingSubscriptionReviewer isActiveReviewer={isActiveReviewer} />
              )}

              {isOwner && show === STEPS_SETTINGS.TRANSACTION && <TransactionSettings />}
            </Content>
          </Layout>
        </div>
      </Layout>
    </>
  );
}
