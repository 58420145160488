import React from "react";
import {Row, Typography, Input} from "antd";

import "./InputMultiline.scss";

const {Text} = Typography;
const {TextArea} = Input;

const InputMultiline = props => {
  const {label, value, disabled, placeholder, onChange, size = 1, required} = props;

  return (
    <Row className="multiline-container">
      <Row className="label">
        <Text>{label}</Text>{required && <span className="required">*</span>}
      </Row>
      <Row>
        {
          size === 1 ? (<Input
            placeholder={placeholder || "Required"}
            className="input"
            disabled={disabled || false}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />) : (<TextArea
            placeholder={placeholder || "Required"}
            className="textarea"
            disabled={disabled || false}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            autoSize={false}
          />)
        }

      </Row>
    </Row>
  );
}

export default InputMultiline;
