import React, { Component } from 'react';
import { Row, Col, Typography, Button, Skeleton } from 'antd';
import InputMask from 'react-input-mask';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';

import InputBox from 'components/InputBox';
import { BackIcon } from 'components/Icons';
import * as API from 'utils/api';
import EditUserRoles from 'components/EditUserRoles/index';
import { validateEmail, validatePhone, validateName } from 'utils/utils';
import { notifyApiError, notifyInfo } from 'utils/notification';
import isAllowed, {
  ROLES_DIVISIONS,
  CLIENT_USER_ID,
  MEDICAL_REVIEWER_ID,
  SALES_REP_ID,
  SALES_MANAGER_ID,
  CLIENT_ADMIN_ID,
  CLIENT_SUPER_ADMIN_ID,
  EXTERNAL_LITIGATION_ATTORNEY_ID,
} from 'layout/roles';

import './EditUser.scss';
import { UploadFileCVUser } from 'utils/upload';

const { Title, Text } = Typography;

class EditUser extends Component {
  constructor(props) {
    super(props);

    let userInfo = {};
    if (this.props.location && this.props.location.state) {
      userInfo = this.props.location.state.data;
    }

    this.state = {
      id: userInfo.id || this.props.match.params.idUser,
      given_name: userInfo.given_name,
      family_name: userInfo.family_name,
      email: userInfo.email,
      phone: userInfo.locale,
      restriction_templates: userInfo['custom:restrictions']
        ? userInfo['custom:restrictions'].includes('templates')
        : false,
      restriction_users: userInfo['custom:restrictions'] ? userInfo['custom:restrictions'].includes('users') : false,
      restriction_contacts: userInfo['custom:restrictions']
        ? userInfo['custom:restrictions'].includes('contacts')
        : false,
      loadingTitle: 'Loading...',
      loading: false,
      selectedRoles: [],
      getRoles: [],
      clients: [],
      client_id: undefined,
      selectedSpecialty: undefined,
      sales_rep_id: null,
      salesReps: [],
      salesManagers: [],
      sales_manager_id: null,
      cv: '',
      newCv: '',
      visibility: true,
    };

    this.handleFindUsers = this.handleFindUsers.bind(this);
    this.handleChangeRoles = this.handleChangeRoles.bind(this);
    this.loadUserRoles = this.loadUserRoles.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.loadNurseRoles = this.loadNurseRoles.bind(this);
    this.handleSetValue = this.handleSetValue.bind(this);
    this.fetchClients = this.fetchClients.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.getCVbyUser = this.getCVbyUser.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleChangeVisibility = this.handleChangeVisibility.bind(this);
  }

  componentDidMount() {
    (async () => {
      try {
        this.setState({ loading: true });
        await Promise.all([this.loadCurrentUser(), this.loadUserRoles(), this.getCVbyUser()]);

        if (this.state.getRoles.length < 1) {
          this.loadNurseRoles();
        }
      } catch (e) {
        notifyApiError(e);
      } finally {
        this.setState({ loading: false });
      }
    })();
  }

  async loadUserRoles() {
    // this.setState({ loading: true });

    try {
      const isUserAdmin = isAllowed(this.props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.SALES_MANAGER,
      ]);
      const isUserManagement = isAllowed(this.props.roles, ROLES_DIVISIONS.PURE_MANAGEMENT);

      const apiCall = tenantOnly => API.GetUserRoles(this.state.id, tenantOnly);

      const roles = isUserManagement && !isUserAdmin ? await apiCall(true) : await apiCall();

      if (roles.user_roles.roles) {
        const selectedRoles = roles.user_roles.roles.map(role => {
          if (role.user_roles.client_id) {
            this.setState({ client_id: role.user_roles.client_id });

            if (role.user_roles?.sales_rep_id) this.setState({ sales_rep_id: role.user_roles.sales_rep_id });
          }

          if (role.user_roles?.sales_manager_id) this.setState({ sales_manager_id: role.user_roles.sales_manager_id });

          return {
            id: role.id,
            name: role.name,
          };
        });

        if (selectedRoles.some(role => [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role.id)))
          await this.handleFindUsers({ loadingFunc: false, roleId: SALES_REP_ID });

        this.setState({ selectedRoles });
      }

      if (roles.user_roles?.specialty) this.setState({ selectedSpecialty: roles.user_roles.specialty });

      this.setState({ visibility: !!roles?.user_roles?.roles[0]?.user_roles?.visibility });

      if (roles?.user_roles) this.setState({ ...roles?.user_roles });
    } catch (e) {
      notifyApiError(e);
    } finally {
      // this.setState({ loading: false });
    }
  }

  async handleFindUsers({ loadingFunc = true, roleId } = {}) {
    try {
      if (loadingFunc) this.setState({ loadingInternal: true });
      return await API.getUsersByRoleId({ roleId });
    } catch (e) {
      notifyApiError(e);
    } finally {
      if (loadingFunc) this.setState({ loadingInternal: false });
    }
  }

  async loadCurrentUser() {
    try {
      const res = await Auth.currentUserInfo();

      if (res) {
        this.setState({ currentUser: res.username });
      }
    } catch (e) {
      notifyApiError(e);
    }
  }

  async loadNurseRoles() {
    try {
      let { roles } = await API.getNurseRoles();

      if (isAllowed(this.props.roles, [...ROLES_DIVISIONS.CLIENT_ADMIN, ...ROLES_DIVISIONS.CLIENT_SUPER_ADMIN]))
        roles = roles.filter(rol =>
          [...ROLES_DIVISIONS.ALL_CLIENT, ...ROLES_DIVISIONS.EXTERNAL_LITIGATION_ATTORNEY].includes(rol.name)
        );

      this.setState({ getRoles: roles });
    } catch (e) {
      notifyApiError(e);
    }
  }

  handleSetValue(type, value) {
    this.setState({
      [type]: value,
    });
  }

  async fetchClients() {
    const { roles } = this.props;

    try {
      const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
      const isUserManagement = isAllowed(roles, ROLES_DIVISIONS.PURE_MANAGEMENT);

      const apiCall = tenantOnly => API.getClientsData('', 0, 1000, tenantOnly);

      const { clients } = isUserManagement && !isUserAdmin ? await apiCall(true) : await apiCall();

      this.setState({ clients });
    } catch (e) {
      notifyApiError(e);
    }
  }

  async handleEditUser(state) {
    const {
      given_name,
      family_name,
      email,
      phone,
      restriction_templates,
      restriction_contacts,
      restriction_users,
      client_id,
      selectedSpecialty,
      selectedRoles,
      clients,
      cv,
      newCv,
      id,
      visibility,
    } = state;

    let restrictions = '';
    if (restriction_templates) restrictions += 'templates,';
    if (restriction_contacts) restrictions += 'contacts,';
    if (restriction_users) restrictions += 'users,';
    if (restrictions === '') restrictions = ',';

    const param = {
      email,
      given_name,
      family_name,
      locale: phone,
      'custom:restrictions': restrictions,
    };

    this.setState({ loadingTitle: 'Saving...', loading: true });

    if (!selectedRoles.length) {
      this.setState({ loading: false });
      return notifyApiError('Please choose at least one role');
    }

    if (selectedRoles.find(role => role.id === CLIENT_USER_ID) && !client_id) {
      this.setState({ loading: false });
      return notifyApiError('Please choose a client');
    }

    if (selectedRoles.find(role => role.id === MEDICAL_REVIEWER_ID) && !selectedSpecialty) {
      this.setState({ loading: false });
      return notifyApiError('Please choose a specialty');
    }

    try {
      await API.updateUser(email, param);

      if (clients.length && client_id) {
        const pickedClient = clients.find(client => client.id === client_id);

        param.tenant_id = pickedClient.tenant_id;
      }

      if (selectedSpecialty) param.specialty = selectedSpecialty;

      param.visibility = visibility;
      param.id = id;

      await API.updateUserSynchronised(param);

      if (newCv) {
        if (cv) {
          await UploadFileCVUser({ file: newCv, user_id: id, isEdit: true, idFileEdit: cv.id });
        } else {
          await UploadFileCVUser({ file: newCv, user_id: id });
        }
      }

      this.props.history.goBack();
      notifyInfo('User was updated');
    } catch (e) {
      notifyApiError(e.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  cancelHandler() {
    const { selectedRoles, client_id, selectedSpecialty } = this.state;

    if (!selectedRoles.length) {
      this.setState({ loading: false });
      return notifyApiError('Please choose at least one role');
    }

    if (selectedRoles.find(role => role.id === CLIENT_USER_ID) && !client_id) {
      return notifyApiError('Please choose a client');
    }

    if (selectedRoles.find(role => role.id === MEDICAL_REVIEWER_ID) && !selectedSpecialty) {
      return notifyApiError('Please choose a specialty');
    }

    this.props.history.goBack();
  }

  async handleChangeRoles({ roles }) {
    if (
      roles.find(role => [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role.id)) &&
      this.state.salesReps.length === 0
    ) {
      const userSalesRep = await this.handleFindUsers({ roleId: SALES_REP_ID });
      this.setState({ salesReps: userSalesRep.users });
    }

    if (roles.find(role => role.id === SALES_REP_ID) && this.state.salesManagers.length === 0) {
      const userSalesManager = await this.handleFindUsers({ roleId: SALES_MANAGER_ID });
      this.setState({ salesManagers: userSalesManager.users });
    }
    this.setState({ selectedRoles: roles });
  }

  async getCVbyUser() {
    try {
      const getCv = await API.getFileByUser({ path: 'CV', userId: this.state.id });

      if (!getCv) return;

      this.setState({ cv: getCv });
    } catch (e) {
      notifyApiError(e);
    }
  }

  uploadFile({ file }) {
    this.setState({ newCv: file });
  }

  handleChangeVisibility({ visibility, client_id }) {
    try {
      this.setState({ loading: true });

      if (!client_id && !visibility) {
        return notifyInfo('The Firm field cannot be empty')
      }

      API.EditRole(this.state.id, EXTERNAL_LITIGATION_ATTORNEY_ID, {
        visibility,
      });
      this.setState({ visibility });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      given_name,
      family_name,
      email,
      phone,
      loading,
      selectedRoles,
      salesReps,
      sales_rep_id,
      salesManagers,
      sales_manager_id,
      cv,
      newCv,
      visibility,
    } = this.state;

    const isFormValid =
      given_name &&
      family_name &&
      selectedRoles.length &&
      validateEmail(email) &&
      validatePhone(phone) &&
      validateName(given_name) &&
      validateName(family_name);

    return (
      <Row className="edit-user-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Edit User
            </Title>
          </Col>
          <Col className="back" onClick={this.cancelHandler}>
            <BackIcon className="icon" />
          </Col>
        </Row>
        <Row className="main">
          <Row className="content">
            {loading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <>
                <Row type="flex" justify="space-between" align="middle" className="main-info">
                  <Col md={11} className="row">
                    <InputBox
                      label="First Name"
                      value={given_name}
                      onChange={value => this.setState({ given_name: value })}
                    />
                  </Col>
                  <Col md={11} className="row">
                    <InputBox
                      label="Last Name"
                      value={family_name}
                      onChange={value => this.setState({ family_name: value })}
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="space-between" align="middle" className="main-info">
                  <Col md={11} className="row">
                    <InputBox
                      disabled
                      label="Email"
                      value={email}
                      onChange={value => this.setState({ email: value })}
                    />
                  </Col>
                  <Col md={11} className="row">
                    <Row className="label">
                      <Text>Phone</Text>
                    </Row>
                    <InputMask
                      mask="999-999-9999"
                      placeholder="Optional"
                      className="input-ssn"
                      value={phone || undefined}
                      onChange={e => this.setState({ phone: e.target.value })}
                    />
                  </Col>
                </Row>
                <EditUserRoles
                  cv={cv}
                  newCv={newCv}
                  selectedRoles={selectedRoles}
                  GetRoles={this.state.getRoles}
                  user_id={this.state.id}
                  clientId={this.state.client_id}
                  specialtyId={this.state.selectedSpecialty}
                  clients={this.state.clients}
                  salesReps={salesReps}
                  salesRepId={sales_rep_id}
                  salesManagers={salesManagers}
                  salesManagerId={sales_manager_id}
                  fetchClients={this.fetchClients}
                  disabledCLient={isAllowed(this.props.roles, ROLES_DIVISIONS.ALL_CLIENT)}
                  onRoleChange={value => this.handleChangeRoles({ roles: value })}
                  onClientChange={({ client_id, sales_rep_id }) => this.setState({ client_id, sales_rep_id })}
                  onSpecialtyChange={({ specialty_id }) => this.setState({ selectedSpecialty: specialty_id })}
                  uploadFile={this.uploadFile}
                  onChangeVisibility={this.handleChangeVisibility}
                  visibility={visibility}
                />
                <Row type="flex" justify="center" className="confirm-button">
                  <Button
                    disabled={!isFormValid}
                    type="primary"
                    size="large"
                    className="green-btn button"
                    onClick={() => this.handleEditUser(this.state)}>
                    Save Changes
                  </Button>
                </Row>
              </>
            )}
          </Row>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, null)(EditUser);
