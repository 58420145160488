export const FIELD_TABLE_LIFE_PLAN_SERVICE_NAME = 'service_name';
export const FIELD_TABLE_LIFE_PLAN_TOTAL_COST = 'total_cost';
export const FIELD_TABLE_LIFE_PLAN_AGE_AT_END = 'age_at_end';
export const FIELD_TABLE_LIFE_PLAN_CPT_HCPCS_CODE = 'cpt_hcpcs_code';
export const FIELD_TABLE_LIFE_PLAN_INFLATION_TOTAL = 'inflation_total';
export const FIELD_TABLE_LIFE_PLAN_RECOMMENDED_BY = 'recommended_by';
export const FIELD_TABLE_LIFE_PLAN_CODES = 'codes';
export const FIELD_TABLE_LIFE_PLAN_START_DATE = 'start_date';
export const FIELD_TABLE_LIFE_PLAN_PURPUSE = 'purpose';
export const FIELD_TABLE_LIFE_PLAN_FREQUECY_AND_DURATION_OF_NEED = 'frequecy_and_duration_of_need';
export const FIELD_TABLE_LIFE_PLAN_AGE_AT_START = 'age_at_start';
export const FIELD_TABLE_LIFE_PLAN_COMMENT = 'comment';
export const FIELD_TABLE_LIFE_PLAN_UNIT_COST = 'unit_cost';
export const FIELD_TABLE_LIFE_PLAN_TOTAL_YEARS = 'total_years';
export const FIELD_TABLE_LIFE_PLAN_ANNUAL_COST = 'annual_cost';
export const FIELD_TABLE_LIFE_PLAN_END_DATE = 'end_date';
export const FIELD_UNITS_NEEDED = 'units_needed';
export const FIELD_EVERY_X_YEAR = 'every_x_year';
export const FIELD_TABLE_LIFE_PLAN_SPECIALTY = 'speciality_id';
export const FIELD_TABLE_LIFE_PLAN_PRESCRIPTIONS = 'prescriptions';
export const FIELD_TABLE_LIFE_PLAN_NDC = 'ndc';
export const FIELD_TABLE_LIFE_PLAN_UNIT_FORM = 'unit_form_id';
export const FIELD_TABLE_LIFE_PLAN_PRESCRIPTIONS_STRENGTH = 'prescription_strength';
export const FIELD_TABLE_LIFE_PLAN_PRESCRIPTIONS_FREQUENCY = 'prescribed_frequency';
export const FIELD_TABLE_LIFE_PLAN_PRICING_POLICY = 'pricing_policy';

export const COLUMNS_TABLE_LIFE_CARE_PLAN = [
  {
    id: 1,
    name: 'Service Name',
    field: FIELD_TABLE_LIFE_PLAN_SERVICE_NAME,
    col: 2,
  },
  {
    id: 2,
    name: 'CPT/HCPCS',
    field: FIELD_TABLE_LIFE_PLAN_CPT_HCPCS_CODE,
    col: 2,
  },
  {
    id: 3,
    name: 'Codes',
    field: FIELD_TABLE_LIFE_PLAN_CODES,
    col: 1,
  },
  {
    id: 4,
    name: 'Frequency And Duration Of Need',
    field: FIELD_TABLE_LIFE_PLAN_FREQUECY_AND_DURATION_OF_NEED,
    col: 3,
    children: [
      {
        name: 'Units Needed',
        col: 8,
        field: FIELD_UNITS_NEEDED,
      },
      {
        name: 'Every',
        col: 8,
        show: 'every',
      },
      {
        name: "'X' Years",
        col: 8,
        field: FIELD_EVERY_X_YEAR,
      },
    ],
  },
  {
    id: 5,
    name: 'Unit Cost',
    field: FIELD_TABLE_LIFE_PLAN_UNIT_COST,
    col: 1,
  },
  {
    id: 6,
    name: 'Annual Cost',
    field: FIELD_TABLE_LIFE_PLAN_ANNUAL_COST,
    col: 1,
  },
  {
    id: 7,
    name: 'Total Cost',
    field: FIELD_TABLE_LIFE_PLAN_TOTAL_COST,
    col: 1,
  },
  {
    id: 8,
    name: 'Inflation Total',
    field: FIELD_TABLE_LIFE_PLAN_INFLATION_TOTAL,
    col: 1,
  },
  {
    id: 9,
    name: 'Start Date',
    field: FIELD_TABLE_LIFE_PLAN_START_DATE,
    col: 1,
  },
  {
    id: 10,
    name: 'Age At Start',
    field: FIELD_TABLE_LIFE_PLAN_AGE_AT_START,
    col: 1,
  },
  {
    id: 11,
    name: 'Total Years',
    field: FIELD_TABLE_LIFE_PLAN_TOTAL_YEARS,
    col: 1,
  },
  {
    id: 12,
    name: 'End Date',
    field: FIELD_TABLE_LIFE_PLAN_END_DATE,
    col: 1,
  },
  {
    id: 13,
    name: 'Age At End',
    field: FIELD_TABLE_LIFE_PLAN_AGE_AT_END,
    col: 1,
  },
  {
    id: 14,
    name: 'Recommended By',
    field: FIELD_TABLE_LIFE_PLAN_RECOMMENDED_BY,
    col: 3,
  },
  {
    id: 15,
    name: 'Purpose',
    field: FIELD_TABLE_LIFE_PLAN_PURPUSE,
    col: 2,
  },
  {
    id: 16,
    name: 'Comment',
    field: FIELD_TABLE_LIFE_PLAN_COMMENT,
    col: 2,
  },
];

export const COLUMNS_TABLE_LIFE_CARE_PLAN_MEDICAL_COST = [
  {
    id: 1,
    name: 'Future Medical Need Of The Type Normally Covered By Medicare',
    field: FIELD_TABLE_LIFE_PLAN_SERVICE_NAME,
    col: 4,
  },
  {
    id: 2,
    name: 'Specialty',
    field: FIELD_TABLE_LIFE_PLAN_SPECIALTY,
    col: 2,
  },
  {
    id: 3,
    name: 'CPT/HCPCS Code',
    field: FIELD_TABLE_LIFE_PLAN_CPT_HCPCS_CODE,
    col: 2,
  },
  {
    id: 4,
    name: 'Frequency And Duration Of Need',
    field: FIELD_TABLE_LIFE_PLAN_FREQUECY_AND_DURATION_OF_NEED,
    col: 5,
    children: [
      {
        name: 'Units Needed',
        col: 8,
        field: FIELD_UNITS_NEEDED,
      },
      {
        name: 'Every',
        col: 8,
        show: 'every',
      },
      {
        name: "'X' Years",
        col: 8,
        field: FIELD_EVERY_X_YEAR,
      },
    ],
  },
  {
    id: 5,
    name: 'Per Unit cost',
    field: FIELD_TABLE_LIFE_PLAN_UNIT_COST,
    col: 2,
  },
  {
    id: 6,
    name: 'Annual Cost',
    field: FIELD_TABLE_LIFE_PLAN_ANNUAL_COST,
    col: 2,
  },
  {
    id: 7,
    name: 'Total Years',
    field: FIELD_TABLE_LIFE_PLAN_TOTAL_YEARS,
    col: 2,
  },
  {
    id: 8,
    name: 'Total Cost',
    field: FIELD_TABLE_LIFE_PLAN_TOTAL_COST,
    col: 2,
  },
  {
    id: 9,
    name: 'Comment',
    field: FIELD_TABLE_LIFE_PLAN_COMMENT,
    col: 3,
  },
];

export const COLUMNS_TABLE_LIFE_CARE_PLAN_PRESCRIPTION = [
  {
    id: 1,
    name: 'Prescription',
    field: FIELD_TABLE_LIFE_PLAN_PRESCRIPTIONS,
    col: 2,
  },
  {
    id: 2,
    name: 'NDC',
    field: FIELD_TABLE_LIFE_PLAN_NDC,
    col: 1,
  },
  {
    id: 3,
    name: 'Unit Form',
    field: FIELD_TABLE_LIFE_PLAN_UNIT_FORM,
    col: 1,
  },
  {
    id: 4,
    name: 'Prescribed Strength',
    field: FIELD_TABLE_LIFE_PLAN_PRESCRIPTIONS_STRENGTH,
    col: 2,
  },
  {
    id: 5,
    name: 'Prescribed Frequency',
    field: FIELD_TABLE_LIFE_PLAN_PRESCRIPTIONS_FREQUENCY,
    col: 3,
    children: [
      {
        name: 'Units per Refill',
        col: 12,
        show: 'units_per_refill',
      },
      {
        name: 'Refills per Year',
        col: 12,
        show: 'refills_per_year',
      },
    ],
  },
  {
    id: 6,
    name: 'Pricing Policy: Average Wholesale Price(AWP)',
    field: FIELD_TABLE_LIFE_PLAN_PRICING_POLICY,
    col: 15,
    children: [
      {
        name: 'Price Per Unit',
        col: 4,
        show: 'price_per_unit',
      },
      {
        name: 'Annual Cost',
        col: 4,
        show: 'annual_cost',
      },
      {
        name: 'Total Years',
        col: 4,
        show: 'total_years',
      },
      {
        name: 'Total Cost',
        col: 4,
        show: 'total_cost',
      },
      {
        name: 'Comments',
        col: 8,
        show: 'comments',
      },
    ],
  },
];