export default {
  avatar: ['image/jpeg', 'image/png'],
  common: [
    // image
    'image/jpeg',
    'image/png',
    // pdf
    'application/pdf',
    // compress
    'application/zip',
    'application/x-7z-compressed',
    'application/vnd.rar',
    'application/octet-stream',
    'application/x-zip-compressed',
    'application/x-zip',
    // txt
    'text/plain',
    // csv
    'text/csv',
    // exel
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    // word
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ],
};
