import { Col, Row, Typography } from "antd";
import AddressFormatter from "components/AddressFormatter";
import React, { Component } from "react";
const { Text } = Typography;
export default class ContactsOnReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const contactList = this.props.contactList;
    return (
      <>
        {contactList.map((el, i) => {
          return (
            <>
              <Col md={7}>
                <Row>
                  <Text>
                    <b>Name</b>
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <b>Email</b>
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <b>Address</b>
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <b>Organisation</b>
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <b>Phone Number</b>
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <b>Fax</b>
                    <hr />
                  </Text>
                </Row>
              </Col>
              <Col md={17}>
                <Row>
                  <Text>
                    {!el.contact.contact_name ? "-" : el.contact.contact_name}
                  </Text>
                </Row>
                <Row>
                  <Text>{!el.contact.email ? "-" : el.contact.email}</Text>
                </Row>
                <Row>
                  {/* <AddressFormatter address={el.contact.address} /> */}
                  <Text>{!el.contact.address ? "-" : el.contact.address}</Text>
                </Row>
                <Row>
                  <Text>
                    {!el.contact.organisation_name
                      ? "-"
                      : el.contact.organisation_name}
                  </Text>
                </Row>
                <Row>
                  <Text>
                    {!el.contact.phone_number ? "-" : el.contact.phone_number}
                  </Text>
                </Row>
                <Row>
                  <Text>
                    {!el.contact.fax_number ? "-" : el.contact.fax_number}
                    <hr />
                  </Text>
                </Row>
              </Col>
            </>
          );
        })}
      </>
    );
  }
}
