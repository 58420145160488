const initialState = {
  isLoading: false,
  settings: {
    limit: 10, //perPage pagination global
  },
};

const application = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
};

export default application;
