import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Divider, Icon, Row, Select, Typography } from 'antd';
import dynamicData from 'dynamicData';
import InlineContact from 'components/InlineContact';
import TextAreaBox from 'components/TextAreaBox';
import InputBox from 'components/InputBox';
import TagsInputBox from 'components/TagsInputBox';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import ClaimantInfo from 'components/ClaimantInfoOnWizard';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import DropDown from 'components/Dropdown';
import TemplateFields from 'components/TemplateFields';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import PrevNextStep from '../../../../components/PrevNextStep';
import { notifyApiError, notifyInfo } from '../../../../utils/notification';
import { EMPTY_TEXT } from 'constants/shortcodes';
import { BtnSaveDraft } from 'containers/Reports/components/BtnSaveDraft';
import { qaHaveAssignedReport } from '../../utils/verify-permissions';
import { CLAIMANT_EDIT, MAIN_NAV } from 'layout/navigate_const';
import './MedicalContent.scss';
import { REPORT_MCP_MSA_COMBO_NAME } from 'constants/reports';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import { LifeSourceAndLifeTable } from 'containers/Reports/components/LifeSourceAndLifeTable';
import { LIFE_TYPE } from 'containers/Reports/components/LifeSourceAndLifeTable/LifeSourceAndLifeTable';
import { matchSourceToItem } from 'containers/Reports/utils/helpers';

const { Title, Text } = Typography;
const { Option } = Select;

const defaultCompensation =
  'Our organization certifies that all rated ages we have obtained and/or have knowledge of regarding this ' +
  'claimant, and generated at any time on or after the Date of Incident for the alleged ' +
  'accident/illness/injury/incident at issue, have been included as part of this submission of a proposed ' +
  "amount for a Workers' Compensation Medicare Set-Aside Arrangement (WCMSA) to the Centers for Medicare" +
  '& Medicaid Services';
class MedicalContent extends Component {
  constructor(props) {
    super(props);

    let report_id = '';
    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    // Permissions
    const { REPORT_PERMISSIONS } = getPermissionsBySection({
      roles: this.props.roles,
      permissions: [REPORT_PERMISSIONS_NAME],
    });
    // end permissions

    this.state = {
      report_id: report_id,
      introduction: '',
      medical_records_reviewed: '',
      description_of_injury: '',
      medical_summary: '',
      missing_medical_records: '',
      related_diagnosis: '',
      pre_existing_conditions: '',
      surgeries_procedures: '',
      treating_providers: undefined,
      current_treatment_plan: '',
      life_expectancy: 0,
      projected_treatment_plan: '',
      social_security_disability_status: '',
      medicare_status: '',
      medicare_lien_status: `Please note that Medicare has a statutory right of recovery for any conditional payments made pursuant to 42 U.S.C. 1395y(b)(2) and 42 C.F.R. 411.24. We recommend that the settlement documents take this factor into account by having a provision that specifies which party will be responsible for any conditional payments made by Medicare.
      According to the MSPRC (Medicare Secondary Payer Recovery Contractor), a conditional payment is a payment made by Medicare for services which another payer was responsible. The MSPRC will provide a payment summary listing of claims paid by Medicare that are related to an accident, illness or other incident. The total money paid by Medicare on one of these listing is called the conditional payment amount.`,
      medicare_allocation_administration: '',
      source_id: 0,
      life_table_id: 0,
      contactsList: [],
      lifeSources: [],
      lifeTables: [],
      loading: false,
      medical_status: '',
      rated_ages_text: '',
      report_contacts: [],
      treatingList: [],
      report_claim_id: '',
      is_template: false,
      compensation: defaultCompensation,
      claimant_id: 0,
      client_id: 0,
      updating: true,
      prognosis: '',
      additional_comments: '',
      REPORT_PERMISSIONS,
      life_table_id: 1,
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);

    this.handleChangeLife = this.handleChangeLife.bind(this);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });

    this.getContactLists();

    API.getReportContacts(report_id).then(res => {
      this.setState({ report_contacts: res.report_contacts.rows });
    });
    API.getReportById(report_id)
      .then(async reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        const redirect = await qaHaveAssignedReport({
          roles: this.props.roles,
          reportId: report_id,
          claimantId: reportInfo.claimant_id,
        });

        if (redirect) return this.props.history.push(MAIN_NAV.path);

        this.props.updateReportInfo(reportInfo);

        API.getLifeSources().then(res => {
          let id = matchSourceToItem(reportInfo.life_expectancy_source, res.life_expects);
          if (!id) {
            this.setState({
              lifeSources: res.life_expects,
              source_id: res.life_expects[0].id,
            });
          } else {
            this.setState({
              lifeSources: res.life_expects,
              source_id: id,
            });
          }
        });
        API.getLifeTables().then(res => {
          let id = matchSourceToItem(reportInfo.life_expectancy_source, res.life_expects);
          if (!id) {
            this.setState({
              lifeTables: res.life_expects,
              life_table_id: res.life_expects[0].id,
            });
          } else {
            this.setState({
              lifeTables: res.life_expects,
              life_table_id: id,
            });
          }
        });

        const defaultIntroduction = `The file of ${reportInfo?.claimant?.claimant_name} 
          ${reportInfo?.claimant?.claimant_last_name} was referred 
          by _____________________________________ for analysis of relevant information and preparation of a 
          Medicare Set-Aside. This Medicare Set-Aside was prepared from a review of the medical records provided 
          and research regarding  Mr/Ms. ${reportInfo?.claimant?.claimant_last_name} diagnosis, medical treatment 
          and prognosis. The proposed Medicare Set-Aside Allocation addresses Mr/Ms. 
          ${reportInfo?.claimant?.claimant_last_name} current medical status and his/her future medical and 
          rehabilitation requirements.`;
        const currentIntroduction = reportInfo.introduction
          ? reportInfo.introduction
          : reportInfo.introduction === EMPTY_TEXT
          ? ''
          : defaultIntroduction;
        const currentCompensation = reportInfo.compensation
          ? reportInfo.compensation
          : reportInfo.compensation === EMPTY_TEXT
          ? ''
          : defaultCompensation;
        this.setState({
          report_template_id: reportInfo.report_template_id,
          is_template: reportInfo.is_template,
          introduction: currentIntroduction,
          medical_records_reviewed: reportInfo.medical_records_reviewed,
          description_of_injury: reportInfo.description_of_injury,
          medical_summary: reportInfo.medical_summary,
          missing_medical_records: reportInfo.missing_medical_records,
          related_diagnosis: reportInfo.related_diagnosis,
          pre_existing_conditions: reportInfo.pre_existing_conditions,
          surgeries_procedures: reportInfo.surgeries_procedures,
          treating_providers: reportInfo.treating_providers || undefined,
          current_treatment_plan: reportInfo.current_treatment_plan,
          projected_treatment_plan: reportInfo.projected_treatment_plan,
          social_security_disability_status: reportInfo.social_security_disability_status,
          medicare_status: reportInfo.medicare_status,
          medicare_lien_status: reportInfo.medicare_lien_status,
          medicare_allocation_administration: reportInfo.medicare_allocation_administration,
          rated_ages_text: reportInfo.rated_ages_text,
          medical_status: reportInfo.medical_status,
          prognosis: reportInfo.prognosis,
          report_claim_id: reportInfo.report_claim_id || '',
          claimant_id: reportInfo.claimant_id,
          loading: false,
          references: reportInfo.references,
          additional_comments: reportInfo.additional_comments,
          client_id: reportInfo?.claimant?.client_id,
          compensation: currentCompensation,
          life_table_id: reportInfo?.life_table_id,
        });
      })
      .catch(err => forbiddenRedirect(err, this.props));
  };

  componentDidUpdate = () => {
    const { reportInfo } = this.props;
    const { lifeSources, lifeTables, life_expectancy, updating } = this.state;

    if (reportInfo.claimant_id && lifeSources.length && lifeTables.length && !life_expectancy && updating) {
      const param = {
        source_id: lifeSources[0].id,
        life_table_id: lifeTables[0].id,
      };
      API.getLifeExpect(reportInfo.claimant_id, param)
        .then(res =>
          this.setState({
            life_expectancy: Math.round(res.years_left),
            updating: false,
          })
        )
        .catch(err =>
          this.setState({
            life_expectancy: 0,
            updating: false,
          })
        );
    }
  };

  getContactLists = () => {
    API.getContactsData({ pageSize: 1000 }) //Getting all contacts data
      .then(res => {
        const dynamic = dynamicData();
        this.setState({
          contactsList: res.contacts,
          treatingList: res.contacts.filter(
            item => (item.contact_type ? item.contact_type.name : '') === dynamic.treating_providers
          ),
        });
      });
  };

  getReportContacts = async () => {
    const { report_id } = this.state;
    let res = await API.getReportContacts(report_id);
    this.setState({ report_contacts: res.report_contacts.rows });
  };
  handleUpdateReport = async (isNextStep = false) => {
    const {
      report_id,
      introduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      treating_providers,
      current_treatment_plan,
      life_expectancy,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      rated_ages_text,
      medical_status,
      compensation,
      prognosis,
      additional_comments,
      life_table_id,
    } = this.state;

    let yrly_src = this.state.lifeSources[this.state.source_id];
    let lyfe_table = this.state.lifeTables[life_table_id];

    let life_expectancy_source = yrly_src?.name + '.' + lyfe_table?.name + ' ';
    const currentCompensation = compensation === '' ? EMPTY_TEXT : compensation;
    const currentIntroduction = introduction === '' ? EMPTY_TEXT : introduction;
    const params = {
      life_expectancy_source,
      id: report_id,
      introduction: currentIntroduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      treating_providers,
      current_treatment_plan,
      life_expectancy,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      rated_ages_text,
      medical_status,
      compensation: currentCompensation,
      prognosis,
      additional_comments,
      life_table_id,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        if (this.state.REPORT_PERMISSIONS.edit) res = await API.updateReport(params);
      }

      if (res?.status) {
        notifyInfo('', res.message);
      }

      if (res?.report) this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  isAdmin = () => isAllowed(this.props.roles, ROLES_DIVISIONS.ADMIN);

  handleUpdateTextArea = (type, value) => {
    var duration = 700;
    this.setState({
      [type]: value,
    });
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(this.handleUpdateReport, duration);
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  goToEditClaimant = () => {
    const claimant = this.props.reportInfo.claimant;
    this.props.history.push(CLAIMANT_EDIT.path, { data: claimant });
  };

  handleChangeLife = ({ life, value }) => {
    if (life === LIFE_TYPE.SOURCE) {
      return this.setState({ source_id: value });
    }

    this.setState({ life_table_id: value });
  };

  render() {
    let dataDynamic = { ...dynamicData() };
    const {
      introduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      current_treatment_plan,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      life_expectancy,
      lifeSources,
      lifeTables,
      loading,
      loadingTitle,
      medical_status,
      rated_ages_text,
      treatingList,
      report_claim_id,
      is_template,
      compensation,
      prognosis,
      additional_comments,
      REPORT_PERMISSIONS,
      source_id,
      life_table_id,
    } = this.state;
    const currentCompensation = compensation === EMPTY_TEXT ? '' : compensation;
    const currentIntroduction = introduction === EMPTY_TEXT ? '' : introduction;

    const { roles = {} } = this.props;

    return (
      <Row className="msa-medical-content-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup {REPORT_MCP_MSA_COMBO_NAME} {this.state.is_template ? 'Template' : 'Report'}
            </Title>
          </Col>
          <Col className="step">
            <DropDown beforeNext={async () => await this.handleUpdateReport(false)} loading={loading} />
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <ClaimantInfo
                    goToEditClaimant={REPORT_PERMISSIONS.edit && this.goToEditClaimant}
                    claimant={{
                      ...this.props.reportInfo.claimant,
                      report_claim_id,
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text className="title">Medical Content</Text>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Introduction"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={currentIntroduction}
                    onChange={value => this.handleUpdateTextArea('introduction', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Records Reviewed"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={medical_records_reviewed}
                    onChange={value => this.handleUpdateTextArea('medical_records_reviewed', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Description of Injury"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={description_of_injury}
                    onChange={value => this.handleUpdateTextArea('description_of_injury', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col sm={24}>
                  <RichTextEditor
                    onChange={value => this.handleUpdateTextArea('medical_summary', value)}
                    value={medical_summary}
                    disabled={!REPORT_PERMISSIONS.edit}
                    label="Medical Summary"
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <TagsInputBox
                    label="Missing Medical Records"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={missing_medical_records}
                    onChange={value => this.handleUpdateTextArea('missing_medical_records', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputBox
                    label="Current Injury Related Conditions"
                    placeholder=" "
                    disabled={true}
                    value={
                      related_diagnosis &&
                      related_diagnosis
                        .split(',')
                        .map(item => {
                          if (item)
                            if (item) {
                              let item2;
                              try {
                                item2 = JSON.parse(item.replace(/</g, ','));
                              } catch (error) {
                                return '';
                              }
                              return item2.code;
                            }
                          return '';
                        })
                        .toString()
                    }
                    onChange={value => this.handleUpdateTextArea('related_diagnosis', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <TagsInputBox
                    label="Pre-existing Conditions"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={pre_existing_conditions}
                    onChange={value => this.handleUpdateTextArea('pre_existing_conditions', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <TagsInputBox
                    label="Surgeries/Procedures related to injury"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={surgeries_procedures}
                    onChange={value => this.handleUpdateTextArea('surgeries_procedures', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    getContactLists={this.getContactLists}
                    contact_type_id={dataDynamic.treating_providers_id}
                    another={true}
                    disabled={!REPORT_PERMISSIONS.edit}
                    label="Treating Providers"
                    report_id={this.state.report_id}
                    contactsList={treatingList}
                    type={'treating_providers'}
                    value={this.state.report_contacts
                      .filter(item => item.type === 'treating_providers')
                      .map(item => item.contact.id)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Current Treatment Plan"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={current_treatment_plan}
                    onChange={value => this.handleUpdateTextArea('current_treatment_plan', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Current Medical Status"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={medical_status}
                    onChange={value => this.handleUpdateTextArea('medical_status', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col sm={24}>
                  <RichTextEditor
                    onChange={value => this.handleUpdateTextArea('projected_treatment_plan', value)}
                    value={projected_treatment_plan}
                    disabled={!REPORT_PERMISSIONS.edit}
                    label="Projected Treatment Plan / Projected Future Medical Care"
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Prognosis"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={prognosis}
                    onChange={value => this.handleUpdateTextArea('prognosis', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Comments"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={additional_comments}
                    onChange={value => this.handleUpdateTextArea('additional_comments', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    disabled={!REPORT_PERMISSIONS.edit}
                    label="Social Security Disability Status"
                    placeholder="Please enter"
                    value={social_security_disability_status}
                    onChange={value => this.handleUpdateTextArea('social_security_disability_status', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Medicare Status"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={medicare_status}
                    onChange={value => this.handleUpdateTextArea('medicare_status', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Medicare Lien Information"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={medicare_lien_status}
                    onChange={value => this.handleUpdateTextArea('medicare_lien_status', value)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <InputBox
                    label="Medicare Allocation Administration"
                    disabled={!REPORT_PERMISSIONS.edit}
                    placeholder="Please enter"
                    value={medicare_allocation_administration}
                    onChange={value =>
                      this.setState({
                        medicare_allocation_administration: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <LifeSourceAndLifeTable
                source_id={source_id}
                lifeSources={lifeSources}
                life_table_id={life_table_id}
                lifeTables={lifeTables}
                onChange={this.handleChangeLife}
              />
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <InputBox label="Life Expectancy" disabled={true} placeholder=" " value={life_expectancy} />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <Row>
                <Col md={24}>
                  {roles && roles.user_roles && this.isAdmin() ? (
                    <TextAreaBox
                      rows="5"
                      label="Compensation"
                      disabled={!REPORT_PERMISSIONS.edit}
                      placeholder="Please enter"
                      value={currentCompensation}
                      onChange={value => this.handleUpdateTextArea('compensation', value)}
                    />
                  ) : (
                    currentCompensation
                  )}
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <Row>
                <Col md={24}>
                  <TextAreaBox
                    label="Rated Age"
                    placeholder="Please enter"
                    disabled={!REPORT_PERMISSIONS.edit}
                    value={rated_ages_text}
                    onChange={value => this.handleUpdateTextArea('rated_ages_text', value)}
                  />
                </Col>
              </Row>
              {(is_template || this.state.report_template_id) && (
                <TemplateFields
                  is_template={is_template}
                  section_id={2}
                  report_template_id={this.state.report_template_id}
                  report_id={this.state.report_id}
                />
              )}
              <Divider className="divider" />
              <PrevNextStep
                saveDraft={REPORT_PERMISSIONS.saveDraft}
                beforeNext={async () => await this.handleUpdateReport(true)}
              />
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
};

export default connect(mapStateToProps, { updateReportInfo })(MedicalContent);
