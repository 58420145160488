import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const ProtectedRoute = ({component: Component, isAuthenticated, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: {from: props.location}
        }}/>
      )
    )}
    />
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, null, null, { pure: false })(ProtectedRoute);
