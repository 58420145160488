export const MAIN_NAV = {
  path: '/app/main',
  key: 'dashboard',
  name: 'Dashboard',
};

export const MARKETING_NAV = {
  path: '/app/marketing',
  key: 'marketing',
  name: 'Marketing Materials/ Sample Reports',
};

export const HELP_NAV = {
  path: '/app/help',
  key: 'help',
  name: 'Help / Tutorials',
};

export const SUPPORT_NAV = {
  path: '/app/support',
  key: 'support',
  name: 'Support',
};

export const SETTINGS_NAV = {
  path: '/app/Settings',
  key: 'Settings',
  name: 'Settings',
};

export const ATTORNEY_CLAIMANT_NAV = {
  path: '/app/AttorneyClaimants',
  key: 'clients',
  name: 'Attorney Claimants',
};

export const ATTORNEY_CLAIMANT_REPORT_NAV = {
  path: `${ATTORNEY_CLAIMANT_NAV.path}/ViewMsaReports`,
  key: 'clients',
  name: 'Attorney Claimants Report',
};

export const CLIENTS_NAV = {
  path: '/app/clients',
  key: 'clients',
  name: 'Clients',
};

export const ATTORNEY_IN_REVIEW_NAV = {
  path: '/app/reviewsAttorney',
  key: 'Reviews',
  name: 'Attorney In Review',
};

export const CLAIMANTS_BY_CLIENT_NAV = ({ id = ':id' } = {}) => {
  return {
    path: `/app/claimants_by_client/${id}`,
    basePath: '/app/claimants_by_client',
    key: 'claimants',
    name: 'My Claimants',
  };
};

export const REPORT_COMPARE_NAV = {
  path: '/app/reportCompare',
  key: 'report_compare',
  name: 'Report Compare',
};

export const CLAIMANT_DETAIL_NAV = {
  path: '/app/claimants/detail',
  key: 'clients',
  name: 'Claimants',
};

export const CLAIMANT_INFO_NAV = {
  path: '/app/claimant',
  key: 'clients',
  name: 'Claimants',
};

export const TEMPLATE_CREATE_STRUCTURE_NAV = {
  path: '/app/template/create/structure',
  key: 'template_create',
  name: 'Template Create',
};

export const TEMPLATE_EDIT_STRUCTURE_NAV = {
  path: '/app/template/structure/edit',
  key: 'template_edit',
  name: 'Template Edit',
};

export const TEMPLATE_CREATE_TABLE_NAV = {
  path: '/app/template/create/table',
  key: 'template_create',
  name: 'Template Create',
};

export const TEMPLATE_EDIT_TABLE_NAV = {
  path: '/app/template/table/edit',
  key: 'template_edit',
  name: 'Template Edit',
};

export const TEMPLATE_CREATE_COLOR_NAV = {
  path: '/app/template/create/color',
  key: 'template_create',
  name: 'Template Create Color',
};

export const TEMPLATE_EDIT_COLOR_NAV = {
  path: '/app/template/edit/color',
  key: 'template_edit',
  name: 'Template Edit Color',
};

export const TEMPLATE_NAV = {
  path: '/app/templates',
  key: 'templates',
  name: 'Templates',
};

export const SEARCH_NAV = {
  path: '/app/search',
  key: 'search',
  name: 'Search',
};

export const USER_EDIT_NAV = {
  path: '/app/users/edit',
  key: 'users',
  name: 'Users Edit',
};

export const PARENT_FOLDER_NAV = {
  path: '/app/parentFolder',
  key: 'clients',
  name: 'Parent Folder',
};

export const PARENT_FOLDER_CREATE_NAV = {
  path: `${PARENT_FOLDER_NAV.path}/create`,
  key: 'clients',
  name: 'Create Parent Folder',
};

export const PARENT_FOLDER_EDIT_NAV = ({ idFolder = ':id' } = {}) => {
  return {
    path: `${PARENT_FOLDER_NAV.path}/edit/${idFolder}`,
    key: 'clients',
    name: 'Create Parent Folder',
  };
};

export const ASSIGNMENTBOARD_NAV = {
  path: '/app/Assign',
  key: 'Assign',
  name: 'Assignment Board',
};

export const CLAIMANT_BY_USER_NAV = ({ id = ':id' } = {}) => {
  return {
    path: `/app/claimants_by_user/${id}`,
    basePath: '/app/claimants_by_user',
    key: 'claimants',
    name: 'Claimants',
  };
};

export const LITIGATION_ATTORNEY_NAV = {
  path: '/app/litigation_attorney',
  key: 'litigation-attorney',
  name: 'Litigation Attorneys',
};

export const NEW_LITIGATION_ATTORNEY_NAV = {
  path: '/app/litigation_attorney/create',
  key: 'litigation-attorney',
  name: 'Litigation Attorneys Create',
};

export const TENANT_CREATE = {
  path: '/add_tenant',
  key: 'add_tenant',
  name: 'Add Tenant',
};

export const CLAIMANT_EDIT = {
  path: '/app/claimants/edit',
};
