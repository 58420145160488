import React, { useState, useEffect, useRef } from 'react';
import DatePickerSimple from 'react-datepicker';
import moment from 'moment';
import { Row, Col, Button, Input, Select, Typography, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { PRIMARY_LIGHT_BLUE } from '../../../lib/utils/colors';
import SettingWrapper from '../../../components/common/SettingWrapper';
import CustomButton from '../../../components/common/CustomButton';
import TextButton from '../../../components/common/TextButton';
import messages from './messages';
import modeMessages from '../../pages/AssignmentPage/messages';
import * as API from 'utils/api';
import { notifyApiError } from 'utils/notification';
import ResetImg from '../../../assets/icon/reset.png';

import '../../../react-calendar-custom.scss';

const { Text } = Typography;

const FilterSetting = props => {
  const { Option } = Select;

  const { changeFilters, initFilters, initLabels, setShowFilterSetting, closeSetting, resetFilters } = props;

  const noteInputRef = useRef(null);

  const [allAssignmentStatus, setAllassignmentStatus] = useState([]);
  const [assignmentTypes, setAssignmentTypes] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [allClaimants, setAllClaimants] = useState([]);
  const [filters, setFilters] = useState(initFilters || {});
  const [labels, setLabels] = useState(initLabels || {});

  useEffect(() => {
    API.GetAssignementsStatuses().then(res => {
      const statusArr = Object.keys(res.statuses).map(key => {
        return { id: key, status_name: res.statuses[key] };
      });
      setAllassignmentStatus(statusArr);
    });

    API.getAllAssignmentsType().then(res => {
      setAssignmentTypes(res.assignment_types);
    });

    API.getClientsData().then(res => {
      setAllClients(res.clients);
    });
  }, []);

  useEffect(() => {
    const loadAssignees = async () => {
      try {
        const { users } = await API.getUsersByAssignmentTypeId(filters.assignment_type_id);

        setAllUsers(users);
      } catch (e) {
        notifyApiError(e);
      }
    };

    if (filters.assignment_type_id) {
      loadAssignees();
    }
  }, [filters]);

  useEffect(() => {
    const loadClaimants = async () => {
      try {
        const { claimants } = await API.GetClaimantByClientId(filters.client_id);

        setAllClaimants(claimants);
      } catch (e) {
        notifyApiError(e);
      }
    };

    if (filters.client_id) {
      loadClaimants();
    }
  }, [filters]);

  const onChangeStartDate = dateString => {
    const newDate = dateString ? moment(dateString) : null;
    setFilters(prev => ({
      ...prev,
      start_date: newDate,
      end_date: filters.end_date ? (newDate?.isAfter(filters.end_date) ? newDate : filters.end_date) : null,
    }));
    noteInputRef.current.focus();
  };

  const applyFilters = () => {
    if (filters.start_date || filters.end_date) props.onSetMode(modeMessages.table);
    Object.values(filters).some(value => !!value) ? changeFilters(filters, labels) : resetFilters();
    setShowFilterSetting(false);
  };

  return (
    <div>
      <SettingWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          <TextButton
            color={PRIMARY_LIGHT_BLUE}
            onClick={() => {
              noteInputRef.current.focus();
              setFilters({});
            }}>
            <img
              src={ResetImg}
              alt="reset-icon"
              style={{
                width: '20px',
                marginRight: '6px',
                transform: 'scaleX(-1)',
              }}
            />
            <Text level={4} style={{ color: PRIMARY_LIGHT_BLUE }}>
              {messages.reset}
            </Text>
          </TextButton>
          <div style={{ fontWeight: '600' }}> {messages.filterSettingHeaderTitle}</div>
          <Button type="text" onClick={() => closeSetting()}>
            <span>X</span>
          </Button>
        </div>
        <Row>
          <Col span={24} style={{ borderTop: '1px solid #e0dcdc' }}></Col>
        </Row>
        <Form style={{ padding: '20px 30px' }} className="filter-wrapper" onSubmit={applyFilters}>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <Text level={4} type="secondary">
                {messages.searchLabel}
              </Text>
            </Col>
            <Col span={24}>
              <Input
                ref={noteInputRef}
                placeholder="Please enter"
                prefix={<SearchOutlined />}
                style={{ width: '100%' }}
                value={filters.note}
                onChange={({ target }) => {
                  setFilters(prev => ({ ...prev, note: target.value }));
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <Text level={4} type="secondary">
                Assignment Type
              </Text>
            </Col>
            <Col md={24}>
              <Select
                placeholder="Select Assignment Type"
                style={{ width: '100%' }}
                value={filters.assignment_type_id}
                onChange={(id, option) => {
                  noteInputRef.current.focus();
                  setLabels(prev => ({ ...prev, assigmentType: option.props.children || '' }));
                  setFilters(prev => ({ ...prev, assignment_type_id: id, user_id: null }));
                }}>
                <Option value={null}>None</Option>
                {assignmentTypes && assignmentTypes.length > 0 ? (
                  assignmentTypes.map((assignment, index) => (
                    <Option value={assignment.id} key={`${index}-${assignment.assignment_type_id}`}>
                      {assignment.type_information}
                    </Option>
                  ))
                ) : (
                  <Option value={filters.assignment_type_id}>{labels.assigmentType}</Option>
                )}
              </Select>
            </Col>
          </Row>

          <Row gutter={[16, 12]}>
            <Col span={12}>
              <Row gutter={[12, 8]}>
                <Col span={24}>
                  <Text level={4} type="secondary">
                    {messages.assigneeLabel}
                  </Text>
                </Col>

                <Col span={24}>
                  <Select
                    showSearch
                    disabled={!filters.assignment_type_id}
                    placeholder="Select User"
                    optionFilterProp="label"
                    value={filters.user_id}
                    style={{ width: '100%' }}
                    onChange={(id, option) => {
                      noteInputRef.current.focus();
                      setFilters(prev => ({ ...prev, user_id: id }));
                      setLabels(prev => ({ ...prev, user: option.props.children || '' }));
                    }}>
                    <Option value={null}>None</Option>
                    {allUsers && allUsers.length > 0 ? (
                      allUsers.map(assignee => {
                        const optionLabel = assignee.given_name + ' ' + assignee.family_name;

                        return (
                          <Option key={assignee.id} value={assignee.id} label={optionLabel}>
                            {optionLabel}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value={filters.user_id}>{labels.user}</Option>
                    )}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[12, 8]}>
                <Col span={24}>
                  <Text level={4} type="secondary">
                    {messages.assignmentLabel}
                  </Text>
                </Col>
                <Col span={24}>
                  <Select
                    showSearch
                    placeholder="Select Status"
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    value={filters.status}
                    onChange={(value, option) => {
                      noteInputRef.current.focus();
                      setFilters(prev => ({ ...prev, status: value }));
                      setLabels(prev => ({ ...prev, status: option.props.children || '' }));
                    }}>
                    <Option value={null}>None</Option>
                    {allAssignmentStatus && allAssignmentStatus.length > 0 ? (
                      allAssignmentStatus.map((status, index) => {
                        return (
                          <Option key={`status-${index}`} value={status.id}>
                            {status.status_name}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value={filters.status}>{labels.status}</Option>
                    )}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 12]}>
            <Col span={12}>
              <Row gutter={[12, 8]}>
                <Col span={24}>
                  <Text level={4} type="secondary">
                    {messages.clientLabel}
                  </Text>
                </Col>
                <Col span={24}>
                  <Select
                    showSearch
                    placeholder="Select Client"
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    value={filters.client_id}
                    onChange={(id, option) => {
                      noteInputRef.current.focus();
                      setFilters(prev => ({ ...prev, client_id: id, claimant_id: null }));
                      setLabels(prev => ({ ...prev, client: option.props.children || '' }));
                    }}>
                    <Option value={null}>None</Option>
                    {allClients && allClients.length > 0 ? (
                      allClients.map((client, index) => {
                        return (
                          <Option key={`client-${index}`} value={client.id}>
                            {client.client_name}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value={filters.client_id}>{labels.client}</Option>
                    )}
                  </Select>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[12, 8]}>
                <Col span={24}>
                  <Text level={4} type="secondary">
                    {messages.claimantLabel}
                  </Text>
                </Col>
                <Col span={24}>
                  <Select
                    showSearch
                    placeholder="Select Claimant"
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    value={filters.claimant_id}
                    onChange={(id, option) => {
                      noteInputRef.current.focus();
                      setFilters(prev => ({ ...prev, claimant_id: id }));
                      setLabels(prev => ({ ...prev, claimant: option.props.children || '' }));
                    }}>
                    <Option value={null}>None</Option>
                    {allClaimants && allClaimants.length > 0 ? (
                      allClaimants.map((claimant, id) => {
                        return (
                          <Option key={`claiments-${id}`} value={claimant.id}>
                            {claimant.claimant_name +
                              ' ' +
                              claimant.claimant_middle_name +
                              ' ' +
                              claimant.claimant_last_name}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value={filters.claimant_id}>{labels.claimant}</Option>
                    )}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 12]}>
            <Col span={12}>
              <Row gutter={[12, 8]}>
                <Col span={24}>
                  <Text level={4} type="secondary">
                    {messages.dateFrom}
                  </Text>
                </Col>
                <Col span={24}>
                  <DatePickerSimple
                    onChange={onChangeStartDate}
                    style={{ width: '100%' }}
                    selected={filters.start_date?.toDate()}
                    minDate={new Date('2020-01-01')}
                    todayButton="Today"
                    showMonthDropdown
                    showYearDropdown
                    placeholderText="Select Start Date"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[12, 8]}>
                <Col span={24}>
                  <Text level={4} type="secondary">
                    {messages.dateTo}
                  </Text>
                </Col>
                <Col span={24}>
                  <DatePickerSimple
                    onChange={dateString => {
                      setFilters(prev => ({
                        ...prev,
                        end_date: dateString ? moment(dateString) : null,
                      }));
                      noteInputRef.current.focus();
                    }}
                    style={{ width: '100%' }}
                    selected={filters.end_date?.toDate()}
                    minDate={filters.start_date?.toDate() || new Date()}
                    todayButton="Today"
                    showMonthDropdown
                    showYearDropdown
                    placeholderText="Select End Date"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center">
            <CustomButton
              backgroundcolor={PRIMARY_LIGHT_BLUE}
              color="#ffffff"
              size="large"
              htmlType="submit"
              style={{ zIndex: 10, marginTop: '15px' }}>
              Apply
            </CustomButton>
          </Row>
        </Form>
      </SettingWrapper>
    </div>
  );
};

export default FilterSetting;
