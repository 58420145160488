import * as React from "react"
import { BLUE_ICON } from "containers/Calendar/src/src/lib/utils/colors"

const PencilIcon = ({ color = BLUE_ICON, onClick, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 18 18"
    onClick={onClick}
    className={className || ''}
  >
    <defs>
      <path id="a" d="M0 0h18v18H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        stroke={color}
        strokeWidth={1.2}
        d="M16.151 5.444 12.556 1.85 1.6 12.804V16.4h3.596L16.15 5.444zM2.058 12.467c1.3 1.272 2.47 2.448 3.51 3.528M9.896 4.669l3.488 3.497"
        mask="url(#b)"
      />
    </g>
  </svg>
)

export default PencilIcon
