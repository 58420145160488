import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Input, Empty, Avatar, Button } from 'antd';
import { connect } from 'react-redux';

import SpinBox from 'components/SpinBox';
import DeleteModal from 'components/DeleteModal';
import Pagination from 'components/Pagination';
import { EditIcon, RemoveIcon, DetailsIcon } from 'components/Icons';
import * as API from 'utils/api';
import { notifyApiError, notifyInfo } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { ATTORNEY_CLAIMANT_NAV, PARENT_FOLDER_NAV } from 'layout/navigate_const';
import { Auth } from 'aws-amplify';
import { getPermissionsBySection, CLIENT_PERMISSIONS_NAME, PARENT_FOLDER_NAME } from 'permissions';
import { BackIcon } from 'components/Icons';

import './MyClients.scss';

const { Search } = Input;
const { Title, Text } = Typography;

class MyClients extends Component {
  constructor(props) {
    super();

    // Permissions
    const { CLIENT_PERMISSIONS, PARENT_FOLDER_PERMISSIONS } = getPermissionsBySection({
      roles: props.roles,
      permissions: [CLIENT_PERMISSIONS_NAME, PARENT_FOLDER_NAME],
    });
    // end permissions
    this.state = {
      pageIndex: 0,
      showModal: false,
      clientsData: [],
      totalCount: 0,
      querySearched: false,
      selectedClient: {},
      searchQuery: '',
      loadingTitle: 'Loading...',
      loading: false,
      parentFolderId: props.match.params.parentFolderId,
      parentFolderName: '',
      CLIENT_PERMISSIONS,
      PARENT_FOLDER_PERMISSIONS,
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handlePagination = pageIndex => {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOpenModal = item => {
    this.setState({
      showModal: true,
      selectedClient: item,
    });
  };

  handleEditClient = item => {
    this.props.history.push('/app/clients/edit', { data: item });
  };

  goToClaimantsPage = item => {
    const path = isAllowed(this.props.roles, ROLES_DIVISIONS.ATTORNEY_ONLY)
      ? ATTORNEY_CLAIMANT_NAV.path
      : '/app/claimants';
    this.props.history.push(path, { data: item });
  };

  handleDeleteClient = async item => {
    const params = {
      id: item.id,
    };

    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: 'Deleting...',
    });

    try {
      const isUserAdmin = isAllowed(this.props.roles, [
        ...ROLES_DIVISIONS.SUPERADMIN,
        ...ROLES_DIVISIONS.SALES_MANAGER,
      ]);
      const isUserManagement = isAllowed(this.props.roles, ROLES_DIVISIONS.PURE_MANAGEMENT);

      const apiParams = isUserManagement && !isUserAdmin ? { tenantOnly: true, ...params } : params;

      await API.deleteClient(apiParams);

      notifyInfo('Client was deleted');

      this.setState({
        clientsData: this.state.clientsData.filter(client => client.id !== params.id),
        totalCount: this.state.totalCount - 1,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSearch = async () => {
    const { searchQuery, pageIndex, querySearched, CLIENT_PERMISSIONS } = this.state;
    this.setState({ loading: true, loadingTitle: 'Loading...' });

    let index = pageIndex;

    try {
      let res;

      if (this.state.parentFolderId) {
        const info = await API.getParentFolderById({ id: this.state.parentFolderId, model: 'client' });
        res = {
          clients: info.clients,
          total_count: info.count,
          parentFolderName: info.name,
        };
      } else {
        if (CLIENT_PERMISSIONS.searchOnlyMe) {
          const getCurrentUser = await Auth.currentAuthenticatedUser();
          const userId = getCurrentUser.attributes.sub;
          res = await API.getClientsDataByUserId({ userId, searchQuery, pageIndex: index });
        } else {
          res = await API.getClientsData(searchQuery, index, 10, false);
        }
      }
      this.setState({
        clientsData: res.clients,
        totalCount: res.total_count,
        parentFolderName: res.parentFolderName,
      });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  isActionDisplay = () => {
    return (
      isAllowed(this.props.roles, ROLES_DIVISIONS.MANAGEMENT) ||
      isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT)
    );
  };

  render() {
    const {
      pageIndex,
      totalCount,
      showModal,
      selectedClient,
      clientsData,
      searchQuery,
      loadingTitle,
      loading,
      PARENT_FOLDER_PERMISSIONS,
      CLIENT_PERMISSIONS,
      parentFolderName,
    } = this.state;

    return (
      <Row className="clients-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              {parentFolderName ? parentFolderName : 'My Clients'}
            </Title>
          </Col>
          <Col className="search searchAndBack">
            {PARENT_FOLDER_PERMISSIONS.open && (
              <div className="back" onClick={() => this.props.history.push(PARENT_FOLDER_NAV.path)}>
                <BackIcon className="icon" />
              </div>
            )}
            <Search
              placeholder="Search..."
              value={searchQuery}
              onChange={e => this.setState({ searchQuery: e.target.value, pageIndex: 0 })}
              onSearch={this.handleSearch}
              className="search-box"
            />
          </Col>
          <Col className="pagination">
            <Pagination onChange={this.handlePagination} totalCount={totalCount} pageIndex={pageIndex} pageSize={10} />
          </Col>
          <Col className="add-button">
            {CLIENT_PERMISSIONS.create && (
              <Button
                type="primary"
                className="green-btn"
                onClick={() => this.props.history.push(`/app/clients/${this.state.parentFolderId}/add`)}>
                Add New Client
              </Button>
            )}
            {CLIENT_PERMISSIONS.mergeClient && (
              <Button
                type="primary"
                className="purple-btn ml-2"
                onClick={() => this.props.history.push('/app/clients/merge')}>
                Merge Client
              </Button>
            )}
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={7}>Client</Col>
          <Col md={7}>Date Added</Col>
          <Col md={5}>Claimants</Col>
          {this.isActionDisplay() && (
            <Col md={5} style={{ textAlign: 'right', paddingRight: '30px' }}>
              Actions
            </Col>
          )}
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {clientsData.length > 0 ? (
              <Row data-test-id="table-clients">
                {clientsData.map(item => (
                  <Row key={item.id} className="record">
                    <Col md={7}>
                      <Col
                        className="client-info"
                        onClick={() => this.goToClaimantsPage(item)}
                        data-test-id="clientTableItem_client">
                        <Avatar size={48} src={item.client_logo} />
                        <Text className="client-name">{item.client_name}</Text>
                      </Col>
                    </Col>
                    <Col md={7}>
                      <Text>{moment(item.createdAt).format('MMMM DD, YYYY')}</Text>
                    </Col>
                    <Col md={5}>
                      <Text>{item.claimant_count}</Text>
                    </Col>
                    <Col md={5} className="actions">
                      <DetailsIcon className="icon" onClick={() => this.goToClaimantsPage(item)} />
                      {CLIENT_PERMISSIONS.edit && (
                        <EditIcon className="icon" onClick={() => this.handleEditClient(item)} />
                      )}
                      {CLIENT_PERMISSIONS.delete && (
                        <RemoveIcon className="icon" onClick={() => this.handleOpenModal(item)} />
                      )}
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                description={loading ? false : 'No Data'}
                image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
        <DeleteModal
          isOpen={showModal}
          title="Delete this Client?"
          avatar={selectedClient.client_logo}
          content={selectedClient.client_name}
          onConfirm={() => this.handleDeleteClient(selectedClient)}
          onCancel={this.handleCloseModal}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps, null)(MyClients);
