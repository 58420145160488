import React, { useEffect, useState, forwardRef } from 'react';
import { Row, Typography, Tabs, Input, Col, Modal, Spin } from 'antd';
import DatePicker from 'react-datepicker';
import Medication from './Medication';
import * as API from 'utils/api';
import './Search.scss';
import { useSelector } from 'react-redux';
import { notifyApiError } from '../../utils/notification';
import Pagination from '../../components/Pagination';
import { CalendarTwoTone } from '@ant-design/icons';
import { BLUE_ICON } from 'containers/Calendar/src/src/lib/utils/colors';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;

export const TAB_MEDICATION = '1';
export const TAB_REPORT = '2';

export default function SearchReport() {
  const { limit } = useSelector(({ application }) => application.settings);

  const [selectedTab, setSelectedTab] = useState('1');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [reportPage, setReportPage] = useState(0);
  const [medicationData, setMedicationData] = useState({ rows: [], count: 0 });
  const [reportData, setReportData] = useState({ rows: [], count: 0 });
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingInternal, setLoadingInternal] = useState(false);

  useEffect(() => {
    handleSearchReport();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async (page_selected = 0) => {
    setPage(page_selected);
    setReportPage(page_selected);
    setLoading(true);

    let search_text = searchQuery.toLowerCase();

    try {
      if (selectedTab === TAB_MEDICATION) {
        if (!search_text.trim()) {
          setMedicationData({ rows: [], count: 0 });
        } else {
          const res = await API.searchMedication({ search: search_text, page: page_selected, limit });
          setMedicationData(res.count ? res : { rows: [], count: 0 });
        }
      }
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = async p => {
    setPage(p);
  };

  const handleSearchReport = async ({ dateStart, dateEnd, pageParams } = {}) => {
    try {
      setLoadingInternal(true);

      const start = dateStart || startDate || '';
      const end = dateEnd || endDate || '';
      const search = searchQuery.toLowerCase();
      const page = pageParams ?? reportPage ?? 0;

      if (start) start.setHours(0, 0, 0);
      if (end) end.setHours(23, 59, 59);

      const res = await API.searchReportByDate({ start, end, search, page, limit });

      let updateList = [];

      if (res.count > 0) {
        updateList = res.rows
          .map(rep => {
            return {
              report_name: rep.report_name,
              claimantName: `${rep['claimant.claimant_name']} ${rep['claimant.claimant_last_name']}`,
              clientName: rep['claimant.client.client_name'],
            };
          })
          .reverse();
      }

      setReportPage(0);
      setReportData({ rows: updateList, count: res.count });
      setStartDate(start);
      setEndDate(end);
      setReportPage(page);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingInternal(false);
    }
  };

  const CalendarCustomInput = forwardRef(({ onClick, onChange }, ref) => (
    <Input
      prefix={<CalendarTwoTone twoToneColor={BLUE_ICON} style={{ fontSize: '17px' }} />}
      defaultValue={`${startDate ? new Intl.DateTimeFormat('en').format(startDate) : ''} ${endDate ? '-' : ''} ${
        endDate ? new Intl.DateTimeFormat('en').format(endDate) : ''
      }`}
      onClick={onClick}
      onBlur={onChange}
      onChange={onChange}
      ref={ref}
      placeholder="Select a date range"
    />
  ));

  return (
    <Row className="search-container">
      {loadingInternal && (
        <Modal visible={loadingInternal} footer={null} className="modal-loader">
          <Spin />
        </Modal>
      )}
      <Row type="flex" align="middle" justify="center" className="header">
        <Title level={4} className="title">
          Search
        </Title>
      </Row>
      <Row className="main">
        <Row className="search-content">
          <Row type="flex" align="middle" justify="space-between" className="main-info">
            {selectedTab === TAB_MEDICATION && (
              <>
                <Search
                  placeholder="Search..."
                  className="search-field"
                  onChange={e => setSearchQuery(e.target.value)}
                  onSearch={() => handleSearch(0)}
                />
                {medicationData.rows.length > 0 && (
                  <div className="pagination-wrap">
                    <Pagination
                      pageIndex={page}
                      pageSize={limit}
                      totalCount={medicationData.count}
                      onChange={onChangePage}
                    />
                  </div>
                )}
              </>
            )}

            {selectedTab === TAB_REPORT && (
              <Row
                type="flex"
                justify="space-between"
                style={{ width: '100%' }}
                className="filter-wrapper"
                gutter={[16, 16]}>
                <Col md={6}>
                  <Search
                    style={{ width: '100%' }}
                    placeholder="Search..."
                    className="search-field"
                    onChange={e => setSearchQuery(e.target.value)}
                    onSearch={() => handleSearchReport()}
                  />
                </Col>
                <Col md={12}>
                  <div className="dates-wrap">
                    <Text strong className="filter-label">
                      Filter by date range
                    </Text>
                    <DatePicker
                      selected={startDate}
                      onChange={dates => handleSearchReport({ dateStart: dates[0], dateEnd: dates[1] })}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      customInput={<CalendarCustomInput />}
                      shouldCloseOnSelect={false}
                    />
                  </div>
                </Col>
                <Col md={6} className="pagination-wrap">
                  {reportData.rows.length > 0 && (
                    <Pagination
                      pageIndex={reportPage}
                      pageSize={limit}
                      totalCount={reportData.count}
                      onChange={page => handleSearchReport({ pageParams: page })}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Row>
          <Tabs className="tab" activeKey={selectedTab} onChange={value => setSelectedTab(value)}>
            <TabPane tab="Medication" key={TAB_MEDICATION}>
              <Medication
                searchQuery={searchQuery}
                medicationData={medicationData.rows}
                selectedTab={selectedTab}
                loading={loading}
                reportData={reportData}
              />
            </TabPane>
            <TabPane tab="Report" key={TAB_REPORT}>
              <Medication
                searchQuery={searchQuery}
                medicationData={medicationData.rows}
                reportData={reportData.rows}
                selectedTab={selectedTab}
                loading={loading}
              />
            </TabPane>
          </Tabs>
        </Row>
      </Row>
    </Row>
  );
}
