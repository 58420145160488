import React, { Component } from 'react';
import { Row, Col, Typography, Button, Skeleton, Modal, Spin } from 'antd';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';

import InputBox from 'components/InputBox';
import { BackIcon } from 'components/Icons';
import * as API from 'utils/api';
import UserRole from 'components/UserRole/Index';
import { notifyApiError, notifyError, notifyInfo } from 'utils/notification';
import { validateEmail, validatePhone, validateName } from 'utils/utils';
import isAllowed, {
  ROLES_DIVISIONS,
  CLIENT_USER_ID,
  CLIENT_ADMIN_ID,
  MEDICAL_REVIEWER_ID,
  SALES_REP_ID,
  SALES_MANAGER_ID,
  CLIENT_SUPER_ADMIN_ID,
  EXTERNAL_LITIGATION_ATTORNEY_ID,
  NURSE_WRITER_ID,
} from 'layout/roles';
import { getClientId } from 'layout/roles';

import './AddUser.scss';
import { UploadFileCVUser } from 'utils/upload';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { LIMIT_REVIEWER_10, LIMIT_REVIEWER_15, LIMIT_REVIEWER_5 } from 'constants/contact-types';
import { CUSTOM_PACKAGE_ID } from 'containers/Tenant/tenantCreate/components/StepPackageTier/components/PlanCard/planList';

const { Title, Text } = Typography;

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      given_name: '',
      family_name: '',
      email: '',
      phone: '',
      loadingTitle: 'Loading...',
      loadingImg: false,
      loading: false,
      roleValue: [],
      imageUrl: '',
      clients: [],
      salesReps: [],
      salesManager: [],
      loadingInternal: false,
      defaultSalesRep: this.props.roles?.user_roles?.roles[0]?.user_roles?.sales_rep_id || null,
      defaultClient: this.props.roles?.user_roles?.roles[0]?.user_roles?.client_id || null,
      cv: '',
      visibility: true,
      modalConfirmExtraPay: false,
      modalMaxExternal: false,
      totalMaxExternal: {},
      modalMaxCreateMedialReviewer: false,
    };

    this.handleSetValue = this.handleSetValue.bind(this);
    this.verifyClientsPermissions = this.verifyClientsPermissions.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleSelectRole = this.handleSelectRole.bind(this);
    this.handleFindUsers = this.handleFindUsers.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleChangeVisibility = this.handleChangeVisibility.bind(this);
    this.handleUpMaxMedical = this.handleUpMaxMedical.bind(this);
  }

  handleSetValue(type, value) {
    this.setState({
      [type]: value,
    });
  }

  verifyClientsPermissions() {
    const { roles } = this.props;

    const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserManager = isAllowed(roles, ROLES_DIVISIONS.MANAGER);

    return isUserManager && !isUserAdmin;
  }

  async loadClients() {
    const tenantOnly = this.verifyClientsPermissions();

    try {
      const res = await API.getClientsData('', 0, 1000, tenantOnly);

      this.setState({ clients: res.clients });
    } catch (e) {
      notifyApiError(e);
    }
  }

  async handleCreateUser({ openModalMedical = true } = {}) {
    const {
      given_name,
      family_name,
      email,
      phone,
      roleValue,
      clients,
      specialty,
      sales_rep_id,
      sales_manager_id,
      defaultSalesRep,
      cv,
      visibility,
    } = this.state;
    let { client_id } = this.state;
    const isClientAdmin = isAllowed(this.props.roles, [
      ...ROLES_DIVISIONS.CLIENT_ADMIN,
      ...ROLES_DIVISIONS.CLIENT_SUPER_ADMIN,
    ]);

    if (isClientAdmin) client_id = getClientId(this.props.roles);

    this.setState({ loadingTitle: 'Saving...', loadingInternal: true });

    if (!roleValue.length) {
      this.setState({ loadingInternal: false });
      return notifyApiError('Please choose at least one role');
    }

    if (roleValue.find(role => role === CLIENT_USER_ID) && !client_id) {
      this.setState({ loadingInternal: false });
      return notifyApiError('Please choose a client');
    }

    // if the selected role is medical reviewer and there is no specialty, we return an error
    if (roleValue.find(role => role === MEDICAL_REVIEWER_ID) && !specialty) {
      this.setState({ loadingInternal: false });
      return notifyApiError('Please choose a specialty');
    }

    const param = {
      email,
      given_name,
      family_name,
      locale: phone,
      restrictions: 'restrictions',
      role_id: roleValue[0],
    };

    // there is specialty and select role is medical reviewer, save specialty
    if (roleValue.find(role => role === MEDICAL_REVIEWER_ID) && specialty) {
      param.specialty = specialty;
    }

    if (clients.length && client_id) {
      const pickedClient = clients.find(client => client.id === client_id);

      param.tenant_id = pickedClient.tenant_id;
    }

    let res;

    try {
      // we search the package
      const tenant_id = this.props.userInfo.attributes['custom:tenant_id'];
      const tenant = await API.getTenantById({ id: tenant_id });

      //  si se quiere crear un medical reviewer validamos la cantidad
      if (param.role_id === MEDICAL_REVIEWER_ID && tenant.tenant.package_id !== CUSTOM_PACKAGE_ID) {
        const resMedicalReviewer = await API.getUsersDataSynchronised({ filterType: MEDICAL_REVIEWER_ID });

        if (resMedicalReviewer?.total_count === 0) {
          this.setState({
            modalMaxExternal: openModalMedical,
            totalMaxExternal: { count: '1 of 5', price: LIMIT_REVIEWER_5.price, max: LIMIT_REVIEWER_5.limit },
          });
          if (openModalMedical) return;
        } else if (resMedicalReviewer?.total_count === 5) {
          this.setState({
            totalMaxExternal: { count: '6 of 10', price: LIMIT_REVIEWER_10.price, max: LIMIT_REVIEWER_10.limit },
          });
        } else if (resMedicalReviewer?.total_count === 10) {
          this.setState({
            totalMaxExternal: { count: '11 of 15', price: LIMIT_REVIEWER_15.price, max: LIMIT_REVIEWER_15.limit },
          });
        } else if (resMedicalReviewer?.total_count >= 15) {
          return this.setState({ modalMaxCreateMedialReviewer: true, loadingInternal: false });
        }
      }

      res = await API.createUser(param);
    } catch (e) {
      if (e?.response?.data?.reason?.[0]?.message) {
        if (e.response.data.reason[0].message.includes('An account with the given email already exists.')) {
          this.setState({ loadingInternal: false });
          return notifyError(e?.response?.data?.reason?.[0]?.message);
        }

        if (e.response.data.reason[0].message.includes('reviewer users max capacity has been exceeded')) {
          return this.setState({ modalMaxExternal: true, loadingInternal: false });
        }
      }

      if (param?.role_id && param.role_id === NURSE_WRITER_ID) {
        return this.setState({ modalConfirmExtraPay: true, loadingInternal: false });
      }
    }

    try {
      const rolesToAppend = roleValue.map(role_id => {
        const assigedRoleToUser = {
          user_id: res.data.User.Username,
          role_id,
        };

        if ([CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role_id)) {
          assigedRoleToUser.client_id = client_id;

          // si es rol seleccionado es client y hay sales rep lo agregamos
          if (
            [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role_id) &&
            (sales_rep_id || defaultSalesRep)
          ) {
            assigedRoleToUser.sales_rep_id = sales_rep_id || defaultSalesRep;
          }
        }

        if (SALES_REP_ID === role_id) assigedRoleToUser.sales_manager_id = sales_manager_id;

        if (EXTERNAL_LITIGATION_ATTORNEY_ID === role_id) {
          if (client_id) {
            assigedRoleToUser.client_id = client_id;
            assigedRoleToUser.visibility = visibility;
          } else {
            assigedRoleToUser.visibility = true;
          }
          assigedRoleToUser.range = false;
        }

        return assigedRoleToUser;
      });

      try {
        await API.synchroniseUser({ ...param, id: res.data.User.Username });
      } catch (e) {
        notifyApiError(e);
        // if (param?.role_id) {
        //   return this.setState({ modalConfirmExtraPay: true, loadingInternal: false });
        // }
      }

      await API.AppendRoles(rolesToAppend);

      // si eres litigation cargamos tu CV en caso de ser necesario
      if (cv && rolesToAppend.some(role => role.role_id === EXTERNAL_LITIGATION_ATTORNEY_ID)) {
        await UploadFileCVUser({ file: cv, user_id: res.data.User.Username });
      }

      this.props.history.push(isClientAdmin ? '/app/users-client' : '/app/users');
      notifyInfo('User was created');
    } catch (error) {
      this.setState({ loadingInternal: false });

      const errorCondition = error?.response?.data?.reason || '';
      const errorMessage = errorCondition ? errorCondition?.[0]?.message || '' : 'Error';

      notifyApiError(errorMessage);
    }
  }

  async handleFindUsers({ roleId }) {
    try {
      this.setState({ loadingInternal: true });
      const users = await API.getUsersByRoleId({ roleId });

      return users;
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loadingInternal: false });
    }
  }

  async handleSelectRole({ roleValue, client_id, specialty, sales_rep_id, sales_manager_id }) {
    if (
      roleValue.find(role => [CLIENT_USER_ID, CLIENT_ADMIN_ID, CLIENT_SUPER_ADMIN_ID].includes(role)) &&
      this.state.salesReps.length === 0
    ) {
      const userSalesRep = await this.handleFindUsers({ roleId: SALES_REP_ID });
      this.setState({ salesReps: userSalesRep.users });
    }

    if (roleValue.find(role => role === SALES_REP_ID) && this.state.salesManager.length === 0) {
      const userSalesManager = await this.handleFindUsers({ roleId: SALES_MANAGER_ID });
      this.setState({ salesManager: userSalesManager.users });
    }

    this.setState({ roleValue, client_id, specialty, sales_rep_id, sales_manager_id });
  }

  uploadFile({ file }) {
    this.setState({ cv: file });
  }

  handleChangeVisibility({ visibility, client_id }) {
    if (!client_id && !visibility) {
      return notifyInfo('The Firm field cannot be empty');
    }

    this.setState({ visibility });
  }

  async handleUpMaxMedical() {
    try {
      this.setState({ loadingInternal: true });
      const tenant_id = this.props.userInfo.attributes['custom:tenant_id'];
      const tenant = await API.getTenantById({ id: tenant_id });
      await API.updateSubscriptionService({
        tenant_id,
        package_id: tenant.tenant.package_id,
        limit_reviewers: this.state.totalMaxExternal.max,
      });
      await this.handleCreateUser({ openModalMedical: false });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loadingInternal: false });
    }
  }

  render() {
    const {
      given_name,
      family_name,
      email,
      phone,
      loading,
      roleValue,
      clients,
      loadingInternal,
      salesReps,
      salesManager,
      defaultSalesRep,
      defaultClient,
      visibility,
      modalConfirmExtraPay,
      // modalConfirmExtraPayOtherRole,
      modalMaxExternal,
      totalMaxExternal,
      modalMaxCreateMedialReviewer,
      specialty,
    } = this.state;

    const isFormValid =
      given_name &&
      family_name &&
      roleValue.length &&
      validateEmail(email) &&
      validatePhone(phone) &&
      validateName(given_name) &&
      validateName(family_name);

    return (
      <Row className="add-user-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Add New User
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
          </Col>
        </Row>
        <Row className="main">
          <Row className="content">
            {loading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <>
                <Row type="flex" justify="space-between" align="middle" className="main-info">
                  <Col md={11} className="row">
                    <InputBox
                      label="First Name"
                      value={given_name}
                      onChange={value => this.setState({ given_name: value })}
                    />
                  </Col>
                  <Col md={11} className="row">
                    <InputBox
                      label="Last Name"
                      value={family_name}
                      onChange={value => this.setState({ family_name: value })}
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="space-between" align="middle" className="main-info">
                  <Col md={11} className="row">
                    <InputBox label="Email" value={email} onChange={value => this.setState({ email: value })} />
                  </Col>
                  <Col md={11} className="row">
                    <Row className="label">
                      <Text>Phone</Text>
                    </Row>
                    <Row>
                      <InputMask
                        mask="999-999-9999"
                        placeholder="Optional"
                        className="input-ssn"
                        value={phone}
                        onChange={e => this.setState({ phone: e.target.value })}
                      />
                    </Row>
                  </Col>
                </Row>
                <UserRole
                  verifyClientsPermissions={this.verifyClientsPermissions}
                  loadClients={this.loadClients}
                  clients={clients}
                  roleValue={roleValue}
                  salesReps={salesReps}
                  defaultSalesReps={defaultSalesRep}
                  specialtyData={specialty}
                  defaultClient={defaultClient}
                  salesManager={salesManager}
                  uploadFile={this.uploadFile}
                  visibility={visibility}
                  onChangeVisibility={this.handleChangeVisibility}
                  disabledCLient={isAllowed(this.props.roles, [
                    ...ROLES_DIVISIONS.CLIENT_ADMIN,
                    ...ROLES_DIVISIONS.CLIENT_SUPER_ADMIN,
                    ...ROLES_DIVISIONS.CLIENT,
                  ])}
                  OnRoleChange={this.handleSelectRole}
                />
                <Row type="flex" justify="center" className="confirm-button">
                  <Button
                    type="primary"
                    size="large"
                    className="green-btn button"
                    onClick={() => this.handleCreateUser()}
                    disabled={!isFormValid}>
                    Create
                  </Button>
                </Row>
              </>
            )}
          </Row>
        </Row>

        <Modal visible={loadingInternal} footer={null} className="modal-loader">
          <Spin />
        </Modal>

        {modalConfirmExtraPay && (
          <ModalConfirm
            onClose={() => this.setState({ modalConfirmExtraPay: false })}
            title="You have reached the maximum limit of 9 Nurse Writes. Please contact an MSA Tech Admin to resolve this"
            btnSuccess="Close"
            onConfirm={() => this.setState({ modalConfirmExtraPay: false })}
            disabledBtnClose
          />
        )}

        {modalMaxExternal && (
          <ModalConfirm
            onClose={() => this.setState({ modalMaxExternal: false })}
            title={`You are adding ${totalMaxExternal.count} reviewers for an additional cost of $${totalMaxExternal.price} a month. Would you like to continue?`}
            btnCancel="Cancel"
            onConfirm={() => this.handleUpMaxMedical()}
          />
        )}

        {modalMaxCreateMedialReviewer && (
          <ModalConfirm
            onClose={() => this.setState({ modalMaxCreateMedialReviewer: false })}
            title="you have reached the maximum number of medical reviewers."
            disabledBtnClose
            btnSuccess="Ok"
            onConfirm={() => this.setState({ modalMaxCreateMedialReviewer: false })}
          />
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles,
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(AddUser);
