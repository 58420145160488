import * as types from './actionTypes';
import * as API from '../../utils/api';
import { notifyApiError } from '../../utils/notification';
import { fetchReportAssignments } from '../../utils/api';
import {
  GET_ASSIGNMENT_HIGHLIGHTS,
  GET_REPORT_ASSIGNMENTS,
  GET_REPORT_DATA_END,
  GET_REPORT_DATA_REQUEST,
  REPORT_UPDATE_ASSIGNMENT_STATUS,
} from './actionTypes';
import { fetchAssignmentById, updateAssignmentById as apiUpdateAssignment } from '../../utils/api-reviews';

export const onLoadingAction = {
  type: GET_REPORT_DATA_REQUEST,
};

export const offLoadingAction = {
  type: GET_REPORT_DATA_END,
};

export const getReportById = reportId => dispatch => {
  dispatch({
    type: types.GET_REPORT_DATA_REQUEST,
  });
  API.getReportById(reportId)
    .then(res => {
      dispatch({
        type: types.GET_REPORT_DATA_SUCCESS,
        reportInfo: res.report,
      });
    })
    .catch(error => {
      let errorMessage = '';
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.GET_REPORT_DATA_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const updateReportInfo = res => dispatch => {
  dispatch({
    type: types.UPDATE_REPORT_DATA,
    reportInfo: res,
  });
};

export const getReportAssignments = (reportId, claimantId) => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const data = await fetchReportAssignments({ reportId, claimantId });
    dispatch({ type: GET_REPORT_ASSIGNMENTS, payload: data });
  } catch (e) {
    console.error(e);
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction);
  }
};

export const getReportFiles = reportId => async dispatch => {
  dispatch(onLoadingAction);
  try {
    let { report_files } = await API.getReportDocuments(reportId);
    const { report_resource_links } = await API.getResourceLinksByReport(reportId);

    const reportFiles = report_files.filter(
      file => !report_resource_links.find(item => item.resource_link.file_id === file.file_id)
    );

    const linkFiles = report_resource_links.map(link => link.resource_link.file);

    dispatch({ type: types.GET_REPORT_FILES_ALL, payload: report_files });
    dispatch({ type: types.GET_REPORT_RESOURCE_LINKS, payload: linkFiles });
    dispatch({ type: types.GET_REPORT_FILES, payload: reportFiles });
  } catch (e) {
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction);
  }
};

export const clearReportFiles = () => async dispatch => {
  dispatch({ type: types.GET_REPORT_FILES_ALL, payload: [] });
  dispatch({ type: types.GET_REPORT_RESOURCE_LINKS, payload: [] });
  dispatch({ type: types.GET_REPORT_FILES, payload: [] });
};

export const getReportResourceLinks = reportId => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const { report_resource_links } = await API.getResourceLinksByReport(reportId);

    const linkFiles = report_resource_links.map(link => link.resource_link.file);

    dispatch({ type: types.GET_REPORT_RESOURCE_LINKS, payload: linkFiles });
  } catch (e) {
    notifyApiError(e);
  } finally {
    dispatch(offLoadingAction);
  }
};

export const getHighlights = id => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const data = await fetchAssignmentById(id);
    dispatch({
      type: GET_ASSIGNMENT_HIGHLIGHTS,
      payload: {
        highlights: data?.assignment?.highlights || [],
        highlightLogs: data?.assignment?.highlight_logs || [],
      },
    });
    return true;
  } catch (e) {
    console.error(e);
    notifyApiError(e);
    return false;
  } finally {
    dispatch(offLoadingAction);
  }
};

export const updateAssignmentById = (id, body) => async dispatch => {
  dispatch(onLoadingAction);
  try {
    const assignment = await apiUpdateAssignment(id, body);
    dispatch({ type: REPORT_UPDATE_ASSIGNMENT_STATUS, payload: assignment });
    return true;
  } catch (e) {
    console.error(e);
    notifyApiError(e);
    return false;
  } finally {
    dispatch(offLoadingAction);
  }
};
