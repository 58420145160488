import React from 'react';
import { Button, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { REPORT_SECTIONS_DEFAULT, REPORT_MSA_REDUCTION, REPORT_SECTIONS_DEFAULT_CLIENT } from '../../constants/reports';
import { goNextStep, goPrevStep, isFirstStep, isLastStep } from '../../utils/report-routing';
import isAllowed, { ROLES_DIVISIONS } from '../../layout/roles';

export default function PrevNextStep({ beforeNext = () => {}, disabled = false, saveDraft }) {
  const { roles } = useSelector(state => state.auth);
  const history = useHistory();
  const [, , , report_id, reportType, step] = history.location.pathname.split('/');

  const { reportInfo } = useSelector(state => state.report);

  const onNextStep = async () => {
    await beforeNext();
    goNextStep({ sections, currentStep: step, report_id, reportType, history, roles });
  };

  const onPrevStep = async () => {
    await beforeNext();
    goPrevStep({ sections, currentStep: step, report_id, reportType, history, roles });
  };

  const isClientAndReduction = isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT) && reportType === REPORT_MSA_REDUCTION;

  let sections = [];

  if (isClientAndReduction) {
    sections = REPORT_SECTIONS_DEFAULT_CLIENT[reportType];
  } else if (reportInfo?.sections) {
    sections = reportInfo?.sections;
  } else {
    sections = REPORT_SECTIONS_DEFAULT[reportType];
  }

  return (
    <Row type="flex" justify="space-between" align="middle" className="confirm-button" style={{ minWidth: '50%' }}>
      {isFirstStep(sections, step) ? (
        <div> </div>
      ) : (
        <Button type="primary" size="large" className="green-btn button" onClick={onPrevStep} disabled={disabled}>
          Previous Step
        </Button>
      )}

      {saveDraft && (
        <Button type="primary" size="large" onClick={beforeNext} className="purple-btn button">
          Save Draft
        </Button>
      )}

      {isLastStep(sections, step) ? (
        <div> </div>
      ) : (
        <Button type="primary" size="large" className="green-btn button" onClick={onNextStep} disabled={disabled}>
          Next Step
        </Button>
      )}
    </Row>
  );
}
