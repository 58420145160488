import React from 'react';

// Icons
import { PencilIcon } from 'components/Svg';

// Colors
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

// Style
import './cardSummary.scss';
import { TYPE_OF_PACKAGE_OPTIONS } from '../../../StepTypeOfPackage/StepTypeOfPackage';
import { PLAN_LIST_INDIVIDUAL, PLAN_LIST_MULTIPLE_USERS } from '../../../StepPackageTier/components/PlanCard/planList';
import {
  LIST_COUNT_TYPE_REVIEWER,
  MD_REVIEW_NAME,
  MSA_REDUCTION__REVIEWNAME,
  QA_REVIEW_NAME,
} from 'constants/contact-types';
import { STEP_A_LA_CARTA, STEP_PACKAGE_TIER } from 'containers/Tenant/tenantCreate/tenantCreate';

const CardSummary = ({ selectedPlan, typePackage, onConfirnEdit, aLaCarte, listPriceAlaCarte }) => {
  const isMultiple = typePackage === TYPE_OF_PACKAGE_OPTIONS.MULTIPLE_USERS;

  const LIST_PLAN = isMultiple ? PLAN_LIST_MULTIPLE_USERS : PLAN_LIST_INDIVIDUAL;

  const showPlan = LIST_PLAN.find(plan => plan.id === selectedPlan);

  const totalCost = (showPlan?.cost || 0) + (listPriceAlaCarte?.[aLaCarte?.selectCount] || 0);

  return (
    <div className="cardSummary">
      <div className="cardSummary_header">
        <h2>Cart summary</h2>
        <p>please review your selected items</p>
      </div>

      <div className="cardSummary_package">
        <div className="cardSummary_package_first">
          <div className="cardSummary_package_header">
            <p>
              <strong>{showPlan?.name || ''}</strong>
            </p>
            <p>${showPlan?.cost || ''}</p>
            <p>per month</p>
          </div>
          <div className="cardSummary_package_content">
            {showPlan?.features &&
              showPlan.features.map(feature => {
                if (feature.allowed)
                  return (
                    <div className="cardSummary_package_content_item" key={feature.name}>
                      <p>
                        <strong>{feature.name}</strong>
                      </p>
                      {feature?.descriptions && <p>{feature.descriptions.join(', ')}</p>}
                    </div>
                  );

                return '';
              })}
          </div>
        </div>
        <div className="cardSummary_package_edit" onClick={() => onConfirnEdit({ step: STEP_PACKAGE_TIER })}>
          <PencilIcon color={PRIMARY_LIGHT_BLUE} />
        </div>
      </div>

      {aLaCarte?.selectCount && (
        <div className="cardSummary_package">
          <div className="cardSummary_package_first">
            <div className="cardSummary_package_header">
              <p>A la Carte</p>
              <p>${listPriceAlaCarte[aLaCarte.selectCount]}</p>
              <p>per month</p>
            </div>
            <div className="cardSummary_package_content">
              <div className="cardSummary_package_content_item">
                <p>
                  <strong>
                    {LIST_COUNT_TYPE_REVIEWER.find(item => item.value === aLaCarte.selectCount).label} Users
                  </strong>
                </p>
                <p>
                  {MD_REVIEW_NAME}, {MSA_REDUCTION__REVIEWNAME}, {QA_REVIEW_NAME}
                </p>
              </div>
            </div>
          </div>
          <div className="cardSummary_package_edit" onClick={() => onConfirnEdit({ step: STEP_A_LA_CARTA })}>
            <PencilIcon color={PRIMARY_LIGHT_BLUE} />
          </div>
        </div>
      )}

      {aLaCarte?.selectQuickbook && (
        <div className="cardSummary_package">
          <div className="cardSummary_package_first">
            <div className="cardSummary_package_header">
              <p>
                <strong>A la Carte</strong>
              </p>
              <p>Yes</p>
            </div>
            <div className="cardSummary_package_content">
              <div className="cardSummary_package_content_item">
                <p>
                  <strong>Set Up QuickBooks or Internal Invoicing</strong>
                </p>
                <p>We will contact you for this quotation</p>
              </div>
            </div>
          </div>
          <div className="cardSummary_package_edit" onClick={() => onConfirnEdit({ step: STEP_A_LA_CARTA })}>
            <PencilIcon color={PRIMARY_LIGHT_BLUE} />
          </div>
        </div>
      )}

      <div className="cardSummary_total">
        <p>
          <strong>Total:</strong>
        </p>
        <p>
          <strong>${totalCost}</strong>
        </p>
      </div>
    </div>
  );
};

export default CardSummary;
