import React, { useEffect } from 'react';
import { TransactionHistory } from '../Components/TransactionHistory';
import { useTransaction } from 'hooks';
import { Row } from 'antd';
import { ReportTransactionHistory } from '../Components/ReportTransactionHistory';

const TransactionSettings = () => {
  const {
    listTransaction,
    countTransaction,
    pageTransaction,
    loadingTransaction,
    pageMonthToDate,
    listMonthToDate,
    countMonthToDate,
    totalPriceMonthToDate,
    paginationTransaction,
    getTransactions,
    getMonthToDateCostHook,
    paginationMonthToDateHook,
  } = useTransaction();

  useEffect(() => {
    (async () => {
      await Promise.allSettled([getTransactions(), getMonthToDateCostHook()]);
    })();
  }, []);

  return (
    <Row className="company_container" type="flex" justify="space-between">
      <ReportTransactionHistory
        listTransaction={listMonthToDate}
        countTransaction={countMonthToDate}
        pageTransaction={pageMonthToDate}
        loading={loadingTransaction}
        totalPrice={totalPriceMonthToDate}
        onPagination={paginationMonthToDateHook}
      />

      <TransactionHistory
        listTransaction={listTransaction}
        countTransaction={countTransaction}
        pageTransaction={pageTransaction}
        loading={loadingTransaction}
        onPagination={paginationTransaction}
      />
    </Row>
  );
};

export default TransactionSettings;
