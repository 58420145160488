import { Button, Col, Input, Modal, Row, Select, Typography } from 'antd';
import InputBox from 'components/InputBox';
import SpinBox from 'components/SpinBox';
import React, { useEffect, useState } from 'react';
import * as API from 'utils/api';
import '../ReportModal/ReportModal.scss';
import DatePickerSimple from 'react-datepicker';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function Index({
  isOpen,
  onCancel,
  claimantInfo,
  onFieldChange,
  getFileToAssign,
  onAssignSubmit,
  loading,
  onNoteChange,
  onDateChange,
  notes,
  endDate,
  selectedUser,
}) {
  const [getAllFiles, setGetAllFiles] = useState([]);
  const [getAllusers, setGetAllUsers] = useState([]);

  const displayUsers = () => {
    API.GetAllRatedAgesUsers().then(res => {
      setGetAllUsers(res.users);
    });
  };
  useEffect(() => {
    API.GetReportFiles(claimantInfo.id).then(res => {
      setGetAllFiles(res.files);
    });
    displayUsers();
  }, []);

  const children = [];
  for (let i = 0; i < getAllFiles.length; i++) {
    children.push(
      <Option key={i.toString(36) + i} value={getAllFiles[i].id}>
        {getAllFiles[i].name}
      </Option>
    );
  }

  const styles = {
    paddingBottom: '5px',
  };
  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      centered={true}
      closable={true}
      width={550}
      className="report-modal"
      title={'Request Rated Age'}
      destroyOnClose>
      <SpinBox loading={loading}>
        <Row type="flex" align="middle" className="report-body">
          <Row type="flex" justify="space-between" align="middle" className="main-info">
            <Col md={24} className="row">
              <Row style={styles}>
                <Text> Users</Text>
              </Row>
              <Select
                placeholder="Please Select"
                style={{ width: '100%', zIndex: 10 }}
                onChange={onFieldChange}
                optionFilterProp="children"
                showSearch>
                {getAllusers &&
                  getAllusers.map((user, index) => (
                    <Option key={index} value={user.id}>
                      {`${user?.given_name} ${user?.family_name} `}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                <Text>Full name</Text>
              </Row>
              <InputBox
                // label="Full Name"
                value={
                  claimantInfo.claimant_name +
                  ' ' +
                  claimantInfo.claimant_middle_name +
                  ' ' +
                  claimantInfo.claimant_last_name
                }
                disabled
              />
            </Col>
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                <Text>Date of Birth</Text>
              </Row>
              <InputBox
                // label="Date of Birth"
                value={claimantInfo.dob}
                disabled
              />
            </Col>
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                <Text>Due Date</Text>
              </Row>
              <DatePickerSimple
                onChange={onDateChange}
                style={{ width: '100%' }}
                selected={endDate}
                minDate={new Date()}
                todayButton="Today"
                placeholderText="Required"
              />
            </Col>
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                {' '}
                <Text> Notes</Text>
              </Row>
              <TextArea rows={8} placeholder="Notes " value={notes} onChange={onNoteChange} />
            </Col>
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 10 }}>
                {' '}
                <Text> Files</Text>
              </Row>
              <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select" onChange={getFileToAssign}>
                {children}
              </Select>
            </Col>
          </Row>

          <Button
            type="primary"
            size="large"
            className="green-btn button"
            onClick={onAssignSubmit}
            disabled={!endDate || !selectedUser}>
            Request
          </Button>
        </Row>
      </SpinBox>
    </Modal>
  );
}
