import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Spin, Button } from 'antd';
import { REPORT_FIELDS, REPORT_FIELDS_TRASLATE } from 'constants/reportFields';
import { REPORT_IGNOREDKEYS } from 'constants/report-ignoredkeys';
import { REPORT_TYPES_BY_ID } from 'constants/reports';
import * as CustomAPI from 'utils/api';
import { filterObject } from 'utils/utils';
import { ReportInfo } from 'components/ReportInfo';
import ComparedFields from 'containers/Reviews/ReportCompare/ComparedFields';
import { ModalCompare } from 'components/Modal/ModalCompare';
import { StepProgress } from 'components/StepProgress';
import moment from 'moment';
import { PreviewCompare } from 'components/PreviewCompare';
import { ModalMessage } from 'components/Modal/ModalMessage';
import { CLAIMANT_INFO_NAV } from 'layout/navigate_const';
import AddAssignForm from 'containers/Calendar/src/src/containers/base/AddAssignForm/index.js';
import { ASSIGNMENT_TYPE_LEGAL_NURSE } from 'constants/assignment-types';
import * as API from 'utils/api';
import { CREATE_SET_UP_PDF } from 'utils/createSetUpPdf';
import { STATUS_IN_SUPERVISOR_REVIEW } from 'constants/report_status';
import { notifyError } from 'utils/notification';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

import './reportCompare.scss';

const ReportCompare = () => {
  const { idReport } = useParams();
  const history = useHistory();
  const [reportInfo, setReportInfo] = useState({});
  const [completedReport, setCompletedReport] = useState({});
  const [viewReportInfo, setViewReportInfo] = useState({});
  const [currentReportInfo, setCurrentReportInfo] = useState({});
  const [modalCompare, setModalCompare] = useState(false);
  const [selectTextCompare, setSelectTextCompare] = useState({});
  const [loading, setLoading] = useState(true);
  const [internalLoading, setInternalLoading] = useState(false);
  const [previewCompare, setPreviewCompare] = useState('');
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [modalAssigment, setModalAssigment] = useState(false);
  const [confirmAssign, setConfirmAssign] = useState(false);
  const [toggleModalConfirm, setToggleModalConfirm] = useState(false);

  const handleTextChange = ({ value, name }) => {
    setViewReportInfo(viewReportInfo => {
      return { ...viewReportInfo, [name]: value };
    });
  };

  const handleSelectText = async ({ field, selectedText, reportId }) => {
    setInternalLoading(true);
    const body = {
      id: reportId,
      [field]: selectedText,
    };

    const updateDraftReportWriter = await CustomAPI.updateDraftReportWriter({ body });

    if (updateDraftReportWriter) {
      setCurrentReportInfo(prevState => {
        return {
          ...prevState,
          [field]: selectedText,
        };
      });
    }
    setInternalLoading(false);
  };

  useEffect(() => {
    let fetchCancel = false;
    let getOriginalReport = true;

    Promise.all([CustomAPI.getViewReport(idReport), CustomAPI.getReportById(idReport, getOriginalReport)]).then(res => {
      if (fetchCancel) return;

      setCompletedReport(res[1]);

      const typeReport = REPORT_TYPES_BY_ID[res[0].report_type.id];

      const reportTypeFields = filterObject({
        raw: res[1],
        allowed: REPORT_FIELDS[typeReport],
        notAllowed: REPORT_IGNOREDKEYS,
        noRepeat: res[0],
      });
      const viewReportTypeFields = filterObject({
        raw: res[0],
        allowed: REPORT_FIELDS[typeReport],
        notAllowed: REPORT_IGNOREDKEYS,
        noRepeat: res[1],
      });

      setReportInfo(reportTypeFields);
      setViewReportInfo(viewReportTypeFields);
      dataCurrentReport({ data: res[1], only: Object.keys(reportTypeFields) });
    });

    const dataCurrentReport = async ({ data, only }) => {
      let getCurrentReport = await CustomAPI.getDraftReportWriter({ idReport });

      if (!getCurrentReport) {
        const body = {
          report_type_id: data.report_type_id,
          report_status_id: data.report_status_id,
          report_uuid: data.report_uuid,
          id: data.id,
        };

        getCurrentReport = await CustomAPI.createDraftReportWrite({ body });
      }

      const reportTypeFields = filterObject({
        raw: getCurrentReport,
        allowed: only,
        notAllowed: REPORT_IGNOREDKEYS,
        notNull: true,
      });

      if (fetchCancel) return;

      setCurrentReportInfo(reportTypeFields);
      setLoading(false);
    };

    return () => {
      fetchCancel = true;
    };
  }, [idReport]);

  const openModalCompare = ({ textOriginal, textRevision, field }) => {
    setModalCompare(true);
    setSelectTextCompare({
      original: textOriginal,
      revision: textRevision,
      field,
    });
  };

  const clearModalCompare = () => {
    setModalCompare(false);
    setSelectTextCompare({});
  };

  const handlePreviewReport = async () => {
    setInternalLoading(true);
    const reportReview = await API.getReviewReportCompare({ idReport });

    if (!reportReview?.report_url) return;

    if (Array.isArray(reportReview.report_url)) {
      setPreviewCompare(reportReview.report_url);
    }else {
      setPreviewCompare([reportReview.report_url]);
    }
    setInternalLoading(false);
  };

  const handleCheckWatermark = value => {
    setInternalLoading(true);
    const body = { id: idReport, is_watermark: value.target.checked };

    Promise.all([
      CustomAPI.updateReportNurse(body),
      CustomAPI.updateRevisionReport({ body }),
      CustomAPI.updateDraftReportWriter({ body }),
    ]).then(() => {
      setCompletedReport(prevState => {
        return { ...prevState, is_watermark: body.is_watermark };
      });
      handlePreviewReport();
    });
  };

  const handleConfirm = () => {
    setToggleModalConfirm(prevState => {
      return !prevState;
    });
  };

  const handleGenerateReport = async () => {
    const body = {
      id: idReport,
    };

    const generate = await API.generatedReportCompare({ body });

    if (generate?.id) {
      await CREATE_SET_UP_PDF[generate.report_type_id](generate.id);

      handleConfirm();
      setConfirmMessage(true);
    }
  };

  const handleAssignLegalNurse = () => {
    setModalAssigment(prevState => {
      return !prevState;
    });
  };

  const handleSubmitAssignLegalNurse = async data => {
    try {
      setInternalLoading(true);

      const assignment = await API.fetchReportAssignments({
        reportId: completedReport.id,
        claimantId: completedReport.claimant_id,
      });

      await API.updateAssignmentById(assignment.assignments[0].id, data);

      const updateReport = {
        ...completedReport,
        ...currentReportInfo,
        report_status_id: STATUS_IN_SUPERVISOR_REVIEW.id,
      };

      await API.updateReportNurse(updateReport);

      setConfirmAssign(true);
      handleAssignLegalNurse();
    } catch (e) {
      notifyError(e.message);
    } finally {
      setInternalLoading(false);
    }
  };

  const allSteps = Object.keys(reportInfo).length;
  const stepProgress = Object.keys(currentReportInfo).length;

  if (loading)
    return (
      <Modal visible={true} footer={null} className="modal-loader">
        <Spin />
      </Modal>
    );

  return (
    <>
      <StepProgress totalStep={allSteps} totalSelectStep={stepProgress} />
      <ReportInfo
        reportId={completedReport.report_uuid}
        claimantName={completedReport.claimant.claimant_name}
        claimantId={completedReport.claimant.id}
        client={completedReport.claimant.client.id}
        returnDate={moment(completedReport.createdAt).format('MM/DD/YYYY')}
        status={completedReport.report_status.name}
      />

      {!previewCompare ? (
        <div className="table-content pt-5">
          {Object.keys(reportInfo).map((key, index) => (
            <ComparedFields
              fieldTitle={`${index + 1}. ${
                REPORT_FIELDS_TRASLATE[REPORT_TYPES_BY_ID[completedReport.report_type_id]][key]
              }`}
              field={key}
              originalText={reportInfo[key]}
              revisionText={viewReportInfo[key]}
              handleTextChange={handleTextChange}
              handleSelectText={handleSelectText}
              currentText={currentReportInfo[key]}
              onOpenCompare={openModalCompare}
              reportId={completedReport.id}
              key={key}
            />
          ))}

          {allSteps === stepProgress && (
            <div className="save_compare_report">
              <Button type="primary" className="green-btn btn-normal" onClick={handlePreviewReport}>
                Review
              </Button>
            </div>
          )}
        </div>
      ) : (
        <PreviewCompare
          path={previewCompare}
          onPreview={() => setPreviewCompare('')}
          watermark={completedReport.is_watermark}
          onCheckWatermark={handleCheckWatermark}
          onConfirm={handleConfirm}
          onAssignLegalNurse={handleAssignLegalNurse}
        />
      )}

      {modalCompare && (
        <ModalCompare
          onClose={clearModalCompare}
          subTitle={selectTextCompare.field}
          textOriginal={selectTextCompare.original}
          textRevision={selectTextCompare.revision}
        />
      )}

      {modalAssigment && (
        <Modal
          width={700}
          maskClosable={false}
          bodyStyle={{ padding: 0, minWidth: 450 }}
          visible={true}
          onCancel={() => setModalAssigment(false)}
          keyboard={false}
          footer={null}>
          <AddAssignForm
            onSubmit={handleSubmitAssignLegalNurse}
            edit={{
              claimant: completedReport.claimant,
              report: completedReport,
              report_id: completedReport.id,
              claimant_id: completedReport.claimant_id,
            }}
            disabledField={{
              assignmentType: true,
              client: true,
              claimant: true,
              report: true,
            }}
            defaultAssignmentType={ASSIGNMENT_TYPE_LEGAL_NURSE}
          />
        </Modal>
      )}

      {confirmAssign && (
        <ModalMessage
          onClose={() => history.push(`${CLAIMANT_INFO_NAV.path}/${completedReport.claimant.id}`)}
          message="The admin will be asked to assign a Legal Nurse Supervisor for a second signature"
          title="A notification has been sent"
        />
      )}

      {confirmMessage && (
        <ModalMessage
          onClose={() => history.push(`${CLAIMANT_INFO_NAV.path}/${completedReport.claimant.id}`)}
          message="You will now be redirected to the claimant folder to verify the correct copy of the report has been generated, and to change status to Completed"
          title="This report has been generated"
        />
      )}

      {internalLoading && (
        <Modal visible={true} footer={null} className="modal-loader">
          <Spin />
        </Modal>
      )}

      {toggleModalConfirm && (
        <ModalConfirm
          title="Are you sure that you want to continue?"
          text={
            <p>
              The Admin will receive a notification. <br />
              He will be asked to assign a Legal Nurse Supervisor for second signature
            </p>
          }
          onClose={handleConfirm}
          onConfirm={handleGenerateReport}
        />
      )}
    </>
  );
};

export default ReportCompare;
