import { useState } from 'react';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';

export default function useSubscription() {
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [subsctiption, setSubsciption] = useState({});

  const getSubscription = async () => {
    try {
      setLoadingSubscription(true);
      // const resBillDue = await API.getSubscriptionService();
      setSubsciption({});
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingSubscription(false);
    }
  };

  const updateSubscription = async ({ tenant_id, package_id, customer }) => {
    try {
      setLoadingSubscription(true);
      await API.updateSubscriptionService({ tenant_id, package_id, customer });
      return true
    } catch (e) {
      notifyApiError(e);
      return false
    } finally {
      setLoadingSubscription(false);
    }
  };

  const cancelSubscriptionHook = async ({ tenant_id }) => {
    try {
      setLoadingSubscription(true);
      await API.cancelSubcriptionService({ tenant_id });
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingSubscription(false);
    }
  };

  return {
    loadingSubscription,
    subsctiption,
    getSubscription,
    updateSubscription,
    cancelSubscriptionHook,
  };
}
