import * as React from 'react';

import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

const UserGroupIcon = ({ color = PRIMARY_LIGHT_BLUE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32" fill="none">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M24.5 9.547a.807.807 0 0 0-.253 0 3.431 3.431 0 0 1-3.307-3.44 3.431 3.431 0 0 1 3.44-3.44 3.44 3.44 0 0 1 3.44 3.44 3.452 3.452 0 0 1-3.32 3.44ZM23.127 19.253c1.826.307 3.84-.013 5.253-.96 1.88-1.253 1.88-3.306 0-4.56-1.427-.946-3.467-1.266-5.293-.946M8.46 9.547a.807.807 0 0 1 .253 0 3.431 3.431 0 0 0 3.307-3.44 3.431 3.431 0 0 0-3.44-3.44 3.44 3.44 0 0 0-3.44 3.44 3.452 3.452 0 0 0 3.32 3.44ZM9.833 19.253c-1.826.307-3.84-.013-5.253-.96-1.88-1.253-1.88-3.306 0-4.56 1.427-.946 3.467-1.266 5.293-.946M16.5 19.507a.809.809 0 0 0-.253 0 3.431 3.431 0 0 1-3.307-3.44 3.431 3.431 0 0 1 3.44-3.44 3.44 3.44 0 0 1 3.44 3.44c-.013 1.866-1.48 3.386-3.32 3.44ZM12.62 23.707c-1.88 1.253-1.88 3.306 0 4.56 2.133 1.426 5.627 1.426 7.76 0 1.88-1.254 1.88-3.307 0-4.56-2.12-1.414-5.627-1.414-7.76 0Z"
    />
  </svg>
);
export default UserGroupIcon;
