import { Button, Col, Input, Modal, Row, Typography } from "antd";
import AssignmentFiles from "components/AssigmentFile/Index";
import SpinBox from "components/SpinBox";
import React from "react"; 
const { Text } = Typography;
const { TextArea } = Input;

export default function RatedAgesById({
  isOpen,
  onCancel,

  handleDataChanges,
  editRatedData,
  loading,
  claimant_id,
  saveEditRatedAges,
}) {
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Update File"}
        destroyOnClose={true}
      >
        <SpinBox loading={loading}>
          <Row type="flex" align="middle" className="report-body">
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                {" "}
                <Text> Note</Text>
              </Row>
              <TextArea
                placeholder="Note"
                value={editRatedData.note}
                onChange={handleDataChanges}
              />
            </Col>
          </Row>
          <Row type="flex" align="middle" className="report-body">
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                {" "}
                <Text> Files</Text>
              </Row>
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                <AssignmentFiles
                  assignment_id={editRatedData.id}
                  claimant_id={claimant_id}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              size="default"
              className="green-btn button"
              onClick={() => {
                saveEditRatedAges();
              }}
            >
              Save
            </Button>
          </Row>
        </SpinBox>
      </Modal>
    </div>
  );
}
