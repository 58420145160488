import React from 'react';

// Components
import { CreditCardInfo } from 'components/CreditCardInfo';

// Icons
import {
  // PencilIcon,
  TrashIcon,
} from 'components/Svg';

// Colors
import { PRIMARY_LIGHT_BLUE } from 'containers/Calendar/src/src/lib/utils/colors';

// Style
import './settingsListCard.scss';

const SettingsListCard = ({ listCard, onDelete }) => {
  return (
    <div className="settingsCard_list">
      {listCard.map(card => (
        <div key={card.customerPaymentProfileId} className="settingsCard_list_content">
          <CreditCardInfo cardInfo={card} />

          <div className="settingsCard_list_actions">
            <TrashIcon color={PRIMARY_LIGHT_BLUE} onClick={() => onDelete({ card })} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettingsListCard;
