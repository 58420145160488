import React from 'react';
import { DOWNGRADE_PLAN, UPGRADE_PLAN } from '../PlanSubscription/const';
import { Button } from 'antd';
import moment from 'moment';

const CardSubscription = ({
  type,
  actualPlan,
  upgradePlan,
  name,
  includes = [],
  pricePackage,
  packageId,
  onOpenModalCanceledPlan,
  onOpenModalConfirmUpgrade,
  onDownActualOrUpgradePlan,
  noneActions = false,
  availableUntil,
  disabled,
  billDue,
}) => {
  return (
    <div
      key={type}
      className={`subscriptionSetting_plan ${actualPlan ? 'subscriptionSetting_plan_actual' : ''} ${
        upgradePlan ? 'subscriptionSetting_plan_upgrade' : ''
      }`}>
      {actualPlan && (
        <div className="subscriptionSetting_plan_item_actual">
          <p>Actual subscription</p>
        </div>
      )}
      <div className="subscriptionSetting_plan_item">
        <div>
          <p>Subscription</p>
          <strong>{name}</strong>
        </div>
        <div>
          {includes.length > 0 && (
            <>
              <p>Report types included</p>
              <div className="subscriptionSetting_plan_info_includes">
                {includes.map(includeReport => (
                  <strong key={includeReport}>{includeReport}</strong>
                ))}
              </div>
            </>
          )}
        </div>
        {billDue !== null && (
          <>
            <div>
              <p>Due Date</p>
              <strong>{billDue?.due_date ? moment(billDue.due_date).format('DD-MM-YYYY') : ''}</strong>
            </div>
            <div>
              <p>Writers Licenses</p>
              <strong>
                {billDue?.count_nurse_writer || 0}/{billDue?.restriction?.max_nurse_writer || 9} writers used: $
                {billDue?.price_nurse_writer || 0}/user
              </strong>
            </div>
            <div>
              <p>Roles Reviewers</p>
              <strong>
                {billDue?.count_reviewers}/{billDue?.restriction?.max_reviewers || 5} reviewers used: $
                {billDue?.price_reviewers || 0}/mo
              </strong>
            </div>
          </>
        )}
      </div>
      <div className="subscriptionSetting_plan_item">
        <div>
          <p className="subscriptionSetting_plan_info_price">${pricePackage}</p>
          <p>
            <strong>per month</strong>
          </p>
          <p>
            <strong>{availableUntil}</strong>
          </p>
        </div>
        <div>
          {!noneActions && (
            <Button
              type="primary"
              className={`${upgradePlan ? 'green-btn' : 'purple-btn-outline'}`}
              disabled={disabled}
              onClick={() =>
                actualPlan
                  ? onOpenModalCanceledPlan()
                  : onOpenModalConfirmUpgrade({
                      subscription: packageId,
                      action: upgradePlan ? UPGRADE_PLAN : DOWNGRADE_PLAN,
                    })
              }>
              {actualPlan
                ? 'Cancel subscription'
                : `${onDownActualOrUpgradePlan ? onDownActualOrUpgradePlan({ type: type }) : ''} subscription`}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardSubscription;
