import React, { Component } from 'react';
import clsx from 'clsx';
import './main.css';
import { PdfLoader, PdfHighlighter, Highlight, Popup, AreaHighlight, setPdfWorker } from 'react-pdf-highlighter';
import { Spin, Icon } from 'antd';
import { Sidebar, Tip } from './components';

/* eslint import/no-webpack-loader-syntax: off */
import PDFWorker from 'worker-loader!pdfjs-dist/lib/pdf.worker';

// setPdfWorker(PDFWorker); //comentar

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length);

const resetHash = () => {
  document.location.hash = '';
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

// const searchParams = new URLSearchParams(document.location.search);

class PdfPage extends Component {
  _isMounted = false;

  state = {
    url: this.props.url,
    highlights: this.props.startHighlights || [],
  };

  resetHighlights = () => {
    const { onChange } = this.props;

    this.setState(
      {
        highlights: [],
      },
      () => onChange(this.state.highlights)
    );
  };

  scrollViewerTo = highlight => {};

  scrollToHighlightFromHash = () => {
    if (!this._isMounted) return;

    const highlight = this.getHighlightById(parseIdFromHash());
    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  componentDidMount() {
    window.addEventListener('hashchange', this.scrollToHighlightFromHash, false);
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.startHighlights) !== JSON.stringify(this.props.startHighlights)) {
      this.setState({
        highlights: this.props.startHighlights,
      });
    }
    if (prevProps.url !== this.props.url) {
      // console.info(`Refresh URL`);
      this.setState({
        url: this.props.url,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getHighlightById(id) {
    const { highlights } = this.state;

    return highlights.find(highlight => highlight.id === id);
  }

  addHighlight(highlight) {
    const { onChange } = this.props;
    const { highlights } = this.state;

    this.setState(
      {
        highlights: [{ ...highlight, id: getNextId() }, ...highlights],
      },
      () => onChange(this.state.highlights)
    );
  }

  editHighlightComment = (index, comment) => {
    const { onChange } = this.props;
    const { highlights } = this.state;

    highlights[index].comment.text = comment;

    this.setState(
      {
        highlights: [...highlights],
      },
      () => onChange(this.state.highlights)
    );
  };

  deleteHighlight = index => {
    const { onChange } = this.props;
    const { highlights } = this.state;

    highlights.splice(index, 1);

    this.setState(
      {
        highlights: [...highlights],
      },
      () => onChange(this.state.highlights)
    );
  };

  updateHighlight(highlightId, position, content) {
    this.setState({
      highlights: this.state.highlights.map(h => {
        const { id, position: originalPosition, content: originalContent, ...rest } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }

  render() {
    const { url, highlights } = this.state;
    const { disabled = false, isAdmin = false, disableHighlights = false, shrink = false, noEdit = false, highlightLogs = [] } = this.props;

    return (
      <div className={clsx('pdf-page__container', shrink && 'pdf-page__container--shrink')}>
        <div className={clsx('pdf-page__document', shrink && 'pdf-page__document--shrink')}>
          <PdfLoader
            url={url}
            beforeLoad={
              <Spin tip="Loading document..." indicator={antIcon} className="spinner" prefixCls="prefix-spinner" />
            }>
            {pdfDocument => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={event => event.ctrlKey}
                onScrollChange={resetHash}
                // pdfScaleValue="page-width"
                scrollRef={scrollTo => {
                  this.scrollViewerTo = scrollTo;

                  this.scrollToHighlightFromHash();
                }}
                onSelectionFinished={
                  !disableHighlights && !noEdit
                    ? (position, content, hideTipAndSelection, transformSelection) => (
                        <Tip
                          disabled={disabled}
                          onOpen={transformSelection}
                          onCancel={hideTipAndSelection}
                          onConfirm={comment => {
                            this.addHighlight({ content, position, comment });

                            hideTipAndSelection();
                          }}
                        />
                      )
                    : () => {}
                }
                highlightTransform={(highlight, index, setTip, hideTip, viewportToScaled, screenshot, isScrolledTo) => {
                  const isTextHighlight = !Boolean(highlight.content && highlight.content.image);

                  const component = isTextHighlight ? (
                    <Highlight isScrolledTo={isScrolledTo} position={highlight.position} comment={highlight.comment} />
                  ) : (
                    <AreaHighlight
                      highlight={highlight}
                      onChange={boundingRect => {
                        this.updateHighlight(
                          highlight.id,
                          { boundingRect: viewportToScaled(boundingRect) },
                          { image: screenshot(boundingRect) }
                        );
                      }}
                    />
                  );

                  return (
                    !disabled && (
                      <Popup
                        popupContent={<HighlightPopup {...highlight} />}
                        onMouseOver={popupContent => setTip(highlight, highlight => popupContent)}
                        onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    )
                  );
                }}
                highlights={highlights}
              />
            )}
          </PdfLoader>
        </div>
        {!disableHighlights && (
          <div className={clsx('pdf-page__sidebar', shrink && 'pdf-page__sidebar--shrink')}>
            <Sidebar
              disabled={disabled}
              isAdmin={isAdmin}
              highlights={highlights}
              highlightLogs={highlightLogs}
              onResetHighlights={this.resetHighlights}
              deleteHighlight={this.deleteHighlight}
              editHighlightComment={this.editHighlightComment}
              loading={this.props.pending}
              shrink={shrink}
              noEdit={noEdit}
            />
          </div>
        )}
      </div>
    );
  }
}

export default PdfPage;
