import { API } from 'aws-amplify';

const BASE = '/transactions';

export const fetchTransactions = async data => {
  return await API.get('MyAPI', `${BASE}`, {
    queryStringParameters: {
      ...data,
    },
  });
};

export const fetchTransaction = async ({ id }) => {
  return await API.get('MyAPI', `${BASE}/${id}`);
};

export const createTransaction = async data => {
  return await API.post('MyAPI', `${BASE}`, {
    body: {
      ...data,
    },
  });
};

export const sendPaymentTransaction = async ({ body }) => {
  return await API.post('MyAPI', `${BASE}/payInvoice`, {
    body,
  });
};

export const updateTransaction = async (id, data) => {
  return await API.put('MyAPI', `${BASE}/${id}`, {
    body: {
      ...data,
    },
  });
};

export const deleteTransaction = async id => {
  return await API.del('MyAPI', `${BASE}/${id}`);
};

export const fetchServices = async () => {
  return await API.get('MyAPI', `${BASE}/services`, {});
};

export const fetchQuickbooksCustomerInfo = async id => {
  return await API.get('MyAPI', `${BASE}/customers/${id}`, {});
};
