import React, { useState, useEffect } from 'react';
import { EditIcon, RemoveIcon } from '../../../../../components/Icons';
import InputMask from 'react-input-mask';
import '../Step.scss';

import { Icon, Select } from 'antd';

const FormCreateContact = ({
  thereIsContact,
  listContact,
  onChange,
  listContactType,
  dataContact,
  validForm,
  onClose,
  juridictionList,
  onSubmit,
  isEdit,
}) => {
  const { Option } = Select;

  return (
    <>
      <strong className="stepText">{`Contact #${listContact.length + 1}`}</strong>

      <div className="stepFormContainer">
        <div className="stepFormGroup">
          <label htmlFor="contact_name">Contact Name</label>
          <input
            onChange={onChange}
            type="text"
            name="contact_name"
            id="contact_name"
            placeholder="Required"
            required
            value={dataContact?.contact_name || ''}
          />
        </div>
        <div className="stepFormGroup">
          <label htmlFor="contact_type_id">Contact Type</label>
          <Select
            showSearch
            placeholder="Required"
            optionFilterProp="children"
            className="dropdown inputSelect contact-type"
            value={dataContact?.contact_type_id || ''}
            onChange={value => onChange({ target: { name: 'contact_type_id', value } })}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            <Option disabled value="">
              Required
            </Option>
            {listContactType.map((el, i) => {
              return (
                <Option key={i} value={el.id}>
                  {el.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="stepFormGroup">
          <label htmlFor="address">Address Line 1</label>
          <input
            onChange={onChange}
            type="text"
            name="address"
            id="address"
            placeholder="Required"
            required
            value={dataContact?.address || ''}
          />
        </div>
        <div className="stepFormGroup">
          <label htmlFor="contactAddressLineTwo">Address Line 2</label>
          <input
            onChange={onChange}
            type="text"
            name="contactAddressLineTwo"
            id="contactAddressLineTwo"
            placeholder="Optional"
            value={dataContact?.contactAddressLineTwo || ''}
          />
        </div>
        <div className="stepFormGroup">
          <label htmlFor="city">City</label>
          <input
            onChange={onChange}
            type="text"
            name="city"
            id="city"
            placeholder="Required"
            required
            value={dataContact?.city || ''}
          />
        </div>
        <div className="stepFormContainer">
          <div className="stepFormGroup">
            <label htmlFor="state">State</label>
            <Select
              showSearch
              placeholder="Please select"
              optionFilterProp="children"
              className="dropdown inputSelect state-select"
              value={dataContact?.state || ''}
              onChange={value => onChange({ target: { name: 'state', value } })}
              name="state"
              suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
              <Option disabled value="">
                Required
              </Option>
              {juridictionList.map((el, i) => {
                return (
                  <Option key={i} value={el.name}>
                    {el.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="stepFormGroup">
            <label htmlFor="zip_code">Zip Code</label>
            <InputMask
              mask="99999"
              placeholder="Required"
              name="zip_code"
              id="zip_code"
              value={dataContact?.zip_code || ''}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="stepFormGroup">
          <label htmlFor="email">Email</label>
          <input
            onChange={onChange}
            type="email"
            name="email"
            id="email"
            placeholder="Required"
            required
            value={dataContact?.email || ''}
          />
        </div>
        <div />
        <div className="stepFormGroup">
          <label htmlFor="phone_number">Phone</label>
          <InputMask
            mask="999-999-9999"
            placeholder="Required"
            value={dataContact?.phone_number || ''}
            name="phone_number"
            id="phone_number"
            onChange={onChange}
          />
        </div>
        <div />
      </div>

      <div className="stepContactGroupButton">
        <button className="saveContactButton" disabled={!validForm(dataContact)} onClick={onSubmit}>
          {isEdit ? 'Edit Contact' : 'Save Contact'}
        </button>

        {thereIsContact && (
          <>
            <button className="cancelContactButton" onClick={() => onClose(false)}>
              cancel
            </button>
          </>
        )}
      </div>
    </>
  );
};

const Step3 = ({ data, onSubmit, juridictionList, listContactType }) => {
  const [listContact, setListContact] = useState(data);
  const [dataContact, setDataContact] = useState({});
  const [dataContactEdit, setDataContactEdit] = useState({});
  const [activeFormAddContact, setActiveFormAddContact] = useState(true);
  const [indiceEditContact, setIndiceEditContact] = useState(null);

  useEffect(() => {
    if (data.length > 0) {
      setActiveFormAddContact(false);
    }
  }, [data]);

  const handleChangeDataContact = e => {
    if (indiceEditContact === null) {
      setDataContact({
        ...dataContact,
        [e.target.name]: e.target.value,
      });
    } else {
      setDataContactEdit({
        ...dataContactEdit,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddListContact = () => {
    setListContact([...listContact, dataContact]);
    setActiveFormAddContact(false);
    setDataContact({});
  };

  const handleDeleteListContact = ({ index }) => {
    listContact.splice(index, 1);
    setListContact([...listContact]);
    if (listContact.length === 0) {
      setActiveFormAddContact(true);
    }
  };

  const handleEditListContact = () => {
    const editContact = Array.from(listContact);
    editContact[indiceEditContact] = dataContactEdit;
    setListContact(editContact);
    handleCloseFormEditContact();
  };
  const handleEditContact = ({ index }) => {
    setDataContactEdit(listContact[index]);
    setIndiceEditContact(index);
  };

  const handleCloseFormEditContact = () => {
    setDataContactEdit({});
    setIndiceEditContact(null);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit({ sectionStep: 'contacts', payload: listContact, nextStep: 4 });
  };

  const validForm = data => {
    return (
      data?.contact_name &&
      data?.contact_type_id &&
      data?.address &&
      data?.city &&
      data?.state &&
      data?.zip_code &&
      data?.email &&
      data?.phone_number
    );
  };

  const thereIsContact = listContact.length > 0;

  return (
    <div className="stepContainer">
      <div className="stepTitleContainer">
        <p className="stepText">{thereIsContact ? 'Step 3' : 'Complete Profile'}</p>
        <h1 className="stepTitle">Contact Information</h1>
      </div>

      {thereIsContact && (
        <>
          {listContact.map((item, index) => (
            <React.Fragment key={item.email}>
              {indiceEditContact === index ? (
                <FormCreateContact
                  thereIsContact={thereIsContact}
                  listContact={listContact}
                  onChange={handleChangeDataContact}
                  listContactType={listContactType}
                  dataContact={dataContactEdit}
                  validForm={validForm}
                  onClose={handleCloseFormEditContact}
                  juridictionList={juridictionList}
                  onSubmit={handleEditListContact}
                  isEdit
                />
              ) : (
                <div className="stepContainerContact">
                  <div className="stepContactItem">
                    <h2>{item.contact_name}</h2>
                  </div>
                  <div className="stepContactItemButtons">
                    <button>
                      <EditIcon onClick={() => handleEditContact({ index })} />
                    </button>
                    <button onClick={() => handleDeleteListContact({ index })}>
                      <RemoveIcon />
                    </button>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Contact Type</p>
                    <p className="stepContactItemValue">
                      {listContactType.find(type => type.id === item.contact_type_id).name}
                    </p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Email</p>
                    <p className="stepContactItemValue">{item.email}</p>
                  </div>
                  <div className="stepContactItem stepCol2">
                    <p className="stepContactItemLabel">Full Address</p>
                    <p className="stepContactItemValue">{item.address + ' ' + item.contactAddressLineTwo}</p>
                  </div>
                  <div className="stepContactItem">
                    <p className="stepContactItemLabel">Phone</p>
                    <p className="stepContactItemValue">{item.phone_number}</p>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}

          {!activeFormAddContact && (
            <button className="addAdditionalContactButton" onClick={() => setActiveFormAddContact(true)}>
              Add Additional Contact
            </button>
          )}
        </>
      )}

      {activeFormAddContact && (
        <FormCreateContact
          thereIsContact={thereIsContact}
          listContact={listContact}
          onChange={handleChangeDataContact}
          listContactType={listContactType}
          dataContact={dataContact}
          validForm={validForm}
          onClose={setActiveFormAddContact}
          juridictionList={juridictionList}
          onSubmit={handleAddListContact}
        />
      )}

      <div className="dividir"></div>

      <button className="stepNextButton" disabled={listContact.length === 0} onClick={handleSubmit}>
        Next Step
      </button>
    </div>
  );
};

export default Step3;
