import React from 'react';
import { Row } from 'antd';

import './reportInfo.scss';

const ReportInfo = ({ reportId, claimantName, claimantId, client, returnDate, status }) => {
  const items = {
    'Report ID': reportId,
    Claimant: claimantName,
    'Claimant ID': claimantId,
    Client: client,
    'Return Date': returnDate,
    Status: status,
  };

  return (
    <Row className="table-content">
      <p className="title">Report Info</p>

      <div className='reportInfo_content'>
        {Object.keys(items).map(item => (
          <div key={item}>
            <p className="textColorGrey">{item}</p>
            <p className="textColorBlack">{items[item]}</p>
          </div>
        ))}
      </div>
    </Row>
  );
};

export default ReportInfo;
