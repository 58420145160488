import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Popover, Row, Typography } from "antd";
import React, { useState } from "react";
import "./ModalTemplateContent.scss";

export default function ModalTemplateConent() {
  const { Text } = Typography;
  const [checkedInput, setCheckedInput] = useState(false);
  const [checked, setChecked] = useState(false);
  const { TextArea } = Input;

  return (
    <>
      <Text className="content_title">Field Details</Text>

      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="main-info"
      >
        <Col md={24} className="row">
          <label>Name</label>

          <Input label="Title" style={{ marginTop: "15px" }} />
        </Col>{" "}
        <Col md={11} className="row">
          <Row>
            <label>Requirements</label>
          </Row>
          <Input label=".." />
          {/* <Input label="Title" className="template_edit_input" /> */}
        </Col>
        <Col md={11} className="row">
          <Row>
            <label>Requirements</label>
          </Row>
          <Input label="Title" className="template_edit_input" />
        </Col>
        <Col md={11} className="row" style={{ marginTop: "12px" }}>
          <Checkbox
            onChange={(e) => setChecked(e.target.checked)}
            value={checked}
            checked={checked}
          >
            Include in seed money
          </Checkbox>{" "}
        </Col>
        <Col md={11} className="row" style={{ marginTop: "15px" }}>
          <Checkbox
            value={checkedInput}
            onChange={(e) => setCheckedInput(e.target.checked)}
            checked={checkedInput}
          >
            Make Pre-filled
          </Checkbox>{" "}
        </Col>
        <Row>
          <Col md={24} className="row">
            <Checkbox>User Fill Input</Checkbox>
            <Popover title="Some info" trigger="hover">
              <InfoCircleOutlined
                style={{ fontSize: "20px", position: "absolute" }}
              />
            </Popover>
          </Col>
        </Row>
        {checkedInput && (
          <Col md={24}>
            <TextArea rows={4} style={{ marginTop: "15px" }} />
          </Col>
        )}
      </Row>

      <Row type="flex" justify="center" align="middle">
        <Button type="primary" className="submit_modal_btn">
          Add
        </Button>{" "}
      </Row>
    </>
  );
}
