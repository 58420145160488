import { Col, Empty, Row, Typography } from 'antd';
import Pagination from 'components/Pagination';
import moment from 'moment';
import React from 'react';

import './reportTransactionHistory.scss';
import { PRIMARY_LIGHT_GREEN } from 'containers/Calendar/src/src/lib/utils/colors';

const { Title, Text } = Typography;

const ReportTransactionHistory = ({ listTransaction, countTransaction, pageTransaction, onPagination, totalPrice }) => {
  return (
    <div className="transactionHistory">
      <div className="transactionHistory_report">
        <div>
          <Title level={4}>Month-to-date Cost</Title>
          <Typography>{moment().format('MMM, YYYY')}</Typography>
        </div>

        <Title level={4}>$ {totalPrice}</Title>
      </div>
      <Row type="flex" className="transactionHistory_header">
        <Col md={7}>Claimant</Col>
        <Col md={7}>Date</Col>
        <Col md={7}>Name of Service</Col>
        <Col md={3} style={{ textAlign: 'right' }}>
          Cost
        </Col>
      </Row>
      <Row>
        {listTransaction.length > 0 ? (
          <>
            {listTransaction.map(item => (
              <Row className="transactionHistory_item" key={item.id}>
                {/* Id */}
                <Col md={7}>
                  <Text>
                    {item?.claimant?.claimant_name || ''} {item?.claimant?.claimant_last_name || ''}
                  </Text>
                </Col>
                {/* Date */}
                <Col md={7}>
                  <Text>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                </Col>
                {/* Paid */}
                <Col md={7}>
                  {item?.price_old !== null ? (
                    <>
                      <Text style={{ color: PRIMARY_LIGHT_GREEN }}>{item?.price_type || ''}</Text> <br />
                    </>
                  ) : (
                    ''
                  )}
                  <Text>{item?.report_type?.name || ''}</Text>
                </Col>
                {/* Cost */}
                {item?.price_old !== null ? (
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Text style={{ color: PRIMARY_LIGHT_GREEN }}>$ {item.price}</Text>
                    <br />
                    <Text style={{ textDecoration: 'line-through' }}>$ {item.price_old}</Text>
                  </Col>
                ) : (
                  <Col md={3} style={{ textAlign: 'right' }}>
                    <Text>$ {item.price}</Text>
                  </Col>
                )}
              </Row>
            ))}

            <div className="transactionHistory_pagination">
              <Pagination
                onChange={newPage => onPagination({ page: newPage })}
                totalCount={countTransaction}
                pageIndex={pageTransaction}
                pageSize={10}
              />
            </div>
          </>
        ) : (
          <Empty description="No Data" image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
        )}
      </Row>
    </div>
  );
};

export default ReportTransactionHistory;
