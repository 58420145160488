import React, { useState } from 'react';
import { Typography } from 'antd';
import { EditIcon } from 'components/Icons';
import { ElipsisIcon, TrashIcon, CancelIcon, CheckIcon } from 'components/Svg';
import InputBox from 'components/InputBox';

const ModalManagerItem = ({ newItem, edit, item, onSelectEdit, onConfirmEdit, onDelete, onChangeNewItem }) => {
  const { Text } = Typography;
  const [data, setData] = useState(item);

  const handleChangeEdit = ({ value }) => {
    onChangeNewItem && onChangeNewItem({ item, value });

    setData({
      ...data,
      name: value,
    });
  };

  const handleCancelEdit = () => {
    setData(item);
    onSelectEdit && onSelectEdit({ id: item.id });
  };

  const showInput = edit || newItem;

  return (
    <div className="modalManager_table_item modalManager_grid2">
      <div className="modalManager_itemText">
        <ElipsisIcon />
        {showInput ? (
          <InputBox
            type="text"
            placeholder="Type Name"
            value={data.name}
            onChange={value => handleChangeEdit({ value: value })}
          />
        ) : (
          <Text>{data.name}</Text>
        )}
      </div>

      <Text className="modalManager_table_btnEnd">
        {edit ? (
          <div className="modalManager_btns_edit">
            <div className="modalManager_table_cancel" onClick={() => onConfirmEdit({ data })}>
              <CheckIcon />
            </div>
            <div className="modalManager_table_cancel" onClick={handleCancelEdit}>
              <CancelIcon />
            </div>
          </div>
        ) : (
          !newItem && (
            <>
              <EditIcon className="icon" onClick={() => onSelectEdit({ id: data.id })} />
              <button className="modalManager_btnTrashRemove" onClick={() => onDelete({ id: data.id })}>
                <TrashIcon color="#0082FF" />
              </button>
            </>
          )
        )}
      </Text>
    </div>
  );
};

export default ModalManagerItem;
