import React from 'react';
import { Typography, Button, Modal } from 'antd';
import moment from 'moment';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { goFirstStep } from '../../../../../../../utils/report-routing';
import AddAssignForm from '../../base/AddAssignForm';
import {
  REPORT_SECTIONS_DEFAULT,
  REPORT_TYPES_BY_ID,
  REPORT_TYPE_NAME_BY_ID,
} from '../../../../../../../constants/reports';
import { useSelector } from 'react-redux';
import { REPORT_COMPARE_NAV } from 'layout/navigate_const';
import { NOT_ACCESS_REPORT_COMPARATION } from 'utils/constant';
import { getPermissionsBySection, ASSIGNMENT_BOARD_NAME } from 'permissions';

const { Text } = Typography;

const handleGotoReportEdit = ({ data, history, roles }) => {
  // si el reporte esta en return to sender (que ya el abogado lo reviso y se lo regreso a la enfermera)
  const isNurseWriter = isAllowed(roles, ROLES_DIVISIONS.NURSE_ONLY);

  if (
    isNurseWriter &&
    data?.extendedProps?.data?.report?.qaRevisionDate &&
    !NOT_ACCESS_REPORT_COMPARATION.includes(data.extendedProps.data.report?.report_status_id)
  )
    return history.push(`${REPORT_COMPARE_NAV.path}/${data.extendedProps.data.report.id}`);

  goFirstStep({
    sections: data?.sections || REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[data.report_type_id]],
    reportType: REPORT_TYPES_BY_ID[data.report_type_id],
    report_id: data.report_id,
    history: history,
  });
};

export default function AssignedModalData({
  open,
  onCancel,
  onDelete,
  data,
  edit,
  handleEdit,
  onEdit,
  saveEdit,
  history,
  onViewReduction,
}) {
  const {
    roles,
    userInfo: { username },
  } = useSelector(state => state.auth);

  // Permissions
  const { ASSIGNMENT_BOARD_PERMISSION } = getPermissionsBySection({
    roles: roles,
    permissions: [ASSIGNMENT_BOARD_NAME],
  });
  // end permissions

  const isAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN]);
  const isNurseManager = isAllowed(roles, [...ROLES_DIVISIONS.SUPERMANGER, ...ROLES_DIVISIONS.QA_ONLY]);
  const isAttorney = isAllowed(roles, [...ROLES_DIVISIONS.ATTORNEY_ONLY]);
  const reportid = data.report_id !== '';

  const canEditAssigment = isAdmin || data?.extendedProps?.data?.user_id === username;

  return (
    <div>
      <Modal
        visible={open}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={700}
        bodyStyle={handleEdit ? { padding: 0 } : {}}
        className="calendar-modal-container">
        {handleEdit ? (
          <AddAssignForm
            onSubmit={saveEdit}
            edit={edit}
            disabledField={{
              client: true,
              claimant: true,
              report: true,
            }}
            assignmentBoardPage
          />
        ) : (
          <div className="inner-container" data-test-id='modalInfoAssignmentBoard'>
            <h2>
              {`${data?.extendedProps?.data?.assignment_type?.name || ''}, ${
                REPORT_TYPE_NAME_BY_ID[data.report_type_id]
              }, ${data?.claimant?.claimant_name || ''} ${data?.claimant?.claimant_last_name || ''}`}
            </h2>
            <span className="title-modal-data">Assignee</span>
            <Text className="data-sub-description">{data.name + ' ' + data.lastname}</Text>
            <span className="title-modal-data">Client</span>
            <Text className="data-sub-description">{data.claimant?.client.client_name}</Text>
            <span className="title-modal-data">Claimant</span>
            <Text className="data-sub-description">
              {data.claimant && data.claimant.claimant_name + ' ' + data.claimant.claimant_last_name}
            </Text>
            <span className="title-modal-data">Report Type - Report ID</span>
            <Text className="data-sub-description">
              {REPORT_TYPE_NAME_BY_ID[data.report_type_id]} - {data?.extendedProps?.data?.report?.report_uuid || ''}
            </Text>
            <span className="title-modal-data">Due Date</span>
            <Text className="data-sub-description">{moment(data.end_date).format('MMM DD, YYYY')}</Text>
            {data?.report?.needed_by && ASSIGNMENT_BOARD_PERMISSION.viewNeedBy && (
              <>
                <span className="title-modal-data">Need By</span>
                <Text className="data-sub-description">{moment(data?.report?.needed_by).format('MMM DD, YYYY')}</Text>
              </>
            )}
            <span className="title-modal-data">Note</span>
            <Text className="data-sub-description">{data.notes}</Text>
            <div className="modal-action-btns">
              {canEditAssigment && (
                <Button
                  type="primary"
                  className="green-btn"
                  style={{ marginRight: '4%', width: '20%' }}
                  onClick={() => {
                    onEdit(data);
                  }}>
                  Edit
                </Button>
              )}
              <Button type="danger" style={{ marginRight: '4%', width: '20%' }} onClick={() => onDelete(data.id)}>
                Complete
              </Button>

              {isNurseManager && reportid && (
                <Button
                  type="primary"
                  className="green-btn"
                  style={{ marginRight: '4%', width: '20%' }}
                  onClick={() => {
                    handleGotoReportEdit({ data, history, roles });
                  }}>
                  View
                </Button>
              )}

              {isAttorney && reportid && (
                <Button
                  type="primary"
                  className="green-btn"
                  style={{ marginRight: '4%', width: '20%' }}
                  onClick={() => {
                    onViewReduction({ data });
                  }}>
                  View
                </Button>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
