import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';

// Components
import { CardAssignSetting } from './Components';
import { ModalLoading } from 'components/Modal/ModalLoading';

const AssignSalesManagers = () => {
  const { roles } = useSelector(state => state.auth);
  const salesRep = roles?.user_roles?.roles[0]?.user_roles || {};

  const [loading, setLoading] = useState(true);
  const [salesManagerInfo, setSalesManagerInfo] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const salesManager = await API.GetUserRoles(salesRep.sales_manager_id);
        setSalesManagerInfo(salesManager?.user_roles || {});
      } catch (e) {
        notifyApiError(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <ModalLoading />}

      {Object.keys(salesManagerInfo).length > 0 && (
        <CardAssignSetting
          name={`${salesManagerInfo?.given_name || ''} ${salesManagerInfo?.family_name || ''}`}
          type="Sales Manager"
          email={salesManagerInfo?.email}
          phone={salesManagerInfo?.locale}
        />
      )}
    </>
  );
};

export default AssignSalesManagers;
