import React, { Component } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Row, Typography, Col, Button, Badge, Tag } from 'antd';
import moment from 'moment';
import DatePickerSimple from 'react-datepicker';

import InputBox from 'components/InputBox';
import { RemoveIcon } from 'components/Icons';

import './index.scss';
import ClaimNumberModal from './components/ClaimNumberModal';

const { Text } = Typography;

/**
 * This component is forming report_claim_id field
 *
 * @param {object} props - props of the component
 * @param {function} props.onChange - function to set report_claim_id in Demographic component
 * @param {string} props.report_claim_id - contain existing report claim id or null
 *
 * @return {ReactNode} component with two (could be increased) fields: date picker and number field "Claim number".
 */
export default class AuthSideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      injuries: [{ date_of_injury: null, claim_number: null, insurance_company: null }],
      claimModal: false,
      claimNumbers: [''],
      editingInjury: 0,
    };
  }

  checkReportClaimantId = report_claim => {
    let isCompleted = true;
    if (report_claim !== '') {
      let report_claim_ids = report_claim.split(',');
      report_claim_ids.forEach(claimId => {
        let array = claimId.split('$');
        if (array[1] === '' || array[2] === '') {
          isCompleted = false;
        }
      });
    }
    this.props.onClaimUpdated(isCompleted);
  };

  handleDelete = index => {
    let injuriesString = this.props.report_claim_id;
    let injuries = this.getArrayFromString(injuriesString);
    injuries.splice(index, 1);
    let props = this.getStringFromArray(injuries);
    this.props.onChange(props);
  };

  /**
   * Split string for the array with objects
   *
   * @returns [{date_of_injury: Moment, claim_number: String}]
   */
  getArrayFromString = props => {
    let injuries = [];

    if (!props) {
      injuries = [{ date_of_injury: null, claim_number: [], insurance_company: '' }];
    } else {
      if (props !== '') {
        props.split(',').forEach(res => {
          let item = res.split('$');
          const claimNumbers = item[1] ? item[1].split(';') : [];
          injuries.push({
            date_of_injury: item[0] ? moment.unix(item[0]) : undefined,
            claim_number: claimNumbers.filter(num => num !== ''),
            insurance_company: item[2] || '',
          });
        });
      } else {
        injuries = [{ date_of_injury: null, claim_number: [], insurance_company: '' }];
      }
    }

    return injuries;
  };

  /**
   * Form correct string for report_claim_id
   *
   * @returns "1619601309$1234;121125;$insurance_company_name" $ is a separator
   */
  getStringFromArray = injuriesArray => {
    let props = '';
    injuriesArray.forEach((injury, i) => {
      let commaBefore = ',';

      if (i === 0) {
        commaBefore = '';
      }

      if (injury.date_of_injury || injury.claim_number || injury.insurance_company) {
        props += `${commaBefore}${
          injury.date_of_injury ? moment(injury.date_of_injury).unix() : ''
        }$${injury.claim_number.join(';')}$${injury.insurance_company}`;
      }
    });
    this.checkReportClaimantId(props);
    return props;
  };

  /**
   * Common function for forming string and sending it to Demographic component
   *
   * @param {number} index - index of the object in injuries array
   * @param {string} field - field of the object in injuries array
   * @param {string} value - new value that would be set to founded field in the array
   */
  handleEdit = (index, field, value) => {
    const injuriesString = this.props.report_claim_id;
    const injuries = this.getArrayFromString(injuriesString);
    injuries[index][field] = value;
    const props = this.getStringFromArray(injuries);
    this.props.onChange(props);
  };

  render() {
    let props = this.props.report_claim_id;
    let injuries = this.getArrayFromString(props);

    return (
      <Col className="report_claim_id">
        {injuries.map((injury, i) => {
          let isOnlyOne = this.state.injuries.length > 1;

          return (
            <Row type="flex" align="bottom" justify="space-between" className="fix-padding" key={i.toString()}>
              {injuries.length > 1 && (
                <div>
                  <Text>{i + 1}.</Text>
                </div>
              )}
              <Col md={isOnlyOne ? 7 : 8}>
                <Row className="label">
                  <Text>Injury Date</Text>
                </Row>
                <Row>
                  <DatePickerSimple
                    className="injury-date"
                    placeholderText="Select Injury Date"
                    disabled={this.props.disabled}
                    onChange={dateString => {
                      if (!dateString) {
                        return alert('Empty Date of injury is not allowed');
                      }
                      this.handleEdit(i, 'date_of_injury', dateString);
                    }}
                    selected={injury.date_of_injury ? moment(injury.date_of_injury).toDate() : undefined}
                    showMonthDropdown
                    showYearDropdown
                    minDate={new Date('2000-01-01')}
                    maxDate={Date.now()}
                  />
                </Row>
              </Col>
              <Col md={isOnlyOne ? 5 : 7}>
                <InputBox
                  classes={injury.insurance_company === '' && !this.props.disabled && !this.props.norequired ? 'add-insurance-company-error' : ''}
                  type="text"
                  label="Insurance company"
                  disabled={this.props.disabled}
                  placeholder="Please enter"
                  value={injury.insurance_company}
                  onChange={value => {
                    this.handleEdit(i, 'insurance_company', value.replace(/[^a-zA-Z\s]/gi, ''));
                  }}
                />
              </Col>
              <Col md={isOnlyOne ? 6 : 7}>
                <Badge className="claimNumsBadge" showZero count={injury.claim_number.length} overflowCount={10}>
                  <Button
                    size="large"
                    disabled={this.props.disabled}
                    className={injury.claim_number.length > 0 | this.props.norequired ? 'add-claim-number' : 'add-claim-number-error'}
                    onClick={() =>
                      this.setState({
                        claimModal: true,
                        claimNumbers: injury.claim_number.length ? injury.claim_number : [''],
                        editingInjury: i,
                      })
                    }>
                    Claim Numbers
                  </Button>
                </Badge>
              </Col>
              {injuries.length > 1 && (
                <div
                  className="remove-btn"
                  onClick={() => {
                    this.handleDelete(i);
                  }}>
                  <RemoveIcon className="icon remove-claim" />
                </div>
              )}
              {injury.claim_number.length > 0 && (
                <Text strong className={`claim-numbers ${injuries.length > 1 && 'left-space'}`}>
                  Claim numbers: &nbsp;
                  {injury.claim_number.map((num, idx) => (
                    <Tag className="claim-number" key={`${num}-${idx}`}>
                      {num}
                    </Tag>
                  ))}
                </Text>
              )}
            </Row>
          );
        })}
        <Button
          type="primary"
          className="green-btn add-icd-code_button new-claim-number"
          disabled={this.props.disabled}
          onClick={() => {
            let injuries = this.getArrayFromString(this.props.report_claim_id);
            injuries.push({
              date_of_injury: injuries[injuries.length - 1].date_of_injury,
              claim_number: [],
              insurance_company: '',
            });
            let props = this.getStringFromArray(injuries);
            this.props.onChange(props);
          }}>
          Add New
        </Button>
        <ClaimNumberModal
          claimNumbers={this.state.claimNumbers}
          visible={this.state.claimModal}
          onCancel={() => this.setState({ claimModal: false })}
          addClaimNumber={claimNums => this.handleEdit(this.state.editingInjury, 'claim_number', claimNums)}
        />
      </Col>
    );
  }
}
