/* global process */
import axios from 'axios';
import { API } from 'aws-amplify';
import queryString from 'query-string';
import store from 'redux/store';
import isAllowed, { isAttorney, ROLES_DIVISIONS } from 'layout/roles';
import moment from 'moment';

let urlForZip = 'https://services.msaprojections.com';
const ODGAPIKEY = '1f62cf5d6e0b37e3451bf4c3955a34361c261bd7';
export const sendEmailForReportReady = async (email, id) => {
  return await API.post('MyAPI', `/clientservice/reports/ready/${id}`, {
    body: { email },
  });
};
export const getDashboardData = async () => {
  return await API.get('MyAPI', `/clientservice/dashboard/getStats`);
};
export const FindACode = async (code, zip, isOwcp = false) => {
  return await API.post('MyAPI', `/clientservice/find_code`, {
    body: { code, zip, isOwcp },
  });
};
export const getAssignmentsData = async (searchQuery = '', pageIndex = 0, pageSize = 10) => {
  return await API.get('MyAPI', `/clientservice/notes?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`);
};
export const getContactTypes = async () => {
  return await API.get('MyAPI', `/contacts/type`);
};

export const getContactsByType = async params => {
  return await API.post('MyAPI', `/contacts/by-type`, { body: params });
};

export const updateAssignment = async params => {
  return await API.put('MyAPI', `/clientservice/notes`, { body: params });
};

export const updateAssignmentById = async (id, data) => {
  return await API.patch('MyAPI', `/clientservice/assignments/${id}`, { body: data });
};

export const getReportsData = async (searchQuery = '', pageIndex = 0, pageSize = 10, params) => {
  return await API.post(
    'MyAPI',
    `/clientservice/filteredreports?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`,
    { body: params }
  );
};

export const getReportsDataByClaimantId = async ({ userId, claimantId, pageIndex = 0, pageSize = 10000 }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/reports?claimant_id=${claimantId}&page=${pageIndex}&pagesize=${pageSize}${
      userId ? `&user_id=${userId}` : ''
    }`
  );
};

export const getReportsDataByUserId = async ({ claimantId, userId, pageIndex = 0, pageSize = 10000 }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/reports?claimant_id=${claimantId}&user_id=${userId}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const getReportsByType = async type => {
  return await API.get('MyAPI', `/clientservice/reports?report_type_id=${type}`);
};

export const joinODGData = async (reportId, params) => {
  return await API.post('MyAPI', `/clientservice/report/set-odg/${reportId}`, { body: params });
};

export const changeReportsELife = async (claimant_id, life_expectancy) => {
  return await API.post('MyAPI', `/clientservice/reports/change-life/${claimant_id}`, {
    body: { life_expectancy: life_expectancy },
  });
};

export const getAllTemplateReports = async ({ search, pageIndex = 0, pageSize = 10000, reportType }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/reports?search=${search ? search : ''}&is_template=true&page=${pageIndex}&pagesize=${pageSize}${
      reportType ? `report_type_id=${reportType}` : ''
    }`
  );
};

export const fetchClaimantReports = async claimantId => {
  return await API.get('MyAPI', `/clientservice/claimantreports/${claimantId}`);
};

export const getViewReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/viewreports/${reportId}`);
};

export const getReportById = async (reportId, flag) => {
  const roles = store.getState().auth;
  const isManager = isAllowed(roles.roles, ROLES_DIVISIONS.SUPERMANGER);
  if (isManager || flag) {
    return await API.get('MyAPI', `/clientservice/report/${reportId}`);
  } else {
    return await API.get('MyAPI', `/clientservice/viewreports/${reportId}`);
  }
};

export const getDraftReportWriter = async ({ idReport }) => {
  return await API.get('MyAPI', `/clientservice/draftreport/${idReport}`);
};

export const createDraftReportWrite = async ({ body }) => {
  return await API.post('MyAPI', `/clientservice/draftreport`, { body });
};

export const updateDraftReportWriter = async ({ body }) => {
  return API.put('MyAPI', `/clientservice/draftreport`, { body });
};

export const getReportByIdReduction = async ({ report_id, copy }) => {
  if (!copy) {
    return await API.get('MyAPI', `/clientservice/report/${report_id}`);
  } else {
    return await API.get('MyAPI', `/clientservice/viewreports/${report_id}`);
  }
};

export const getViewReportById = async reportId => {
  return await API.get('MyAPI', `/clientservice/viewreports/${reportId}`);
};

export const getReportcompareById = async reportId => {
  return await API.get('MyAPI', `/clientservice/reports/${reportId}`);
};
export const fetchReportAssignments = async ({ reportId, claimantId }) => {
  return await API.get('MyAPI', `/clientservice/assignments`, {
    queryStringParameters: {
      report_id: reportId,
      claimant_id: claimantId,
    },
  });
};

export const generatedReportCompare = ({ body }) => {
  return API.post('MyAPI', `/clientservice/reports/generated`, { body });
};

export const getReviewReportCompare = ({ idReport, report_type_id }) => {
  return API.get(
    'MyAPI',
    `/clientservice/previewReport/${idReport}${report_type_id ? `?report_type_id=${report_type_id}` : ''}`
  );
};

export const getCLientForId = ({ id }) => {
  return API.get('MyAPI', `/clientservice/clients?id=${id}`);
};

export const mergeClients = async params => {
  return API.post('MyAPI', '/mergeAccounts', { body: params });
};

export const getClientModeStrict = ({ client_name, tenant_id }) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/session/getClient?client_name=${client_name}&&tenant_id=${tenant_id}`
  );
};

export const suggestCorrectCompanyName = async ({ name, tenant_id }) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/session/getClientCoincidence?client_name=${name}&&tenant_id=${tenant_id}`
  );
};

export const createUserWhenLawFirmIsExists = ({ body }) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/session/signup/existingLawFirm`, body);
};

export const getClientsDataByUserId = ({ userId, searchQuery = '', pageIndex = 0, pageSize = 10 }) => {
  return API.get(
    'MyAPI',
    `/clientservice/clients?user_id=${userId}&search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};
export const getClientsData = (searchQuery = '', pageIndex = 0, pageSize = 10) => {
  if (isAttorney(store.getState().auth.roles)) {
    const userId = store.getState().auth.roles.user_roles.id;
    return API.get(
      'MyAPI',
      `/clientservice/clients?user_id=${userId}&search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
    );
  } else {
    return API.get('MyAPI', `/clientservice/clients?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`);
  }
};

export const createClient = async params => {
  return await API.post('MyAPI', `/clientservice/clients`, { body: params });
};

export const updateClient = async params => {
  return await API.put('MyAPI', `/clientservice/clients`, { body: params });
};

export const setQuickbook = async params => {
  return await API.put('MyAPI', `/clientservice/clients/set/quickbooks`, { body: params });
};

export const createQuickbooksCustomer = async ({ params, tenant_id }) => {
  return await API.post('MyAPI', `/clientservice/tenants/quickbooks?tenant_id=${tenant_id}`, { body: params });
};

export const deleteClient = async params => {
  return await API.del('MyAPI', `/clientservice/clients`, { body: params });
};

export const getTemplatesData = async (searchQuery = '', pageIndex = 0, pageSize = 10) => {
  return await API.get(
    'MyAPI',
    `/clientservice/templates?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const createTemplate = async params => {
  return await API.post('MyAPI', `/clientservice/templates`, { body: params });
};

export const updateTemplate = async params => {
  return await API.put('MyAPI', `/clientservice/templates`, { body: params });
};

export const deleteTemplate = async params => {
  return await API.del('MyAPI', `/clientservice/templates`, { body: params });
};

export const getReportSections = async report_type_id => {
  return await API.get('MyAPI', `/clientservice/report_section?report_type_id=` + report_type_id);
};

export const getTemplateOrders = async templateId => {
  return await API.get('MyAPI', `/clientservice/template_orders?template_id=${templateId}`);
};

export const createTemplateOrder = async params => {
  return await API.post('MyAPI', `/clientservice/template_orders`, { body: params });
};

export const getUsersData = async (searchQuery = '', pageIndex = 0, pageSize = 10) => {
  return await API.get('MyAPI', `/users/getUsers?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`);
};
export const getUsersDataSynchronised = async ({
  searchQuery = '',
  pageIndex = 0,
  pageSize = 10,
  filterType,
  filterLawFirm,
} = {}) => {
  return await API.get(
    'MyAPI',
    `/clientservice/users?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}${
      filterType ? `&role_ids=${filterType}` : ''
    }${filterLawFirm ? `&client_ids=${filterLawFirm}` : ''}`
  );
};

export const getUserDataById = async ({ id } = {}) => {
  return await API.get('MyAPI', `/clientservice/user/${id}`);
};

export const createTenant = async ({ params }) => {
  return await API.post('MyAPI', `/clientservice/tenants`, { body: params });
};

export const getTenantById = async ({ id }) => {
  return await API.get('MyAPI', `/clientservice/tenants/${id}`);
};

export const updateTenant = async params => {
  return await API.patch('MyAPI', `/clientservice/tenants`, { body: params });
};

export const createTenantAdmin = async ({ body }) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/admincp/customers`, { body });
};

export const createUserClientService = async ({ params }) => {
  return await API.post('MyAPI', `/clientservice/users`, { body: params });
};

export const createUser = async params => {
  return await API.post('MyAPI', `/users`, { body: params });
};
export const createUserTenant = async params => {
  // return await API.post("MyAPI", `/clientservice/tenants
  // `, { body: params });
  return axios.post(`${process.env.REACT_APP_API_URL}/public/tenants`, params);
};
export const getPricingReviews = async () => axios.get(`${process.env.REACT_APP_API_URL}/public/pricing_reviewers`);

export const createUserBankData = async params => {
  return axios.post(`${process.env.REACT_APP_API_URL}/public/payment_profile `, params);
};
export const addNewTenantPublic = async params =>
  axios.post(`${process.env.REACT_APP_API_URL}/public/add_tenant`, params);

export const synchroniseUser = async params => {
  return await API.post('MyAPI', `/clientservice/users`, { body: params });
};

export const updateUser = async (email, params) => {
  return await API.patch('MyAPI', `/users/${email}`, { body: params });
};
export const updateUserSynchronised = async params => {
  return await API.put('MyAPI', `/clientservice/users`, { body: params });
};
export const updateUserAllowed = async ({ body }) => {
  return await API.put('MyAPI', `/clientservice/allowUsers`, { body });
};
export const deleteUser = async (email, body = {}) => {
  return await API.del('MyAPI', `/users/${email}`, { body });
};
export const deleteUserSynchronised = async (id, body = {}) => {
  return await API.del('MyAPI', `/clientservice/users/${id}`, { body });
};

export const getUserVacation = async () => {
  return await API.get('MyAPI', `/clientservice/user/vacation`);
};
export const getUserVacations = async id => {
  return await API.get('MyAPI', `/clientservice/user/vacations/${id}`);
};
export const createUserVacation = async params => {
  return await API.post('MyAPI', `/clientservice/user/vacations`, { body: params });
};
export const editUserVacation = async (id, params) => {
  return await API.put('MyAPI', `/clientservice/user/vacations/${id}`, { body: params });
};
export const deleteUserVacation = async id => {
  return await API.del('MyAPI', `/clientservice/user/vacations/${id}`);
};

export const getContactsByTenant = async ({ tenant_id, exclude }) =>
  await API.get('MyAPI', `/contacts/?tenant_id=${tenant_id}&filter_type=${exclude}`);

export const getContactsData = async ({
  searchQuery = '',
  pageIndex = 0,
  pageSize = 10,
  emailSearch,
  filterType,
  filterOrganization,
}) => {
  return await API.get(
    'MyAPI',
    `/contacts?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}${emailSearch ? '&email_search=true' : ''}${
      filterType ? `&contact_type=${filterType}` : ''
    }${filterOrganization ? `&organization_name=${encodeURIComponent(filterOrganization)}` : ''}`
  );
};

export const getContactsPublic = async (searchQuery = '', pageIndex = 0, pageSize = 10, emailSearch) => {
  axios.defaults.timeout = 3000000;
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/public/contacts?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}${
      emailSearch ? '&email_search=true' : ''
    }`
  );
  return res.data;
};

export const getContactsByType2 = async (pagesize, type = '') => {
  return await API.get('MyAPI', `/contacts?pagesize=${pagesize}&contact_type_id=${escape(type)}`);
};

export const getUsersByClient = async ({ idClient, searchQuery = '', pageIndex = '', pageSize = '', filterType }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/usersByClient/${idClient}?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}${
      filterType ? `&role_ids=${filterType}` : ''
    }`
  );
};

export const createContact = async params => {
  return await API.post('MyAPI', `/contacts`, { body: params });
};

export const createShareLink = async params => {
  return await API.post('MyAPI', `/clientservice/clients/share`, { body: params });
};

export const updateContact = async (id, params) => {
  return await API.patch('MyAPI', `/contacts/${id}`, { body: params });
};

export const deleteContact = async (id, body) => {
  return await API.del('MyAPI', `/contacts/${id}`, { body });
};

export const getClaimantsData = async ({ userId, clientId, searchQuery = '', pageIndex = 0, pageSize = 10 }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/claimants?client_id=${clientId}&search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}${
      userId ? `&user_id=${userId}` : ''
    }`
  );
};

export const getAllClaimantsData = async (searchQuery = '', pageIndex = 0, pageSize = 10) => {
  return await API.get(
    'MyAPI',
    `/clientservice/claimants?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const getClaimantsDataByUserId = async (userId, searchQuery = '', pageIndex = 0, pageSize = 10) => {
  return await API.get(
    'MyAPI',
    `/clientservice/claimants?user_id=${userId}&search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const getClaimantsDataByClaimantId = async claimantId => {
  return await API.get('MyAPI', `/clientservice/claimants/${claimantId}`);
};

export const createClaimant = async params => {
  return await API.post('MyAPI', `/clientservice/claimants`, { body: params });
};

export const updateClaimant = async params => {
  return await API.put('MyAPI', `/clientservice/claimants`, { body: params });
};

export const deleteClaimant = async params => {
  return await API.del('MyAPI', `/clientservice/claimants`, { body: params });
};

export const createReport = async params => {
  return await API.post('MyAPI', `/clientservice/reports`, { body: params });
};

export const createViewReport = async params => {
  return await API.post('MyAPI', `/clientservice/viewreports`, { body: params });
};

export const updateRevisionReport = async ({ body }) => {
  return API.put('MyAPI', `/clientservice/revisionreport`, { body });
};

export const updateReport = params => {
  const roles = store.getState().auth;
  const isManager = isAllowed(roles.roles, [
    ...ROLES_DIVISIONS.SUPERMANGER,
    ...ROLES_DIVISIONS.ALL_CLIENT,
    ...ROLES_DIVISIONS.ATTORNEY_ONLY,
  ]);
  if (isManager) {
    return API.put('MyAPI', `/clientservice/reports`, { body: params });
  } else {
    return API.put('MyAPI', `/clientservice/viewreports`, { body: params });
  }
};

export const updateReportClient = params => {
  const roles = store.getState().auth;
  const isQaNurse = isAllowed(roles.roles, ROLES_DIVISIONS.QA_ONLY);

  if (isQaNurse) return API.put('MyAPI', `/clientservice/viewreports`, { body: params });

  return API.put('MyAPI', `/clientservice/reports`, { body: params });
};

export const updateReportQA = params => {
  return API.put('MyAPI', `/clientservice/viewreports`, { body: params });
};

export const updateReportNurse = params => {
  return API.put('MyAPI', `/clientservice/reports`, { body: params });
};

export const copyReport = async params => {
  const url = params.report_type_id
    ? `/clientservice/copyreport/${params.id}?report_type_id=${params.report_type_id}`
    : `/clientservice/copyreport/${params.id}`;
  return await API.get('MyAPI', url);
};

export const deleteReport = async params => {
  return await API.del('MyAPI', `/clientservice/reports`, { body: params });
};

export const getReportStatuses = async () => {
  return await API.get('MyAPI', `/clientservice/report_status`);
};

export const getNotesDataByClaimantId = async (claimantId, pageIndex = 0, pageSize = 10) => {
  return await API.get(
    'MyAPI',
    `/clientservice/notes?claimant_id=${claimantId}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const createNote = async params => {
  return await API.post('MyAPI', `/clientservice/notes`, { body: params });
};

export const updateNote = async params => {
  return await API.put('MyAPI', `/clientservice/notes`, { body: params });
};

export const deleteNote = async params => {
  return await API.del('MyAPI', `/clientservice/notes`, { body: params });
};

export const getSpeciality = async () => {
  return await API.get('MyAPI', `/clientservice/speciality`);
};

export const getMedicareDataOri = async reportId => {
  return await API.get('MyAPI', `/clientservice/medicare_cost_projection?report_id=${reportId}`);
};

export const getMedicareData = async ({ reportId, report_type_id }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/medicare_cost_projection?report_id=${reportId}${
      Number.isInteger(report_type_id) ? `&report_type_id=${report_type_id}` : ''
    }`
  );
};

export const getMedicareDataReduction = async reportId => {
  return await API.get('MyAPI', `/clientservice/medicare_cost_projection?report_id=${reportId}`);
};

export const getMedicareChangesData = async reportId => {
  return await API.get('MyAPI', `/clientservice/medicare_cost_projection_changes?report_id=${reportId}`);
};

export const getReportTypes = async () => {
  return await API.get('MyAPI', `/clientservice/report_type`);
};

export const createMedicareItem = async params => {
  const roles = store.getState().auth;
  const isManager = isAllowed(roles.roles, ROLES_DIVISIONS.SUPERMANGER);
  if (isManager) {
    return await API.post('MyAPI', `/clientservice/medicare_cost_projection`, {
      body: params,
    });
  }
};

export const createMedicareItemReduction = async params => {
  return await API.post('MyAPI', `/clientservice/medicare_cost_projection`, {
    body: params,
  });
};

export const createMedicareChangesItem = async params => {
  return await API.post('MyAPI', `/clientservice/medicare_cost_projection_changes`, {
    body: params,
  });
};

export const cloneMedicareSection = async params => {
  return await API.post('MyAPI', `/clientservice/medicare_cost_projection/clone`, {
    body: params,
  });
};

export const updateMedicareItem = async params => {
  const roles = store.getState().auth;
  const isManager = isAllowed(roles.roles, ROLES_DIVISIONS.SUPERMANGER);
  if (isManager) {
    return await API.patch('MyAPI', `/clientservice/medicare_cost_projection/${params.id}`, { body: params });
  } else {
    return await API.patch('MyAPI', `/clientservice/medicare_cost_projection_changes/${params.id}`, { body: params });
  }
};

export const updateMedicareItemReduction = async params => {
  return await API.patch('MyAPI', `/clientservice/medicare_cost_projection/${params.id}`, { body: params });
};

export const updateMedicareItemQA = async params => {
  return await API.patch('MyAPI', `/clientservice/medicare_cost_projection_changes/${params.id}`, { body: params });
};

export const updateMedicareItemNurse = async params => {
  return await API.patch('MyAPI', `/clientservice/medicare_cost_projection/${params.id}`, { body: params });
};

export const deleteMedicareItem = async medicareId => {
  return await API.del('MyAPI', `/clientservice/medicare_cost_projection/${medicareId}`);
};

export const getCPTData = async (value, id) => {
  if (value) {
    return await API.get('MyAPI', `/lookups/cpt_codes${id ? `/${id}` : ''}?search=` + value);
  }
  return await API.get('MyAPI', `/lookups/cpt_codes`);
};

export const getCPTById = async id => {
  return await API.get('MyAPI', `/lookups/cpt_codes/${id}`);
};

export const addCptCode = async params => {
  return await API.post('MyAPI', `/lookups/cpt_code`, { body: params });
};

export const getIcdCodeData = async search => {
  if (search) {
    return await API.get('MyAPI', `/lookups/icd_codes?search=` + search);
  }
  return await API.get('MyAPI', `/lookups/icd_codes`);
};

export const getJuridictionStateList = async () => {
  return await API.get('MyAPI', `/clientservice/juridiction_states`);
};

export const getIcdCodeNameByReportId = async reportId => {
  return await API.get('MyAPI', `/lookups/bulk_icd_codes/${reportId}`);
};

export const getMedicareInfoData = async reportId => {
  return await API.get('MyAPI', `/clientservice/report/${reportId}/total`);
};

export const getNonMedicareInfoData = async reportId => {
  return await API.get('MyAPI', `/clientservice/report/${reportId}/non_medicare_total`);
};

export const setUpReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/setUpReport/${reportId}`, { timeout: 60000 });
};
export const setUpMCPReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/setupMcp/${reportId}`);
};
export const setUpSurgicalReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/setupSurgical/${reportId}`);
};
export const setUpBD1 = async reportId => {
  return await API.get('MyAPI', `/clientservice/setUpDemandReport/${reportId}`);
};
export const setUpLegalDemandTemplateReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/setUpLegalDemandTemplateReport/${reportId}`);
};
export const setUpEarlyInterventionReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/setUpEarlyInterventionReport/${reportId}`);
};
export const setUpMcpMsaReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/setUpMcpMsaCombo/${reportId}`);
};
export const setUpMsaReport = async reportId => {
  return await API.get(
    'MyAPI',
    `/clientservice/downloadReport/${reportId}
  `
  );
};
export const setUpReductionReport = async reportId => {
  return await API.get(
    'MyAPI',
    `/clientservice/setUpReductionReport/${reportId}
  `
  );
};
export const setUpLetterReport = async reportId => {
  return await API.get(
    'MyAPI',
    `/clientservice/setUpLetterReport/${reportId}
  `
  );
};
export const setUpLifeCarePlanReport = async reportId => {
  return await API.get(
    'MyAPI',
    `/clientservice/setUpLifeCarePlan/${reportId}
  `
  );
};
export const getReportFilesReductionProcess = async ({ reportId }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/files/reportCompleted/${reportId}
  `
  );
};

export const downloadReport = async reportId => {
  return await API.get('MyAPI', `/clientservice/downloadReport/${reportId}`);
};

export const signUp = async ({ params, tenant_id }) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/session/signup?tenant_id=${tenant_id}`, params);
};

export const getListContactType = async ({ tenant_id }) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/public/contacts/types?tenant_id=${tenant_id}`);
};

export const addActiveUser = async params => {
  return axios.post(`${process.env.REACT_APP_API_URL}/session/active_user`, params);
};

export const isActiveUser = async param => {
  return axios.get(`${process.env.REACT_APP_API_URL}/session/active_user?user_id=${param}`);
};
export const isActiveUserV2 = async id => {
  return await API.get('MyAPI', `/clientservice/active_user/is_active/${id}`);
};
export const isActiveTenant = async () => await API.get('MyAPI', '/clientservice/checkIfSubscriptionStatus');

export const setActiveUser = async params => {
  return await API.put('MyAPI', `/clientservice/active_user`, {
    body: params,
  });
};

export const deleteActiveUser = async params => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/session/active_user`, {
    data: params,
  });
};

export const updateActiveUser = async params => {
  return axios.put(`${process.env.REACT_APP_API_URL}/session/active_user`, params);
};

export const getLifeSources = async () => {
  return await API.get('MyAPI', `/lookups/source`);
};

export const getLifeTables = async () => {
  return await API.get('MyAPI', `/lookups/life_table`);
};

export const getLifeExpect = async (claimantId, params) => {
  return await API.post('MyAPI', `/lookups/years_left?claimant_id=${claimantId}`, {
    body: params,
  });
};

export const getUnitForms = async () => {
  return await API.get('MyAPI', `/clientservice/unit_form`);
};

//report contacts
export const addReportContact = async (report_id, contact_id, type) => {
  return await API.post('MyAPI', `/clientservice/reportcontacts`, {
    body: { report_id, contact_id, type },
  });
};
export const removeReportContact = async (report_id, contact_id, type) => {
  return await API.del('MyAPI', `/clientservice/reportcontacts`, {
    body: { report_id, contact_id, type },
  });
};
export const getReportContacts = async report_id => {
  return await API.get('MyAPI', `/clientservice/reportcontacts?report_id=${report_id}`);
};

export const getPresignedUrl = async fileName => {
  return await API.post('MyAPI', `/clientservice/presign`, {
    body: { fileName, fileType: 'application/pdf' },
  });
};
export const zipFiles = async data => {
  axios.defaults.timeout = 3000000;
  return await axios.post(urlForZip + '/zip', data);
};

export const getAllAssignments = async queryString => {
  return await API.get('MyAPI', `/clientservice/assignments${queryString}`);
};

export const getAllAssignmentsType = async () => {
  return await API.get('MyAPI', `/clientservice/assignment_types`);
};

export const getAllClaimants = async () => {
  return await API.get(
    'MyAPI',
    `/clientservice/claimants
    `
  );
};
export const GetClaimantByClientId = async (id, pageIndex = 0, pageSize = 1000) => {
  return await API.get(
    'MyAPI',
    `/clientservice/claimants?client_id=${id}&page=${pageIndex}&pagesize=${pageSize}
    `
  );
};

export const getReportsByClaimantId = async id => {
  return await API.get(
    'MyAPI',
    `/clientservice/reports?claimant_id=${id}&pagesize=10000
    `
  );
};
export const getAllReports = async () => {
  return await API.get(
    'MyAPI',
    `/clientservice/reports
    `
  );
};

// obtener los usuarios mediante
export const getUsersBySpecialty = async ({ specialtyId }) => {
  return await API.get('MyAPI', `/clientservice/users?specialty=${specialtyId}`);
};

export const getUsersByAssignmentTypeId = async id => {
  return await API.get('MyAPI', `/clientservice/users/assignment_type/${id}`);
};

export const createAssignementUser = async params => {
  return await API.post('MyAPI', `/clientservice/assignments`, { body: params });
};

export const deleteAssignmentUser = async (id, body) => {
  return await API.del('MyAPI', `/clientservice/assignments/${id}`, { body });
};

export const ReportFileUpload = async (claimantId, params) => {
  return await API.post('MyAPI', `/clientservice/files${claimantId ? '/' + claimantId : ''}`, {
    body: params,
  });
};

export const getNurseRoles = async () => {
  return await API.get('MyAPI', `/clientservice/roles?isNurse=true`);
};

export const AppendRoles = async params => {
  return await API.post('MyAPI', `/clientservice/roles/bulk`, {
    body: params,
  });
};
export const GetUserRoles = async id => {
  return await API.get('MyAPI', `/clientservice/user/${id}/roles`);
};
export const DeleteRoles = async (user_id, role_id) => {
  return await API.del('MyAPI', `/clientservice/user/${user_id}/role/${role_id}`);
};
export const AddRole = async (user_id, role_id, body) => {
  return await API.post('MyAPI', `/clientservice/user/${user_id}/role/${role_id}`, {
    body,
  });
};
export const EditRole = async (user_id, role_id, body) => {
  return await API.patch('MyAPI', `/clientservice/user/${user_id}/role/${role_id}/edit`, {
    body,
  });
};
export const getTenantCompanyProfile = async id => {
  return await API.get('MyAPI', `/clientservice/tenants/${id}`);
};
export const updateTenantProfile = async params => {
  return await API.patch('MyAPI', `/clientservice/tenants`, { body: params });
};
export const getStates = async () => {
  axios.defaults.timeout = 3000000;
  return await axios.get(`${process.env.REACT_APP_API_URL}/public/states`);
};
export const GetReportFiles = async claimantId => {
  return await API.get('MyAPI', `/clientservice/files` + (claimantId ? '?claimant_id=' + claimantId : ''));
};
export const deleteFile = async (fileId, params) => {
  return await API.del(
    'MyAPI',
    `/clientservice/files/${fileId}`,
    params && {
      body: params,
    }
  );
};
export const getPresignedLogo = async data => {
  axios.defaults.timeout = 3000000;
  return await axios.post(`${process.env.REACT_APP_API_URL}/public/presign`, data);
};
export const getPresignedPublicUrlLogo = async key => {
  axios.defaults.timeout = 300000;
  return await axios.get(`${process.env.REACT_APP_API_URL}/public/presignurlbykey`, { params: { key } });
};

//after uppload to s3 get presigned url for display in img
export const getPresignedLogoUrl = async key => {
  return await API.get('MyAPI', `/clientservice/files/presignbykey`, {
    queryStringParameters: {
      key,
    },
  });
};

export const updateUploadedFile = async (idFile, params) => {
  return await API.patch('MyAPI', `/clientservice/files/${idFile}`, {
    body: params,
  });
};

export const getFile = async claimantId => {
  return await API.get('MyAPI', `/clientservice/files/${claimantId}`);
};

export const getPresignLogoUrl = async id => {
  return await API.get('MyAPI', `/clientservice/files/presigned/${id}`);
};
export const getPresignUrlFile = async data => {
  return await API.post('MyAPI', '/clientservice/files/presign', {
    body: data,
  });
};

export const uploadFileToUser = async ({ name, type, url, idUser }) => {
  return await API.post('MyAPI', `/clientservice/files/user/${idUser}`, {
    body: {
      name,
      type,
      url,
    },
  });
};

export const getFileByUser = async ({ path, userId }) => {
  return await API.get('MyAPI', `/clientservice/files/user/${path}/${userId}`);
};

export const GetAllRatedAgesUsers = async () => {
  return await API.get('MyAPI', `/clientservice/users/role/5`); // rol Rated Age
};

export const getUsersByRoleId = async ({ roleId }) => {
  return await API.get('MyAPI', `/clientservice/users/role/${roleId}`);
};

export const CreateRateAgeUserAssignment = async params => {
  return await API.post('MyAPI', `/clientservice/assignments`, {
    body: params,
  });
};

export const CreateAssignementFiles = async params => {
  return await API.post('MyAPI', `/clientservice/assignment/files/bulk`, {
    body: params,
  });
};
export const CreateAssignementFile = async (file_id, assignment_id) => {
  return await API.post('MyAPI', `/clientservice/assignment/files`, {
    body: { file_id, assignment_id },
  });
};
export const GetCreatedAssignement = async id => {
  return await API.get(
    'MyAPI',
    `/clientservice/assignments?assignment_type_id=3&claimant_id=${id}&assignment_files=true`
  );
};

export const GetCreatedAnnuityQuote = async id => {
  return await API.get(
    'MyAPI',
    `/clientservice/assignments?assignment_type_id=4&claimant_id=${id}&assignment_files=true`
  );
};

export const GetUserAssignments = async queryString => {
  return await API.get('MyAPI', `/clientservice/assignments/user${queryString}`);
};

export const UpdateCreatedAssignement = async (id, params) => {
  return await API.patch('MyAPI', `/clientservice/assignments/${id}`, {
    body: params,
  });
};
export const deleteAssignement = async (id, params) => {
  return await API.del('MyAPI', `/clientservice/assignments/${id}`, { body: params });
};

export const getAssigmentFiles = async assignment_id => {
  return await API.get(
    'MyAPI',
    `/clientservice/assignment/files${assignment_id ? '?assignment_id=' + assignment_id : ''}`
  );
};
export const DeleteAssigmentFile = async id => {
  return await API.del('MyAPI', `/clientservice/assignment/files/${id}`);
};

export const createResourceLInk = async params => {
  if (isAttorney(store.getState().auth.roles)) {
    params.resource_link_type_id = 2;
    return await API.post('MyAPI', `/clientservice/resource_links`, { body: params });
  } else {
    params.resource_link_type_id = 1;
    return await API.post('MyAPI', `/clientservice/resource_links`, { body: params });
  }
};

export const SearchResourceLink = async (word = '', index = 0) => {
  if (isAttorney(store.getState().auth.roles)) {
    return await API.get(
      'MyAPI',
      `/clientservice/resource_links?resource_link_type_id=2&search=${escape(word)}&page=${index}&limit=10`
    );
  } else {
    return await API.get(
      'MyAPI',
      `/clientservice/resource_links?resource_link_type_id=1&search=${escape(word)}&page=${index}&limit=10`
    );
  }
};

export const GetResourceLink = async () => {
  if (isAttorney(store.getState().auth.roles)) {
    return await API.get('MyAPI', `/clientservice/resource_links?resource_link_type_id=2`);
  } else {
    return await API.get('MyAPI', `/clientservice/resource_links?resource_link_type_id=1`);
  }
};

export const UpdateResourceLink = async (id, params) => {
  if (isAttorney(store.getState().auth.roles)) {
    params.resource_link_type_id = 2;
  } else {
    params.resource_link_type_id = 1;
  }
  return await API.patch('MyAPI', `/clientservice/resource_links/${id}`, {
    body: params,
  });
};

export const DeleteResourceLink = async id => {
  return await API.del('MyAPI', `/clientservice/resource_links/${id}`);
};

// export const SearchAssignedUser = async (claimantId, client_id, note, user_id, assignement_status) => {
//   return await API.get("MyAPI", `/clientservice/assignments?${client_id ? "client_id=" + client_id : ""}
//     &${claimantId ? "claimantId=" + claimantId : ''}
//     &${note ? "note=" + note : ''}
//     &${user_id ? "user_id=" + user_id : ''}
//     &${assignement_status ? "assignement_status=" + assignement_status : ''}

//     `);
// };

export const SearchAssignedUser = async (claimant_id, assignement_type_id, note) => {
  let filterDatas = {};

  if (claimant_id) {
    filterDatas.claimant_id = claimant_id;
  }

  if (assignement_type_id) {
    filterDatas.assignement_type_id = assignement_type_id;
  }
  if (note) {
    filterDatas.note = note;
  }

  const result = queryString.stringify(filterDatas);
  return await API.get('MyAPI', `/clientservice/assignments?${result}`);
};

export const GetAssignementsStatuses = async () => {
  return await API.get('MyAPI', `/clientservice/assignments/statuses`);
};
export const UpdateAssignement = async (id, params) => {
  return await API.patch('MyAPI', `/clientservice/assignments/${id}`, {
    body: params,
  });
};
export const SearchAssignement = async (index = 0) => {
  return await API.get('MyAPI', `/clientservice/assignments?page=${index}&limit=10`);
};

export const createComment = async params => {
  return await API.post('MyAPI', `/clientservice/claimant_comments`, {
    body: params,
  });
};

export const getAllComments = async () => {
  return await API.get('MyAPI', `/clientservice/claimant_comments`);
};
export const deleteComment = async id => {
  return await API.del('MyAPI', `/clientservice/claimant_comments/${id}`);
};
export const updateComment = async (id, params) => {
  return await API.patch('MyAPI', `/clientservice/claimant_comments/${id}`, {
    body: params,
  });
};
export const getInternalComments = async is_external => {
  return await API.get('MyAPI', `/clientservice/claimant_comments?is_external=${is_external}`);
};

export const getExternalComments = async () => {
  return await API.get('MyAPI', `/clientservice/claimant_comments?is_external=true`);
  //copy claimant
};

export const loadMoreComments = async (pageIndex, pageSize, is_external, id) => {
  return await API.get(
    'MyAPI',
    `/clientservice/claimant_comments?page=${pageIndex}&limit=${pageSize}&is_external=${is_external}&claimant_id=${id}`
  );
};
// export const loadMoreComments = async (
//   pageIndex,
//   pageSize = 4
// ) => {
//   return await API.get(
//     "MyAPI",
//     `/clientservice/claimant_comments?page=${pageIndex}&limit=${pageSize}`
//   );
// };

export const copy_claimant = async (client_id, claimantId) => {
  return await API.post('MyAPI', `/clientservice/claimants/copy/${claimantId}`, {
    body: { client_id },
  });
};

export const GetOdgGuidelines = async code => {
  let data;
  try {
    data = await axios.post(
      `https://www.odgwebservices.com/restws/procsum/format/json`,
      `<procsum><icdcodes><code>${code}</code></icdcodes></procsum>`,
      {
        headers: {
          'X-ODG-KEY': ODGAPIKEY,
        },
      }
    );
  } catch (error) {
    data = { data: [] };
  }
  return data;
};
export const CreateReportDocuments = async params => {
  return await API.post('MyAPI', `/clientservice/report/files`, {
    body: params,
  });
};
export const getReportDocuments = async report_id => {
  return await API.get('MyAPI', `/clientservice/report/files/${report_id}`);
};
export const deleteReportDocument = async (report_id, body) => {
  return await API.del('MyAPI', `/clientservice/report/files/${report_id}`, { body });
};

export const deleteReportDocumentByFileId = async body => {
  return await API.del('MyAPI', `/clientservice/report/files`, { body });
};

export const BulkUpdateReportDocuments = async list => {
  return await API.patch('MyAPI', `/clientservice/report/files/bulk`, {
    body: list,
  });
};
export const GenerateReport = async report_id => {
  return await API.post('MyAPI', `/clientservice/files/merge/${report_id}`, { timeout: 60000 });
};
export const GenerateAllReport = async ({ claimantId }) => {
  return await API.get('MyAPI', `/clientservice/files/mergereportspdf/${claimantId}`, { timeout: 60000 });
};

export const DownloadAllReportFiles = async ({ listIdFiles }) => {
  return await API.post('MyAPI', `/clientservice/files/zip`, { body: { ids: listIdFiles } });
};

export const createPricingGroupName = async params => {
  return await API.post('MyAPI', `/clientservice/pricing_groups`, { body: params });
};

export const getResourceGroups = async (limit = 10, page = 0) => {
  return await API.get('MyAPI', `/clientservice/pricing_groups?limit=${limit}&page=${page}`);
};
export const deletePricingGroup = async name => {
  return await API.del('MyAPI', `/clientservice/pricing_groups/${name}`);
};
export const updatePricingGroup = async (id, params) => {
  return await API.patch('MyAPI', `/clientservice/pricing_groups/${id}`, {
    body: params,
  });
};
export const cretePricingPlan = async params => {
  return await API.post('MyAPI', `/clientservice/surgical_pricing_plans`, {
    body: params,
  });
};
export const createSurgicalPricingPlan = async params => {
  return await API.post('MyAPI', `/clientservice/surgical_pricing_plans_levels/bulk`, {
    body: params,
  });
};

export const getSurgicalPlans = async (group_id, page = 0, limit = 10) => {
  return await API.get(
    'MyAPI',
    `/clientservice/surgical_pricing_plans?pricing_group_id=${group_id}&page=${page}&limit=${limit}`
  );
};

export const deleteSurgicalPlan = async id => {
  return await API.del('MyAPI', `/clientservice/surgical_pricing_plans/${id}`);
};

export const updateSurgicalPlanData = async (id, params) => {
  return await API.patch('MyAPI', `/clientservice/surgical_pricing_plans/${id}`, {
    body: params,
  });
};
export const deleteSurgicalPlanLevel = async id => {
  return await API.del('MyAPI', `/clientservice/surgical_pricing_plans_levels/${id}`);
};
export const updateSurgicalPlanLevel = async params => {
  return await API.patch('MyAPI', `/clientservice/surgical_pricing_plans_levels/bulk`, { body: params });
};
export const getPriceForSurgical = async (report_id, code_id) => {
  return await API.get('MyAPI', `/clientservice/surgical_pricing_plans/report/${report_id}/code/${code_id}`);
};
export const getResourceLinksByReport = async id => {
  return await API.get('MyAPI', `/clientservice/report/resource_links/${id}`);
};
export const addReportResourceLinks = async (report_id, resource_link_id) => {
  return await API.post('MyAPI', `/clientservice/report/resource_links`, {
    body: {
      report_id,
      resource_link_id,
    },
  });
};

export const deleteReportResourceLinks = async id => {
  return await API.del('MyAPI', `/clientservice/report/resource_links/${id}`);
};

export const searchMedication = async ({ search, page, limit = 10 }) => {
  return await API.get('MyAPI', `/clientservice/report/medication/search`, {
    queryStringParameters: {
      search,
      page,
      limit,
    },
  });
};
export const searchReport = async ({ search, page = 0, limit = 10 }) => {
  return await API.get('MyAPI', `/clientservice/searchreport`, {
    queryStringParameters: {
      search,
      page,
      limit,
    },
  });
};

export const addReportSupportingDocuments = async params => {
  return await API.post('MyAPI', `/clientservice/report/files/supporting`, {
    body: params,
  });
};
export const getBlueBook = async (cpt, zip) => {
  return await API.get('MyAPI', `/clientservice/procedures/cpt_code/${cpt}/zip_code/${zip}`);
};

export const getQueueOfClaimants = async () => {
  return await API.get('MyAPI', `/clientservice/claimants/queue`);
};
export const ReportCabinet = async (createdAt, endDay) => {
  return await API.get('MyAPI', `/clientservice/reports/cabinet?createdat=${createdAt}&endday=${endDay}`);
};

export const getReportFiles = async id => {
  return await API.get(
    'MyAPI',
    `/clientservice/files/report/${id}
  `
  );
};

export const getReportsByMonth = async monthSelected => {
  return await API.get('MyAPI', `/clientservice/dashboard/getStatsPerMonth/${monthSelected}`);
};

export const searchReportByDate = async ({ start = '', end = '', search = '', limit = 10, page = 0 }) => {
  return await API.get(
    'MyAPI',
    `/clientservice/searchreport?first_date=${start}&second_date=${end}&search=${search}&limit=${limit}&page=${page}
  `
  );
};

export const createTemplateField = async params => {
  return await API.post('MyAPI', `/clientservice/template_fields`, { body: params });
};

export const getTemplateFields = async (id, section_id) => {
  return await API.get(
    'MyAPI',
    `/clientservice/template_fields?report_template_id=${id}&report_section_id=${section_id}`
  );
};

export const deleteTemplateField = async id => {
  return await API.del('MyAPI', `/clientservice/template_fields/${id}`);
};
export const updateTemplateField = async (id, params) => {
  return await API.patch('MyAPI', `/clientservice/template_fields/${id}`, {
    body: params,
  });
};

export const GetFieldValues = async report_id => {
  return await API.get('MyAPI', `/clientservice/report_extra_field_values?report_id=${report_id}`);
};
export const EditFields = async (id, body) => {
  return await API.patch('MyAPI', `/clientservice/report_extra_field_values/${id}`, {
    body,
  });
};
export const AddExtraFieldValue = async (template_fields_id, report_id, value) => {
  return await API.post('MyAPI', '/clientservice/report_extra_field_values', {
    body: {
      template_fields_id,
      report_id: parseInt(report_id),
      value,
    },
  });
};

export const getChatClaimant = async claimantId => {
  return await API.get('MyAPI', `/clientservice/chat/claimant/${claimantId}`);
};

// Endpoint MARKETING
export const getMarketingSection = async () => {
  return await API.get('MyAPI', '/clientservice/marketing_sections');
};

export const createMarketingSection = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/marketing_sections', {
    body,
  });
};

export const updateMarketingSection = async ({ body }) => {
  return await API.put('MyAPI', '/clientservice/marketing_sections', {
    body,
  });
};

export const deleteMarketingSection = async ({ body }) => {
  return await API.del('MyAPI', `/clientservice/marketing_sections/${body}`);
};

export const deleteAllMarketingSection = async ({ body }) => {
  return await API.del('MyAPI', '/clientservice/marketing_sections', { body });
};

export const getMarketing = async () => {
  return await API.get('MyAPI', '/clientservice/marketing');
};

export const createMarketing = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/marketing', {
    body,
  });
};

export const updateMarketing = async ({ body }) => {
  return await API.put('MyAPI', '/clientservice/marketing', {
    body,
  });
};

export const deleteMarketing = async ({ id }) => {
  return await API.del('MyAPI', `/clientservice/marketing/${id}`);
};

export const getOrderMaterial = async () => {
  return await API.get('MyAPI', '/clientservice/marketing_sections/order');
};

export const updateOrderMaterial = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/marketing_sections/order', {
    body,
  });
};

export const downloadDocumentMarketing = async ({ url, nameDocument }) => {
  return axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nameDocument);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

// Tutorials
export const getTutorials = async () => {
  return await API.get('MyAPI', '/clientservice/tutorial');
};

export const getTutorialsSection = async () => {
  return await API.get('MyAPI', '/clientservice/tutorial_sections');
};

export const deleteTutorials = async ({ id }) => {
  return await API.del('MyAPI', `/clientservice/tutorial/${id}`);
};

export const createTutorialSection = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/tutorial_sections', {
    body,
  });
};

export const updateOrderMaterialTutorial = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/tutorial_sections/order', {
    body,
  });
};

export const updateTutorialSection = async ({ body }) => {
  return await API.put('MyAPI', '/clientservice/tutorial_sections', {
    body,
  });
};

export const deleteTutorialSection = async ({ body }) => {
  return await API.del('MyAPI', `/clientservice/tutorial_sections/${body}`);
};

export const deleteAllTutorialSection = async ({ body }) => {
  return await API.del('MyAPI', '/clientservice/tutorial_sections', { body });
};

export const createTutorial = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/tutorial', {
    body,
  });
};

export const updateTutorial = async ({ body }) => {
  return await API.put('MyAPI', '/clientservice/tutorial', {
    body,
  });
};

// Template life care plan
export const getLayoutLifeCarePlan = async () => {
  return await API.get('MyAPI', '/clientservice/template/sections');
};

export const getTemplateLifeCarePlanByReportId = async ({ idReport }) => {
  return await API.get('MyAPI', `/clientservice/template/report/${idReport}`);
};
export const getTemplateAndTableLifeCarePlanByReportId = async ({ idReport }) => {
  return await API.get('MyAPI', `/clientservice/template/layout/table/report/${idReport}`);
};
export const createTemplateLifeCarePlan = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/template', {
    body,
  });
};

export const editTemplateLifeCarePlanStructure = async ({ body }) => {
  return await API.put('MyAPI', `/clientservice/template`, { body });
};

export const deleteTemplateLifeCarePlanStructure = async ({ idStructure }) => {
  return await API.del('MyAPI', `/clientservice/template/${idStructure}`);
};

export const deleteStructureReportLCP = async ({ report_id }) => {
  return await API.del('MyAPI', `/clientservice/all/template/${report_id}`);
};

export const createTemplateLifeCarePlanTable = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/template/table', { body });
};

export const findTemplateLifeCarePlanTable = async ({ idReport }) => {
  return await API.get('MyAPI', `/clientservice/template/table/report/${idReport}`);
};

export const updateTemplateLifeCarePlanTable = async ({ body }) => {
  return await API.put('MyAPI', `/clientservice/template/table`, { body });
};

export const updateOrderTemplateLifeCarePlanTable = async ({ body }) => {
  return await API.post('MyAPI', `/clientservice/template/table/order`, { body });
};

export const deleteTemplateLifeCarePlanTable = async ({ idTable, reportId, tableType }) => {
  return await API.del('MyAPI', `/clientservice/template/table/${idTable}/${reportId}/${tableType}`);
};

export const getTemplateCreatedLCP = async ({ id }) => {
  return await API.get('MyAPI', `/clientservice/clone/template/${id}`);
};

export const getFieldByReportTypeId = async ({ reportTypeId }) => {
  return await API.get('MyAPI', `/clientservice/section_fields/${reportTypeId}`);
};

// Life care plan report
export const getReportLifeCarePlan = async ({ idReport }) => {
  return await API.get('MyAPI', `/clientservice/report/lifecareplan/${idReport}`);
};

export const assignTemplateInReportLCP = async ({ body }) => {
  return await API.post('MyAPI', `/clientservice/reports/lifecareplan`, { body });
};

// Parent folder
export const createParentFolder = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/client_folder', { body });
};

export const getAllParentFolder = async ({ searchQuery, page = 0, pageSize = 10, model } = {}) => {
  return await API.get(
    'MyAPI',
    `/clientservice/client_folder/?page=${page}&pagesize=${pageSize}${model ? `&model=${model}` : ''}${
      searchQuery ? `&search=${searchQuery}` : ''
    }`
  );
};

export const getClientWithoutFolder = async ({ pageSize = 10 } = {}) => {
  return await API.get('MyAPI', `/clientservice/client_folder/clientsWithoutFolder?pagesize=${pageSize}`);
};

export const getParentFolderById = async ({ id, model }) => {
  return await API.get('MyAPI', `/clientservice/client_folder/${id}${model ? `?model=${model}` : ''}`);
};

export const deleteParentFolder = async ({ id }) => {
  return await API.del('MyAPI', `/clientservice/client_folder/${id}`);
};

export const updateParentFolder = async ({ body, id }) => {
  return await API.patch('MyAPI', `/clientservice/client_folder/${id}`, { body });
};

export const assigUserToClient = async ({ body }) => {
  return await API.post('MyAPI', '/clientservice/client_folder/adduser', { body });
};

export const assigUserToClaimant = async ({ user_id, claimant_id }) => {
  return await API.post('MyAPI', '/clientservice/claimants/adduser', { body: { user_id, claimant_id } });
};

// Litigation Attorney
export const getAllLitigationAttorney = async ({ search, visibility, pagesize = 10, pageIndex = 0, range, group }) => {
  let params = `?pagesize=${pagesize}&page=${pageIndex}`;
  params += visibility !== undefined ? `&visibility=${visibility}` : '';
  params += range ? '&range=true' : '';
  params += group ? '&group=true' : '';
  params += search ? `&search=${search}` : '';

  return await API.get('MyAPI', `/clientservice/litigationAttorneys${params}`);
};

// Notification management
export const getNotificationManagement = async ({ idUser }) => {
  return await API.get('MyAPI', `/clientservice/notifications/getNotificationUser/${idUser}`);
};

export const createNotificationManagement = async ({ notification_type_id, user_id }) => {
  return await API.post('MyAPI', `/clientservice/notifications/addNotAllowNotificationUser`, {
    body: {
      notification_type_id,
      user_id,
    },
  });
};

export const updateNotificationManagement = async ({ notification_allow_id, is_allowed }) => {
  return await API.post('MyAPI', '/clientservice/notifications/updateNotAllowNotificationUser', {
    body: {
      notification_allow_id,
      is_allowed,
    },
  });
};

export const getPublicKeyAuthorize = async () => {
  return await axios.get(`${process.env.REACT_APP_API_URL}/paymentservie/merchantAuthenticationType`);
};

export const getCustomerProfileByLawFirm = async ({ idLawFirm }) => {
  return await API.get('MyAPI', `/paymentservie/getClientProfile?client_id=${idLawFirm}`);
};

export const getCustomerProfileByUser = async ({ id }) => {
  return await API.get('MyAPI', `/paymentservie/getUserProfile?customer_profile_id=${id}`);
};

export const getListCredictCardByLawFirm = async ({ idLawFirm }) => {
  return await API.get('MyAPI', `/paymentservie/getCustomerPaymentProfileList/${idLawFirm}`);
};

export const getListCredictCardById = async ({ id }) => {
  return await API.get('MyAPI', `/paymentservie/getCustomerPaymentUserProfileList?customer_profile_id=${id}`);
};

export const addCreditCard = async ({ profileCustomerId, token, defaultPayment, client_id }) => {
  return await API.post('MyAPI', '/paymentservie/createCustomerPaymentProfile', {
    body: {
      profileCustomerId,
      token,
      defaultPayment,
      client_id,
    },
  });
};

export const addCreditCardWithUser = async ({
  profileCustomerId,
  token,
  user_id,
  tenant_id,
  default_payment,
  billingInfo,
}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/paymentservie/createCustomerPaymentProfile`, {
    profileCustomerId,
    token,
    user_id,
    tenant_id,
    default_payment,
    billingInfo,
  });
};

export const createPurchases = async ({ payment_invoices_id, customer, invoice_id, service_type_id }) =>
  await API.post('MyAPI', '/clientservice/createPurchase', {
    body: {
      date: moment().format('YYYY-MM-DD'),
      customer,
      invoice_id,
      service_type_id,
      payment_invoices_id,
    },
  });

export const payInvoiceWithCreditCard = async ({ customerProfileId, paymentProfileId, invoice_id }) => {
  return await API.post('MyAPI', '/paymentservie/createTransactionQuickbooks', {
    body: {
      customerProfileId,
      paymentProfileId,
      invoice_id,
    },
  });
};

export const deleteCreditCard = async ({ profileCustomerPaymentId, profileCustomerId }) => {
  return await API.del('MyAPI', `/paymentservie/deleteClientProfile/${profileCustomerId}/${profileCustomerPaymentId}`);
};

export const changeStatusInvoices = async ({ invoiceId }) => {
  return await API.post('MyAPI', '/transactions/payInvoice', { body: { invoice_id: invoiceId } });
};

export const createCustomerProfile = async ({ body }) => {
  return await API.postt('MyAPI', '/paymentservie/createClientProfile', body);
};

export const updateDefaultCard = async ({ profileCustomerPaymentId, defaultPayment, profileCustomerId }) => {
  return await API.put('MyAPI', '/paymentservie/updateCustomerPaymentProfile', {
    body: {
      profileCustomerPaymentId,
      defaultPayment,
      profileCustomerId,
    },
  });
};

export const getTransactionHistory = async ({ page, pageSize = 10 }) =>
  await API.get('MyAPI', `/clientservice/getPurchase?page=${page}&limit=${pageSize}`);

export const getMonthToDateCostService = async ({ page, pageSize = 10 }) =>
  await API.get('MyAPI', `/clientservice/getPurchaseEstimate?page=${page}&limit=${pageSize}`);

export const downloadTransactionService = async ({ id }) => await API.get('MyAPI', `/clientservice/getInvoice/${id}`);

// Pricing Global
export const getPricingGlobal = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/public/packages`);
};

// Bill Due
export const getBillDueService = async () => await API.get('MyAPI', '/clientservice/package/billdue');

// Subscription
export const paySubcriptionWithCreditCard = async ({
  customer_profile_id,
  customer_payment_profile_id,
  package_id,
  customer,
}) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/paymentservie/createTransactionPackage`, {
    customer_profile_id,
    customer_payment_profile_id,
    package_id,
    customer,
  });
};

export const updateSubscriptionService = async ({ tenant_id, package_id, customer, limit_reviewers }) =>
  await API.post('MyAPI', '/paymentservie/updateTransactionPackage', {
    body: {
      tenant_id,
      package_id,
      customer,
      limit_reviewers,
    },
  });

export const cancelSubcriptionService = async ({ tenant_id }) =>
  API.post('MyAPI', '/paymentservie/requestCancelSubscription', {
    body: {
      tenant_id,
    },
  });

// Dashboard
export const getDashboardCounts = async () => API.get('MyAPI', '/clientservice/dashboard/getCounts');

// Support
export const sendSupportService = async ({ attachments, message }) =>
  API.post('MyAPI', '/clientservice/support', {
    body: {
      attachments,
      message,
    },
  });
