/* global process  */
import React, { useState, useEffect } from 'react';
import { useAcceptJs } from 'react-acceptjs';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import {Country, State} from 'country-state-city';
// Components
import InputBox from 'components/InputBox';
import { CustomBoxCheckBox } from 'components/CustomBoxCheckBox';
import { InputCardNumber } from 'components/InputCardNumber';
import { Button, Select } from 'antd';
import { ModalLoading } from 'components/Modal/ModalLoading';

// Hooks
import { useCreditCard } from 'hooks';

// Utils
import { formatExpired, validInputNumber } from 'utils/utils';
import { notifyApiError } from 'utils/notification';
import * as API from 'utils/api';

const FormAddCreditCard = ({
  publicKey,
  textBtn,
  afterAdd,
  goBack,
}) => {
  const { userInfo } = useSelector(state => state.auth);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [selectDefault, setSelectDefault] = useState(false);
  const [data, setData] = useState({}); 
  const { loadingCreditCard, addCreditCardWithUser } = useCreditCard();
  const { Option } = Select;

  const handleChange = ({ field, value }) => {
    if (field === 'country') {
      const country = Country.getCountryByCode(value);
      const states = State.getStatesOfCountry(country.isoCode);
      setStates(states);
    }
    setData({
      ...data,
      [field]: value,
    });
  };

  const { dispatchData } = useAcceptJs({
    authData: {
      apiLoginID: process.env.REACT_APP_AUTHORIZENET_API_KEY,
      clientKey: publicKey,
    },
    environment: 'PRODUCTION'
  });

  const handleSendData = async () => {
    if (!cardNumber || !expirationDate || !cvv) return notifyApiError('Please fill the required fields');
    if(!data.country) return notifyApiError('Please select a country.');
    if(!data.state) return notifyApiError('Please select a state');
    if(!data.city) return notifyApiError('Please select a city');
    if(!data.address) return notifyApiError('Please enter an address');
    if(!data.zipCode) return notifyApiError('Please enter a zip code');
    if(!data.phoneNumber) return notifyApiError('Please enter a phone number');
    if(!data.firstName) return notifyApiError('Please enter a first name');
    if(!data.lastName) return notifyApiError('Please enter a last name');

    const response = await dispatchData({
      cardData: {
        cardNumber,
        month: expirationDate.substring(0, 2),
        year: expirationDate.slice(-2),
        cardCode: cvv,
      },
    });
    if (!response?.opaqueData?.dataValue) return notifyApiError('The card details are incorrect');

    const { tenant } = await API.getTenantById({ id: userInfo?.attributes["custom:tenant_id"] });
    const customer_profile_id = tenant?.customer_profile_id || '';

    await addCreditCardWithUser({
      token: response.opaqueData.dataValue,
      profileCustomerId: customer_profile_id,
      user_id: userInfo.username,
      selectDefault,
      tenant_id: tenant.id,
      billingInfo: data,
    });

    afterAdd && afterAdd();
  };

  const StyledInput = {
    fontSize: '14px',
  };

  useEffect(() => {
    const countries = Country.getAllCountries();
    setCountries(countries);
  }, []);

  return (
    <>
      {loadingCreditCard && <ModalLoading />}
      <div className="modalAddCardPayInvoices">
        <div className="modalAddCardPayInvoices_gruop">
          <InputBox
            label="First Name"
            name="firstName"
            value={data?.firstName || ''}
            onChange={value => handleChange({ field: 'firstName', value })}
            placeholder="Required"
            type="text"
          />
          <InputBox
            label="Last Name"
            name="lastName"
            value={data?.lastName || ''}
            onChange={value => handleChange({ field: 'lastName', value })}
            placeholder="Required"
            type="text"
          />
        </div>
        <div className="modalAddCardPayInvoices_gruop2">
          <InputCardNumber cardNumber={cardNumber} onChange={setCardNumber} />
        </div>
        <div className="modalAddCardPayInvoices_gruop">
          <InputBox
            label="Expiration Date"
            value={expirationDate}
            onChange={value => setExpirationDate(formatExpired({ value: value }))}
            placeholder="MM/YY"
          />
          <InputBox
            label="CVV Code"
            value={cvv}
            onChange={value => setCvv(validInputNumber({ value: value, max: 4 }))}
            placeholder="CVV Code"
          />
        </div>
        <div className="modalAddCardPayInvoices_gruop">
          <InputBox
            label="Address"
            name="address"
            value={data?.address || ''}
            onChange={value => handleChange({ field: 'address', value })}
            placeholder="Required"
            type="text"
          />
          <div>
            <label>Phone Number</label>
            <InputMask
              className="input-ssn22"
              mask="999-999-9999"
              size="large"
              placeholder="Required"
              value={data?.phoneNumber || ''}
              name="phoneNumber"
              style={StyledInput}
              onChange={e => handleChange({ field: 'phoneNumber', value: e.target.value })}
            />
          </div>
        </div>
        <div className="modalAddCardPayInvoices_gruop">
          <InputBox
            label="City"
            name="city"
            value={data?.city || ''}
            onChange={value => handleChange({ field: 'city', value })}
            placeholder="Required"
            type="text"
          />
          <div>
            <label>Zip Code</label>
            <InputMask
              mask="99999"
              placeholder="Required"
              className="input-ssn22"
              value={data?.zipCode || ''}
              onChange={e => handleChange({ field: 'zipCode', value: e.target.value })}
            />
          </div>
        </div>
        <div className="modalAddCardPayInvoices_gruop">
          <div>
            <label>Country</label>
            <Select
              placeholder="Required"
              className="clients-select"
              name="country"
              value={data.country}
              onChange={name => handleChange({ field: 'country', value: name })}
              style={{ width: '100%' }}>
              {countries.map(state => (
                <Option key={state.name} value={state.isoCode}>
                  {state.name}
                </Option>
              ))}
              
            </Select>
          </div>
          <div>
            <label>State</label>
            <Select
              placeholder="Required"
              className="clients-select"
              value={data.state}
              onChange={name => handleChange({ field: 'state', value: name })}
              style={{ width: '100%' }}>
              {states.length > 1  && states.map(state => (
                <Option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </Option>
              ))}
            </Select>
          </div>
          
        </div>
        <CustomBoxCheckBox
          optionSignature={true}
          onChange={() => setSelectDefault(prevState => !prevState)}
          label="Select as default"
          htmlId="selectDefaultCreditCardAdd"
          value={selectDefault}
        />

        <div className="modalAddCardPayInvoices_btn">
          {goBack && (
            <Button type="primary" size="large" className="button btn_transparente" onClick={goBack}>
              close
            </Button>
          )}
          <Button type="primary" size="large" className="m-2 green-btn button btn-normal" onClick={handleSendData}>
            {textBtn}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddCreditCard;
