const ASSIGNMENT_STATUS_PENDING_REVIEW = 1;
const ASSIGNMENT_STATUS_IN_PROGRESS = 2;
const ASSIGNMENT_STATUS_REFUSED = 3;
const ASSIGNMENT_STATUS_APPROVED = 4;
const ASSIGNMENT_STATUS_RETURNED = 5;
const ASSIGNMENT_STATUS_COMPLETED = 6;
const ASSIGNMENT_STATUS_FOLLOWUP = 7;
const ASSIGNMENT_STATUS_UNKNOWN = 1000;

const ASSIGNMENT_STATUSES_BY_KEYS = {
  pending: {
    code: ASSIGNMENT_STATUS_PENDING_REVIEW,
    label: 'Pending review',
  },
  in_progress: {
    code: ASSIGNMENT_STATUS_IN_PROGRESS,
    label: 'In progress',
  },
  refused: {
    code: ASSIGNMENT_STATUS_REFUSED,
    label: 'Refused',
  },
  approved: {
    code: ASSIGNMENT_STATUS_APPROVED,
    label: 'Approved',
  },
  returned_for_revision: {
    code: ASSIGNMENT_STATUS_RETURNED,
    label: 'Returned for Revision',
  },
  complete: {
    code: ASSIGNMENT_STATUS_COMPLETED,
    label: 'Completed',
  },
  follow_up: {
    code: ASSIGNMENT_STATUS_FOLLOWUP,
    label: 'Follow UP',
  },
};

const ASSIGNMENT_STATUS_CODES = {
  [ASSIGNMENT_STATUS_PENDING_REVIEW]: { label: 'Pending Review', key: 'pending', color: 'secondary' },
  [ASSIGNMENT_STATUS_IN_PROGRESS]: { label: 'In progress', key: 'in_progress', color: 'info' },
  [ASSIGNMENT_STATUS_REFUSED]: { label: 'Refused', key: 'refused', color: 'error' },
  [ASSIGNMENT_STATUS_APPROVED]: { label: 'Approved', key: 'approved', color: 'success' },
  [ASSIGNMENT_STATUS_RETURNED]: { label: 'Returned for Revision', key: 'returned_for_revision', color: 'warning' },
  [ASSIGNMENT_STATUS_UNKNOWN]: { label: 'Undefined', key: 'undefined', color: 'secondary' },
  [ASSIGNMENT_STATUS_COMPLETED]: { label: 'Completed', key: 'Completed', color: 'success' },
};

const STATUS_CODES = {
  'Pending Review': 1,
  'In progress': 2,
  'Refused': 3,
  'Approved': 4,
  'Returned for Revision': 5,
  'Completed': 6
};

const STATUS_VALUES = {
  [ASSIGNMENT_STATUS_PENDING_REVIEW]: 'Pending Review',
  [ASSIGNMENT_STATUS_IN_PROGRESS]: 'In progress',
  [ASSIGNMENT_STATUS_REFUSED]: 'Refused',
  [ASSIGNMENT_STATUS_APPROVED]: 'Approved',
  [ASSIGNMENT_STATUS_RETURNED]: 'Returned for Revision',
  [ASSIGNMENT_STATUS_COMPLETED]: 'Completed',
};

export {
  ASSIGNMENT_STATUSES_BY_KEYS,
  ASSIGNMENT_STATUS_CODES,
  ASSIGNMENT_STATUS_APPROVED,
  ASSIGNMENT_STATUS_PENDING_REVIEW,
  ASSIGNMENT_STATUS_IN_PROGRESS,
  ASSIGNMENT_STATUS_REFUSED,
  ASSIGNMENT_STATUS_RETURNED,
  ASSIGNMENT_STATUS_UNKNOWN,
  ASSIGNMENT_STATUS_COMPLETED,
  ASSIGNMENT_STATUS_FOLLOWUP,
  STATUS_CODES,
  STATUS_VALUES
};
