import dayjs from 'dayjs';
import { BackIcon } from 'components/Icons';
import './ReportChanges.scss';
import { REPORT_IGNOREDKEYS } from '../../constants/report-ignoredkeys';
import React, {
  useState,
  useEffect,
  // forwardRef
} from 'react';
import { Col, Row, Typography, Input, Button, Empty, Select, DatePicker, Icon, Tooltip, Checkbox, Divider } from 'antd';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import TagsInputBox from 'components/TagsInputBox';
import moment from 'moment';
import arrayMove from 'array-move';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
// import PrescriptionTable from 'components/PrescriptionTable';
import ClaimAndInjury from 'components/ClaimAndInjury/index';
// import { CalendarTwoTone } from '@ant-design/icons';
import InputBox from 'components/InputBox';
import { notifyApiError, notifySuccess } from 'utils/notification';
import { ASSIGNMENT_STATUS_IN_PROGRESS } from 'containers/Reviews/assignment-statuses';
import { connect, useSelector } from 'react-redux';
import InputCurrencyBox from 'components/InputCurrencyBox';
import dynamicData from 'dynamicData';
import { PRIMARY_LIGHT, PRIMARY_LIGHT_GREEN } from '../../containers/Calendar/src/src/lib/utils/colors';
import CustomButton from '../../containers/Calendar/src/src/components/common/CustomButton';
import * as content from '../../constants/report-types-content-keys';
import { REPORT_FIELDS } from 'constants/reportFields';
import InlineContact from 'components/InlineContact';
import { compareText } from 'utils/compareStrings';
import { sortArrayObjectForKey } from 'utils/utils';
import {
  REPORT_MSA_TYPE_ID,
  REPORT_EARLY_INTERVENTION_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  REPORT_SURGICAL_TYPE_ID,
  REPORT_MCP_MSA_TYPE_ID,
  REPORT_TYPES_BY_ID,
} from 'constants/reports';

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;
const calculationMethodItems = ['Average Wholesale Price', 'Actual Charges', 'Fee Schedule', 'Other'];

const ReportChanges = props => {
  const reportInfo = props.history.location.state.data;
  const assigment_id = props.history.location.state.data.id;
  const [pending, setPending] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportKeys] = useState([]);
  const [changes, setChanges] = useState({ id: reportInfo.report.id, actions: {} });
  const [changesMedicare, setChangesMedicare] = useState({ actions: {} });
  const [isClaimCompleted, setClaimCompleted] = useState(false);
  const [employerList, setEmployerList] = useState([]);
  const [plaintifList, setPlaintifList] = useState([]);
  const [treatingList, setTreatingList] = useState([]);
  const [related_diagnosis, setRelated_diagnosis] = useState([]);
  const [icdCodeList, setIcdCodeList] = useState([]);
  const [lifeSources, setLifeSources] = useState([]);
  const [report_contacts, setReport_contacts] = useState([]);
  const [defenseList, setDefenseList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const { roles } = useSelector(state => state.auth);
  // const [medicareDataList, setMedicareDataList] = useState([]);
  const [report_claim_id, setReport_claim_id] = useState('');
  const [insuranceList, setInsuranceList] = useState([]);
  const [juridictionStateList, setJuridictionStateList] = useState([]);

  useEffect(() => {
    const reportsData = async () => {
      setPending(true);
      setLoading(true);
      try {
        if (reportInfo.report.id) {
          const res = await API.getReportcompareById(reportInfo.report.id); // bakend deberia filtrar datos innecesarios
          const medicareDataNurse = await API.getMedicareData({ reportId: reportInfo.report.id });
          const medicareDataQA = await API.getMedicareChangesData(reportInfo.report.id);
          const viewReport = await API.getViewReportById(reportInfo.report.id); //cambios echos por el rol qa attorney
          const arraysNurse = medicareDataNurse.medicare_cost_projections;
          const arrayQA = medicareDataQA.medicare_cost_projections;

          arraysNurse.forEach(value => {
            arrayQA.forEach(key => {
              if (value.id === key.id) {
                if (value.comments !== key.comments) {
                  reportKeys.push({
                    key: value.type,
                    nurse: value.comments,
                    qa: key.comments,
                    id: value.id,
                    name: value.name,
                  });
                }
              }
            });
          });

          const typeReport = REPORT_TYPES_BY_ID[reportInfo.report.report_type.id];
          const keys = Object.keys(res).filter(
            key => !REPORT_IGNOREDKEYS.includes(key) && REPORT_FIELDS[typeReport].includes(key)
          );
          keys.forEach(key => {
            if (res[key] !== viewReport[key]) {
              reportKeys.push({
                key: key, // la clave
                nurse: res[key], //el valor
                qa: viewReport[key], //el valor cambiado en qa
              });
            }
          });
          loadJuridicionStates();
          loadCodeList();
          loadLifeSource();
          getReportContacts();
          getContactLists();
          setReport_claim_id(res.report_claim_id);
          reportKeys.forEach(item => {
            item.result = compareText(item.nurse, item.qa);
          });
        }
      } catch (e) {
        notifyApiError(e);
      } finally {
        setPending(false);
        setLoading(false);
      }
    };
    if (reportKeys.length === 0) reportsData();
  }, [changes]);

  const loadCodeList = async () => {
    await API.getIcdCodeData('').then(res => {
      setIcdCodeList(res.icd_codes);
    });
  };

  const getReportContacts = async () => {
    let res = await API.getReportContacts(reportInfo.report.id);
    setReport_contacts(res.report_contacts.rows);
  };

  const getContactLists = () => {
    const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserNurseManager = isAllowed(roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);
    const tenantOnly = isUserNurseManager && !isUserAdmin;

    //Getting all contacts data
    API.getContactsData({ pageSize: 1000, tenantOnly }).then(res => {
      const dynamic = dynamicData();

      setContactsList(res.contacts);
      setPlaintifList(
        res.contacts.filter(item => (item.contact_type ? item.contact_type.name : '') === dynamic.plaintiff_attorney)
      );
      setDefenseList(
        res?.contacts.filter(item => (item.contact_type ? item.contact_type.name : '') === dynamic.defense_attorney)
      );
      setInsuranceList(
        res?.contacts.filter(item => (item.contact_type ? item.contact_type.name : '') === dynamic.insurance_carrier)
      );
      setEmployerList(
        res?.contacts.filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.employer_demographics
        )
      );
      setTreatingList(
        res.contacts.filter(item => (item.contact_type ? item.contact_type.name : '') === dynamic.treating_providers)
      );
    });
  };

  const handleDiagnosisDragOver = e => {
    e.preventDefault();

    this.dragged.style.display = 'none';

    if (e.target.tagName !== 'LI') return;

    const dgIndex = this.dragged.querySelector('.related-list-item').dataset.id;
    const taIndex = e.target.querySelector('.related-list-item').dataset.id;
    const animateName = dgIndex > taIndex ? 'drag-up' : 'drag-down';

    if (
      this.over &&
      e.target.querySelector('.related-list-item').dataset.rid !==
        this.over.querySelector('.related-list-item').dataset.rid
    ) {
      this.over.classList.remove('drag-up', 'drag-down');
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      this.over = e.target;
    }
  };

  const loadJuridicionStates = async () => {
    try {
      await API.getJuridictionStateList().then(res => {
        const states = sortArrayObjectForKey({ array: res.juridictionStates, key: 'name' });
        setJuridictionStateList(states);
      });
    } catch (e) {
      notifyApiError(e);
    }
  };

  const loadLifeSource = async () => {
    API.getLifeSources().then(res => {
      setLifeSources(res.life_expects);
    });
  };

  const handleDiagnosisDragStart = e => {
    this.dragged = e.currentTarget.parentElement.parentElement;
  };

  const handleDiagnosisDragEnd = e => {
    this.dragged.style.display = 'block';

    e.target.parentElement.parentElement.classList.remove('drag-up');
    this.over.classList.remove('drag-up');

    e.target.parentElement.parentElement.classList.remove('drag-down');
    this.over.classList.remove('drag-down');

    const from = this.dragged.querySelector('.related-list-item').dataset.id;
    const to = this.over.querySelector('.related-list-item').dataset.id;
    const data = arrayMove(related_diagnosis, from, to);

    setRelated_diagnosis(data);
  };

  // const CalendarCustomInput = forwardRef(({ onClick, onChange, selected }, ref) => (
  //   <Input
  //     className="contain"
  //     suffix={<CalendarTwoTone twoToneColor="#0075F9" style={{ fontSize: '17px' }} />}
  //     defaultValue={selected}
  //     onClick={onClick}
  //     onBlur={onChange}
  //     onChange={onChange}
  //     ref={ref}
  //     placeholder="Select a date range"
  //   />
  // ));

  const updateReport = async () => {
    setSaving(true);
    setLoading(true);
    try {
      Object.keys(changesMedicare).map(async e => {
        await API.updateMedicareItem(changesMedicare[e]);
      });
      Object.keys(changesMedicare).map(async e => {
        await API.updateMedicareItemQA(changesMedicare[e]);
      });
      await API.updateReport(changes);
      await API.updateReportQA(changes);
      await API.updateAssignmentById(assigment_id, {
        status: ASSIGNMENT_STATUS_IN_PROGRESS,
      });
      notifySuccess('Report save successfully');
    } catch (e) {
      notifyApiError(e);
    } finally {
      setSaving(false);
      setLoading(false);
      props.history.goBack();
    }
  };

  const handleSaveData = (key, value, accepted, item) => {
    try {
      setChanges((changes.actions[key] = accepted));
      setChanges({ ...changes, [key]: value });
      item.result = compareText(item.nurse, item.qa);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const handleSaveDataMedicare = (key, value, accepted, item) => {
    try {
      setChangesMedicare((changesMedicare.actions[key] = accepted));
      setChangesMedicare({ ...changesMedicare, [key]: { ['comments']: value, ['id']: key } });
      item.result = compareText(item.nurse, item.qa);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const handleReverted = key => {
    try {
      setChanges(changes => {
        const newData = { ...changes };
        delete newData[key];
        delete newData.actions[key];
        return newData;
      });
      setChangesMedicare(changesMedicare => {
        const newData = { ...changesMedicare };
        delete newData[key];
        delete newData.actions[key];
        return newData;
      });
    } catch (e) {
      notifyApiError(e);
    }
  };

  const resetChanges = item => {
    var key = changes.hasOwnProperty(item['key']) ? item['key'] : item['id'];
    setChanges(changes => {
      const newData = { ...changes };
      delete newData[key];
      return newData;
    });
    setChangesMedicare(changesMedicare => {
      const newData = { ...changesMedicare };
      delete newData[key];
      return newData;
    });
    item.result = compareText(item.nurse, item.qa);
  };

  const handleChange = (e, item) => {
    setChanges((changes.actions[item['key']] = 'qa'));
    setChanges({ ...changes, [item['key']]: e.target.value });
    item.result = compareText(item.nurse, e.target.value);
  };

  const handleChangeMedicare = (e, item) => {
    setChangesMedicare((changesMedicare.actions[item['id']] = item['id']));
    setChangesMedicare({ ...changesMedicare, [item['id']]: { ['comments']: e.target.value, ['id']: item['id'] } });
    item.result = compareText(item.nurse, e.target.value);
  };

  const handleChangeRichText = (e, item) => {
    setChanges((changes.actions[item['key']] = 'qa'));
    setChanges({ ...changes, [item['key']]: e });
    item.result = compareText(item.nurse, e);
  };

  const handleChangeRichSelect = (e, item) => {
    setChanges((changes.actions[item['key']] = 'qa'));
    setChanges({ ...changes, [item['key']]: e });
    item.result = compareText(item.nurse ? 'true' : 'false', e ? 'true' : 'false');
  };

  const handleChangeSelect = (e, item) => {
    setChanges((changes.actions[item['key']] = 'qa'));
    setChanges({ ...changes, [item['key']]: e.toString() });
    item.result = compareText(item.nurse, e.toString());
  };

  const handleChangeChecked = (e, item) => {
    setChanges((changes.actions[item['key']] = 'qa'));
    setChanges({ ...changes, [item['key']]: e.target.checked });
    item.result = compareText(item.nurse, e.target.checked);
  };

  const FieldDisplayed = ({ value, item, types }) => {
    let dataDynamic = { ...dynamicData() };

    switch (item['key']) {
      case content.TYPE_INLINECONTACT_DEFE.find(element => element === item['key']):
        return (
          <InlineContact
            getReportContacts={getReportContacts}
            getContactLists={getContactLists}
            disabled={!(types === 'Qa')}
            another={false}
            contact_type_id={dataDynamic.defense_attorney_id}
            report_id={reportInfo.report.id}
            contactsList={defenseList}
            type={item}
            value={report_contacts.filter(e => e.type === item).map(x => x.contact.id)}
          />
        );
      case content.TYPE_INLINECONTACT_EMPLO.find(element => element === item['key']):
        return (
          <InlineContact
            getReportContacts={getReportContacts}
            getContactLists={getContactLists}
            disabled={!(types === 'Qa')}
            contact_type_id={dataDynamic.employer_demographics_id}
            another={false}
            report_id={reportInfo.report.id}
            contactsList={employerList}
            type={item}
            value={report_contacts.filter(e => e.type === item).map(x => x.contact.id)}
          />
        );
      case content.TYPE_INLINECONTACT_TREATING.find(element => element === item['key']):
        return (
          <InlineContact
            getReportContacts={getReportContacts}
            getContactLists={getContactLists}
            contact_type_id={dataDynamic.treating_providers_id}
            another={true}
            disabled={!(types === 'Qa')}
            report_id={reportInfo.report.id}
            contactsList={treatingList}
            type={item}
            value={report_contacts.filter(e => e.type === item).map(x => x.contact.id)}
          />
        );
      case content.TYPE_INLINECONTACT_PLAINTIFF.find(element => element === item['key']):
        return (
          <InlineContact
            getReportContacts={getReportContacts}
            getContactLists={getContactLists}
            disabled={!(types === 'Qa')}
            contact_type_id={dataDynamic.plaintiff_attorney_id}
            another={false}
            report_id={reportInfo.report.id}
            contactsList={plaintifList}
            type={item}
            value={report_contacts
              .filter(item => item.type === 'plaintiff_attorney')
              .map(e => {
                if (!e.contact) {
                  return null;
                }
                return e.contact.id;
              })
              .filter(x => x)}
          />
        );
      case content.TYPE_SELECT.find(element => element === item['key']):
        return (
          <Select
            getPopupContainer={triggerNode => triggerNode.parentNode}
            optionFilterProp="children"
            className="dropdown"
            value={value}
            disabled={!(types === 'Qa')}
            onChange={e => handleChange(e, item)}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            {item['key'] === 'juridiction_state_id'
              ? juridictionStateList.map(juri => (
                  <Option value={juri.id} key={juri.id}>
                    {juri.name}
                  </Option>
                ))
              : lifeSources.map(juri => (
                  <Option value={juri.id} key={juri.id}>
                    {juri.name}
                  </Option>
                ))}
          </Select>
        );
      case content.TYPE_TAGSINPUTBOX.find(element => element === item['key']):
        return (
          <TagsInputBox
            classes={!(types === 'Qa') ? 'tagsInputBoxDisable' : 'tagsInputBox'}
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value || ''
            }
            disabled={!(types === 'Qa')}
            onChange={value => handleChangeRichText(value, item)}
          />
        );
      case content.TYPE_DATEPICKERSIMPLE.find(element => element === item['key']):
        return (
          <DatePicker
            disabled={!(types === 'Qa')}
            className="date"
            minDate={new Date('2000-01-01')}
            format="MM/DD/YYYY"
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? moment(changes[item['key']])
                : moment(value) || null
            }
            onChange={date => handleChangeRichText(moment(date).format('MM/DD/YYYY'), item)}
          />
        );
      case content.TYPE_INPUT.find(element => element === item['key']):
        return (
          <Input
            type="text"
            className="input"
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value || ''
            }
            disabled={!(types === 'Qa')}
            onChange={e => handleChange(e, item)}
          />
        );
      case content.TYPE_SELECTMULTI.find(element => element === item['key']):
        return (
          <>
            <div className="related-list" onDragOver={e => handleDiagnosisDragOver(e)}>
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                mode="multiple"
                className="dropdown"
                filterOption={false}
                disabled={!(types === 'Qa')}
                value={
                  changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                    ? changes[item['key']].split(',')
                    : value === ''
                    ? []
                    : value.split(',')
                }
                onChange={e => handleChangeSelect(e, item)}>
                {icdCodeList.map((item, index) => {
                  let value1 = JSON.stringify({
                    code: item.code,
                    med_descr: item.med_descr ? item.med_descr.replace(/,/g, '$') : '',
                  }).replace(/,/g, '<');
                  const sortId =
                    related_diagnosis.indexOf(value1) !== -1
                      ? related_diagnosis.indexOf(value1)
                      : related_diagnosis.length + index;
                  return (
                    <Option key={index} value={value1}>
                      <Tooltip
                        overlayStyle={{ maxWidth: 300 }}
                        placement="right"
                        title={` (${item.code}) ${item.med_descr}`}>
                        <span
                          draggable="true"
                          onMouseDown={e => {
                            e.stopPropagation();
                          }}
                          onDragStart={e => handleDiagnosisDragStart(e)}
                          onDragEnd={e => handleDiagnosisDragEnd(e)}
                          data-id={sortId}
                          data-rid={`${item.code}-${item.med_descr}`}
                          className="related-list-item">
                          {` (${item.code}) ${item.med_descr}`}
                        </span>
                      </Tooltip>
                    </Option>
                  );
                })}
              </Select>
            </div>
          </>
        );
      case content.TYPE_INPUTCURRENCYBOX.find(element => element === item['key']):
        return (
          <InputCurrencyBox
            className="contain"
            placeholder=" "
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value || 0
            }
            disabled={!(types === 'Qa')}
            onChange={e => handleChangeRichText(e, item)}
          />
        );
      case content.TYPE_INPUTBOX.find(element => element === item['key']):
        return (
          <InputBox
            classes={'contain'}
            onChange={e => handleChangeRichText(e, item)}
            disabled={!(types === 'Qa')}
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value || ''
            }
          />
        );
      case content.TYPE_TEXTAREA.find(element => element === item['key']):
        return (
          <TextArea
            className="textarea"
            style={{ marginRight: 15 }}
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value || ''
            }
            disabled={!(types === 'Qa')}
            onChange={e => handleChange(e, item)}
            type="text"
            rows={3}
          />
        );
      case content.TYPE_CLAIM.find(element => element === item['key']):
        return (
          <ClaimAndInjury
            onChange={e => handleChange(e, item)}
            report_claim_id={report_claim_id}
            disabled={!(types === 'Qa')}
            onClaimUpdated={value => setClaimCompleted(value)}
          />
        );
      case content.TYPE_CHECKBOX.find(element => element === item['key']):
        return (
          <Checkbox
            onChange={e => handleChangeChecked(e, item)}
            disabled={!(types === 'Qa')}
            checked={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value
            }></Checkbox>
        );
      case content.TYPE_SELECTMETHOD.find(element => element === item['key']):
        return (
          <Select
            getPopupContainer={triggerNode => triggerNode.parentNode}
            showSearch
            placeholder="Please select"
            optionFilterProp="children"
            disabled={!(types === 'Qa')}
            className="dropdown"
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value
            }
            onChange={e => handleChangeRichText(e, item)}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            <Option value={'Fee Schedule'} key={'1'}>
              Fee Schedule
            </Option>
            <Option value={'Full Actual Charges'} key={'2'}>
              Full Actual Charges
            </Option>
          </Select>
        );
      case content.TYPE_SELECTRELEASES.find(element => element === item['key']):
        return (
          <Select
            getPopupContainer={triggerNode => triggerNode.parentNode}
            showSearch
            placeholder="Please select"
            disabled={!(types === 'Qa')}
            optionFilterProp="children"
            className="dropdown"
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                  ? 'yes'
                  : 'no'
                : value
                ? 'yes'
                : 'no'
            }
            onChange={e => (e === 'yes' ? handleChangeRichSelect(true, item) : handleChangeRichSelect(false, item))}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            <Option value={'yes'} key={'1'}>
              Yes
            </Option>
            <Option value={'no'} key={'2'}>
              No
            </Option>
          </Select>
        );
      case content.TYPE_SELECTFUTURE.find(element => element === item['key']):
        return (
          <Select
            getPopupContainer={triggerNode => triggerNode.parentNode}
            placeholder="Please select"
            optionFilterProp="children"
            className="dropdown"
            disabled={!(types === 'Qa')}
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value
            }
            onChange={e => handleChangeRichText(e, item)}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            {calculationMethodItems.map(item => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>
        );
      case content.TYPE_SELECTSETTETYPEA.find(element => element === item['key']):
        return (
          <Select
            getPopupContainer={triggerNode => triggerNode.parentNode}
            showSearch
            placeholder="Please select"
            optionFilterProp="children"
            disabled={!(types === 'Qa')}
            className="dropdown"
            value={
              changes.hasOwnProperty(item['key']) && types === 'Qa' && changes.actions[item['key']] === 'qa'
                ? changes[item['key']]
                : value
            }
            onChange={e => handleChangeRichText(e, item)}
            suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} theme="filled" />}>
            <Option value={'lump sum'} key={'1'}>
              Lump sum
            </Option>
            <Option value={'structured'} key={'2'}>
              Structured
            </Option>
          </Select>
        );

      default:
        return (
          <TextArea
            className="textarea"
            style={{ marginRight: 15 }}
            value={
              changesMedicare.hasOwnProperty(item['id']) &&
              types === 'Qa' &&
              changesMedicare.actions[item['id']] === item['id']
                ? changesMedicare[item['id']]['comments']
                : value || ''
            }
            disabled={!(types === 'Qa')}
            onChange={e => handleChangeMedicare(e, item)}
            type="text"
            rows={3}
          />
        );
    }
  };

  const totalItemChange = [
    REPORT_MSA_TYPE_ID,
    REPORT_EARLY_INTERVENTION_TYPE_ID,
    REPORT_MCP_TYPE_ID,
    REPORT_SURGICAL_TYPE_ID,
    REPORT_MCP_MSA_TYPE_ID,
  ].includes(reportInfo.report.report_type.id)
    ? Object.keys(changes).length + Object.keys(changesMedicare).length - 1
    : Object.keys(changes).length - 2 + Object.keys(changesMedicare).length - 1;

  return (
    <Row className="report-change-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Report Changes
          </Title>
        </Col>
        <Col className="back" onClick={() => props.history.goBack()}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <Row className="report-info">
        <Row type="flex" justify="start" className="report-title-info">
          <Col>
            <Text strong className="title">
              Report Info
            </Text>
          </Col>
        </Row>
        <Row type="flex" justify="start" className="report-description-info">
          <Col md={4}>
            <Text>Report ID</Text>
          </Col>
          <Col md={4}>
            <Text>Claimant</Text>
          </Col>
          <Col md={4}>
            <Text>Claimant ID</Text>
          </Col>
          <Col md={4}>
            <Text>Client</Text>
          </Col>
          <Col md={4}>
            <Text>Return date</Text>
          </Col>
          <Col md={4}>
            <Text>Status</Text>
          </Col>
          <Col md={4}>
            <Text strong>{reportInfo.report.report_uuid}</Text>
          </Col>
          <Col md={4}>
            <Text strong>
              {reportInfo.claimant.claimant_name} {reportInfo.claimant.claimant_last_name}
            </Text>
          </Col>
          <Col md={4}>
            <Text strong>{reportInfo.claimant_id}</Text>
          </Col>
          <Col md={4}>
            <Text strong>{reportInfo.claimant.client.client_name}</Text>
          </Col>
          <Col md={4}>
            <Text strong>{dayjs(reportInfo.end_date).format('MMM DD, YYYY')}</Text>
          </Col>
          <Col md={4}>
            <Text strong>{reportInfo.status.name}</Text>
          </Col>
        </Row>
      </Row>
      <Row className="report-info">
        <Row type="flex" justify="start" align="middle" className="report-title-info">
          <Col md={6}>
            <Text strong className="title">
              Item
            </Text>
          </Col>
          <Col md={6}>
            <Text strong className="title">
              Original
            </Text>
          </Col>
          <Col md={6}>
            <Text strong className="title">
              Revision
            </Text>
          </Col>
          <Col md={6}>
            <Text strong className="title">
              Comparison
            </Text>
          </Col>
          <Divider className="divider" />
        </Row>
        <Row type="flex" justify="space-between" align="top" className="report-description-info">
          <Col md={24} className="content">
            <SpinBox loading={pending}>
              {reportKeys.length > 0 ? (
                <>
                  {reportKeys.map((item, i) => {
                    return (
                      <Row type="flex" align="top" justify="space-between" className="fix-padding" key={i.toString()}>
                        <Col md={4}>
                          <Row>
                            <Text>{i + 1}. </Text>
                            <Text>{item['key'].split('_').join(' ').toUpperCase()}</Text>
                          </Row>
                          {item['name'] ? (
                            <>
                              <Row>
                                <Text>
                                  {i + 1}.{1}{' '}
                                </Text>
                                <Text>{item['name'].split('_').join(' ').toUpperCase()}</Text>
                              </Row>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </Col>
                        <Col md={6}>
                          <Row>
                            {/* AQUI PASAMOS LOS DATOS QUE AGREGO EL NURSE AL REPORTE */}
                            {FieldDisplayed({ value: item['nurse'], item: item, types: 'Nurse' })}

                            {/* AQUI ABAJO ESTAN LOS BOTONES DE USE REVISION Y DE ACEPTES AND CANCEL */}
                            {/* {(changes.hasOwnProperty(item['key']) && changes.actions[item['key']] === 'original') ||
                          (changesMedicare.hasOwnProperty(item['id']) &&
                            changesMedicare.actions[item['id']] === 'original') ? (
                            <Row>
                              <Col md={20}>
                                <Button type="primary" className="accepted-revision-btn">
                                  Accepted
                                </Button>
                                <Button
                                  type="link"
                                  className="reset-btn"
                                  onClick={() =>
                                    handleReverted(changes.hasOwnProperty(item['key']) ? item['key'] : item['id'])
                                  }>
                                  Cancel
                                </Button>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col md={20}>
                                <Button
                                  type="primary"
                                  className="original-btn"
                                  onClick={() =>
                                    item['name']
                                      ? handleSaveDataMedicare(item['id'], item['nurse'], 'original', item)
                                      : handleSaveData(item['key'], item['nurse'], 'original', item)
                                  }>
                                  Use Original
                                </Button>
                                <Button
                                  type="link"
                                  disabled={
                                    (changes.hasOwnProperty(item['key']) && changes.actions[item['key']] === 'nurse') ||
                                    (changesMedicare.hasOwnProperty(item['id']) &&
                                      changesMedicare.actions[item['id']] === item['id'])
                                      ? false
                                      : true
                                  }
                                  className={
                                    (changes.hasOwnProperty(item['key']) && changes.actions[item['key']] === 'nurse') ||
                                    (changesMedicare.hasOwnProperty(item['id']) &&
                                      changesMedicare.actions[item['id']] === item['id'])
                                      ? 'reset-btn-color'
                                      : 'reset-btn'
                                  }
                                  onClick={() => resetChanges(item)}>
                                  Reset
                                </Button>
                              </Col>
                            </Row>
                          )} */}
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row>
                            {/* AQUI PAMAOS LOS CAMBIOS QUE HIZO EL AQ EN EL CAMPO */}
                            {FieldDisplayed({ value: changes[item['key']] || item['qa'], item: item, types: 'Qa' })}
                            {(changes.hasOwnProperty(item['key']) && changes.actions[item['key']] === 'revision') ||
                            (changesMedicare.hasOwnProperty(item['id']) &&
                              changesMedicare.actions[item['id']] === 'revision') ? (
                              <Row>
                                <Col md={20}>
                                  <Button type="primary" className="accepted-original-btn">
                                    Accepted
                                  </Button>
                                  <Button
                                    type="link"
                                    className="reset-btn"
                                    onClick={() =>
                                      handleReverted(changes.hasOwnProperty(item['key']) ? item['key'] : item['id'])
                                    }>
                                    Cancel
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col md={20}>
                                  {/* <Button
                                  type="primary"
                                  className="green-btn button"
                                  onClick={() =>
                                    item['name']
                                      ? handleSaveDataMedicare(item['id'], item['qa'], 'revision', item)
                                      : handleSaveData(item['key'], item['qa'], 'revision', item)
                                  }>
                                  Use Revision
                                </Button> */}
                                  <Button
                                    type="primary"
                                    className="green-btn button"
                                    onClick={() =>
                                      item['name']
                                        ? handleSaveDataMedicare(item['id'], item['qa'], 'revision', item)
                                        : handleSaveData(item['key'], changes[item['key']] || item['qa'], 'revision', {
                                            ...item,
                                            qa: changes[item['key']] || item['qa'],
                                          })
                                    }>
                                    Use Revision
                                  </Button>
                                  <Button type="link" className="reset-btn" onClick={() => resetChanges(item)}>
                                    Reset
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </Row>
                        </Col>
                        {/* Aqui va la comparacion  */}
                        {/*<div className="container" dangerouslySetInnerHTML={{__html:item['id']}}></div>*/}
                        <Col md={6}>
                          <TagsInputBox classes={'tagsInputBox'} value={item['result']} disabled={true} />
                        </Col>
                        <Divider className="divider" />
                      </Row>
                    );
                  })}
                  {!pending && (
                    <Row>
                      <Col className="report-buttons">
                        <CustomButton
                          className="save"
                          backgroundcolor={PRIMARY_LIGHT_GREEN}
                          color={PRIMARY_LIGHT}
                          size="large"
                          style={{ marginTop: '10px' }}
                          onClick={updateReport}
                          loading={saving}>
                          Save & Exit
                        </CustomButton>
                        <Button type="link" className="exit-btn" onClick={() => props.history.goBack()}>
                          Discard & Exit
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <Empty
                  description={loading ? false : 'No Changes'}
                  image={!loading ? Empty.PRESENTED_IMAGE_SIMPLE : null}
                  className="empty-icon"
                />
              )}
            </SpinBox>
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

const mapStateToProps = state => {
  const props = {
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {})(ReportChanges);
