import React from 'react';
import ReactTooltip from 'react-tooltip';

// Colors
import { PRIMARY_BORDER_GREY } from 'containers/Calendar/src/src/lib/utils/colors';

import './tooltip.scss';

const Tooltip = ({ children, content, className, id = 'customTooltip' }) => (
  <>
    <div data-tip data-for={id} className={className}>
      {children}
    </div>

    <ReactTooltip
      id={id}
      className="customTooltip"
      effect="solid"
      backgroundColor="white"
      place="bottom"
      border
      borderColor={PRIMARY_BORDER_GREY}
      borderRadius="10px">
      {content}
    </ReactTooltip>
  </>
);

export default Tooltip;
