import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Row, Col, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const SortableItem = SortableElement(({ value, type, deleteFile, canEdit }) => {
  let el = value;

  return (
    <Row type="flex" justify="space-between">
      <Col sm={22} className="item">
        <Tooltip title={el.file?.name}>
          <div
            style={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}
            onClick={() => {
              window.open(el.file?.url, '_blank');
            }}>
            {el.file?.name}
          </div>
        </Tooltip>
      </Col>
      {canEdit && (
        <Col sm={2}>
          <CloseCircleOutlined
            onClick={() => {
              deleteFile(type, el);
            }}
            style={{
              color: '#ff0000',
              fontSize: 12,
              marginTop: 7,
              marginLeft: 10,
              borderRadius: 360,
            }}
          />
        </Col>
      )}
    </Row>
  );
});

export default SortableItem;
