import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { reorder } from 'containers/Reports/utils/helpers';

import './templateLifeCarePlanSection.scss';
import { TemplateLifeCarePlanFieldOnly } from '../TemplateLifeCarePlanFieldOnly';
import { TemplateLifeCarePlanFieldGroup } from '../TemplateLifeCarePlanFieldGroup';

const TemplateLifeCarePlanSection = ({ item, index, onDelete, onSave, listReportType }) => {
  const [dataStructure, setDataStructure] = useState(item);
  const [alias, setAlias] = useState(item.alias);
  const [editName, setEditName] = useState(false);
  const [showSaveOrder, setShowSaveOrder] = useState(false);

  useEffect(() => {
    const clone = _.cloneDeep(item);

    setDataStructure({
      ...clone,
      template_fields: clone.template_fields.sort(function (a, b) {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      }),
    });
  }, [item]);

  const handleSaveFieldSection = ({ fieldEdit, index }) => {
    const clone = _.cloneDeep(dataStructure);
    clone.template_fields[index] = fieldEdit;

    onSave({ update: clone });
  };

  const hanldeChangeAlias = e => {
    setAlias(e.target.value);
  };

  const handleSaveAlias = () => {
    const clone = _.cloneDeep(dataStructure);
    clone.alias = alias;

    toggleEdit();
    onSave({ update: clone });
  };

  const toggleEdit = () => setEditName(prevState => !prevState);

  const onDragEnd = async data => {
    const { source, destination } = data;

    // cambiamos de posicion los field
    if (source && destination && source.index === destination.index) return;

    const oldPosition = data.source.index;
    const newPosition = data.destination.index;

    const newTemplateFields = reorder(dataStructure.template_fields, oldPosition, newPosition);

    const update = reOrderFieldByIndex({ list: newTemplateFields });

    if (!showSaveOrder) setShowSaveOrder(true);

    setDataStructure(prevState => {
      return {
        ...prevState,
        template_fields: update,
      };
    });
  };

  const reOrderFieldByIndex = ({ list }) => {
    return list.map((item, index) => ({
      ...item,
      order: index,
    }));
  };

  const handleSaveOrder = () => {
    setShowSaveOrder(false);
    onSave({ update: dataStructure });
  };

  return (
    <>
      {dataStructure.template_fields.length === 1 ? (
        <TemplateLifeCarePlanFieldOnly
          dataStructure={dataStructure}
          index={index}
          handleSaveFieldSection={handleSaveFieldSection}
          onDelete={onDelete}
          listReportType={listReportType}
        />
      ) : (
        <TemplateLifeCarePlanFieldGroup
          dataStructure={dataStructure}
          index={index}
          hanldeChangeAlias={hanldeChangeAlias}
          alias={alias}
          editName={editName}
          toggleEdit={toggleEdit}
          handleSaveAlias={handleSaveAlias}
          onDelete={onDelete}
          onDragEnd={onDragEnd}
          handleSaveFieldSection={handleSaveFieldSection}
          listReportType={listReportType}
          showSaveOrder={showSaveOrder}
          handleSaveOrder={handleSaveOrder}
        />
      )}
    </>
  );
};

export default TemplateLifeCarePlanSection;
