import React, { useEffect, useState } from 'react';
import { ModalChooseTemplateLCP } from 'components/Modal/ModalChooseTemplateLCP';
import * as API from 'utils/api';
import { Modal, Spin } from 'antd';
import { notifyApiError } from 'utils/notification';
import { REPORT_LIFE_CARE_PLAN_TYPE_ID } from 'constants/reports';

const ChooseTemplate = ({ onClose, onConfirm }) => {
  const [listTemplate, setListTemplate] = useState([]);
  const [chooseTemplate, setChooseTemplate] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);

  const getListTemplates = async () => {
    try {
      const listTemplate = await API.getAllTemplateReports({ reportType: REPORT_LIFE_CARE_PLAN_TYPE_ID });

      setListTemplate(listTemplate?.reports || []);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getListTemplates();
  }, []);

  const handleConfirm = () => {
    onConfirm({ chooseTemplateId: chooseTemplate });
  };

  return (
    <>
      <Modal visible={loadingPage} footer={null} className="modal-loader">
        <Spin />
      </Modal>

      <ModalChooseTemplateLCP
        close={onClose}
        handleChange={({ id }) => setChooseTemplate(id)}
        dataList={listTemplate}
        onConfirm={handleConfirm}
        selectId={chooseTemplate}
      />
    </>
  );
};

export default ChooseTemplate;
