export const PRIMARY_BLUE_DARK = '#0029ab';
export const PRIMARY_BLUE_LIGHT = '#15a0dd';
export const SECONDARY_BLUE_LIGHT = '#0098db';
export const BORDER_GREY_LIGHT = 'rgba(0,0,0,0.12)';
export const PRIMARY_BORDER_GREY = '#b1b1b1';
export const PRIMARY_RED = '#e50000';
export const PRIMARY_BACKGROUND_GREY = '#f7f7f7';
export const SECONDARY_BACKGROUND_GREY = '#f3f3f3';
export const SECONDARY_HOVER_GREY = 'rgba(0, 0, 0, 0.08)';
export const TRANSPARENT = 'rgba(0, 0, 0, 0)';
export const PRIMARY_LIGHT = '#FFFFFF';
export const PRIMARY_DARK = '#000000';
export const PRIMARY_GREY = '#f2f4f7';
export const SECONDARY_GREY = '#787b7d';
export const PRIMARY_TEXT_DARK = '#251e1e';
export const PRIMARY_LIGHT_GREEN = '#1dcd67';
export const PRIMARY_LIGHT_BLUE = '#6f66fd';
export const PRIMARY_YELLOW = '#ffcb72';
export const BLUE_ICON = '#0082FF';