import React, { useState } from 'react';
import { Button, Col, Row, Skeleton, Typography } from 'antd';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Icons
import { BackIcon } from 'components/Icons';

// Components
import InputBox from 'components/InputBox';
import UserRole from 'components/UserRole/Index';

// Utils
import * as API from 'utils/api';
import { UploadFileCVUser } from 'utils/upload';
import { notifyApiError, notifyError, notifyInfo } from 'utils/notification';
import isAllowed, { ROLES_DIVISIONS, EXTERNAL_LITIGATION_ATTORNEY_ID } from 'layout/roles';
import { validateEmail, validateName, validatePhone } from 'utils/utils';

import './litigationAttorneyCreate.scss';
import { LITIGATION_ATTORNEY_NAV } from 'layout/navigate_const';

const { Title, Text } = Typography;

const LitigationAttorneyCreatePage = () => {
  const { roles } = useSelector(state => state.auth);
  const history = useHistory();

  // State
  const [loading, setLoading] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [clients, setClients] = useState([]);
  const [roleValue] = useState([EXTERNAL_LITIGATION_ATTORNEY_ID]);
  const [cv, setCv] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const verifyClientsPermissions = () => {
    const isUserAdmin = isAllowed(roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserManager = isAllowed(roles, ROLES_DIVISIONS.MANAGER);

    return isUserManager && !isUserAdmin;
  };

  const loadClients = async () => {
    const tenantOnly = verifyClientsPermissions();

    try {
      const res = await API.getClientsData('', 0, 1000, tenantOnly);

      setClients(res.clients);
    } catch (e) {
      notifyApiError(e);
    }
  };

  const uploadFile = ({ file }) => setCv(file);

  const handleSelectRole = async ({ client_id }) => setSelectedClient(client_id);

  const handleCreateUser = async () => {
    setLoading(true);
    const param = {
      email,
      given_name: givenName,
      family_name: familyName,
      locale: phone,
      restrictions: 'restrictions',
      role_id: roleValue[0],
    };

    const pickedClient = clients.find(client => client.id === selectedClient);

    param.tenant_id = pickedClient.tenant_id;

    let res;

    try {
      res = await API.createUser(param);
    } catch (e) {
      if (e?.response?.data?.reason?.[0]?.message) {
        if (e.response.data.reason[0].message.includes('An account with the given email already exists.')) {
          setLoading(false);
          return notifyError(e?.response?.data?.reason?.[0]?.message);
        }
      }
    }

    try {
      const rolesToAppend = roleValue.map(role_id => {
        const assigedRoleToUser = {
          user_id: res.data.User.Username,
          role_id,
        };

        if (EXTERNAL_LITIGATION_ATTORNEY_ID === role_id) {
          if (selectedClient) {
            assigedRoleToUser.client_id = selectedClient;
            assigedRoleToUser.visibility = true;
          }

          assigedRoleToUser.range = false;
        }

        return assigedRoleToUser;
      });

      try {
        await API.synchroniseUser({ ...param, id: res.data.User.Username });
      } catch (e) {
        notifyApiError(e);
      }

      await API.AppendRoles(rolesToAppend);

      if (cv && rolesToAppend.some(role => role.role_id === EXTERNAL_LITIGATION_ATTORNEY_ID)) {
        await UploadFileCVUser({ file: cv, user_id: res.data.User.Username });
      }

      notifyInfo('User was created');
      history.push(LITIGATION_ATTORNEY_NAV.path);
    } catch (error) {
      setLoading(false);

      const errorCondition = error?.response?.data?.reason || '';
      const errorMessage = errorCondition ? errorCondition?.[0]?.message || '' : 'Error';

      notifyApiError(errorMessage);
    }
  };

  const isFormValid =
    givenName &&
    familyName &&
    roleValue.length &&
    validateEmail(email) &&
    validatePhone(phone) &&
    validateName(givenName) &&
    validateName(familyName);

  return (
    <Row className="add-user-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Add New Litigation Attorney
          </Title>
        </Col>
        <Col className="back" onClick={() => history.goBack()}>
          <BackIcon className="icon" />
        </Col>
      </Row>
      <Row className="main">
        <Row className="content">
          {loading ? (
            <Skeleton active paragraph={{ rows: 4 }} />
          ) : (
            <>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox label="First Name" value={givenName} onChange={value => setGivenName(value)} />
                </Col>
                <Col md={11} className="row">
                  <InputBox label="Last Name" value={familyName} onChange={value => setFamilyName(value)} />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox label="Email" value={email} onChange={value => setEmail(value)} />
                </Col>
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <Row>
                    <InputMask
                      mask="999-999-9999"
                      placeholder="Optional"
                      className="input-ssn"
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </Row>
                </Col>
              </Row>
              <UserRole
                verifyClientsPermissions={verifyClientsPermissions}
                loadClients={loadClients}
                clients={clients}
                roleValue={roleValue}
                uploadFile={uploadFile}
                disabledSelectRole
                isNewAttorney
                hiddenVisibility
                OnRoleChange={handleSelectRole}
              />
              <Row type="flex" justify="center" className="confirm-button">
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => handleCreateUser()}
                  disabled={!isFormValid}>
                  Create
                </Button>
              </Row>
            </>
          )}
        </Row>
      </Row>
    </Row>
  );
};

export default LitigationAttorneyCreatePage;
