import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Row, Col, Button, Input, Typography } from 'antd';
import { login } from '../../../redux/auth/actions';
import AuthSideBar from '../../../components/AuthSideBar';
import Password from '../../../components/Password';
import { generateReportRedirectLink } from '../../../utils/report';
import { ModalCustomPackage } from 'components/Modal/ModalCustomPackage';
import './Login.scss';

const { Title, Text } = Typography;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      changeFlag: false,
      modalContactMSA: false,
    };
  }

  login = async () => {
    let { username, password } = this.state;
    this.setState({ changeFlag: false });

    username = username.replace(/\s+/g, '');

    const res = await this.props.login(username, password);

    if (res === 'contact a msa') this.setState({ modalContactMSA: true });
  };

  checkAuthRedirect = () => {
    if (this.props.isNewPasswordRequest === true) {
      return <Redirect to="/setPassword" />;
    }

    if (this.props.isAuthenticated === true) {
      const query = QueryString.parse(this.props.location.search);
      const resource = query.resource;
      const id = query.id;
      if (!resource || !id) return <Redirect to="/" />;
      //redirect to requested resource page (for example Report)
      if (resource === 'report') {
        const type = query.type;
        if (!type) return <Redirect to="/" />;
        return <Redirect to={generateReportRedirectLink(id, type)} />;
      }
      return <Redirect to="/" />;
    }
    return null;
  };

  componentDidMount() {
    const emailQuery = new URLSearchParams(this.props.location.search).get('email');
    if (emailQuery) this.setState({ username: emailQuery });
  }

  render() {
    const { username, password, changeFlag, modalContactMSA } = this.state;
    const { errorMessage, isLoading } = this.props;

    const redir = this.checkAuthRedirect();

    return redir ? (
      redir
    ) : (
      <Row type="flex" justify="center" className="login-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          {/*<Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <div className="auth_wrapper">
              <Text>Don't have an Account?</Text>
              <Button
                type="primary"
                onClick={() => this.props.history.push('/Signup')}
                className="signup_cta_btn"
                ghost>
                Sign Up
              </Button>
            </div>
          </Row>*/}
          <Row type="flex" justify="center">
            <Col xs={20} md={10}>
              <Row type="flex" justify="space-around" className="main-container">
                <Col style={{ margin: 'auto' }}>
                  <Row type="flex" justify="center" align="middle" style={{ flexDirection: 'column' }}>
                    <Title level={1} className="title">
                      Sign In
                    </Title>
                    <Row className="input-row">
                      <Input
                        size="large"
                        placeholder="Login"
                        className="email"
                        value={username}
                        onChange={e =>
                          this.setState({
                            username: e.target.value,
                            changeFlag: true,
                          })
                        }
                        onPressEnter={this.login}
                      />
                      <Password
                        size="large"
                        placeholder="Password"
                        className="password"
                        value={password}
                        onChange={e =>
                          this.setState({
                            password: e.target.value,
                            changeFlag: true,
                          })
                        }
                        onPressEnter={this.login}
                      />
                    </Row>
                    <Row className="error-text">
                      {errorMessage && !changeFlag && <Text type="danger">{errorMessage}</Text>}
                    </Row>
                    <Row>
                      {isLoading ? (
                        <Button type="primary" size="large" className="green-btn button" loading>
                          Loading
                        </Button>
                      ) : (
                        <Button
                          disabled={!username || !password}
                          type="primary"
                          size="large"
                          onClick={this.login}
                          className="green-btn button">
                          Sign me in
                        </Button>
                      )}
                    </Row>
                  </Row>
                </Col>
                <Row className="reset">
                  Forgot your password?
                  <Button type="link" href="/sendRequest">
                    Reset
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>

        {
          modalContactMSA && <ModalCustomPackage />
        }
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    isNewPasswordRequest: state.auth.isNewPasswordRequest,
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    errorMessage: state.auth.errorMessage,
  };
  return props;
};

export default connect(mapStateToProps, { login })(Login);
