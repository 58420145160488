import React, { Component } from 'react';
import { Row, Typography, Input } from 'antd';

import './InputBox.scss';

const { Text } = Typography;

export default class InputBox extends Component {
  render() {
    const { label, value, disabled, placeholder, onChange, maxLength, onFocus, onBlur, type, classes, dataTestId } = this.props;

    return (
      <Row className="input-box-container">
        <Row className="label">
          <Text>{label}</Text>
        </Row>
        <Row>
          <Input
            type={type}
            placeholder={placeholder ?? 'Required'}
            className={classes === "" ? "input" : classes}
            disabled={disabled || false}
            value={value}
            maxLength={maxLength}
            onChange={e => onChange(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            id={dataTestId}
          />
        </Row>
      </Row>
    );
  }
}
