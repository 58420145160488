import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Modal, Button, Divider, Input, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SpinBox from '../../../../components/SpinBox';
import { RemoveIcon } from '../../../../components/Icons';
import { createTransaction, fetchServices, updateTransaction } from '../../../../utils/api-transactions';
import { fetchClaimantReports } from '../../../../utils/api';
import { notifyApiError } from '../../../../utils/notification';
import InputBox from 'components/InputBox';
import Select from 'react-select';

import './transactions.scss';

const CUSTOM_ENTRY_TEXT = 'CUSTOM ENTRY';

const ChooseReportsModal = ({
  claimantInfo,
  isOpened,
  editedTransaction = null,
  reportDefault,
  disabledReport,
  addRow = () => {},
  onClose = () => {},
}) => {
  const { Text } = Typography;
  const { confirm } = Modal;
  const EDIT_INVOICE = Boolean(editedTransaction?.invoice_id);

  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [reports, setReports] = useState([]);
  const [service, setService] = useState();
  const [customService, setCustomService] = useState(null);
  const [services, setServices] = useState(editedTransaction?.data || []);
  const [addedReport, setAddedReport] = useState();
  const [notes, setNotes] = useState('');
  const [billingArray, setBillingArray] = useState([]);
  const [billingArrayOption, setBillingArrayOption] = useState([]);
  const [infoReportDefault, setInfoReportDefault] = useState(null);

  const getReportDefault = ({ reports }) => {
    const getReportDefault = reports
      .map((item, index) => {
        if (item.id === reportDefault) {
          return {
            value: index,
            label: `${item.report_uuid || ''} - ${item.report_name || ''}`,
          };
        }

        return null;
      })
      .filter(item => Boolean(item));

    if (getReportDefault.length > 0) {
      setInfoReportDefault(getReportDefault[0]);
      setAddedReport(getReportDefault[0].value);
    }
  };

  useEffect(() => {
    if (editedTransaction && editedTransaction.notes) {
      setNotes(editedTransaction.notes);
    }
  }, [editedTransaction]);

  useEffect(() => {
    const getReports = async () => {
      setLoading(true);
      try {
        const res = await fetchClaimantReports(claimantInfo.id);
        setReports(res.reports || []);

        if (reportDefault) {
          getReportDefault({ reports: res.reports });
        }
      } catch (e) {
        notifyApiError(e);
        console.error(e);
      } finally {
        setLoading(false);
        setInitLoading(false);
      }
    };
    if (isOpened) getReports();
    // eslint-disable-next-line
  }, [claimantInfo.id, isOpened]);

  useEffect(() => {
    const getServices = async () => {
      setPending(true);
      try {
        const res = await fetchServices();
        if (!(res && Array.isArray(res))) {
          return;
        }

        setBillingArray(
          res.map(service => ({
            id: service.Id,
            name: service.Name,
            price: service.UnitPrice || 0,
          }))
        );

        setBillingArrayOption(formatOptGroupOptions({ array: res }));
      } catch (e) {
        notifyApiError(e);
        console.error(e);
      } finally {
        setPending(false);
      }
    };
    if (isOpened) getServices();
  }, [isOpened]);

  const onAddService = () => {
    setServices(prev => [
      ...prev,
      {
        service:
          billingArray[service].name === CUSTOM_ENTRY_TEXT
            ? { ...billingArray[service], description: customService }
            : billingArray[service],
        report:
          addedReport >= 0
            ? {
                ...reports[addedReport],
                // eslint-disable-next-line
                link: `${process.env.REACT_APP_BASE_URL}/login?resource=report&id=${reports[addedReport]?.id}&type=${reports[addedReport]?.report_type?.id}`,
              }
            : null,
      },
    ]);

    setCustomService('');
  };

  const onDeleteService = index => () => {
    const filtered = [...services];
    filtered.splice(index, 1);
    setServices(filtered);
  };

  useEffect(() => {
    setServices(editedTransaction?.data || []);
  }, [editedTransaction]);

  const onSubmit = async (complete = true) => {
    setLoading(true);

    const request = {
      services,
      quickbooks_customer_id: claimantInfo.client.quickbooks_customer_id,
      notes,
      claimantInfo,
    };

    if (complete) request.complete = true;

    try {
      if (!editedTransaction?.invoice_id) {
        request.claimant_id = claimantInfo.id;
        request.quickbooksEmail = false;
        request.email = null;

        const data = await createTransaction(request);
        if (data?.transaction?.id) addRow(data);
      } else {
        const data = await updateTransaction(editedTransaction.id, request);
        if (data?.transaction?.id) {
          addRow({
            transaction: {
              ...editedTransaction,
              data: services,
              invoice: data?.invoice?.DocNumber,
              invoice_id: data?.invoice?.Id,
              notes,
            },
            invoice: data?.invoice,
          });
        }
      }
      handleClose();
    } catch (e) {
      console.error(e);
      notifyApiError(e);
    } finally {
      setLoading(false);
    }
  };

  function confirmComplete() {
    const title = EDIT_INVOICE
      ? 'Would you like to edit invoice ?'
      : 'Would you like to create invoice for the invoicing?';
    const content = EDIT_INVOICE
      ? 'Invoice will be edited.'
      : 'Invoicing will be created and invoice will be generated.';

    confirm({
      title,
      content,
      onOk: onSubmit,
    });
  }

  const handleClose = () => {
    setServices([]);
    onClose();
    setNotes('');
  };

  const formatOptGroupOptions = ({ array }) => {
    const result = {};
    array.forEach((item, index) => {
      if (item['Type'] === 'Service' && !!item['ParentRef']) {
        if (!result[item['ParentRef']['name']]) {
          result[item['ParentRef']['name']] = [{ ...item, index }];
        } else {
          result[item['ParentRef']['name']].push({ ...item, index });
        }
      }
    });

    return Object.keys(result).map(item => {
      return {
        label: item,
        options: result[item].map(option => {
          const label =
            option.Name.slice(-1) === '-'
              ? `${option.Name} (${option.UnitPrice})`
              : `${option.Name} - (${option.UnitPrice})`;

          return {
            label,
            value: option.index,
          };
        }),
      };
    });
  };

  let totalService = 0;

  return (
    <div className="new-transaction-container">
      <Modal
        title="Claimant Invoicing"
        visible={isOpened}
        onCancel={handleClose}
        width="1000px"
        className="transaction-modal"
        destroyOnClose
        footer={[
          !EDIT_INVOICE ? (
            <Button key="cancel" type="dashed" onClick={handleClose}>
              Cancel
            </Button>
          ) : null,
          !EDIT_INVOICE ? (
            <Button
              key="save"
              loading={loading}
              onClick={() => onSubmit(false)}
              disabled={loading || services.length === 0}>
              Save Draft
            </Button>
          ) : null,
          <Tooltip
            key="submit"
            title={claimantInfo?.client?.quickbooks_customer_id ? 'Generate Quickbooks Invoice' : null}>
            <Button
              style={{ marginLeft: 30 }}
              type="primary"
              loading={loading}
              onClick={confirmComplete}
              disabled={!claimantInfo?.client?.quickbooks_customer_id || loading || services.length === 0}>
              {EDIT_INVOICE ? 'Edit Invoicing' : 'Complete Invoicing'}
            </Button>
          </Tooltip>,
          claimantInfo?.client?.quickbooks_customer_id ? null : (
            <Tooltip
              key="quickbook-client"
              title={"Quickbooks Customer ID is not set. Open client's details and save for set it"}>
              <ExclamationCircleOutlined style={{ color: 'red', marginLeft: 5 }} />
            </Tooltip>
          ),
        ]}>
        {initLoading ? (
          <div className="selectTransacction_loading">
            <SpinBox loading={initLoading} />
          </div>
        ) : (
          <>
            <Row className="service-list">
              <Row type="flex" className="table-header">
                <Col md={10}>Service</Col>
                <Col md={9} style={{ paddingLeft: 15 }}>
                  Report
                </Col>
                <Col md={4}>Price</Col>
                <Col md={1} />
              </Row>
              <Row className="table-content" type="flex" justify="center">
                <SpinBox loading={loading || pending} title="Pending...">
                  {services.length > 0 ? (
                    services.map((item, index) => {
                      totalService += item.service.price;

                      return (
                        <Row type="flex" className="service-item" key={`service-${index}`}>
                          <Col md={10}>
                            <Text>
                              {item.service.name === CUSTOM_ENTRY_TEXT ? item.service.description : item.service.name}
                            </Text>
                          </Col>
                          <Col md={9} style={{ paddingLeft: 15 }}>
                            <Text>{item.report ? item.report.report_uuid : 'N/A'}</Text>
                          </Col>
                          <Col md={4}>
                            <Text>{item.service.price}</Text>
                          </Col>
                          <Col md={1}>
                            <RemoveIcon className="icon" onClick={onDeleteService(index)} />
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <Text style={{ paddingTop: 20 }}>Add services to invoicing using the form below</Text>
                  )}
                </SpinBox>
              </Row>
              {services.length > 0 && (
                <Row className="table-content" type="flex" justify="center">
                  <Row type="flex" className="service-item">
                    <Col md={19}></Col>
                    <Col md={5}>
                      <Text>{totalService}</Text>
                    </Col>
                  </Row>
                </Row>
              )}
            </Row>

            <Divider style={{ marginBottom: 20 }} />

            <Row className="add-service-form" gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
              <Col md={24} className="service-type">
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                  <Col md={12}>
                    <Row>
                      <Text>Service type</Text>
                    </Row>
                    <Row>
                      <Select
                        isDisabled={loading || pending || billingArray?.length === 0}
                        onChange={({ value }) => setService(value)}
                        isSearchable
                        className="selectTransacction"
                        options={billingArrayOption}
                        placeholder="Please select"
                      />
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Row>
                      <Text>Attach Report</Text>
                    </Row>
                    <Row>
                      <Select
                        isDisabled={loading || disabledReport}
                        placeholder="No attached report"
                        className="selectTransacction"
                        onChange={({ value }) => setAddedReport(value)}
                        defaultValue={infoReportDefault}
                        options={reports.map((item, index) => ({
                          value: index,
                          label: `${item.report_uuid || ''} - ${item.report_name || ''}`,
                        }))}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
              {billingArray[service]?.name === CUSTOM_ENTRY_TEXT && (
                <Col md={24} className="attached-report">
                  <Row>
                    <Text>Custom Service</Text>
                  </Row>
                  <Input
                    size="large"
                    label="Custom Service"
                    placeholder="Please enter"
                    value={customService}
                    onChange={e => setCustomService(e.target.value)}
                    style={{ height: 60 }}
                  />
                </Col>
              )}
              <Col md={4} className="attached-report">
                <Button
                  type="primary"
                  className="green-btn button"
                  onClick={onAddService}
                  disabled={
                    loading ||
                    service === undefined ||
                    (billingArray[service]?.name === CUSTOM_ENTRY_TEXT && !customService) ||
                    !(addedReport >= 0)
                  }>
                  Add Service
                </Button>
              </Col>
              <Col md={24}>
                <InputBox
                  label="Notes"
                  placeholder="Please Enter"
                  disabled={loading}
                  value={notes}
                  onChange={setNotes}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ChooseReportsModal;
