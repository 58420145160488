import { EXTERNAL_AR_ID, EXTERNAL_QA_ID, MSA_MEDICAL_REVIEWER_ID } from 'layout/roles';

export const INDIVIDUAL_BASIC_ID = 1;
export const INDIVIDUAL_PROFESSIONAL_ID = 2;
export const INDIVIDUAL_ELITE_ID = 3;
export const MULTIPLE_BASIC_ID = 4;
export const MULTIPLE_PROFESSIONAL_ID = 5;
export const MULTIPLE_ELITE_ID = 6;
export const MULTIPLE_CUSTOM_ID = 13;

export const CUSTOM_PACKAGE_ID = 8;

// LICENSES
export const LICENSES_MEDICAL_REVIEWER_ID = 15;
export const LICENSES_QA_REVIEWER_ID = 16;
export const LICENSES_REDUCTION_REVIEWER_ID = 17;

export const BASIC_NAME = 'Basic Package';
export const PROFESSIONAL_PACKAGE = 'Professional Package';
export const ELITE_PACKAGE = 'Elite Package';

export const PLAN_LIST_INDIVIDUAL = [
  {
    id: INDIVIDUAL_BASIC_ID,
    name: BASIC_NAME,
    cost: 200,
    trialFree: 'Try for Free with a 7-Day Trial',
    features: [
      {
        name: 'Report types are correct',
        descriptions: ['Medical Cost Projection', 'MSA'],
        allowed: true,
      },
      {
        name: 'Up to 100 GB of Storage',
        allowed: true,
      },
      {
        name: 'Internal Reviewer Rates',
        descriptions: [
          '$50 for less than or equal to 5 users',
          '$100 for more than 5 or less than or equal to 10',
          '$150 for more than 10 users',
        ],
        allowed: true,
      },
    ],
  },
  {
    id: INDIVIDUAL_PROFESSIONAL_ID,
    name: PROFESSIONAL_PACKAGE,
    cost: 250,
    features: [
      {
        name: 'Report Types',
        allowed: true,
        descriptions: [
          'Medical Cost Projection',
          'MSA',
          'Mini Cost Projection',
          'Early Intervention Report (Peer Review)',
        ],
      },
      {
        name: 'Up to 100 GB of Storage',
        allowed: true,
      },
      {
        name: 'Internal Reviewer Rates',
        description: [
          '$50 for less than or equal to 5 users',
          '$100 for more than 5 or less than or equal to 10',
          '$150 for more than 10 users',
        ],
        allowed: true,
      },
    ],
  },
  {
    id: INDIVIDUAL_ELITE_ID,
    name: ELITE_PACKAGE,
    cost: 300,
    features: [
      {
        name: 'Report Types',
        allowed: true,
        descriptions: [
          'Medical Cost Projection',
          'MSA',
          'Mini Cost Projection',
          'Early Intervention Report (Peer Review)',
          'MSA/MCP Combo',
          'Surgical Therapeutic Projection',
          'Life Care Plan',
        ],
      },
      {
        name: 'Up to 100 GB of Storage',
        allowed: true,
      },
      {
        name: 'Internal Reviewer Rates',
        description: [
          '$50 for less than or equal to 5 users',
          '$100 for more than 5 or less than or equal to 10',
          '$150 for more than 10 users',
        ],
        allowed: true,
      },
    ],
  },
];

export const PLAN_LIST_MULTIPLE_USERS = [
  {
    id: MULTIPLE_BASIC_ID,
    name: 'Basic Package',
    cost: 200,
    users: 9,
    extra_cost: 200,
    id_extra: 7,
    trialFree: 'Try for Free with a 7-Day Trial',
    features: [
      {
        name: 'Included Role Types',
        descriptions: ['Super Admin (Default)', 'LCP/Nurse Writer/MD Writer'],
        allowed: true,
      },
      {
        name: 'Up to 100 GB of Storage',
        allowed: true,
      },
      {
        name: 'Internal Reviewer Rates',
        allowed: true,
        description: [
          '$50 for less than or equal to 5 users',
          '$100 for more than 5 or less than or equal to 10',
          '$150 for more than 10 users',
        ],
      },
    ],
  },
  {
    id: MULTIPLE_PROFESSIONAL_ID,
    name: PROFESSIONAL_PACKAGE,
    cost: 250,
    users: 9,
    extra_cost: 250,
    id_extra: 8,
    features: [
      {
        name: 'Included Role Types',
        allowed: true,
        descriptions: [
          'Super Admin (Default)',
          'LCP/Nurse Writer/MD Writer',
          'Internal Case Management Roles ',
          'QA Reviewer',
        ],
      },
      {
        name: 'Up to 100 GB of Storage',
        allowed: true,
      },
      {
        name: 'Internal Reviewer Rates',
        allowed: true,
        descriptions: [
          '$50 for less than or equal to 5 users',
          '$100 for more than 5 or less than or equal to 10',
          '$150 for more than 10 users',
        ],
      },
    ],
  },
  {
    id: MULTIPLE_ELITE_ID,
    name: ELITE_PACKAGE,
    cost: 300,
    users: 9,
    id_extra: 9,
    extra_cost: 300,
    features: [
      {
        name: 'Included Role Types',
        allowed: true,
        descriptions: [
          'Super Admin (Default)',
          'LCP/Nurse Writer/MD Writer',
          'Internal Case Management ',
          'Sales Management/Field Case Management',
          'External Case Management',
          'Medical Reviewer',
          'Reduction Reviewer',
          'Medical Admin',
          'Life Care Plan',
        ],
      },
      {
        name: 'Up to 100 GB of Storage',
        allowed: true,
      },
      {
        name: 'Internal Reviewer Rates',
        allowed: true,
        descriptions: [
          '$50 for less than or equal to 5 users',
          '$100 for more than 5 or less than or equal to 10',
          '$150 for more than 10 users',
        ],
      },
    ],
  },
  {
    id: MULTIPLE_CUSTOM_ID,
    name: 'Custom Package',
    description:
      'Please email requestademo@msatech.io or contact Sam Singleton 727-776-8867 to discuss your custom package and receive a quote. ',
  },
];

export const PLAN_LIST_ID_BASIC = [INDIVIDUAL_BASIC_ID, MULTIPLE_BASIC_ID];
export const PLAN_LIST_ID_PROFESSIONAL = [INDIVIDUAL_PROFESSIONAL_ID, MULTIPLE_PROFESSIONAL_ID];
export const PLAN_LIST_ID_ELITE = [INDIVIDUAL_ELITE_ID, MULTIPLE_ELITE_ID];

export const PLAN_LIST_ID_INDIVIDUAL = [INDIVIDUAL_BASIC_ID, INDIVIDUAL_PROFESSIONAL_ID, INDIVIDUAL_ELITE_ID];
export const PLAN_LIST_ID_MULTIPLE = [MULTIPLE_BASIC_ID, MULTIPLE_PROFESSIONAL_ID, MULTIPLE_ELITE_ID];

export const DISPLAY_NAME_PLAN_REVIEWERS = {
  [EXTERNAL_QA_ID]: 'QA Reviewer License',
  [EXTERNAL_AR_ID]: 'Reduction Reviewer License',
  [MSA_MEDICAL_REVIEWER_ID]: 'MSA Medical Reviewer License',
};

export const LICENSES_REVIEWER_BY_ROLE_REVIEWER = {
  [EXTERNAL_QA_ID]: LICENSES_QA_REVIEWER_ID,
  [EXTERNAL_AR_ID]: LICENSES_REDUCTION_REVIEWER_ID,
  [MSA_MEDICAL_REVIEWER_ID]: LICENSES_MEDICAL_REVIEWER_ID,
};
