import React, { Component } from 'react';
import { Row, Col, Button, Modal, Icon } from 'antd';
import InputBox from 'components/InputBox';
import SpinBox from 'components/SpinBox';
import * as API from 'utils/api';
import * as Utils from 'utils/utils';
import './ModalSharedRegister.scss';

export default class ModalSharedRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client_name: '',
      email: '',
      loading: false,
      loadingTitle: 'Loading...',
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleCreateContact = () => {
    const { client_name, email } = this.state;

    const param = {
      client_name,
      email,
    };

    if (!client_name) {
      Modal.error({ content: 'Please input the Client Name' });
      return;
    }

    if (!email || !Utils.validateEmail(email)) {
      Modal.error({ content: 'Please input the Valid Email' });
      return;
    }
    
    this.setState({ loadingTitle: 'Saving...', loading: true });
    API.createShareLink(param)
      .then(res => {
        this.setState({ loading: false });
        Modal.success({
          content: 'Success',
          onOk: () => this.props.onConfirm(),
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason ? 'Please fill all the fields with valid data' : 'Error';
        Modal.error({
          content: errorMessage,
        });
      });
  };

  handleCopyLink = () => {
    const { tenantId } = this.props;
    const link = `${process.env.REACT_APP_BASE_URL}/register/${tenantId}`;
    navigator.clipboard.writeText(link);
    Modal.success({
      content: 'Copied to clipboard',
    });
  };

  render() {
    const { title, isOpen, onCancel } = this.props;
    const {
      client_name,
      email,
      loading,
      loadingTitle,
    } = this.state;

    return (
      <Modal
        visible={isOpen}
        onCancel={!loading && onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={500}
        className="add-contact-modal"
        title={title}>
        <Row className="modal-body">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <InputBox
                    label="Client Name"
                    value={client_name}
                    onChange={value => this.setState({ client_name: value })}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={24} className="row">
                  <InputBox label="Email" value={email} onChange={value => this.setState({ email: value })} />
                </Col>
              </Row>
              <Row type="flex" justify="end" className="confirm-button">
                <Button type="primary" size="large" className="green-btn button" onClick={this.handleCreateContact}>
                  Send
                </Button>
                <Button type="primary" size="large" className="purple-btn button2" onClick={this.handleCopyLink}>
                  <Icon type="copy" />
                  Copy link
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Modal>
    );
  }
}
