export const STATUS_OPEN = {
  name: 'Open',
  id: 1,
};

export const STATUS_COMPLETED = {
  name: 'Completed',
  id: 7,
};

export const STATUS_IN_QA_REVIEW = {
  name: 'In QA Review',
  id: 14,
};

export const STATUS_IN_ATTORNEY_REVIEW = {
  name: 'In Attorney Review',
  id: 15,
};

export const STATUS_REVISION_REQUESTED = {
  name: 'Revision Requested',
  id: 16,
};

export const STATUS_REVISION_COMPLETED = {
  name: 'Revision Completed',
  id: 17,
};

export const STATUS_REDUCTION_REQUESTED = {
  name: 'Reduction Requested',
  id: 18,
};

export const STATUS_REDUCTION_COMPLETED = {
  name: 'Reduction Completed',
  id: 19,
};

export const STATUS_GENERATED = {
  name: 'Generated',
  id: 20,
};

export const STATUS_IN_SUPERVISOR_REVIEW = {
  name: 'In Legal Nurse Review',
  id: 23,
};
