import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import dynamicData from 'dynamicData';
import { Button, Col, Divider, Icon, Input, message, Modal, Row, Select, Typography, Upload, Tooltip } from 'antd';
import DatePickerSimple from 'react-datepicker';
import arrayMove from 'array-move';
import ClaimAndInjury from 'components/ClaimAndInjury/index';
import TemplateFields from 'components/TemplateFields';
// import ClaimantInfo from 'components/ClaimantInfoOnWizard';
import InlineContact from 'components/InlineContact';
// import InputCurrencyBox from 'components/InputCurrencyBox';
import SpinBox from 'components/SpinBox';
import { updateReportInfo } from 'redux/report';
import * as API from 'utils/api';
import DropDown from 'components/Dropdown';
import { displayData, openDrawer } from '../../../../redux/Drawer/DrawerActions';
import { forbiddenRedirect, verifyPermissions } from '../../utils/verify-permissions';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { notifyApiError, notifyError, notifyInfo } from '../../../../utils/notification';
import PrevNextStep from '../../../../components/PrevNextStep';
import { getPresignedPublicUrlLogo } from 'utils/api';
import { createViewReportIfNotExists } from 'utils/report';

// New
import InputBox from 'components/InputBox';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { LabelDinamic } from 'components/LabelDinamic';
// finished new

import './Demographic.scss';

const { Title, Text } = Typography;
const { Option } = Select;

class Demographic extends Component {
  constructor(props) {
    super(props);

    let report_id = '';

    if (this.props.match.params && this.props.match.params.id) {
      report_id = this.props.match.params.id;
    }

    this.state = {
      report_id: report_id,
      contactsList: [],
      icdCodeList: [],
      // juridictionStateList: [],
      // plaintiff_attorney: undefined,
      // defense_attorney: undefined,
      // insurance_carrier: undefined,
      report_claim_id: '',
      // employer_demographics: undefined,
      // body_parts: '',
      // additional_comments: '',
      related_diagnosis: [],
      referral_date: moment(),
      date_of_report: moment(),
      damage_phone: '',
      imageUrl: '',
      icd_code: '',
      icd_descr: '',
      isModalOpen: false,
      // medicareInfo: {},
      loadingTitle: 'Loading...',
      loading: false,
      report_contacts: [],
      // juridiction_state_id: undefined,
      // is_owcp: undefined,
      date_of_injury: moment(),
      // plaintifList: [],
      // defenseList: [],
      insuranceList: [],
      // employerList: [],
      is_template: false,
      insurance_driver: '',

      // new i need
      description_of_injury: '',
      description_of_injuryLabel: {
        title: 'Facts of Collision',
        field: 'description_of_injury',
      },
      loss_of_enjoyment: '',
      loss_of_enjoymentLabel: {
        title: 'Loss of Enjoyment',
        field: 'loss_of_enjoyment',
      },
      medical_summary: '',
      medical_summaryLabel: {
        title: 'Medical Treatment',
        field: 'medical_summary',
      },
      pre_existing_conditions: '',
      pre_existing_conditionsLabel: {
        title: 'Historical Information',
        field: 'pre_existing_conditions',
      },
      future_medical_expenses: '',
      future_medical_expensesLabel: {
        title: 'Total Medical Expenses and Liens',
        field: 'future_medical_expenses',
      },
      medical_status: '',
      medical_statusLabel: {
        title: 'Property Damage',
        field: 'medical_status',
      },
      insurance_company: '',
      // claim_number: '',
      summary: '',
      // finished new
    };
    this.timeoutId = setInterval(this.handleUpdateReport, this.props.autoSavingTime);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  handleSearchIcdCodes = value => {
    API.getIcdCodeData(value).then(res => {
      let correctArr = [...res.icd_codes];
      this.setState({
        icdCodeList: correctArr,
      });
    });
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const { report_id } = this.state;

    this.setState({ loading: true });

    this.getContactLists();

    API.getIcdCodeData().then(res => {
      let correctArr = [...res.icd_codes];
      this.setState({
        icdCodeList: correctArr,
      });
    });
    
    API.getReportContacts(report_id).then(res => {
      this.setState({ report_contacts: res.report_contacts.rows });
    });

    // si eres Internal QA buscamos la copia del reporte si no existe la creamos
    if(isAllowed(this.props.roles, ROLES_DIVISIONS.QA_ONLY)) {
      await createViewReportIfNotExists({ reportId: report_id });
    }

    API.getReportById(report_id)
      .then(reportInfo => {
        if (!reportInfo.is_template) {
          verifyPermissions(reportInfo, this.props);
        }

        this.props.updateReportInfo(reportInfo);
        let related_diagnosis = [];
        let newIcdCodeList = [];
        let finallIcdCodeList = [];
        if (reportInfo.related_diagnosis && reportInfo.related_diagnosis !== '') {
          related_diagnosis = reportInfo.related_diagnosis.split(',');
          newIcdCodeList = related_diagnosis
            .map(item => {
              return {
                ...JSON.parse(item.split('<').join(',').split('$').join(',')),
                //med_descr: "",
              };
            })
            .filter(item => !this.state.icdCodeList.find(diagnosis => diagnosis.code === item.code));
          finallIcdCodeList = [...newIcdCodeList, ...this.state.icdCodeList];
          this.setState({ icdCodeList: finallIcdCodeList });
        }

        const fields = {};

        reportInfo.editable_field_names.forEach(e => {
          fields[e.field] = e;
        });

        const data = {
          // plaintiff_attorney: reportInfo.plaintiff_attorney || undefined,
          // defense_attorney: reportInfo.defense_attorney || undefined,
          // insurance_carrier: reportInfo.insurance_carrier || undefined,
          report_claim_id: reportInfo.report_claim_id || '',
          // body_parts: reportInfo.body_parts,
          // additional_comments: reportInfo.additional_comments,
          // juridiction_state_id: reportInfo.is_owcp ? 1000 : reportInfo.juridiction_state_id,
          // is_owcp: reportInfo.is_owcp,
          is_template: reportInfo.is_template,
          report_template_id: reportInfo.report_template_id,
          oldDiagnosis: reportInfo.related_diagnosis,
          related_diagnosis: related_diagnosis,
          date_of_injury: reportInfo.date_of_injury ? moment(reportInfo.date_of_injury) : moment(),
          referral_date: reportInfo.referral_date ? moment(reportInfo.referral_date) : moment(),
          date_of_report: reportInfo.date_of_report ? moment(reportInfo.date_of_report) : moment(),
          is_signature_photo: reportInfo.is_signature_photo || false,
          damage_phone: reportInfo.damage_phone,
          loading: false,
          insurance_company: reportInfo.insurance_company,
          insurance_driver: reportInfo.insurance_driver,
          description_of_injury: reportInfo.description_of_injury,
          loss_of_enjoyment: reportInfo.loss_of_enjoyment,
          medical_summary: reportInfo.medical_summary,
          pre_existing_conditions: reportInfo.pre_existing_conditions,
          future_medical_expenses: reportInfo.future_medical_expenses,
          medical_status: reportInfo.medical_status,
          insurance_company: reportInfo.insurance_company,
          // claim_number: reportInfo.claim_number,
          summary: reportInfo.summary,
        };

        if (Object.keys(fields).length > 0) {
          Object.assign(data, {
            description_of_injuryLabel: fields.description_of_injury,
            loss_of_enjoymentLabel: fields.loss_of_enjoyment,
            medical_summaryLabel: fields.medical_summary,
            pre_existing_conditionsLabel: fields.pre_existing_conditions,
            future_medical_expensesLabel: fields.future_medical_expenses,
            medical_statusLabel: fields.medical_status,
          });
        }

        this.setState(data);
        if (reportInfo.is_signature_photo && reportInfo.damage_phone) {
          getPresignedPublicUrlLogo(reportInfo.damage_phone).then(res =>
            this.setState({ imageUrl: res.url || res.data.url })
          );
        }
      })
      .catch(err => {
        forbiddenRedirect(err, this.props);
      });
  };

  getContactLists = () => {
    const isUserAdmin = isAllowed(this.props.roles, [...ROLES_DIVISIONS.SUPERADMIN, ...ROLES_DIVISIONS.SALES_MANAGER]);
    const isUserNurseManager = isAllowed(this.props.roles, ROLES_DIVISIONS.NURSE_MANAGEMENT);

    const tenantOnly = isUserNurseManager && !isUserAdmin;

    //Getting all contacts data
    API.getContactsData({ pageSize: 1000, tenantOnly }).then(res => {
      const dynamic = dynamicData();

      this.setState({
        contactsList: res.contacts,
        // plaintifList: res.contacts.filter(
        //   item => (item.contact_type ? item.contact_type.name : '') === dynamic.plaintiff_attorney
        // ),
        // defenseList: res.contacts.filter(
        //   item => (item.contact_type ? item.contact_type.name : '') === dynamic.defense_attorney
        // ),
        insuranceList: res.contacts.filter(
          item => (item.contact_type ? item.contact_type.name : '') === dynamic.insurance_carrier
        ),
        // employerList: res.contacts.filter(
        //   item => (item.contact_type ? item.contact_type.name : '') === dynamic.employer_demographics
        // ),
      });
    });
  };

  uploadSignature = async file => {
    this.setState({ loading: true });

    try {
      const { url } = await API.getPresignUrlFile({
        name: file.name,
        type: file.type,
      });

      const formData = new FormData();
      for (const key in url.data.fields) {
        formData.append(key, url.data.fields[key]);
      }
      formData.append('file', file);

      await axios.post(url.data.url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      let s3key = url.fileUrl;
      const res = await getPresignedPublicUrlLogo(s3key);
      const signedUrl = res.url || res.data.url;

      this.setState({ imageUrl: signedUrl, damage_phone: s3key, is_signature_photo: true });
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  beforeUpload = async file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notifyError('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notifyError('Image must smaller than 2MB!');
    }
    await this.uploadSignature(file);
    return false;
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleAddNewICDCode = () => {
    const { icd_code, icd_descr, icdCodeList, related_diagnosis } = this.state;
    if (window.isEmptyOrSpaces(icd_code) || window.isEmptyOrSpaces(icd_descr)) {
      return alert('Please input valid data');
    }
    const newItem = { code: icd_code, med_descr: icd_descr };
    if (icd_code === '' || icd_descr === '') {
      Modal.error({
        content: 'Please input the values',
      });
      return;
    }

    newItem.med_descr = newItem.med_descr.split(',').join('$');
    icdCodeList.push(newItem);
    related_diagnosis.push(JSON.stringify(newItem).split(',').join('<'));

    this.setState({ isModalOpen: false, icd_code: '', icd_descr: '' });
  };

  getReportContacts = async () => {
    const { report_id } = this.state;
    let res = await API.getReportContacts(report_id);
    this.setState({ report_contacts: res.report_contacts.rows });
  };

  handleUpdateReport = async (isNextStep = false) => {
    const {
      report_id,
      // plaintiff_attorney,
      // defense_attorney,
      // insurance_carrier,
      // juridiction_state_id,
      // is_owcp,
      date_of_injury,
      report_claim_id,
      // body_parts,
      // additional_comments,
      related_diagnosis,
      referral_date,
      insurance_driver,
      date_of_report,
      damage_phone,
      is_signature_photo,
      icdCodeList,
      // claim_number,
      medical_status,
      medical_statusLabel,
      description_of_injury,
      description_of_injuryLabel,
      loss_of_enjoyment,
      loss_of_enjoymentLabel,
      medical_summary,
      medical_summaryLabel,
      pre_existing_conditions,
      pre_existing_conditionsLabel,
      future_medical_expenses,
      future_medical_expensesLabel,
      imageUrl,
      insurance_company,
      is_template,
      summary,
      report_contacts,
    } = this.state;

    // agrupamos los inputs que se pueden modificar
    const editable_fields = [
      description_of_injuryLabel && description_of_injuryLabel,
      medical_statusLabel && medical_statusLabel,
      loss_of_enjoymentLabel && loss_of_enjoymentLabel,
      medical_summaryLabel && medical_summaryLabel,
      pre_existing_conditionsLabel && pre_existing_conditionsLabel,
      future_medical_expensesLabel && future_medical_expensesLabel,
    ];

    if (related_diagnosis) {
      this.props.displayData({ related_diagnosis: related_diagnosis });
      this.props.openDrawer();
    }
    const new_related_diagnosis = [];
    related_diagnosis.forEach(value => {
      let item = icdCodeList.find(ele => value === ele.code);
      if (item) {
        new_related_diagnosis.push(JSON.stringify(item).replace(',', '|'));
      } else new_related_diagnosis.push(value);
    });

    const params = {
      // plaintiff_attorney,
      // defense_attorney,
      // insurance_carrier,
      report_claim_id,
      // body_parts,
      // additional_comments,
      related_diagnosis: new_related_diagnosis.toString(),
      // juridiction_state_id: is_owcp ? null : juridiction_state_id,
      // is_owcp,

      id: report_id,
      damage_phone,
      is_signature_photo,
      date_of_injury: moment(date_of_injury).format('MM/DD/YYYY'),
      date_of_report: moment(date_of_report).format('MM/DD/YYYY'),
      referral_date: moment(referral_date).format('MM/DD/YYYY'),
      // claim_number,
      medical_status,
      description_of_injury,
      loss_of_enjoyment,
      medical_summary,
      insurance_driver,
      imageUrl,
      insurance_company,
      is_template,
      summary,
      report_contacts,
      pre_existing_conditions,
      future_medical_expenses,
      editable_fields,
    };

    let res;
    try {
      if (isNextStep) {
        this.setState({
          loading: true,
          loadingTitle: 'Saving...  ',
        });
        res = await API.updateReport(params);
        clearTimeout(this.timeoutId);
      } else {
        res = await API.updateReport(params);
      }
      if (res.status === 201) {
        notifyInfo('', res.message);
      }
      this.props.updateReportInfo(res.report);
    } catch (e) {
      notifyApiError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  // goToEditClaimant = () => {
  //   const claimant = this.props.reportInfo.claimant;
  //   this.props.history.push('/app/claimants/edit', { data: claimant });
  // };

  handleDiagnosisDragStart = e => {
    this.dragged = e.currentTarget.parentElement.parentElement;
  };

  handleDiagnosisDragEnd = e => {
    this.dragged.style.display = 'block';

    e.target.parentElement.parentElement.classList.remove('drag-up');
    this.over.classList.remove('drag-up');

    e.target.parentElement.parentElement.classList.remove('drag-down');
    this.over.classList.remove('drag-down');

    const from = this.dragged.querySelector('.related-list-item').dataset.id;
    const to = this.over.querySelector('.related-list-item').dataset.id;
    const data = arrayMove(this.state.related_diagnosis, from, to);

    this.setState({ related_diagnosis: data });
  };

  handleDiagnosisDragOver = e => {
    e.preventDefault();

    this.dragged.style.display = 'none';

    if (e.target.tagName !== 'LI') return;

    const dgIndex = this.dragged.querySelector('.related-list-item').dataset.id;
    const taIndex = e.target.querySelector('.related-list-item').dataset.id;
    const animateName = dgIndex > taIndex ? 'drag-up' : 'drag-down';

    if (
      this.over &&
      e.target.querySelector('.related-list-item').dataset.rid !==
        this.over.querySelector('.related-list-item').dataset.rid
    ) {
      this.over.classList.remove('drag-up', 'drag-down');
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      this.over = e.target;
    }
  };

  handleUpdateTextArea = (type, value) => {
    var duration = 2500;
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.setState({
        [type]: value,
      });
    }, duration);
  };

  render() {
    let dataDynamic = { ...dynamicData() };
    const {
      icdCodeList,
      report_claim_id,
      related_diagnosis,
      referral_date,
      // date_of_report,
      // damage_phone,
      // is_signature_photo,
      imageUrl,
      icd_code,
      icd_descr,
      isModalOpen,
      // medicareInfo,
      loadingTitle,
      loading,
      // juridiction_state_id,
      // is_owcp,
      // juridictionStateList,
      // plaintifList,
      // defenseList,
      insuranceList,
      is_template,
      description_of_injury,
      description_of_injuryLabel,
      insurance_driver,
      medical_status,
      medical_statusLabel,
      loss_of_enjoyment,
      loss_of_enjoymentLabel,
      medical_summary,
      medical_summaryLabel,
      pre_existing_conditions,
      pre_existing_conditionsLabel,
      future_medical_expenses,
      future_medical_expensesLabel,
      insurance_company,
      // claim_number,
      summary,
    } = this.state;

    const notValid = !is_template;
    const isSend =
      this.state.report_contacts.length > 0 && !!insurance_company && !!report_claim_id && !!insurance_driver;

    return (
      <Row className="msa-demographic-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup BI Demand #2
            </Title>
          </Col>
          <Col className="step">
            <DropDown
              beforeNext={async () => await this.handleUpdateReport(false)}
              loading={loading}
              disabled={notValid}
            />
          </Col>
        </Row>

        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Title level={4} className="title">
                    Basic Information
                  </Title>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={24}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    getContactLists={this.getContactLists}
                    contact_type_id={dataDynamic.insurance_carrier_id}
                    another={false}
                    label="Insurance Carrier"
                    report_id={this.state.report_id}
                    contactsList={insuranceList}
                    placeholder="Required"
                    type={'insurance_carrier'}
                    value={this.state.report_contacts
                      .filter(item => item.type === 'insurance_carrier')
                      .map(item => item.contact.id)}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <InputBox
                    type="text"
                    label="Injured Party Name"
                    placeholder="Required"
                    value={insurance_company}
                    onChange={value => {
                      this.setState({ insurance_company: value.replace(/[^a-zA-Z\s]/gi, '') });
                    }}
                  />
                </Col>
                {/* <Col md={11}>
                  <InputBox
                    type="number"
                    label="Claim Number"
                    placeholder="Required"
                    value={claim_number}
                    onChange={value => {
                      this.setState({ claim_number: value });
                    }}
                  />
                </Col> */}
              </Row>

              <ClaimAndInjury
                onChange={value => this.setState({ report_claim_id: value })}
                report_claim_id={report_claim_id}
                onClaimUpdated={value => {}}
              />

              <Row type="flex" justify="space-between" align="top" className="row">
                <Col md={11}>
                  <InputBox
                    type="text"
                    label="Insurance Driver"
                    placeholder="Required"
                    value={insurance_driver}
                    onChange={value => {
                      this.setState({ insurance_driver: value });
                    }}
                  />
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>Date of Loss</Text>
                  </Row>
                  <Row>
                    <DatePickerSimple
                      className="date"
                      placeholderText="Select Referral Date"
                      selected={referral_date?.toDate()}
                      onChange={date => this.setState({ referral_date: date ? moment(date) : undefined })}
                      minDate={new Date('2000-01-01')}
                      showYearDropdown
                      showMonthDropdown
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <RichTextEditor
                    label="Summary"
                    placeholder="Please enter"
                    value={summary}
                    onChange={value => this.handleUpdateTextArea('summary', value)}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <LabelDinamic
                    text={description_of_injuryLabel.title}
                    onChange={value =>
                      this.setState({
                        description_of_injuryLabel: {
                          ...description_of_injuryLabel,
                          title: value,
                        },
                      })
                    }
                  />
                </Col>
                <Col md={24}>
                  <RichTextEditor
                    placeholder="Please enter"
                    value={description_of_injury}
                    onChange={value => this.handleUpdateTextArea('description_of_injury', value)}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <LabelDinamic
                    text={medical_statusLabel.title}
                    onChange={value =>
                      this.setState({
                        medical_statusLabel: {
                          ...medical_statusLabel,
                          title: value,
                        },
                      })
                    }
                  />
                </Col>
                <Col md={24}>
                  <RichTextEditor
                    placeholder="Please enter"
                    value={medical_status}
                    onChange={value => this.handleUpdateTextArea('medical_status', value)}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Text>Property Damage Images</Text>
                </Col>
                <Col md={24}>
                  <Row type="flex" justify="center" align="middle" className="upload">
                    <Upload
                      name="logo"
                      listType="text"
                      className="logo-uploader"
                      showUploadList={false}
                      beforeUpload={this.beforeUpload}
                      ref={node => {
                        this.upload_btn = node;
                      }}>
                      {imageUrl ? (
                        <img src={imageUrl} alt="damage_phone" className="signature-image" />
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <LabelDinamic
                    text={medical_summaryLabel.title}
                    onChange={value =>
                      this.setState({
                        medical_summaryLabel: {
                          ...medical_summaryLabel,
                          title: value,
                        },
                      })
                    }
                  />
                </Col>
                <Col md={24}>
                  <RichTextEditor
                    placeholder="Please enter"
                    value={medical_summary}
                    onChange={value => this.handleUpdateTextArea('medical_summary', value)}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <LabelDinamic
                    text={loss_of_enjoymentLabel.title}
                    onChange={value =>
                      this.setState({
                        loss_of_enjoymentLabel: {
                          ...loss_of_enjoymentLabel,
                          title: value,
                        },
                      })
                    }
                  />
                </Col>
                <Col md={24}>
                  <RichTextEditor
                    placeholder="Please enter"
                    value={loss_of_enjoyment}
                    onChange={value => this.handleUpdateTextArea('loss_of_enjoyment', value)}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <Row className="label">
                    <Text>Related Diagnosis</Text>
                  </Row>
                  <Row>
                    <div className="related-list" onDragOver={e => this.handleDiagnosisDragOver(e)}>
                      <Select
                        mode="multiple"
                        className="dropdown"
                        placeholder="Please enter"
                        value={related_diagnosis}
                        filterOption={false}
                        onSearch={value => {
                          if (value) {
                            this.handleSearchIcdCodes(value);
                          }
                        }}
                        onChange={value => {
                          this.setState({ related_diagnosis: value });
                        }}>
                        {icdCodeList.map((item, index) => {
                          let value = JSON.stringify({
                            code: item.code,
                            med_descr: item.med_descr ? item.med_descr.replace(/,/g, '$') : '',
                          }).replace(/,/g, '<');
                          const sortId =
                            related_diagnosis.indexOf(value) !== -1
                              ? related_diagnosis.indexOf(value)
                              : related_diagnosis.length + index;
                          return (
                            <Option
                              key={index}
                              //filter={item.code.concat("_" + item.med_descr)}
                              value={value}>
                              <Tooltip
                                overlayStyle={{ maxWidth: 300 }}
                                placement="right"
                                title={` (${item.code}) ${item.med_descr}`}>
                                <span
                                  draggable="true"
                                  onMouseDown={e => {
                                    e.stopPropagation();
                                  }}
                                  onDragStart={e => this.handleDiagnosisDragStart(e)}
                                  onDragEnd={e => this.handleDiagnosisDragEnd(e)}
                                  data-id={sortId}
                                  data-rid={`${item.code}-${item.med_descr}`}
                                  className="related-list-item">
                                  {` (${item.code}) ${item.med_descr}`}
                                </span>
                              </Tooltip>
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Row>
                  <Row>
                    <Button type="primary" className="green-btn add-icd-code_button" onClick={this.handleOpenModal}>
                      Add Custom Code
                    </Button>
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <LabelDinamic
                    text={pre_existing_conditionsLabel.title}
                    onChange={value =>
                      this.setState({
                        pre_existing_conditionsLabel: {
                          ...pre_existing_conditionsLabel,
                          title: value,
                        },
                      })
                    }
                  />
                </Col>
                <Col md={24}>
                  <RichTextEditor
                    placeholder="Please enter"
                    value={pre_existing_conditions}
                    onChange={value => this.handleUpdateTextArea('pre_existing_conditions', value)}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={24}>
                  <LabelDinamic
                    text={future_medical_expensesLabel.title}
                    onChange={value =>
                      this.setState({
                        future_medical_expensesLabel: {
                          ...future_medical_expensesLabel,
                          title: value,
                        },
                      })
                    }
                  />
                </Col>
                <Col md={24}>
                  <RichTextEditor
                    placeholder="Please enter"
                    value={future_medical_expenses}
                    onChange={value => this.handleUpdateTextArea('future_medical_expenses', value)}
                  />
                </Col>
              </Row>

              {(is_template || this.state.report_template_id) && (
                <TemplateFields
                  is_template={is_template}
                  section_id={20}
                  report_template_id={this.state.report_template_id}
                  report_id={this.state.report_id}
                />
              )}
              <Divider className="divider" />
              <PrevNextStep disabled={!isSend} beforeNext={async () => await this.handleUpdateReport(true)} />

              <Modal
                visible={isModalOpen}
                onCancel={this.handleCloseModal}
                footer={null}
                centered={true}
                closable={true}
                width={500}
                className="add-icd-code-modal"
                title={'Add Custom Code'}>
                <Row className="modal-body">
                  <Row type="flex" justify="start" align="middle" className="row">
                    <Col className="label">
                      <Text className="label">Code</Text>
                    </Col>
                    <Col className="input-col">
                      <Input
                        placeholder={'Required'}
                        className="input"
                        disabled={false}
                        value={icd_code}
                        onChange={e => this.setState({ icd_code: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" align="middle" className="row">
                    <Col className="label">
                      <Text className="label">Description</Text>
                    </Col>
                    <Col className="input-col">
                      <Input
                        placeholder={'Required'}
                        className="input"
                        disabled={false}
                        value={icd_descr}
                        onChange={e => this.setState({ icd_descr: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row type="flex" justify="end">
                    <Button type="primary" className="green-btn button" onClick={this.handleAddNewICDCode}>
                      ADD
                    </Button>
                  </Row>
                </Row>
              </Modal>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
    userInfo: state.auth.userInfo,
    roles: state.auth.roles,
  };
  return props;
};

export default connect(mapStateToProps, {
  updateReportInfo,
  displayData,
  openDrawer,
})(Demographic);
