import React, { useState } from 'react';
import { Row, Col, Button, Dropdown, Icon, Empty, Menu, Typography } from 'antd';
import SpinBox from 'components/SpinBox';
import moment from 'moment';
import {
  STATUS_REVISION_REQUESTED,
  STATUS_REDUCTION_REQUESTED,
  STATUS_COMPLETED,
  STATUS_REVISION_COMPLETED,
  STATUS_REDUCTION_COMPLETED,
} from 'constants/report_status';
import {
  REPORT_MSA_TYPE_ID,
  REPORT_MSA_REDUCTION_TYPE_ID,
  REPORT_MCP_TYPE_ID,
  REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID,
  REPORT_SECTIONS_DEFAULT,
  REPORT_TYPES_BY_ID,
} from 'constants/reports';
import { CloseCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import DropdownStatus from 'components/DropdownStatus';
import isAllowed, { ROLES_DIVISIONS } from 'layout/roles';
import { useSelector } from 'react-redux';
import { DetailsIcon, RemoveIcon, EditIcon } from 'components/Icons';
import { SPECIALTY_IDS_OBJECT } from 'constants/specialty';
import { getPermissionsBySection, REPORT_PERMISSIONS_NAME } from 'permissions';
import * as API from 'utils/api';

// Style
import './ReportList.scss';
import { getRushFees } from 'utils/utils';
import { notifyApiError, notifyInfo } from 'utils/notification';
import { validateOpenReportForPackage } from 'containers/Reports/utils/helpers';
import { ModalPdf } from 'containers/Marketing/components/ModalPdf';
import { NOT_ACCESS_REPORT_COMPARATION } from 'utils/constant';
import { REPORT_COMPARE_NAV } from 'layout/navigate_const';
import { goFirstStep } from 'utils/report-routing';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ModalUpgratePackageOpenReport } from 'components/Modal/ModalUpgratePackageOpenReport';

const { Text } = Typography;

const ReportList = ({
  loading,
  reportsData,
  reportStatuses,
  handleOpenModalTransaction,
  openAssingModel,
  openReportFiles,
  openReportModal,
  openReportDeleteModal,
  handleOpenModalReview,
  handleOpenModalRev,
  claimantInfo,
}) => {
  const { roles } = useSelector(state => state.auth);
  const history = useHistory();

  const [previewReport, setPreviewReport] = useState('');
  const [loadingPage, setLoadingPage] = useState(false);
  const [openModalUpgradedPlan, setOpenModalUpgradedPlan] = useState({});

  // Permissions
  const { REPORT_PERMISSIONS } = getPermissionsBySection({
    roles,
    permissions: [REPORT_PERMISSIONS_NAME],
  });
  // end permissions

  const isClient = isAllowed(roles, ROLES_DIVISIONS.ALL_CLIENT);

  const getTypeReport = ({ report }) => {
    if (report.report_type.id === REPORT_MSA_TYPE_ID) {
      if (report.pre_settlement) return `${report.report_type.name} Pre Settlement`;

      if (report.post_settlement) return `${report.report_type.name} Post Settlement`;
    }

    if ([REPORT_MCP_TYPE_ID, REPORT_LEGAL_DEMAND_TEMPLATE_TYPE_ID].includes(report.report_type_id) && report?.md_tbd)
      return `${report.report_type.name} ${SPECIALTY_IDS_OBJECT[report.md_tbd].name}`;

    return report.report_type.name;
  };

  const handlePreview = async ({ report }) => {
    try {
      if (
        ![STATUS_REDUCTION_COMPLETED.id, STATUS_REVISION_COMPLETED.id, STATUS_COMPLETED.id].includes(
          report.report_status_id
        )
      )
        return notifyInfo('Report is not completed');

      setLoadingPage(true);
      const reportReview = await API.getReviewReportCompare({ idReport: report.id });

      if (!reportReview?.report_url) return;

      setPreviewReport(reportReview.report_url);
    } catch (e) {
      notifyApiError(e);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleGotoReportEdit = async ({ selectedReport, resquestedReduction = false }) => {
    try {
      setLoadingPage(true);
      const validOpenRerpot = await validateOpenReportForPackage({
        report_type: selectedReport.report_type_id,
        tenant_id: roles.user_roles.tenant_id,
      });

      if (!validOpenRerpot.open)
        return setOpenModalUpgradedPlan({
          ...validOpenRerpot,
          open: true,
          tenant_id: roles.user_roles.tenant_id,
          customer: roles.user_roles.id,
        });

      const sendDataHistory = {
        claimantInfo,
        resquestedReduction,
      };

      const isNurseWriter = isAllowed(roles, ROLES_DIVISIONS.NURSE_ONLY);

      if (
        isNurseWriter &&
        selectedReport?.qaRevisionDate &&
        !NOT_ACCESS_REPORT_COMPARATION.includes(selectedReport?.report_status_id)
      )
        return history.push(`${REPORT_COMPARE_NAV.path}/${selectedReport.id}`);

      goFirstStep({
        sections:
          selectedReport?.sections || REPORT_SECTIONS_DEFAULT[REPORT_TYPES_BY_ID[selectedReport.report_type_id]],
        reportType: REPORT_TYPES_BY_ID[selectedReport.report_type_id],
        report_id: selectedReport.id,
        history: history,
        sendDataHistory,
      });
    } catch (e) {
      setLoadingPage(false);
      notifyApiError(e);
    }
  };

  const menu = item => (
    <Menu>
      {REPORT_PERMISSIONS.preview && (
        <Menu.Item>
          <a
            onClick={() => {
              handlePreview({ report: item });
            }}
            className="item-menu">
            <DetailsIcon className="icon" />
            Preview
          </a>
        </Menu.Item>
      )}
      {REPORT_PERMISSIONS.download && (
        <Menu.Item>
          <a
            onClick={() => {
              openReportFiles({ report: item });
            }}
            className="item-menu">
            <DownloadOutlined
              height={'20px'}
              style={{ color: '#0082ff', fontSize: 18 }}
              width={'20px'}
              className="icon"
            />
            Download
          </a>
        </Menu.Item>
      )}
      {REPORT_PERMISSIONS.view && (
        <Menu.Item>
          <a onClick={() => handleGotoReportEdit({ selectedReport: item })} className="item-menu">
            <DetailsIcon className="icon" />
            View Report
          </a>
        </Menu.Item>
      )}

      {REPORT_PERMISSIONS.edit && (
        <Menu.Item>
          <a onClick={() => openReportModal('EDIT', item)} className="item-menu">
            <EditIcon className="icon" />
            Edit
          </a>
        </Menu.Item>
      )}

      {REPORT_PERMISSIONS.assign && (
        <Menu.Item>
          <a onClick={() => openAssingModel({ report: item })} className="item-menu">
            <Icon type="file-search" style={{ color: '#0082ff', fontSize: 18 }} />
            Assign
          </a>
        </Menu.Item>
      )}

      {REPORT_PERMISSIONS.delete && (
        <Menu.Item>
          <a onClick={() => openReportDeleteModal(item)} className="item-menu">
            <RemoveIcon className="icon" />
            Delete
          </a>
        </Menu.Item>
      )}

      {isClient && item.report_type_id === REPORT_MSA_REDUCTION_TYPE_ID && (
        <Menu.Item>
          <a onClick={() => handleGotoReportEdit({ selectedReport: item })} className="item-menu">
            <DetailsIcon className="icon" />
            View Report
          </a>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Row className="reports-list-container">
      <Row type="flex" className="table-header table-header__reports">
        <Col md={2}>Report ID</Col>
        {!isClient && <Col md={3}>Name</Col>}
        <Col md={isClient ? 3 : 3}>Type</Col>
        {!isClient && <Col md={3}>Requested By</Col>}
        <Col md={isClient ? 3 : 3}>Date of Report</Col>
        <Col md={isClient ? 3 : 3} align="center">
          Needed by
        </Col>
        <Col align="center" md={isClient ? 3 : 3}>
          Status
        </Col>
        <Col md={3} style={{ textAlign: 'center' }}>
          Assigned Nurse
        </Col>
        <Col md={isClient ? 7 : 1} style={{ textAlign: 'right' }}>
          Actions
        </Col>
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading || loadingPage}>
          {reportsData.length > 0 ? (
            <Row data-test-id="detailClaimant_reports">
              {reportsData.map(item => (
                <Row key={item.id} className="record">
                  {/* Report ID */}
                  <Col md={2}>
                    <Text>{item.report_uuid}</Text>
                  </Col>

                  {/* Report Name */}
                  {!isClient && (
                    <Col md={3}>
                      <Text>{item.report_name}</Text>
                    </Col>
                  )}

                  {/* Report Type */}
                  <Col md={isClient ? 3 : 3}>{item.report_type && <Text>{getTypeReport({ report: item })}</Text>}</Col>

                  {/* Requested By */}
                  {!isClient && (
                    <Col md={3}>
                      {item.report_type && (
                        <Text>
                          {item?.created_by?.given_name || ''} {item?.created_by?.family_name || ''}
                        </Text>
                      )}
                    </Col>
                  )}

                  {/* Report Date */}
                  <Col md={isClient ? 3 : 3}>
                    <Text>{moment(item.createdAt).format('MM/DD/YYYY, hh:mm a')}</Text>
                  </Col>

                  {/* Needed by */}
                  <Col md={isClient ? 3 : 3} className="itemRushFees textCenter">
                    {getRushFees({ createdAt: item.createdAt, needed_by: item?.needed_by, max_days: 14 }) && (
                      <p className="textPurple">Rush</p>
                    )}
                    <Text>{item?.needed_by ? moment(item.needed_by).format('MM/DD/YYYY') : ''}</Text>
                  </Col>

                  {/* Report Status */}
                  <Col md={isClient ? 3 : 3} align="center">
                    {!REPORT_PERMISSIONS.edit ? (
                      <>
                        {item.report_status_id === STATUS_REVISION_REQUESTED.id ? (
                          <Button
                            type="primary"
                            className="tagReviewReport purple-btn"
                            onClick={() => handleOpenModalRev({ item })}>
                            {STATUS_REVISION_REQUESTED.name}
                            <CloseCircleOutlined
                              className="remove-report"
                              style={{ color: '#FF7272', fontSize: '18px' }}
                            />
                          </Button>
                        ) : (
                          <Text>{item.report_status.name}</Text>
                        )}
                      </>
                    ) : (
                      <DropdownStatus
                        reportStatuses={reportStatuses}
                        data={item}
                        openModalTransaction={() => handleOpenModalTransaction({ report: item })}
                      />
                    )}
                  </Col>

                  {/* Report Assigned */}
                  <Col
                    md={3}
                    style={{ justifyContent: 'center' }}
                    className={`actions detailedClaimantAssigment ${isClient ? 'hiddenPointer' : ''}`}
                    data-test-id="claimantDetail_assignedNurseWriter"
                    onClick={
                      REPORT_PERMISSIONS.assign
                        ? () =>
                            openAssingModel({
                              report: item,
                              assignmentTypeDisabled: true,
                              clearUser: true,
                              defaultUser: true,
                            })
                        : null
                    }>
                    {item?.nurse?.given_name || '-----'}
                  </Col>

                  {/* BTN CLIENTS */}
                  {isClient && (
                    <Col md={6} className="btnTable_by_client">
                      {![STATUS_REVISION_REQUESTED.id, STATUS_REDUCTION_REQUESTED.id].includes(item.report_status_id) &&
                        [STATUS_COMPLETED.id, STATUS_REVISION_COMPLETED.id, STATUS_REDUCTION_COMPLETED.id].includes(
                          item.report_status_id
                        ) && (
                          <Button
                            type="primary"
                            size="default"
                            className="green-btn button buttonRevision padding-5"
                            onClick={() => handleOpenModalReview(item)}>
                            Request Revision
                          </Button>
                        )}

                      {item.report_type_id === REPORT_MSA_TYPE_ID &&
                        [STATUS_COMPLETED.id, STATUS_REVISION_COMPLETED.id].includes(item.report_status_id) && (
                          <Button
                            type="primary"
                            size="default"
                            className="purple-btn button buttonRevision padding-5"
                            onClick={() =>
                              handleGotoReportEdit({
                                selectedReport: { ...item, report_type_id: REPORT_MSA_REDUCTION_TYPE_ID },
                                resquestedReduction: true,
                              })
                            }>
                            Request Reduction
                          </Button>
                        )}
                    </Col>
                  )}

                  {/* Actions */}
                  <Col md={isClient ? 1 : 1} style={{ textAlign: 'right' }} className="actions">
                    <Dropdown overlay={menu(item)} placement="bottomRight" trigger={['click']}>
                      <Icon type="ellipsis" className="dots-icon" />
                    </Dropdown>
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Empty
              description={loading || loadingPage ? false : 'No Data'}
              image={!loading || !loadingPage ? Empty.PRESENTED_IMAGE_SIMPLE : null}
              className="empty-icon"
            />
          )}
        </SpinBox>
      </Row>

      {/* Modals */}
      {previewReport && <ModalPdf path={previewReport} close={() => setPreviewReport(null)} />}
      {openModalUpgradedPlan?.open && (
        <ModalUpgratePackageOpenReport data={openModalUpgradedPlan} onClose={() => setOpenModalUpgradedPlan({})} />
      )}
    </Row>
  );
};

export default ReportList;
