import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Button, Row, Col } from 'antd';

import './ClaimNumberModal.scss';

const ClaimNumberModal = ({ visible, onCancel, claimNumbers, addClaimNumber }) => {
  const [claimNums, setClaimNums] = useState([]);

  useEffect(() => {
    setClaimNums(claimNumbers);
  }, [claimNumbers]);

  const buttonDisabled = claimNums.some(num => num === '');

  const createClaimNumbers = () => {
    addClaimNumber(claimNums);
    onCancel();
  };

  const editClaimNumber = (target, idx) =>
    setClaimNums(prev =>
      prev.map((num, i) => {
        if (idx === i) {
          return target.value.replace(/[^a-zA-Z0-9\s]/gi, '');
        }

        return num;
      })
    );

  const deleteClaimNumber = idx => setClaimNums(prev => prev.filter((_, i) => i !== idx));

  return (
    <Modal
      title="Claim Numbers"
      visible={visible}
      onCancel={() => {
        onCancel();
        setClaimNums([]);
      }}
      className="report-modal"
      footer={false}
      centered>
      <Form className="claim-number-form">
        {claimNums.map((num, idx) =>
          claimNums.length === 1 ? (
            <Form.Item key={idx}>
              <Input name="claim_number" value={num} onChange={({ target }) => editClaimNumber(target, idx)} />
            </Form.Item>
          ) : (
            <Form.Item key={idx}>
              <Row type="flex" justify="space-between" align="middle">
                <Col md={21}>
                  <Input name="claim_number" value={num} onChange={({ target }) => editClaimNumber(target, idx)} />
                </Col>
                <Col md={2}>
                  <Button ghost type="danger" icon="delete" onClick={() => deleteClaimNumber(idx)} />
                </Col>
              </Row>
            </Form.Item>
          )
        )}
        <Button disabled={buttonDisabled} onClick={() => setClaimNums(prev => [...prev, ''])}>
          Add Another
        </Button>
        <Form.Item className="create-button">
          <Button
            onClick={createClaimNumbers}
            disabled={buttonDisabled}
            type="primary"
            size="large"
            className="button green-btn">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ClaimNumberModal;
