import React, { Component } from "react";
import "./index.scss";

export default class AddressFormatter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    let { address } = this.props;
    address = address.split(",");
    return (
      <div>
        {address[0]}
        <br />
        {address[address.length - 2]}
        <br />
        {address[address.length - 1]}
      </div>
    );
  }
}
