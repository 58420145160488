import { combineReducers } from 'redux';
import auth from './auth';
import report from './report';
import review from './review';
import help from './help';
import notification from './notification';
import application from './application';
import Drawer from './Drawer/DrawerReducer';
import * as types from './auth/actionTypes';

const appReducer = combineReducers({
  auth,
  report,
  review,
  application,
  Drawer,
  help,
  notification,
});

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT_REQUEST) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
