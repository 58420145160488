

function normalizeYear(year) {
    // Century fix
    let YEARS_AHEAD = 20;
    if (year < 100) {
        let currentYear = new Date().getFullYear();
        year += Math.floor(currentYear / 100) * 100;
        if (year > currentYear + YEARS_AHEAD) {
            year -= 100;
        } else if (year <= currentYear - 100 + YEARS_AHEAD) {
            year += 100;
        }
    }
    return year;
}
export const validateDate = (date) => {
    const matchFormatDate = /^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/
    if (!matchFormatDate.test(date)) {
        return;
    }
    let exp = new Date(normalizeYear(1 * matchFormatDate[2]), 1 * matchFormatDate[1] - 1, 1).valueOf();
    let now = new Date();
    let currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
    if (exp <= currMonth) {
        alert('Expired');
    } else {
        alert('Valid');
    };

}

