import React from 'react';

import { Col, Row, Typography } from 'antd';

import { formatCurrency } from 'utils/utils';
import SeeMore from 'components/SeeMore';

const { Text } = Typography;

const LCPMedicareCostTableContentMedicalCost = ({ item }) => {
  return (
    <>
      <Col xs={4} className="text-left">
        <Text>{item.name}</Text>
      </Col>
      <Col xs={2}>
        <Text>{item?.speciality?.name || ''}</Text>
      </Col>
      <Col xs={2}>
        <Text>{item?.cpt_code?.code || ''}</Text>
      </Col>
      <Col xs={5}>
        <Row type="flex" align="middle">
          <Col xs={8}>
            <Text>{item?.units_needed || ''}</Text>
          </Col>
          <Col xs={8}>
            <Text>{'Every'}</Text>
          </Col>
          <Col xs={8}>
            <Text>{item?.every_x_year || ''}</Text>
          </Col>
        </Row>
      </Col>
      <Col xs={2}>
        <Text>{formatCurrency(item?.per_unit_cost || '')}</Text>
      </Col>
      <Col xs={2}>
        <Text>{formatCurrency(item?.annual_cost || '')}</Text>
      </Col>
      <Col xs={2}>
        <Text>{item?.total_years || ''}</Text>
      </Col>
      <Col xs={2}>
        <Text>{formatCurrency(item?.total_cost || '')}</Text>
      </Col>
      <Col xs={3} className="text-left">
        <Text>
          <SeeMore text={item?.comments || ''} />
        </Text>
      </Col>
    </>
  );
};

export default LCPMedicareCostTableContentMedicalCost;
